import React, { useEffect, useRef } from 'react';
import Axios from 'axios';
import FileService from '../services/File/FileService';

import placeHolderImg from '../assets/placeholders/img_placeholder_108x80.jpg';

const Image = ({ src, alt, className }) => {
    let imageRef = useRef(null);
    const CancelToken = Axios.CancelToken;
    let source = CancelToken.source();

    useEffect(() => {
        async function getImage() {
            const image = await FileService.GetImage(src, source.token);
            if (imageRef) {
                if (image) {
                    imageRef.src = image;
                }
                else {
                    imageRef.src = placeHolderImg;
                }
            }
        }
        
        if (src) {
            getImage();
        }
        else {
            imageRef.src = placeHolderImg;
        }

        return () => {
            // source.cancel();
        }
    })

    return (
        <img ref={ref => imageRef = ref} src={placeHolderImg} alt={alt} className={className} />
    )
}

export default Image;