import React, { Component } from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import { DropDownList } from '@syncfusion/ej2-react-dropdowns';
import { DataManager } from '@syncfusion/ej2-data';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Page, Sort, Toolbar, Selection, Search, Reorder, Resize, Edit } from '@syncfusion/ej2-react-grids';
import Axios from 'axios';
import XLSX from 'xlsx';
import { withRouter } from 'react-router-dom';
import Dropzone from 'react-dropzone'

import NavigationPrompt from '../../components/NavigationPrompt';
import Localization from '../../utilities/Localization';

import SessionService from '../../services/SessionService';
import Loading from '../../components/Loading';
import CustomerService from '../../services/Customer/CustomerService';
import SettingsService from '../../services/SettingsService';
import UserService from '../../services/User/UserService';
import UserGroupService from '../../services/User/UserGroupService';
import { buildGridSettings } from '../../utilities/Helper';

export class UserImportExport extends Component {
    source = Axios.CancelToken.source();
    gridSettingsKey = "UserImportExportList";
    hasDraggedColumn = false;
    gridSettings = '';

    constructor() {
        super();

        this.state = {
            hasUnsavedChanges: false,
            loading: true,
            hasInvalidData: false,
            stageData: [],
            validationReport: [],
            files: [],
            currentTabSelected: 0
        };

        this.data = [];

        this.pageSettings = {
            pageSize: 25,
            pageSizes: ['Alle', '5', '10', '20', '50', '100']
        };

        this.reviewDataPageSettings = {
            pageSize: 10
        };

        this.selectionSettings = {
            persistSelection: true
        };

        this.filterSettings = {
            type: 'Menu'
        };
        
        this.importTabHeader = [
            { text: "Vælg fil" }, 
            { text: "Se data" },
            { text: "Import" }
        ];

        this.ImportColumns = [ "Bruger nr.", "Fornavn", "Mellem Navn", "Efternavn", "Stilling", "Tlf. nr.", "Mail", "Initialer", "Brugernavn for Intralogix", "Brugernavn for FlexMat", "Brugernavn for OilMat", "Afdeling", "Brugergruppe", "Primær kontakt (Y/N)", "Godkendelse af Ordre (Y/N)" ];

        this.init = this.init.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.processImport = this.processImport.bind(this);
        this.validateData = this.validateData.bind(this);
        this.dialogOpen = this.dialogOpen.bind(this);

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.buttons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.dialogInstance.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        this.notificationButtons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.validationReportDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        /* End Dialog options  */
    }

    async componentDidMount() {
        this.source.cancel();
        this.source = Axios.CancelToken.source();

        this.init();

        const sessionInfo = await SessionService.GetSessionInfo();
        this.CustomerId = sessionInfo.Id;

        const users = await CustomerService.GetUsers(sessionInfo.Id, { active: true }, this.source.token);
        this.data = users;
        
        const userGroups = await UserGroupService.GetUserGroups(sessionInfo.Id, this.source.token);
        this.userGroups = userGroups;

        const departments = await CustomerService.GetAccountDepartments(sessionInfo.Id, this.source.token);
        this.departments = departments;

        this.userGroupDropDownParams = {
            create: () => {
                this.userGroupElem = document.createElement('input');
                return this.userGroupElem;
            },
            destroy: () => { this.userGroupObj.destroy(); },
            read: () => {
                this.selectedUserGroup = this.userGroupObj.value;
                return this.userGroupElem.value;
            },
            write: (e) => {
                this.userGroupObj = new DropDownList({
                    change: (args) => {
                        
                    },
                    dataSource: new DataManager(userGroups),
                    fields: { value: 'Id', text: 'Name' },
                    floatLabelType: 'Never',
                    value: e.rowData.UserGroupId
                  });
                this.userGroupObj.appendTo(this.userGroupElem);
            },
        };

        this.departmentDropDownParams = {
            create: () => {
                this.departmentElem = document.createElement('input');
                return this.departmentElem;
            },
            destroy: () => { this.departmentObj.destroy(); },
            read: () => {
                this.selectedDepartment = this.departmentObj.value;
                return this.departmentElem.value;
            },
            write: (e) => {
                this.departmentObj = new DropDownList({
                    change: (args) => {
                        
                    },
                    dataSource: new DataManager(departments),
                    fields: { value: 'Id', text: 'Name' },
                    floatLabelType: 'Never',
                    value: e.rowData.DepartmentId
                  });
                  this.departmentObj.appendTo(this.departmentElem);
            },
        };

        const gridSettings = await SettingsService.GetGridSettings(this.gridSettingsKey, this.source.token);
        if (!gridSettings.HasError) {
            this.gridSettings = gridSettings.Settings;
        }

        this.setState({ loading: false }, () => {
            window.addEventListener('wheel', this.onWheelScroll);
        });
    }

    init() {
        this.toolbarOptions = [
            { text: 'Import', tooltipText: 'Import', id: 'import', prefixIcon: 'e-upload' },
            { text: 'Excel Export', tooltipText: 'Excel Export', id: 'export', prefixIcon: 'e-export-excel' },
            { text: 'Download Template', tooltipText: 'Download Template', id: 'download_template', prefixIcon: 'e-download' },
            { text: Localization.General.GridResetFilter, tooltipText: Localization.General.GridResetFilter, id: 'reset_filter', prefixIcon: 'e-cancel' }
        ];

        this.reviewDataToolbarOptions = [
            'Edit', 'Update', 'Cancel', 'Delete'
        ];

        this.reviewDataEditSettings = {
            allowEditing: true,
            allowDeleting: true,
            showDeleteConfirmDialog: true
        }
    }

    componentWillUnmount() {
        this.source.cancel();
        window.removeEventListener('wheel', this.onWheelScroll);
    }

    onColumnDragStart(args) {
        this.hasDraggedColumn = true;
    }

    onCreated() {
        if (this.gridSettings) {
            let settings = JSON.parse(this.gridSettings);
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(column => {
                        if (column.FromIndex !== column.ToIndex) {
                            var tempIndex = this.userGridRef.getColumnIndexByField(column.Field);
                            if (tempIndex !== column.ToIndex){
                                this.userGridRef.reorderColumnByIndex(column.FromIndex, column.ToIndex);
                            }
                        }
    
                        if (column.Width) {
                            this.userGridRef.columns[column.ToIndex].width = column.Width;
                        }
                    });
                }

                if (settings.colName && settings.direction) {
                    this.userGridRef.sortColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.userGridRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.userGridRef.refreshColumns();

                if (settings.pageSize) {
                    if (settings.pageSize === "Alle") {
                        this.userGridRef.pageSettings.pageSize = this.userGridRef.pageSettings.totalRecordsCount;
                        document.querySelector("#user-grid .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.userGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
            }
        }
    }

    async onActionComplete(args) {
        if (args.requestType === 'paging') {
            if (this.userGridRef.pageSettings.pageSize === this.userGridRef.pageSettings.totalRecordsCount) {
                document.querySelector("#user-grid .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, this.userGridRef.pageSettings.pageSize, this.userGridRef.pageSettings.totalRecordsCount, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };
            const result = await SettingsService.SaveGridSettings(payload, this.source.token);
        }
        else if (args.requestType === 'filtering') {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, this.source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, this.source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.userGridRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, this.hasDraggedColumn, columns);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, this.source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }

            this.hasDraggedColumn = false;
        }
    }

    async onResizeStop(args) {
        args.requestType = "resizingGrid";
        let columns = this.userGridRef.getColumns();
        let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, columns);
        this.gridSettings = tempGridSettings;
        const payload = {
            Key: this.gridSettingsKey,
            Settings: this.gridSettings
        };

        const result = await SettingsService.SaveGridSettings(payload, this.source.token);

        if (!result.HasError) {
            this.props.notify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
        }
    }

    dialogOpen(header, content) {
        this.dialogInstance.header = header;
        this.dialogInstance.content = content;
        this.dialogInstance.show();
    }

    async onToolbarClick(args) {
        if (args.item.id === "import") {
            this.userImportDialog.show();
            this.userImportWizardTab.select(0);
            this.userImportWizardTab.enableTab(1, false);
            this.userImportWizardTab.enableTab(2, false);
            this.reviewButton.disabled = true;
            this.setState({ files: [] });
            setTimeout(() => {
                let element = document.querySelector('.user-file-list .file-name-text');
                if (element) {
                    element.innerHTML = "";
                }
            }, 300);
        }
        else if (args.item.id === "export") {
            let source = this.userGridRef.getSelectedRecords();
            if (source.length <= 0) {
                this.props.notify("Advarsel", Localization.General.PleaseSelectItemsToExport);
            } else {                
                let formattedSource = [];

                formattedSource.push(this.ImportColumns);
            
                for (let index = 0; index < source.length; index++) {
                    const element = source[index];
                    let item = [];
                    item.push(element.Id);
                    item.push(element.Account.FirstName);
                    item.push(element.Account.MiddleName);
                    item.push(element.Account.LastName);
                    item.push(element.Position);
                    item.push(element.Account.PhoneNumber);
                    item.push(element.Account.Email);
                    item.push(element.Initials);
                    item.push(element.Account.IntUserName);
                    item.push(element.Account.FmUserName);
                    item.push(element.Account.OmUserName);
                    item.push(element.Department.Id);
                    item.push(element.UserGroup.Id);
                    item.push(element.PrimaryContact ? "Y" : "N");
                    item.push(element.OrdersApproval ? "Y" : "N");
                    formattedSource.push(item);
                }

                let data2 = [
                    [ "Id", "Navn"]
                ];
    
                let data3 = [
                    [ "Id", "Navn"]
                ];
    
                this.departments.forEach(element => {
                    let item = [];
                    item.push(element.Id);
                    item.push(element.Name);
                    data2.push(item);
                });
    
                this.userGroups.forEach(element => {
                    let item = [];
                    item.push(element.Id);
                    item.push(element.Name);
                    data3.push(item);
                });

                let data = XLSX.utils.aoa_to_sheet(formattedSource);
                let sheet2 = XLSX.utils.aoa_to_sheet(data2);
                let sheet3 = XLSX.utils.aoa_to_sheet(data3);
                let workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, data, "User");
                XLSX.utils.book_append_sheet(workbook, sheet2, "Afdeling Ids");
                XLSX.utils.book_append_sheet(workbook, sheet3, "Usergrupper Ids");
                XLSX.writeFile(workbook, "Users Export.xlsx"); // FOR TRANSLATION
            }
        }
        else if (args.item.id === "download_template") {
            let data = [
                this.ImportColumns
            ];

            let data2 = [
                [ "Id", "Navn"]
            ];

            let data3 = [
                [ "Id", "Navn"]
            ];

            this.departments.forEach(element => {
                let item = [];
                item.push(element.Id);
                item.push(element.Name);
                data2.push(item);
            });

            this.userGroups.forEach(element => {
                let item = [];
                item.push(element.Id);
                item.push(element.Name);
                data3.push(item);
            });

            let sheet = XLSX.utils.aoa_to_sheet(data);
            let sheet2 = XLSX.utils.aoa_to_sheet(data2);
            let sheet3 = XLSX.utils.aoa_to_sheet(data3);
            let workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, sheet, "Bruger");
            XLSX.utils.book_append_sheet(workbook, sheet2, "Afdeling Ids");
            XLSX.utils.book_append_sheet(workbook, sheet3, "Usergrupper Ids");
            XLSX.writeFile(workbook, "User Template.xlsx"); // FOR TRANSLATION
        } else if (args.item.id === 'reset_filter') {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                settings.filters = [];
                this.gridSettings = settings;
                const payload = {
                    Key: this.gridSettingsKey,
                    Settings: JSON.stringify(this.gridSettings)
                };
    
                const result = await SettingsService.SaveGridSettings(payload, this.source.token);
    
                if (!result.HasError) {
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                }
            }

            this.userGridRef.clearFiltering();
        }
    }

    onChangeFile(files) {
        if (this.importStatusRef) {
            this.importStatusRef.dataSource = [];
            this.importStatusRef.refresh();
            this.importStatusRef.showSpinner();
        }

        const file = files[0];
        let importStatusData = [];
        
        this.setState({ files }, () => {
            let element = document.querySelector('.user-file-list .file-name-text');
            element.innerHTML = file.name;
        });

        if (file) {
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;
            reader.onload = e => {
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                
                const colHeaders = []
                const colCount = XLSX.utils.decode_range(ws['!ref']).e.c + 1
                for (let i = 0; i < colCount; ++i) {
                    colHeaders[i] = ws[`${XLSX.utils.encode_col(i)}1`].v.toString().trim();
                }

                var colDiffs = this.ImportColumns.filter(x => !colHeaders.includes(x));
                if (colDiffs && colDiffs.length > 0) {
                    importStatusData.push({
                        Row: "Column Headers",
                        Status: `Column/s (${colDiffs.join(", ")}) findes ikke`
                    });
                }

                const tempData = XLSX.utils.sheet_to_json(ws, { header: 0 });
                let tempUsers = this.data.map(x => {return { Id: 0, IntUserName: x.Account.IntUserName, FmUserName: x.Account.FmUserName, OmUserName: x.Account.OmUserName, Email: x.Account.Email, SourceId: x.Id }});

                let data = tempData.map((t, index) => {
                    let errorMessages = [];
                    let isPrimaryContact = false, orderApproval = false;
                    let rawDepartment = t["Afdeling"];
                    let rawUserGroup = t["Brugergruppe"];
                    let department = null, userGroup = null;
                    let rawUserId = t["Bruger nr."] ? parseInt(t["Bruger nr."]) : 0;
                    let existingUser = this.data.find(x => x.Id === rawUserId);

                    if (rawDepartment) {
                        department = this.departments.find(x => x.Id == rawDepartment);
                        if (!department) {
                            errorMessages.push(`Afdeling Id/s (${rawDepartment}) findes ikke`);
                        }
                    }

                    if (rawUserGroup) {
                        userGroup = this.userGroups.find(x => x.Id == rawUserGroup);
                        if (!userGroup) {
                            errorMessages.push(`Brugergruppe Id/s (${rawUserGroup}) findes ikke`);
                        }
                    }

                    if ("Primær kontakt (Y/N)" in t) {
                        isPrimaryContact = t["Primær kontakt (Y/N)"].toString().trim().toLowerCase() === 'y';
                    }

                    if ("Godkendelse af Ordre (Y/N)" in t) {
                        orderApproval = t["Godkendelse af Ordre (Y/N)"].toString().trim().toLowerCase() === "y";
                    }

                    let element = {
                        Id: index + 1,
                        SourceId: existingUser ? existingUser.Id : null,
                        FirstName: t["Fornavn"] ? t["Fornavn"].toString().trim() : '',
                        MiddleName: t["Mellem Navn"] ? t["Mellem Navn"].toString().trim() : '',
                        LastName: t["Efternavn"] ? t["Efternavn"].toString().trim() : '',
                        Position: t["Stilling"] ? t["Stilling"].toString().trim() : '',
                        PhoneNumber: t["Tlf. nr."] ? t["Tlf. nr."].toString().trim() : '',
                        Email: t["Mail"] ? t["Mail"].toString().trim() : '',
                        Initials: t["Initialer"] ? t["Initialer"].toString().trim() : '',
                        IntUserName: t["Brugernavn for Intralogix"] ? t["Brugernavn for Intralogix"].toString().trim() : '',
                        FmUserName: t["Brugernavn for FlexMat"] ? t["Brugernavn for FlexMat"].toString().trim() : '',
                        OmUserName: t["Brugernavn for OilMat"] ? t["Brugernavn for OilMat"].toString().trim() : '',
                        DepartmentId: department ? department.Id : null,
                        DepartmentName: department ? department.Name : null,
                        UserGroupId: userGroup ? userGroup.Id : null,
                        UserGroupName: userGroup ? userGroup.Name : null,
                        PrimaryContact: isPrimaryContact,
                        OrdersApproval: orderApproval
                    };

                    tempUsers.push({ Id: element.Id, IntUserName: element.IntUserName, FmUserName: element.FmUserName, OmUserName: element.OmUserName, Email: element.Email });
                    errorMessages.push(...this.validateElement(element, tempUsers));

                    if (errorMessages.length > 0) {
                        importStatusData.push({
                            Row: (index + 1).toString(),
                            Status: errorMessages.join(", ")
                        });
                    }

                    return element;
                })

                this.setState({ stageData: data });

                if (this.importStatusRef) {
                    this.importStatusRef.hideSpinner();
                    if (data && data.length === 0) {
                        this.reviewButton.disabled = true;
                        importStatusData.push({
                            Row: "Alle",
                            Status: Localization.ImportExport.NoRowsFound
                        });
                    } else if (importStatusData.length === 0) {
                        this.reviewButton.disabled = false;
                        importStatusData.push({
                            Row: "Alle",
                            Status: Localization.ImportExport.NoErrorsFound
                        });
                    } else {
                        this.reviewButton.disabled = true;
                    }
                    this.importStatusRef.dataSource = importStatusData;
                    this.importStatusRef.refresh();
                }
            };

            if (rABS) reader.readAsBinaryString(file);
            else reader.readAsArrayBuffer(file);
        }
    }

    onButtonClick(args) {
        let { stageData, files } = this.state;
        
        switch(args.target.id) {
            case "select-file":
                this.userImportWizardTab.select(0);
                this.userImportWizardTab.enableTab(1, false);
                this.userImportWizardTab.enableTab(2, false);
                break;
            case "review-data":
                if (files.length <= 0) {
                    this.props.notify("Advarsel", Localization.ImportExport.PleaseSelectAFile);
                    return;
                }
                else if (stageData.length <= 0) {
                    this.props.notify("Advarsel", Localization.ImportExport.NoRowsFound);
                    return;
                }

                this.userImportWizardTab.enableTab(1, true);
                this.userImportWizardTab.select(1);
                setTimeout(() => {
                    this.stageGridRef.dataSource = stageData;
                    this.stageGridRef.refresh();
                }, 300);
                break;
            case "finalize-import":
                let isValid = this.validateData();

                if (!isValid) {
                    this.validationReportDialog.show();
                    return;
                }

                this.userImportWizardTab.enableTab(2, true);
                this.userImportWizardTab.select(2);

                this.processImport();
                break;
        }
    }

    validateData() {
        let { stageData, validationReport } = this.state;
        validationReport = [];

        let users = this.data.map(x => {return { Id: 0, IntUserName: x.Account.IntUserName, FmUserName: x.Account.FmUserName, OmUserName: x.Account.OmUserName, Email: x.Account.Email, SourceId: x.Id }});
        users.push(...stageData.map(x => {return { Id: x.Id, IntUserName: x.IntUserName, FmUserName: x.FmUserName, OmUserName: x.OmUserName, Email: x.Email }}));

        for (let index = 0; index < stageData.length; index++) {
            const element = stageData[index];
            let errorList = [];

            errorList = this.validateElement(element, users);

            if (errorList.length > 0) {
                validationReport.push({
                    Id: element.Id,
                    Messages: errorList
                })
            }
        }

        this.setState({ validationReport });
        return validationReport.length <= 0;
    }

    validateElement(element, tempUsers) {
        let errorList = [];

        if (!element.FirstName) {
            errorList.push('Fornavn kræves');
        }
        if (!element.LastName) {
            errorList.push('Efternavn kræves');
        }
        if (!element.Email) {
            errorList.push('Mail kræves');
        }
        if (!element.DepartmentId) {
            errorList.push('Afdeling kræves');
        }
        if (!element.IntUserName && !element.FmUserName && !element.OmUserName) {
            errorList.push('Brugernavn kræves');
        }
        if (!element.UserGroupId) {
            errorList.push('Brugergruppe kræves');
        }
        
        if (element.IntUserName) {
            if (!element.IntUserName.match(/^[a-z0-9]+$/i)) {
                errorList.push(Localization.Customer.ErrorInvalidUserNameSystem.replace("{system}", "Intralogix"));
            }

            if (tempUsers.some(x => x.IntUserName === element.IntUserName && x.Id !== element.Id && !(element.SourceId && x.SourceId === element.SourceId))) {
                errorList.push(Localization.User.ExistingUsernameSystem.replace("{system}", "Intralogix"));
            }
        }

        if (element.FmUserName) {
            if (!element.FmUserName.match(/^[a-z0-9]+$/i)) {
                errorList.push(Localization.Customer.ErrorInvalidUserNameSystem.replace("{system}", "FlexMat"));
            }

            if (tempUsers.some(x => x.FmUserName === element.FmUserName && x.Id !== element.Id && !(element.SourceId && x.SourceId === element.SourceId))) {
                errorList.push(Localization.User.ExistingUsernameSystem.replace("{system}", "FlexMat"));
            }
        }

        if (element.PhoneNumber && !element.PhoneNumber.match(/^[+]{0,1}[\d\s]*$/i)) {
            errorList.push(Localization.Customer.ErrorInvalidTelephone);
        }

        if (element.OmUserName && tempUsers.some(x => x.OmUserName === element.OmUserName && x.Id !== element.Id && !(element.SourceId && x.SourceId === element.SourceId))) {
            errorList.push(Localization.User.ExistingUsernameSystem.replace("{system}", "OilMat"));
        }

        if (tempUsers.some(x => x.Email === element.Email && x.Id !== element.Id && !(element.SourceId && x.SourceId === element.SourceId))) {
            errorList.push(Localization.User.ExistingEmail);
        }
        return errorList;
    }

    fileSelectTab() {
        return (
            <div className="tab-control--item">
                <Dropzone onDrop={this.onChangeFile} accept=".xls, .xlsx" maxFiles={1}>
                    {({getRootProps, getInputProps}) => (
                        <section className="container">
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} />
                                <p>Træk og slip filen her, eller klik for at vælge en fil</p>
                            </div>
                            <div className="user-file-list">
                                <div className="file-name-label">Valgte fil:</div>
                                <div className="file-name-text"></div>
                            </div>
                        </section>
                    )}
                </Dropzone>
                <div className="import-status-container">
                    <div>Import Status:</div>
                </div>
                <GridComponent ref={ref => this.importStatusRef = ref } dataSource={this.statusData} allowPaging={true} allowSorting={true} pageSettings={{ pageSize: 5 }}
                    selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both" width="100%" allowTextWrap={true}>
                    <ColumnsDirective>
                        <ColumnDirective headerText="Row" field="Row" width='200' isPrimaryKey={true} />
                        <ColumnDirective headerText="Status" field="Status" allowEditing={false} />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Search]} />
                </GridComponent>
                <div className="wizard-action-buttons">
                    <button ref={ref => this.reviewButton = ref} id="review-data" className="e-btn e-primary" onClick={this.onButtonClick}>Se data</button>
                </div>
            </div>
        )
    }

    async processImport() {
        let { stageData } = this.state;
        let payload = []

        for (let index = 0; index < stageData.length; index++) {
            const element = stageData[index];
            element.Id = 0;

            payload.push({
                Id: element.SourceId ? element.SourceId : 0,
                Account: {
                    Active: true,
                    IntUserName: element.IntUserName,
                    FmUserName: element.FmUserName,
                    OmUserName: element.OmUserName ? element.OmUserName : null,
                    FirstName: element.FirstName,
                    MiddleName: element.MiddleName,
                    LastName: element.LastName,
                    Email: element.Email,
                    PhoneNumber: element.PhoneNumber,
                    AccessControlPanel: false,
                    LoginRule: 1,
                    CustomerId: this.CustomerId,
                    Initials: element.Initials,
                    AppAccess: true
                },
                UserGroupId: element.UserGroupId,
                DepartmentId: element.DepartmentId,
                CustomMenu: { Intralogix: [], Flexmat: [] },
                PrimaryContact: element.PrimaryContact,
                Position: element.Position,
                ResponsiblityGroupIds: [],
                OrdersApproval: element.OrdersApproval,
                CustomerId: this.CustomerId,
                IsDelete: false,
                ChangeOrder: index + 1
            })
        }
        
        const result = await UserService.BulkSave(payload, this.source.token);

        if (!result.HasError) {
            this.props.notify(Localization.General.SuccessHeader, Localization.Tools.SuccessImport);
            
            const users = await CustomerService.GetUsers(this.CustomerId, { active: true }, this.source.token);
            this.data = users;

            this.userGridRef.dataSource = users;
            this.userGridRef.refresh();

            this.importStatusRef.dataSource = [];
            this.importStatusRef.refresh();

            this.userImportDialog.hide();
            this.userImportWizardTab.select(0);
            this.userImportWizardTab.enableTab(1, false);
            this.userImportWizardTab.enableTab(2, false);

            this.setState({ stageData: [], files: [] });
        }
        else {
            this.props.notify("Advarsel", result.ErrorMessage);
            this.onButtonClick({ target: { id: 'review-data' }});
        }
    }

    reviewDataActionBegin(args) {
        if (args.requestType === "save") {
            let { stageData } = this.state;
            let data = stageData.find(x => x.Id === args.data.Id);
            data = args.data;
            data.UserGroupId = this.selectedUserGroup;
            data.DepartmentId = this.selectedDepartment;
            this.setState({ stageData })
        }
    }

    reviewDataActionComplete(args) {
        if (args.requestType === "delete") {
            let { stageData } = this.state;
            stageData = stageData.filter(x => x.Id !== args.data[0].Id);
            this.setState({ stageData });
        }
    }

    reviewDataTab() {
        return (
            <div className="tab-control--item">
                <GridComponent ref={ref => this.stageGridRef = ref } dataSource={this.stageData} allowPaging={true} allowSorting={true} pageSettings={this.reviewDataPageSettings} toolbar={this.reviewDataToolbarOptions}
                    selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both" editSettings={this.reviewDataEditSettings}
                    allowResizing={true} actionBegin={this.reviewDataActionBegin.bind(this)} actionComplete={this.reviewDataActionComplete.bind(this)} width="100%">
                    <ColumnsDirective>
                        <ColumnDirective headerText="No." field="Id" isPrimaryKey={true} />
                        <ColumnDirective headerText="Fornavn" field="FirstName" />
                        <ColumnDirective headerText="Mellem Navn" field="MiddleName" />
                        <ColumnDirective headerText="Efternavn" field="LastName" />
                        <ColumnDirective headerText="Stilling" field="Position" />
                        <ColumnDirective headerText="Tlf. nr." field="PhoneNumber" />
                        <ColumnDirective headerText="Mail" field="Email" />
                        <ColumnDirective headerText="Initialer" field="Initials" />
                        <ColumnDirective headerText="Brugernavn for Intralogix" field="IntUserName" />
                        <ColumnDirective headerText="Brugernavn for FlexMat" field="FmUserName" />
                        <ColumnDirective headerText="Brugernavn for OilMat" field="OmUserName" />
                        <ColumnDirective headerText="Afdeling" field="DepartmentName" editType="dropdownedit" edit={this.departmentDropDownParams} />
                        <ColumnDirective headerText="Brugergruppe" field="UserGroupName" editType="dropdownedit" edit={this.userGroupDropDownParams} />
                        <ColumnDirective headerText="Primær kontakt" field="PrimaryContact" textAlign="Center" editType="booleanedit" displayAsCheckBox={true}  />
                        <ColumnDirective headerText="Godkendelse af Ordre" field="OrdersApproval" textAlign="Center" editType="booleanedit" displayAsCheckBox={true}  />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Selection, Toolbar, Search, Resize, Edit]} />
                </GridComponent>
                <div className="wizard-action-buttons">
                    <button id="select-file" className="e-btn" onClick={this.onButtonClick}>Vælg fil</button>
                    <button id="finalize-import" className="e-btn e-primary" onClick={this.onButtonClick}>Bekræft og importer</button>
                </div>
            </div>
        )
    }

    finalizeImportTab() {
        return (
            <div className="tab-control--item">
                <div className="importing-text">Importere data. Vent  venligst</div>
            </div>
        )
    }

    onSelect(args) {
        if (args.isSwiped) {
            args.cancel = true;
        }
    }

    onWheelScroll = () => {
        let grid = document.getElementById('user-grid');
        let toolbar = document.querySelector('#user-grid.e-grid .e-toolbar');
        let tableHeader = document.querySelector('#user-grid .e-gridheader');

        if (toolbar && tableHeader) {
            let boundingRect = tableHeader.getBoundingClientRect();
            
            if (boundingRect.top < 90) {
                toolbar.classList.add('c-hover-grid-toolbar');
                toolbar.style.width = (grid.clientWidth - 5) + 'px';
            }
            else {
                toolbar.classList.remove('c-hover-grid-toolbar');
                toolbar.style.width = 'auto';
            }
        }
    }
    
    render() {
        const { loading, hasUnsavedChanges, validationReport } = this.state;
        
        if (loading) {
            return <Loading />
        }

        return (
            <div style={{ margin: '20px 0' }}>
                <NavigationPrompt when={hasUnsavedChanges} 
                    navigate={path => this.props.history.push(path)}
                    initiateSave={() => {}}
                    shouldBlockNavigation={location => {
                        if (hasUnsavedChanges) {
                            return true;
                        }
                        return false;
                    }}
                />
                <GridComponent id="user-grid" ref={ref => this.userGridRef = ref } dataSource={this.data} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings} toolbar={this.toolbarOptions}
                    selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both" editSettings={{}}
                    allowReordering={true} allowResizing={true} toolbarClick={this.onToolbarClick.bind(this)} resizeStop={this.onResizeStop.bind(this)}
                    actionComplete={this.onActionComplete.bind(this)} columnDragStart={this.onColumnDragStart.bind(this)} created={this.onCreated.bind(this)}>
                    <ColumnsDirective>
                        <ColumnDirective type='checkbox' width='30' textAlign="Center"></ColumnDirective>
                        <ColumnDirective field="Id" headerText="Bruger nr." width='80' allowFiltering={false} isPrimaryKey={true}/>
                        <ColumnDirective field="Name" headerText="Navn" width='120' allowFiltering={true} />
                        <ColumnDirective field="Position" headerText="Stilling" width='100' allowFiltering={true} />
                        <ColumnDirective field="PhoneNumber" headerText="Tlf. Nr." width='100' allowFiltering={true} />
                        <ColumnDirective field="Email" headerText="Mail" width='100' allowFiltering={true} />
                        <ColumnDirective field="Initials" headerText="Initialer" width='80' allowFiltering={true} />
                        <ColumnDirective field="IntUserName" headerText="Brugernavn for Intralogix" width='100' allowFiltering={true} />
                        <ColumnDirective field="FmUserName" headerText="Brugernavn for FlexMat" width='100' allowFiltering={true} />
                        <ColumnDirective field="OmUserName" headerText="Brugernavn for OilMat" width='100' allowFiltering={true} />
                        <ColumnDirective field="DepartmentName" headerText="Afdeling" width='100' allowFiltering={true} />
                        <ColumnDirective field="UserGroupName" headerText="Brugergruppe" width='110' allowFiltering={true} />
                        <ColumnDirective field="PrimaryContact" headerText="Primær Kontakt" width='110' textAlign="Center" displayAsCheckBox={true} allowFiltering={true} />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Selection, Toolbar, Search, Reorder, Resize]} />
                </GridComponent>
                <DialogComponent id="user-card-dialog" isModal={true} buttons={this.buttons} width='auto' ref={dialog => this.dialogInstance = dialog} target='body' visible={false} showCloseIcon={true}
                    cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} />
                <div>
                    <DialogComponent isModal={true} width='auto' ref={dialog => this.userImportDialog = dialog} target='body'visible={false} showCloseIcon={true} header="Import Brugere" 
                        cssClass="import-dialog" animationSettings={this.animationSettings} allowDragging={true} enableResize={true}>
                            <div className="dialog-content">
                                <TabComponent ref={ref => this.userImportWizardTab = ref} className="tab-control" heightAdjustMode="Content" selecting={this.onSelect.bind(this)}>
                                    <TabItemsDirective>
                                        <TabItemDirective header={this.importTabHeader[0]} content={this.fileSelectTab.bind(this)} />
                                        <TabItemDirective header={this.importTabHeader[1]} content={this.reviewDataTab.bind(this)} disabled={true} />
                                        <TabItemDirective header={this.importTabHeader[2]} content={this.finalizeImportTab.bind(this)} disabled={true} />
                                    </TabItemsDirective>
                                </TabComponent>
                            </div>
                    </DialogComponent>
                </div>
                <div>
                    <DialogComponent isModal={true} width='auto' ref={dialog => this.validationReportDialog = dialog} target='body'visible={false} showCloseIcon={true} header="Rapport over Ændringer" 
                        cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} buttons={this.notificationButtons}>
                            <div>
                                <table className="table" style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th>Row #</th>
                                            <th className="text-left">Validation Messages</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            validationReport.map(validation => {
                                                return (
                                                    <tr className="changelog-row">
                                                        <td className="text-center">{validation.Id}</td>
                                                        <td className="required">
                                                            {validation.Messages.join(', ')}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                    </DialogComponent>
                </div>
            </div>
        )
    }
}

export default withRouter(UserImportExport);
