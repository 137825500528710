import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import Main from './Main';
import SigninCallback from './pages/SigninCallback';
import SilentCallback from './pages/SilentCallback';
import ModuleApprovals from './pages/ModuleApprovals';
import ModuleOilmatOrder from './pages/ModuleOilmatOrder';

import ForgotPassword from './pages/Account/ForgotPassword';
import ResetPassword from './pages/Account/ResetPassword';

import './styles/material.scss';
import './styles/changepassword.scss';
import './styles/custom.scss'
import OilmatRegistration from './pages/Oilmat/OilmatRegistration';

export default class App extends Component {
    render() {
        return (
            <Switch>
                <Route exact path={`/signin-callback`} component={SigninCallback} />
                <Route exact path={`/silent-callback`} component={SilentCallback} />
                <Route exact path="/oilmat/registration" component={OilmatRegistration} />
                <Route path={`/message/oilmat/order`} component={ModuleOilmatOrder} />
                <Route path={`/message/approvals`} component={ModuleApprovals} />
                <Route path={`/account/forgot`} component={ForgotPassword} />
                <Route path={`/account/reset`} component={ResetPassword} />
                <Route path={`/`} component={Main} />
            </Switch>
        ) 
    }
}
