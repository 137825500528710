import HttpClient from '../../utilities/HttpClient';

const API = {
    getMachine: `/machine/{id}`,
    deleteMachine: '/machine/{id}',
    getMachineByCustomer: '/machine/{customer_id}/byCustomer',
    postMachineSettings: '/machine/settings',
    postMachineModules: '/machine/modules',
    postMachineModuleConfig: '/machine/moduleConfig',
    postMachineMain: '/machine/main',
    postMachineBox: '/machine/box',
    getMachineProductsBySupplier: '/machine/{machineId}/products/{supplierId}',
    getMachineBox: '/machine/box/{box_id}',
    getAvailableBoxes: '/machine/availableBoxes?customerId={customerId}'
}

class MachineService {
    async GetMachine(id, cancelToken) {
        const url = API.getMachine.replace('{id}', id);
        return HttpClient.Get(url, null, cancelToken);
    }

    async GetMachineByCustomer(id, cancelToken) {
        const url = API.getMachineByCustomer.replace('{customer_id}', id);
        let result = await HttpClient.Get(url, null, cancelToken);

        result = result.map(r => {
            let contactPersons = [];

            if (r.PrimaryContacts) {
                r.PrimaryContacts.map(p => {
                    if (p) {
                        let fullName = p.Account.FullName.toLowerCase();
                        fullName = fullName.charAt(0).toUpperCase() + fullName.slice(1)
                        contactPersons.push(fullName);
                    }
                });
            }
            
            return {
                ...r,
                ContactPersons: contactPersons.join(', ')
            }
        })

        return result;
    }

    async DeleteMachine(id, param, cancelToken) {
        const url = API.deleteMachine.replace('{id}', id);
        return HttpClient.Delete(url, param, cancelToken);
    }

    async PostMachineSettings(data, cancelToken) {
        return HttpClient.Post(API.postMachineSettings, data, cancelToken);
    }

    async PostMachineModules(data, cancelToken) {
        return HttpClient.Post(API.postMachineModules, data, cancelToken);
    }

    async PostMachineModuleConfig(data, cancelToken) {
        return HttpClient.Post(API.postMachineModuleConfig, data, cancelToken);
    }

    async PostMachineMain(data, cancelToken) {
        const payload = {
            Id: data.Id,
            CustomName: data.MachineName,
            Active: data.Active
        }
        return HttpClient.Post(API.postMachineMain, payload, cancelToken);
    }

    async PostMachineBox(data, cancelToken) {
        const payload = {
            Id: data.Id,
            SlotStart: data.SlotStart,
            ShelfSlotNo: data.ShelfSlotNo,
            BoxType: data.BoxType,
            ModuleId: data.ModuleId
        }
        return HttpClient.Post(API.postMachineBox, payload, cancelToken);
    }

    async GetMachineProductsBySupplier(machineId, supplierId, cancelToken) {
        const url = API.getMachineProductsBySupplier.replace('{machineId}', machineId).replace('{supplierId}', supplierId);
        return HttpClient.Get(url, null, cancelToken);
    }

    async GetMachineBox(id, cancelToken) {
        const url = API.getMachineBox.replace('{box_id}', id);
        return HttpClient.Get(url, null, cancelToken);
    }

    async GetMachineAvailableBoxes(customerId, cancelToken) {
        const url = API.getAvailableBoxes.replace("{customerId}", customerId);
        return HttpClient.Get(url, null, cancelToken);
    }
}

const machineService = new MachineService();
export default machineService;