import React, { Component } from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DataManager } from '@syncfusion/ej2-data';
import { TabComponent, TabItemDirective, TabItemsDirective, TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Page, Sort, Toolbar, Selection, Search, Reorder, Resize, Edit } from '@syncfusion/ej2-react-grids';
import { TreeGridComponent, ColumnsDirective as TreeGridColumnsDirective, ColumnDirective as TreeGridColumnDirective, Filter as TreeGridFilter, 
    Sort as TreeGridSort, Inject as TreeGridInject, Edit as TreeGridEdit, Page as TreeGridPage, Toolbar as TreeGridToolbar } from '@syncfusion/ej2-react-treegrid';
import Axios from 'axios';
import { DropDownList } from '@syncfusion/ej2-react-dropdowns';
import XLSX from 'xlsx';
import Dropzone from 'react-dropzone'

import NavigationPrompt from '../../components/NavigationPrompt';
import Localization from '../../utilities/Localization';
import Card from '../../components/Card';
import placeHolderImg from '../../assets/placeholders/img_placeholder_108x80.jpg';
import Image from '../../components/Image';
import SessionService from '../../services/SessionService';
import ProductGroupService from '../../services/Product/ProductGroupService';
import Loading from '../../components/Loading';
import SettingsService from '../../services/SettingsService';
import ExpenseGroupService from '../../services/Customer/ExpenseGroupService';
import FileService from '../../services/File/FileService';
import { buildGridSettings } from '../../utilities/Helper';

export class ProductGroupImportExport extends Component {
    source = Axios.CancelToken.source();
    gridSettingsKey = "ProductGroupImportExportList";
    hasDraggedColumn = false;
    gridSettings = '';

    constructor() {
        super();

        this.state = {
            hasUnsavedChanges: false,
            loading: true,
            stageData: [],
            validationReport: [],
            selectedProductGroup: {},
            statusData: []
        }

        this.data = [];

        this.pageSettings = {
            pageSize: 25,
            pageSizes: ['Alle', '5', '10', '20', '50', '100']
        };

        this.importStatusDataPageSettings = {
            pageSize: 5
        };

        this.reviewDataPageSettings = {
            pageSize: 10
        };

        this.selectionSettings = {
            persistSelection: true
        };

        this.filterSettings = {
            type: 'Menu'
        };
        
        this.importTabHeader = [
            { text: "Vælg fil" }, 
            { text: "Se data" },
            { text: "Import" }
        ];

        this.UnderWeightData = [
            { Id: 1, text: "Indtast altid antal" },
            { Id: 2, text: "Vej altid" },
            { Id: 3, text: "Vælg ved træk" },
        ];

        this.ImportColumns = [ "Navn", "Faktor", "Avance I %", "Sags Nr. (Y/N)", "Under Garanteret Vælg(Indtast altid antal/Vej altid/Vælg ved træk)", "Omkostnings gruppe", "Ved indkøb Tolerance i %", "Overliggende gruppe", "Billede Fil Navn" ];

        this.init = this.init.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.processImport = this.processImport.bind(this);
        this.validateData = this.validateData.bind(this);

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.notificationButtons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.validationReportDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];

        this.otherSettingsButtons = [
            {
                click: this.onOtherSettingClose,
                buttonModel: {
                    content: 'Tilbage'
                }
            },
            {
                click: this.onSaveOtherSetting,
                buttonModel: {
                    isPrimary: true,
                    content: 'OK'
                }
            }
        ];
        /* End Dialog options  */
    }

    async componentDidMount() {
        this.source.cancel();
        this.source = Axios.CancelToken.source();

        this.init();

        const sessionInfo = await SessionService.GetSessionInfo();
        this.CustomerId = sessionInfo.Id;

        const productGroups = await ProductGroupService.GetProductGroups(sessionInfo.Id, this.source.token);
        this.productGroups = productGroups;
        this.data = productGroups;
        this.productGroupsIter = this.iterGroups(productGroups, null);
        
        // const result = await ProductGroupService.GetProductGroupsLite(sessionInfo.Id, this.source.token);
        // this.productGroupLiteData = result;
        // this.primaryProductGroupFields.dataSource = result;

        const prodGroupImages = await FileService.GetProductImages(true, this.source.token);
        this.productGroupImages = prodGroupImages;

        const expenseGroups = await ExpenseGroupService.GetList(sessionInfo.Id, this.source.token);
        this.expenseGroups = expenseGroups;

        const gridSettings = await SettingsService.GetGridSettings(this.gridSettingsKey, this.source.token);
        if (!gridSettings.HasError) {
            this.gridSettings = gridSettings.Settings;
            let settings = JSON.parse(this.gridSettings);
            if (settings && settings.pageSize) {
                this.pageSettings.pageSize = settings.pageSize === "Alle" ? productGroups.length : settings.pageSize;
            }
        }

        this.setState({ loading: false }, () => {
            window.addEventListener('wheel', this.onWheelScroll);
        });
    }

    iterGroups(productGroups, parentGroupName) {
        let groups = [];
        productGroups.forEach(s => {
            s.ParentGroupName = parentGroupName;
            groups.push(s);
            if (s.SubGroups.length > 0) {
                let subGroups = this.iterGroups(s.SubGroups, s.Name);
                groups.push(...subGroups);
            }
        });
        return groups;
    }

    init() {
        this.toolbarOptions = [
            { text: 'Import', tooltipText: 'Import', id: 'import', prefixIcon: 'e-upload' },
            { text: 'Excel Export', tooltipText: 'Excel Export', id: 'export', prefixIcon: 'e-export-excel' },
            { text: 'Download Template', tooltipText: 'Download Template', id: 'download_template', prefixIcon: 'e-download' },
            { text: Localization.General.GridResetFilter, tooltipText: Localization.General.GridResetFilter, id: 'reset_filter', prefixIcon: 'e-cancel' }
        ];

        this.reviewDataToolbarOptions = [
            'Edit', 'Update', 'Cancel', 'Delete'
        ];

        this.reviewDataEditSettings = {
            allowEditing: true,
            allowDeleting: true,
            showDeleteConfirmDialog: true
        };
        
        this.numericParams = { 
            params: { 
                showSpinButton: false, min: 0, step: 0, cssClass: 'text-right', decimals: 2, validateDecimalOnType: true, format: "N2"
            }
        };

        this.primaryProductGroupFields = {
            dataSource: [], 
            id: 'Id', text: 'Name', 
            child: 'SubGroups' 
        };

        this.parentProductGroupParams = {
            create: () => {
                this.parentGroupElem = document.createElement('input');
                return this.parentGroupElem;
            },
            destroy: () => { this.parentGroupObj.destroy(); },
            read: () => {
                this.selectedProductGroup = this.parentGroupObj.value;
                return this.parentGroupElem.value;
            },
            write: (e) => {
                this.parentGroupObj = new DropDownList({
                    dataSource: this.parentGroups,
                    fields: { value: 'Id', text: 'Name' },
                    floatLabelType: 'Never',
                    value: e.rowData.TempParentId
                  });
                  this.parentGroupObj.appendTo(this.parentGroupElem);
            }
        };

        this.underWeightParams = {
            create: () => {
                this.underWeightElem = document.createElement('input');
                return this.underWeightElem;
            },
            destroy: () => { this.underWeightObj.destroy(); },
            read: () => {
                this.selectedUnderWeight = this.underWeightObj.value;
                return this.underWeightElem.value;
            },
            write: (e) => {
                this.underWeightObj = new DropDownList({
                    dataSource: new DataManager(this.UnderWeightData),
                    fields: { value: 'Id', text: 'text' },
                    floatLabelType: 'Never',
                    value: e.rowData.GuaranteedWeight
                  });
                  this.underWeightObj.appendTo(this.underWeightElem);
            }
        };

        this.expenseGroupParams = {
            create: () => {
                this.expenseGroupElem = document.createElement('input');
                return this.expenseGroupElem;
            },
            destroy: () => { this.expenseGroupObj.destroy(); },
            read: () => {
                this.selectedExpenseGroup = this.expenseGroupObj.value;
                return this.expenseGroupElem.value;
            },
            write: (e) => {
                this.expenseGroupObj = new DropDownList({
                    dataSource: new DataManager(this.expenseGroups),
                    fields: { value: 'Id', text: 'Name' },
                    floatLabelType: 'Never',
                    value: e.rowData.ExpenseGroupId
                  });
                  this.expenseGroupObj.appendTo(this.expenseGroupElem);
            }
        };
    }

    componentWillUnmount() {
        this.source.cancel();
        window.removeEventListener('wheel', this.onWheelScroll);
    }

    onColumnDragStart(args) {
        this.hasDraggedColumn = true;
    }

    onCreated() {
        if (this.gridSettings) {
            let settings = JSON.parse(this.gridSettings);
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(column => {
                        if (column.FromIndex !== column.ToIndex) {
                            var tempIndex = this.productGroupGridRef.getColumnIndexByField(column.Field);
                            if (tempIndex !== column.ToIndex){
                                this.productGroupGridRef.reorderColumnByIndex(column.FromIndex, column.ToIndex);
                            }
                        }
    
                        if (column.Width) {
                            this.productGroupGridRef.columns[column.ToIndex].width = column.Width;
                        }
                    });
                }

                if (settings.colName && settings.direction) {
                    this.productGroupGridRef.sortByColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.productGroupGridRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.productGroupGridRef.refreshColumns();

                if (settings.pageSize) {
                    if (settings.pageSize === "Alle") {
                        this.productGroupGridRef.pageSettings.pageSize = this.productGroupGridRef.pageSettings.totalRecordsCount;
                        document.querySelector("#product-group-grid .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.productGroupGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
            }
        }
    }

    async onActionComplete(args) {
        if (args.requestType === 'paging') {
            if (this.productGroupGridRef.pageSettings.pageSize === this.productGroupGridRef.pageSettings.totalRecordsCount) {
                document.querySelector("#product-group-grid .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, this.productGroupGridRef.pageSettings.pageSize, this.productGroupGridRef.pageSettings.totalRecordsCount, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };
            const result = await SettingsService.SaveGridSettings(payload, this.source.token);
        }
        else if (args.requestType === 'filtering') {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, this.source.token);

            if (!result.HasError) {
                this.props.notify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, this.source.token);

            if (!result.HasError) {
                this.props.notify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.productGroupGridRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, this.hasDraggedColumn, columns);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, this.source.token);

            if (!result.HasError) {
                this.props.notify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }

            this.hasDraggedColumn = false;
        }
    }

    async onResizeStop(args) {
        args.requestType = "resizingGrid";
        let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
        this.gridSettings = tempGridSettings;
        const payload = {
            Key: this.gridSettingsKey,
            Settings: this.gridSettings
        };

        const result = await SettingsService.SaveGridSettings(payload, this.source.token);

        if (!result.HasError) {
            this.props.notify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
        }
    }

    async onToolbarClick(args) {
        if (args.item.id === "import") {
            this.productImportDialog.show();
            this.productImportWizardTab.select(0);
            this.productImportWizardTab.enableTab(1, false);
            this.productImportWizardTab.enableTab(2, false);
            this.reviewButton.disabled = true;
            this.setState({ files: [] });
            
            setTimeout(() => {
                let element = document.querySelector('.product-file-list .file-name-text');
                if (element) {
                    element.innerHTML = "";
                }
            }, 300);
        }
        else if (args.item.id === "export") {
            let source = this.productGroupGridRef.getSelectedRecords();
            if (source.length <= 0) {
                this.props.notify("Advarsel", Localization.General.PleaseSelectItemsToExport);
            } else {
                let productFormattedSource = [];
    
                productFormattedSource.push([ "Navn", "Faktor", "Avance I %", "Sags Nr. (Y/N)", "Under Garanteret Vælg(Indtast altid antal/Vej altid/Vælg ved træk)", "Omkostnings gruppe", "Ved indkøb Tolerance i %", "Overliggende gruppe", "Billede Fil Navn" ]);
                let parentWithSubGroup = [];
    
                for (let index = 0; index < source.length; index++) {
                    const element = source[index];
                    let item = [];
    
                    item.push(element.Name);
                    item.push(element.Factor);
                    item.push(element.ProfitPercentage ? element.ProfitPercentage * 100 : element.ProfitPercentage);
                    item.push(element.ActiveCaseNumber ? "Y" : "N");
                    item.push(element.GuaranteedWeight);
                    item.push(element.ExpenseGroup ? element.ExpenseGroup.Id : null);
                    item.push(element.PurchaseTolerance ? element.PurchaseTolerance * 100 : element.PurchaseTolerance);
                    item.push("");
                    item.push(element.ImageKey);
                    productFormattedSource.push(item);
    
                    if (element.SubGroups.length > 0) {
                        this.traverseSubGroup(productFormattedSource, element.Name, element.SubGroups);
                    }
                }

                let data3 = [
                    [ "Id", "Navn" ]
                ];
    
                this.expenseGroups.forEach(element => {
                    let item = [];
                    item.push(element.Id);
                    item.push(element.Name);
                    data3.push(item);
                });
    
                let sheet3 = XLSX.utils.aoa_to_sheet(data3);
                let productGroup = XLSX.utils.aoa_to_sheet(productFormattedSource);
                let workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, productGroup, "Produktgrupper");
                XLSX.utils.book_append_sheet(workbook, sheet3, "Omkostnings gruppe Ids");
                XLSX.writeFile(workbook, "Products Export.xlsx"); // FOR TRANSLATION
            }
        }
        else if (args.item.id === "download_template") {
            let data1 = [
                this.ImportColumns
            ];

            let data2 = [
                [ "Id", "Navn", "Overliggende gruppe" ]
            ];

            let data3 = [
                [ "Id", "Navn" ]
            ];

            this.productGroupsIter.forEach(element => {
                let item = [];
                item.push(element.Id);
                item.push(element.Name);
                item.push(element.ParentGroupName);
                data2.push(item);
            });

            this.expenseGroups.forEach(element => {
                let item = [];
                item.push(element.Id);
                item.push(element.Name);
                data3.push(item);
            });

            let sheet = XLSX.utils.aoa_to_sheet(data1);
            let sheet2 = XLSX.utils.aoa_to_sheet(data2);
            let sheet3 = XLSX.utils.aoa_to_sheet(data3);
            let workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, sheet, "Produktgrupper");
            XLSX.utils.book_append_sheet(workbook, sheet2, "Produktgrupper Ids");
            XLSX.utils.book_append_sheet(workbook, sheet3, "Omkostnings gruppe Ids");
            XLSX.writeFile(workbook, "Product Group Template.xlsx"); // FOR TRANSLATION
        } else if (args.item.id === 'reset_filter') {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                settings.filters = [];
                this.gridSettings = settings;
                const payload = {
                    Key: this.gridSettingsKey,
                    Settings: JSON.stringify(this.gridSettings)
                };
    
                const result = await SettingsService.SaveGridSettings(payload, this.source.token);
    
                if (!result.HasError) {
                    this.props.notify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                }
            }

            this.productGroupGridRef.clearFiltering();
        }
    }

    traverseSubGroup(list, parentName, data) {
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            let item = [];

            item.push(element.Name);
            item.push(element.Factor);
            item.push(element.ProfitPercentage ? element.ProfitPercentage * 100 : element.ProfitPercentage);
            item.push(element.ActiveCaseNumber ? "Y" : "N");
            item.push(element.GuaranteedWeight);
            item.push(element.ExpenseGroup ? element.ExpenseGroup.Id : null);
            item.push(element.PurchaseTolerance ? element.PurchaseTolerance * 100 : element.PurchaseTolerance);
            item.push(parentName);
            item.push(element.ImageKey);
            list.push(item);
            
            if (element.SubGroups.length > 0) {
                this.traverseSubGroup(list, element.Name, element.SubGroups);
            }
        }

        return list;
    }

    onChangeFile(files) {
        if (this.importStatusRef) {
            this.importStatusRef.dataSource = [];
            this.importStatusRef.refresh();
            this.importStatusRef.showSpinner();
        }

        const file = files[0];
        let importStatusData = [];
        
        this.setState({ files }, () => {
            let element = document.querySelector('.productgroup-file-list .file-name-text');
            element.innerHTML = file.name;
        });

        if (file) {
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;
            reader.onload = e => {
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
                const productSheet = wb.Sheets[wb.SheetNames[0]];

                const colHeaders = []
                const colCount = XLSX.utils.decode_range(productSheet['!ref']).e.c + 1
                for (let i = 0; i < colCount; ++i) {
                    colHeaders[i] = productSheet[`${XLSX.utils.encode_col(i)}1`].v.toString().trim();
                }

                var colDiffs = this.ImportColumns.filter(x => !colHeaders.includes(x));
                if (colDiffs && colDiffs.length > 0) {
                    importStatusData.push({
                        Row: "Column Headers",
                        Status: `Column/s (${colDiffs.join(", ")}) findes ikke`
                    });
                }

                const productData = XLSX.utils.sheet_to_json(productSheet, { header: 0, raw: false });
                let data = [];
                let groupIds = this.productGroupsIter.map(element => element.Id);
                let maxGroupId = Math.max(...groupIds);

                for (let index = 0; index < productData.length; index++) {
                    const t = productData[index];
                    maxGroupId++;
                    let errorMessages = [];

                    let rawParentName = t["Overliggende gruppe"], rawExpenseGroup = t["Omkostnings gruppe"];
                    let rawGuarantedWeight = t["Under Garanteret Vælg(Indtast altid antal/Vej altid/Vælg ved træk)"];
                    let guaranteedWeight = 1, caseNumber = false;
                    let underWeight = null, expenseGroup = null, parentGroup = null;
                    let rawGroupName = t["Navn"] ? t["Navn"].toString().trim() : '';
                    let existingGroup = this.productGroupsIter.find(x => x.Name.toString().trim().toLowerCase() === rawGroupName.toLowerCase());

                    if (rawGuarantedWeight) {
                        underWeight = this.UnderWeightData.find(x => x.Id == rawGuarantedWeight);
                        if (underWeight) {
                            guaranteedWeight = underWeight.Id;
                        } else {
                            errorMessages.push(`Ukorrekt udfyldt under garanteret vægt (value: ${rawGuarantedWeight})`);
                        }
                    }

                    if (rawExpenseGroup) {
                        expenseGroup = this.expenseGroups.find(x => x.Id == rawExpenseGroup);
                        if (!expenseGroup) {
                            errorMessages.push(`Omkostnings gruppe (${rawExpenseGroup}) findes ikke`);
                        }
                    }

                    if (rawParentName) {
                        parentGroup = this.productGroupsIter.find(x => x.toString().trim().toLowerCase() == rawParentName.toString().trim().toLowerCase());
                        if (!parentGroup) {
                            parentGroup = this.productGroupsIter.find(x => x.Id === existingGroup.ParentId);
                        }
                    }

                    if ("Sags Nr. (Y/N)" in t) {
                        caseNumber = t["Sags Nr. (Y/N)"].toString().trim().toLowerCase() === "y";
                    }

                    let imageKey = null;
                    if (t["Billede Fil Navn"]) {
                        if (this.productGroupImages && this.productGroupImages.length > 0) {
                            let image = this.productGroupImages.find(x => x.Name.toLowerCase() == t["Billede Fil Navn"].toString().trim().toLowerCase() || x.NameWithoutExtension.toLowerCase() == t["Billede Fil Navn"].toString().trim().toLowerCase())
                            if (image) {
                                imageKey = image.Key;
                            }
                        }

                        if (!imageKey && existingGroup) {
                            imageKey = existingGroup.ImageKey;
                        }

                        if (!imageKey) {
                            errorMessages.push(`Billede (${t["Billede Fil Navn"]}) findes ikke`);
                        }
                    }
                    
                    data.push({
                        Id: maxGroupId,
                        SourceId: existingGroup ? existingGroup.Id : null,
                        RowId: index + 1,
                        Name: rawGroupName,
                        Factor: parseFloat(t["Faktor"]) ? parseFloat(t["Faktor"]) : null,
                        ProfitPercentage: parseFloat(t["Avance I %"]) ? parseFloat(t["Avance I %"]) : null,
                        GuaranteedWeight: guaranteedWeight,
                        ExpenseGroupId: expenseGroup ? expenseGroup.Id : null,
                        ExpenseGroupName: expenseGroup ? expenseGroup.Name : null,
                        ProductDrawText: underWeight ? underWeight.text : 'Indtast altid antal',
                        PurchaseTolerance: parseFloat(t["Ved indkøb Tolerance i %"]) ? parseFloat(t["Ved indkøb Tolerance i %"]) : 0,
                        ActiveCaseNumber: caseNumber,
                        ParentId: parentGroup ? parentGroup.Id : null,
                        TempParentId: parentGroup ? parentGroup.Id : 0,
                        TempParentName: parentGroup ? null : rawParentName,
                        ParentGroupName: parentGroup ? parentGroup.Name : rawParentName,
                        ImageKey: imageKey
                    });

                    if (errorMessages.length > 0) {
                        importStatusData.push({
                            Row: (index + 1).toString(),
                            Status: errorMessages.join(", ")
                        });
                    }
                }

                let ds = [];

                data.forEach(d => {
                    if (!ds.some(x => x.Id === d.Id)) {
                        ds.push({ Id: d.Id, Name: d.Name })
                    }

                    if (d.TempParentName) {
                        let x = data.find(x => x.Name === d.TempParentName);
                        d.TempParentId = x ? x.Id : 0;
                        if (!x) {
                            importStatusData.push(
                            {
                                Row: d.RowId.toString(),
                                Status: `Overliggende gruppe (${d.TempParentName}) findes ikke`
                            });
                        }
                    }
                });

                this.parentGroups = ds;
                
                this.setState({ stageData: data });

                if (this.importStatusRef) {
                    this.importStatusRef.hideSpinner();
                    if (data && data.length === 0) {
                        this.reviewButton.disabled = true;
                        importStatusData.push({
                            Row: "Alle",
                            Status: Localization.ImportExport.NoRowsFound
                        });
                    } else if (importStatusData.length === 0) {
                        this.reviewButton.disabled = false;
                        importStatusData.push({
                            Row: "Alle",
                            Status: Localization.ImportExport.NoErrorsFound
                        });
                    } else {
                        this.reviewButton.disabled = true;
                    }
                    this.importStatusRef.dataSource = importStatusData;
                    this.importStatusRef.refresh();
                }
            };

            if (rABS) reader.readAsBinaryString(file);
            else reader.readAsArrayBuffer(file);
        }
    }

    onButtonClick(args) {
        console.log("Clicked!")
        let { stageData, files } = this.state;
        
        switch(args.target.id) {
            case "select-file":
                this.productImportWizardTab.select(0);
                this.productImportWizardTab.enableTab(1, false);
                this.productImportWizardTab.enableTab(2, false);
                break;
            case "review-data":
                if (files.length <= 0) {
                    this.props.notify("Advarsel", Localization.ImportExport.PleaseSelectAFile);
                    return;
                }
                else if (stageData.length <= 0) {
                    this.props.notify("Advarsel", Localization.ImportExport.NoRowsFound);
                    return;
                }

                this.productImportWizardTab.enableTab(1, true);
                this.productImportWizardTab.select(1);

                setTimeout(() => {
                    this.stageGridRef.dataSource = stageData;
                    this.stageGridRef.refresh();
                }, 300);
                break;
            case "finalize-import":
                let isValid = this.validateData();

                if (!isValid) {
                    this.validationReportDialog.show();
                    return;
                }

                this.productImportWizardTab.enableTab(2, true);
                this.productImportWizardTab.select(2);

                this.processImport();
                break;
        }
    }

    validateData() {
        let { stageData, validationReport } = this.state;
        validationReport = [];
        let tempGroups = this.productGroupsIter.map(x => {return { Id: 0, Name: x.Name, SourceId: x.Id };});
        tempGroups.push(...stageData.map(x => {return { Id: x.Id, Name: x.Name }}));
        
        for (let index = 0; index < stageData.length; index++) {
            const element = stageData[index];
            let errorList = [];

            if (!element.Name) {
                errorList.push('Indtast navn på produktgruppen');
            }
            else {
                if (tempGroups.some(x => x.Name === element.Name && x.Id !== element.Id && !(element.SourceId && x.SourceId === element.SourceId))) {
                    errorList.push('produktgruppen er allerede taget');
                }
            }

            if (!element.Factor && !element.ProfitPercentage) {
                errorList.push('Faktor. og avance i % er begge ikke udfyldt, en af dem skal ha en værdi');
            }
            else if (element.Factor && element.ProfitPercentage) {
                errorList.push('Faktor. og avance i % har begge en værdi');
            }

            if (errorList.length > 0) {
                validationReport.push({
                    Id: element.Id,
                    Messages: errorList
                })
            }
        }

        this.setState({ validationReport });
        return validationReport.length <= 0;
    }

    imageTemplate(props) {
        if (props.ImageKey) {
            return (
                <Image src={props.ImageKey} alt="" className="product-image-data" />
            )
        }

        return <img src={placeHolderImg} alt="primary product" className="product-image-data" />;
    }

    fileSelectTab() {
        return (
            <div className="tab-control--item">
                <Dropzone onDrop={this.onChangeFile} accept=".xls, .xlsx" maxFiles={1}>
                    {({getRootProps, getInputProps}) => (
                        <section className="container">
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} />
                                <p>Træk og slip filen her, eller klik for at vælge en fil</p>
                            </div>
                            <div className="productgroup-file-list">
                                <div className="file-name-label">Valgte fil:</div>
                                <div className="file-name-text"></div>
                            </div>
                        </section>
                    )}
                </Dropzone>
                <div className="import-status-container">
                    <div>Import Status:</div>
                </div>
                <GridComponent ref={ref => this.importStatusRef = ref } dataSource={this.statusData} allowPaging={true} allowSorting={true} pageSettings={this.importStatusDataPageSettings}
                    selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both" width="100%" allowTextWrap={true}>
                    <ColumnsDirective>
                        <ColumnDirective headerText="Row" field="Row" width='200' isPrimaryKey={true} />
                        <ColumnDirective headerText="Status" field="Status" allowEditing={false} />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Search]} />
                </GridComponent>
                <div className="wizard-action-buttons">
                    <button ref={ref => this.reviewButton = ref} id="review-data" className="e-btn e-primary" onClick={this.onButtonClick} disabled={false}>Se data</button>
                </div>
            </div>
        )
    }

    async processImport() {
        let { stageData } = this.state;
        let payload = [];

        for (let index = 0; index < stageData.length; index++) {
            const element = stageData[index];

            let parentGroup = this.productGroupsIter.find(x => x.Id == element.TempParentId);

            payload.push({
                Id: element.SourceId ? element.SourceId : 0,
                TempId: element.Id,
                TempParentId: parentGroup ? 0 : (element.TempParentId ? parseInt(element.TempParentId) : 0),
                ParentId: parentGroup ? parentGroup.Id : null,
                CustomerId: this.CustomerId,
                Name: element.Name,
                Factor: element.Factor,
                ProfitPercentage: element.ProfitPercentage ? element.ProfitPercentage / 100 : element.ProfitPercentage,
                GuaranteedWeight: element.GuaranteedWeight,
                PurchaseTolerance: element.PurchaseTolerance ? element.PurchaseTolerance / 100 : element.PurchaseTolerance,
                ActiveCaseNumber: element.ActiveCaseNumber,
                ExpenseGroupId: element.ExpenseGroupId,
                IsDelete: false,
                ChangeOrder: index + 1,
                ImageKey: element.ImageKey
            })
        }
        
        const result = await ProductGroupService.BulkSave(payload, this.source.token);

        if (!result.HasError) {
            this.props.notify(Localization.General.SuccessHeader, Localization.Tools.SuccessImport);
            
            const productGroups = await ProductGroupService.GetProductGroups(this.CustomerId, this.source.token);
            this.productGroups = productGroups;
            this.productGroupsIter = this.iterGroups(productGroups);

            this.productGroupGridRef.dataSource = productGroups;
            this.productGroupGridRef.refresh();

            this.productImportDialog.hide();
            this.productImportWizardTab.select(0);
            this.productImportWizardTab.enableTab(1, false);
            this.productImportWizardTab.enableTab(2, false);

            this.importStatusRef.dataSource = [];
            this.importStatusRef.refresh();

            this.setState({ stageData: [] });
        }
        else {
            this.props.notify("Advarsel", result.ErrorMessage);
            this.onButtonClick({ target: { id: 'review-data' }});
        }
    }

    reviewDataActionBegin(args) {
        if (args.requestType === "save") {
            let errorList = [];

            if (errorList.length > 0) {
                this.props.notify("Advarsel", errorList.join('<br/>'));
                args.cancel = true;
                return;
            }
        }
    }

    reviewDataActionComplete(args) {
        if (args.requestType === "save") {
            let { stageData } = this.state;
            let data = stageData.find(x => x.Id === args.data.Id);
            
            data.Factor = args.data.Factor;
            data.ProfitPercentage = args.data.ProfitPercentage;
            data.ActiveCaseNumber = args.data.ActiveCaseNumber;
            data.GuaranteedWeight = this.selectedUnderWeight;
            data.PurchaseTolerance = args.data.PurchaseTolerance;
            data.TempParentId = this.selectedProductGroup;
            data.ExpenseGroupId = this.selectedExpenseGroup;

            this.setState({ stageData }, () => {
                this.stageData = stageData;
                this.stageGridRef.dataSource = stageData;
                this.stageGridRef.refresh();
            });
        }
        else if (args.requestType === "delete") {
            let { stageData } = this.state;
            stageData = stageData.filter(x => x.Id !== args.data[0].Id);
            this.setState({ stageData });
        }
        else if (args.requestType === "beginEdit") {
            let rowData = args.rowData;

            let filteredData = this.parentGroups.filter(x => x.Id !== rowData.Id);
            filteredData.push(...this.productGroupsIter);
            this.parentGroupObj.dataSource = filteredData;
            this.parentGroupObj.dataBind();
        }
    }

    reviewDataTab() {
        return (
            <div className="tab-control--item">
                <GridComponent ref={ref => this.stageGridRef = ref } dataSource={this.stageData} allowPaging={true} allowSorting={true} pageSettings={this.reviewDataPageSettings} toolbar={this.reviewDataToolbarOptions}
                    selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both" editSettings={this.reviewDataEditSettings}
                    allowResizing={true} actionBegin={this.reviewDataActionBegin.bind(this)} actionComplete={this.reviewDataActionComplete.bind(this)} width="100%">
                    <ColumnsDirective>
                        <ColumnDirective headerText="No." field="RowId" width='100' isPrimaryKey={true} />
                        <ColumnDirective headerText="Produkt Gr." field="Name" allowEditing={false} />
                        <ColumnDirective headerText="Faktor" field="Factor" textAlign="Right" editType='numericedit' edit={this.numericParams} format="N2"/>
                        <ColumnDirective headerText="Avance i %" field="ProfitPercentage" textAlign="Right" editType='numericedit' edit={this.numericParams} format="N2"/>
                        <ColumnDirective headerText="Sags Nr." field="ActiveCaseNumber" displayAsCheckBox={true} editType='booleanedit' />
                        <ColumnDirective headerText="Under Garanteret Vægt" field="ProductDrawText" editType='dropdownedit' edit={this.underWeightParams} />
                        <ColumnDirective headerText="Omkostnings gruppe" field='ExpenseGroupName' editType='dropdownedit' edit={this.expenseGroupParams} />
                        <ColumnDirective headerText="Ved indkøb Tolerance i %" field="PurchaseTolerance" textAlign="Right" editType='numericedit' edit={this.numericParams} format="N2" />
                        <ColumnDirective headerText="Overliggende gruppe" field="ParentGroupName" editType='dropdownedit' edit={this.parentProductGroupParams} />
                        <ColumnDirective headerText="Billede" field="ImageKey" headerText="Billede" template={this.imageTemplate} />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Selection, Toolbar, Search, Resize, Edit]} />
                </GridComponent>
                <div className="wizard-action-buttons">
                    <button id="select-file" className="e-btn" onClick={this.onButtonClick}>Vælg fil</button>
                    <button id="finalize-import" className="e-btn e-primary" onClick={this.onButtonClick}>Bekræft og importer</button>
                </div>
            </div>
        )
    }

    finalizeImportTab() {
        return (
            <div className="tab-control--item">
                <div className="importing-text">Importere data. Vent  venligst</div>
            </div>
        )
    }

    onSelect(args) {
        if (args.isSwiped) {
            args.cancel = true;
        }
    }

    purchaseToleranceTemplate(props) {
        let purchaseTolerance = null;
        if (props.PurchaseTolerance) {
            purchaseTolerance = props.PurchaseTolerance * 100;
            return (<div>{purchaseTolerance}%</div>)
        } else{
            return (<div>{purchaseTolerance}</div>)
        }
        
    }

    profitPercentageTemplate(props) {
        let profitPercentage = null;
        if (props.ProfitPercentage) {
            profitPercentage = props.ProfitPercentage * 100;
            return (<div>{profitPercentage}%</div>)
        } else {
            return (<div>{profitPercentage}</div>)
        }
    }

    onWheelScroll = () => {
        let grid = document.getElementById('product-group-grid');
        let toolbar = document.querySelector('#product-group-grid .e-grid .e-toolbar');
        let tableHeader = document.querySelector('#product-group-grid .e-gridheader');

        if (toolbar && tableHeader) {
            let boundingRect = tableHeader.getBoundingClientRect();
            
            if (boundingRect.top < 90) {
                toolbar.classList.add('c-hover-grid-toolbar');
                toolbar.style.width = (grid.clientWidth - 5) + 'px';
            }
            else {
                toolbar.classList.remove('c-hover-grid-toolbar');
                toolbar.style.width = 'auto';
            }
        }
    }

    render() {
        const { loading, hasUnsavedChanges, validationReport, selectedProductGroup } = this.state;
        
        if (loading) {
            return <Loading />
        }
        //resizeStop={this.onResizeStop.bind(this)} actionComplete={this.onActionComplete.bind(this)} columnDragStart={this.onColumnDragStart.bind(this)} created={this.onCreated.bind(this)}
        // allowReordering={true} allowResizing={true} 
        return (
            <div style={{ margin: '20px 0' }}>
                <NavigationPrompt when={hasUnsavedChanges} 
                    navigate={path => this.props.history.push(path)}
                    initiateSave={() => {}}
                    shouldBlockNavigation={location => {
                        if (hasUnsavedChanges) {
                            return true;
                        }
                        return false;
                    }}
                />
                <TreeGridComponent id="product-group-grid" ref={ref => this.productGroupGridRef = ref } dataSource={this.data} childMapping="SubGroups" allowPaging={true} allowSorting={true} 
                    pageSettings={this.pageSettings} toolbar={this.toolbarOptions} filterSettings={{ type: 'Menu', hierarchyMode: 'Parent' }} 
                    selectionSettings={this.selectionSettings} allowFiltering={true} locale="da" gridLines="Both" editSettings={{}}
                    toolbarClick={this.onToolbarClick.bind(this)} actionComplete={this.onActionComplete.bind(this)} created={this.onCreated.bind(this)}
                    treeColumnIndex={1}>
                    <TreeGridColumnsDirective>
                        <ColumnDirective type='checkbox' width='50'/>
                        <TreeGridColumnDirective field='Name' headerText='Produkt Gr.' textAlign='left' />
                        <TreeGridColumnDirective field='Factor' headerText='Faktor' textAlign='right' />
                        <TreeGridColumnDirective field='ProfitPercentage' headerText='Avance i %' textAlign='right' template={this.profitPercentageTemplate} />
                        <TreeGridColumnDirective field='ActiveCaseNumber' headerText='Sags Nr.' textAlign='left' displayAsCheckBox={true} />
                        <TreeGridColumnDirective field='ProductDrawText' headerText='Under Garanteret Vægt' textAlign='right' />
                        <TreeGridColumnDirective field='ExpenseGroupName' headerText="Omkostnings gruppe" />
                        <TreeGridColumnDirective field='PurchaseTolerance' headerText='Ved indkøb Tolerance i %' textAlign='right' template={this.purchaseToleranceTemplate} />
                        <TreeGridColumnDirective field="Id" headerText="Billede" template={this.imageTemplate} isPrimaryKey={true} />
                    </TreeGridColumnsDirective>
                    <TreeGridInject services={[TreeGridFilter, TreeGridSort, TreeGridPage, TreeGridEdit, TreeGridToolbar]}/>
                </TreeGridComponent>
                <div>
                    <DialogComponent isModal={true} width='auto' ref={dialog => this.productImportDialog = dialog} target='body'visible={false} showCloseIcon={true} header="Import Produkt Grupper" 
                        cssClass="import-dialog" animationSettings={this.animationSettings} allowDragging={true} enableResize={true}>
                            <div className="dialog-content">
                                <TabComponent ref={ref => this.productImportWizardTab = ref} className="tab-control" heightAdjustMode="Content" selecting={this.onSelect.bind(this)}>
                                    <TabItemsDirective>
                                        <TabItemDirective header={this.importTabHeader[0]} content={this.fileSelectTab.bind(this)} />
                                        <TabItemDirective header={this.importTabHeader[1]} content={this.reviewDataTab.bind(this)} disabled={true} />
                                        <TabItemDirective header={this.importTabHeader[2]} content={this.finalizeImportTab.bind(this)} disabled={true} />
                                    </TabItemsDirective>
                                </TabComponent>
                            </div>
                    </DialogComponent>
                </div>
                <div>
                    <DialogComponent isModal={true} width='auto' ref={dialog => this.validationReportDialog = dialog} target='body'visible={false} showCloseIcon={true} header="Rapport over Ændringer" 
                        cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} buttons={this.notificationButtons}>
                            <div>
                                <table className="table" style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th>Row #</th>
                                            <th className="text-left">Validation Messages</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            validationReport.map(validation => {
                                                return (
                                                    <tr className="changelog-row">
                                                        <td className="text-center">{validation.Id}</td>
                                                        <td className="required">
                                                            {validation.Messages.join(', ')}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                    </DialogComponent>
                </div>
                {/* <div>
                    <DialogComponent id="other-settings-dialog" isModal={true} width='auto' ref={dialog => this.otherSettingsDialog = dialog} target='body'visible={false} showCloseIcon={true} header="Øvrige indstillinger" 
                        animationSettings={this.animationSettings} allowDragging={true} enableResize={true} buttons={this.otherSettingsButtons}>
                            <div className="import-other-settings-wrapper">
                                
                            <div>Vælg venligst overliggende gruppe:</div>
                                <TreeViewComponent ref={ref => this.productGroupTree = ref} id="product-group-selection" fields={this.primaryProductGroupFields} showCheckBox={true} autoCheck={false} nodeChecked={this.onProductGroupChecked} />
                            </div>
                    </DialogComponent>
                </div> */}
            </div>
        )
    }
}

export default ProductGroupImportExport;
