import React, { Component } from 'react';
import AppContext from '../context/AppContext';
import SessionService from '../services/SessionService';

export class BaseComponent extends Component {
    hasPermission = true;
    modulePermissions = {};

    async componentDidMount() {
        let title = document.head.getElementsByTagName('title')[0];
        title.innerText = `ILX System - ${this.pageName}`;

        let { siteRoutes } = this.context;
        let module = siteRoutes.find(x => x.Name === this.moduleName);

        if (!module) {
            this.hasPermission = false;
        }
        else {
            this.hasPermission = true;
            this.modulePermissions = module;
        }
    }

    processModuleResponse(result) {
        if (result.Status === 404) {
            return ({ loading: false, redirect: { to: '/', isRedirect: true }});
        }
        if (result.Status === 401) {
            return ({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
        }
    }

    hasMenuItem(menuItemName) {
        return this.modulePermissions.MenuItems.some(x => x.Name === menuItemName);
    }

    hasMenuItemFunction(menuItemName, functionName) {
        let menuItem = this.modulePermissions.MenuItems.find(x => x.Name === menuItemName);
        if (menuItem) {
            return menuItem.Functions.some(x => x === functionName);
        }
        else { return false; }
    }

    hasMenuItemTabFunction(menuItemName, tabName, functionName) {
        let menuItem = this.modulePermissions.MenuItems.find(x => x.Name === menuItemName);
        if (menuItem) {
            let tabItem = menuItem.MenuTabs.find(x => x.Name === tabName);
            if (tabItem) {
                if (Array.isArray(functionName)) {
                    let hasAccess = false;

                    tabItem.Functions.forEach(x => {
                        if (!hasAccess) {
                            hasAccess = functionName.some(f => f === x);
                        }
                    });

                    return hasAccess;
                }
                else {
                    return tabItem.Functions.some(f => f === functionName);
                }
            }
            else { return false; }
        }
        else { return false; }
    }

    hasMenuItemTabAccess(menuItemName, tabName) {
        let menuItem = this.modulePermissions.MenuItems.find(x => x.Name === menuItemName);
        if (menuItem) {
            return menuItem.MenuTabs.some(x => x.Name === tabName);
        }
        else { return false; }
    }

    hasTabAccess(tabName) {
        return this.modulePermissions.MenuTabs.some(x => x.Name === tabName);
    }

    hasFunction(functionName) {
        return this.modulePermissions.Functions.some(x => x === functionName);
    }

    hasTabFunction(tabName, functionName) {
        let tab = this.modulePermissions.MenuTabs.find(x => x.Name === tabName);
        if (tab) {
            return tab.Functions.some(x => x === functionName);
        }
        else {
            return false;
        }
    }

    hasReportMenuTemplateAccess(parentMenuId, templateId) {
        let report = this.modulePermissions.MenuItems.find(x => x.Id === parentMenuId);
        if (report) {
            return report.ReportTemplates && report.ReportTemplates.some(rt => rt.Id === templateId);
        }
        return false;
    }

    getMenuItem(name) {
        return this.modulePermissions.MenuItems.find(x => x.Name === name);
    }

    getSiteRoute(name) {
        let { siteRoutes } = this.context;
        return siteRoutes.find(s => s.Name === name);
    }

    getTabs() {
        return this.modulePermissions.MenuTabs;
    }

    getMenuTab(tabName) {
        return this.modulePermissions.MenuTabs.find(x => x.Name === tabName);
    }

    getReportMenuItem(parentMenuId, templateId) {
        let report = this.modulePermissions.MenuItems.find(x => x.Id === parentMenuId);
        if (report) {
            if (report.ReportTemplates && report.ReportTemplates.some(rt => rt.Id === templateId)) {
                let reportTemplate = report.ReportTemplates.find(rt => rt.Id === templateId);

                return {
                    ...reportTemplate,
                    parentMenuName: report.Name
                }
            }
            return null;
        }
        return null;
    }

    getMenuItemTabs(menuItemName) {
        return this.modulePermissions.MenuItems.find(x => x.Name === menuItemName);
    }

    getMenuItemFunctions(menuItemName) {
        let menuItems = this.modulePermissions.MenuItems.find(x => x.Name === menuItemName);

        if (menuItems) {
            return menuItems.Functions;
        }

        return [];
    }
}

BaseComponent.contextType = AppContext;
export default BaseComponent;
