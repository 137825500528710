import React from "react";
import i18n from '../../../utilities/Internationalization';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

export default function OilmatDataConfirmation({ data, onSwitchStep, validation, t }) {
    return (
        <div>
            {
                validation.hasError &&
                <ul className="c-ul m-bottom-20">
                    {
                        validation.errors.map(error => <li className="error">{error.message}</li> )
                    }
                </ul>
            }
            <div className="title">{t('TitleOverview')}</div>
            <div className="info-text m-bottom-15">{t('DataOverviewInfoText')}</div>

            <table className="table m-bottom-20">
                <thead>
                    <tr>
                        <th colSpan={2} className="table-header">
                            {t('TitleCompanyData')}
                            <ButtonComponent onClick={() => onSwitchStep(2)} cssClass="update-label">
                                {t('Edit')}
                            </ButtonComponent>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="label" width="30%">{t('LabelWholesalerNo')}</td>
                        <td>{data.wholesalerNo}</td>
                    </tr>
                    <tr>
                        <td className="label">{t('LabelCompanyName')}</td>
                        <td>{data.CompanyName}</td>
                    </tr>
                    <tr>
                        <td className="label">{t('LabelAddress')}</td>
                        <td>{data.Street}</td>
                    </tr>
                    <tr>
                        <td className="label">{t('LabelPostalCode')}</td>
                        <td>{data.PostalCodeNew ?? data.PostalCodeName}</td>
                    </tr>
                    <tr>
                        <td className="label">{t('LabelCity')}</td>
                        <td>{data.CityNew ?? data.CityName}</td>
                    </tr>
                    <tr>
                        <td className="label">{t('LabelRegion')}</td>
                        <td>{data.RegionNew ?? data.RegionName}</td>
                    </tr>
                    <tr>
                        <td className="label">{t('LabelCountry')}</td>
                        <td>{data.CountryName}</td>
                    </tr>
                    <tr>
                        <td className="label">{t('LabelTelephoneNumber')}</td>
                        <td>{data.PhoneNumber}</td>
                    </tr>
                    <tr>
                        <td className="label">{t('LabelMail')}</td>
                        <td>{data.ContactMail}</td>
                    </tr>
                    {
                        i18n.language === 'da' &&
                        <tr>
                            <td className="label">{t('LabelCvrNo')}</td>
                            <td>{data.VatNo}</td>
                        </tr>
                    }
                </tbody>
            </table>

            <table className="table m-bottom-20">
                <thead>
                    <tr>
                        <th colSpan={2} className="table-header">
                            {t('TitleSupplierAddress')}
                            <ButtonComponent onClick={() => onSwitchStep(3)} cssClass="update-label">
                                {t('Edit')}
                            </ButtonComponent>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="label" width="30%">{t('LabelAddress')}</td>
                        <td>{data.DeliveryAddress.DeliveryStreet}</td>
                    </tr>
                    <tr>
                        <td className="label">{t('LabelPostalCode')}</td>
                        <td>{data.DeliveryAddress.DeliveryPostalCodeNew ?? data.DeliveryAddress.DeliveryPostalCodeName}</td>
                    </tr>
                    <tr>
                        <td className="label">{t('LabelCity')}</td>
                        <td>{data.DeliveryAddress.CityNew ?? data.DeliveryAddress.CityName}</td>
                    </tr>
                    <tr>
                        <td className="label">{t('LabelRegion')}</td>
                        <td>{data.DeliveryAddress.RegionNew ?? data.DeliveryAddress.RegionName}</td>
                    </tr>
                    <tr>
                        <td className="label">{t('LabelCountry')}</td>
                        <td>{data.DeliveryAddress.CountryName}</td>
                    </tr>
                    <tr>
                        <td className="label">{t('LabelTelephoneNumber')}</td>
                        <td>{data.DeliveryAddress.PhoneNumber}</td>
                    </tr>
                    <tr>
                        <td className="label">{t('LabelMail')}</td>
                        <td>{data.DeliveryAddress.Email}</td>
                    </tr>
                </tbody>
            </table>

            <table className="table">
                <thead>
                    <tr>
                        <th colSpan={2} className="table-header">
                            {t('TitleUserData')}
                            <ButtonComponent onClick={() => onSwitchStep(4)} cssClass="update-label">
                                {t('Edit')}
                            </ButtonComponent>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="label" width="30%">{t('LabelFirstName')}</td>
                        <td>{data.User.FirstName}</td>
                    </tr>
                    <tr>
                        <td className="label">{t('LabelMiddleName')}</td>
                        <td>{data.User.MiddleName}</td>
                    </tr>
                    <tr>
                        <td className="label">{t('LabelLastName')}</td>
                        <td>{data.User.LastName}</td>
                    </tr>
                    <tr>
                        <td className="label">{t('LabelInitials')}</td>
                        <td>{data.User.Initials}</td>
                    </tr>
                    <tr>
                        <td className="label">{t('LabelTelephoneNumber')}</td>
                        <td>{data.User.PhoneNumber}</td>
                    </tr>
                    <tr>
                        <td className="label">{t('LabelMailUsername')}</td>
                        <td>{data.User.Username}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}