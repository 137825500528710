import React, { Component } from 'react';
import { ToolbarComponent, ItemsDirective, ItemDirective, TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';

import NavigationPrompt from '../../components/NavigationPrompt';
import Card from '../../components/Card';
import Localization from '../../utilities/Localization';
import MessageHelper from '../../utilities/MessageHelper';

import ResponsibilityGroupService from '../../services/Customer/ResponsibilityGroupService';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class ResponsibilityGroup extends Component {
    groupEditingKey = "GroupEditKey";
    
    constructor() {
        super();

        this.state = {
            data: {

            },
            responsibilityGroups: [],
            selectedGroup: -1,
            editGroup: -1,
            isEditMode: false,
            hasUnsavedChanges: false,
            UndoRedoStatus : {
                hasUndo: false,
                hasRedo: false,
                undoTooltip : '',
                redoTooltip: ''
            },
            tempList: [],
            tempIndexId: 4999
        };

        this.treeData = [];

        this.treeFields = {
            dataSource: this.treeData, id: 'Id', text: 'Name', child: 'SubGroups'
        };

        this.onResponsibilityGroupToolbarClicked = this.onResponsibilityGroupToolbarClicked.bind(this);
        this.onResponsibilityGroupChange = this.onResponsibilityGroupChange.bind(this);
        this.onNodeChecked = this.onNodeChecked.bind(this);
        this.onRowDoubleClick = this.onRowDoubleClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.nodeClicked = this.nodeClicked.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.groupEditing = this.groupEditing.bind(this);
        
        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.deleteConfirmationButtons = [
            {
                click: () => {
                    let { tempList, hasUnsavedChanges } = this.state;
                    if (tempList.some(x => x.IsDelete)) {
                        tempList = tempList.filter(x => !x.IsDelete);
                    }

                    if (tempList.length <= 0) {
                        hasUnsavedChanges = false;
                    }

                    this.setState({ hasUnsavedChanges, tempList });
                    this.deleteConfirmationDialog.hide();
                },
                buttonModel: {
                    content: 'Annuller'
                }
            },
            {
                click: () => {
                    this.confirmDelete();
                    this.deleteConfirmationDialog.hide();
                },
                buttonModel: {
                    isPrimary: true,
                    content: 'Slet'
                }
            }
        ];
        /* End Dialog options  */
    }

    async componentDidMount() {
        source.cancel();
        source = CancelToken.source();

        const groups = await ResponsibilityGroupService.GetList(source.token);
        const messages = await ResponsibilityGroupService.GetMessageList(source.token);
        
        this.treeData = messages;
        this.messageGroupTreeRef.fields.dataSource = messages;
        this.messageGroupTreeRef.refresh()
        this.messageGroupTreeRef.expandAll();
        
        this.ResponsibilityGroupToolbarRef.enableItems(1, true);
        this.ResponsibilityGroupToolbarRef.enableItems(2, false);
        this.ResponsibilityGroupToolbarRef.enableItems(3, false);
        this.setState({ responsibilityGroups: groups.Data, });

        window.addEventListener('keyup', this.keyUp);
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.keyUp);
    }

    keyUp = (e) => {
        let { isEditMode, selectedGroup, editGroup } = this.state
        if (isEditMode && selectedGroup !== -1) {
            if (e.keyCode === 113) {
                editGroup = selectedGroup;
                this.setState({ editGroup }, () => {
                    let inputElem = document.querySelector('.responsibility-group-list .table input.e-input');
                    if (inputElem) {
                        inputElem.focus();
                    }
                });
            }
        }
    }

    async onResponsibilityGroupToolbarClicked(args) {
        if (args.item.id === 'add') {
            let { responsibilityGroups, tempList, tempIndexId } = this.state;
            tempIndexId = tempIndexId + 1;

            let item = {
                Id: `T${tempIndexId}`,
                Name: "Ny gruppe",
                MessageGroups: []
            };
            
            responsibilityGroups.push(item);
            tempList.push(item);

            this.messageGroupTreeRef.checkedNodes = [];
            this.messageGroupTreeRef.disabled = false;
            this.ResponsibilityGroupToolbarRef.enableItems(1, false);
            this.ResponsibilityGroupToolbarRef.enableItems(2, true);
            this.ResponsibilityGroupToolbarRef.enableItems(3, true);

            this.setState({ responsibilityGroups, tempList, isEditMode: true, hasUnsavedChanges: true, tempIndexId, editGroup: item.Id, selectedGroup: item.Id }, () => {                
                let input = document.getElementsByClassName(`input-${item.Id}`)[0];
                if (input) {
                    input.focus();
                    input.select();
                }
            });
        }
        else if (args.item.id === 'edit') {
            let { selectedGroup, editGroup } = this.state;
            if (selectedGroup !== -1) {
                editGroup = selectedGroup;
            }

            this.messageGroupTreeRef.disabled = false;
            this.ResponsibilityGroupToolbarRef.enableItems(1, false);
            this.ResponsibilityGroupToolbarRef.enableItems(2, true);
            this.ResponsibilityGroupToolbarRef.enableItems(3, true);
            this.setState({ isEditMode: true, editGroup });
        }
        else if (args.item.id === 'update') {
            let { tempList, UndoRedoStatus } = this.state;
            
            if (tempList.length <= 0) {
                this.dialogOpen(Localization.General.NoteHeader, Localization.General.NoChangesMade);
                this.setState({ isEditMode: false });
                return;
            }

            let errorList = [];
            let payload = [];

            for (let index = 0; index < tempList.length; index++) {
                const element = tempList[index];
                
                if (element.Name === "Ny gruppe") {
                    errorList.push("Ugyldigt navn. Standardnavnet kan ikke bruges.");
                    break;
                }

                payload.push({
                    Id: typeof element.Id === "string" ? 0 : element.Id,
                    Name: element.Name,
                    MessageGroups: element.MessageGroups,
                    IsDelete: element.IsDelete,
                    ChangeOrder: element.ChangeOrder
                });
            }

            if (errorList.length > 0) {
                this.props.onNotify("Advarsel", errorList.join('<br />'));
                return;
            }

            const result = await ResponsibilityGroupService.Save(payload, source.token);

            if (!result.HasError) {
                const groups = await ResponsibilityGroupService.GetList(source.token);

                sessionStorage.removeItem(this.groupEditingKey);
                this.messageGroupTreeRef.checkedNodes = [];
                this.messageGroupTreeRef.disabled = true;
                this.ResponsibilityGroupToolbarRef.enableItems(1, true);
                this.ResponsibilityGroupToolbarRef.enableItems(2, false);
                this.ResponsibilityGroupToolbarRef.enableItems(3, false);
                
                UndoRedoStatus.hasUndo = groups.UndoRedoStatus.Undo;
                UndoRedoStatus.hasRedo = groups.UndoRedoStatus.Redo;
                UndoRedoStatus.undoTooltip = groups.UndoRedoStatus.UndoAction;
                UndoRedoStatus.redoTooltip = groups.UndoRedoStatus.RedoAction;

                this.setState({ hasUnsavedChanges: false, isEditMode: false, tempList: [], selectedGroup: -1, editGroup: -1, responsibilityGroups: groups.Data, reloadList: true, UndoRedoStatus }, () => {
                    setTimeout(() => {
                        this.setState({ reloadList: false });
                    }, 250);
                });
                this.props.onNotify(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Ansvars gruppe"));
            }
            else {
                this.props.onNotify("Advarsel", result.ErrorMessage);
            }
        }
        else if (args.item.id === 'cancel') {
            const groups = await ResponsibilityGroupService.GetList(source.token);
            this.messageGroupTreeRef.checkedNodes = [];
            this.messageGroupTreeRef.disabled = true;
            this.ResponsibilityGroupToolbarRef.enableItems(1, true);
            this.ResponsibilityGroupToolbarRef.enableItems(2, false);
            this.ResponsibilityGroupToolbarRef.enableItems(3, false);

            this.setState({ isEditMode: false, hasUnsavedChanges: false, responsibilityGroups: groups.Data, tempList: [], selectedGroup: -1, editGroup: -1 });
        }
        else if (args.item.id === 'delete') {
            let { responsibilityGroups, tempList, selectedGroup } = this.state;

            if (selectedGroup) {
                let group = responsibilityGroups.find(x => x.Id === selectedGroup);
                tempList.push({
                    ...group,
                    IsDelete: true,
                    ChangeOrder: tempList.length + 1
                });

                // responsibilityGroups = responsibilityGroups.filter(x => x.Id !== selectedGroup);
                this.setState({ tempList, responsibilityGroups, hasUnsavedChanges: true });
                this.deleteConfirmationDialog.show();
            }
            else {
                this.props.onNotify("Advarsel", Localization.General.PleaseSelectItem.replace("{context}", "Ansvars gruppe"));
            }

            this.setState({ responsibilityGroups, tempList, hasUnsavedChanges: true });
        }
        else if (args.item.id === 'undo') {
            let { UndoRedoStatus } = this.state;

            const result = await ResponsibilityGroupService.Undo(source.token);

            if (!result.HasError) {
                const groups = await ResponsibilityGroupService.GetList(source.token);
                this.messageGroupTreeRef.checkedNodes = [];
                this.messageGroupTreeRef.disabled = true;

                UndoRedoStatus.hasUndo = result.Undo;
                UndoRedoStatus.hasRedo = result.Redo;
                UndoRedoStatus.undoTooltip = result.UndoAction;
                UndoRedoStatus.redoTooltip = result.RedoAction;
                this.setState({ hasUnsavedChanges: false, isEditMode: false, tempList: [], selectedGroup: -1, editGroup: -1, responsibilityGroups: groups.Data, reloadList: true, UndoRedoStatus }, () => {
                    setTimeout(() => {
                        this.setState({ reloadList: false });
                    }, 250);
                });
            }
            else {
                this.props.onNotify("Advarsel", result.ErrorMessage);
            }
        }
        else if (args.item.id === 'redo') {
            let { UndoRedoStatus } = this.state;

            const result = await ResponsibilityGroupService.Redo(source.token);

            if (!result.HasError) {
                const groups = await ResponsibilityGroupService.GetList(source.token);
                this.messageGroupTreeRef.checkedNodes = [];
                this.messageGroupTreeRef.disabled = true;
                
                UndoRedoStatus.hasUndo = result.Undo;
                UndoRedoStatus.hasRedo = result.Redo;
                UndoRedoStatus.undoTooltip = result.UndoAction;
                UndoRedoStatus.redoTooltip = result.RedoAction;
                this.setState({ hasUnsavedChanges: false, isEditMode: false, tempList: [], selectedGroup: -1, editGroup: -1, responsibilityGroups: groups.Data, reloadList: true, UndoRedoStatus }, () => {
                    setTimeout(() => {
                        this.setState({ reloadList: false });
                    }, 250);
                });
            }
            else {
                this.props.onNotify("Advarsel", result.ErrorMessage);
            }
        }
    }

    groupEditing() {
        let { hasUnsavedChanges } = this.state;
        if (hasUnsavedChanges) {
            sessionStorage.setItem(this.groupEditingKey, "Expense Group");
        }
    }

    onNodeChecked(args) {
        if (args.isInteracted) {
            let { selectedGroup, responsibilityGroups, tempList } = this.state;
            let group = responsibilityGroups.find(x => x.Id === selectedGroup);
            let temp = tempList.find(x => x.Id === selectedGroup);

            if (group) {
                group.MessageGroups = this.messageGroupTreeRef.checkedNodes.map(x => parseInt(x));
            }

            if (temp) {
                temp.MessageGroups = group.MessageGroups;
            }
            else {
                tempList.push({
                    ...group,
                    IsDelete: false,
                    ChangeOrder: tempList.length + 1
                });
            }

            this.setState({ responsibilityGroups, tempList, hasUnsavedChanges: true }, () => {
                this.groupEditing();
            });
        }
    }

    nodeClicked(args) {
        let { selectedGroup, responsibilityGroups, tempList } = this.state;
        let node = [args.node];
        if (args.event.target.classList.contains('e-fullrow') || args.event.key == "Enter") {
            let getNodeDetails = this.messageGroupTreeRef.getNode(args.node);
            let group = responsibilityGroups.find(x => x.Id === selectedGroup);
            let temp = tempList.find(x => x.Id === selectedGroup);

            if (getNodeDetails.isChecked == "true") {
                this.messageGroupTreeRef.uncheckAll(node);
                // let bIndex = data.BusinessCategories.indexOf(parseInt(getNodeDetails.id));
                // data.BusinessCategories.splice(bIndex, 1);
            }
            else {
                // data.BusinessCategories.push(parseInt(getNodeDetails.id));
                this.messageGroupTreeRef.checkAll(node);
            }
            
            if (group) {
                group.MessageGroups = this.messageGroupTreeRef.checkedNodes.map(x => parseInt(x));
            }

            if (temp) {
                temp.MessageGroups = group.MessageGroups;
            }
            else {
                tempList.push({
                    ...group,
                    IsDelete: false,
                    ChangeOrder: tempList.length + 1
                });
            }
        }

        this.setState({ responsibilityGroups, tempList, hasUnsavedChanges: true }, () => {
            this.groupEditing();
        });
    }

    onRowDoubleClick(args, id) {
        let { isEditMode, editGroup } = this.state;
        if (isEditMode) {
            editGroup = id;
            this.setState({ editGroup });
        }
    }

    onRowClick(group) {
        let { isEditMode, editGroup } = this.state;
        if (isEditMode) {
            editGroup = group.Id
        }
        this.messageGroupTreeRef.checkedNodes = group.MessageGroups.map(a => a.toString());
        this.setState({ selectedGroup: group.Id, editGroup });
    }

    onChange(e, id) {
        let { responsibilityGroups, tempList } = this.state;

        let group = responsibilityGroups.find(e => e.Id === id);
        let temp = tempList.find(e => e.Id === id);

        if (group) {
            group.Name = e.target.value;
        }

        if (temp) {
            temp.Name = e.target.value;
        }
        else {
            tempList.push(group);
        }

        this.setState({ responsibilityGroups, tempList, hasUnsavedChanges: true }, () => {
            this.groupEditing();
        });
    }

    onKeyUp(e, id) {
        e.stopPropagation();
        let { value } = e.currentTarget;

        let { isEditMode } = this.state;
        if (isEditMode) {
            if (e.keyCode === 13) {
                if (value === "Ny gruppe") {
                    this.props.onNotify("Advarsel", "Invalid name. Default name cannot be used");
                    return;
                }
                this.setState({ editGroup: -1 });
            }
        }
    }

    async confirmDelete() {
        let { tempList, UndoRedoStatus } = this.state;

        let payload = [];

        for (let index = 0; index < tempList.length; index++) {
            const element = tempList[index];
            
            if (element.IsDelete) {
                payload.push({
                    Id: typeof element.Id === "string" ? 0 : element.Id,
                    Name: element.Name,
                    MessageGroups: element.MessageGroups,
                    IsDelete: element.IsDelete,
                    ChangeOrder: element.ChangeOrder
                });

            }
        }

        if (payload.length > 0) {
            const result = await ResponsibilityGroupService.Save(payload, source.token);
    
            if (!result.HasError) {
                const groups = await ResponsibilityGroupService.GetList(source.token);
    
                this.messageGroupTreeRef.checkedNodes = [];
                this.messageGroupTreeRef.disabled = true;
                this.ResponsibilityGroupToolbarRef.enableItems(1, true);
                this.ResponsibilityGroupToolbarRef.enableItems(2, false);
                this.ResponsibilityGroupToolbarRef.enableItems(3, false);
                
                UndoRedoStatus.hasUndo = groups.UndoRedoStatus.Undo;
                UndoRedoStatus.hasRedo = groups.UndoRedoStatus.Redo;
                UndoRedoStatus.undoTooltip = groups.UndoRedoStatus.UndoAction;
                UndoRedoStatus.redoTooltip = groups.UndoRedoStatus.RedoAction;
    
                this.setState({ isEditMode: false, tempList: [], selectedGroup: -1, editGroup: -1, responsibilityGroups: groups.Data, reloadList: true, UndoRedoStatus }, () => {
                    setTimeout(() => {
                        this.setState({ reloadList: false });
                    }, 250);
                });
                this.props.onNotify(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Ansvars gruppe"));
            }
            else {
                this.props.onNotify("Advarsel", result.ErrorMessage);
            }
        }
    }

    nodeSelecting(args) {
        args.cancel = true;
    }

    onResponsibilityGroupChange(args, group) {
        let { editGroup, isEditMode } = this.state;
        if (isEditMode) {
            editGroup = args.value
        }
        this.messageGroupTreeRef.checkedNodes = group.MessageGroups.map(a => a.toString());
        this.setState({ selectedGroup: args.value, editGroup });
    }

    render() {
        const { hasUnsavedChanges, responsibilityGroups, isEditMode, UndoRedoStatus, selectedGroup, editGroup, reloadList } = this.state;

        return (
            <div className="responsibility-groups-wrapper">
                <NavigationPrompt when={hasUnsavedChanges} 
                    navigate={path => this.props.history.push(path)}
                    initiateSave={() => this.onResponsibilityGroupToolbarClicked({ item: { id: 'update' } })}
                    onCancelSave={() => sessionStorage.removeItem(this.groupEditingKey)}
                    shouldBlockNavigation={location => {
                        if (hasUnsavedChanges) {
                            return true;
                        }
                        return false;
                    }}
                />
                <div className="responsibility-groups">
                    <div className="responsibility-group-toolbar-wrapper c-toolbar-plain">
                        <ToolbarComponent ref={ref => this.ResponsibilityGroupToolbarRef = ref} clicked={this.onResponsibilityGroupToolbarClicked}>
                            <ItemsDirective>
                                <ItemDirective id="add" prefixIcon='tb-icons c-icon-add-black' text="Ny" tooltipText='Ny' />
                                <ItemDirective id="edit" prefixIcon='tb-icons c-icon-edit' text="Redigere" tooltipText='Redigere' />
                                <ItemDirective id="update" prefixIcon='tb-icons c-icon-update' text="Gem" tooltipText='Gem' />
                                <ItemDirective id="cancel" prefixIcon='tb-icons c-icon-cancel' text="Annuller" tooltipText='Annuller' />   
                                <ItemDirective id="delete" prefixIcon='tb-icons c-icon-delete' text="Slet" tooltipText='Slet' />
                                <ItemDirective id="undo" prefixIcon='tb-icons c-icon-undo' text="Fortryd" tooltipText={UndoRedoStatus.undoTooltip} disabled={!UndoRedoStatus.hasUndo} />
                                <ItemDirective id="redo" prefixIcon='tb-icons c-icon-redo' text="Gentag" tooltipText={UndoRedoStatus.redoTooltip} disabled={!UndoRedoStatus.hasRedo} />
                            </ItemsDirective>
                        </ToolbarComponent>
                    </div>
                    <div className="responsibility-group-content">
                        <div className="responsibility-group-list">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="label" width="80%">Ansvars Gruppe</th>
                                        <th width="20%"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        responsibilityGroups.length <= 0 || reloadList ? 
                                        <tr>
                                            <td colSpan="2" className="sub-text text-center">{Localization.General.EmptyList}</td>
                                        </tr>
                                        :
                                        responsibilityGroups.map(res => {
                                            return (
                                                <tr className={`clickable ${res.Id === selectedGroup ? 'is-selected' : ''}`} onDoubleClick={(e) => this.onRowDoubleClick(e, res.Id)} onClick={() => this.onRowClick(res)}>
                                                    <td className="regular-text">
                                                        {
                                                            editGroup === res.Id ? <input className={`e-input input-${res.Id}`} value={res.Name} onChange={(e) => this.onChange(e, res.Id)} onKeyUp={(e) => this.onKeyUp(e, res.Id)} /> 
                                                            : <span className="regular-text">{res.Name}</span>
                                                        }
                                                    </td>
                                                    <td>
                                                        <div className="responsibility-group-selection">
                                                            <RadioButtonComponent checked={res.Id === selectedGroup} value={res.Id} name="Group" change={(e) => this.onResponsibilityGroupChange(e, res)} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="message-group-list">
                            <Card headerText="Vælg en profil til venstre, vælg derefter hvilke beskeder gruppen skal have vist" subText="Klik på + for tilhørende funktioner">
                                <TreeViewComponent id='message-group-tree' ref={ref => this.messageGroupTreeRef = ref} fields={this.treeFields} nodeSelecting={this.nodeSelecting} showCheckBox={true} disabled={true} nodeChecked={this.onNodeChecked}
                                    nodeClicked={this.nodeClicked} />
                            </Card>
                        </div>
                    </div>
                </div>
                <DialogComponent isModal={true} width='auto' ref={dialog => this.deleteConfirmationDialog = dialog} target='body' buttons={this.deleteConfirmationButtons}
                    header="Advarsel" content={MessageHelper.MB7.message.replace("{context}", "ansvars gruppe")}
                    visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} />
            </div>
        )
    }
}

export default withRouter(ResponsibilityGroup);
