import React from 'react';
import logo from '../assets/logo_ilx_hor.png';

const Loading = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 72px)'
        }}>
            <div>
                <img src={logo}/>
            </div>
        </div>
    )
}

export default Loading;