import HttpClient from '../../utilities/HttpClient';

const API = {
    getShelf: `/weight/shelf`,
    postShelf: `/weight/shelf`,
    getShelfCalc: `/weight/shelf/calc`,
    postShelfTest: `/weight/shelf/test`,
    postShelfTransfer: `/weight/shelf/transfer`,

    getCabinet: `/weight/cabinet`,
    getCabinetCalc: `/weight/cabinet/calc`,
    postCabinet: `/weight/cabinet`,
    postCabinetTest: `/weight/cabinet/test`,
    postCabinetTransfer: `/weight/cabinet/transfer`,

    getDrawer: `/weight/drawer`,
    getDrawerCalc: `/weight/drawer/calc`,
    postDrawer: `/weight/drawer`,
    postDrawerTest: `/weight/drawer/test`,
    postDrawerTransfer: `/weight/drawer/transfer`,
}

class WeightService {
    async GetShelf(param, cancelToken) {
        return await HttpClient.Get(API.getShelf, param, cancelToken);
    }    
    async PostShelf(data, cancelToken) {
        return await HttpClient.Post(API.postShelf, data, cancelToken);
    }
    async GetShelfCalc(param, cancelToken) {
        return await HttpClient.Get(API.getShelfCalc, param, cancelToken);
    }
    async PostShelfTest(data, cancelToken) {
        return await HttpClient.Post(API.postShelfTest, data, cancelToken);
    }
    async PostShelfTransferData(data, cancelToken) {
        return await HttpClient.Post(API.postShelfTransfer, data, cancelToken);
    }

    async GetCabinet(param, cancelToken) {
        return await HttpClient.Get(API.getCabinet, param, cancelToken);
    }
    async GetCabinetCalc(param, cancelToken) {
        return await HttpClient.Get(API.getCabinetCalc, param, cancelToken);
    }
    async PostCabinet(data, cancelToken) {
        return await HttpClient.Post(API.postCabinet, data, cancelToken);
    }
    async PostCabinetTest(data, cancelToken) {
        return await HttpClient.Post(API.postCabinetTest, data, cancelToken);
    }
    async PostCabinetTransferData(data, cancelToken) {
        return await HttpClient.Post(API.postCabinetTransfer, data, cancelToken);
    }

    async GetDrawer(param, cancelToken) {
        return await HttpClient.Get(API.getDrawer, param, cancelToken);
    }
    async GetDrawerCalc(param, cancelToken) {
        return await HttpClient.Get(API.getDrawerCalc, param, cancelToken);
    }
    async PostDrawer(data, cancelToken) {
        return await HttpClient.Post(API.postDrawer, data, cancelToken);
    }
    async PostDrawerTest(data, cancelToken) {
        return await HttpClient.Post(API.postDrawerTest, data, cancelToken);
    }
    async PostDrawerTransferData(data, cancelToken) {
        return await HttpClient.Post(API.postDrawerTransfer, data, cancelToken);
    }
}

const weightService = new WeightService();
export default weightService;