import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { Redirect } from 'react-router-dom';

import BaseComponent from '../../components/BaseComponent';
import Loading from '../../components/Loading';

import BranchCategory from './Register/BusinessCategory';

export class Register extends BaseComponent {
    moduleName = "System indstillinger";
    pageName = "System Indstillinger - Registre";

    constructor() {
        super();

        this.state = {
            loading: true,
            redirect: {
                to: '',
                isRedirect: false
            },
            currentTabSelected: 0
        }
        
        this.tabHeader = [
            { text: "Branche kategori", url: "/system-indstillinger/registres/branche-kategori" },
        ];
        
        this.dialogOpen = this.dialogOpen.bind(this);
        this.branchCategoryTab = this.branchCategoryTab.bind(this);
        this.onTabSelected = this.onTabSelected.bind(this);

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.buttons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        /* End Dialog options  */
    }

    componentDidMount() {
        super.componentDidMount();

        this.setState({ loading: false });
    }

    onTabSelected(index) {
        const selectedTab = this.tabHeader[index];
        let headerElement = document.querySelector('.module-header > h1');
        headerElement.innerHTML = selectedTab.text;
        this.props.history.push({
            pathname: selectedTab.url,
            state: {
                tab: selectedTab.text
            }
        });
        this.setState({ currentTabSelected: index });
    }

    dialogOpen(header, content) {
        this.notificationDialog.header = header;
        this.notificationDialog.content = content;
        this.notificationDialog.show();
    }

    branchCategoryTab() {
        return <BranchCategory onNotify={this.dialogOpen} />
    }

    render() {
        const { loading, redirect, currentTabSelected } = this.state;

        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        return (
            <div className='content-pane-wrapper'>
                <div className="module-header">
                    <h1>{this.tabHeader[currentTabSelected].text}</h1>
                </div>
                <div className='control-section tab-control'>
                    <Tabs id="register-tab" className="c-tab" selectedIndex={currentTabSelected} onSelect={index => this.onTabSelected(index)} disabledTabClassName="c-tabs__tab--disabled">
                        <TabList className="c-tab__list">
                            <Tab className={`c-tabs__tab`} selectedClassName="c-tabs__tab--selected">{this.tabHeader[0].text}</Tab>
                        </TabList>
                        <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                            {this.branchCategoryTab()}
                        </TabPanel>
                    </Tabs>
                </div>
                <DialogComponent id="register-dialog" isModal={true} buttons={this.buttons} width='auto' ref={dialog => this.notificationDialog = dialog} target='body'
                    visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} />
            </div>
        )
    }
}

export default Register;
