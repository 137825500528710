import React from "react";
import i18n from '../../../utilities/Internationalization';

export default function OilmatWholesalerOverview({ t, supplierData }) {
    return (
        <div className="m-top-30">
            <div className="title">{t('TitleConfirmWholesaler')}</div>
            <div className="info-text m-bottom-15">{t('TitleConfirmWholesalerInfoText')}</div>
            <table className="table">
                <tbody>
                    <tr>
                        <td className="label">{t('LabelWholesalerNo')}</td>
                        <td>{supplierData.CustomerNo}</td>
                    </tr>
                    <tr>
                        <td className="label">{t('LabelCompanyName')}</td>
                        <td>{supplierData.CompanyName}</td>
                    </tr>
                    <tr>
                        <td className="label">{t('LabelAddress')}</td>
                        <td>{supplierData.Street}</td>
                    </tr>
                    <tr>
                        <td className="label">{t('LabelPostalCode')}</td>
                        <td>{supplierData.PostalCode?.Value}</td>
                    </tr>
                    <tr>
                        <td className="label">{t('LabelCity')}</td>
                        <td>{supplierData.City?.Name}</td>
                    </tr>
                    <tr>
                        <td className="label">{t('LabelRegion')}</td>
                        <td>{supplierData.Region?.Name}</td>
                    </tr>
                    <tr>
                        <td className="label">{t('LabelCountry')}</td>
                        <td>{supplierData.Country?.Name}</td>
                    </tr>
                    <tr>
                        <td className="label">{t('LabelTelephoneNumber')}</td>
                        <td>{supplierData.Account?.PhoneNumber}</td>
                    </tr>
                    <tr>
                        <td className="label">{t('LabelMail')}</td>
                        <td>{supplierData.ContactMail}</td>
                    </tr>
                    {
                        i18n.language === 'da' &&
                        <tr>
                            <td className="label">{t('LabelCvrNo')}</td>
                            <td>{supplierData.VatNo}</td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );
}