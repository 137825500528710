import React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Page, Sort, Toolbar, Selection, Search, Reorder, Resize } from '@syncfusion/ej2-react-grids';
import { Redirect } from 'react-router-dom';

import BaseComponent from '../../components/BaseComponent';
import Axios from 'axios';
import Loading from '../../components/Loading';
import LabelService from '../../services/LabelService';
import SessionService from '../../services/SessionService';
import SettingsService from '../../services/SettingsService';
import Image from '../../components/Image';
import Localization from '../../utilities/Localization';
import { buildGridSettings } from '../../utilities/Helper';

import placeHolderImg from '../../assets/placeholders/img_placeholder_108x80.jpg';
const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class Labels extends BaseComponent {
    moduleName = "Etiketter";
    pageName = "Etiketter";
    productLabelGridSettingsKey = "ProductLabel";
    locationLabelGridSettingsKey = "LocationLabel";
    hasDraggedColumn = false;
    productLabelGridSettings = '';
    locationLabelGridSettings = '';

    constructor() {
        super();

        this.state = {
            loading: true,
            redirect: {
                to: '',
                isRedirect: false
            }
        }

        this.tabHeader = [
            { text: "Etiket til produkter" }, 
            { text: "Etiket til lokation" }
        ];

        this.pageSettings = {
            pageSize: 25,
            pageSizes: ['Alle', '5', '10', '20', '50', '100']
        };

        this.selectionSettings = {
            persistSelection: true
        }

        this.filterSettings = {
            type: 'Menu'
        };

        this.productLabelData = [];
        this.locationLabelData = [];

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.notificationButtons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        /* End Dialog options  */

        this.init = this.init.bind(this);
        this.onProductLabelToolbarClick = this.onProductLabelToolbarClick.bind(this);
        this.onLocationLabelToolbarClick = this.onLocationLabelToolbarClick.bind(this);
    }
    
    async componentDidMount() {
        super.componentDidMount();
        source.cancel();
        source = CancelToken.source();

        if (!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }

        this.init();
        
        const sessionInfo = await SessionService.GetSessionInfo();
        const productData = await LabelService.GetProductLabels(sessionInfo.Id, source.token);
        this.productLabelData = productData;

        const locationData = await LabelService.GetLocationLabels(sessionInfo.Id, source.token);
        this.locationLabelData = locationData.map((label, index) => {
            return {
                ...label,
                Id: index
            }
        });

        const productLabelGridSettings = await SettingsService.GetGridSettings(this.productLabelGridSettingsKey, source.token);
        if (!productLabelGridSettings.HasError) {
            this.productLabelGridSettings = productLabelGridSettings.Settings;
        }

        const locationLabelGridSettings = await SettingsService.GetGridSettings(this.locationLabelGridSettingsKey, source.token);
        if (!locationLabelGridSettings.HasError) {
            this.locationLabelGridSettings = locationLabelGridSettings.Settings;
        }

        this.setState({ loading: false}, () => {
            window.addEventListener('wheel', this.onWheelScroll);
        });
    }

    componentWillUnmount() {
        source.cancel();
        window.removeEventListener('wheel', this.onWheelScroll);
    }

    init() {
        this.productToolbarOptions = [];
        this.locationToolbarOptions = [];

        this.productToolbarOptions.push({ text: 'Print', tooltipText: 'Print', id: 'print', prefixIcon: 'e-print' });
        this.productToolbarOptions.push({ text: Localization.General.GridResetFilter, tooltipText: Localization.General.GridResetFilter, id: 'reset_filter', prefixIcon: 'e-cancel' });

        this.locationToolbarOptions.push({ text: 'Print', tooltipText: 'Print', id: 'print', prefixIcon: 'e-print' });
        this.locationToolbarOptions.push({ text: Localization.General.GridResetFilter, tooltipText: Localization.General.GridResetFilter, id: 'reset_filter', prefixIcon: 'e-cancel' });
    }

    dialogOpen(header, content) {
        this.notificationDialog.header = header;
        this.notificationDialog.content = content;
        this.notificationDialog.show();
    }

    imageTemplate(props) {
        if (props.ImageKey) {
            return (
                <Image src={props.ImageKey} alt="" className="product-image-data" />
            )
        }

        return <img src={placeHolderImg} alt="primary product" className="product-image-data" />;
    }

    async onProductLabelToolbarClick(args) {
        if(args.item.id === 'print') {
            let selectedRecords = this.productLabelGridRef.getSelectedRecords();
            if (selectedRecords.length <= 0) {
                this.dialogOpen("Advarsel", Localization.Label.PleaseSelectRecords)
            }
            else {
                let labels = [];
                selectedRecords.forEach(prod => {
                    labels.push({ p: prod.ProductNo, d: prod.Description1, e: prod.EAN });
                });
                localStorage.setItem('PRINT_PROD_LABEL', JSON.stringify(labels));
                window.open(`/etiketter/printer`, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
                // window.open(`/etiketter/printer/?Products=${JSON.stringify(labels)}&Type=Product`, 
                // '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
            }
        }
        else if (args.item.id === 'reset_filter') {
            if (this.productLabelGridSettings) {
                let settings = JSON.parse(this.productLabelGridSettings);
                settings.filters = [];
                this.productLabelGridSettings = settings;
                const payload = {
                    Key: this.productLabelGridSettingsKey,
                    Settings: JSON.stringify(this.productLabelGridSettings)
                };
    
                const result = await SettingsService.SaveGridSettings(payload, source.token);
    
                if (!result.HasError) {
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                }
            }

            this.productLabelGridRef.clearFiltering();
        }
    }

    async onLocationLabelToolbarClick(args) {
        if(args.item.id === 'print') {
            let selectedRecords = this.locationLabelGridRef.getSelectedRecords();
            if (selectedRecords.length <= 0) {
                this.dialogOpen("Advarsel", Localization.Label.PleaseSelectRecords)
            }
            else {
                let labels = [];
                selectedRecords.forEach(box => {
                    labels.push(box.BoxNo);
                });
                window.open(`/etiketter/printer/?Boxes=${labels.join(',')}`, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
            }
        }
        else if (args.item.id === 'reset_filter') {
            if (this.locationLabelGridSettings) {
                let settings = JSON.parse(this.locationLabelGridSettings);
                settings.filters = [];
                this.locationLabelGridSettings = settings;
                const payload = {
                    Key: this.locationLabelGridSettingsKey,
                    Settings: JSON.stringify(this.locationLabelGridSettings)
                };
    
                const result = await SettingsService.SaveGridSettings(payload, source.token);
    
                if (!result.HasError) {
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                }
            }

            this.locationLabelGridRef.clearFiltering();
        }
    }

    onColumnDragStart(args) {
        this.hasDraggedColumn = true;
    }

    onProductLabelCreated() {
        if (this.productLabelGridSettings) {
            let settings = JSON.parse(this.productLabelGridSettings);
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(column => {
                        if (column.FromIndex !== column.ToIndex) {
                            var tempIndex = this.productLabelGridRef.getColumnIndexByField(column.Field);
                            if (tempIndex !== column.ToIndex){
                                this.productLabelGridRef.reorderColumnByIndex(column.FromIndex, column.ToIndex);
                            }
                        }
    
                        if (column.Width) {
                            this.productLabelGridRef.columns[column.ToIndex].width = column.Width;
                        }
                    });
                }

                if (settings.colName && settings.direction) {
                    this.productLabelGridRef.sortColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.productLabelGridRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.productLabelGridRef.refreshColumns();

                // Prevent resizing of last column
                let lastColumnIndex = this.productLabelGridRef.columns.length - 1;    
                this.productLabelGridRef.columns[lastColumnIndex].allowResizing = false;
                this.productLabelGridRef.refreshColumns();

                if (settings.pageSize) {
                    if (settings.pageSize === "Alle") {
                        this.productLabelGridRef.pageSettings.pageSize = this.productLabelGridRef.pageSettings.totalRecordsCount;
                        document.querySelector("#product-label-grid .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.productLabelGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
            }
        }
    }

    async onProductLabelActionComplete(args) {
        if (args.requestType === 'paging') {
            if (this.productLabelGridRef.pageSettings.pageSize === this.productLabelGridRef.pageSettings.totalRecordsCount) {
                document.querySelector("#product-label-grid .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.productLabelGridSettings, this.productLabelGridRef.pageSettings.pageSize, this.productLabelGridRef.pageSettings.totalRecordsCount, false, []);
            this.productLabelGridSettings = tempGridSettings;
            const payload = {
                Key: this.productLabelGridSettingsKey,
                Settings: this.productLabelGridSettings
            };
            const result = await SettingsService.SaveGridSettings(payload, source.token);
        }
        else if (args.requestType === 'filtering') {
            if (this.productLabelGridSettings) {
                let settings = JSON.parse(this.productLabelGridSettings);
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let tempGridSettings = buildGridSettings(args, this.productLabelGridSettings, 0, 0, false, []);
            this.productLabelGridSettings = tempGridSettings;
            const payload = {
                Key: this.productLabelGridSettingsKey,
                Settings: this.productLabelGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.productLabelGridSettings) {
                let settings = JSON.parse(this.productLabelGridSettings);
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.productLabelGridSettings, 0, 0, false, []);
            this.productLabelGridSettings = tempGridSettings;
            const payload = {
                Key: this.productLabelGridSettingsKey,
                Settings: this.productLabelGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.productLabelGridRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.productLabelGridSettings, 0, 0, this.hasDraggedColumn, columns);
            this.productLabelGridSettings = tempGridSettings;
            const payload = {
                Key: this.productLabelGridSettingsKey,
                Settings: this.productLabelGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                let lastColumnIndex = this.productLabelGridRef.columns.length - 1;
                for (let index = 0; index < this.productLabelGridRef.columns.length; index++) {
                    let column = this.productLabelGridRef.columns[index];
                    if (index === lastColumnIndex) { column.allowResizing = false }
                    else { column.allowResizing = true }
                }
                this.productLabelGridRef.refreshColumns();
            }

            this.hasDraggedColumn = false;
        }
    }

    async onProductLabelResizeStop(args) {
        args.requestType = "resizingGrid";
        let columns = this.productLabelGridRef.getColumns();
        let tempGridSettings = buildGridSettings(args, this.productLabelGridSettings, 0, 0, false, columns);
        this.productLabelGridSettings = tempGridSettings;
        const payload = {
            Key: this.productLabelGridSettingsKey,
            Settings: this.productLabelGridSettings
        };

        const result = await SettingsService.SaveGridSettings(payload, source.token);

        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);

            // Adjust size of last column to fill spaces
            let lastColumnIndex = this.productLabelGridRef.columns.length - 1;
            let totalColumnWidth = this.productLabelGridRef.columns.reduce((acc, curVal) => acc + parseInt(curVal.width), 0);
            let lastColumnWidth = this.productLabelGridRef.element.clientWidth - totalColumnWidth;
            this.productLabelGridRef.columns[lastColumnIndex].width = this.productLabelGridRef.columns[lastColumnIndex].width + lastColumnWidth;
            this.productLabelGridRef.refreshColumns();
        }
    }

    productLabelTab() {
        return (
            <div style={{marginTop: 20}}>
                <div className="print-note">
                    Filtrer tabellen nedenfor så tabellen viser de produkter du ønsker at udskrive labels for, tryk derefter print
                </div>
                <GridComponent id="product-label-grid" ref={ref => this.productLabelGridRef = ref } dataSource={this.productLabelData} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings} toolbar={this.productToolbarOptions}
                    selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both"
                    toolbarClick={this.onProductLabelToolbarClick} allowReordering={true} allowResizing={true} actionComplete={this.onProductLabelActionComplete.bind(this)}
                    columnDragStart={this.onColumnDragStart.bind(this)} created={this.onProductLabelCreated.bind(this)} resizeStop={this.onProductLabelResizeStop.bind(this)}>
                    <ColumnsDirective>
                        <ColumnDirective type='checkbox' width='30' textAlign="Center"></ColumnDirective>
                        <ColumnDirective field="Id" visible={false} isPrimaryKey={true}></ColumnDirective>
                        <ColumnDirective headerText="Billede" field="ProductNo" width='100' template={this.imageTemplate} allowFiltering={false} />
                        <ColumnDirective headerText="Produkt Nr." field="ProductNo" width='100' />
                        <ColumnDirective headerText="Beskrivelse" field="Description1" width='100' />
                        <ColumnDirective headerText="EAN" field="EAN" width='100' />
                        <ColumnDirective headerText="Produktgruppe 1" field="ProductGroup" width='100' />
                        <ColumnDirective headerText="Produktgruppe 2" field="" width='100' />
                        <ColumnDirective headerText="Afdeling" field="DeptName" width='100' />
                        <ColumnDirective headerText="Automat" field="BoxNoPart1" width='100' />
                        <ColumnDirective headerText="Lokation" field="BoxNoPart2" width='70' />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Selection, Toolbar, Search, Reorder, Resize]} />
                </GridComponent>
            </div>
        )
    }

    onLocationLabelCreated() {
        if (this.locationLabelGridSettings) {
            let settings = JSON.parse(this.locationLabelGridSettings);
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(column => {
                        if (column.FromIndex !== column.ToIndex) {
                            var tempIndex = this.locationLabelGridRef.getColumnIndexByField(column.Field);
                            if (tempIndex !== column.ToIndex){
                                this.locationLabelGridRef.reorderColumnByIndex(column.FromIndex, column.ToIndex);
                            }
                        }
    
                        if (column.Width) {
                            this.locationLabelGridRef.columns[column.ToIndex].width = column.Width;
                        }
                    });
                }

                if (settings.colName && settings.direction) {
                    this.locationLabelGridRef.sortColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.locationLabelGridRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.locationLabelGridRef.refreshColumns();

                // Prevent resizing of last column
                let lastColumnIndex = this.locationLabelGridRef.columns.length - 1;    
                this.locationLabelGridRef.columns[lastColumnIndex].allowResizing = false;
                this.locationLabelGridRef.refreshColumns();

                if (settings.pageSize) {
                    if (settings.pageSize === "Alle") {
                        this.locationLabelGridRef.pageSettings.pageSize = this.locationLabelGridRef.pageSettings.totalRecordsCount;
                        document.querySelector("#location-label .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.locationLabelGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
            }
        }
    }

    async onLocationLabelActionComplete(args) {
        if (args.requestType === 'paging') {
            if (this.locationLabelGridRef.pageSettings.pageSize === this.locationLabelGridRef.pageSettings.totalRecordsCount) {
                document.querySelector("#location-label-grid .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.locationLabelGridSettings, this.locationLabelGridRef.pageSettings.pageSize, this.locationLabelGridRef.pageSettings.totalRecordsCount, false, []);
            this.locationLabelGridSettings = tempGridSettings;
            const payload = {
                Key: this.locationLabelGridSettingsKey,
                Settings: this.locationLabelGridSettings
            };
            const result = await SettingsService.SaveGridSettings(payload, source.token);
        }
        else if (args.requestType === 'filtering') {
            if (this.locationLabelGridSettings) {
                let settings = JSON.parse(this.locationLabelGridSettings);
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let tempGridSettings = buildGridSettings(args, this.locationLabelGridSettings, 0, 0, false, []);
            this.locationLabelGridSettings = tempGridSettings;
            const payload = {
                Key: this.locationLabelGridSettingsKey,
                Settings: this.locationLabelGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.locationLabelGridSettings) {
                let settings = JSON.parse(this.locationLabelGridSettings);
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.locationLabelGridSettings, 0, 0, false, []);
            this.locationLabelGridSettings = tempGridSettings;
            const payload = {
                Key: this.locationLabelGridSettingsKey,
                Settings: this.locationLabelGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.locationLabelGridRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.locationLabelGridSettings, 0, 0, this.hasDraggedColumn, columns);
            this.locationLabelGridSettings = tempGridSettings;
            const payload = {
                Key: this.locationLabelGridSettingsKey,
                Settings: this.locationLabelGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                let lastColumnIndex = this.locationLabelGridRef.columns.length - 1;
                for (let index = 0; index < this.locationLabelGridRef.columns.length; index++) {
                    let column = this.locationLabelGridRef.columns[index];
                    if (index === lastColumnIndex) { column.allowResizing = false }
                    else { column.allowResizing = true }
                }
                this.locationLabelGridRef.refreshColumns();
            }

            this.hasDraggedColumn = false;
        }
    }

    async onLocationLabelResizeStop(args) {
        args.requestType = "resizingGrid";
        let columns = this.locationLabelGridRef.getColumns();
        let tempGridSettings = buildGridSettings(args, this.locationLabelGridSettings, 0, 0, false, columns);
        this.locationLabelGridSettings = tempGridSettings;
        const payload = {
            Key: this.locationLabelGridSettingsKey,
            Settings: this.locationLabelGridSettings
        };

        const result = await SettingsService.SaveGridSettings(payload, source.token);

        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);

            // Adjust size of last column to fill spaces
            let lastColumnIndex = this.locationLabelGridRef.columns.length - 1;
            let totalColumnWidth = this.locationLabelGridRef.columns.reduce((acc, curVal) => acc + parseInt(curVal.width), 0);
            let lastColumnWidth = this.locationLabelGridRef.element.clientWidth - totalColumnWidth;
            this.locationLabelGridRef.columns[lastColumnIndex].width = this.locationLabelGridRef.columns[lastColumnIndex].width + lastColumnWidth;
            this.locationLabelGridRef.refreshColumns();
        }
    }

    locationLabelTab() {
        return (
            <div style={{marginTop: 20}}>
                <div className="print-note">
                    Filtrer tabellen nedenfor så tabellen viser de lokationer du ønsker at udskrive labels for, tryk derefter print
                </div>
                <GridComponent id="location-label-grid" ref={ref => this.locationLabelGridRef = ref } dataSource={this.locationLabelData} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings} toolbar={this.locationToolbarOptions}
                    selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both"
                    toolbarClick={this.onLocationLabelToolbarClick} allowReordering={true} allowResizing={true} actionComplete={this.onLocationLabelActionComplete.bind(this)}
                    columnDragStart={this.onColumnDragStart.bind(this)} created={this.onLocationLabelCreated.bind(this)} resizeStop={this.onLocationLabelResizeStop.bind(this)}>
                    <ColumnsDirective>
                        <ColumnDirective type='checkbox' width='20px'></ColumnDirective>
                        <ColumnDirective field="Id" visible={false} isPrimaryKey={true}></ColumnDirective>
                        <ColumnDirective headerText="Afdeling" field="DeptName" width='100' />
                        <ColumnDirective headerText="Automat" field="BoxNoPart1" width='100' />
                        <ColumnDirective headerText="Lokation" field="BoxNoPart2" width='100' />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Selection, Toolbar, Search, Reorder, Resize]} />
                </GridComponent>
            </div>
        )
    }

    dialogOpen(header, content) {
        this.notificationDialog.header = header;
        this.notificationDialog.content = content;
        this.notificationDialog.show();
    }

    onWheelScroll = () => {
        let grid = document.getElementById('product-label-grid');
        let toolbar = document.querySelector('#product-label-grid.e-grid .e-toolbar');
        let tableHeader = document.querySelector('#product-label-grid .e-gridheader');

        if (toolbar && tableHeader) {
            let boundingRect = tableHeader.getBoundingClientRect();
            
            if (boundingRect.top < 90) {
                toolbar.classList.add('c-hover-grid-toolbar');
                toolbar.style.width = (grid.clientWidth - 5) + 'px';
            }
            else {
                toolbar.classList.remove('c-hover-grid-toolbar');
                toolbar.style.width = 'auto';
            }
        }

        let grid2 = document.getElementById('location-label-grid');
        let toolbar2 = document.querySelector('#location-label-grid.e-grid .e-toolbar');
        let tableHeader2 = document.querySelector('#location-label-grid .e-gridheader');

        if (toolbar2 && tableHeader2) {
            let boundingRect = tableHeader2.getBoundingClientRect();
            
            if (boundingRect.top < 90) {
                toolbar2.classList.add('c-hover-grid-toolbar');
                toolbar2.style.width = (grid2.clientWidth - 5) + 'px';
            }
            else {
                toolbar2.classList.remove('c-hover-grid-toolbar');
                toolbar2.style.width = 'auto';
            }
        }
    }
    
    render() {
        const { loading, redirect } = this.state;

        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        return (
            <div className="content-pane-wrapper">
                <div className="module-header">
                    <h1>{this.pageName}</h1>
                </div>
                <div className='control-section tab-control'>
                    <TabComponent ref={ref => this.accountTabRef = ref} heightAdjustMode='Auto' id='labels-tab'>
                        <TabItemsDirective>
                            <TabItemDirective header={this.tabHeader[0]} content={this.productLabelTab.bind(this)} />
                            <TabItemDirective header={this.tabHeader[1]} content={this.locationLabelTab.bind(this)} />
                        </TabItemsDirective>
                    </TabComponent>
                </div>
                <DialogComponent id="machine-dialog" isModal={true} buttons={this.notificationButtons} width='auto' ref={dialog => this.notificationDialog = dialog} 
                    target='body' visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true}/>
            </div>
        )
    }
}

export default Labels;
