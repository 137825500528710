import React, { Component } from "react";
import { Query } from '@syncfusion/ej2-data';
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import i18n from '../../../utilities/Internationalization';

export default class OilmatSupplier extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSameAsInvoiceAddress: false
        };
        this.queryText = '';

        this.onPostalCodeFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('Value', 'startswith', e.text, true) : query;
            this.queryText = e.text;
            e.updateData(props.lookup.postalCodes, query)
        }

        this.onCityFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('Name', 'startswith', e.text, true) : query;
            this.queryText = e.text;
            e.updateData(props.lookup.cities, query)
        }

        this.onRegionFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('Name', 'startswith', e.text, true) : query;
            this.queryText = e.text;
            e.updateData(props.lookup.regions, query)
        }

        this.onCountryFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('Name', 'startswith', e.text, true) : query;
            e.updateData(props.lookup.countries, query)
        }
    }

    onChange = (args) => {
        this.props.onChange(args.event.target.name, args.value);
    }

    onChecked = (args) => {
        const { data } = this.props
        this.setState({ isSameAsInvoiceAddress: args.checked });
        this.props.onChange('supplierSameAsInvoiceAddress', args.checked);

        if (args.checked) {
            this.props.onChange("DeliveryAddress.DeliveryStreet", data.Street);
            this.props.onChange("DeliveryAddress.DeliveryPostalCodeId", data.PostalCodeId);
            this.props.onChange("DeliveryAddress.DeliveryPostalCodeName", data.PostalCodeName);
            this.props.onChange("DeliveryAddress.DeliveryPostalCodeNew", data.PostalCodeNew);
            this.props.onChange("DeliveryAddress.CityId", data.CityId);
            this.props.onChange("DeliveryAddress.CityName", data.CityName);
            this.props.onChange("DeliveryAddress.CityNew", data.CityNew);
            this.props.onChange("DeliveryAddress.RegionId", data.RegionId);
            this.props.onChange("DeliveryAddress.RegionName", data.RegionName);
            this.props.onChange("DeliveryAddress.RegionNew", data.RegionNew);
            this.props.onChange("DeliveryAddress.CountryId", data.CountryId);
            this.props.onChange("DeliveryAddress.CountryName", data.CountryName);
            this.props.onChange("DeliveryAddress.PhoneNumber", data.PhoneNumber);
            this.props.onChange("DeliveryAddress.Email", data.ContactMail);
        }
        else {
            this.props.onChange("DeliveryAddress.DeliveryStreet", null);
            this.props.onChange("DeliveryAddress.DeliveryPostalCodeId", null);
            this.props.onChange("DeliveryAddress.DeliveryPostalCodeName", null);
            this.props.onChange("DeliveryAddress.DeliveryPostalCodeNew", null);
            this.props.onChange("DeliveryAddress.CityId", null);
            this.props.onChange("DeliveryAddress.CityName", null);
            this.props.onChange("DeliveryAddress.CityNew", null);
            this.props.onChange("DeliveryAddress.RegionId", null);
            this.props.onChange("DeliveryAddress.RegionName", null);
            this.props.onChange("DeliveryAddress.RegionNew", null);
            this.props.onChange("DeliveryAddress.CountryId", null);
            this.props.onChange("DeliveryAddress.CountryName", null);
            this.props.onChange("DeliveryAddress.PhoneNumber", null);
            this.props.onChange("DeliveryAddress.Email", null);
        }
    }
    
    onPostalCodeChange = (args) => {
        if (args.isInteracted) {
            this.props.onChange("DeliveryAddress.DeliveryPostalCodeId", args.value);
            this.props.onChange("DeliveryAddress.DeliveryPostalCodeName", args.itemData.Value);

            if (args.itemData.CityId) {
                this.props.onChange("DeliveryAddress.DeliveryPostalCodeNew", null);
                this.props.onChange("DeliveryAddress.CityId", args.itemData.CityId);
    
                const city = this.props.lookup.cities.find(city => city.Id === args.itemData.CityId);
                this.props.onChange("DeliveryAddress.CityName", city.Name);
                this.props.onChange("DeliveryAddress.RegionId", city.RegionId);
    
                const region = this.props.lookup.regions.find(region => region.Id === city.RegionId);
                this.props.onChange("DeliveryAddress.RegionName", region.Name);
                this.props.onChange("DeliveryAddress.CountryId", region.CountryId);
    
                const country = this.props.lookup.countries.find(country => country.Id === region.CountryId);
                this.props.onChange("DeliveryAddress.CountryName", country.Name);
            }
        }
    }

    onCityChange = (args) => {
        if (args.isInteracted) {
            this.props.onChange("DeliveryAddress.CityId", args.value);
            this.props.onChange("DeliveryAddress.CityName", args.itemData.Name);

            if (args.itemData.RegionId) {
                this.props.onChange("DeliveryAddress.CityNew", null);
                this.props.onChange("DeliveryAddress.RegionId", args.itemData.RegionId);
    
                const region = this.props.lookup.regions.find(region => region.Id === args.itemData.RegionId);
                this.props.onChange("DeliveryAddress.RegionName", region.Name);
                this.props.onChange("DeliveryAddress.CountryId", region.CountryId);
    
                const country = this.props.lookup.countries.find(country => country.Id === region.CountryId);
                this.props.onChange("DeliveryAddress.CountryName", country.Name);
            }
        }
    }

    onDropdownChange = (name, args) => {
        if (args.isInteracted) {
            this.props.onChange(name, args.value);
            
            if (name === "DeliveryAddress.RegionId") {
                this.props.onChange("DeliveryAddress.RegionName", args.itemData.Name);

                if (args.itemData.CountryId) {
                    this.props.onChange("DeliveryAddress.RegionNew", null);
                    this.props.onChange("DeliveryAddress.CountryId", args.itemData.CountryId);
    
                    const country = this.props.lookup.countries.find(country => country.Id === args.itemData.CountryId);
                    this.props.onChange("DeliveryAddress.CountryName", country.Name);
                }
            }
            else if (name === "DeliveryAddress.CountryId") {
                this.props.onChange("DeliveryAddress.CountryName", args.itemData.Name);
            }
        }
    }

    addMissingItem = (name) => {
        this.props.onAddMissingItem(name, this.queryText, (newValue) => {
            switch (name) {
                case 'DeliveryAddress.DeliveryPostalCodeNew':
                    this.postalCodeRef.hidePopup();
                    this.postalCodeRef.value = newValue;
                    this.postalCodeRef.refresh();
                    break;
                case 'DeliveryAddress.CityNew':
                    this.cityRef.hidePopup();
                    this.cityRef.value = newValue;
                    this.cityRef.refresh();
                    break;
                case 'DeliveryAddress.RegionNew':
                    this.regionRef.hidePopup();
                    this.regionRef.value = newValue;
                    this.regionRef.refresh();
                    break;
            }
        });
    }

    noRecordTemplate = (name) => {
        const { t } = this.props;
        return <span>
            {t('NoRecordFound')} <span className="clickable c-link" onClick={() => this.addMissingItem(name)}>{`${t('Add')} ${this.queryText}?`}</span>
        </span>;
    }

    render() {
        const { data, lookup, validation, t } = this.props;

        return <div>
            <div className="title">{t('TitleSupplierAddress')}</div>
            <div className="m-15">
                <CheckBoxComponent 
                    cssClass="control-left m-right-15" 
                    checked={data.supplierSameAsInvoiceAddress}
                    change={this.onChecked}
                />
                <span>{t('DeliveryAddressSameAsInvoiceAddress')}</span>
            </div>

            <div className="m-bottom-10">
                <TextBoxComponent
                    name="DeliveryAddress.DeliveryStreet"
                    value={data.DeliveryAddress.DeliveryStreet}
                    placeholder={t('LabelAddress') + "*"}
                    input={this.onChange}
                    floatLabelType="Auto" 
                    cssClass={`${validation.hasError && validation.errors.some(e => e.id === "DeliveryAddress.DeliveryStreet") ? "e-error" : ""}`}
                />
                { validation.hasError && validation.errors.some(e => (e.id === "DeliveryAddress.DeliveryStreet") && (e.message !== null || e.message !== undefined)) 
                    && <span className="error">{validation.errors.find(e => e.id === "DeliveryAddress.DeliveryStreet").message}</span> }
            </div>
            <div className="m-bottom-10">
                {
                    i18n.language === "da" &&
                    <>
                        <DropDownListComponent 
                            ref={ref => this.postalCodeRef = ref}
                            name="DeliveryAddress.DeliveryPostalCodeId" 
                            value={data.DeliveryAddress.DeliveryPostalCodeId} 
                            dataSource={lookup.postalCodes} 
                            filtering={this.onPostalCodeFiltering.bind(this)} 
                            filterBarPlaceholder={t('SearchPostalCode')}
                            allowFiltering={true} 
                            placeholder={t('LabelPostalCode') + "*"}
                            fields={{ text: 'Value', value: 'Id' }} 
                            change={this.onPostalCodeChange} 
                            floatLabelType="Auto"
                            noRecordsTemplate={() => this.noRecordTemplate('DeliveryAddress.DeliveryPostalCodeNew')}
                            cssClass={`${validation.hasError && validation.errors.some(e => e.id === "DeliveryAddress.DeliveryPostalCodeId") ? "e-error" : ""}`} />
                        { validation.hasError && validation.errors.some(e => (e.id === "DeliveryAddress.DeliveryPostalCodeId") && (e.message !== null || e.message !== undefined)) 
                            && <span className="error">{validation.errors.find(e => e.id === "DeliveryAddress.DeliveryPostalCodeId").message}</span> }
                    </>
                }
                {
                    i18n.language !== "da" &&
                    <>
                        <TextBoxComponent
                            name="DeliveryAddress.DeliveryPostalCodeNew"
                            value={data.DeliveryAddress.DeliveryPostalCodeNew}
                            placeholder={t('LabelPostalCode') + "*"}
                            input={this.onChange} 
                            floatLabelType="Auto"
                            cssClass={`${validation.hasError && validation.errors.some(e => e.id === "DeliveryAddress.DeliveryPostalCodeNew") ? "e-error" : ""}`}
                        />
                        { validation.hasError && validation.errors.some(e => (e.id === "DeliveryAddress.DeliveryPostalCodeNew") && (e.message !== null || e.message !== undefined)) 
                            && <span className="error">{validation.errors.find(e => e.id === "DeliveryAddress.DeliveryPostalCodeNew").message}</span> }
                    </>
                }
            </div>
            <div className="m-bottom-10">
                {
                    i18n.language === "da" &&
                    <>
                        <DropDownListComponent 
                            ref={ref => this.cityRef = ref}
                            name="DeliveryAddress.CityId"
                            value={data.DeliveryAddress.CityId} 
                            dataSource={lookup.cities} 
                            filtering={this.onCityFiltering.bind(this)}
                            filterBarPlaceholder={t('SearchCity')}
                            allowFiltering={true} 
                            placeholder={t('LabelCity') + "*"}
                            fields={{ text: 'Name', value: 'Id' }} 
                            change={this.onCityChange} 
                            floatLabelType="Auto"
                            noRecordsTemplate={() => this.noRecordTemplate('DeliveryAddress.CityNew')}
                            cssClass={`${validation.hasError && validation.errors.some(e => e.id === "DeliveryAddress.CityId") ? "e-error" : ""}`}
                        />
                        { validation.hasError && validation.errors.some(e => (e.id === "DeliveryAddress.CityId") && (e.message !== null || e.message !== undefined)) 
                            && <span className="error">{validation.errors.find(e => e.id === "DeliveryAddress.CityId").message}</span> }
                    </>
                }
                {
                    i18n.language !== "da" &&
                    <>
                        <TextBoxComponent
                            name="DeliveryAddress.CityNew"
                            value={data.DeliveryAddress.CityNew}
                            placeholder={t('LabelCity') + "*"}
                            input={this.onChange} 
                            floatLabelType="Auto"
                            cssClass={`${validation.hasError && validation.errors.some(e => e.id === "DeliveryAddress.CityNew") ? "e-error" : ""}`}
                        />
                        { validation.hasError && validation.errors.some(e => (e.id === "DeliveryAddress.CityNew") && (e.message !== null || e.message !== undefined)) 
                            && <span className="error">{validation.errors.find(e => e.id === "DeliveryAddress.CityNew").message}</span> }
                    </>
                }
            </div>
            <div className="m-bottom-10">
                {
                    i18n.language === "da" &&
                    <>
                        <DropDownListComponent 
                            ref={ref => this.regionRef = ref}
                            name="DeliveryAddress.RegionId" 
                            value={data.DeliveryAddress.RegionId}
                            dataSource={lookup.regions}
                            filtering={this.onRegionFiltering.bind(this)} 
                            filterBarPlaceholder={t('SearchRegion')}
                            allowFiltering={true}
                            placeholder={t('LabelRegion') + "*"}
                            fields={{ text: 'Name', value: 'Id' }} 
                            change={this.onDropdownChange.bind(this, "DeliveryAddress.RegionId")} 
                            floatLabelType="Auto"
                            noRecordsTemplate={() => this.noRecordTemplate('DeliveryAddress.RegionNew')}
                            cssClass={`${validation.hasError && validation.errors.some(e => e.id === "DeliveryAddress.RegionId") ? "e-error" : ""}`}
                        />
                        { validation.hasError && validation.errors.some(e => (e.id === "DeliveryAddress.RegionId") && (e.message !== null || e.message !== undefined)) 
                            && <span className="error">{validation.errors.find(e => e.id === "DeliveryAddress.RegionId").message}</span> }
                    </>
                }
                {
                    i18n.language !== "da" &&
                    <>
                        <TextBoxComponent
                            name="DeliveryAddress.RegionNew"
                            value={data.DeliveryAddress.RegionNew}
                            placeholder={t('LabelRegion') + "*"}
                            input={this.onChange} 
                            floatLabelType="Auto"
                            cssClass={`${validation.hasError && validation.errors.some(e => e.id === "DeliveryAddress.RegionNew") ? "e-error" : ""}`}
                        />
                        { validation.hasError && validation.errors.some(e => (e.id === "DeliveryAddress.RegionNew") && (e.message !== null || e.message !== undefined)) 
                            && <span className="error">{validation.errors.find(e => e.id === "DeliveryAddress.RegionNew").message}</span> }
                    </>
                }
            </div>
            <div className="m-bottom-10">
                <DropDownListComponent 
                    name="DeliveryAddress.CountryId"
                    value={data.DeliveryAddress.CountryId}
                    dataSource={lookup.countries}
                    filtering={this.onCountryFiltering.bind(this)} 
                    filterBarPlaceholder={t('SearchCountry')}
                    allowFiltering={true}
                    placeholder={t('LabelCountry') + "*"}
                    fields={{ text: 'Name', value: 'Id' }}
                    change={this.onDropdownChange.bind(this, "DeliveryAddress.CountryId")} 
                    floatLabelType="Auto"
                    cssClass={`${validation.hasError && validation.errors.some(e => e.id === "DeliveryAddress.CountryId") ? "e-error" : ""}`} />
            </div>
            <div className="m-bottom-10">
                <TextBoxComponent
                    name="DeliveryAddress.PhoneNumber"
                    value={data.DeliveryAddress.PhoneNumber}
                    placeholder={t('LabelTelephoneNumber') + "*"}
                    input={this.onChange} 
                    floatLabelType="Auto" 
                    cssClass={`${validation.hasError && validation.errors.some(e => e.id === "DeliveryAddress.PhoneNumber") ? "e-error" : ""}`}
                />
                { validation.hasError && validation.errors.some(e => (e.id === "DeliveryAddress.PhoneNumber") && (e.message !== null || e.message !== undefined)) 
                    && <span className="error">{validation.errors.find(e => e.id === "DeliveryAddress.PhoneNumber").message}</span> }
            </div>
            <div className="m-bottom-10">
                <TextBoxComponent
                    name="DeliveryAddress.Email"
                    value={data.DeliveryAddress.Email}
                    placeholder={t('LabelMail') + "*"}
                    input={this.onChange} 
                    floatLabelType="Auto"
                    cssClass={`${validation.hasError && validation.errors.some(e => e.id === "DeliveryAddress.Email") ? "e-error" : ""}`}
                />
                { validation.hasError && validation.errors.some(e => (e.id === "DeliveryAddress.Email") && (e.message !== null || e.message !== undefined)) 
                    && <span className="error">{validation.errors.find(e => e.id === "DeliveryAddress.Email").message}</span> }
            </div>
        </div>;
    }
}