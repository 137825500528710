import React, { Component } from 'react';
import isEqual from "react-fast-compare";
import '../styles/machine-mapper.scss';

import RoomImg from '../assets/machines/rooms.png';
import MediumRoomImg from '../assets/machines/machine_plasic_bins_medium.png';

export class RoomLocationMapper extends Component {
    constructor() {
        super();

        this.initCanvas = this.initCanvas.bind(this);
        this.renderRoomsArea = this.renderRoomsArea.bind(this);
        this.watchedProps = [ "active", "activeRoom", "occupiedRooms", "selectedPlasticBin" ];
    }

    shouldComponentUpdate(nextProps) {
		const propChanged = this.watchedProps.some(
			prop => this.props[prop] !== nextProps[prop]
		);
		return !isEqual(this.props.activeRoom, this.state.activeRoom) || propChanged;
    }

    componentWillMount() {
		this.updateCacheMap();
	}

	componentDidUpdate() {
		this.updateCacheMap();
		this.initCanvas();
	}

	updateCacheMap() {
		this.setState(
            { 
                activeRoom: JSON.parse(JSON.stringify(this.props.activeRoom)) ,
                occupiedRooms: JSON.parse(JSON.stringify(this.props.occupiedRooms))
            },
			this.initCanvas
		);
	}

    initCanvas() {
        if (this.props.width) this.image.width = this.props.width;
        
        this.container.style.height = '407px';

        this.canvas.width = this.image.clientWidth;
        this.canvas.height = 407;
        this.canvasContext = this.canvas.getContext("2d");

        this.renderFilledRoom();
    }

    drawrect(coords, fillColor, lineWidth, strokeColor) {
		let [left, top, right, bot] = coords;
		this.canvasContext.fillStyle = fillColor;
		this.canvasContext.lineWidth = lineWidth;
		this.canvasContext.strokeStyle = strokeColor;
		this.canvasContext.strokeRect(left, top, right - left, bot - top);
		this.canvasContext.fillRect(left, top, right - left, bot - top);
		this.canvasContext.fillStyle = fillColor;
    }
    
    drawText(text, coords) {
        this.canvasContext.font = "bold 20px";
		this.canvasContext.fillStyle = "#fff";
        this.canvasContext.fillText(text, coords[0], coords[1]);
    }

    click(area, index, event) {
        if (this.props.onClick) {
			event.preventDefault();
			this.props.onClick(area, index, event);
		}
    }

    computeCenter(area) {
        if (!area) return [0, 0];
        
		const scaledCoords = this.scaleCoords(area);

		// Calculate centroid
        const n = scaledCoords.length / 2;
        const { y, x } = scaledCoords.reduce(
            ({ y, x }, val, idx) => {
                return !(idx % 2) ? { y, x: x + val / n } : { y: y + val / n, x };
            },
            { y: 0, x: 0 }
        );
        return [x, y];
	}

    scaleCoords(coords) {
		const { imgWidth, width } = this.props;
		// calculate scale based on current 'width' and the original 'imgWidth'
		const scale = width && imgWidth && imgWidth > 0 ? width / imgWidth : 1;
		return coords.map(coord => parseInt(coord) * scale);
    }
    
    renderFilledRoom() {
        const { occupiedRooms } = this.props;
        // const rooms = [
        //     { No: "10", Coords: "10,22,84,62"}, { No: "9", Coords: "10,66,84,98"},
        //     { No: "8", Coords: "10,101,84,133"}, { No: "7", Coords: "10,136,84,168"},
        //     { No: "6", Coords: "10,171,84,203"}, { No: "5", Coords: "10,206,84,238"},
        //     { No: "4", Coords: "10,241,84,273"}, { No: "3", Coords: "10,276,84,308"},
        //     { No: "2", Coords: "10,311,84,343"}, { No: "1", Coords: "10,346,84,390"}
        // ];
        
        const bin = {
            smallRooms: [
                { No: "10", Coords: "10,22,84,62"}, { No: "9", Coords: "10,66,84,98"},
                { No: "8", Coords: "10,101,84,133"}, { No: "7", Coords: "10,136,84,168"},
                { No: "6", Coords: "10,171,84,203"}, { No: "5", Coords: "10,206,84,238"},
                { No: "4", Coords: "10,241,84,273"}, { No: "3", Coords: "10,276,84,308"},
                { No: "2", Coords: "10,311,84,343"}, { No: "1", Coords: "10,346,84,390"}
            ],
            mediumRooms: [
                { No: "10", Coords: "10,22,160,62"}, { No: "9", Coords: "10,66,160,98"},
                { No: "8", Coords: "10,101,160,133"}, { No: "7", Coords: "10,136,160,168"},
                { No: "6", Coords: "10,171,160,203"}, { No: "5", Coords: "10,206,160,238"},
                { No: "4", Coords: "10,241,160,273"}, { No: "3", Coords: "10,276,160,308"},
                { No: "2", Coords: "10,311,160,343"}, { No: "1", Coords: "10,346,160,390"}
            ]
        }

        let rooms = null;
        if (this.props.selectedPlasticBin === 1) {
            rooms = bin.smallRooms;
        }
        else if (this.props.selectedPlasticBin === 2) {
            rooms = bin.mediumRooms;
        }
        else {
            return;
        }
        
        let isSelectedEnded = true;
        let startNo = '', endNo = '';
        if (this.props.activeRoom) {
            if (this.props.activeRoom.start && this.props.activeRoom.end) {
                if (parseInt(this.props.activeRoom.start.No) < parseInt(this.props.activeRoom.end.No)) {
                    startNo = this.props.activeRoom.end.No;
                    endNo = this.props.activeRoom.start.No;
                }
                else {
                    startNo = this.props.activeRoom.start.No;
                    endNo = this.props.activeRoom.end.No;
                }
            }
            else if (this.props.activeRoom.start) {
                startNo = this.props.activeRoom.start.No;
            }
        }
        
        rooms.map(room => {
            if (occupiedRooms.some(x => x.RoomNoStart <= +room.No && x.RoomNoEnd >= +room.No)) {
                this.drawrect(room.Coords.split(","), '#a5a5a5', 1, '#a5a5a5');

                if (this.props.activeRoom) {
                    if (startNo === room.No) {
                        this.drawrect(room.Coords.split(","), '#92d050', 1, '#92d050');
    
                        if (endNo) {
                            isSelectedEnded = false;
                        }
                    }
                    if (!isSelectedEnded) {
                        if (endNo === room.No) {
                            this.drawrect(room.Coords.split(","), '#92d050', 1, '#92d050');
                            isSelectedEnded = true;
                        }
                        else {
                            this.drawrect(room.Coords.split(","), '#92d050', 1, '#92d050');
                        }
                    }
                }
            }
            else {
                if (this.props.activeRoom) {
                    if (startNo === room.No) {
                        this.drawrect(room.Coords.split(","), '#92d050', 1, '#92d050');
    
                        if (endNo) {
                            isSelectedEnded = false;
                        }
                    }
                    if (!isSelectedEnded) {
                        if (endNo === room.No) {
                            this.drawrect(room.Coords.split(","), '#92d050', 1, '#92d050');
                            isSelectedEnded = true;
                        }
                        else {
                            this.drawrect(room.Coords.split(","), '#92d050', 1, '#92d050');
                        }
                    }
                }
            }

            let center = this.computeCenter(room.Coords.split(","));
            this.drawText(room.No, center);
        });
    }

    renderRoomsArea() {
        // const rooms = [
        //     { No: "10", Coords: "10,22,84,62"}, { No: "9", Coords: "10,66,84,98"},
        //     { No: "8", Coords: "10,101,84,133"}, { No: "7", Coords: "10,136,84,168"},
        //     { No: "6", Coords: "10,171,84,203"}, { No: "5", Coords: "10,206,84,238"},
        //     { No: "4", Coords: "10,241,84,273"}, { No: "3", Coords: "10,276,84,308"},
        //     { No: "2", Coords: "10,311,84,343"}, { No: "1", Coords: "10,346,84,390"}
        // ];

        const bin = {
            smallRooms: [
                { No: "10", Coords: "10,22,160,62"}, { No: "9", Coords: "10,66,84,98"},
                { No: "8", Coords: "10,101,84,133"}, { No: "7", Coords: "10,136,84,168"},
                { No: "6", Coords: "10,171,84,203"}, { No: "5", Coords: "10,206,84,238"},
                { No: "4", Coords: "10,241,84,273"}, { No: "3", Coords: "10,276,84,308"},
                { No: "2", Coords: "10,311,84,343"}, { No: "1", Coords: "10,346,84,390"}
            ],
            mediumRooms: [
                { No: "10", Coords: "10,22,160,62"}, { No: "9", Coords: "10,66,160,98"},
                { No: "8", Coords: "10,101,160,133"}, { No: "7", Coords: "10,136,160,168"},
                { No: "6", Coords: "10,171,160,203"}, { No: "5", Coords: "10,206,160,238"},
                { No: "4", Coords: "10,241,160,273"}, { No: "3", Coords: "10,276,160,308"},
                { No: "2", Coords: "10,311,160,343"}, { No: "1", Coords: "10,346,160,390"}
            ]
        }

        let rooms = null;
        const { occupiedRooms } = this.props;
        
        if (this.props.selectedPlasticBin === 1) {
            rooms = bin.smallRooms;
        }
        else if (this.props.selectedPlasticBin === 2) {
            rooms = bin.mediumRooms;
        }
        else {
            return;
        }

        return rooms.map((room, index) => {
            if (occupiedRooms.some(x => x.RoomNoStart <= +room.No && x.RoomNoEnd >= +room.No)) {
                return;
            }
            return <area key={index} shape="rect"
                coords={room.Coords}
                onClick={this.click.bind(this, room, index)}
            />
        });
    }

    render() {
        const { selectedPlasticBin } = this.props;
        let src = null, className = '';

        if (selectedPlasticBin === 1) {
            src = RoomImg;
        }
        else if (selectedPlasticBin === 2) {
            src = MediumRoomImg;
            className = 'medium-room'
        }

        return (
            <div className={`room-image-wrapper ${className}`} ref={node => (this.container = node)}>
                <img ref={ref => this.image = ref} src={src} useMap={`#${this.props.name}`}
                    className="main-image" onLoad={this.initCanvas} />
                <canvas ref={ref => this.canvas = ref} className="image-canvas"></canvas>
                <map name={this.props.name} style={{cursor: 'pointer'}}>
                    { this.renderRoomsArea() }
                </map>
            </div>
        )
    }
}

export default RoomLocationMapper;
