import React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { CheckBoxComponent, RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import QueryString from 'query-string';
import { Redirect } from 'react-router-dom';

import NavigationPrompt from '../../components/NavigationPrompt';
import BaseComponent from '../../components/BaseComponent';
import AppContext from '../../context/AppContext';
import Breadcrumb from '../../components/Breadcrumb';
import MachineMapper from '../../components/MachineMapper';
import AssignedProducts from './AssignedProducts';
import AssignProductToLocation from './AssignProductToLocation';
import AddRemoveMoveModule from './AddRemoveMoveModule';
import ConfigureModule from './ConfigureModule';
import Axios from 'axios';

import { getMachineImage, predefinedModules } from './PredefinedModules';

import StockService from '../../services/Stock/StockService';
import MachineService from '../../services/Machine/MachineService';
import CustomerService from '../../services/Customer/CustomerService';
import AuthenticationService from '../../services/AuthenticationService';
import SessionService from '../../services/SessionService';
import messageHelper from '../../utilities/MessageHelper';

import '../../styles/machine.scss';
import Loading from '../../components/Loading';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class Machine extends BaseComponent {
    moduleName = "Automater";
    pageName = "Automater";
    IS_ASSIGNING_PRODUCTS_KEY = "IS_ASSIGNING_PRODUCTS";

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            moduleState: 'add',
            data: {
                Id: null,
                Modules: [],
                MachineName: '',
                MachineType: 0,
                CameraSetting: 0,
                DepartmentId: 0,
                PrimaryContactPerson: [],
                linkedProducts: [],
                Active: false,
                CameraWhenPullingProduct: false,
                CameraByLogin: false,
                SerialNumber: '',
                SyncNumber: ''
            },
            originalDeptId: 0,
            tempModuleData: {
                Modules: [],
                DeletedModuleIds: []
            },
            selectedModule: -1,
            selectedTab: 0,
            tabs: [
                { id: 0, name: 'mainModule', breadcrumbItem: 'Konfigurer moduler Main' },
                { id: 1, name: 'crudMachine', breadcrumbItem: 'Konfigurer moduler Main' },
                { id: 2, name: 'configureModule', breadcrumbItem: 'Konfigurer moduler Main' },
                { id: 3, name: 'linkProductToMachine', breadcrumbItem: 'Tildel produkter til maskine' },
                { id: 4, name: 'productListLink', breadcrumbItem: 'Produktliste for tildeling af produkt til Automat' }
            ],
            breadcrumbs: [{ text: 'Automatliste', link: '/automatliste' }, 'Konfigurer moduler Main'],
            header: '',
            showAddProduct: false,
            forceSaveAssignedProducts: false,
            redirect: {
                to: '',
                isRedirect: false
            },
            hasUnsavedChanges: false
        }

        this.departments = [];
        this.contactPersons = [];
        this.isSuperAdmin = false;

        this.init = this.init.bind(this);
        this.mainModulePane = this.mainModulePane.bind(this);
        this.crudModulePane = this.crudModulePane.bind(this);
        this.configureModule = this.configureModule.bind(this);
        this.assignProduct = this.assignProduct.bind(this);
        this.onSaveMachineOtherSettings = this.onSaveMachineOtherSettings.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.onMachineTypeChange = this.onMachineTypeChange.bind(this);
        this.onCameraSettingsChange = this.onCameraSettingsChange.bind(this);
        this.onAddPrimaryContact = this.onAddPrimaryContact.bind(this);
        this.onDeletePrimaryContact = this.onDeletePrimaryContact.bind(this);
        this.onBack = this.onBack.bind(this);
        this.dialogOpen = this.dialogOpen.bind(this);
        this.assignProductToLocation = this.assignProductToLocation.bind(this);
        this.onNavigate = this.onNavigate.bind(this);
        this.onSelectModule = this.onSelectModule.bind(this);
        this.onUpdateMachineConfiguration = this.onUpdateMachineConfiguration.bind(this);
        this.onChangeActiveStatus = this.onChangeActiveStatus.bind(this);
        this.onPreSaveMachineSettings = this.onPreSaveMachineSettings.bind(this);
        this.onSerialNumberChange = this.onSerialNumberChange.bind(this);
        this.onSerialNumberFocus = this.onSerialNumberFocus.bind(this);
        this.onSerialNumberBlur = this.onSerialNumberBlur.bind(this);
        this.refreshMachine = this.refreshMachine.bind(this);

        this.onPrimaryContactFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('Name', 'startswith', e.text, true) : query;
            e.updateData(this.departmentPrimaryContacts, query)
        }

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.notificationButtons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];

        this.buttons = [
            {
                // Click the footer buttons to hide the Dialog
                click: async () => {
                    let { data } = this.state;
                    if (data.Id) {
                        const result = await MachineService.GetMachine(data.Id, source.token);
                        data.MachineName = result.CustomName;
                        data.DepartmentId = result.Department.Id;
                        data.PrimaryContactPerson = result.PrimaryContacts.map(x => x.Id);
                        data.MachineType = result.MachineType;
                        data.CameraWhenPullingProduct = result.CameraWhenPullingProduct;
                        data.CameraByLogin = result.CameraByLogin;
                        data.SerialNumber = result.SerialNumber;
                        let prim = this.contactPersons.filter(p => p.DeptId === result.Department.Id);
                        if (prim.length > 0) {
                            this.departmentPrimaryContacts = prim;
                        }
                        else {
                            this.departmentPrimaryContacts = [];
                        }
                        this.setState({ data }, () => {
                            this.otherSettingsDialog.hide();
                        });
                    }
                    else {
                        this.otherSettingsDialog.hide();
                    }
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    //Enables the primary button
                    content: 'Tilbage'
                }
            },
            {
                // Click the footer buttons to hide the Dialog
                click: () => this.onPreSaveMachineSettings(),
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    //Enables the primary button
                    isPrimary: true,
                    content: 'Næste'
                }
            }
        ];
        
        this.changeDepartmentButtons = [
            {
                // Click the footer buttons to hide the Dialog
                click: () => {                    
                    this.onSaveMachineOtherSettings();
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    //Enables the primary button
                    isPrimary: true,
                    content: 'Ændre'
                }
            },
            {
                // Click the footer buttons to hide the Dialog
                click: () => {
                    this.refreshMachine();
                    this.changeDepartmentDialog.hide();
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    //Enables the primary button
                    content: 'OK'
                }
            }
        ];

        this.addProductsButtons = [
            {
                // Click the footer buttons to hide the Dialog
                click: () => {                    
                    this.notificationDialog.hide();
                    this.notificationDialog.buttons = this.buttons;
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    //Enables the primary button
                    content: 'Tilbage'
                }
            },
            {
                // Click the footer buttons to hide the Dialog
                click: () => {
                    this.notificationDialog.buttons = this.buttons;
                    this.notificationDialog.hide();
                    this.setState({ showAddProduct: true });
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    //Enables the primary button
                    isPrimary: true,
                    content: 'Næste'
                }
            }
        ]

        /* End Dialog options  */
    }

    async componentDidMount() {
        super.componentDidMount();
        source.cancel();
        source = CancelToken.source();

        if (!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }

        this.init();
        
        const { match, location } = this.props;
        let { moduleState, selectedTab, data, originalDeptId, assignProductLocation, selectedModule } = this.state;
        const machineId = match && match.params && match.params.id;

        if (machineId) {
            moduleState = 'edit';
            const machineData = await MachineService.GetMachine(machineId, source.token);
            if (machineData.HasError) {
                let result = super.processModuleResponse(machineData);
                this.setState(result);
                return;
            }

            Object.assign(data, machineData);
            data.DepartmentId = machineData.Department.Id;
            data.PrimaryContactPerson = machineData.PrimaryContacts.map(x => x.Id);
            data.MachineName = machineData.CustomName;

            data.Modules = data.Modules.map(m => {
                const predefinedModule = predefinedModules.find(x=> x.name === m.Name);
                if (predefinedModule) {
                    m.Shelves = m.Shelves.map(s => {
                        const shelf = predefinedModule.Shelves.find(a => a.SlotNo === s.SlotNo);
                        s.IsEditable = shelf.IsEditable;
                        return {...s}
                    });
                }
                
                return { ...m }
            });
            
            if (location.search) {
                let stockId = 0;
                let queryString = location.search.replace('?', '').split('&');
                for (let index = 0; index < queryString.length; index++) {
                    let val = QueryString.parse(queryString[index]);
                    if ('tab' in val) {
                        selectedTab = +val.tab;
                    }
                    else if ('module' in val) {
                        selectedModule = +val.module;
                    }
                    else if ('sid' in val) {
                        stockId = +val.sid;
                    }
                }

                let accessibleTabs = super.getTabs();
                if (accessibleTabs) {
                    if (selectedTab === 1 && !super.hasTabFunction("Redigere", "Tilføj/Fjern/Flyt Moduler")) {
                        this.props.history.replace(`/automater/configurer/edit/${data.Id}`);
                        selectedTab = 0;
                    }
                    else if (selectedTab === 2 && !super.hasTabFunction("Redigere", "Konfigurer modul")) {
                        this.props.history.replace(`/automater/configurer/edit/${data.Id}`);
                        selectedTab = 0;
                    }
                    else if (selectedTab === 3 && !super.hasTabFunction("Redigere", "Tildel produkter")) {
                        this.props.history.replace(`/automater/configurer/edit/${data.Id}`);
                        selectedTab = 0;
                    }
                    else if (selectedTab === 5 && !super.hasTabFunction("Redigere", "Øvrige indstillinger")) {
                        this.props.history.replace(`/automater/configurer/edit/${data.Id}`);
                        selectedTab = 0;
                    }
                }

                if (selectedTab > 4 || selectedTab < 0) {
                    this.props.history.replace(`/automater/configurer/edit/${data.Id}`);
                    selectedTab = 0;
                }
                if (selectedTab === 2) {
                    if (selectedModule === -1) {
                        this.props.history.replace(`/automater/configurer/edit/${data.Id}`);
                        selectedTab = 0;
                    }
                }
                
                if (selectedTab === 4) {
                    if (stockId) {
                        const selectedProduct = await StockService.GetStock(stockId, source.token);

                        if (!selectedProduct.HasError) {
                            assignProductLocation = selectedProduct;
                        }
                    }
                    else {
                        this.props.history.replace(`/automater/configurer/edit/${data.Id}`);
                        selectedTab = 0;
                    }
                }
            }
            else {
                selectedTab = 0;
            }

            originalDeptId = data.DepartmentId;
        }
        else {
            selectedTab = 0;
            data.MachineType = 2;
        }

        const session = await SessionService.GetSessionInfo();

        if (session.userType === 'customer' && session.CustomerGroup.Id === 1) {
            this.isSuperAdmin = true;
        }

        const departmentResult = await CustomerService.GetAccountDepartments(session.Id, source.token);
        this.departments = departmentResult;
        
        let users = await CustomerService.GetUsers(session.Id, source.token);
        let contactPersons = users.map(p => {
            let fullName = p.Account.FullName.toLowerCase();
            fullName = fullName.charAt(0).toUpperCase() + fullName.slice(1);
            return {
                Id: p.Id,
                Name: fullName,
                category: p.PrimaryContact ? 'Primærer Kontakt' : 'Other Users',
                DeptId: p.Department.Id
            }
        });
        contactPersons.sort((a,b) => (a.Name > b.Name) ? 1 : -1)
        this.contactPersons = contactPersons;

        if (machineId) {
            this.departmentPrimaryContacts = contactPersons.filter(x => x.DeptId === data.Department.Id);
        }

        this.setState({ loading: false, moduleState, selectedTab, data, originalDeptId, assignProductLocation, selectedModule }, () => {
            setTimeout(() => {
                if (!data.Id) {
                    this.otherSettingsDialog.show();
                }
                else if (selectedTab === 5 && super.hasTabFunction("Redigere", "Øvrige indstillinger")) {
                    this.otherSettingsDialog.show();
                }
                this.resizeMachines();
            }, 300);
        });
    }

    componentWillUnmount() {
        source.cancel();
    }

    init() {

    }

    async onSaveMachineOtherSettings() {
        source.cancel();
        source = CancelToken.source();
        const { data } = this.state;

        const sessionInfo = await SessionService.GetSessionInfo();

        if (!this.isSuperAdmin) {
            data.MachineType = 2;
        }

        let serialNumber = data.SerialNumber.split('-');

        
        const payload = {
            CustomName: data.MachineName,
            DeptId: data.DepartmentId,
            PrimaryContacts: data.PrimaryContactPerson,
            MachineType: data.MachineType,
            CameraWhenPullingProduct: data.CameraWhenPullingProduct,
            CameraByLogin: data.CameraByLogin,
            Id: data.Id,
            EditableSerialNumber: serialNumber[0],
            CustomerId: parseInt(sessionInfo.Id)
        };

        const result = await MachineService.PostMachineSettings(payload, source.token);
        //Automat er nu oprettet
        if (!result.HasError) {
            this.dialogOpen("Udført", "Indstillinger er opdateret.");
            if (data.Id === null) {
                // Object.assign(data, result);
                data.Id = result.Id;
                data.Customname = result.CustomName;
                data.Department = result.Department;
                data.SyncNumber = result.SyncNumber;
                data.SerialNumber = data.SerialNumber + '-' + data.SyncNumber;
                this.setState({ data, originalDeptId: result.Department.Id }, () => {
                    this.props.history.push(`/automater/configurer/edit/${result.Id}`);
                });
            }
            else {
                // Object.assign(data, result);
                data.Customname = result.CustomName;
                data.SyncNumber = result.SyncNumber;
                data.SerialNumber = data.SerialNumber + '-' + data.SyncNumber;
                this.setState({ data, originalDeptId: result.Department.Id });
            }
            this.changeDepartmentDialog.hide();
            this.otherSettingsDialog.hide();
        }
        else {
            this.dialogOpen("Advarsel", result.ErrorMessage);
        }
    }

    async onPreSaveMachineSettings() {
        const { data, originalDeptId } = this.state;

        let hasError = false;
        // let primaryContacts = [];

        if (!data.MachineName) {
            hasError = true;
            this.MachineNameRef.element.parentElement.classList.add('e-error');
        }
        else { this.MachineNameRef.element.parentElement.classList.remove('e-error'); }

        if (!data.DepartmentId) {
            hasError = true;
            this.DepartmentRef.element.parentElement.classList.add('e-error');
        }
        else { this.DepartmentRef.element.parentElement.classList.remove('e-error'); }

        if (this.isSuperAdmin) { 
            if (data.MachineType === 0) {
                hasError = true;
            }
        }

        if (data.PrimaryContactPerson.length <= 0) {
            hasError = true;
        }

        if (hasError) {
            this.dialogOpen("Advarsel", "Du mangler at udfylde felter, makeret med rødt.");
            return;
        }

        if (data.Id && data.DepartmentId !== originalDeptId) {
            let content = 'Du er ved at ændre afdeling på denne automat<br>';
            content += '<ul><li>Ønsker du at Fortryde tryk OK</li><li>Ønsker du at ændre afdeling tryk Ændre</li></ul>'
            this.changeDepartmentDialog.content = content;
            this.changeDepartmentDialog.show();
            return;
        }
        else {
            await this.onSaveMachineOtherSettings();
        }
    }

    async refreshMachine() {
        let { data } = this.state;
        const machineData = await MachineService.GetMachine(data.Id, source.token);
        Object.assign(data, machineData);
        data.DepartmentId = machineData.Department.Id;
        data.PrimaryContactPerson = machineData.PrimaryContacts.map(x => x.Id);
        data.MachineName = machineData.CustomName;

        data.Modules = data.Modules.map(m => {
            const predefinedModule = predefinedModules.find(x=> x.name === m.Name);
            if (predefinedModule) {
                m.Shelves = m.Shelves.map(s => {
                    const shelf = predefinedModule.Shelves.find(a => a.SlotNo === s.SlotNo);
                    s.IsEditable = shelf.IsEditable;
                    return {...s}
                });
            }
            
            return { ...m }
        });

        let prim = this.contactPersons.filter(p => p.DeptId === machineData.Department.Id);
        
        if (prim.length > 0) {
            this.departmentPrimaryContacts = prim;
        }
        else {
            this.departmentPrimaryContacts = [];
        }

        this.setState({ data });
    }

    onSelectModule(id, args) {
        let { selectedModule } = this.state;
        if (selectedModule === id) {
            selectedModule = -1;
        }
        else {
            selectedModule = id;
        }
        
        this.setState({ selectedModule });
    }

    onButtonClick(args) {
        const { data, selectedModule } = this.state;
        if (args.target.id === 'btnCrud') {
            if (!data.Id) {
                this.dialogOpen("Advarsel", "Du skal konfigurer automaten, før du kan tilføje/ændre moduler.");
                return;
            }
            this.props.history.push(`/automater/configurer/edit/${data.Id}?tab=1`);
            this.setState({ selectedTab: 1 });
        }
        else if (args.target.id === 'btnConfigureModule') {
            if (data.Modules.length <= 0) {
                this.dialogOpen("Advarsel", "Du skal konfigurer automaten, før du kan konfigurer et modul.");
                return;
            }

            const { selectedModule } = this.state;
            if (selectedModule === -1) {
                this.dialogOpen("Advarsel", "Vælg venligst et modul du vil konfigurere.");
                return;
            }
            
            this.props.history.push({
                pathname: `/automater/configurer/edit/${data.Id}?tab=2&module=${selectedModule}`,
                // state: {
                //     selectedModule
                // }
            });
            this.setState({ selectedTab: 2 });
        }
        else if (args.target.id === 'btnAssignProduct') {
            if (data.Modules.length <= 0) {
                this.dialogOpen("Advarsel", "Der kan ikke tilføjes produkt, da der ikke er konfigureret en Automat endnu.");
                return;
            }
            
            this.props.history.push(`/automater/configurer/edit/${data.Id}?tab=3`);
            this.setState({ selectedTab: 3 });
        }
        else if (args.target.id === 'btnOtherSettings') {
            this.otherSettingsDialog.show();
        }
    }

    onChangeActiveStatus(args) {
        let { data } = this.state;
        data.Active = args.checked;
        this.setState({ data });
    }

    mainModulePane() {
        let { data, selectedModule } = this.state;

        return (
            <div className="machine-content-pane">
                <div className="machine-module-controls">
                    <ul>
                        {
                            super.hasTabFunction("Redigere", "Tilføj/Fjern/Flyt Moduler") &&
                            <li>
                                <button id="btnCrud" className="e-control e-btn" onClick={this.onButtonClick}>Tilføj / Fjern / Flyt Moduler</button>
                            </li>
                        }
                        {
                            super.hasTabFunction("Redigere", "Konfigurer modul") &&
                            <li>
                                <button id="btnConfigureModule" className="e-control e-btn" onClick={this.onButtonClick}>Konfigurer modul</button>
                            </li>
                        }
                        {
                            super.hasTabFunction("Redigere", "Tildel produkter") &&
                            <li>
                                <button id="btnAssignProduct" className="e-control e-btn" onClick={this.onButtonClick}>Tildel produkter</button>
                            </li>
                        }
                        {
                            super.hasTabFunction("Redigere", "Øvrige indstillinger") &&
                            <li>
                                <button id="btnOtherSettings" className="e-control e-btn" onClick={this.onButtonClick}>Øvrige indstillinger</button>
                            </li>
                        }
                        <li>
                            <div className="field-bordered">
                                <label htmlFor="activateMachine">Aktiver automat til drift</label>
                                <CheckBoxComponent id="activateMachine" checked={data.Active} name="inactive" cssClass="control-right" change={this.onChangeActiveStatus} />
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="machine-module">
                    <ul className="c-ul">
                        {
                            data.Modules.map((module, index) => {
                                let machineImageSrc = getMachineImage(module.Name);

                                return (
                                    <li className="machine-wrapper" key={`${module.Name}_${module.Id}_${index}`}>
                                        <div className="machine-selection-wrapper">
                                            <CheckBoxComponent label={module.Name} checked={selectedModule === module.Id} name="MachineType" change={this.onSelectModule.bind(this, module.Id)} />
                                        </div>
                                        <MachineMapper src={machineImageSrc} shelves={module.Shelves} width={151} active={false} isRemoving={false} />
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        )
    }

    crudModulePane() {
        const { data } = this.state;
        return <AddRemoveMoveModule data={data} onUpdate={this.onUpdateMachineConfiguration} onNotify={this.dialogOpen} onSelectModule={this.onSelectModule} />
    }

    configureModule() {
        const { data, selectedModule, tempModuleData } = this.state;
        // let theData = null;
        // if (data.Modules) {
        //     theData = data.Modules;
        // }
        // else if (tempModuleData.Modules) {
        //     theData = tempModuleData.Modules;
        // }
        return <ConfigureModule data={data} selectedModule={selectedModule} onUpdate={this.onUpdateMachineConfiguration} onNotify={this.dialogOpen} />
    }

    onUpdateMachineConfiguration(value, deletedModule = null) {
        let { tempModuleData, data } = this.state;
        tempModuleData.Modules = value;
        
        if (deletedModule && deletedModule.index > -1) {
            let module = data.Modules[deletedModule.index];
            if (module) {
                if (module.Id === deletedModule.moduleId) {
                    tempModuleData.DeletedModuleIds.push(module.Id);
                }
                else {
                    module = data.Modules.find(m => m.Id === deletedModule.moduleId);
                    if (module) {
                        tempModuleData.DeletedModuleIds.push(module.Id);
                    }
                }
            }
        }
        
        this.setState({ tempModuleData, hasUnsavedChanges: true });
    }

    assignProduct() {
        const { data, showAddProduct, forceSaveAssignedProducts } = this.state;
        
        let machineNames = data.Modules.map(mod => mod.Name);
        let header = '', addntlCssClass = '';
        header = `Produkt i Automat - ${machineNames.join(' : ')} - ${data.Department.Name}`;
        if (header.length > 126) {
            addntlCssClass = 'is-title-long';
        }
        header = `<label class="label">${header}</label>`;

        return (
            // <div className="assign-product-pane">
                <AssignedProducts machineId={data.Id} showAddProduct={showAddProduct} onNotify={this.dialogOpen} onUpdate={this.onUpdateMachineConfiguration} forceUpdate={forceSaveAssignedProducts} onAssignProductLocation={(product) => this.onNavigate(4, product)} 
                    onNavigate={() => this.onNavigate(0, null)} header={header} headerCssClass={addntlCssClass} />
            // </div>
        )
    }

    assignProductToLocation() {
        const { data, assignProductLocation } = this.state;
        return <AssignProductToLocation data={data} productData={assignProductLocation} onUpdate={this.onUpdateMachineConfiguration} onNotify={this.dialogOpen} onNavigate={() => this.onNavigate(3, null)} refreshMachine={this.refreshMachine} />
    }

    async onNavigate(tab, product) {
        let { assignProductLocation, data } = this.state;
        if (product) {
            assignProductLocation = product;
        }

        if (tab === 3 || tab === 4) {
            await this.refreshMachine();
        }

        switch (tab) {
            case 0:
                this.props.history.push(`/automater/configurer/edit/${data.Id}`);
                break;
            case 3:
                this.props.history.push(`/automater/configurer/edit/${data.Id}?tab=3`);
                break;
            case 4:
                this.props.history.push({ 
                    pathname: `/automater/configurer/edit/${data.Id}?tab=4&sid=${product.Id}`,
                    // state: {
                    //     productStockId: product.Id
                    // }
                });
                break;
        }
        this.setState({ selectedTab: tab, assignProductLocation }, () => {
            if (tab === 0) {
                this.resizeMachines();
            }
        });
    }

    onBack() {
        let { selectedTab, tempModuleData, data } = this.state;
        if (selectedTab !== 0) {
            selectedTab = 0;
            tempModuleData.Modules = [];
            switch (selectedTab) {
                case 0:
                    this.props.history.push(`/automater/configurer/edit/${data.Id}`);
                    break;
                case 3:
                    this.props.history.push(`/automater/configurer/edit/${data.Id}?tab=3`);
                    break;
            }
            
            this.setState({ selectedTab, hasUnsavedChanges: false });
        }
        else {
            this.props.history.push('/automatliste');
        }
    }

    async onFinish() {
        source.cancel();
        source = CancelToken.source();

        let { selectedTab, data, tempModuleData, selectedModule } = this.state;

        if (selectedTab === 1 || selectedTab === 2) {
            let prevDataModules = JSON.stringify(data.Modules);
            
            if (tempModuleData.Modules.length > 0) {
                data.Modules = tempModuleData.Modules;
            }

            let result = null;
            let payload = {};

            switch(selectedTab) {
                case 1:
                    for (let index = 0; index < data.Modules.length; index++) {
                        data.Modules[index].Order = index + 1;
                    }
            
                    if (data.Id) {
                        payload.Id = data.Id;
                    }

                    if (tempModuleData.DeletedModuleIds.length > 0) {
                        payload.DeletedModuleIds = tempModuleData.DeletedModuleIds;
                    }

                    payload.Modules = data.Modules;
                    result = await MachineService.PostMachineModules(payload, source.token);
                    break;
                case 2:
                    let module = data.Modules.find(x => x.Id === selectedModule);
            
                    if (data.Id) {
                        payload.MachineId = data.Id;
                    }
                    payload.Id = module.Id;
                    payload.ShelfSize = module.ShelfSize;
                    payload.Shelves = module.Shelves;
                    result = await MachineService.PostMachineModuleConfig(payload, source.token);
                    break;
            }

            if (!result.HasError) {
                if (selectedTab === 1) {
                    selectedTab = 0;
                }
                else if (selectedTab === 2) { 
                    selectedTab = 0;
                }

                if (result.Name) {
                    data.Name = result.Name;
                }
                
                data.Modules = result.Modules;
                data.Modules.sort((a,b) => (a.Order > b.Order) ? 1 : -1)

                tempModuleData.Modules = [];
                tempModuleData.DeletedModuleIds = [];

                this.setState({ selectedTab, tempModuleData, data, hasUnsavedChanges: false }, () => {
                    this.props.history.push(`/automater/configurer/edit/${data.Id}`);
                    this.resizeMachines();
                });
            }
            else {
                data.Modules = JSON.parse(prevDataModules);
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
        else if (selectedTab === 3) {
            // Validate if user has ongoing edit when button is clicked
            let isAssigningproducts = sessionStorage.getItem(this.IS_ASSIGNING_PRODUCTS_KEY);
            if (isAssigningproducts) {
                this.notificationDialog.header = "Advarsel";
                this.notificationDialog.content = messageHelper.MB1.message;
                let buttons = messageHelper.MB1.buttons;

                buttons[0].click = () => {
                    this.notificationDialog.button = this.notificationButtons;
                    this.notificationDialog.hide();
                }

                buttons[1].click = async () => {
                    this.setState({ forceSaveAssignedProducts: true });
                    // const result = await StockService.GetMachineStock(data.Id, source.token);
                    // if (result.length > 0) {
                    //     this.notificationDialog.button = this.notificationButtons;
                    //     this.notificationDialog.hide();
                    // }
                    // else {
                    //     this.notificationDialog.buttons = this.addProductsButtons;
                    //     this.dialogOpen("Du er endnu ikke tilføjet nogle produkter til Automaten", "Tryk på næste, for at tilføje produkter.");
                    // }
                }

                this.notificationDialog.buttons = buttons;
                this.notificationDialog.show();
                return;
            }

            const result = await StockService.GetMachineStock(data.Id, source.token);
            if (result.length > 0) {
                this.setState({ selectedTab: 0, forceSaveAssignedProducts: false });
            }
            else {
                this.notificationDialog.buttons = this.addProductsButtons;
                this.dialogOpen("Du er endnu ikke tilføjet nogle produkter til Automaten", "Tryk på næste, for at tilføje produkter.");
            }
        }
        else {
            const result = await MachineService.PostMachineMain(data, source.token);

            if (!result.HasError) {
                this.dialogOpen("Udført", "Automat konfigurationen er nu gemt.", () => {
                    this.notificationDialog.hide();
                    this.props.history.push('/automatliste');
                });
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
    }

    onChange(args) {
        let input = args.container.querySelector('input');

        if (input) {
            let { data } = this.state;
            let key = input.name;
            let value = args.value;
            data[key] = value;
            this.setState({ data });
        }
    }

    onDepartmentChange(args) {
        if (args.isInteracted) {
            let { data } = this.state;
            data.PrimaryContactPerson = [];
            data.DepartmentId = args.value;
    
            let prim = this.contactPersons.filter(p => p.DeptId === args.value);
            if (prim.length > 0) {
                this.departmentPrimaryContacts = prim;
            }
            else {
                this.departmentPrimaryContacts = [];
            }
            
            this.setState({ data });
        }
    }

    onPrimaryContactChange(index, args) {
        const { data } = this.state;
        data.PrimaryContactPerson[index] = args.value;
        this.setState({ data });
    }

    onMachineTypeChange(args) {
        const { data } = this.state;
        data[args.event.target.name] = args.value
        this.setState({ data });
    }

    onCameraSettingsChange(args) {
        const { data } = this.state;
        data[args.event.target.name] = args.checked;
        this.setState({ data });
    }

    onAddPrimaryContact() {
        const { data } = this.state;
        if (data.PrimaryContactPerson.length < 3) {
            data.PrimaryContactPerson.push({ id: 0 });
            this.setState({ data });
        }
    }

    onDeletePrimaryContact(index) {
        const { data } = this.state;
        data.PrimaryContactPerson.splice(index, 1);
        this.setState({ data });
    }

    resizeMachines() {
        let moduleList = document.querySelector('.machine-module ul.c-ul');
        if (moduleList) {
            if (moduleList.clientWidth < moduleList.scrollWidth) {
                moduleList.style.justifyContent = 'unset';
            }
            else {
                moduleList.style.justifyContent = 'center';
            }
        }
    }

    onSerialNumberChange(args) {
        let { data } = this.state;
        data.SerialNumber = args.target.value;
        
        // if (!args.target.value.match(/^[a-z0-9]$/i)) {
        //     return false;
        // }
        this.setState({ data });
    }

    onSerialNumberFocus(args) {
        let value = args.target.value;
        this.SerialNumberRef.value = value.split('-')[0];
    }

    onSerialNumberBlur(args) {
        const { data } = this.state;
        let value = args.target.value;
        this.SerialNumberRef.value = `${value}-${data.SyncNumber}`;
    }

    dialogOpen(header, content, callback = null) {
        this.notificationDialog.header = header;
        this.notificationDialog.content = content;

        if (callback) {
            this.notificationDialog.buttons = [{
                // Click the footer buttons to hide the Dialog
                click: () => {
                    this.notificationDialog.hide();
                    callback();
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    //Enables the primary button
                    isPrimary: true,
                    content: 'OK'
                }
            }];
        }

        this.notificationDialog.show();
    }

    render() {
        let { loading, data, breadcrumbs, tabs, selectedTab, redirect, hasUnsavedChanges } = this.state;

        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }
        
        let finishButtonName = 'Udført';
        let header = '', addntlCssClass = '';

        if (selectedTab === 0) {
            if (data.Name && data.Department) {
                header = `${data.Name} / ${data.Department.Name}`;
                if (header.length > 126) {
                    addntlCssClass = 'is-title-long';
                }
            }
            else {
                header = 'Vælg Automat du vil redigere';
            }
        }
        else if (selectedTab === 1) {
            finishButtonName = 'Udført';
            header = '<label class="label">Når du har valgt de ønskede moduler og placeret dem i den ønskede rækkefølge. <br/>Tryk venligst næste for at gøre konfigurationen færdig</label>';
        }
        else if (selectedTab === 2) {
            header = '<div class="text-center"><ul class="c-ul text-center" style="font-size: 13px; font-weight: 500;"><li>• Tryk tilføj ud for det element du ønsker at tilføje, vælg derefter en af de markeret positioner i modulet</li><li>• Tryk fjern element, vælg derefter et af de markeret elementer i modulet</li></ul></div>'
        }
        // else if (selectedTab === 3) {
        //     let machineNames = data.Modules.map(mod => mod.Name);
        //     header = `Produkt i Automat - ${machineNames.join(' : ')} - ${data.Department.Name}`;
        //     if (header.length > 126) {
        //         addntlCssClass = 'is-title-long';
        //     }
        //     header = `<label class="label">${header}</label>`
        // }

        return (
            <div className="content-pane-wrapper">
                <NavigationPrompt when={hasUnsavedChanges} 
                    navigate={path => this.props.history.push(path)}
                    initiateSave={() => this.onFinish()}
                    shouldBlockNavigation={location => {
                        if (hasUnsavedChanges) {
                            return true;
                        }
                        return false;
                    }}
                />
                <div><Breadcrumb breadcrumbs={breadcrumbs} /></div>
                <div className="module-header">
                    <h1>{this.pageName}</h1>
                </div>
                { selectedTab === 4 && this.assignProductToLocation() }
                { selectedTab === 3 && this.assignProduct() }
                {
                    // selectedTab === 4  ? this.assignProductToLocation() && 
                    (selectedTab === 0 || selectedTab === 1 || selectedTab === 2) && 
                    <div className="machine-module-wrapper">
                        <div className="machine-module-header">
                            <div className="action-back">
                                <span className="clickable c-back-arrow" onClick={this.onBack}>Tilbage</span>
                            </div>
                            <div className={`machine-header ${addntlCssClass}`} dangerouslySetInnerHTML={{ __html: header }}>
                            </div>
                            <div className="action-finish">
                                <button className="e-control e-btn e-primary btn-finish" onClick={this.onFinish}>{finishButtonName}</button>
                            </div>
                        </div>
                        <div className="machine-module-content">
                            { selectedTab === 0 && this.mainModulePane() }
                            { selectedTab === 1 && this.crudModulePane() }
                            { selectedTab === 2 && this.configureModule() }
                            {/* { selectedTab === 3 && this.assignProduct() } */}
                        </div>
                    </div>
                }

                <div>
                    <DialogComponent ref={dialog => this.notificationDialog = dialog} id="machine-dialog" isModal={true} buttons={this.notificationButtons} width='auto' 
                        target='body' visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true}/>
                    <DialogComponent ref={dialog => this.otherSettingsDialog = dialog} id="machine-other-settings-dialog" isModal={true} buttons={this.buttons} header="Øvrige indstillinger" width='auto'
                        target='body' visible={false} showCloseIcon={true} animationSettings={this.animationSettings} allowDragging={true} enableResize={true}>
                            <div className="other-settings-wrapper">
                                <TextBoxComponent ref={ref => this.MachineNameRef = ref} value={data.MachineName} placeholder="Indtast brugerdefineret automatnavn" name="MachineName" change={this.onChange.bind(this)} floatLabelType="Auto" />
                            </div>
                            <div className="other-settings-wrapper">
                                <DropDownListComponent ref={ref => this.DepartmentRef = ref} id="ddlDepartment" name="DepartmentId" value={data.DepartmentId} dataSource={this.departments} 
                                    placeholder="Link automat til afdeling" fields={{ text: 'Name', value: 'Id' }} change={this.onDepartmentChange.bind(this)} floatLabelType="Auto" />
                            </div>
                            <div className="other-settings-wrapper">
                                <table>
                                    <thead>
                                        <tr>
                                            <th align='left'><label>Link automat til primær kontaktperson<span className="required">*</span></label></th>
                                            <th align='right'>
                                                <button className="machine-settings-add-primary clickable" onClick={this.onAddPrimaryContact} disabled={data.PrimaryContactPerson.length === 3}>
                                                    <i className="c-icon c-icon-add-black"></i><span>Ny</span>
                                                </button>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data.PrimaryContactPerson.map((prim, index) => {
                                                
                                                return (
                                                    <tr key={`primary-contact-${index}`}>
                                                        <td>
                                                            <DropDownListComponent value={prim} dataSource={this.departmentPrimaryContacts} fields={{ text: 'Name', value: 'Id', groupBy: 'category' }} placeholder="Vælg primærer kontakt" locale="da"
                                                                filtering={this.onPrimaryContactFiltering.bind(this)} allowFiltering={true} change={this.onPrimaryContactChange.bind(this, index)} floatLabelType="Auto" />
                                                        </td>
                                                        <td>
                                                            <span className="clickable" onClick={() => this.onDeletePrimaryContact(index)}><i className="c-icon c-icon-delete"></i></span>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            { 
                                super.hasTabFunction("Redigere", "Vælg type automat") && 
                                <div className="other-settings-wrapper">
                                    <label>Vælg type automat</label>
                                    <ul>
                                        <li><RadioButtonComponent label='Test automat' checked={data.MachineType === 1} value={1} name="MachineType" change={this.onMachineTypeChange} /></li>
                                        <li><RadioButtonComponent label='Kunde automat' checked={data.MachineType === 2} value={2} name="MachineType" change={this.onMachineTypeChange} /></li>
                                    </ul>
                                </div>
                            }
                            <div className="other-settings-wrapper">
                                <label>Brug kamera</label>
                                <ul>
                                    <li><CheckBoxComponent label='Ved træk af produkt' checked={data.CameraWhenPullingProduct} name="CameraWhenPullingProduct" change={this.onCameraSettingsChange} /></li>
                                    <li><CheckBoxComponent label='Ved login' checked={data.CameraByLogin} name="CameraByLogin" change={this.onCameraSettingsChange} /></li>
                                </ul>
                            </div>
                            <div className="other-settings-wrapper">
                                <label>Serie nummer</label>
                                <ul>
                                    <li>
                                        <input type="text" ref={ref => this.SerialNumberRef = ref} onChange={this.onSerialNumberChange} className="e-input" value={data.SerialNumber} onFocus={this.onSerialNumberFocus} 
                                            onBlur={this.onSerialNumberBlur} />
                                    </li>
                                </ul>
                            </div>
                    </DialogComponent>
                    <DialogComponent ref={dialog => this.changeDepartmentDialog = dialog} id="change-department-dialog" isModal={true} buttons={this.changeDepartmentButtons} width='auto' 
                        header="Advarsel" target='body' visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true}>
                    </DialogComponent>
                </div>
            </div>
        )
    }
}

Machine.contextType = AppContext;
export default Machine;
