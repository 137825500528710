import React from 'react';
import { FileManagerComponent, Inject, NavigationPane, DetailsView, Toolbar } from '@syncfusion/ej2-react-filemanager';
// import { PdfViewerComponent, Toolbar as PDFViewerToolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, 
//     ThumbnailView, Print, TextSelection, Annotation, TextSearch, Inject as PDFViewerInject } from '@syncfusion/ej2-react-pdfviewer';
import { Redirect } from 'react-router-dom';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import Axios from 'axios';

import BaseComponent from '../components/BaseComponent';
import AuthenticationService from '../services/AuthenticationService';
import FileService, { FileArchiveAPI } from '../services/File/FileService';

import '../styles/filearchive.scss';
import Loading from '../components/Loading';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class FileArchive extends BaseComponent {
    moduleName = "Fil Arkiv";
    pageName = "Fil Arkiv";

    constructor() {
        super();

        this.state = {
            loading: true,
            token: null,
            redirect: {
                to: '',
                isRedirect: false
            }
        }
        
        this.init = this.init.bind(this);
        this.beforeSend = this.beforeSend.bind(this);
        this.beforeImageLoad = this.beforeImageLoad.bind(this);
        this.onFileOpen = this.onFileOpen.bind(this);
    }
    
    async componentDidMount() {
        super.componentDidMount();

        if (!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }

        // this.init();
        
        const user = await AuthenticationService.getUser();

        if (user === null) {
            AuthenticationService.login();
        }

        source.cancel();
        source = CancelToken.source();

        this.setState({ loading: false, token: user.access_token });
    }

    componentWillUnmount() {
        source.cancel();
    }

    init() {
        this.toolbarSettings = {};
        this.toolbarSettings.items = [];
        this.fileManagerContextMenuSettings = {
            file: ['Open'], folder: ['Open'],
            layout: ['SortBy', 'View', 'Refresh' ],
        };

        if (super.hasFunction("Ny mappe")) {
            this.toolbarSettings.items.push('NewFolder');

            this.fileManagerContextMenuSettings.file.push('|');
            this.fileManagerContextMenuSettings.file.push('Delete');
            this.fileManagerContextMenuSettings.file.push('Rename');

            this.fileManagerContextMenuSettings.folder.push('|');
            this.fileManagerContextMenuSettings.folder.push('Delete');
            this.fileManagerContextMenuSettings.folder.push('Rename');

            this.fileManagerContextMenuSettings.layout.push('|');
            this.fileManagerContextMenuSettings.layout.push('NewFolder');
        }
        
        this.fileManagerContextMenuSettings.file.push('|');
        this.fileManagerContextMenuSettings.file.push('Details');

        this.fileManagerContextMenuSettings.folder.push('|');
        this.fileManagerContextMenuSettings.folder.push('Details');

        if (super.hasFunction("Upload")) {
            this.toolbarSettings.items.push('Upload');
            if (this.fileManagerContextMenuSettings.layout[this.fileManagerContextMenuSettings.layout.length - 1] !== "|") {
                this.fileManagerContextMenuSettings.layout.push('|');    
            }
            this.fileManagerContextMenuSettings.layout.push('Upload');
        }

        this.fileManagerContextMenuSettings.layout.push('|');
        this.fileManagerContextMenuSettings.layout.push('Details');
        this.fileManagerContextMenuSettings.layout.push('|');
        this.fileManagerContextMenuSettings.layout.push('SelectAll');

        if (super.hasFunction("Klip")) {
            this.toolbarSettings.items.push('Cut');
        }
        if (super.hasFunction("Kopiere")) {
            this.toolbarSettings.items.push('Copy');
            this.toolbarSettings.items.push('Paste');
        }
        if (super.hasFunction("Slet")) {
            this.toolbarSettings.items.push('Delete');
        }
        if (super.hasFunction("Download")) {
            this.toolbarSettings.items.push('Download');
        }
        if (super.hasFunction("Omdøb")) {
            this.toolbarSettings.items.push('Rename');
        }

        this.toolbarSettings.items.push('SortBy');
        this.toolbarSettings.items.push('Refresh');
        this.toolbarSettings.items.push('Selection');
        this.toolbarSettings.items.push('View');
        this.toolbarSettings.items.push('Details');
    }

    async beforeSend(args) {
        const { token } = this.state;
        args.ajaxSettings.beforeSend = function(e) {
            e.httpRequest.setRequestHeader("Authorization", `Bearer ${token}`);
        }
    }
    
    async beforeImageLoad(args) {
        const { token } = this.state;
        args.imageUrl += `&access_token=${token}`;
    }

    beforeDownload(args) {
        console.log(args);
        localStorage.setItem("FILE_ARCHIVE", JSON.stringify(args));
    }

    async onFileOpen(args) {
        let allowedExtensions = [".png", ".jpg", ".jpeg"]; // file extensions that can be opened thru FileManagerComponent
        let customOpenExtensions = [".pdf"]; // file extensions with custom open dialog/page, not handled by FileManagerComponent
        console.log(args);

        if (customOpenExtensions.some(x => x === args.fileDetails.type)) {
            console.log("Custom open pdf file.");
            const filePath = args.fileDetails.filterPath + args.fileDetails.name;
            const fileUrl = await FileService.GetFile(filePath, false, source.token);
            window.open(fileUrl, "theFrame");
            this.customFileViewDialogRef.header = args.fileDetails.name;
            this.customFileViewDialogRef.show();
        }
        else if (!allowedExtensions.some(x => x === args.fileDetails.type) && args.fileDetails.isFile) {
            this.fileManagerRef.downloadFiles([args.fileDetails.name]);
        }
    }

    render() {
        const { loading, token, redirect, pdfName } = this.state;

        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }
        {/* toolbarSettings={this.toolbarSettings} contextMenuSettings={this.fileManagerContextMenuSettings} */}
        return (
            <div className="content-pane-wrapper">
                <div className="module-header">
                    <h1>{this.pageName}</h1>
                </div>
                <FileManagerComponent id="file-archive" ref={ref => this.fileManagerRef = ref} height="calc(100vh - 200px)" ajaxSettings={{
                    url: `${FileArchiveAPI.Url}`,
                    downloadUrl: `${FileArchiveAPI.DownloadUrl}?access_token=${token}`,
                    uploadUrl: `${FileArchiveAPI.UploadUrl}`,
                    getImageUrl: `${FileArchiveAPI.GetImageUrl}`
                }} beforeSend={this.beforeSend} view={"Details"} beforeImageLoad={this.beforeImageLoad} beforeDownload={this.beforeDownload} locale="da"
                fileOpen={this.onFileOpen}> 
                    <Inject services={[ NavigationPane, DetailsView, Toolbar]} />
                </FileManagerComponent>
                <div>
                    <DialogComponent id="filearchive-pdf-viewer" ref={ref => this.customFileViewDialogRef = ref} isModal={true} target='body' visible={false} animationSettings={this.animationSettings} 
                        allowDragging={true} enableResize={true} showCloseIcon={true} width="90%" height="90%">
                        {/* <PdfViewerComponent documentPath="PDF_Succinctly.pdf" serviceUrl={FileArchiveAPI.ViewPdfUrl} style={{ 'height': '640px' }}>
                            <PDFViewerInject services={[PDFViewerToolbar, Magnification, Navigation, Annotation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch]}/>
                        </PdfViewerComponent> */}
                        <iframe name="theFrame"></iframe>
                    </DialogComponent>
                </div>
            </div>
        )
    }
}

export default FileArchive;
