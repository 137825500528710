import Transport from 'winston-transport';
import AuthenticationService from '../services/AuthenticationService';

import LogService from '../services/LogService';

class LogTransport extends Transport {
    log(info, callback) {
        setImmediate(() => {
            this.emit('logged', info);
        });

        AuthenticationService.getUser(user => {
            if (user !== null) {
                // Call API endpoint for logging
                LogService.log("", info);
            }
        });

        callback();
    }
}

const transport = new LogTransport();
export default transport;