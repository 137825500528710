import HttpClient from '../../utilities/HttpClient';

const API = {
    getOrders: `/order`,
    getOrder: '/order/{id}',
    getOrdersByCustomer: '/order/{customer_id}/byCustomer',
    getOrdersByCustomerOptimized: '/order/{customer_id}/byCustomer/optimized',
    postOrder: '/order',
    deleteOrder: '/order/{id}',
    putOrder: '/order/approve',
    putApproveOrder: '/order/approve',

    getOrderDetailList: '/order/{id}/details',
    getOrderDetail: '/orderdetail/{id}',
    deleteOrderDetail: '/orderdetail/{id}',
    postOrderDetail: '/orderdetail',

    getOrderSettings: '/settings/order',
    postOrderSettings: '/settings/order',

    postOrderActions: '/order/approve/{type}',
    postOilmatOrderActions: '/oilmatorder/process/{type}'
}

class OrderService {
    //#region Order API
    async GetOrders(cancelToken) {
        return HttpClient.Get(API.getOrders, null, cancelToken);
    }

    async GetOrder(id, cancelToken) {
        const url = API.getOrder.replace('{id}', id);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async GetOrdersByCustomer(id, cancelToken) {
        const url = API.getOrdersByCustomer.replace('{customer_id}', id);
        let result = await HttpClient.Get(url, null, cancelToken);

        if (!result.HasError) {
            result = result.map(p => {
                let orderBy = '';
                if (p.CreatedByAccount) {
                    orderBy = p.CreatedByAccount.FullName;
                }
                else if (p.Machine) {
                    orderBy = p.Machine.CustomName;
                }


                return {
                    ...p,
                    OrderBy: orderBy,
                    CreationDate: p.CreationDate ? new Date(p.CreationDate) : null,
                    OrderedDate: p.OrderedDate ? new Date(p.OrderedDate) : null,
                    ReceivedDate: p.ReceivedDate ? new Date(p.ReceivedDate) : null
                }
            })
        }

        return result;
    }

    async GetOrdersByCustomerOptimized(id, cancelToken) {
        const url = API.getOrdersByCustomerOptimized.replace('{customer_id}', id);
        let result = await HttpClient.Get(url, null, cancelToken);

        if (!result.HasError) {
            result = result.map(p => {
                let orderBy = '';
                if (p.CreatedByAccount) {
                    orderBy = p.CreatedByAccount.FullName;
                }
                else if (p.Machine) {
                    orderBy = p.Machine.CustomName;
                }


                return {
                    ...p,
                    OrderBy: orderBy,
                    CreationDate: p.CreationDate ? new Date(p.CreationDate) : null,
                    OrderedDate: p.OrderedDate ? new Date(p.OrderedDate) : null,
                    ReceivedDate: p.ReceivedDate ? new Date(p.ReceivedDate) : null
                }
            })
        }

        return result;
    }

    async PostOrder(data, cancelToken) {
        return await HttpClient.Post(API.postOrder, data, cancelToken);
    }

    async DeleteOrder(id, cancelToken) {
        const url = API.deleteOrder.replace('{id}', id);
        return await HttpClient.Delete(url, null, cancelToken);
    }

    async PutOrder(data, cancelToken) {
        return await HttpClient.Post(API.putOrder, data, cancelToken);
    }

    async ApproverOrder(data, cancelToken) {
        return await HttpClient.Put(API.putApproveOrder, data, cancelToken);
    }
    //#endregion

    //#region Order Detail API
    async GetOrderDetails(id, cancelToken) {
        const url = API.getOrderDetailList.replace('{id}', id);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async GetOrderDetail(id, cancelToken) {
        const url = API.getOrderDetail.replace('{id}', id);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async DeleteOrderDetail(id, cancelToken) {
        const url = API.deleteOrderDetail.replace('{id}', id);
        return await HttpClient.Delete(url, null, cancelToken);
    }

    async PostOrderDetail(data, cancelToken) {
        return await HttpClient.Post(API.postOrderDetail, data, cancelToken);
    }
    //#endregion

    //#region Order Settings
    async GetOrderSettings(cancelToken) {
        return await HttpClient.Get(API.getOrderSettings, null, cancelToken);
    }

    async PostOrderSettings(data, cancelToken) {
        return await HttpClient.Post(API.postOrderSettings, data, cancelToken);
    }
    //#endregion


    async OrderAction(type, data, cancelToken) {
        const url = API.postOrderActions.replace("{type}", type);
        return await HttpClient.Post(url, data, cancelToken, false, true);
    }

    async OilmatOrderAction(type, data, cancelToken) {
        const url = API.postOilmatOrderActions.replace("{type}", type);
        return await HttpClient.Post(url, data, cancelToken, false, true);
    }
}

const orderService = new OrderService();
export default orderService;