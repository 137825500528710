import React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Page, Sort, Toolbar, Selection, Search, Reorder, Resize } from '@syncfusion/ej2-react-grids';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import Axios from 'axios';
import BaseComponent from '../../components/BaseComponent';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { Redirect } from 'react-router-dom';

import Image from '../../components/Image';
import QueryString from 'query-string';
import productService from '../../services/Product/ProductService';
// import AuthenticationService from '../../services/AuthenticationService';
import Loading from '../../components/Loading';
import SettingsService from '../../services/SettingsService';
import Localization from '../../utilities/Localization';
import MessageHelper from '../../utilities/MessageHelper';
import SessionService from '../../services/SessionService';
import { buildGridSettings } from '../../utilities/Helper';

import placeHolderImg from '../../assets/placeholders/img_placeholder_108x80.jpg';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

class ProductList extends BaseComponent {
    moduleName = "Produkt";
    pageName = "Produktliste";
    gridSettingsKey = "ProductList";
    hasDraggedColumn = false;
    gridSettings = '';
    selectedProductStatus = null;

    constructor() {
        super();
        
        this.state = {
            loading: true,
            dialogOpts: {
                header: '',
                content: null,
                visible: false
            },
            redirect: {
                to: '',
                isRedirect: false
            },
            status: null
        }

        this.pageSettings = {
            pageSize: 15,
            pageSizes: ["Alle", "5", "10", "20", "50", "100"]
        };

        this.data = [];

        this.selectionSettings = {
            type: 'Single'
        }

        this.filterSettings = {
            type: 'Menu'
        };

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.buttons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];

        this.confirmDeleteButtons = [
            {
                // Click the footer buttons to hide the Dialog
                click: () => {
                    this.confirmDeleteDialog.hide();
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    content: 'Annuller'
                }
            },
            {
                // Click the footer buttons to hide the Dialog
                click: () => {
                    
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    isPrimary: true,
                    content: 'OK'
                }
            }
        ];
        /* End Dialog options  */

        this.refreshGrid = this.refreshGrid.bind(this);
        this.dialogOpen = this.dialogOpen.bind(this);
        this.openProductTemplate = this.openProductTemplate.bind(this);
        this.productStatusTemplate = this.productStatusTemplate.bind(this);
        this.init = this.init.bind(this);
    }

    async componentDidMount() {
        super.componentDidMount();
        source.cancel();
        source = CancelToken.source();

        if (!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }
        else if (!super.hasMenuItem('Produktliste')) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }

        this.init();

        const sessionInfo = await SessionService.GetSessionInfo();
        let active = null;

        const gridSettings = await SettingsService.GetGridSettings(this.gridSettingsKey, source.token);
        if (!gridSettings.HasError) {
            this.gridSettings = gridSettings.Settings;

            let settings = JSON.parse(gridSettings.Settings);
            if ('IsActiveProduct' in settings) {
                if (settings.IsActiveProduct === 1) {
                    active = true;
                }
                else if (settings.IsActiveProduct === 2) {
                    active = false;
                }
                this.selectedProductStatus = settings.IsActiveProduct;
            }
        }

        const products = await productService.GetProducts(sessionInfo.Id, { active }, source.token);
        this.data = products;

        this.setState({ loading: false }, () => {
            setTimeout(() => {
                const { location } = this.props;
                if (location.search) {
                    let status = '';
                    let queryString = location.search.substring(1).split('&');
                    for (let index = 0; index < queryString.length; index++) {
                        let val = QueryString.parse(queryString[index].toLowerCase());
                        if ('status' in val) {
                            status = val.status;
                        }
                    }

                    if (status) {
                        this.productGridRef.clearFiltering();
                        this.productGridRef.filterByColumn("Status", "contains", status);
                        this.setState({ status });
                    }
                }
                
                window.addEventListener('wheel', this.onWheelScroll);
            }, 300);
        });
    }

    onWheelScroll() {
        let grid = document.getElementById('product-grid-wrapper');
        let toolbar = document.querySelector('#product-grid-wrapper.e-grid .e-toolbar');
        let tableHeader = document.querySelector('#product-grid-wrapper .e-gridheader');

        if (toolbar && tableHeader) {
            let boundingRect = tableHeader.getBoundingClientRect();
            
            if (boundingRect.top < 60) {
                toolbar.classList.add('c-hover-grid-toolbar');
                toolbar.style.width = (grid.clientWidth - 5) + 'px';
            }
            else {
                toolbar.classList.remove('c-hover-grid-toolbar');
                toolbar.style.width = 'auto';
            }
        }
    }

    componentWillUnmount() {
        source.cancel();
        window.removeEventListener('wheel', this.onWheelScroll);
    }

    init() {
        this.toolbarOptions = [];
        this.defaultEditSettings = {};

        if (super.hasMenuItemFunction("Produktliste", "Ny")) {
            this.toolbarOptions.push({ text: 'Ny', tooltipText: 'Ny', id: 'add', prefixIcon: 'e-add' });
        }
        if (super.hasMenuItemFunction("Produktliste", "Åben")) {
            this.toolbarOptions.push({ text: 'Åben', tooltipText: 'Åben', id: 'open', prefixIcon: 'e-external-link' });
        }
        if (super.hasMenuItemFunction("Produktliste", "Slette")) {
            this.toolbarOptions.push({text: 'Slette', tooltipText: 'Slette', id: 'delete', prefixIcon: 'e-delete'});
            this.defaultEditSettings = {
                allowDeleting: true,
                showDeleteConfirmDialog: true,
            };
        }

        this.toolbarOptions.push({ text: Localization.General.GridResetFilter, tooltipText: Localization.General.GridResetFilter, id: 'reset_filter', prefixIcon: 'e-cancel' });
    
        this.toolbarOptions.push({ template: this.productStatusTemplate, align: "Right" });
    }

    productStatusTemplate = () => {
        const items = [
            { item: 'Alle', value: 0 },
            { item: 'Aktiv produkt', value: 1 },
            { item: 'Inaktiv produkt', value: 2 }
        ];
        
        const self = this;
        const onChange = async (args) => {
            if (self.gridSettings) {
                let settings = {};
                try {
                    settings = JSON.parse(self.gridSettings);
                } catch (error) {
                    settings = self.gridSettings;
                }
                settings.IsActiveProduct = args.itemData.value;
                self.gridSettings = settings;
                const payload = {
                    Key: self.gridSettingsKey,
                    Settings: JSON.stringify(self.gridSettings)
                };

                self.selectedProductStatus = args.itemData.value;

                const result = await SettingsService.SaveGridSettings(payload, source.token);
            }

            self.refreshGrid(args.itemData.value);
        }
        
        return (<DropDownListComponent id="ddlProductStatus" cssClass="customer-status-dropdown" dataSource={items} placeholder="Vælg" value={this.selectedProductStatus} fields= {{ text: 'item', value: 'value' }} change={onChange} />)
    }

    async refreshGrid(status) {
        source.cancel();
        source = CancelToken.source();
        const sessionInfo = await SessionService.GetSessionInfo();

        let active = null;
        if (status === 1) {
            active = true;
        }
        else if (status === 2) {
            active = false;
        }

        const products = await productService.GetProducts(sessionInfo.Id, { active: active }, source.token);
        this.productGridRef.dataSource = products;
        this.productGridRef.dataBind();
    }

    async clickHandler(args) {
        if (this.productGridRef && args.item.id === 'add') {
            this.props.history.push('/produktkort/add');
        }
        else if (this.productGridRef && args.item.id === 'open') {
            const selectedRows = this.productGridRef.getSelectedRecords();
            if (selectedRows.length <= 0) {
                this.dialogOpen("Advarsel", Localization.General.NoRowSelected);
            }
            else {
                this.props.history.push(`/produktkort/edit/${selectedRows[0].Id}`);
            }
        }
        else if (args.item.id === 'delete') {
            const selectedRows = this.productGridRef.getSelectedRecords();
            if (selectedRows.length <= 0) {
                this.dialogOpen("Advarsel", Localization.General.NoRowSelected);
            }
            else {
                let buttons = null;
                let selectedProduct = selectedRows[0];

                if (selectedProduct.ProductStorages && selectedProduct.ProductStorages.length > 0) {
                    if (selectedProduct.ProductStorages.some(x => x.FutureStockQty > 0 || x.StockQty > 0)) {
                        this.dialogOpen("Advarsel", Localization.Product.UnableToDeleteProductContainsStock);
                        return;
                    }
                }

                if (selectedProduct.Deactivated) {
                    this.dialogOpen("Advarsel", Localization.Product.ProductAlreadyInactive);
                    return;
                }

                if (selectedProduct.InUse) {
                    buttons = MessageHelper.MB3.buttons;

                    buttons[0].click = () => {
                        this.confirmDeleteDialog.hide();
                    };

                    buttons[1].click = async () => {
                        await this.deleteProduct(selectedProduct.Id, true);
                    }

                    this.confirmDeleteDialog.content = MessageHelper.MB3.message;
                }
                else {
                    buttons = this.confirmDeleteButtons;

                    buttons[1].click = async () => {
                        await this.deleteProduct(selectedProduct.Id, false);
                    }

                    this.confirmDeleteDialog.content = 'Er du sikker på at du vil slette data?';
                }

                this.confirmDeleteDialog.buttons = buttons;
                this.confirmDeleteDialog.show();
            }
        }
        else if (args.item.id === 'reset_filter') {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                settings.filters = [];
                this.gridSettings = settings;
                const payload = {
                    Key: this.gridSettingsKey,
                    Settings: JSON.stringify(this.gridSettings)
                };
    
                const result = await SettingsService.SaveGridSettings(payload, source.token);
    
                if (!result.HasError) {
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                }
            }

            this.productGridRef.clearFiltering();
            this.refreshGrid(this.selectedProductStatus);
        }
    }

    async deleteProduct(id, force) {
        source.cancel();
        source = CancelToken.source();
        const payload = {force};
        const result = await productService.DeleteProduct(id, payload, source.token);
        
        if (!result.HasError) {
            // prompt success message
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessDelete.replace("{context}", "Produkt"));
        }
        else {
            // promt validation error
            this.dialogOpen("Advarsel", result.ErrorMessage);
        }

        this.confirmDeleteDialog.hide();
        this.refreshGrid();
    }

    imageTemplate(props) {
        if (props.ProductImages) {
            let primaryImage = props.ProductImages.find(x => x.IsPrimary);
            if (!primaryImage && props.ProductImages.length > 0) {
                primaryImage = props.ProductImages[0];
            }

            if (primaryImage) {
                return (
                    <Image src={primaryImage.ImageKey} alt="" className="product-image-data" />
                )
            }
        }

        return <img src={placeHolderImg} alt="primary product" className="product-image-data" />;
    }

    descriptionTemplate(props) {
        let description = props.Description1;
        if (props.Description1) {
            if (props.Description1.length > 50) {
                description = `${props.Description1.substring(0, 50)}...`;
            }
        }
        return (<div>{description}</div>)
    }

    openProductTemplate(props) {
        return (
            <i className="c-icon c-icon-external-link clickable" onClick={() => this.props.history.push(`/produktkort/edit/${props.Id}`)}></i>
        )
    }

    statusTemplate(props) {
        let status = '';

        if (props.Status) {
            try {
                status = props.Status.split(',');
                status = status.join('<br>');
            } catch (error) {
                
            }
        }

        return ( <div dangerouslySetInnerHTML={{__html: status}}></div> )
    }

    dialogOpen(header, content) {
        this.notificationDialog.header = header;
        this.notificationDialog.content = content;
        this.notificationDialog.show();
    }

    onColumnDragStart(args) {
        this.hasDraggedColumn = true;
    }

    onCreated() {
        if (this.gridSettings) {
            let settings = JSON.parse(this.gridSettings);
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach((column, index) => {
                        if (column.FromIndex !== column.ToIndex) {
                            var tempIndex = this.productGridRef.getColumnIndexByField(column.Field);
                            if (tempIndex !== column.ToIndex){
                                this.productGridRef.reorderColumnByIndex(column.FromIndex, column.ToIndex);
                            }
                        }
                        
                        if (column.Width) {
                            this.productGridRef.columns[column.ToIndex].width = column.Width;
                        }
                    });
                }

                if (settings.colName && settings.direction) {
                    this.productGridRef.sortColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.productGridRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.productGridRef.refreshColumns();

                // Prevent resizing of last column
                let lastColumnIndex = this.productGridRef.columns.length - 1;    
                this.productGridRef.columns[lastColumnIndex].allowResizing = false;
                this.productGridRef.refreshColumns();

                if (settings.pageSize) {
                    if (settings.pageSize === "Alle") {
                        this.productGridRef.pageSettings.pageSize = this.productGridRef.pageSettings.totalRecordsCount;
                    } else {
                        this.productGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
            }
        }
    }

    async onActionBegin(args) {
        if (args.requestType === "paging") {
            if (isNaN(this.productGridRef.pageSettings.pageSize)) {
                this.productGridRef.pagerModule.setPageSize(this.productGridRef.pageSettings.totalRecordsCount);
            }
        }
    }

    async onActionComplete(args) {
        if (args.requestType === 'paging') {
            if (this.productGridRef.pageSettings.pageSize === this.productGridRef.pageSettings.totalRecordsCount) {
                document.querySelector("#product-grid-wrapper .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, this.productGridRef.pageSettings.pageSize, this.productGridRef.pageSettings.totalRecordsCount, false, []);
            tempGridSettings = JSON.parse(tempGridSettings);
            tempGridSettings.IsActiveProduct = this.selectedProductStatus;
            tempGridSettings = JSON.stringify(tempGridSettings);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };
            const result = await SettingsService.SaveGridSettings(payload, source.token);
            this.onWheelScroll();
        }
        else if (args.requestType === 'filtering') {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            tempGridSettings = JSON.parse(tempGridSettings);
            tempGridSettings.IsActiveProduct = this.selectedProductStatus;
            tempGridSettings = JSON.stringify(tempGridSettings);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            tempGridSettings = JSON.parse(tempGridSettings);
            tempGridSettings.IsActiveProduct = this.selectedProductStatus;
            tempGridSettings = JSON.stringify(tempGridSettings);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.productGridRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, this.hasDraggedColumn, columns);
            tempGridSettings = JSON.parse(tempGridSettings);
            tempGridSettings.IsActiveProduct = this.selectedProductStatus;
            tempGridSettings = JSON.stringify(tempGridSettings);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                let lastColumnIndex = this.productGridRef.columns.length - 1;
                for (let index = 0; index < this.productGridRef.columns.length; index++) {
                    let column = this.productGridRef.columns[index];
                    if (index === lastColumnIndex) { column.allowResizing = false }
                    else { column.allowResizing = true }
                }
                this.productGridRef.refreshColumns();
            }

            this.hasDraggedColumn = false;
        }
    }

    async onResizeStop(args) {
        args.requestType = "resizingGrid";
        let columns = this.productGridRef.getColumns();
        let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, columns);
        tempGridSettings = JSON.parse(tempGridSettings);
        tempGridSettings.IsActiveProduct = this.selectedProductStatus;
        tempGridSettings = JSON.stringify(tempGridSettings);
        this.gridSettings = tempGridSettings;
        const payload = {
            Key: this.gridSettingsKey,
            Settings: this.gridSettings
        };

        const result = await SettingsService.SaveGridSettings(payload, source.token);

        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);

            // Adjust size of last column to fill spaces
            let lastColumnIndex = this.productGridRef.columns.length - 1;
            let totalColumnWidth = this.productGridRef.columns.reduce((acc, curVal) => acc + parseInt(curVal.width), 0);
            let lastColumnWidth = this.productGridRef.element.clientWidth - totalColumnWidth;
            this.productGridRef.columns[lastColumnIndex].width = this.productGridRef.columns[lastColumnIndex].width + lastColumnWidth;
            this.productGridRef.refreshColumns();
        }
    }

    render() {
        const { loading, breadcrumbs, redirect } = this.state;

        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        return (
            <div className="content-pane-wrapper">
                <div className="module-header">
                    <h1>{this.pageName}</h1>
                </div>
                <GridComponent id="product-grid-wrapper" ref={ref => this.productGridRef = ref } dataSource={this.data} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings} toolbar={this.toolbarOptions}
                    editSettings={this.defaultEditSettings} selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da"
                    gridLines="Both" toolbarClick={this.clickHandler.bind(this)} allowTextWrap='true' allowReordering={true} allowResizing={true} allowPaging={true}
                    actionComplete={this.onActionComplete.bind(this)} columnDragStart={this.onColumnDragStart.bind(this)} created={this.onCreated.bind(this)} resizeStop={this.onResizeStop.bind(this)}
                    actionBegin={this.onActionBegin.bind(this)}>
                    <ColumnsDirective>
                        <ColumnDirective field="Id" headerText="Billede" width='100' template={this.imageTemplate} />
                        <ColumnDirective field="ProductNo" headerText="Produkt Nr." width='100' />
                        <ColumnDirective field="Description1" headerText="Beskrivelse" width='100' template={this.descriptionTemplate} />
                        <ColumnDirective field="EAN" headerText="EAN" width='100' />
                        <ColumnDirective field="ProductGroup.Name" headerText="Produkt Grupper" width='100' />
                        <ColumnDirective field="PrimarySupplierName" headerText="Primære Leverandør" width='100' />
                        <ColumnDirective field="PrimarySupplierProductNo" headerText="Primære Leverandør Produkt Nr." width='125' />
                        <ColumnDirective field="Department" headerText="Afdeling" width='100' />
                        <ColumnDirective field="Status" headerText="Status" template={this.statusTemplate} />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Selection, Toolbar, Search, Reorder, Resize]} />
                </GridComponent>
                <DialogComponent ref={dialog => this.notificationDialog = dialog}  id="product-dialog" isModal={true} buttons={this.buttons} 
                    width='auto'target='body' visible={false} cssClass="dialog-notification" animationSettings={this.animationSettings} />
                <div>
                    <DialogComponent id="user-card-dialog" isModal={true} buttons={this.confirmDeleteButtons} width='auto' ref={dialog => this.confirmDeleteDialog = dialog} target='body' visible={false} showCloseIcon={true}
                        header="Advarsel" cssClass="dialog-notification" animationSettings={this.animationSettings} />
                </div>
            </div>
        )
    }
}

export default ProductList;