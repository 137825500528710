import React from 'react';
import { ToolbarComponent, ItemsDirective, ItemDirective } from '@syncfusion/ej2-react-navigations';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { CheckBoxComponent, RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import BaseComponent from '../../components/BaseComponent';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import NavigationPrompt from '../../components/NavigationPrompt'
import SessionService from '../../services/SessionService';
import OilmatProductService from '../../services/Product/OilmatProductService';
import Loading from '../../components/Loading';
import Axios from 'axios';

import Localization from '../../utilities/Localization';
import { toDanishDecimalFormat } from '../../utilities/Helper';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

class OilmatProduct extends BaseComponent {
    moduleName = "Produkt";
    pageName = "Oilmat Produkt";
    sessionId = 0;

    constructor() {
        super();

        this.state = {
            loading: true,
            // hasUnsavedChanges: false
            currentTabSelected: 0,
            products: [],
            isEditMode: false,
            hasUnsavedChanges: false,
            redirectId: 0
        };

        this.onToolbarClicked = this.onToolbarClicked.bind(this);
        this.dialogOpen = this.dialogOpen.bind(this);
        this.animationSettings = { effect: 'None' };
        this.buttons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];

        this.onChange = this.onChange.bind(this);
    }

    async componentDidMount() {
        super.componentDidMount();        
        source.cancel();
        source = CancelToken.source();

        if (!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }

        const sessionInfo = await SessionService.GetSessionInfo();
        this.sessionId = sessionInfo.Id;
        // const result = await OilmatProductService.GetProducts(sessionInfo.Id, source.token);
        const result = await OilmatProductService.GetProducts(this.sessionId, source.token);
        
        this.setState({ products: result, loading: false }, () => {
            if (result.length <= 0) {
                this.dialogOpen("Advarsel", "Customer has no Oilmat account.")
            }
        });
    }

    refreshData = async () => {
        const result = await OilmatProductService.GetProducts(this.sessionId, source.token);
        this.setState({ products: result });
    }

    dialogOpen(header, content) {
        this.notificationDialog.header = header;
        this.notificationDialog.content = content;
        this.notificationDialog.show();
    }

    async onToolbarClicked(args) {
        switch (args.item.id) {
            case 'edit':
                this.setState({ isEditMode: true });
                break;
            case 'save':
                this.initiateSave();
                break;
            case 'cancel':
                this.setState({ isEditMode: false }, () => {
                    this.refreshData();
                });
                break;
            default:
                break;
        }
    }

    initiateSave = async () => {
        source.cancel()
        source = CancelToken.source();
        const { products, currentTabSelected } = this.state;
        let product = products[currentTabSelected];

        const data = {
            CaseFormat: product.CaseFormat,
            UsePinCode: product.UsePinCode,
            UseCaseNumber: product.UseCaseNumber,
            OilmatPlan: product.OilmatPlan
        };

        const result = await OilmatProductService.UpdateProduct(this.sessionId, product.Id, data, source.token);
        if (!result.HasError) {
            this.toolbarRef.enableItems(2, true);
            this.toolbarRef.enableItems(3, true);
            this.toolbarRef.enableItems(4, false);

            this.setState({ isEditMode: false, hasUnsavedChanges: false }, () => {
                this.refreshData();
            });
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "OilMat Produkt"));
        }
        else {
            this.dialogOpen("Advarsel", result.ErrorMessage);
        }
    }

    onChange(args) {
        let { products, currentTabSelected } = this.state;
        let product = products[currentTabSelected];

        if (args.event != null && args.event.target.type === "checkbox") {
            product[args.event.target.name] = args.checked;
        }
        else if (args.event != null && args.event.target.type === "radio") {
            product[args.event.target.name] = args.value;
        }
        else {
            product[args.target.name] = args.target.value;
        }

        this.setState({ products, hasUnsavedChanges: true });
    }

    onTabSelect = (index) => {
        if (this.state.hasUnsavedChanges) {
            this.refreshData();
        }
        
        this.setState({ currentTabSelected: index, hasUnsavedChanges: false, isEditMode: false });
    }

    renderTab(product, isEditMode) {
        return (
            <div>
                <table className="table system-setting-message-table">
                    <tbody className='label'>
                        <tr>
                            <td width="40%">SKU</td>
                            <td>{product.ProductNo}</td>
                        </tr>
                        <tr>
                            <td width="40%">Group</td>
                            <td>{product.ProductGroup}</td>
                        </tr>
                        <tr>
                            <td width="40%">Customer Group</td>
                            <td>{product.CustomerGroup}</td>
                        </tr>
                        <tr>
                            <td width="40%">Produkt beskrivelse</td>
                            <td>{product.ProductType}</td>
                        </tr>
                        <tr>
                            <td width="40%">Dunk størrelse</td>
                            <td>{product.DunkSize}</td>
                        </tr>
                        <tr>
                            <td width="40%">Density</td>
                            <td>{product.Density}</td>
                        </tr>
                        <tr>
                            <td width="40%">Pumpe ID nummer</td>
                            <td>{product.PumpId}</td>
                        </tr>
                        <tr>
                            <td width="40%">Status</td>
                            <td>{product.StatusText}</td>
                        </tr>
                        <tr>
                            <td width="40%">Stock</td>
                            <td>{product.StockQtyDisplay}</td>
                        </tr>
                        <tr>
                            <td width="40%">Brug Pinkode</td>
                            {/* <td><input type="checkbox" ref={ref => this.UsePinCodeRef = ref} checked={product.UsePinCode} disabled /></td> */}
                            <td><CheckBoxComponent ref={ref => this.UsePinCodeRef = ref} checked={product.UsePinCode} disabled={!isEditMode} name="UsePinCode" change={this.onChange} /></td>
                        </tr>
                        <tr>
                            <td width="40%">Brug Sags nummer</td>
                            {/* <td><input type="checkbox" ref={ref => this.UseCaseNumberRef = ref} checked={product.UseCaseNumber} disabled /></td> */}
                            <td><CheckBoxComponent ref={ref => this.UseCaseNumberRef = ref} checked={product.UseCaseNumber} disabled={!isEditMode} name="UseCaseNumber" change={this.onChange} /></td>
                        </tr>
                        <tr>
                            <td width="40%">Sags nr. styring</td>
                            {/* <td>{product.CaseFormat}</td> */}
                            <td>
                                <input type="text" ref={ref => this.CaseFormatRef = ref} value={product.CaseFormat} disabled={!isEditMode} name="CaseFormat" className="e-control e-textbox e-lib e-input" onChange={this.onChange} />
                            </td>
                        </tr>
                        <tr>
                            <td width="40%">Plan</td>
                            {/* <td>{product.OilmatPlan === 0 ? "OilMat Standard" : "OilMat Premium"}</td> */}
                            <td>
                                <ul className="list-no-style">
                                    <li><RadioButtonComponent label='OilMat Standard' checked={product.OilmatPlan === 0} value={0} name="OilmatPlan" change={this.onChange} disabled={!isEditMode} /></li>
                                    <li><RadioButtonComponent label='OilMat Premium' checked={product.OilmatPlan === 1} value={1} name="OilmatPlan" change={this.onChange} disabled={!isEditMode} /></li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    render() {
        const { loading, currentTabSelected, products, isEditMode, hasUnsavedChanges } = this.state;
        
        if(loading) {
            return <Loading />
        }

        return (
            <div className='content-pane-wrapper'>
                <NavigationPrompt 
                    ref={ref => this.NavigationPromptRef = ref}
                    when={hasUnsavedChanges}
                    navigate={path => this.props.history.push(path)}
                    initiateSave={this.initiateSave}
                    shouldBlockNavigation={location => {
                        if (hasUnsavedChanges) {
                            return true;
                        }
                        return false;
                    }}
                />
                <div className="module-header">
                    <h1>{this.pageName}</h1>
                </div>
                <div>
                    <ToolbarComponent ref={ref => this.toolbarRef = ref} clicked={this.onToolbarClicked}>
                        <ItemsDirective>
                            <ItemDirective id="edit" prefixIcon='tb-icons c-icon-edit' text="Redigere" tooltipText='Redigere' disabled={isEditMode} />
                            <ItemDirective id="save" prefixIcon='tb-icons c-icon-update' text='Gem' tooltipText='Gem' disabled={!isEditMode} />
                            <ItemDirective id="cancel" prefixIcon='tb-icons c-icon-cancel' text="Annuller" tooltipText='Annuller' disabled={!isEditMode} />
                        </ItemsDirective>
                    </ToolbarComponent>
                </div>
                <div className='control-section tab-control'>
                    <Tabs className="c-tab" selectedIndex={currentTabSelected} onSelect={this.onTabSelect} disabledTabClassName="c-tabs__tab--disabled">
                        <TabList className="c-tab__list">
                            {
                                products.map((product, index) => <Tab className={`c-tabs__tab`} selectedClassName="c-tabs__tab--selected">{product.PumpId}</Tab>)                                
                            }
                        </TabList>
                        {
                            products.map((product, index) => {
                                return <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                                    {this.renderTab(product, isEditMode)}
                                </TabPanel>
                            })
                        }
                    </Tabs>
                    <DialogComponent isModal={true} buttons={this.buttons} width='auto' ref={dialog => this.notificationDialog = dialog} target='body' showCloseIcon={true}
                        cssClass="dialog-notification" animationSettings={this.animationSettings} visible={false} allowDragging={true} enableResize={true} />
                </div>
            </div>
        );
    }
}

export default OilmatProduct;