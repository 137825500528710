import React from 'react'
import { PivotViewComponent, Inject, Toolbar, PDFExport, ExcelExport, FieldList } from '@syncfusion/ej2-react-pivotview';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject as GridInject, Page, Sort, Toolbar as GridToolbar, Selection, Edit, Search, Reorder, Resize, Aggregate, 
        AggregateColumnsDirective, AggregateColumnDirective, AggregateDirective, AggregatesDirective, ColumnChooser, ExcelExport as GridExcelExport, PdfExport as GridPdfExport } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import Axios from 'axios';
import { Redirect } from 'react-router-dom';
import { ListViewComponent } from '@syncfusion/ej2-react-lists';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DataManager } from '@syncfusion/ej2-data';

import BaseComponent from '../../components/BaseComponent';
import Breadcrumb from '../../components/Breadcrumb';
import Loading from '../../components/Loading';
import MessageHelper from '../../utilities/MessageHelper';

import SessionService from '../../services/SessionService';
import ReportService from '../../services/ReportService';
import SettingsService from '../../services/SettingsService';
import CustomerService from '../../services/Customer/CustomerService';
import '../../styles/reports.scss';
import Localization from '../../utilities/Localization';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class UserDefinedReports extends BaseComponent {
    moduleName = "Rapporter";
    pageName = "Bruger Defineret";
    isSaveReportInitial = false;
    isCustomer = false;

    constructor() {
        super();

        this.state = {
            loading: true,
            reportSource: {
                AggregateFields: [],
                Data: []
            },
            breadcrumbs: ['Rapporter', 'Bruger Defineret'],
            redirect: {
                to: '',
                isRedirect: false
            },
            reportSettings: {
                reportType: null,
                reportTypeName: '',
                reportMenu: null,
                reportMenuName: ''
            },
            standardReportSetting: {
                selectedReport: null,
                selectedReportName: '',
                selectedReportLevel: null
            },
            forceSave: false
        };
        
        this.reportType = [
            { Id: 1, Name: 'Standard Report', selected: true }, 
            // { Id: 2, Name: 'Pivot Report' }
        ];
        // this.reportAvailability = [{ Id: 3, Name: 'Rapport kun tilgængelig på denne konto' }, { Id: 1, Name: 'Rapport tilgængelig for alle brugere' }];

        this.reportMenuItems = [];
        this.reportTemplates = [];
        this.columnsList = [];

        //#region Standard Table properties
        this.customColumns = [];
        this.columns = [];
        this.toolbarOptions = [
            { text: 'Ny', tooltipText: 'Ny', id: 'add', prefixIcon: 'e-add-report' },
            { text: 'Gem', tooltipText: 'Gem', id: 'save', prefixIcon: 'e-update' },
            { text: 'Slette', tooltipText: 'Slette', id: 'delete', prefixIcon: 'e-delete' },
            { template: this.reportListTemplate, align: "Left", id: 'reportsListTemplate' }, 
            'ColumnChooser', 
            'ExcelExport', 'PdfExport', 'CsvExport'
        ];
        
        this.pageSettings = {
            pageSize: 15
        };

        this.selectionSettings = {
            type: 'Single'
        }

        this.filterSettings = {
            type: 'Menu'
        };

        this.standardReportData = [];
        
        //#endregion

        //#region Pivot Table properties
        this.pivotToolbarOptions = ['New', 'Save', 'Rename', 'Remove', 'Load', 'Grid', 'Chart', 'Export', 'SubTotal', 'GrandTotal', 'FieldList'];
        this.displayOption = { view: 'Primary'};
        this.dataSourceSettings = {
            dataSource: [],
            expandAll: false,
            allowLabelFilter: true,
            allowValueFilter: true,
            columns: [],
            rows: [],
            values: [],
            formatSettings: [],
            filters: [],
            filterSettings: [],
            enableSorting: true,
            showRowGrandTotals: false
        };
        //#endregion

        this.dialogOpen = this.dialogOpen.bind(this);
        this.standardTableToolbarClicked = this.standardTableToolbarClicked.bind(this);
        this.onSubmitClick = this.onSubmitClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.onPivotCreated = this.onPivotCreated.bind(this);
        this.onReportMenuChange = this.onReportMenuChange.bind(this);
        this.onSelectAllChange = this.onSelectAllChange.bind(this);
        this.onColumnListViewSelect = this.onColumnListViewSelect.bind(this);
        this.onReportTypeChange = this.onReportTypeChange.bind(this);
        this.onRefreshReportOptionsClick = this.onRefreshReportOptionsClick.bind(this);

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.buttons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        this.standardReportButtons = [
            {
                click: () => {
                    this.reportName.value = '';
                    this.ddlReportAvailabilityRef.value = null;
                    this.standardReportSaveDialog.hide();
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    content: 'Annuller'
                }
            },
            {
                click: async () => {
                    await this.saveStandardReport();
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    isPrimary: true,
                    content: 'Gem'
                }
            }
        ];
        this.udReportDialogInitialButtons = [
            {
                click: this.onSubmitClick,
                buttonModel: {
                    isPrimary: true,
                    content: 'Opret'
                }
            }
        ];
        this.udReportDialogButtons = [
            {
                click: () => {
                    this.ddlReportTypeRef.value = null;
                    this.ddlReportMenusRef.value = null;
                    this.udReportDialog.hide();
                },
                buttonModel: {
                    content: 'Annuller'
                }
            },
            {
                click: this.onSubmitClick,
                buttonModel: {
                    isPrimary: true,
                    content: 'Opret'
                }
            }
        ];
        this.deleteConfirmationbuttons = [
            {
                click: () => {
                    this.deleteConfirmationDialog.hide();
                },
                buttonModel: MessageHelper.MB2.buttons[0].buttonModel
            },
            {
                click: this.onDeleteClick,
                buttonModel: MessageHelper.MB2.buttons[1].buttonModel
            }
        ];
        this.overwriteExistingReportButtons = [
            {
                click: () => {
                    this.standardReportSaveDialog.hide();
                    this.notificationDialog.hide();
                },
                buttonModel: {
                    content: 'Annuller'
                }
            },
            {
                click: () => {
                    this.setState({ forceSave: true }, () => {
                        this.saveStandardReport();
                        this.standardReportSaveDialog.hide();
                        this.notificationDialog.hide();
                    });
                },
                buttonModel: {
                    //Enables the primary button
                    isPrimary: true,
                    content: 'OK'
                }
            }
        ]
        /* End Dialog options  */
    }

    async componentDidMount() {
        super.componentDidMount();
        source.cancel();
        source = CancelToken.source();

        if (!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }
        else if (!super.hasMenuItem("Bruger Defineret")) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }

        const session = await SessionService.GetSessionInfo();
        let reportItems = [];
        if (session.userType === 'customer') {
            let group = session.CustomerGroup.Menu.Intralogix.find(x => x.Name === 'Rapporter');
            if (group) {
                reportItems = group.MenuItems.filter(x => x.Name !== 'Bruger Defineret' && x.Name !== 'Favoritter');
            }

            this.isCustomer = session.userType === 'customer';
        }
        else if (session.userType === 'user') {
            let group = session.UserGroup.Menu.Intralogix.find(x => x.Name === 'Rapporter');
            if (group) {
                reportItems = group.MenuItems.filter(x => x.Name !== 'Bruger Defineret' && x.Name !== 'Favoritter');
            }
            
            let customMenu = session.GroupMenu.find(x => x.Name === 'Rapporter');
            if (customMenu) {
                reportItems.concat(customMenu.MenuItems.filter(x => x.Name !== 'Bruger Defineret' && x.Name !== 'Favoritter'));
            }
        }
        
        const menuColumns = await ReportService.GetMenuColumns(source.token);
        if (!menuColumns.HasError) {
            this.menuColumns = menuColumns;
        }

        const reportAvailabilities = await SettingsService.GetReportAvailabilities(source.token);
        if (!reportAvailabilities.HasError) {
            let reportAvailabilityOptions = [
                reportAvailabilities.find(x => x.Value === 3)
            ];
            let moduleFunctions = super.getMenuItemFunctions("Bruger Defineret");

            moduleFunctions.forEach(func => {
                if (reportAvailabilities.some(x => x.Key === func)) {
                    let opt = reportAvailabilities.find(x => x.Key === func);
                    reportAvailabilityOptions.push(opt);
                }
            });
            
            this.reportAvailability = reportAvailabilityOptions;
        }

        this.customerId = session.Id;
        this.reportMenuItems = reportItems.sort((a,b) => a.Name.localeCompare(b.Name));
        this.setState({ loading: false }, () => {
            setTimeout(() => {
                this.udReportDialog.buttons = this.udReportDialogInitialButtons;
                this.udReportDialog.show();

                // Set default option if Report Availability contains a single option
                if (this.reportAvailability.length === 1 && this.ddlReportAvailabilityRef) {
                    this.ddlReportAvailabilityRef.value = this.reportAvailability[0].Value;
                }
            }, 400);
        });
    }

    componentWillUnmount() {
        source.cancel();
    }

    async save(reportName, datasourceSetting) {
        const { reportSettings } = this.state;
        let reportTemplate = this.reportTemplates.find(x => x.Name === reportName);
        let state = 'add';
        // let shared = false;

        // if (this.ddlPivotReportAvailabilityRef) {
        //     shared = this.ddlPivotReportAvailabilityRef.value !== 1;
        // }

        const payload = {
            Name: reportName,
            Config: datasourceSetting,
            ParentMenuItemId: reportSettings.reportMenu,
            CustomerId: parseInt(this.customerId),
            // Shared: shared,
            ReportType: reportSettings.reportType,
            ReportLevel: this.ddlPivotReportAvailabilityRef.value
        };

        if (reportTemplate) {
            payload.Id = reportTemplate.Id;
            state = 'update';
        }
        
        const actionText = state === 'add' ? 'added' : 'updated';
        const result = await ReportService.AddUpdateReport(state, payload, source.token);
        if (!result.HasError) {
            if (reportTemplate) {
                const index = this.reportTemplates.findIndex(e => e.Id === result.Id);
                let templates = [...this.reportTemplates];

                if (index !== -1) {
                    templates[index] = { ...templates[index], Id: result.Id, Config: result.Config, ParentMenuItemId: result.ParentMenuItemId };
                }
                else {
                    payload.Id = result.Id;
                    templates.push(payload);
                }

                this.reportTemplates = templates;
            }
            else {
                this.reportTemplates.push(result);
            }
            this.dialogOpen(Localization.General.SuccessHeader, `${actionText === 'added' ? Localization.General.SuccessAdd.replace("{context}", "Rapport") : Localization.General.SuccessUpdate.replace("{context}", "Rapport")}`);
        }
        else {
            this.dialogOpen("Advarsel", result.ErrorMessage);
        }
    }

    saveReport(args) {
        if (this.isSaveReportInitial && args.reportName !== "Sample Report") {
            this.save(args.reportName, args.report);
        }
        else {
            if (this.pivotView) {
                this.pivotView.toolbarModule.currentReport = ""; 
                this.isSaveReportInitial = true;
            }
        }
    }

    fetchReport(args) {
        let reportList = [];
        this.reportTemplates.map( item => { reportList.push(item.Name); });
        args.reportName = reportList;
    }

    loadReport(args) {
        const { reportSource } = this.state;
        let report = this.reportTemplates.find(x => x.Name === args.reportName);
        if (report) {
            let settings = JSON.parse(report.Config);
            args.report = settings;
            this.pivotView.dataSourceSettings = settings.dataSourceSettings;
            this.pivotView.dataSourceSettings.dataSource = reportSource.Data;
            this.pivotView.refreshData();
            this.pivotSelectedReport = report;
        }
    }

    async removeReport(args) {
        let report = this.pivotSelectedReport;
        // let report = this.reportTemplates.find(x => x.Name === args.reportName);
        if (report) {
            const result = await ReportService.DeleteReport(report.Id, source.token);
            if (!result.HasError) {
                this.props.updateUserMenu();
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessDelete.replace("{context}", "Rapport"));
                this.reportTemplates = this.reportTemplates.filter(x => x.Id !== report.Id);
                
                this.pivotView.dataSourceSettings = {
                    dataSource: [],
                    expandAll: false,
                    allowLabelFilter: true,
                    allowValueFilter: true,
                    columns: [],
                    rows: [],
                    values: [],
                    formatSettings: [],
                    filters: [],
                    filterSettings: [],
                    enableSorting: true,
                    showRowGrandTotals: false
                };
                this.pivotSelectedReport = null;
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
    }

    async renameReport(args) {
        const template = this.reportTemplates.find(x => x.Name === args.reportName);

        if (template) {
            template.Name = args.rename;

            const payload = {
                Id: template.Id,
                Name: args.rename,
                Config: template.Config,
                ParentMenuItemId: this.ddlReportMenusRef.value,
                CustomerId: parseInt(this.customerId)
            };
            const result = await ReportService.AddUpdateReport('update', payload, source.token);
            if (!result.HasError) {
                this.props.updateUserMenu();
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Rapport"));
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
        else {
            this.dialogOpen("Advarsel", Localization.Report.ErrorRenamingReportNotExist);
        }
    }

    beforeExport(args) {
        args.excelExportProperties = {
            fileName: `${this.pivotSelectedReport.Name}.xlsx`
        }

        args.pdfExportProperties = {
            fileName: `${this.pivotSelectedReport.Name}.pdf`,
            header: {
                contents: [ 
                    { position: { x: 0, y: 10 }, style: { textBrushColor: '#000000', fontSize: 22 }, type: 'Text', value: this.pivotSelectedReport.Name } 
                ],
                fromTop: 0, height: 50
            }
        }
    }
    
    newReport() {
        this.pivotView.setProperties({ dataSourceSettings: { columns: [], rows: [], values: [], filters: [] } }, false);
    }

    reportListTemplate = () => {
        let items = this.reportTemplates;

        const self = this;
        const onChange = async (args) => {
            self.refreshStandardGridTable(args.value);
        }

        return (<DropDownListComponent id="ddlReportList" cssClass="customer-status-dropdown" dataSource={items} showClearButton={true} placeholder={Localization.Report.SelectReportList} 
            fields={{ text: 'Name', value: 'Id' }} change={onChange} popupWidth="350px" />)
    }

    async refreshStandardGridTable(reportId) {
        let { standardReportSetting, reportSettings } = this.state;

        if (reportId) {
            const report = this.reportTemplates.find(x => x.Id === reportId);
            let settings = JSON.parse(report.Config);
            
            this.customColumns = settings.columns;
            standardReportSetting.selectedReport = reportId;
            standardReportSetting.selectedReportName = report.Name;
            standardReportSetting.selectedReportLevel = report.ReportLevel;

            let reportSource = { AggregateFields: [], Data: [] };
            let params = settings.columns.filter(x => x.visible).map(f => f.field);

            switch(reportSettings.reportMenuName) {
                case 'Produkt':
                    reportSource = await ReportService.GetProductsReport(this.customerId, params, source.token);
                    break;
                case 'Indkøb':
                    reportSource = await ReportService.GetOrdersReport(this.customerId, params, source.token);
                    break;
                case 'Brugere':
                    reportSource = await ReportService.GetUsersReport(this.customerId, params, source.token);
                    break;
                case 'Leverandører':
                    reportSource = await ReportService.GetSupplierReport(this.customerId, params, source.token);
                    break;
                case 'Kunder':
                    reportSource = await ReportService.GetCustomersReport(this.customerId, params, source.token);
                    break;
                case 'Automater':
                    reportSource = await ReportService.GetMachineReport(this.customerId, params, source.token);
                    break;
                case 'Lager':
                    reportSource = await ReportService.GetStockReport(this.customerId, params, source.token);
                    break;
                case 'Forbrug':
                    reportSource = await ReportService.GetConsumeReport(this.customerId, params, source.token);
                    break;
            }

            if (!reportSource.HasError) {
                this.columns = reportSource.Columns;
                this.customColumns = settings.columns.map(value => {
                    let type = reportSource.Columns.find(x => x.FieldName === value.field) ? reportSource.Columns.find(x => x.FieldName === value.field).Type : "";
                    value.Type = type;
                    return value;
                });
                var dateFields = [];
                var timeFields = [];
                var date = new Date();

                let visibleColumns = Object.keys(reportSource.Data[0]);
                this.columns.forEach(c => {
                    c.Visible = visibleColumns.some(v => v === c.FieldName)
                    if (c.Type === "DateTime") {
                        dateFields.push(c.FieldName);
                    }
                    if (c.Type === "TimeSpan") {
                        timeFields.push(c.FieldName);
                    }
                });
                
                reportSource.Data.forEach(d => {
                    this.columns.forEach(c => {
                        if (!(c.FieldName in d)) {
                            d[c.FieldName] = null;
                        }
                    })

                    dateFields.forEach(field => {
                        if (d[field]) {
                            d[field] = new Date(d[field]);
                        }
                    });
                    timeFields.forEach(field => {
                        if (d[field]) {
                            d[field] = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), d[field].Hours, d[field].Minutes, d[field].Seconds);
                        }
                    });
                });

                this.reportGridRef.dataSource = reportSource.Data;
                this.reportGridRef.dataBind();
    
                this.setState({ standardReportSetting }, () => {
                    this.dataChanged = true;
                    // this.reportGridRef.columns.forEach(gc => {
                    //     gc.visible = this.columns.some(c => c.FieldName === gc.field && c.Visible);
                    // });
                    // this.reportGridRef.refreshColumns();
                });
            }
            else {
                this.dialogOpen("Advarsel", reportSource.ErrorMessage);
            }
        }
        else {
            this.customColumns = [];
            standardReportSetting.selectedReport = null;
            standardReportSetting.selectedReportName = '';
    
            this.setState({ standardReportSetting });
        }
    }

    async saveStandardReport() {
        let { reportSettings, standardReportSetting, forceSave } = this.state;
        let state = 'add';
        
        let filteredColumns = [];
        if (this.reportGridRef.filterModule && this.reportGridRef.filterModule.filterSettings && this.reportGridRef.filterModule.filterSettings.columns && this.reportGridRef.filterModule.filterSettings.columns.length > 0) {
            this.reportGridRef.filterModule.filterSettings.columns.forEach(column => {
                filteredColumns.push({
                    field: column.field,
                    operator: column.operator,
                    value: column.value
                })
            });
        }
        let columns = this.reportGridRef.columns;
        
        let dataSourceSettings = {
            columns: columns.map(c => {
                return { field: c.field, headerText: c.headerText, width: c.width, index: c.index, sortDirection: c.sortDirection, visible: c.visible }
            }),
            filters: filteredColumns,
            sortedColumns: this.reportGridRef.sortedColumns
        };

        // let shared = false;
        // if (this.ddlReportAvailabilityRef) {
        //     shared = this.ddlReportAvailabilityRef.value !== 3;
        // }

        if (this.ddlReportAvailabilityRef.value) {
            this.dialogOpen("Advarsel", "Report availability is required.");
            return;
        }

        const payload = {
            Name: this.reportName.value,
            Config: JSON.stringify(dataSourceSettings),
            ParentMenuItemId: reportSettings.reportMenu,
            CustomerId: parseInt(this.customerId),
            // Shared: shared,
            ReportType: reportSettings.reportType,
            ReportLevel: this.ddlReportAvailabilityRef.value,
            Force: forceSave
        };

        if (this.transactionState === 'add') {

        }
        else if (this.transactionState === 'update') {
            if (standardReportSetting.selectedReport) {
                payload.Id = standardReportSetting.selectedReport;
                state = 'update';
            }
        }
        
        const result = await ReportService.AddUpdateReport(state, payload, source.token);
        if (!result.HasError) {
            let reportList = document.getElementById('ddlReportList').ej2_instances[0];
            this.props.updateUserMenu();
            if (state === 'add') {
                if (reportList) {
                    if (forceSave) {
                        let reportItem = reportList.dataSource.find(x => x.Id === result.Id);
                        reportItem.Name = result.Name;
                        reportItem.Config = result.Config;
                        reportItem.Shared = result.Shared;
                        reportItem.ReportLevel = result.ReportLevel;
                    }
                    else {
                        reportList.dataSource.push(result);
                    }
                }
                // this.reportGridRef.toolbarModule.enableItems(['save', 'delete'], true);
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessAdd.replace("{context}", "Rapport"));
            }
            else if (state === 'update') {
                if (reportList) {
                    let reportItem = reportList.dataSource.find(x => x.Id === result.Id);
                    reportItem.Name = result.Name;
                    reportItem.Config = result.Config;
                    reportItem.Shared = result.Shared;
                    reportItem.ReportLevel = result.ReportLevel;
                }

                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Rapport"));
            }

            this.transactionState = '';
            this.standardReportSaveDialog.hide();

            this.setState({ forceSave: false });
        }
        else {
            if (result.ErrorMessage === "Report name already exist") {
                this.dialogOpen("Advarsel", "Report name already exist. Would you like to overwrite it?", this.overwriteExistingReportButtons);
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
    }

    async standardTableToolbarClicked(args) {
        if (args.item.id === 'add') {
            this.transactionState = 'add';
            this.standardReportSaveDialog.show();
        }
        else if (args.item.id === 'save') {
            let { standardReportSetting } = this.state;

            if (standardReportSetting.selectedReportName) {
                this.reportName.value = standardReportSetting.selectedReportName;
            }
            this.transactionState = 'update';
            this.standardReportSaveDialog.show();
        }
        else if (args.item.id === 'delete') {
            let { reportMenu, reportType, standardReportSetting } = this.state;
            if (standardReportSetting.selectedReport) {
                this.deleteConfirmationDialog.content = MessageHelper.MB2.message.replace('{context_message}', `"${standardReportSetting.selectedReportName}"`);
                this.deleteConfirmationDialog.show();
            }
            else {
                this.dialogOpen("Advarsel", Localization.Report.PleaseSelectReportTemplate);
            }
        }
        else if (args.item.text === 'PDF Export') {
            let { standardReportSetting } = this.state;
            const query = this.reportGridRef.renderModule.data.generateQuery();

            for(let i = 0; i < query.queries.length; i++ ) {
                if(query.queries[i].fn === 'onPage'){
                    query.queries.splice(i,1);// remove page query to get all records
                    break;
                }
            }

            new DataManager({json: this.standardReportData}).executeQuery(query).then((e) => {
                let data = e.result;
                if (data.length > 0) {
                    let pdfExportProperties = {};
                    
                    if (standardReportSetting.selectedReport) {
                        pdfExportProperties = {
                            fileName: `${standardReportSetting.selectedReportName}.pdf`,
                            header: {
                                contents: [ 
                                    { position: { x: 0, y: 10 }, style: { textBrushColor: '#000000', fontSize: 22 }, type: 'Text', value: standardReportSetting.selectedReportName } 
                                ],
                                fromTop: 0, height: 50
                            }
                        }
                    }
                    pdfExportProperties.dataSource = data;
                    this.reportGridRef.pdfExport(pdfExportProperties);
                }
                else {
                    this.dialogOpen("Advarsel", Localization.Report.NoRecordsToExport);
                }
            }).catch(e => true);
        }
        else if (args.item.text === 'Excel Export') {
            let { standardReportSetting } = this.state;
            const query = this.reportGridRef.renderModule.data.generateQuery();

            for(let i = 0; i < query.queries.length; i++ ){
                if(query.queries[i].fn === 'onPage'){
                  query.queries.splice(i,1);// remove page query to get all records
                  break;
                }
            }

            new DataManager({json: this.standardReportData}).executeQuery(query).then((e) => {
                let data = e.result;
                if (data.length > 0) {
                    let excelExportProperties = {};
                
                    if (standardReportSetting.selectedReport) {
                        excelExportProperties = {
                            fileName: `${standardReportSetting.selectedReportName}.xlsx`
                        }
                    }
                    excelExportProperties.dataSource = data;
                    this.reportGridRef.excelExport(excelExportProperties);
                }
                else {
                    this.dialogOpen("Advarsel", Localization.Report.NoRecordsToExport);
                }
            }).catch(e => true);
        }
        else if (args.item.text === 'CSV Export') {
            let { standardReportSetting } = this.state;
            const query = this.reportGridRef.renderModule.data.generateQuery();

            for(let i = 0; i < query.queries.length; i++ ){
                if(query.queries[i].fn === 'onPage') {
                  query.queries.splice(i,1);// remove page query to get all records
                  break;
                }
            }

            new DataManager({json: this.standardReportData}).executeQuery(query).then((e) => {
                let data = e.result;
                if (data.length > 0) {
                    let excelExportProperties = {};
                
                    if (standardReportSetting.selectedReport) {
                        excelExportProperties = {
                            fileName: `${standardReportSetting.selectedReportName}.csv`
                        }
                    }
                    excelExportProperties.dataSource = data;
                    this.reportGridRef.csvExport(excelExportProperties);
                }
                else {
                    this.dialogOpen("Advarsel", Localization.Report.NoRecordsToExport);
                }
            }).catch(e => true);
        }
    }

    async onSubmitClick() {
        let { reportSettings } = this.state;
        // let reportName = this.ddlReportMenusRef.text;
        // reportType = this.ddlReportTypeRef.value;
        // reportMenu = this.ddlReportMenusRef.value;

        reportSettings.reportType = this.ddlReportTypeRef.value;
        reportSettings.reportTypeName = this.ddlReportTypeRef.text;
        reportSettings.reportMenu = this.ddlReportMenusRef.value;
        reportSettings.reportMenuName = this.ddlReportMenusRef.text;

        if (!reportSettings.reportType || !reportSettings.reportMenu) {
            this.dialogOpen("Advarsel", "Please fill in report options.");
            return;
        }

        const report = await CustomerService.GetReportTemplates(this.customerId, reportSettings.reportMenu, source.token);

        if (report) {
            this.reportTemplates = report.filter(r => r.ReportType === reportSettings.reportType);
            let ddlReportList = document.getElementById('ddlReportList');
            if (ddlReportList) {
                let reportList = ddlReportList.ej2_instances[0];
                if (reportList) {
                    reportList.dataSource = this.reportTemplates;
                }
            }
        }
        
        let reportSource = { AggregateFields: [], Data: [] };
        let param = [];
        if (reportSettings.reportType === 1) {
            let selectedItems = this.columnsListView.getSelectedItems();
            param = selectedItems.data.map(d => d.FieldName);
        }

        switch(reportSettings.reportMenuName) {
            case 'Produkt':
                reportSource = await ReportService.GetProductsReport(this.customerId, param, source.token);
                break;
            case 'Indkøb':
                reportSource = await ReportService.GetOrdersReport(this.customerId, param, source.token);
                break;
            case 'Brugere':
                reportSource = await ReportService.GetUsersReport(this.customerId, param, source.token);
                break;
            case 'Leverandører':
                reportSource = await ReportService.GetSupplierReport(this.customerId, param, source.token);
                break;
            case 'Kunder':
                reportSource = await ReportService.GetCustomersReport(this.customerId, param, source.token);
                break;
            case 'Automater':
                reportSource = await ReportService.GetMachineReport(this.customerId, param, source.token);
                break;
            case 'Lager':
                reportSource = await ReportService.GetStockReport(this.customerId, param, source.token);
                break;
            case 'Forbrug':
                reportSource = await ReportService.GetConsumeReport(this.customerId, param, source.token);
                break;
        }

        if (reportSource.HasError) {
            this.dialogOpen("Advarsel", reportSource.ErrorMessage);
            return;
        }

        this.setState({ reportSettings, reportSource }, () => {
            // this.ddlReportTypeRef.value = null;
            // this.ddlReportMenusRef.value = null;
            this.udReportDialog.hide();
            if (reportSettings.reportType === 1) {
                this.customColumns = [];
                this.columns = reportSource.Columns;
                var dateFields = [];
                var timeFields = [];
                var date = new Date();

                if (reportSource.Data && reportSource.Data.length > 0) {
                    let visibleColumns = Object.keys(reportSource.Data[0]);
                    this.columns.forEach(c => {
                        c.Visible = visibleColumns.some(v => v === c.FieldName)
                        if (c.Type === "DateTime") {
                            dateFields.push(c.FieldName);
                        }
                        if (c.Type === "TimeSpan") {
                            timeFields.push(c.FieldName);
                        }
                    });
                    
                    reportSource.Data.forEach(d => {
                        this.columns.forEach(c => {
                            if (!(c.FieldName in d)) {
                                d[c.FieldName] = null;
                            }
                        })

                        dateFields.forEach(field => {
                            if (d[field]) {
                                d[field] = new Date(d[field]);
                            }
                        });
                        timeFields.forEach(field => {
                            if (d[field]) {
                                d[field] = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), d[field].Hours, d[field].Minutes, d[field].Seconds);
                            }
                        });
                    });
                    this.standardReportData = reportSource.Data;
                    if (this.reportGridRef) {
                        this.reportGridRef.dataSource = reportSource.Data;
                        this.reportGridRef.dataBind();

                        this.reportGridRef.columns.forEach(x => {
                            if (visibleColumns.includes(x.field)) {
                                x.visible = true;
                            }
                            else {
                                x.visible = false;
                            }
                        });
                        this.reportGridRef.refreshHeader();
                    }
                }

                this.setState({ reportType: reportSettings.reportType });
            }
            else if (reportSettings.reportType === 2) {
                this.setState({ reportType: reportSettings.reportType }, () => {
                    setTimeout(() => {
                        if (this.pivotView) {
                            if (this.pivotView.toolbarModule.reportList) {
                                this.pivotView.toolbarModule.reportList.dataSource = report.filter(r => r.ReportType === reportSettings.reportType).map(r => r.Name);
                            }
                            this.pivotView.dataSourceSettings.dataSource = report.dataSource;
            
                            this.pivotView.toolbarModule.currentReport =  "";
                            this.pivotView.setProperties({ dataSourceSettings: { columns: [], rows: [], values: [], filters: [] } }, false);
                            this.pivotView.dataSourceSettings.dataSource = reportSource.Data;   
                        }
                    }, 300);
                });
            }
        });
    }

    async onDeleteClick() {
        let { standardReportSetting } = this.state;

        const result = await ReportService.DeleteReport(standardReportSetting.selectedReport, source.token);
        if (!result.HasError) {
            let reportList = document.getElementById('ddlReportList').ej2_instances[0];
            if (reportList) {
                reportList.dataSource = reportList.dataSource.filter(f => f.Id !== standardReportSetting.selectedReport);
            }

            this.props.updateUserMenu();
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessDelete.replace("{context}", "Rapport"));
            this.deleteConfirmationDialog.hide();
            
            this.customColumns = [];
            standardReportSetting.selectedReport = null;
            standardReportSetting.selectedReportName = '';
    
            this.setState({ standardReportSetting }, () => {
                this.reportGridRef.refreshColumns();
            });
        }
        else {
            this.dialogOpen("Advarsel", result.ErrorMessage);
        }
    }

    async onActionComplete(args) {
        if (args.requestType === "columnstate") {
            const { reportSettings } = this.state;
            // let columns = args.columns.filter(x => x.visible).map(c => c.field);
            let hiddenColumns = args.columns.filter(x => !x.visible).map(c => c.field);
            let currentColumns = this.reportGridRef.columns.filter(x => x.visible).map(c => c.field);

            if (hiddenColumns.length > 0) {
                hiddenColumns.forEach(h => {
                    if (currentColumns.some(c => c === h)) {
                        let colIndex = currentColumns.indexOf(h);
                        currentColumns.splice(colIndex, 1);
                    }
                });
            }

            let reportSource = { AggregateFields: [], Data: [] };
            let params = [...currentColumns];

            switch(reportSettings.reportMenuName) {
                case 'Produkt':
                    reportSource = await ReportService.GetProductsReport(this.customerId, params, source.token);
                    break;
                case 'Indkøb':
                    reportSource = await ReportService.GetOrdersReport(this.customerId, params, source.token);
                    break;
                case 'Brugere':
                    reportSource = await ReportService.GetUsersReport(this.customerId, params, source.token);
                    break;
                case 'Leverandører':
                    reportSource = await ReportService.GetSupplierReport(this.customerId, params, source.token);
                    break;
                case 'Kunder':
                    reportSource = await ReportService.GetCustomersReport(this.customerId, params, source.token);
                    break;
                case 'Automater':
                    reportSource = await ReportService.GetMachineReport(this.customerId, params, source.token);
                    break;
                case 'Lager':
                    reportSource = await ReportService.GetStockReport(this.customerId, params, source.token);
                    break;
                case 'Forbrug':
                    reportSource = await ReportService.GetConsumeReport(this.customerId, params, source.token);
                    break;
            }

            if (!reportSource.HasError) {
                this.columns = reportSource.Columns;
                var dateFields = [];
                var timeFields = [];
                var date = new Date();

                let visibleColumns = Object.keys(reportSource.Data[0]);
                this.columns.forEach(c => {
                    c.Visible = visibleColumns.some(v => v === c.FieldName);
                    if (c.Type === "DateTime") {
                        dateFields.push(c.FieldName);
                    }
                    if (c.Type === "TimeSpan") {
                        timeFields.push(c.FieldName);
                    }
                });
                
                reportSource.Data.forEach(d => {
                    this.columns.forEach(c => {
                        if (!(c.FieldName in d)) {
                            d[c.FieldName] = null;
                        }
                    })

                    dateFields.forEach(field => {
                        if (d[field]) {
                            d[field] = new Date(d[field]);
                        }
                    });
                    timeFields.forEach(field => {
                        if (d[field]) {
                            d[field] = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), d[field].Hours, d[field].Minutes, d[field].Seconds);
                        }
                    });
                });

                this.reportGridRef.dataSource = reportSource.Data;
                this.reportGridRef.dataBind();
                this.props.updateUserMenu();
            }
            else {
                this.dialogOpen("Advarsel", reportSource.ErrorMessage);
            }
        }
    }

    async onReportMenuChange(args) {
        if (this.ddlReportTypeRef.value === 1) {
            let columns = this.menuColumns.find(x => x.Id === args.value);
            this.columnsListView.dataSource = columns.Columns.sort((a,b) => (a.Translation > b.Translation) ? 1 : -1 );
            this.columnsListView.dataBind();
            this.columnsListView.refresh();
    
            let columnsSelection = document.getElementsByClassName('standard-report-columnselection')[0];
            if (columnsSelection) {
                if (this.ddlReportTypeRef.value === 1) {
                    this.chkSelectAllRef.checked = true;
                    document.getElementsByClassName("select-all-columns")[0].style.backgroundColor = "#FEF8E5";
                    this.columnsListView.checkAllItems();
                    columnsSelection.style.display = 'block';
                }
                else {
                    columnsSelection.style.display = 'none';
                }
            }
        }
    }

    async onReportTypeChange(args) {
        if (args.isInteracted) {
            if (this.ddlReportMenusRef.value && args.value === 1) {
                let columns = this.menuColumns.find(x => x.Id === this.ddlReportMenusRef.value);
                this.columnsListView.dataSource = columns.Columns.sort((a,b) => (a.Translation > b.Translation) ? 1 : -1 );
                this.columnsListView.dataBind();
                this.columnsListView.refresh();
        
                let columnsSelection = document.getElementsByClassName('standard-report-columnselection')[0];
                if (columnsSelection) {
                    if (this.ddlReportTypeRef.value === 1) {
                        this.chkSelectAllRef.checked = true;
                        document.getElementsByClassName("select-all-columns")[0].style.backgroundColor = "#FEF8E5";
                        this.columnsListView.checkAllItems();
                        columnsSelection.style.display = 'block';
                    }
                    else {
                        columnsSelection.style.display = 'none';
                    }
                }
            }
            else {
                let columnsSelection = document.getElementsByClassName('standard-report-columnselection')[0];
                columnsSelection.style.display = 'none';
            }
        }
    }

    onSelectAllChange(args) {
        if (args.checked) {
            this.columnsListView.checkAllItems();
            document.getElementsByClassName("select-all-columns")[0].style.backgroundColor = "#FEF8E5";
        }
        else {
            this.columnsListView.uncheckAllItems();
            document.getElementsByClassName("select-all-columns")[0].style.backgroundColor = "";
        }
    }

    onColumnListViewSelect(args) {
        let selectedItems = this.columnsListView.getSelectedItems();
        let dataCount = this.columnsListView.dataSource.length;
        if (selectedItems.data.length !== dataCount) {
            this.chkSelectAllRef.indeterminate = true;
        }
        else {
            this.chkSelectAllRef.indeterminate = false;
            this.chkSelectAllRef.checked = true;
        }
    }

    onColumnDragStart(args) {
        this.hasDraggedColumn = true;
    }

    onCreated() {
        if (this.reportTemplates.length <= 0) {
            this.reportGridRef.toolbarModule.enableItems(['delete'], false);
        }
        // if (this.standardReportData.length <= 0) {
        //     this.reportGridRef.toolbarModule.enableItems(['add', 'save', 'delete'], false);
        // }
    }

    onPivotCreated() {
        let pivotView = document.getElementById('PivotView');
        let loadReportElement = pivotView.querySelector('.e-pivot-toolbar .e-toolbar-left .e-toolbar-item.e-template .e-reportlist-drop');
        if (loadReportElement) {
            loadReportElement.style.width = "200px";
        }
    }

    onRefreshReportOptionsClick() {
        let { reportSettings } = this.state;

        if (reportSettings.reportType === 1) {
            let columns = this.reportGridRef.columns.filter(c => c.visible).map(m => { return { FieldName : m.field, Translation: m.headerText } });

            if (this.columnsListView.dataSource.length === this.reportGridRef.columns.length) {
                this.chkSelectAllRef.checked = true;
                this.chkSelectAllRef.indeterminate = false;
            }
            else {
                this.chkSelectAllRef.checked = false;
                this.chkSelectAllRef.indeterminate = true;
            }
            this.columnsListView.uncheckAllItems();
            columns.forEach(c => {
                this.columnsListView.checkItem(c);
            })
        }
        this.udReportDialog.buttons = this.udReportDialogButtons;
        this.udReportDialog.show();
    }

    dialogOpen(header, content, buttons = null) {
        this.notificationDialog.header = header;
        this.notificationDialog.content = content;

        if (buttons) { 
            this.notificationDialog.buttons = buttons;
        }
        else {
            this.notificationDialog.buttons = this.buttons;
        }

        this.notificationDialog.show();
    }

    footerSum(props) {
        return (<span>Sum: {props.Sum ? props.Sum.toFixed(2) : ""}</span>)
    }

    dataBound() {
        if (this.dataChanged) {
            this.reportGridRef.columns.forEach(gc => {
                gc.visible = this.columns.some(c => c.FieldName === gc.field && c.Visible);
            });
            this.reportGridRef.refreshColumns();
            this.dataChanged = false;
        }

        this.reportGridRef.autoFitColumns();
    }
    
    render() {
        const { loading, breadcrumbs, redirect, reportType, reportSettings, reportSource } = this.state;

        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        return (
            <div className="content-pane-wrapper user-defined-report-wrapper">
                <div><Breadcrumb breadcrumbs={breadcrumbs} /></div>
                <div className="module-header">
                    <h1>{this.pageName}</h1>
                </div>
                {
                    (reportSettings.reportType !== null && reportSettings.reportmenu !== null)  &&
                    <div className="report-selection-wrapper">
                        <div className="report-selection">
                            <span className="label">Report Type:</span>
                            <span>{reportSettings.reportTypeName}</span>
                        </div>
                        <div className="report-selection">
                            <span className="label">Report Menu:</span>
                            <span>{reportSettings.reportMenuName}</span>
                        </div>
                        <div className="report-selection">
                            <button className="e-btn e-primary" onClick={this.onRefreshReportOptionsClick}>Refresh report options</button>
                        </div>
                    </div>
                }
                {
                    (reportSettings.reportType === null && reportSettings.reportmenu === null)  &&
                    <div className="report-selection-wrapper">
                        <button className="e-btn e-primary" onClick={this.onRefreshReportOptionsClick}>Refresh report options</button>
                    </div>
                }
                {
                    reportType === 1 &&
                    <div>
                        {/* toolbarClick={this.clickHandler} actionBegin={this.actionBegin.bind(this)} resizeStop={this.onResizeStop.bind(this)} */}
                        <GridComponent ref={ref => this.reportGridRef = ref } dataSource={this.standardReportData} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings}
                            selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both"
                            allowReordering={true} allowResizing={true} showColumnChooser={true} toolbar={this.toolbarOptions} toolbarClick={this.standardTableToolbarClicked}
                            allowExcelExport={true} allowPdfExport={true} columnDragStart={this.onColumnDragStart.bind(this)} created={this.onCreated.bind(this)} 
                            actionComplete={this.onActionComplete.bind(this)} dataBound={this.dataBound.bind(this)}>
                            <ColumnsDirective>
                                {
                                    this.customColumns.length > 0 ?
                                    this.customColumns.map(column => {
                                        return (
                                            // <ColumnDirective index={column.index} field={column.field} headerText={column.headerText} 
                                            //     visible={column.visible} width={column.width} />
                                            column.Type && column.Type === "DateTime" ?
                                            <ColumnDirective field={column.field} headerText={column.headerText} type="date" format={{ type: 'date', format: 'dd-MM-yyyy' }} visible={column.Visible} index={column.index} width={column.width} />
                                            :
                                            column.Type && column.Type === "TimeSpan"
                                            ?
                                            <ColumnDirective field={column.field} headerText={column.headerText} type="datetime" format={{ type: 'date', format: 'HH:mm' }} visible={column.Visible} index={column.index} width={column.width} />
                                            :
                                            <ColumnDirective field={column.field} headerText={column.headerText} visible={column.Visible} index={column.index}  width={column.width} />
                                        )
                                    })
                                    :
                                    this.columns.map(column => {
                                        return (
                                            // <ColumnDirective field={column.FieldName} headerText={column.Translation} visible={column.Visible} width='100' />
                                            column.Type && column.Type === "DateTime" ?
                                            <ColumnDirective field={column.FieldName} headerText={column.Translation} type="date" format={{ type: 'date', format: 'dd-MM-yyyy' }} visible={column.Visible} width='100' />
                                            :
                                            column.Type && column.Type === "TimeSpan"
                                            ?
                                            <ColumnDirective field={column.FieldName} headerText={column.Translation} type="datetime" format={{ type: 'date', format: 'HH:mm' }} visible={column.Visible} width='100' />
                                            :
                                            <ColumnDirective field={column.FieldName} headerText={column.Translation} visible={column.Visible} width='100' />
                                        )
                                    })
                                }
                            </ColumnsDirective>
                            {
                                reportSource.AggregateFields.length > 0 &&
                                <AggregatesDirective>
                                    <AggregateDirective>
                                        <AggregateColumnsDirective>
                                            {
                                                reportSource.AggregateFields.map(f => {
                                                    return <AggregateColumnDirective field={f} type='Sum' footerTemplate={this.footerSum}> </AggregateColumnDirective>
                                                })
                                            }
                                        </AggregateColumnsDirective>
                                    </AggregateDirective>
                                </AggregatesDirective>
                            }
                            <GridInject services={[Page, Sort, Filter, Selection, GridToolbar, Edit, Search, Reorder, Resize, Aggregate, ColumnChooser, GridExcelExport, GridPdfExport]} />
                        </GridComponent>
                    </div>
                }
                {
                    reportType === 2 &&
                    <React.Fragment>
                    <div className="pivot-field">
                        <DropDownListComponent id="ddlReportAvailability" ref={ref => this.ddlPivotReportAvailabilityRef = ref} dataSource={this.reportAvailability} placeholder={Localization.Report.ReportAvailability} 
                            fields={{ text: 'Key', value: 'Value' }} floatLabelType="Auto" locale="da" />
                    </div>
                    <div className="pivot-table-wrapper">
                        <PivotViewComponent id='PivotView' ref={ref => this.pivotView = ref} width={'100%'} height={'580'} gridSettings={{ columnWidth: 140 }} displayOption={this.displayOption}
                            locale="da" allowExcelExport={true} allowPdfExport={true} showToolbar={true} toolbar={this.pivotToolbarOptions} showFieldList={true} created={this.onPivotCreated}
                            newReport={this.newReport.bind(this)} renameReport={this.renameReport.bind(this)} removeReport={this.removeReport.bind(this)} loadReport={this.loadReport.bind(this)} 
                            fetchReport={this.fetchReport.bind(this)} saveReport={this.saveReport.bind(this)} beforeExport={this.beforeExport.bind(this)}>
                            <Inject services={[ FieldList, Toolbar, PDFExport, ExcelExport ]} />
                        </PivotViewComponent>
                    </div>
                    </React.Fragment>
                }
                <div>
                    <DialogComponent isModal={true} buttons={this.buttons} width='auto' ref={dialog => this.notificationDialog = dialog} target='body' visible={false} showCloseIcon={true} 
                        cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true}/>
                    <DialogComponent isModal={true} buttons={this.deleteConfirmationbuttons} width='auto' ref={dialog => this.deleteConfirmationDialog = dialog} target='body' visible={false} showCloseIcon={true} 
                        cssClass="dialog-notification" animationSettings={this.animationSettings} header="Advarsel" allowDragging={true} enableResize={true}/>
                    <DialogComponent id="c-report-dialog" isModal={true} buttons={this.standardReportButtons} width='350px' ref={dialog => this.standardReportSaveDialog = dialog} target='body' 
                        visible={false} showCloseIcon={true} animationSettings={this.animationSettings} header="Report Details" allowDragging={true} enableResize={true}>
                            <div className="dialog-field">
                                <TextBoxComponent ref={ref => this.reportName = ref} placeholder="Report name" floatLabelType="Auto"></TextBoxComponent>
                            </div>
                            <div className="dialog-field">
                                <DropDownListComponent id="ddlReportAvailability" ref={ref => this.ddlReportAvailabilityRef = ref} dataSource={this.reportAvailability} placeholder={Localization.Report.ReportAvailability} 
                                    fields={{ text: 'Key', value: 'Value' }} floatLabelType="Auto" locale="da" value={this.state.standardReportSetting.selectedReportLevel} />
                            </div>
                    </DialogComponent>
                    <DialogComponent ref={dialog => this.udReportDialog = dialog} id="c-report-info-dialog" isModal={true} buttons={this.udReportDialogButtons} width='450px' target='.user-defined-report-wrapper' 
                        visible={false} animationSettings={this.animationSettings} header="Select Report Options" allowDragging={true} enableResize={true}>
                        <div className="dialog-field">
                            <DropDownListComponent id="ddlReportType" ref={ref => this.ddlReportTypeRef = ref} dataSource={this.reportType} placeholder={`${Localization.Report.ReportType}*`}
                                fields={{ text: 'Name', value: 'Id' }} floatLabelType="Auto" locale="da" change={this.onReportTypeChange} value={1} />
                        </div>
                        <div className="dialog-field">
                            <DropDownListComponent id="ddlReportMenus" ref={ref => this.ddlReportMenusRef = ref} dataSource={this.reportMenuItems} placeholder={`${Localization.Report.ReportMenu}*`}
                                fields={{ text: 'Name', value: 'Id' }} floatLabelType="Auto" locale="da" change={this.onReportMenuChange} />
                        </div>
                        <div className="dialog-field standard-report-columnselection">
                            <label className="label">Select Columns:</label>
                            <div className="report-column-selection">
                                <div className="select-all-columns">
                                    <CheckBoxComponent ref={ref => this.chkSelectAllRef = ref} label='Select All' change={this.onSelectAllChange}></CheckBoxComponent>
                                </div>
                                <ListViewComponent ref={ref => this.columnsListView = ref} fields={{ id: "FieldName", text: "Translation" }} dataSource={this.columnsList} showCheckBox={true} select={this.onColumnListViewSelect}></ListViewComponent>
                            </div>
                        </div>
                    </DialogComponent>
                </div>
            </div>
        )
    }
}

export default UserDefinedReports;
