import { UserManager, WebStorageStateStore } from 'oidc-client';
import AppSettings from '../settings/AppSettings';
import { parseJwt } from '../utilities/Helper';
import { StorageKeys } from '../settings/StorageKeys';

class AuthenticationService {
    constructor() {
        const settings = {
            authority: AppSettings.AUTH_URL,
            client_id: AppSettings.AUTH_CLIENT_ID,
            redirect_uri: `${AppSettings.CLIENT_URL}signin-callback`,
            silent_redirect_uri: `${AppSettings.CLIENT_URL}silent-callback`,
            post_logout_redirect_uri: AppSettings.CLIENT_URL,
            scope: 'openid profile Read Write Account Products Files Machines Orders WebGateway Messages Mcus',
            response_type: 'id_token token',
            userStore: new WebStorageStateStore({ store: localStorage })
        };

        this.userManager = new UserManager(settings);
        this.userManager.events.addAccessTokenExpired(() => {
            this.logout();
        });
    }

    getUser = async () => {
        let user = await this.userManager.getUser();
        let loggedInCustomer = sessionStorage.getItem(StorageKeys.LoggedInAs_Key);

        if (loggedInCustomer) {
            let parsedUser = parseJwt(loggedInCustomer);
            parsedUser.profile = {};
            parsedUser.profile.sub = parsedUser.sub;
            parsedUser.profile.isCustomer = "true";
            parsedUser.profile.parentAcctId = parseInt(user.profile.sub);
            parsedUser.access_token = loggedInCustomer;
            return parsedUser;
        }

        return user;
    }

    login = () => {
        return this.userManager.signinRedirect();
    }

    loginCallback = () => {
        return this.userManager.signinRedirectCallback();
    }

    renewToken = () => {
        return this.userManager.signinSilent();
    }

    logout = () => {
        return this.userManager.signoutRedirect();
    }
}

let authenticationService = new AuthenticationService();
Object.freeze(authenticationService);
export default authenticationService;