import { loadCldr, L10n } from '@syncfusion/ej2-base';

// import * as numberingSystems from '../formatting/cldr-data/supplemental/numberingSystems.json';
// import * as currencyData from '../formatting/cldr-data/supplemental/currencyData.json';
// import * as numbers from '../formatting/cldr-data/main/de/numbers.json';
// import * as currencies from '../formatting/cldr-data/main/de/currencies.json';

// import * as gregorian from 'cldr-data/main/da/ca-gregorian.json';

loadCldr(
    require('cldr-data/main/da/numbers.json'),
    require('cldr-data/main/da/ca-gregorian.json'),
    require('cldr-data/main/da/timeZoneNames.json')
);

L10n.load({
    'da': {
        'pager': {
            'currentPageInfo': 'Side {0} af {1}',
            'totalItemsInfo': '({0} poster)',
            'firstPageTooltip': 'Gå til første side',
            'lastPageTooltip': 'Gå til sidste side',
            'nextPageTooltip': 'Gå til næste side',
            'previousPageTooltip': 'Gå til forrige side',
            'pagerDropDown': 'Poster pr. side',
            'pagerAllDropDown': 'Poster',
            'All': "Alle"
        },
        'grid': {
            'Add': 'Ny',
            'Edit': 'Redigere',
            'Delete': 'Slette',
            'SaveButton': 'Gem',
            'CancelButton': 'Annuller',
            'Update': 'Gem',
            'Cancel': 'Annuller',
            'EmptyRecord': 'Ingen poster at vise',
            'EditOperationAlert': 'Ingen data er valgt for redigering',
            'DeleteOperationAlert': 'Ingen data er valgt for sletning',
            'ConfirmDelete': 'Er du sikker på at du vil slette data?',
            'pagerDropDown': 'Poster pr. side',
            'pagerAllDropDown': 'Poster'
        },
        'pivotview': {
            'noValue': 'Ingen poster at vise',
            'total': 'Total',
            'grandTotal': 'Samlet total',
            'rowAxisPrompt': 'Slip række her',
            'columnAxisPrompt': 'Slip kolonne her',
            'valueAxisPrompt':	'Slip værdien her',
            'filterAxisPrompt':	'Slip filter her',
            'filter':	'Filter',
            'filtered':	'Filtreret',
            'sort':	'Sortere efter',
            'filters':	'Filterer',
            'rows':	'Rows', // FOR TRANSLATION
            'columns':	'Columns', // FOR TRANSLATION
            'values':	'Values', // FOR TRANSLATION
            'close':	'Close', // FOR TRANSLATION
            'cancel':	'Annuller',
            'delete':	'Slette',
            'calculatedField':	'Calculated Field', // FOR TRANSLATION
            'createCalculatedField':	'Create Calculated Field', // FOR TRANSLATION
            'fieldName':	'Enter the field name', // FOR TRANSLATION
            'error':	'Error', // FOR TRANSLATION
            'invalidFormula':	'Invalid formula.', // FOR TRANSLATION
            'dropAction':	'Calculated field cannot be place in any other region except value axis.', // FOR TRANSLATION
            'alert':	'Advarsel',
            'warning':	'Warning', // FOR TRANSLATION
            'ok':	'OK',
            'search':	'Søg',
            'drag':	'Drag', // FOR TRANSLATION
            'remove':	'Remove', // FOR TRANSLATION
            'sum':	'Sum', // FOR TRANSLATION
            'average':	'Average', // FOR TRANSLATION
            'count':	'Count', // FOR TRANSLATION
            'min':	'Min', // FOR TRANSLATION
            'max':	'Max', // FOR TRANSLATION
            'allFields':	'All Fields', // FOR TRANSLATION
            'formula':	'Formula', // FOR TRANSLATION
            'addToRow':	'Add to Row', // FOR TRANSLATION
            'addToColumn':	'Add to Column', // FOR TRANSLATION
            'addToValue':	'Add to Value', // FOR TRANSLATION
            'addToFilter':	'Add to Filter', // FOR TRANSLATION
            'emptyData':	'No records to display', // FOR TRANSLATION
            'fieldExist':	'A field already exists in this name. Please enter a different name.', // FOR TRANSLATION
            'confirmText':	'A calculation field already exists in this name. Do you want to replace it?', // FOR TRANSLATION
            'noMatches':	'No matches', // FOR TRANSLATION
            'format':	'Summaries values by', // FOR TRANSLATION
            'edit':	'Redigere',
            'clear':	'Clear', // FOR TRANSLATION
            'formulaField':	'Drag and drop fields to formula', // FOR TRANSLATION
            'dragField':	'Drag field to formula', // FOR TRANSLATION
        },
        'pivotfieldlist': {
            'staticFieldList': 'Pivot Field List', // FOR TRANSLATION
            'fieldList': 'Field List', // FOR TRANSLATION
            'dropFilterPrompt': 'Drop filter here', // FOR TRANSLATION
            'dropColPrompt': 'Drop column here', // FOR TRANSLATION
            'dropRowPrompt': 'Drop row here', // FOR TRANSLATION
            'dropValPrompt': 'Drop value here', // FOR TRANSLATION
            'addPrompt': 'Add field here', // FOR TRANSLATION
            'adaptiveFieldHeader': 'Choose field', // FOR TRANSLATION
            'centerHeader': 'Drag fields between axes below:', // FOR TRANSLATION
            'add': 'add', // FOR TRANSLATION
            'drag': 'Drag', // FOR TRANSLATION
            'filter': 'Filter', // FOR TRANSLATION
            'filtered': 'Filtered', // FOR TRANSLATION
            'sort': 'Sortere efter',
            'remove': 'Remove', // FOR TRANSLATION
            'filters': 'Filters', // FOR TRANSLATION
            'rows': 'Rows', // FOR TRANSLATION
            'columns': 'Columns', // FOR TRANSLATION
            'values': 'Values', // FOR TRANSLATION
            'calculatedField': 'Calculated Field', // FOR TRANSLATION
            'createCalculatedField': 'Create Calculated Field', // FOR TRANSLATION
            'fieldName': 'Enter the field name', // FOR TRANSLATION
            'error':	'Error', // FOR TRANSLATION
            'invalidFormula':	'Invalid formula.', // FOR TRANSLATION
            'search': 'Søg',
            'close': 'Close', // FOR TRANSLATION
            'cancel': 'Annuller',
            'delete': 'Delete', // FOR TRANSLATION
            'alert': 'Advarsel',
            'warning': 'Advarsel',
            'ok': 'OK', // FOR TRANSLATION
            'sum': 'Sum', // FOR TRANSLATION
            'average': 'Average', // FOR TRANSLATION
            'count': 'Count', // FOR TRANSLATION
            'min': 'Min', // FOR TRANSLATION
            'max': 'Max', // FOR TRANSLATION
            'allFields': 'All Fields', // FOR TRANSLATION
            'formula': 'Formula', // FOR TRANSLATION
            'fieldExist': 'A field already exists in this name. Please enter a different name.', // FOR TRANSLATION
            'confirmText': 'A calculation field already exists in this name. Do you want to replace it?', // FOR TRANSLATION
            'noMatches': 'No matches', // FOR TRANSLATION
            'format': 'Summaries values by', // FOR TRANSLATION
            'edit': 'Redigere',
            'clear': 'Clear', // FOR TRANSLATION
            'formulaField': 'Drag and drop fields to formula', // FOR TRANSLATION
            'dragField': 'Drag field to formula', // FOR TRANSLATION
        },
        'dropdowns': {
            'noRecordsTemplate': 'Ingen data fundet'
        },
        'filemanager': {
            "NewFolder": "Ny mappe",
            "Upload": "Upload",
            "Delete": "Slet",
            "Rename": "Omdøb",
            "Download": "Download",
            "Cut": "Klip",
            "Copy": "Kopiere",
            "Paste": "Indsæt",
            "SortBy": "Sortere efter",
            "Refresh": "Opdater",
            "Item-Selection": "Element valgt",
            "Items-Selection": "Elementer valgt",
            "View": "Vis",
            "Details": "Detaljer",
            "SelectAll": "Vælg alle",
            "Open": "Åben",
            "Tooltip-NewFolder": "Ny mappe",
            "Tooltip-Upload": "Upload",
            "Tooltip-Delete": "Slet",
            "Tooltip-Rename": "Omdøb",
            "Tooltip-Download": "Download",
            "Tooltip-Cut": "Klip",
            "Tooltip-Copy": "Kopiere",
            "Tooltip-Paste": "Indsæt",
            "Tooltip-SortBy": "Sortere efter",
            "Tooltip-Refresh": "Opdater",
            "Tooltip-Selection": "Ryd valgte",
            "Tooltip-View": "Vis",
            "Tooltip-Details": "Detaljer",
            "Tooltip-SelectAll": "Vælg alle",
            "Name": "Navn",
            "Size": "Størrelse",
            "DateModified": "Ændret",
            "DateCreated": "Oprettet dato",
            "Path": "Sti",
            "Modified": "Ændret",
            "Created": "Oprettet",
            "Location": "Lokation",
            "Type": "Type",
            "Permission": "Permission",
            "Ascending": "Stigende",
            "Descending": "Aftagende",
            "View-LargeIcons": "Store ikoner",
            "View-Details": "Detaljer",
            "Search": "Søg",
            "Button-Ok": "OK",
            "Button-Cancel": "Annullere",
            "Button-Yes": "Ja",
            "Button-No": "Nej",
            "Button-Create": "Opret",
            "Button-Save": "Gem",
            "Header-NewFolder": "Mappe",
            "Content-NewFolder": "Indtast mappe navn",
            "Header-Rename": "Omdøb",
            "Content-Rename": "Indtast navn",
            "Header-Rename-Confirmation": "Bekræft du vil omdøbe",
            "Content-Rename-Confirmation": "Hvis du ændrer filtypenavn",
            "Header-Delete": "Slet fil",
            "Content-Delete": "Er du sikker på du vil slette denne fil?",
            "Header-Multiple-Delete": "Slet alle valgte filer",
            "Content-Multiple-Delete": "Er du sikker på du vil slette disse {0} filer?",
            "Header-Duplicate": "Filen eksisterer",
            "Content-Duplicate": "Eksisterer allerede, er du sikker på du vil erstatte den",
            "Header-Upload": "Upload fil",
            "Error": "Fejl",
            "Validation-Empty": "Fil eller mappe navn kan ikke være tomt.",
            "Validation-Invalid": 'Fil eller mappe navn {0] indeholder ikke godkendte karakter, må ikke slutte med punktum eller mellemrum, og indeholde V: * ? " < > |',
            "Validation-NewFolder-Exists": "Fil eller mappe med navn {0} eksisterer allerede.",
            "Validation-Rename-Exists": "Kan ikke omdøbe {0} til {1}.",
            "Folder-Empty": "Denne mappe er tom",
            "File-Upload": "Træk filer her for at uploade",
            "Search-Empty": "Træk filer her for at uploade.",
            "Search-Key": "Prøv med andre søgeord.",
            "Sub-Folder-Error": "Mappe destinationen er en undermappe af valgte mappe.",
            "Access-Denied": "Adgang ikke tilladt",
            "Access-Details": "Du har ikke tilladelse til denne mappe.",
            "Header-Retry": "Filen eksisterer allerede",
            "Content-Retry": "En fil med samme navn eksisterer allerede, hvad vil du gøre?",
            "Button-Keep-Both": "Gem begge",
            "Button-Replace": "Erstat",
            "Button-Skip": "Spring over",
            "ApplyAll-Label": "Gør dette for alle elementer"
        },
        'richtexteditor': {
            "align": "Juster",
            "alignments": "Justeringer",
            // "alternateHeader": "Alternativ tekst",
            "alttext": "Alternativ tekst",
            "backgroundColor": "Baggrundsfarve",
            "bold": "Fed",
            "browse": "Browse",
            "caption": "Billedtekst",
            "clearAll": "Slet alt",
            "clearFormat": "Ryd format",
            "copy": "Kopier",
            "createLink": "Indsæt link",
            "cut": "Klip",
            // "dialogCancel": "Annuleer",
            // "dialogInsert": "invoegen",
            // "dialogUpdate": "Bijwerken",
            "dimension": "Dimension",
            "display": "Visningstype",
            "editLink": "Rediger link",
            "fontColor": "Skrift farve",
            "fontName": "Skriftnavn",
            "fontSize": "Skriftstørrelse",
            "formats": "Formater",
            "fullscreen": "Fuld skærm",
            "image": "Indsæt billede",
            // "imageAlternateText": "Alternatieve tekst",
            // "imageCaption": "onderschrift",
            // "imageDeviceUploadMessage": "Klik hier om te uploaden",
            // "imageHeader": "Voeg afbeelding in",
            // "imageHeight": "Hoogte",
            // "imageLinkHeader": "U kunt ook een link van internet opgeven",
            // "imageSizeHeader": "Afbeeldingsgrootte",
            // "imageUploadMessage": "Zet hier een afbeelding neer of klik om te uploaden",
            // "imageUrl": "URL",
            // "imageWidth": "Breedte",
            "indent": "Forøg indrykning",
            "insertLink": "Indsæt link",
            "insertcode": "Indsæt kode",
            "italic": "Kursiv",
            "justifyCenter": "Centreret",
            "justifyFull": "Anvend lige margener",
            "justifyLeft": "Juster til venstre",
            "justifyRight": "Juster til højre",
            // "linkHeader": "Link invoegen",
            // "linkOpenInNewWindow": "Open de link in een nieuw venster",
            // "linkText": "Displaytekst",
            // "linkTooltipLabel": "tooltip",
            // "linkWebUrl": "Webadres",
            "lowerCase": "Små bogstaver",
            "maximize": "Maksimer",
            "minimize": "Minimer",
            "openLink": "Åben link",
            "orderedList": "Nummereret liste",
            "outdent": "Reducer indrykning",
            "paste": "Indsæt",
            "preview": "Eksempel",
            // "print": "Drucken",
            "redo": "Gentag",
            "remove": "Fjern",
            "removeLink": "Fjern link",
            "replace": "Erstat",
            "sourcecode": "Kildekode",
            "strikethrough": "Gennemstreget",
            "subscript": "Sænket skrift",
            // "superscript": "Überschrift",
            "underline": "Understreget",
            "undo": "Fortryd",
            "unorderedList": "Punktliste",
            "upperCase": "Store bogstaver",
            "viewside": "Vis side",
            // "zoomIn": "hineinzoomen",
            // "zoomOut": "Rauszoomen"
        }
    }
});