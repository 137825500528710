import React, { Component } from 'react';
import MachineMapper from '../../components/MachineMapper';

import DividerHighImg from '../../assets/machines/standalone/divider_high.png';
import DividerLowImg from '../../assets/machines/standalone/divider_low.png';
import WeightshelfFullImg from '../../assets/machines/standalone/weightshelf_full.png';
import WeightshelfHalfImg from '../../assets/machines/standalone/weightshelf_half.png';
import Localization from '../../utilities/Localization';
import { getMachineImage, predefinedModules } from './PredefinedModules';
import SettingsService from '../../services/SettingsService';

import Axios from 'axios';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class ConfigureModule extends Component {
    constructor(props) {
        super(props);
        let tempStoredData = JSON.stringify(props.data);
        this.state = {
            loading: true,
            data: JSON.parse(tempStoredData),
            isInteracting: false,
            isRemoving: false,
            interaction: '',
            selectedModule: props.selectedModule,
            activeElement: null,
            elementsList: []
        };

        this.onRemoveElement = this.onRemoveElement.bind(this);
    }

    async componentDidMount() {
        source.cancel();
        source = CancelToken.source();

        let { elementsList } = this.state;
        const elements = await SettingsService.GetMachineElements(source.token);
        elementsList = elements;

        this.setState({ loading: false, elementsList }, () => {
            let removeButton = document.querySelector('.btn-remove-element');
            let finishButton = document.querySelector('.btn-finish');
            if (removeButton) {
                finishButton.style.width = removeButton.clientWidth + 'px';
            }
            
            this.onResize();
            window.addEventListener('resize', this.onResize);
        });
    }

    onResize() {
        let elementsPanel = document.querySelector('.elements-content-pane');
        // let modulesPanelWrapper = document.querySelector('.build-machine-wrapper');

        elementsPanel.style.height = `calc(100vh - ${330}px)`;

        // if (modulesPanelWrapper) {
        //     elementsPanel.style.maxHeight = modulesPanelWrapper.offsetHeight + 'px';
        // }
        
        let elementsList = document.querySelector('.elements-list-wrapper');
        if (elementsList) {
            elementsList.style.maxHeight = `calc(100vh - ${330}px)`;
            elementsList.style.height = `calc(100vh - ${330}px)`;
        }
    }

    componentWillUnmount() {
        source.cancel();
        window.removeEventListener('resize', this.onResize);
    }

    highlightElements(module) {
        let dividerLowEnabled = false, dividerHighEnabled = false, fullShelfEnabled = false, halfShelfEnabled = false;
        let prevShelf = null;

        for (let index = 0; index < module.Shelves.length; index++) {
            const shelf = module.Shelves[index];
            const nextShelf = index + 1 > module.Shelves.length + 1 ? null : module.Shelves[index + 1];
            const isWeightShelf = shelf.ShelfType === 2 || shelf.ShelfType === 3;

            // Divider Low
            if (shelf.DividerLow === false && shelf.DividerHigh === false && isWeightShelf) {
                if (prevShelf && prevShelf.ShelfType === 3) {
                    dividerLowEnabled = true;
                }
                else if (prevShelf && prevShelf.ShelfType === 1) {
                    
                }
                else {
                    if (nextShelf) {
                        if (shelf.ShelfType === 3) {
                            dividerLowEnabled = true;
                        }
                        // if (shelf.ShelfType !== 2 && nextShelf.ShelfType !== 3) {
                        //     dividerLowEnabled = true;
                        // }
                        // if (shelf.ShelfType !== 2 && nextShelf.ShelfType !== 3) {
                        //     dividerLowEnabled = false;
                        // }
                        // else {
                        //     dividerLowEnabled = true;
                        // }
                    }
                    else {
                        dividerLowEnabled = true;
                    }
                }
            }
            else if (shelf.DividerLow === false && shelf.DividerHigh === false && shelf.ShelfType === 1) {
                if (prevShelf && prevShelf.ShelfType === 3) {
                    dividerLowEnabled = true;
                }
                else {
                    if (nextShelf) {
                        if (shelf.ShelfType === 1 || shelf.ShelfType === 3) {
                            if ((prevShelf && prevShelf.ShelfType === 1) && (nextShelf.ShelfType === 3)) {
                                // this setup is a 2 high divider, divider low must not be available for adding
                            }
                            else {
                                dividerLowEnabled = true;
                            }
                        }
                        // if ((shelf.ShelfType !== 2 && shelf.ShelfType !== 1 && shelf.ShelfType !== 6) && nextShelf.ShelfType !== 3) {
                        //     dividerLowEnabled = false;
                        // }
                        // else {
                        //     dividerLowEnabled = true;
                        // }
                    }
                    else {
                        dividerLowEnabled = true;
                    }
                }
            }

            // Divider high
            if (prevShelf) {
                if (prevShelf.ShelfType === 1 && prevShelf.DividerLow === true && !shelf.DividerHigh) {
                    dividerHighEnabled = true;
                }
                else if (prevShelf.ShelfType === 1 && prevShelf.DividerHigh === true && !shelf.DividerHigh) {
                    dividerHighEnabled = true;
                }
            }

            // Full shelf
            if (shelf.ShelfType === 1) {
                if (nextShelf && !nextShelf.DividerHigh) {
                    fullShelfEnabled = true;
                }
            }
            // if (nextShelf) {
            //     if (!nextShelf.DividerHigh) {
            //         fullShelfEnabled = true;
            //     }
            //     else {
            //         fullShelfEnabled = false;
            //     }
            // }
            // else {
            //     fullShelfEnabled = true;
            // }

            // Half shelf
            if (shelf.ShelfType === 1 && nextShelf.DividerHigh) {
                if (nextShelf && nextShelf.ShelfType === 2) {
                    // For 2 divider setup, should not be able to add if next shelf has half shelf
                }
                else if (prevShelf && prevShelf.ShelfType === 2) {
                    // For 2 divider setup, should not be able to add if prev shelf has half shelf
                }
                else {
                    halfShelfEnabled = true;
                }
            }

            prevShelf = shelf;
        }

        return {
            dividerLowEnabled, dividerHighEnabled, 
            fullShelfEnabled, halfShelfEnabled
        }
    }    

    onClick = (area, index, event) => {
        let { data, interaction, isRemoving, activeElement } = this.state;
        let { onUpdate, selectedModule, onNotify } = this.props;

        let tempData = {};
        Object.assign(tempData, data);
        let module = tempData.Modules.find(x => x.Id === selectedModule);

        if (!module) {
            module = tempData.Modules[selectedModule - 1];
        }
        
        let shelf = module.Shelves.find(x => x.SlotNo === area.SlotNo);
        let prevShelf = module.Shelves.find(x => x.SlotNo === area.SlotNo - 1);
        let nextShelf = module.Shelves.find(x => x.SlotNo === area.SlotNo + 1);
        let hasProduct = false;

        if (shelf.Boxes.length > 0) {
            if (shelf.Boxes.some(b => b.Rooms.length > 0)) {
                hasProduct = true;
            }
        }

        switch(interaction) {
            case 'add':
                if (hasProduct) {
                    onNotify("Advarsel", Localization.Machine.UnableToModifyIfContainsProduct);
                    return;
                }
                if (activeElement.Type === 'DividerLow') {
                    shelf.DividerLow = true;
                }
                else if (activeElement.Type === 'DividerHigh') {
                    if (prevShelf && !prevShelf.DividerHigh) {
                        prevShelf.DividerLow = true;
                    }

                    shelf.DividerHigh = true;
                }
                else if (activeElement.Type === 'FullShelf') {
                    shelf.ShelfType = 3;
                }
                else if (activeElement.Type === 'HalfShelf') {
                    shelf.ShelfType = 2;
                }
                break;
            case 'remove':
                if (hasProduct) {
                    onNotify("Advarsel", Localization.Machine.UnableToModifyIfContainsProduct);
                    return;
                }
                
                if (area.isDivider) {
                    shelf.DividerLow = false;
                    shelf.DividerHigh = false;

                    if (prevShelf) {
                        if (prevShelf.DividerLow && prevShelf.ShelfType === 2) {
                            prevShelf.ShelfType = 1;
                            prevShelf.DividerHigh = false;
                        }
                    }

                    if (nextShelf) {
                        if (nextShelf.DividerHigh) {
                            nextShelf.DividerHigh = false;
                            shelf.ShelfType = 1;
                        }
                    }
                }
                else {
                    if (nextShelf) {
                        if (nextShelf.DividerLow) {
                            nextShelf.DividerLow = false;
                        }
                    }

                    shelf.ShelfType = 1;
                }
                
                isRemoving = false;
                break;
            default:
                return;
        }

        onUpdate(tempData.Modules);
        this.setState({ interaction: '', isInteracting: false, activeElement: null, isRemoving, data });
    }

    onRemoveElement() {
        this.setState({ interaction: 'remove', isInteracting: true, isRemoving: true });
    }

    onAddElement(element, e) {
        this.setState({ interaction: 'add', isInteracting: true, activeElement: element, isRemoving: false });
    }

    render() {
        let { data, isInteracting, loading, elementsList, selectedModule, isRemoving, activeElement } = this.state;
        let machineMap = data.Modules.find(x => x.Id === selectedModule);

        if (!machineMap) {
            machineMap = data.Modules[selectedModule - 1];
        }
        
        let predefinedModule = predefinedModules.find(x => x.name === machineMap.Name);
        machineMap.Shelves.forEach(s => {
            let slot = predefinedModule.Shelves.find(x => s.SlotNo === x.SlotNo);
            Object.assign(s, { IsEditable: slot.IsEditable });
        });

        let machineImageSrc = getMachineImage(machineMap.Name);

        let { dividerLowEnabled, dividerHighEnabled, fullShelfEnabled, halfShelfEnabled } = this.highlightElements(machineMap);

        return (
            <div className="elements-content-pane">
                <div className="elements-list-wrapper">
                    <label className="label">Vælg element</label>
                    {
                        loading && <div className="sub-text loading-list">Indlæser elementer...</div>
                    }
                    {
                        !loading &&
                        <ul>
                            {
                                elementsList.map(element => {
                                    let imgSource = null;
                                    let isEnabled = false;

                                    switch(element.Type) {
                                        case "DividerLow":
                                            imgSource = DividerLowImg;
                                            isEnabled = dividerLowEnabled;
                                            break;
                                        case "DividerHigh":
                                            imgSource = DividerHighImg;
                                            isEnabled = dividerHighEnabled;
                                            break;
                                        case "FullShelf":
                                            imgSource = WeightshelfFullImg;
                                            isEnabled = fullShelfEnabled;
                                            break;
                                        case "HalfShelf":
                                            imgSource = WeightshelfHalfImg;
                                            isEnabled = halfShelfEnabled;
                                            break;
                                        default:
                                            return;
                                    }

                                    return (
                                        <li className={`element-item-wrapper ${isEnabled ? '' : 'disabled'}`} key={`element-item-${element.Id}`}>
                                            <div className="element-info-wrapper">
                                                <div className="element-description">
                                                    <div className="label">{element.Name}</div>
                                                    <div className="label">{`Vare nr.: ${element.No}`}</div>
                                                    <div>{element.Description}</div>
                                                </div>
                                                <div className="element-image"><img src={imgSource} alt={element.Name} /></div>
                                            </div>
                                            <div className="action-button">
                                                <button className="e-btn e-control e-primary" disabled={!isEnabled} onClick={this.onAddElement.bind(this, element)}>Tilføj</button>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    }
                </div>
                <div className="build-machine-wrapper">
                    <div className="centered-container">
                        <div className="build-machine-inner">
                            <div className="machine-configuration-wrapper is-selected">
                                <MachineMapper src={machineImageSrc} shelves={machineMap.Shelves} name={machineMap.Name} activeElement={activeElement}
                                    onClick={this.onClick} width={151} active={isInteracting} isRemoving={isRemoving} />
                            </div>
                        </div>
                        <div className="action-remove">
                            <button className="e-btn e-control btn-remove-element" onClick={this.onRemoveElement}>Fjern element</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ConfigureModule;
