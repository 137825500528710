import React from 'react'
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { Redirect } from 'react-router-dom';
import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Page, Sort, Toolbar, Selection, Search, Reorder, Resize, Edit } from '@syncfusion/ej2-react-grids';
import Axios from 'axios';
import QueryString from 'query-string';

import BaseComponent from '../components/BaseComponent';
import Loading from '../components/Loading';
import Localization from '../utilities/Localization';
import MessageHelper from '../utilities/MessageHelper';
import SettingsService from '../services/SettingsService';
import SessionService from '../services/SessionService';
import VideoService from '../services/File/VideoService';
import MessageService from '../services/Messages/MessagesService';
import Image from '../components/Image';
import placeHolderImg from '../assets/placeholders/img_placeholder_108x80.jpg';
import NavigationPrompt from '../components/NavigationPrompt';
import AppSettings from '../settings/AppSettings';
import { buildGridSettings } from '../utilities/Helper';

import '../styles/video-archive.scss';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class VideoArchive extends BaseComponent {
    moduleName = "Video Arkiv";
    pageName = "Video Arkiv";
    findVideoGridSettingsKey = "VideoArchiveFV";
    findPictureGridSettingsKey = "VideoArchiveFP";
    retrievedVideoGridSettingsKey = "VideoArchiveRV";
    hasDraggedColumn = false;
    findVideoGridSettings = '';
    findPictureGridSettings = '';
    retrievedVideoGridSettings = '';

    constructor() {
        super();
        
        this.state = {
            loading: true,
            redirect: {
                to: '',
                isRedirect: false
            },
            currentTabSelected: 0,
            postNoteContent: '',
            stageDownload: {
                type: 0,
                Ids: []
            },
            stageDeletion: [],
            hasUnsavedChanges: false,
            gallery: {
                items: [],
                currentItem: {},
                hasNext: false,
                hasPrev: false
            },
        };

        this.tabHeader = [
            { text: "Find video ved træk" },
            { text: "Find billed ved log in" },
            { text: "Hentet filer" }
        ];

        this.pageSettings = {
            pageSize: 25,
            pageSizes: ['Alle', '5', '10', '20', '50', '100']
        };

        this.selectionSettings = {
            persistSelection: true
        }

        this.editSettings = {
            allowEditing: true,
        }

        this.filterSettings = {
            type: 'Menu'
        };

        this.findVideoData = [];
        this.findPictureData = [];
        this.retrieveVideoData = [];

        this.dialogOpen = this.dialogOpen.bind(this);
        this.init = this.init.bind(this);
        this.onRetrieveVideoToolbarClick = this.onRetrieveVideoToolbarClick.bind(this);
        this.onFindPictureToolbarClick = this.onFindPictureToolbarClick.bind(this);
        this.onFindVideoToolbarClick = this.onFindVideoToolbarClick.bind(this);
        this.onTabSelected = this.onTabSelected.bind(this);
        this.refreshDownloadedPicturesVideos = this.refreshDownloadedPicturesVideos.bind(this);

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.notificationButtons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        this.noteButtons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.noteDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];

        this.confirmationButtons = [
            {
                // Click the footer buttons to hide the Dialog
                click: () => {
                    let { stageDownload } = this.state;
                    stageDownload.type = 0;
                    stageDownload.Ids = [];
                    this.setState({ stageDownload }, () => {
                        this.confirmationDialog.hide();
                    });
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    content: 'Tilbage'
                }
            },
            {
                // Click the footer buttons to hide the Dialog
                click: () => {
                    this.processDownload();
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    //Enables the primary button
                    isPrimary: true,
                    content: 'OK'
                }
            }
        ]
        /* End Dialog options  */
    }

    async componentDidMount() {
        super.componentDidMount();
        source.cancel();
        source = CancelToken.source();

        // if (!this.hasPermission) {
        //     this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
        //     return;
        // }

        this.init();

        let { currentTabSelected, postNoteContent } = this.state;
        let { location } = this.props;
        let filter = { machine: '', location: '', id: '' };
        if (location.search) {
            let query = location.search.substring(1).toLowerCase().split('&');
            
            for (let index = 0; index < query.length; index++) {
                let val = QueryString.parse(query[index]);
                if ('tab' in val) {
                    try {
                        currentTabSelected = parseInt(val.tab);
                        if (isNaN(currentTabSelected)) {
                            throw new Error("Tab parameter should be numeric");
                        }

                        currentTabSelected -=  1;
                    } catch (error) {
                        currentTabSelected = 0;
                    }
                }
                if ('machine' in val) {
                    filter.machine = val.machine.toUpperCase();
                }
                if ('location' in val) {
                    filter.location = val.location;
                }
            }
        }

        const sessionInfo = await SessionService.GetSessionInfo();
        this.CustomerId = sessionInfo.Id;
        this.Email = sessionInfo.Account.Email;
        this.AccessToken = sessionInfo.access_token;

        let requests = [];

        requests.push(VideoService.GetVideosByPull(this.CustomerId, source.token));
        requests.push(VideoService.GetPicturesByLogin(this.CustomerId, source.token));
        requests.push(VideoService.RetrieveVideos(this.CustomerId, source.token));

        Promise.all(requests).then(response => {
            if (!response[0].HasError) {
                this.findVideoData = response[0];
            }

            if (!response[1].HasError) {
                this.findPictureData = response[1].map(d => {
                    return {
                        ...d,
                        Timestamp: d.Timestamp + "Z",
                        DateTimeStamp: d.Timestamp + "Z"
                    }
                });
                console.log(this.findPictureData);
            }
            
            if (!response[2].HasError) {
                this.retrieveVideoData = response[2].map(d => {
                    return {
                        ...d,
                        Timestamp: d.Timestamp + "Z",
                        DateTimeStamp: d.Timestamp + "Z"
                    }
                });
            }
        }).then(async () => {
            const findVideoGridSettings = await SettingsService.GetGridSettings(this.findVideoGridSettingsKey, source.token);
            if (!findVideoGridSettings.HasError) {
                this.findVideoGridSettings = findVideoGridSettings.Settings;
            }
    
            const findPictureGridSettings = await SettingsService.GetGridSettings(this.findPictureGridSettingsKey, source.token);
            if (!findPictureGridSettings.HasError) {
                this.findPictureGridSettings = findPictureGridSettings.Settings;
            }
    
            const retrievedVideoGridSettings = await SettingsService.GetGridSettings(this.retrievedVideoGridSettingsKey, source.token);
            if (!retrievedVideoGridSettings.HasError) {
                this.retrievedVideoGridSettings = retrievedVideoGridSettings.Settings;
            }
            
            switch (currentTabSelected) {
                case 0:
                    postNoteContent = "Filtrer tabellen nedenfor så tabellen viser de lokationer du ønsker at downloade videoer for, tryk derefter på knappen hent video";
                    break;
                case 1:
                    postNoteContent = "Filtrer tabellen nedenfor så tabellen viser de log ind du ønsker at downloade, tryk derefter på knappen hent Billede";
                    break;
                case 2:
                    postNoteContent = "";
                    break;
            }
    
            this.setState({ loading: false, postNoteContent, currentTabSelected }, () => {
                switch(currentTabSelected) {
                    case 0:
                        setTimeout(() => {
                            if (this.findVideoGridRef) {
                                if (filter.machine && filter.location) {
                                    this.findVideoGridRef.filterByColumn("BoxNoLetter", "equal", filter.machine);
                                    this.findVideoGridRef.filterByColumn("BoxNoDigit", "equal", filter.location);
                                    this.findVideoGridRef.selectRow(0, true);
                                    let selectedRow = this.findVideoGridRef.getSelectedRecords();
                                    let { stageDownload } = this.state;
                                    stageDownload.type = 1;
                                    stageDownload.Ids = [selectedRow[0].Id];
                                    this.setState({ stageDownload }, () => {
                                        this.processDownload();
                                    });
                                }
                            }
                        }, 300);
                        break;
                    case 2:
                        setTimeout(() => {
                            if (this.retrievedVideoGridRef) {
                                if (filter.machine) {
                                    this.retrievedVideoGridRef.filterByColumn("BoxNoLetter", "equal", filter.machine);
                                }
                                if (filter.location) {
                                    this.retrievedVideoGridRef.filterByColumn("BoxNoDigit", "equal", filter.location);
                                }
                            }
                        }, 300);
                        break;
                }

                window.addEventListener('wheel', this.onWheelScroll);
            });
        });
    }

    componentWillUnmount() {
        source.cancel();
        window.removeEventListener('wheel', this.onWheelScroll);
    }

    init() {
        this.findVideoToolbarOptions = [
            { text: 'Hent Video', tooltipText: 'Hent Video', id: 'download', prefixIcon: 'e-print' },
            { text: Localization.General.GridResetFilter, tooltipText: Localization.General.GridResetFilter, id: 'reset_filter', prefixIcon: 'e-cancel' }
        ];
        this.findPictureToolbarOptions = [
            { text: 'Hent Billede', tooltipText: 'Hent Billede', id: 'download', prefixIcon: 'e-print' },
            { text: Localization.General.GridResetFilter, tooltipText: Localization.General.GridResetFilter, id: 'reset_filter', prefixIcon: 'e-cancel' }
        ];
        this.retrievedVideoToolbarOptions = [
            { text: 'Send til mail', tooltipText: 'Send til mail', id: 'send_to_mail', prefixIcon: 'e-print' },
            { text: 'Se video, billede', tooltipText: 'Se video, billede', id: 'watch', prefixIcon: 'e-external-link' },
            { text: 'Favorit', tooltipText: 'Favorit', id: 'mark_favorite', prefixIcon: 'e-favorite' },
            { text: 'Slette', tooltipText: 'Slette', id: 'delete', prefixIcon: 'e-delete' },
            'Edit', 'Update', 'Cancel',
            { text: 'Åben note', tooltipText: 'Åben note', id: 'open_note', prefixIcon: 'e-external-link' },
            { text: Localization.General.GridResetFilter, tooltipText: Localization.General.GridResetFilter, id: 'reset_filter', prefixIcon: 'e-cancel' }
        ];
    }

    onColumnDragStart(args) {
        this.hasDraggedColumn = true;
    }

    async processDownload() {
        const { stageDownload } = this.state;

        const result = await VideoService.Download(stageDownload.type, this.CustomerId, stageDownload.Ids, source.token);

        if (!result.HasError) {
            let contextMessage = "video";
            if (stageDownload.type === 2) {
                contextMessage = "Billede";
            }

            this.confirmationDialog.hide();
            // this.dialogOpen(Localization.General.SuccessHeader, Localization.VideoArchive.SuccessfullyRequestForDownload.replace("{context}", contextMessage));

            if (this.findPictureGridRef) {
                this.findPictureGridRef.clearSelection();
            }

            if (this.findVideoGridRef) {
                this.findVideoGridRef.clearSelection();
            }

            stageDownload.type = 0;
            stageDownload.Ids = [];
            this.setState({ stageDownload });
        }
        else {
            this.confirmationDialog.hide();
            this.dialogOpen("Advarsel", result.ErrorMessage);

            stageDownload.type = 0;
            stageDownload.Ids = [];
            this.setState({ stageDownload });
        }
    }

    //#region Grid events
    onFindVideoCreated() {
        if (this.findVideoGridSettings) {
            let settings = JSON.parse(this.findVideoGridSettings);
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(column => {
                        if (column.FromIndex !== column.ToIndex) {
                            var tempIndex = this.findVideoGridRef.getColumnIndexByField(column.Field);
                            if (tempIndex !== column.ToIndex){
                                this.findVideoGridRef.reorderColumnByIndex(column.FromIndex, column.ToIndex);
                            }
                        }
    
                        if (column.Width) {
                            this.findVideoGridRef.columns[column.ToIndex].width = column.Width;
                        }
                    });
                }

                if (settings.colName && settings.direction) {
                    this.findVideoGridRef.sortColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.findVideoGridRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.findVideoGridRef.refreshColumns();

                // Prevent resizing of last column
                let lastColumnIndex = this.findVideoGridRef.columns.length - 1;    
                this.findVideoGridRef.columns[lastColumnIndex].allowResizing = false;
                this.findVideoGridRef.refreshColumns();

                if (settings.pageSize) {
                    if (settings.pageSize === "Alle") {
                        this.findVideoGridRef.pageSettings.pageSize = this.findVideoGridRef.pageSettings.totalRecordsCount;
                        document.querySelector("#video-grid .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.findVideoGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
            }
        }
    }

    onFindPictureCreated() {
        if (this.findPictureGridSettings) {
            let settings = JSON.parse(this.findPictureGridSettings);
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(column => {
                        if (column.FromIndex !== column.ToIndex) {
                            var tempIndex = this.findPictureGridRef.getColumnIndexByField(column.Field);
                            if (tempIndex !== column.ToIndex){
                                this.findPictureGridRef.reorderColumnByIndex(column.FromIndex, column.ToIndex);
                            }
                        }
    
                        if (column.Width) {
                            this.findPictureGridRef.columns[column.ToIndex].width = column.Width;
                        }
                    });
                }

                if (settings.colName && settings.direction) {
                    this.findPictureGridRef.sortColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.findPictureGridRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.findPictureGridRef.refreshColumns();

                // Prevent resizing of last column
                let lastColumnIndex = this.findPictureGridRef.columns.length - 1;    
                this.findPictureGridRef.columns[lastColumnIndex].allowResizing = false;
                this.findPictureGridRef.refreshColumns();

                if (settings.pageSize) {
                    if (settings.pageSize === "Alle") {
                        this.findPictureGridRef.pageSettings.pageSize = this.findPictureGridRef.pageSettings.totalRecordsCount;
                        document.querySelector("#picture-grid .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.findPictureGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
            }
        }
    }

    onRetrieveVideoCreated() {
        if (this.retrievedVideoGridSettings) {
            let settings = JSON.parse(this.retrievedVideoGridSettings);
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(column => {
                        if (column.FromIndex !== column.ToIndex) {
                            var tempIndex = this.retrievedVideoGridRef.getColumnIndexByField(column.Field);
                            if (tempIndex !== column.ToIndex){
                                this.retrievedVideoGridRef.reorderColumnByIndex(column.FromIndex, column.ToIndex);
                            }
                        }
    
                        if (column.Width) {
                            this.retrievedVideoGridRef.columns[column.ToIndex].width = column.Width;
                        }
                    });
                }

                if (settings.colName && settings.direction) {
                    this.retrievedVideoGridRef.sortColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.retrievedVideoGridRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.retrievedVideoGridRef.refreshColumns();

                // Prevent resizing of last column
                let lastColumnIndex = this.retrievedVideoGridRef.columns.length - 1;    
                this.retrievedVideoGridRef.columns[lastColumnIndex].allowResizing = false;
                this.retrievedVideoGridRef.refreshColumns();

                if (settings.pageSize) {
                    if (settings.pageSize === "Alle") {
                        this.retrievedVideoGridRef.pageSettings.pageSize = this.retrievedVideoGridRef.pageSettings.totalRecordsCount;
                        document.querySelector("#download-grid .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.retrievedVideoGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
            }
        }
    }

    async onFindVideoActionComplete(args) {
        if (args.requestType === 'paging') {
            if (this.findVideoGridRef.pageSettings.pageSize === this.findVideoGridRef.pageSettings.totalRecordsCount) {
                document.querySelector("#video-grid .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.findVideoGridSettings, this.findVideoGridRef.pageSettings.pageSize, this.findVideoGridRef.pageSettings.totalRecordsCount, false, []);
            this.findVideoGridSettings = tempGridSettings;
            const payload = {
                Key: this.findVideoGridSettingsKey,
                Settings: this.findVideoGridSettings
            };
            const result = await SettingsService.SaveGridSettings(payload, source.token);
        }
        else if (args.requestType === 'filtering') {
            if (this.findVideoGridSettings) {
                let settings = JSON.parse(this.findVideoGridSettings);
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let tempGridSettings = buildGridSettings(args, this.findVideoGridSettings, 0, 0, false, []);
            this.findVideoGridSettings = tempGridSettings;
            const payload = {
                Key: this.findVideoGridSettingsKey,
                Settings: this.findVideoGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.findVideoGridSettings) {
                let settings = JSON.parse(this.findVideoGridSettings);
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.findVideoGridSettings, 0, 0, false, []);
            this.findVideoGridSettings = tempGridSettings;
            const payload = {
                Key: this.findVideoGridSettingsKey,
                Settings: this.findVideoGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.findVideoGridRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.findVideoGridSettings, 0, 0, this.hasDraggedColumn, columns);
            this.findVideoGridSettings = tempGridSettings;
            const payload = {
                Key: this.findVideoGridSettingsKey,
                Settings: this.findVideoGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                let lastColumnIndex = this.findVideoGridRef.columns.length - 1;
                for (let index = 0; index < this.findVideoGridRef.columns.length; index++) {
                    let column = this.findVideoGridRef.columns[index];
                    if (index === lastColumnIndex) { column.allowResizing = false }
                    else { column.allowResizing = true }
                }
                this.findVideoGridRef.refreshColumns();
            }

            this.hasDraggedColumn = false;
        }
    }

    async onFindVideoResizeStop(args) {
        args.requestType = "resizingGrid";
        let columns = this.findVideoGridRef.getColumns();
        let tempGridSettings = buildGridSettings(args, this.findVideoGridSettings, 0, 0, false, columns);
        this.findVideoGridSettings = tempGridSettings;
        const payload = {
            Key: this.findVideoGridSettingsKey,
            Settings: this.findVideoGridSettings
        };

        const result = await SettingsService.SaveGridSettings(payload, source.token);

        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);

            // Adjust size of last column to fill spaces
            let lastColumnIndex = this.findVideoGridRef.columns.length - 1;
            let totalColumnWidth = this.findVideoGridRef.columns.reduce((acc, curVal) => acc + parseInt(curVal.width), 0);
            let lastColumnWidth = this.findVideoGridRef.element.clientWidth - totalColumnWidth;
            this.findVideoGridRef.columns[lastColumnIndex].width = this.findVideoGridRef.columns[lastColumnIndex].width + lastColumnWidth;
            this.findVideoGridRef.refreshColumns();
        }
    }

    async onFindPictureActionComplete(args) {
        if (args.requestType === 'paging') {
            if (this.findPictureGridRef.pageSettings.pageSize === this.findPictureGridRef.pageSettings.totalRecordsCount) {
                document.querySelector("#picture-grid .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.findPictureGridSettings, this.findPictureGridRef.pageSettings.pageSize, this.findPictureGridRef.pageSettings.totalRecordsCount, false, []);
            this.findPictureGridSettings = tempGridSettings;
            const payload = {
                Key: this.findPictureGridSettingsKey,
                Settings: this.findPictureGridSettings
            };
            const result = await SettingsService.SaveGridSettings(payload, source.token);
        }
        else if (args.requestType === 'filtering') {
            if (this.findPictureGridSettings) {
                let settings = JSON.parse(this.findPictureGridSettings);
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let tempGridSettings = buildGridSettings(args, this.findPictureGridSettings, 0, 0, false, []);
            this.findPictureGridSettings = tempGridSettings;
            const payload = {
                Key: this.findPictureGridSettingsKey,
                Settings: this.findPictureGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.findPictureGridSettings) {
                let settings = JSON.parse(this.findPictureGridSettings);
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.findPictureGridSettings, 0, 0, false, []);
            this.findPictureGridSettings = tempGridSettings;
            const payload = {
                Key: this.findPictureGridSettingsKey,
                Settings: this.findPictureGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.findPictureGridRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.findPictureGridSettings, 0, 0, this.hasDraggedColumn, columns);
            this.findPictureGridSettings = tempGridSettings;
            const payload = {
                Key: this.findPictureGridSettingsKey,
                Settings: this.findPictureGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                let lastColumnIndex = this.findPictureGridRef.columns.length - 1;
                for (let index = 0; index < this.findPictureGridRef.columns.length; index++) {
                    let column = this.findPictureGridRef.columns[index];
                    if (index === lastColumnIndex) { column.allowResizing = false }
                    else { column.allowResizing = true }
                }
                this.findPictureGridRef.refreshColumns();
            }

            this.hasDraggedColumn = false;
        }
    }

    async onFindPictureResizeStop(args) {
        args.requestType = "resizingGrid";
        let columns = this.findPictureGridRef.getColumns();
        let tempGridSettings = buildGridSettings(args, this.findPictureGridSettings, 0, 0, false, columns);
        this.findPictureGridSettings = tempGridSettings;
        const payload = {
            Key: this.findPictureGridSettingsKey,
            Settings: this.findPictureGridSettings
        };

        const result = await SettingsService.SaveGridSettings(payload, source.token);

        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);

            // Adjust size of last column to fill spaces
            let lastColumnIndex = this.findPictureGridRef.columns.length - 1;
            let totalColumnWidth = this.findPictureGridRef.columns.reduce((acc, curVal) => acc + parseInt(curVal.width), 0);
            let lastColumnWidth = this.findPictureGridRef.element.clientWidth - totalColumnWidth;
            this.findPictureGridRef.columns[lastColumnIndex].width = this.findPictureGridRef.columns[lastColumnIndex].width + lastColumnWidth;
            this.findPictureGridRef.refreshColumns();
        }
    }

    async onRetrieveVideoActionComplete(args) {
        if (args.requestType === 'beginEdit') {
            args.form.elements.namedItem('Note').focus();
        }
        if (args.requestType === 'paging') {
            if (this.retrievedVideoGridRef.pageSettings.pageSize === this.retrievedVideoGridRef.pageSettings.totalRecordsCount) {
                document.querySelector("#download-grid .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.retrievedVideoGridSettings, this.retrievedVideoGridRef.pageSettings.pageSize, this.retrievedVideoGridRef.pageSettings.totalRecordsCount, false, []);
            this.retrievedVideoGridSettings = tempGridSettings;
            const payload = {
                Key: this.retrievedVideoGridSettingsKey,
                Settings: this.retrievedVideoGridSettings
            };
            const result = await SettingsService.SaveGridSettings(payload, source.token);
        }
        else if (args.requestType === 'filtering') {
            if (this.retrievedVideoGridSettings) {
                let settings = JSON.parse(this.retrievedVideoGridSettings);
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let tempGridSettings = buildGridSettings(args, this.retrievedVideoGridSettings, 0, 0, false, []);
            this.retrievedVideoGridSettings = tempGridSettings;
            const payload = {
                Key: this.retrievedVideoGridSettingsKey,
                Settings: this.retrievedVideoGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.retrievedVideoGridSettings) {
                let settings = JSON.parse(this.retrievedVideoGridSettings);
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.retrievedVideoGridSettings, 0, 0, false, []);
            this.retrievedVideoGridSettings = tempGridSettings;
            const payload = {
                Key: this.retrievedVideoGridSettingsKey,
                Settings: this.retrievedVideoGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.retrievedVideoGridRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.retrievedVideoGridSettings, 0, 0, this.hasDraggedColumn, columns);
            this.retrievedVideoGridSettings = tempGridSettings;
            const payload = {
                Key: this.retrievedVideoGridSettingsKey,
                Settings: this.retrievedVideoGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                let lastColumnIndex = this.retrievedVideoGridRef.columns.length - 1;
                for (let index = 0; index < this.retrievedVideoGridRef.columns.length; index++) {
                    let column = this.retrievedVideoGridRef.columns[index];
                    if (index === lastColumnIndex) { column.allowResizing = false }
                    else { column.allowResizing = true }
                }
                this.retrievedVideoGridRef.refreshColumns();
            }

            this.hasDraggedColumn = false;
        }
    }

    async onRetrieveVideoResizeStop(args) {
        args.requestType = "resizingGrid";
        let columns = this.retrievedVideoGridRef.getColumns();
        let tempGridSettings = buildGridSettings(args, this.retrievedVideoGridSettings, 0, 0, false, columns);
        this.retrievedVideoGridSettings = tempGridSettings;
        const payload = {
            Key: this.retrievedVideoGridSettingsKey,
            Settings: this.retrievedVideoGridSettings
        };

        const result = await SettingsService.SaveGridSettings(payload, source.token);

        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);

            // Adjust size of last column to fill spaces
            let lastColumnIndex = this.retrievedVideoGridRef.columns.length - 1;
            let totalColumnWidth = this.retrievedVideoGridRef.columns.reduce((acc, curVal) => acc + parseInt(curVal.width), 0);
            let lastColumnWidth = this.retrievedVideoGridRef.element.clientWidth - totalColumnWidth;
            this.retrievedVideoGridRef.columns[lastColumnIndex].width = this.retrievedVideoGridRef.columns[lastColumnIndex].width + lastColumnWidth;
            this.retrievedVideoGridRef.refreshColumns();
        }
    }

    //#endregion

    async onFindVideoToolbarClick(args) {
        if (args.item.id === 'download') {
            let selectedRows = this.findVideoGridRef.getSelectedRecords();

            if (selectedRows.length > 0) {
                let { stageDownload } = this.state;
                stageDownload.type = 1;
                stageDownload.Ids = selectedRows.map(x => x.Id);
                this.setState({ stageDownload }, () => {
                    this.confirmationDialog.header = Localization.General.NoteHeader;
                    this.confirmationDialog.content = "Du er ved at hente en eller flere filer.<br><br>Dette kan tage op til 10 min. afhængigt af antallet af filer der hentes.<br><br>Når filerne er downloadet findes de i fanen Hentet filer";
                    this.confirmationDialog.content = this.confirmationDialog.content + "<br><br>Tryk Ok, for at fortsætte";
                    this.confirmationDialog.buttons = this.confirmationButtons;
                    this.confirmationDialog.show();
                });
            }
            else {
                this.dialogOpen("Advarsel", Localization.General.NoRowSelected);
            }
        }
        else if (args.item.id === 'reset_filter') {
            if (this.findVideoGridSettings) {
                let settings = JSON.parse(this.findVideoGridSettings);
                settings.filters = [];
                this.findVideoGridSettings = settings;
                const payload = {
                    Key: this.findVideoGridSettingsKey,
                    Settings: JSON.stringify(this.findVideoGridSettings)
                };
    
                const result = await SettingsService.SaveGridSettings(payload, source.token);
    
                if (!result.HasError) {
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                }
            }

            this.findVideoGridRef.clearFiltering();
        }
    }

    async onFindPictureToolbarClick(args) {
        if (args.item.id === 'download') {
            let selectedRows = this.findPictureGridRef.getSelectedRecords();

            if (selectedRows.length > 0) {
                let { stageDownload } = this.state;
                stageDownload.type = 2;
                stageDownload.Ids = selectedRows.map(x => x.Id);
                this.setState({ stageDownload }, () => {
                    // TODO: ask for separate message for find picture
                    this.confirmationDialog.header = Localization.General.NoteHeader;
                    this.confirmationDialog.content = "Du er ved at hente en eller flere filer.<br><br>Dette kan tage op til 10 min. afhængigt af antallet af filer der hentes.<br><br>Når filerne er downloadet findes de i fanen Hentet filer";
                    this.confirmationDialog.content = this.confirmationDialog.content + "<br><br>Tryk Ok, for at fortsætte";
                    this.confirmationDialog.buttons = this.confirmationButtons;
                    this.confirmationDialog.show();
                });
            }
            else {
                this.dialogOpen("Advarsel", Localization.General.NoRowSelected);
            }
        }
        else if (args.item.id === 'reset_filter') {
            if (this.findPictureGridSettings) {
                let settings = JSON.parse(this.findPictureGridSettings);
                settings.filters = [];
                this.findPictureGridSettings = settings;
                const payload = {
                    Key: this.findPictureGridSettingsKey,
                    Settings: JSON.stringify(this.findPictureGridSettings)
                };
    
                const result = await SettingsService.SaveGridSettings(payload, source.token);
    
                if (!result.HasError) {
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                }
            }

            this.findPictureGridRef.clearFiltering();
        }
    }

    async onRetrieveVideoToolbarClick(args) {
        let selectedRows = this.retrievedVideoGridRef.getSelectedRecords();

        if (args.item.id === 'reset_filter') {
            if (this.retrievedVideoGridSettings) {
                let settings = JSON.parse(this.retrievedVideoGridSettings);
                settings.filters = [];
                this.retrievedVideoGridSettings = settings;
                const payload = {
                    Key: this.retrievedVideoGridSettingsKey,
                    Settings: JSON.stringify(this.retrievedVideoGridSettings)
                };
    
                const result = await SettingsService.SaveGridSettings(payload, source.token);
    
                if (!result.HasError) {
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                }
            }

            this.retrievedVideoGridRef.clearFiltering();
        }
        else if (selectedRows.length > 0) {
            if (args.item.id === 'send_to_mail') {
                let attachments = selectedRows.map(s => {
                    let f = s.FileLink.split('/');
                    return {
                        Key: s.FileLink,
                        Name: f[f.length - 1]
                    }
                });

                // Add attachments into local storage for pulling in Message center module
                localStorage.setItem("MSG_VIDEO_ATTACHMENTS", JSON.stringify(attachments));
                this.props.history.push('/beskeder?m=new&s=video-arkiv');
            }
            else if (args.item.id === 'watch') {
                let { gallery } = this.state;
                
                gallery.items = selectedRows.map(s => {
                    return {
                        Id: s.Id,
                        VideoType: s.VideoType,
                        FileLink: s.FileLink
                    }
                });

                gallery.items.push({
                    Id: 99,
                    VideoType: 2,
                    FileLink: '1/1902-watermark-images-08.png'
                });

                gallery.hasNext = gallery.items.length > 1;
                gallery.hasPrev = false;
                gallery.currentItem = gallery.items[0];

                this.setState({ gallery }, () => {
                    this.videoPlayerDialog.show();
                });
            }
            else if (args.item.id === 'delete') {
                let { stageDeletion } = this.state;
                stageDeletion = selectedRows.map(x => x.Id);

                this.setState({ stageDeletion }, () => {
                    this.confirmationDialog.header = "Advarsel";
                    this.confirmationDialog.content = MessageHelper.MB7.message.replace("{context}", "hentet filer");
    
                    let buttons = MessageHelper.MB7.buttons;

                    buttons[0].click = () => {
                        this.confirmationDialog.hide();
                        this.setState({ stageDeletion: [] });
                    }

                    buttons[1].click = async () => {
                        let { stageDeletion } = this.state;
                        let payload = stageDeletion.map(d => {
                            return {
                                Id: d,
                                IsDelete: true
                            }
                        });

                        const result = await VideoService.Save(payload, source.token);
                        if (!result.HasError) {
                            const retrievedVideos = await VideoService.RetrieveVideos(this.CustomerId, source.token);
                            this.retrieveVideoData = retrievedVideos;
                            this.retrievedVideoGridRef.dataSource = retrievedVideos;
                            this.retrievedVideoGridRef.refresh();

                            this.confirmationDialog.hide();
                            this.setState({ stageDeletion: [] });
                            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessDelete.replace("{context}", "Hentet filer"));
                        }
                        else {
                            this.dialogOpen("Advarsel", result.ErrorMessage);
                        }
                    }
    
                    this.confirmationDialog.buttons = buttons;
                    this.confirmationDialog.show();
                });
            }
            else if (args.item.id === 'mark_favorite') {
                let payload = selectedRows.map(x => {
                    return {
                        Id: x.Id,
                        Favorite: !x.Favorite,
                        Note: x.Note,
                        IsDelete: false
                    }
                })

                const result = await VideoService.Save(payload, source.token);

                if (!result.HasError) {
                    await this.refreshDownloadedPicturesVideos();
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.VideoArchive.SuccessfullySavedAsFavorite);
                }
                else {
                    this.dialogOpen("Advarsel", result.ErrorMessage);
                }
            }
            else if (args.item.id === 'open_note') {
                if (selectedRows.length === 1) {
                    // this.dialogOpen(`${selectedRows[0].ProductNo} - Note`, selectedRows[0].Note ? selectedRows[0].Note : '');
                    this.noteDialog.header = `${selectedRows[0].ProductNo} - Note`;
                    this.noteDialog.content = selectedRows[0].Note ? selectedRows[0].Note : '';
                    this.noteDialog.show();
                }
                else {
                    this.dialogOpen("Advarsel", Localization.VideoArchive.CanOnlyViewOneNote);
                }
            }
        }
        else {
            args.cancel = true;
            this.dialogOpen("Advarsel", Localization.General.NoRowSelected);
        }
    }

    async refreshDownloadedPicturesVideos() {
        const result = await VideoService.RetrieveVideos(this.CustomerId, source.token)
        if (!result.HasError) {
            this.retrieveVideoData = result.map(d => {
                return {
                    ...d,
                    DateTimeStamp: new Date(d.Timestamp).toLocaleDateString("da-DK")
                }
            });
            this.retrievedVideoGridRef.dataSource = this.retrieveVideoData;
            this.retrievedVideoGridRef.refresh();
        }
    }

    async onRetrieveVideoActionBegin(args) {
        if (args.requestType === 'beginEdit') {
            this.setState({ hasUnsavedChanges: true });
        }
        else if (args.requestType === 'save') {
            let payload = [];

            payload.push({
                Id: args.data.Id,
                Note: args.data.Note,
                Favorite: args.data.Favorite,
                IsDelete: false
            })
            const result = await VideoService.Save(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Videoer"));
                this.setState({ hasUnsavedChanges: false });
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
    }

    imageTemplate(props) {
        if (props.ProductImage) {
            return (
                <Image src={props.ProductImage} alt="" className="product-image-data" />
            )
        }

        return <img src={placeHolderImg} alt="primary product" className="product-image-data" />;
    }

    dateDKTemplate(props) {
        return new Date(props.DateTimeStamp).toLocaleString('da-DK', { timeZone: 'Europe/Copenhagen', year: 'numeric', month: '2-digit', day: '2-digit' });
    }

    timeDKTemplate(props) {
        return new Date(props.DateTimeStamp).toLocaleString('da-DK', { timeZone: 'Europe/Copenhagen', hour: 'numeric', minute: 'numeric' });
    }

    downloadedDateDKTemplate(props) {
        return new Date(props.DateTimeStamp).toLocaleString('da-DK', { timeZone: 'Europe/Copenhagen', year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric', second: '2-digit' });
    }

    dateTimeStamptDKTemplate(props) {
        return new Date(props.DateTimeStamp).toLocaleString('da-DK', { timeZone: 'Europe/Copenhagen', year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric' });
    }

    timeStamptDKTemplate(props) {
        return new Date(props.Timestamp).toLocaleString('da-DK', { timeZone: 'Europe/Copenhagen', year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric' });
    }

    findVideoTab() {
        return (
            <div style={{marginTop: 20}}>
                <GridComponent id="video-grid" ref={ref => this.findVideoGridRef = ref } dataSource={this.findVideoData} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings} toolbar={this.findVideoToolbarOptions}
                    selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both" allowReordering={true} allowResizing={true} 
                    toolbarClick={this.onFindVideoToolbarClick} actionComplete={this.onFindVideoActionComplete.bind(this)} columnDragStart={this.onColumnDragStart.bind(this)} 
                    created={this.onFindVideoCreated.bind(this)} resizeStop={this.onFindVideoResizeStop.bind(this)}>
                    <ColumnsDirective>
                        <ColumnDirective type='checkbox' width='70' textAlign="Center"></ColumnDirective>
                        <ColumnDirective field="Id" visible={false} isPrimaryKey={true}></ColumnDirective>
                        <ColumnDirective headerText="Billede" field="ProductImage" template={this.imageTemplate} allowFiltering={false} />
                        <ColumnDirective headerText="Produkt Nr." field="ProductNo" />
                        <ColumnDirective headerText="Beskrivelse" field="Desc" />
                        <ColumnDirective headerText="EAN" field="EAN" />
                        <ColumnDirective headerText="Afdeling" field="Machine.Department.Name" />
                        <ColumnDirective headerText="Automat" field="BoxNoLetter" />
                        <ColumnDirective headerText="Lokation" field="BoxNoDigit" textAlign="Right" />
                        <ColumnDirective headerText="Antal Videoer" field="VideoCount" textAlign="Right" />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Selection, Toolbar, Search, Reorder, Resize]} />
                </GridComponent>
            </div>
        )
    }

    findPictureTab() {
        return (
            <div style={{marginTop: 20}}>
                <GridComponent id="picture-grid" ref={ref => this.findPictureGridRef = ref } dataSource={this.findPictureData} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings} toolbar={this.findPictureToolbarOptions}
                    selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da-DK" gridLines="Both" allowReordering={true} allowResizing={true} 
                    toolbarClick={this.onFindPictureToolbarClick} actionComplete={this.onFindPictureActionComplete.bind(this)} columnDragStart={this.onColumnDragStart.bind(this)} 
                    created={this.onFindPictureCreated.bind(this)} resizeStop={this.onFindPictureResizeStop.bind(this)}>
                    <ColumnsDirective>
                        <ColumnDirective type='checkbox' width='30' textAlign="Center"></ColumnDirective>
                        <ColumnDirective field="Id" visible={false} isPrimaryKey={true}></ColumnDirective>
                        <ColumnDirective headerText="Dato" field="DateTimeStamp" type="date" template={this.dateDKTemplate} />
                        <ColumnDirective headerText="Tid" field="TimeStamp" type="dateTime" template={this.timeDKTemplate} />
                        {/* <ColumnDirective headerText="Tid2" field="Timestamp" type="dateTime" template={this.imageTemplate} /> */}
                        <ColumnDirective headerText="Bruger" field="Username" />
                        <ColumnDirective headerText="Afdeling" field="Machine.Department.Name" />
                        <ColumnDirective headerText="Automat" field="Machine.Name" />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Selection, Toolbar, Search, Reorder, Resize]} />
                </GridComponent>
            </div>
        )
    }

    retrievedVideos() {
        return (
            <div style={{marginTop: 20}}>
                <GridComponent id="download-grid" ref={ref => this.retrievedVideoGridRef = ref } dataSource={this.retrieveVideoData} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings} toolbar={this.retrievedVideoToolbarOptions}
                    selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both" allowReordering={true} allowResizing={true} editSettings={this.editSettings}
                    toolbarClick={this.onRetrieveVideoToolbarClick} actionComplete={this.onRetrieveVideoActionComplete.bind(this)} columnDragStart={this.onColumnDragStart.bind(this)} actionBegin={this.onRetrieveVideoActionBegin.bind(this)}
                    created={this.onRetrieveVideoCreated.bind(this)} resizeStop={this.onRetrieveVideoResizeStop.bind(this)}>
                    <ColumnsDirective>
                        <ColumnDirective type='checkbox' width='70' textAlign="Center"></ColumnDirective>
                        <ColumnDirective field="Id" visible={false} isPrimaryKey={true}></ColumnDirective>
                        <ColumnDirective headerText="Dato" field="DownloadedDate" type="date" template={this.downloadedDateDKTemplate} allowEditing={false} />
                        <ColumnDirective headerText="Afdeling" field="Machine.Department.Name" allowEditing={false} />
                        <ColumnDirective headerText="Automat" field="BoxNoLetter" allowEditing={false} />
                        <ColumnDirective headerText="Lokation" field="BoxNoDigit" allowEditing={false} textAlign="Right" />
                        <ColumnDirective headerText="Beskrivelse" field="Desc" allowEditing={false} />
                        <ColumnDirective headerText="Billede" field="ProductImage" template={this.imageTemplate} allowFiltering={false} allowEditing={false} />
                        <ColumnDirective headerText="Dato for Handling" field="DateTimeStamp" type="date" template={this.dateTimeStamptDKTemplate} allowEditing={false} />
                        <ColumnDirective headerText="Tidspunkt" field="Timestamp" type="datetime" template={this.timeStamptDKTemplate} allowEditing={false} />
                        <ColumnDirective headerText="User" field="Username" allowEditing={false} />
                        <ColumnDirective headerText="Note" field="Note" />
                        <ColumnDirective headerText="Favorit" field="Favorite" displayAsCheckBox={true} allowEditing={false} />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Selection, Toolbar, Search, Reorder, Resize, Edit]} />
                </GridComponent>
            </div>
        )
    }

    onTabSelected(args) {
        let { postNoteContent } = this.state;
        switch (args.selectedIndex) {
            case 0:
                postNoteContent = "Filtrer tabellen nedenfor så tabellen viser de lokationer du ønsker at downloade videoer for, tryk derefter på knappen hent video";
                break;
            case 1:
                postNoteContent = "Filtrer tabellen nedenfor så tabellen viser de log ind du ønsker at downloade, tryk derefter på knappen hent Billede";
                break;
            case 2:
                postNoteContent = "";
                break;
        }

        this.setState({ currentTabSelected: args.selectedIndex, postNoteContent });
    }

    dialogOpen(header, content) {
        this.notificationDialog.header = header;
        this.notificationDialog.content = content;
        this.notificationDialog.show();
    }

    onPrevClick = () => {
        let { gallery } = this.state;
        let currentItemIndex = gallery.items.indexOf(gallery.currentItem);
        currentItemIndex -= 1;
        gallery.currentItem = gallery.items[currentItemIndex];
        gallery.hasPrev = !!gallery.items[currentItemIndex - 1];
        gallery.hasNext = !!gallery.items[currentItemIndex + 1];
        this.setState({ gallery });
    }

    onNextClick = () => {
        let { gallery } = this.state;
        let currentItemIndex = gallery.items.indexOf(gallery.currentItem);
        currentItemIndex += 1;
        gallery.currentItem = gallery.items[currentItemIndex];
        gallery.hasNext = !!gallery.items[currentItemIndex + 1];
        gallery.hasPrev = !!gallery.items[currentItemIndex - 1];
        this.setState({ gallery });
    }

    onClosePlayerDialog = () => {
        let { gallery } = this.state;
        gallery.currentItem = {};
        gallery.item = [];
        gallery.hasNext = false;
        gallery.hasPrev = false;
        this.setState({ gallery });
    }

    onWheelScroll = () => {
        let grid = document.getElementById('video-grid');
        let toolbar = document.querySelector('#video-grid.e-grid .e-toolbar');
        let tableHeader = document.querySelector('#video-grid .e-gridheader');

        if (toolbar && tableHeader) {
            let boundingRect = tableHeader.getBoundingClientRect();
            
            if (boundingRect.top < 90) {
                toolbar.classList.add('c-hover-grid-toolbar');
                toolbar.style.width = (grid.clientWidth - 5) + 'px';
            }
            else {
                toolbar.classList.remove('c-hover-grid-toolbar');
                toolbar.style.width = 'auto';
            }
        }

        let grid2 = document.getElementById('picture-grid');
        let toolbar2 = document.querySelector('#picture-grid.e-grid .e-toolbar');
        let tableHeader2 = document.querySelector('#picture-grid .e-gridheader');

        if (toolbar2 && tableHeader2) {
            let boundingRect = tableHeader2.getBoundingClientRect();
            
            if (boundingRect.top < 90) {
                toolbar2.classList.add('c-hover-grid-toolbar');
                toolbar2.style.width = (grid2.clientWidth - 5) + 'px';
            }
            else {
                toolbar2.classList.remove('c-hover-grid-toolbar');
                toolbar2.style.width = 'auto';
            }
        }

        let grid3 = document.getElementById('download-grid');
        let toolbar3 = document.querySelector('#download-grid.e-grid .e-toolbar');
        let tableHeader3 = document.querySelector('#download-grid .e-gridheader');

        if (toolbar3 && tableHeader3) {
            let boundingRect = tableHeader3.getBoundingClientRect();
            
            if (boundingRect.top < 90) {
                toolbar3.classList.add('c-hover-grid-toolbar');
                toolbar3.style.width = (grid3.clientWidth - 5) + 'px';
            }
            else {
                toolbar3.classList.remove('c-hover-grid-toolbar');
                toolbar3.style.width = 'auto';
            }
        }
    }

    render() {
        const { loading, redirect, postNoteContent, hasUnsavedChanges, gallery, currentTabSelected } = this.state;

        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }
        
        return (
            <div className="content-pane-wrapper">
                <NavigationPrompt when={hasUnsavedChanges} 
                    navigate={path => this.props.history.push(path)}
                    initiateSave={() => this.retrievedVideoGridRef.endEdit()}
                    shouldBlockNavigation={location => {
                        if (hasUnsavedChanges) {
                            return true;
                        }
                        return false;
                    }}
                />
                <div className="module-header">
                    <h1>{this.pageName}</h1>
                </div>
                {
                    postNoteContent && 
                    <div className="c-post-note">
                        <div className="post-note-wrapper">
                            <div className="post-note-content text-left">{postNoteContent}</div>
                        </div>
                    </div>
                }
                <div className='control-section tab-control'>
                    <TabComponent ref={ref => this.videoTabRef = ref} selectedItem={currentTabSelected} heightAdjustMode='Auto' id='labels-tab' selected={this.onTabSelected}>
                        <TabItemsDirective>
                            <TabItemDirective header={this.tabHeader[0]} content={this.findVideoTab.bind(this)} />
                            <TabItemDirective header={this.tabHeader[1]} content={this.findPictureTab.bind(this)} />
                            <TabItemDirective header={this.tabHeader[2]} content={this.retrievedVideos.bind(this)} />
                        </TabItemsDirective>
                    </TabComponent>
                </div>
                <DialogComponent id="video-archive-dialog" isModal={true} buttons={this.notificationButtons} width='auto' ref={dialog => this.notificationDialog = dialog} target='body'
                    visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} />
                <DialogComponent id="video-archive-confirmation-dialog" isModal={true} buttons={this.confirmationButtons} width='450px' ref={dialog => this.confirmationDialog = dialog} target='body'
                    visible={false} showCloseIcon={true} cssClass="dialog-notification free-width" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} />
                <DialogComponent id="video-archive-player-dialog" isModal={true} ref={dialog => this.videoPlayerDialog = dialog} target='body' showCloseIcon={true} header="Picture and Video player"
                    visible={false} animationSettings={this.animationSettings} allowDragging={true} enableResize={true} close={this.onClose}>
                    <div className="flex-center">
                        <div className="video-archive-player-wrapper">
                            <div className="video-archive-player">
                                {
                                    gallery.currentItem.VideoType === 1 ? 
                                    <video src={`${AppSettings.API_URL}/file?Key=${gallery.currentItem.FileLink}&access_token=${this.AccessToken}`} type="video/mp4" 
                                        onError={(e) => console.log(e)} controls={true} disablePictureInPicture controlsList="nodownload"
                                    />
                                    :
                                    <img src={`${AppSettings.API_URL}/file?Key=${gallery.currentItem.FileLink}&access_token=${this.AccessToken}`} />
                                }
                            </div>
                            {
                                gallery.hasPrev && <div className="clickable btn-prev" onClick={this.onPrevClick}></div>
                            }
                            {
                                gallery.hasNext && <div className="clickable btn-next" onClick={this.onNextClick}></div>
                            }
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent id="video-archive-note-dialog" isModal={true} buttons={this.noteButtons} ref={dialog => this.noteDialog = dialog} target='body'
                    visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true}>
                </DialogComponent>
            </div>
        )
    }
}

export default VideoArchive;
