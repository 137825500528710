import React from 'react'
import { ToolbarComponent, ItemsDirective, ItemDirective, TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { ListViewComponent } from '@syncfusion/ej2-react-lists';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { Redirect } from 'react-router-dom';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Page, Sort, Toolbar, Selection, Edit, Search, Reorder, Resize } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { RichTextEditorComponent, Inject as InjectRTE, Link, Image, HtmlEditor, Toolbar as RTEToolbar, QuickToolbar } from '@syncfusion/ej2-react-richtexteditor';
import Axios from 'axios';

import Card from '../components/Card';
import BaseComponent from '../components/BaseComponent';
import NavigationPrompt from '../components/NavigationPrompt';
import { buildVirtualMenu, getCheckedMenuNodes, buildMenuFromCheckedNodes, buildCheckedNodes } from '../utilities/Helper';
import Localization from '../utilities/Localization';
import MessageHelper from '../utilities/MessageHelper';
import Loading from '../components/Loading';

import MenuService from '../services/MenuService';
import SessionService from '../services/SessionService';
import HelpArticleService from '../services/HelpArticleService';

import '../styles/help.scss';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class HelpArticles extends BaseComponent {
    moduleName = "Hjælp";
    pageName = "Hjælp";

    constructor() {
        super();

        this.state = {
            loading: true,
            hasUnsavedChanges: false,
            isEditMode: false,
            redirect: {
                to: '',
                isRedirect: false
            },
            systemInfo: {
                id: 0,
                name: ''
            },
            allowModification: false,
            articles: [],
            readArticle: {
                subject: '',
                body: ''
            },
            selectedMenu: {},
            selectedArticle: {}
        }

        this.treeFields = { 
            dataSource: [], id: 'Id', text: 'Name', child: 'MenuItems'
        };

        this.pageSettings = {
            pageSize: 10
        };

        this.selectionSettings = {
            type: 'Single'
        }

        this.filterSettings = {
            type: 'Menu'
        };

        this.format = {
            types: [
              { text: "Paragraf", value: "p", cssClass: "e-paragraph" },
              { text: "Kode", value: "pre", cssClass: "e-code" },
              { text: "Citationstegn", value: "blockquote", cssClass: "e-quote" },
              { text: "Overskrift 1", value: "h1", cssClass: "e-h1" },
              { text: "Overskrift 2", value: "h2", cssClass: "e-h2" },
              { text: "Overskrift 3", value: "h3", cssClass: "e-h3" },
              { text: "Overskrift 4", value: "h4", cssClass: "e-h4" },
              { text: "Overskrift 5", value: "h5", cssClass: "e-h5" },
              { text: "Overskrift 6", value: "h6", cssClass: "e-h6" }
            ]
        };

        this.toolbarSettings = {
            items: [ 'Bold', 'Italic', 'Underline', '|', 'Formats', 'Alignments', 'OrderedList', 'UnorderedList', '|', 'CreateLink', '|', 'Undo', 'Redo'
            ]
        }

        this.onHelpToolbarClicked = this.onHelpToolbarClicked.bind(this);
        this.onNodeSelected = this.onNodeSelected.bind(this);
        this.viewTopic = this.viewTopic.bind(this);
        this.readTopic = this.readTopic.bind(this);
        this.processTopic = this.processTopic.bind(this);
        this.refreshMenuArticles = this.refreshMenuArticles.bind(this);

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.notificationButtons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];

        this.deleteButtons = MessageHelper.MB7.buttons;
        this.deleteButtons[0].click = () => {
            this.setState({ selectedArticle: null }, () => {
                this.deleteDialog.hide();
            });
        };
        this.deleteButtons[1].click = () => {
            this.processTopic("delete");
        };

        this.helpButtons = [
            {
                click: () => {
                    this.addEditHelpDialog.hide();
                },
                buttonModel: {
                    content: 'Tilbage'
                }
            },
            {
                click: () => {
                    let { selectedArticle } = this.state;
                    let state = selectedArticle !== null ? 'edit' : 'add';
                    this.processTopic(state);
                },
                buttonModel: {
                    isPrimary: true,
                    content: 'Gem'
                }
            }
        ];

        this.readHelpButtons = [
            {
                click: () => {
                    this.readHelpDialog.hide();
                },
                buttonModel: {
                    isPrimary: true,
                    content: 'OK'
                }
            }
        ];

        /* End Dialog options  */
    }

    async componentDidMount() {
        source.cancel();
        source = CancelToken.source();

        // if (!this.hasPermission) {
        //     this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
        //     return;
        // }
        
        let { allowModification } = this.state;

        const session = await SessionService.GetSessionInfo();
        allowModification = session.userType === 'customer' && session.CustomerGroup.Level === 1;

        const { match, location } = this.props;
        const system = match && match.params && match.params.system;
        let systemInfo = this.getSystemInfo(system);

        let result = await HelpArticleService.GetBySystem(systemInfo.id, source.token);
        this.treeFields.dataSource = result;
        
        this.setState({ loading: false, articles: result, systemInfo, allowModification }, () => {
            this.setTitlePanelHeight();
        });
    }

    async componentDidUpdate(prevProps, prevState) {
        const system = this.props.match && this.props.match.params && this.props.match.params.system;
        const prevSystem = prevProps.match && prevProps.match.params && prevProps.match.params.system;

        if (system !== prevSystem) {
            let systemInfo = this.getSystemInfo(system);
            this.setState({ loading: true });
            let result = await HelpArticleService.GetBySystem(systemInfo.id, source.token);
            this.treeFields.dataSource = result;
            // this.menuTreeRef.refresh();
            this.setState({ loading: false, articles: result, systemInfo });
        }
    }

    componentWillUnmount() {
        source.cancel();
    }

    setTitlePanelHeight() {
        let titleWrapper = document.getElementsByClassName('help-title-wrapper')[0];
        let menuTitle = document.querySelector('.help-title-wrapper h3');

        if (titleWrapper && menuTitle) {
            let elem = document.getElementById('help-menu-tree');
            elem.style.maxHeight = `${(titleWrapper.clientHeight - menuTitle.clientHeight - 50)}px`;
            elem.style.overflowY = 'auto';
        }
    }

    getSystemInfo(system) {
        switch(system) {
            case "intralogix":
                return { id: 1, name: "Intralogix"};
            case "flexmat":
                return { id: 2, name: "Flexmat"};
        }
    }

    async onHelpToolbarClicked(args) {
        if (args.item.id === 'add') {
            this.subjectRef.value = '';
            this.richTextEditorRef.value = '';
            this.addEditHelpDialog.header = "Ny hjælp";
            this.addEditHelpDialog.show();
        }
        else if (args.item.id === 'edit') {
            let selectedRows = this.helpGridRef.getSelectedRecords();

            if (selectedRows.length > 0) {
                this.subjectRef.value = selectedRows[0].Subject;
                this.richTextEditorRef.value = selectedRows[0].Body;
                this.addEditHelpDialog.header = "Redigere hjælp";
                this.addEditHelpDialog.show();
                this.setState({ selectedArticle: selectedRows[0] });
            }
            else {
                this.dialogOpen("Advarsel", Localization.General.EditNoRowSelection.replace("{context}", "hjælp"));
            }
        }
        else if (args.item.id === 'delete') {
            let selectedRows = this.helpGridRef.getSelectedRecords();

            if (selectedRows.length > 0) {
                this.setState({ selectedArticle: selectedRows[0] }, () => {
                    this.deleteDialog.show();
                });
            }
            else {
                this.dialogOpen("Advarsel", Localization.General.PleaseSelectItem.replace("{context}", "hjælp"));
            }
        }
    }

    async processTopic(state) {
        let { article, selectedArticle, selectedMenu } = this.state;
        let result = null;
        switch (state) {
            case "add":
            case "edit":
                let payload = {
                    Id: selectedArticle ? selectedArticle.Id : 0,
                    Subject: this.subjectRef.value,
                    Body: this.richTextEditorRef.value,
                    MenuRefId: selectedMenu.Id
                }

                result = await HelpArticleService.Save(payload, source.token);

                if (!result.HasError) {
                    this.refreshMenuArticles(selectedMenu.Id).then(() => {
                        this.addEditHelpDialog.hide();
                        this.setState({ selectedArticle: null });

                        let message = state == "add" ? Localization.General.SuccessAdd.replace("{context}", "hjælp") : 
                            Localization.General.SuccessUpdate.replace("{context}", "hjælp");
                        
                        this.dialogOpen(Localization.General.SuccessHeader, message);
                    });
                }
                else {
                    this.dialogOpen("Advarsel", result.ErrorMessage);
                }
                break;
            case "delete":
                result = await HelpArticleService.Delete(selectedArticle.Id, source.token);
                if (!result.HasError) {
                    this.refreshMenuArticles(selectedArticle.MenuRefId).then(() => {
                        selectedArticle = null;
                        this.setState({ selectedArticle });
                        this.deleteDialog.hide();
                        this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessDelete.replace("{context}", "hjælp"));
                    });
                }
                else {
                    this.dialogOpen("Advarsel", result.ErrorMessage);
                }
                break;
        }
    }

    async refreshMenuArticles(menuRefId) {
        let { articles } = this.state;
        const response = await HelpArticleService.GetByMenu(menuRefId, source.token);
        
        if (!response.HasError) {
            let article = articles.find(a => a.Id === menuRefId);
            if (article) {
                article.HelpArticles = response;
                this.helpGridRef.dataSource = response;
                this.helpGridRef.refresh();
            }

            this.setState({ article });
        }
    }

    viewTopic(props) {
        return (
            <i className="c-icon c-icon-external-link clickable" onClick={() => this.readTopic(props)}></i>
        )
    }

    readTopic(data) {
        let { readArticle } = this.state;
        readArticle.subject = data.Subject;
        readArticle.body = data.Body;
        this.setState({ readArticle }, () => {
            this.readHelpDialog.show();
        });
    }

    onNodeSelected(args) {
        let { articles } = this.state;
        if (args.node.classList.contains('e-level-1')) {
            this.menuTreeRef.collapseAll();
            this.menuTreeRef.expandAll([args.node]);
            this.menuTreeRef.expandOn = "None";
        }

        if (articles.some(a => a.Id === parseInt(args.nodeData.id))) {
            let article = articles.find(a => a.Id === parseInt(args.nodeData.id));
            this.helpGridRef.dataSource = article.HelpArticles;
            this.helpGridRef.refresh();
            this.setState({ selectedMenu: article, selectedArticle: null });
        }
    }

    dialogOpen(header, content) {
        this.notificationDialog.header = header;
        this.notificationDialog.content = content;
        this.notificationDialog.show();
    }

    render() {
        const { loading, redirect, hasUnsavedChanges, systemInfo, allowModification, readArticle } = this.state;

        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        return (
            <div className="content-pane-wrapper">
                <div className="module-header">
                    <h1>{this.pageName}</h1>
                </div>
                <div className="help-article-wrapper">
                    <NavigationPrompt when={hasUnsavedChanges} 
                        navigate={path => this.props.history.push(path)}
                        initiateSave={() => this.onHelpToolbarClicked({ item: { id: 'update' } })}
                        shouldBlockNavigation={location => {
                            if (hasUnsavedChanges) {
                                return true;
                            }
                            return false;
                        }}
                    />
                    <div className="help-title-wrapper">
                        <h3>{systemInfo.name}</h3>
                        <TreeViewComponent id='help-menu-tree' ref={ref => this.menuTreeRef = ref} fields={this.treeFields} nodeSelected={this.onNodeSelected} />
                    </div>
                    <div className="help-articles-wrapper">
                        {
                            allowModification &&
                            <div className="help-articles-toolbar c-toolbar-plain">
                                <ToolbarComponent ref={ref => this.helpToolbarRef = ref} clicked={this.onHelpToolbarClicked}>
                                    <ItemsDirective>
                                        <ItemDirective id="add" prefixIcon='tb-icons c-icon-add-black' text="Ny" tooltipText='Ny' />
                                        <ItemDirective id="edit" prefixIcon='tb-icons c-icon-edit' text="Redigere" tooltipText='Redigere' />
                                        <ItemDirective id="delete" prefixIcon='tb-icons c-icon-delete' text="Slet" tooltipText='Slet' />
                                    </ItemsDirective>
                                </ToolbarComponent>
                            </div>
                        }
                        <div className="help-articles-container">
                            <GridComponent ref={ref => this.helpGridRef = ref } dataSource={this.data} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings} editSettings={{}}
                                selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both">
                                <ColumnsDirective>
                                    <ColumnDirective field="Subject" headerText="Emne" />
                                    <ColumnDirective field="Id" headerText="Vis" textAlign="Center" width='100' template={this.viewTopic} />
                                </ColumnsDirective>
                                <Inject services={[Page, Sort, Filter, Selection, Toolbar, Edit, Search, Reorder, Resize]} />
                            </GridComponent>
                        </div>
                    </div>
                    <DialogComponent isModal={true} width='auto' ref={dialog => this.notificationDialog = dialog} target='body' buttons={this.notificationButtons}
                        visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} />
                    <DialogComponent isModal={true} width='auto' ref={dialog => this.deleteDialog = dialog} target='body' buttons={this.deleteButtons} header="Advarsel" content={MessageHelper.MB7.message.replace("{context}", "hjælp")}
                        visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} />
                    <DialogComponent isModal={true} width='auto' ref={dialog => this.addEditHelpDialog = dialog} target='body' buttons={this.helpButtons} visible={false} showCloseIcon={true} 
                        animationSettings={this.animationSettings} cssClass="help-addedit-dialog" allowDragging={true} enableResize={true} width="50%" close={() => {

                        }}>
                            <div className="help-addeditdialog-wrapper">
                                <div className="field">
                                    <TextBoxComponent id="subject" ref={ref => this.subjectRef = ref} placeholder="Emne" floatLabelType="Auto" />
                                </div>
                                <div className="field">
                                    <label>Body:</label>
                                    <RichTextEditorComponent id="outlookRTE" ref={rteRef => this.richTextEditorRef = rteRef} locale="da" format={this.format} toolbarSettings={this.toolbarSettings}>
                                        <InjectRTE services={[HtmlEditor, RTEToolbar, Image, Link, QuickToolbar]} />
                                    </RichTextEditorComponent>
                                </div>
                            </div>
                    </DialogComponent>
                    <DialogComponent isModal={true} width='auto' ref={dialog => this.readHelpDialog = dialog} target='body' buttons={this.readHelpButtons} visible={false} showCloseIcon={true} 
                        animationSettings={this.animationSettings} allowDragging={true} enableResize={true} width="50%" header="Hjælp">
                            <div className="help-topic-info-wrapper">
                                <div className="help-topic-subject">
                                    <label>Emne: {readArticle.subject}</label>
                                </div>
                                <div className="help-topic-body" dangerouslySetInnerHTML={{__html: readArticle.body}}></div>
                            </div>
                    </DialogComponent>
                </div>
            </div>
        )
    }
}

export default HelpArticles;
