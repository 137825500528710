import HttpClient from '../../utilities/HttpClient';

const API = {
    getDepartments: '/department',
    postDepartment: '/department',
    putDepartment: '/department',
    deleteDepartment: '/department/{department_id}',
    putUndoDepartment: '/department/undo/{customerId}',
    putRedoDepartment: '/department/redo/{customerId}'
}

class DepartmentService {
    async AddUpdateDepartment(state, data, cancelToken) {
        const payload = {
            Name: data.Name,
            Street: data.Street,
            PostalCodeId: parseInt(data.PostalCodeId),
            Country: data.Country,
            CustomerId: parseInt(data.CustomerId),
            PhoneNumber: data.PhoneNumber,
            Email: data.Email,
            DeliveryStreet: data.DeliveryStreet,
            DeliveryPostalCodeId: parseInt(data.DeliveryPostalCodeId),
            DeliveryCountry: data.DeliveryCountry,
            DeliveryATT: data.DeliveryATT
        };

        switch(state) {
            case 'add':
                return await HttpClient.Post(API.postDepartment, payload, cancelToken);
            case 'update':
                payload.Id = data.Id;
                return await HttpClient.Put(API.putDepartment, payload, cancelToken);
            default:
                return null;
        }
    }

    async DeleteDepartment(id, cancelToken) {
        const url = API.deleteDepartment.replace('{department_id}', id);
        return await HttpClient.Delete(url, null, cancelToken);
    }

    async GetDepartments(cancelToken) {
        return await HttpClient.Get(API.getDepartments, null, cancelToken);
    }

    async UndoDepartment(id, cancelToken) {
        const url = API.putUndoDepartment.replace('{customerId}', id);
        return await HttpClient.Put(url, null, cancelToken);
    }

    async RedoDepartment(id, cancelToken) {
        const url = API.putRedoDepartment.replace('{customerId}', id);
        return await HttpClient.Put(url, null, cancelToken);
    }
}

const departmentService = new DepartmentService();
export default departmentService;