import HttpClient from '../../utilities/HttpClient';

const API = {
    getSuppliers: `/supplier/{customer_id}/byCustomer`,
    getSupplier: `/supplier/{supplier_id}`,
    postSupplierInfo: `/supplier/info`,
    postSupplierSettings: `/supplier/settings`,
    deleteSupplier: `/supplier/{supplier_id}`,
    undoSupplier: `/supplier/undo/{id}`,
    redoSupplier: `/supplier/redo/{id}`,

    getContacts: '/suppliercontact/{supplier_id}',
    getContact: '/suppliercontact/{supplier_id}/get',
    postContact: '/suppliercontact',
    undoContact: '/suppliercontact/{supplier_id}/undo',
    redoContact: '/suppliercontact/{supplier_id}/redo',

    postBulkSave: '/supplier/bulkSave'
}

class SupplierService {
    async GetSuppliers(customerId, cancelToken) {
        const url = API.getSuppliers.replace("{customer_id}", customerId);
        let result = await HttpClient.Get(url, null, cancelToken);

        if (!result.HasError) {
            result = result.map(r => {
                r.PostalCodeId = r.PostalCode ? r.PostalCode.Id : null;
                return r;
            });
        }

        return result;
    }

    async GetSupplier(id, cancelToken) {
        const url = API.getSupplier.replace("{supplier_id}", id);

        let result = await HttpClient.Get(url, null, cancelToken);

        if (!result.HasError) {
            result.PostalCodeId = result.PostalCode ? result.PostalCode.Id : null;
            result.CityId = result.City ? result.City.Id : null;
            result.RegionId = result.Region ? result.Region.Id : null;
            result.CountryId = result.Country ? result.Country.Id : null;
        }

        return result;
    }

    async DeleteSupplier(id, cancelToken) {
        const url = API.deleteSupplier.replace('{supplier_id}', id);
        return await HttpClient.Delete(url, null, cancelToken);
    }

    async AddUpdateSupplier(data, cancelToken) {
        let payload = {
            Id: data.Id,
            SupplierNo: data.SupplierNo,
            Company: data.Company,
            Street: data.Street,
            PostalCodeId: data.PostalCodeId,
            Country: data.Country,
            MainPhone: data.MainPhone,
            MainEmail: data.MainEmail,
            VatNo: data.VatNo,
            CustomerId: parseInt(data.CustomerId),
            DeptMachCustomerNo: data.DeptMachCustomerNo
        };
        
        return await HttpClient.Post(API.postSupplierInfo, payload, cancelToken);
    }

    async AddUpdateSupplierSettings(data, cancelToken) {
        let payload = {
            Id: data.Id,
            DeliveryTimeInDays: data.DeliveryTimeInDays,
            AllOrderInterval: data.AllOrderInterval ? data.AllOrderInterval : 0,
            OrderOutput: data.OrderOutput,
            OrderOutputFormat: data.OrderOutputFormat,
            OrderOutputEmails: data.OrderOutputEmails,
            CustomerId: parseInt(data.CustomerId),
            OrderTimeSend: data.OrderTimeSend,
            BackOrder: data.BackOrder,
            BackOrderDays: data.BackOrderDays,
            SupplierConfirmation: data.SupplierConfirmation
        };

        // if (data.OrderTimeSend) {
        //     if (!isNaN(Date.parse(data.OrderTimeSend))) {
        //         let date = new Date(data.OrderTimeSend);
        //         let hrs = date.getHours();
        //         let mins = date.getMinutes();
                
        //         if (hrs.toString().length === 1) {
        //             hrs = `0${hrs}`;
        //         }
        //         if (mins.toString().length === 1) {
        //             mins = `0${mins}`;
        //         }
        //         payload.OrderTimeSend = `${hrs}:${mins}`;
        //     }
        // }

        if (data.DeptOrderInterval) {
            payload.DeptOrderInterval = data.DeptOrderInterval.filter(x => x.OrderInterval && x.OrderInterval != 0).map(dept => {
                return {
                    DeptId: dept.Id,
                    OrderInterval: dept.OrderInterval
                }
            })
        }
        
        return await HttpClient.Post(API.postSupplierSettings, payload, cancelToken);
    }

    async UndoSupplier(id, cancelToken) {
        const url = API.undoSupplier.replace('{id}', id);
        return await HttpClient.Put(url, null, cancelToken);
    }

    async RedoSupplier(id, cancelToken) {
        const url = API.redoSupplier.replace('{id}', id);
        return await HttpClient.Put(url, null, cancelToken);
    }

    async GetContacts(supplierId, cancelToken) {
        const url = API.getContacts.replace("{supplier_id}", supplierId);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async GetContact(supplierId, cancelToken) {
        const url = API.getContact.replace("{supplier_id}", supplierId);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async SaveContacts(data, cancelToken) {
        return await HttpClient.Post(API.postContact, data, cancelToken);
    }

    async UndoContacts(id, cancelToken) {
        const url = API.undoContact.replace('{supplier_id}', id);
        return await HttpClient.Put(url, null, cancelToken);
    }

    async RedoContacts(id, cancelToken) {
        const url = API.redoContact.replace('{supplier_id}', id);
        return await HttpClient.Put(url, null, cancelToken);
    }

    async BulkSave(data, cancelToken) {
        return await HttpClient.Post(API.postBulkSave, data, cancelToken);
    }
}

const supplierService = new SupplierService();
export default supplierService;