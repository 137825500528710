import React, { Component } from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import { DropDownList } from '@syncfusion/ej2-react-dropdowns';
import { DataManager } from '@syncfusion/ej2-data';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Page, Sort, Toolbar, Selection, Search, Reorder, Resize, Edit } from '@syncfusion/ej2-react-grids';
import Axios from 'axios';
import XLSX from 'xlsx';
import { withRouter } from 'react-router-dom';
import { DateTimePicker } from '@syncfusion/ej2-calendars';
import Dropzone from 'react-dropzone'

import NavigationPrompt from '../../components/NavigationPrompt';
import Localization from '../../utilities/Localization';

import SessionService from '../../services/SessionService';
import Loading from '../../components/Loading';
import SettingsService from '../../services/SettingsService';
import SupplierService from '../../services/Supplier/SupplierService';
import CustomerService from '../../services/Customer/CustomerService';
import regionService from '../../services/RegionService';
import { ValidateEmail } from '../../utilities/Helper';
import { buildGridSettings } from '../../utilities/Helper';

export class SupplierImportExport extends Component {
    source = Axios.CancelToken.source();
    gridSettingsKey = "SupplierImportExportList";
    hasDraggedColumn = false;
    gridSettings = '';

    constructor() {
        super();

        this.state = {
            hasUnsavedChanges: false,
            loading: true,
            hasInvalidData: false,
            stageData: [],
            validationReport: [],
            files: [],
            tempOtherSettingsChanges: {}
        };

        this.data = [];

        this.pageSettings = {
            pageSize: 25,
            pageSizes: ['Alle', '5', '10', '20', '50', '100']
        };

        this.reviewDataPageSettings = {
            pageSize: 10
        }

        this.selectionSettings = {
            persistSelection: true
        };

        this.filterSettings = {
            type: 'Menu'
        };
        
        this.importTabHeader = [
            { text: "Vælg fil" }, 
            { text: "Se data" },
            { text: "Import" }
        ];

        this.ImportColumns = [ "Leverandør Nr.", "Firma Navn", "Adresse", "Post Nr.", "Tlf. Nr.", "Mail", "Cvr. Nr.", "Leveringstid I Dage", "Afsend Ordre Inden Kl.(24hr format)", "Genbestillings Interval (Alle afdelinger)", "Ordre output(Email/API)", "Email Address(separate by comma)", "Ordre output Filformat(PDF/CSV/Excel)", "Tillad restordre (Y/N)", "Slet restordre ældre end(dage)", "Leverandør skal bekræfte Ordre(Y/N)" ];
        this.ContactImportColumns = [ "Leverandør Nr.", "Navn", "Afdeling", "Stilling", "Notat", "Tlf. nr. 1", "Tlf. nr. 2", "Mail" ];

        this.init = this.init.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.processImport = this.processImport.bind(this);
        this.validateData = this.validateData.bind(this);
        this.onOtherSettingClose = this.onOtherSettingClose.bind(this);
        this.onSaveOtherSetting = this.onSaveOtherSetting.bind(this);

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.notificationButtons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.validationReportDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];

        this.otherSettingsButtons = [
            {
                click: this.onOtherSettingClose,
                buttonModel: {
                    content: 'Tilbage'
                }
            },
            {
                click: this.onSaveOtherSetting,
                buttonModel: {
                    isPrimary: true,
                    content: 'OK'
                }
            }
        ];
        /* End Dialog options  */
    }

    async componentDidMount() {
        this.source.cancel();
        this.source = Axios.CancelToken.source();

        this.init();

        const sessionInfo = await SessionService.GetSessionInfo();
        this.CustomerId = sessionInfo.Id;

        const suppliers = await SupplierService.GetSuppliers(sessionInfo.Id, this.source.token);
        this.data = suppliers;
        
        const outputData = await SettingsService.GetOutputOptions(this.source.token);
        this.orderOutput = outputData;

        const fileFormatData = await SettingsService.GetFileFormats(this.source.token);
        this.orderOutputFileFormat = fileFormatData;

        const regions = await regionService.GetRegion(this.source.token);
        this.postalCodes = [];
        if (Array.isArray(regions)) {
            regions.forEach(region => {
                region.Cities.forEach(city => {
                    city.PostalCodes.forEach(postal => {
                        this.postalCodes.push(postal);
                    })
                });
            });
        };

        const departments = await CustomerService.GetAccountDepartments(sessionInfo.Id, this.source.token);
        this.departments = departments;

        this.departmentDropDownParams = {
            create: () => {
                this.departmentElem = document.createElement('input');
                return this.departmentElem;
            },
            destroy: () => { this.departmentObj.destroy(); },
            read: () => {
                this.selectedDepartment = this.departmentObj.value;
                return this.departmentElem.value;
            },
            write: (e) => {
                this.departmentObj = new DropDownList({
                    dataSource: new DataManager(departments),
                    fields: { value: 'Id', text: 'Name' },
                    floatLabelType: 'Never',
                    value: e.rowData.DeptId
                  });
                  this.departmentObj.appendTo(this.departmentElem);
            },
        };

        this.postalCodeDropDownParams = {
            create: () => {
                this.postalCodeElem = document.createElement('input');
                return this.postalCodeElem;
            },
            destroy: () => { this.postalCodeObj.destroy(); },
            read: () => {
                this.selectedPostalCode = this.postalCodeObj.value;
                return this.postalCodeElem.value;
            },
            write: (e) => {
                this.postalCodeObj = new DropDownList({
                    dataSource: new DataManager(this.postalCodes),
                    fields: { value: 'Id', text: 'Value' },
                    floatLabelType: 'Never',
                    value: e.rowData.PostalCodeId
                  });
                  this.postalCodeObj.appendTo(this.postalCodeElem);
            },
        };

        this.orderOutputParams = {
            create: () => {
                this.orderOutputElem = document.createElement('input');
                return this.orderOutputElem;
            },
            destroy: () => { this.orderOutputObj.destroy(); },
            read: () => {
                this.selectedOrderOuput = this.orderOutputObj.value;
                return this.orderOutputElem.value;
            },
            write:(e) => {
                this.orderOutputObj = new DropDownList({
                    dataSource: new DataManager(this.orderOutput),
                    fields: { value: 'Value', text: 'Key' },
                    floatLabelType: 'Never',
                    value: e.rowData.OrderOutput
                  });
                this.orderOutputObj.appendTo(this.orderOutputElem);
            }
        };

        this.orderOutputFileFormatParams = {
            create: () => {
                this.orderOutputFormatElem = document.createElement('input');
                return this.orderOutputFormatElem;
            },
            destroy: () => { this.orderOutputFormatObj.destroy(); },
            read: () => {
                this.selectedOrderOutputFormat = this.orderOutputFormatObj.value;
                return this.orderOutputFormatElem.value;
            },
            write:(e) => {
                this.orderOutputFormatObj = new DropDownList({
                    dataSource: new DataManager(this.orderOutputFileFormat),
                    fields: { value: 'Value', text: 'Key' },
                    floatLabelType: 'Never',
                    value: e.rowData.OrderOutputFormat
                  });
                this.orderOutputFormatObj.appendTo(this.orderOutputFormatElem);
            }
        };

        const gridSettings = await SettingsService.GetGridSettings(this.gridSettingsKey, this.source.token);
        if (!gridSettings.HasError) {
            this.gridSettings = gridSettings.Settings;
        }

        this.setState({ loading: false }, () => {
            window.addEventListener('wheel', this.onWheelScroll);
        });
    }

    init() {
        this.toolbarOptions = [
            { text: 'Import', tooltipText: 'Import', id: 'import', prefixIcon: 'e-upload' },
            { text: 'Excel Export', tooltipText: 'Excel Export', id: 'export', prefixIcon: 'e-export-excel' },
            { text: 'Download Template', tooltipText: 'Download Template', id: 'download_template', prefixIcon: 'e-download' },
            { text: Localization.General.GridResetFilter, tooltipText: Localization.General.GridResetFilter, id: 'reset_filter', prefixIcon: 'e-cancel' }
        ];

        this.reviewDataToolbarOptions = [
            'Edit', 'Update', 'Cancel', 'Delete',
            { text: 'Øvrige indstillinger', tooltipText: 'Øvrige indstillinger', id: 'other_settings', prefixIcon: 'e-more-details' }
        ];

        this.reviewDataEditSettings = {
            allowEditing: true,
            allowDeleting: true,
            showDeleteConfirmDialog: true
        };

        this.orderTimeSendEdit = {
            create:() => {
                this.orderTimeElem = document.createElement('input');
                return this.orderTimeElem;
            },
            destroy:() => {
                this.datePickerObj.destroy();
            },
            read:() => {
                return this.datePickerObj.value;
            },
            write:(args) => {
                this.datePickerObj = new DateTimePicker({
                  floatLabelType: 'Never',
                  value: new Date(args.rowData[args.column.field])
                });
              this.datePickerObj.appendTo(this.orderTimeElem);
            }
        };

        this.otherSettingEditSettings = {
            allowEditing: true,
            allowDeleting: true,
            showDeleteConfirmDialog: true
        }

        this.otherSettingToolbarOptions = [
            'Edit', 'Update', 'Cancel', 'Delete'
        ];
    }

    componentWillUnmount() {
        this.source.cancel();
        window.removeEventListener('wheel', this.onWheelScroll);
    }

    onColumnDragStart(args) {
        this.hasDraggedColumn = true;
    }

    onCreated() {
        if (this.gridSettings) {
            let settings = JSON.parse(this.gridSettings);
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(column => {
                        if (column.FromIndex !== column.ToIndex) {
                            var tempIndex = this.supplierGridRef.getColumnIndexByField(column.Field);
                            if (tempIndex !== column.ToIndex){
                                this.supplierGridRef.reorderColumnByIndex(column.FromIndex, column.ToIndex);
                            }
                        }
    
                        if (column.Width) {
                            this.supplierGridRef.columns[column.ToIndex].width = column.Width;
                        }
                    });
                }

                if (settings.colName && settings.direction) {
                    this.supplierGridRef.sortColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.supplierGridRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.supplierGridRef.refreshColumns();

                if (settings.pageSize) {
                    if (settings.pageSize === "Alle") {
                        this.supplierGridRef.pageSettings.pageSize = this.supplierGridRef.pageSettings.totalRecordsCount;
                        document.querySelector("#supplier-tools-grid .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.supplierGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
            }
        }
    }

    async onActionComplete(args) {
        if (args.requestType === 'paging') {
            if (this.supplierGridRef.pageSettings.pageSize === this.supplierGridRef.pageSettings.totalRecordsCount) {
                document.querySelector("#supplier-tools-grid .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, this.supplierGridRef.pageSettings.pageSize, this.supplierGridRef.pageSettings.totalRecordsCount, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };
            const result = await SettingsService.SaveGridSettings(payload, this.source.token);
        }
        else if (args.requestType === 'filtering') {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, this.source.token);

            if (!result.HasError) {
                this.props.notify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, this.source.token);

            if (!result.HasError) {
                this.props.notify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.supplierGridRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, this.hasDraggedColumn, columns);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, this.source.token);

            if (!result.HasError) {
                this.props.notify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }

            this.hasDraggedColumn = false;
        }
    }

    async onResizeStop(args) {
        args.requestType = "resizingGrid";
        let columns = this.supplierGridRef.getColumns();
        let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, columns);
        this.gridSettings = tempGridSettings;
        const payload = {
            Key: this.gridSettingsKey,
            Settings: this.gridSettings
        };

        const result = await SettingsService.SaveGridSettings(payload, this.source.token);

        if (!result.HasError) {
            this.props.notify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
        }
    }

    async onToolbarClick(args) {
        if (args.item.id === "import") {
            this.supplierImportDialog.show();
            this.supplierImportWizardTab.select(0);
            this.supplierImportWizardTab.enableTab(1, false);
            this.supplierImportWizardTab.enableTab(2, false);
            this.reviewButton.disabled = true;
            this.setState({ files: [] });

            setTimeout(() => {
                let element = document.querySelector('.supplier-file-list .file-name-text');
                if (element) {
                    element.innerHTML = "";
                }
            }, 300);
        }
        else if (args.item.id === "export") {
            let source = this.supplierGridRef.getSelectedRecords();
            if (source.length <= 0) {
                this.props.notify("Advarsel", Localization.General.PleaseSelectItemsToExport);
            } else {
                let supplierFormattedSource = [];
                let supplierContactFormattedSource = [];
    
                supplierFormattedSource.push([
                        "Leverandør Nr.", "Firma Navn", "Adresse", "Post Nr.", "Tlf. Nr.", "Mail", "Cvr. Nr.", "Leveringstid I Dage", "Afsend Ordre Inden Kl.(24hr format)", "Genbestillings Interval (Alle afdelinger)", "Ordre output(Email/API)", 
                        "Email Address(separate by comma)", "Ordre output Filformat(PDF/CSV/Excel)", "Tillad restordre (Y/N)", "Slet restordre ældre end(dage)", "Leverandør skal bekræfte Ordre(Y/N)" ]);
                supplierContactFormattedSource.push([ "Leverandør Nr.", "Navn", "Afdeling", "Stilling", "Notat", "Tlf. nr. 1", "Tlf. nr. 2", "Mail" ]);
    
                for (let index = 0; index < source.length; index++) {
                    const element = source[index];
                    let item = [];
                    let orderOutput = this.orderOutput.find(a => a.Value === element.OrderOutput);
                    let orderOutputFormat = this.orderOutputFileFormat.find(a => a.Value === element.OrderOutputFormat);
    
                    item.push(element.SupplierNo);
                    item.push(element.Company);
                    item.push(element.Street);
                    item.push(element.PostalCode ? element.PostalCode.Value : '');
                    item.push(element.MainPhone);
                    item.push(element.MainEmail);
                    item.push(element.VatNo);
                    item.push(element.DeliveryTimeInDays);
                    item.push(element.OrderTimeSend);
                    item.push(element.AllOrderInterval);
                    item.push(orderOutput ? orderOutput.Key : null);
                    item.push(element.OrderOutputEmails.join(','))
                    item.push(orderOutputFormat ? orderOutputFormat.Key : null);
                    item.push(element.BackOrder ? "Y" : "N");
                    item.push(element.BackOrderDays);
                    item.push(element.SupplierConfirmation ? "Y" : "N");
                    supplierFormattedSource.push(item);
    
                    for (let index = 0; index < element.SupplierContacts.length; index++) {
                        const contact = element.SupplierContacts[index];
                        let department = this.departments.find(d => d.Id === contact.DeptId);
                        let contactItem = [];
                        contactItem.push(element.SupplierNo);
                        contactItem.push(contact.Name);
                        contactItem.push(department ? department.Name : null);
                        contactItem.push(contact.Position);
                        contactItem.push(contact.Note);
                        contactItem.push(contact.DirectPhone1);
                        contactItem.push(contact.DirectPhone2);
                        contactItem.push(contact.Mail);
                        supplierContactFormattedSource.push(contactItem);
                    }
                }
    
                let supplierInfo = XLSX.utils.aoa_to_sheet(supplierFormattedSource);
                let supplierContacts = XLSX.utils.aoa_to_sheet(supplierContactFormattedSource);
                let workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, supplierInfo, "Leverandør Info");
                XLSX.utils.book_append_sheet(workbook, supplierContacts, "Leverandør Kontakt");
                XLSX.writeFile(workbook, "Suppliers Export.xlsx"); // FOR TRANSLATION
            }
        }
        else if (args.item.id === "download_template") {
            let data1 = [
                this.ImportColumns
            ];
            
            let data2 = [
                this.ContactImportColumns
            ];

            let sheet = XLSX.utils.aoa_to_sheet(data1);
            let sheet2 = XLSX.utils.aoa_to_sheet(data2);
            let workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, sheet, "Leverandør Info");
            XLSX.utils.book_append_sheet(workbook, sheet2, "Leverandør Kontakt");
            XLSX.writeFile(workbook, "Supplier Template.xlsx"); // FOR TRANSLATION
        } else if (args.item.id === 'reset_filter') {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                settings.filters = [];
                this.gridSettings = settings;
                const payload = {
                    Key: this.gridSettingsKey,
                    Settings: JSON.stringify(this.gridSettings)
                };
    
                const result = await SettingsService.SaveGridSettings(payload, this.source.token);
    
                if (!result.HasError) {
                    this.props.notify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                }
            }

            this.supplierGridRef.clearFiltering();
        }
    }

    onChangeFile(files) {
        if (this.importStatusRef) {
            this.importStatusRef.dataSource = [];
            this.importStatusRef.refresh();
            this.importStatusRef.showSpinner();
        }

        const file = files[0];
        let importStatusData = [];
        
        this.setState({ files }, () => {
            let element = document.querySelector('.supplier-file-list .file-name-text');
            element.innerHTML = file.name;
        });

        if (file) {
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;
            reader.onload = e => {
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
                const supplierInfoSheet = wb.Sheets[wb.SheetNames[0]];
                const contactSheetName = wb.Sheets[wb.SheetNames[1]];

                const colHeaders = [];
                const colCount = XLSX.utils.decode_range(supplierInfoSheet['!ref']).e.c + 1;
                for (let i = 0; i < colCount; ++i) {
                    colHeaders[i] = supplierInfoSheet[`${XLSX.utils.encode_col(i)}1`].v.toString().trim();
                }

                const contactColHeaders = [];
                const contactColCount = contactSheetName ? XLSX.utils.decode_range(contactSheetName['!ref']).e.c + 1 : 0;
                for (let i = 0; i < contactColCount; ++i) {
                    contactColHeaders[i] = contactSheetName[`${XLSX.utils.encode_col(i)}1`].v.toString().trim();
                }

                let allColDiffs = [];
                var colDiffs = this.ImportColumns.filter(x => !colHeaders.includes(x));
                var contactColDiffs = this.ContactImportColumns.filter(x => !contactColHeaders.includes(x));
                if (colDiffs) allColDiffs.push(...colDiffs);
                if (contactColDiffs) allColDiffs.push(...contactColDiffs);
                if (allColDiffs && allColDiffs.length > 0) {
                    importStatusData.push({
                        Row: "Column Headers",
                        Status: `Column/s (${allColDiffs.join(", ")}) findes ikke`
                    });
                }

                const supplierData = XLSX.utils.sheet_to_json(supplierInfoSheet, { header: 0, raw: false, dateNF: "dd-mm-yyyy" });
                const contactData = XLSX.utils.sheet_to_json(contactSheetName, { header: 0, raw: false });
                let tempSuppliers = this.data.map(x => {return { Id: 0, SupplierNo: x.SupplierNo, SourceId: x.Id }});

                let data = supplierData.map((t, index) => {
                    let errorMessages = [];
                    let supplierNo = t["Leverandør Nr."] ? t["Leverandør Nr."].toString().trim() : '';
                    let isBackOrderEnabled = false, mustConfirmOrder = false;
                    let rawOrderOutput = t["Ordre output(Email/API)"];
                    let rawOrderOutputFormat = t["Ordre output Filformat(PDF/CSV/Excel)"];
                    let rawPostalCode = t["Post Nr."];
                    let postalCode = null, orderOutput = null, orderOutputFormat = null, orderTime = null;
                    let supplierContacts = [];
                    let existingSupplier = this.data.find(x => x.SupplierNo.toLowerCase() === supplierNo.toLowerCase());

                    if (rawPostalCode) {
                        postalCode = this.postalCodes.find(x => x.Value.toLowerCase() === rawPostalCode.toString().trim().toLowerCase());
                        if (!postalCode) {
                            errorMessages.push(`Post nr. (${rawPostalCode.toString().trim()}) findes ikke`);
                        }
                    }
                    
                    if (rawOrderOutput) {
                        orderOutput = this.orderOutput.find(x => x.Key.toLowerCase() === rawOrderOutput.toString().trim().toLowerCase());
                        if (!orderOutput) {
                            errorMessages.push(`Ordre output (${rawOrderOutput.toString().trim()}) findes ikke`);
                        }
                    }

                    if (rawOrderOutputFormat) {
                        orderOutputFormat = this.orderOutputFileFormat.find(x => x.Key.toLowerCase() === rawOrderOutputFormat.toString().trim().toLowerCase());
                        if (!orderOutputFormat) {
                            errorMessages.push(`Ordre output filformat (${rawOrderOutputFormat.toString().trim()}) findes ikke`);
                        }
                    }

                    if ("Tillad restordre (Y/N)" in t) {
                        isBackOrderEnabled = t["Tillad restordre (Y/N)"].toString().trim().toLowerCase() === "y";
                    }

                    if ("Leverandør skal bekræfte Ordre(Y/N)" in t) {
                        mustConfirmOrder = t["Leverandør skal bekræfte Ordre(Y/N)"].toString().trim().toLowerCase() === "y";
                    }

                    let contacts = contactData.filter(x => x["Leverandør Nr."] === t["Leverandør Nr."]);
                    if (contacts.length > 0) {
                        supplierContacts = contacts.map((c, cindex) => {
                            let rawDepartment = c["Afdeling"];
                            let department = null;

                            if (rawDepartment) {
                                department = this.departments.find(d => d.Name.toLowerCase() === rawDepartment.toString().trim().toLowerCase());
                                if (!department) {
                                    errorMessages.push(`Afdeling (${rawDepartment.toString().trim()}) findes ikke`);
                                }
                            }

                            let contactElement = {
                                Id: cindex + 1,
                                SourceId: 0,
                                Name: c["Navn"] ? c["Navn"].toString().trim() : '',
                                DeptId: department ? department.Id : 0,
                                DeptName: department ? department.Name : null,
                                Position: c["Stilling"] ? c["Stilling"].toString().trim() : '',
                                Note: c["Notat"] ? c["Notat"].toString().trim() : '',
                                DirectPhone1: c["Tlf. nr. 1"] ? c["Tlf. nr. 1"].toString().trim() : '',
                                DirectPhone2: c["Tlf. nr. 2"] ? c["Tlf. nr. 2"].toString().trim() : '',
                                Mail: c["Mail"] ? c["Mail"].toString().trim() : '',
                            };

                            let existingContact = {};
                            if (existingSupplier && department) {
                                existingContact = existingSupplier.SupplierContacts.find(x => x.Name.toString().trim().toLowerCase() === contactElement.Name.toString().trim().toLowerCase() && x.DeptId === department.Id);
                            }

                            if (existingContact) {
                                contactElement.SourceId = existingContact.Id;
                            }

                            return contactElement;
                        })
                    }

                    if ("Afsend Ordre Inden Kl.(24hr format)" in t) {
                        var hourArray = t["Afsend Ordre Inden Kl.(24hr format)"].toString().trim().split(":");
                        if (hourArray && hourArray.length >= 2) {
                            var hour = parseInt(hourArray[0]);
                            var minute = parseInt(hourArray[1]);
                            orderTime = new Date(2020, 1, 1, hour, minute, 0);
                        } else if (Date.parse(t["Afsend Ordre Inden Kl.(24hr format)"].toString().trim())) {
                            orderTime = new Date(`01/01/2020 ${t["Afsend Ordre Inden Kl.(24hr format)"]}`)
                        }

                        if (!orderTime) {
                            errorMessages.push(`Ukorrekt udfyldt afsend Ordre Inden Kl.(24hr format) (value: ${t["Afsend Ordre Inden Kl.(24hr format)"].toString().trim()})`);
                        }
                    }

                    let element = {
                        Id: index + 1,
                        SourceId: existingSupplier ? existingSupplier.Id : null,
                        SupplierNo: supplierNo,
                        Company: t["Firma Navn"] ? t["Firma Navn"].toString().trim() : '',
                        Address: t["Adresse"] ? t["Adresse"].toString().trim() : '',
                        PostalCodeId: postalCode ? postalCode.Id : '',
                        PostalCode: postalCode ? postalCode.Value : '',
                        PhoneNumber: t["Tlf. Nr."] ? t["Tlf. Nr."].toString().trim() : '',
                        Mail: t["Mail"] ? t["Mail"].toString().trim() : '',
                        VatNo: t["Cvr. Nr."] ? t["Cvr. Nr."].toString().trim() : '',
                        DeliveryTimeInDays: t["Leveringstid I Dage"] ? t["Leveringstid I Dage"].toString().trim() : '',
                        OrderTimeSend: orderTime ? orderTime : new Date('01/01/2020 00:00 AM'),
                        AllOrderInterval: parseInt(t["Genbestillings Interval (Alle afdelinger)"]) ? parseInt(t["Genbestillings Interval (Alle afdelinger)"]) : 0,
                        OrderOutput: orderOutput ? orderOutput.Value : 1,
                        OrderOutputText: orderOutput ? orderOutput.Key : "Email",
                        OrderOutputFormat: orderOutputFormat ? orderOutputFormat.Value : 1,
                        OrderOutputFormatText: orderOutputFormat ? orderOutputFormat.Key : "PDF",
                        OrderOutputEmails: t["Email Address(separate by comma)"] ? t["Email Address(separate by comma)"].toString().trim() : null,
                        BackOrder: isBackOrderEnabled,
                        BackOrderDays: t["Slet restordre ældre end(dage)"] ? t["Slet restordre ældre end(dage)"].toString().trim() : '',
                        SupplierConfirmation: mustConfirmOrder,
                        SupplierContacts: supplierContacts
                    };

                    tempSuppliers.push({ Id: element.Id, SupplierNo: element.SupplierNo });
                    errorMessages.push(...this.validateElement(element, tempSuppliers));

                    if (errorMessages.length > 0) {
                        importStatusData.push({
                            Row: (index + 1).toString(),
                            Status: errorMessages.join(", ")
                        });
                    }

                    return element;
                })
                
                this.setState({ stageData: data });

                if (this.importStatusRef) {
                    this.importStatusRef.hideSpinner();
                    if (data && data.length === 0) {
                        this.reviewButton.disabled = true;
                        importStatusData.push({
                            Row: "Alle",
                            Status: Localization.ImportExport.NoRowsFound
                        });
                    } else if (importStatusData.length === 0) {
                        this.reviewButton.disabled = false;
                        importStatusData.push({
                            Row: "Alle",
                            Status: Localization.ImportExport.NoErrorsFound
                        });
                    } else {
                        this.reviewButton.disabled = true;
                    }
                    this.importStatusRef.dataSource = importStatusData;
                    this.importStatusRef.refresh();
                }
            };

            if (rABS) reader.readAsBinaryString(file);
            else reader.readAsArrayBuffer(file);
        }
    }

    onButtonClick(args) {
        let { stageData, files } = this.state;
        
        switch(args.target.id) {
            case "select-file":
                this.supplierImportWizardTab.select(0);
                this.supplierImportWizardTab.enableTab(1, false);
                this.supplierImportWizardTab.enableTab(2, false);
                break;
            case "review-data":
                if (files.length <= 0) {
                    this.props.notify("Advarsel", Localization.ImportExport.PleaseSelectAFile);
                    return;
                }
                else if (stageData.length <= 0) {
                    this.props.notify("Advarsel", Localization.ImportExport.NoRowsFound);
                    return;
                }

                this.supplierImportWizardTab.enableTab(1, true);
                this.supplierImportWizardTab.select(1);
                setTimeout(() => {
                    this.stageGridRef.dataSource = stageData;
                    this.stageGridRef.refresh();
                }, 300);
                break;
            case "finalize-import":
                let isValid = this.validateData();

                if (!isValid) {
                    this.validationReportDialog.show();
                    return;
                }

                this.supplierImportWizardTab.enableTab(2, true);
                this.supplierImportWizardTab.select(2);

                this.processImport();
                break;
        }
    }

    validateData() {
        let { stageData, validationReport } = this.state;
        validationReport = [];
        let tempSuppliers = this.data.map(x => {return { Id: 0, SupplierNo: x.SupplierNo, SourceId: x.Id }});
        tempSuppliers.push(...stageData.map(x => {return { Id: x.Id, SupplierNo: x.SupplierNo }}));

        for (let index = 0; index < stageData.length; index++) {
            const element = stageData[index];
            let errorList = [];

            errorList = this.validateElement(element, tempSuppliers);

            if (errorList.length > 0) {
                validationReport.push({
                    Id: element.Id,
                    Messages: errorList
                })
            }
        }

        this.setState({ validationReport });
        return validationReport.length <= 0;
    }

    validateElement(element, tempSuppliers) {
        let errorList = [];

        if (!element.SupplierNo) {
            errorList.push('Leverandør Nr. kræves');
        }
        else if (tempSuppliers.some(x => x.SupplierNo === element.SupplierNo && x.Id !== element.Id && !(element.SourceId && x.SourceId === element.SourceId))) {
            errorList.push('Leverandør nr. er allerede taget');
        }

        if (!element.Company) {
            errorList.push('Firma Navn kræves');
        }
        if (!element.Mail) {
            errorList.push('Mail kræves');
        }

        if (!element.DeliveryTimeInDays) {
            errorList.push('Leveringstid I Dage kræves');
        }

        if (!element.OrderTimeSend) {
            errorList.push('Afsend Ordre Inden Kl. kræves');
        }

        if (!element.OrderOutput) {
            errorList.push('Ordre output kræves');
        }

        if (element.OrderOutputEmails) {
            let outputEmails = element.OrderOutputEmails.split(",");
            let emails = [];
            outputEmails.forEach(e => {
                if (!ValidateEmail(e)) {
                    emails.push(e);
                }
            });

            if (emails.length > 0) {
                errorList.push(Localization.General.InvalidEmailFormat);
            }
        }

        return errorList;
    }

    fileSelectTab() {
        return (
            <div className="tab-control--item">
                <Dropzone onDrop={this.onChangeFile} accept=".xls, .xlsx" maxFiles={1}>
                    {({getRootProps, getInputProps}) => (
                        <section className="container">
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} />
                                <p>Træk og slip filen her, eller klik for at vælge en fil</p>
                            </div>
                            <div className="supplier-file-list">
                                <div className="file-name-label">Valgte fil:</div>
                                <div className="file-name-text"></div>
                            </div>
                        </section>
                    )}
                </Dropzone>
                <div className="import-status-container">
                    <div>Import Status:</div>
                </div>
                <GridComponent ref={ref => this.importStatusRef = ref } dataSource={this.statusData} allowPaging={true} allowSorting={true} pageSettings={{ pageSize: 5 }}
                    selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both" width="100%" allowTextWrap={true}>
                    <ColumnsDirective>
                        <ColumnDirective headerText="Row" field="Row" width='200' isPrimaryKey={true} />
                        <ColumnDirective headerText="Status" field="Status" allowEditing={false} />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Search]} />
                </GridComponent>
                <div className="wizard-action-buttons">
                    <button ref={ref => this.reviewButton = ref} id="review-data" className="e-btn e-primary" onClick={this.onButtonClick}>Se data</button>
                </div>
            </div>
        )
    }

    async processImport() {
        let { stageData } = this.state;
        let payload = []

        for (let index = 0; index < stageData.length; index++) {
            const element = stageData[index];
            element.Id = 0;

            let orderTime = element.OrderTimeSend;

            payload.push({
                Id: element.SourceId ? element.SourceId : 0,
                SupplierNo: element.SupplierNo,
                Company: element.Company,
                Street: element.Address,
                PostalCodeId: element.PostalCodeId ? parseInt(element.PostalCodeId) : 0,
                MainPhone: element.PhoneNumber,
                MainEmail: element.Mail,
                VatNo: element.VatNo,
                DepartmentId: element.DepartmentId,
                DeliveryTimeInDays: parseInt(element.DeliveryTimeInDays),
                OrderTimeSend: `${orderTime.getHours()}`.padStart(2, 0) + ":" + `${orderTime.getMinutes()}`.padStart(2, 0), // FOR FORMATTING
                AllOrderInterval: parseInt(element.AllOrderInterval),
                DeptOrderInterval: [],
                DeptMachCustomerNo: [],
                OrderOutput: element.OrderOutput,
                OrderOutputFormat: element.OrderOutputFormat,
                OrderOutputEmails: element.OrderOutputEmails ? element.OrderOutputEmails.split(',') : [],
                BackOrder: element.BackOrder,
                BackOrderDays: parseInt(element.BackOrderDays),
                SupplierConfirmation: element.SupplierConfirmation,
                SupplierContacts: element.SupplierContacts.map(c => {
                    return {
                        Id: 0,
                        Name: c.Name,
                        DeptId: c.DeptId ? c.DeptId : 0,
                        Position: c.Position,
                        Note: c.Note,
                        DirectPhone1: c.DirectPhone1,
                        DirectPhone2: c.DirectPhone2,
                        Mail: c.Mail ? c.Mail : null,
                        IsDelete: false
                    }
                }),
                CustomerId: this.CustomerId,
                IsDelete: false,
                ChangeOrder: index + 1
            })
        }
        
        const result = await SupplierService.BulkSave(payload, this.source.token);

        if (!result.HasError) {
            this.props.notify(Localization.General.SuccessHeader, Localization.Tools.SuccessImport);
            const suppliers = await SupplierService.GetSuppliers(this.CustomerId, this.source.token);
            this.data = suppliers;

            this.supplierGridRef.dataSource = suppliers;
            this.supplierGridRef.refresh();

            this.importStatusRef.dataSource = [];
            this.importStatusRef.refresh();

            this.supplierImportDialog.hide();
            this.supplierImportWizardTab.select(0);
            this.supplierImportWizardTab.enableTab(1, false);
            this.supplierImportWizardTab.enableTab(2, false);

            this.setState({ stageData: [] });
        }
        else {
            this.props.notify("Advarsel", result.ErrorMessage);
            this.onButtonClick({ target: { id: 'review-data' }});
        }
    }

    reviewDataActionBegin(args) {
        if (args.requestType === "save") {
            let { stageData } = this.state;
            let data = stageData.find(x => x.Id === args.data.Id);
            data = Object.assign({}, args.data);
            data.PostalCodeId = this.selectedPostalCode;
            data.OrderOutput = this.selectedOrderOuput;
            data.OrderOutputFormat = this.selectedOrderOutputFormat;
            this.setState({ stageData });
        }
    }

    reviewDataActionComplete(args) {
        if (args.requestType === "delete") {
            let { stageData } = this.state;
            stageData = stageData.filter(x => x.Id !== args.data[0].Id);
            this.setState({ stageData });
        }
    }

    reviewDataTab() {
        return (
            <div className="tab-control--item">
                <GridComponent ref={ref => this.stageGridRef = ref } dataSource={this.stageData} allowPaging={true} allowSorting={true} pageSettings={this.reviewDataPageSettings} toolbar={this.reviewDataToolbarOptions}
                    selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both" editSettings={this.reviewDataEditSettings}
                    allowResizing={true} actionBegin={this.reviewDataActionBegin.bind(this)} actionComplete={this.reviewDataActionComplete.bind(this)} toolbarClick={this.onReviewDataToolbarClick.bind(this)} width="100%">
                    <ColumnsDirective>
                        <ColumnDirective headerText="No." field="Id" width='100' isPrimaryKey={true} />
                        <ColumnDirective headerText="Leverandør Nr." field="SupplierNo" />
                        <ColumnDirective headerText="Firma Navn" field="Company" />
                        <ColumnDirective headerText="Adresse" field="Address" />
                        <ColumnDirective headerText="Post Nr." field="PostalCode" editType="dropdownedit" edit={this.postalCodeDropDownParams} />
                        <ColumnDirective headerText="Tlf. nr." field="PhoneNumber" />
                        <ColumnDirective headerText="Mail" field="Mail" />
                        <ColumnDirective headerText="Cvr. Nr." field="VatNo" />
                        <ColumnDirective headerText="Leveringstid I Dage" field="DeliveryTimeInDays" textAlign="Right" />
                        <ColumnDirective headerText="Afsend Ordre Inden Kl." field="OrderTimeSend" type="datetime" edit={this.orderTimeSendEdit} format={{ type: 'datetime', format: 'HH:mm' }} />
                        <ColumnDirective headerText="Genbestillings Interval" field="AllOrderInterval" textAlign="Right" />
                        <ColumnDirective headerText="Ordre output" field="OrderOutputText" editType="dropdownedit" edit={this.orderOutputParams} />
                        <ColumnDirective headerText="Ordre output Filformat" field="OrderOutputFormatText" editType="dropdownedit" edit={this.orderOutputFileFormatParams} />
                        <ColumnDirective headerText="Email Address" field="OrderOutputEmails" />
                        <ColumnDirective headerText="Tillad restordre" field="BackOrder" textAlign="Center" editType="booleanedit" displayAsCheckBox={true} />
                        <ColumnDirective headerText="Slet restordre ældre end" field="BackOrderDays" />
                        <ColumnDirective headerText="Leverandør skal bekræfte Ordre" field="SupplierConfirmation" textAlign="Center" editType="booleanedit" displayAsCheckBox={true} />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Selection, Toolbar, Search, Resize, Edit]} />
                </GridComponent>
                <div className="wizard-action-buttons">
                    <button id="select-file" className="e-btn" onClick={this.onButtonClick}>Vælg fil</button>
                    <button id="finalize-import" className="e-btn e-primary" onClick={this.onButtonClick}>Bekræft og importer</button>
                </div>
            </div>
        )
    }

    finalizeImportTab() {
        return (
            <div className="tab-control--item">
                <div className="importing-text">Importere data. Vent  venligst</div>
            </div>
        )
    }

    onReviewDataToolbarClick(args) {
        if (args.item.id === "other_settings") {
            let { selectedSupplier, tempOtherSettingsChanges } = this.state;

            let suppliers = this.stageGridRef.getSelectedRecords();
            if (suppliers.length > 0)  {
                selectedSupplier = suppliers[0];
                tempOtherSettingsChanges = selectedSupplier;
                
                this.supplierContactsData = selectedSupplier.SupplierContacts;
                this.supplierContactsGridRef.dataSource = selectedSupplier.SupplierContacts;
                this.supplierContactsGridRef.refresh();

                this.setState({ selectedSupplier, tempOtherSettingsChanges });
                this.otherSettingsDialog.show();
            }
            else {
                this.props.notify("Advarsel", Localization.General.NoRowSelected);
            }
        }
    }

    onOtherSettingsActionBegin(args) {
        if (args.requestType === "save") {
            let { tempOtherSettingsChanges } = this.state;
            let contacts = Object.assign([], tempOtherSettingsChanges.SupplierContacts);
            let contact = contacts.find(c => c.Id === args.data.Id);
            contact.Name = args.data.Name;
            contact.Mail = args.data.Mail;
            contact.Position = args.data.Position;
            contact.Note = args.data.Note;
            contact.DirectPhone1 = args.data.DirectPhone1;
            contact.DirectPhone2 = args.data.DirectPhone2;
            contact.DeptId = this.selectedDepartment;
            contact.DeptName = args.data.DeptName;
            tempOtherSettingsChanges.SupplierContacts = contacts;

            this.supplierContactsGridRef.dataSource = tempOtherSettingsChanges.SupplierContacts;
            this.supplierContactsGridRef.refresh();
            this.setState({ tempOtherSettingsChanges });
        }
    }

    onOtherSettingsActionComplete(args) {
        if (args.requestType === "delete") {
            let { tempOtherSettingsChanges } = this.state;
            tempOtherSettingsChanges.SupplierContacts = tempOtherSettingsChanges.SupplierContacts.filter(c => c.Id !== args.data[0].Id);
            this.setState({ tempOtherSettingsChanges });
        }
    }

    onOtherSettingClose() {
        this.otherSettingsDialog.hide();
        this.setState({ tempOtherSettingsChanges: {}, selectedSupplier: null });
    }

    onSaveOtherSetting() {
        let { stageData, tempOtherSettingsChanges, selectedSupplier } = this.state;
        let supplier = stageData.find(s => s.Id === selectedSupplier.Id);
        supplier.SupplierContacts = tempOtherSettingsChanges.SupplierContacts;
        this.otherSettingsDialog.hide();
        this.setState({ stageData, tempOtherSettingsChanges: {}, selectedSupplier: null });
    }

    onSelect(args) {
        if (args.isSwiped) {
            args.cancel = true;
        }
    }

    onWheelScroll = () => {
        let grid = document.getElementById('supplier-tools-grid');
        let toolbar = document.querySelector('#supplier-tools-grid.e-grid .e-toolbar');
        let tableHeader = document.querySelector('#supplier-tools-grid .e-gridheader');

        if (toolbar && tableHeader) {
            let boundingRect = tableHeader.getBoundingClientRect();
            
            if (boundingRect.top < 90) {
                toolbar.classList.add('c-hover-grid-toolbar');
                toolbar.style.width = (grid.clientWidth - 5) + 'px';
            }
            else {
                toolbar.classList.remove('c-hover-grid-toolbar');
                toolbar.style.width = 'auto';
            }
        }
    }
    
    render() {
        const { loading, hasUnsavedChanges, validationReport } = this.state;
        
        if (loading) {
            return <Loading />
        }

        return (
            <div style={{ margin: '20px 0' }}>
                <NavigationPrompt when={hasUnsavedChanges} 
                    navigate={path => this.props.history.push(path)}
                    initiateSave={() => {}}
                    shouldBlockNavigation={location => {
                        if (hasUnsavedChanges) {
                            return true;
                        }
                        return false;
                    }}
                />
                <GridComponent id="supplier-tools-grid" ref={ref => this.supplierGridRef = ref } dataSource={this.data} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings} toolbar={this.toolbarOptions}
                    selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both" editSettings={{}}
                    allowReordering={true} allowResizing={true} toolbarClick={this.onToolbarClick.bind(this)} resizeStop={this.onResizeStop.bind(this)}
                    actionComplete={this.onActionComplete.bind(this)} columnDragStart={this.onColumnDragStart.bind(this)} created={this.onCreated.bind(this)}>
                    <ColumnsDirective>
                        <ColumnDirective type='checkbox' width='30' textAlign="Center"></ColumnDirective>
                        <ColumnDirective field="Id" headerText="Id" width='100' isPrimaryKey={true}/>
                        <ColumnDirective field="SupplierNo" headerText="Leverandør Nr." width='100' />
                        <ColumnDirective field="Company" headerText="Firma Navn" width='100' />
                        <ColumnDirective field="MainPhone" headerText="Telefon Nr." width='100' />
                        <ColumnDirective field="VatNo" headerText="Cvr. Nr." width='100' />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Selection, Toolbar, Search, Reorder, Resize]} />
                </GridComponent>
                <div>
                    <DialogComponent isModal={true} width='auto' ref={dialog => this.supplierImportDialog = dialog} target='body'visible={false} showCloseIcon={true} header="Import Leverandør" 
                        cssClass="import-dialog" animationSettings={this.animationSettings} allowDragging={true} enableResize={true}>
                            <div className="dialog-content">
                                <TabComponent ref={ref => this.supplierImportWizardTab = ref} className="tab-control" heightAdjustMode="Content" selecting={this.onSelect.bind(this)}>
                                    <TabItemsDirective>
                                        <TabItemDirective header={this.importTabHeader[0]} content={this.fileSelectTab.bind(this)} />
                                        <TabItemDirective header={this.importTabHeader[1]} content={this.reviewDataTab.bind(this)} disabled={true} />
                                        <TabItemDirective header={this.importTabHeader[2]} content={this.finalizeImportTab.bind(this)} disabled={true} />
                                    </TabItemsDirective>
                                </TabComponent>
                            </div>
                    </DialogComponent>
                </div>
                <div>
                    <DialogComponent isModal={true} width='auto' ref={dialog => this.validationReportDialog = dialog} target='body'visible={false} showCloseIcon={true} header="Rapport over Ændringer" 
                        cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} buttons={this.notificationButtons}>
                            <div>
                                <table className="table" style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th>Row #</th>
                                            <th className="text-left">Validation Messages</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            validationReport.map(validation => {
                                                return (
                                                    <tr className="changelog-row">
                                                        <td className="text-center">{validation.Id}</td>
                                                        <td className="required">
                                                            {validation.Messages.join(', ')}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                    </DialogComponent>
                </div>
                <div>
                    <DialogComponent id="other-settings-dialog" isModal={true} width='auto' ref={dialog => this.otherSettingsDialog = dialog} target='body'visible={false} showCloseIcon={true} header="Øvrige indstillinger" 
                        animationSettings={this.animationSettings} allowDragging={true} enableResize={true} buttons={this.otherSettingsButtons}>
                            <div className="other-settings-wrapper">
                                <GridComponent ref={ref => this.supplierContactsGridRef = ref } dataSource={this.supplierContactsData} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings} toolbar={this.otherSettingToolbarOptions}
                                    selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both" editSettings={this.otherSettingEditSettings}
                                    actionBegin={this.onOtherSettingsActionBegin.bind(this)} actionComplete={this.onOtherSettingsActionComplete.bind(this)} allowResizing={true}>
                                    <ColumnsDirective>
                                        <ColumnDirective field="Name" headerText="Navn" /> 
                                        <ColumnDirective field="DeptName" headerText="Afdeling" editType= 'dropdownedit' edit={this.departmentDropDownParams} /> 
                                        <ColumnDirective field="Position" headerText="Stilling" />
                                        <ColumnDirective field="Note" headerText="Notat" />
                                        <ColumnDirective field="DirectPhone1" headerText="Tlf. nr. 1" />
                                        <ColumnDirective field="DirectPhone2" headerText="Tlf. nr. 2" />
                                        <ColumnDirective field="Mail" headerText="Mail" />
                                    </ColumnsDirective>
                                    <Inject services={[Page, Sort, Filter, Selection, Toolbar, Search, Edit, Resize]} />
                                </GridComponent>
                            </div>
                    </DialogComponent>
                </div>
            </div>
        )
    }
}

export default withRouter(SupplierImportExport);
