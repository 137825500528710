import React, { Component } from 'react';
import logo from '../assets/logo_ilx_hor.png';

export class ErrorBoundary extends Component {
    constructor() {
        super();
        this.state  = { hasError : false };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        // CALL API TO LOG ERROR
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 'calc(100vh - 72px)'
                }}>
                    <div className="text-center">
                        <img src={logo}/>
                        <h1 style={{
                            marginTop: 30
                        }}>Something went wrong.</h1>
                    </div>
                </div>
            )
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
