import HttpClient from '../utilities/HttpClient';

const API = {    
    getList: '/otaVersion',
    save: '/otaVersion',
    delete: '/otaVersion/{id}'
}

class FirmwareVersionService {
    async GetList(cancelToken) {
        return await HttpClient.Get(API.getList, null, cancelToken);
    }

    async Save(data, cancelToken) {
        return await HttpClient.Post(API.save, data, cancelToken);
    }

    async Delete(helpId, cancelToken) {
        const url = API.delete.replace("{id}", helpId);
        return await HttpClient.Delete(url, null, cancelToken);
    }
}

const otaVersionService = new FirmwareVersionService();
export default otaVersionService;