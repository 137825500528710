import React from 'react';
import { ToolbarComponent, ItemsDirective, ItemDirective } from '@syncfusion/ej2-react-navigations';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import Axios from 'axios';
import { Redirect } from 'react-router-dom';
import NavigationPrompt from '../../components/NavigationPrompt';

import BaseComponent from '../../components/BaseComponent';

import Loading from '../../components/Loading';
import Breadcrumb from '../../components/Breadcrumb';
import SettingsService from '../../services/SettingsService';

import '../../styles/integration.scss';
import Localization from '../../utilities/Localization';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class Integration extends BaseComponent {
    moduleName = "System indstillinger";
    pageName = "Integration";

    constructor() {
        super();

        this.state = {
            loading: true,
            breadcrumbs: ['System Indstillinger','Integration'],
            data: {
                ILXFromERP: false,
                CostPriceILX: false,
                SalesPriceILX: false,
                ProductGroupILX: false,
                CostPriceERP: false,
                SalesPriceERP: false,
                ProductGroupERP: false
            },
            editMode: false,
            redirect: {
                to: '',
                isRedirect: false
            },
            hasUnsavedChanges: false
        }

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.buttons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        /* End Dialog options  */

        this.init = this.init.bind(this);
        this.onCheckChange = this.onCheckChange.bind(this);
        this.onIntegrationToolbarClicked = this.onIntegrationToolbarClicked.bind(this);
        this.refreshData = this.refreshData.bind(this);
    }

    async componentDidMount() {
        super.componentDidMount();
        source.cancel();
        source = CancelToken.source();

        if (!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }
        else if (!super.hasMenuItem('Integration')) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }

        this.init();

        let { data } = this.state;

        const result = await SettingsService.GetIntegrations(source.token);

        data.ILXFromERP = result.ILXFromERP;
        data.CostPriceILX = result.CostPriceILX;
        data.SalesPriceILX = result.SalesPriceILX;
        data.ProductGroupILX = result.ProductGroupILX;
        data.CostPriceERP = !result.CostPriceILX;
        data.SalesPriceERP = !result.SalesPriceILX;
        data.ProductGroupERP = !result.ProductGroupILX;

        this.setState({ loading: false, data });
    }

    componentWillUnmount() {
        source.cancel();
    }

    init() {

    }

    onCheckChange(args) {
        let { data } = this.state;
        data[args.event.target.id] = args.checked;
        let counterpart = '';

        switch(args.event.target.id) {
            case 'CostPriceILX':
            case 'CostPriceERP':
                counterpart = args.event.target.id === 'CostPriceILX' ? 'CostPriceERP' : 'CostPriceILX';
                data[counterpart] = !args.checked;
                break;
            case 'SalesPriceILX':
            case 'SalesPriceERP':
                counterpart = args.event.target.id === 'SalesPriceILX' ? 'SalesPriceERP' : 'SalesPriceILX';
                data[counterpart] = !args.checked;
                break;
            case 'ProductGroupILX':
            case 'ProductGroupERP':
                counterpart = args.event.target.id === 'ProductGroupILX' ? 'ProductGroupERP' : 'ProductGroupILX';
                data[counterpart] = !args.checked;
                break;
            default:
                break;
                
        }

        this.setState({ data, hasUnsavedChanges: true });
    }

    async onIntegrationToolbarClicked(args) {
        switch(args.item.id) {
            case 'edit':
                this.IntegrationToolbarRef.enableItems(0, false);
                this.IntegrationToolbarRef.enableItems(1, true);
                this.IntegrationToolbarRef.enableItems(2, true);
                this.setState({ editMode: true });
                break;
            case 'update':
                const { data } = this.state;
                const payload = {
                    ILXFromERP: data.ILXFromERP,
                    CostPriceILX: data.CostPriceILX,
                    SalesPriceILX: data.SalesPriceILX,
                    ProductGroupILX: data.ProductGroupILX
                }
                const result = await SettingsService.UpdateIntegrations(payload, source.token);
                if (!result.HasError) {
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Integrations"));
                    this.setState({ editMode: false, hasUnsavedChanges: false });
                }
                else {
                    this.dialogOpen("Avarsel", result.ErrorMessage);
                }
                break;
            case 'cancel':
                this.refreshData().then(() => {
                    this.setState({ editMode: false, hasUnsavedChanges: false });
                });
                break;
            default:
                break;
        }
    }

    async refreshData() {
        let { data } = this.state;

        const result = await SettingsService.GetIntegrations(source.token);

        data.ILXFromERP = result.ILXFromERP;
        data.CostPriceILX = result.CostPriceILX;
        data.SalesPriceILX = result.SalesPriceILX;
        data.ProductGroupILX = result.ProductGroupILX;
        data.CostPriceERP = !result.CostPriceILX;
        data.SalesPriceERP = !result.SalesPriceILX;
        data.ProductGroupERP = !result.ProductGroupILX;

        this.setState({ data });
    }

    dialogOpen(header, content) {
        this.notificationDialog.header = header;
        this.notificationDialog.content = content;
        this.notificationDialog.show();
    }

    render() {
        const { loading, breadcrumbs, data, editMode, redirect, hasUnsavedChanges } = this.state;

        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        return (
            <div className="content-pane-wrapper integration-wrapper">
                <NavigationPrompt when={hasUnsavedChanges} 
                    navigate={path => this.props.history.push(path)}
                    initiateSave={() => this.onIntegrationToolbarClicked({ item: { id: 'update' } })}
                    shouldBlockNavigation={location => {
                        if (hasUnsavedChanges) {
                            return true;
                        }
                        return false;
                    }}
                />
                {/* <div><Breadcrumb breadcrumbs={breadcrumbs} /></div> */}
                <div className="module-header">
                    <h1>{this.pageName}</h1>
                </div>
                <div className={`integration-toolbar-wrapper`}>
                    <ToolbarComponent ref={ref => this.IntegrationToolbarRef = ref} clicked={this.onIntegrationToolbarClicked}>
                        <ItemsDirective>
                            <ItemDirective id="edit" prefixIcon='tb-icons c-icon-edit' text="Redigere" tooltipText='Redigere' disabled={editMode} />
                            <ItemDirective id="update" prefixIcon='tb-icons c-icon-update' text="Gem" tooltipText='Gem' disabled={!editMode} />
                            <ItemDirective id="cancel" prefixIcon='tb-icons c-icon-cancel' text="Annuller" tooltipText='Annuller' disabled={!editMode} />
                        </ItemsDirective>
                    </ToolbarComponent>
                </div>
                <div className="integration-table-values">
                    <table className="table">
                        <thead>
                            <tr className="bg-white">
                                <th colSpan='3'>
                                    <div className="field-bordered text-left">
                                        <label htmlFor="ERPIntegration">Integration fra ERP</label>
                                        <CheckBoxComponent id="ILXFromERP" disabled={!editMode} checked={data.ILXFromERP} name="inactive" cssClass="control-right" change={this.onCheckChange} />
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                <th width="200">Funktion</th>
                                <th width="200">Styres fra Intralogi</th>
                                <th width="200">Styres fra ERP</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Kostpriser</td>
                                <td align="center"><CheckBoxComponent id="CostPriceILX" disabled={!editMode} checked={data.CostPriceILX} change={this.onCheckChange} /></td>
                                <td align="center"><CheckBoxComponent id="CostPriceERP" disabled={!editMode} checked={data.CostPriceERP} change={this.onCheckChange} /></td>
                            </tr>
                            <tr>
                                <td>Salgspriser</td>
                                <td align="center"><CheckBoxComponent id="SalesPriceILX" disabled={!editMode} checked={data.SalesPriceILX} change={this.onCheckChange} /></td>
                                <td align="center"><CheckBoxComponent id="SalesPriceERP" disabled={!editMode} checked={data.SalesPriceERP} change={this.onCheckChange} /></td>
                            </tr>
                            <tr>
                                <td>Produkt grupper</td>
                                <td align="center"><CheckBoxComponent id="ProductGroupILX" disabled={!editMode} checked={data.ProductGroupILX} change={this.onCheckChange} /></td>
                                <td align="center"><CheckBoxComponent id="ProductGroupERP" disabled={!editMode} checked={data.ProductGroupERP} change={this.onCheckChange} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>                
                <div>
                    <DialogComponent isModal={true} buttons={this.buttons} width='auto' ref={dialog => this.notificationDialog = dialog} target='body' 
                        visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true}/>
                </div>
            </div>
        )
    }
}

export default Integration
