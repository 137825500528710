import React, { Component } from 'react';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { withRouter } from 'react-router-dom';

import NavigationPrompt from '../../components/NavigationPrompt';
import { getMachineImage } from './PredefinedModules';
import MachineLocationMapper from '../../components/MachineLocationMapper';
import RoomLocationMapper from '../../components/RoomLocationMapper';
import Image from '../../components/Image';

import PlasticBinMedium from '../../assets/machines/medium_box.png';
import PlasticBinSmall from '../../assets/machines/small_box.png';

import MachineService from '../../services/Machine/MachineService';
import StockService from '../../services/Stock/StockService';

import messageHelper from '../../utilities/MessageHelper';

import Axios from 'axios';
import Localization from '../../utilities/Localization';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class AssignProductToLocation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            data: props.data,
            productData: props.productData,
            location: null,
            selectedPlasticBin: -1,
            productState: 'bin',
            occupyingProduct: null,
            selectedRoom: {
                start: null,
                end: null
            },
            boxProducts: {},
            roomRange: [],
            hasUnsavedChanges: false
        };

        this.animationSettings = { effect: 'None' };
        this.confirmButtons = [
            {
                // Click the footer buttons to hide the Dialog
                click: () => {
                    this.confirmationDialog.hide();
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    content: messageHelper.CancelAssignProductToLocation.buttons[0].buttonModel.content
                }
            },
            {
                // Click the footer buttons to hide the Dialog
                click: () => {
                    this.props.onNavigate();
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    //Enables the primary button
                    isPrimary: true,
                    content: messageHelper.CancelAssignProductToLocation.buttons[1].buttonModel.content
                }
            }
        ];
        this.notificationButtons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];

        this.onButtonClick = this.onButtonClick.bind(this);
        this.onBoxAreaClick = this.onBoxAreaClick.bind(this);
        this.onPlasticBinChange = this.onPlasticBinChange.bind(this);
        this.onNext = this.onNext.bind(this);
        this.onRoomClick = this.onRoomClick.bind(this);
        this.dialogOpen = this.dialogOpen.bind(this);
        this.onConfirmRoom = this.onConfirmRoom.bind(this);
        this.getRoom = this.getRoom.bind(this);
        this.onBack = this.onBack.bind(this);
        this.checkLowPlasticBinSetup = this.checkLowPlasticBinSetup.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.resizeMachinesWrapper);
        setTimeout(() => {
            this.resizeMachinesWrapper();
        }, 300);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeMachinesWrapper);
    }

    resizeMachinesWrapper() {
        let machineInnerWrapper = document.querySelector('.assign-product-location-wrapper .modules-inner-wrapper');
        let modules = document.querySelectorAll('.assign-product-location-wrapper .modules-inner-wrapper .module-wrapper');
        let totalModulesWidth = 0;
        modules.forEach(x => totalModulesWidth += x.clientWidth);
        
        if (totalModulesWidth > machineInnerWrapper.clientWidth) {
            machineInnerWrapper.style.justifyContent = 'unset';
            machineInnerWrapper.style.marginLeft = '40px';
        }
        else {
            machineInnerWrapper.style.justifyContent = 'center';
        }
    }

    checkLowPlasticBinSetup(shelf, currentIndex) {
        let boxSetup = [];
        let combinations = [ "SSSS", "SSM", "MSS", "MM" ];
        let settings = {}

        for (let index = 0; index < shelf.Boxes.length; index++) {
            const box = shelf.Boxes[index];

            switch (box.BoxType) {
                case 2:
                    boxSetup.push({ code: 'S', productCount: box.Rooms.length });
                    break;
                case 3:
                    boxSetup.push({ code: 'M', productCount: box.Rooms.length });
                    break;
            }
        }

        let shelfCombination = boxSetup.map(x => x.code).join('');
        let combination = combinations.find(c => c === shelfCombination);
        let currentBox = boxSetup[currentIndex];
        let nextBox = boxSetup[currentIndex + 1];
        let prevBox = boxSetup[currentIndex - 1];
        let currentBoxHasProduct = currentBox && currentBox.productCount > 0;
        let nextBoxHasProduct = nextBox && nextBox.productCount > 0;
        let prevBoxHasProduct = prevBox && prevBox.productCount > 0;

        switch(combination) {
            case 'SSSS':
                switch (currentIndex) {
                    case 0:
                    case 2:
                        if (currentBoxHasProduct) {
                            settings.nextState = "room";
                            settings.backState = "";
                        }
                        else {
                            if (nextBoxHasProduct) {
                                settings.nextState = "room";
                                settings.backState = "";
                            }
                            else {
                                settings.nextState = "bin";
                                settings.backState = "bin";
                            }
                        }
                        break;
                    case 1:
                    case 3:
                        if (currentBoxHasProduct) {
                            settings.nextState = "room";
                            settings.backState = "";
                        }
                        else {
                            if (prevBoxHasProduct) {
                                settings.nextState = "room";
                                settings.backState = "";
                            }
                            else {
                                settings.nextState = "bin";
                                settings.backState = "bin";
                            }
                        }
                        break;
                }
                break;
            case 'SSM':
                switch (currentIndex) {
                    case 0:
                        if (currentBoxHasProduct) {
                            settings.nextState = "room";
                            settings.backState = "";
                        }
                        else {
                            if (nextBoxHasProduct) {
                                settings.nextState = "room";
                                settings.backState = "";
                            }
                            else {
                                settings.nextState = "bin";
                                settings.backState = "bin";
                            }
                        }
                        break;
                    case 1:
                        if (currentBoxHasProduct) {
                            settings.nextState = "room";
                            settings.backState = "";
                        }
                        else {
                            if (prevBoxHasProduct) {
                                settings.nextState = "room";
                                settings.backState = "";
                            }
                            else {
                                settings.nextState = "bin";
                                settings.backState = "bin";
                            }
                        }
                        break;
                    case 2:
                        if (currentBoxHasProduct) {
                            settings.nextState = "room";
                            settings.backState = "";
                        }
                        else {
                            settings.nextState = "bin";
                            settings.backState = "bin";
                        }
                        break;
                }
                break;
            case 'MSS':
                switch (currentIndex) {
                    case 0:
                        if (currentBoxHasProduct) {
                            settings.nextState = "room";
                            settings.backState = "";
                        }
                        else {
                            settings.nextState = "bin";
                            settings.backState = "bin";
                        }
                        break;
                    case 1:
                        if (currentBoxHasProduct) {
                            settings.nextState = "room";
                            settings.backState = "";
                        }
                        else {
                            if (nextBoxHasProduct) {
                                settings.nextState = "room";
                                settings.backState = "";
                            }
                            else {
                                settings.nextState = "bin";
                                settings.backState = "bin";
                            }
                        }
                        break;
                    case 2:
                        if (currentBoxHasProduct) {
                            settings.nextState = "room";
                            settings.backState = "";
                        }
                        else {
                            if (prevBoxHasProduct) {
                                settings.nextState = "room";
                                settings.backState = "";
                            }
                            else {
                                settings.nextState = "bin";
                                settings.backState = "bin";
                            }
                        }
                        break;
                }
                break;
            case 'MM':
                switch (currentIndex) {
                    case 0:
                    case 1:
                        if (currentBoxHasProduct) {
                            settings.nextState = "room";
                            settings.backState = "";
                        }
                        else {
                            settings.nextState = "bin";
                            settings.backState = "bin";
                        }
                        break;
                }
                break;
        }

        return {
            boxSetup,
            ...settings
        };
    }

    async onBoxAreaClick(boxArea, index, event) {
        let { productState, location, data, occupyingProduct, boxProducts, selectedPlasticBin, selectedRoom } = this.state;

        const result = await MachineService.GetMachineBox(boxArea.box.Id, source.token);
        if (!result.HasError) {
            boxProducts = result;
        }
        else {
            this.dialogOpen("Advarsel", result.ErrorMessage);
            return;
        }

        let module = data.Modules.find(x => x.Id === boxArea.moduleId);
        let shelf = module.Shelves.find(s => s.Boxes.some(b => b.Id === boxProducts.Id));
        let setup = this.checkLowPlasticBinSetup(shelf, index);
        
        location = boxArea;
        
        switch(boxArea.box.BoxType) {
            case 1:
                productState = 'confirm';
                if (boxArea.box.Rooms.length > 0) {
                    productState = 'confirm-occupied';
                    occupyingProduct = boxProducts.Rooms[0];
                }
                location.boxIndex = index;
                selectedPlasticBin = -1;
                break;
            case 2:
                productState = setup.nextState;
                selectedRoom = { start: null, end: null };
                selectedPlasticBin = 1;
                location.boxIndex = boxArea.box.SlotEnd;
                break;
            case 3:
                productState = setup.nextState;
                if (setup.nextState === 'room') {
                    selectedPlasticBin = 2;
                }
                else {
                    selectedPlasticBin = -1;
                }
                
                location.boxIndex = index;
                selectedRoom = { start: null, end: null };
                break;
            case 4:
                productState = 'room';
                selectedPlasticBin = 2;
                selectedRoom = {
                    start: null,
                    end: null
                }
                location.boxIndex = index;
                break;
            default:
                productState = 'bin';
                selectedRoom = { start: null, end: null };
                break;
        }
        
        this.setState({ location: boxArea, productState, occupyingProduct, boxProducts, selectedPlasticBin, selectedRoom, roomRange: [] });
    }

    getRoom() {
        let { selectedRoom } = this.state;
        let roomRange = [];
        
        if (selectedRoom.start && selectedRoom.end) {
            roomRange.push(parseInt(selectedRoom.start.No));
            roomRange.push(parseInt(selectedRoom.end.No));
        }
        else if (selectedRoom.start && !selectedRoom.end) {
            roomRange.push(parseInt(selectedRoom.start.No));
            roomRange.push(parseInt(selectedRoom.start.No));
        }
        if (roomRange.length > 0) {
            roomRange.sort((a,b) => a - b);
            return { roomStart: roomRange[0], roomEnd: roomRange[1] };
        }

        return { roomStart: 0, roomEnd: 0 };
    }

    async onButtonClick(args) {
        const { location, selectedRoom, data, selectedPlasticBin, productData, occupyingProduct } = this.state;
        const { onNavigate } = this.props;
        
        switch(args.target.id) {
            case 'confirmed':
            case 'finish':
                let boxId = location.box.Id;
                
                if (selectedPlasticBin === 2) {
                    const payload = {
                        Id: boxId,
                        SlotStart: location.boxIndex,
                        ShelfSlotNo: location.shelf.SlotNo,
                        BoxType: 3,
                        ModuleId: location.moduleId
                    };

                    const result = await MachineService.PostMachineBox(payload, source.token);
        
                    if (result.HasError) {
                        this.dialogOpen("Advarsel", result.ErrorMessage);
                        return;
                    }
                    else {
                        boxId = result.Id;
                    }
                }
                else if (location.box.BoxType === 3 && selectedPlasticBin === 1) {
                    const payload = {
                        Id: boxId,
                        SlotStart: location.boxIndex,
                        ShelfSlotNo: location.shelf.SlotNo,
                        BoxType: 2,
                        ModuleId: location.moduleId
                    };

                    const result = await MachineService.PostMachineBox(payload, source.token);
        
                    if (result.HasError) {
                        this.dialogOpen("Advarsel", result.ErrorMessage);
                        return;
                    }
                    else {
                        boxId = result.Id;
                    }
                }

                let { roomStart, roomEnd } = this.getRoom();
                const stockPayload = {
                    Id: productData.Id,
                    ProductId: productData.Product.Id,
                    MinQty: productData.MinQty,
                    MaxQty: productData.MaxQty,
                    PurchaseQty: productData.PurchaseQty,
                    StandardLocation: productData.StandardLocation,
                    MachineId: location.machineId,
                    Location: {
                        BoxId: boxId,
                        RoomNoStart: roomStart,
                        RoomNoEnd: roomEnd
                    }
                };

                if (occupyingProduct) {
                    stockPayload.Location.Id = occupyingProduct.Id;
                }

                const stockResult = await StockService.AddUpdateStock('update', stockPayload, source.token);

                if (!stockResult.HasError) {
                    selectedRoom.start = null;
                    selectedRoom.end = null;
                    
                    this.setState({ location: null, selectedPlasticBin: -1, selectedRoom, productState: 'bin' }, ()  => {
                        this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Produkt lokation"), () => onNavigate());
                    });
                }
                else {
                    this.dialogOpen("Advarsel", stockResult.ErrorMessage);
                    return;
                }
                break;
            case 'new':
                selectedRoom.start = null;
                selectedRoom.end = null;
                this.setState({ location: null, selectedPlasticBin: -1, selectedRoom, productState: 'bin' });
                break;
            case 'back':
                //confirmation modal
                this.confirmationDialog.content = messageHelper.CancelAssignProductToLocation.message;
                this.confirmationDialog.show();
                break;
        }
    }
    
    onPlasticBinChange(args) {
        this.setState({ selectedPlasticBin: args.value });
    }

    onRoomClick(roomArea, index, event) {
        let { selectedRoom, location, roomRange } = this.state;

        if (location.box.Rooms.some(x => x.RoomNoStart === roomArea.No || x.RoomNoEnd === roomArea.No)) {
            return;
        }

        roomRange.sort();
        roomRange.push(roomArea.No);

        let tempRoomStart = roomRange[0];
        let tempRoomEnd = roomRange[roomRange.length - 1];
        roomRange = [];
        roomRange.push(tempRoomStart);
        roomRange.push(tempRoomEnd);

        if (roomRange.length > 0) {
            if (roomRange.length === 1) {
                selectedRoom.start = { No: roomRange[0] };
                selectedRoom.end = null;
            }
            else {
                selectedRoom.start = { No: roomRange[0] };
                selectedRoom.end = { No: roomRange[roomRange.length - 1] };
            }
        }
        else {
            selectedRoom.start = null;
            selectedRoom.end = null;
        }
        // if (selectedRoom.start === null) {
        //     selectedRoom.start = roomArea;
        // }
        // else if (selectedRoom.start && selectedRoom.end) {
        //     selectedRoom.start = roomArea;
        //     selectedRoom.end = null;
        // }
        // else {
        //     selectedRoom.end = roomArea;
        // }

        this.setState({ selectedRoom, roomRange });
    }

    onConfirmRoom() {
        let { boxProducts, occupyingProduct, productState, hasUnsavedChanges } = this.state;
        let { roomStart, roomEnd } = this.getRoom();

        if (roomStart === 0 && roomEnd === 0) {
            this.dialogOpen("Advarsel", Localization.Machine.MustSelectRoom);
            return;
        }
        if (boxProducts.Rooms && boxProducts.Rooms.some(x => x.RoomNoStart === roomStart && x.RoomNoEnd === roomEnd)) {
            occupyingProduct = boxProducts.Rooms.find(x => x.RoomNoStart === roomStart && x.RoomNoEnd === roomEnd);
            productState = 'confirm-occupied';
        }
        else {
            productState = 'confirm';
        }

        this.setState({ occupyingProduct, productState, hasUnsavedChanges: true });
    }

    onNext(state) {
        let { productState, selectedRoom, location, selectedPlasticBin, boxProducts, data } = this.state;
        
        let module = data.Modules.find(x => x.Id === location.moduleId);
        let shelf = module.Shelves.find(s => s.Boxes.some(b => b.Id === boxProducts.Id));
        let currentBoxIndex = shelf.Boxes.findIndex(x => x.Id === boxProducts.Id);

        if (productState === 'room' && state === 'bin') {
            selectedRoom.start = null;
            selectedRoom.end = null;

            switch(location.box.BoxType) {
                case 2:
                case 3:
                    let setup = this.checkLowPlasticBinSetup(shelf, location.boxIndex);
                    if (!setup.backState) {
                        location = null;
                    }
                    break;
                case 4:
                    location = null;
                    break;
            }
        }
        else if (productState === 'confirm-occupied' && state === 'bin') {
            location = null;
            selectedRoom.start = null;
            selectedRoom.end = null;
            selectedPlasticBin = -1;
        }

        this.setState({ productState: state, selectedRoom, location, selectedPlasticBin });
    }

    dialogOpen(header, content, callback = null) {
        this.notificationDialog.header = header;
        this.notificationDialog.content = content;

        if (callback) {
            let buttons = this.notificationButtons;
            buttons[0].click = callback;
            this.notificationDialog.buttons = buttons;
        }

        this.notificationDialog.show();
    }

    renderLocationPane() {
        let { data, productData, location, selectedPlasticBin, productState, selectedRoom, occupyingProduct } = this.state;

        // if plastic bin
        if (productState === 'bin') {
            return (
                <React.Fragment>
                    <div className="sub-text">Tryk på den ønskede kasse nedenfor</div>
                    <div className="location-inner-wrapper">
                        <div className="plastic-bin-selection-wrapper">
                            <div className="inline plastic-bin-selection">
                                <div><img src={PlasticBinSmall} /></div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <div>
                                        <RadioButtonComponent checked={selectedPlasticBin === 1} value={1} name="PlasticBinType" change={this.onPlasticBinChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="inline plastic-bin-selection">
                                <div><img src={PlasticBinMedium} /></div>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <div>
                                        <RadioButtonComponent checked={selectedPlasticBin === 2} value={2} name="PlasticBinType" change={this.onPlasticBinChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-right">
                            <button className="e-btn e-primary" onClick={() => this.onNext('room')}>Næste</button>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
        else if (productState === 'room') {
            let disabledClass = '';
            if (!selectedRoom.start && !selectedRoom.end) {
                disabledClass = 'e-disabled';
            }
            
            return (
                <React.Fragment>
                    <div className="sub-text">Vælg et eller flere sammenhængende rum i kassen, For at danne det rum du ønsker</div>
                    <div className="room-location-wrapper">
                        <RoomLocationMapper name="plastic-bin-rooms" selectedPlasticBin={selectedPlasticBin} occupiedRooms={location.box.Rooms} activeRoom={selectedRoom} onClick={this.onRoomClick} active={true} />
                        <div className="bin-room-action-wrapper text-center">
                            <button className="e-btn e-control" onClick={() => this.onNext('bin')}>Tilbage</button>
                            <button className={`e-btn e-control e-primary ${disabledClass}`} disabled={disabledClass ? 'disabled' : ''} onClick={this.onConfirmRoom}>Ok</button>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
        else if (productState === 'confirm') {
            let imageKey = '';
            if (productData.Product.ProductImages && productData.Product.ProductImages.length > 0) {
                imageKey = productData.Product.ProductImages[0].ImageKey;
            }
            let room = '';
            let { roomStart, roomEnd } = this.getRoom();

            if (location.box.BoxType !== 1) {
                room = `-Room ${roomEnd}`;
            }

            return (
                <React.Fragment>
                    <div className="sub-text text-danger">Du har nu tilføjet produktet til lokation</div>
                    <div className="location-inner-wrapper cabinet">
                        <div className="product-location-wrapper">
                            <div className="product-image-wrapper">
                                <Image src={imageKey} />
                            </div>
                            <div className="product-location-details-wrapper">
                                <div className="label inline">Produkt Nr.:</div>
                                <div className="inline">{productData.Product.ProductNo}</div>
                            </div>
                        <div className="product-location-details-wrapper">
                                <div className="label inline">Beskrivelse:</div>
                                <div className="inline">{productData.Product.Description1}</div>
                            </div>
                            <div className="product-location-details-wrapper">
                                <div className="label inline">Lokation:</div>
                                <div className="inline">{`${location.box.No}${room}`}</div>
                            </div>
                        </div>
                        <div className="product-location-action-wrapper">
                            <div className="action-button-wrapper">
                                <div className="inline action-desc">Tryk på Udført for vende tilbage til listen og gemme lokationen</div>
                                <div className="inline action-button"><button id="finish" className="e-btn e-primary" onClick={this.onButtonClick}>Udført</button></div>
                            </div>
                            {/* <div className="action-button-wrapper">
                                <div className="inline action-desc">Tryk på Ny for at tilføje endnu en Lokation</div>
                                <div className="inline action-button"><button id="new" className="e-btn" onClick={this.onButtonClick}>Ny</button></div>
                            </div> */}
                            <div className="action-button-wrapper">
                                <div className="inline action-desc">Tryk på annuller for at vende tilbage Til listen uden at gemme tilføjet lokationer</div>
                                <div className="inline action-button"><button id="back" className="e-btn" onClick={this.onButtonClick}>Annuller</button></div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
        else if (productState === 'confirm-occupied') {
            let occupyingProductImageKey = '', imageKey = '', room = '';

            if (productData.Product.ProductImages && productData.Product.ProductImages.length > 0) {
                imageKey = productData.Product.ProductImages[0].ImageKey;
            }

            if (occupyingProduct.Stock.Product.ProductImages && occupyingProduct.Stock.Product.ProductImages.length > 0) {
                occupyingProductImageKey = occupyingProduct.Stock.Product.ProductImages[0].ImageKey;
            }

            let { roomStart, roomEnd } = this.getRoom();

            if (location.box.BoxType !== 1) {
                room = `-Room ${roomEnd}`;
            }

            return (
                <React.Fragment>
                    <div className="error-label sub-text">Advarsel! Optaget lokation</div>
                    <div className="occupied-location-wrapper">
                        <div className="bordered occupied-location-inner-wrapper">
                            <label className="label label-title">Produkt du vil fjerne fra lokation</label>
                            <div className="product-image-wrapper">
                                <Image src={occupyingProductImageKey} />
                            </div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="label">Produkt Nr.:</td>
                                        <td>{occupyingProduct.Stock.Product.ProductNo}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Beskrivelse:</td>
                                        <td>{occupyingProduct.Stock.Product.Description1}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Lokation:</td>
                                        <td>{`${occupyingProduct.Stock.Box.No}${room}`}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="bordered occupied-location-inner-wrapper">
                            <label className="label label-title">Produkt du vil tildele lokationen</label>
                            <div className="product-image-wrapper">
                                <Image src={imageKey} />
                            </div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="label">Produkt Nr.:</td>
                                        <td>{productData.Product.ProductNo}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Beskrivelse:</td>
                                        <td>{productData.Product.Description1}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Lokation:</td>
                                        <td>{`${location.box.No}${room}`}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="action-buttons text-center">
                                <button className="e-btn e-control" onClick={() => this.onNext('bin')}>Tilbage</button>
                                <button id="confirmed" className="e-btn e-control e-primary" onClick={this.onButtonClick}>Udskift produkt</button>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }

    onBack() {
        this.props.onNavigate();
    }

    render() {
        const { data, productData, location, productState, selectedPlasticBin, hasUnsavedChanges } = this.state;
        let selectedBoxId = null;
        let imageKey = '';
        if (location && location.box) {
            selectedBoxId = location.box.Id;
        }

        if (productData.Product.ProductImages && productData.Product.ProductImages.length > 0) {
            if (productData.Product.ProductImages.some(x => x.IsPrimary)) {
                imageKey = productData.Product.ProductImages.find(x => x.IsPrimary).ImageKey;
            }
            else {
                imageKey = productData.Product.ProductImages[0].ImageKey;
            }
        }

        let productNo = '', productDesc = '';

        if (productData.Product.ProductNo) {
            productNo = productData.Product.ProductNo.length > 14 ? productData.Product.ProductNo.substring(0, 14) + '...' : productData.Product.ProductNo;
        }
        if (productData.Product.Description1) {
            productDesc = productData.Product.Description1.length > 14 ? productData.Product.Description1.substring(0, 14) + '...' : productData.Product.Description1;
        }

        return (
            <div className="assign-product-location-wrapper">
                <NavigationPrompt when={hasUnsavedChanges} 
                    navigate={path => this.props.history.push(path)}
                    initiateSave={() => this.onButtonClick({target: { id: 'finish' }})}
                    shouldBlockNavigation={location => {
                        if (hasUnsavedChanges) {
                            return true;
                        }
                        return false;
                    }}
                />
                <div className="header-wrapper">
                    <div className="action-back">
                        <div className="clickable c-back-arrow" onClick={this.onBack}>Tilbage</div>
                    </div>
                    <div className="page-instructions">
                        <div className="label">Vælg venligst en location</div>
                        <div className="sub-text">(Grøn = Ledig - Gul = Delvis ledig - Rød = Optaget)</div>
                    </div>
                    <div className="product-info">
                        <div>
                            <Image src={imageKey} />
                        </div>
                        <div>
                            <div className="text-left"><label className="label">Produkt Nr.: </label><span>{productNo}</span></div>
                            <div className="text-left"><label className="label">Beskrivelse: </label><span>{productDesc}</span></div>
                        </div>
                    </div>
                </div>
                <div className="content-wrapper">
                    <div className="location-wrapper">
                        { productState === 'confirm-occupied' && <div className="label">Udskift produkt på lokation</div> }
                        <div className="label">Lokation</div>
                        { !location && <div className="sub-text">Ingen lokation valgt</div> }
                        {
                            location && this.renderLocationPane()
                        }
                    </div>
                    <div className="modules-wrapper">
                        <div className="modules-inner-wrapper">
                            {
                                data.Modules.map((module, index) => {
                                    let machineImageSrc = getMachineImage(module.Name);
                                    return (
                                        <div className="module-wrapper" key={`machine_${module.Name}`}>
                                            <MachineLocationMapper Id={data.Id} machineId={module.Id} src={machineImageSrc} active={true} isRemoving={false} 
                                                name={`${module.Name}_${index}`} shelves={module.Shelves} width={151} 
                                                onClick={this.onBoxAreaClick} selectedPlasticBin={selectedPlasticBin}
                                                activeBoxId={selectedBoxId} confirmingBox={productState === 'confirm'} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <DialogComponent ref={dialog => this.notificationDialog = dialog} id="product-to-location-dialog" isModal={true} buttons={this.notificationButtons} width='auto' 
                    target='body' visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true}/>
                <DialogComponent id="confirm-dialog" isModal={true} buttons={this.confirmButtons} width='auto' ref={dialog => this.confirmationDialog = dialog}
                    header="Advarsel" target='body' visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true}/>
            </div>
        )
    }
}

export default withRouter(AssignProductToLocation);
