import HttpClient from '../../utilities/HttpClient';

const API = {
    getStocks: `/stock`,
    getStock: `/stock/{id}`,
    postPutStock: '/stock',
    deleteStock: '/stock/{id}',
    getProductStock: '/stock/product/{product_id}',
    getMachineStock: '/stock/machine/{machine_id}',
    undoStock: '/stock/undo/{product_id}',
    redoStock: '/stock/redo/{product_id}',
    getByCustomer: '/stock/{customerId}/byCustomer',
    postBulkSave: '/stock/bulkSave',
    postImportBulkSave: '/stock/bulkSave/import'
}

class StockService {
    async GetStocks(cancelToken) {
        return HttpClient.Get(API.getStocks, null, cancelToken);
    }
    
    async GetStock(id, cancelToken) {
        const url = API.getStock.replace('{id}', id);
        return HttpClient.Get(url, null, cancelToken);
    }

    async DeleteStock(id, cancelToken) {
        const url = API.deleteStock.replace('{id}', id);
        return HttpClient.Delete(url, null, cancelToken);
    }

    async AddUpdateStock(state, data, cancelToken) {
        switch(state) {
            case 'add':
                return await HttpClient.Post(API.postPutStock, data, cancelToken);
            case 'update':
                return await HttpClient.Put(API.postPutStock, data, cancelToken);
            default:
                return null;
        }
    }

    async GetMachineStock(id, cancelToken) {
        const url = API.getMachineStock.replace('{machine_id}', id);
        return HttpClient.Get(url, null, cancelToken);
    }
    
    async GetProductStock(id, cancelToken) {
        const url = API.getProductStock.replace('{product_id}', id);
        return HttpClient.Get(url, null, cancelToken);
    }

    async UndoStock(id, cancelToken) {
        const url = API.undoStock.replace('{id}', id);
        return await HttpClient.Put(url, null, cancelToken);
    }

    async RedoStock(id, cancelToken) {
        const url = API.redoStock.replace('{id}', id);
        return await HttpClient.Put(url, null, cancelToken);
    }

    async GetByCustomer(customerId, cancelToken) {
        const url = API.getByCustomer.replace('{customerId}', customerId);
        return HttpClient.Get(url, null, cancelToken);
    }

    async BulkSave(data, cancelToken) {
        return await HttpClient.Post(API.postBulkSave, data, cancelToken);
    }

    async ImportBulkSave(data, cancelToken) {
        return await HttpClient.Post(API.postImportBulkSave, data, cancelToken);
    }
}

const stockService = new StockService();
export default stockService;