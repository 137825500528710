import HttpClient from '../../utilities/HttpClient';

const API = {
    getUser: '/user/{user_id}',
    getUserByAccount: '/user/{user_id}/byAccount',
    putUserPrimaryContact: '/customer/{user_id}/primaryContact',
    deleteUser: '/user/{user_id}',
    postUser: '/user',
    updateUser: '/user',
    undoUser: '/user/undo/{id}',
    redoUser: '/user/redo/{id}',
    bulkSave: '/user/save'
}

class UserService {
    async GetUser(id, cancelToken) {
        const url = API.getUser.replace('{user_id}', id);
        let result =  await HttpClient.Get(url, null, cancelToken);
        if (!result.HasError) {
            result = {
                ...result,
                FirstName: result.Account ? result.Account.FirstName: '',
                LastName: result.Account ? result.Account.LastName: '',
                MiddleName: result.Account ? result.Account.MiddleName: '',
                Email: result.Account ? result.Account.Email: '',
                PhoneNumber: result.Account ? result.Account.PhoneNumber: '',
                DepartmentId: result.Department ? result.Department.Id: 0,
                UserGroupId: result.UserGroup ? result.UserGroup.Id: 0,
                Username: result.Account ? result.Account.Username : '',
                FullName: result.Account ? result.Account.FullName : '',
                PhoneNumber: result.Account ? result.Account.PhoneNumber : '',
                LoginRule: result.Account ? result.Account.LoginRule : 0,
                Email: result.Account ? result.Account.Email : '',
                Initials: result.Account ? result.Account.Initials : '',
                OmUserName: result.Account ? result.Account.OmUserName : '',
                IntUserName: result.Account ? result.Account.IntUserName : '',
                FmUserName: result.Account ? result.Account.FmUserName : '',
                AccessControlPanel: result.Account ? result.Account.AccessControlPanel : false,
                UserGroupName: result.UserGroup ? result.UserGroup.Name : '',
                DepartmentName: result.Department ?  result.Department.Name : ''
            }
        }
        return result
    }

    async GetUserAccount(id, cancelToken) {
        const url = API.getUserByAccount.replace('{user_id}', id);
        let result =  await HttpClient.Get(url, null, cancelToken);
        if (!result.HasError) {
            result = {
                ...result,
                FirstName: result.Account ? result.Account.FirstName: '',
                LastName: result.Account ? result.Account.LastName: '',
                MiddleName: result.Account ? result.Account.MiddleName: '',
                Email: result.Account ? result.Account.Email: '',
                PhoneNumber: result.Account ? result.Account.PhoneNumber: '',
                DepartmentId: result.Department ? result.Department.Id: 0,
                UserGroupId: result.UserGroup ? result.UserGroup.Id: 0,
                Username: result.Account ? result.Account.Username : '',
                FullName: result.Account ? result.Account.FullName : '',
                PhoneNumber: result.Account ? result.Account.PhoneNumber : '',
                LoginRule: result.Account ? result.Account.LoginRule : 0,
                Email: result.Account ? result.Account.Email : '',
                Initials: result.Account ? result.Account.FirstName.slice(0, 1) + result.Account.MiddleName.slice(0, 1) + result.Account.LastName.slice(0, 1) : '',
                OmUserName: result.Account ? result.Account.OmUserName : '',
                IntUserName: result.Account ? result.Account.IntUserName : '',
                FmUserName: result.Account ? result.Account.FmUserName : '',
                AccessControlPanel: result.Account ? result.Account.AccessControlPanel : false,
                UserGroupName: result.UserGroup ? result.UserGroup.Name : '',
                DepartmentName: result.Department ?  result.Department.Name : ''
            }
        }
        return result
    }
    
    async UpdateUserPrimaryContact(id, params, cancelToken) {
        const url = API.putUserPrimaryContact.replace('{user_id}', id);
        return await HttpClient.Put(url, params, cancelToken);
    }

    async DeleteUser(id, param, cancelToken) {
        const url = API.deleteUser.replace('{user_id}', id);
        return await HttpClient.Delete(url, param, cancelToken);
    }
    
    async AddUpdateUser(state, data, cancelToken) {
        const payload = {
            Account: {
                Active: true,
                FirstName: data.FirstName,
                MiddleName: data.MiddleName,
                LastName: data.LastName,
                Email: data.Email,
                PhoneNumber: data.PhoneNumber,
                AccessControlPanel: data.AccessControlPanel,
                LoginRule: data.LoginRule,
                Initials: data.Initials,
                AppAccess: data.Account.AppAccess,
                OmUserName: data.OmUserName,
                IntUserName: data.IntUserName,
                FmUserName: data.FmUserName,
            },
            UserGroupId: data.UserGroupId,
            Position: data.Position,
            DepartmentId: data.DepartmentId,
            MachineLoginRule: data.LoginRule,
            PrimaryContact: data.PrimaryContact,
            CustomMenu: data.CustomMenu,
            CustomerId: parseInt(data.CustomerId),
            ResponsiblityGroupIds: data.ResponsiblityGroupIds,
            OrdersApproval: data.OrdersApproval,
            OilmatUser: {
                ApprovePurchaseOrders: data.OilmatUser.ApprovePurchaseOrders
            }
        }

        switch(state) {
            case 'add':
                return await HttpClient.Post(API.postUser, payload, cancelToken);
            case 'update':
                payload.Id = parseInt(data.Id);
                payload.Account.Id = data.AccountId;
                return await HttpClient.Put(API.updateUser, payload, cancelToken);
            default:
                return null;
        }
    }

    async UndoUser(id, cancelToken) {
        const url = API.undoUser.replace('{id}', id);
        return await HttpClient.Put(url, null, cancelToken);
    }

    async RedoUser(id, cancelToken) {
        const url = API.redoUser.replace('{id}', id);
        return await HttpClient.Put(url, null, cancelToken);
    }

    async BulkSave(data, cancelToken) {
        return await HttpClient.Post(API.bulkSave, data, cancelToken);
    }
}

const userService = new UserService();
export default userService;