import HttpClient from '../../utilities/HttpClient';

const API = {
    getPicturesByLogin: '/video/{customerId}/login',
    getVideosByPull: '/video/{customerId}/product',
    getRetrievedVideos: '/video/{customerId}/downloads',
    postVideo: '/video/save',
    postDownload: '/video/download',
    postLogVideo: '/video/log',
};

class VideoService {
    async GetPicturesByLogin(customerId, cancelToken) {
        const url = API.getPicturesByLogin.replace('{customerId}', customerId);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async GetVideosByPull(customerId, cancelToken) {
        const url = API.getVideosByPull.replace('{customerId}', customerId);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async RetrieveVideos(customerId, cancelToken) {
        const url = API.getRetrievedVideos.replace('{customerId}', customerId);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async Download(type, customerId, data, cancelToken) {
        const url = API.postDownload + `?type=${type}&customerId=${customerId}`;
        return await HttpClient.Post(url, data, cancelToken);
    }

    async Log(data, cancelToken) {
        return await HttpClient.Post(API.postLogVideo, data, cancelToken);
    }

    async Save(data, cancelToken) {
        return await HttpClient.Post(API.postVideo, data, cancelToken);
    }
}

const videoService = new VideoService();
export default videoService;