import HttpClient from '../../utilities/HttpClient';
import AppSettings from '../../settings/AppSettings';

const API = {
    getImage: '/file?Key={imageKey}',
    getFileList: '/file/list',
    getProductImages: '/file/prodImages/?isGroup={isGroup}',
    getFile: '/file?Key={fileKey}&hasParentFolder={hasParentFolder}'
}

export const FileArchiveAPI = {
    Url: `${AppSettings.API_URL}/FileManager/fileOperations`,
    DownloadUrl: `${AppSettings.API_URL}/FileManager/download`,
    UploadUrl: `${AppSettings.API_URL}/FileManager/upload`,
    GetImageUrl: `${AppSettings.API_URL}/FileManager/getImage`,
    GetFileUrl: `${AppSettings.API_URL}/file?`
}

// export const FileArchiveAPI = {
//     Url: `http://localhost:62869/api/FileManager/FileOperations`,
//     DownloadUrl: `http://localhost:62869/api/FileManager/Download`,
//     UploadUrl: `http://localhost:62869/api/FileManager/Upload`,
//     GetImageUrl: `http://localhost:62869/api/FileManager/GetImage`
// }

class FileService {
    async GetImage(imageKey, cancelToken) {
        const url = API.getImage.replace("{imageKey}", imageKey);
        return await HttpClient.GetImage(url, cancelToken);
    }

    async GetFileList(cancelToken) {
        return await HttpClient.Get(API.getFileList, null, cancelToken);
    }

    async GetImageList(cancelToken) {
        let result =  await HttpClient.Get(API.getFileList, null, cancelToken);

        if (!result.HasError) {
            result.SubFiles = this.filterImageOnly(result.SubFiles);
        }
        else {
            result.SubFiles = [];
        }

        return result;
    }

    async GetProductImages(isGroup, cancelToken) {
        const url = API.getProductImages.replace("{isGroup}", isGroup);
        return await HttpClient.Get(url, cancelToken);
    }

    filterImageOnly(files) {
        let tempF = files.filter(f => f.ContentType.startsWith("image/") || f.SubFiles !== null);
        if (tempF.some(t => t.SubFiles !== null)) {
            let folders = tempF.filter(t => t.SizeReadable === "-" && (t.SubFiles.length > 0 || t.SubFiles === null));
            folders.forEach(f => {
                if (f.SubFiles.length > 0) {
                    f.SubFiles = this.filterImageOnly(f.SubFiles);
                }
            });
        }
        return tempF;
    }

    async GetFile(key, hasParentFolder, cancelToken) {
        const url = API.getFile.replace("{fileKey}", key).replace("{hasParentFolder}", hasParentFolder);
        return await HttpClient.GetFile(url, cancelToken);
    }
}

const fileService = new FileService();
export default fileService;