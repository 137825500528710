import React, { Component } from 'react';
import { TreeViewComponent, ToolbarComponent, ItemsDirective, ItemDirective } from '@syncfusion/ej2-react-navigations';
import { ListViewComponent } from '@syncfusion/ej2-react-lists';
import { SwitchComponent } from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import Axios from 'axios';
import { withRouter } from 'react-router-dom';

import NavigationPrompt from '../../components/NavigationPrompt';
import Card from '../../components/Card';
import { buildVirtualMenu, getCheckedMenuNodes, buildMenuFromCheckedNodes, buildCheckedNodes } from '../../utilities/Helper';

import SessionService from '../../services/SessionService';
import UserGroupService from '../../services/User/UserGroupService';
import CustomerService from '../../services/Customer/CustomerService';
import SettingsService from '../../services/SettingsService';
import ProductGroupService from '../../services/Product/ProductGroupService';
// import MenuService from '../../services/MenuService';
// import AuthenticationService from '../../services/AuthenticationService';
import Localization from '../../utilities/Localization';
import MessageHelper from '../../utilities/MessageHelper';
import '../../styles/user.scss';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class UserGroups extends Component {
    disableCustomMenu = false;

    constructor() {
        super();

        this.state = {
            loading: true,
            userGroupsData: [],
            data: {
                loginRule: 0
            },
            selectedMenus: [],
            selectedProducts: [],
            selectedGroup: {},
            showGroupMenu: true,
            hasUnsavedChanges: false,
            isEditMode: false
        }

        this.loginRuleData = [];
        this.folderPermissionData = [];
        this.productData = [];

        this.menuFields = {
            dataSource: [], id: 'Id', text: 'Name', child: 'VirtualMenuItems' 
        };

        this.productTreeFields = {
            dataSource: [], 
            id: 'Id', text: 'Name', 
            child: 'SubGroups' 
        };

        this.onUserGroupSelected = this.onUserGroupSelected.bind(this);
        this.userGroupToolbarClicked = this.userGroupToolbarClicked.bind(this);
        this.undoRedoOptions = this.undoRedoOptions.bind(this);
        this.reloadUserGroup = this.reloadUserGroup.bind(this);
        this.addGroup = this.addGroup.bind(this);
        this.itemTemplate = this.itemTemplate.bind(this);
        this.onEditClick = this.onEditClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.saveGroup = this.saveGroup.bind(this);
        
        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.buttons = [
            {
                // Click the footer buttons to hide the Dialog
                click: () => {
                    this.NewGroupNameRef.value = '';
                    this.addGroupDialog.hide();
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    //Enables the primary button
                    content: 'Annuller'
                }
            },
            {
                // Click the footer buttons to hide the Dialog
                click: async () => {
                    let { selectedGroup } = this.state;
                    const { onNotify} = this.props;

                    if (this.isGroupEditing) {
                        selectedGroup.Name = this.NewGroupNameRef.value;
                        const sessionInfo = await SessionService.GetSessionInfo();
                        const payload = {
                            Id: selectedGroup.Id,
                            CustomerId: parseInt(sessionInfo.Id),
                            Name: selectedGroup.Name,
                            Menu: selectedGroup.Menu,
                            Products: selectedGroup.Products,
                            LoginRule: selectedGroup.LoginRule,
                            OrdersApproval: selectedGroup.OrdersApproval,
                            Level: selectedGroup.Level
                        };
                
                        const result = await UserGroupService.UpdateUserGroup(payload, source.token);

                        if (!result.HasError) {
                            let listDataSource = this.groupListRef.dataSource;
                            let item = listDataSource.find(x => x.Id === selectedGroup.Id);
                            item.text = selectedGroup.Name;
                            this.groupListRef.dataSource = listDataSource;
                            this.groupListRef.dataBind();
                            this.groupListRef.refresh();

                            let header = document.querySelector('h2.user-group-header');
                            if (header) {
                                header.innerHTML = payload.Name;
                            };

                            this.NewGroupNameRef.value = '';
                            this.copyUserGroup.value = null;
                            this.addGroupDialog.hide();
                            await this.reloadUserGroup();
                            onNotify(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Brugergrupper"));
                        }
                        else {
                            onNotify("Advarsel", result.ErrorMessage);
                        }
                    }
                    else {
                        this.addGroup(this.NewGroupNameRef.value);
                    }
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    //Enables the primary button
                    isPrimary: true,
                    content: 'Gem'
                }
            }
        ];
        /* End Dialog options  */
    }

    async componentDidMount() {
        source = CancelToken.source();
        
        const sessionInfo = await SessionService.GetSessionInfo();
        const userGroups = await UserGroupService.GetUserGroups(sessionInfo.Id, source.token);
        userGroups.sort((a,b) => (a.Level > b.Level) ? 1 : -1);
        this.userGroupItems = userGroups.map(x => {
            return {
                text: x.Name,
                Id: x.Id,
                editable: x.CustomerId === sessionInfo.Id
            }
        });

        const customer = await CustomerService.GetCustomer(sessionInfo.Id, source.token);
        let menu = buildVirtualMenu(customer.CustomerGroup.Menu);
        this.Customer = customer;
        this.menuFields.dataSource = menu;

        if (customer.CustomerGroup.Id === 4) {
            this.disableCustomMenu = true;
        }

        const loginRules = await SettingsService.GetLoginRules(source.token);
        this.loginRuleData = loginRules.filter(x => x.Value == 1);

        this.folderPermissionData = await SettingsService.GetFolderPermissions(source.token);

        const products = await ProductGroupService.GetProductGroupsLite(sessionInfo.Id, source.token);
        this.productTreeFields.dataSource = [
            {
                Id: -1,
                Name: "Alle Produkt Grupper",
                ParentId: null,
                expanded: true,
                SubGroups: products.map(p => {
                    return {
                        ...p,
                        expanded: true
                    }
                })
            }
        ];
        this.productData = products;

        this.setState({ loading: false, userGroupsData: userGroups, menu }, () => {
            setTimeout(() => {
                if (this.userMenuTreeRef) {
                    this.userMenuTreeRef.collapseAll();
                }
                if (this.menuProductTreeRef) {
                    this.menuProductTreeRef.collapseAll();
                }
            }, 250);
            
            this.onResize();
            window.addEventListener('resize', this.onResize);
        });
    }

    onResize() {
        setTimeout(() => {
            let userGroupList = document.querySelector('#user-groups-list .e-content > .e-ul');
            let userGroupsWrapper = document.querySelector('.user-groups-wrapper .user-groups');

            if (userGroupList) {
                userGroupList.style.maxHeight = (userGroupsWrapper.clientHeight - 132) + 'px';
                userGroupList.style.height = (userGroupsWrapper.clientHeight - 132) + 'px';
                userGroupList.style.overflowY = 'auto';
            }
        }, 300);
    }

    componentWillUnmount() {
        source.cancel();
        window.removeEventListener('resize', this.onResize);
    }

    async onUserGroupSelected(args) {
        source.cancel();
        source = CancelToken.source();

        const { data } = this.state;

        let header = document.querySelector('h2.user-group-header');
        if (header) {
            header.innerHTML = args.text;
        };

        const result = await UserGroupService.GetUserGroup(args.data.Id, source.token);

        this.ddlLoginRule.value = result.LoginRule;
        this.approvalOfOrderRef.checked = result.OrdersApproval;
        this.ddlFolderPermission.value = result.AccessSystemFolder;
        
        let menu = buildVirtualMenu(this.Customer.CustomerGroup.Menu);
        
        let checkedMenuNodes = buildCheckedNodes(menu, result.Menu);
        this.userMenuTreeRef.fields.dataSource = [];
        this.userMenuTreeRef.refresh();
        this.menuFields.dataSource = checkedMenuNodes;
        this.userMenuTreeRef.fields.dataSource = checkedMenuNodes;

        if (result.Products && result.Products.length > 0) {
            let selectedProducts = [];
            result.Products.forEach(product => {
                if (product.SubGroups && product.SubGroups.length > 0) {
                    const menuIds = product.SubGroups.map(x => x.Id.toString());
                    selectedProducts = selectedProducts.concat(menuIds);
                }
                else {
                    selectedProducts.push(product.Id.toString());
                }
            });
            this.menuProductTreeRef.checkedNodes = selectedProducts;
        }
        else {
            this.menuProductTreeRef.checkedNodes = [];
        }

        this.userMenuTreeRef.disabled = true;
        this.menuProductTreeRef.disabled = true;
        this.ddlLoginRule.enabled = false;
        this.approvalOfOrderRef.disabled = true;
        this.ddlFolderPermission.enabled = false;
        this.userGroupToolbar.enableItems(0, true);
        this.userGroupToolbar.enableItems(3, false);
        this.userGroupToolbar.enableItems(4, false);
        this.userMenuTreeRef.showCheckBox = true;

        const sessionInfo = await SessionService.GetSessionInfo();
        if (result.CustomerId === 1 && sessionInfo.Id !== 1) {
            this.userGroupToolbar.enableItems(0, false);
            this.userGroupToolbar.enableItems(3, false);
            this.userMenuTreeRef.showCheckBox = false;
            this.userMenuTreeRef.disabled = false;
        }

        this.setState({ data, selectedGroup: result, isEditMode: false, hasUnsavedChanges: false }, () => {
            setTimeout(() => {
                this.userMenuTreeRef.collapseAll();
                this.menuProductTreeRef.collapseAll();
            }, 200);
        });
    }

    async userGroupToolbarClicked(args) {
        const { onNotify } = this.props;
        if (args.item.id === 'edit') {
            this.userGroupToolbar.enableItems(0, false);
            this.userGroupToolbar.enableItems(1, true);
            this.userGroupToolbar.enableItems(2, true);

            if (this.disableCustomMenu) {
                this.userMenuTreeRef.disabled = true;
            }
            else {
                this.userMenuTreeRef.disabled = false;
            }
            
            this.menuProductTreeRef.disabled = false;
            this.ddlLoginRule.enabled = true;
            this.approvalOfOrderRef.disabled = false;
            this.ddlFolderPermission.enabled = true;
            this.setState({ isEditMode: true });
        }
        else if (args.item.id === 'save') {
            let buttons = [
                {
                    click: async () => {
                        this.saveGroup(false);
                        this.userNotificationDialog.hide();
                    },
                    buttonModel: {
                        content: 'No'
                    }
                },
                {
                    click: async () => {
                        this.saveGroup(true);
                        this.userNotificationDialog.hide();
                    },
                    buttonModel: {
                        isPrimary: true,
                        content: 'Yes'
                    }
                }
            ];

            this.userNotificationDialog.header = Localization.General.NoteHeader;
            this.userNotificationDialog.content = "Apply this to all users?";
            this.userNotificationDialog.buttons = buttons;
            this.userNotificationDialog.show();
        }
        else if (args.item.id === 'cancel') {
            this.userGroupToolbar.enableItems(0, true);
            this.userGroupToolbar.enableItems(1, false);
            this.userGroupToolbar.enableItems(2, false);

            this.userMenuTreeRef.disabled = true;
            this.menuProductTreeRef.disabled = true;
            this.ddlLoginRule.enabled = false;
            this.approvalOfOrderRef.disabled = true;
            this.ddlFolderPermission.enabled = false;
            
            await this.reloadUserGroup().then(() => {
                this.setState({ isEditMode: false, hasUnsavedChanges: false });
            });
        }
        else if (args.item.id === 'delete') {
            // Show Confirmation
            let buttons = MessageHelper.MB2.buttons;
            buttons[0].click = () => {
                this.userNotificationDialog.hide();
            }
            buttons[1].click = async () => {
                let { selectedGroup } = this.state;
                const result = await UserGroupService.DeleteUserGroup(selectedGroup.Id, source.token);
                if (!result.HasError) {
                    this.setState({ selectedGroup: {} });
                    
                    const sessionInfo = await SessionService.GetSessionInfo();
                    const userGroups = await UserGroupService.GetUserGroups(sessionInfo.Id, source.token);
                    userGroups.sort((a,b) => (a.Level > b.Level) ? 1 : -1);
                    this.userGroupItems = userGroups.map(x => {
                        return {
                            text: x.Name,
                            Id: x.Id,
                            editable: x.CustomerId === sessionInfo.Id
                        }
                    });
                    this.groupListRef.dataSource = this.userGroupItems;
                    this.groupListRef.dataBind();

                    // await this.reloadUserGroup();
                    this.userMenuTreeRef.selectedNodes = [];
                    this.menuProductTreeRef.selectedNodes = [];
                    this.ddlLoginRule.value = null;
                    this.approvalOfOrderRef.checked = false;
                    this.ddlFolderPermission.value = null;

                    onNotify(Localization.General.SuccessHeader, Localization.General.SuccessDelete.replace("{context}", "Brugergruppe"));
                    this.userNotificationDialog.hide();
                }
                else {
                    onNotify("Advarsel", result.ErrorMessage);
                    this.userNotificationDialog.hide();
                }
            }
            
            this.userNotificationDialog.header = "Advarsel";
            this.userNotificationDialog.content = MessageHelper.MB2.message.replace("{context_message}", "brugergruppe");
            this.userNotificationDialog.buttons = buttons;
            this.userNotificationDialog.show();
        }
        else if (args.item.id === 'undo') {
            source.cancel();
            source = CancelToken.source();

            const result = await UserGroupService.UndoUserGroup(source.token);

            if(!result.HasError) {
                await this.reloadUserGroup();
                this.undoRedoOptions(result.Undo, result.Redo, result.UndoAction, result.RedoAction);
                onNotify(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "brugergruppe"));
            }
            else {
                onNotify("Advarsel", result.ErrorMessage);
            }
        }
        else if (args.item.id === 'redo') {
            source.cancel();
            source = CancelToken.source();

            const result = await UserGroupService.RedoUserGroup(source.token);
            
            if(!result.HasError) {
                await this.reloadUserGroup();
                this.undoRedoOptions(result.Undo, result.Redo, result.UndoAction, result.RedoAction);
                onNotify(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Brugergrupper"));
            }
            else {
                onNotify("Advarsel", result.ErrorMessage);
            }
        }
    }

    async saveGroup(applyToAllUsers) {
        source.cancel();
        source = CancelToken.source();
        let { data, selectedGroup, selectedProducts, selectedMenus, menu } = this.state;
        const { onNotify } = this.props;

        const menuTreeIds = this.userMenuTreeRef.checkedNodes;            
        const menuProductTreeIds = this.menuProductTreeRef.checkedNodes;
        console.log(menuTreeIds);
        debugger;
        selectedMenus = buildMenuFromCheckedNodes(menu, menuTreeIds);
        selectedProducts = [];
        
        menuProductTreeIds.forEach(nId => {
            if (this.productData.some(x => x.Id === parseInt(nId))) { // if parent node is checked
                const iMenu = this.productData.find(x => x.Id === parseInt(nId));
                selectedProducts.push(iMenu);
            }
            else { // if child node is checked
                this.productData.forEach(mi => {
                    if (mi.SubGroups.some(x => x.Id === parseInt(nId))) {
                        const menuItem = mi.SubGroups.find(x => x.Id === parseInt(nId));
                        if (selectedProducts.some(u => u.Id === parseInt(mi.Id))) { // if child node's parent node already exist in state
                            const product = selectedProducts.find(u => u.Id === parseInt(mi.Id));
                            if (!product.SubGroups.some(x=> x.Id === menuItem.Id)) {
                                product.SubGroups.push(menuItem)
                            }
                        }
                        else { // if child node's parent node does not exist in state
                            const newMenu = Object.assign({}, mi);
                            newMenu.SubGroups = newMenu.SubGroups.filter(x=> x.Id === menuItem.Id);
                            selectedProducts.push(newMenu);
                        }
                    }
                });
            }
        });

        const sessionInfo = await SessionService.GetSessionInfo();
        const payload = {
            Id: selectedGroup.Id,
            CustomerId: parseInt(sessionInfo.Id),
            Name: selectedGroup.Name,
            Menu: selectedMenus,
            Products: selectedProducts,
            LoginRule: parseInt(this.ddlLoginRule.value),
            OrdersApproval: this.approvalOfOrderRef.checked,
            AccessSystemFolder: parseInt(this.ddlFolderPermission.value),
            Level: selectedGroup.Level,
            ApplyToAllUsers: applyToAllUsers
        };

        const result = await UserGroupService.UpdateUserGroup(payload, source.token);

        if(!result.HasError) {
            await this.reloadUserGroup();
            this.undoRedoOptions(result.UndoRedoStatus.Undo, result.UndoRedoStatus.Redo, result.UndoRedoStatus.UndoAction, result.UndoRedoStatus.RedoAction);
            this.userGroupToolbar.enableItems(0, true);
            this.userGroupToolbar.enableItems(1, false);
            this.userGroupToolbar.enableItems(2, false);

            this.userMenuTreeRef.disabled = true;
            this.menuProductTreeRef.disabled = true;
            this.ddlLoginRule.enabled = false;
            this.approvalOfOrderRef.disabled = true;
            this.ddlFolderPermission.enabled = false;

            onNotify(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Brugergrupper"));
            this.setState({ isEditMode: false, hasUnsavedChanges: false });
        }
        else {
            onNotify("Advarsel", result.ErrorMessage);
        }
    }

    async addGroup(name) {
        const { userGroupsData } = this.state;
        const { onNotify } = this.props;
        let nextLevel = 1;

        if (userGroupsData.length > 0) {
            nextLevel = Math.max.apply(Math, userGroupsData.map(function(u) { return u.Level; }));
            nextLevel = nextLevel + 1;
        }

        let copiedUserGroup = null;

        if (this.copyUserGroup.value) {
            copiedUserGroup = userGroupsData.find(u => u.Id === this.copyUserGroup.value);
        }

        const sessionInfo = await SessionService.GetSessionInfo();
        const payload = {
            Name: name,
            Menu: copiedUserGroup ? copiedUserGroup.Menu : { Intralogix: [], Flexmat: [] },
            Products: copiedUserGroup ? copiedUserGroup.Products : [],
            OrdersApproval: this.approvalOfOrderRef.checked,
            AccessSystemFolder: 0,
            Level: nextLevel,
            LoginRule: 1,
            CustomerId: parseInt(sessionInfo.Id)
        };
        
        const result = await UserGroupService.AddUserGroup(payload, source.token);
        if (!result.HasError) {
            userGroupsData.push(result);
            let dataSource = this.groupListRef.dataSource;
            dataSource.push({
                text: result.Name,
                Id: result.Id
            })
            this.groupListRef.dataSource = dataSource;
            this.groupListRef.dataBind();
            this.groupListRef.refresh();
            onNotify(Localization.General.SuccessHeader, Localization.General.SuccessAdd.replace("{context}", "Brugergrupper"));
            this.setState({ userGroupsData, }, () => {
                this.addGroupDialog.hide();
            });
        }
        else {
            onNotify("Advarsel", result.ErrorMessage);
        }
    }

    async reloadUserGroup() {
        const { selectedGroup } = this.state;
        await this.onUserGroupSelected({ text: selectedGroup.Name, data: { Id: selectedGroup.Id } });
    }

    undoRedoOptions(hasUndo, hasRedo, undoTooltip = '', redoTooltip = '') {
        this.userGroupToolbar.items[4].disabled = !hasUndo;
        this.userGroupToolbar.items[4].tooltipText = undoTooltip;
        this.userGroupToolbar.items[5].disabled = !hasRedo;
        this.userGroupToolbar.items[5].tooltipText = redoTooltip;
    }

    userGroupNodeCheck(args) {
        let checkedNode = [args.node];
        if (args.event.target.classList.contains('e-fullrow') || args.event.key == "Enter") {
            let getNodeDetails = this.userMenuTreeRef.getNode(args.node);
            if (getNodeDetails.expanded) {
                this.userMenuTreeRef.collapseAll(checkedNode);
                // this.userMenuTreeRef.uncheckAll(checkedNode);
            }
            else {
                this.userMenuTreeRef.expandAll(checkedNode);
                // this.userMenuTreeRef.checkAll(checkedNode);
            }
        }
        this.setState({ hasUnsavedChanges: true });
    }

    userGroupProductNodeCheck(args) {
        let checkedNode = [args.node];
        if (args.event.target.classList.contains('e-fullrow') || args.event.key == "Enter") {
            let getNodeDetails = this.menuProductTreeRef.getNode(args.node);
            if (getNodeDetails.isChecked == 'true') {
                this.menuProductTreeRef.uncheckAll(checkedNode);
            }
            else {
                this.menuProductTreeRef.checkAll(checkedNode);
            }
        }
        this.setState({ hasUnsavedChanges: true });
    }

    onEditClick() {
        this.isGroupEditing = true;
        this.addGroupDialog.header = "Omdøb Gruppe";
        this.addGroupDialog.show();

        let copyGroup = document.querySelector('.copy-group-wrapper');
        if (copyGroup) {
            copyGroup.style.display = 'none';
        }
        
        setTimeout(() => {
            const { selectedGroup } = this.state;
            this.copyUserGroup.enabled = false;
            this.copyUserGroup.value = null;
            this.NewGroupNameRef.value = selectedGroup.Name;
            
        }, 350);
    }

    itemTemplate(props) {
        return (
            <div className="item-wrapper">{props.text}{props.editable && <div className="c-icon-edit item-edit" onClick={this.onEditClick}></div>}</div>
        )
    }

    onChange() {
        this.setState({ hasUnsavedChanges: true });
    }

    showAddEditGroupDialog = () => {
        this.NewGroupNameRef.value = '';
        this.copyUserGroup.value = '';
        this.copyUserGroup.enabled = true;
        this.copyUserGroup.dataSource = this.userGroupItems;
        this.copyUserGroup.dataBind();
        this.addGroupDialog.show();
        this.addGroupDialog.header = "Du er ved at oprette en kundegruppel";
        
        let copyGroup = document.querySelector('.copy-group-wrapper');
        if (copyGroup) {
            copyGroup.style.display = 'block';
        }
    }

    render() {
        const { loading, data, selectedGroup, showGroupMenu, isEditMode, hasUnsavedChanges } = this.state;
        // const { permission } = this.props;

        if (loading) {
            return <div></div>
        }

        let isEditEnabled = false, isSaveCancelEnabled = false;

        if (Object.keys(selectedGroup).length > 0) {
            isEditEnabled = true;
            const sessionInfo = SessionService.GetSessionInfo();
            isEditEnabled = selectedGroup.CustomerId === 1 && sessionInfo.Id === 1;
        }

        if (isEditMode) {
            isEditEnabled = false;
            isSaveCancelEnabled = true;
        }

        return (
            <div className="user-groups-wrapper">
                <NavigationPrompt when={hasUnsavedChanges} 
                    navigate={path => this.props.history.push(path)}
                    initiateSave={() => this.userGroupToolbarClicked({ item: { id: 'save' } })}
                    shouldBlockNavigation={location => {
                        if (hasUnsavedChanges) {
                            return true;
                        }
                        return false;
                    }}
                />
                <div className="user-groups">
                    <h3>Brugergruppe</h3>
                    {/* {
                        permission.Functions.some(f => f === "Ny") &&
                    } */}
                    <div className="text-center" style={{ margin: '15px 0' }}>
                        <button className="e-btn e-primary" type="button" onClick={this.showAddEditGroupDialog}><i className="c-icon c-icon-add-white"></i>{Localization.User.AddUserGroup}</button>
                    </div>
                    <ListViewComponent ref={ref => this.groupListRef = ref} id="user-groups-list" dataSource={this.userGroupItems} select={this.onUserGroupSelected} template={this.itemTemplate}></ListViewComponent>
                </div>
                <div className="user-groups-menu">
                    <h2 className="user-group-header"></h2>
                    <div className="user-group-toolbar-wrapper">
                        <ToolbarComponent ref={ref => this.userGroupToolbar = ref} clicked={this.userGroupToolbarClicked}>
                            <ItemsDirective>
                                <ItemDirective id="edit" prefixIcon='tb-icons c-icon-edit' text="Redigere" tooltipText='Redigere' disabled={!isEditEnabled} /> {/* visible={permission.Functions.some(f => f === "Redigere")} */}
                                <ItemDirective id="save" prefixIcon='tb-icons c-icon-update' text="Gem" tooltipText='Gem' disabled={!isSaveCancelEnabled} />{/* visible={permission.Functions.some(f => f === "Redigere")} */}
                                <ItemDirective id="cancel" prefixIcon='tb-icons c-icon-cancel' text="Annuller" tooltipText='Annuller' disabled={!isSaveCancelEnabled} />{/* visible={permission.Functions.some(f => f === "Redigere")} */}
                                <ItemDirective id="delete" prefixIcon='tb-icons c-icon-delete' text="Slet" tooltipText='Slet' disabled={!isEditEnabled} /> {/* visible={permission.Functions.some(f => f === "Slette")}  */}
                                <ItemDirective id="undo" prefixIcon='tb-icons c-icon-undo' text="Fortryd" tooltipText='Fortryd' disabled={true} /> {/*visible={permission.Functions.some(f => f === "Undo")}*/}
                                <ItemDirective id="redo" prefixIcon='tb-icons c-icon-redo' text="Gentag" tooltipText='Gentag' disabled={true} /> {/*visible={permission.Functions.some(f => f === "Fortryd")}*/}
                            </ItemsDirective>
                        </ToolbarComponent>
                    </div>
                    <div className="user-group-options-wrapper">
                        <div className="user-group-options--menu">
                            <Card headerText="Vælg en profil til venstre, vælg derefter hvilke menuer der skal være tilgængelige" subText="Klik på + for tilhørende funktioner">
                                <TreeViewComponent id='user-group-menu-tree' ref={ref => this.userMenuTreeRef = ref} fields={this.menuFields} showCheckBox={true} disabled={true}
                                nodeClicked={this.userGroupNodeCheck.bind(this)} />
                            </Card>
                        </div>
                        <div className="user-group-options--product">
                            <Card headerText="Vælg hvilke produkt gr. Bruger gr. Skal have adgang til" subText="Klik på + for tilhørende funktioner">
                                <TreeViewComponent id='user-group-product-tree' ref={ref => this.menuProductTreeRef = ref} fields={this.productTreeFields} showCheckBox={true} disabled={true} 
                                    nodeClicked={this.userGroupProductNodeCheck.bind(this)} />
                            </Card>
                        </div>
                        <div className="user-group-options--misc">
                            <Card headerText="Log in regel på FlexMat">
                                <DropDownListComponent id="ddlLoginRule" ref={ref => this.ddlLoginRule = ref} name="ddlLoginRule" dataSource={this.loginRuleData} value={data.loginRule}
                                    placeholder="Log in regel på FlexMat" fields={{ text: 'Key', value: 'Value' }} floatLabelType="Auto" enabled={false} locale="da" change={this.onChange} />
                            </Card>
                            <Card showHeader={false}>
                                <div className="field-bordered">
                                    <label className="label" htmlFor="approvalOfOrder">Godkendelse af Ordre</label>
                                    <SwitchComponent ref={ref => this.approvalOfOrderRef = ref} id="approvalOfOrder" cssClass="control-right" disabled={true} change={this.onChange} />
                                </div>
                            </Card>
                            <Card headerText="Access System Folder">
                                <DropDownListComponent id="ddlFolderPermission" ref={ref => this.ddlFolderPermission = ref} name="ddlFolderPermission" dataSource={this.folderPermissionData} value={selectedGroup.AccessSystemFolder}
                                    placeholder="Access System Folder" fields={{ text: 'Key', value: 'Value' }} floatLabelType="Auto" enabled={false} locale="da" change={this.onChange} />
                            </Card>
                        </div>
                    </div>
                </div>
                <DialogComponent isModal={true} buttons={this.buttons} width='450px' ref={dialog => this.addGroupDialog = dialog} header={Localization.User.AddUserGroup} target='body' 
                    visible={false} className="add-user-group-dialog" showCloseIcon={true} animationSettings={this.animationSettings} allowDragging={true} enableResize={true}>
                        <div className="dialog-content">
                            <div style={{marginBottom: 15}}>
                                <TextBoxComponent ref={ref => this.NewGroupNameRef = ref} placeholder="Indtast navn" floatLabelType="Auto" />
                            </div>
                            <div className="copy-group-wrapper">
                                <DropDownListComponent ref={ref => this.copyUserGroup = ref} placeholder="Vil du oprette gruppen baseret på:" locale="da" dataSource={this.userGroupItems} fields={{ text: 'text', value: 'Id' }} floatLabelType="Auto" />
                            </div>
                        </div>
                </DialogComponent>
                <DialogComponent id="system-group-dialog" isModal={true} width='auto' ref={dialog => this.userNotificationDialog = dialog} target='body'
                    visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true}/>
            </div>
        )
    }
}

export default withRouter(UserGroups);
