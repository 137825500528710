import HttpClient from '../../utilities/HttpClient';

const API = {
    getBusinessCategories: '/businesscategory',
    getBusinessCategory: '/businesscategory/{id}',
    postBusinessCategory: '/businesscategory',
    undo: '/businesscategory/undo',
    redo: '/businesscategory/redo'
}

class BusinessCategory {
    async GetList(cancelToken) {
        return await HttpClient.Get(API.getBusinessCategories, null, cancelToken);
    }

    async Get(id, cancelToken) {
        const url = API.getBusinessCategory.replace('{id}', id);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async Bulk(data, cancelToken) {
        return await HttpClient.Post(API.postBusinessCategory, data, cancelToken);
    }

    async Undo(cancelToken) {
        return await HttpClient.Put(API.undo, null, cancelToken);
    }

    async Redo(data, cancelToken) {
        return await HttpClient.Put(API.redo, null, cancelToken);
    }
}

const businessCategory = new BusinessCategory();
export default businessCategory;