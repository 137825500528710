import React, { Component } from 'react'
import PropTypes from "prop-types";
import isEqual from "react-fast-compare";

import emptyModule from '../assets/machines/modules/empty_module.png';
import '../styles/machine-mapper.scss';

export class MachineLocationMapper extends Component {
    constructor() {
        super();

        this.initCanvas = this.initCanvas.bind(this);
        this.renderShelvesArea = this.renderShelvesArea.bind(this);
        this.watchedProps = [ "active", "activeBoxId", "selectedPlasticBin" ];
    }

    shouldComponentUpdate(nextProps) {
		const propChanged = this.watchedProps.some(
			prop => this.props[prop] !== nextProps[prop]
		);
		return !isEqual(this.props.shelves, this.state.shelves) || propChanged;
    }

    componentWillMount() {
		this.updateCacheMap();
	}

	componentDidUpdate() {
		this.updateCacheMap();
		this.initCanvas();
	}

	updateCacheMap() {
		this.setState(
			{ shelves: JSON.parse(JSON.stringify(this.props.shelves)) },
			this.initCanvas
		);
	}

    initCanvas() {
        if (this.props.width) this.image.width = this.props.width;

        if (this.props.height) this.image.height = this.props.height;
        
        this.container.style.width =
			(this.props.width || this.image.clientWidth) + "px";
		this.container.style.height =
            (this.props.height || this.image.clientHeight) + "px";
            
        if (this.props.active) {
            this.canvas.width = this.props.width || this.image.clientWidth;
            this.canvas.height = this.image.clientHeight;
            this.canvasContext = this.canvas.getContext("2d");

            this.renderFilledBoxes();
        }
    }

    drawrect(coords, fillColor, lineWidth, strokeColor) {
		let [left, top, right, bot] = coords;
		this.canvasContext.fillStyle = fillColor;
		this.canvasContext.lineWidth = lineWidth;
		this.canvasContext.strokeStyle = strokeColor;
		this.canvasContext.strokeRect(left, top, right - left, bot - top);
		this.canvasContext.fillRect(left, top, right - left, bot - top);
		this.canvasContext.fillStyle = fillColor;
    }
    
    drawText(text, font, coords) {
        this.canvasContext.font = `normal ${font} Arial`;
        this.canvasContext.fillStyle = "black";
        this.canvasContext.fillText(text, coords[0], coords[1]);
    }

    click(area, index, event) {
        if (this.props.onClick) {
			event.preventDefault();
			this.props.onClick(area, index, event);
		}
    }

    computeCenter(area) {
        if (!area) return [0, 0];
        
		const scaledCoords = this.scaleCoords(area);

		// Calculate centroid
        const n = scaledCoords.length / 2;
        const { y, x } = scaledCoords.reduce(
            ({ y, x }, val, idx) => {
                return !(idx % 2) ? { y, x: x + val / n } : { y: y + val / n, x };
            },
            { y: 0, x: 0 }
        );
        return [x, y];
	}

    scaleCoords(coords) {
		const { imgWidth, width } = this.props;
		// calculate scale based on current 'width' and the original 'imgWidth'
		const scale = width && imgWidth && imgWidth > 0 ? width / imgWidth : 1;
		return coords.map(coord => parseInt(coord) * scale);
	}

    renderFilledBoxes() {
        for (let index = 0; index < this.state.shelves.length; index++) {
            const shelf = this.state.shelves[index];
            const hasBoxes = shelf.Boxes.length > 0;//shelf.ShelfType === 2 || shelf.ShelfType === 3 || shelf.ShelfType === 4 || shelf.ShelfType === 5;

            if (!hasBoxes) continue;

            const nextShelf = index + 1 > this.state.shelves.length ? null : this.state.shelves[index + 1];

            let [left, top, right, bot] = shelf.Coords.split(",");
            let boxWidth = 0, newLeft = 13, newTop = 0, newBottom = 0, fillColor = '';

            switch (shelf.ShelfType) {
                case 1: //Empty shelf
                    newTop = top - 34;
                    boxWidth = 138;
                    break;
                case 2: // Half shelf
                case 3: // Full Shelf
                    if (nextShelf && nextShelf.ShelfType === 4) {
                        newBottom = bot - 10;
                    }
                    else {
                        newBottom = bot - 2;
                    }

                    newTop = top - 34;
                    boxWidth = 138 / shelf.Boxes.length;
                    break;
                case 4: // low plastic bin
                    if (nextShelf && nextShelf.ShelfType === 5) {
                        newBottom = bot - 6;
                    }
                    else if (index + 1 === this.state.shelves.length) {
                        newBottom = bot - 20;
                    }
                    else {
                        newBottom = bot - 9;
                    }

                    newTop = top - 23;
                    boxWidth = 137 / shelf.Boxes.length;
                    newLeft = 19;
                    break;
                case 5: // high plastic bin
                    if (index + 1 === this.state.shelves.length) {
                        newBottom = bot - 20;
                        newTop = top - 17;
                    }
                    else {
                        newTop = top - 8;
                        newBottom = bot - 10;
                    }
                    
                    boxWidth = 137 / shelf.Boxes.length;
                    break;
                default:
                    break;
            }

            let isSkipNextBox = false;
            let prevBox = null;

            for (let boxIndex = 0; boxIndex < shelf.Boxes.length; boxIndex++) {
                const box = shelf.Boxes[boxIndex];
                let newRight = boxWidth * (boxIndex + 1);
                let center = null;

                let boxBottom = newBottom;
                prevBox = shelf.Boxes[boxIndex - 1];

                if (isSkipNextBox) {
                    isSkipNextBox = false;
                    continue;
                }

                if (box.ShelfSpan > 1) {
                    let spanIndex = index + (box.ShelfSpan - 1);
                    let [boxLeft, boxTop, boxRight, boxBot ] = this.state.shelves[spanIndex].Coords.split(',');
                    boxBottom = boxBot;
                }

                switch(box.BoxState) {
                    case 1:
                        fillColor = "#92d050";
                        break;
                    case 2:
                        fillColor = "#ffff00";
                        break;
                    case 3:
                        fillColor = "#ff0000";
                        break;
                    default:
                        break;
                }

                if (box.BoxType === 1) {
                    if (box.SlotStart === 0 && box.SlotEnd === 1) {
                        newLeft = 13;
                        newRight = newLeft + 60; 
                    }
                    else if (box.SlotStart === 2 && box.SlotEnd === 3) {
                        newLeft = 79;
                        newRight = newLeft + 57;
                    }
                    else if (box.SlotStart === 0 && box.SlotEnd === 3) {
                        // do nothing
                    }
                }
                if (box.BoxType === 2 || box.BoxType === 3) {
                    if (box.SlotStart !== box.SlotEnd) {
                        switch(boxIndex) {
                            case 0:
                                newRight = newLeft + 50;
                                break;
                            case 2:
                                newLeft = 82;
                                newRight = 134;
                                break;
                            default:
                                break;
                        }
                    }
                    else {
                        switch(box.SlotStart) {
                            case 0:
                                newLeft = 18;
                                break;
                            case 1:
                                newLeft = 45;
                                break;
                            case 2:
                                newLeft = 82;
                                break;
                            case 3:
                                newLeft = 109;
                                break;
                            default:
                                break;
                        }

                        newRight = newLeft + 25;
                    }
                }
                else if (box.BoxType === 4) {
                    switch(boxIndex) {
                        case 0:
                            newLeft = 15;
                            newRight = newLeft + 60; 
                            break;
                        case 1:
                            newLeft = 79;
                            newRight = newLeft + 55;
                            break;
                        default:
                            break;
                    }
                }

                if (this.props.activeBoxId) {
                    if (box.Id === this.props.activeBoxId) {
                        fillColor = '#00b0f0';
                    }
                }
                if (this.props.confirmingBox && this.props.activeBoxId) {
                    if (box.Id === this.props.activeBoxId) {
                        fillColor = '#ffff00';
                    }
                }

                // visual for medium plastic bin before completion
                if ((box.Id === this.props.activeBoxId) && this.props.selectedPlasticBin) {
                    if (this.props.selectedPlasticBin === 2 && box.BoxType !== 3 && box.BoxType !== 4) {
                        if (box.SlotStart === 0) {
                            newLeft = 18;
                            newRight = newLeft + 53;
                            isSkipNextBox = true;
                        }
                        else if (box.SlotStart === 1) {
                            if (prevBox && prevBox.BoxType === 3) {
                                newLeft = 82;
                                newRight = 134;
                                isSkipNextBox = true;
                            }
                            else {
                                newLeft = 18;
                                newRight = newLeft + 53;
                            }
                        }
                        else if (box.SlotStart === 2) {
                            newLeft = 82;
                            newRight = 134;
                            isSkipNextBox = true;
                        }
                        else if (box.SlotStart === 3) {
                            newLeft = 82;
                            newRight = 134;
                        }
                    }
                    else if (this.props.selectedPlasticBin === 1 && box.BoxType === 3) {
                        if (box.SlotStart === 0 && box.SlotEnd === 1) {
                            // 
                            newLeft = 18;
                            newRight = newLeft + 25;
                            
                            // Draw extra rectangle beside to visualize a small plastic bin
                            this.drawrect([45, newTop, 45 + 25, boxBottom], "#92d050", 1, "#92d050");
                        }
                        else if (box.SlotStart === 2 && box.SlotEnd === 3) {
                            newLeft = 82;
                            newRight = newLeft + 25;

                            // Draw extra rectangle beside to visualize a small plastic bin
                            this.drawrect([109, newTop, 109 + 25, boxBottom], "#92d050", 1, "#92d050");
                        }
                    }
                }
                
                center = this.computeCenter([newLeft - 15, newTop, newRight, boxBottom]);

                if (box.BoxType === 4) {
                    center = this.computeCenter([newLeft - 25, newTop, newRight, boxBottom]);
                }

                this.drawrect([newLeft, newTop, newRight, boxBottom], fillColor, 1, fillColor);
                let font = "11px";
                let boxNo = box.No;
                
                if ((box.Id === this.props.activeBoxId) && this.props.selectedPlasticBin) {
                    if (this.props.selectedPlasticBin === 2 && box.BoxType !== 3 && box.BoxType !== 4) {
                        if (box.SlotStart === 0) {
                            let nextBox = shelf.Boxes[boxIndex + 1];
                            let nextBoxNo = nextBox.No.replace( /^\D+/g, '');
                            boxNo = boxNo + "/" + nextBoxNo;
                            center[0] = center[0] - 8;
                        }
                        else if (box.SlotStart === 1) {
                            if (prevBox && prevBox.BoxType === 2) {
                                let prevBoxNo = prevBox.No.replace(/^\D+/g, '');
                                let tempBoxNo = boxNo.replace(/^\D+/g, '');
                                let tempBoxLetters = boxNo.replace(/[0-9]/g, '');
                                boxNo = tempBoxLetters + prevBoxNo + "/" + tempBoxNo;
                                center[0] = center[0] - 8;
                            }
                        }
                        else if (box.SlotStart === 2) {
                            let nextBox = shelf.Boxes[boxIndex + 1];
                            let nextBoxNo = nextBox.No.replace( /^\D+/g, '');
                            boxNo = boxNo + "/" + nextBoxNo;
                            center[0] = center[0] - 8;
                        }
                        else if (box.SlotStart === 3) {
                            if (prevBox && prevBox.BoxType === 2) {
                                let prevBoxNo = prevBox.No.replace( /^\D+/g, '');
                                let tempBoxNo = boxNo.replace( /^\D+/g, '');
                                let tempBoxLetters = boxNo.replace(/[0-9]/g, '');
                                boxNo = tempBoxLetters + prevBoxNo + "/" + tempBoxNo;
                                center[0] = center[0] - 8;
                            }
                        }
                    }
                    else if (this.props.selectedPlasticBin === 1 && box.BoxType === 3) {
                        if ((box.SlotStart === 0 && box.SlotEnd === 1) || (box.SlotStart === 2 && box.SlotEnd === 3)) {
                            let tempBoxLetters = boxNo.replace(/[0-9\/]/g, '');
                            let tempBoxNo = boxNo.replace(tempBoxLetters, '').split('/');
                            let vBoxNo = tempBoxLetters + tempBoxNo[1];
                            boxNo = tempBoxLetters + tempBoxNo[0];
                            // Separate box number
                            if (box.SlotStart === 0 && box.SlotEnd === 1) {
                                let tempCenter = this.computeCenter([45 - 15, newTop, 45 + 25, boxBottom]);
                                this.drawText(vBoxNo, "8px", tempCenter);
                            }
                            else if (box.SlotStart === 2 && box.SlotEnd === 3) {
                                let tempCenter = this.computeCenter([109 - 15, newTop, 109 + 25, boxBottom]);
                                this.drawText(vBoxNo, "8px", tempCenter);
                            }
                        }
                        // else if (box.SlotStart === 2 && box.SlotEnd === 3) {
                        //     let tempBoxLetters = boxNo.replace(/[0-9\/]/g, '');
                        //     let tempBoxNo = boxNo.replace(tempBoxLetters, '').split('/');
                        //     let vBoxNo = tempBoxLetters + tempBoxNo[0];

                        //     // Separate box number
                        //     this.drawText(vBoxNo, font, center);
                        // }
                    }
                }

                if (box.BoxType === 2 || box.BoxType === 3) {
                    font = "8px";
                }
                else if (boxNo.length > 4) {
                    center[0] = center[0] - 8;
                }
                this.drawText(boxNo, font, center);
                newLeft = newRight;
            }
        }
	}

    renderShelvesArea() {
        return this.state.shelves.map((shelf, index) => {
            const hasBoxes = shelf.Boxes.length > 0;//shelf.ShelfType === 2 || shelf.ShelfType === 3 || shelf.ShelfType === 4 || shelf.ShelfType === 5;
            
            if(!hasBoxes) return;

            const currentShelfIndex = index;
            const nextShelf = index + 1 > this.state.shelves.length ? null : this.state.shelves[index + 1];
            let [left, top, right, bot] = shelf.Coords.split(",");
            let newTop = 0, newBottom = 0, boxWidth = 0, newLeft = 13;

            switch (shelf.ShelfType) {
                case 1: // Empty shelf
                    newTop = top - 34;
                    boxWidth = 138;
                    break;
                case 2: // Half shelf
                case 3: // Full Shelf
                    if (nextShelf && nextShelf.ShelfType === 4) {
                        newBottom = bot - 10;
                    }
                    else {
                        newBottom = bot - 2;
                    }

                    newTop = top - 34;
                    boxWidth = 138 / shelf.Boxes.length;
                    break;
                case 4: // low plastic bin
                    if (nextShelf && nextShelf.ShelfType === 5) {
                        newBottom = bot - 5;
                    }
                    else {
                        newBottom = bot - 9;
                    }

                    newTop = top - 25;
                    boxWidth = 137 / shelf.Boxes.length;
                    break;
                case 5: // high plastic bin
                    newTop = bot - 50;
                    newBottom = bot;
                    // newBottom = bot - 15;
                    boxWidth = 137 / shelf.Boxes.length;
                    break;
                default:
                    break;
            }

            // loop through boxes
            let isSkipNextBox = false;
            let prevBox = null;
            return shelf.Boxes.map((box, index) => {
                const nextBox = (index + 1) > (shelf.Boxes.length - 1) ? null : shelf.Boxes[index + 1];
                const extendedArea = { machineId: this.props.Id, moduleId: this.props.machineId, shelf: { ...shelf }, box: {...box} };
                let newRight = boxWidth * (index + 1);

                newLeft = newRight;
                
                prevBox = shelf.Boxes[index - 1];

                if (isSkipNextBox) {
                    isSkipNextBox = false;
                    return;
                }
                
                if (box.ShelfSpan > 1) {
                    let spanIndex = currentShelfIndex + (box.ShelfSpan - 1);
                    let [boxLeft, boxTop, boxRight, boxBot ] = this.state.shelves[spanIndex].Coords.split(',');
                    newBottom = boxBot - 2;
                }

                if (box.BoxType === 1) {
                    if (box.SlotStart === 0 && box.SlotEnd === 1) {
                        newLeft = 13;
                        newRight = newLeft + 60; 
                    }
                    else if (box.SlotStart === 2 && box.SlotEnd === 3) {
                        newLeft = 79;
                        newRight = newLeft + 57;
                    }
                    else if (box.SlotStart === 0 && box.SlotEnd === 3) {
                        newLeft = 13;
                        newRight = 138;
                    }
                }
                if (box.BoxType === 2 || box.BoxType === 3) {
                    if (box.SlotStart !== box.SlotEnd) {
                        switch(index) {
                            case 0:
                                newLeft = 13;
                                newRight = newLeft + 50;
                                break;
                            case 2:
                                newLeft = 82;
                                newRight = 134;
                                break;
                            default:
                                break;
                        }
                    }
                    else {
                        switch(box.SlotStart) {
                            case 0:
                                newLeft = 13;
                                break;
                            case 1:
                                newLeft = 45;
                                break;
                            case 2:
                                newLeft = 82;
                                break;
                            case 3:
                                newLeft = 109;
                                break;
                            default:
                                break;
                        }

                        newRight = newLeft + 25;
                    }
                }
                else if (box.BoxType === 4) {
                    switch(index) {
                        case 0:
                            newLeft = 15;
                            newRight = newLeft + 60; 
                            break;
                        case 1:
                            newLeft = 79;
                            newRight = newLeft + 55;
                            break;
                        default:
                            break;
                    }
                }

                if ((box.Id === this.props.activeBoxId) && this.props.selectedPlasticBin) {
                    if (this.props.selectedPlasticBin === 2 && box.BoxType !== 3 && box.BoxType !== 4) {
                        if (box.SlotStart === 0) {
                            newLeft = 18;
                            newRight = newLeft + 53;
                            isSkipNextBox = true;
                        }
                        else if (box.SlotStart === 1) {
                            if (prevBox && prevBox.BoxType === 3) {
                                newLeft = 82;
                                newRight = 134;
                                isSkipNextBox = true;
                            }
                            else {
                                newLeft = 18;
                                newRight = newLeft + 53;
                            }
                        }
                        else if (box.SlotStart === 2) {
                            newLeft = 82;
                            newRight = 134;
                            isSkipNextBox = true;
                        }
                        else if (box.SlotStart === 3) {
                            newLeft = 82;
                            newRight = 134;
                        }
                    }
                    else if (this.props.selectedPlasticBin === 1 && box.BoxType === 3) {
                        if (box.SlotStart === 0 && box.SlotEnd === 1) {
                            newLeft = 18;
                            newRight = newLeft + 25;
                        }
                        else if (box.SlotStart === 2 && box.SlotEnd === 3) {
                            newLeft = 82;
                            newRight = newLeft + 25;
                        }
                    }
                }

                const coords = [newLeft, newTop, newRight, newBottom];

                return (
                    <area
                        key={shelf._id || index}
                        shape="rect"
                        coords={coords.join(",")}
                        onClick={this.click.bind(this, extendedArea, index)}
                    />
                );
            });
        });
    }

    render() {
        return (
            <div className="image-mapper-wrapper" style={{width: this.props.width}} ref={node => (this.container = node)}>
                <img ref={ref => this.image = ref} src={this.props.src || emptyModule} useMap={`#${this.props.name}`}
                    className="main-image" onLoad={this.initCanvas} />
                {
                    this.props.active &&
                    <React.Fragment>
                        <canvas ref={ref => this.canvas = ref} className="image-canvas"></canvas>
                        {
                            this.props.shelves &&
                            <map name={this.props.name} style={{cursor: 'pointer'}}>
                                { this.renderShelvesArea() }
                            </map>
                        }
                    </React.Fragment>
                }
            </div>
        )
    }
}

MachineLocationMapper.defaultProps = {
    name: "image-map-" + Math.random(),
    active: false,
    isRemoving: false,
    activeElement: null,
    width: 151
};

MachineLocationMapper.propTypes = {
    name: PropTypes.string,
    src: PropTypes.string,
    width: PropTypes.number,
    active: PropTypes.bool,
    activeElement: PropTypes.object,
    shelves: PropTypes.arrayOf(
        PropTypes.shape({
            Coords: PropTypes.string,
            SlotNo: PropTypes.number,
            IsEditable: PropTypes.bool,
            ShelfType: PropTypes.number
        })
    )
}

export default MachineLocationMapper;
