import React, { Component } from 'react';
import { BarcodeGeneratorComponent } from "@syncfusion/ej2-react-barcode-generator";
import QueryString from 'query-string';

import '../../styles/label.scss';

export class LabelPrinter extends Component {
    componentDidMount() {
        let header = document.querySelector('div.header');
        let sidebar = document.querySelector('#sidebar-menu');
        let mainContent = document.querySelector('.main-content-wrapper');

        if (header) {
            header.style.display = 'none';
        }
        if (sidebar) {
            sidebar.style.display = 'none';
        }
        if (mainContent) {
            mainContent.style.height = '100%';

            let content = mainContent.querySelector('div.content.e-content-animation');
            content.style.marginLeft = '0px';
        }

        setTimeout(() => {
            window.print();
        }, 300);
    }

    render() {
        const { location } = this.props;
        let printParam = {};

        if (location.search) {
            let queryString = location.search.replace('?', '').split('&');
            for (let index = 0; index < queryString.length; index++) {
                let key = queryString[index].split('=')[0];
                let val = QueryString.parse(queryString[index]);
                printParam[key] = val[key];
            }
        }

        if (Object.keys(printParam).length <= 0) {
            let productLabels = localStorage.getItem('PRINT_PROD_LABEL');
            if (productLabels) {
                printParam.Type = "Product";
                printParam.Products = productLabels;
            }
        }

        function renderProductLabel(printParam) {
            let description = printParam.d;
            if (description && description.length > 30) {
                description = description.substring(0, 30) + '...';
            }

            return (
                <div className="print-area">
                    <div className="product-label-wrapper">
                        <div className="text-center product-label-description">{description}</div>
                        <div className="text-center product-label-productno">Produkt Nr.: {printParam.p}</div>
                        {printParam.e && 
                            <BarcodeGeneratorComponent  width={"149px"} height={"40px"} mode='SVG' type='Ean13' value={printParam.e} displayText={{ size: 10 }} margin={{ top: 0, right: 0 }}>
                            </BarcodeGeneratorComponent>
                        }
                    </div>
                </div>
            )
        }
    
        function renderLocationLabel(printParam) {
            const boxChars = printParam.split("");
            let boxName = '', roomNo = '';
            
            for (let index = 0; index < boxChars.length; index++) {
                if (!parseInt(boxChars[index])) {
                    boxName += boxChars[index];
                }
                else {
                    roomNo = printParam.substring(boxName.length, printParam.length);
                    break;
                }
            }

            if (!boxName || !roomNo) {
                return;
            }

            return (
                <div className="print-area">
                    <div className="location-label-wrapper">
                        <div className="text-center location-label-box">{boxName}</div>
                        <div className="text-center location-label-room">{roomNo}</div>
                    </div>
                </div>
            )
        }

        if (printParam.Type === "Product") {
            // 4 by 12
            let items = [];
            let products = JSON.parse(printParam.Products);
            for (let index = 0; index < products.length; index++) {
                items.push(<li key={`product_barcode_${index}`}>{ renderProductLabel(products[index]) }</li>);
            }
            return ( <ul className="product-labels-wrapper">{items}</ul> )
        }
        else {
            let items = [];
            let labels = printParam.Boxes.split(',');
            for (let index = 0; index < labels.length; index++) {
                items.push(<li key={`location_label_${index}`}>{ renderLocationLabel(labels[index]) }</li>);
            }
            return (
                <ul className="location-labels-wrapper">{items}</ul>
            )
        }
    }
}

export default LabelPrinter;