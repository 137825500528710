import React, { Component } from "react";
import { Query } from '@syncfusion/ej2-data';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';

import OilmatWholesalerOverview from "./OilmatWholesalerOverview";

export default class OilmatWholesaler extends Component {
    constructor(props) {
        super(props);

        let wholesaler = null;
        if (props.data.WholesalerId !== null) {
            wholesaler = props.supplierList.find(x => x.Id == props.data.WholesalerId);
        }

        this.state = {
            selectedWholesaler: wholesaler
        };
        
        this.onWholesalerFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('CompanyName', 'startswith', e.text, true) : query;
            e.updateData(props.supplierList, query)
        }
    }

    onDropdownChange = (name, args) => {
        if (args.isInteracted) {
            this.props.onChange(name, args.value);
            this.props.onChange("wholesalerNo", args.itemData.CustomerNo);

            this.setState({ selectedWholesaler: args.itemData })
        }
    }

    render() {
        const { selectedWholesaler } = this.state;
        const { data, supplierList, t } = this.props;

        return (
            <div className="wholesaler-search-container">
                <div className="title">{t('TitleWholesaler')}</div>
                <div className="info-text m-bottom-15">{t('TitleWholesalerInfoText')}</div>
                <DropDownListComponent 
                    name="WholesalerId" 
                    value={data.WholesalerId}
                    dataSource={supplierList}
                    filtering={this.onWholesalerFiltering.bind(this)} 
                    filterBarPlaceholder={t('SearchWholesaler')}
                    allowFiltering={true}
                    placeholder={t('LabelWholesaler') + "*"}
                    fields={{ text: 'CompanyName', value: 'Id' }} 
                    change={this.onDropdownChange.bind(this, "WholesalerId")} 
                    floatLabelType="Auto"
                />
                { selectedWholesaler !== null && <OilmatWholesalerOverview supplierData={selectedWholesaler} t={t} /> }
            </div>
        );
    }
}