 import HttpClient from '../../utilities/HttpClient';

const API = {
    getUserGroups: `/usergroup/{customer_id}/byCustomer`,
    getUserGroup: `/usergroup/{usergroup_id}`,
    postUserGroups: `/usergroup`,
    putUserGroups: `/usergroup`,
    undoUserGroup: `/usergroup/undo`,
    redoUserGroup: `/usergroup/redo`,
    deleteUserGroup: '/usergroup/{id}'
}

class UserGroupService {
    async GetUserGroups(customerId, cancelToken) {
        const url = API.getUserGroups.replace('{customer_id}', customerId);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async GetUserGroup(id, cancelToken) {
        const url = API.getUserGroup.replace('{usergroup_id}', id);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async AddUserGroup(data, cancelToken) {
        return await HttpClient.Post(API.postUserGroups, data, cancelToken);
    }

    async UpdateUserGroup(data, cancelToken) {
        return await HttpClient.Put(API.putUserGroups, data, cancelToken);
    }

    async UndoUserGroup(cancelToken) {
        return await HttpClient.Put(API.undoUserGroup, null, cancelToken);
    }

    async RedoUserGroup(cancelToken) {
        return await HttpClient.Put(API.redoUserGroup, null, cancelToken);
    }

    async DeleteUserGroup(id, cancelToken) {
        const url = API.deleteUserGroup.replace('{id}', id);
        return await HttpClient.Delete(url, null, cancelToken);
    }
}

const userGroupService = new UserGroupService();
export default userGroupService;