import React, { Component } from 'react';
import ILXLogo from '../../assets/logo_ilx_hor.png';
import { DialogComponent } from '@syncfusion/ej2-react-popups';

import { ValidateEmail } from '../../utilities/Helper';
import AuthenticationService from '../../services/AuthenticationService';
import AccountService from '../../services/AccountService';
import Localization from '../../utilities/Localization';
import Axios from 'axios';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class ForgotPassword extends Component {
    constructor() {
        super();

        this.state = {
            email: '',
            errorMessage: '',
            response: {
                hasError: false,
                message: ''
            }
        }
        
        this.animationSettings = { effect: 'None' };
        this.buttons = [
            {
                // Click the footer buttons to hide the Dialog
                click: () => {
                    AuthenticationService.login();
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    //Enables the primary button
                    isPrimary: true,
                    content: Localization.General.GoBackToLoginPage
                }
            }
        ];
    }

    componentDidMount() {
        this.mailRef.focus();
    }

    onClick = async () => {
        this.submitBtn.disabled = true;
        let { email, response } = this.state;

        if (!email) {
            this.submitBtn.disabled = false;
            this.setState({ errorMessage: 'Mail skal udfyldes'})
        }
        else if (!ValidateEmail(email)) {
            this.submitBtn.disabled = false;
            this.setState({ errorMessage: Localization.General.InvalidEmailFormat });
            return false;
        }
        debugger;

        const result = await AccountService.ForgotPassword({ Email: this.state.email }, source.token);

        if (!result.HasError) {
            this.notificationDialog.header = Localization.General.NoteHeader;
            this.notificationDialog.content = Localization.ForgotPassword.EmailConfirmed;
            this.notificationDialog.show();
        }
        else {
            response.message = result.ErrorMessage;
            response.hasError = true;
        }
        this.submitBtn.disabled = false;
        this.setState({ email: '', response });
    }

    onChange = (args) => {
        let value = args.target.value;
        this.setState({ email: value });
    }

    render() {
        const { email, errorMessage, response } = this.state;

        return (
            <div className="account-forgot-wrapper">
                <div className="account-forgot-container">
                    <div>
                        <img src={ILXLogo} alt="ILX Systems" />
                    </div>
                    <div className="title">
                        <h1>Glemt Adgangskode</h1>
                    </div>
                    {
                        response.hasError && 
                        <div className="error">
                            {response.message}
                        </div>
                    }
                    <div>
                        <div class="e-float-input e-control-wrapper e-valid-input">
                            <input type='text' name="Email" ref={ref => this.mailRef = ref} class="e-control e-textbox e-lib" role="textbox" id="textbox_0" 
                                onChange={this.onChange} aria-labelledby="label_textbox_0" value={email} autoComplete="off" />
                            <span class="e-float-line"></span>
                            <label class="e-float-text e-label-top" id="label_textbox_0" for="textbox_0">Mail*</label>
                        </div>
                        {
                            errorMessage && 
                            <div className="error"><small>{errorMessage}</small></div>
                        }
                    </div>
                    <div className="action-button">
                        <button ref={ref => this.submitBtn = ref} className="e-btn e-primary" disabled={!email} onClick={this.onClick}>Bekræft</button>
                    </div>
                </div>
                <DialogComponent isModal={true} buttons={this.buttons} width='auto' ref={dialog => this.notificationDialog = dialog} target='body' visible={false} 
                    showCloseIcon={false} cssClass="dialog-notification" animationSettings={this.animationSettings} />
            </div>
        )
    }
}

export default ForgotPassword
