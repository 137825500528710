import HttpClient from '../utilities/HttpClient';

const API = {    
    getHelpArticles: '/help',
    getHelpBySystem: '/help/bySystem/{system}',
    getByMenu: '/help/byMenu/{menuId}',
    getHelp: '/help/{id}',
    saveHelp: '/help',
    deleteHelp: '/help/{id}'
}

class HelpArticleService {
    async GetList(cancelToken) {
        return await HttpClient.Get(API.getHelpArticles, null, cancelToken);
    }

    async GetBySystem(system, cancelToken) {
        const url = API.getHelpBySystem.replace("{system}", system);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async GetByMenu(menuId, cancelToken) {
        const url = API.getByMenu.replace("{menuId}", menuId);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async Get(helpId, cancelToken) {
        const url = API.getHelp.replace("{id}", helpId);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async Save(data, cancelToken) {
        return await HttpClient.Post(API.saveHelp, data, cancelToken);
    }

    async Delete(helpId, cancelToken) {
        const url = API.deleteHelp.replace("{id}", helpId);
        return await HttpClient.Delete(url, null, cancelToken);
    }
}

const helpArticleService = new HelpArticleService();
export default helpArticleService;