import React, { Component } from 'react';
// import { UserManager } from 'oidc-client';
import { Redirect } from 'react-router-dom';

import AuthenticationService from '../services/AuthenticationService';

class SigninCallback extends Component {
    state = {
        loading: true
    }

    async componentDidMount() {
        await AuthenticationService.loginCallback().then(e => {
            this.setState({ loading: false });
        });
    }

    render() {
        const { loading } = this.state;
        if (loading) {
            return (
                <div>
                    Checking...
                </div>
            );
        }

        return <Redirect to="/beskeder" />
    }
}

export default SigninCallback;