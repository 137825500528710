import Axios from 'axios';
import AppSettings from '../settings/AppSettings';
import AuthenticationService from './AuthenticationService';
import { StorageKeys } from '../settings/StorageKeys';

const API = {
    forgotPassword: '/account/forgotpassword',
    resetPassword: '/account/resetpassword',
    changePassword: '/account/changepassword',
    autoResetPassword: '/account/autoresetpassword'
}

class AccountService {
    async ForgotPassword(param, cancelToken) {
        let config = {};
        let url = AppSettings.AUTH_URL + API.forgotPassword;

        if (cancelToken) {
            config.cancelToken = cancelToken;
        }
        return Axios.post(url, param, config)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            let errorMessage = '';

            if (error.response.data) {
                let errs = error.response.data.map(err => {
                    return err.Message || err.message;
                });
                errorMessage = errs.join('<br>');
            }
            else {
                errorMessage = error.response;
            }

            return {
                Success: false,
                HasError: true,
                ErrorMessage: errorMessage,
                ValidationResult: error.response.data ? error.response.data: ''
            }
        });
    }

    async ResetPassword(param, cancelToken) {
        let config = {};
        let url = AppSettings.AUTH_URL + API.resetPassword;

        if (cancelToken) {
            config.cancelToken = cancelToken;
        }
        return Axios.post(url, param, config)
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    let errorMessage = '';

                    if (error.response.data) {
                        let errs = error.response.data.map(err => {
                            return err.Message || err.message;
                        });
                        errorMessage = errs.join('<br>');
                    }
                    else {
                        errorMessage = error.response;
                    }

                    return {
                        Success: false,
                        HasError: true,
                        ErrorMessage: errorMessage,
                        ValidationResult: error.response.data ? error.response.data: ''
                    }
                });
    }

    async ChangePassword(param, cancelToken) {
        let config = {
            headers: {}
        };
        let url = AppSettings.AUTH_URL + API.changePassword;

        if (cancelToken) {
            config.cancelToken = cancelToken;
        }
        
        return await AuthenticationService.getUser().then(async user => {
            if (user !== null) {
                let token = user.access_token;
                try {
                    let loggedInAsToken = sessionStorage.getItem(StorageKeys.LoggedInAs_Key);
                    if (loggedInAsToken) {
                        token = loggedInAsToken;
                    }
                } catch (error) {
                    
                }
                config.headers.Authorization = `Bearer ${token}`;

                return Axios.post(url, param, config)
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    let errorMessage = '';

                    if (error.response.data) {
                        let errs = error.response.data.map(err => {
                            return err.Message || err.message;
                        });
                        errorMessage = errs.join('<br>');
                    }
                    else {
                        errorMessage = error.response;
                    }

                    return {
                        Success: false,
                        HasError: true,
                        ErrorMessage: errorMessage,
                        ValidationResult: error.response.data ? error.response.data: ''
                    }
                });
            }
            else {
                AuthenticationService.login();
            }
        });
    }

    async AutoResetPassword(param, cancelToken) {
        let config = {
            headers: {}
        };
        let url = AppSettings.AUTH_URL + API.autoResetPassword;

        if (cancelToken) {
            config.cancelToken = cancelToken;
        }
        
        return await AuthenticationService.getUser().then(async user => {
            if (user !== null) {
                let token = user.access_token;
                try {
                    let loggedInAsToken = sessionStorage.getItem(StorageKeys.LoggedInAs_Key);
                    if (loggedInAsToken) {
                        token = loggedInAsToken;
                    }
                } catch (error) {
                    
                }
                config.headers.Authorization = `Bearer ${token}`;

                return Axios.post(url, param, config)
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    let errorMessage = '';

                    if (error.response.data) {
                        let errs = error.response.data.map(err => {
                            return err.Message || err.message;
                        });
                        errorMessage = errs.join('<br>');
                    }
                    else {
                        errorMessage = error.response;
                    }

                    return {
                        Success: false,
                        HasError: true,
                        ErrorMessage: errorMessage,
                        ValidationResult: error.response.data ? error.response.data: ''
                    }
                });
            }
            else {
                AuthenticationService.login();
            }
        });
    }
}


const accountService = new AccountService();
export default accountService;