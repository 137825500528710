export function toDanishNumberFormat(amount) {
    return formatMoney('', amount, 0, ',', '.');
}

export function toDanishDecimalFormat(amount) {
    return formatMoney('', amount, 2, ',', '.');
}

export function toDanishCurrency(amount) {
    return formatMoney('Kr.', amount, 0, ',', '.');
}

export function arrayMove(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
};

export function ValidateEmail(mail) 
{
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail))
    {
        return true;
    }
    return false;
}

export function isImage(file) {
    const fileTypes = ["jpg", "jpeg", "png"];
    const s = file.split('.');
    return fileTypes.some(f => f === s[s.length - 1]);
}

export function getFileIcon(file) {
    let s = file.split('.');
    let icon = '';
    const video = [ "mp4", "mov", "wmv", "flv", "avi"];
    const excel = [ "xls", "xlsx", "ods" ];
    const doc = [ "doc", "docx" ];

    if (video.some(v => v === s[s.length - 1])) {
        icon = 'c-icon-file-video';
    }
    else if (excel.some(e => e === s[s.length - 1])) {
        icon = 'c-icon-file-excel';
    }
    else if (doc.some(d => d === s[s.length - 1])) {
        icon = 'c-icon-file-doc';
    }
    else if (s[s.length - 1] === "csv") {
        icon = 'c-icon-file-csv';
    }
    else if (s[s.length - 1] === "pdf") {
        icon = 'c-icon-file-pdf';
    }
    else if (s[s.length - 1] === "zip") {
        icon = 'c-icon-file-zip';
    }
    else if (s[s.length - 1] === "html") {
        icon = 'c-icon-file-html';
    }
    else {
        icon = 'c-icon-file-txt';
    }

    return icon;
}

export function formatMoney(currencySign, amount, decimalCount, decimal, thousands) {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return currencySign + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
}

export function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

function buildMenuStructure(menuId, items) {
    let result = [];
    if (items && items.length > 0) {

        items.forEach(i => {
            menuId = menuId + 1;

            let menuItem = {
                Id: menuId, 
                SourceId: i.Id,
                Name: i.Name, 
                expanded: true, 
                ItemType: "MenuItem",
                Active: i.Active, Url: i.Url, 
                Order: i.Order, MenuHeaderId: i.MenuHeaderId,
                VirtualMenuItems: []
            };

            let virtualMenuItems = [];
            if (i.Functions.length > 0) {
                const { lastIndex: lastFunctionId, VirtualMenuItems: functionVirtualMenuItems } = buildFunctionStructure(menuId, i.Functions);
                menuId = lastFunctionId;
                virtualMenuItems = virtualMenuItems.concat(functionVirtualMenuItems);
            }

            if (i.MenuTabs.length > 0) {
                menuId = menuId + 1
                const { lastIndex: lastTabId, VirtualMenuItems: tabVirtualMenuItems } = buildTabStructure(menuId, i.MenuTabs);
                menuId = lastTabId;
                virtualMenuItems = virtualMenuItems.concat(tabVirtualMenuItems);
            }

            menuItem.VirtualMenuItems = virtualMenuItems;
            result.push(menuItem);
        });
        
    }
    return { lastIndex: menuId, VirtualMenuItems: result };
}

function buildTabStructure(lastIndex, items) {
    let result = [];
    if (items && items.length > 0) {
        lastIndex = lastIndex + 1;
        result.push({
            Id: lastIndex, 
            Name: items.some(x => x.Name === "Ny") ? "Functions" : "Tabs", 
            expanded: true,
            ItemType: "Tab",
            VirtualMenuItems: items.map(tab => {
                lastIndex = lastIndex + 1;

                let tabItem = {
                    Id: lastIndex, 
                    SourceId: tab.Id,
                    Name: tab.Name, 
                    expanded: true, 
                    Url: tab.Url, Order: tab.Order, 
                    VirtualMenuItems: []
                };
                
                const { lastIndex: lastFunctionId, VirtualMenuItems } = buildFunctionStructure(lastIndex, tab.Functions);
                lastIndex = lastFunctionId;
                
                tabItem.VirtualMenuItems = VirtualMenuItems;
                return tabItem;
                // return {
                //     Id: tab.Id, 
                //     SourceId: tab.Id,
                //     Name: tab.Name, 
                //     // expanded: true, 
                //     Url: tab.Url, Order: tab.Order, 
                //     VirtualMenuItems
                // };
            })
        });
    }
    return { lastIndex, VirtualMenuItems: result};
}

function buildFunctionStructure(lastIndex, items) {
    let result = [];
    if (items && items.length > 0) {
        lastIndex = lastIndex + 1;
        result.push({ 
            Id: lastIndex, Name: "Functions", expanded: true, ItemType: "Function", VirtualMenuItems: items.map(i => { 
                lastIndex = lastIndex + 1;
                return { Id: lastIndex, Name: i, 
                    // expanded: true, 
                    VirtualMenuItems: [] }
            }) 
        });
    }
    return { lastIndex, VirtualMenuItems: result};
}


export function buildVirtualMenu(menu) {
    let result = [];
    let intMenus = [];
    let fmtMenus = [];

    let indexId = 0;

    menu.Intralogix.forEach(int => {
        indexId = indexId + 1;
        let intMenu = {
            Id: indexId, 
            SourceId: int.Id,
            Name: int.Name, 
            expanded: true, 
            Active: int.Active,
            Url: int.Url, 
            Order: int.Order,
            VirtualMenuItems: []
        }

        if (int.Functions.length > 0) {
            // indexId = indexId + 1;
            let { lastIndex, VirtualMenuItems } = buildFunctionStructure(indexId, int.Functions);
            indexId = lastIndex;
            intMenu.VirtualMenuItems = intMenu.VirtualMenuItems.concat(VirtualMenuItems);
        }

        if (int.MenuItems.length > 0) {
            let { lastIndex, VirtualMenuItems } = buildMenuStructure(indexId, int.MenuItems);
            indexId = lastIndex;
            intMenu.VirtualMenuItems = intMenu.VirtualMenuItems.concat(VirtualMenuItems);
        }

        if (int.MenuTabs.length > 0) {
            // indexId = indexId + 1;
            let { lastIndex, VirtualMenuItems } = buildTabStructure(indexId, int.MenuTabs);
            indexId = lastIndex;
            intMenu.VirtualMenuItems = intMenu.VirtualMenuItems.concat(VirtualMenuItems);
        }

        intMenus.push(intMenu);
    });

    menu.Flexmat.forEach(fmt => {
        indexId = indexId + 1;
        let fmtMenu = {
            Id: indexId, 
            SourceId: fmt.Id,
            Name: fmt.Name, 
            expanded: true, 
            Active: fmt.Active,
            Url: fmt.Url, 
            Order: fmt.Order,
            VirtualMenuItems: []
        };

        if (fmt.Functions.length > 0) {
            indexId = indexId + 1;
            let { lastIndex, VirtualMenuItems } = buildFunctionStructure(indexId, fmt.Functions);
            indexId = lastIndex;
            fmtMenu.VirtualMenuItems = fmtMenu.VirtualMenuItems.concat(VirtualMenuItems);
        }

        if (fmt.MenuItems.length > 0) {
            indexId = indexId + 1;
            let { lastIndex, VirtualMenuItems } = buildMenuStructure(indexId, fmt.MenuItems);
            indexId = lastIndex;
            fmtMenu.VirtualMenuItems = fmtMenu.VirtualMenuItems.concat(VirtualMenuItems);
        }

        if (fmt.MenuTabs.length > 0) {
            indexId = indexId + 1;
            let { lastIndex, VirtualMenuItems } = buildTabStructure(indexId, fmt.MenuTabs);
            indexId = lastIndex;
            fmtMenu.VirtualMenuItems = fmtMenu.VirtualMenuItems.concat(VirtualMenuItems);
        }

        fmtMenus.push(fmtMenu);
    });

    result = [
        { Id: -1, Name: "Intralogix Software", 
        expanded: true, 
        VirtualMenuItems: [ ...intMenus ] },
        { Id: -2, Name: "Flexmat Software", 
        expanded: true, 
        VirtualMenuItems: [ ...fmtMenus ] },
    ];

    return result;
}

function getFunctions(items, groupFunctions) {
    let ids = [];
    groupFunctions.forEach(f => {
        let s = items.find(x => x.Name == f);
        ids.push(s.Id.toString());
    });
    return ids;
}

function getTabs(tabsVirtualMenuItems, groupMenuTabs) {
    let ids = [];
    groupMenuTabs.forEach(f => {
        let tabVMI = tabsVirtualMenuItems.find(t => t.SourceId === f.Id)
        if (f.Functions.length <= 0) {
            ids.push(f.Id.toString());
        }
        else {
            let functionsVMI = tabVMI.VirtualMenuItems.find(x => x.ItemType === "Function");
            if (functionsVMI) {
                ids = ids.concat(getFunctions(functionsVMI.VirtualMenuItems, f.Functions));
                // ids.push(functionsVMI.Id.toString());
                // ids.push(f.Id.toString());
            }
        }
    });

    return ids;
}

export function getCheckedMenuNodes(menu, groupMenu) {
    if (menu.length <= 0) return [];
    let selectedNodes = [];
    let flexmatNodes = [];
    let intralogixNodes = [];
    let intralogixMenu = menu[0].VirtualMenuItems;
    let flexmatMenu = menu[1].VirtualMenuItems;

    groupMenu.Intralogix.forEach(int => {
        let currMenu = intralogixMenu.find(m => m.Name === int.Name);
        if (currMenu) {
            if (currMenu.VirtualMenuItems.length <= 0) {
                intralogixNodes.push(currMenu.Id.toString());
            }
            else {
                if (int.Functions.length > 0) {
                    let functions = currMenu.VirtualMenuItems.find(x => x.ItemType === "Function");
                    if (functions.VirtualMenuItems.length > 0) {
                        intralogixNodes = intralogixNodes.concat(getFunctions(functions.VirtualMenuItems, int.Functions));
                        intralogixNodes.push(functions.Id.toString());
                    }
                }
                
                if (int.MenuTabs.length > 0) {
                    let tabs = currMenu.VirtualMenuItems.find(x => x.ItemType === "Tab");
                    if (tabs.VirtualMenuItems.length > 0) {
                        intralogixNodes = intralogixNodes.concat(getTabs(tabs.VirtualMenuItems, int.MenuTabs));
                    }
                    // intralogixNodes.push(tabs.Id.toString());
                }
    
                if (int.MenuItems.length > 0) {
                    let menuItems = currMenu.VirtualMenuItems.filter(x => x.ItemType !== "Tab" && x.ItemType !== "Function");
                    int.MenuItems.forEach(mi => {
                        let subMenuItems = menuItems.find(x => x.Name === mi.Name);
                        if (subMenuItems) {
                            if (subMenuItems.VirtualMenuItems <= 0) {
                                intralogixNodes.push(subMenuItems.Id.toString());
                            }
                            else {
                                let menuItemFunctions = subMenuItems.VirtualMenuItems.find(m => m.ItemType === "Function");
                                if (menuItemFunctions) {
                                    intralogixNodes = intralogixNodes.concat(getFunctions(menuItemFunctions.VirtualMenuItems, mi.Functions));
                                    if (menuItemFunctions.VirtualMenuItems.length > 0) {
                                        intralogixNodes.push(menuItemFunctions.Id.toString());
                                    }
                                }
            
                                let menuItemTabs = subMenuItems.VirtualMenuItems.find(m => m.ItemType === "Tab");
                                if (menuItemTabs) {
                                    intralogixNodes = intralogixNodes.concat(getTabs(menuItemTabs.VirtualMenuItems, mi.MenuTabs));
                                    // intralogixNodes.push(menuItemTabs.Id.toString());
                                }

                                // if (menuItemFunctions || menuItemTabs) {
                                //     intralogixNodes.push(subMenuItems.Id.toString());
                                // }
                            }
                        }
                    });
                }

                // if (int.Functions.length > 0 || int.MenuTabs.length > 0 || int.MenuItems.length > 0) {
                //     intralogixNodes.push(currMenu.Id.toString());
                // }
            }
        }
    });

    if (intralogixNodes.length > 0) {
        intralogixNodes.forEach(s => parseInt(s));
        intralogixNodes.sort((a, b) => a - b);
        intralogixNodes.forEach(s => s.toString());
        selectedNodes = selectedNodes.concat(intralogixNodes);
        // selectedNodes.push("-1"); //Intralogix
    }

    groupMenu.Flexmat.forEach(fmt => {
        let currMenu = flexmatMenu.find(m => m.Name === fmt.Name);
        if (currMenu) {
            if (currMenu.VirtualMenuItems.length <= 0) {
                flexmatNodes.push(currMenu.Id.toString());
            }
            else {
                if (fmt.Functions.length > 0) {
                    let functions = currMenu.VirtualMenuItems.find(x => x.ItemType === "Function");
                    if (functions.VirtualMenuItems.length > 0) {
                        flexmatNodes = flexmatNodes.concat(getFunctions(functions.VirtualMenuItems, fmt.Functions));
                        flexmatNodes.push(functions.Id.toString());
                    }
                }
                
                if (fmt.MenuTabs.length > 0) {
                    let tabs = currMenu.VirtualMenuItems.find(x => x.ItemType === "Tab");
                    if (tabs.VirtualMenuItems.length > 0) {
                        flexmatNodes = flexmatNodes.concat(getTabs(tabs.VirtualMenuItems, fmt.MenuTabs));
                    }
                    // flexmatNodes.push(tabs.Id.toString());
                }
    
                if (fmt.MenuItems.length > 0) {
                    let menuItems = currMenu.VirtualMenuItems.filter(x => x.ItemType !== "Tab" && x.ItemType !== "Function");
                    fmt.MenuItems.forEach(mi => {
                        let subMenuItems = menuItems.find(x => x.Name === mi.Name);
                        if (subMenuItems) {
                            if (subMenuItems.VirtualMenuItems <= 0) {
                                flexmatNodes.push(subMenuItems.Id.toString());
                            }
                            else {
                                let menuItemFunctions = subMenuItems.VirtualMenuItems.find(m => m.ItemType === "Function");
                                if (menuItemFunctions) {
                                    flexmatNodes = flexmatNodes.concat(getFunctions(menuItemFunctions.VirtualMenuItems, mi.Functions));
                                    if (menuItemFunctions.VirtualMenuItems.length > 0) {
                                        flexmatNodes.push(menuItemFunctions.Id.toString());
                                    }
                                }
            
                                let menuItemTabs = subMenuItems.VirtualMenuItems.find(m => m.ItemType === "Tab");
                                if (menuItemTabs) {
                                    flexmatNodes = flexmatNodes.concat(getTabs(menuItemTabs.VirtualMenuItems, mi.MenuTabs));
                                    // flexmatNodes.push(menuItemTabs.Id.toString());
                                }

                                // if (menuItemFunctions || menuItemTabs) {
                                //     flexmatNodes.push(subMenuItems.Id.toString());
                                // }
                            }
                        }
                    });
                }

                // if (fmt.Functions.length > 0 || fmt.MenuTabs.length > 0 || fmt.MenuItems.length > 0) {
                //     flexmatNodes.push(currMenu.Id.toString());
                // }
            }
        }
    });
    
    if (flexmatNodes.length > 0) {
        flexmatNodes.forEach(s => parseInt(s));
        flexmatNodes.sort((a, b) => a - b);
        flexmatNodes.forEach(s => s.toString());
        selectedNodes = selectedNodes.concat(flexmatNodes);
        // selectedNodes.push("-2"); //Flexmat
    }

    return selectedNodes;
}

export function buildCheckedNodes(menu, groupMenu) {
    if (menu.length <= 0) return [];
        
    // let intralogixMenu = menu[0].VirtualMenuItems;
    // let flexmatMenu = menu[1].VirtualMenuItems;

    menu.forEach(main => {
        let groupMI = main.Id === -1 ? groupMenu.Intralogix : groupMenu.Flexmat;

        if (main.VirtualMenuItems.length > 0) {
            main.VirtualMenuItems.forEach(vmi => {
                let isTools = vmi.Name === "Værktøjer";

                if (groupMI.some(g => g.Name === vmi.Name) && vmi.VirtualMenuItems.length <= 0) {
                    vmi.isChecked = true;
                }
                else if(groupMI.some(g => g.Name === vmi.Name) && vmi.VirtualMenuItems.length > 0) {
                    let cg = groupMI.find(g => g.Name === vmi.Name);

                    if (cg.Functions && cg.Functions.length > 0) {
                        let functions = vmi.VirtualMenuItems.find(x => x.ItemType === "Function");
                        // vmi.VirtualMenuItems = vmi.VirtualMenuItems.concat(buildCheckedNodesFunction(functions.VirtualMenuItems, cg.Functions));
                        if (functions) {
                            buildCheckedNodesFunction(functions.VirtualMenuItems, cg.Functions);
                        }
                    }
                    else {
                        let functions = vmi.VirtualMenuItems.find(x => x.ItemType === "Function");
                        if (functions) {
                            uncheckItems(functions.VirtualMenuItems);
                        }
                    }

                    if (cg.MenuItems && cg.MenuItems.length > 0) {
                        let menuItems = vmi.VirtualMenuItems.filter(x => x.ItemType !== "Function" && x.ItemType !== "Tab");
                        // vmi.VirtualMenuItems = vmi.VirtualMenuItems.concat(buildCheckedNodesMenuItem(menuItems, cg.MenuItems));
                        buildCheckedNodesMenuItem(menuItems, cg.MenuItems, isTools)
                    }

                    if (cg.MenuTabs && cg.MenuTabs.length > 0) {
                        let tabs = vmi.VirtualMenuItems.find(x => x.ItemType === "Tab");
                        // vmi.VirtualMenuItems = vmi.VirtualMenuItems.concat(buildCheckedNodesTab(tabs.VirtualMenuItems, cg.MenuTabs));
                        if (tabs) {
                            buildCheckedNodesTab(tabs.VirtualMenuItems, cg.MenuTabs, cg.Name === "Automater");
                        }
                    }
                    else {
                        let tabs = vmi.VirtualMenuItems.find(x => x.ItemType === "Tab");
                        if (tabs) {
                            uncheckItems(tabs.VirtualMenuItems);
                        }
                    }

                }
                else {
                    vmi.isChecked = false;
                }
            });
        }
        else {
            main.isChecked = true;
        }
    });

    return menu;
}

function buildCheckedNodesFunction(menu, groupMenu) {
    menu.forEach(m => {
        if (groupMenu.some(f => f === m.Name)) {
            m.isChecked = true;
        }
        else {
            m.isChecked = false;
        }
    });

    // return menu;
}

function buildCheckedNodesTab(menu, groupMenu, isTools = false) {
    menu.forEach(m => {
        if (m.VirtualMenuItems.length > 0) {
            let cg = groupMenu.find(g => g.Name === m.Name);
            if (!cg) { 
                m.isChecked = false;
                if (m.VirtualMenuItems.length > 0) {
                    uncheckItems(m.VirtualMenuItems);
                }
                return;
            }

            if (cg.Functions && cg.Functions.length > 0) {
                // m.VirtualMenuItems = buildCheckedNodesFunction(m.VirtualMenuItems[0].VirtualMenuItems, cg.Functions);
                buildCheckedNodesFunction(m.VirtualMenuItems[0].VirtualMenuItems, cg.Functions)
            }

            if (cg.Functions && cg.Functions.length <= 0) {
                m.isChecked = false;
            }
        }
        else if (isTools) {
            let cg = groupMenu.find(g => g.Name === m.Name);
            if (cg) {
                m.isChecked = true;
            }
            else {
                m.isChecked = false;
            }
        }
        else {
            m.isChecked = true;
        }
    });

    // return menu;
}

function uncheckItems(list) {
    list.forEach(l => {
        if (l.VirtualMenuItems.length > 0) {
            uncheckItems(l.VirtualMenuItems);
        }
        else {
            l.isChecked = false;
        }
    });
}

function buildCheckedNodesMenuItem(menu, groupMenu, isTools = false) {
    menu.forEach(m => {
        if (m.VirtualMenuItems.length > 0) {
            let cg = groupMenu.find(g => g.Name === m.Name);
            if (!cg) {
                m.isChecked = false;
                if (m.VirtualMenuItems.length > 0) {
                    uncheckItems(m.VirtualMenuItems);
                }
                
                return;
            }

            if (cg.MenuTabs && cg.MenuTabs.length > 0) {
                let t = m.VirtualMenuItems.find(n => n.ItemType === "Tab");
                isTools = isTools || cg.Name === "Grupper" || cg.Name === "OilMat";
                buildCheckedNodesTab(t.VirtualMenuItems, cg.MenuTabs, isTools);
            }

            if (cg.Functions && cg.Functions.length > 0) {
                let f = m.VirtualMenuItems.find(n => n.ItemType === "Function");
                buildCheckedNodesFunction(f.VirtualMenuItems, cg.Functions);
            }

            if ((cg.MenuTabs && cg.MenuTabs.length <= 0) && (cg.MenuTabs && cg.MenuTabs.length <= 0)) {
                m.isChecked = false;
            }
        }
        else {
            if (groupMenu.some(x => x.Name === m.Name)) {
                m.isChecked = true;
            }
            else {
                m.isChecked = false;
            }
        }
    });

    // return menu;
}

export function buildMenuFromCheckedNodes(menu, checkedNodes) {
    let result = {
        Intralogix: [],
        Flexmat: []
    }
    
    let intralogixMenu = menu[0].VirtualMenuItems;
    let flexmatMenu = menu[1].VirtualMenuItems;

    intralogixMenu.forEach(int => {
        let isTools = int.Name === "Værktøjer";

        if (checkedNodes.some(c => c === int.Id.toString())) {
            let module = {
                Active: int.Active,
                Id: int.SourceId,
                Order: 1,
                Name: int.Name,
                Functions: [],
                MenuTabs: [],
                MenuItems: [],
                Url: int.Url
            }

            let vMenuItems = int.VirtualMenuItems.filter(x => x.ItemType !== 'Function' && x.ItemType !== 'Tab');
            let vFunctions = int.VirtualMenuItems.filter(x => x.ItemType === 'Function');
            let vTabs = int.VirtualMenuItems.filter(x => x.ItemType === 'Tab');
            
            if (vFunctions.length > 0) {
                vFunctions.forEach(f => 
                    f.VirtualMenuItems.forEach(v => {
                        if (checkedNodes.some(c => c === v.Id.toString())) {
                            module.Functions.push(v.Name);
                        }
                    })
                );
            }

            if (vTabs.length > 0) {
                vTabs.forEach(t => {
                    t.VirtualMenuItems.forEach(x => {
                        let tabFunctions = x.VirtualMenuItems.find(x => x.ItemType === "Function");
                        let addFunction = [];
                        if (tabFunctions) {
                            tabFunctions.VirtualMenuItems.forEach(f => {
                                if (checkedNodes.some(c => c === f.Id.toString())) {
                                    addFunction.push(f.Name);
                                }
                            });

                            if (addFunction.length > 0) {
                                module.MenuTabs.push({ 
                                    Id: x.SourceId, Name: x.Name, Order: x.Order, Url: x.Url,
                                    Functions: addFunction
                                });
                            }
                        }
                        else if (int.Name === "Automater") {
                            if (checkedNodes.some(c => c === x.Id.toString())) {
                                module.MenuTabs.push({
                                    Functions: [],
                                    Id: x.SourceId,
                                    Name: x.Name,
                                    Order: x.Order,
                                    Url: x.Url
                                });
                            }
                        }
                    });
                });
            }

            if (vMenuItems.length > 0) {
                vMenuItems.forEach(mi => {
                    if (checkedNodes.some(c => c === mi.Id.toString())) {
                        let miModule = {
                            Active: mi.Active,
                            Id: mi.SourceId,
                            Name: mi.Name,
                            Url: mi.Url,
                            Order: mi.Order,
                            MenuHeaderId: mi.MenuHeaderId,
                            Functions: [],
                            MenuTabs: []
                        };
    
                        let miFunctions = mi.VirtualMenuItems.find(x => x.ItemType === "Function");
                        let miTabs = mi.VirtualMenuItems.find(x => x.ItemType === "Tab");
    
                        if (miFunctions) {
                            let addFunction = [];
                            miFunctions.VirtualMenuItems.forEach(f => {
                                if (checkedNodes.some(c => c === f.Id.toString())) {
                                    addFunction.push(f.Name);
                                }
                            });
                            miModule.Functions = miModule.Functions.concat(addFunction);
                        }
    
                        if (miTabs) {
                            miTabs.VirtualMenuItems.forEach(tab => {
                                if (isTools || mi.Name === "Grupper" || mi.Name === "OilMat") {
                                    if (checkedNodes.some(c => c === tab.Id.toString())) {
                                        miModule.MenuTabs.push({
                                            Functions: [],
                                            Id: tab.SourceId,
                                            Name: tab.Name,
                                            Order: tab.Order,
                                            Url: tab.Url
                                        });
                                    }
                                }
                                else {
                                    let tabFunctions = tab.VirtualMenuItems.find(tf => tf.ItemType === "Function");
                                    let addFunction = [];
                                    if (tabFunctions) {
                                        tabFunctions.VirtualMenuItems.forEach(f => {
                                            if (checkedNodes.some(c => c === f.Id.toString())) {
                                                addFunction.push(f.Name);
                                            }
                                        });

                                        if (addFunction.length > 0) {
                                            miModule.MenuTabs.push({
                                                Functions: addFunction,
                                                Id: tab.SourceId,
                                                Name: tab.Name,
                                                Order: tab.Order,
                                                Url: tab.Url
                                            });
                                        }
                                    }
                                }
                            });
                        }
    
                        module.MenuItems.push(miModule);
                    }
                });
            }

            result.Intralogix.push(module);
        }
        else {
            let vMenuItems = int.VirtualMenuItems.filter(x => x.ItemType !== 'Function' && x.ItemType !== 'Tab');
            let vFunctions = int.VirtualMenuItems.filter(x => x.ItemType === 'Function');
            let vTabs = int.VirtualMenuItems.filter(x => x.ItemType === 'Tab');

            let module = {
                Active: int.Active,
                Id: int.SourceId,
                Order: 1,
                Name: int.Name,
                Functions: [],
                MenuTabs: [],
                MenuItems: [],
                Url: int.Url
            };

            
            if (vFunctions.length > 0) {
                vFunctions.forEach(f => 
                    f.VirtualMenuItems.forEach(v => {
                        if (checkedNodes.some(c => c === v.Id.toString())) {
                            module.Functions.push(v.Name);
                        }
                    })
                );
            }

            
            if (vTabs.length > 0) {
                vTabs.forEach(t => {
                    t.VirtualMenuItems.forEach(x => {
                        let tabFunctions = x.VirtualMenuItems.find(x => x.ItemType === "Function");
                        let addFunction = [];

                        if (tabFunctions) {
                            tabFunctions.VirtualMenuItems.forEach(f => {
                                if (checkedNodes.some(c => c === f.Id.toString())) {
                                    addFunction.push(f.Name);
                                }
                            });

                            if (addFunction.length > 0) {
                                module.MenuTabs.push({ 
                                    Id: x.SourceId, Name: x.Name, Order: x.Order, Url: x.Url,
                                    Functions: addFunction
                                });
                            }
                        }
                        else if (int.Name === "Automater") {
                            if (checkedNodes.some(c => c === x.Id.toString())) {
                                module.MenuTabs.push({
                                    Functions: [],
                                    Id: x.SourceId,
                                    Name: x.Name,
                                    Order: x.Order,
                                    Url: x.Url
                                });
                            }
                        }
                    });
                });
            }

            if (vMenuItems.length > 0) {
                vMenuItems.forEach(mi => {
                    let miModule = {
                        Active: mi.Active,
                        Id: mi.SourceId,
                        Name: mi.Name,
                        Url: mi.Url,
                        Order: mi.Order,
                        MenuHeaderId: mi.MenuHeaderId,
                        Functions: [],
                        MenuTabs: []
                    };

                    if (checkedNodes.some(c => c === mi.Id.toString())) {    
                        let miFunctions = mi.VirtualMenuItems.find(x => x.ItemType === "Function");
                        let miTabs = mi.VirtualMenuItems.find(x => x.ItemType === "Tab");
    
                        if (miFunctions) {
                            let addFunction = [];
                            miFunctions.VirtualMenuItems.forEach(f => {
                                if (checkedNodes.some(c => c === f.Id.toString())) {
                                    addFunction.push(f.Name);
                                }
                            });
                            miModule.Functions = miModule.Functions.concat(addFunction);
                        }
    
                        if (miTabs) {
                            miTabs.VirtualMenuItems.forEach(tab => {
                                if (isTools || mi.Name === "Grupper" || mi.Name === "OilMat") {
                                    if (checkedNodes.some(c => c === tab.Id.toString())) {
                                        miModule.MenuTabs.push({
                                            Functions: [],
                                            Id: tab.SourceId,
                                            Name: tab.Name,
                                            Order: tab.Order,
                                            Url: tab.Url
                                        });
                                    }
                                }
                                else {
                                    let tabFunctions = tab.VirtualMenuItems.find(tf => tf.ItemType === "Function");
                                    let addFunction = [];
    
                                    if (tabFunctions) {
                                        tabFunctions.VirtualMenuItems.forEach(f => {
                                            if (checkedNodes.some(c => c === f.Id.toString())) {
                                                addFunction.push(f.Name);
                                            }
                                        });
                                        
                                        if (addFunction.length > 0) {
                                            miModule.MenuTabs.push({
                                                Functions: addFunction,
                                                Id: tab.SourceId,
                                                Name: tab.Name,
                                                Order: tab.Order,
                                                Url: tab.Url
                                            });
                                        }
                                    }
                                }
                            });
                        }

                        module.MenuItems.push(miModule);
                    }
                    else {
                        let miFunctions = mi.VirtualMenuItems.find(x => x.ItemType === "Function");
                        let miTabs = mi.VirtualMenuItems.find(x => x.ItemType === "Tab");
                        
                        if (miFunctions) {
                            let addFunction = [];
                            miFunctions.VirtualMenuItems.forEach(f => {
                                if (checkedNodes.some(c => c === f.Id.toString())) {
                                    addFunction.push(f.Name);
                                }
                            });
                            miModule.Functions = miModule.Functions.concat(addFunction);
                        }
    
                        if (miTabs) {
                            miTabs.VirtualMenuItems.forEach(tab => {
                                if (isTools || mi.Name === "Grupper" || mi.Name === "OilMat") {
                                    if (checkedNodes.some(c => c === tab.Id.toString())) {
                                        miModule.MenuTabs.push({
                                            Functions: [],
                                            Id: tab.SourceId,
                                            Name: tab.Name,
                                            Order: tab.Order,
                                            Url: tab.Url
                                        });
                                    }
                                }
                                else {
                                    let tabFunctions = tab.VirtualMenuItems.find(tf => tf.ItemType === "Function");
                                    let addFunction = [];
                                    if (tabFunctions) {
                                        tabFunctions.VirtualMenuItems.forEach(f => {
                                            if (checkedNodes.some(c => c === f.Id.toString())) {
                                                addFunction.push(f.Name);
                                            }
                                        });
    
                                        if (addFunction.length > 0) {
                                            miModule.MenuTabs.push({
                                                Functions: addFunction,
                                                Id: tab.SourceId,
                                                Name: tab.Name,
                                                Order: tab.Order,
                                                Url: tab.Url
                                            });
                                        }
                                    }
                                }
                            });
                        }
                        
                        if (miModule.Functions.length > 0 || miModule.MenuTabs.length > 0) {
                            module.MenuItems.push(miModule);
                        }
                    }
                });
            }

            if (module.Functions.length > 0 || module.MenuTabs.length > 0 || module.MenuItems.length > 0) {
                result.Intralogix.push(module);
            }
        }
    });
    
    flexmatMenu.forEach(int => {
        if (checkedNodes.some(c => c === int.Id.toString())) {
            let module = {
                Active: int.Active,
                Id: int.SourceId,
                Order: 1,
                Name: int.Name,
                Functions: [],
                MenuTabs: [],
                MenuItems: [],
                Url: int.Url
            }

            let vMenuItems = int.VirtualMenuItems.filter(x => x.ItemType !== 'Function' && x.ItemType !== 'Tab');
            let vFunctions = int.VirtualMenuItems.filter(x => x.ItemType === 'Function');
            let vTabs = int.VirtualMenuItems.filter(x => x.ItemType === 'Tab');
            
            if (vFunctions.length > 0) {
                vFunctions.forEach(f => 
                    f.VirtualMenuItems.forEach(v => {
                        if (checkedNodes.some(c => c === v.Id.toString())) {
                            module.Functions.push(v.Name);
                        }
                    })
                );
            }

            if (vTabs.length > 0) {
                vTabs.forEach(t => {
                    t.VirtualMenuItems.forEach(x => {                        
                        let tabFunctions = x.VirtualMenuItems.find(x => x.ItemType === "Function");
                        let addFunction = [];
                        if (tabFunctions) {
                            tabFunctions.VirtualMenuItems.forEach(f => {
                                if (checkedNodes.some(c => c === f.Id.toString())) {
                                    addFunction.push(f.Name);
                                }
                            });

                            if (addFunction.length > 0) {
                                module.MenuTabs.push({ 
                                    Id: x.SourceId, Name: x.Name, Order: x.Order, Url: x.Url,
                                    Functions: addFunction
                                });
                            }
                        }
                    });
                });
            }

            if (vMenuItems.length > 0) {
                vMenuItems.forEach(mi => {
                    if (checkedNodes.some(c => c === mi.Id.toString())) {
                        let miModule = {
                            Active: mi.Active,
                            Id: mi.SourceId,
                            Name: mi.Name,
                            Url: mi.Url,
                            Order: mi.Order,
                            MenuHeaderId: mi.MenuHeaderId,
                            Functions: [],
                            MenuTabs: []
                        };
    
                        let miFunctions = mi.VirtualMenuItems.find(x => x.ItemType === "Function");
                        let miTabs = mi.VirtualMenuItems.find(x => x.ItemType === "Tab");
    
                        if (miFunctions) {
                            let addFunction = [];
                            miFunctions.VirtualMenuItems.forEach(f => {
                                if (checkedNodes.some(c => c === f.Id.toString())) {
                                    addFunction.push(f.Name);
                                }
                            });
                            miModule.Functions = miModule.Functions.concat(addFunction);
                        }
    
                        if (miTabs) {
                            miTabs.VirtualMenuItems.forEach(tab => {
                                let tabFunctions = tab.VirtualMenuItems.find(tf => tf.ItemType === "Function");
                                let addFunction = [];
                                if (tabFunctions) {
                                    tabFunctions.VirtualMenuItems.forEach(f => {
                                        if (checkedNodes.some(c => c === f.Id.toString())) {
                                            addFunction.push(f.Name);
                                        }
                                    });

                                    if (addFunction.length > 0) {
                                        miModule.MenuTabs.push({
                                            Functions: addFunction,
                                            Id: tab.SourceId,
                                            Name: tab.Name,
                                            Order: tab.Order,
                                            Url: tab.Url
                                        });
                                    }
                                }
                            });
                        }
    
                        module.MenuItems.push(miModule);
                    }
                });
            }

            result.Flexmat.push(module);
        }
        else {
            let vMenuItems = int.VirtualMenuItems.filter(x => x.ItemType !== 'Function' && x.ItemType !== 'Tab');
            let vFunctions = int.VirtualMenuItems.filter(x => x.ItemType === 'Function');
            let vTabs = int.VirtualMenuItems.filter(x => x.ItemType === 'Tab');

            let module = {
                Active: int.Active,
                Id: int.SourceId,
                Order: 1,
                Name: int.Name,
                Functions: [],
                MenuTabs: [],
                MenuItems: [],
                Url: int.Url
            };

            
            if (vFunctions.length > 0) {
                vFunctions.forEach(f => 
                    f.VirtualMenuItems.forEach(v => {
                        if (checkedNodes.some(c => c === v.Id.toString())) {
                            module.Functions.push(v.Name);
                        }
                    })
                );
            }

            
            if (vTabs.length > 0) {
                vTabs.forEach(t => {
                    t.VirtualMenuItems.forEach(x => {
                        let tabFunctions = x.VirtualMenuItems.find(x => x.ItemType === "Function");
                        let addFunction = [];
                        if (tabFunctions) {
                            tabFunctions.VirtualMenuItems.forEach(f => {
                                if (checkedNodes.some(c => c === f.Id.toString())) {
                                    addFunction.push(f.Name);
                                }
                            });

                            if (addFunction.length > 0) {
                                module.MenuTabs.push({ 
                                    Id: x.SourceId, Name: x.Name, Order: x.Order, Url: x.Url,
                                    Functions: addFunction
                                });
                            }
                        }
                    });
                });
            }

            if (vMenuItems.length > 0) {
                vMenuItems.forEach(mi => {
                    let miModule = {
                        Active: mi.Active,
                        Id: mi.SourceId,
                        Name: mi.Name,
                        Url: mi.Url,
                        Order: mi.Order,
                        MenuHeaderId: mi.MenuHeaderId,
                        Functions: [],
                        MenuTabs: []
                    };

                    if (checkedNodes.some(c => c === mi.Id.toString())) {    
                        let miFunctions = mi.VirtualMenuItems.find(x => x.ItemType === "Function");
                        let miTabs = mi.VirtualMenuItems.find(x => x.ItemType === "Tab");
    
                        if (miFunctions) {
                            let addFunction = [];
                            miFunctions.VirtualMenuItems.forEach(f => {
                                if (checkedNodes.some(c => c === f.Id.toString())) {
                                    addFunction.push(f.Name);
                                }
                            });
                            miModule.Functions = miModule.Functions.concat(addFunction);
                        }
    
                        if (miTabs) {
                            miTabs.VirtualMenuItems.forEach(tab => {
                                let tabFunctions = tab.VirtualMenuItems.find(tf => tf.ItemType === "Function");
                                let addFunction = [];
                                if (tabFunctions) {
                                    tabFunctions.VirtualMenuItems.forEach(f => {
                                        if (checkedNodes.some(c => c === f.Id.toString())) {
                                            addFunction.push(f.Name);
                                        }
                                    });

                                    if (addFunction.length > 0) {
                                        miModule.MenuTabs.push({
                                            Functions: addFunction,
                                            Id: tab.SourceId,
                                            Name: tab.Name,
                                            Order: tab.Order,
                                            Url: tab.Url
                                        });
                                    }
                                }
                            });
                        }

                        module.MenuItems.push(miModule);
                    }
                    else {
                        let miFunctions = mi.VirtualMenuItems.find(x => x.ItemType === "Function");
                        let miTabs = mi.VirtualMenuItems.find(x => x.ItemType === "Tab");
                        
                        if (miFunctions) {
                            let addFunction = [];
                            miFunctions.VirtualMenuItems.forEach(f => {
                                if (checkedNodes.some(c => c === f.Id.toString())) {
                                    addFunction.push(f.Name);
                                }
                            });
                            miModule.Functions = miModule.Functions.concat(addFunction);
                        }
    
                        if (miTabs) {
                            miTabs.VirtualMenuItems.forEach(tab => {
                                let tabFunctions = tab.VirtualMenuItems.find(tf => tf.ItemType === "Function");
                                let addFunction = [];
                                if (tabFunctions) {
                                    tabFunctions.VirtualMenuItems.forEach(f => {
                                        if (checkedNodes.some(c => c === f.Id.toString())) {
                                            addFunction.push(f.Name);
                                        }
                                    });

                                    if (addFunction.length > 0) {
                                        miModule.MenuTabs.push({
                                            Functions: addFunction,
                                            Id: tab.SourceId,
                                            Name: tab.Name,
                                            Order: tab.Order,
                                            Url: tab.Url
                                        });
                                    }
                                }
                            });
                        }
                        
                        if (miModule.Functions.length > 0 || miModule.MenuTabs.length > 0) {
                            module.MenuItems.push(miModule);
                        }
                    }
                });
            }

            if (module.Functions.length > 0 || module.MenuTabs.length > 0 || module.MenuItems.length > 0) {
                result.Flexmat.push(module);
            }
        }
    });

    return result;
}

export function buildGridSettings(args, gridSettings, pageSize, totalRecordsCount, hasDraggedColumn, columns) {
    if (args.requestType === 'paging') {
        let tempSettings = {
            columns: [],
            colName: '',
            direction: '',
            filters: [],
            pageSize: null
        };
        let settings = {};

        if (gridSettings) {
            settings = JSON.parse(gridSettings);
            tempSettings.columns = settings.columns && settings.columns.length > 0 ? settings.columns : [];
            tempSettings.colName = settings.colName;
            tempSettings.direction = settings.direction;
            tempSettings.filters = settings.filters && settings.filters.length > 0 ? settings.filters : [];
        }

        tempSettings.pageSize = pageSize;
        if (pageSize === totalRecordsCount) {
            tempSettings.pageSize = "Alle";
        }

        gridSettings = JSON.stringify(tempSettings);
    }
    else if (args.requestType === 'filtering') {
        let tempSettings = {
            columns: [],
            colName: '',
            direction: '',
            filters: [],
            pageSize: null
        };
        let settings = {};

        if (gridSettings) {
            settings = JSON.parse(gridSettings);
            tempSettings.columns = settings.columns && settings.columns.length > 0 ? settings.columns : [];

            tempSettings.colName = settings.colName;
            tempSettings.direction = settings.direction;
            if (pageSize && totalRecordsCount) {
                tempSettings.pageSize = pageSize === totalRecordsCount ? "Alle" : pageSize;
            }
            else {
                tempSettings.pageSize = settings.pageSize;
            }
        }

        if (args.action === 'clearFilter') {
            tempSettings.filters = settings && settings.filters && settings.filters.length > 0 ? settings.filters.filter(x => x.field !== args.currentFilterObject.field) : [];
        } else if (args.columns && args.columns.length > 0) {
            args.columns.forEach(col => {
                tempSettings.filters.push({
                    field: col.field,
                    operator: col.operator,
                    value: col.value
                });
            });
        }

        gridSettings = JSON.stringify(tempSettings);
    }
    else if (args.requestType === "sorting") {
        let tempSettings = {
            columns: [],
            colName: '',
            direction: '',
            filters: [],
            pageSize: null
        };
        let settings = {};

        if (gridSettings) {
            settings = JSON.parse(gridSettings);
            tempSettings.columns = settings.columns && settings.columns.length > 0 ? settings.columns : [];
            tempSettings.filters = settings.filters && settings.filters.length > 0 ? settings.filters : [];
            tempSettings.pageSize = settings.pageSize ? settings.pageSize : null;
            tempSettings.colName = args.columnName;
            tempSettings.direction = args.direction;
        }

        gridSettings = JSON.stringify(tempSettings);
    }
    else if (args.requestType === "reorder" && hasDraggedColumn) {
        let tempSettings = {
            columns: [],
            colName: '',
            direction: '',
            filters: [],
            pageSize: null
        };
        let tempCols = [], orderedColumns = [], settings = {};

        if (gridSettings) {
            settings = JSON.parse(gridSettings);
            orderedColumns = settings.columns ? settings.columns : [];
            tempSettings.filters = settings.filters && settings.filters.length > 0 ? settings.filters : [];
            tempSettings.colName = settings.colName;
            tempSettings.direction = settings.direction;
            tempSettings.pageSize = settings.pageSize ? settings.pageSize : null;
        }

        columns.forEach((column, index) => {
            if (index === args.fromIndex) {
                tempCols.push({
                    HeaderText: column.headerText,
                    Field: column.field,
                    FromIndex: column.index,
                    ToIndex: index,
                    Width: column.width
                });
            }
            else {
                if (orderedColumns.some(x => x.HeaderText === column.headerText)) { //Check if previously saved column has been reordered
                    let col = orderedColumns.find(x => x.HeaderText === column.headerText)
                    tempCols.push({
                        HeaderText: col.HeaderText,
                        Field: col.Field,
                        FromIndex: col.FromIndex,
                        ToIndex: index,
                        Width: col.Width
                    });
                }
            }
        });

        tempSettings.columns = tempCols;
        gridSettings = JSON.stringify(tempSettings);
    }
    else if (args.requestType === "resizingGrid") {
        let tempSettings = {
            columns: [],
            colName: '',
            direction: '',
            filters: [],
            pageSize: null
        };
        let settings = {};

        if (gridSettings) {
            settings = JSON.parse(gridSettings);
            tempSettings.columns = settings.columns && settings.columns.length > 0 ? settings.columns : [];
            tempSettings.colName = settings.colName;
            tempSettings.direction = settings.direction;
            tempSettings.filters = settings.filters && settings.filters.length > 0 ? settings.filters : [];
            tempSettings.pageSize = settings.pageSize ? settings.pageSize : null;
        }

        var toIndex = args.column.index;
        if (columns) {
            columns.forEach((col, index) => {
                if (col.field === args.column.field) {
                    toIndex = index;
                }
            });
        }

        if (tempSettings.columns.length > 0) {
            let column = tempSettings.columns.find(c => c.Field === args.column.field);
            if (column) {
                column.FromIndex =  args.column.index;
                column.ToIndex = toIndex;
                column.Width = args.column.width
            }
            else {
                tempSettings.columns.push({
                    HeaderText: args.column.headerText,
                    Field: args.column.field,
                    FromIndex: args.column.index,
                    ToIndex: toIndex,
                    Width: args.column.width
                });
            }
        }
        else {
            tempSettings.columns.push({
                HeaderText: args.column.headerText,
                Field: args.column.field,
                FromIndex: args.column.index,
                ToIndex: toIndex,
                Width: args.column.width
            });
        }

        gridSettings = JSON.stringify(tempSettings);
    }

    return gridSettings;
}

export function numberTo2DecimalPlace(num) {
    if (num) {
        var newNumber = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
        return newNumber;
    }
    return "0";
}