import HttpClient from '../utilities/HttpClient';

const API = {
    getSettings: '/settings/oilmatIntegration',
    postSettings: '/settings/oilmatIntegration'
}

class OilmatIntegrationService {
    async GetSettings(cancelToken) {
        return await HttpClient.Get(API.getSettings, null, cancelToken);
    }

    async UpdateSettings(data, cancelToken) {
        return await HttpClient.Post(API.postSettings, data, cancelToken);
    }
}

const oilmatIntegrationService = new OilmatIntegrationService();
export default oilmatIntegrationService;