import React, { Component } from 'react';
import Axios from 'axios';

import { ToolbarComponent, ItemsDirective, ItemDirective } from '@syncfusion/ej2-react-navigations';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';

import OilmatIntegrationService from '../../../services/OilmatIntegrationService';
import Localization from '../../../utilities/Localization';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class HeliosIntegration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isEditMode: false,
            data: {
                ProxyHostname: '',
                ProxyUsername: '',
                ProxyPassword: '',
                SendOrderViaAPI: false,
                DatasetPump: false,
                DatasetChangeStock: false,
                DatasetDunkChange: false,
                OptionalDataUserId: false,
                OptionalDataProductDescription: false,
                ProductNumber: ''
            }
        };
        
        this.onSettingsToolbarClicked = this.onSettingsToolbarClicked.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
    }

    async componentDidMount() {
        let { data } = this.state;
        const settings = await OilmatIntegrationService.GetSettings(source.token);
        if (!settings.HasError) {
            data.ProxyHostname = settings.HeliosProxyHostname;
            data.ProxyUsername = settings.HeliosProxyUsername;
            data.ProxyPassword = settings.HeliosProxyPassword;
            data.SendOrderViaAPI = settings.HeliosSendOrderViaApi;
            data.ProductNumber = settings.HeliosCustomProductNumber;

            if (settings.HeliosOilmatDataset.length > 0) {
                for (let index = 0; index < settings.HeliosOilmatDataset.length; index++) {
                    const dataset = settings.HeliosOilmatDataset[index];
                    if (dataset.includes("Pump")) { data.DatasetPump = true; }
                    if (dataset.includes("ChangeStock")) { data.DatasetChangeStock = true; }
                    if (dataset.includes("DunkChange")) { data.DatasetDunkChange = true; }
                }
            }

            if (settings.HeliosOptionalData.length > 0) {
                for (let index = 0; index < settings.HeliosOptionalData.length; index++) {
                    const dataset = settings.HeliosOptionalData[index];
                    if (dataset.includes("UserId")) { data.OptionalDataUserId = true; }
                    if (dataset.includes("ProductDescription")) { data.OptionalDataProductDescription = true; }
                }
            }

            this.setState({ data });
        }
        else {
            this.props.dialogOpen("Advarsel", settings.ErrorMessage);
        }
    }

    componentWillUnmount() {

    }

    async onSettingsToolbarClicked(args) {
        if (args.item.id === 'edit') {
            this.setState({ isEditMode: true });
        }
        else if (args.item.id === 'update') {
            const { data } = this.state;

            let oilmatDataset = [], heliosOptionalData = [];
            if (data.DatasetPump) { oilmatDataset.push('Pump') }
            if (data.DatasetChangeStock) { oilmatDataset.push('ChangeStock') }
            if (data.DatasetDunkChange) { oilmatDataset.push('DunkChange') }
            if (!data.DatasetPump && !data.DatasetChangeStock && !data.DatasetDunkChange) {
                oilmatDataset = [];
                oilmatDataset.push('None');
            }
                
            if (data.OptionalDataUserId) { heliosOptionalData.push('UserId') }
            if (data.OptionalDataProductDescription) { heliosOptionalData.push('ProductDescription') }
            if (!data.OptionalDataUserId && !data.OptionalDataProductDescription) {
                heliosOptionalData = [];
                heliosOptionalData.push('None');
            }

            const payload = {
                HeliosProxyHostname: data.ProxyHostname,
                HeliosProxyUsername: data.ProxyUsername,
                HeliosProxyPassword: data.ProxyPassword,
                HeliosSendOrderViaAPI: data.SendOrderViaAPI,
                HeliosOilmatDataset: oilmatDataset,
                HeliosOptionalData: heliosOptionalData,
                CustomerId: this.props.sessionId,
                IntegrationKey: "Helios",
                HeliosCustomProductNumber: data.ProductNumber
            };
            
            const result = await OilmatIntegrationService.UpdateSettings(payload, source.token);
            if (!result.HasError) {
                this.props.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "OilMat Integration"));
                this.refreshData().then(() => {
                    this.setState({ isEditMode: false });
                    this.props.dirtyProperty(false);
                });
            }
            else {
                this.props.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
        else if (args.item.id === 'cancel') {
            this.props.dirtyProperty(false);
            this.setState({ isEditMode: false }, () => {
                this.refreshData();
            });
        }
    }

    async refreshData() {
        let data = {};
        const settings = await OilmatIntegrationService.GetSettings(source.token);
        if (!settings.HasError) {
            data.ProxyHostname = settings.HeliosProxyHostname;
            data.ProxyUsername = settings.HeliosProxyUsername;
            data.ProxyPassword = settings.HeliosProxyPassword;
            data.SendOrderViaAPI = settings.HeliosSendOrderViaApi;
            data.ProductNumber = settings.HeliosCustomProductNumber;
    
            if (settings.HeliosOilmatDataset.length > 0) {
                for (let index = 0; index < settings.HeliosOilmatDataset.length; index++) {
                    const dataset = settings.HeliosOilmatDataset[index];
                    if (dataset.includes("Pump")) { data.DatasetPump = true; }
                    if (dataset.includes("ChangeStock")) { data.DatasetChangeStock = true; }
                    if (dataset.includes("DunkChange")) { data.DatasetDunkChange = true; }
                }
            }

            if (settings.HeliosOptionalData.length > 0) {
                for (let index = 0; index < settings.HeliosOptionalData.length; index++) {
                    const dataset = settings.HeliosOptionalData[index];
                    if (dataset.includes("UserId")) { data.OptionalDataUserId = true; }
                    if (dataset.includes("ProductDescription")) { data.OptionalDataProductDescription = true; }
                }
            }

            this.setState({ data });
        }
    }

    onChange(name, args) {
        let { data } = this.state;
        data[name] = args.target.value;
        this.setState({ data });
        this.props.dirtyProperty(true);
    }

    onCheckboxChange(name, args) {
        let { data } = this.state;
        switch (name) {
            case "API":
                data.SendOrderViaAPI = this.SendviaAPIRef.checked;
                break;
            case "DatasetPump":
                data.DatasetPump = this.DatasetPumpRef.checked;
                break;
            case "DatasetChangeStock":
                data.DatasetChangeStock = this.DatasetChangeStockRef.checked;
                break;
            case "DatasetDunkChange":
                data.DatasetDunkChange = this.DatasetDunkChangeRef.checked;
                break;
            case "OptionalDataUserId":
                data.OptionalDataUserId = this.OptionalDataUserIdRef.checked;
                break;
            case "OptionalDataProductDescription":
                data.OptionalDataProductDescription = this.OptionalDataProductDescRef.checked;
                break;
            default:
                break;
        }
        this.setState({ data });
        this.props.dirtyProperty(true);
    }

    render() {
        const { isEditMode, data } = this.state;

        return (
            <React.Fragment>
                <div className="system-setting-message-toolbar-wrapper">
                    <ToolbarComponent ref={ref => this.unitsToolbarRef = ref} clicked={this.onSettingsToolbarClicked}>
                        <ItemsDirective>
                            <ItemDirective id="edit" prefixIcon='tb-icons c-icon-edit' text="Redigere" tooltipText='Redigere' disabled={isEditMode}/>
                            <ItemDirective id="update" prefixIcon='tb-icons c-icon-update' text="Gem" tooltipText='Gem' disabled={!isEditMode} />
                            <ItemDirective id="cancel" prefixIcon='tb-icons c-icon-cancel' text="Annuller" tooltipText='Annuller' disabled={!isEditMode} />
                        </ItemsDirective>
                    </ToolbarComponent>
                </div>
                <table className="table system-setting-message-table">
                    <tbody>
                        <tr>
                            <td className="label" width="40%">Proxy Hostname</td>
                            <td colSpan="2">
                                <input value={data.ProxyHostname} disabled={!isEditMode} name="ProxyHostname" onChange={this.onChange.bind(this, "ProxyHostname")} className="e-input e-control" />
                            </td>
                        </tr>
                        <tr>
                            <td className="label" width="40%">Proxy Username</td>
                            <td colSpan="2">
                                <input value={data.ProxyUsername} disabled={!isEditMode} name="ProxyUsername" onChange={this.onChange.bind(this, "ProxyUsername")} className="e-input e-control" />
                            </td>
                        </tr>
                        <tr>
                            <td className="label" width="40%">Proxy Password</td>
                            <td colSpan="2">
                                <input value={data.ProxyPassword} disabled={!isEditMode} name="ProxyPassword" onChange={this.onChange.bind(this, "ProxyPassword")} className="e-input e-control" />
                            </td>
                        </tr>
                        <tr>
                            <td className="label" width="40%">Send Data Set</td>
                            <td>
                                <CheckBoxComponent ref={ref => this.SendviaAPIRef = ref} disabled={!isEditMode} label="via API" checked={data.SendOrderViaAPI} value={data.SendOrderViaAPI} name="viaApi" change={this.onCheckboxChange.bind(this, "API")} />
                            </td>
                        </tr>
                        <tr>
                            <td className="label" width="40%">Data Set</td>
                            <td colSpan="2">
                                <CheckBoxComponent ref={ref => this.DatasetPumpRef = ref} disabled={!isEditMode} label="Pump" checked={data.DatasetPump} value={data.DatasetPump} name="pump" change={this.onCheckboxChange.bind(this, "DatasetPump")} /> <br/>
                                <CheckBoxComponent ref={ref => this.DatasetChangeStockRef = ref} disabled={!isEditMode} label="Change stock" checked={data.DatasetChangeStock} value={data.DatasetChangeStock} name="changStock" change={this.onCheckboxChange.bind(this, "DatasetChangeStock")} /> <br/>
                                <CheckBoxComponent ref={ref => this.DatasetDunkChangeRef = ref} disabled={!isEditMode} label="Dunk change" checked={data.DatasetDunkChange} value={data.DatasetDunkChange} name="dunkChange" change={this.onCheckboxChange.bind(this, "DatasetDunkChange")} />
                            </td>
                        </tr>
                        <tr>
                            <td className="label" width="40%">Optional Data:</td>
                            <td>
                                <CheckBoxComponent ref={ref => this.OptionalDataUserIdRef = ref} disabled={!isEditMode} label="User Id" checked={data.OptionalDataUserId} value={data.SendOrderViaAPI} name="OptionalData" change={this.onCheckboxChange.bind(this, "OptionalDataUserId")} /> <br/>
                                <CheckBoxComponent ref={ref => this.OptionalDataProductDescRef = ref} disabled={!isEditMode} label="Product Description" checked={data.OptionalDataProductDescription} value={data.SendOrderViaAPI} name="OptionalData" change={this.onCheckboxChange.bind(this, "OptionalDataProductDescription")} />
                            </td>
                        </tr>
                        <tr>
                            <td className="label" width="40%">Product Number:</td>
                            <td>
                                <input value={data.ProductNumber} disabled={!isEditMode} name="ProductNumber" onChange={this.onChange.bind(this, "ProductNumber")} className="e-input e-control" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </React.Fragment>
        )
    }
}

export default HeliosIntegration;