import React, { Component } from 'react';
import { Query } from '@syncfusion/ej2-data';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import OilmatLogo from '../../../assets/logo_oilmat_ilx_system.png'

export default class OilmatCountrySelection extends Component {
    constructor(props) {
        super(props);

        this.onCountryFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('Name', 'startswith', e.text, true) : query;
            e.updateData(props.lookup.countries, query)
        }
    }

    onDropdownChange = (name, args) => {
        if (args.isInteracted) {
            this.props.onChange(name, args.value);
            this.props.onChange("CountryId", args.value);
            this.props.onChange("countryOfOriginName", args.itemData.Name);
            this.props.onSelectCountry(args.itemData.Code);
        }
    }

    render() {
        const { data, t, lookup } = this.props;

        return (
            <div className="container h-100vh flex-col ">
                <div className="w-500">
                    <div className="oilmat-banner m-bottom-20">
                        <img src={OilmatLogo} alt="oilmat-logo" />
                    </div>
                    <div className="title m-bottom-10">{t('SelectCountryOfOrigin')}</div>
                    <DropDownListComponent
                        name="countryOfOrigin"
                        value={data.countryOfOrigin}
                        dataSource={lookup.countries}
                        filtering={this.onCountryFiltering.bind(this)} 
                        filterBarPlaceholder={t('SearchCountry')}
                        allowFiltering={true}
                        placeholder={t('LabelCountry')}
                        fields={{ text: 'Name', value: 'Id' }}
                        change={this.onDropdownChange.bind(this, "countryOfOrigin")} 
                        floatLabelType="Auto"
                    />
                </div>
            </div>
        )
    }
}