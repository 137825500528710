import React from 'react'
import { PivotViewComponent, Inject, Toolbar, PDFExport, ExcelExport } from '@syncfusion/ej2-react-pivotview';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject as GridInject, Page, Sort, Toolbar as GridToolbar, Selection, Edit, Search, Reorder, Resize, Aggregate, 
        AggregateColumnsDirective, AggregateColumnDirective, AggregateDirective, AggregatesDirective, ColumnChooser, ExcelExport as GridExcelExport, PdfExport as GridPdfExport } from '@syncfusion/ej2-react-grids';
import Axios from 'axios';
import { Redirect } from 'react-router-dom';
import { DataManager } from '@syncfusion/ej2-data';
import { DialogComponent } from '@syncfusion/ej2-react-popups';

import BaseComponent from '../../components/BaseComponent';
// import Breadcrumb from '../../components/Breadcrumb';
import Loading from '../../components/Loading';
import Localization from '../../utilities/Localization';

import ReportService from '../../services/ReportService';
import SessionService from '../../services/SessionService'
import SettingsService from '../../services/SettingsService';
import { buildGridSettings } from '../../utilities/Helper';
import '../../styles/reports.scss';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class Reports extends BaseComponent {
    moduleName = "Rapporter";
    pageName = "Rapporter";
    reportSource = { AggregateFields: [], Data: [] };
    gridSettingsKey = '';
    gridSettings = '';
    hasDraggedColumn = false;

    constructor() {
        super();

        this.state = {
            loading: true,
            reportSource: { AggregateFields: [], Data: [] },
            breadcrumbs: ['Rapporter'],
            currentReportId: null,
            reportSettings: {
                Id: null,
                ParentMenuItemId: null,
                ReportType: null
            },
            standardReportSetting: {
                selectedReport: null,
                selectedReportName: ''
            },
            redirect: {
                to: '',
                isRedirect: false
            },
            reportReady: false
        };

        //#region Standard Report Settings
        this.pageSettings = {
            pageSize: 10,
            pageSizes: ['Alle', '5', '10', '20', '50', '100']
        };

        this.selectionSettings = {
            type: 'Single'
        }

        this.filterSettings = {
            columns: [],
            type: 'Menu'
        };

        this.standardReportData = [];
        this.columns = [];
        this.toolbarOptions = [
            'ExcelExport', 'PdfExport', 'CsvExport'
        ];
        //#endregion

        //#region Pivot Report Settings
        this.pivotToolbarOptions = ['Grid', 'Chart', 'Export', 'SubTotal', 'GrandTotal'];
        this.displayOption = { view: 'Primary'};

        this.dataSourceSettings = {
            columns: [],
            valueSortSettings: { headerDelimiter: ' - ' },
            values: [],
            dataSource: [],
            rows: [],
            formatSettings: [],
            filters: []
        };
        //#endregion

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.buttons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        /* End Dialog options  */

        this.standardTableToolbarClicked = this.standardTableToolbarClicked.bind(this);
    }

    async componentDidMount() { 
        super.componentDidMount();
        source.cancel();
        source = CancelToken.source();

        let { breadcrumbs, reportSettings, standardReportSetting } = this.state;
        const { location } = this.props;

        if (!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }

        if (location.search) {
            let query = location.search.substring(1);
            if (query[0] === 'r') {
                this.gridSettingsKey = query.substring(2);

                let params = atob(query.substring(2)).split(',');
                reportSettings.Id = parseInt(params[0]);
                reportSettings.ParentMenuItemId = parseInt(params[1]);
                reportSettings.ReportType = parseInt(params[2]);

                if (!super.hasReportMenuTemplateAccess(reportSettings.ParentMenuItemId, reportSettings.Id)) {
                    this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
                    return;
                }
            }
            else {
                this.setState({ loading: false, redirect: { to: '*', isRedirect: true }});
                return;
            }
        }
        else {
            this.setState({ loading: false, redirect: { to: '*', isRedirect: true }});
            return;
        }

        const session = await SessionService.GetSessionInfo();
        this.UserCustomerId = session.Id;
        const report = await ReportService.GetReport(reportSettings.Id, source.token);
        if (report.HasError) {
            this.setState({ loading: false, redirect: { to: '*', isRedirect: true }});
            return;
        }
        
        const gridSettings = await SettingsService.GetGridSettings(this.gridSettingsKey, source.token);
        if (!gridSettings.HasError) {
            this.gridSettings = gridSettings.Settings;
        }

        let menuName = '';
        let reportMenuItem = super.getReportMenuItem(reportSettings.ParentMenuItemId, reportSettings.Id);
        menuName = reportMenuItem.parentMenuName;
        breadcrumbs.push(menuName);
        
        let param = [];
        if (report.ReportType === 1) {
            let config = JSON.parse(report.Config);
            let visibleColumns  = config.columns.filter(x => x.visible).map(f => f.field);
            param = visibleColumns;
            this.currentReport = config;
        }
        
        let reportSource = { AggregateFields: [], Data: [] };

        switch(menuName) {
            case 'Produkt':
                reportSource = await ReportService.GetProductsReport(session.Id, param, source.token);
                break;
            case 'Indkøb':
                reportSource = await ReportService.GetOrdersReport(session.Id, param, source.token);
                break;
            case 'Brugere':
                reportSource = await ReportService.GetUsersReport(session.Id, param, source.token);
                break;
            case 'Leverandører':
                reportSource = await ReportService.GetSupplierReport(session.Id, param, source.token);
                break;
            case 'Kunder':
                reportSource = await ReportService.GetCustomersReport(session.Id, param, source.token);
                break;
            case 'Automater':
                reportSource = await ReportService.GetMachineReport(session.Id, param, source.token);
                break;
            case 'Lager':
                reportSource = await ReportService.GetStockReport(session.Id, param, source.token);
                break;
            case 'Forbrug':
                reportSource = await ReportService.GetConsumeReport(session.Id, param, source.token);
                break;
        }
        
        this.reportSource = reportSource;

        if (reportSettings.ReportType === 1) {
            standardReportSetting.selectedReport = reportSettings.Id;
            standardReportSetting.selectedReportName = report.Name;
            let config = JSON.parse(report.Config);
            var dateFields = [];
            var timeFields = [];
            this.columns = config.columns.filter(x => x.visible).map(value => {
                let type = reportSource.Columns.find(x => x.FieldName === value.field) ? reportSource.Columns.find(x => x.FieldName === value.field).Type : "";
                value.Type = type;
                if (value.Type === "DateTime") {
                    dateFields.push(value.field);
                }
                if (value.Type === "TimeSpan") {
                    timeFields.push(value.field);
                }
                return value;
            });

            var date = new Date();
            this.standardReportData = reportSource.Data.map(val => {
                dateFields.forEach(field => {
                    if (val[field]) {
                        val[field] = new Date(val[field]);
                    }
                });
                timeFields.forEach(field => {
                    if (val[field]) {
                        val[field] = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), val[field].Hours, val[field].Minutes, val[field].Seconds);
                    }
                });
                return val;
            });
            
            if (config.filters) {
                config.filters.forEach(property => {
                    this.filterSettings.columns.push({
                        field: property.field,
                        ignoreAccent: false,
                        matchCase: false,
                        operator: property.operator,
                        predicate: 'and',
                        value: property.value
                    })
                });
            } else {
                this.filterSettings.columns = [];
            }

            this.toolbarOptions.push(
                { 
                    text: report.Favorite ? 'Fjern som favorit' : 'Favorit', 
                    tooltipText: report.Favorite ? 'Fjern som favorit' : 'Favorit', 
                    id: 'save_favorite', 
                    prefixIcon: 'e-favorite' 
                }
            );

            this.toolbarOptions.push({ text: Localization.General.GridResetFilter, tooltipText: Localization.General.GridResetFilter, id: 'reset_filter', prefixIcon: 'e-cancel' });            
        }
        else if (reportSettings.ReportType === 2) {
            let config = JSON.parse(report.Config);
            this.dataSourceSettings = config.dataSourceSettings;
            this.dataSourceSettings.dataSource = reportSource.Data;
        }

        this.setState({ loading: false, report, reportSettings, breadcrumbs, reportMenuName: menuName, standardReportSetting, reportReady: true });
    }

    async componentDidUpdate(prevProps, prevState) {
        const currentPage = this.props.location;
        const prevPage = prevProps.location;
        
        if (currentPage.pathname != prevPage.pathname && currentPage.search === prevPage.search) {
            return;
        }
        else if (currentPage.pathname != prevPage.pathname || currentPage.search != prevPage.search) {
            this.setState({ reportReady: false });
        }

        const { reportSettings: currReportSettings  } = prevState;
        const { location } = this.props;

        let reportSettings = {};
        if (location.search) {
            let query = location.search.substring(1);
            if (query[0] === 'r') {
                let params = atob(query.substring(2)).split(',');
                reportSettings.Id = parseInt(params[0]);
                reportSettings.ParentMenuItemId = parseInt(params[1]);
                reportSettings.ReportType = parseInt(params[2]);
                
                if (!super.hasReportMenuTemplateAccess(reportSettings.ParentMenuItemId, reportSettings.Id)) {
                    this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
                    return;
                }
            }
            else {
                this.setState({ loading: false, redirect: { to: '*', isRedirect: true }});
                return;
            }
        }
        else {
            this.setState({ loading: false, redirect: { to: '*', isRedirect: true }});
            return;
        }

        if (currReportSettings.Id !== reportSettings.Id) {
            let { breadcrumbs, standardReportSetting } = this.state;
            const report = await ReportService.GetReport(reportSettings.Id, source.token);
            if (report.HasError) {
                this.setState({ loading: false, redirect: { to: '*', isRedirect: true }});
                return;
            }

            let menuName = '';
            let reportMenuItem = super.getReportMenuItem(reportSettings.ParentMenuItemId, reportSettings.Id);
            
            menuName = reportMenuItem.parentMenuName;
            breadcrumbs = ['Rapporter', menuName];
        
            let param = [];
            let sortedColumns = [];
            let configColumns = [];
            if (report.ReportType === 1) {
                let config = JSON.parse(report.Config);
                let visibleColumns  = config.columns.filter(x => x.visible).map(f => f.field);
                param = visibleColumns;
                sortedColumns = config.sortedColumns ? config.sortedColumns : [];
                configColumns = config.columns;
            }

            let reportSource = { AggregateFields: [], Data: [] };
            switch(menuName) {
                case 'Produkt':
                    reportSource = await ReportService.GetProductsReport(this.UserCustomerId, param, source.token);
                    break;
                case 'Indkøb':
                    reportSource = await ReportService.GetOrdersReport(this.UserCustomerId, param, source.token);
                    break;
                case 'Brugere':
                    reportSource = await ReportService.GetUsersReport(this.UserCustomerId, param, source.token);
                    break;
                case 'Leverandører':
                    reportSource = await ReportService.GetSupplierReport(this.UserCustomerId, param, source.token);
                    break;
                case 'Kunder':
                    reportSource = await ReportService.GetCustomersReport(this.UserCustomerId, param, source.token);
                    break;
                case 'Automater':
                    reportSource = await ReportService.GetMachineReport(this.UserCustomerId, param, source.token);
                    break;
                case 'Lager':
                    reportSource = await ReportService.GetStockReport(this.UserCustomerId, param, source.token);
                    break;
                case 'Forbrug':
                    reportSource = await ReportService.GetConsumeReport(this.UserCustomerId, param, source.token);
                    break;
            }

            this.reportSource = reportSource;

            if (reportSettings.ReportType === 1) {
                standardReportSetting.selectedReport = reportSettings.Id;
                standardReportSetting.selectedReportName = report.Name;
                let config = JSON.parse(report.Config);
                var dateFields = [];
                var timeFields = [];
                this.columns = config.columns.filter(x => x.visible).map(value => {
                    let type = reportSource.Columns.find(x => x.FieldName === value.field) ? reportSource.Columns.find(x => x.FieldName === value.field).Type : "";
                    value.Type = type;
                    if (value.Type === "DateTime") {
                        dateFields.push(value.field);
                    }
                    if (value.Type === "TimeSpan") {
                        timeFields.push(value.field);
                    }
                    return value;
                });
                var date = new Date();
                this.standardReportData = reportSource.Data.map(val => {
                    dateFields.forEach(field => {
                        if (val[field]) {
                            val[field] = new Date(val[field]);
                        }
                    });
                    timeFields.forEach(field => {
                        if (val[field]) {
                            val[field] = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), val[field].Hours, val[field].Minutes, val[field].Seconds);
                        }
                    });
                    return val;
                });
                this.reportSource = reportSource;

                if (config.filters) {
                    config.filters.forEach(property => {
                        this.filterSettings.columns.push({
                            field: property.field,
                            ignoreAccent: false,
                            matchCase: false,
                            operator: property.operator,
                            predicate: 'and',
                            value: property.value
                        })
                    });
                } else {
                    this.filterSettings.columns = [];
                }

                if (this.reportGridRef) {
                    this.reportGridRef.dataSource = reportSource.Data;
                    this.reportGridRef.refreshColumns();
                    this.reportGridRef.refreshHeader();
                    this.reportGridRef.refresh();

                    if (sortedColumns.length > 0) {
                        sortedColumns.forEach(s => {
                            let col = configColumns.find(x => x.field === s);
                            this.reportGridRef.sortColumn(s, col.sortDirection);
                        })
                    }
                }

                // this.reportGridRef.columns.forEach(gc => {
                //     gc.visible = this.columns.some(c => c.FieldName === gc.field && c.Visible);
                // });
                // this.reportGridRef.dataSource = reportSource.Data;
                // this.reportGridRef.dataBind();
                
                // this.toolbarOptions.push(
                //     { 
                //         text: report.Favorite ? 'Fjern som favorit' : 'Favorit', 
                //         tooltipText: report.Favorite ? 'Fjern som favorit' : 'Favorit', 
                //         id: 'save_favorite', 
                //         prefixIcon: 'e-favorite' 
                //     }
                // );

                var button = document.querySelector("#save_favorite");
                if (button) {
                    button.parentElement.setAttribute("title", report.Favorite ? 'Fjern som favorit' : 'Favorit');
                    var btnText = button.querySelector('.e-tbar-btn-text');
                    btnText.innerHTML = report.Favorite ? 'Fjern som favorit' : 'Favorit';
                }

            }
            else if (reportSettings.ReportType === 2) {
                standardReportSetting.selectedReport = null;
                standardReportSetting.selectedReportName = "";
                let config = JSON.parse(report.Config);
                this.dataSourceSettings = config.dataSourceSettings;
                this.dataSourceSettings.dataSource = reportSource.Data;
            }


            this.setState({ report, breadcrumbs, reportSettings, reportMenuName: menuName, standardReportSetting, reportReady: true }, () => {

            });
        }
    }

    // fetchReport(args) {
    //     let reportList = this.state.reports.map(report => report.Name);
    //     args.reportName = reportList;
    // }

    // loadReport(args) {
    //     const { reports, reportSource } = this.state;
    //     let report = reports.find(x => x.Name === args.reportName);
    //     if (report) {
    //         args.report = report.Config;
    //         this.pivotView.dataSourceSettings = JSON.parse(report.Config).dataSourceSettings;
    //         this.pivotView.dataSourceSettings.dataSource = reportSource;
    //         this.pivotView.refresh();
    //         this.pivotView.toolbarModule.currentReport = args.reportName; 
    //     }
    // }

    beforeExport(args) {
        const { report } = this.state;
        args.excelExportProperties = {
            fileName: `${report.Name}.xlsx`
        }

        args.pdfExportProperties = {
            fileName: `${report.Name}.pdf`,
        }
    }

    async standardTableToolbarClicked(args) {
        if (args.item.text === 'PDF Export') {
            let { standardReportSetting } = this.state;
            const query = this.reportGridRef.renderModule.data.generateQuery();

            for(let i = 0; i < query.queries.length; i++ ){
                if(query.queries[i].fn === 'onPage'){
                  query.queries.splice(i,1);// remove page query to get all records
                  break;
                }
            }

            new DataManager({json: this.standardReportData}).executeQuery(query).then((e) => {
                let data = e.result;
                if (data.length > 0) {
                    let pdfExportProperties = {};
                    
                    if (standardReportSetting.selectedReport) {
                        pdfExportProperties = {
                            fileName: `${standardReportSetting.selectedReportName}.pdf`,
                            header: {
                                contents: [ 
                                    { position: { x: 0, y: 10 }, style: { textBrushColor: '#000000', fontSize: 22 }, type: 'Text', value: standardReportSetting.selectedReportName } 
                                ],
                                fromTop: 0, height: 50
                            },
                            pageOrientation: 'Landscape',
                        }
                    }
                    pdfExportProperties.dataSource = data;
                    this.reportGridRef.pdfExport(pdfExportProperties);
                }
                else {
                    this.dialogOpen("Advarsel", Localization.Report.NoRecordsToExport);
                }
            }).catch(e => true);
        }
        else if (args.item.text === 'Excel Export') {
            let { standardReportSetting } = this.state;
            const query = this.reportGridRef.renderModule.data.generateQuery();

            for(let i = 0; i < query.queries.length; i++ ){
                if(query.queries[i].fn === 'onPage'){
                  query.queries.splice(i,1);// remove page query to get all records
                  break;
                }
            }

            new DataManager({json: this.standardReportData}).executeQuery(query).then((e) => {
                let data = e.result;
                if (data.length > 0) {
                    let excelExportProperties = {};
                
                    if (standardReportSetting.selectedReport) {
                        excelExportProperties = {
                            fileName: `${standardReportSetting.selectedReportName}.xlsx`
                        }
                    }
                    excelExportProperties.dataSource = data;
                    this.reportGridRef.excelExport(excelExportProperties);
                }
                else {
                    this.dialogOpen("Advarsel", Localization.Report.NoRecordsToExport);
                }
            }).catch(e => true);
        }
        else if (args.item.text === 'CSV Export') {
            let { standardReportSetting } = this.state;
            const query = this.reportGridRef.renderModule.data.generateQuery();

            for(let i = 0; i < query.queries.length; i++ ){
                if(query.queries[i].fn === 'onPage'){
                  query.queries.splice(i,1);// remove page query to get all records
                  break;
                }
            }

            new DataManager({json: this.standardReportData}).executeQuery(query).then((e) => {
                let data = e.result;
                if (data.length > 0) {
                    let excelExportProperties = {};
                
                    if (standardReportSetting.selectedReport) {
                        excelExportProperties = {
                            fileName: `${standardReportSetting.selectedReportName}.csv`
                        }
                    }
                    excelExportProperties.dataSource = data;
                    this.reportGridRef.csvExport(excelExportProperties);
                }
                else {
                    this.dialogOpen("Advarsel", Localization.Report.NoRecordsToExport);
                }
            }).catch(e => true);
        }
        else if (args.item.id === 'save_favorite') {
            let { standardReportSetting, report } = this.state;
            const payload = {
                ReportId: standardReportSetting.selectedReport,
                Favorite: !report.Favorite
            };
            const result = await ReportService.SaveFavorite(payload, source.token);

            if (!result.HasError) {
                let currentText = report.Favorite ? 'Fjern som favorit' : 'Favorit';
                let txt = !report.Favorite ? 'Fjern som favorit' : 'Favorit';
                let selector = '.e-toolbar-item[title="' + currentText + '"]';
                this.reportGridRef.toolbarModule.element.querySelector(selector).setAttribute("title", txt);
                this.reportGridRef.toolbarModule.element.querySelector('#save_favorite .e-tbar-btn-text').innerText = txt;
                this.dialogOpen(Localization.General.SuccessHeader, Localization.VideoArchive.SuccessfullySavedAsFavorite);

                report.Favorite = !report.Favorite;
                this.setState({ report });
                this.props.updateUserMenu();
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
        else if (args.item.id === 'reset_filter') {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                settings.filters = [];
                this.gridSettings = settings;
                const payload = {
                    Key: this.gridSettingsKey,
                    Settings: JSON.stringify(this.gridSettings)
                };
    
                const result = await SettingsService.SaveGridSettings(payload, source.token);
    
                if (!result.HasError) {
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                }
            }

            this.reportGridRef.clearFiltering();
        }
    }

    async onActionComplete(args) {
        if (args.requestType === 'filtering') {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field === args.currentFilterObject.field && x.operator === args.currentFilterObject.operator && x.value === args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                if (settings.colName && settings.colName === args.columnName && settings.direction && settings.direction === args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.reportGridRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, this.hasDraggedColumn, columns);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                let lastColumnIndex = this.reportGridRef.columns.length - 1;
                for (let index = 0; index < this.reportGridRef.columns.length; index++) {
                    let column = this.reportGridRef.columns[index];
                    if (index === lastColumnIndex) { column.allowResizing = false }
                    else { column.allowResizing = true }
                }
                this.reportGridRef.refreshColumns();
            }

            this.hasDraggedColumn = false;
        }
    }
    
    onCreated() {
        if (this.gridSettings) {
            let settings = JSON.parse(this.gridSettings);
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(column => {
                        if (column.FromIndex !== column.ToIndex) {
                            var tempIndex = this.reportGridRef.getColumnIndexByField(column.Field);
                            if (tempIndex !== column.ToIndex){
                                this.reportGridRef.reorderColumnByIndex(column.FromIndex, column.ToIndex);
                            }
                        }
    
                        if (column.Width) {
                            this.reportGridRef.columns[column.ToIndex].width = column.Width;
                        }
                    });
                }

                if (settings.colName && settings.direction) {
                    this.reportGridRef.sortColumn(settings.colName, settings.direction);
                }
                else if (this.currentReport) {
                    let sortedColumns = this.currentReport.sortedColumns ? this.currentReport.sortedColumns : [];
                    if (sortedColumns.length > 0) {
                        sortedColumns.forEach(s => {
                            let col = this.currentReport.columns.find(x => x.field === s);
                            this.reportGridRef.sortColumn(s, col.sortDirection);
                        })
                    }
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.reportGridRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.reportGridRef.refreshColumns();

                // Prevent resizing of last column
                let lastColumnIndex = this.reportGridRef.columns.length - 1;    
                this.reportGridRef.columns[lastColumnIndex].allowResizing = false;
                this.reportGridRef.refreshColumns();

                if (settings.pageSize) {
                    if (settings.pageSize === "Alle") {
                        this.reportGridRef.pageSettings.pageSize = this.reportGridRef.pageSettings.totalRecordsCount;
                        document.querySelector("#report-grid .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.reportGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
            }
        }
        else if(this.currentReport) {
            if (this.reportGridRef) {
                let sortedColumns = this.currentReport.sortedColumns ? this.currentReport.sortedColumns : [];
                if (sortedColumns.length > 0) {
                    sortedColumns.forEach(s => {
                        let col = this.currentReport.columns.find(x => x.field === s);
                        this.reportGridRef.sortColumn(s, col.sortDirection);
                    })
                }
            }
        }
    }

    footerSum(props) {
        return (<span>Sum: {props.Sum.toFixed(2)}</span>)
    }

    dialogOpen(header, content) {
        this.notificationDialog.header = header;
        this.notificationDialog.content = content;
        this.notificationDialog.show();
    }

    dataBound() {
        this.reportGridRef.autoFitColumns();
    }

    onColumnDragStart(args) {
        this.hasDraggedColumn = true;
    }

    render() {
        const { loading, breadcrumbs, reportMenuName, redirect, reportSettings, report } = this.state;

        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        return (
            <div className="content-pane-wrapper user-defined-report-wrapper">
                {/* <div><Breadcrumb breadcrumbs={breadcrumbs} /></div> */}
                <div className="module-header">
                    <h1>{`${reportMenuName} ${this.pageName}`}</h1>
                </div>
                <div className="report-name">
                    { report && <h2>{report.Name}</h2> }
                </div>
                <div>
                    {
                        this.state.reportReady && reportSettings.ReportType === 1 &&
                        <GridComponent ref={ref => this.reportGridRef = ref } id="report-grid" dataSource={this.standardReportData} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings}
                            selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both"
                            allowReordering={true} allowResizing={true} showColumnChooser={true} toolbar={this.toolbarOptions} toolbarClick={this.standardTableToolbarClicked} created={this.onCreated.bind(this)}
                            allowExcelExport={true} allowPdfExport={true} dataBound={this.dataBound.bind(this)} columnDragStart={this.onColumnDragStart.bind(this)} actionComplete={this.onActionComplete.bind(this)}>
                            <ColumnsDirective>
                                {
                                    this.columns.map(column => {
                                        return (
                                            column.Type && column.Type === "DateTime" ?
                                            <ColumnDirective field={column.field} headerText={column.headerText} type="date" format={{ type: 'date', format: 'dd-MM-yyyy' }} />
                                            :
                                            column.Type && column.Type === "TimeSpan"
                                            ?
                                            <ColumnDirective field={column.field} headerText={column.headerText} type="datetime" format={{ type: 'date', format: 'HH:mm' }} />
                                            :
                                            <ColumnDirective field={column.field} headerText={column.headerText} />
                                        )
                                    })
                                }
                            </ColumnsDirective>
                            {
                                this.reportSource.AggregateFields.length > 0 &&
                                <AggregatesDirective>
                                    <AggregateDirective>
                                        <AggregateColumnsDirective>
                                            {
                                                this.reportSource.AggregateFields.map(f => {
                                                    return <AggregateColumnDirective field={f} type='Sum' footerTemplate={this.footerSum}> </AggregateColumnDirective>
                                                })
                                            }
                                        </AggregateColumnsDirective>
                                    </AggregateDirective>
                                </AggregatesDirective>
                            }
                            <GridInject services={[Page, Sort, Filter, Selection, GridToolbar, Edit, Search, Reorder, Resize, Aggregate, ColumnChooser, GridExcelExport, GridPdfExport]} />
                        </GridComponent>
                    }
                    {
                        reportSettings.ReportType === 2 &&
                        <PivotViewComponent id='PivotView' ref={d => this.pivotView = d} width={'100%'} height={'580'} gridSettings={{ columnWidth: 140 }} allowExcelExport={true} allowPdfExport={true}
                            locale="da" toolbar={this.pivotToolbarOptions} showToolbar={true} beforeExport={this.beforeExport.bind(this)} displayOption={this.displayOption} dataSourceSettings={this.dataSourceSettings}>
                            <Inject services={[ Toolbar, PDFExport, ExcelExport ]} />
                        </PivotViewComponent>
                    }
                </div>
                <DialogComponent isModal={true} buttons={this.buttons} width='auto' ref={dialog => this.notificationDialog = dialog} target='body' visible={false} showCloseIcon={true} 
                    cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true}/>
            </div>
        )
    }
}

export default Reports
