import React from 'react';
import { ToolbarComponent, ItemsDirective, ItemDirective } from '@syncfusion/ej2-react-navigations'; //TabComponent, TabItemDirective, TabItemsDirective, 
// import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Page, Sort, Toolbar, Selection, Edit, Search, Reorder, Resize } from '@syncfusion/ej2-react-grids';
import { TreeGridComponent, ColumnsDirective as TreeGridColumnsDirective, ColumnDirective as TreeGridColumnDirective, Filter as TreeGridFilter, 
    Sort as TreeGridSort, Inject as TreeGridInject, Edit as TreeGridEdit, Page as TreeGridPage, Toolbar as TreeGridToolbar } from '@syncfusion/ej2-react-treegrid';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { CheckBoxComponent, SwitchComponent  } from '@syncfusion/ej2-react-buttons';
import { DropDownList, DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Redirect } from 'react-router-dom';
import NavigationPrompt from '../../components/NavigationPrompt';

import BaseComponent from '../../components/BaseComponent';
import Axios from 'axios';
import Card from '../../components/Card';
import { ValidateEmail } from '../../utilities/Helper';

import Breadcrumb from '../../components/Breadcrumb';
import regionService from '../../services/RegionService';
import SupplierService from '../../services/Supplier/SupplierService';
import CustomerService from '../../services/Customer/CustomerService';
import SettingsService from '../../services/SettingsService';
import SessionService from '../../services/SessionService';

// import SupplierContact from './SupplierContact';
// import VendorSettings from './VendorSettings';

import '../../styles/supplier.scss';
import '../../styles/tabs.scss';
import Loading from '../../components/Loading';
import Localization from '../../utilities/Localization';
import NumberFormat from 'react-number-format';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class Supplier extends BaseComponent {
    moduleName = "Leverandør";
    pageName = "Leverandør Info";
    supplierContactGridSettingsKey = "SupplierContactList";
    supplierContactGridSettings = '';
    isFromProduct = false;

    constructor() {
        super();

        this.state = {
            loading: true,
            supplierData: {
                AllOrderInterval: 0,
                SupplierNo: '',
                Company: '',
                Street: '',
                PostalCodeId: null,
                CityId: null,
                RegionId: null,
                CountryId: null,
                MainPhone: '',
                MainEmail: '',
                VatNo: '',
                DeliveryTimeInDays: '',
                OrderTimeSend: '',
                OrderOutput: null,
                OrderOutputFormat: null,
                DeptOrderInterval: [],
                OrderOutputEmails: [],
                Contacts: [],
                SupplierConfirmation: false,
                BackOrder: false,
                BackOrderDays: 0
            },
            breadcrumbs: [{text: 'Leverandørliste', link: '/leverandor/leverandorliste'}, 'Leverandør Info'],
            dialogOpts: {
                redirectOnClose: false,
                redirectTo: ''
            },
            moduleState: 'add',
            isEditMode: false,
            currentTabSelected: 0,
            undoRedoStatus: {
                Redo: false,
                RedoAction: '',
                Undo: false,
                UndoAction: ''
            },
            redirect: {
                to: '',
                isRedirect: false
            },
            hasUnsavedChanges: false,
            tempContactList: [],
            hasEdit: {
                info: false,
                settings: false
            },
            tabPages: [],
            hasNext: false,
            hasPrev: false
        }

        this.regionSource = []
        this.postalCodes = [];
        this.cities = [];
        this.regions = [];
        this.countries = [];
        this.outputData = [];
        this.fileFormatData = [];
        this.supplierContactData = [];
        this.infoFields = ["SupplierNo", "Company", "Street", "MainPhone", "MainEmail", "VatNo" ];
        this.settingsFields = ["DeliveryTimeInDays", "AllOrderInterval", "OrderTimeSend", "OrderOutput", "OrderOutputFormat", "OutputEmails", "BackOrderDays"];

        this.tabHeader = [ {text: 'Leverandør Info'}, 
            {text: 'Leverandør Kontakt'}, 
            {text: 'Leverandør Indstillinger'}
        ];

        this.onPostalCodeFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('Value', 'startswith', e.text, true) : query;
            e.updateData(this.postalCodes, query)
        }

        this.onCityFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('ShortName', 'startswith', e.text, true) : query;
            e.updateData(this.cities, query)
        }

        this.onRegionFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('Name', 'startswith', e.text, true) : query;
            e.updateData(this.regions, query)
        }

        this.onCountryFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('Name', 'startswith', e.text, true) : query;
            e.updateData(this.countries, query)
        }
        
        // this.supplierInfoToolbarClicked = this.supplierInfoToolbarClicked.bind(this);
        this.toggleSupplierInfoFields = this.toggleSupplierInfoFields.bind(this);
        this.dialogOpen = this.dialogOpen.bind(this);
        this.dialogClose = this.dialogClose.bind(this);
        this.refreshSupplier = this.refreshSupplier.bind(this);
        this.undoRedoOptions = this.undoRedoOptions.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.onAddEmail = this.onAddEmail.bind(this);
        this.onChangeByName = this.onChangeByName.bind(this);
        this.supplierInfoTab = this.supplierInfoTab.bind(this);
        this.vendorSettingsTab = this.vendorSettingsTab.bind(this);
        this.supplierContactTab = this.supplierContactTab.bind(this);
        this.onTabSelected = this.onTabSelected.bind(this);
        this.supplierInfoToolbarClicked = this.supplierInfoToolbarClicked.bind(this);
        this.onChange = this.onChange.bind(this);
        this.init = this.init.bind(this);
        this.initiateSave = this.initiateSave.bind(this);
        this.onSupplierConfirmationChange = this.onSupplierConfirmationChange.bind(this);
        this.onBackOrderChange = this.onBackOrderChange.bind(this);

        this.onTreeActionBegin = this.onTreeActionBegin.bind(this);
        this.onTreeActionComplete = this.onTreeActionComplete.bind(this);
        this.onUnload = this.onUnload.bind(this);

        this.treeGridEditSettings = {
            allowEditing: true,
            mode: 'row'
        }

        this.supplierContactToolbarOptions = ['Add', 'Edit', 'Update', 'Cancel', 'Delete']
        this.supplierContactEditSettings = {
            allowAdding: true,
            allowEditing: true,
            allowDeleting: true,
            showDeleteConfirmDialog: true
        }

        this.selectionSettings = {
            type: 'Single'
        };

        this.filterSettings = {
            type: 'Menu'
        };

        this.pageSettings = {
            pageSize: 15
        };

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.buttons = [{
            // Click the footer buttons to hide the Dialog
            click: this.dialogClose,
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        /* End Dialog options  */
    }

    onUnload = e => {
        const { hasUnsavedChanges } = this.state;
        if (hasUnsavedChanges) {
            this.NavigationPromptRef.showModal(window.location.href);
            e.preventDefault();
            e.returnValue = '';
            return null;
        }
        else {
            return;
        }
    }

    async componentDidMount() {
        super.componentDidMount();
        window.addEventListener("beforeunload", this.onUnload);
        source.cancel();
        source = CancelToken.source();

        if (!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }
        else if (!super.hasMenuItem('Leverandørliste')) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }

        this.init();

        const { match } = this.props;
        const supplierId = match && match.params && match.params.id;
        let moduleState = 'add';
        let { supplierData, isEditMode, tabPages, hasNext, hasPrev, currentTabSelected } = this.state;

        const sessionInfo = await SessionService.GetSessionInfo();
        this.CustomerId = sessionInfo.Id

        const outputData = await SettingsService.GetOutputOptions(source.token);
        const fileFormatData = await SettingsService.GetFileFormats(source.token);

        this.outputData = outputData;
        this.fileFormatData = fileFormatData;

        if (supplierId) {
            const supplierResult = await SupplierService.GetSupplier(supplierId, source.token);
            if (supplierResult.HasError) {
                let result = super.processModuleResponse(supplierResult);
                this.setState(result);
                return;
            }

            supplierData = supplierResult;
            moduleState = 'edit';

            const supplierContacts = await SupplierService.GetContacts(supplierId, source.token);
            this.supplierContactData = Object.assign([], supplierContacts.Data);
            supplierData.Contacts = Object.assign([], supplierContacts.Data);
        }

        const departments = await CustomerService.GetAccountDepartments(sessionInfo.Id, source.token);
        supplierData.DeptOrderInterval = departments.map(dept => {
            let deptOrderInterval = null;

            if (supplierData.DeptOrderInterval) {
                deptOrderInterval = supplierData.DeptOrderInterval.find(x => x.DeptId === dept.Id);
            }
            
            return {
                Id: dept.Id,
                Name: dept.Name,
                OrderInterval: deptOrderInterval && deptOrderInterval.OrderInterval && deptOrderInterval.OrderInterval != 0 ? deptOrderInterval.OrderInterval : null
            }
        });
        
        this.accountDepartments = Object.assign([], departments);
        this.customerMachineDepartmentData = departments.map(dept => {
            let setup = null;
            
            if (supplierData.DeptMachCustomerNo) {
                setup = supplierData.DeptMachCustomerNo.find(d => d.DeptId === dept.Id);
    
                if (setup) {
                    dept.CustomerNo = setup.CustomerNo;
                }
            }

            if (dept.Machines.length > 0) {
                dept.Machines.forEach(m => {
                    m.Name = m.CustomName;
                    m.Type = 'Machine';

                    let machineSetup = setup && setup.Machines ? setup.Machines.find(x => x.MachineId === m.Id) : null;
                    if (machineSetup) {
                        m.CustomerNo = machineSetup.CustomerNo;
                    }
                });
            }

            return dept;
        });

        this.departmentLite = departments.map(d => {
            return {
                Id: d.Id,
                Name: d.Name
            }
        });

        // Get city and region
        const regions = await regionService.GetRegion(source.token);
        this.regionSource = regions;

        let tempRegions = [], tempCities = [];

        if (Array.isArray(regions)) {
            regions.forEach(region => {
                tempRegions.push(region);
    
                region.Cities.forEach(city => {
    
                    // if (tempCities.some(x => x.ShortName === city.ShortName)) 
                    //     return;
    
                    tempCities.push(city);
    
                    city.PostalCodes.forEach(postal => {
                        this.postalCodes.push(postal);
                    })
                });
            });
        }

        tempRegions = [...new Set(tempRegions)];
        tempCities = [...new Set(tempCities)];

        tempRegions.sort((a,b) => (a.value > b.value) ? 1 : -1);
        tempCities.sort((a,b) => (a.value > b.value) ? 1 : -1);

        this.cities = tempCities;
        this.regions = tempRegions;

        const countryResult = await SettingsService.GetCountries(source.token);
        this.countries = countryResult;
        
        const gridSettings = await SettingsService.GetGridSettings(this.supplierContactGridSettingsKey, source.token);
        if (!gridSettings.HasError) {
            this.supplierContactGridSettings = gridSettings.Settings;
        }

        let tabCount = 0;
        if (super.hasMenuItemTabAccess("Leverandørkort", "Leverandør Info")) {
            tabPages[tabCount] = 0;
            tabCount++;
        }

        if (super.hasMenuItemTabAccess("Leverandørkort", "Leverandør Kontakt")) {
            tabPages[tabCount] = 1;
            tabCount++;
        }

        if (super.hasMenuItemTabAccess("Leverandørkort", "Leverandør Indstillinger")) {
            tabPages[tabCount] = 2;
            tabCount++;
        }

        if (tabPages) {
            const selectedTabIndex = tabPages.indexOf(currentTabSelected);
            hasNext = selectedTabIndex > -1 && selectedTabIndex < (tabPages.length - 1);
            hasPrev = selectedTabIndex > 0 && selectedTabIndex < tabPages.length;
        }

        this.setState({ loading: false, supplierData, moduleState, isEditMode, tabPages, hasNext, hasPrev }, () => {
            // setTimeout(() => {
            //     if (moduleState === 'edit') {
            //         this.toggleSupplierInfoFields(false);
            //     }
            // }, 200);
        });
    }

    async componentWillUnmount() {
        source.cancel();
        window.removeEventListener("beforeunload", this.onUnload);
    }

    init() {

    }

    onChange(args) {
        // let input = args.container.querySelector('input');
        // if (input) {
        //     let { supplierData } = this.state;
        //     supplierData[input.name] = args.value
        //     this.setState({ supplierData });
        // }
        
        let { supplierData, hasEdit } = this.state;
        if (this.infoFields.includes(args.target.name)) {
            hasEdit.info = true;
        } else if (this.settingsFields.includes(args.target.name)) {
            hasEdit.settings = true;
        }
        supplierData[args.target.name] = args.target.value;

        if (args.target.name === "AllOrderInterval") {
            supplierData.DeptOrderInterval.forEach(x => {
                if (x.OrderInterval === parseInt(supplierData.AllOrderInterval)) {
                    x.OrderInterval = null;
                    document.getElementById(`DeliveryTimeInDays_${x.Id}`).value = null;
                }
            });
        }
        this.setState({ supplierData, hasUnsavedChanges: true, hasEdit });
    }

    onPostalCodeChange(args) {
        if (args.isInteracted) {
            let city = this.cities.find(c => c.Id === args.itemData.CityId);

            this.CityRef.value = args.itemData.CityId;

            let tempRegions = this.regions.filter(region => region.Id === city.RegionId);
            let selectedRegion = this.regions.find(x => x.Id === city.RegionId);

            this.RegionRef.dataSource = tempRegions;
            this.RegionRef.dataBind();

            this.RegionRef.value = city.RegionId;
            this.RegionRef.text =  selectedRegion.Name;

            let { supplierData, hasEdit } = this.state;
            hasEdit.info = true;
            supplierData.PostalCodeId = args.value;
            supplierData.CityId = args.itemData.CityId;
            supplierData.RegionId = city.RegionId
            supplierData.CountryId = selectedRegion.CountryId;
            this.setState({ supplierData, hasUnsavedChanges: true, hasEdit });
        }
    }

    onCityChange(args) {
        if (args.isInteracted) {
            let { supplierData } = this.state;

            let tempRegions = this.regionSource.filter(region => region.Id === args.itemData.RegionId);    
            this.regions = tempRegions;
            this.RegionRef.text = null;
            this.RegionRef.dataSource = tempRegions;
            this.RegionRef.dataBind();
            
            if (supplierData.PostalCodeId !== args.itemData.PostalCodes[0].Id) {
                supplierData.PostalCodeId = args.itemData.PostalCodes[0].Id;
            }

            let selectedRegion = this.regions.find(x => x.Id === args.itemData.RegionId);
            this.RegionRef.value = args.itemData.RegionId;
            this.RegionRef.text = selectedRegion.Name;

            supplierData.CityId = args.value;
            supplierData.RegionId = args.itemData.RegionId;

            this.setState({ supplierData, hasUnsavedChanges: true });
        }
    }

    onRegionChange(args) {
        if (args.isInteracted) {
            let { supplierData } = this.state;
            supplierData.RegionId = args.value;
            supplierData.CountryId = args.itemData.CountryId
            this.setState({ supplierData, hasUnsavedChanges: true });
        }
    }

    onCountryChange(args) {
        if (args.isInteracted) {
            let { supplierData } = this.state;
            supplierData.CountryId = args.value;
            this.setState({ supplierData });
        }
    }
    
    refreshDropdown = () => {
        this.PostalCodeRef.showPopup();
        this.CityRef.showPopup();
        this.RegionRef.showPopup();

        setTimeout(() => {
            this.PostalCodeRef.hidePopup();
            this.CityRef.hidePopup();
            this.RegionRef.hidePopup();
        }, 200);
    }

    async refreshSupplier() {
        let { supplierData, currentTabSelected } = this.state;
        const supplierResult = await SupplierService.GetSupplier(supplierData.Id, source.token);
        supplierData = supplierResult;
        
        const supplierContacts = await SupplierService.GetContacts(supplierData.Id, source.token);
        supplierData.Contacts = supplierContacts.Data;

        const departments = await CustomerService.GetAccountDepartments(this.CustomerId, source.token);
        this.accountDepartments = departments;
        
        switch(currentTabSelected) {
            case 0:
                this.SupplierNoRef.value = supplierData.SupplierNo;
                this.CompanyRef.value = supplierData.Company;
                this.StreetRef.value = supplierData.Street;

                if (supplierData.PostalCode) {
                    this.PostalCodeRef.value = supplierData.PostalCode.Id;
                    // let elemPostalCode = document.getElementById('ddlPostalCode').ej2_instances[0];
                    // if (elemPostalCode) {
                    //     elemPostalCode.refresh();
                    // }
                }
                else {
                    this.PostalCodeRef.value = null;
                    this.PostalCodeRef.text = null;
                }

                if (supplierData.City) {
                    this.CityRef.value = supplierData.City.Id;
                }
                else {
                    this.CityRef.value = null;
                    this.CityRef.text = null;
                }

                if (supplierData.Region) {
                    this.RegionRef.value = supplierData.Region.Id;
                }
                else {
                    this.RegionRef.value = null;
                    this.RegionRef.text = null;
                }

                if (supplierData.Country) {
                    this.CountryRef.value = supplierData.Country.Id;
                }
                else {
                    this.CountryRef.value = null;
                    this.CountryRef.text = null;
                }

                this.MainPhoneRef.value = supplierData.MainPhone;
                this.MainEmailRef.value = supplierData.MainEmail;
                this.VatNoRef.value = supplierData.VatNo;

                this.customerMachineDepartmentData = departments.map(dept => {
                    let setup = null;
                    if (supplierData.DeptMachCustomerNo) {
                        setup = supplierData.DeptMachCustomerNo.find(d => d.DeptId === dept.Id);
            
                        if (setup) {
                            dept.CustomerNo = setup.CustomerNo;
                        }
                    }
        
                    if (dept.Machines.length > 0) {
                        dept.Machines.forEach(m => {
                            m.Name = m.CustomName;
                            m.Type = 'Machine';
        
                            let machineSetup = setup && setup.Machines ? setup.Machines.find(x => x.MachineId === m.Id) : null;
                            if (machineSetup) {
                                m.CustomerNo = machineSetup.CustomerNo;
                            }
                        });
                    }
        
                    return dept;
                });

                this.customerMachineDepartmentRef.dataSource = this.customerMachineDepartmentData;
                this.customerMachineDepartmentRef.refresh();
                break;
            case 1:
                this.supplierContactGridRef.dataSource = supplierData.Contacts;
                this.supplierContactGridRef.refresh();
                break;
            case 2:
                this.DeliveryTimeInDaysRef.value = supplierData.DeliveryTimeInDays;
                this.OrderTimeSendRef.value = supplierData.OrderTimeSend;
                this.AllOrderIntervalRef.enabled = supplierData.AllOrderInterval;
                this.OrderOutputRef.enabled = supplierData.OrderOutput;
                this.OrderOutputFormatRef.enabled = supplierData.OrderOutputFormat;
                break;
        }
        
        supplierData = this.refreshDeptOrderInterval(supplierData);

        this.setState({ supplierData, hasUnsavedChanges: false });
    }

    refreshDeptOrderInterval(supplierData) {
        supplierData.DeptOrderInterval = this.accountDepartments.map(dept => {
            let deptOrderInterval = null;

            if (supplierData.DeptOrderInterval) {
                deptOrderInterval = supplierData.DeptOrderInterval.find(x => x.DeptId === dept.Id);
            }
            
            return {
                Id: dept.Id,
                Name: dept.Name,
                OrderInterval: deptOrderInterval && deptOrderInterval.OrderInterval && deptOrderInterval.OrderInterval != 0 ? deptOrderInterval.OrderInterval : null
            }
        });

        return supplierData;
    }

    toggleSupplierInfoFields(enabled, isEmptyValues = false) {
        const { currentTabSelected } = this.state;

        switch(currentTabSelected) {
            case 0:
                this.SupplierNoRef.disabled = !enabled;
                this.CompanyRef.disabled = !enabled;
                this.StreetRef.disabled = !enabled;
                this.PostalCodeRef.enabled = enabled;
                this.CityRef.enabled = enabled;
                this.RegionRef.enabled = enabled;
                // this.CountryRef.disabled = !enabled;
                this.MainPhoneRef.disabled = !enabled;
                this.MainEmailRef.disabled = !enabled;
                this.VatNoRef.disabled = !enabled;

                if (isEmptyValues) {
                    this.SupplierNoRef.value = '';
                    this.CompanyRef.value = '';
                    this.StreetRef.value = '';

                    this.PostalCodeRef.value = null;
                    this.PostalCodeRef.text = null;
                    this.CityRef.value = null;
                    this.CityRef.text = null;
                    this.RegionRef.value = null;
                    this.RegionRef.text = null;

                    this.CountryRef.value = null;
                    this.MainPhoneRef.value = null;
                    this.MainEmailRef.value = null;
                    this.VatNoRef.value = null;
                }
                break;
            case 1:
                if (enabled) {
                    this.supplierContactGridRef.element.classList.remove('c-disablegrid');
                    let wrapper = document.getElementsByClassName('supplier-contact-wrapper')[0];
                    if (wrapper) {
                        wrapper.classList.remove('c-disablegrid-wrapper');
                    }
                }
                else {
                    this.supplierContactGridRef.element.classList.add('c-disablegrid');
                    let wrapper = document.getElementsByClassName('supplier-contact-wrapper')[0];
                    if (wrapper) {
                        wrapper.classList.add('c-disablegrid-wrapper');
                    }
                }
                break;
            case 2:
                this.DeliveryTimeInDaysRef.enabled = enabled;
                this.OrderTimeSendRef.enabled = enabled;
                this.AllOrderIntervalRef.enabled = enabled;
                this.OrderOutputRef.enabled = enabled;
                this.OrderOutputFormatRef.enabled = enabled;

                let customerDepartments = document.querySelector('table.customer-department-table');
                if (customerDepartments) {
                    let tableData = customerDepartments.querySelectorAll('tbody tr.department-row-data');

                    for (let index = 0; index < tableData.length; index++) {
                        let column = tableData[index].querySelector('td:last-child');
                        // let span = column.querySelector('span');
                        let input = column.querySelector('input.e-control.e-input');
                        
                        if (enabled) {
                            // span.classList.remove('e-disabled');
                            input.classList.remove('e-disabled');
                            input.removeAttribute('disabled');
                        }
                        else {
                            // span.classList.add('e-disabled');
                            input.classList.add('e-disabled');
                            input.setAttribute('disabled', 'disabled');
                        }
                    }
                }
                
                if (isEmptyValues) {
                    this.DeliveryTimeInDaysRef.value = '';
                    this.OrderTimeSendRef.value = '';
                    this.AllOrderIntervalRef.value = '';
                    this.OrderOutputRef.value = null;
                    this.OrderOutputRef.text = null;
                    this.OrderOutputFormatRef.value = null;
                    this.OrderOutputFormatRef.text = null;

                    let deptOrderIntervals = document.querySelectorAll(".department-row-data input");
                    if (deptOrderIntervals) {
                        for (let i = 0; i < deptOrderIntervals.length; i++) {
                            deptOrderIntervals[i].value = null;
                        }
                    }
                }
                break;
        }
    }

    onTreeActionBegin(args) {
        let { isEditMode } = this.state;
        if (args.type === 'edit') {
            if (!isEditMode) {
                args.cancel = true;
            }
        }
    }

    onTreeActionComplete(args) {
        if (args.requestType === 'save') {
            let { supplierData, hasEdit } = this.state;
            hasEdit.info = true;
            let rowData = args.data;
            
            if (rowData.Type === "Machine") {
                let dept = supplierData.DeptMachCustomerNo.find(x => x.DeptId === rowData.parentItem.Id);
    
                if (dept) {
                    if (dept.Machines && dept.Machines.some(m => m.MachineId === rowData.Id)) {
                        let machine = dept.Machines.find(m => m.MachineId === rowData.Id);
                        machine.CustomerNo = rowData.CustomerNo;
                    }
                    else {
                        if (dept.Machines) {
                            dept.Machines.push({ MachineId: rowData.Id, CustomerNo: rowData.CustomerNo });
                        }
                        else {
                            dept.Machines = [];
                            dept.Machines.push({ MachineId: rowData.Id, CustomerNo: rowData.CustomerNo });
                        }
                    }
                }
                else {
                    supplierData.DeptMachCustomerNo.push({
                        DeptId: rowData.parentItem.Id,
                        CustomerNo: "",
                        Machines: [{ MachineId: rowData.Id, CustomerNo: rowData.CustomerNo }]
                    });
                }
            }
            else {
                let dept = supplierData.DeptMachCustomerNo.find(x => x.DeptId === rowData.Id);
                if (dept) {
                    dept.CustomerNo = rowData.CustomerNo;
                }
                else {
                    supplierData.DeptMachCustomerNo.push({
                        DeptId: rowData.Id,
                        CustomerNo: rowData.CustomerNo,
                        Machines: []
                    });
                }
            }

            this.setState({ supplierData, hasUnsavedChanges: true, hasEdit });
        }
    }

    supplierInfoTab() {
        const { supplierData, moduleState, isEditMode } = this.state
        
        let isEnabled = false;
        if (moduleState === 'add') {
            isEnabled = true;
        }
        else if (isEditMode) {
            isEnabled = true;
        }
        
        return (
            <div className="tab-control--item supplier-info-wrapper">
                <div className="supplier-info-pane">
                    <table className="supplier-info-table" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td className="label">Leverandør Nr.<span className="required">*</span></td>
                                <td>
                                    {/* <TextBoxComponent ref={ref => this.SupplierNoRef = ref} value={supplierData.SupplierNo} enabled={isEnabled} name="SupplierNo" /> */}
                                    <input type="text" ref={ref => this.SupplierNoRef = ref} value={supplierData.SupplierNo} disabled={!isEnabled} name="SupplierNo" className="e-control e-textbox e-lib e-input" onChange={this.onChange} />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Firma Navn<span className="required">*</span></td>
                                <td>
                                    {/* <TextBoxComponent ref={ref => this.CompanyRef = ref} value={supplierData.Company} enabled={isEnabled} name="Company" /> */}
                                    <input type="text" ref={ref => this.CompanyRef = ref} value={supplierData.Company} disabled={!isEnabled} name="Company" className="e-control e-textbox e-lib e-input" onChange={this.onChange} />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Adresse</td>
                                <td>
                                    {/* <TextBoxComponent ref={ref => this.StreetRef = ref} value={supplierData.Street} enabled={isEnabled} name="Street" /> */}
                                    <input type="text" ref={ref => this.StreetRef = ref} value={supplierData.Street} disabled={!isEnabled} name="Street" className="e-control e-textbox e-lib e-input" onChange={this.onChange} />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Post Nr.</td>
                                <td>
                                    <DropDownListComponent ref={ref => this.PostalCodeRef = ref} id="ddlPostalCode" name="PostalCodeId" value={supplierData.PostalCodeId} dataSource={this.postalCodes} 
                                        filtering={this.onPostalCodeFiltering.bind(this)} filterBarPlaceholder={Localization.General.SearchPostalCode} allowFiltering={true} enabled={isEnabled}
                                        fields={{ text: 'Value', value: 'Id' }} change={this.onPostalCodeChange.bind(this)} locale="da-DK" />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">By</td>
                                <td>
                                    <DropDownListComponent ref={ref => this.CityRef = ref} id="ddlCity" name="CityId" value={supplierData.CityId} dataSource={this.cities} 
                                        filtering={this.onCityFiltering.bind(this)} filterBarPlaceholder={Localization.General.SearchCity} allowFiltering={true} enabled={isEnabled}
                                        fields={{ text: 'ShortName', value: 'Id' }} change={this.onCityChange.bind(this)} locale="da-DK" />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Region</td>
                                <td>
                                    <DropDownListComponent ref={ref => this.RegionRef = ref} id="ddlRegion" name="RegionId" value={supplierData.RegionId} dataSource={this.regions} 
                                        filtering={this.onRegionFiltering.bind(this)} filterBarPlaceholder={Localization.General.SearchRegion} allowFiltering={true} enabled={isEnabled}
                                        fields={{ text: 'Name', value: 'Id' }} change={this.onRegionChange.bind(this)} locale="da-DK" />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Land</td>
                                <td>
                                    {/* <input type="text" ref={ref => this.CountryRef = ref} value={supplierData.Country} disabled={!isEnabled} name="Country" className="e-control e-textbox e-input" onChange={this.onChange} /> */}
                                    <DropDownListComponent ref={ref => this.CountryRef = ref} id="ddlCountry" name="CountryId" value={supplierData.CountryId} dataSource={this.countries} 
                                        filtering={this.onCountryFiltering.bind(this)} filterBarPlaceholder={Localization.General.SearchCountry} allowFiltering={true} enabled={false}
                                        fields={{ text: 'Name', value: 'Id' }} change={this.onCountryChange.bind(this)} locale="da-DK" />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Tlf. Nr.</td>
                                <td>
                                    {/* <TextBoxComponent ref={ref => this.MainPhoneRef = ref} value={supplierData.MainPhone} enabled={isEnabled} name="MainPhone" /> */}
                                    <input type="text" ref={ref => this.MainPhoneRef = ref} value={supplierData.MainPhone} disabled={!isEnabled} name="MainPhone" className="e-control e-textbox e-input" onChange={this.onChange} />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Mail<span className="required">*</span></td>
                                <td>
                                    {/* <TextBoxComponent ref={ref => this.MainEmailRef = ref} value={supplierData.MainEmail} enabled={isEnabled} name="MainEmail" /> */}
                                    <input type="text" ref={ref => this.MainEmailRef = ref} value={supplierData.MainEmail} disabled={!isEnabled} name="MainEmail" className="e-control e-textbox e-input" onChange={this.onChange} />
                                     {/* change={this.onChange.bind(this)} */}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Cvr. Nr.</td>
                                <td>
                                    {/* <TextBoxComponent ref={ref => this.VatNoRef = ref} value={supplierData.VatNo} enabled={isEnabled} name="VatNo" /> */}
                                    <input type="text" ref={ref => this.VatNoRef = ref} value={supplierData.VatNo} disabled={!isEnabled} name="VatNo" className="e-control e-textbox e-input" onChange={this.onChange} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="supplier-customer-machinedepartment-pane">
                    <Card headerText="Vores kunde nr. pr afdeling / automat" subText="Hvis separat fakturering ønskes fra denne leverandør kan der indsættes forskellige kunde numre pr. afdeling/automat">
                        {
                            !isEnabled && <div className="supplier-tree-overlay"></div>
                        }
                        <TreeGridComponent ref={ref => this.customerMachineDepartmentRef = ref } dataSource={this.customerMachineDepartmentData} childMapping="Machines" gridLines='Both'
                            allowFiltering={true} filterSettings={{ type: 'Menu', hierarchyMode: 'Parent' }} locale="da" editSettings={this.treeGridEditSettings}
                            actionBegin={this.onTreeActionBegin} actionComplete={this.onTreeActionComplete}>
                            <TreeGridColumnsDirective>
                                <TreeGridColumnDirective field='Name' headerText='Afdeling/Automat' textAlign='left' allowEditing={false} allowFiltering={false} />
                                <TreeGridColumnDirective field='CustomerNo' headerText='Vores Kunde Nr.' textAlign='Center' allowFiltering={false} />
                            </TreeGridColumnsDirective>
                            <TreeGridInject services={[TreeGridFilter, TreeGridSort, TreeGridPage, TreeGridEdit, TreeGridToolbar]}/>
                        </TreeGridComponent>
                    </Card>
                </div>
            </div>
        )
    }

    supplierContactTab() {
        return (
            <div className="tab-control--item supplier-contact-wrapper">
                <GridComponent ref={ref => this.supplierContactGridRef = ref } dataSource={this.supplierContactData} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings} toolbar={this.supplierContactToolbarOptions}
                    editSettings={this.supplierContactEditSettings} selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da"
                    gridLines="Both" allowTextWrap='true' toolbarClick={this.stockClickHandler.bind(this)}
                    allowReordering={true} allowResizing={true} actionComplete={this.onSupplierContactActionComplete.bind(this)} columnDragStart={this.onColumnDragStart.bind(this)} 
                    created={this.onSupplierContactCreated.bind(this)} resizeStop={this.onSupplierContactResizeStop.bind(this)}>
                    <ColumnsDirective>
                        <ColumnDirective field="Id" isPrimaryKey={true} visible={false} allowEditing={false} /> 
                        <ColumnDirective field="Name" headerText="Navn" width='100' />
                        <ColumnDirective field="Dept" headerText="Afdeling" width='100' />
                        <ColumnDirective field="Position" headerText="Stilling" width='100' />
                        <ColumnDirective field="Note" headerText="Notat" width='100' />
                        <ColumnDirective field="DirectPhone1" headerText="Tlf. nr. 1" width='100' />
                        <ColumnDirective field="DirectPhone2" headerText="Tlf. nr. 2" width='100' />
                        <ColumnDirective field="Mail" headerText="Mail" width='100' />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Selection, Toolbar, Edit, Search, Reorder, Resize]} />
                </GridComponent>
            </div>
        )
    }

    async onSupplierContactResizeStop(args) {
        let columns = [];

        if (this.supplierContactGridSettings) {
            columns = JSON.parse(this.supplierContactGridSettings);
        }

        if (columns.length > 0) {
            let column = columns.find(c => c.Field === args.column.field);
            if (column) {
                column.FromIndex =  args.column.index;
                column.ToIndex = args.column.index;
                column.Width = args.column.width
            }
            else {
                columns.push({
                    HeaderText: args.column.headerText,
                    Field: args.column.field,
                    FromIndex: args.column.index,
                    ToIndex: args.column.index,
                    Width: args.column.width
                });
            }
        }
        else {
            columns.push({
                HeaderText: args.column.headerText,
                Field: args.column.field,
                FromIndex: args.column.index,
                ToIndex: args.column.index,
                Width: args.column.width
            });
        }

        const payload = {
            Key: this.supplierContactGridSettingsKey,
            Settings: JSON.stringify(columns)
        };

        const result = await SettingsService.SaveGridSettings(payload, source.token);

        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);

            // Adjust size of last column to fill spaces
            let lastColumnIndex = this.supplierContactGridRef.columns.length - 1;
            let totalColumnWidth = this.supplierContactGridRef.columns.filter(x => x.visible).reduce((acc, curVal) => acc + parseInt(curVal.width), 0);
            let lastColumnWidth = this.supplierContactGridRef.element.clientWidth - totalColumnWidth;
            this.supplierContactGridRef.columns[lastColumnIndex].width = this.supplierContactGridRef.columns[lastColumnIndex].width + lastColumnWidth;
            this.supplierContactGridRef.refreshColumns();
        }
    }
    
    onColumnDragStart(args) {
        this.hasDraggedColumn = true;
    }

    onSupplierContactCreated() {
        if (this.supplierContactGridSettings) {
            let settings = JSON.parse(this.supplierContactGridSettings);
            if (settings.length > 0) {
                settings.forEach(setting => {
                    if (setting.FromIndex !== setting.ToIndex) {
                        this.supplierContactGridRef.reorderColumnByIndex(setting.FromIndex, setting.ToIndex);
                    }

                    if (setting.Width) {
                        this.supplierContactGridRef.columns[setting.ToIndex].width = setting.Width;
                    }
                });

                this.supplierContactGridRef.refreshColumns();

                // Prevent resizing of last column
                let lastColumnIndex = this.supplierContactGridRef.columns.length - 1;    
                this.supplierContactGridRef.columns[lastColumnIndex].allowResizing = false;
                this.supplierContactGridRef.refreshColumns();
            }
        }
    }

    async stockClickHandler(args) {
    }
   
    async onSupplierContactActionComplete(args) {
        if (args.requestType === "save") {
            let { supplierData, tempContactList, moduleState } = this.state;

            debugger;
            if (args.data.Id) {
                let contact = supplierData.Contacts.find(c => c.Id === args.data.Id);
                contact.Name = args.data.Name;
                contact.Dept = args.data.Dept;
                contact.Position = args.data.Position;
                contact.Note = args.data.Note;
                contact.DirectPhone1 = args.data.DirectPhone1;
                contact.DirectPhone2 = args.data.DirectPhone2;
                contact.Mail = args.data.Mail;

                if (tempContactList.some(s => s.Id === args.data.Id)) {
                    let t = tempContactList.find(s => s.Id === args.data.Id);
                    t.Name = args.data.Name;
                    t.Dept = args.data.Dept;
                    t.Position = args.data.Position;
                    t.Note = args.data.Note;
                    t.DirectPhone1 = args.data.DirectPhone1;
                    t.DirectPhone2 = args.data.DirectPhone2;
                    t.Mail = args.data.Mail;
                }
                else {
                    tempContactList.push({
                        ...contact,
                        IsDelete: false,
                    });
                }
            }
            else {
                let item = {
                    Id: `T${supplierData.Contacts ? supplierData.Contacts.length + 1 : 1}`,
                    TempId: `T${supplierData.Contacts ? supplierData.Contacts.length + 1 : 1}`,
                    Name: args.data.Name,
                    DeptId: this.selectedDepartment,
                    Dept: args.data.Dept,
                    Position: args.data.Position,
                    Note: args.data.Note,
                    DirectPhone1: args.data.DirectPhone1,
                    DirectPhone2: args.data.DirectPhone2,
                    Mail: args.data.Mail,
                    IsDelete: false,
                }

                if (supplierData.Contacts && supplierData.Contacts.some(s => !s.Id && s.Name == args.data.Name)) {
                    let contact = supplierData.Contacts.find(s => !s.Id && s.Name == args.data.Name);
                    contact.Id = item.Id;
                    contact.TempId = item.TempId;
                    contact.Name = args.data.Name;
                    contact.Dept = args.data.Dept;
                    contact.Position = args.data.Position;
                    contact.Note = args.data.Note;
                    contact.DirectPhone1 = args.data.DirectPhone1;
                    contact.DirectPhone2 = args.data.DirectPhone2;
                    contact.Mail = args.data.Mail;

                    if (tempContactList.some(s => s.Id === args.data.Id)) {
                        let t = tempContactList.find(s => s.Id === args.data.Id);
                        t.Name = args.data.Name;
                        t.Dept = args.data.Dept;
                        t.Position = args.data.Position;
                        t.Note = args.data.Note;
                        t.DirectPhone1 = args.data.DirectPhone1;
                        t.DirectPhone2 = args.data.DirectPhone2;
                        t.Mail = args.data.Mail;
                    }
                    else {
                        tempContactList.push({
                            ...contact,
                            IsDelete: false,
                        });
                    }
                }
                else {
                    supplierData.Contacts.push(item);
                    tempContactList.push(item);
                }
                this.supplierContactGridRef.dataSource = supplierData.Contacts;
                this.supplierContactGridRef.refresh();
            }
            
            this.setState({ supplierData, tempContactList, hasUnsavedChanges: true });
            // if (moduleState !== 'add') {
            //     this.supplierInfoToolbarClicked({ item: { id: "save" } });
            // }
        }
        else if (args.requestType === "delete") {
            let { supplierData, tempContactList } = this.state;
            if (args.data[0].Id) {

                tempContactList.push({
                    Id: args.data[0].Id,
                    IsDelete: true
                });

                this.setState({ supplierData, tempContactList, hasUnsavedChanges: true });
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.supplierContactGridRef.getColumns();
            let tempCols = [], orderedColumns = [];

            if (this.supplierContactGridSettings) {
                orderedColumns = JSON.parse(this.supplierContactGridSettings);
            }

            columns.forEach((column, index) => {
                if (index === args.fromIndex) {
                    tempCols.push({
                        HeaderText: column.headerText,
                        Field: column.field,
                        FromIndex: column.index,
                        ToIndex: index
                    });
                }
                else {
                    if (orderedColumns.some(x => x.HeaderText === column.headerText)) { //Check if previously saved column has been reordered
                        let col = orderedColumns.find(x => x.HeaderText === column.headerText)
                        if (col.ToIndex !== index) {
                            tempCols.push({
                                HeaderText: col.HeaderText,
                                Field: col.Field,
                                FromIndex: col.FromIndex,
                                ToIndex: index
                            });
                        }
                    }
                }
            });

            const payload = {
                Key: this.supplierContactGridSettingsKey,
                Settings: JSON.stringify(tempCols)
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                let lastColumnIndex = this.supplierContactGridRef.columns.length - 1;
                for (let index = 0; index < this.supplierContactGridRef.columns.length; index++) {
                    let column = this.supplierContactGridRef.columns[index];
                    if (index === lastColumnIndex) { column.allowResizing = false }
                    else { column.allowResizing = true }
                }
                this.supplierContactGridRef.refreshColumns();
            }

            this.hasDraggedColumn = false;
        } else if (args.requestType === "add") {
            this.supplierContactGridRef.startEdit();
        }
    }

    async supplierInfoToolbarClicked(args) {
        if (args.item.id === 'add') {
            this.props.history.push('/leverandor/add');
            let supplierData = {
                AllOrderInterval: 0,
                SupplierNo: '',
                Company: '',
                Street: '',
                PostalCodeId: null,
                CityId: null,
                RegionId: null,
                CountryId: null,
                MainPhone: '',
                MainEmail: '',
                VatNo: '',
                DeliveryTimeInDays: '',
                OrderTimeSend: '',
                OrderOutput: null,
                OrderOutputFormat: null,
                DeptOrderInterval: [],
                OrderOutputEmails: [],
                Contacts: [],
                SupplierConfirmation: false,
                BackOrder: false,
                BackOrderDays: 0
            }

            supplierData = this.refreshDeptOrderInterval(supplierData);

            this.supplierContactData = [];
            this.toggleSupplierInfoFields(true, true);
            this.setState({ moduleState: 'add', supplierData, tempContactList: [] }, () => {
                this.onTabSelected(0);
            });
        }
        else if (args.item.id === 'search') {
            this.props.history.push('/leverandor/leverandorliste');
        }
        else if (args.item.id === 'edit') {
            this.toggleSupplierInfoFields(true);
            this.setState({ isEditMode: true });
        }
        else if (args.item.id === 'delete') {
            source.cancel();
            source = CancelToken.source();

            let { supplierData, dialogOpts } = this.state;

            const result = await SupplierService.DeleteSupplier(supplierData.Id, source.token);
            if (!result.HasError) {
                dialogOpts.redirectOnClose = true;
                dialogOpts.redirectTo = '/leverandor/leverandorliste';
                this.setState({ dialogOpts }, () => {
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessDelete.replace("{context}", "Leverandør"));
                });
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
        else if (args.item.id === 'save') {
            this.initiateSave();
        }
        else if (args.item.id === 'cancel') {
            let { moduleState, currentTabSelected } = this.state;
            if (moduleState === 'edit') {
                switch(currentTabSelected) {
                    case 0:
                        this.refreshDropdown();
                        break;
                }
                await this.refreshSupplier();
                this.toggleSupplierInfoFields(false);
                this.setState({ isEditMode: false });
            }
            else {
                this.props.history.push('/leverandor/leverandorliste');
            }
        }
        else if (args.item.id === 'undo') {
            let { supplierData, currentTabSelected } = this.state;
            const result = await SupplierService.UndoSupplier(supplierData.Id, source.token);

            if(!result.HasError) {
                // this.undoRedoOptions(result.Undo, result.Redo, result.UndoAction, result.RedoAction);
                this.setState({ undoRedoStatus: result });
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Leverandør"));
                
                switch(currentTabSelected) {
                    case 0:
                        this.refreshDropdown();
                        break;
                }

                setTimeout(() => {
                    this.refreshSupplier();
                }, 400);
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
        else if (args.item.id === 'redo') {
            let { supplierData, currentTabSelected } = this.state;
            const result = await SupplierService.RedoSupplier(supplierData.Id, source.token);

            if(!result.HasError) {
                // this.undoRedoOptions(result.Undo, result.Redo, result.UndoAction, result.RedoAction);
                this.setState({ undoRedoStatus: result });
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Leverandør"));

                switch(currentTabSelected) {
                    case 0:
                        this.refreshDropdown();
                        break;
                }

                setTimeout(() => {
                    this.refreshSupplier();
                }, 400);
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
        else if (args.item.id === 'next' || args.item.id === 'prev') {
            let { currentTabSelected, tabPages, hasNext, hasPrev } = this.state;

            var index = tabPages.indexOf(currentTabSelected);
            if (args.item.id === 'next') {
                if (index < tabPages.length)
                    index++;
            } else {
                if (index > 0)
                    index--;
            }
    
            currentTabSelected = tabPages[index];
            hasNext = index > -1 && index < (tabPages.length - 1);
            hasPrev = index > 0 && index < tabPages.length;
    
            this.onTabSelected(currentTabSelected);
            
            this.setState({ currentTabSelected, hasNext, hasPrev });
        }
    }

    async initiateSave() {
        source.cancel();
        source = CancelToken.source();
        if (this.supplierContactGridRef) {
            this.supplierContactGridRef.endEdit();
        }

        if (this.customerMachineDepartmentRef) {
            this.customerMachineDepartmentRef.endEdit();
        }

        // Add validation here
        let hasError = this.validateFields();
        if (hasError) {
            return;
        }

        const sessionInfo = await SessionService.GetSessionInfo();
        let { supplierData, moduleState, currentTabSelected, tempContactList, hasEdit } = this.state;

        let actionText = moduleState === 'add' ? Localization.General.SuccessAdd : Localization.General.SuccessUpdate;
        let result = null;
        
        supplierData.CustomerId = parseInt(sessionInfo.Id);
        if (hasEdit.info && currentTabSelected != 0) {
            result = await SupplierService.AddUpdateSupplier(supplierData, source.token);
            if (result && !result.HasError) {
                supplierData.Id = result.Id;
            }
        }
        
        if (hasEdit.settings && currentTabSelected != 2) {
            supplierData.DeliveryTimeInDays = parseInt(supplierData.DeliveryTimeInDays);
            supplierData.AllOrderInterval = parseInt(supplierData.AllOrderInterval);
            supplierData.BackOrderDays = supplierData.BackOrderDays ? parseInt(supplierData.BackOrderDays) : 0;
            result = await SupplierService.AddUpdateSupplierSettings(supplierData, source.token);
            if (result && !result.HasError) {
                supplierData.Id = result.Id;
            }
        }
        
        switch(currentTabSelected) {
            case 0:
                supplierData.SupplierNo = this.SupplierNoRef.value;
                supplierData.Company = this.CompanyRef.value;
                supplierData.Street = this.StreetRef.value;
                supplierData.PostalCodeId = this.PostalCodeRef.value;
                supplierData.CountryId = this.CountryRef.value;
                supplierData.MainPhone = this.MainPhoneRef.value;
                supplierData.MainEmail = this.MainEmailRef.value;
                supplierData.VatNo = this.VatNoRef.value;

                result = await SupplierService.AddUpdateSupplier(supplierData, source.token);
                break;
            case 1:
                break;
            case 2:
                supplierData.DeliveryTimeInDays = parseInt(this.DeliveryTimeInDaysRef.value);
                supplierData.AllOrderInterval = parseInt(this.AllOrderIntervalRef.value);
                supplierData.BackOrderDays = supplierData.BackOrderDays ? parseInt(supplierData.BackOrderDays) : 0;

                result = await SupplierService.AddUpdateSupplierSettings(supplierData, source.token);
                break;
        }

        if (supplierData.Id && tempContactList && tempContactList.length > 0) {
            let payload = [];
            for (let index = 0; index < tempContactList.length; index++) {
                const element = tempContactList[index];

                payload.push({
                    Id: element.TempId ? 0 : element.Id,
                    Name: element.Name,
                    Dept: element.Dept,
                    Position: element.Position,
                    Note: element.Note,
                    DirectPhone1: element.DirectPhone1,
                    DirectPhone2: element.DirectPhone2,
                    Mail: element.Mail,
                    SupplierId: supplierData.Id,
                    IsDelete: element.IsDelete,
                    ChangeOrder: index + 1
                });
            }
            result = await SupplierService.SaveContacts(payload, source.token);
        }

        if (!result.HasError) {
            actionText = actionText.replace("{context}", "Leverandør");
            switch(currentTabSelected) {
                case 0:
                    this.refreshDropdown();
                    break;
                case 1:
                    break;
                case 2:
                    break;
            }

            hasEdit.info = false;
            hasEdit.settings = false;
            await this.refreshSupplier().then(() => {
                this.dialogOpen(Localization.General.SuccessHeader, actionText);
                this.toggleSupplierInfoFields(false);
                this.setState({ supplierData, isEditMode: false, moduleState: 'edit', undoRedoStatus: result.UndoRedoStatus, hasUnsavedChanges: false, hasEdit, tempContactList: [] }, () => {
                    // this.undoRedoOptions(result.UndoRedoStatus.Undo, result.UndoRedoStatus.Redo, result.UndoRedoStatus.UndoAction, result.UndoRedoStatus.RedoAction);
                    if (moduleState === 'add') {
                        this.props.history.push(`/leverandor/edit/${supplierData.Id}`);
                    }
                });
            });
        }
        else {
            this.dialogOpen("Advarsel", result.ErrorMessage);
        }
    }

    validateFields() {
        const { supplierData, currentTabSelected } = this.state;
        let hasError = false, invalidMailFormat = false;
        let errorMessage = [];

        switch (currentTabSelected) {
            case 0:
                if (!this.SupplierNoRef.value) { this.SupplierNoRef.classList.add('e-error'); hasError = true; }
                else { this.SupplierNoRef.classList.remove('e-error'); }
        
                if (!this.CompanyRef.value) { this.CompanyRef.classList.add('e-error'); hasError = true; }
                else { this.CompanyRef.classList.remove('e-error'); }
        
                if (!this.MainEmailRef.value) { this.MainEmailRef.classList.add('e-error'); hasError = true; }
                else { this.MainEmailRef.classList.remove('e-error'); }

                break;
            case 2:
                if (!this.DeliveryTimeInDaysRef.value && this.DeliveryTimeInDaysRef.value <= 0) { this.DeliveryTimeInDaysRef.classList.add('e-error'); hasError = true; }
                else { this.DeliveryTimeInDaysRef.classList.remove('e-error'); }

                if (!this.OrderTimeSendRef.value ) { this.OrderTimeSendRef.element.parentElement.classList.add('e-error'); hasError = true; }
                else { this.OrderTimeSendRef.element.parentElement.classList.remove('e-error'); }

                let allOrderInterval = parseInt(this.AllOrderIntervalRef.value) ? parseInt(this.AllOrderIntervalRef.value) : 0;
                if (allOrderInterval < 0) { this.AllOrderIntervalRef.classList.add('e-error'); hasError = true; }
                else { this.AllOrderIntervalRef.classList.remove('e-error'); }

                if (!this.OrderOutputRef.value || this.OrderOutputRef.value === 0) { this.OrderOutputRef.element.parentElement.classList.add('e-error'); hasError = true; }
                else { this.OrderOutputRef.element.parentElement.classList.remove('e-error'); }

                if (!this.OrderOutputFormatRef.value || this.OrderOutputFormatRef.value === 0) { this.OrderOutputFormatRef.element.parentElement.classList.add('e-error'); hasError = true; }
                else { this.OrderOutputFormatRef.element.parentElement.classList.remove('e-error'); }

                if (supplierData.BackOrder && !supplierData.BackOrderDays) {
                    this.BackOrderDaysRef.classList.add('e-error'); hasError = true;
                }
                else {
                    this.BackOrderDaysRef.classList.remove('e-error');
                }

                if (supplierData.OrderOutputEmails.length > 0) {
                    let indexes = [];
                    supplierData.OrderOutputEmails.forEach((e, index) => {
                        if (!ValidateEmail(e)) {
                            indexes.push(index);
                        }
                    });

                    let emailList = document.querySelectorAll('.output-email-list-content .output-email-row');
                    if (indexes.length > 0) {
                        // hasError = true;
                        invalidMailFormat = true;

                        emailList.forEach((elem, index) => {
                            if (indexes.some(x => x === index)) {
                                let input = elem.querySelector('div:first-child input');
                                input.classList.add('e-error');
                            }
                        });
                    }
                    else {
                        emailList.forEach(elem => {
                            let input = elem.querySelector('div:first-child input');
                            input.classList.remove('e-error');
                        });
                    }
                }
                break;
        }

        let msg = [];
        if (hasError) {
            msg.push("Du mangler at udfylde felter, makeret med rødt.");
            if (invalidMailFormat) {
                msg.push(Localization.General.InvalidEmailFormat);
            }
            this.dialogOpen("Advarsel", msg.join('<br>'));
        }
        else if (!hasError && invalidMailFormat) {
            msg.push(Localization.General.InvalidEmailFormat);
            this.dialogOpen("Advarsel", msg.join('<br>'));
            hasError = true;
        }
        
        return hasError;
    }

    undoRedoOptions(hasUndo, hasRedo, undoTooltip = '', redoTooltip = '') {
        // this.SupplierInfoToolbarRef.items[6].disabled = !hasUndo;
        // this.SupplierInfoToolbarRef.items[6].tooltipText = undoTooltip;
        // this.SupplierInfoToolbarRef.items[7].disabled = !hasRedo;
        // this.SupplierInfoToolbarRef.items[7].tooltipText = redoTooltip;
    }

    // supplierContactTab() {
    //     const { moduleState } = this.state;
    //     return <SupplierContact state={moduleState} onNotify={this.dialogOpen} />
    // }

    onDepartmentDaysChange(args) {
        let { supplierData } = this.state;
        if (args.container) {
            let input = args.container.querySelector('input');
    
            if (input) {
                supplierData[input.name] = args.value
                this.setState({ supplierData });
            }
        }
        else if (args.event) {
            supplierData[args.event.srcElement.id] = args.value;
            this.setState({ supplierData });
        }
    }

    onChangeByName(name, args) {
        if (args.isInteracted) {
            let { supplierData, hasEdit } = this.state;
            
            if (name === "OrderTimeSend") {
                supplierData[name] = args.text;
            }
            else {
                supplierData[name] = args.value;
            }

            if (name === 'OrderOutput') {
                let emailSettings = document.querySelector('.vendor-settings-email');
                if (args.value === 1) {
                    emailSettings.style.display = 'block';
                }
                else if (args.value === 2) {
                    emailSettings.style.display = 'none';
                }
            }

            if (this.infoFields.includes(name)) {
                hasEdit.info = true;
            } else if (this.settingsFields.includes(name)) {
                hasEdit.settings = true;
            }

            this.setState({ supplierData, hasUnsavedChanges: true, hasEdit });
        }
    }

    onAddEmail() {
        let { supplierData, hasEdit } = this.state
        hasEdit.settings = true;
        supplierData.OrderOutputEmails.push('');
        this.setState({ supplierData, hasUnsavedChanges: true, hasEdit });
    }

    onChangeEmail(index, event) {
        let { supplierData, hasEdit } = this.state;
        hasEdit.settings = true;
        supplierData.OrderOutputEmails[index] = event.target.value;
        this.setState({ supplierData, hasUnsavedChanges: true, hasEdit });
    }

    onDeleteEmail(index, event) {
        let { supplierData, hasEdit } = this.state;
        hasEdit.settings = true;
        supplierData.OrderOutputEmails.splice(index, 1);
        this.setState({ supplierData, hasUnsavedChanges: true, hasEdit });
        // let emailList = document.querySelector('div.output-email-list-content');
    }

    onDepartmentChangeInterval(id, event) {
        const { supplierData } = this.state;
        let department = supplierData.DeptOrderInterval.find(x => x.Id === id);
        department.OrderInterval = parseInt(event.target.value);
        if (department.OrderInterval ===  parseInt(supplierData.AllOrderInterval)) {
            department.OrderInterval = null;
            event.target.value = null;
        }
        this.setState({ supplierData, hasUnsavedChanges: true });
    }

    onTimepickerBlur(args) {
        const { supplierData } = this.state;
        let data  = '';

        if (supplierData.OrderTimeSend) {
            data = supplierData.OrderTimeSend;
        }
        else {
            data = args.target.value;
        }
        
        if (!data.includes(':')) {
            // args.target.value
            if (data.length < 4) {
                data = `0${data}`;
            }

            if (data.length === 4) {
                let nums = data.split('');
                nums.splice(2, 0, ':');
                supplierData.OrderTimeSend = nums.join('');
                this.setState({ supplierData });
            }
        }
    }

    onSupplierConfirmationChange(args) {
        let { supplierData, hasEdit } = this.state;
        hasEdit.settings = true;
        supplierData.SupplierConfirmation = args.checked;
        this.setState({ supplierData, hasUnsavedChanges: true, hasEdit });
    }

    onBackOrderChange(args) {
        let { supplierData, hasEdit } = this.state;
        hasEdit.settings = true;
        supplierData.BackOrder = args.checked;
        this.setState({ supplierData, hasUnsavedChanges: true, hasEdit });
    }

    vendorSettingsTab() {
        const { supplierData, isEditMode, moduleState } = this.state;
        //return ( <VendorSettings state={moduleState} onNotify={this.dialogOpen} data={supplierData} /> )

        let isEnabled = false, isBackOrderDaysEnabled = false;
        if (moduleState === 'add') {
            isEnabled = true;

            if (supplierData.BackOrder) {
                isBackOrderDaysEnabled = true;
            }
        }
        else if (isEditMode) {
            isEnabled = true;

            if (supplierData.BackOrder) {
                isBackOrderDaysEnabled = true;
            }
        }
        
        return (
            <div className="tab-control--item vendor-settings-wrapper">
                <div className="vendor-settings-card-wrapper">
                    <Card headerText="Leveringsbetingelser">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="vendor-settings-label-wrapper">
                                            <div className="vendor-settings-label">Leveringstid I Dage</div>
                                            <div className="vendor-settings-sub-label">Fra orde modtagelse til afsendelse Weekend dage undtaget</div>
                                        </div>
                                        <div className="vendor-settings-value-wrapper">
                                            <input type="number" ref={ref => this.DeliveryTimeInDaysRef = ref} value={supplierData.DeliveryTimeInDays} disabled={!isEnabled} name="DeliveryTimeInDays" className="text-right e-control e-textbox e-input is-numeric" 
                                                onChange={this.onChange} />
                                            {/* <NumericTextBoxComponent ref={ref => this.DeliveryTimeInDaysRef = ref} cssClass="input-text-right" format="#,###" value={supplierData.DeliveryTimeInDays} showSpinButton={false} 
                                                id="DeliveryTimeInDays" enabled={isEnabled} /> */}
                                                {/* change={this.onChangeByName.bind(this, "DeliveryTimeInDays")}  */}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="vendor-settings-label-wrapper">
                                            <div className="vendor-settings-label">Afsend Ordre Inden Kl.:</div>
                                            <div className="vendor-settings-sub-label ">Når orde modtages inden indtastet tidspunkt vil ordre blive afsendt samme dag</div>
                                        </div>
                                        <div className="vendor-settings-value-wrapper">
                                            <TimePickerComponent ref={ref => this.OrderTimeSendRef = ref} cssClass="input-text-right timer-pop-up" value={supplierData.OrderTimeSend} id="OrderTimeSend" format="HH:mm" 
                                                change={this.onChangeByName.bind(this, "OrderTimeSend")} enabled={isEnabled} onBlur={this.onTimepickerBlur.bind(this)} openOnFocus={true} allowEdit={false} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Card>
                    <Card headerText="Genbestillings Interval">
                        <table className="table customer-department-table">
                            <thead>
                                <tr>
                                    <th>{Localization.Product.Department}</th>
                                    <th>Interval I Dage</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{Localization.Product.AllDepartment}</td>
                                    <td>
                                        <input type="number" ref={ref => this.AllOrderIntervalRef = ref} value={supplierData.AllOrderInterval} disabled={!isEnabled} name="AllOrderInterval" className="text-right e-control e-textbox e-input is-numeric" 
                                            onChange={this.onChange} />
                                        {/* <NumericTextBoxComponent ref={ref => this.AllOrderIntervalRef = ref} cssClass="input-text-right" format="#,###" showSpinButton={false} id="AllOrderInterval" 
                                            value={supplierData.AllOrderInterval} enabled={isEnabled} />  */}
                                            {/* change={this.onDepartmentDaysChange.bind(this)}  */}
                                    </td>
                                </tr>
                                {
                                    supplierData.DeptOrderInterval.map((department, index) => {
                                        return (
                                            <tr data-id={department.Id} className="department-row-data" key={`department_row_${index}`}>
                                                <td>{department.Name}</td>
                                                <td>
                                                    {/* <NumericTextBoxComponent cssClass="input-text-right" format="#,###" showSpinButton={false} value={department.OrderInterval} 
                                                        change={this.onDepartmentChangeInterval.bind(this, department.Id)} enabled={isEnabled} /> */}
                                                    <input type="number" id={`DeliveryTimeInDays_${department.Id}`} className="e-control e-input input-text-right is-numeric" value={(department.OrderInterval === supplierData.AllOrderInterval ? null : department.OrderInterval)} disabled={!isEnabled}
                                                        onChange={this.onDepartmentChangeInterval.bind(this, department.Id)} placeholder="Alle afdelinger" />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </Card>
                </div>
                <div className="vendor-settings-card-wrapper data-output">
                    <Card headerText="Ordre output">
                        <div className="vendor-settings-order-output">
                            <div className="vendor-settings-output-label-wrapper">Vælg venligst output</div>
                            <div className="vendor-settings-output-value-wrapper">
                                <DropDownListComponent ref={ref => this.OrderOutputRef = ref} value={supplierData.OrderOutput} dataSource={this.outputData} fields={{ text: 'Key', value: 'Value' }} 
                                    change={this.onChangeByName.bind(this, "OrderOutput")} enabled={isEnabled} locale="da-DK" />
                            </div>
                        </div>
                        <div className="vendor-settings-order-output">
                            <div className="vendor-settings-output-label-wrapper">Vælg venligst Filformat</div>
                            <div className="vendor-settings-output-value-wrapper">
                                <DropDownListComponent ref={ref => this.OrderOutputFormatRef = ref} value={supplierData.OrderOutputFormat} dataSource={this.fileFormatData} fields={{ text: 'Key', value: 'Value' }} 
                                    change={this.onChangeByName.bind(this, "OrderOutputFormat")} enabled={isEnabled} locale="da-DK" />
                            </div>
                        </div>
                    </Card>
                    {/* supplierData.OrderOutput === 1 && */}
                    <div className="vendor-settings-email">
                        <div className="output-email-list-header label">
                            Indtast venligst E-mail adresse
                            <button type="button" className="add-email-button c-button c-button-plain" disabled={!isEnabled} onClick={this.onAddEmail}>
                                <i className="c-icon c-icon-add-black"></i>Ny
                            </button>
                        </div>
                        <div className="output-email-list-content">
                            {
                                supplierData.OrderOutputEmails.map((email, index) => {
                                    return (
                                        <div className="output-email-row" key={`email-row-${index}`}>
                                            <div className="output-email-data-col">
                                                <input type="text" className={`e-control e-lib e-input ${isEnabled ? '' : 'e-disabled'}`} name="OutputEmails" value={email} onChange={this.onChangeEmail.bind(this, index)} />
                                            </div>
                                            {
                                                isEnabled &&
                                                <div className="output-email-action-col">
                                                    <i className="c-icon c-icon-delete clickable" onClick={this.onDeleteEmail.bind(this, index)}></i>
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="vendor-settings-card-wrapper back-order-settings">
                    <Card headerText="Restordre">
                        <div className="field-bordered">
                            <label className="regular-text" htmlFor="Inaktiv">Tillad restordre</label>
                            <CheckBoxComponent id="Inaktiv" disabled={!isEnabled} checked={supplierData.BackOrder} change={this.onBackOrderChange.bind(this)} name="inactive" cssClass="control-right" />
                        </div>
                        <div className="field-bordered">
                            <label className="regular-text vendor-settings-output-label-wrapper" htmlFor="Inaktiv">Slet restordre ældre end</label>
                            <div className="vendor-settings-output-value-wrapper vendor-settings-back-order">
                                <input type="number" ref={ref => this.BackOrderDaysRef = ref} value={supplierData.BackOrderDays} disabled={!isBackOrderDaysEnabled} name="BackOrderDays" className="text-right e-control e-textbox e-input is-numeric" 
                                    onChange={this.onChange} />
                                <div className="regular-text">dage</div>
                            </div>
                        </div>
                    </Card>
                    <Card headerText="Notifikation">
                        <div className="field-bordered">
                            <label htmlFor="SupplierConfirmation" className="regular-text">Leverandør skal bekræfte Ordre</label>
                            <SwitchComponent id="SupplierConfirmation" ref={ref => this.SupplierConfirmationRef = ref} disabled={!isEnabled} checked={supplierData.SupplierConfirmation} change={this.onSupplierConfirmationChange.bind(this)} cssClass="control-right" />
                        </div>
                    </Card>
                </div>
            </div>
        )
    }

    tabSelected(args) {
        const { breadcrumbs, supplierData, isEditMode, moduleState } = this.state;
        if (breadcrumbs.length > 1) {
            breadcrumbs.pop();
        }
        breadcrumbs.push(this.tabHeader[args.selectedIndex].text);

        const headerText = this.tabHeader[args.selectedIndex].text;
        let headerElement = document.querySelector('.module-header > h1');
        headerElement.innerHTML = headerText;

        const selectedTab = this.supplierTabRef.selectedItem;
        switch(selectedTab) {
            case 0:
                break;
            case 1:
                let emailSettings = document.querySelector('.vendor-settings-email');
                if (emailSettings) {
                    if (supplierData.OrderOutput === 1) {
                        emailSettings.style.display = 'block';
                    }
                    else if (supplierData.OrderOutput === 2) {
                        emailSettings.style.display = 'none';
                    }
                }
                break;
        }

        this.setState({ breadcrumbs });
    }

    dialogOpen(header, content, callback = null) {
        this.dialogInstance.header = header;
        this.dialogInstance.content = content;
        
        if (callback) {
            let buttons = this.buttons;
            buttons[0].click = callback;
            this.dialogInstance.buttons = buttons;
        }

        this.dialogInstance.show();
    }

    dialogClose() {
        let { dialogOpts, currentTabSelected, breadcrumbs } = this.state;
        this.dialogInstance.hide();

        if (this.hasRequiredFieldNotification) {
            let headerElement = document.querySelector('.module-header > h1');
            headerElement.innerHTML = 'Leverandør Indstillinger';
            breadcrumbs.push('Leverandør Indstillinger');
            this.hasRequiredFieldNotification = false;
            currentTabSelected = this.tabSelecting;
        }

        this.setState({ dialogOpts, currentTabSelected, breadcrumbs }, () => {
            if(dialogOpts.redirectOnClose) {
                this.props.history.push(dialogOpts.redirectTo);
            }
        });
    }

    delay(time) {
        return new Promise((resolve) => {
            setTimeout(() => resolve(), time);
        });
    }

    async onTabSelected(index) {
        const { breadcrumbs, supplierData, isEditMode, currentTabSelected, moduleState, tempContactList } = this.state;
        if (breadcrumbs.length > 1) {
            breadcrumbs.pop();
        }

        let headerText = '';
        let headerElement = document.querySelector('.module-header > h1');

        if (this.supplierContactGridRef) {
            this.supplierContactGridRef.endEdit();
            console.log(this.supplierContactGridRef.getRows());
            await this.delay(100);
        }

        if (this.customerMachineDepartmentRef) {
            this.customerMachineDepartmentRef.endEdit();
        }

        switch(index) {
            case 0:
                headerText = 'Leverandør Info';
                headerElement.innerHTML = headerText;
                breadcrumbs.push(headerText);
                break;
            case 1:
                if (currentTabSelected === 0) {
                    let isProceedToNextTab = true;
    
                    if (!supplierData.SupplierNo) { isProceedToNextTab =  false; }
                    if (!supplierData.Company) { isProceedToNextTab =  false; }
                    if (!supplierData.MainEmail) { isProceedToNextTab =  false; }
    
                    if (!isProceedToNextTab) {
                        this.tabSelecting = 1;
                        this.hasRequiredFieldNotification = true;
                        this.dialogOpen(Localization.General.NoteHeader, Localization.General.NotifyRequireFieldsNotFilled);
                        return false;
                    }
                }

                headerText = 'Leverandør Kontakt';
                headerElement.innerHTML = headerText;
                breadcrumbs.push(headerText);
                break;
            case 2:
                if (currentTabSelected === 0) {
                    let isProceedToNextTab = true;
    
                    if (!supplierData.SupplierNo) { isProceedToNextTab =  false; }
                    if (!supplierData.Company) { isProceedToNextTab =  false; }
                    if (!supplierData.MainEmail) { isProceedToNextTab =  false; }
    
                    if (!isProceedToNextTab) {
                        this.tabSelecting = 2;
                        this.hasRequiredFieldNotification = true;
                        this.dialogOpen(Localization.General.NoteHeader, Localization.General.NotifyRequireFieldsNotFilled);
                        return false;
                    }
                }
                
                headerText = 'Leverandør Indstillinger';
                headerElement.innerHTML = headerText;
                breadcrumbs.push(headerText);
                break;
        }

        this.setState({ currentTabSelected: index, breadcrumbs }, () => {
            switch(index) {
                case 0:
                    // this.customerMachineDepartmentData = Object.assign([], this.accountDepartments.map(dept => {
                    //     let setup = null;
                    //     if (supplierData.DeptMachCustomerNo) {
                    //         setup = supplierData.DeptMachCustomerNo.find(d => d.DeptId === dept.Id);
                
                    //         if (setup) {
                    //             dept.CustomerNo = setup.CustomerNo;
                    //         }
                    //     }
            
                    //     if (dept.Machines.length > 0) {
                    //         dept.Machines.forEach(m => {
                    //             m.Name = m.CustomName;
                    //             m.Type = 'Machine';
            
                    //             let machineSetup = setup && setup.Machines ? setup.Machines.find(x => x.MachineId === m.Id) : null;
                    //             if (machineSetup) {
                    //                 m.CustomerNo = machineSetup.CustomerNo;
                    //             }
                    //         });
                    //     }
            
                    //     return dept;
                    // }));
    
                    // this.customerMachineDepartmentRef.refresh();
                    break;
                case 1:
                    if (moduleState === 'add' || isEditMode) {
                        this.supplierContactGridRef.element.classList.remove('c-disablegrid');
                        let wrapper = document.getElementsByClassName('supplier-contact-wrapper')[0];
                        if (wrapper) {
                            wrapper.classList.remove('c-disablegrid-wrapper');
                        }
                    }
                    else {
                        this.supplierContactGridRef.element.classList.add('c-disablegrid');
                        let wrapper = document.getElementsByClassName('supplier-contact-wrapper')[0];
                        if (wrapper) {
                            wrapper.classList.add('c-disablegrid-wrapper');
                        }
                    }
                    
                    this.supplierContactGridRef.dataSource = supplierData.Contacts;
                    this.supplierContactGridRef.refresh();
                    break;
            }
        });
    }

    render() {
        const { loading, breadcrumbs, supplierData, moduleState, isEditMode, undoRedoStatus, currentTabSelected, redirect, hasUnsavedChanges, hasPrev, hasNext } = this.state;
        
        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        let isUpdateDisabled = false, isEditDisabled = false, isCancelDisabled = false, isDeleteDisabled = false;
        let isEditVisible = false, isSaveVisible = false, isCancelVisible = false;

        if(super.hasMenuItemTabFunction("Leverandørkort", this.tabHeader[currentTabSelected].text, "Redigere")) {
            isEditVisible = true;
        }

        if (moduleState === 'add') {
            isEditDisabled = true;
            isDeleteDisabled = true;

            isSaveVisible = true;
            isCancelVisible = true;
        }
        else {
            if (isEditVisible) {
                isSaveVisible = true;
                isCancelVisible = true;
            }

            if (isEditMode) {
                isEditDisabled = true;
            }
            else {
                isUpdateDisabled = true;
                isCancelDisabled = true;
            }
        }

        return (
            <div className='content-pane-wrapper supplier-card-wrapper'>
                <NavigationPrompt
                    ref={ref => this.NavigationPromptRef = ref}
                    when={hasUnsavedChanges} 
                    navigate={path => this.props.history.push(path)}
                    initiateSave={this.initiateSave}
                    shouldBlockNavigation={location => {
                        if (hasUnsavedChanges) {
                            return true;
                        }
                        return false;
                    }}
                />
                <div>
                    <Breadcrumb breadcrumbs={breadcrumbs} />
                </div>
                <div className="module-header">
                    <h1>{this.tabHeader[0].text}</h1>
                </div>
                <div className="supplier-info-toolbar-wrapper">
                    <ToolbarComponent ref={ref => this.SupplierInfoToolbarRef = ref} clicked={this.supplierInfoToolbarClicked}>
                        <ItemsDirective>
                            <ItemDirective ref={ref => this.toolbarAdd = ref} id="add" prefixIcon='tb-icons c-icon-add-black' text="Ny" tooltipText='Ny' visible={super.hasMenuItemTabFunction("Leverandørkort", this.tabHeader[currentTabSelected].text, "Ny")} />
                            <ItemDirective ref={ref => this.toolbarSearch = ref} id="search" prefixIcon='tb-icons c-icon-search-list' text="Søgeliste" tooltipText='Søgeliste' visible={super.hasMenuItem("Leverandørliste")} />
                            <ItemDirective ref={ref => this.toolbarEdit = ref} id="edit" prefixIcon='tb-icons c-icon-edit' text="Redigere" tooltipText='Redigere' disabled={isEditDisabled} visible={isEditVisible} />
                            <ItemDirective ref={ref => this.toolbarEdit = ref} id="delete" prefixIcon='tb-icons c-icon-delete' text="Slette" tooltipText='Slette' disabled={isDeleteDisabled} visible={super.hasMenuItemTabFunction("Leverandørkort", this.tabHeader[currentTabSelected].text,"Slette")} />
                            <ItemDirective ref={ref => this.toolbarSave = ref} id="save" prefixIcon='tb-icons c-icon-update' text='Gem' 
                                tooltipText='Gem' disabled={isUpdateDisabled} visible={isSaveVisible} />
                            <ItemDirective ref={ref => this.toolbarCancel = ref} id="cancel" prefixIcon='tb-icons c-icon-cancel' text="Annuller" tooltipText='Annuller' disabled={isCancelDisabled} visible={isCancelVisible}/>
                            <ItemDirective ref={ref => this.toolbarEdit = ref} id="undo" prefixIcon='tb-icons c-icon-undo' text="Fortryd" tooltipText={undoRedoStatus.UndoAction} disabled={!undoRedoStatus.Undo} visible={super.hasMenuItemTabFunction("Leverandørkort", this.tabHeader[currentTabSelected].text, ["Ny","Redigere","Slette"])} />
                            <ItemDirective ref={ref => this.toolbarEdit = ref} id="redo" prefixIcon='tb-icons c-icon-redo' text="Gentag" tooltipText={undoRedoStatus.RedoAction} disabled={!undoRedoStatus.Redo} visible={super.hasMenuItemTabFunction("Leverandørkort", this.tabHeader[currentTabSelected].text, ["Ny","Redigere","Slette"])} />
                            <ItemDirective id="prev" prefixIcon='tb-icons c-icon-chevron-arrow-left' text={Localization.General.Previous} tooltipText={Localization.General.Previous} disabled={!hasPrev} />
                            <ItemDirective id="next" prefixIcon='tb-icons c-icon-chevron-arrow-right' text={Localization.General.Next} tooltipText={Localization.General.Next} disabled={!hasNext} />
                        </ItemsDirective>
                    </ToolbarComponent>
                </div>
                {
                    moduleState === 'edit' &&
                    <div className="supplier-name-header">
                        <h2>{supplierData.Company}</h2>
                    </div>
                }
                <div className='control-section tab-control'>
                    {/* <TabComponent ref={ref => this.supplierTabRef = ref} heightAdjustMode='Auto' id='supplier-tab' selected={this.tabSelected.bind(this)} heightAdjustMode="Auto">
                        <TabItemsDirective>
                            <TabItemDirective header={this.tabHeader[0]} content={this.supplierInfoTab.bind(this)} />
                            //  <TabItemDirective header={this.tabHeader[1]} content={this.supplierContactTab.bind(this)}/>
                            <TabItemDirective header={this.tabHeader[1]} content={{
                                template: this.vendorSettingsTab,
                                data: { supplierData: this.state.supplierData, isEditMode: this.state.isEditMode, moduleState: this.state.moduleState }
                            }} />
                        </TabItemsDirective>
                    </TabComponent> */}
                    <Tabs className="c-tab" selectedIndex={currentTabSelected} onSelect={index => this.onTabSelected(index)} disabledTabClassName="c-tabs__tab--disabled">
                        <TabList className="c-tab__list">
                            <Tab className={`c-tabs__tab ${super.hasMenuItemTabAccess("Leverandørkort", "Leverandør Info") ? '' : 'hidden'}`} disabled={!super.hasMenuItemTabAccess("Leverandørkort", "Leverandør Info")} selectedClassName="c-tabs__tab--selected">Leverandør Info</Tab>
                            <Tab className={`c-tabs__tab ${super.hasMenuItemTabAccess("Leverandørkort", "Leverandør Kontakt") ? '' : 'hidden'}`} disabled={!super.hasMenuItemTabAccess("Leverandørkort", "Leverandør Kontakt")} selectedClassName="c-tabs__tab--selected">Leverandør Kontakt</Tab>
                            <Tab className={`c-tabs__tab ${super.hasMenuItemTabAccess("Leverandørkort", "Leverandør Indstillinger") ? '' : 'hidden'}`} disabled={!super.hasMenuItemTabAccess("Leverandørkort", "Leverandør Indstillinger")} selectedClassName="c-tabs__tab--selected">Leverandør Indstillinger</Tab>
                        </TabList>
                        <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                            {this.supplierInfoTab()}
                        </TabPanel>
                        <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                            {this.supplierContactTab()}
                        </TabPanel>
                        <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                            {this.vendorSettingsTab()}
                        </TabPanel>
                    </Tabs>
                </div>
                <div>
                    <DialogComponent id="supplier-card-dialog" isModal={true} buttons={this.buttons} width='auto' ref={dialog => this.dialogInstance = dialog} target='body' visible={false} 
                        showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true}/>
                </div>
            </div>
        )
    }
}

export default Supplier;
