import React, { Component } from 'react';
import ILXLogo from '../../assets/logo_ilx_hor.png';
import { DialogComponent } from '@syncfusion/ej2-react-popups';

import AuthenticationService from '../../services/AuthenticationService';
import AccountService from '../../services/AccountService';
import Loading from '../../components/Loading';
import Localization from '../../utilities/Localization';
import Axios from 'axios';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class ResetPassword extends Component {
    queryEmail = '';
    queryToken = '';

    constructor() {
        super();

        this.state = {
            loading: true,
            password: '',
            confirmPassword: '',
            response: {
                hasError: false,
                message: ''
            }
        }
        
        this.animationSettings = { effect: 'None' };
        this.buttons = [
            {
                // Click the footer buttons to hide the Dialog
                click: () => {
                    AuthenticationService.login();
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    //Enables the primary button
                    isPrimary: true,
                    content: Localization.General.GoBackToLoginPage
                }
            }
        ];
    }

    componentDidMount() {
        const { location } = this.props;
        
        if (location.search) {
            let query = location.search.substring(1).split('&');
            
            let token = query.find(x => x.startsWith('token='));
            let email = query.find(x => x.startsWith('email='));
            this.queryToken = token.replace('token=', '');
            this.queryEmail = email.replace('email=', '');
        }

        this.setState({ loading: false });
    }

    onClick = async () => {
        this.submitBtn.disabled = true;
        let { password, confirmPassword, response } = this.state;

        const payload = {
            Email: this.queryEmail,
            Token: this.queryToken,
            Password: password,
            ConfirmPassword: confirmPassword
        };
        const result = await AccountService.ResetPassword(payload, source.token);

        if (!result.HasError) {
            this.notificationDialog.header = Localization.General.NoteHeader;
            this.notificationDialog.content = Localization.ForgotPassword.SuccessfullyReset;
            this.notificationDialog.show();
        }
        else {
            response.hasError = true;
            response.message = result.ErrorMessage;
        }

        this.setState({ response });
    }

    onChange = (args) => {
        let value = args.target.value;
        let name = args.target.name;
        this.setState({ [name]: value });
    }

    render() {
        const { loading, password, confirmPassword, response } = this.state;
        
        if (loading) {
            return <Loading />
        }

        return (
            <div className="account-reset-wrapper">
                <div className="account-reset-container">
                    <div>
                        <img src={ILXLogo} alt="ILX Systems" />
                    </div>
                    <div className="title">
                        <h1>Nulstil Adgangskode</h1>
                    </div>
                    {
                        response.hasError && 
                        <div className="error">
                            {response.message}
                        </div>
                    }
                    <div className="field">
                        <div class="e-float-input e-control-wrapper e-valid-input">
                            <input type='password' name="password" ref={ref => this.mailRef = ref} class="e-control e-textbox e-lib" role="textbox" id="textbox_0" 
                                onChange={this.onChange} aria-labelledby="label_textbox_0" value={password} />
                            <span class="e-float-line"></span>
                            <label class="e-float-text e-label-top" id="label_textbox_0" for="textbox_0">Ny Adgangskode*</label>
                        </div>
                    </div>
                    <div className="field">
                        <div class="e-float-input e-control-wrapper e-valid-input">
                            <input type='password' name="confirmPassword" ref={ref => this.mailRef = ref} class="e-control e-textbox e-lib" role="textbox" id="textbox_0" 
                                onChange={this.onChange} aria-labelledby="label_textbox_0" value={confirmPassword} />
                            <span class="e-float-line"></span>
                            <label class="e-float-text e-label-top" id="label_textbox_0" for="textbox_0">Bekræft Adgangskode*</label>
                        </div>
                    </div>
                    <div className="action-button">
                        <button ref={ref => this.submitBtn = ref} className="e-btn e-primary" disabled={!password || !confirmPassword} onClick={this.onClick}>Bekræft</button>
                    </div>
                    <DialogComponent isModal={true} buttons={this.buttons} width='auto' ref={dialog => this.notificationDialog = dialog} target='body' visible={false} 
                        showCloseIcon={false} cssClass="dialog-notification" animationSettings={this.animationSettings} />
                </div>
            </div>
        )
    }
}

export default ResetPassword
