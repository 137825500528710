import HttpClient from '../../utilities/HttpClient';

const API = {
    getList: '/responsibilityGroup',
    getMessageGroup: '/responsibilityGroup/messageGroups',
    postResponsibilityGroup: '/responsibilityGroup',
    get: '/responsibilityGroup/{id}',
    undo: '/responsibilityGroup/undo',
    redo: '/responsibilityGroup/redo',
};

class ResponsibilityGroupService {
    async GetList(cancelToken) {
        return await HttpClient.Get(API.getList, null, cancelToken);
    }

    async GetMessageList(cancelToken) {
        return await HttpClient.Get(API.getMessageGroup, null, cancelToken);
    }

    async Save(data, cancelToken) {
        return await HttpClient.Post(API.postResponsibilityGroup, data, cancelToken);
    }

    async Get(id, cancelToken) {
        const url = API.get.replace('{id}', id);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async Undo(cancelToken) {
        return await HttpClient.Put(API.undo, null, cancelToken);
    }

    async Redo(cancelToken) {
        return await HttpClient.Put(API.redo, null, cancelToken);
    }
}

const responsibilityGroupService = new ResponsibilityGroupService();
export default responsibilityGroupService;