import React, { Component } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Page, Sort, Toolbar, Selection, Edit, Search, Reorder, Resize } from '@syncfusion/ej2-react-grids';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { Redirect } from 'react-router-dom';

import BaseComponent from '../../components/BaseComponent';
import AppContext from '../../context/AppContext';
// import Breadcrumb from '../../components/Breadcrumb';

import Localization from '../../utilities/Localization';
import SupplierService from '../../services/Supplier/SupplierService';
import AuthenticationService from '../../services/AuthenticationService';
import SettingsService from '../../services/SettingsService';
import SessionService from '../../services/SessionService';
import { buildGridSettings } from '../../utilities/Helper';

import Axios from 'axios';
import Loading from '../../components/Loading';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class SupplierList extends BaseComponent {
    moduleName = "Leverandør";
    pageName = "Leverandørliste";
    gridSettingsKey = "SupplierList";
    hasDraggedColumn = false;
    gridSettings = '';

    constructor() {
        super();

        this.state = {
            loading: true,
            redirect: {
                to: '',
                isRedirect: false
            }
        }

        this.pageSettings = {
            pageSize: 10,
            pageSizes: ['Alle', '5', '10', '20', '50', '100']
        };

        this.data = [];
        
        this.openDetailsTemplate = this.openDetailsTemplate.bind(this);

        this.selectionSettings = {
            type: 'Single'
        }

        this.filterSettings = {
            type: 'Menu'
        };
        
        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.buttons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.dialogInstance.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        /* End Dialog options  */

        this.refreshGrid = this.refreshGrid.bind(this);
        this.clickHandler = this.clickHandler.bind(this);
        this.actionBegin = this.actionBegin.bind(this);
        this.dialogOpen = this.dialogOpen.bind(this);
        this.init = this.init.bind(this);
    }

    async componentDidMount() {
        super.componentDidMount();
        source.cancel();
        source = CancelToken.source();

        if (!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }
        else if (!super.hasMenuItem('Leverandørliste')) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }

        this.init();

        const session = await SessionService.GetSessionInfo();

        const supplierList = await SupplierService.GetSuppliers(session.Id, source.token);
        this.data = supplierList;

        const gridSettings = await SettingsService.GetGridSettings(this.gridSettingsKey, source.token);
        if (!gridSettings.HasError) {
            this.gridSettings = gridSettings.Settings;
        }

        this.setState({ loading: false }, () => {
            window.addEventListener('wheel', this.onWheelScroll);
        });
    }

    init() {
        this.toolbarOptions = [];
        this.defaultEditSettings = {};

        if (super.hasMenuItemFunction("Leverandørliste", "Ny")) {
            this.toolbarOptions.push({ text: 'Ny', tooltipText: 'Ny', id: 'add', prefixIcon: 'e-add' });
        }
        if (super.hasMenuItemFunction("Leverandørliste", "Åben")) {
            this.toolbarOptions.push({ text: 'Åben', tooltipText: 'Åben', id: 'open', prefixIcon: 'e-external-link' });
        }
        if (super.hasMenuItemFunction("Leverandørliste", "Slette")) {
            this.toolbarOptions.push('Delete');
            this.defaultEditSettings = {
                allowDeleting: true,
                showDeleteConfirmDialog: true,
            };
        }

        this.toolbarOptions.push({ text: Localization.General.GridResetFilter, tooltipText: Localization.General.GridResetFilter, id: 'reset_filter', prefixIcon: 'e-cancel' });
    }

    async componentWillUnmount() {
        source.cancel();
        window.removeEventListener('wheel', this.onWheelScroll);
    }

    async refreshGrid() {
        source.cancel();
        source = CancelToken.source();

        const session = await SessionService.GetSessionInfo();
        const supplierList = await SupplierService.GetSuppliers(session.Id, source.token);
        this.supplierGridRef.dataSource = supplierList;
        this.supplierGridRef.dataBind();
        this.data = supplierList;
    }

    openDetailsTemplate(props) {
        return (
            <div className="text-left clickable">
                <i className="c-icon c-icon-external-link" onClick={() => this.props.history.push(`/leverandor/edit/${props.Id}`) }></i>
            </div>
        )
    }

    async actionBegin(args) {
        if (args.requestType === 'delete') {
            args.cancel = true;
            source.cancel();
            source = CancelToken.source();
            let { dialogOpts } = this.state;
            
            const result = await SupplierService.DeleteSupplier(args.data[0].Id, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessDelete.replace("{context}", "Leverandør"));
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }

            this.setState({ dialogOpts }, () => {
                this.refreshGrid();
            });
        }
    }

    async clickHandler(args) {
        if (this.supplierGridRef && args.item.id === 'add') {
            // window.open('/leverandor/add');
            this.props.history.push(`/leverandor/add`);
        }
        else if (this.supplierGridRef && args.item.id === 'open') {
            const selectedRows = this.supplierGridRef.getSelectedRows();
            if (selectedRows.length <= 0) {
                this.dialogOpen("Advarsel", Localization.General.NoRowSelected);
            }
            else {
                const id = parseInt(selectedRows[0].querySelector('td').innerHTML);
                // window.open(`/leverandor/edit/${id}`);
                this.props.history.push(`/leverandor/edit/${id}`);
            }
        }
        else if (args.item.id === 'reset_filter') {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                settings.filters = [];
                this.gridSettings = settings;
                const payload = {
                    Key: this.gridSettingsKey,
                    Settings: JSON.stringify(this.gridSettings)
                };
    
                const result = await SettingsService.SaveGridSettings(payload, source.token);
    
                if (!result.HasError) {
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                }
            }

            this.supplierGridRef.clearFiltering();
        }
    }

    dialogOpen(header, content) {
        this.dialogInstance.header = header;
        this.dialogInstance.content = content;
        this.dialogInstance.show();
    }

    onColumnDragStart(args) {
        this.hasDraggedColumn = true;
    }

    onCreated() {
        if (this.gridSettings) {
            let settings = JSON.parse(this.gridSettings);
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(column => {
                        if (column.FromIndex !== column.ToIndex) {
                            var tempIndex = this.supplierGridRef.getColumnIndexByField(column.Field);
                            if (tempIndex !== column.ToIndex){
                                this.supplierGridRef.reorderColumnByIndex(column.FromIndex, column.ToIndex);
                            }
                        }
    
                        if (column.Width) {
                            this.supplierGridRef.columns[column.ToIndex].width = column.Width;
                        }
                    });
                }

                if (settings.colName && settings.direction) {
                    this.supplierGridRef.sortColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.supplierGridRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.supplierGridRef.refreshColumns();

                // Prevent resizing of last column
                let lastColumnIndex = this.supplierGridRef.columns.length - 1;    
                this.supplierGridRef.columns[lastColumnIndex].allowResizing = false;
                this.supplierGridRef.refreshColumns();

                if (settings.pageSize) {
                    if (settings.pageSize === "Alle") {
                        this.supplierGridRef.pageSettings.pageSize = this.supplierGridRef.pageSettings.totalRecordsCount;
                        document.querySelector("#supplier-grid .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.supplierGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
            }
        }
    }

    async onActionComplete(args) {
        if (args.requestType === 'paging') {
            if (this.supplierGridRef.pageSettings.pageSize === this.supplierGridRef.pageSettings.totalRecordsCount) {
                document.querySelector("#supplier-grid .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, this.supplierGridRef.pageSettings.pageSize, this.supplierGridRef.pageSettings.totalRecordsCount, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };
            const result = await SettingsService.SaveGridSettings(payload, source.token);
        }
        else if (args.requestType === 'filtering') {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.supplierGridRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, this.hasDraggedColumn, columns);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                let lastColumnIndex = this.supplierGridRef.columns.length - 1;
                for (let index = 0; index < this.supplierGridRef.columns.length; index++) {
                    let column = this.supplierGridRef.columns[index];
                    if (index === lastColumnIndex) { column.allowResizing = false }
                    else { column.allowResizing = true }
                }
                this.supplierGridRef.refreshColumns();
            }

            this.hasDraggedColumn = false;
        }
    }

    async onResizeStop(args) {
        args.requestType = "resizingGrid";
        let columns = this.supplierGridRef.getColumns();
        let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, columns);
        this.gridSettings = tempGridSettings;
        const payload = {
            Key: this.gridSettingsKey,
            Settings: this.gridSettings
        };

        const result = await SettingsService.SaveGridSettings(payload, source.token);

        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);

            // Adjust size of last column to fill spaces
            let lastColumnIndex = this.supplierGridRef.columns.length - 1;
            let totalColumnWidth = this.supplierGridRef.columns.reduce((acc, curVal) => acc + parseInt(curVal.width), 0);
            let lastColumnWidth = this.supplierGridRef.element.clientWidth - totalColumnWidth;
            this.supplierGridRef.columns[lastColumnIndex].width = this.supplierGridRef.columns[lastColumnIndex].width + lastColumnWidth;
            this.supplierGridRef.refreshColumns();
        }
    }

    onWheelScroll = () => {
        let grid = document.getElementById('supplier-grid');
        let toolbar = document.querySelector('#supplier-grid.e-grid .e-toolbar');
        let tableHeader = document.querySelector('#supplier-grid .e-gridheader');

        if (toolbar && tableHeader) {
            let boundingRect = tableHeader.getBoundingClientRect();
            
            if (boundingRect.top < 90) {
                toolbar.classList.add('c-hover-grid-toolbar');
                toolbar.style.width = (grid.clientWidth - 5) + 'px';
            }
            else {
                toolbar.classList.remove('c-hover-grid-toolbar');
                toolbar.style.width = 'auto';
            }
        }
    }

    render() {
        const { loading, redirect } = this.state;
        
        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        // const breadcrumbs = ['Leverandørt', 'Leverandørliste'];

        return (
            <div className="content-pane-wrapper">
                {/* <div><Breadcrumb breadcrumbs={breadcrumbs} /></div> */}
                <div className="module-header">
                    <h1>{this.pageName}</h1>
                </div>
                <div>
                    <GridComponent id="supplier-grid" ref={ref => this.supplierGridRef = ref } dataSource={this.data} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings} toolbar={this.toolbarOptions}
                        editSettings={this.defaultEditSettings} selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da"
                        actionBegin={this.actionBegin} gridLines="Both" toolbarClick={this.clickHandler} allowTextWrap='true' allowReordering={true} allowResizing={true} actionComplete={this.onActionComplete.bind(this)}
                        columnDragStart={this.onColumnDragStart.bind(this)} created={this.onCreated.bind(this)} resizeStop={this.onResizeStop.bind(this)}>
                        <ColumnsDirective>
                            <ColumnDirective field="Id" headerText="Id" width='100' />
                            <ColumnDirective field="SupplierNo" headerText="Leverandør Nr." width='100' />
                            <ColumnDirective field="Company" headerText="Firma Navn" width='100' />
                            <ColumnDirective field="MainPhone" headerText="Telefon Nr." width='100' />
                            <ColumnDirective field="VatNo" headerText="Cvr. Nr." width='100' />
                        </ColumnsDirective>
                        <Inject services={[Page, Sort, Filter, Selection, Toolbar, Edit, Search, Reorder, Resize]} />
                    </GridComponent>
                </div>
                <DialogComponent id="supplier-dialog" isModal={true} buttons={this.buttons} width='auto' ref={dialog => this.dialogInstance = dialog} target='body' 
                    visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true}/>
            </div>
        )
    }
}

SupplierList.contextType = AppContext;
export default SupplierList;
