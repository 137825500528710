import React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import Axios from 'axios';
import { ToolbarComponent, ItemsDirective, ItemDirective } from '@syncfusion/ej2-react-navigations';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { Redirect } from 'react-router-dom';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import NavigationPrompt from '../../components/NavigationPrompt';

import BaseComponent from '../../components/BaseComponent';
import Loading from '../../components/Loading';
// import Breadcrumb from '../../components/Breadcrumb';

import SessionService from '../../services/SessionService';
import MessageService from '../../services/Messages/MessagesService';

import '../../styles/systemsettings.scss';
import Localization from '../../utilities/Localization';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class MailSettings extends BaseComponent {
    moduleName = "System indstillinger";
    pageName = "Mailindstillinger";

    constructor() {
        super();

        this.state = {
            loading: true,
            breadcrumbs: ['System Indstillinger', 'Mailindstillinger'],
            isEditMode: false,
            data: {
                EWS: true,
                IMAP: false,
                ExchangeVersion: '',
                Host: '',
                Username: '',
                Password: '',
                SmtpPort: 587,
                ImapPort: 993
            },
            redirect: {
                to: '',
                isRedirect: false
            },
            hasUnsavedChanges: false
        }

        this.exchangeVersions = [];

        this.onSettingsToolbarClicked = this.onSettingsToolbarClicked.bind(this);
        this.dialogOpen = this.dialogOpen.bind(this);
        this.refreshData = this.refreshData.bind(this);
        this.ewsVersionChange = this.ewsVersionChange.bind(this);

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.buttons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        /* End Dialog options  */
    }

    async componentDidMount() {
        super.componentDidMount();
        source.cancel();
        source = CancelToken.source();

        if (!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }
        else if (!super.hasMenuItem('Mailindstillinger')) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }

        let { data } = this.state;
        const settings = await MessageService.GetSettingsMailServer(source.token);
        Object.assign(data, settings);
        data.IMAP = !data.EWS;

        let exchangeVersions = await MessageService.GetSettingsMailVersion(source.token);
        if (!exchangeVersions.HasError) {
            this.exchangeVersions = exchangeVersions;
        }

        this.setState({ loading: false, data });
    }

    async onSettingsToolbarClicked(args) {
        if (args.item.id === 'edit') {
            this.setState({ isEditMode: true });
        }
        else if (args.item.id === 'update') {
            const { data } = this.state;

            const sessionInfo = await SessionService.GetSessionInfo();
            const payload = {
                EWS: data.EWS,
                ExchangeVersion: data.EWS ? this.EWSVersionRef.value : 0,
                Host: data.Host,
                Username: data.Username,
                Password: data.Password,
                CustomerId: parseInt(sessionInfo.Id),
                SmtpPort: parseInt(data.SmtpPort),
                ImapPort: parseInt(data.ImapPort)
            };
            const result = await MessageService.UpdateMailSettings(payload, source.token);
            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Mailindstillinger"));
                this.refreshData().then(() => {
                    this.setState({ isEditMode: false, hasUnsavedChanges: false });
                });
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
        else if (args.item.id === 'cancel') {
            this.setState({ isEditMode: false, hasUnsavedChanges: false }, () => {
                this.refreshData()
            });
        }
    }

    async refreshData() {
        let data = {};
        const settings = await MessageService.GetSettingsMailServer(source.token);
        Object.assign(data, settings);
        data.IMAP = !data.EWS;
        this.setState({ data });
    }

    onChange(name, args) {
        let { data } = this.state;
        data[name] = args.target.value;
        this.setState({ data, hasUnsavedChanges: true });
    }

    ewsVersionChange(args) {
        this.setState({ hasUnsavedChanges: true });
    }

    onMailProtocolChange(name, args) {
        let { data } = this.state;
        switch (name) {
            case "EWS":
                data.EWS = this.EWSRef.checked;
                data.IMAP = !this.IMAPRef.checked;
                break;
            case "IMAP":
                data.EWS = !this.EWSRef.checked;
                data.IMAP = this.IMAPRef.checked;
                break;
        }
        this.setState({ data, hasUnsavedChanges: true });
    }

    dialogOpen(header, content) {
        this.notificationDialog.header = header;
        this.notificationDialog.content = content;
        this.notificationDialog.show();
    }

    render() {
        const { loading, isEditMode, data, breadcrumbs, redirect, hasUnsavedChanges } = this.state;
        
        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        return (
            <div className="content-pane-wrapper">
                <NavigationPrompt when={hasUnsavedChanges} 
                    navigate={path => this.props.history.push(path)}
                    initiateSave={() => this.onSettingsToolbarClicked({ item: { id: 'update' } })}
                    shouldBlockNavigation={location => {
                        if (hasUnsavedChanges) {
                            return true;
                        }
                        return false;
                    }}
                />
                {/* <div>
                    <Breadcrumb breadcrumbs={breadcrumbs} />
                </div> */}
                <div className="module-header">
                    <h1>{this.pageName}</h1>
                </div>
                <div className="system-setting-message-toolbar-wrapper">
                    <ToolbarComponent ref={ref => this.unitsToolbarRef = ref} clicked={this.onSettingsToolbarClicked}>
                        <ItemsDirective>
                            <ItemDirective id="edit" prefixIcon='tb-icons c-icon-edit' text="Redigere" tooltipText='Redigere' disabled={isEditMode}/>
                            <ItemDirective id="update" prefixIcon='tb-icons c-icon-update' text="Gem" tooltipText='Gem' disabled={!isEditMode} />
                            <ItemDirective id="cancel" prefixIcon='tb-icons c-icon-cancel' text="Annuller" tooltipText='Annuller' disabled={!isEditMode} />
                        </ItemsDirective>
                    </ToolbarComponent>
                </div>
                <div>
                    <table className="table system-setting-message-table">
                        <tbody>
                            <tr>
                                <td className="label" width="40%">Protocol</td>
                                <td><RadioButtonComponent ref={ref => this.EWSRef = ref} disabled={!isEditMode} label="EWS" checked={data.EWS} value={data.EWS} name="ews" change={this.onMailProtocolChange.bind(this, "EWS")} /></td>
                                <td><RadioButtonComponent ref={ref => this.IMAPRef = ref} disabled={!isEditMode} label="IMAP" checked={data.IMAP} value={data.IMAP} name="imap" change={this.onMailProtocolChange.bind(this, "IMAP")} /></td>
                            </tr>
                            <tr>
                                <td className="label" width="40%">Host</td>
                                <td colSpan="2">
                                    {/* <TextBoxComponent value={data.Host} enabled={isEditMode} name="Host" change={this.onChange.bind(this, "Host")} /> */}
                                    <input value={data.Host} disabled={!isEditMode} name="Host" onChange={this.onChange.bind(this, "Host")} className="e-input e-control" />
                                </td>
                            </tr>
                            {data.IMAP && 
                                <tr>
                                    <td className="label" width="40%">IMAP Port</td>
                                    <td colSpan="2">
                                        <input value={data.ImapPort} type="number" disabled={!isEditMode} name="ImapPort" onChange={this.onChange.bind(this, "ImapPort")} className="e-input e-control" />
                                    </td>
                                </tr>
                            }
                            {data.IMAP && 
                                <tr>
                                    <td className="label" width="40%">SMTP Port</td>
                                    <td colSpan="2">
                                        <input value={data.SmtpPort} type="number" disabled={!isEditMode} name="SmtpPort" onChange={this.onChange.bind(this, "SmtpPort")} className="e-input e-control" />
                                    </td>
                                </tr>
                            }
                            <tr>
                                <td className="label" width="40%">Username</td>
                                <td colSpan="2">
                                    {/* <TextBoxComponent value={data.Username} enabled={isEditMode} name="Username" change={this.onChange.bind(this, "Username")} /> */}
                                    <input value={data.Username} disabled={!isEditMode} name="Username" onChange={this.onChange.bind(this, "Username")} className="e-input" />
                                </td>
                            </tr>
                            <tr>
                                <td className="label" width="40%">Password</td>
                                <td colSpan="2">
                                    {/* <TextBoxComponent type="password" value={data.Password} enabled={isEditMode} name="Password" change={this.onChange.bind(this, "Password")} /> */}
                                    <input type="password" value={data.Password} disabled={!isEditMode} name="Password" onChange={this.onChange.bind(this, "Password")} className="e-input" />
                                </td>
                            </tr>
                            {
                                data.EWS && 
                                <tr>
                                    <td className="label" width="40%">EWS Version</td>
                                    <td colSpan="2"><DropDownListComponent ref={ref => this.EWSVersionRef = ref} dataSource={this.exchangeVersions} value={data.ExchangeVersion} enabled={isEditMode} fields={{ text: 'Key', value: 'Value' }} change={this.ewsVersionChange} /></td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <DialogComponent isModal={true} buttons={this.buttons} width='auto' ref={dialog => this.notificationDialog = dialog} target='body' showCloseIcon={true}
                    cssClass="dialog-notification" animationSettings={this.animationSettings} visible={false} allowDragging={true} enableResize={true} />
            </div>
        )
    }
}

export default MailSettings;
