import React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Page, Sort, Toolbar, Selection, Search, Edit, Reorder, Resize } from '@syncfusion/ej2-react-grids';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { Internationalization } from '@syncfusion/ej2-base';
import { Redirect } from 'react-router-dom';

import BaseComponent from '../../components/BaseComponent';
import Axios from 'axios';
import AppContext from '../../context/AppContext';
import QueryString from 'query-string';

// import AuthenticationService from '../../services/AuthenticationService';
import OrderService from '../../services/Order/OrderService';
import CustomerService from '../../services/Customer/CustomerService';
import SupplierService from '../../services/Supplier/SupplierService';
import SettingsService from '../../services/SettingsService';
import Localization from '../../utilities/Localization';
import SessionService from '../../services/SessionService';
import { buildGridSettings } from '../../utilities/Helper';

import messageHelper from '../../utilities/MessageHelper';
import Loading from '../../components/Loading';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class PurchaseList extends BaseComponent {
    moduleName = "Indkøb";
    pageName = "Indkøbsordrer";
    intl = null;
    gridSettingsKey = "PurchaseList";
    hasDraggedColumn = false;
    gridSettings = '';

    constructor() {
        super();

        this.state = {
            loading: true,
            dialogOpts: {
                header: '',
                content: null,
                visible: false
            },
            data: {
                DepartmentId: -1,
                MachineId: -1,
                SupplierId: -1,
                MachineName: ''
            },
            orderStatuses: [],
            approvalStatuses: [],
            redirect: {
                to: '',
                isRedirect: false
            }
        }

        this.pageSettings = {
            pageSize: 15,
            pageSizes: ['Alle', '5', '10', '20', '50', '100']
        };

        this.data = [];
        
        this.actionBegin = this.actionBegin.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
        this.onRecordDoubleClick = this.onRecordDoubleClick.bind(this);
        this.openPurchaseTemplate = this.openPurchaseTemplate.bind(this);
        this.init = this.init.bind(this);
        
        this.dateTemplate = this.dateTemplate.bind(this);
        this.onOrderProcessChange = this.onOrderProcessChange.bind(this);

        this.selectionSettings = {
            type: 'Single'
        };

        this.filterSettings = {
            type: 'Menu'
        };

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.buttons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];

        this.deleteConfirmationButtons = [
            {
                // Click the footer buttons to hide the Dialog
                click: () => {
                    this.deleteConfirmationDialog.hide();
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    //Enables the primary button
                    content: 'Tilbage'
                }
            },
            {
                // Click the footer buttons to hide the Dialog
                click: () => {
                    const { selectedId } = this.state;
                    this.executePurchaseOrder(selectedId);
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    //Enables the primary button
                    isPrimary: true,
                    content: 'OK'
                }
            },
        ];
        /* End Dialog options  */

        this.clickHandler = this.clickHandler.bind(this);
        this.onAddOrder = this.onAddOrder.bind(this);
        this.dialogOpen = this.dialogOpen.bind(this);
        this.onCloseAddDialog = this.onCloseAddDialog.bind(this);
    }

    async componentDidMount() {
        super.componentDidMount();

        if (!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }
        else if (!super.hasMenuItem('Indkøbsordre')) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }

        this.init();
        source.cancel();
        source = CancelToken.source();
        this.intl = new Internationalization();

        let { location } = this.props;
        let { orderStatuses, approvalStatuses } = this.state;

        const sessionInfo = await SessionService.GetSessionInfo();
        const res = await OrderService.GetOrdersByCustomerOptimized(sessionInfo.Id, source.token);
        this.data = res;

        const orderRes = await SettingsService.GetOrderStatus(source.token);
        orderStatuses = orderRes;

        const approvalRes = await SettingsService.GetApprovalStatus(source.token);
        approvalStatuses = approvalRes;

        const departments = await CustomerService.GetAccountDepartments(sessionInfo.Id, source.token);
        this.departmentsData = departments;

        const suppliers = await SupplierService.GetSuppliers(sessionInfo.Id, source.token);
        this.suppliersData = suppliers;

        const gridSettings = await SettingsService.GetGridSettings(this.gridSettingsKey, source.token);
        if (!gridSettings.HasError) {
            this.gridSettings = gridSettings.Settings;
        }
        
        this.setState({ loading: false, orderStatuses, approvalStatuses }, () => {
            let openAddDialog = false;
            if (location && location.state) {
                openAddDialog = location.state.openAddDialog;
            }
            setTimeout(() => {
                if (openAddDialog) {
                    this.addPurchaseDialog.show();
                }
                
                if (location.search) {
                    let status = '';
                    let queryString = location.search.substring(1).split('&');
                    for (let index = 0; index < queryString.length; index++) {
                        let val = QueryString.parse(queryString[index].toLowerCase());
                        if ('status' in val) {
                            status = val.status;
                        }
                    }

                    if (status) {
                        this.purchaseOrderGridRef.filterByColumn("OrderStateText", "contains", status);
                    }
                }
            }, 300);
            window.addEventListener('wheel', this.onWheelScroll);
        });
    }

    componentWillUnmount() {
        source.cancel();
        window.removeEventListener('wheel', this.onWheelScroll);
    }

    init() {
        this.toolbarOptions = [];
        this.defaultEditSettings = {};

        if (super.hasMenuItemFunction("Indkøbsordre", "Ny")) {
            this.toolbarOptions.push({ text: 'Ny', tooltipText: 'Ny', id: 'add', prefixIcon: 'e-add' });
        }
        if (super.hasMenuItemFunction("Indkøbsordre", "Åben")) {
            this.toolbarOptions.push({ text: 'Åben', tooltipText: 'Åben', id: 'open', prefixIcon: 'e-external-link' });
        }
        if (super.hasMenuItemFunction("Indkøbsordre", "Slette")) {
            this.toolbarOptions.push('Delete');
            this.defaultEditSettings = {
                allowDeleting: true,
                showDeleteConfirmDialog: true,
            };
        }
        
        this.toolbarOptions.push({ text: Localization.General.GridResetFilter, tooltipText: Localization.General.GridResetFilter, id: 'reset_filter', prefixIcon: 'e-cancel' });
    }

    async clickHandler(args) {
        if (this.purchaseOrderGridRef && args.item.id === 'add') {
            this.addPurchaseDialog.show();
        }
        else if (this.purchaseOrderGridRef && args.item.id === 'open') {
            const selectedRows = this.purchaseOrderGridRef.getSelectedRecords();
            if (selectedRows.length > 0) {
                this.props.history.push(`/indkob/ordre-detaljer/edit/${selectedRows[0].Id}`);
            }
            else {
                this.dialogOpen("Advarsel", Localization.General.NoRowSelected);
            }
        }
        else if (args.item.id === 'reset_filter') {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                settings.filters = [];
                this.gridSettings = settings;
                const payload = {
                    Key: this.gridSettingsKey,
                    Settings: JSON.stringify(this.gridSettings)
                };
    
                const result = await SettingsService.SaveGridSettings(payload, source.token);
    
                if (!result.HasError) {
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                }
            }

            this.purchaseOrderGridRef.clearFiltering();
        }
    }

    onRecordDoubleClick(args) {
        this.props.history.push(`/indkob/ordre-detaljer/edit/${args.rowData.Id}`);
    }

    onDropdownChange(args) {
        if (args.isInteracted) {
            this.ddlMachineRef.dataSource = args.itemData.Machines;
            this.ddlMachineRef.enabled = true;
        }
    }

    async onAddOrder() {
        // const { data } = this.state;
        
        // const hasDepartment = data.DepartmentId !== -1  ? true : false;
        // const hasMachine = data.MachineId !== -1 ? true : false;
        // const hasSupplier = data.SupplierId !== -1 ? true : false;

        const hasDepartment = this.ddlDepartmentRef.value && this.ddlDepartmentRef.value !== -1  ? true : false;
        const hasMachine = this.ddlMachineRef.value && this.ddlMachineRef.value !== -1 ? true : false;
        const hasSupplier = this.ddlSupplierRef.value && this.ddlSupplierRef.value !== -1 ? true : false;
        const hasSelectedAutomatedPurchase = !this.chkIsManualPurchase && !this.chkIsBasedOnPurchaseSettings;

        if (!hasDepartment || !hasMachine || !hasSupplier) {
            this.dialogOpen('Advarsel', Localization.Purchase.ErrorSelectDeptMachSupplier);
        }
        else if (hasSelectedAutomatedPurchase) {
            this.dialogOpen('Advarsel', "Vælg venligst om indkøb skal være baseret på indkøbs betingelser eller om det er et manuelt indkøb");
        }
        else {
            const sessionInfo = await SessionService.GetSessionInfo();
            const payload = {
                MachineId: this.ddlMachineRef.value,
                SupplierId: this.ddlSupplierRef.value,
                RushOrder: this.chkIsUrgent.checked,
                CustomerId: parseInt(sessionInfo.Id),
                IsAutomate: !this.chkIsManualPurchase.checked
            };
            const result = await OrderService.PostOrder(payload, source.token);
            if (!result.HasError) {
                this.props.history.push(`/indkob/ordre-detaljer/edit/${result.Id}`);
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
    }

    dateTemplate(props) {
        if (props[props.column.field]) {
            let date = new Date(props[props.column.field]);
            return <span>{this.intl.formatDate(date, { format: 'dd.MM.y' })}</span>
        }
        return '';
    }

    async executePurchaseOrder(Id) {
        const result = await OrderService.DeleteOrder(Id, source.token);
        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessDelete.replace("{context}", "Indkøbsordre"));
        }
        else {
            this.dialogOpen("Advarsel", result.ErrorMessage)
        }
        this.deleteConfirmationDialog.hide();
        await this.refreshGrid();
    }

    async refreshGrid() {
        const sessionInfo = await SessionService.GetSessionInfo();
        const res = await OrderService.GetOrdersByCustomerOptimized(sessionInfo.Id, source.token);
        this.purchaseOrderGridRef.dataSource = res;
        this.data = res;
        this.purchaseOrderGridRef.dataBind();
    }

    async actionBegin(args) {
        if (args.requestType === 'delete') {
            args.cancel = true;

            if (args.data[0].OrderState === 3) {
                this.setState({ selectedId: args.data[0].Id });
                let text = '<ul><li>Tryk på OK, for at annullerer ordre</li><li>Tryk Tilbage, for at fortryde</li></ul>';
                this.confirmDeleteDialogOpen("Advarsel", `Du er ved at annullerer en ubehandlet ordre<br> ${text}`);
            }
            else if (args.data[0].OrderState === 4) {
                this.setState({ selectedId: args.data[0].Id });
                let text = '<ul><li>Tryk OK for at anmode om annullering</li><li>Tryk Tilbage, for at fortryde</li></ul>';
                this.confirmDeleteDialogOpen("Advarsel", `Du er ved at annullerer en bestilt ordre<br>${text}`);
            }
            else {
                this.dialogOpen("Advarsel", "Du kan ikke annullere en ordre med denne status");
            }
        }
    }

    openPurchaseTemplate(props) {
        return (<i className="c-icon c-icon-external-link clickable" onClick={() => this.props.history.push(`/indkob/ordre-detaljer/edit/${props.Id}`)}></i>)
    }

    dialogOpen(header, content) {
        this.notificationDialog.header = header;
        this.notificationDialog.content = content;
        this.notificationDialog.show();
    }

    confirmDeleteDialogOpen(header, content) {
        this.deleteConfirmationDialog.header = header;
        this.deleteConfirmationDialog.content = content;
        this.deleteConfirmationDialog.show();
    }

    onColumnDragStart(args) {
        this.hasDraggedColumn = true;
    }

    onCreated() {
        if (this.gridSettings) {
            let settings = JSON.parse(this.gridSettings);
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(column => {
                        if (column.FromIndex !== column.ToIndex) {
                            var tempIndex = this.purchaseOrderGridRef.getColumnIndexByField(column.Field);
                            if (tempIndex !== column.ToIndex){
                                this.purchaseOrderGridRef.reorderColumnByIndex(column.FromIndex, column.ToIndex);
                            }
                        }
    
                        if (column.Width) {
                            this.purchaseOrderGridRef.columns[column.ToIndex].width = column.Width;
                        }
                    });
                }

                if (settings.colName && settings.direction) {
                    this.purchaseOrderGridRef.sortColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.purchaseOrderGridRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.purchaseOrderGridRef.refreshColumns();

                // Prevent resizing of last column
                let lastColumnIndex = this.purchaseOrderGridRef.columns.length - 1;    
                this.purchaseOrderGridRef.columns[lastColumnIndex].allowResizing = false;
                this.purchaseOrderGridRef.refreshColumns();

                if (settings.pageSize) {
                    if (settings.pageSize === "Alle") {
                        this.purchaseOrderGridRef.pageSettings.pageSize = this.purchaseOrderGridRef.pageSettings.totalRecordsCount;
                        document.querySelector("#purchase-grid .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.purchaseOrderGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
            }
        }
    }

    async onActionComplete(args) {
        if (args.requestType === 'paging') {
            if (this.purchaseOrderGridRef.pageSettings.pageSize === this.purchaseOrderGridRef.pageSettings.totalRecordsCount) {
                document.querySelector("#purchase-grid .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, this.purchaseOrderGridRef.pageSettings.pageSize, this.purchaseOrderGridRef.pageSettings.totalRecordsCount, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };
            const result = await SettingsService.SaveGridSettings(payload, source.token);
        }
        else if (args.requestType === 'filtering') {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.purchaseOrderGridRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, this.hasDraggedColumn, columns);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                let lastColumnIndex = this.purchaseOrderGridRef.columns.length - 1;
                for (let index = 0; index < this.purchaseOrderGridRef.columns.length; index++) {
                    let column = this.purchaseOrderGridRef.columns[index];
                    if (index === lastColumnIndex) { column.allowResizing = false }
                    else { column.allowResizing = true }
                }
                this.purchaseOrderGridRef.refreshColumns();
            }

            this.hasDraggedColumn = false;
        }
    }

    async onResizeStop(args) {
        args.requestType = "resizingGrid";
        let columns = this.purchaseOrderGridRef.getColumns();
        let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, columns);
        this.gridSettings = tempGridSettings;
        const payload = {
            Key: this.gridSettingsKey,
            Settings: this.gridSettings
        };

        const result = await SettingsService.SaveGridSettings(payload, source.token);

        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);

            // Adjust size of last column to fill spaces
            let lastColumnIndex = this.purchaseOrderGridRef.columns.length - 1;
            let totalColumnWidth = this.purchaseOrderGridRef.columns.reduce((acc, curVal) => acc + parseInt(curVal.width), 0);
            let lastColumnWidth = this.purchaseOrderGridRef.element.clientWidth - totalColumnWidth;
            this.purchaseOrderGridRef.columns[lastColumnIndex].width = this.purchaseOrderGridRef.columns[lastColumnIndex].width + lastColumnWidth;
            this.purchaseOrderGridRef.refreshColumns();
        }
    }

    onCloseAddDialog() {
        let { data } = this.state;
        data.DepartmentId = -1;
        data.MachineId = -1;
        data.SupplierId = -1;
        this.setState({ data }, () => {
            this.ddlDepartmentRef.value = null;
            this.ddlMachineRef.value = null;
            this.ddlSupplierRef.value = null;
            this.chkIsUrgent.checked = false;
            this.addPurchaseDialog.hide();
        });
    }

    onWheelScroll = () => {
        let grid = document.getElementById('purchase-grid');
        let toolbar = document.querySelector('#purchase-grid.e-grid .e-toolbar');
        let tableHeader = document.querySelector('#purchase-grid .e-gridheader');

        if (toolbar && tableHeader) {
            let boundingRect = tableHeader.getBoundingClientRect();
            
            if (boundingRect.top < 90) {
                toolbar.classList.add('c-hover-grid-toolbar');
                toolbar.style.width = (grid.clientWidth - 5) + 'px';
            }
            else {
                toolbar.classList.remove('c-hover-grid-toolbar');
                toolbar.style.width = 'auto';
            }
        }
    }

    onOrderProcessChange(event, name) {
        if (name === "manual") {
            this.chkIsBasedOnPurchaseSettings.checked = false;
        }
        else if (name === "purchasesetting") {
            this.chkIsManualPurchase.checked = false;
        }
    }

    render() {
        const { loading, data, redirect } = this.state;

        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        return (
            <div className="content-pane-wrapper">
                <div className="module-header">
                    <h1>{this.pageName}</h1>
                </div>
                <div>
                    <GridComponent id="purchase-grid" ref={ref => this.purchaseOrderGridRef = ref } dataSource={this.data} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings} toolbar={this.toolbarOptions}
                        editSettings={this.defaultEditSettings} selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} allowTextWrap='true' locale="da"
                        actionBegin={this.actionBegin} gridLines="Both" toolbarClick={this.clickHandler} recordDoubleClick={this.onRecordDoubleClick} allowReordering={true} allowResizing={true}
                        actionComplete={this.onActionComplete.bind(this)} columnDragStart={this.onColumnDragStart.bind(this)} created={this.onCreated.bind(this)} resizeStop={this.onResizeStop.bind(this)}>
                        <ColumnsDirective>
                            <ColumnDirective headerText="Afdeling" field="Machine.Department.Name" width='80' />
                            <ColumnDirective headerText="Bestilt af" field="OrderBy" width='160' />
                            <ColumnDirective headerText="Status" field="OrderStateText" width='80' />
                            <ColumnDirective headerText="Godkendt" field="ApprovedStateText" width='80' />
                            <ColumnDirective headerText="Opret Dato" field="CreationDate" width='80' type="date" format={{ type: 'date', format: 'dd.MM.y' }} textAlign="Right" /> {/* type="date" format={{ type: 'date', format: 'dd.MM.y' }}  */}
                            <ColumnDirective headerText="Bestilt Dato" field="OrderedDate" width='80' type="date" format={{ type: 'date', format: 'dd.MM.y' }} textAlign="Right" /> {/* type="date" format={{ type: 'date', format: 'dd.MM.y' }}  */}
                            <ColumnDirective headerText="Modtaget Dato" field="ReceivedDate" width='90' type="date" format={{ type: 'date', format: 'dd.MM.y' }} textAlign="Right" /> {/* type="date" format={{ type: 'date', format: 'dd.MM.y' }}  */}
                            <ColumnDirective headerText="Indkøbs Nr." field="PurchaseNo" width='80' textAlign="Right" />
                            {/* <ColumnDirective headerText="Gå til bestilling" field="Id" width='100' textAlign="Center" template={this.openPurchaseTemplate} allowFiltering={false} /> */}
                        </ColumnsDirective>
                        <Inject services={[Page, Sort, Filter, Selection, Toolbar, Edit, Search, Reorder, Resize]} />
                    </GridComponent>
                </div>
                <div>
                    <DialogComponent ref={dialog => this.notificationDialog = dialog} id="purchase-dialog" isModal={true} buttons={this.buttons} width='auto' target='body'
                         visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true}/>
                    <DialogComponent id="add-purchase-dialog" isModal={true} header="Oprette En Indkøbsordre" width='auto' ref={dialog => this.addPurchaseDialog = dialog} 
                        target='body' visible={false} showCloseIcon={true} animationSettings={this.animationSettings} close={this.onCloseAddDialog} allowDragging={true} enableResize={true}>
                        <div className="dialog-content">
                            <div className="mb-20">
                                <DropDownListComponent ref={ref => this.ddlDepartmentRef = ref} name="DepartmentId" value={data.DepartmentId} dataSource={this.departmentsData} 
                                    placeholder="Vælg Afdeling" fields={{ text: 'Name', value: 'Id' }} change={this.onDropdownChange.bind(this)} floatLabelType="Auto" locale="da" />
                            </div>
                            <div className="mb-20">
                                <DropDownListComponent ref={ref => this.ddlMachineRef = ref} name="MachineId" value={data.MachineId} dataSource={this.machinesData} enabled={data.DepartmentId !== -1}
                                    placeholder="Vælg Automat" fields={{ text: 'CustomName', value: 'Id' }} floatLabelType="Auto" locale="da" />
                            </div>
                            <div className="mb-20">
                                <DropDownListComponent ref={ref => this.ddlSupplierRef = ref} name="SupplierId" value={data.SupplierId} dataSource={this.suppliersData}
                                    placeholder="Vælge Leverandør" fields={{ text: 'Company', value: 'Id' }} floatLabelType="Auto" locale="da" />
                            </div>
                            <div className="mb-20">
                                <CheckBoxComponent ref={ref => this.chkIsManualPurchase = ref} label="Manuel Indkøb" checked={true} change={e => this.onOrderProcessChange(e, 'manual')} />
                            </div>
                            <div className="mb-20">
                                <CheckBoxComponent ref={ref => this.chkIsBasedOnPurchaseSettings = ref} label="Baseret på indkøbsbetingelser" change={e => this.onOrderProcessChange(e, 'purchasesetting')} />
                            </div>
                            <div className="divider"></div>
                            <div>
                                <CheckBoxComponent ref={ref => this.chkIsUrgent = ref} label="Haste ordre" />
                            </div>
                        </div>
                        <div className="dialog-footer">
                            <button className="e-control e-btn e-lib" onClick={this.onCloseAddDialog}>Annuller</button>
                            <button className="e-control e-btn e-lib e-primary" style={{width: 121}} onClick={this.onAddOrder}>Gem</button>
                        </div>
                    </DialogComponent>
                    <DialogComponent ref={dialog => this.deleteConfirmationDialog = dialog} isModal={true} buttons={this.deleteConfirmationButtons} width='auto' target='body'
                        visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true}/>
                </div>
            </div>
        )
    }
}

PurchaseList.contextType = AppContext;
export default PurchaseList;
