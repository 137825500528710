import React from 'react';
import { TreeViewComponent, ToolbarComponent, ItemsDirective, ItemDirective } from '@syncfusion/ej2-react-navigations';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
// import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { RadioButtonComponent, CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import Axios from 'axios';
import QueryString from 'query-string';
// import { FileManagerComponent, Inject as FileManagerInject, NavigationPane, DetailsView } from '@syncfusion/ej2-react-filemanager';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Page, Sort, Toolbar, Selection, Edit, Search, Reorder, Resize } from '@syncfusion/ej2-react-grids';
import NumberFormat from 'react-number-format';
import { Redirect } from 'react-router-dom';

import productPlaceholder from '../../assets/placeholders/img_placeholder_120x120.jpg';

import BaseComponent from '../../components/BaseComponent';
import AppContext from '../../context/AppContext';
// import ProductDescription from './ProductDescription';
// import ProductSize from './ProductSize';
// import ProductPrice from './ProductPrice';
// import ProductStock from './ProductStock';
import ProductSupplier from './ProductSupplier';
// import ProductPicture from './ProductPicture';
// import ProductGroup from './ProductGroup';
import Breadcrumb from '../../components/Breadcrumb';
// import ProductGeneralSettings from './ProductGeneralSettings';
import { numberTo2DecimalPlace, formatMoney } from '../../utilities/Helper';
import NavigationPrompt from '../../components/NavigationPrompt'
import { buildGridSettings } from '../../utilities/Helper';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Card from '../../components/Card';
// import Image from '../../components/Image';

import SessionService from '../../services/SessionService';
// import AuthenticationService from '../../services/AuthenticationService';
import SettingsService from '../../services/SettingsService';
import ProductService from '../../services/Product/ProductService';
import ProductGroupService from '../../services/Product/ProductGroupService';
import FileService from '../../services/File/FileService';
import SupplierService from '../../services/Supplier/SupplierService';
import CustomerService from '../../services/Customer/CustomerService';
import StockService from '../../services/Stock/StockService';
// import { FileArchiveAPI } from '../../services/File/FileService';
import Localization from '../../utilities/Localization';
import WeightService from '../../services/SystemSettings/WeightService';

import Image from '../../components/Image';
import '../../styles/products.scss';
import Loading from '../../components/Loading';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

let TabFields = {
    Description: [ "ProductNo", "EAN", "Description1", "Description2", "SalesUnit", "SalesUnitPerPcs" ],
    Sizes: [ "Length", "Height", "Width", "CustomVolume", "Weight" ],
    Price: [ "Factor", "ProfitPercentage", "Profit", "SalesPrice" ],
    Setting: [ "PurchaseTolerance", "ActiveCaseNumber", "Deactivated", "AlwaysInputQty", "AlwaysWeight", "ChooseWhenProductDraw" ]
}

class Product extends BaseComponent {
    moduleName = "Produkt";
    pageName = "Produktkort";
    isTabRendering = true;
    productStockGridSettingsKey = "ProductStockList";
    productSupplierGridSettingsKey = "ProductSupplierList";
    productImagesGridSettingsKey = "ProductImageList";
    hasDraggedColumn = false;
    productStockGridSettings = '';
    productSupplierGridSettings = '';
    productImagesGridSettings = '';
    GuaranteedWeight = 0;
    hasOpenedSupplierTab = false;
    supplierPermission = null;

    constructor(props) {
        super(props);
        
        const { match, location } = props;
        const productId = match && match.params && match.params.id;
        let link = '/produktkort/add';

        if (productId) {
            link = `/produktkort/edit/${productId}`;
        }

        this.state = {
            loading: true,
            data: {
                ProductGroupId: 0,
                ProductGroup: {},
                AlwaysInputQty: true,
                AlwaysWeight: false,
                ChooseWhenProductDraw: false,
                ProductSuppliers: [],
                ProductStorages: [],
                ProductImages: []
            },
            breadcrumbs: [{text: 'Produktliste', link: '/produktliste'}, 'Beskrivelse'],
            selectedWeightId: 1,
            selectedDimensionId: 1,
            currentTabSelected: 0,
            priceGlobal: {
                CostPrice: 0,
                Factor: 0,
                ProfitPercent: 0,
                Profit: 0,
                SalesPrice: 0
            },
            dialogOpts: {
                token: null
            },
            isEditMode: false,
            transaction: {
                hasUndo: false,
                hasRedo: false,
                undoTooltip : '',
                redoTooltip: ''
            },
            redirect: {
                to: '',
                isRedirect: false
            },
            hasUnsavedChanges: false,
            transactionCategory: [],
            transactionTableDelLog: {
                Suppliers: [],
                Stock: [],
                Pictures: []
            },
            supplierDialogProps: {
                Id: null,
                visible: false
            },
            groupGuaranteedWeight: 1,
            oldGroupId: 0,
            changeOrder: [],
            stockChangeLog: [],
            supplierChangeLog: [],
            selectedMachine: null,
            redirectPath: null,
            tabPages: [],
            hasNext: false,
            hasPrev: false,
            redirectId: ''
        }

        this.tabHeader = [
            { text: "Beskrivelse" }, { text: "Størrelse" }, { text: "Produkt grupper" }, 
            { text: "Leverandør" }, { text: "Pris" }, { text: "Lager" },
            // { text: "Produkt pakker" }, { text: "Dokumenter" }, 
            { text: "Billeder" }, 
            // { text: "Søgeord" }, 
            { text: "Generelle indstillinger" }
        ];

        this.pageSettings = {
            pageSize: 10,
            pageSizes: ['Alle', '5', '10', '20', '50', '100']
        };

        this.departmentTreeFields = {
            dataSource: [], 
            id: 'tempId', text: 'Name',
            child: 'Machines' 
        }

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.buttons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];

        this.deleteButtons = [
            {
                // Click the footer buttons to hide the Dialog
                click: () => {
                    this.dialogInstance.hide();
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    //Enables the primary button
                    content: 'Annuller'
                }
            },
            {
                // Click the footer buttons to hide the Dialog
                click: this.deleteStockLocation,
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    //Enables the primary button
                    isPrimary: true,
                    content: 'Slet'
                }
            }
        ];
        this.dialogOpen = this.dialogOpen.bind(this);
        /* End Dialog options  */

        this.primaryProductGroupFields = {
            dataSource: [], id: 'Id', text: 'Name', child: 'SubGroups' 
        };

        this.productPicturesFields = {
            dataSource: [], id: 'Key', text: 'Name', child: 'SubFiles' 
        };

        this.selectionSettings = {
            type: 'Single'
        };

        this.filterSettings = {
            type: 'Menu'
        };

        // this.onDataChange = this.onDataChange.bind(this);
        this.init = this.init.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.toggleProductDescriptionFields = this.toggleProductDescriptionFields.bind(this);
        this.toggleProductSizeFields = this.toggleProductSizeFields.bind(this);
        this.onPrimaryProductGroupChecked = this.onPrimaryProductGroupChecked.bind(this);
        this.toggleProductGroupFields = this.toggleProductGroupFields.bind(this);
        this.toggleProductPriceFields = this.toggleProductPriceFields.bind(this);
        this.toggleGeneralSettingsTab = this.toggleGeneralSettingsTab.bind(this);
        this.onProductToolbarClicked = this.onProductToolbarClicked.bind(this);
        this.onProductCreated = this.onProductCreated.bind(this);
        this.undoRedoOptions = this.undoRedoOptions.bind(this);
        this.toggleTabFields = this.toggleTabFields.bind(this);
        this.onProfitPercentageChange = this.onProfitPercentageChange.bind(this);
        this.onTabSelected = this.onTabSelected.bind(this);
        this.onEditMode = this.onEditMode.bind(this);

        this.productDescriptionTab = this.productDescriptionTab.bind(this);
        this.productSizeTab = this.productSizeTab.bind(this);
        this.productGroupTab = this.productGroupTab.bind(this);
        this.productSupplierTab = this.productSupplierTab.bind(this);
        this.productPriceTab = this.productPriceTab.bind(this);
        this.productStockTab = this.productStockTab.bind(this);
        this.productPicturesTab = this.productPicturesTab.bind(this);
        this.productGeneralSettingsTab = this.productGeneralSettingsTab.bind(this);
        // this.initiateSave = this.initiateSave.bind(this);

        this.numericParams = { params: { showSpinButton: false, min: 0, step: 0, cssClass: 'text-right' }};
        this.numericParams2 = { params: { decimals: 4, format: "N", showSpinButton: false, min: 0, step: 0, cssClass: 'text-right' }};

        // Pictures tab binding
        this.onCheckChange = this.onCheckChange.bind(this);
        this.dialogClose = this.dialogClose.bind(this);
        this.onFileImageSelected = this.onFileImageSelected.bind(this);
        this.primaryTemplate = this.primaryTemplate.bind(this);
        this.onActionBegin = this.onActionBegin.bind(this);
        this.clickHandler = this.clickHandler.bind(this);
        this.beforeSend = this.beforeSend.bind(this);
        this.refreshPicturesGrid = this.refreshPicturesGrid.bind(this);
        this.pictureNodeCheck = this.pictureNodeCheck.bind(this);

        // Supplier Tab binding
        this.productSupplierGridData = [];
        this.suppliersData = [];
        this.onSupplierSaveSelected = this.onSupplierSaveSelected.bind(this);
        this.onActionBegin = this.onActionBegin.bind(this);
        this.refreshSupplierGrid = this.refreshSupplierGrid.bind(this);
        this.computeAveragePrice = this.computeAveragePrice.bind(this);
        this.validateEAN = this.validateEAN.bind(this);

        // Stock tab binding and properties
        this.productStockGridData = [];
        this.customerDepartments = [];
        this.refreshStockGrid = this.refreshStockGrid.bind(this);
        this.onStockSaveSelected = this.onStockSaveSelected.bind(this);
        this.onDepartmentChecked = this.onDepartmentChecked.bind(this);
        this.onMachineNodeClicked = this.onMachineNodeClicked.bind(this);
        this.onProductGroupNodeClicked = this.onProductGroupNodeClicked.bind(this);
        this.deleteStockLocation = this.deleteStockLocation.bind(this);

        this.onUnload = this.onUnload.bind(this);
    }

    onUnload = e => {
        const { hasUnsavedChanges } = this.state;
        if (hasUnsavedChanges) {
            this.NavigationPromptRef.showModal(window.location.href);
            e.preventDefault();
            e.returnValue = '';
            return null;
        }
        else {
            return;
        }
    }

    async componentDidMount() {
        super.componentDidMount();
        window.addEventListener("beforeunload", this.onUnload);
        source.cancel();
        source = CancelToken.source();

        if (!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }
        else if (!super.hasMenuItem('Produktkort')) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }

        this.init();

        const { match, location } = this.props;
        let { data, priceGlobal, currentTabSelected, dialogOpts, isEditMode, transaction, groupGuaranteedWeight, oldGroupId, tabPages, hasNext, hasPrev } = this.state;

        const productId = match && match.params && match.params.id;
        let moduleState = 'add', imageResult = '';
        let selectedWeight = {};
        let selectedDimension = {};

        const sessionInfo = await SessionService.GetSessionInfo();
        dialogOpts.token = sessionInfo.access_token;
        this.UserCustomerId = sessionInfo.Id;

        if(productId) {
            moduleState = 'edit';
            // isEditMode = true;
            const result = await ProductService.GetProduct(productId, source.token);
            if (result.HasError) {
                let response = super.processModuleResponse(result);
                this.setState(response);
                return;
            }
            
            Object.assign(data, result);
            data.PurchaseTolerance = data.PurchaseTolerance ? data.PurchaseTolerance * 100 : data.ProductGroup ? data.ProductGroup.PurchaseTolerance * 100 : 0;
            data.ProfitPercentage = data.ProfitPercentage ? data.ProfitPercentage * 100 : null;

            if (data.ProductImages !== null && data.ProductImages.length > 0) {
                let primaryImage = data.ProductImages.find(img => img.IsPrimary);

                if (!primaryImage && data.ProductImages.length > 0) {
                    primaryImage = data.ProductImages[0];
                }

                if (primaryImage) {
                    imageResult = await FileService.GetImage(primaryImage.ImageKey);
                }

                this.picturesData = data.ProductImages;
            }
            
            let sessionSelectedTab = sessionStorage.getItem("PRODUCT_TAB_DEFAULT");
            if (sessionSelectedTab) {
                if (this.tabHeader.some(x => x.text.toLowerCase() === sessionSelectedTab.toLowerCase())) {
                    currentTabSelected = this.tabHeader.findIndex(x => x.text.toLowerCase() === sessionSelectedTab.toLowerCase());
                }
            }

            if (location.search) {
                let queryString = location.search.replace('?', '').split('&');
                for (let index = 0; index < queryString.length; index++) {
                    let val = QueryString.parse(queryString[index]);
                    if ('tab' in val) {
                        if (this.tabHeader.some(x => x.text.toLowerCase() === val.tab.toLowerCase())) {
                            currentTabSelected = this.tabHeader.findIndex(x => x.text === val.tab);
                        }
                    }
                }

                let accessibleTabs = super.getMenuItemTabs("Produktkort");
                if (accessibleTabs) {
                    if (currentTabSelected === 0 && super.hasMenuItemTabAccess("Produktkort", "Beskrivelse")) {
                        currentTabSelected = 0;
                    }
                    else if (currentTabSelected === 1 && super.hasMenuItemTabAccess("Produktkort", "Størrelse")) {
                        currentTabSelected = 1;
                    }
                    else if (currentTabSelected === 2 && super.hasMenuItemTabAccess("Produktkort", "Produkt grupper")) {
                        currentTabSelected = 2;
                    }
                    else if (currentTabSelected === 3 && super.hasMenuItemTabAccess("Produktkort", "Leverandør")) {
                        currentTabSelected = 3;
                    }
                    else if (currentTabSelected === 4 && super.hasMenuItemTabAccess("Produktkort", "Pris")) {
                        currentTabSelected = 4;
                    }
                    else if (currentTabSelected === 5 && super.hasMenuItemTabAccess("Produktkort", "Lager")) {
                        currentTabSelected = 5;
                    }
                    else if (currentTabSelected === 6 && super.hasMenuItemTabAccess("Produktkort", "Billeder")) {
                        currentTabSelected = 6;
                    }
                    else if (currentTabSelected === 7 && super.hasMenuItemTabAccess("Produktkort", "Generelle indstillinger")) {
                        currentTabSelected = 7;
                    }
                    else {
                        currentTabSelected = 0;
                        this.props.history.push(`/produktkort/edit/${productId}`);
                    }
                }
            }

            const transactionStatus = await ProductService.GetUndoRedoStatus(data.Id, currentTabSelected, source.token);
            
            transaction.hasUndo = transactionStatus.Undo;
            transaction.hasRedo = transactionStatus.Redo;
            transaction.undoTooltip = transactionStatus.UndoAction;
            transaction.redoTooltip = transactionStatus.RedoAction;

            this.productSupplierGridData = data.ProductSuppliers.sort((a, b) => a.Priority > b.Priority ? 1 : -1);
            this.productStockGridData = data.ProductStorages;

            if (result.ProductGroup) {
                data.ProductGroupId = result.ProductGroup.Id;
            }
        }
        else {
            isEditMode = true;
        }
        
        const files = await FileService.GetImageList(source.token);
        let tempFiles = [{ Key: files.Key, SubFiles: files.SubFiles, Name: files.Name, expanded: true }];
        this.productPicturesFields.dataSource = tempFiles;

        const dimensions = await SettingsService.GetDimensions(source.token);
        const weights = await SettingsService.GetWeights(source.token);
        const productGroupsResult = await ProductGroupService.GetProductGroupsLite(sessionInfo.Id, source.token);

        let departments = await CustomerService.GetAccountDepartments(sessionInfo.Id, source.token);
        departments = departments.filter(x => x.Machines.length > 0)
        departments = departments.map(dept => {
            dept.Machines = dept.Machines.map(machine => {
                machine.Name = machine.CustomName;

                return {
                    ...machine,
                    tempId: machine.Id + 2000,
                    expanded: true
                }
            });

            return {
                ...dept,
                tempId: dept.Id + 1000,
                expanded: true
            }
        })

        this.customerDepartments = departments;
        this.departmentTreeFields.dataSource = departments;
        const units = await SettingsService.GetUnitSettings(source.token);

        if (data.DimensionUnit) {
            selectedDimension = dimensions.find(x => x.Value === data.DimensionUnit);
        }
        else {
            selectedDimension = dimensions.find(x => x.Value === units.DimensionUnit);
        }

        if (data.WeightUnit) {
            selectedWeight = weights.find(x => x.Value === data.WeightUnit);
        }
        else {
            selectedWeight = weights.find(x => x.Value === units.WeightUnit);
        }

        this.primaryProductGroupFields.dataSource = productGroupsResult.map(x => {
            return {
                ...x,
                expanded: x.SubGroups.length > 0 ? true : false
            }
        });
        this.productGroups = productGroupsResult;

        if (data.ProductGroup) {
            priceGlobal.Factor = data.ProductGroup.Factor;
            priceGlobal.ProfitPercent = data.ProductGroup.ProfitPercentage;
            groupGuaranteedWeight = data.ProductGroup.GuaranteedWeight;
            oldGroupId = data.ProductGroup.Id;
        }
        if (data.ProductSuppliers) {
            // let costPrice = 0
            // for (let index = 0; index < data.ProductSuppliers.length; index++) {
            //     costPrice += data.ProductSuppliers[index].PurchasePrice;
            // }
            // priceGlobal.CostPrice = costPrice / data.ProductSuppliers.length;
            priceGlobal.CostPrice = data.ProductSuppliers.length > 0 ? data.ProductSuppliers.sort((a,b) => (a.Priority > b.Priority) ? 1 : -1)[0].PurchasePrice : 0;
        }

        const weightPayload = { test: false, prev: false };
        let shelfWeight = await WeightService.GetShelf(weightPayload, source.token);
        if (shelfWeight) {
            this.GuaranteedWeight = shelfWeight.GuaranteeWeight;

            if (data.Weight > this.GuaranteedWeight) {
                oldGroupId = 0;
            }
        }

        const suppliers = await SupplierService.GetSuppliers(sessionInfo.Id, source.token);
        this.suppliersData = suppliers;
        this.filteredSuppliersData = suppliers;

        const productStockGridSettings = await SettingsService.GetGridSettings(this.productStockGridSettingsKey, source.token);
        if (!productStockGridSettings.HasError) {
            this.productStockGridSettings = productStockGridSettings.Settings;
        }

        const productSupplierGridSettings = await SettingsService.GetGridSettings(this.productSupplierGridSettingsKey, source.token);
        if (!productSupplierGridSettings.HasError) {
            this.productSupplierGridSettings = productSupplierGridSettings.Settings;
        }

        const productImagesGridSettings = await SettingsService.GetGridSettings(this.productImagesGridSettingsKey, source.token);
        if (!productImagesGridSettings.HasError) {
            this.productImagesGridSettings = productImagesGridSettings.Settings;
        }

        let supplierPermission = super.getSiteRoute("Leverandør");
        this.supplierPermission = supplierPermission;

        hasNext = data.NextId > 0;
        hasPrev = data.PrevId > 0;

        this.setState({ loading: false, moduleState, data, dimensionsData: dimensions, weightsData: weights, selectedWeight, selectedDimension, isEditMode,
                selectedWeightId: selectedWeight.Value, selectedDimensionId: selectedDimension.Value, priceGlobal, dialogOpts, defaultUnits: units, currentTabSelected, 
                groupGuaranteedWeight, oldGroupId, tabPages, hasNext, hasPrev  }, async () => {
            if (imageResult) {
                this.productImage.src = imageResult ? imageResult : productPlaceholder;
            }

            if (data.Id) {
                switch(currentTabSelected) {
                    case 0:
                        this.toggleProductDescriptionFields(isEditMode);
                        break;
                    case 1:
                        this.toggleProductSizeFields(isEditMode);
                        break;
                    case 2:
                        this.toggleProductGroupFields(isEditMode);
                        if (data.ProductGroup && Object.keys(data.ProductGroup).length > 0) {
                            if (data.ProductGroup.Id !== data.ProductGroupId) {
                                this.primaryProductGroupRef.checkedNodes = [data.ProductGroupId.toString()];
                            }
                            else if (data.ProductGroup && data.ProductGroup.Id) {
                                this.primaryProductGroupRef.checkedNodes = [data.ProductGroup.Id.toString()];
                            }
                        }
                        else {
                            if (data.ProductGroupId) {
                                this.primaryProductGroupRef.checkedNodes = [data.ProductGroupId.toString()];
                            }
                        }
                        break;
                    case 3: //supplier
                        setTimeout(() => {
                            if (this.productSupplierGridRef) {
                                this.productSupplierGridRef.toolbarModule.enableItems(['add', 'open'], isEditMode);
                                let editButton = document.querySelector('.product-supplier-wrapper .e-grid .e-toolbar-item[title="Redigere"]');
                                let deleteButton = document.querySelector('.product-supplier-wrapper .e-grid .e-toolbar-item[title="Slette"]');
                                if (editButton) {
                                    if (!isEditMode) {
                                        editButton.classList.add('e-overlay');
                                    }
                                    else {
                                        editButton.classList.remove('e-overlay');
                                    }
                                    editButton.querySelector('button').disabled = !isEditMode;
                                }
    
                                if (deleteButton) {
                                    if (!isEditMode) {
                                        deleteButton.classList.add('e-overlay');
                                    }
                                    else {
                                        deleteButton.classList.remove('e-overlay');
                                    }
                                    deleteButton.querySelector('button').disabled = !isEditMode;
                                }

                                if (isEditMode) {
                                    this.productSupplierGridRef.element.querySelector('.e-gridcontent').classList.remove('c-disablegrid');
                                    let wrapper = document.getElementsByClassName('product-supplier-wrapper')[0];
                                    if (wrapper) {
                                        wrapper.classList.remove('c-disablegrid-wrapper');
                                    }
                                }
                                else {
                                    this.productSupplierGridRef.element.querySelector('.e-gridcontent').classList.add('c-disablegrid');
                                    let wrapper = document.getElementsByClassName('product-supplier-wrapper')[0];
                                    if (wrapper) {
                                        wrapper.classList.remove('c-disablegrid-wrapper');
                                    }
                                }
                            }
                        }, 250);
                        break;
                    case 4:
                        this.toggleProductPriceFields(isEditMode);
                        break;
                    case 5: //stock
                        setTimeout(() => {
                            if (this.productStockGridRef) {
                                this.productStockGridRef.toolbarModule.enableItems(['add'], isEditMode);
                                let editButton = document.querySelector('.product-stock-wrapper .e-grid .e-toolbar-item[title="Redigere"]');
                                let deleteButton = document.querySelector('.product-stock-wrapper .e-grid .e-toolbar-item[title="Slette"]');
                                if (editButton) {
                                    if (!isEditMode) {
                                        editButton.classList.add('e-overlay');
                                    }
                                    else {
                                        editButton.classList.remove('e-overlay');
                                    }
                                }
    
                                if (deleteButton) {
                                    if (!isEditMode) {
                                        deleteButton.classList.add('e-overlay');
                                    }
                                    else {
                                        deleteButton.classList.remove('e-overlay');
                                    }
                                }

                                if (isEditMode) {
                                    this.productStockGridRef.element.querySelector('.e-gridcontent').classList.remove('c-disablegrid');
                                    let wrapper = document.getElementsByClassName('product-stock-wrapper')[0];
                                    if (wrapper) {
                                        wrapper.classList.remove('c-disablegrid-wrapper');
                                    }
                                }
                                else {
                                    this.productStockGridRef.element.querySelector('.e-gridcontent').classList.add('c-disablegrid');
                                    let wrapper = document.getElementsByClassName('product-stock-wrapper')[0];
                                    if (wrapper) {
                                        wrapper.classList.remove('c-disablegrid-wrapper');
                                    }
                                }
                            }
                        }, 250);
                        break;
                    case 6: //pictures
                        setTimeout(() => {
                            if (this.ProductPicturesGridRef) {
                                this.ProductPicturesGridRef.toolbarModule.enableItems(['linkImage'], isEditMode);
                                
                                let deleteButton = document.querySelector('.product-image-wrapper .e-grid .e-toolbar-item[title="Slette"]');
    
                                if (deleteButton) {
                                    if (!isEditMode) {
                                        deleteButton.classList.add('e-overlay');
                                    }
                                    else {
                                        deleteButton.classList.remove('e-overlay');
                                    }
                                }

                                if (isEditMode) {
                                    this.ProductPicturesGridRef.element.querySelector('.e-gridcontent').classList.remove('c-disablegrid');
                                    let wrapper = document.getElementsByClassName('product-image-wrapper')[0];
                                    if (wrapper) {
                                        wrapper.classList.remove('c-disablegrid-wrapper');
                                    }
                                }
                                else {
                                    this.ProductPicturesGridRef.element.querySelector('.e-gridcontent').classList.add('c-disablegrid');
                                    let wrapper = document.getElementsByClassName('product-image-wrapper')[0];
                                    if (wrapper) {
                                        wrapper.classList.remove('c-disablegrid-wrapper');
                                    }
                                }
                            }
                        }, 250);
                        break;
                    case 7:
                        this.toggleGeneralSettingsTab(isEditMode, moduleState);
                        break;
                    default:
                        break;
                }
            }
            // setTimeout(() => {
            //     if(moduleState === 'add') {
            //         this.toggleProductDescriptionFields(true);
            //         this.toggleProductSizeFields(true);
            //         this.toggleProductGroupFields(true);
            //         this.toggleProductPriceFields(true);
            //         this.toggleGeneralSettingsTab(true);
            //     }

            //     this.ProductToolbarRef.enableItems(6, false);
            //     this.ProductToolbarRef.enableItems(7, false);
            // }, 200);
        });
    }

    componentWillUnmount() {
        sessionStorage.removeItem("PRODUCT_TAB_DEFAULT");
        sessionStorage.removeItem('prod_id');
        source.cancel();
        window.removeEventListener("beforeunload", this.onUnload);
    }

    init() {
        // PICTURE TAB 
        this.toolbarOptions = [];
        this.defaultEditSettings = {};
        
        this.productPictureFileFields = {
            dataSource: [], 
            id: 'Key', text: 'Name',
            child: 'SubFiles' 
        };

        if (super.hasMenuItemTabFunction("Produktkort", "Billeder", "Ny")) {
            this.toolbarOptions.push({ text: 'Link Billede', tooltipText: 'Link Billede', id: 'linkImage', prefixIcon: 'e-icon-link' })
        }
        if (super.hasMenuItemTabFunction("Produktkort", "Billeder", "Slette")) {
            this.toolbarOptions.push('Delete');
            this.defaultEditSettings = {
                allowDeleting: true,
                showDeleteConfirmDialog: true
            };
        }

        // SUPPLIER TAB 
        this.supplierToolbarOptions = [];
        this.supplierEditSettings = {};
        if (super.hasMenuItemTabFunction("Produktkort", "Leverandør", "Ny")) {
            this.supplierToolbarOptions.push({ text: 'Ny', tooltipText: 'Ny', id: 'add', prefixIcon: 'e-add' });
        }
        if (super.getSiteRoute("Leverandør")) {
            this.supplierToolbarOptions.push({ text: 'Åben Leverandør', tooltipText: 'Åben Leverandør', id: 'open', prefixIcon: 'e-external-link' });
        }
        if (super.hasMenuItemTabFunction("Produktkort", "Leverandør", "Redigere")) {
            this.supplierToolbarOptions.push('Edit');
            this.supplierToolbarOptions.push("Update");
            this.supplierToolbarOptions.push('Cancel');
            this.supplierEditSettings.allowEditing = true;
        }
        if (super.hasMenuItemTabFunction("Produktkort", "Leverandør", "Slette")) {
            this.supplierToolbarOptions.push('Delete');
            this.supplierEditSettings.allowDeleting = true;
            this.supplierEditSettings.showDeleteConfirmDialog = true;
        }

        this.supplierToolbarOptions.push({ text: Localization.General.GridResetFilter, tooltipText: Localization.General.GridResetFilter, id: 'reset_filter', prefixIcon: 'e-cancel' });

        //STOCK TAB
        this.stockToolbarOptions = [];

        this.stockEditSettings = {};
        if (super.hasMenuItemTabFunction("Produktkort", "Lager", "Ny")) {
            this.stockToolbarOptions.push({ text: 'Ny', tooltipText: 'Ny', id: 'add', prefixIcon: 'e-add' });
        }
        if (super.hasMenuItemTabFunction("Produktkort", "Lager", "Redigere")) {
            this.stockToolbarOptions.push('Edit');
            this.stockEditSettings.allowEditing = true;
        }
        if (super.hasMenuItemTabFunction("Produktkort", "Lager", "Slette")) {
            this.stockToolbarOptions.push({ text: 'Slette', tooltipText: 'Slette', id: 'delete', prefixIcon: 'e-delete' });
            // this.stockToolbarOptions.push('Delete');
            // this.stockEditSettings.allowDeleting = true;
            // this.stockEditSettings.showDeleteConfirmDialog = true;
        }
        if (super.hasMenuItemTabFunction("Produktkort", "Lager", "Redigere")) {
            this.stockToolbarOptions.push('Update');
            this.stockToolbarOptions.push('Cancel');
        }

        this.stockToolbarOptions.push({ text: Localization.General.GridResetFilter, tooltipText: Localization.General.GridResetFilter, id: 'reset_filter', prefixIcon: 'e-cancel' });
    }

    //#region Tabs and event handlers

    onChange(args) {
        let value = args.target.value;
        let name = args.target.name;
        let { data, transactionCategory } = this.state;

        if (name === "ProductNo") {
            if (value) {
                if (!value.match(/^[a-z0-9\-]+$/i)) {
                    return false;
                }
            }
        }
        else if (name === "EAN") {
            if (value) {
                if (!value.match(/^\d+$/)) {
                    return false;
                }
            }
        }

        data[name] = value;
        
        let tabKeys = Object.keys(TabFields);
        for (let index = 0; index < tabKeys.length; index++) {
            const element = tabKeys[index];
            if (TabFields[element].some(t => t === name)) {
                if (!transactionCategory.includes(element)) {
                    transactionCategory.push(element);
                }
            }
        }

        this.setState({ data, hasUnsavedChanges: true, transactionCategory });
    }

    onValueChange(values, name) {
        if (values.floatValue === undefined) {
            console.log(values);
            return;
        }
        
        // if (this.isInitialUpdateTolerance && name === "PurchaseTolerance") {
        //     this.isInitialUpdateTolerance = false;
        //     return;
        // }

        let { data, transactionCategory, oldGroupId, isEditMode } = this.state;

        if (values.floatValue && values.floatValue > 0) {
            data[name] = values.floatValue;
        }
        else if (!values.floatValue) {
            data[name] = values.floatValue;
        }
        else {
            data[name] = null;
        }

        if (name === "CustomVolume") {
            data.Length = null;
            data.Height = null;
            data.Width = null;
        }
        if ((name === "Length" || name === "Height" || name === "Width") && values.floatValue > 0) {
            data.CustomVolume = null;
        }

        if(name === "Weight") {
            if (values.floatValue > this.GuaranteedWeight){
                oldGroupId = 0;
            }                
        }

        switch(name) {
            case "Factor":
                if (values.floatValue && values.floatValue > 0) {
                    data.ProfitPercentage = null;
                    data.Profit = null;
                    data.SalesPrice = null;
                }
                break;
            case "ProfitPercentage":
                if (values.value && values.value > 0) {
                    // data.ProfitPercentage = values.value;
                    data.Factor = null;
                    data.Profit = null;
                    data.SalesPrice = null;
                }
                break;
            case "Profit":
                if (values.floatValue && values.floatValue > 0) {
                    data.Factor = null;
                    data.ProfitPercentage = null;
                    data.SalesPrice = null;
                }
                break;
            case "SalesPrice":
                if (values.floatValue && values.floatValue > 0) {
                    data.Factor = null;
                    data.Profit = null;
                    data.ProfitPercentage = null;
                }
                break;
        }
        
        let tabKeys = Object.keys(TabFields);
        for (let index = 0; index < tabKeys.length; index++) {
            const element = tabKeys[index];
            if (TabFields[element].some(t => t === name)) {
                if (!transactionCategory.includes(element)) {
                    transactionCategory.push(element);
                }
            }
        }

        this.setState({ data, hasUnsavedChanges: isEditMode, transactionCategory, oldGroupId });
    }

    onChangeByName(name, args) {
        if (args.isInteracted) {
            const { data, selectedDimension } = this.state;
            data[name] = args.value;

            if (name === 'CustomValue') {
                data.Length = null;
                data.Height = null;
                data.Width = null;
            }

            this.setState({ data });
            if (name === 'CustomValue') {
                this.CustomVolumeRef.format = `###${selectedDimension.Key === 'mm' ? 'mm³' : selectedDimension.Key}`;
                this.CustomVolumeRef.refresh();

                if (args.value > 0) {
                    this.LengthRef.value = null;
                    this.HeightRef.value = null;
                    this.WidthRef.value = null;
                }
            }
        }
    }

    //#region Product Description Tab
    toggleProductDescriptionFields(enabled) {
        this.ProductNoRef.disabled = !enabled;
        this.EANRef.disabled = !enabled;
        this.Description1Ref.disabled = !enabled;
        this.Description2Ref.disabled = !enabled;
        this.SalesUnitRef.disabled = !enabled;
        // this.SalesUnitPerPcsRef.disabled = !enabled;
    }

    productDescriptionTab() {
        const { data, moduleState, isEditMode } = this.state;
        let isFieldEnabled = false;
        if (moduleState === 'add') {
            isFieldEnabled = true;
        }
        else {
            if (isEditMode) {
                isFieldEnabled = true;
            }
        }

        return (
            <div className="tab-control--item product-description-wrapper">
                <table className="table" cellSpacing="0">
                    <tbody>
                        <tr>
                            <td width="50%" className="label">Vare Nr.<span className="required">*</span></td>
                            <td>
                                <input ref={ref => this.ProductNoRef = ref} type="text" className="e-input" value={data.ProductNo} disabled={!isFieldEnabled} name="ProductNo" onChange={this.onChange}  />
                            </td>
                        </tr>
                        <tr>
                            <td width="50%" className="label">Ean Nr.</td>
                            <td>
                                <input ref={ref => this.EANRef = ref} name="EAN" className="e-input" value={data.EAN} disabled={!isFieldEnabled} onChange={this.onChange} />
                            </td>
                        </tr>
                        <tr>
                            <td width="50%" className="label">Beskrivelse<span className="required">*</span></td>
                            <td>
                                <textarea ref={ref => this.Description1Ref = ref} value={data.Description1} className="e-input" disabled={!isFieldEnabled} rows={3} name="Description1" onChange={this.onChange} />
                            </td>
                        </tr>
                        <tr>
                            <td width="50%" className="label">Beskrivelse 2</td>
                            <td>
                                <textarea ref={ref => this.Description2Ref = ref} value={data.Description2} className="e-input" disabled={!isFieldEnabled} rows={3} name="Description2" onChange={this.onChange} />
                            </td>
                        </tr>
                        <tr>
                            <td width="50%" className="label">Enhed</td>
                            <td>
                                <input ref={ref => this.SalesUnitRef = ref} name="SalesUnit" className="e-input" value={data.SalesUnit ? data.SalesUnit : ''} disabled={!isFieldEnabled} onChange={this.onChange} />
                            </td>
                        </tr>
                        <tr>
                            <td width="50%" className="label">Salgs enhed i stk.</td>
                            <td>
                                <NumberFormat ref={ref => this.SalesUnitPerPcsRef = ref} type="text" thousandSeparator="." decimalSeparator="," className="e-input input-text-right" disabled={!isFieldEnabled} 
                                    value={data.SalesUnitPerPcs} onValueChange={(values) => this.onValueChange(values, "SalesUnitPerPcs")}  />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
    //#endregion

    //#region Product Size Tab
    toggleProductSizeFields(enabled) {
        // this.LengthRef.disabled = !enabled;
        // this.HeightRef.disabled = !enabled;
        // this.WidthRef.disabled = !enabled;
        // this.CustomVolumeRef.disabled = !enabled;
        // this.WeightRef.disabled = !enabled;
    }

    onProductSizeChange = (args) => {
        let computedVolumElem = document.getElementsByClassName('j-computed-volume-text')[0];
        computedVolumElem.innerHTML = (this.LengthRef.value * this.HeightRef.value * this.WidthRef.value).toFixed(2);
    }

    onDimensionChange = (args) => {
        const { dimensionsData, transactionCategory } = this.state;
        let dimensionElem = document.getElementsByClassName('j-selected-dimension-text');
        
        const dimension = dimensionsData.find(x => x.Value === args.value);

        if (dimensionElem.length > 0) {
            for (let index = 0; index < dimensionElem.length; index++) {
                dimensionElem[index].innerHTML = dimension.Key;
            }
        }

        if (!transactionCategory.includes("Sizes")) {
            transactionCategory.push("Sizes");
        }

        this.setState({ selectedDimensionId: args.value, selectedDimension: dimension, transactionCategory });
    }

    onWeightChange = (args) => {
        const { weightsData, transactionCategory } = this.state;
        let weightElem = document.getElementsByClassName('j-selected-salesunitweight-text');

        const weight = weightsData.find(x => x.Value === args.value);
        if (weightElem.length > 0) {
            for (let index = 0; index < weightElem.length; index++) {
                weightElem[index].innerHTML = weight.Key;
            }
        }

        if (!transactionCategory.includes("Sizes")) {
            transactionCategory.push("Sizes");
        }

        this.setState({ selectedWeightId: args.value, selectedWeight: weight, transactionCategory });
    }

    productSizeTab() {
        const { data, dimensionsData, weightsData, selectedDimension, selectedWeight, isEditMode, moduleState } = this.state;
        const computedVolumeText = (data.Length * data.Width * data.Height).toFixed(2);
        let isFieldEnabled = false;
        let volumeFormat = '';

        if (selectedDimension) {
            volumeFormat = `${selectedDimension.Key}³`;
        }

        if (moduleState === 'add') {
            isFieldEnabled = true;
        }
        else {
            if (isEditMode) {
                isFieldEnabled = true;
            }
        }
        
        return (
            <div className="tab-control--item product-sizes-wrapper">
                <div className="product-sizes-units-wrapper">
                    <table className="table" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td className="label">Længde</td>
                                <td width="30%">
                                    <NumberFormat ref={ref => this.LengthRef = ref} type="text" thousandSeparator="." decimalSeparator="," disabled={!isFieldEnabled} className="e-input input-text-right" value={data.Length} onValueChange={(values) => this.onValueChange(values, "Length")}  />
                                </td>
                                <td width="30%"><span className="j-selected-dimension-text">{selectedDimension.Key}</span></td>
                            </tr>
                            <tr>
                                <td className="label">Højde</td>
                                <td width="30%">
                                    <NumberFormat ref={ref => this.HeightRef = ref} type="text" thousandSeparator="." decimalSeparator="," disabled={!isFieldEnabled} className="e-input input-text-right" value={data.Height} onValueChange={(values) => this.onValueChange(values, "Height")}  />
                                </td>
                                <td width="30%"><span className="j-selected-dimension-text">{selectedDimension.Key}</span></td>
                            </tr>
                            <tr>
                                <td className="label">Bredde</td>
                                <td width="30%">
                                    <NumberFormat ref={ref => this.WidthRef = ref} type="text" thousandSeparator="." decimalSeparator="," disabled={!isFieldEnabled} className="e-input input-text-right" value={data.Width} onValueChange={(values) => this.onValueChange(values, "Width")}  />
                                </td>
                                <td width="30%"><span className="j-selected-dimension-text">{selectedDimension.Key}</span></td>
                            </tr>
                            <tr>
                                <td className="label">Volume</td>
                                <td width="30%">
                                    <NumberFormat ref={ref => this.CustomVolumeRef = ref} type="text" thousandSeparator="." decimalSeparator="," suffix={volumeFormat} disabled={!isFieldEnabled} className="e-input input-text-right" value={data.CustomVolume} onValueChange={(values) => this.onValueChange(values, "CustomVolume")}  />
                                </td>
                                <td width="30%"><span className="j-computed-volume-text">{isNaN(computedVolumeText) ? 0 : computedVolumeText}</span><span className="j-selected-dimension-text">{`${selectedDimension.Key}³`}</span></td>
                            </tr>
                            <tr>
                                <td className="label">Salgs Enheds Vægt</td>
                                <td width="30%">
                                    <NumberFormat ref={ref => this.WeightRef = ref} type="text" thousandSeparator="." decimalSeparator="," disabled={!isFieldEnabled} className="e-input input-text-right" value={data.Weight} onValueChange={(values) => this.onValueChange(values, "Weight")}  />
                                </td>
                                <td width="30%"><span className="j-selected-salesunitweight-text">{selectedWeight.Key}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="product-sizes-settings-units-wrapper">
                    <Card headerText="Måle Enheder">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="product-size-units-label">
                                            Dimensioner (L:H:B)
                                        </div>
                                        <div className="product-size-units-values">
                                            {
                                                dimensionsData.map((dimension, index) => {
                                                    let isChecked = selectedDimension.Value === dimension.Value;
                                                    return (<RadioButtonComponent key={`product-size-dimension-${index}`} label={dimension.Key} disabled={!isEditMode} checked={isChecked} value={dimension.Value} name="dimension" change={this.onDimensionChange} />)
                                                })
                                            }
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="product-size-units-label">
                                            Vægt
                                        </div>
                                        <div className="product-size-units-values">
                                            {
                                                weightsData.map((weight, index) => {
                                                    let isChecked = selectedWeight.Value === weight.Value;
                                                    return (<RadioButtonComponent key={`product-size-weight-${index}`} label={weight.Key} disabled={!isEditMode} checked={isChecked} value={weight.Value} name="weight" change={this.onWeightChange}/>)
                                                })
                                            }
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Card>
                </div>
            </div>
        )
    }
    //#endregion

    //#region Price Tab
    toggleProductPriceFields(enabled) {
        this.FactorRef.enabled = enabled;
        this.ProfitPercentageRef.enabled = enabled;
        this.ProfitRef.enabled = enabled;
        this.SalesPriceRef.enabled = enabled;
    }

    onProductPriceChange = (e) => {
        const { priceGlobal, data } = this.state;
        let name = e.target.name;
        let value = e.target.value;

        switch(name) {
            case "Factor":
                if (value && value > 0) {
                    this.ProfitPercentageRef.value = null;
                    this.ProfitRef.value = null;
                    this.SalesPriceRef.value = null;

                    data.ProfitPercentage = null;
                    data.Profit = null;
                    data.SalesPrice = null;
                }
                break;
            case "ProfitPercentage":
                if (value && value > 0) {
                    this.FactorRef.value = null;
                    this.ProfitRef.value = null;
                    this.SalesPriceRef.value = null;

                    data.Factor = null;
                    data.Profit = null;
                    data.SalesPrice = null;
                }
                break;
            case "Profit":
                if (value && value > 0) {
                    this.FactorRef.value = null;
                    this.ProfitPercentageRef.value = null;
                    this.SalesPriceRef.value = null;

                    data.Factor = null;
                    data.ProfitPercentage = null;
                    data.SalesPrice = null;
                }
                break;
            case "SalesPrice":
                if (value && value > 0) {
                    this.FactorRef.value = null;
                    this.ProfitPercentageRef.value = null;
                    this.ProfitRef.value = null;

                    data.Factor = null;
                    data.Profit = null;
                    data.ProfitPercentage = null;
                }
                break;
        }

        this.setState({ data });
    }

    onProfitPercentageChange(args) {
        if (args.isInteracted) {
            const { data } = this.state;
            data.ProfitPercentage = args.value;
            if (args.value > 0) {
                data.Factor = null;
                this.FactorRef.value = null;
            }
            this.setState({ data });
        }
    }

    productPriceTab() {
        const { data, priceGlobal, moduleState, isEditMode } = this.state;
        let globalSalesPrice = 0, globalProfit = 0, globalAvance = 0;
        let costPrice = priceGlobal.CostPrice;
        let factor = 0, profitPercentage = 0, displayProfitPercentage = data.ProfitPercentage;
        let isCalculatingByFactor = false, isCalculatingByAvance = false;
        let hasGlobalFactorValue = priceGlobal.Factor > 0 ? true : false;
        let hasGlobalProfitPercentageValue = priceGlobal.ProfitPercent > 0 ? true : false;
        let isFieldEnabled = false;
        let factorPlaceholder = '', profitPercentagePlaceholder = '', profitPlaceholder = '', salespricePlaceholder = '';
        
        // #region Compute global column
        globalSalesPrice = (costPrice * priceGlobal.Factor);
        globalProfit = (globalSalesPrice - costPrice);
        if (priceGlobal.ProfitPercent && priceGlobal.ProfitPercent > 0) {
            globalAvance = priceGlobal.ProfitPercent;
        }
        else {
            globalAvance = ((globalProfit / globalSalesPrice) * 100).toFixed(2);
        }
        //#endregion

        //#region Compute custom column
        if (data.Factor && data.Factor !== 0) {
            factor = data.Factor;
            isCalculatingByFactor = true;
            data.ProfitPercentage = null;
        }
        
        if (data.ProfitPercentage && data.ProfitPercentage !== 0) {
            profitPercentage = data.ProfitPercentage / 100;
            displayProfitPercentage = displayProfitPercentage / 100;
            isCalculatingByAvance = true;
        }
        
        if (isCalculatingByFactor) {
            salespricePlaceholder = (costPrice * factor).toFixed(2);
            profitPlaceholder = (salespricePlaceholder - costPrice).toFixed(2);
            profitPercentagePlaceholder = numberTo2DecimalPlace((profitPlaceholder / salespricePlaceholder) * 100);
        }
        else if (isCalculatingByAvance) {
            salespricePlaceholder = (costPrice / (1 - profitPercentage)).toFixed(2);
            profitPlaceholder = (salespricePlaceholder - costPrice).toFixed(2);
            factorPlaceholder = (salespricePlaceholder / costPrice).toFixed(2);
        }
        else {
            if (data.Profit && data.Profit > 0) {
                salespricePlaceholder = (data.Profit + costPrice).toFixed(2);
                profitPercentagePlaceholder = numberTo2DecimalPlace((data.Profit / salespricePlaceholder) * 100);
                factorPlaceholder = (salespricePlaceholder / costPrice).toFixed(2);
            }
            else if (data.SalesPrice && data.SalesPrice > 0) {
                profitPlaceholder = (data.SalesPrice - costPrice).toFixed(2);
                profitPercentagePlaceholder = numberTo2DecimalPlace((profitPlaceholder / data.SalesPrice) * 100);
                factorPlaceholder = (data.SalesPrice / costPrice).toFixed(2);
            }
        }
        
        factorPlaceholder = formatMoney('', factorPlaceholder, 2, ',', '.');
        profitPercentagePlaceholder = formatMoney('', profitPercentagePlaceholder, 2, ',', '.') + '%';
        profitPlaceholder = formatMoney('', profitPlaceholder, 2, ',', '.');
        salespricePlaceholder = formatMoney('', salespricePlaceholder, 2, ',', '.');
        //#endregion

        globalProfit = isNaN(globalProfit) ? 0 : globalProfit;
        globalSalesPrice = isNaN(globalSalesPrice) ? 0 : globalSalesPrice;
        globalAvance = isNaN(globalAvance) ? 0 : globalAvance;

        if (moduleState === 'add') {
            isFieldEnabled = true;
        }
        else {
            if (isEditMode) {
                isFieldEnabled = true;
            }
        }
        
        return (
            <div className="tab-control--item product-price-wrapper">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Tekst</th>
                            <th width="25%">Custom</th>
                            <th width="25%">Global</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="label">Kostpris</td>
                            <td></td>
                            <td align="right">
                                <NumberFormat displayType="text" decimalScale={4} fixedDecimalScale={true} className="e-input input-text-right text-fade" value={costPrice} thousandSeparator="." decimalSeparator="," />
                            </td>
                        </tr>
                        <tr>
                            <td className="label">Faktor</td>
                            <td>
                                <NumberFormat ref={ref => this.FactorRef = ref} type="text" decimalScale={2} fixedDecimalScale={true} disabled={!isFieldEnabled} className={`e-input input-text-right ${data.Factor > 0 ? 'text-bold' : ''}`} name="Factor" value={data.Factor} 
                                    thousandSeparator="." decimalSeparator="," onValueChange={(values) => this.onValueChange(values, "Factor")} placeholder={factorPlaceholder} />
                            </td>
                            <td align="right" className="text-fade">
                                <NumberFormat displayType="text" decimalScale={2} fixedDecimalScale={true} className={`e-input input-text-right ${hasGlobalFactorValue ? 'text-bold': ''}`} value={priceGlobal.Factor}
                                    thousandSeparator="." decimalSeparator="," />
                            </td>
                        </tr>
                        <tr>
                            <td className="label">Avance i %</td>
                            <td>
                                <NumberFormat ref={ref => this.ProfitPercentageRef = ref} type="text" decimalScale={2} fixedDecimalScale={true} disabled={!isFieldEnabled} suffix="%" 
                                    className={`e-input input-text-right ${data.ProfitPercentage > 0 ? 'text-bold' : ''}`} value={data.ProfitPercentage} thousandSeparator="." decimalSeparator=","
                                    name="ProfitPercentage" onValueChange={(values) => this.onValueChange(values, "ProfitPercentage")} placeholder={profitPercentagePlaceholder} isAllowed={(values) => {
                                        const { formattedValue, floatValue } = values;
                                        return formattedValue === "" || (floatValue < 100 && floatValue >= 0);
                                    }} />
                            </td>
                            <td align="right" className="text-fade">
                                <NumberFormat displayType="text" decimalScale={2} fixedDecimalScale={true} className={`e-input input-text-right ${hasGlobalProfitPercentageValue ? 'text-bold': ''}`} suffix="%" value={globalAvance}
                                    thousandSeparator="." decimalSeparator="," />
                            </td>
                        </tr>
                        <tr>
                            <td className="label">Dækningsbidrag</td>
                            <td>
                                <NumberFormat ref={ref => this.ProfitRef = ref} type="text" decimalScale={2} fixedDecimalScale={true} disabled={!isFieldEnabled} className={`e-input input-text-right ${data.Profit > 0 ? 'text-bold' : ''}`} 
                                    name="Profit" value={data.Profit} onValueChange={(values) => this.onValueChange(values, "Profit")} placeholder={profitPlaceholder} thousandSeparator="." decimalSeparator="," />
                            </td>
                            <td align="right" className="text-fade">
                                <NumberFormat displayType="text" decimalScale={2} fixedDecimalScale={true} className="e-input input-text-right" value={globalProfit} thousandSeparator="." decimalSeparator="," />
                            </td>
                        </tr>
                        <tr>
                            <td className="label">Salgspris</td>
                            <td>
                                <NumberFormat ref={ref => this.SalesPriceRef = ref} type="text" decimalScale={2} fixedDecimalScale={true} disabled={!isFieldEnabled} className={`e-input input-text-right ${data.SalesPrice > 0 ? 'text-bold' : ''}`} 
                                    name="SalesPrice" value={data.SalesPrice} onValueChange={(values) => this.onValueChange(values, "SalesPrice")} placeholder={salespricePlaceholder} thousandSeparator="." decimalSeparator="," />
                            </td>
                            <td align="right" className="text-fade">
                                <NumberFormat displayType="text" decimalScale={2} fixedDecimalScale={true} className="e-input input-text-right" value={globalSalesPrice} thousandSeparator="." decimalSeparator="," />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
    //#endregion

    //#region Product Group Tab
    toggleProductGroupFields(enabled) {
        this.primaryProductGroupRef.disabled = !enabled;
    }

    onPrimaryProductGroupChecked(args) {
        if (args.action !== "indeterminate" && args.isInteracted) {
            let { data, transactionCategory, groupGuaranteedWeight } = this.state;
            if (args.action === 'check') {
                data.ProductGroupId = parseInt(args.data[0].id);
                this.primaryProductGroupRef.checkedNodes = [args.data[0].id];
            }
            else {
                data.ProductGroupId = 0;
                this.primaryProductGroupRef.checkedNodes = [];
                groupGuaranteedWeight = 1;
            }

            if (!transactionCategory.includes("Group")) {
                transactionCategory.push("Group");
            }

            this.setState({ data, hasUnsavedChanges: true, transactionCategory, groupGuaranteedWeight }, async () => {
                if (data.ProductGroupId !== 0) {
                    const { priceGlobal } = this.state;
                    const result = await ProductGroupService.GetProductGroup(data.ProductGroupId, source.token);
                    priceGlobal.Factor = result.Factor;
                    priceGlobal.ProfitPercent = result.ProfitPercentage;
                    groupGuaranteedWeight = result.GuaranteedWeight;
                    this.setState({ priceGlobal, groupGuaranteedWeight });
                }
            });
        }
    }

    onProductGroupNodeClicked(args) {
        let checkedNode = [args.node];
        if (args.event.target.classList.contains('e-fullrow') || args.event.key == "Enter") {
            let getNodeDetails = this.primaryProductGroupRef.getNode(args.node);
            if (getNodeDetails.isChecked == 'true') {
                this.menuTreeRef.uncheckAll(checkedNode);
            }
            else {
                this.menuTreeRef.checkAll(checkedNode);
            }
        }
    }

    productGroupTab() {
        return (
            <div className="tab-control--item product-group-wrapper">
                <div className="product-group-card-wrapper">
                    <Card headerText="Tildel primær produkt gruppe" subText="Klik på '>' for tilhørende funktioner">
                        <TreeViewComponent id="product-group-tab" ref={ref => this.primaryProductGroupRef = ref} fields={this.primaryProductGroupFields} showCheckBox={true}
                            nodeChecked={this.onPrimaryProductGroupChecked} autoCheck={false} />
                    </Card>
                </div>
            </div>
        )
    }
    //#endregion

    //#region Stock Tab
    async refreshStockGrid(id) {
        const result = await ProductService.GetProductStorages(id, source.token);
        this.productStockGridData = result;
        this.productStockGridRef.dataSource = result;
        this.productStockGridRef.dataBind();
    }

    async onStockGridActionBegin(args) {
        if (args.requestType === 'delete') {
            if (args.data[0].StandardLocation) {
                args.cancel = true;
                this.dialogOpen("Advarsel", Localization.Product.UnmarkAsStandardLocationBeforeDelete);
            }
        }
        else if (args.action === 'edit' && args.requestType === 'save') {
            // args.cancel = true;
            // this.isStockSaving = false;
            const action = args.data.Id ? 'update' : 'add';
            this.executeStock(action, args);
        }
        else if (args.requestType === 'beginEdit') {
            this.stockGridIsEditing = true;
            this.setState({ hasUnsavedChanges: true });
        }
        else if (args.requestType === 'cancel') {
            this.stockGridIsEditing = false;
        }
    }

    executeStock = async (action, args) => {
        const { data, transactionCategory, stockChangeLog } = this.state;
        let result = null;

        switch(action) {
            case 'add':
            case 'update':
                let hasError = false, errorList = [];

                // if (!args.data.MaxQty && !args.data.PurchaseQty) {
                //     hasError = true;
                //     errorList.push("Maks. og indkøbs antal er begge ikke udfyldt, en af dem skal ha en værdi");
                // }

                if (args.data.MaxQty > 0 && args.data.PurchaseQty > 0) {
                    hasError = true;
                    errorList.push("Maks. og indkøbs antal har begge en værdi");
                }

                if (args.data.StandardLocation) {
                    let identicalMachine = args.data.TempId ? data.ProductStorages.find(s => s.Machine.Id === args.data.Machine.Id && s.StandardLocation === true && args.data.TempId !== s.TempId) 
                        : data.ProductStorages.find(s => s.Machine.Id === args.data.Machine.Id && s.StandardLocation === true && args.data.Id !== s.Id);

                    if (identicalMachine) {
                        hasError = true;
                        errorList.push("Der kan kun være en standard lokation pr. maskine");
                    }
                }

                if (hasError) {
                    args.cancel = true;
                    this.dialogOpen("Advarsel", errorList.join('<br/>'));
                    
                    if (this.stockGridIsEditing && this.isUpdateTriggered) {
                        this.isUpdateTriggered = false;
                    }

                    if (this.stockGridIsEditing && this.isTabUpdateTriggered) {
                        this.isTabUpdateTriggered = false;
                        this.tabUpdateIndex = null;
                    }
                    return;
                }
                else {
                    this.stockGridIsEditing = false;
                }

                debugger;
                let storageIndex = args.data.TempId ? data.ProductStorages.findIndex(x => x.TempId === args.data.TempId)
                    : data.ProductStorages.findIndex(x => x.Id === args.data.Id);
                
                if (storageIndex > -1) {
                    data.ProductStorages[storageIndex].MachineId = args.data.Machine.Id;
                    data.ProductStorages[storageIndex].MinQty = args.data.MinQty;
                    data.ProductStorages[storageIndex].MaxQty = args.data.MaxQty > 0 ? args.data.MaxQty : (args.data.PurchaseQty == null || args.data.PurchaseQty == 0) ? 0 : null;
                    data.ProductStorages[storageIndex].PurchaseQty = args.data.PurchaseQty > 0 ? args.data.PurchaseQty : null;
                    data.ProductStorages[storageIndex].StandardLocation = args.data.StandardLocation;
                    data.ProductStorages[storageIndex].Location = null;
                }
                
                this.productStockGridData = data.ProductStorages;
                this.productStockGridRef.dataSource = data.ProductStorages;
                this.productStockGridRef.dataBind();

                if (!transactionCategory.includes("Stock")) {
                    transactionCategory.push("Stock");
                }

                let logIndex = args.data.TempId ? stockChangeLog.find(x => x.TempId === args.data.TempId)
                    : stockChangeLog.find(x => x.Id === args.data.Id);

                if (logIndex > -1) {
                    stockChangeLog[logIndex].MachineId = args.data.Machine.Id;
                    stockChangeLog[logIndex].MinQty = args.data.MinQty;
                    stockChangeLog[logIndex].MaxQty = args.data.MaxQty > 0 ? args.data.MaxQty : (args.data.PurchaseQty == null || args.data.PurchaseQty == 0) ? 0 : null;
                    stockChangeLog[logIndex].PurchaseQty = args.data.PurchaseQty > 0 ? args.data.PurchaseQty : null;
                    stockChangeLog[logIndex].StandardLocation = args.data.StandardLocation;
                }
                else {
                    stockChangeLog.push({
                        ...data.ProductStorages[storageIndex],
                        ChangeOrder: stockChangeLog.length + 1,
                        IsDelete: false
                    });
                }

                this.setState({ data, hasUnsavedChanges: true, transactionCategory, stockChangeLog }, () => {
                    if (this.stockGridIsEditing && this.isUpdateTriggered) {
                        this.stockGridIsEditing = false;
                        this.isUpdateTriggered = false;
                        this.onProductToolbarClicked({ item: { id: "update" } });
                    }

                    if (this.stockGridIsEditing && this.isTabUpdateTriggered) {
                        this.stockGridIsEditing = false;
                        this.isTabUpdateTriggered = false;
                        this.onTabSelected(this.tabUpdateIndex);
                        this.tabUpdateIndex = null;
                    }
                });
                // const payload = {
                //     MachineId: args.data.Machine.Id,
                //     MinQty: args.data.MinQty,
                //     MaxQty: args.data.MaxQty > 0 ? args.data.MaxQty : null,
                //     PurchaseQty: args.data.PurchaseQty > 0 ? args.data.PurchaseQty : null,
                //     StandardLocation: args.data.StandardLocation,
                //     ProductId: data.Id,
                //     Location : null
                // };
        
                // if (action === 'update') {
                //     payload.Id = args.data.Id
                // }
        
                // result = await StockService.AddUpdateStock(action, payload, source.token);
        
                // if (!result.HasError) {
                //     this.isStockSaving = true;
                //     this.productStockGridRef.endEdit();
                //     this.ProductToolbarRef.items[6].disabled = !result.UndoRedoStatus.Undo;
                //     this.ProductToolbarRef.items[6].tooltipText = result.UndoRedoStatus.UndoAction;
                //     this.ProductToolbarRef.items[7].disabled = !result.UndoRedoStatus.Redo;
                //     this.ProductToolbarRef.items[7].tooltipText = result.UndoRedoStatus.RedoAction;
                //     this.refreshStockGrid(data.Id);
                //     this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Lager"));
                // }
                // else {
                //     this.isStockSaving = true;
                //     this.dialogOpen('Advarsel', result.ErrorMessage);
                // }
                break;
            case 'delete':
                // result = await StockService.DeleteStock(args.data[0].Id, source.token);
                // if (!result.HasError) {
                //     this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessDelete.replace("{context}", "Lager"));
                //     this.ProductToolbarRef.items[6].disabled = !result.UndoRedoStatus.Undo;
                //     this.ProductToolbarRef.items[6].tooltipText = result.UndoRedoStatus.UndoAction;
                //     this.ProductToolbarRef.items[7].disabled = !result.UndoRedoStatus.Redo;
                //     this.ProductToolbarRef.items[7].tooltipText = result.UndoRedoStatus.RedoAction;
                //     this.refreshStockGrid(data.Id);
                // }
                // else {
                //     this.dialogOpen('Advarsel', result.ErrorMessage);
                // }
                break;
        }
    }

    async stockClickHandler(args) {
        if (this.productStockGridRef && args.item.id === 'add') {
            this.departmentSelectionTreeRef.selectedNodes = [];
            this.departmentSelectionTreeRef.checkedNodes = [];
            this.departmentSelectionTreeRef.uncheckAll();
            this.stockDialogRef.show();
            this.setState({ selectedMachine: null });
        }
        else if (args.item.id === 'reset_filter') {
            if (this.productStockGridSettings) {
                let settings = JSON.parse(this.productStockGridSettings);
                settings.filters = [];
                this.productStockGridSettings = settings;
                const payload = {
                    Key: this.productStockGridSettingsKey,
                    Settings: JSON.stringify(this.productStockGridSettings)
                };
    
                const result = await SettingsService.SaveGridSettings(payload, source.token);
    
                if (!result.HasError) {
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                }
            }

            this.productStockGridRef.clearFiltering();
        } else if (args.item.id === 'delete') {
            const selectedRecord = this.productStockGridRef.getSelectedRecords();
            if (selectedRecord.length <= 0) {
                this.dialogOpen("Advarsel", Localization.General.NoRowSelected);
            }
            else if (selectedRecord[0].StandardLocation) {
                this.dialogOpen("Advarsel", Localization.Product.UnmarkAsStandardLocationBeforeDelete);
            }
            else {
                this.dialogInstance.header = "Advarsel";
                if (selectedRecord[0].StockQty > 0) {
                    this.dialogInstance.content = "Du kan ikke slette en lokation med beholdning, 0 beholdningen I lagerkladde først";
                    this.dialogInstance.buttons = [
                        {
                            click: () => {
                                this.dialogInstance.hide();
                            },
                            buttonModel: {
                                content: 'Tilbage'
                            }
                        },
                        {
                            click: () => {
                                this.props.history.push(`/værktøjer/produkter/lager-regulering?machine=${selectedRecord[0].Machine.Name}${(selectedRecord[0].Box && selectedRecord[0].Box ? `&boxNo=${selectedRecord[0].Box.No}&roomNo=${selectedRecord[0].Room.Name}` : "")}`);
                            },
                            buttonModel: {
                                isPrimary: true,
                                content: 'Næste'
                            }
                        }
                    ];
                }
                else {
                    this.dialogInstance.content = "Er du sikker på at du vil slette data?";
                    this.dialogInstance.buttons = [
                        {
                            click: () => {
                                this.dialogInstance.hide();
                            },
                            buttonModel: {
                                content: 'Annuller'
                            }
                        },
                        {
                            click: this.deleteStockLocation,
                            buttonModel: {
                                isPrimary: true,
                                content: 'Slet'
                            }
                        }
                    ];
                }
                this.dialogInstance.show();
            }
        }
    }

    async deleteStockLocation() {
        let { data, transactionCategory, stockChangeLog } = this.state;
        const selectedRecord = this.productStockGridRef.getSelectedRecords();
        let argsData = selectedRecord[0];
        
        if (argsData.TempId) {
            data.ProductStorages = data.ProductStorages.filter(x => x.TempId !== argsData.TempId);
        }
        else {
            data.ProductStorages = data.ProductStorages.filter(x => x.Id !== argsData.Id);
        }

        this.productStockGridData = data.ProductStorages;
        this.productStockGridRef.dataSource = data.ProductStorages;
        this.productStockGridRef.refresh();

        if (!transactionCategory.includes("Stock")) {
            transactionCategory.push("Stock");
        }
        
        if (argsData.Id) {
            if (stockChangeLog.some(x => x.Id === argsData.Id)) {
                let st = stockChangeLog.find(x => x.Id === argsData.Id);
                st.IsDelete = true;
            }
            else {
                stockChangeLog.push({
                    Id: argsData.Id,
                    IsDelete: true,
                    ChangeOrder: stockChangeLog.length + 1
                });
            }
        }
        else {
            if (stockChangeLog.some(x => x.TempId === argsData.TempId)) {
                stockChangeLog = stockChangeLog.filter(x => x.TempId !== argsData.TempId);
            }
        }

        this.setState({ data, hasUnsavedChanges: true, transactionCategory, stockChangeLog });
        this.dialogInstance.hide();
    }

    async onStockSaveSelected() {
        let { selectedMachine, data, transactionCategory, stockChangeLog } = this.state;
        
        if (selectedMachine) {
            let department = this.customerDepartments.find(d => d.Id === selectedMachine.deptId);
            let machines = department.Machines.find(m => m.Id === selectedMachine.machineId);

            let existingMachine = Array.isArray(data.ProductStorages) ? data.ProductStorages.filter(m => m.MachineId === selectedMachine.machineId || m.Machine.Id === selectedMachine.machineId) : [];
            
            let payload = {
                ProductId: data.Id ? data.Id : null,
                MachineId: selectedMachine.machineId,
                Machine: {
                    Id: selectedMachine.machineId,
                    Department: {
                        Name: department.Name
                    },
                    CustomName: machines.CustomName
                },
                Location: null,
                MinQty: 0,
                MaxQty: 0,
                PurchaseQty: null,
                StandardLocation: existingMachine.length <= 0 ? true : false,
                StockQty: 0,
                FutureStockQty: 0,
                Weight: 0,
                Id: null,
                TempId: `T${data.ProductStorages.length}`
            };
            data.ProductStorages.push(payload);

            if (!transactionCategory.includes("Stock")) {
                transactionCategory.push("Stock");
            }

            stockChangeLog.push({  
                ...payload,
                IsDelete: false,
                ChangeOrder: stockChangeLog.length + 1
            });

            this.productStockGridData = data.ProductStorages;
            this.productStockGridRef.dataSource = data.ProductStorages;
            this.productStockGridRef.refresh();

            this.setState({ data, hasUnsavedChanges: true, transactionCategory, stockChangeLog }, () => {
                this.stockDialogRef.hide();
            });
            // const result = await StockService.AddUpdateStock('add', payload, source.token);

            // if (!result.HasError) {
            //     this.undoRedoOptions(result.UndoRedoStatus.Undo, result.UndoRedoStatus.Redo, result.UndoRedoStatus.UndoAction, result.UndoRedoStatus.RedoAction);
            //     await this.refreshStockGrid(result.Product.Id);
            //     this.stockDialogRef.hide();
            //     this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Lager"));
                // () => {
                //     this.props.history.push({
                //         pathname: `/automater/configurer/edit/${selectedMachine.machineId}?tab=4`,
                //         state: { productStockId: result.Id }
                //     });
                // }
            // }
            // else {
            //     this.dialogOpen(Localization.General.ErrorHeader, result.ErrorMessage);
            // }
        }
        else {
            this.dialogOpen('Advarsel', Localization.Product.PleaseSelectMachine);
        }
    }

    onDepartmentChecked(args) {
        if (args.action !== "indeterminate" && args.isInteracted) {
            let { selectedMachine } = this.state;
            if (args.action === 'check') {
                selectedMachine = { 
                    deptId: parseInt(args.data[1].id) - 1000, 
                    deptName: args.data[1].text,
                    machineId: parseInt(args.data[0].id) - 2000,
                    MachineName: args.data[0].text,
                };
                this.departmentSelectionTreeRef.checkedNodes = [args.data[0].id];
            }
            else {
                selectedMachine = null;
                this.departmentSelectionTreeRef.checkedNodes = [];
            }
            this.setState({ selectedMachine });
        }
    }

    onMachineNodeClicked(args) {
        let checkedNode = [args.node];
        if (args.event.target.classList.contains('e-fullrow') || args.event.key == "Enter") {
            let { selectedMachine } = this.state;
            let getNodeDetails = this.departmentSelectionTreeRef.getNode(args.node);
            if (getNodeDetails.isChecked == 'true') {
                selectedMachine = null;
                this.departmentSelectionTreeRef.uncheckAll(checkedNode);
            }
            else {
                this.departmentSelectionTreeRef.uncheckAll();
                selectedMachine = { 
                    deptId: parseInt(getNodeDetails.parentID) - 1000, 
                //     deptName: args.data[1].text,
                    machineId: parseInt(getNodeDetails.id) - 2000,
                //     MachineName: args.data[0].text,
                };
                this.departmentSelectionTreeRef.checkAll(checkedNode);
            }
            this.setState({ selectedMachine });
        }
    }

    onProductStockColumnDragStart(args) {
        this.hasDraggedColumn = true;
    }

    onProductStockCreated() {
        if (this.productStockGridSettings) {
            let settings = JSON.parse(this.productStockGridSettings);
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(column => {
                        if (column.FromIndex !== column.ToIndex) {
                            var tempIndex = this.productStockGridRef.getColumnIndexByField(column.Field);
                            if (tempIndex !== column.ToIndex){
                                this.productStockGridRef.reorderColumnByIndex(column.FromIndex, column.ToIndex);
                            }
                        }
    
                        if (column.Width) {
                            this.productStockGridRef.columns[column.ToIndex].width = column.Width;
                        }
                    });
                }

                if (settings.colName && settings.direction) {
                    this.productStockGridRef.sortColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.productStockGridRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.productStockGridRef.refreshColumns();

                // Prevent resizing of last column
                let lastColumnIndex = this.productStockGridRef.columns.length - 1;    
                this.productStockGridRef.columns[lastColumnIndex].allowResizing = false;
                this.productStockGridRef.refreshColumns();

                if (settings.pageSize) {
                    if (settings.pageSize === "Alle") {
                        this.productStockGridRef.pageSettings.pageSize = this.productStockGridRef.pageSettings.totalRecordsCount;
                        document.querySelector("#product-stock-grid .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.productStockGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
            }
            // if (settings.length > 0) {
            //     settings.forEach(setting => {
            //         if (setting.FromIndex !== setting.ToIndex) {
            //             this.productStockGridRef.reorderColumnByIndex(setting.FromIndex, setting.ToIndex);
            //         }

            //         if (setting.Width) {
            //             this.productStockGridRef.columns[setting.ToIndex].width = setting.Width;
            //         }
            //     });

            //     this.productStockGridRef.refreshColumns();
            // }
        }
    }

    async onProductStockActionComplete(args) {
        if (args.requestType === "delete") {
            let { data, transactionCategory, stockChangeLog } = this.state;
            let argsData = args.data[0];
            
            if (argsData.TempId) {
                data.ProductStorages = data.ProductStorages.filter(x => x.TempId !== argsData.TempId);
            }
            else {
                data.ProductStorages = data.ProductStorages.filter(x => x.Id !== argsData.Id);
            }

            this.productStockGridData = data.ProductStorages;
            this.productStockGridRef.dataSource = data.ProductStorages;
            this.productStockGridRef.refresh();

            if (!transactionCategory.includes("Stock")) {
                transactionCategory.push("Stock");
            }
            
            if (argsData.Id) {
                if (stockChangeLog.some(x => x.Id === argsData.Id)) {
                    let st = stockChangeLog.find(x => x.Id === argsData.Id);
                    st.IsDelete = true;
                }
                else {
                    stockChangeLog.push({
                        Id: argsData.Id,
                        IsDelete: true,
                        ChangeOrder: stockChangeLog.length + 1
                    });
                }
            }
            else {
                if (stockChangeLog.some(x => x.TempId === argsData.TempId)) {
                    stockChangeLog = stockChangeLog.filter(x => x.TempId !== argsData.TempId);
                }
            }

            this.setState({ data, hasUnsavedChanges: true, transactionCategory, stockChangeLog });
        }
        if (args.requestType === 'paging') {
            if (this.productStockGridRef.pageSettings.pageSize === this.productStockGridRef.pageSettings.totalRecordsCount) {
                document.querySelector("#product-stock-grid .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.productStockGridSettings, this.productStockGridRef.pageSettings.pageSize, this.productStockGridRef.pageSettings.totalRecordsCount, false, []);
            this.productStockGridSettings = tempGridSettings;
            const payload = {
                Key: this.productStockGridSettingsKey,
                Settings: this.productStockGridSettings
            };
            const result = await SettingsService.SaveGridSettings(payload, source.token);
        }
        else if (args.requestType === 'filtering') {
            if (this.productStockGridSettings) {
                let settings = JSON.parse(this.productStockGridSettings);
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let tempGridSettings = buildGridSettings(args, this.productStockGridSettings, 0, 0, false, []);
            this.productStockGridSettings = tempGridSettings;
            const payload = {
                Key: this.productStockGridSettingsKey,
                Settings: this.productStockGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.productStockGridSettings) {
                let settings = JSON.parse(this.productStockGridSettings);
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.productStockGridSettings, 0, 0, false, []);
            this.productStockGridSettings = tempGridSettings;
            const payload = {
                Key: this.productStockGridSettingsKey,
                Settings: this.productStockGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.productStockGridRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.productStockGridSettings, 0, 0, this.hasDraggedColumn, columns);
            this.productStockGridSettings = tempGridSettings;
            const payload = {
                Key: this.productStockGridSettingsKey,
                Settings: this.productStockGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                let lastColumnIndex = this.productStockGridRef.columns.length - 1;
                for (let index = 0; index < this.productStockGridRef.columns.length; index++) {
                    let column = this.productStockGridRef.columns[index];
                    if (index === lastColumnIndex) { column.allowResizing = false }
                    else { column.allowResizing = true }
                }
                this.productStockGridRef.refreshColumns();
            }

            this.hasDraggedColumn = false;
        }
        else if (args.requestType === "save") {
            this.onProductToolbarClicked({ item: { id: "update" } });
        }
        // else if (args.requestType === "reorder" && this.hasDraggedColumn) {
        //     let columns = this.productStockGridRef.getColumns();
        //     let tempCols = [], orderedColumns = [];

        //     if (this.productStockGridSettings) {
        //         orderedColumns = JSON.parse(this.productStockGridSettings);
        //     }

        //     columns.forEach((column, index) => {
        //         if (index === args.fromIndex) {
        //             tempCols.push({
        //                 HeaderText: column.headerText,
        //                 Field: column.field,
        //                 FromIndex: column.index,
        //                 ToIndex: index
        //             });
        //         }
        //         else {
        //             if (orderedColumns.some(x => x.HeaderText === column.headerText)) { //Check if previously saved column has been reordered
        //                 let col = orderedColumns.find(x => x.HeaderText === column.headerText)
        //                 if (col.ToIndex !== index) {
        //                     tempCols.push({
        //                         HeaderText: col.HeaderText,
        //                         Field: col.Field,
        //                         FromIndex: col.FromIndex,
        //                         ToIndex: index
        //                     });
        //                 }
        //             }
        //         }
        //     });

        //     const payload = {
        //         Key: this.productStockGridSettingsKey,
        //         Settings: JSON.stringify(tempCols)
        //     };

        //     const result = await SettingsService.SaveGridSettings(payload, source.token);

        //     if (!result.HasError) {
        //         this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
        //     }

        //     this.hasDraggedColumn = false;
        // }
    }

    async onProductStockResizeStop(args) {
        args.requestType = "resizingGrid";
        let columns = this.productStockGridRef.getColumns();
        let tempGridSettings = buildGridSettings(args, this.productStockGridSettings, 0, 0, false, columns);
        this.productStockGridSettings = tempGridSettings;
        const payload = {
            Key: this.productStockGridSettingsKey,
            Settings: this.productStockGridSettings
        };

        const result = await SettingsService.SaveGridSettings(payload, source.token);

        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);

            // Adjust size of last column to fill spaces
            let lastColumnIndex = this.productStockGridRef.columns.length - 1;
            let totalColumnWidth = this.productStockGridRef.columns.reduce((acc, curVal) => acc + parseInt(curVal.width), 0);
            let lastColumnWidth = this.productStockGridRef.element.clientWidth - totalColumnWidth;
            this.productStockGridRef.columns[lastColumnIndex].width = this.productStockGridRef.columns[lastColumnIndex].width + lastColumnWidth;
            this.productStockGridRef.refreshColumns();
        }
        // let columns = [];

        // if (this.productStockGridSettings) {
        //     columns = JSON.parse(this.productStockGridSettings);
        // }

        // if (columns.length > 0) {
        //     let column = columns.find(c => c.Field === args.column.field);
        //     if (column) {
        //         column.FromIndex =  args.column.index;
        //         column.ToIndex = args.column.index;
        //         column.Width = args.column.width
        //     }
        //     else {
        //         columns.push({
        //             HeaderText: args.column.headerText,
        //             Field: args.column.field,
        //             FromIndex: args.column.index,
        //             ToIndex: args.column.index,
        //             Width: args.column.width
        //         });
        //     }
        // }
        // else {
        //     columns.push({
        //         HeaderText: args.column.headerText,
        //         Field: args.column.field,
        //         FromIndex: args.column.index,
        //         ToIndex: args.column.index,
        //         Width: args.column.width
        //     });
        // }

        // const payload = {
        //     Key: this.productStockGridSettingsKey,
        //     Settings: JSON.stringify(columns)
        // };

        // const result = await SettingsService.SaveGridSettings(payload, source.token);

        // if (!result.HasError) {
        //     this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
        // }
    }

    onSupplierDialogClose = async () => {
        let { supplierDialogProps, data } = this.state;
        supplierDialogProps.visible = false;
        supplierDialogProps.Id = null;

        const suppliers = await SupplierService.GetSuppliers(this.UserCustomerId, source.token);
        if (!suppliers.HasError) {
            this.suppliersData = suppliers;
            data.ProductSuppliers = data.ProductSuppliers.map(x => {
                let tempSupplier = suppliers.find(z => z.Id === x.Supplier.Id);
                if (tempSupplier) {
                    x.Supplier.DeliveryTimeInDays = tempSupplier.DeliveryTimeInDays;
                }
                return x;
            }).sort((a, b) => a.Priority > b.Priority ? 1 : -1);
            this.productSupplierGridData = data.ProductSuppliers;
            this.productSupplierGridData.dataSource = data.ProductSuppliers;
            this.productSupplierGridRef.refresh();
            this.setState({ data, supplierDialogProps });
        }
    }

    onSupplierChangeAddEditState = (state) => {
        if (state === "Add") {
            this.supplierDialogRef.header = "Ny leverandør";
        }
        else if (state === "Edit") {
            this.supplierDialogRef.header = "Redigere leverandør";
        }
    }

    productStockTab() {
        const { moduleState, data } = this.state;
        // let gridToolbarOptions = this.stockToolbarOptions;
        // let gridEditSettings = this.stockEditSettings;
        // return <ProductStock state={moduleState} onNotify={this.dialogOpen} data={data} onProductCreated={this.onProductCreated} gridToolbarOptions={gridToolbarOptions} gridEditSettings={gridEditSettings} undoRedoOptions={this.undoRedoOptions} onNotify={this.dialogOpen}  />
        return (
            <div className="tab-control--item product-stock-wrapper">
                <GridComponent ref={ref => this.productStockGridRef = ref } id="product-stock-grid" dataSource={this.productStockGridData} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings} toolbar={this.stockToolbarOptions}
                    editSettings={this.stockEditSettings} selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da"
                    gridLines="Both" actionBegin={this.onStockGridActionBegin.bind(this)} toolbarClick={this.stockClickHandler.bind(this)} allowTextWrap='true' allowReordering={true} allowResizing={true} 
                    actionComplete={this.onProductStockActionComplete.bind(this)} columnDragStart={this.onProductStockColumnDragStart.bind(this)} created={this.onProductStockCreated.bind(this)}
                    resizeStop={this.onProductStockResizeStop.bind(this)}>
                    <ColumnsDirective>
                        <ColumnDirective field="Machine.Department.Name" headerText="Afdeling" width='100' allowEditing={false} /> 
                        <ColumnDirective field="Machine.Name" headerText="Automat" width='100' allowEditing={false} /> 
                        <ColumnDirective field="Box.No" headerText="Box no." width='100' allowEditing={false} /> 
                        <ColumnDirective field="Room.Name" headerText="Rum no." width='100' allowEditing={false} /> 
                        <ColumnDirective field="MinQty" headerText="Min" width='80' textAlign="Right" editType='numericedit' edit={this.numericParams} format="n2" /> 
                        <ColumnDirective field="MaxQty" headerText="Max" width='80' textAlign="Right" editType='numericedit' edit={this.numericParams} format="n2"  /> 
                        <ColumnDirective field="PurchaseQty" headerText="Indkøbs Antal" width='100' textAlign="Right" editType='numericedit' edit={this.numericParams} format="n2" /> 
                        <ColumnDirective field="StockQty" headerText="Beholdning" textAlign="Right" width='110' allowEditing={false} /> 
                        <ColumnDirective field="FutureStockQty" headerText="Fremtidig Beholdning" width='110' textAlign="Right" allowEditing={false} />
                        <ColumnDirective field="StandardLocation" headerText="Standard lokation" width='100' editType='booleanedit' displayAsCheckBox={true} allowEditing={true} />
                        <ColumnDirective field="WeightText" headerText="Vægt / stk." width='100' allowEditing={false} textAlign="Right"  />
                        <ColumnDirective field="WeightTotalText" headerText="Vægt" width='100' allowEditing={false} textAlign="Right"  />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Selection, Toolbar, Edit, Search, Reorder, Resize]} />
                </GridComponent>
                <DialogComponent ref={dialog => this.dialogInstance = dialog} id="machine-list-dialog" isModal={true} buttons={this.deleteButtons} width='auto' 
                        target='body' visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} />
            </div>
        )
    }
    //#endregion

    //#region Supplier Tab

    onWindowVisibilityChange = (e) => {
        let ref = localStorage.getItem("product_supplier_ref");
        if (ref === "false") {
            this.supplierWindow = null;
            window.location.reload();
            window.removeEventListener('visibilitychange', this.onWindowVisibilityChange);
        }
    }

    async supplierToolbarClickHandler(args) {
        if (this.productSupplierGridRef && args.item.id === 'add') {
            let productSuppliers = this.productSupplierGridRef.dataSource.map(x => x.Supplier.Id);
            
            this.filteredSuppliersData = this.suppliersData.filter(x => !productSuppliers.some(ps => ps === x.Id));
            this.addSuppliersGridRef.dataSource = this.filteredSuppliersData;
            this.addSuppliersGridRef.refresh();
            this.addSupplierDialog.show();
        }
        else if (this.productSupplierGridRef && args.item.id === 'open') {
            const selectedRecord = this.productSupplierGridRef.getSelectedRecords();
            let { supplierDialogProps } = this.state;
            
            // window.addEventListener('visibilitychange', this.onWindowVisibilityChange);
            // localStorage.setItem("product_supplier_ref", true);

            let supplierId = null;

            if (selectedRecord.length <= 0) {
                // this.dialogOpen("Advarsel", Localization.General.NoRowSelected);
                // this.supplierWindow = window.open(`/leverandor/add?ref=product`, "_blank");
                this.supplierDialogRef.header = "Ny leverandør";
            }
            else {
                // this.supplierWindow = window.open(`/leverandor/edit/${selectedRecord[0].Supplier.Id}?ref=product`, "_blank");
                supplierDialogProps.Id = selectedRecord[0].Supplier.Id;
                this.supplierDialogRef.header = "Redigere leverandør";
            }

            supplierDialogProps.visible = true;

            this.setState({ supplierDialogProps }, () => {
                this.supplierDialogRef.show();
            });
        }
        else if (args.item.id === 'reset_filter') {
            if (this.productSupplierGridSettings) {
                let settings = JSON.parse(this.productSupplierGridSettings);
                settings.filters = [];
                this.productSupplierGridSettings = settings;
                const payload = {
                    Key: this.productSupplierGridSettingsKey,
                    Settings: JSON.stringify(this.productSupplierGridSettings)
                };
    
                const result = await SettingsService.SaveGridSettings(payload, source.token);
    
                if (!result.HasError) {
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                }
            }

            this.productSupplierGridRef.clearFiltering();
        }
        else if (args.item.id === "product-supplier-grid_update") {
            this.onProductToolbarClicked({ item: { id: "update" } });
        }
    }
    async onSupplierGridActionBegin(args) {
        if (args.requestType === 'delete') {
            // args.cancel = true;
            // const result = await ProductService.DeleteProductSupplier(args.data[0].Id, source.token);

            // if (!result.HasError) {
            //     this.undoRedoOptions(result.Undo, result.Redo, result.UndoAction, result.RedoAction);
            //     this.refreshSupplierGrid(data.Id);
            //     this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessDelete.replace("{context}", "Produkt Leverandøre"));
            // }
            // else {
            //     this.dialogOpen('Advarsel', result.ErrorMessage);
            // }
        }
        else if (args.action === 'edit' && args.requestType === 'save') {
            let { data, transactionCategory, supplierChangeLog } = this.state;
            let action = 'add', errorList = [];
            // if (!args.data.DeliveryQty || args.data.DeliveryQty < 0) {
            //     errorList.push("Leveringsmængde er påkrævet.");
            // }
            // if (!args.data.PurchasePrice || args.data.PurchasePrice < 0) {
            //     errorList.push("Indkøbspri skal udfyldes.");
            // }
            if (!args.data.Priority || args.data.Priority <= 0) {
                errorList.push("Prioitet skal udfyldes.");
            }

            if (args.data.SupplierEAN) {
                if (!args.data.SupplierEAN.match(/[0-9]/g)) {
                    errorList.push("Ukorrekt udfyldt ean nr.");
                }
                else if (!this.validateEAN(args.data.SupplierEAN)) {
                    errorList.push("Ukorrekt udfyldt ean nr.");
                }
            }

            if (errorList.length > 0) {
                args.cancel = true;
                this.dialogOpen('Advarsel', errorList.join('<br/>'));
                return;
            }

            // const payload = {
            //     SupplierId: args.data.Supplier.Id,
            //     SupplierProductNo: args.data.SupplierProductNo,
            //     SupplierEAN: args.data.SupplierEAN,
            //     SupplierProductDesc: args.data.SupplierProductDesc,
            //     DeliveryQty: args.data.DeliveryQty ? args.data.DeliveryQty : 1,
            //     PurchasePrice: args.data.PurchasePrice,
            //     Priority: args.data.Priority,
            //     DeliveryDays: args.data.DeliveryDays,
            //     ProductId: args.data.ProductId,
            //     TransactionState: "Modified"
            // };

            let editedSupplier = args.data.TempId ? data.ProductSuppliers.find(s => s.TempId === args.data.TempId)
                : data.ProductSuppliers.find(s => s.Id === args.data.Id);
                
            // editedSupplier.Supplier = {
            //     Id: args.data.SupplierId,
            //     SupplierNo: args.data.SupplierNo,
            //     Company: args.data.Company,
            //     DeliveryTimeInDays: args.data.DeliveryTimeInDays
            // };
            // editedSupplier.SupplierId = args.data.SupplierId;
            editedSupplier.SupplierProductNo = args.data.SupplierProductNo;
            editedSupplier.SupplierEAN = args.data.SupplierEAN;
            editedSupplier.SupplierProductDesc = args.data.SupplierProductDesc;
            editedSupplier.DeliveryQty = args.data.DeliveryQty;
            editedSupplier.PurchasePrice = args.data.PurchasePrice;
            editedSupplier.Priority = args.data.Priority;
            editedSupplier.DeliveryDays = args.data.DeliveryDays;
            // editedSupplier.ProductId = args.data.ProductId;
            editedSupplier.TransactionState = "Modified";

            let priceGlobal = this.computeAveragePrice();

            if (!transactionCategory.includes("Supplier")) {
                transactionCategory.push("Supplier");
            }
            
            let log = args.data.TempId ? supplierChangeLog.find(x => x.TempId === args.data.TempId)
            : supplierChangeLog.find(x => x.Id === args.data.Id);

            if (log) {
                log.SupplierProductNo = args.data.SupplierProductNo;
                log.SupplierEAN = args.data.SupplierEAN;
                log.SupplierProductDesc = args.data.SupplierProductDesc;
                log.DeliveryQty = args.data.DeliveryQty;
                log.PurchasePrice = args.data.PurchasePrice;
                log.Priority = args.data.Priority;
            }
            else {
                supplierChangeLog.push({
                    ...editedSupplier,
                    ChangeOrder: supplierChangeLog.length + 1,
                    IsDelete: false
                });
            }

            this.setState({ data, hasUnsavedChanges: true, priceGlobal, transactionCategory, supplierChangeLog }, () => {
                this.productSupplierGridData = data.ProductSuppliers;
                if (this.productSupplierGridRef) {
                    this.productSupplierGridRef.dataSource = data.ProductSuppliers;
                    this.productSupplierGridRef.dataBind();
                }
            });
        }
    }

    validateEAN(ean) {
        let lastDigit = null;
        if (ean.length < 13) {
            ean = ean.padStart(13, '0');
            lastDigit = parseInt(ean.substring(ean.length - 1));
            ean = ean.substring(0, ean.length - 1);
        }
        else if (ean.length > 13) {
            return false;
        }
        else if (ean.length === 13) {
            lastDigit = parseInt(ean.substring(ean.length - 1));
            ean = ean.substring(0, ean.length - 1);
        }

        let even = parseInt(ean[1]) + parseInt(ean[3]) + parseInt(ean[5]) + parseInt(ean[7]) + parseInt(ean[9]) + parseInt(ean[11]);
        even = even * 3;

        let odd = parseInt(ean[0]) + parseInt(ean[2]) + parseInt(ean[4]) + parseInt(ean[6]) + parseInt(ean[8]) + parseInt(ean[10]);

        let total = even + odd;
        let checksum = total % 10;

        if (checksum !== 0) {
            checksum = 10 - checksum;
        }

        return (checksum === lastDigit);
    }

    async refreshSupplierGrid(id) {
        const productSuppliers = await ProductService.GetProductSuppliers(id, source.token);
        this.productSupplierGridData = productSuppliers;
        this.productSupplierGridRef.dataSource = productSuppliers;
        this.productSupplierGridRef.dataBind();
    }

    computeAveragePrice() {
        const { priceGlobal } = this.state;
        const data = this.productSupplierGridRef.dataSource;
        let supplierTotalPrice = 0, divisorValue = 0;
        if (data.length > 0) {
            // for (let index = 0; index < data.length; index++) {
            //     if (data[index].PurchasePrice > 0) {
            //         divisorValue = divisorValue + 1;
            //     }
            //     supplierTotalPrice += data[index].PurchasePrice;
            // }
            
            // if (divisorValue > 0)  {
                //priceGlobal.CostPrice = supplierTotalPrice / divisorValue;
                priceGlobal.CostPrice = data.sort((a,b) => (a.Priority > b.Priority) ? 1 : -1)[0].PurchasePrice;
            // }
            // else {
            //     priceGlobal.CostPrice = 0;
            // }
        }
        else {
            priceGlobal.CostPrice = 0;
        }

        return priceGlobal;
    }

    async onSupplierSaveSelected() {
        const selectedRow = this.addSuppliersGridRef.getSelectedRecords();
        if (selectedRow.length > 0) {
            let { data, transactionCategory, supplierChangeLog } = this.state;
            // let result = null;   
            const dataCount = this.productSupplierGridRef.dataSource.length;
            
            const payload = {
                Supplier: {
                    Id: selectedRow[0].Id,
                    SupplierNo: selectedRow[0].SupplierNo,
                    Company: selectedRow[0].Company,
                    DeliveryTimeInDays: selectedRow[0].DeliveryTimeInDays,
                },
                SupplierId: selectedRow[0].Id,
                SupplierProductNo: '', //data.ProductNo ? data.ProductNo : '',
                SupplierEAN: '', // data.EAN ? data.EAN : '',
                SupplierProductDesc: '', //data.Description1 ? data.Description1 : '',
                DeliveryQty: 1,
                PurchasePrice: 0,
                Priority: dataCount + 1,
                DeliveryDays: 0,
                ProductId: data.Id ? data.Id : null,
                TempId: `T${data.ProductSuppliers.length}`,
                TransactionState: "Added"
            };

            data.ProductSuppliers.push(payload);
            let priceGlobal = this.computeAveragePrice();

            if (!transactionCategory.includes("Supplier")) {
                transactionCategory.push("Supplier");
            }

            this.productSupplierGridData = data.ProductSuppliers.sort((a, b) => a.Priority > b.Priority ? 1 : -1);
            this.productSupplierGridRef.dataSource = data.ProductSuppliers.sort((a, b) => a.Priority > b.Priority ? 1 : -1);
            this.productSupplierGridRef.refresh();

            supplierChangeLog.push({
                ...payload,
                ChangeOrder: supplierChangeLog.length + 1,
                IsDelete: false
            });

            this.setState({ data, hasUnsavedChanges: true, priceGlobal, transactionCategory, supplierChangeLog }, () => {
                this.addSupplierDialog.hide();
            });
        }
        else {
            this.dialogOpen('Advarsel', Localization.Product.PleaseSelectSupplier);
        }
    }

    onProductSupplierColumnDragStart(args) {
        this.hasDraggedColumn = true;
    }

    onProductSupplierCreated() {
        if (this.productSupplierGridSettings) {
            let settings = JSON.parse(this.productSupplierGridSettings);
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(column => {
                        if (column.FromIndex !== column.ToIndex) {
                            var tempIndex = this.productSupplierGridRef.getColumnIndexByField(column.Field);
                            if (tempIndex !== column.ToIndex){
                                this.productSupplierGridRef.reorderColumnByIndex(column.FromIndex, column.ToIndex);
                            }
                        }
    
                        if (column.Width) {
                            this.productSupplierGridRef.columns[column.ToIndex].width = column.Width;
                        }
                    });
                }

                if (settings.colName && settings.direction) {
                    this.productSupplierGridRef.sortColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.productSupplierGridRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.productSupplierGridRef.refreshColumns();

                // Prevent resizing of last column
                let lastColumnIndex = this.productSupplierGridRef.columns.length - 1;    
                this.productSupplierGridRef.columns[lastColumnIndex].allowResizing = false;
                this.productSupplierGridRef.refreshColumns();

                if (settings.pageSize) {
                    if (settings.pageSize === "Alle") {
                        this.productSupplierGridRef.pageSettings.pageSize = this.productSupplierGridRef.pageSettings.totalRecordsCount;
                        document.querySelector("#productSupplierGridRef .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.productSupplierGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
            }
            // if (settings.length > 0) {
            //     settings.forEach(setting => {
            //         if (setting.FromIndex !== setting.ToIndex) {
            //             this.productSupplierGridRef.reorderColumnByIndex(setting.FromIndex, setting.ToIndex);
            //         }

            //         if (setting.Width) {
            //             this.productSupplierGridRef.columns[setting.ToIndex].width = setting.Width;
            //         }
            //     });

            //     this.productSupplierGridRef.refreshColumns();
            // }
        }
    }

    async onProductSupplierActionComplete(args) {
        if (args.requestType === "delete") {
            let { data, transactionCategory, supplierChangeLog } = this.state;
            // data.ProductSuppliers = this.productSupplierGridRef.dataSource;
            let argsData = args.data[0];
            
            if (argsData.TempId) {
                data.ProductSuppliers = data.ProductSuppliers.filter(x => x.TempId !== argsData.TempId);
            }
            else {
                data.ProductSuppliers = data.ProductSuppliers.filter(x => x.Id !== argsData.Id);
            }

            this.productSupplierGridData = data.ProductSuppliers;
            this.productSupplierGridRef.dataSource = data.ProductSuppliers;
            this.productSupplierGridRef.refresh();

            let priceGlobal = this.computeAveragePrice();

            if (!transactionCategory.includes("Supplier")) {
                transactionCategory.push("Supplier");
            }

            if (argsData.Id) {
                if (supplierChangeLog.some(x => x.Id === argsData.Id)) {
                    let st = supplierChangeLog.find(x => x.Id === argsData.Id);
                    st.IsDelete = true;
                }
                else {
                    supplierChangeLog.push({
                        ...argsData,
                        ChangeOrder: supplierChangeLog.length + 1,
                        IsDelete: true
                    });
                }
            }
            else {
                if (supplierChangeLog.some(x => x.TempId === argsData.TempId)) {
                    supplierChangeLog = supplierChangeLog.filter(x => x.TempId !== argsData.TempId);
                }
            }

            this.setState({ data, hasUnsavedChanges: true, priceGlobal, transactionCategory, supplierChangeLog });
        }
        else if (args.requestType === 'paging') {
            if (this.productSupplierGridRef.pageSettings.pageSize === this.productSupplierGridRef.pageSettings.totalRecordsCount) {
                document.querySelector("#product-supplier-grid .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.productSupplierGridSettings, this.productSupplierGridRef.pageSettings.pageSize, this.productSupplierGridRef.pageSettings.totalRecordsCount, false, []);
            this.productSupplierGridSettings = tempGridSettings;
            const payload = {
                Key: this.productSupplierGridSettingsKey,
                Settings: this.productSupplierGridSettings
            };
            const result = await SettingsService.SaveGridSettings(payload, source.token);
        }
        else if (args.requestType === 'filtering') {
            if (this.productSupplierGridSettings) {
                let settings = JSON.parse(this.productSupplierGridSettings);
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let tempGridSettings = buildGridSettings(args, this.productSupplierGridSettings, 0, 0, false, []);
            this.productSupplierGridSettings = tempGridSettings;
            const payload = {
                Key: this.productSupplierGridSettingsKey,
                Settings: this.productSupplierGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.productSupplierGridSettings) {
                let settings = JSON.parse(this.productSupplierGridSettings);
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.productSupplierGridSettings, 0, 0, false, []);
            this.productSupplierGridSettings = tempGridSettings;
            const payload = {
                Key: this.productSupplierGridSettingsKey,
                Settings: this.productSupplierGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.productSupplierGridRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.productSupplierGridSettings, 0, 0, this.hasDraggedColumn, columns);
            this.productSupplierGridSettings = tempGridSettings;
            const payload = {
                Key: this.productSupplierGridSettingsKey,
                Settings: this.productSupplierGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                let lastColumnIndex = this.productSupplierGridRef.columns.length - 1;
                for (let index = 0; index < this.productSupplierGridRef.columns.length; index++) {
                    let column = this.productSupplierGridRef.columns[index];
                    if (index === lastColumnIndex) { column.allowResizing = false }
                    else { column.allowResizing = true }
                }
                this.productSupplierGridRef.refreshColumns();
            }

            this.hasDraggedColumn = false;
        }
        // else if (args.requestType === "reorder" && this.hasDraggedColumn) {
        //     let columns = this.productSupplierGridRef.getColumns();
        //     let tempCols = [], orderedColumns = [];

        //     if (this.productSupplierGridSettings) {
        //         orderedColumns = JSON.parse(this.productSupplierGridSettings);
        //     }

        //     columns.forEach((column, index) => {
        //         if (index === args.fromIndex) {
        //             tempCols.push({
        //                 HeaderText: column.headerText,
        //                 Field: column.field,
        //                 FromIndex: column.index,
        //                 ToIndex: index
        //             });
        //         }
        //         else {
        //             if (orderedColumns.some(x => x.HeaderText === column.headerText)) { //Check if previously saved column has been reordered
        //                 let col = orderedColumns.find(x => x.HeaderText === column.headerText)
        //                 if (col.ToIndex !== index) {
        //                     tempCols.push({
        //                         HeaderText: col.HeaderText,
        //                         Field: col.Field,
        //                         FromIndex: col.FromIndex,
        //                         ToIndex: index
        //                     });
        //                 }
        //             }
        //         }
        //     });

        //     const payload = {
        //         Key: this.productSupplierGridSettingsKey,
        //         Settings: JSON.stringify(tempCols)
        //     };

        //     const result = await SettingsService.SaveGridSettings(payload, source.token);

        //     if (!result.HasError) {
        //         this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
        //     }

        //     this.hasDraggedColumn = false;
        // }
    }

    async onProductSupplierResizeStop(args) {
        args.requestType = "resizingGrid";
        let columns = this.productSupplierGridRef.getColumns();
        let tempGridSettings = buildGridSettings(args, this.productSupplierGridSettings, 0, 0, false, columns);
        this.productSupplierGridSettings = tempGridSettings;
        const payload = {
            Key: this.productSupplierGridSettingsKey,
            Settings: this.productSupplierGridSettings
        };

        const result = await SettingsService.SaveGridSettings(payload, source.token);

        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);

            // Adjust size of last column to fill spaces
            let lastColumnIndex = this.productSupplierGridRef.columns.length - 1;
            let totalColumnWidth = this.productSupplierGridRef.columns.reduce((acc, curVal) => acc + parseInt(curVal.width), 0);
            let lastColumnWidth = this.productSupplierGridRef.element.clientWidth - totalColumnWidth;
            this.productSupplierGridRef.columns[lastColumnIndex].width = this.productSupplierGridRef.columns[lastColumnIndex].width + lastColumnWidth;
            this.productSupplierGridRef.refreshColumns();
        }
        // let columns = [];

        // if (this.productSupplierGridSettings) {
        //     columns = JSON.parse(this.productSupplierGridSettings);
        // }

        // if (columns.length > 0) {
        //     let column = columns.find(c => c.Field === args.column.field);
        //     if (column) {
        //         column.FromIndex =  args.column.index;
        //         column.ToIndex = args.column.index;
        //         column.Width = args.column.width
        //     }
        //     else {
        //         columns.push({
        //             HeaderText: args.column.headerText,
        //             Field: args.column.field,
        //             FromIndex: args.column.index,
        //             ToIndex: args.column.index,
        //             Width: args.column.width
        //         });
        //     }
        // }
        // else {
        //     columns.push({
        //         HeaderText: args.column.headerText,
        //         Field: args.column.field,
        //         FromIndex: args.column.index,
        //         ToIndex: args.column.index,
        //         Width: args.column.width
        //     });
        // }

        // const payload = {
        //     Key: this.productSupplierGridSettingsKey,
        //     Settings: JSON.stringify(columns)
        // };

        // const result = await SettingsService.SaveGridSettings(payload, source.token);

        // if (!result.HasError) {
        //     this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
        // }
    }

    onProductSupplierSearchClick = (url) => {
        this.props.history.push(url);
    }

    productSupplierTab() {
        const { moduleState, data } = this.state;        
        // return <ProductSupplier state={moduleState} isTabRendering={this.isTabRendering} onNotify={this.dialogOpen} data={data} onProductCreated={this.onProductCreated} undoRedoOptions={this.undoRedoOptions} onUpdatePrice={this.onUpdateSuppliersAveragePrice} />
        return (
            <div className="tab-control--item product-supplier-wrapper">
                <GridComponent ref={ref => this.productSupplierGridRef = ref } id="product-supplier-grid" dataSource={this.productSupplierGridData} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings} toolbar={this.supplierToolbarOptions}
                    editSettings={this.supplierEditSettings} selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da"
                    gridLines="Both" toolbarClick={this.supplierToolbarClickHandler.bind(this)} actionBegin={this.onSupplierGridActionBegin.bind(this)} allowTextWrap='true'
                    allowReordering={true} allowResizing={true} actionComplete={this.onProductSupplierActionComplete.bind(this)} columnDragStart={this.onProductSupplierColumnDragStart.bind(this)} 
                    created={this.onProductSupplierCreated.bind(this)} resizeStop={this.onProductSupplierResizeStop.bind(this)}>
                    <ColumnsDirective>
                        <ColumnDirective field="Supplier.SupplierNo" headerText="Leverandør #" width='100' allowEditing={false} /> 
                        <ColumnDirective field="Supplier.Company" headerText="Leverandør navn" width='100' allowEditing={false} /> 
                        <ColumnDirective field="SupplierProductNo" headerText="Lev produkt#" width='100' />
                        <ColumnDirective field="SupplierEAN" headerText="Lev. EAN#" width='100' />
                        <ColumnDirective field="SupplierProductDesc" headerText="Lev produkt navn" width='100' />
                        <ColumnDirective field="DeliveryQty" headerText="Leverer i antal på" textAlign="Right" width='100' editType='numericedit' edit={this.numericParams} format="n2" />
                        <ColumnDirective field="PurchasePrice" headerText="Indkøbspris / stk." textAlign="Right" width='100' editType='numericedit' edit={this.numericParams2} format="N4" />
                        <ColumnDirective field="Priority" headerText="Prioitet" width='100' textAlign="Right" />
                        <ColumnDirective field="Supplier.DeliveryTimeInDays" headerText="Leveringstid i dage" width='100' textAlign="Right" allowEditing={false} />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Selection, Toolbar, Edit, Search, Reorder, Resize]} />
                </GridComponent>
            </div>
        )
    }
    //#endregion

    //#region Package Tab
    productPackagesTab() {
        return ( <div></div> )
    }
    //#endregion

    //#region Documents Tab
    productDocumentsTab() {
        return ( <div></div> )
    }
    //#endregion

    //#region Pictures Tab
    dialogClose() {
        let { dialogOpts } = this.state;
        dialogOpts.visible = false;
        this.setState({ dialogOpts });
        this.fileArchiveDialog.hide();
    }

    async onCheckChange(args, props) {
        let { data, transactionCategory } = this.state;
        var imageKey = "";

        data.ProductImages.forEach(img => {
            if (img.TempId && img.TempId === props.TempId) {
                img.IsPrimary = args.checked;
                imageKey = img.ImageKey;
            }
            else if (img.Id && img.Id === props.Id) {
                img.IsPrimary = args.checked;
                imageKey = img.ImageKey;
            }
            else {
                img.IsPrimary = false;
            }
        });
        
        if (args.checked && imageKey){
            var imageResult = await FileService.GetImage(imageKey);
            this.productImage.src = imageResult ? imageResult : productPlaceholder;
        }

        if (!transactionCategory.includes("Picture")) {
            transactionCategory.push("Picture");
        }

        this.ProductPicturesGridRef.dataSource = data.ProductImages;
        this.ProductPicturesGridRef.refresh();

        this.setState({ data, hasUnsavedChanges: true, transactionCategory });
    }

    async onFileImageSelected(args) {
        const selectedFiles = this.fileImagesRef.checkedNodes;
        
        if (selectedFiles.length > 0) {
            let { data, transactionCategory } = this.state;
            let allowableImageCount = 5 - data.ProductImages.length;

            if (selectedFiles.length > allowableImageCount) {
                this.dialogOpen('Advarsel', Localization.Product.ErrorMaxImageReached);
                return;
            }

            let isPrimary = data.ProductImages.length === 0 && selectedFiles.length === 1;

            for (let index = 0; index < selectedFiles.length; index++) {
                let imageTypes = ['png', 'jpg', 'jpeg'];
                let image = selectedFiles[index];

                let spImg = image.split('.');
                if (imageTypes.includes(spImg[spImg.length - 1])) {
                    const pic = {
                        Id: null,
                        TempId: `T${data.ProductImages.length}`,
                        ImageKey: selectedFiles[index],
                        IsPrimary: isPrimary,
                        ProductId: data.Id,
                    }

                    data.ProductImages.push(pic);
                }
            }

            if (!transactionCategory.includes("Picture")) {
                transactionCategory.push("Picture");
            }

            this.picturesData = data.ProductImages;
            this.ProductPicturesGridRef.dataSource = data.ProductImages;
            this.ProductPicturesGridRef.refresh();

            this.setState({ data, hasUnsavedChanges: true, transactionCategory }, () => {
                this.fileImagesRef.uncheckAll();
                this.fileArchiveDialog.hide();
            });
        }
        else {
            this.dialogOpen('Advarsel', Localization.Product.PleaseSelectImage);
        }
    }

    imageTemplate(props) {
        return ( <Image src={props.ImageKey} alt="" className="product-image-data" /> )
    }

    imagePathTemplate(props) {        
        let path = props.ImageKey.split('/');
        path.shift();
        return ( <div>{path.join('/')}</div> )
    }

    imageNameTemplate(props) {        
        let imgFullPath = props.ImageKey.split('/');
        let imageName = imgFullPath[imgFullPath.length - 1];
        return ( <div>{imageName}</div> );
    }

    primaryTemplate(props) {
        return ( <div><CheckBoxComponent name="primary_picture" checked={props.IsPrimary} change={(e) => { this.onCheckChange(e, props) }} /></div> )
    }

    async onActionBegin(args) {
        if (args.requestType === "delete") {
            // args.cancel = true;
            // source.cancel();
            // source = CancelToken.source();

            // const { undoRedoOptions } = this.props;
            // const result = await ProductService.DeleteProductPicture(args.data[0].Id, source.token);

            // if(!result.HasError) {
            //     this.undoRedoOptions(result.Undo, result.Redo, result.UndoAction, result.RedoAction);
            //     this.dialogOpen(Localization.General.SuccessHeader, Localization.Product.SuccessUnlinkedImage);
            //     this.refreshPicturesGrid();
            // }
            // else {
            //     this.dialogOpen('Advarsel', result.ErrorMessage );
            // }
        }
    }

    async refreshPicturesGrid() {
        const { data } = this.state;
        const productImages = await ProductService.GetProductImages(data.Id, source.token);
        this.picturesData = productImages;
        this.ProductPicturesGridRef.dataSource = productImages;
        this.ProductPicturesGridRef.dataBind();

        let primaryImage = productImages.find(img => img.IsPrimary);

        if (!primaryImage && productImages.length > 0) {
            primaryImage = productImages[0];
        }
        
        if (primaryImage) {
            var imageResult = await FileService.GetImage(primaryImage.ImageKey);
            this.productImage.src = imageResult ? imageResult : productPlaceholder;
        }
    }

    async clickHandler(args) {
        if (this.ProductPicturesGridRef && args.item.id === 'linkImage') {
            const dataSource = this.ProductPicturesGridRef.dataSource;
            if (dataSource.length == 5) {
                this.dialogOpen("Advarsel", Localization.Product.ErrorMaxImageReached)
            }
            else {
                let { dialogOpts } = this.state;
                dialogOpts.visible = true;
                this.setState({ dialogOpts });
                this.fileArchiveDialog.show();
            }
        }
    }

    beforeSend(args) {
        const { dialogOpts } = this.state;
        args.ajaxSettings.beforeSend = function(e) {
            e.httpRequest.setRequestHeader("Authorization", `Bearer ${dialogOpts.token}`);
        }
    }

    onProductImagesColumnDragStart(args) {
        this.hasDraggedColumn = true;
    }

    onProductImagesCreated() {
        if (this.productImagesGridSettings) {
            let settings = JSON.parse(this.productImagesGridSettings);
            if (settings.length > 0) {
                settings.forEach(setting => {
                    if (setting.FromIndex !== setting.ToIndex) {
                        this.ProductPicturesGridRef.reorderColumnByIndex(setting.FromIndex, setting.ToIndex);
                    }

                    if (setting.Width) {
                        this.ProductPicturesGridRef.columns[setting.ToIndex].width = setting.Width;
                    }
                });

                this.ProductPicturesGridRef.refreshColumns();
                let lastColumnIndex = this.ProductPicturesGridRef.columns.length - 1;    
                this.ProductPicturesGridRef.columns[lastColumnIndex].allowResizing = false;
                this.ProductPicturesGridRef.refreshColumns();
            }
        }
    }

    async onProductImagesActionComplete(args) {
        if (args.requestType === "delete") {
            let { data, transactionCategory, transactionTableDelLog } = this.state;
            data.ProductImages = this.ProductPicturesGridRef.dataSource;

            if (!transactionCategory.includes("Picture")) {
                transactionCategory.push("Picture");
            }

            if (args.data[0].Id) {
                transactionTableDelLog.Pictures.push(args.data[0].Id);
            }

            if (data.ProductImages.length === 1) {
                data.ProductImages[0].IsPrimary = true;
            }

            this.picturesData = data.ProductImages;
            this.ProductPicturesGridRef.refresh();

            this.setState({ data, hasUnsavedChanges: true, transactionCategory, transactionTableDelLog });
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.ProductPicturesGridRef.getColumns();
            let tempCols = [], orderedColumns = [];

            if (this.productImagesGridSettings) {
                orderedColumns = JSON.parse(this.productImagesGridSettings);
            }

            columns.forEach((column, index) => {
                if (index === args.fromIndex) {
                    tempCols.push({
                        HeaderText: column.headerText,
                        Field: column.field,
                        FromIndex: column.index,
                        ToIndex: index
                    });
                }
                else {
                    if (orderedColumns.some(x => x.HeaderText === column.headerText)) { //Check if previously saved column has been reordered
                        let col = orderedColumns.find(x => x.HeaderText === column.headerText)
                        if (col.ToIndex !== index) {
                            tempCols.push({
                                HeaderText: col.HeaderText,
                                Field: col.Field,
                                FromIndex: col.FromIndex,
                                ToIndex: index
                            });
                        }
                    }
                }
            });

            const payload = {
                Key: this.productImagesGridSettingsKey,
                Settings: JSON.stringify(tempCols)
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                let lastColumnIndex = this.ProductPicturesGridRef.columns.length - 1;
                for (let index = 0; index < this.ProductPicturesGridRef.columns.length; index++) {
                    let column = this.ProductPicturesGridRef.columns[index];
                    if (index === lastColumnIndex) { column.allowResizing = false }
                    else { column.allowResizing = true }
                }
                this.ProductPicturesGridRef.refreshColumns();
            }

            this.hasDraggedColumn = false;
        }
    }

    async onProductImagesResizeStop(args) {
        let columns = [];

        if (this.productImagesGridSettings) {
            columns = JSON.parse(this.productImagesGridSettings);
        }

        if (columns.length > 0) {
            let column = columns.find(c => c.Field === args.column.field);
            if (column) {
                column.FromIndex =  args.column.index;
                column.ToIndex = args.column.index;
                column.Width = args.column.width
            }
            else {
                columns.push({
                    HeaderText: args.column.headerText,
                    Field: args.column.field,
                    FromIndex: args.column.index,
                    ToIndex: args.column.index,
                    Width: args.column.width
                });
            }
        }
        else {
            columns.push({
                HeaderText: args.column.headerText,
                Field: args.column.field,
                FromIndex: args.column.index,
                ToIndex: args.column.index,
                Width: args.column.width
            });
        }

        const payload = {
            Key: this.productImagesGridSettingsKey,
            Settings: JSON.stringify(columns)
        };

        const result = await SettingsService.SaveGridSettings(payload, source.token);

        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);

            // Adjust size of last column to fill spaces
            let lastColumnIndex = this.ProductPicturesGridRef.columns.length - 1;
            let totalColumnWidth = this.ProductPicturesGridRef.columns.reduce((acc, curVal) => acc + parseInt(curVal.width), 0);
            let lastColumnWidth = this.ProductPicturesGridRef.element.clientWidth - totalColumnWidth;
            this.ProductPicturesGridRef.columns[lastColumnIndex].width = this.ProductPicturesGridRef.columns[lastColumnIndex].width + lastColumnWidth;
            this.ProductPicturesGridRef.refreshColumns();
        }
    }

    productPicturesTab() {
        const { moduleState, data } = this.state;
        // return <ProductPicture state={moduleState} data={data} onProductCreated={this.onProductCreated} undoRedoOptions={this.undoRedoOptions} onUpdatePrimaryPicture={this.onUpdatePrimaryPicture} />
        return (
            <div className="tab-control--item product-image-wrapper">
                <GridComponent ref={ref => this.ProductPicturesGridRef = ref } dataSource={this.picturesData} toolbar={this.toolbarOptions} editSettings={this.defaultEditSettings} width="780px"
                    selectionSettings={this.selectionSettings} locale="da" gridLines="Both" toolbarClick={this.clickHandler.bind(this)} actionBegin={this.onActionBegin} allowTextWrap={true}
                    allowReordering={true} allowResizing={true} actionComplete={this.onProductImagesActionComplete.bind(this)} columnDragStart={this.onProductImagesColumnDragStart.bind(this)} 
                    created={this.onProductImagesCreated.bind(this)} resizeStop={this.onProductImagesResizeStop.bind(this)}>
                    <ColumnsDirective>
                        <ColumnDirective field="ImageKey" headerText="Billede" width='100' textAlign="center" template={this.imageTemplate} />
                        <ColumnDirective field="ImageKey" headerText="Fil Sti" width='100' textAlign="center" template={this.imagePathTemplate} />
                        <ColumnDirective field="ImageKey" headerText="Fil Navn" width='100' textAlign="center" template={this.imageNameTemplate} />
                        <ColumnDirective field="IsPrimary" headerText="Primær" width='100' textAlign="center" template={this.primaryTemplate} /> 
                    </ColumnsDirective>
                    <Inject services={[Selection, Toolbar, Reorder, Resize]} />
                </GridComponent>
            </div>
        )
    }
    //#endregion

    //#region Search Word Tab
    productSearchwordTab() {
        return ( <div></div> )
    }
    //#endregion

    //#region General Settings Tab
    onActiveCaseNumberChange = (args) => {
        const { data, transactionCategory } = this.state;
        data.ActiveCaseNumber = args.checked;
        if (!transactionCategory.includes("Setting")) {
            transactionCategory.push("Setting");
        }
        this.setState({ data, hasUnsavedChanges: true, transactionCategory });
    }

    onDeactivatedChange = (args) => {
        const { data, transactionCategory } = this.state;
        data.Deactivated = args.checked;
        if (!transactionCategory.includes("Setting")) {
            transactionCategory.push("Setting");
        }
        this.setState({ data, hasUnsavedChanges: true, transactionCategory });
    }

    onAlwaysInputQtyChange = (args) => {
        const { data, transactionCategory } = this.state;

        if (!data.Weight || data.Weight === 0) {
            data.AlwaysInputQty = true;
            this.AlwaysInputQtyRef.checked = true;
            data.AlwaysWeight = false;
            this.AlwaysWeightRef.checked = false;
            data.ChooseWhenProductDraw = false;
            this.ChooseWhenProductDrawRef.checked = false;

            // this.AlwaysWeightRef.disabled = false;
            // this.divAlwaysWeight.classList.remove('text-fade');

            // this.ChooseWhenProductDrawRef.disabled = false;
            // this.divChooseWhenProductDraw.classList.remove('text-fade');
        }
        // else if (args.checked && (!data.Weight || data.Weight === 0)) {
        //     this.AlwaysWeightRef.disabled = true;
        //     this.AlwaysWeightRef.checked = false;
        //     this.divAlwaysWeight.classList.add('text-fade');

        //     this.ChooseWhenProductDrawRef.disabled = true;
        //     this.ChooseWhenProductDrawRef.checked = false;
        //     this.divChooseWhenProductDraw.classList.add('text-fade');
        // }
        else {
            data.AlwaysInputQty = args.checked;
            // data.AlwaysWeight = !args.checked;
            // data.ChooseWhenProductDraw = !args.checked;
            if (args.checked) {
                this.AlwaysWeightRef.checked = false;
                data.AlwaysWeight = false;
                this.ChooseWhenProductDrawRef.checked = false;
                data.ChooseWhenProductDraw = false;
            }
        }

        if (!transactionCategory.includes("Setting")) {
            transactionCategory.push("Setting");
        }

        this.setState({ data, hasUnsavedChanges: true, transactionCategory });
    }

    onAlwaysWeightChange = (args) => {
        const { data, transactionCategory } = this.state;
        data.AlwaysWeight = args.checked
        if (args.checked) {
            data.AlwaysInputQty = false;
            this.AlwaysInputQtyRef.checked = false;
            data.ChooseWhenProductDraw = false;
            this.ChooseWhenProductDrawRef.checked = false;
        }

        if (!transactionCategory.includes("Setting")) {
            transactionCategory.push("Setting");
        }
        this.setState({ data, hasUnsavedChanges: true, transactionCategory });
    }

    onChooseWhenProductDrawChange = (args) => {
        const { data, transactionCategory } = this.state;
        data.ChooseWhenProductDraw = args.checked        
        if (args.checked) {
            data.AlwaysInputQty = false;
            this.AlwaysInputQtyRef.checked = false;
            data.AlwaysWeight = false;
            this.AlwaysWeightRef.checked = false;
        }

        if (!transactionCategory.includes("Setting")) {
            transactionCategory.push("Setting");
        }

        this.setState({ data, hasUnsavedChanges: true, transactionCategory });
    }

    onPurchaseToleranceChange = (args) => {
        const { data, transactionCategory } = this.state;
        data.PurchaseTolerance = args.value

        if (!transactionCategory.includes("Setting")) {
            transactionCategory.push("Setting");
        }

        this.setState({ data, hasUnsavedChanges: true, transactionCategory });
    }

    toggleGeneralSettingsTab(enabled, moduleState) {
        const { data, groupGuaranteedWeight } = this.state;
        let { oldGroupId } = this.state;
        this.ActiveCaseNumberRef.disabled = !enabled;
        this.DeactivatedRef.disabled = !enabled;
        this.AlwaysInputQtyRef.disabled = !enabled;
        this.PurchaseToleranceRef.disabled = !enabled;

        // if (moduleState === 'add') {
        //     this.AlwaysWeightRef.disabled = true;
        //     this.ChooseWhenProductDrawRef.disabled = true;
        // }
        // else {
            if (data.Weight > 0) {
                // 2 & 3 only active if product weight has weight
                this.AlwaysWeightRef.disabled = !enabled;
                this.ChooseWhenProductDrawRef.disabled = !enabled;
                
                if (data.Weight <= this.GuaranteedWeight) {
                    // 1 & 3 - only active if product is under guaranteed weight
                    this.AlwaysInputQtyRef.checked = data.AlwaysInputQty;
                    this.AlwaysInputQtyRef.disabled = !enabled;
                    this.divAlwaysInputQty.classList.remove('text-fade');

                    this.AlwaysWeightRef.disabled = !enabled;
                    this.AlwaysWeightRef.checked = data.AlwaysWeight;
                    this.divAlwaysWeight.classList.remove('text-fade');
                    
                    this.ChooseWhenProductDrawRef.disabled = !enabled;
                    this.ChooseWhenProductDrawRef.checked = data.ChooseWhenProductDraw;
                    this.divChooseWhenProductDraw.classList.remove('text-fade');

                    if ((data.ProductGroupId != oldGroupId) || !(data.AlwaysInputQty || data.AlwaysWeight || data.ChooseWhenProductDraw)) {
                        this.AlwaysInputQtyRef.checked = groupGuaranteedWeight == 1;
                        this.AlwaysWeightRef.checked = groupGuaranteedWeight == 2;
                        this.ChooseWhenProductDrawRef.checked = groupGuaranteedWeight == 3;
                        oldGroupId = data.ProductGroupId;
                        this.setState({ oldGroupId });
                    }
                }
                else if (data.Weight > this.GuaranteedWeight) { 
                    // 2 - only option when product is over guaranteed weight
                    this.AlwaysInputQtyRef.disabled = true;
                    this.AlwaysInputQtyRef.checked = false;
                    data.AlwaysInputQty = false;
                    this.divAlwaysInputQty.classList.add('text-fade');

                    this.AlwaysWeightRef.disabled = !enabled;
                    this.AlwaysWeightRef.checked = true;
                    // this.AlwaysWeightRef.checked = true;
                    data.AlwaysWeight = true;
                    this.divAlwaysWeight.classList.remove('text-fade');

                    this.ChooseWhenProductDrawRef.disabled = true;
                    this.ChooseWhenProductDrawRef.checked = false;
                    data.ChooseWhenProductDraw = false;
                    this.divChooseWhenProductDraw.classList.add('text-fade');
                }
                else {

                }
            }
            else {
                this.AlwaysInputQtyRef.checked = true;
                data.AlwaysInputQty = true;
                this.divAlwaysInputQty.classList.remove('text-fade');
                
                this.AlwaysWeightRef.disabled = true;
                this.AlwaysWeightRef.checked = false;
                data.AlwaysWeight = false;
                this.divAlwaysWeight.classList.add('text-fade');
                
                this.ChooseWhenProductDrawRef.disabled = true;
                this.ChooseWhenProductDrawRef.checked = false;
                data.ChooseWhenProductDraw = false;
                this.divChooseWhenProductDraw.classList.add('text-fade');
            }

            // this.isInitialUpdateTolerance = true;
            // data.PurchaseTolerance = data.PurchaseTolerance ? data.PurchaseTolerance * 100 : data.ProductGroup ? data.ProductGroup.PurchaseTolerance * 100 : 0;
        // }

        this.setState({ data });
    }

    productGeneralSettingsTab() {
        const { data, moduleState, isEditMode } = this.state;
        // return <ProductGeneralSettings state={moduleState} onNotify={this.dialogOpen} data={data} onDataChange={this.onDataChange} />
        
        return (
            <div className="tab-control--item product-settings-wrapper">
                <div>
                    <Card headerText="Sags nummer styring">
                        <div className="field-bordered">
                            <label htmlFor="ActiveCaseNumber">Aktiv</label>
                            <CheckBoxComponent id="ActiveCaseNumber" ref={ref => this.ActiveCaseNumberRef = ref} checked={data.ActiveCaseNumber} name="ActiveCaseNumber" cssClass="control-right" change={this.onActiveCaseNumberChange} />
                        </div>
                    </Card>
                    <Card headerText="Inaktiv">
                        <div className="field-bordered">
                            <label htmlFor="Inaktiv">Inaktiv</label>
                            <CheckBoxComponent id="Inaktiv" ref={ref => this.DeactivatedRef = ref} checked={data.Deactivated} name="inactive" cssClass="control-right" change={this.onDeactivatedChange} />
                        </div>
                    </Card>
                </div>
                <div>
                    <Card headerText="Produkt træk procedure" className="product-pull-procedure">
                        <div ref={ref => this.divAlwaysInputQty = ref} className="field-bordered">
                            <label htmlFor="AlwaysInputQty">1. Indtast altid Antal</label>
                            <CheckBoxComponent id="AlwaysInputQty" ref={ref => this.AlwaysInputQtyRef = ref} checked={data.AlwaysInputQty} name="AlwaysInputQty" cssClass="control-right" change={this.onAlwaysInputQtyChange} />
                        </div>
                        <div ref={ref => this.divAlwaysWeight = ref} className="field-bordered">
                            <label htmlFor="AlwaysWeight">2. Vej altid Antal</label>
                            <CheckBoxComponent id="AlwaysWeight" ref={ref => this.AlwaysWeightRef = ref} checked={data.AlwaysWeight} name="AlwaysWeight" cssClass="control-right" change={this.onAlwaysWeightChange} />
                        </div>
                        <div ref={ref => this.divChooseWhenProductDraw = ref} className="field-bordered">
                            <label htmlFor="ChooseWhenProductDraw">3. Vælg ved træk</label>
                            <CheckBoxComponent id="ChooseWhenProductDraw" ref={ref => this.ChooseWhenProductDrawRef = ref} checked={data.ChooseWhenProductDraw} name="ChooseWhenProductDraw" cssClass="control-right" change={this.onChooseWhenProductDrawChange} />
                        </div>
                    </Card>
                    <Card headerText="Tillæg til tolerance ved indkøb">
                        <div className="field-bordered purchase-tolerance-field">
                            <label htmlFor="PurchaseTolerance">Indtast værdi</label>
                            <NumberFormat ref={ref => this.PurchaseToleranceRef = ref} type="text" decimalScale={2} fixedDecimalScale={true} suffix="%" 
                                    className={`control-right e-input input-text-right`} value={data.PurchaseTolerance} disabled={!isEditMode}
                                    name="PurchaseTolerance" onValueChange={(values) => this.onValueChange(values, "PurchaseTolerance")} />
                        </div>
                    </Card>
                </div>
            </div>
        )
    }
    //#endregion

    //#endregion

    tabSelected(args) {
        const { breadcrumbs, moduleState, isEditMode, data } = this.state;
        if (breadcrumbs.length > 1) {
            breadcrumbs.pop();
        }
        breadcrumbs.push(this.tabHeader[args.selectedIndex].text);

        let isFieldsEnabled = false;

        if (moduleState === 'add') {
            isFieldsEnabled = true;
        }
        else if (moduleState === 'edit') {
            if (isEditMode) {
                isFieldsEnabled = true;
            }
        }

        switch(args.selectedIndex) {
            case 0:
                this.toggleProductDescriptionFields(isFieldsEnabled);
                break;
            case 1:
                this.toggleProductSizeFields(isFieldsEnabled);
                break;
            case 2:
                this.toggleProductGroupFields(isFieldsEnabled);
                
                if (data.ProductGroup) {
                    this.primaryProductGroupRef.checkedNodes = [data.ProductGroup.Id.toString()];
                }
                break;
            case 3: //supplier
                break;
            case 4:
                this.toggleProductPriceFields(isFieldsEnabled);
                break;
            case 5: //stock
                break;
            case 6: //pictures
                break;
            case 7:
                this.toggleGeneralSettingsTab(isFieldsEnabled, moduleState);
                break;
            default:
                break;
        }
        
        this.ProductToolbarRef.enableItems(6, false);
        this.ProductToolbarRef.enableItems(7, false);

        this.setState({ breadcrumbs });
    }

    onProductCreated(id) {
        let { data } = this.state;
        data.Id = id;
        this.setState({ data });
    }

    undoRedoOptions(hasUndo, hasRedo, undoTooltip = '', redoTooltip = '') {
        let { transaction } = this.state;
        transaction.hasUndo = hasUndo;
        transaction.hasRedo = hasRedo;
        transaction.undoTooltip = undoTooltip;
        transaction.redoTooltip = redoTooltip;
        this.setState({ transaction });
    }

    dialogOpen(header, content, callback = null) {
        console.log(this.notificationDialog);
        console.log(header);
        console.log(content);
        this.notificationDialog.header = header;
        this.notificationDialog.content = content;

        if (callback) {
            let buttons = Object.assign([],this.notificationDialog.buttons);
            buttons[0].click = callback;
            this.notificationDialog.buttons = buttons;
        }
        else {
            let buttons = this.notificationDialog.buttons;
            buttons[0].click = () => {
                this.notificationDialog.hide();
            }
            this.notificationDialog.buttons = buttons;
        }

        this.notificationDialog.show();
    }

    validate() {
        const { data, currentTabSelected } = this.state;
        let hasError = false;
        let errorList = [], redirectTab = [];
        
        if (!data.ProductNo) {
            errorList.push('<li>Vare Nr.</li>');
            hasError = true;
            redirectTab.push(0);
        }

        // if (!data.EAN) {
        //     errorList.push('<li>Ean Nr.</li>');
        //     hasError = true;
        //     redirectTab.push(0);
        // }

        if (!data.Description1) {
            errorList.push('<li>Beskrivelse</li>');
            hasError = true;
            redirectTab.push(0);
        }

        if (!data.ProductGroupId) {
            errorList.push('<li>Produktgruppe</li>');
            hasError = true;
            redirectTab.push(2);
        }

        if (hasError) {
            let text = 'Du mangler at udfylde et eller flere felter for at færdiggøre oprettelsen <br>af produktet og kan derfor ikke gemme dette produkt før nedenstående <br>oplysninger er udfyldt:';
            text += `<ul>${errorList.join('')}</ul>`;
            this.dialogOpen("Advarsel", text, () => {
                this.notificationDialog.hide();
                let tab = redirectTab[0] || 0;
                this.setState({ currentTabSelected: tab }, () => {
                    switch(tab) {
                        case 0:
                            if (!data.ProductNo) {
                                this.ProductNoRef.classList.add('e-error');
                            }
                    
                            // if (!data.EAN) {
                            //     this.EANRef.classList.add('e-error');
                            // }
                    
                            if (!data.Description1) {
                                this.Description1Ref.classList.add('e-error');
                            }
                        break;
                    }
                });
            });
        }

        return hasError;
    }

    async reloadProductData() {
        let { data, currentTabSelected, dimensionsData, weightsData, selectedWeight, selectedDimension, selectedWeightId, selectedDimensionId } = this.state;

        let result = await ProductService.GetProduct(data.Id, source.token);
        if (!result.HasError) {
            data = Object.assign(data, result);
            switch(currentTabSelected) {
                case 0:
                    this.ProductNoRef.value = result.ProductNo;
                    this.EANRef.value = result.EAN;
                    this.Description1Ref.value = result.Description1;
                    this.Description2Ref.value = result.Description2;
                    this.SalesUnitRef.value = result.SalesUnit;
                    this.SalesUnitPerPcsRef.value = result.SalesUnitPerPcs;
                    break;
                case 1:
                    this.LengthRef.value = result.Length;
                    this.HeightRef.value = result.Height;
                    this.WidthRef.value = result.Width;
                    this.CustomVolumeRef.value = result.CustomVolume;
                    this.WeightRef.value = result.Weight;
                    selectedWeight = weightsData.find(x => x.Value === data.WeightUnit);
                    if (selectedWeight) {
                        selectedWeightId = selectedWeight.Value;
                    }
                    selectedDimension = dimensionsData.find(x => x.Value === data.DimensionUnit);
                    if (selectedDimension) {
                        selectedDimensionId = selectedDimension.Value;
                    }
                    break;
                case 2:
                    this.primaryProductGroupRef.checkedNodes = [result.ProductGroup.Id.toString()];
                    break;
                case 4:
                    this.FactorRef.value = result.Factor;
                    this.ProfitPercentageRef.value = result.ProfitPercentage;
                    this.ProfitRef.value = result.Profit;
                    this.SalesPriceRef.value = result.SalesPrice;
    
                    let costPrice = 0
                    for (let index = 0; index < data.ProductSuppliers.length; index++) {
                        costPrice += data.ProductSuppliers[index].PurchasePrice;
                    }
    
                    // this.GlobalCostPriceRef.innerHTML = toDanishNumberFormat(costPrice);
                    if (!result.Factor) {
                        // this.GlobalFactorRef.innerHTML = result.ProductGroup.Factor;
                    }
                    // else {
                    //     this.GlobalFactorRef.innerHTML = '';
                    // }
                    if (!result.ProfitPercentage) {
                        // this.GlobalProfitPercentRef.innerHTML = result.ProductGroup.ProfitPercentage;
                    }
                    // else {
                    //     this.GlobalProfitPercentRef.innerHTML = '';
                    // }
                    if (!result.Profit) {
                        // this.GlobalProfitRef.innerHTML = toDanishNumberFormat((costPrice * result.ProductGroup.Factor) - costPrice); 
                    }
                    // else {
                    //     this.GlobalProfitRef.innerHTML = '';
                    // }
                    if (!result.SalesPrice) {
                        // this.GlobalSalesPriceRef.innerHTML = toDanishNumberFormat(costPrice * result.ProductGroup.Fac tor);
                    }
                    // else {
                    //     this.GlobalSalesPriceRef.innerHTML = '';
                    // }
                    break;
                default:
                    break;
            }
        }

        this.setState({ data, selectedWeight, selectedDimension, selectedDimensionId, selectedWeightId });
    }

    toggleTabFields(enabled) {
        const { currentTabSelected } = this.state;
        switch(currentTabSelected) {
            case 0:
                this.toggleProductDescriptionFields(enabled);
                break;
            case 1:
                this.toggleProductSizeFields(enabled);
                break;
            case 2:
                this.toggleProductGroupFields(enabled);
                break;
            case 3: //supplier
                if (!enabled) {
                    this.productSupplierGridRef.closeEdit();
                }
                this.productSupplierGridRef.toolbarModule.enableItems(['add', 'open'], enabled);

                setTimeout(() => {
                    let editButton = document.querySelector('.product-supplier-wrapper .e-grid .e-toolbar-item[title="Redigere"]');
                    let deleteButton = document.querySelector('.product-supplier-wrapper .e-grid .e-toolbar-item[title="Slette"]');
                    
                    if (editButton) {
                        if (!enabled) {
                            editButton.classList.add('e-overlay');
                        }
                        else {
                            editButton.classList.remove('e-overlay');
                        }
                    }
    
                    if (deleteButton) {
                        if (!enabled) {
                            deleteButton.classList.add('e-overlay');
                        }
                        else {
                            deleteButton.classList.remove('e-overlay');
                        }
                    }

                    if (enabled) {
                        this.productSupplierGridRef.element.querySelector('.e-gridcontent').classList.remove('c-disablegrid');
                        let wrapper = document.getElementsByClassName('product-supplier-wrapper')[0];
                        if (wrapper) {
                            wrapper.classList.remove('c-disablegrid-wrapper');
                        }
                    }
                    else {
                        this.productSupplierGridRef.element.querySelector('.e-gridcontent').classList.add('c-disablegrid');
                        let wrapper = document.getElementsByClassName('product-supplier-wrapper')[0];
                        if (wrapper) {
                            wrapper.classList.remove('c-disablegrid-wrapper');
                        }
                    }
                }, 200);
                break;
            case 4:
                this.toggleProductPriceFields(enabled);
                break;
            case 5: //stock
                if (!enabled) {
                    this.productStockGridRef.closeEdit();
                }
                this.productStockGridRef.toolbarModule.enableItems(['add'], enabled);
                
                setTimeout(() => {
                    let editButton = document.querySelector('.product-stock-wrapper .e-grid .e-toolbar-item[title="Redigere"]');
                    let deleteButton = document.querySelector('.product-stock-wrapper .e-grid .e-toolbar-item[title="Slette"]');
                    
                    if (editButton) {
                        if (!enabled) {
                            editButton.classList.add('e-overlay');
                        }
                        else {
                            editButton.classList.remove('e-overlay');
                        }
                    }
    
                    if (deleteButton) {
                        if (!enabled) {
                            deleteButton.classList.add('e-overlay');
                        }
                        else {
                            deleteButton.classList.remove('e-overlay');
                        }
                    }

                    if (enabled) {
                        this.productStockGridRef.element.querySelector('.e-gridcontent').classList.remove('c-disablegrid');
                        let wrapper = document.getElementsByClassName('product-stock-wrapper')[0];
                        if (wrapper) {
                            wrapper.classList.remove('c-disablegrid-wrapper');
                        }
                    }
                    else {
                        this.productStockGridRef.element.querySelector('.e-gridcontent').classList.add('c-disablegrid');
                        let wrapper = document.getElementsByClassName('product-stock-wrapper')[0];
                        if (wrapper) {
                            wrapper.classList.remove('c-disablegrid-wrapper');
                        }
                    }
                }, 200);
                break;
            case 6: //pictures
                this.ProductPicturesGridRef.toolbarModule.enableItems(['linkImage'], enabled);
                
                setTimeout(() => {
                    let deleteButton = document.querySelector('.product-image-wrapper .e-grid .e-toolbar-item[title="Slette"]');
    
                    if (deleteButton) {
                        if (!enabled) {
                            deleteButton.classList.add('e-overlay');
                        }
                        else {
                            deleteButton.classList.remove('e-overlay');
                        }
                    }

                    if (enabled) {
                        this.ProductPicturesGridRef.element.querySelector('.e-gridcontent').classList.remove('c-disablegrid');
                        let wrapper = document.getElementsByClassName('product-image-wrapper')[0];
                        if (wrapper) {
                            wrapper.classList.remove('c-disablegrid-wrapper');
                        }
                    }
                    else {
                        this.ProductPicturesGridRef.element.querySelector('.e-gridcontent').classList.add('c-disablegrid');
                        let wrapper = document.getElementsByClassName('product-image-wrapper')[0];
                        if (wrapper) {
                            wrapper.classList.remove('c-disablegrid-wrapper');
                        }
                    }
                }, 200);
                break;
            case 7:
                this.toggleGeneralSettingsTab(enabled);
                break;
            default:
                break;
        }
    }

    async onProductToolbarClicked(args) {
        if (args.item.id === 'add') {
            let { defaultUnits, selectedWeight, selectedDimension, dimensionsData, weightsData, currentTabSelected } = this.state;
            this.props.history.push('/produktkort/add');
            let data = {
                ProductGroup: {},
                ProductImages: [],
                ProductSuppliers: [],
                ProductStorages: [],
            };

            let defaultPriceGlobal = {
                CostPrice: 0,
                Factor: 0,
                ProfitPercent: 0,
                Profit: 0,
                SalesPrice: 0
            }

            selectedDimension = dimensionsData.find(x => x.Value === defaultUnits.DimensionUnit);
            selectedWeight = weightsData.find(x => x.Value === defaultUnits.WeightUnit);

            this.productSupplierGridData = [];
            this.productStockGridData = [];
            this.picturesData = [];
            this.setState({ moduleState: 'add', isEditMode: true, data, loading: true, defaultPriceGlobal, selectedWeight, selectedDimension, currentTabSelected: 0, groupGuaranteedWeight: 1 }, () => {
                setTimeout(() => {
                    this.setState({ loading: false });
                }, 300)
            });
        }
        else if (args.item.id === 'search') {
            this.props.history.push('/produktliste');
        }
        else if (args.item.id === 'edit') {
            this.onEditMode(true);
        }
        else if (args.item.id === 'copy') {
            const { data, currentTabSelected } = this.state;
            if (data.Id) {
                source.cancel();
                source = CancelToken.source();
                const result = await ProductService.CopyProduct(data.Id, source.token);

                if (!result.HasError) {
                    data.Id = result.Id;
                    data.ProductNo = '';
                    data.EAN = '';

                    const result2 = await ProductService.GetProduct(data.Id, source.token);
                    Object.assign(data, result2);
                    
                    this.setState({ data, isEditMode: true }, () => {
                        this.dialogOpen(Localization.General.SuccessHeader, Localization.Product.SuccessCopyProduct);
                        this.props.history.push(`/produktkort/edit/${result.Id}`);

                        switch(currentTabSelected) {
                            case 0:
                            case 1:
                            case 2:
                            case 4:
                            case 7:
                                this.reloadProductData();
                                break;
                            case 3:
                                this.refreshSupplierGrid(data.Id);
                                break;
                            case 5:
                                this.refreshStockGrid(data.Id);
                                break;
                            case 6:
                                this.refreshPicturesGrid();
                                break;
                        }

                        this.onEditMode(true);
                    });
                }
                else {
                    this.dialogOpen("Advarsel", result.ErrorMessage);
                }
            }
        }
        else if (args.item.id === 'update') {
            this.ProductToolbarRef.enableItems(4, false);
            source.cancel();
            source = CancelToken.source();
            let { data, moduleState, selectedDimensionId, selectedWeightId, currentTabSelected, transactionCategory, transactionTableDelLog, stockChangeLog, supplierChangeLog, redirectId } = this.state;
    
            const hasError = this.validate();

            if (hasError) {
                this.ProductToolbarRef.enableItems(4, true);
                return;
            }
            
            data.CustomerId = parseInt(this.UserCustomerId);

            if (!data.Id) {
                let result = await ProductService.AddInitialProduct({ CustomerId: parseInt(this.UserCustomerId) }, source.token);
                data.Id = result.Id;
            }
            
            if (this.stockGridIsEditing) {
                this.isUpdateTriggered = true;
                this.productStockGridRef.endEdit();
            }

            if (this.productSupplierGridRef) {
                this.productSupplierGridRef.endEdit();
            }

            let requests = [], changeOrder = 0, commands = [];
            // let supplierRequest = null, stockRequest = null, pictureRequest = null;
            let prodImgPayload = [], stockPayload = [], supplierPayload = [];

            for (let index = 0; index < transactionCategory.length; index++) {
                const trans = transactionCategory[index];
                let payload = {};
                switch(trans) {
                    case "Description":
                        changeOrder = changeOrder + 1;
                        payload = {
                            Id: data.Id,
                            ProductNo: data.ProductNo,
                            EAN: data.EAN,
                            Description1: data.Description1,
                            Description2: data.Description2,
                            SalesUnit: data.SalesUnit,
                            SalesUnitPerPcs: data.SalesUnitPerPcs,
                            CustomerId: data.CustomerId
                        };
                        commands.push({
                            ChangeOrder: changeOrder,
                            Type: 1,
                            Command: JSON.stringify(payload)
                        });
                        break;
                    case "Sizes":
                        data.DimensionUnit = selectedDimensionId;
                        data.WeightUnit = selectedWeightId;
                        changeOrder = changeOrder + 1;
                        payload = {
                            Id: data.Id,
                            Length: data.Length,
                            Height: data.Height,
                            Width: data.Width,
                            CustomVolume: data.CustomVolume,
                            Weight: data.Weight,
                            CustomerId: data.CustomerId,
                            DimensionUnit: data.DimensionUnit,
                            WeightUnit: data.WeightUnit
                        };
                        commands.push({
                            ChangeOrder: changeOrder,
                            Type: 2,
                            Command: JSON.stringify(payload)
                        });
                        break;
                    case "Group":
                        changeOrder = changeOrder + 1;
                        payload = {
                            Id: data.Id,
                            ProductGroupId: data.ProductGroupId,
                            CustomerId: data.CustomerId
                        };
                        commands.push({
                            ChangeOrder: changeOrder,
                            Type: 3,
                            Command: JSON.stringify(payload)
                        });
                        break;
                    case "Price":
                        if (data.Factor === 0) {
                            data.Factor = null;
                        }
    
                        if (data.ProfitPercentage === 0) {
                            data.ProfitPercentage = null;
                        }
                        else {
                            data.ProfitPercentage = data.ProfitPercentage / 100;
                        }
                        changeOrder = changeOrder + 1;
                        payload = {
                            Id: data.Id,
                            Factor: data.Factor,
                            ProfitPercentage: data.ProfitPercentage,
                            Profit: data.Profit,
                            SalesPrice: data.SalesPrice,
                            CustomerId: data.CustomerId
                        };
                        commands.push({
                            ChangeOrder: changeOrder,
                            Type: 4,
                            Command: JSON.stringify(payload)
                        });
                        break;
                    case "Supplier":
                        for (let index = 0; index < supplierChangeLog.length; index++) {
                            const element = supplierChangeLog[index];

                            supplierPayload.push({
                                Id: element.Id ? element.Id : 0,
                                ChangeOrder: element.ChangeOrder,
                                SupplierId: element.SupplierId ? element.SupplierId : element.Supplier.Id,
                                SupplierProductNo: element.SupplierProductNo,
                                SupplierEAN: element.SupplierEAN,
                                SupplierProductDesc: element.SupplierProductDesc,
                                DeliveryQty: element.DeliveryQty,
                                PurchasePrice: element.PurchasePrice,
                                Priority: element.Priority,
                                DeliveryDays: element.DeliveryDays,
                                ProductId: data.Id,
                                IsDelete: element.IsDelete
                            });
                        }
                        
                        // requests.push(ProductService.BulkSaveSupplier(supplierPayload, source.token));
                        break;
                    case "Stock":
                        for (let index = 0; index < stockChangeLog.length; index++) {
                            const element = stockChangeLog[index];
                            let location = element.Box && element.Room ?  {
                                Id: element.Room.Id,
                                BoxId: element.Box.Id,
                                RoomNoStart: element.Room.RoomNoStart,
                                RoomNoEnd: element.Room.RoomNoEnd
                            } : null;

                            stockPayload.push({
                                Id: element.Id ? element.Id : 0,
                                ChangeOrder: element.ChangeOrder,
                                ProductId: data.Id,
                                MinQty: element.MinQty,
                                MaxQty: element.MaxQty,
                                PurchaseQty: element.PurchaseQty,
                                StandardLocation: element.StandardLocation,
                                Location: location,
                                MachineId: element.MachineId,
                                IsDelete: element.IsDelete
                            });
                        }
                        
                        // requests.push(ProductService.BulkSaveStock(stockPayload, source.token));
                        break;
                    case "Picture":
                        for (let index = 0; index < transactionTableDelLog.Pictures.length; index++) {
                            const item = transactionTableDelLog.Pictures[index];
                            
                            prodImgPayload.push({
                                Id: item,
                                ChangeOrder: index + 1,
                                ProductId: data.Id,
                                IsDelete: true
                            });
                        }

                        for (let index = 0; index < data.ProductImages.length; index++) {
                            let item = data.ProductImages[index];
                            const payload = {
                                ImageKey: item.ImageKey,
                                IsPrimary: item.IsPrimary,
                                ProductId: data.Id,
                                Id: item.Id ? item.Id : 0,
                                ChangeOrder: prodImgPayload.length + 1,
                                IsDelete: false
                            };
                
                            prodImgPayload.push(payload);
                        }
                        
                        // requests.push(ProductService.ProductImageSave(prodImgPayload, source.token));
                        break;
                    case "Setting":
                        data.PurchaseTolerance = data.PurchaseTolerance / 100;
                        changeOrder = changeOrder + 1;
                        payload = {
                            Id: data.Id,
                            ActiveCaseNumber: data.ActiveCaseNumber,
                            Deactivated: data.Deactivated,
                            AlwaysInputQty: data.AlwaysInputQty,
                            AlwaysWeight: data.AlwaysWeight,
                            ChooseWhenProductDraw: data.ChooseWhenProductDraw,
                            PurchaseTolerance: data.PurchaseTolerance,
                            CustomerId: data.CustomerId
                        };
                        commands.push({
                            ChangeOrder: changeOrder,
                            Type: 5,
                            Command: JSON.stringify(payload)
                        });
                        break;
                }
            }

            if (commands.length > 0) {
                requests.push(ProductService.BulkSave(data.Id, commands, source.token));
            }
            
            if (requests.length > 0 || supplierPayload.length > 0 || stockPayload.length > 0 || prodImgPayload.length > 0) {
                await Promise.all(requests).then(async response => {
                    let errorList = [], tabWithErrors = [];
                    for (let index = 0; index < response.length; index++) {
                        const res = response[index];
                        for (let index = 0; index < res.length; index++) {
                            const element = res[index];

                            if (!element.Success) {
                                element.Errors.forEach(r => {
                                    if (!errorList.includes(r.Message)) {
                                        errorList.push(r.Message);

                                        if (TabFields.Description.some(x => x === r.PropertyName)) {
                                            tabWithErrors.push(0);
                                        }
                                        else if (TabFields.Sizes.some(x => x === r.PropertyName)) {
                                            tabWithErrors.push(1);
                                        }
                                        else if (TabFields.Price.some(x => x === r.PropertyName)) {
                                            tabWithErrors.push(4);
                                        }
                                        else if (TabFields.Setting.some(x => x === r.PropertyName)) {
                                            tabWithErrors.push(7);
                                        }
                                    }
                                });
                            }
                        }
                    }
                    
                    if (errorList.length <= 0) {
                        // Remove from categories so it wont be saved again
                        if (transactionCategory.includes("Description")) {
                            transactionCategory = transactionCategory.filter(x => x !== "Description");
                        }
                        if (transactionCategory.includes("Sizes")) {
                            transactionCategory = transactionCategory.filter(x => x !== "Sizes");
                        }
                        if (transactionCategory.includes("Group")) {
                            transactionCategory = transactionCategory.filter(x => x !== "Group");
                        }
                        if (transactionCategory.includes("Price")) {
                            transactionCategory = transactionCategory.filter(x => x !== "Price");
                        }
                        if (transactionCategory.includes("Setting")) {
                            transactionCategory = transactionCategory.filter(x => x !== "Setting");
                        }

                        this.setState({ transactionCategory });
                        return {
                            success: true
                        };
                    }
                    else {
                        // this.setState({ currentTabSelected: tabWithErrors[0] });
                        // this.dialogOpen("Advarsel", errorList.join('<br />'));
                        throw {
                            success: false,
                            selectTab: tabWithErrors[0],
                            message: errorList.join('<br />')
                        };
                    }
                }).then(async res => {
                    if (res.success) {
                        if (supplierPayload.length > 0) {
                            let errorList = []
                            const supplierResult = await ProductService.BulkSaveSupplier(supplierPayload, source.token);

                            for (let index = 0; index < supplierResult.length; index++) {
                                const response = supplierResult[index];
                                for (let index = 0; index < response.length; index++) {
                                    const element = response[index];

                                    if (!element.Success) {
                                        element.Errors.forEach(r => {
                                            if (!errorList.includes(r.Message)) {
                                                errorList.push(r.Message);
                                            }
                                        });
                                    }
                                }
                            }

                            if (errorList.length <= 0) {
                                // TODO: Do I need to update state here?
                                transactionTableDelLog.Suppliers = [];
                                transactionCategory = transactionCategory.filter(x => x !== "Supplier");
                                this.setState({ supplierChangeLog: [], transactionTableDelLog, transactionCategory });
                                return {
                                    success: true
                                }
                            }
                            else {
                                throw {
                                    success: false,
                                    selectTab: 3,
                                    message: errorList.join('<br />')
                                };
                            }
                        }
                        else {
                            return res;
                        }
                    }
                    else {
                        throw res;
                    }
                }).then(async res => {
                    if (res.success) {
                        if (stockPayload.length > 0) {
                            let errorList = [];
                            const stockResult = await ProductService.BulkSaveStock(stockPayload, source.token);

                            for (let index = 0; index < stockResult.length; index++) {
                                const response = stockResult[index];
                                for (let index = 0; index < response.length; index++) {
                                    const element = response[index];

                                    if (!element.Success) {
                                        element.Errors.forEach(r => {
                                            if (!errorList.includes(r.Message)) {
                                                errorList.push(r.Message);
                                            }
                                        });
                                    }
                                }
                            }

                            if (errorList.length <= 0) {
                                // TODO: Do I need to update state here?
                                transactionTableDelLog.Stock = [];
                                transactionCategory = transactionCategory.filter(x => x !== "Stock");
                                this.setState({ stockChangeLog: [], transactionTableDelLog, transactionCategory });
                                return {
                                    success: true
                                }
                            }
                            else {
                                throw {
                                    success: false,
                                    selectTab: 5,
                                    message: errorList.join('<br />')
                                };
                            }
                        }
                        else {
                            return res;
                        }
                    }
                    else {
                        throw res;
                    }
                }).then(async res => {
                    if (res.success) {
                        if (prodImgPayload.length > 0) {
                            let errorList = [];
                            const pictureResult = await ProductService.ProductImageSave(prodImgPayload, source.token);

                            for (let index = 0; index < pictureResult.length; index++) {
                                const response = pictureResult[index];
                                for (let index = 0; index < response.length; index++) {
                                    const element = response[index];

                                    if (!element.Success) {
                                        element.Errors.forEach(r => {
                                            if (!errorList.includes(r.Message)) {
                                                errorList.push(r.Message);
                                            }
                                        });
                                    }
                                }
                            }

                            if (errorList.length <= 0) {
                                // TODO: Do I need to update state here?
                                transactionTableDelLog.Pictures = [];
                                transactionCategory = transactionCategory.filter(x => x !== "Picture");
                                this.setState({ transactionTableDelLog, transactionTableDelLog, transactionCategory });
                                return {
                                    success: true
                                }
                            }
                            else {
                                throw {
                                    success: false,
                                    selectTab: 6,
                                    message: errorList.join('<br />')
                                };
                            }
                        }
                        else {
                            return res;
                        }
                    }
                    else {
                        throw res;
                    }
                }).then(async res => {
                    if (res.success) {
                        let actionText = moduleState === 'add' ? 'added' : 'updated';
                        moduleState = moduleState === 'add' && data.Id ? 'edit' : moduleState;
    
                        const { redirectPath } = this.state;
                        if (!redirectPath) {
                            this.dialogOpen(Localization.General.SuccessHeader, `${actionText === 'added' ? Localization.General.SuccessAdd.replace("{context}", "Produkt") : Localization.General.SuccessUpdate.replace("{context}", "Produkt")}`);
                        }
                        
                        const product = await ProductService.GetProduct(data.Id, source.token);
                        data = product;
                        data.ProductGroupId = product.ProductGroup.Id;
                        data.PurchaseTolerance = data.PurchaseTolerance ? data.PurchaseTolerance * 100 : data.ProductGroup ? data.ProductGroup.PurchaseTolerance * 100 : 0;
                        
                        const transactionStatus = await ProductService.GetUndoRedoStatus(data.Id, currentTabSelected, source.token);
                        let transaction = {};
                        if (!transactionStatus.HasError) {
                            transaction.hasUndo = transactionStatus.Undo;
                            transaction.hasRedo = transactionStatus.Redo;
                            transaction.undoTooltip = transactionStatus.UndoAction;
                            transaction.redoTooltip = transactionStatus.RedoAction;
                        }
    
                        transactionCategory = [];
                        // transactionTableDelLog.Suppliers = [];
                        // transactionTableDelLog.Stock = [];
                        // transactionTableDelLog.Pictures = [];
                        this.requireSupplierDataSourceRefresh = true;
                        this.requireStockDataSourceRefresh = true;
                        this.requirePicturesDataSourceRefresh = true;

                        switch(currentTabSelected) {
                            case 3:
                                this.requireSupplierDataSourceRefresh = false;
                                this.productSupplierGridData = product.ProductSuppliers;
                                this.productSupplierGridRef.dataSource = product.ProductSuppliers;
                                this.productSupplierGridRef.refresh();
                                break;
                            case 5:
                                this.requireStockDataSourceRefresh = false;
                                this.productStockGridData = product.ProductStorages;
                                this.productStockGridRef.dataSource = product.ProductStorages;
                                this.productStockGridRef.refresh();
                                break;
                            case 6:
                                this.requirePicturesDataSourceRefresh = false;
                                this.picturesData = product.ProductImages;
                                this.ProductPicturesGridRef.dataSource = product.ProductImages;
                                this.ProductPicturesGridRef.refresh();
                                break;
                        }
    
                        this.setState({ data, moduleState, isEditMode: false, hasUnsavedChanges: false, transactionCategory, transaction }, () => {
                            if (actionText === 'added') {
                                this.props.history.push(`/produktkort/edit/${data.Id}`);
                            }
                            const { redirectPath } = this.state;
                            if (redirectPath) {
                                this.props.history.push(redirectPath);
                            }
                            this.setState({ redirectPath: null });
                            this.toggleTabFields(false);
                        });
                    }
                }).catch(err => {
                    this.setState({ currentTabSelected: err.selectTab });
                    this.dialogOpen("Advarsel", err.message);
                });
                this.ProductToolbarRef.enableItems(4, true);
            }
            else {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Produkt"));

                transactionCategory = [];
                transactionTableDelLog.Suppliers = [];
                transactionTableDelLog.Stock = [];
                transactionTableDelLog.Pictures = [];

                this.setState({ isEditMode: false, hasUnsavedChanges: false, transactionCategory, transactionTableDelLog }, () => {
                    this.ProductToolbarRef.enableItems(4, true);
                    this.toggleTabFields(false);
                    if (redirectId) {
                        this.onProductToolbarClicked({ item: { id: redirectId } })
                    }
                });
            }
        }
        else if (args.item.id === 'cancel') {
            const { moduleState, data, currentTabSelected } = this.state;
            if (moduleState === 'add') {
                this.props.history.push('/produktliste');
            }
            this.ProductToolbarRef.enableItems(2, true);
            this.ProductToolbarRef.enableItems(4, false);
            this.ProductToolbarRef.enableItems(5, false);
            this.setState({ isEditMode: false, transactionCategory: [], hasUnsavedChanges: false }, () => {
                this.toggleTabFields(false);
                switch(currentTabSelected) {
                    case 0:
                    case 1:
                    case 2:
                    case 4:
                    case 7:
                        this.reloadProductData();
                        break;
                    case 3:
                        this.refreshSupplierGrid(data.Id);
                        break;
                    case 5:
                        this.refreshStockGrid(data.Id);
                        break;
                    case 6:
                        this.refreshPicturesGrid();
                        break;
                }
            });
        }
        else if (args.item.id === 'undo') {
            const { data, currentTabSelected } = this.state;
            let result =  null;
            this.onEditMode(true);
            switch (currentTabSelected) {
                case 0:
                case 1:
                case 2:
                case 4:
                case 7:
                    result = await ProductService.UndoProduct(data.Id);
                    break;
                case 3:
                    result = await ProductService.UndoProductSupplier(data.Id);
                    break;
                case 5:
                    result = await ProductService.UndoProductStorage(data.Id);
                    break;
                case 6:
                    result = await ProductService.UndoProductImage(data.Id);
                    break;
            }

            if (!result.HasError) {
                this.undoRedoOptions(result.Undo, result.Redo, result.UndoAction, result.RedoAction);
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Produkt"));

                await this.reloadProductData();
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
        else if (args.item.id === 'redo') {
            const { data, currentTabSelected } = this.state;
            let result =  null;
            this.onEditMode(true);
            switch (currentTabSelected) {
                case 0:
                case 1:
                case 2:
                case 4:
                case 7:
                    result = await ProductService.RedoProduct(data.Id);
                    break;
                case 3:
                    result = await ProductService.RedoProductSupplier(data.Id);
                    break;
                case 5:
                    result = await ProductService.RedoProductStorage(data.Id);
                    break;
                case 6:
                    result = await ProductService.RedoProductImage(data.Id);
                    break;
            }
            
            if (!result.HasError) {
                this.undoRedoOptions(result.Undo, result.Redo, result.UndoAction, result.RedoAction);
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Produkt"));

                await this.reloadProductData();
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
        else if (args.item.id === 'next' || args.item.id === 'prev') {
            const { data, hasUnsavedChanges, currentTabSelected } = this.state;
            const toProdId = args.item.id === 'next' ? data.NextId : data.PrevId;
            const path = `/produktkort/edit/${toProdId}`;
            if (hasUnsavedChanges) {
                this.setState({redirectId: args.item.id});
                this.NavigationPromptRef.showModal(path);
            }
            else {
                this.props.history.push(path);
                // if ([0, 1, 2, 4, 7].includes(currentTabSelected)) {
                //     data.Id = toProdId;
                // } else {
                    const result2 = await ProductService.GetProduct(toProdId, source.token);
                    Object.assign(data, result2);
                // }
                
                this.requireSupplierDataSourceRefresh = true;
                this.requireStockDataSourceRefresh = true;
                this.requirePicturesDataSourceRefresh = true;
                
                this.setState({ data, isEditMode: false, transactionCategory: [], hasUnsavedChanges: false, hasNext: data.NextId > 0, hasPrev: data.PrevId > 0, redirectId: '' }, async () => {
                    this.onEditMode(false);
                    switch(currentTabSelected) {
                        case 0:
                        case 1:
                        case 2:
                        case 4:
                        case 7:
                            this.reloadProductData();
                            break;
                        case 3:
                            this.refreshSupplierGrid(toProdId);
                            break;
                        case 5:
                            this.refreshStockGrid(toProdId);
                            break;
                        case 6:
                            this.refreshPicturesGrid();
                            break;
                    }

                    if (data && data.ProductImages) {
                        let primaryImage = data.ProductImages.find(img => img.IsPrimary);
                        if (!primaryImage && data.ProductImages.length > 0) {
                            primaryImage = data.ProductImages[0];
                        }
                        
                        if (primaryImage) {
                            var imageResult = await FileService.GetImage(primaryImage.ImageKey);
                            this.productImage.src = imageResult ? imageResult : productPlaceholder;
                        }
                    }
                });
            }
        }
    }

    onEditMode(isEdit){
        this.ProductToolbarRef.enableItems(2, !isEdit);
        this.ProductToolbarRef.enableItems(4, isEdit);
        this.ProductToolbarRef.enableItems(5, isEdit);
        this.setState({ isEditMode: isEdit }, () => {
            this.toggleTabFields(isEdit);
        });
        
    }
    
    // async initiateSave() {
    //     source.cancel();
    //     source = CancelToken.source();
    //     let { data, moduleState, selectedDimensionId, selectedWeightId, currentTabSelected } = this.state;
    //     let retValue = false;
    //     const hasError = this.validate();

    //     if (hasError) {
    //         return false;
    //     }
        
    //     data.CustomerId = parseInt(this.UserCustomerId);
    //     return await ProductService.AddUpdateProductDescription(data, source.token).then(result => {
    //         if (!result.HasError) {
    //             return result;
    //         }
    //         else {
    //             throw new Error(result.ErrorMessage);
    //         }
    //     }).then(async result => {
    //         data.Id = result.Id;
    //         data.DimensionUnit = selectedDimensionId;
    //         data.WeightUnit = selectedWeightId;
    //         return await ProductService.AddUpdateProductSizes(data, source.token);
    //     }).then(result => {
    //         if (!result.HasError) {
    //             return result;
    //         }
    //         else {
    //             throw new Error(result.ErrorMessage);
    //         }
    //     }).then(async result => {
    //         if (data.ProductGroupId) {
    //             return await ProductService.AddUpdateProductGroup(data, source.token);
    //         }
    //         else {
    //             return null;
    //         }
    //     }).then(result => {
    //         if (!result.HasError || result === null) {
    //             return result;
    //         }
    //         else {
    //             throw new Error(result.ErrorMessage);
    //         }
    //     }).then(async result => {
    //         if (data.Factor === 0) {
    //             data.Factor = null;
    //         }

    //         if (data.ProfitPercentage === 0) {
    //             data.ProfitPercentage = null;
    //         }
    //         else {
    //             data.ProfitPercentage = data.ProfitPercentage / 100;
    //         }
    //         return await ProductService.AddUpdateProductPrice(data, source.token);
    //     }).then(result => {
    //         if (!result.HasError) {
    //             return result;
    //         }
    //         else {
    //             throw new Error(result.ErrorMessage);
    //         }
    //     }).then(async result => {
    //         // data.AlwaysInputQty = this.AlwaysInputQtyRef.checked;
    //         // data.AlwaysWeight = this.AlwaysWeightRef.checked;
    //         // data.ChooseWhenProductDraw = this.ChooseWhenProductDrawRef.checked;
    //         return await ProductService.AddUpdateProductSettings(data, source.token);
    //     }).then(result => {
    //         if (!result.HasError) {
    //             let actionText = moduleState === 'add' ? 'added' : 'updated';
    //             this.dialogOpen(Localization.General.SuccessHeader, `${actionText === 'added' ? Localization.General.SuccessAdd.replace("{context}", "Produkt") : Localization.General.SuccessUpdate.replace("{context}", "Produkt")}`);
    //             this.setState({ hasUnsavedChanges: false });
    //             return true;
    //         }
    //         else {
    //             throw new Error(result.ErrorMessage);
    //         }
    //     }).catch(err => {
    //         this.dialogOpen("Advarsel", err);
    //         return false;
    //     });
    // }

    async onTabSelected(index) {
        const { breadcrumbs, moduleState, isEditMode, data, transaction } = this.state;
        if (breadcrumbs.length > 1) {
            breadcrumbs.pop();
        }
        breadcrumbs.push(this.tabHeader[index].text);
        
        let isFieldsEnabled = false;

        if (moduleState === 'add') {
            isFieldsEnabled = true;
        }
        else if (moduleState === 'edit') {
            if (isEditMode) {
                isFieldsEnabled = true;
            }
        }

        if (data.Id) {
            ProductService.GetUndoRedoStatus(data.Id, index, source.token).then(response => {
                if (!response.HasError) {
                    transaction.hasUndo = response.Undo;
                    transaction.hasRedo = response.Redo;
                    transaction.undoTooltip = response.UndoAction;
                    transaction.redoTooltip = response.RedoAction;
                }
                else {
                    transaction.hasUndo = false;
                    transaction.hasRedo = false;
                    transaction.undoTooltip = '';
                    transaction.redoTooltip = '';
                }

                this.setState({ transaction });
            });
        }

        if (this.stockGridIsEditing) {
            this.isTabUpdateTriggered = true;
            this.tabUpdateIndex = index;
            this.productStockGridRef.endEdit();
            return false;
        }

        if (this.productSupplierGridRef) {
            this.productSupplierGridRef.endEdit();
        }

        if (this.productStockGridRef) {
            this.productStockGridRef.endEdit();
        }

        sessionStorage.setItem("PRODUCT_TAB_DEFAULT", `${this.tabHeader[index].text}`);
        this.setState({ breadcrumbs, currentTabSelected: index, transaction }, () => {
            switch(index) {
                case 0:
                    this.toggleProductDescriptionFields(isFieldsEnabled);
                    break;
                case 1:
                    this.toggleProductSizeFields(isFieldsEnabled);
                    break;
                case 2:
                    this.toggleProductGroupFields(isFieldsEnabled);
                    if (data.ProductGroup && Object.keys(data.ProductGroup).length > 0) {
                        if (data.ProductGroup.Id !== data.ProductGroupId) {
                            this.primaryProductGroupRef.checkedNodes = [data.ProductGroupId.toString()];
                        }
                        else if (data.ProductGroup && data.ProductGroup.Id) {
                            this.primaryProductGroupRef.checkedNodes = [data.ProductGroup.Id.toString()];
                        }
                    }
                    else {
                        if (data.ProductGroupId) {
                            this.primaryProductGroupRef.checkedNodes = [data.ProductGroupId.toString()];
                        }
                    }
                    break;
                case 3: //supplier
                    if (this.requireSupplierDataSourceRefresh) {
                        this.requireSupplierDataSourceRefresh = false;
                        this.productSupplierGridData = data.ProductSuppliers;
                        this.productSupplierGridRef.dataSource = data.ProductSuppliers;
                        this.productSupplierGridRef.refresh();
                    }

                    setTimeout(() => {
                        if (this.productSupplierGridRef) {
                            this.productSupplierGridRef.toolbarModule.enableItems(['add', 'open'], isFieldsEnabled);
                            let editButton = document.querySelector('.product-supplier-wrapper .e-grid .e-toolbar-item[title="Redigere"]');
                            let deleteButton = document.querySelector('.product-supplier-wrapper .e-grid .e-toolbar-item[title="Slette"]');
                            if (editButton) {
                                if (!isFieldsEnabled) {
                                    editButton.classList.add('e-overlay');
                                }
                                else {
                                    editButton.classList.remove('e-overlay');
                                }
                                editButton.querySelector('button').disabled = !isFieldsEnabled;
                            }

                            if (deleteButton) {
                                if (!isFieldsEnabled) {
                                    deleteButton.classList.add('e-overlay');
                                }
                                else {
                                    deleteButton.classList.remove('e-overlay');
                                }
                                deleteButton.querySelector('button').disabled = !isFieldsEnabled;
                            }
                        }
                    }, 250);

                    if (isFieldsEnabled) {
                        this.productSupplierGridRef.element.querySelector('.e-gridcontent').classList.remove('c-disablegrid');
                        let wrapper = document.getElementsByClassName('product-supplier-wrapper')[0];
                        if (wrapper) {
                            wrapper.classList.remove('c-disablegrid-wrapper');
                        }
                    }
                    else {
                        this.productSupplierGridRef.element.querySelector('.e-gridcontent').classList.add('c-disablegrid');
                        let wrapper = document.getElementsByClassName('product-supplier-wrapper')[0];
                        if (wrapper) {
                            wrapper.classList.remove('c-disablegrid-wrapper');
                        }
                    }
                    break;
                case 4:
                    this.toggleProductPriceFields(isFieldsEnabled);
                    break;
                case 5: //stock
                    if (this.requireStockDataSourceRefresh) {
                        this.requireStockDataSourceRefresh = false;
                        this.productStockGridData = data.ProductStorages;
                        this.productStockGridRef.dataSource = data.ProductStorages;
                        this.productStockGridRef.refresh();
                    }

                    setTimeout(() => {
                        if (this.productStockGridRef) {
                            this.productStockGridRef.toolbarModule.enableItems(['add'], isFieldsEnabled);
                            let editButton = document.querySelector('.product-stock-wrapper .e-grid .e-toolbar-item[title="Redigere"]');
                            let deleteButton = document.querySelector('.product-stock-wrapper .e-grid .e-toolbar-item[title="Slette"]');
                            if (editButton) {
                                if (!isFieldsEnabled) {
                                    editButton.classList.add('e-overlay');
                                }
                                else {
                                    editButton.classList.remove('e-overlay');
                                }
                            }

                            if (deleteButton) {
                                if (!isFieldsEnabled) {
                                    deleteButton.classList.add('e-overlay');
                                }
                                else {
                                    deleteButton.classList.remove('e-overlay');
                                }
                            }
                        }
                    }, 250);

                    if (isFieldsEnabled) {
                        this.productStockGridRef.element.querySelector('.e-gridcontent').classList.remove('c-disablegrid');
                        let wrapper = document.getElementsByClassName('product-stock-wrapper')[0];
                        if (wrapper) {
                            wrapper.classList.remove('c-disablegrid-wrapper');
                        }
                    }
                    else {
                        this.productStockGridRef.element.querySelector('.e-gridcontent').classList.add('c-disablegrid');
                        let wrapper = document.getElementsByClassName('product-stock-wrapper')[0];
                        if (wrapper) {
                            wrapper.classList.remove('c-disablegrid-wrapper');
                        }
                    }
                    break;
                case 6: //pictures
                    if (this.requirePicturesDataSourceRefresh) {
                        this.requirePicturesDataSourceRefresh = false;
                        this.picturesData = data.ProductImages;
                        this.ProductPicturesGridRef.dataSource = data.ProductImages;
                        this.ProductPicturesGridRef.refresh();
                    }
                    
                    setTimeout(() => {
                        if (this.ProductPicturesGridRef) {
                            this.ProductPicturesGridRef.toolbarModule.enableItems(['linkImage'], isFieldsEnabled);
                            
                            let deleteButton = document.querySelector('.product-image-wrapper .e-grid .e-toolbar-item[title="Slette"]');

                            if (deleteButton) {
                                if (!isFieldsEnabled) {
                                    deleteButton.classList.add('e-overlay');
                                }
                                else {
                                    deleteButton.classList.remove('e-overlay');
                                }
                            }
                        }
                    }, 250);

                    if (isFieldsEnabled) {
                        this.ProductPicturesGridRef.element.querySelector('.e-gridcontent').classList.remove('c-disablegrid');
                        let wrapper = document.getElementsByClassName('product-image-wrapper')[0];
                        if (wrapper) {
                            wrapper.classList.remove('c-disablegrid-wrapper');
                        }
                    }
                    else {
                        this.ProductPicturesGridRef.element.querySelector('.e-gridcontent').classList.add('c-disablegrid');
                        let wrapper = document.getElementsByClassName('product-image-wrapper')[0];
                        if (wrapper) {
                            wrapper.classList.remove('c-disablegrid-wrapper');
                        }
                    }
                    break;
                case 7:
                    this.toggleGeneralSettingsTab(isFieldsEnabled, moduleState);
                    break;
                default:
                    break;
            }
            
            this.ProductToolbarRef.enableItems(6, false);
            this.ProductToolbarRef.enableItems(7, false);
        });
    }

    pictureNodeCheck(args) {
        let checkedNode = [args.node];
        if (args.event.target.classList.contains('e-fullrow') || args.event.key == "Enter") {
            let nodeDetails = this.fileImagesRef.getNode(args.node);
            
            let imageTypes = ['png', 'jpg', 'jpeg'];
            let s = nodeDetails.text.split('.');
            if (imageTypes.includes(s[s.length - 1])) {
                if (nodeDetails.isChecked == 'true') {
                    this.fileImagesRef.uncheckAll(checkedNode);
                }
                else {
                    this.fileImagesRef.checkAll(checkedNode);
                }
            }
        }
    }

    render() {
        let { loading, breadcrumbs, data, moduleState, dialogOpts, isEditMode, transaction, currentTabSelected, redirect, hasUnsavedChanges, supplierDialogProps, hasNext, hasPrev } = this.state;

        if (loading) { 
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        let updateToolDisabled = true, cancelToolDisabled = true, editToolDisabled = true, isProductPriceDisabled = true;
        let isEditVisible = false, isSaveVisible = false, isCancelVisible = false;
        
        if(super.hasMenuItemTabFunction("Produktkort", this.tabHeader[currentTabSelected].text, "Redigere")) {
            isEditVisible = true;
        }
        
        if (moduleState === 'edit') {
            if (isEditVisible) {
                isSaveVisible = true;
                isCancelVisible = true;
            }

            updateToolDisabled = isEditMode ? false : true;
            editToolDisabled = isEditMode ? true : false;
        }
        else {
            isSaveVisible = true;
            isCancelVisible = true;

            updateToolDisabled = false;
        }

        cancelToolDisabled = isEditMode ? false : true;

        // if (data.ProductGroup && data.ProductGroup.Id) {
        //     if (!super.hasMenuItemTabAccess("Produktkort", "Pris")) {
        //         isProductPriceDisabled = true;
        //     }
        //     else {
        //         isProductPriceDisabled = false;
        //     }
        // }

        let customMessage = '', customHeader = '', redirectTab = [], moduleKey = "product-add";
        if (moduleState === 'add') {
            let errorList = [];
            
            if (!data.ProductNo) {
                errorList.push('<li>Vare Nr.</li>');
                redirectTab.push(0);
            }
    
            // if (!data.EAN) {
            //     errorList.push('<li>Ean Nr.</li>');
            //     if (!redirectTab.includes(0)) redirectTab.push(0);
            // }
    
            if (!data.Description1) {
                errorList.push('<li>Beskrivelse</li>');
                if (!redirectTab.includes(0)) redirectTab.push(0);
            }

            if (!data.ProductGroupId) {
                errorList.push('<li>Produktgruppe</li>');
                redirectTab.push(2);
            }

            if (errorList.length > 0) {
                hasUnsavedChanges = true;
                customHeader = 'Du er i gang med at oprette et produkt';
                customMessage = 'Advarsel!<br>Du mangler at udfylde et eller flere felter for at færdiggøre oprettelsen <br>af produktet og kan derfor ikke gemme dette produkt før nedenstående <br>oplysninger er udfyldt:';
                customMessage += `<ul>${errorList.join('')}</ul>`;
                customMessage += `<br><br>Tryk Ok for at vende tilbage for at udfylde felter markeret med rødt<br><br>Tryk Annuller for at afslutte oprettelsen af produktet uden at gemme`;
            }
            else {
                moduleKey = ''
            }
        }
        else {
            moduleKey = ''
        }

        return (
            <div className='content-pane-wrapper product-card-wrapper'>
                <NavigationPrompt
                    ref={ref => this.NavigationPromptRef = ref}
                    when={hasUnsavedChanges}
                    moduleKey={moduleKey}
                    navigate={path => this.props.history.push(path)}
                    initiateSave={() => this.onProductToolbarClicked({ item: { id: "update" } })}
                    customMessage={customMessage}
                    customHeader={customHeader}
                    navigateToField={() => {
                        let tab = redirectTab[0] || 0;
                        this.setState({ currentTabSelected: tab }, () => {
                            switch(tab) {
                                case 0:
                                    if (!data.ProductNo) {
                                        this.ProductNoRef.classList.add('e-error');
                                    }
                            
                                    // if (!data.EAN) {
                                    //     this.EANRef.classList.add('e-error');
                                    // }
                            
                                    if (!data.Description1) {
                                        this.Description1Ref.classList.add('e-error');
                                    }
                                    break;
                                case 2:
                                    break;
                            }

                            this.onProductToolbarClicked({ item: { id: "update" } })
                        });
                    }}
                    shouldBlockNavigation={location => {
                        if (hasUnsavedChanges) {
                            this.setState({ redirectPath: location.pathname });
                            return true;
                        }

                        return false;
                    }}
                />
                <div>
                    <Breadcrumb breadcrumbs={breadcrumbs} />
                </div>
                <div className="module-header">
                    <h1>{this.pageName}</h1>
                </div>
                <div className="product-card-toolbar-wrapper">
                    <ToolbarComponent ref={ref => this.ProductToolbarRef = ref} clicked={this.onProductToolbarClicked}>
                        <ItemsDirective>
                            <ItemDirective id="add" prefixIcon='tb-icons c-icon-add-black' text="Ny" tooltipText='Ny' disabled={moduleState === 'add' ? true: false} visible={super.hasMenuItemTabFunction("Produktkort", this.tabHeader[currentTabSelected].text, "Ny")}/>
                            <ItemDirective id="search" prefixIcon='tb-icons c-icon-search-list' text="Søgeliste" tooltipText='Søgeliste' visible={super.hasMenuItem("Produktliste")} />
                            <ItemDirective id="edit" prefixIcon='tb-icons c-icon-edit' text="Redigere" tooltipText='Redigere' disabled={editToolDisabled} visible={isEditVisible} />
                            <ItemDirective id="copy" prefixIcon='tb-icons c-icon-copy' text="Kopi" tooltipText='Kopi' disabled={moduleState === 'add' ? true: false} visible={super.hasMenuItemTabFunction("Produktkort", this.tabHeader[currentTabSelected].text, "Kopi")} />
                            <ItemDirective id="update" prefixIcon='tb-icons c-icon-update' text='Gem'tooltipText='Gem' disabled={updateToolDisabled} visible={isSaveVisible} />
                            <ItemDirective id="cancel" prefixIcon='tb-icons c-icon-cancel' text="Annuller" tooltipText='Annuller' disabled={cancelToolDisabled} visible={isCancelVisible} />
                            <ItemDirective id="undo" prefixIcon='tb-icons c-icon-undo' text="Fortryd" tooltipText={transaction.undoTooltip !== '' ? transaction.undoTooltip : 'Fortryd'} disabled={!transaction.hasUndo} visible={super.hasMenuItemTabFunction("Produktkort", this.tabHeader[currentTabSelected].text, ["Ny","Redigere","Slette"])} />
                            <ItemDirective id="redo" prefixIcon='tb-icons c-icon-redo' text="Gentag" tooltipText={transaction.redoTooltip !== '' ? transaction.redoTooltip :'Gentag'} disabled={!transaction.hasRedo} visible={super.hasMenuItemTabFunction("Produktkort", this.tabHeader[currentTabSelected].text, ["Ny","Redigere","Slette"])} />
                            <ItemDirective id="prev" prefixIcon='tb-icons c-icon-chevron-arrow-left' text={Localization.General.Previous} tooltipText={Localization.General.Previous} disabled={!hasPrev} />
                            <ItemDirective id="next" prefixIcon='tb-icons c-icon-chevron-arrow-right' text={Localization.General.Next} tooltipText={Localization.General.Next} disabled={!hasNext} />
                        </ItemsDirective>
                    </ToolbarComponent>
                </div>
                {
                    data.Id &&
                    <div className="product-card-header">
                        <div className="product-card-image">
                            <img ref={ref => this.productImage = ref} src={productPlaceholder} alt="product" />
                        </div>
                        <div className="product-card-info">
                            <div className="product-card-header-wrapper">
                                <div className="product-header-label">Vare Nr.:</div>
                                <div className="product-header-text">{data.ProductNo}</div>
                            </div>
                            <div className="product-card-header-wrapper">
                                <div className="product-header-label">Beskrivelse:</div>
                                <div className="product-header-text">{data.Description1}</div>
                            </div>
                        </div>
                    </div>
                }
                <div className='control-section tab-control'>
                    <Tabs className="c-tab" selectedIndex={currentTabSelected} onSelect={index => this.onTabSelected(index)} disabledTabClassName="c-tabs__tab--disabled">
                        <TabList className="c-tab__list">
                            <Tab className={`c-tabs__tab ${super.hasMenuItemTabAccess("Produktkort", "Beskrivelse") ? '' : 'hidden'}`} disabled={!super.hasMenuItemTabAccess("Produktkort", "Beskrivelse")} selectedClassName="c-tabs__tab--selected">{this.tabHeader[0].text}</Tab>
                            <Tab className={`c-tabs__tab ${super.hasMenuItemTabAccess("Produktkort", "Størrelse") ? '' : 'hidden'}`} disabled={!super.hasMenuItemTabAccess("Produktkort", "Størrelse")} selectedClassName="c-tabs__tab--selected">{this.tabHeader[1].text}</Tab>
                            <Tab className={`c-tabs__tab ${super.hasMenuItemTabAccess("Produktkort", "Produkt grupper") ? '' : 'hidden'}`} disabled={!super.hasMenuItemTabAccess("Produktkort", "Produkt grupper")} selectedClassName="c-tabs__tab--selected">{this.tabHeader[2].text}</Tab>
                            <Tab className={`c-tabs__tab ${super.hasMenuItemTabAccess("Produktkort", "Leverandør") ? '' : 'hidden'}`} disabled={!super.hasMenuItemTabAccess("Produktkort", "Leverandør")} selectedClassName="c-tabs__tab--selected">{this.tabHeader[3].text}</Tab>
                            <Tab className={`c-tabs__tab ${super.hasMenuItemTabAccess("Produktkort", "Pris") ? '' : 'hidden'}`} selectedClassName="c-tabs__tab--selected" disabled={!super.hasMenuItemTabAccess("Produktkort", "Pris")}>{this.tabHeader[4].text}</Tab>
                            <Tab className={`c-tabs__tab ${super.hasMenuItemTabAccess("Produktkort", "Lager") ? '' : 'hidden'}`} disabled={!super.hasMenuItemTabAccess("Produktkort", "Lager")} selectedClassName="c-tabs__tab--selected">{this.tabHeader[5].text}</Tab>
                            <Tab className={`c-tabs__tab ${super.hasMenuItemTabAccess("Produktkort", "Billeder") ? '' : 'hidden'}`} disabled={!super.hasMenuItemTabAccess("Produktkort", "Billeder")} selectedClassName="c-tabs__tab--selected">{this.tabHeader[6].text}</Tab>
                            <Tab className={`c-tabs__tab ${super.hasMenuItemTabAccess("Produktkort", "Generelle indstillinger") ? '' : 'hidden'}`} disabled={!super.hasMenuItemTabAccess("Produktkort", "Generelle indstillinger")} selectedClassName="c-tabs__tab--selected">{this.tabHeader[7].text}</Tab>
                        </TabList>
                        <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">{this.productDescriptionTab()}</TabPanel>
                        <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">{this.productSizeTab()}</TabPanel>
                        <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">{this.productGroupTab()}</TabPanel>
                        <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">{this.productSupplierTab()}</TabPanel>
                        <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">{this.productPriceTab()}</TabPanel>
                        <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">{this.productStockTab()}</TabPanel>
                        <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">{this.productPicturesTab()}</TabPanel>
                        <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">{this.productGeneralSettingsTab()}</TabPanel>
                    </Tabs>
                </div>
                <DialogComponent id="product-notification-dialog" isModal={true} buttons={this.buttons} width='auto' ref={dialog => this.notificationDialog = dialog} 
                    target='body' visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true}/>
                <DialogComponent id="product-pictures-dialog" isModal={true} visible={false} ref={dialog => this.fileArchiveDialog = dialog} width="686px"
                    target='body' animationSettings={this.animationSettings} allowDragging={true} enableResize={true} header="Tildel Billede til produkt">
                    {/* <h3>Tildel Billede til produkt</h3> */}
                    <div style={{marginBottom: 10}}></div>
                    <small>Klik på + for tilhørende funktioner</small>
                    <div className="dialog-content">
                        <TreeViewComponent ref={ref => this.fileImagesRef = ref} id="product-picture-treeview" fields={this.productPicturesFields} showCheckBox={true} autoCheck={false}
                            nodeClicked={this.pictureNodeCheck} />
                    </div>
                    <div className="dialog-footer">
                        <button className="e-control e-btn e-lib" onClick={this.dialogClose}>Annuller</button>
                        <button className="e-control e-btn e-lib e-primary" onClick={this.onFileImageSelected}>Gem</button>
                    </div>
                </DialogComponent>
                <DialogComponent id="add-supplier-dialog" isModal={true} header="Tilføj Leverandør" width='auto' allowTextWrap='true' ref={dialog => this.addSupplierDialog = dialog} target='body' 
                    visible={false} showCloseIcon={true} animationSettings={this.animationSettings} allowDragging={true} enableResize={true} >
                    <div className="dialog-content">
                        <GridComponent ref={ref => this.addSuppliersGridRef = ref } allowPaging={true} dataSource={this.filteredSuppliersData} allowSorting={true} pageSettings={this.pageSettings} className="suppliers-list-grid" allowTextWrap='true'
                            selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both" toolbarClick={this.clickHandler.bind(this)}>
                            <ColumnsDirective>
                                <ColumnDirective field={"Id"} headerText={"Leverandør #"} width='100' allowFiltering={false} /> 
                                <ColumnDirective field={"Company"} headerText={"Leverandør"} width='100' allowFiltering={false} /> 
                                <ColumnDirective field={"DeliveryTimeInDays"} headerText={"Leveringstid i dage"} width='100' allowFiltering={false} /> 
                            </ColumnsDirective>
                            <Inject services={[Page, Sort, Filter, Selection, Toolbar, Edit, Search]} />
                        </GridComponent>
                    </div>
                    <div className="dialog-footer">
                        <button className="e-control e-btn e-lib" onClick={() => this.addSupplierDialog.hide()}>Annuller</button>
                        <button className="e-control e-btn e-lib e-primary" onClick={this.onSupplierSaveSelected}>Gem</button>
                    </div>
                </DialogComponent>
                <DialogComponent id="add-stock-dialog" isModal={true} width='auto' ref={dialog => this.stockDialogRef = dialog} target='body' visible={false} animationSettings={this.animationSettings} width="550px" 
                    allowDragging={true} enableResize={true} header={Localization.Product.SelectDepartment}>
                    <div className="dialog-content">
                        <div style={{marginBottom: 10}}></div>
                        <TreeViewComponent ref={ref => this.departmentSelectionTreeRef = ref} fields={this.departmentTreeFields} cssClass="c-remove-parent-checkbox" nodeChecked={this.onDepartmentChecked} 
                            nodeClicked={this.onMachineNodeClicked} showCheckBox={true} />
                    </div>
                    <div className="dialog-footer">
                        <button className="e-control e-btn e-lib" onClick={() => {
                            this.departmentSelectionTreeRef.uncheckAll();
                            this.stockDialogRef.hide();
                        }}>Annuller</button>
                        <button className="e-control e-btn e-lib e-primary" onClick={this.onStockSaveSelected}>Gem</button>
                    </div>
                </DialogComponent>
                <DialogComponent ref={dialog => this.supplierDialogRef = dialog} id="product-supplier-dialog" target="body" visible={false}
                 showCloseIcon={true} close={this.onSupplierDialogClose} animationSettings={this.animationSettings} allowDragging={true} enableResize={true} isModal={true}>
                    {
                        supplierDialogProps.visible && <ProductSupplier supplierId={supplierDialogProps.Id} permissions={this.supplierPermission} onSearchClick={this.onProductSupplierSearchClick} onClose={this.onSupplierDialogClose}
                            changeAddEditState={this.onSupplierChangeAddEditState} />
                    }
                </DialogComponent>
            </div>
        )
    }
}

export default Product;