import React, { Component } from 'react';
import { ToolbarComponent, ItemsDirective, ItemDirective, TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import Axios from 'axios';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { withRouter, Redirect } from 'react-router-dom';

import BusinessCategoryService from '../../../services/Customer/BusinessCategoryService';

import Loading from '../../../components/Loading';
import Localization from '../../../utilities/Localization';
import NavigationPrompt from '../../../components/NavigationPrompt';
import Card from '../../../components/Card';
import MessageHelper from '../../../utilities/MessageHelper';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class BusinessCategory extends Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            isEditMode: false,
            hasUnsavedChanges: false,
            redirect: {
                to: '',
                isRedirect: false
            },
            businessCategories: [],
            tempBusinessCategoryList: [],
            UndoRedoStatus : {
                hasUndo: false,
                hasRedo: false,
                undoTooltip : '',
                redoTooltip: ''
            }
        };

        this.treeData = [];

        this.treeFields = { 
            dataSource: this.treeData, id: 'Id', text: 'Name', child: 'SubCategories', htmlAttributes: 'hasAttributes'
        };

        this.tempIndex = 1000;
        this.onBranchCategoryToolbarClicked = this.onBranchCategoryToolbarClicked.bind(this);
        this.dialogOpen = this.dialogOpen.bind(this);
        this.nodeEdited = this.nodeEdited.bind(this);
        this.nodeEditing = this.nodeEditing.bind(this);
        this.clearTreeSelection = this.clearTreeSelection.bind(this);
        this.onNodeClicked = this.onNodeClicked.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.buttons = [
            {
                // Click the footer buttons to hide the Dialog
                click: () => {
                    this.bCNotificationDialog.hide();
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    //Enables the primary button
                    isPrimary: true,
                    content: 'Ok'
                }
            }
        ];

        this.deleteConfirmationButtons = [
            {
                click: () => {
                    let { tempBusinessCategoryList, hasUnsavedChanges } = this.state;
                    if (tempBusinessCategoryList.some(x => x.IsDelete)) {
                        tempBusinessCategoryList = tempBusinessCategoryList.filter(x => x.IsDelete);
                    }

                    if (tempBusinessCategoryList.length <= 0) {
                        hasUnsavedChanges = false;
                    }

                    this.setState({ tempBusinessCategoryList, hasUnsavedChanges });
                    this.deleteConfirmationDialog.hide();
                },
                buttonModel: {
                    content: 'Annuller'
                }
            },
            {
                click: () => {
                    this.confirmDelete();
                },
                buttonModel: {
                    isPrimary: true,
                    content: 'Slet'
                }
            }
        ];
        /* End Dialog options  */
    }

    async componentDidMount() {
        source.cancel();
        source = CancelToken.source();

        let result = await BusinessCategoryService.GetList(source.token);
        let { tempIdIndex } = this.state;
        
        // result.Data.forEach(res => {
        //     tempIdIndex = tempIdIndex + 1;
        //     res.SourceId = res.Id;
        //     res.Id = tempIdIndex;

        //     if (res.SubCategories.length > 0) {
        //         res.SubCategories = buildTreeId(tempIdIndex, res.SubCategories);
        //     }
        // });
        this.treeData = result.Data;
        this.treeFields.dataSource = result.Data;

        this.setState({ loading: false, businessCategories: result.Data, tempIdIndex }, () => {
            this.branchCategoryToolbarRef.enableItems(0, true);
            this.branchCategoryToolbarRef.enableItems(1, true);
            this.branchCategoryToolbarRef.enableItems(2, false);
            this.branchCategoryToolbarRef.enableItems(3, false);
        });
    }

    async confirmDelete() {
        let { tempBusinessCategoryList,UndoRedoStatus } = this.state;
        let payload = [];

        if (tempBusinessCategoryList[0].TempId) {
            this.branchCategoryTreeRef.removeNodes([tempBusinessCategoryList[0].TempId.toString()]);
            tempBusinessCategoryList = tempBusinessCategoryList.filter(x => x.TempId !== tempBusinessCategoryList[0].TempId)
            this.setState({ tempBusinessCategoryList }, () => {
                this.deleteConfirmationDialog.hide();
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessDelete.replace("{context}", "Branche kategori"));
            });
            return;
        }

        for (let index = 0; index < tempBusinessCategoryList.length; index++) {
            const element = tempBusinessCategoryList[index];
            if (element.IsDelete) {
                payload.push({ 
                    Id: element.Id === element.TempId ? 0 : element.Id,
                    TempId: element.TempId,
                    Name: element.Name,
                    ParentId: element.ParentId ? element.ParentId : 0,
                    ParentTempId: element.ParentTempId ? element.ParentTempId : 0, 
                    Order: element.Order,
                    IsDelete: element.IsDelete,
                    ChangeOrder: index + 1
                });
            }
        }

        if (payload.length > 0) {
            const result = await BusinessCategoryService.Bulk(payload, source.token);
            
            this.deleteConfirmationDialog.hide();
    
            if (!result.HasError) {
                this.treeData = result.Data;
                this.branchCategoryTreeRef.fields.dataSource = result.Data;
                this.branchCategoryTreeRef.refresh();
                
                UndoRedoStatus.hasUndo = result.Undo;
                UndoRedoStatus.hasRedo = result.Redo;
                UndoRedoStatus.undoTooltip = result.UndoAction;
                UndoRedoStatus.redoTooltip = result.RedoAction;
    
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessDelete.replace("{context}", "Branche kategori"));
                this.setState({ isEditMode: false, tempBusinessCategoryList: [], businessCategories: result.Data, UndoRedoStatus });
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
    }

    async onBranchCategoryToolbarClicked(args) {
        if (args.item.id === 'add') {
            let { tempIdIndex, tempBusinessCategoryList, businessCategories } = this.state;
            let parentId = this.branchCategoryTreeRef.selectedNodes ? this.branchCategoryTreeRef.selectedNodes[0] : null;
            let order = 0, tempData = {};
            
            let nodeId = `${this.tempIndex}`;

            if (parentId) {
                let parentNode = {};
                for (let index = 0; index < businessCategories.length; index++) {
                    const element = businessCategories[index];
                    let pn = this.searchTree(element, parseInt(parentId));

                    if (pn) {
                        Object.assign(parentNode, pn);
                        break;
                    }
                }

                tempData = {
                    TempId: this.tempIndex,
                    Id: 0,
                    Name: 'Ny kategori',
                    ParentId: Object.keys(parentNode).length > 0 ? parseInt(parentId) : null,
                    ParentTempId: Object.keys(parentNode).length <= 0 ? parseInt(parentId) : null,
                    Order: parentNode.SubCategories ? parentNode.SubCategories.length + 1 : 1,
                    IsDelete: false,
                    SubCategories: []
                };
            }
            else {
                order = businessCategories.length + 1;
                tempData = {
                    TempId: this.tempIndex,
                    Id: 0,
                    Name: 'Ny kategori',
                    ParentId: parentId ? parseInt(parentId) : null,
                    ParentTempId: null,
                    Order: order,
                    IsDelete: false,
                    SubCategories: []
                };
            }

            this.tempIndex++;

            let item = { Id: nodeId, Name: "Ny kategori" };
            this.branchCategoryTreeRef.addNodes([item], parentId, null);

            this.treeData.push(item);
            tempBusinessCategoryList.push(tempData);

            this.setState({ isEditMode: true, tempBusinessCategoryList, tempIdIndex, hasUnsavedChanges: true }, () => {
                setTimeout(() => {
                    this.branchCategoryTreeRef.beginEdit(nodeId);
                }, 250);
                
                this.branchCategoryToolbarRef.enableItems(1, false);
                this.branchCategoryToolbarRef.enableItems(2, true);
                this.branchCategoryToolbarRef.enableItems(3, true);
            });
        }
        else if (args.item.id === 'edit') {
            this.branchCategoryToolbarRef.enableItems(1, false);
            this.branchCategoryToolbarRef.enableItems(2, true);
            this.branchCategoryToolbarRef.enableItems(3, true);
            
            this.setState({ isEditMode: true });
        }
        else if (args.item.id === 'update') {
            let { tempBusinessCategoryList, UndoRedoStatus } = this.state;

            if (tempBusinessCategoryList.length <= 0) {
                this.dialogOpen(Localization.General.NoteHeader, Localization.General.NoChangesMade);
                this.setState({ isEditMode: false });
                return;
            }

            let payload = [], errorList = [];

            for (let index = 0; index < tempBusinessCategoryList.length; index++) {
                const element = tempBusinessCategoryList[index];
                if (element.Name === "Ny kategori") {
                    errorList.push("Ugyldigt navn. Standardnavnet kan ikke bruges");
                    break;
                }
                
                payload.push({ 
                    Id: element.Id === element.TempId ? 0 : element.Id,
                    TempId: element.TempId,
                    Name: element.Name,
                    ParentId: element.ParentId ? element.ParentId : 0,
                    ParentTempId: element.ParentTempId ? element.ParentTempId : 0, 
                    Order: element.Order,
                    IsDelete: element.IsDelete,
                    ChangeOrder: index + 1
                });
            }

            if (errorList.length > 0) {
                this.dialogOpen("Advarsel", errorList.join("<br />"));
                return;
            }
            
            const result = await BusinessCategoryService.Bulk(payload, source.token);

            if (!result.HasError) {
                this.treeData = result.Data;
                this.branchCategoryTreeRef.fields.dataSource = result.Data;
                this.branchCategoryTreeRef.refresh();
                
                UndoRedoStatus.hasUndo = result.Undo;
                UndoRedoStatus.hasRedo = result.Redo;
                UndoRedoStatus.undoTooltip = result.UndoAction;
                UndoRedoStatus.redoTooltip = result.RedoAction;

                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Branche kategori"));

                this.setState({ isEditMode: false, tempBusinessCategoryList: [], businessCategories: result.Data, UndoRedoStatus, hasUnsavedChanges: false }, () => {
                    this.branchCategoryToolbarRef.enableItems(1, true);
                    this.branchCategoryToolbarRef.enableItems(2, false);
                    this.branchCategoryToolbarRef.enableItems(3, false);
                });
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
        else if (args.item.id === 'cancel') {
            const result = await BusinessCategoryService.GetList(source.token);

            this.branchCategoryTreeRef.fields.dataSource = result.Data;
            this.branchCategoryTreeRef.refresh();
            this.branchCategoryTreeRef.collapseAll();
            this.branchCategoryTreeRef.selectedNodes = [];

            this.setState({ isEditMode: false, tempBusinessCategoryList: [] }, () => {

                this.branchCategoryToolbarRef.enableItems(1, true);
                this.branchCategoryToolbarRef.enableItems(2, false);
                this.branchCategoryToolbarRef.enableItems(3, false);
            });
        }
        else if (args.item.id === 'delete') {
            let nodes = this.branchCategoryTreeRef.selectedNodes;
            let { tempBusinessCategoryList, businessCategories } = this.state;

            if (nodes.length > 0) {
                let node = null;
                if (tempBusinessCategoryList.some(x => x.TempId === parseInt(nodes[0]))) {
                    let temp = tempBusinessCategoryList.find(x => x.TempId === parseInt(nodes[0]));
                    temp.IsDelete = true;
                }
                else {
                    for (let index = 0; index < businessCategories.length; index++) {
                        const element = businessCategories[index];
                        node = this.searchTree(element, parseInt(nodes[0]));
        
                        if (node) {
                            break;
                        }
                    }

                    tempBusinessCategoryList.push({
                        ...node,
                        IsDelete: true,
                    });
                }

                // this.branchCategoryTreeRef.removeNodes([nodes[0]]);
                this.setState({ tempBusinessCategoryList, hasUnsavedChanges: true });
                this.deleteConfirmationDialog.show();
            }
            else {
                this.dialogOpen("Advarsel", Localization.General.PleaseSelectItem.replace("{context}", "branche kategori"));
            }
        }
        else if (args.item.id === 'undo') {
            const result = await BusinessCategoryService.Undo(source.token);

            if (!result.HasError) {
                let { UndoRedoStatus } = this.state;
                UndoRedoStatus.hasUndo = result.Undo;
                UndoRedoStatus.hasRedo = result.Redo;
                UndoRedoStatus.undoTooltip = result.UndoAction;
                UndoRedoStatus.redoTooltip = result.RedoAction;
                this.setState({ UndoRedoStatus });
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
        else if (args.item.id === 'redo') {
            const result = await BusinessCategoryService.Undo(source.token);

            if (!result.HasError) {
                let { UndoRedoStatus } = this.state;
                UndoRedoStatus.hasUndo = result.Undo;
                UndoRedoStatus.hasRedo = result.Redo;
                UndoRedoStatus.undoTooltip = result.UndoAction;
                UndoRedoStatus.redoTooltip = result.RedoAction;
                this.setState({ UndoRedoStatus });
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
    }

    onNodeClicked(args) {
        if (args.event.which === 3) {
            this.branchCategoryTreeRef.selectedNodes = [args.node.getAttribute('data-uid')];
        }
    }

    nodeEdited(args) {
        let { tempBusinessCategoryList, businessCategories } = this.state;
        
        // if (args.newText === "Ny kategori") {
        //     args.cancel = true;
        //     this.dialogOpen("Advarsel", "Ugyldigt navn. Standardnavnet kan ikke bruges.");
        //     setTimeout(() => {
        //         this.branchCategoryTreeRef.beginEdit(args.nodeData.id);
        //     }, 300);
        //     return;
        // }
        // else 
        if (args.newText.trim() === "") {
            args.cancel = true;
            this.dialogOpen("Advarsel", "Branche kategori navn kræves");
            setTimeout(() => {
                this.branchCategoryTreeRef.beginEdit(args.nodeData.id);
            }, 300);
            return;
        }

        if (tempBusinessCategoryList.some(x => x.TempId === parseInt(args.nodeData.id))) {
            let category = tempBusinessCategoryList.find(x => x.TempId === parseInt(args.nodeData.id));
            category.Name = args.newText;
            // tempBusinessCategoryList.push(category);
        }
        else if (tempBusinessCategoryList.some(x => x.ParentId === parseInt(args.nodeData.parentID) && x.Name === "Ny kategori")) {
            let category = tempBusinessCategoryList.find(x => x.ParentId === parseInt(args.nodeData.parentID) && x.Name === "Ny kategori");
            category.Name = args.newText;
        }
        else {
            let node = null;
            for (let index = 0; index < businessCategories.length; index++) {
                const element = businessCategories[index];
                node = this.searchTree(element, parseInt(args.nodeData.id));

                if (node) {
                    break;
                }
            }
            
            if (node) {
                // let category = businessCategories.find(x => x.Id === parseInt(args.nodeData.id));
                if (node.Name !== args.newText) {
                    node.Name = args.newText;
                    tempBusinessCategoryList.push(node);
                }
            }
        }

        this.setState({ tempBusinessCategoryList, hasUnsavedChanges: true });
    }

    searchTree(list, Id) {
        if(list.Id === Id) {
            return list;
        }
        else if (list.SubCategories) {
            let result = null;
            
            for(let i = 0; result === null && i < list.SubCategories.length; i++) {
                result = this.searchTree(list.SubCategories[i], Id);
            }

            return result;
        }
        return null;
    }

    nodeEditing(args) {
        let { isEditMode } = this.state;
        if (!isEditMode) {
            args.cancel = true;
        }
    }

    clearTreeSelection() {
        this.branchCategoryTreeRef.selectedNodes = [];
    }

    dialogOpen(header, content) {
        this.bCNotificationDialog.header = header;
        this.bCNotificationDialog.content = content;
        this.bCNotificationDialog.show();
    }

    render() {
        const { loading, redirect, isEditMode, hasUnsavedChanges, UndoRedoStatus } = this.state;

        if (loading) { 
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        let editInstructions = "";

        if (isEditMode) {
            editInstructions = "<br /> Dobbeltklik på   række eller tryk på F2 for at redigere";
        }

        return (
            <div className="branch-category-wrapper">
                <NavigationPrompt when={hasUnsavedChanges} 
                    navigate={path => this.props.history.push(path)}
                    initiateSave={() => this.onBranchCategoryToolbarClicked({ item: { id: 'update' } })}
                    shouldBlockNavigation={location => {
                        if (hasUnsavedChanges) {
                            return true;
                        }
                        return false;
                    }}
                />
                <div className="branch-category-toolbar-wrapper c-toolbar-plain">
                    <ToolbarComponent ref={ref => this.branchCategoryToolbarRef = ref} clicked={this.onBranchCategoryToolbarClicked}>
                        <ItemsDirective>
                            <ItemDirective id="add" prefixIcon='tb-icons c-icon-add-black' text="Ny" tooltipText='Ny' disabled={true} />
                            <ItemDirective id="edit" prefixIcon='tb-icons c-icon-edit' text="Redigere" tooltipText='Redigere' disabled={true} /> {/* visible={permission.Functions.some(f => f === "Redigere")} */}
                            <ItemDirective id="update" prefixIcon='tb-icons c-icon-update' text="Gem" tooltipText='Gem' disabled={true} /> {/* visible={permission.Functions.some(f => f === "Redigere")} */}
                            <ItemDirective id="cancel" prefixIcon='tb-icons c-icon-cancel' text="Annuller" tooltipText='Annuller' disabled={true} /> {/* visible={permission.Functions.some(f => f === "Redigere")} */}   
                            <ItemDirective id="delete" prefixIcon='tb-icons c-icon-delete' text="Slet" tooltipText='Slet' /> {/*visible={permission.Functions.some(f => f === "Slette")} */}
                            <ItemDirective id="undo" prefixIcon='tb-icons c-icon-undo' text="Fortryd" disabled={!UndoRedoStatus.hasUndo} tooltipText={UndoRedoStatus.undoTooltip} /> {/* visible={permission.Functions.some(f => f === "Undo")}*/}
                            <ItemDirective id="redo" prefixIcon='tb-icons c-icon-redo' text="Gentag" disabled={!UndoRedoStatus.hasRedo} tooltipText={UndoRedoStatus.redoTooltip} /> {/* visible={permission.Functions.some(f => f === "Fortryd")}*/}
                        </ItemsDirective>
                    </ToolbarComponent>
                </div>
                <div className="branch-category-content">
                    <Card headerText="Vælg en menu du vil redigere, eller lave en under menu" subText={`Klik på + for tilhørende funktioner ${editInstructions}`}>
                        <div className="regular-text" style={{textAlign: 'center', paddingBottom: 15}}>
                            <span id="remove-product-group-filter" className="clickable" style={{borderBottom: '1px solid #1F1C1A'}} onClick={this.clearTreeSelection}>Clear selection</span>
                        </div>
                        <div id="tree">
                            <TreeViewComponent id='branch-category-menu-tree' ref={ref => this.branchCategoryTreeRef = ref} fields={this.treeFields} allowEditing={true} nodeClicked={this.onNodeClicked}
                                nodeEditing={this.nodeEditing} nodeEdited={this.nodeEdited} />
                        </div>
                    </Card>
                </div>
                <DialogComponent id="branch-category-dialog" isModal={true} width='auto' ref={dialog => this.bCNotificationDialog = dialog} target='body' buttons={this.buttons}
                    visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} />
                <DialogComponent isModal={true} width='auto' ref={dialog => this.deleteConfirmationDialog = dialog} target='body' buttons={this.deleteConfirmationButtons}
                    header="Advarsel" content="Du er i gang med at slette en meddelelse <br/>tryk slet for at slette eller annuller for at fortryde"
                    visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} />
            </div>
        )
    }
}

export default withRouter(BusinessCategory)
