import React, { Component } from 'react';
import { TabComponent, TabItemDirective, TabItemsDirective, ToolbarComponent, ItemsDirective, ItemDirective, TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Page, Sort, Toolbar, Selection, Edit, Reorder, Resize } from '@syncfusion/ej2-react-grids';
import { CheckBoxComponent, SwitchComponent  } from '@syncfusion/ej2-react-buttons';
import { Query } from '@syncfusion/ej2-data';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { Redirect } from 'react-router-dom';

import Card from '../../components/Card';
// import PrimaryContact from './PrimaryContact';
import { StorageKeys } from '../../settings/StorageKeys';
import Breadcrumb from '../../components/Breadcrumb';
import Loading from '../../components/Loading';
import NavigationPrompt from '../../components/NavigationPrompt';

import BusinessCategoryService from '../../services/Customer/BusinessCategoryService';
import SessionService from '../../services/SessionService';
import BaseComponent from '../../components/BaseComponent';
import CustomerService from '../../services/Customer/CustomerService';
import CustomerGroupService from '../../services/Customer/CustomerGroupService';
import DepartmentService from '../../services/Customer/DepartmentService';
import AuthenticationService from '../../services/AuthenticationService';
import UserService from '../../services/User/UserService';
import RegionService from '../../services/RegionService';
import Localization from '../../utilities/Localization';
import SettingsService from '../../services/SettingsService';
import { buildGridSettings } from '../../utilities/Helper';

import '../../styles/account.scss';

import Axios from 'axios';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

class MyAccount extends BaseComponent {
    moduleName = "Min Konto";
    pageName = "Min Konto";
    accountDepartmentGridSettingsKey = "AccountDepartmentList";
    PrimaryContactGridSettingsKey = "PrimaryContactList";
    hasDraggedColumn = false;
    accountDepartmentGridSettings = '';
    PrimaryContactGridSettings = '';
    defaultDepartmentData = () => {
        return {
            Id: null,
            Name: '',
            Street: '',
            PostalCodeId: null,
            CityId: null,
            RegionId: null,
            CountryId: null,
            PhoneNumber: '',
            Email: '',
            DeliveryStreet: '',
            DeliveryPostalCodeId: null,
            DeliveryCityId: null,
            DeliveryRegionId: null,
            DeliveryCountryId: null,
            DeliveryATT: ''
        }
    }
    
    constructor() {
        super();

        this.state = {
            loading: true,
            breadcrumbs: [{text: 'Min Konto', link: '/min-konto'}, 'Konto Info'],
            data: {},
            dialogOpts: {
                header: '',
                content: null,
                visible: false,
                redirectOnClose: false,
                redirectTo: ''
            },
            departmentData: {
                Id: null,
                Name: '',
                Street: '',
                PostalCodeId: null,
                CityId: null,
                RegionId: null,
                CountryId: null,
                PhoneNumber: '',
                Email: '',
                DeliveryStreet: '',
                DeliveryPostalCodeId: null,
                DeliveryCityId: null,
                DeliveryRegionId: null,
                DeliveryCountryId: null,
                DeliveryATT: ''
            },
            redirect: {
                to: '',
                isRedirect: false
            },
            currentTabIndex: 0,
            undoRedoStatus: {
                Undo: false,
                Redo: false,
                UndoTooltip: '',
                RedoTooltip: ''
            },
            hasUnsavedChanges: false
        }

        this.commonValidationRules = { required: true };
        this.tabHeader = [{ text: "Konto Info" }, { text: "Konto Afdeling" }, { text: "Primærer Kontakt" }, { text: "Konto Indstillinger" }];

        this.isSave = false;

        this.selectionSettings = {
            type: 'Single'
        }

        this.filterSettings = {
            type: 'Menu'
        };

        // account settings initialization
        this.branchCategoryFields = {
            dataSource: [], id: 'Id', text: 'Name', child: 'SubCategories'
        };

        this.menuFields = {
            dataSource: [], 
            id: 'id', parentID: 'pid', text: 'name', 
            hasChildren: 'hasChild' 
        };

        this.pageSettings = {
            pageSize: 10,
            pageSizes: ['Alle', '5', '10', '20', '50', '100']
        };

        this.regionSource = []
        this.postalCodes = [];
        this.cities = [];
        this.regions = [];
        this.countries = [];

        this.deliveryCities = [];
        this.deliveryRegions = [];
        this.deliveryPostalCodes = [];
        this.deliveryCountries = [];

        this.onPostalCodeFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('Value', 'startswith', e.text, true) : query;
            e.updateData(this.postalCodes, query)
        }

        this.onCityFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('ShortName', 'startswith', e.text, true) : query;
            e.updateData(this.cities, query)
        }

        this.onRegionFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('Name', 'startswith', e.text, true) : query;
            e.updateData(this.regions, query)
        }

        this.onCountryFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('Name', 'startswith', e.text, true) : query;
            e.updateData(this.countries, query)
        }

        this.onDeliveryPostalCodeFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('Value', 'startswith', e.text, true) : query;
            e.updateData(this.deliveryPostalCodes, query)
        }

        this.onDeliveryCityFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('ShortName', 'startswith', e.text, true) : query;
            e.updateData(this.deliveryCities, query)
        }

        this.onDeliveryRegionFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('Name', 'startswith', e.text, true) : query;
            e.updateData(this.deliveryRegions, query)
        }

        this.onDeliveryCountryFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('Name', 'startswith', e.text, true) : query;
            e.updateData(this.deliveryCountries, query)
        }

        this.accountSettingsCustomerGroupItems = [];
        this.init = this.init.bind(this);
        this.refreshAccountDepartmentData = this.refreshAccountDepartmentData.bind(this);
        this.refreshPrimaryContactData = this.refreshPrimaryContactData.bind(this);
        this.onAccountInfoToolbarClicked = this.onAccountInfoToolbarClicked.bind(this);
        this.dialogClose = this.dialogClose.bind(this);
        this.undoRedoOptions = this.undoRedoOptions.bind(this);
        this.onAccountToolbarClicked = this.onAccountToolbarClicked.bind(this);
        this.dialogOpen = this.dialogOpen.bind(this);
        this.departmentToolbarClicked = this.departmentToolbarClicked.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);
        this.refreshAccountInfoData = this.refreshAccountInfoData.bind(this);
        this.initiateAccountInfoSave = this.initiateAccountInfoSave.bind(this);
        this.onAccountSettingsToolbarClicked = this.onAccountSettingsToolbarClicked.bind(this);
        this.toggleAccountSettingsFields = this.toggleAccountSettingsFields.bind(this);
        this.onFaqMailClicked = this.onFaqMailClicked.bind(this);
        
        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.buttons = [{
            // Click the footer buttons to hide the Dialog
            click: this.dialogClose,
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];

        this.buttons2 = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.dialogInstance2.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];

        this.departmentButtons = [
            {
                click: () => {
                    this.departmentDialog.hide();
                },
                buttonModel: {
                    content: 'Annuller'
                }
            },
            {
                click: async () => {
                    const { departmentData } = this.state;
                    if (departmentData.Id) {
                        this.executeAccountDepartment('update', departmentData);
                    }
                    else {
                        this.executeAccountDepartment('add', departmentData);
                    }
                },
                buttonModel: {
                    isPrimary: true,
                    content: 'Gem'
                }
            }
        ];

        this.primaryContactButtons = [
            {
                click: () => {
                    this.primaryContactDialog.hide();
                },
                buttonModel: {
                    content: 'Annuller'
                }
            },
            {
                click: async () => {
                    source.cancel();
                    source = CancelToken.source();
                    
                    const selectedRows = this.primaryUserGridRef.getSelectedRecords();
                    if (selectedRows.length > 0) {
                        const result = await UserService.UpdateUserPrimaryContact(selectedRows[0].Id, { Id: selectedRows[0].Id, PrimaryContact: true }, source.token);
        
                        if (!result.HasError) {
                            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessAdd.replace("{context}", "Primærer Kontakt"));
                            this.undoRedoOptions(result.UndoRedoStatus.Undo, result.UndoRedoStatus.Redo, result.UndoRedoStatus.UndoAction, result.UndoRedoStatus.RedoAction);
                        }
                        else {
                            this.dialogOpen("Advarsel", result.ErrorMessage);
                        }
                        
                        this.refreshPrimaryContactData();
                        this.primaryContactDialog.hide();
                    }
                    else {
                        this.dialogOpen("Advarsel", Localization.General.NoRowSelected);
                    }
                },
                buttonModel: {
                    isPrimary: true,
                    content: 'Tilføje'
                }
            }
        ];
        /* End Dialog options  */
    }

    async componentDidMount() {
        super.componentDidMount();
        source.cancel();
        source = CancelToken.source();

        if (!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }

        this.init();

        // const user = await AuthenticationService.getUser();
        const sessionInfo = await SessionService.GetSessionInfo();
        this.AccountId = sessionInfo.AccountId;
        this.CustomerId = sessionInfo.Id;
        this.ParentAcctId = sessionInfo.ParentAcctId;

        const accountData = await CustomerService.GetCustomer(this.CustomerId, source.token);

        accountData.PostalCodeId = accountData.PostalCode.Id;
        accountData.CityId = accountData.City.Id;
        accountData.RegionId = accountData.Region.Id;
        accountData.PhoneNumber = accountData.Account.PhoneNumber;
        accountData.Email = accountData.Account.Email;
        accountData.CustomerGroupId = accountData.CustomerGroup.Id;

        const accountDepartment = await CustomerService.GetAccountDepartments(sessionInfo.Id, source.token);
        this.accountDepartmentData = accountDepartment;

        const accountPrimaryContact = await CustomerService.GetAccountPrimaryContacts(sessionInfo.Id, source.token);
        this.accountPrimaryContactData = accountPrimaryContact;

        const accountCustomerGroups = await CustomerGroupService.GetCustomerGroups(source.token);
        
        accountCustomerGroups.sort((a,b) => (a.Level > b.Level) ? 1 : -1);
        this.accountSettingsCustomerGroupItems = accountCustomerGroups;

        let userList = await CustomerService.GetUsers(sessionInfo.Id, {active: true}, source.token);
        if (userList != null && userList.length > 0) {
            userList = userList.filter(x => x.PrimaryContact === false);
        }
        
        this.userListData = userList;

        // Get city and region
        const regions = await RegionService.GetRegion(source.token);
        this.regionSource = regions;

        let tempRegions = [], tempCities = [];

        regions.forEach(region => {
            tempRegions.push(region);

            region.Cities.forEach(city => {

                // if (tempCities.some(x => x.ShortName === city.ShortName)) 
                //     return;

                tempCities.push(city);

                city.PostalCodes.forEach(postal => {
                    this.postalCodes.push(postal);
                    this.deliveryPostalCodes.push(postal);
                })
            });
        });

        tempRegions = [...new Set(tempRegions)];
        tempCities = [...new Set(tempCities)];

        tempRegions.sort((a,b) => (a.value > b.value) ? 1 : -1);
        tempCities.sort((a,b) => (a.value > b.value) ? 1 : -1);

        this.cities = tempCities;
        this.regions = tempRegions;
        
        this.deliveryCities = tempCities;
        this.deliveryRegions = tempRegions;

        const countryResult = await SettingsService.GetCountries(source.token);
        this.countries = countryResult;
        this.deliveryCountries = countryResult;

        const categories = await BusinessCategoryService.GetList(source.token);
        this.businessCategories = categories.Data;
        this.branchCategoryFields.dataSource = this.businessCategories;

        const accountDepartmentGridSettings = await SettingsService.GetGridSettings(this.accountDepartmentGridSettingsKey, source.token);
        if (!accountDepartmentGridSettings.HasError) {
            this.accountDepartmentGridSettings = accountDepartmentGridSettings.Settings;
        }
        
        const PrimaryContactGridSettings = await SettingsService.GetGridSettings(this.PrimaryContactGridSettingsKey, source.token);
        if (!PrimaryContactGridSettings.HasError) {
            this.PrimaryContactGridSettings = PrimaryContactGridSettings.Settings;
        }

        this.setState({ data: accountData, loading: false }, () => {
            this.AccountToolbarRef.items[0].disabled = true;
            this.AccountToolbarRef.items[1].disabled = true;

            setTimeout(() => {
                window.addEventListener('wheel', this.onWheelScroll);
            }, 500);
        });
    }

    init() {
        let departmentTab = this.modulePermissions.MenuTabs.find(x => x.Name === "Konto Afdeling");
        let primaryContactTab = this.modulePermissions.MenuTabs.find(x => x.Name === "Primærer Kontakt");
        this.accountDepartmentToolbarOptions = [];
        this.accountDepartmentEditSettings = {};
        this.accountPrimaryContactToolbarOptions = [];
        this.accountPrimaryContactEditSettings = {};

        if (departmentTab) {
            if (super.hasTabFunction("Konto Afdeling", "Redigere")) {
                this.accountDepartmentToolbarOptions.push({text: 'Ny', tooltipText: 'Ny', id: 'add', prefixIcon: 'e-add' });
                this.accountDepartmentToolbarOptions.push({text: 'Redigere', tooltipText: 'Redigere', id: 'edit', prefixIcon: 'e-edit'});
                this.accountDepartmentToolbarOptions.push('Delete');
                this.accountDepartmentEditSettings.allowDeleting = true;
                this.accountDepartmentEditSettings.showDeleteConfirmDialog = true;
            }

            this.accountDepartmentToolbarOptions.push({ text: Localization.General.GridResetFilter, tooltipText: Localization.General.GridResetFilter, id: 'reset_filter', prefixIcon: 'e-cancel' });
        }

        if (primaryContactTab) {
            if (super.hasTabFunction("Primærer Kontakt", "Redigere")) {
                this.accountPrimaryContactToolbarOptions.push({text: 'Tilføj Kontakt', tooltipText: 'Tilføj Kontakt', id: 'add', prefixIcon: 'e-add' });
                this.accountPrimaryContactToolbarOptions.push('Delete');
                this.accountPrimaryContactEditSettings = {
                    allowDeleting: true,
                    showDeleteConfirmDialog: true
                };
            }

            this.accountPrimaryContactToolbarOptions.push({ text: Localization.General.GridResetFilter, tooltipText: Localization.General.GridResetFilter, id: 'reset_filter', prefixIcon: 'e-cancel' });
        }
    }

    onWheelScroll() {
        let departmentGrid = document.getElementById('account-department-grid');
        if (departmentGrid) {
            let toolbar = document.querySelector('#account-department-grid.e-grid .e-toolbar');
            let tableHeader = document.querySelector('#account-department-grid .e-gridheader');
    
            if (toolbar && tableHeader) {
                let boundingRect = tableHeader.getBoundingClientRect();
                
                if (boundingRect.top < 30) {
                    toolbar.classList.add('c-hover-grid-toolbar');
                    toolbar.style.width = (departmentGrid.clientWidth - 5) + 'px';
                }
                else {
                    toolbar.classList.remove('c-hover-grid-toolbar');
                    toolbar.style.width = 'auto';
                }
            }
        }
        
        let primaryContactGrid = document.getElementById('account-primary-contact-grid');
        if (primaryContactGrid) {
            let toolbar = document.querySelector('#account-primary-contact-grid.e-grid .e-toolbar');
            let tableHeader = document.querySelector('#account-primary-contact-grid .e-gridheader');
    
            if (toolbar && tableHeader) {
                let boundingRect = tableHeader.getBoundingClientRect();
                
                if (boundingRect.top < 30) {
                    toolbar.classList.add('c-hover-grid-toolbar');
                    toolbar.style.width = (primaryContactGrid.clientWidth - 5) + 'px';
                }
                else {
                    toolbar.classList.remove('c-hover-grid-toolbar');
                    toolbar.style.width = 'auto';
                }
            }
        }
    }

    componentWillUnmount() {
        source.cancel();
        window.removeEventListener('wheel', this.onWheelScroll);
    }

    onAccountDepartmentActionBegin(args) {
        if (args.requestType === 'delete') {
            args.cancel = true;
            this.executeAccountDepartment('delete', args.data);
        }
    }

    async executeAccountDepartment(state, data) {
        source.cancel();
        source = CancelToken.source();
        let result = null;

        switch(state) {
            case 'add':
            case 'update':
                if (data.Id) {
                    data.Id = parseInt(data.Id);
                }
                data.CustomerId = this.CustomerId;
                result = await DepartmentService.AddUpdateDepartment(state, data, source.token);
                
                if (!result.HasError) {
                    this.departmentDialog.hide();
                    let localizeActionMessage = state === 'update' ? Localization.General.SuccessUpdate : Localization.General.SuccessAdd;
                    this.dialogOpen(Localization.General.SuccessHeader, localizeActionMessage.replace("{context}", "Konto afdeling"));
                    this.undoRedoOptions(result.UndoRedoStatus.Undo, result.UndoRedoStatus.Redo, result.UndoRedoStatus.UndoAction, result.UndoRedoStatus.RedoAction);
                    this.refreshAccountDepartmentData();
                }
                else {
                    this.dialogOpen("Advarsel", result.ErrorMessage);
                }                
                break;
            case 'delete':
                result = await DepartmentService.DeleteDepartment(data[0].Id, source.token);

                if (!result.HasError) {
                    // prompt success message
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessDelete.replace("{context}", "Konto afdeling"));
                    this.undoRedoOptions(result.Undo, result.Redo, result.UndoAction, result.RedoAction);
                }
                else {
                    this.dialogOpen("Advarsel", result.ErrorMessage);
                }
                this.refreshAccountDepartmentData();
                break;
        }
    }

    async refreshAccountDepartmentData() {
        const accountDepartment = await CustomerService.GetAccountDepartments(this.CustomerId, source.token);
        this.accountDepartmentGridRef.dataSource = accountDepartment;
        this.accountDepartmentGridRef.dataBind();
    }

    async onPrimaryContactActionComplete(args) {
        if (args.requestType === 'save') {
            if (args.action === 'add') {
                source.cancel();
                source = CancelToken.source();

                // Get value from localStorage
                let userId = localStorage.getItem(StorageKeys.primaryContactKey);
                const result = await UserService.UpdateUserPrimaryContact(parseInt(userId), { Id: parseInt(userId), PrimaryContact: true }, source.token);

                if (!result.HasError) {
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessAdd.replace("{context}", "Primærer Kontakt"));
                    this.undoRedoOptions(result.UndoRedoStatus.Undo, result.UndoRedoStatus.Redo, result.UndoRedoStatus.UndoAction, result.UndoRedoStatus.RedoAction);
                }
                else {
                    this.dialogOpen("Advarsel", result.ErrorMessage);
                }
                
                this.refreshPrimaryContactData();
            }
        }
        else if (args.requestType === 'delete') {
            const result = await UserService.UpdateUserPrimaryContact(args.data[0].Id, { Id: args.data[0].Id, PrimaryContact: false }, source.token);
            
            if (!result.HasError) {
                // prompt success message
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Primærer Kontakt"));
                this.undoRedoOptions(result.Undo, result.Redo, result.UndoAction, result.RedoAction);
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }

            this.refreshPrimaryContactData();
        }
        else if (args.requestType === 'paging') {
            if (this.primaryContactGridRef.pageSettings.pageSize === this.primaryContactGridRef.pageSettings.totalRecordsCount) {
                document.querySelector("#account-department-grid .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.PrimaryContactGridSettings, this.primaryContactGridRef.pageSettings.pageSize, this.primaryContactGridRef.pageSettings.totalRecordsCount, false, []);
            this.PrimaryContactGridSettings = tempGridSettings;
            const payload = {
                Key: this.PrimaryContactGridSettingsKey,
                Settings: this.PrimaryContactGridSettings
            };
            const result = await SettingsService.SaveGridSettings(payload, source.token);
            this.onWheelScroll();
        }
        else if (args.requestType === 'filtering') {
            if (this.PrimaryContactGridSettings) {
                let settings = JSON.parse(this.PrimaryContactGridSettings);
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let tempGridSettings = buildGridSettings(args, this.PrimaryContactGridSettings, 0, 0, false, []);
            this.PrimaryContactGridSettings = tempGridSettings;
            const payload = {
                Key: this.PrimaryContactGridSettingsKey,
                Settings: this.PrimaryContactGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.PrimaryContactGridSettings) {
                let settings = JSON.parse(this.PrimaryContactGridSettings);
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.PrimaryContactGridSettings, 0, 0, false, []);
            this.PrimaryContactGridSettings = tempGridSettings;
            const payload = {
                Key: this.PrimaryContactGridSettingsKey,
                Settings: this.PrimaryContactGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.primaryContactGridRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.PrimaryContactGridSettings, 0, 0, this.hasDraggedColumn, columns);
            this.PrimaryContactGridSettings = tempGridSettings;
            const payload = {
                Key: this.PrimaryContactGridSettingsKey,
                Settings: this.PrimaryContactGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                let lastColumnIndex = this.primaryContactGridRef.columns.length - 1;
                for (let index = 0; index < this.primaryContactGridRef.columns.length; index++) {
                    let column = this.primaryContactGridRef.columns[index];
                    if (index === lastColumnIndex) { column.allowResizing = false }
                    else { column.allowResizing = true }
                }
                this.primaryContactGridRef.refreshColumns();
            }

            this.hasDraggedColumn = false;
        }
    }

    async refreshPrimaryContactData() {
        const accountPrimaryContact = await CustomerService.GetAccountPrimaryContacts(this.CustomerId, source.token);
        this.primaryContactGridRef.dataSource = accountPrimaryContact;
        this.primaryContactGridRef.dataBind();
    }

    onAccountInfoChange(args) {
        let input = args.container.querySelector('input');

        if (input) {
            let { data, isDirty } = this.state;
            let key = input.name;

            if (key.includes('.')) {
                let ksplit = key.split('.');
                data[ksplit[0]][ksplit[1]] = args.value;
            }
            else {
                data[key] = args.value;
            }

            this.setState({ data, hasUnsavedChanges: true });
        }
    }

    onCountryChange(args) {
        if (args.isInteracted) {
            let { data } = this.state;
            data.CountryId = args.value;
            this.setState({ data });
        }
    }

    onRegionChange(args) {
        if (args.isInteracted) {
            let { data } = this.state;
            data.RegionId = args.value;
            data.CountryId = args.itemData.CountryId
            this.setState({ data, hasUnsavedChanges: true });
        }
    }

    onCityChange(args) {
        if (args.isInteracted) {
            let { data } = this.state;
            data.CityId = args.value;
            data.RegionId = args.itemData.RegionId;
            this.ddlRegionRef.value = args.itemData.RegionId;
            
            data.PostalCodeId = args.itemData.PostalCodes[0].Id;
            this.ddlPostalCodeRef.value = args.itemData.PostalCodes[0].Id;

            let selectedRegion = this.regions.find(x => x.Id === args.itemData.RegionId);
            data.CountryId = selectedRegion.CountryId;
            this.ddlCountryRef.value = selectedRegion.CountryId;

            this.setState({ data, hasUnsavedChanges: true });
        }
    }

    onPostalCodeChange(args) {
        if (args.isInteracted) {
            let { data } = this.state;
            let city = this.cities.find(c => c.Id === args.itemData.CityId);
            let selectedRegion = this.regionSource.find(x => x.Id === city.RegionId);
            data.PostalCodeId = args.value;
            data.CityId = args.itemData.CityId;
            data.RegionId = selectedRegion.Id;
            data.CountryId = selectedRegion.CountryId;

            this.ddlCityRef.value = args.itemData.CityId;
            this.ddlRegionRef.value = selectedRegion.Id;
            this.ddlCountryRef.value = selectedRegion.CountryId;
            this.setState({ data, hasUnsavedChanges: true });
        }
    }

    refreshDropdown = () => {
        this.ddlPostalCodeRef.showPopup();
        this.ddlCityRef.showPopup();
        this.ddlRegionRef.showPopup();

        setTimeout(() => {
            this.ddlPostalCodeRef.hidePopup();
            this.ddlCityRef.hidePopup();
            this.ddlRegionRef.hidePopup();
        }, 200);
    }

    accountInfoFields(enabled) {
        this.CompanyNameRef.enabled = enabled;
        this.StreetRef.enabled = enabled;
        this.ddlPostalCodeRef.enabled = enabled;
        this.ddlCityRef.enabled = enabled;
        this.ddlRegionRef.enabled = enabled;
        this.AccountPhonenumberRef.enabled = enabled;
        this.EmailRef.enabled = enabled;
        this.VatNoRef.enabled = enabled;
        this.ContactMailRef.enabled = enabled;
        this.FirstNameRef.enabled = enabled;
        this.MiddleNameRef.enabled = enabled;
        this.LastNameRef.enabled = enabled;
    }

    async onAccountInfoToolbarClicked(args) {
        if (args.item.id === 'edit') {
            this.AccountInfoToolbarRef.enableItems(0, false);
            this.AccountInfoToolbarRef.enableItems(1, true);
            this.AccountInfoToolbarRef.enableItems(2, true);
            this.AccountInfoToolbarRef.enableItems(3, true);
            this.AccountInfoToolbarRef.enableItems(4, true);
            
            this.accountInfoFields(true);
        }
        else if (args.item.id === 'update') {
            this.initiateAccountInfoSave();
        }    
        else if (args.item.id === 'cancel') {
            this.refreshDropdown();
            setTimeout(async () => {
                await this.refreshAccountInfoData().then(() => {
                    this.AccountInfoToolbarRef.enableItems(0, true);
                    this.AccountInfoToolbarRef.enableItems(1, false);
                    this.AccountInfoToolbarRef.enableItems(2, false);
                    this.AccountInfoToolbarRef.enableItems(3, false);
                    this.AccountInfoToolbarRef.enableItems(4, false);
                    this.accountInfoFields(false);
                });
            }, 400);
        }
    }

    async initiateAccountInfoSave() {
        source.cancel();
        source = CancelToken.source();

        let { data } = this.state;
        const payload = {
            Id: data.Id,
            Active: data.Account.Active,
            CompanyName:  data.CompanyName,
            Street: data.Street,
            PostalCodeId: parseInt(data.PostalCodeId),
            Country: data.Country,
            VatNo: data.VatNo,
            ParentId: data.ParentId,
            CustomerGroupId: data.CustomerGroupId,
            AccountId: data.AccountId,
            IntUserName: data.Account.IntUserName,
            FmUserName: data.Account.FmUserName,
            FirstName: data.Account.FirstName,
            MiddleName: data.Account.MiddleName,
            LastName: data.Account.LastName,
            Email: data.Email,
            ContactMail: data.ContactMail,
            PhoneNumber: data.PhoneNumber,
            BusinessCategories: data.BusinessCategories,
            AccessControlPanel: data.Account.AccessControlPanel,
            TwoFactorEnabled: data.Account.TwoFactorEnabled
        };
        
        const result = await CustomerService.AddUpdateCustomer('update', payload, source.token);

        if (!result.HasError) {
            this.AccountInfoToolbarRef.enableItems(0, true);
            this.AccountInfoToolbarRef.enableItems(1, false);
            this.AccountInfoToolbarRef.enableItems(2, false);
            this.AccountInfoToolbarRef.enableItems(3, false);
            this.AccountInfoToolbarRef.enableItems(4, false); 
            this.refreshDropdown();
            this.accountInfoFields(false);
            this.undoRedoOptions(result.UndoRedoStatus.Undo, result.UndoRedoStatus.Redo, result.UndoRedoStatus.UndoAction, result.UndoRedoStatus.RedoAction);
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Konto Info"));
            this.setState({ hasUnsavedChanges: false });
        }
        else {
            this.dialogOpen("Advarsel", result.ErrorMessage);
        }
    }

    async refreshAccountInfoData() {
        let { currentTabIndex } = this.state;
        const accountData = await CustomerService.GetCustomer(this.CustomerId, source.token);

        accountData.PostalCodeId = accountData.PostalCode.Id;
        accountData.CityId = accountData.City.Id;
        accountData.RegionId = accountData.Region.Id;
        accountData.PhoneNumber = accountData.Account.PhoneNumber;
        accountData.Email = accountData.Account.Email;
        accountData.CustomerGroupId = accountData.CustomerGroup.Id;

        switch(currentTabIndex) {
            case 0:
                this.CompanyNameRef.value = accountData.CompanyName;
                this.StreetRef.value = accountData.Street;
                this.ddlPostalCodeRef.value = accountData.PostalCode.Id;
                this.ddlCityRef.value = accountData.City.Id;
                this.ddlRegionRef.value = accountData.Region.Id;
                this.ddlCountryRef.value = accountData.Country.Id;
                this.AccountPhonenumberRef.value = accountData.Account.PhoneNumber;
                this.EmailRef.value = accountData.Account.Email;
                this.VatNoRef.value = accountData.VatNo;
                break;
            case 3:
                let categories = [];
                Object.assign(categories, accountData.BusinessCategories);
                categories = categories.map(c => c.toString());
                this.accountSettingsBranchCategoryRef.checkedNodes = categories;
                this.enableTwofactorRef.value = accountData.Account.TwoFactorEnabled;
                break;
        }

        this.setState({ data: accountData, hasUnsavedChanges: false })
    }

    accountInfoTab() {
        const { data } = this.state;

        return (
            <div className="tab-control--item account-info-wrapper">
                <div className="account-toolbar-wrapper">
                    <ToolbarComponent ref={ref => this.AccountInfoToolbarRef = ref} clicked={this.onAccountInfoToolbarClicked}>
                        <ItemsDirective>
                            <ItemDirective id="edit" prefixIcon='tb-icons c-icon-edit' text="Redigere" tooltipText='Redigere' visible={super.hasTabFunction("Konto Info", "Redigere")} />
                            <ItemDirective id="update" prefixIcon='tb-icons c-icon-update' text="Gem" tooltipText='Gem' disabled={true} visible={super.hasTabFunction("Konto Info", "Redigere")} />
                            <ItemDirective id="cancel" prefixIcon='tb-icons c-icon-cancel' text="Annuller" tooltipText='Annuller' disabled={true} visible={super.hasTabFunction("Konto Info", "Redigere")} />
                        </ItemsDirective>
                    </ToolbarComponent>
                </div>
                <table className="table" cellSpacing="0">
                    <tbody>
                        <tr>
                            <td>Id</td>
                            <td>
                                {data.Id}
                            </td>
                        </tr>
                        <tr>
                            <td>Konto Nr.</td>
                            <td>
                                {/* <TextBoxComponent ref={ref => this.AccountIdRef = ref} value={data.AccountId} enabled={false} name="AccountId" change={this.onAccountInfoChange.bind(this)} /> */}
                                {data.CustomerNo}
                            </td>
                        </tr>
                        <tr>
                            <td>Firma Navn</td>
                            <td><TextBoxComponent ref={ref => this.CompanyNameRef = ref} value={data.CompanyName} enabled={false} name="CompanyName" change={this.onAccountInfoChange.bind(this)} /></td>
                        </tr>
                        <tr>
                            <td>Adresse</td>
                            <td><TextBoxComponent ref={ref => this.StreetRef = ref} value={data.Street} enabled={false} name="Street" change={this.onAccountInfoChange.bind(this)} /></td>
                        </tr>
                        <tr>
                            <td>Post Nr.</td>
                            <td>
                                <DropDownListComponent id="ddlPostalCodeId" ref={ref => this.ddlPostalCodeRef = ref} name="PostalCodeId" value={data.PostalCode.Id} dataSource={this.postalCodes} 
                                    filtering={this.onPostalCodeFiltering.bind(this)} filterBarPlaceholder={Localization.General.SearchPostalCode} allowFiltering={true} fields={{ text: 'Value', value: 'Id' }} 
                                    change={this.onPostalCodeChange.bind(this)} enabled={false} locale="da"/>
                                {/* <TextBoxComponent value={data.ZipNo} enabled={false} name="ZipNo" change={this.onAccountInfoChange.bind(this)} /> */}
                            </td>
                        </tr>
                        <tr>
                            <td>By</td>
                            <td>
                                <DropDownListComponent id="ddlCity" ref={ref => this.ddlCityRef = ref} name="CityId" value={data.City.Id} dataSource={this.cities} 
                                    filtering={this.onCityFiltering.bind(this)} filterBarPlaceholder={Localization.General.SearchCity} allowFiltering={true} fields={{ text: 'ShortName', value: 'Id' }} 
                                    change={this.onCityChange.bind(this)} enabled={false} locale="da" />
                                {/* <TextBoxComponent value={data.City.ShortName} enabled={false} name="City.ShortName" change={this.onAccountInfoChange.bind(this)} /> */}
                            </td>
                        </tr>
                        <tr>
                            <td>Region</td>
                            <td>
                                <DropDownListComponent id="ddlRegion" ref={ref => this.ddlRegionRef = ref} name="RegionId" value={data.Region.Id} dataSource={this.regions} 
                                    filtering={this.onRegionFiltering.bind(this)} filterBarPlaceholder={Localization.General.SearchRegion} allowFiltering={true} fields={{ text: 'Name', value: 'Id' }} 
                                    change={this.onRegionChange.bind(this)} enabled={false} locale="da" />
                                {/* <TextBoxComponent value={data.Region.Name} enabled={false} name="Region.Name" change={this.onAccountInfoChange.bind(this)} /> */}
                            </td>
                        </tr>
                        <tr>
                            <td>Land</td>
                            <td>
                                {/* <TextBoxComponent ref={ref => this.CountryRef = ref} value={data.Country} enabled={false} name="Country" change={this.onAccountInfoChange.bind(this)} /> */}
                                <DropDownListComponent id="ddlCountry" ref={ref => this.ddlCountryRef = ref} name="CountryId" value={data.Country.Id} dataSource={this.countries} 
                                    filtering={this.onCountryFiltering.bind(this)} filterBarPlaceholder={Localization.General.SearchCountry} allowFiltering={true} fields={{ text: 'Name', value: 'Id' }} 
                                    change={this.onCountryChange.bind(this)} enabled={false} locale="da" />
                            </td>
                        </tr>
                        <tr>
                            <td>Tlf. Nr.</td>
                            <td><TextBoxComponent ref={ref => this.AccountPhonenumberRef = ref} value={data.Account.PhoneNumber} enabled={false} name="PhoneNumber" change={this.onAccountInfoChange.bind(this)} /></td>
                        </tr>
                        <tr>
                            <td>Kontakt mail</td>
                            <td><TextBoxComponent ref={ref => this.ContactMailRef = ref} value={data.ContactMail} enabled={false} name="ContactMail" change={this.onAccountInfoChange.bind(this)} /></td>
                        </tr>
                        <tr>
                            <td>Cvr. Nr.</td>
                            <td><TextBoxComponent ref={ref => this.VatNoRef = ref} value={data.VatNo} enabled={false} name="VatNo" change={this.onAccountInfoChange.bind(this)} /></td>
                        </tr>
                        <tr>
                            <td><span style={{display: "flex"}}>Mail for log in og interne beskeder <i className="c-icon c-icon-help-inactive clickable" onClick={this.onFaqMailClicked}></i></span></td>
                            <td><TextBoxComponent ref={ref => this.EmailRef = ref} value={data.Account.Email} enabled={false} name="Email" change={this.onAccountInfoChange.bind(this)} /></td>
                        </tr>
                        <tr>
                            <td>Fornavn</td>
                            <td><TextBoxComponent ref={ref => this.FirstNameRef = ref} value={data.Account.FirstName} enabled={false} name="Account.FirstName" change={this.onAccountInfoChange.bind(this)} /></td>
                        </tr>
                        <tr>
                            <td>Mellem navn</td>
                            <td><TextBoxComponent ref={ref => this.MiddleNameRef = ref} value={data.Account.MiddleName} enabled={false} name="Account.MiddleName" change={this.onAccountInfoChange.bind(this)} /></td>
                        </tr>
                        <tr>
                            <td>Efternavn</td>
                            <td><TextBoxComponent ref={ref => this.LastNameRef = ref} value={data.Account.LastName} enabled={false} name="Account.LastName" change={this.onAccountInfoChange.bind(this)} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    onAccountDepartmentCreated() {
        if (this.accountDepartmentGridSettings) {
            let settings = JSON.parse(this.accountDepartmentGridSettings);
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(column => {
                        if (column.FromIndex !== column.ToIndex) {
                            var tempIndex = this.accountDepartmentGridRef.getColumnIndexByField(column.Field);
                            if (tempIndex !== column.ToIndex){
                                this.accountDepartmentGridRef.reorderColumnByIndex(column.FromIndex, column.ToIndex);
                            }
                        }
    
                        if (column.Width) {
                            this.accountDepartmentGridRef.columns[column.ToIndex].width = column.Width;
                        }
                    });
                }

                if (settings.colName && settings.direction) {
                    this.accountDepartmentGridRef.sortColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.accountDepartmentGridRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.accountDepartmentGridRef.refreshColumns();

                // Prevent resizing of last column
                let lastColumnIndex = this.accountDepartmentGridRef.columns.length - 1;    
                this.accountDepartmentGridRef.columns[lastColumnIndex].allowResizing = false;
                this.accountDepartmentGridRef.refreshColumns();

                if (settings.pageSize) {
                    if (settings.pageSize === "Alle") {
                        this.accountDepartmentGridRef.pageSettings.pageSize = this.accountDepartmentGridRef.pageSettings.totalRecordsCount;
                        document.querySelector("#account-department-grid .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.accountDepartmentGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
            }
        }
    }

    async onAccountDepartmentActionComplete(args) {
        if (args.requestType === 'paging') {
            if (this.accountDepartmentGridRef.pageSettings.pageSize === this.accountDepartmentGridRef.pageSettings.totalRecordsCount) {
                document.querySelector("#account-department-grid .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.accountDepartmentGridSettings, this.accountDepartmentGridRef.pageSettings.pageSize, this.accountDepartmentGridRef.pageSettings.totalRecordsCount, false, []);
            this.accountDepartmentGridSettings = tempGridSettings;
            const payload = {
                Key: this.accountDepartmentGridSettingsKey,
                Settings: this.accountDepartmentGridSettings
            };
            const result = await SettingsService.SaveGridSettings(payload, source.token);
            this.onWheelScroll();
        }
        else if (args.requestType === 'filtering') {
            if (this.accountDepartmentGridSettings) {
                let settings = JSON.parse(this.accountDepartmentGridSettings);
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let tempGridSettings = buildGridSettings(args, this.accountDepartmentGridSettings, 0, 0, false, []);
            this.accountDepartmentGridSettings = tempGridSettings;
            const payload = {
                Key: this.accountDepartmentGridSettingsKey,
                Settings: this.accountDepartmentGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.accountDepartmentGridSettings) {
                let settings = JSON.parse(this.accountDepartmentGridSettings);
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.accountDepartmentGridSettings, 0, 0, false, []);
            this.accountDepartmentGridSettings = tempGridSettings;
            const payload = {
                Key: this.accountDepartmentGridSettingsKey,
                Settings: this.accountDepartmentGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.accountDepartmentGridRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.accountDepartmentGridSettings, 0, 0, this.hasDraggedColumn, columns);
            this.accountDepartmentGridSettings = tempGridSettings;
            const payload = {
                Key: this.accountDepartmentGridSettingsKey,
                Settings: this.accountDepartmentGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                let lastColumnIndex = this.accountDepartmentGridRef.columns.length - 1;
                for (let index = 0; index < this.accountDepartmentGridRef.columns.length; index++) {
                    let column = this.accountDepartmentGridRef.columns[index];
                    if (index === lastColumnIndex) { column.allowResizing = false }
                    else { column.allowResizing = true }
                }
                this.accountDepartmentGridRef.refreshColumns();
            }

            this.hasDraggedColumn = false;
        }
        // if (args.requestType === "reorder" && this.hasDraggedColumn) {
        //     let columns = this.accountDepartmentGridRef.getColumns();
        //     let tempCols = [], orderedColumns = [];

        //     if (this.accountDepartmentGridSettings) {
        //         orderedColumns = JSON.parse(this.accountDepartmentGridSettings);
        //     }

        //     columns.forEach((column, index) => {
        //         if (index === args.fromIndex) {
        //             tempCols.push({
        //                 HeaderText: column.headerText,
        //                 Field: column.field,
        //                 FromIndex: column.index,
        //                 ToIndex: index
        //             });
        //         }
        //         else {
        //             if (orderedColumns.some(x => x.HeaderText === column.headerText)) { //Check if previously saved column has been reordered
        //                 let col = orderedColumns.find(x => x.HeaderText === column.headerText)
        //                 if (col.ToIndex !== index) {
        //                     tempCols.push({
        //                         HeaderText: col.HeaderText,
        //                         Field: col.Field,
        //                         FromIndex: col.FromIndex,
        //                         ToIndex: index
        //                     });
        //                 }
        //             }
        //         }
        //     });

        //     const payload = {
        //         Key: this.accountDepartmentGridSettingsKey,
        //         Settings: JSON.stringify(tempCols)
        //     };

        //     const result = await SettingsService.SaveGridSettings(payload, source.token);

        //     if (!result.HasError) {
        //         this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
        //     }

        //     this.hasDraggedColumn = false;
        // }
    }

    async onAccountDepartmentResizeStop(args) {
        args.requestType = "resizingGrid";
        let columns = this.accountDepartmentGridRef.getColumns();
        let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, columns);
        this.gridSettings = tempGridSettings;
        const payload = {
            Key: this.accountDepartmentGridSettingsKey,
            Settings: this.gridSettings
        };

        const result = await SettingsService.SaveGridSettings(payload, source.token);

        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);

            // Adjust size of last column to fill spaces
            let lastColumnIndex = this.accountDepartmentGridRef.columns.length - 1;
            let totalColumnWidth = this.accountDepartmentGridRef.columns.reduce((acc, curVal) => acc + parseInt(curVal.width), 0);
            let lastColumnWidth = this.accountDepartmentGridRef.element.clientWidth - totalColumnWidth;
            this.accountDepartmentGridRef.columns[lastColumnIndex].width = this.accountDepartmentGridRef.columns[lastColumnIndex].width + lastColumnWidth;
            this.accountDepartmentGridRef.refreshColumns();
        }
    }

    async departmentToolbarClicked(args) {
        if (args.item.id === 'add') {
            let departmentData = this.defaultDepartmentData();
            this.setState({ departmentData }, () => {
                this.departmentDialog.header = "Tilføj Ny Afdeling";
                this.departmentDialog.show();
            });
        }
        else if (args.item.id === 'edit') {
            const selectedRow = this.accountDepartmentGridRef.getSelectedRecords();

            if (selectedRow.length <= 0) {
                this.dialogOpen("Advarsel", Localization.General.NoRowSelected);
            }
            else {
                let departmentData = this.defaultDepartmentData();
                departmentData.Id = selectedRow[0].Id;
                departmentData.Name = selectedRow[0].Name;
                departmentData.Street = selectedRow[0].Street;
                departmentData.PostalCodeId = selectedRow[0].PostalCodeId;
                departmentData.CityId = selectedRow[0].CityId;
                departmentData.RegionId = selectedRow[0].RegionId;
                departmentData.CountryId = selectedRow[0].CountryId;
                departmentData.PhoneNumber = selectedRow[0].PhoneNumber;
                departmentData.Email = selectedRow[0].Email;
                departmentData.DeliveryStreet = selectedRow[0].DeliveryStreet;
                departmentData.DeliveryPostalCodeId = selectedRow[0].DeliveryPostalCodeId;
                departmentData.DeliveryCityId = selectedRow[0].DeliveryCityId;
                departmentData.DeliveryRegionId = selectedRow[0].DeliveryRegionId;
                departmentData.DeliveryCountryId = selectedRow[0].DeliveryCountryId;
                departmentData.DeliveryATT = selectedRow[0].DeliveryATT;
                this.setState({ departmentData }, () => {
                    this.departmentDialog.header = "Rediger Afdeling";
                    this.departmentDialog.show();
                });
            }
        }
        else if (args.item.id === 'reset_filter') {
            if (this.accountDepartmentGridSettings) {
                let settings = JSON.parse(this.accountDepartmentGridSettings);
                settings.filters = [];
                this.accountDepartmentGridSettings = settings;
                const payload = {
                    Key: this.accountDepartmentGridSettingsKey,
                    Settings: JSON.stringify(this.accountDepartmentGridSettings)
                };
    
                const result = await SettingsService.SaveGridSettings(payload, source.token);
    
                if (!result.HasError) {
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                }
            }

            this.accountDepartmentGridRef.clearFiltering();
        }
    }

    onDepartmentChange(args) {
        let input = args.container.querySelector('input');

        if (input) {
            let { departmentData } = this.state;
            departmentData[input.name] = args.value;
            this.setState({ departmentData });
        }
    }

    onDepartmentPostalCodeChange(args) {
        if (args.isInteracted) {
            let { departmentData } = this.state;
            let city = this.cities.find(c => c.Id === args.itemData.CityId);
            // this.ddlCityRef.text = null;
            // this.ddlCityRef.value = null;
            // this.ddlCityRef.dataSource = tempCities;
            // this.ddlCityRef.dataBind();

            this.ddlCityRef.value = args.itemData.CityId;

            let tempRegions = this.regions.filter(region => region.Id === city.RegionId);
            let selectedRegion = this.regions.find(x => x.Id === city.RegionId);

            this.ddlRegionRef.value = city.RegionId;
            this.ddlRegionRef.text =  selectedRegion.Name;
            
            departmentData.PostalCodeId =  args.value;
            departmentData.CityId = args.itemData.CityId;
            departmentData.RegionId = city.RegionId;
            departmentData.CountryId = selectedRegion.CountryId;
            this.setState({ departmentData });
        }
    }

    onDepartmentCityChange(args) {
        if (args.isInteracted) {
            let { departmentData } = this.state;
            let tempRegions = this.regionSource.filter(region => region.Id === args.itemData.RegionId);
            this.ddlRegionRef.text = null;
            
            if (departmentData.PostalCodeId !== args.itemData.PostalCodes[0].Id) {
                departmentData.PostalCodeId = args.itemData.PostalCodes[0].Id;
            }

            let selectedRegion = this.regions.find(x => x.Id === args.itemData.RegionId);
            this.ddlRegionRef.value = args.itemData.RegionId;
            this.ddlRegionRef.text = selectedRegion.Name;
            
            departmentData.CityId = args.value;
            departmentData.RegionId = args.itemData.RegionId;
            departmentData.CountryId = selectedRegion.CountryId;

            this.setState({ departmentData });
        }
    }

    onDepartmentRegionChange(args) {
        if (args.isInteracted) {
            let { departmentData } = this.state;
            departmentData.RegionId = args.value;
            departmentData.CountryId = args.itemData.CountryId;
            this.setState({ departmentData });
        }
    }

    onDepartmentDeliveryPostalCodeChange(args) {
        if (args.isInteracted) {
            let { departmentData } = this.state;
            let city = this.deliveryCities.find(c => c.Id === args.itemData.CityId);
            // this.ddlDeliveryCityRef.text = null;
            // this.ddlDeliveryCityRef.dataSource = tempCities;
            // this.ddlDeliveryCityRef.dataBind();

            this.ddlDeliveryCityRef.value = args.itemData.CityId;

            let selectedRegion = this.regions.find(x => x.Id === city.RegionId);

            this.ddlDeliveryRegionRef.value = city.RegionId;
            this.ddlDeliveryRegionRef.text =  selectedRegion.Name;
            departmentData.DeliveryPostalCodeId =  args.value;
            departmentData.DeliveryCityId = args.itemData.CityId;
            departmentData.DeliveryRegionId = city.RegionId;
            departmentData.DeliveryCountryId = selectedRegion.CountryId;

            this.setState({ departmentData });
        }
    }

    onDepartmentDeliveryCityChange(args) {
        if (args.isInteracted) {
            let { departmentData } = this.state;
            let tempRegions = this.regionSource.filter(region => region.Id === args.itemData.RegionId);
    
            this.deliveryRegions = tempRegions;
            this.ddlDeliveryRegionRef.text = null;
            
            if (departmentData.DeliveryPostalCodeId !== args.itemData.PostalCodes[0].Id) {
                departmentData.DeliveryPostalCodeId = args.itemData.PostalCodes[0].Id;
            }

            let selectedRegion = this.regions.find(x => x.Id === args.itemData.RegionId);
            this.ddlDeliveryRegionRef.value = args.itemData.RegionId;
            this.ddlDeliveryRegionRef.text = selectedRegion.Name;
            
            this.ddlDeliveryCountryRef.value = selectedRegion.CountryId;
            departmentData.DeliveryCityId = args.value;
            departmentData.DeliveryRegionId = args.itemData.RegionId;
            departmentData.DeliveryCountryId = selectedRegion.CountryId
            this.setState({ departmentData });
        }
    }

    onDepartmentDeliveryRegionChange(args) {
        if (args.isInteracted) {
            let { departmentData } = this.state;
            departmentData.DeliveryRegionId = args.value;
            departmentData.DeliveryCountryId = args.value;
            this.setState({ departmentData });
        }
    }

    onDepartmentDeliveryCountryChange(args) {
        if (args.isInteracted) {
            let { departmentData } = this.state;
            departmentData.DeliveryCountryId = args.value;
            this.setState({ departmentData });
        }
    }

    accountDepartmentTab() {
        return (
            <div className="tab-control--item">
                <GridComponent id="account-department-grid" ref={ref => this.accountDepartmentGridRef = ref } dataSource={this.accountDepartmentData} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings} 
                    toolbar={this.accountDepartmentToolbarOptions} editSettings={this.accountDepartmentEditSettings} selectionSettings={this.selectionSettings} allowFiltering={true} allowTextWrap='true'
                    filterSettings={this.filterSettings} locale="da" actionBegin={this.onAccountDepartmentActionBegin.bind(this)} gridLines="Both" allowReordering={true} allowResizing={true} 
                    actionComplete={this.onAccountDepartmentActionComplete.bind(this)} columnDragStart={this.onColumnDragStart.bind(this)} created={this.onAccountDepartmentCreated.bind(this)}
                    resizeStop={this.onAccountDepartmentResizeStop.bind(this)} toolbarClick={this.departmentToolbarClicked}>
                    <ColumnsDirective>
                        <ColumnDirective headerText="Afdeling Nr." field="Id"/> 
                        <ColumnDirective headerText="Afdelings Navn" field="Name" />
                        <ColumnDirective headerText="Adresse" field="Street" /> 
                        <ColumnDirective headerText="Post Nr" field="PostalCodeName" /> 
                        <ColumnDirective headerText="By" field="CityName" /> 
                        <ColumnDirective headerText="Region" field="RegionName" /> 
                        <ColumnDirective headerText="Land" field="CountryName" /> 
                        <ColumnDirective headerText="Automat" field="MachineNames" /> 
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Selection, Toolbar, Edit, Reorder, Resize]} />
                </GridComponent>
            </div>
        )
    }

    async primaryContactToolbarClick(args) {
        if (this.primaryContactGridRef && args.item.id === 'undo') {
            const selectedRow = this.primaryContactGridRef.getSelectedRecords();
            if (selectedRow.length > 0) {
                const result = await DepartmentService.UndoDepartment(selectedRow[0].Id);
                
                if (!result.HasError) {
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Primærer Kontakt"));
                }
                else {
                    this.dialogOpen("Advarsel", result.ErrorMessage);
                }
            }
            else {
                this.dialogOpen("Advarsel", Localization.General.NoRowSelected);
            }
        }
        else if (this.primaryContactGridRef && args.item.id === 'redo') {
            const selectedRow = this.primaryContactGridRef.getSelectedRecords();
            if (selectedRow.length > 0) {
                const result = await DepartmentService.RedoDepartment(selectedRow[0].Id);
                
                if (!result.HasError) {
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Primærer Kontakt"));
                }
                else {
                    this.dialogOpen("Advarsel", Localization.General.NoRowSelected);
                }
            }
            else {
                this.dialogOpen("Advarsel", Localization.General.NoRowSelected);
            }
        }
        else if (args.item.id === 'add') {
            this.primaryContactDialog.show();
        }
        else if (args.item.id === 'reset_filter') {
            // if (this.PrimaryContactGridSettings) {
            //     let settings = JSON.parse(this.PrimaryContactGridSettings);
            //     settings.filters = [];
            //     this.PrimaryContactGridSettings = settings;
            //     const payload = {
            //         Key: this.PrimaryContactGridSettingsKey,
            //         Settings: JSON.stringify(this.PrimaryContactGridSettings)
            //     };
    
            //     const result = await SettingsService.SaveGridSettings(payload, source.token);
    
            //     if (!result.HasError) {
            //         this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            //     }
            // }

            this.primaryContactGridRef.clearFiltering();
        }
    }

    onColumnDragStart(args) {
        this.hasDraggedColumn = true;
    }

    onPrimaryContactCreated() {
        if (this.PrimaryContactGridSettings) {
            let settings = JSON.parse(this.PrimaryContactGridSettings);
            if (settings ) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(column => {
                        if (column.FromIndex !== column.ToIndex) {
                            var tempIndex = this.primaryContactGridRef.getColumnIndexByField(column.Field);
                            if (tempIndex !== column.ToIndex){
                                this.primaryContactGridRef.reorderColumnByIndex(column.FromIndex, column.ToIndex);
                            }
                        }
    
                        if (column.Width) {
                            this.primaryContactGridRef.columns[column.ToIndex].width = column.Width;
                        }
                    });
                }

                if (settings.colName && settings.direction) {
                    this.primaryContactGridRef.sortColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.primaryContactGridRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.primaryContactGridRef.refreshColumns();

                // Prevent resizing of last column
                let lastColumnIndex = this.primaryContactGridRef.columns.length - 1;    
                this.primaryContactGridRef.columns[lastColumnIndex].allowResizing = false;
                this.primaryContactGridRef.refreshColumns();

                if (settings.pageSize) {
                    if (settings.pageSize === "Alle") {
                        this.primaryContactGridRef.pageSettings.pageSize = this.primaryContactGridRef.pageSettings.totalRecordsCount;
                        document.querySelector("#account-primary-contact-grid .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.primaryContactGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
            }
            // if (settings.length > 0) {
            //     settings.forEach(setting => {
            //         if (setting.FromIndex !== setting.ToIndex) {
            //             this.primaryContactGridRef.reorderColumnByIndex(setting.FromIndex, setting.ToIndex);
            //         }

            //         if (setting.Width) {
            //             this.primaryContactGridRef.columns[setting.ToIndex].width = setting.Width;
            //         }
            //     });
            //     this.primaryContactGridRef.refreshColumns();
            // }
        }
    }

    async onPrimaryContactResizeStop(args) {
        args.requestType = "resizingGrid";
        let columns = this.primaryContactGridRef.getColumns();
        let tempGridSettings = buildGridSettings(args, this.PrimaryContactGridSettings, 0, 0, false, columns);
        this.PrimaryContactGridSettings = tempGridSettings;
        const payload = {
            Key: this.PrimaryContactGridSettingsKey,
            Settings: this.PrimaryContactGridSettings
        };

        const result = await SettingsService.SaveGridSettings(payload, source.token);

        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);

            // Adjust size of last column to fill spaces
            let lastColumnIndex = this.primaryContactGridRef.columns.length - 1;
            let totalColumnWidth = this.primaryContactGridRef.columns.reduce((acc, curVal) => acc + parseInt(curVal.width), 0);
            let lastColumnWidth = this.primaryContactGridRef.element.clientWidth - totalColumnWidth;
            this.primaryContactGridRef.columns[lastColumnIndex].width = this.primaryContactGridRef.columns[lastColumnIndex].width + lastColumnWidth;
            this.primaryContactGridRef.refreshColumns();
        }
    }

    primaryContactTab() {
        return (
            <div className="tab-control--item">
                <GridComponent id="account-primary-contact-grid" ref={ref => this.primaryContactGridRef = ref } dataSource={this.accountPrimaryContactData} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings} toolbar={this.accountPrimaryContactToolbarOptions}
                    editSettings={this.accountPrimaryContactEditSettings} selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" allowTextWrap='true'
                    actionComplete={this.onPrimaryContactActionComplete.bind(this)} gridLines="Both" toolbarClick={this.primaryContactToolbarClick.bind(this)} allowReordering={true} allowResizing={true}
                    columnDragStart={this.onColumnDragStart.bind(this)} created={this.onPrimaryContactCreated.bind(this)} resizeStop={this.onPrimaryContactResizeStop.bind(this)}>
                    <ColumnsDirective>
                        <ColumnDirective field="Name" headerText="Navn" />
                        <ColumnDirective field="DepartmentName" headerText="Afdelings Navn" />
                        <ColumnDirective field="Position" headerText="Stilling" />
                        <ColumnDirective field="PhoneNumber" headerText="Tlf. Nr." />
                        <ColumnDirective field="Email" headerText="Mail" />
                        <ColumnDirective field="Department.MachineNames" headerText="Automat" />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Selection, Toolbar, Edit, Reorder, Resize]} />
                </GridComponent>
            </div>
        )
    }

    async onAccountSettingsToolbarClicked(args) {
        if (args.item.id === 'edit') {
            this.AccountSettingsToolbarRef.enableItems(0, false);
            this.AccountSettingsToolbarRef.enableItems(1, true);
            this.AccountSettingsToolbarRef.enableItems(2, true);
            this.toggleAccountSettingsFields(true);
        }
        else if (args.item.id === 'update') {
            let { data } = this.state;

            // let businessCategoryIds = this.accountSettingsBranchCategoryRef.selectedNodes;
            // businessCategoryIds.forEach(b => parseInt(b));

            if (data.CustomerGroupId === 1 || (data.CreatedById === this.ParentAcctId) ) {
                if (data.BusinessCategories.length <= 0) {
                    this.dialogOpen("Advarsel", "Branche Kategori kræves");
                    return;
                }
            }

            const payload = {
                Id: data.Id,
                Active: data.Account.Active,
                CompanyName:  data.CompanyName,
                Street: data.Street,
                PostalCodeId: parseInt(data.PostalCodeId),
                Country: data.Country,
                VatNo: data.VatNo,
                ParentId: data.ParentId,
                CustomerGroupId: data.CustomerGroupId,
                AccountId: data.AccountId,
                IntUserName: data.Account.IntUserName,
                FmUserName: data.Account.FmUserName,
                FirstName: data.Account.FirstName,
                MiddleName: data.Account.MiddleName,
                LastName: data.Account.LastName,
                Email: data.Email,
                ContactMail: data.ContactMail,
                PhoneNumber: data.PhoneNumber,
                BusinessCategories: data.BusinessCategories,
                AccessControlPanel: data.Account.AccessControlPanel,
                TwoFactorEnabled: data.Account.TwoFactorEnabled
            };
            
            const result = await CustomerService.AddUpdateCustomer('update', payload, source.token);

            if (!result.HasError) {
                this.AccountSettingsToolbarRef.enableItems(0, true);
                this.AccountSettingsToolbarRef.enableItems(1, false);
                this.AccountSettingsToolbarRef.enableItems(2, false);
                this.toggleAccountSettingsFields(false);
                this.undoRedoOptions(result.UndoRedoStatus.Undo, result.UndoRedoStatus.Redo, result.UndoRedoStatus.UndoAction, result.UndoRedoStatus.RedoAction);
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Konto indstillinger"));
                this.setState({ hasUnsavedChanges: false, data });
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
        else if (args.item.id === 'cancel') {
            await this.refreshAccountInfoData().then(() => {
                this.AccountSettingsToolbarRef.enableItems(0, true);
                this.AccountSettingsToolbarRef.enableItems(1, false);
                this.AccountSettingsToolbarRef.enableItems(2, false);
                this.toggleAccountSettingsFields(false);
            });
        }
    }

    toggleAccountSettingsFields(enabled) {
        let { data } = this.state;
        
        // Allow ILX group and parent account to modify field
        if (data.CustomerGroupId === 1 || (data.CreatedById === this.ParentAcctId) ) {
            this.accountSettingsBranchCategoryRef.disabled = !enabled;
        }
        
        this.enableTwofactorRef.disabled = !enabled;
    }

    accountSettingsTab() {
        const { data } = this.state;

        let customerGroup = [
            {
                Id: data.CustomerGroupId,
                Name: data.CustomerGroup.Name
            }
        ]

        return (
            <div className="tab-control--item account-settings">
                <div className="account-toolbar-wrapper">
                    <ToolbarComponent ref={ref => this.AccountSettingsToolbarRef = ref} clicked={this.onAccountSettingsToolbarClicked}>
                        <ItemsDirective>
                            <ItemDirective id="edit" prefixIcon='tb-icons c-icon-edit' text="Redigere" tooltipText='Redigere' visible={super.hasTabFunction("Konto Info", "Redigere")} />
                            <ItemDirective id="update" prefixIcon='tb-icons c-icon-update' text="Gem" tooltipText='Gem' visible={super.hasTabFunction("Konto Info", "Redigere")} />
                            <ItemDirective id="cancel" prefixIcon='tb-icons c-icon-cancel' text="Annuller" tooltipText='Annuller' visible={super.hasTabFunction("Konto Info", "Redigere")} />
                        </ItemsDirective>
                    </ToolbarComponent>
                </div>
                <div>
                    <div className="account-settings--branch-category">
                        <Card headerText="Branche Kategori" subText="Vælg en eller flere brancher nedenfor">
                            <div style={{marginBottom: 15}}>Klik på + for tilhørende funktioner</div>
                            <TreeViewComponent ref={treeview => this.accountSettingsBranchCategoryRef = treeview} fields={this.branchCategoryFields} expandOn="Click" autoCheck={false}
                                showCheckBox={true} disabled={true} id="account-business-category-tree" nodeChecked={this.branchCategoryNodeSelected.bind(this)} nodeClicked={this.branchCategoryNodeClicked.bind(this)} />
                        </Card>
                    </div>
                    <div className="account-settings--misc-info">
                        <div>
                            <Card headerText="Kundegruppe">
                                {/* {data.CustomerGroup.Name} */}
                                <DropDownListComponent id="ddlCustomerGroup" ref={ref => this.ddlCustomerGroupRef = ref} name="CustomerGroupId" value={data.CustomerGroupId} dataSource={customerGroup} 
                                    placeholder={Localization.Customer.SearchCustomerGroup} fields={{ text: 'Name', value: 'Id' }} change={this.onCustomerGroupChange.bind(this)} enabled={false} />
                            </Card>
                        </div>
                        <div>
                            <Card headerText="Log in Regel på kontrolpanel">
                                <div className="field-bordered">
                                    <label htmlFor="enableTwofactor">To Faktor Godkendelse</label>
                                    <SwitchComponent id="enableTwofactor" ref={ref => this.enableTwofactorRef = ref} disabled={true} checked={data.Account.TwoFactorEnabled} change={this.onChangeTwoFactorState.bind(this)} cssClass="control-right" />
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    branchCategoryNodeSelected(args) {
        if (args.isInteracted) {
            let { data } = this.state;
            
            if (args.data[0].isChecked === "true") {
                data.BusinessCategories.push(parseInt(args.data[0].id));
            }
            else {
                let bIndex = data.BusinessCategories.indexOf(parseInt(args.data[0].id));
                data.BusinessCategories.splice(bIndex, 1);
            }

            this.setState({ data, hasUnsavedChanges: true });
        }
    }

    branchCategoryNodeClicked(args) {
        let { data } = this.state;
        let node = [args.node];
        if (args.event.target.classList.contains('e-fullrow') || args.event.key == "Enter") {
            let getNodeDetails = this.accountSettingsBranchCategoryRef.getNode(args.node);
            
            if (getNodeDetails.isChecked == "true") {
                this.accountSettingsBranchCategoryRef.uncheckAll(node);
                let bIndex = data.BusinessCategories.indexOf(parseInt(getNodeDetails.id));
                data.BusinessCategories.splice(bIndex, 1);
            }
            else {
                data.BusinessCategories.push(parseInt(getNodeDetails.id));
                this.accountSettingsBranchCategoryRef.checkAll(node);
            }
        }

        this.setState({ hasUnsavedChanges: true, data });
    }

    onCustomerGroupChange(args) {

    }

    onChangeTwoFactorState (args) {
        let { data } = this.state;
        data.Account.TwoFactorEnabled = args.checked;
        this.setState({ data, hasUnsavedChanges: true });
    }

    onChangeActiveState(args) {

    }

    tabSelected(args) {
        const { breadcrumbs, data } = this.state;

        if (breadcrumbs.length > 1) {
            breadcrumbs.pop();
        }
        breadcrumbs.push(this.tabHeader[args.selectedIndex].text);

        const headerText = this.tabHeader[args.selectedIndex].text;
        let headerElement = document.querySelector('.module-header > h1');
        headerElement.innerHTML = headerText;

        // setTimeout(() => {
        //     let primaryContactGrid = document.getElementById('account-primary-contact-grid');
        //     if (primaryContactGrid) {
        //         let addPrimaryContactButton = primaryContactGrid.querySelector('button[aria-label="Ny"]');
        //         if (addPrimaryContactButton) {
        //             let buttonText = addPrimaryContactButton.querySelector('span.e-tbar-btn-text');
        //             buttonText.innerHTML = "Tilføj Kontakt";
        //         }
        //     }
        // }, 200);

        this.undoRedoOptions(false, false, '', '');
        this.setState({ breadcrumbs, currentTabIndex: args.selectedIndex }, () => {
            switch (args.selectedIndex) {
                case 3:
                    let categories = [];
                    Object.assign(categories, data.BusinessCategories);
                    categories = categories.map(c => c.toString());
                    this.accountSettingsBranchCategoryRef.checkedNodes = categories;

                    this.AccountSettingsToolbarRef.enableItems(0, true);
                    this.AccountSettingsToolbarRef.enableItems(1, false);
                    this.AccountSettingsToolbarRef.enableItems(2, false);
                    break;
            }
        });
    }

    async onAccountToolbarClicked(args) {
        const selectedTab = this.accountTabRef.selectedItem;

        if (args.item.id === 'undo') {
            let result = null;

            switch(selectedTab) {
                case 0:
                case 2:
                case 3:
                    result = await CustomerService.UndoCustomer(this.CustomerId, source.token);
                    break;
                case 1:
                    result = await DepartmentService.UndoDepartment(this.CustomerId, source.token);
                    break;
            }
            
            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Kontokort"));
                this.undoRedoOptions(result.Undo, result.Redo, result.UndoAction, result.RedoAction);
                this.reloadAccount();
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
        else if (args.item.id === 'redo') {
            let result = null;

            switch(selectedTab) {
                case 0:
                case 2:
                case 3:
                    result = await CustomerService.RedoCustomer(this.CustomerId, source.token);
                    break;
                case 1:
                    result = await DepartmentService.RedoDepartment(this.CustomerId, source.token);
                    break;
            }

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Kontokort"));
                this.undoRedoOptions(result.Undo, result.Redo,  result.UndoAction, result.RedoAction);
                this.reloadAccount();
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
    }    

    undoRedoOptions(hasUndo, hasRedo, undoTooltip = '', redoTooltip = '') {
        this.AccountToolbarRef.items[0].disabled = !hasUndo;
        this.AccountToolbarRef.items[0].tooltipText = undoTooltip;
        this.AccountToolbarRef.items[1].disabled = !hasRedo;
        this.AccountToolbarRef.items[1].tooltipText = redoTooltip;
        let { undoRedoStatus } = this.state;
        undoRedoStatus.Undo = hasUndo;
        undoRedoStatus.Redo = hasRedo
        undoRedoStatus.UndoTooltip = undoTooltip;
        undoRedoStatus.RedoTooltip = redoTooltip;
        this.setState({ undoRedoStatus });
    }

    async reloadAccount() {
        const selectedTab = this.accountTabRef.selectedItem;
        let { data } = this.state;

        switch(selectedTab) {
            case 0:
                const result = await CustomerService.GetCustomer(this.CustomerId, source.token);

                data = Object.assign(data, result);

                this.CompanyNameRef.value = result.CompanyName;
                this.StreetRef.value = result.Street;
                this.ddlPostalCodeRef.value = result.PostalCode.Id;
                this.ddlCityRef.value = result.City.Id;
                this.ddlRegionRef.value = result.Region.Id;
                this.ddlCountryRef.value = result.Country.Id;
                this.AccountPhonenumberRef.value = result.Account.PhoneNumber;
                this.EmailRef.value = result.Account.Email;
                this.VatNoRef.value = result.VatNo;

                this.setState({ data });
                break;
            case 1:
                this.refreshAccountDepartmentData();
                break;
            case 2:
                this.refreshPrimaryContactData();
                break;
            case 3:
                break;
            default:
                break;
        }
    }

    // primaryContactDialogTemplate(props) {
    //     return <PrimaryContact {...props} />
    // }

    dialogOpen(header, content) {
        this.dialogInstance.header = header;
        this.dialogInstance.content = content;
        this.dialogInstance.show();
    }

    dialogClose() {
        let { dialogOpts } = this.state;
        this.dialogInstance.hide();
        if(dialogOpts.redirectOnClose) {
            this.props.history.push(dialogOpts.redirectTo);
        }
    }

    onRowSelected(args) {
        const selectedRows = this.primaryUserGridRef.getSelectedRows();
        const userId = selectedRows[0].querySelector('td').innerText;
        localStorage.setItem(StorageKeys.primaryContactKey, userId);
    }

    onFaqMailClicked() {
        this.dialogInstance2.header = "Help";
        this.dialogInstance2.show();
    }

    render() {
        const { loading, dialogOpts, breadcrumbs, departmentData, redirect, hasUnsavedChanges, currentTabIndex, undoRedoStatus } = this.state;

        if (loading) { 
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        return (
            <div className='content-pane-wrapper'>
                <NavigationPrompt when={hasUnsavedChanges} 
                    navigate={path => this.props.history.push(path)}
                    initiateSave={this.initiateAccountInfoSave}
                    shouldBlockNavigation={location => {
                        if (hasUnsavedChanges) {
                            return true;
                        }
                        return false;
                    }}
                />
                {/* <div>
                    <Breadcrumb breadcrumbs={breadcrumbs} />
                </div> */}
                <div className="module-header">
                    <h1>{this.tabHeader[0].text}</h1>
                </div>
                <div className="account-toolbar-wrapper">
                    <ToolbarComponent ref={ref => this.AccountToolbarRef = ref} clicked={this.onAccountToolbarClicked}>
                        <ItemsDirective>
                            <ItemDirective id="undo" prefixIcon='tb-icons c-icon-undo' text="Fortryd" tooltipText={undoRedoStatus.UndoTooltip ? undoRedoStatus.UndoTooltip : 'Fortryd'} disabled={!undoRedoStatus.Undo} 
                                visible={super.hasTabFunction(this.tabHeader[currentTabIndex].text, "Redigere")} />
                            <ItemDirective id="redo" prefixIcon='tb-icons c-icon-redo' text="Gentag" tooltipText={undoRedoStatus.RedoTooltip ? undoRedoStatus.RedoTooltip : 'Gentag'} disabled={!undoRedoStatus.Redo} 
                                visible={super.hasTabFunction(this.tabHeader[currentTabIndex].text, "Redigere")} />
                        </ItemsDirective>
                    </ToolbarComponent>
                </div>
                <div className='control-section tab-control'>
                    <TabComponent ref={ref => this.accountTabRef = ref} heightAdjustMode='Auto' id='account-tab' selected={this.tabSelected.bind(this)}>
                        <TabItemsDirective>
                            <TabItemDirective header={this.tabHeader[0]} content={this.accountInfoTab.bind(this)} visible={super.hasTabAccess("Konto Info")} />
                            <TabItemDirective header={this.tabHeader[1]} content={this.accountDepartmentTab.bind(this)} visible={super.hasTabAccess("Konto Afdeling")} />
                            <TabItemDirective header={this.tabHeader[2]} content={this.primaryContactTab.bind(this)} visible={super.hasTabAccess("Primærer Kontakt")} />
                            <TabItemDirective header={this.tabHeader[3]} content={this.accountSettingsTab.bind(this)} visible={super.hasTabAccess("Konto Indstillinger")} />
                        </TabItemsDirective>
                    </TabComponent>
                </div>
                <DialogComponent id="myaccount-dialog" isModal={true} buttons={this.buttons} width='auto' visible={dialogOpts.visible} showCloseIcon={true}
                    ref={dialog => this.dialogInstance = dialog} target='body' cssClass="dialog-notification" animationSettings={this.animationSettings}allowDragging={true} enableResize={true}  />
                <DialogComponent id="myaccount-dialog" isModal={true} buttons={this.buttons2} width='auto' visible={dialogOpts.visible} showCloseIcon={true}
                    ref={dialog => this.dialogInstance2 = dialog} target='body' cssClass="dialog-notification" animationSettings={this.animationSettings} width={300}>
                        <p width={300}>
                            Denne mail bruges til at sende log in <br/>oplysninger samt til system beskeder ved <br/>oprettelse af en konto. <br/><br/>
                            Ved oprettelse af brugere der tildeles <br/>ansvarsområder, vil mail vedr. disse <br/>ansvarsområder ikke længere blive sendt<br/>til denne mail.
                        </p>
                    </DialogComponent>
                <div>
                    <DialogComponent id="account-department-dialog" isModal={true} buttons={this.departmentButtons} width='auto' ref={dialog => this.departmentDialog = dialog} 
                        target='body' visible={false} showCloseIcon={true} animationSettings={this.animationSettings} allowDragging={true} enableResize={true} >
                            <div className="form account-department-form">
                                <div className="form-title"><h2>Afdeling Detaljer</h2></div>
                                <div className="form-row">
                                    <div className="form-group form-col">
                                        <TextBoxComponent value={departmentData.Id} placeholder="Afdelings Nr.*" name="Id" disabled={true} change={this.onDepartmentChange.bind(this)} floatLabelType="Auto" />
                                    </div>
                                    <div className="form-group form-col">
                                        <TextBoxComponent value={departmentData.Name} placeholder="Afdelings Navn*" name="Name" change={this.onDepartmentChange.bind(this)} floatLabelType="Auto" />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group form-col">
                                        <TextBoxComponent value={departmentData.Street} placeholder="Adresse" name="Street" change={this.onDepartmentChange.bind(this)} floatLabelType="Auto" />
                                    </div>
                                    <div className="form-group form-col">
                                        <DropDownListComponent ref={ref => this.ddlDeptPostalCodeRef = ref} name="PostalCodeId" value={departmentData.PostalCodeId} dataSource={this.postalCodes} filtering={this.onPostalCodeFiltering.bind(this)} filterBarPlaceholder={Localization.General.SearchPostalCode} 
                                            allowFiltering={true} placeholder="Post nr" fields={{ text: 'Value', value: 'Id' }} change={this.onDepartmentPostalCodeChange.bind(this)} floatLabelType="Auto" locale="da" />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group form-col">
                                        <DropDownListComponent ref={ref => this.ddlDeptCityRef = ref} name="City" value={departmentData.CityId} dataSource={this.cities} filtering={this.onCityFiltering.bind(this)} filterBarPlaceholder={Localization.General.SearchCity}
                                        allowFiltering={true} placeholder="By" fields={{ text: 'ShortName', value: 'Id' }} change={this.onDepartmentCityChange.bind(this)} floatLabelType="Auto" locale="da" />
                                    </div>
                                    <div className="form-group form-col">
                                        <DropDownListComponent ref={ref => this.ddlDeptRegionRef = ref} name="Region" value={departmentData.RegionId} dataSource={this.regions} filtering={this.onRegionFiltering.bind(this)} filterBarPlaceholder={Localization.General.SearchRegion}
                                        allowFiltering={true} placeholder="Region" fields={{ text: 'Name', value: 'Id' }} change={this.onDepartmentRegionChange.bind(this)} floatLabelType="Auto" locale="da" />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group form-col">
                                        {/* <TextBoxComponent value={departmentData.Country} placeholder="Land" name="Country" change={this.onDepartmentChange.bind(this)} floatLabelType="Auto" /> */}
                                        <DropDownListComponent ref={ref => this.ddlDeptCountryRef = ref} name="Land" value={departmentData.CountryId} dataSource={this.countries} filtering={this.onCountryFiltering.bind(this)} filterBarPlaceholder={Localization.General.SearchCountry}
                                            allowFiltering={true} placeholder="Land" fields={{ text: 'Name', value: 'Id' }} change={this.onDepartmentRegionChange.bind(this)} floatLabelType="Auto" locale="da" enabled={false} />
                                    </div>
                                    <div className="form-group form-col">
                                        <TextBoxComponent value={departmentData.PhoneNumber} placeholder="Tlf . Nr." name="PhoneNumber" change={this.onDepartmentChange.bind(this)} floatLabelType="Auto" />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group form-col">
                                        <TextBoxComponent value={departmentData.Email} placeholder="Mail" name="Email" change={this.onDepartmentChange.bind(this)} floatLabelType="Auto" />
                                    </div>
                                </div>
                                <div className="form-title"><h2>Leverings Adresse</h2></div>
                                <div className="form-row">
                                    <div className="form-group form-col">
                                        <TextBoxComponent value={departmentData.DeliveryStreet} placeholder="Adresse" name="DeliveryStreet" change={this.onDepartmentChange.bind(this)} floatLabelType="Auto" />
                                    </div>
                                    <div className="form-group form-col">
                                        <DropDownListComponent id="ddlDeliveryPostalCodeId" ref={ref => this.ddlDeliveryPostalCodeRef = ref} name="DeliveryPostalCodeId" value={departmentData.DeliveryPostalCodeId} dataSource={this.deliveryPostalCodes} filtering={this.onDeliveryPostalCodeFiltering.bind(this)} filterBarPlaceholder={Localization.General.SearchPostalCode} 
                                            allowFiltering={true} placeholder="Post nr" fields={{ text: 'Value', value: 'Id' }} change={this.onDepartmentDeliveryPostalCodeChange.bind(this)} floatLabelType="Auto" locale="da" />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group form-col">
                                        <DropDownListComponent id="ddlDeliveryCity" ref={ref => this.ddlDeliveryCityRef = ref} name="DeliveryCityId" value={departmentData.DeliveryCityId} dataSource={this.deliveryCities} filtering={this.onDeliveryCityFiltering.bind(this)} filterBarPlaceholder={Localization.General.SearchCity}
                                        allowFiltering={true} placeholder="By" fields={{ text: 'ShortName', value: 'Id' }} change={this.onDepartmentDeliveryCityChange.bind(this)} floatLabelType="Auto" locale="da" />
                                    </div>
                                    <div className="form-group form-col">
                                        <DropDownListComponent id="ddlDeliveryRegion" ref={ref => this.ddlDeliveryRegionRef = ref} name="DeliveryRegionId" value={departmentData.DeliveryRegionId} dataSource={this.deliveryRegions} filtering={this.onDeliveryRegionFiltering.bind(this)} filterBarPlaceholder={Localization.General.SearchRegion}
                                        allowFiltering={true} placeholder="Region" fields={{ text: 'Name', value: 'Id' }} change={this.onDepartmentDeliveryRegionChange.bind(this)} floatLabelType="Auto" locale="da" />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group form-col">
                                        {/* <TextBoxComponent value={departmentData.DeliveryCountry} placeholder="Land" name="DeliveryCountry" change={this.onDepartmentChange.bind(this)} floatLabelType="Auto" /> */}
                                        <DropDownListComponent id="ddlDeliveryCountry" ref={ref => this.ddlDeliveryCountryRef = ref} name="DeliveryCountryId" value={departmentData.DeliveryCountryId} dataSource={this.deliveryCountries} filtering={this.onDeliveryCountryFiltering.bind(this)} filterBarPlaceholder={Localization.General.SearchCountry}
                                        allowFiltering={true} placeholder="Land" fields={{ text: 'Name', value: 'Id' }} change={this.onDepartmentDeliveryCountryChange.bind(this)} floatLabelType="Auto" locale="da" enabled={false} />
                                    </div>
                                    <div className="form-group form-col">
                                        <TextBoxComponent value={departmentData.DeliveryATT} placeholder="ATT" name="DeliveryATT" change={this.onDepartmentChange.bind(this)} floatLabelType="Auto" />
                                    </div>
                                </div>
                            </div>
                    </DialogComponent>
                    <DialogComponent id="primary-account-dialog" isModal={true} buttons={this.primaryContactButtons} width='auto' ref={dialog => this.primaryContactDialog = dialog} 
                        target='body' visible={false} showCloseIcon={true} animationSettings={this.animationSettings} header="Tilføj Kontakt" allowDragging={true} enableResize={true} >
                        {/* <PrimaryContact /> */}
                        <div style={{marginTop: 20}} className="account-primary-contact-grid">
                            <GridComponent ref={ref => this.primaryUserGridRef = ref } dataSource={this.userListData} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings} 
                                selectionSettings={this.selectionSettings} width="auto" allowFiltering={true} filterSettings={this.filterSettings} locale="da" allowTextWrap='true'
                                rowSelected={this.onRowSelected.bind(this)} gridLines="Both">
                                <ColumnsDirective>
                                    <ColumnDirective field="Id" isPrimary={true} headerText="Bruger nr." width="250px" /> 
                                    <ColumnDirective field="Name" headerText="Navn" width="250px" /> 
                                    <ColumnDirective field="Position" headerText="Stilling" width="250px" /> 
                                    <ColumnDirective field="PhoneNumber" headerText="Tlf. Nr." width="250px" /> 
                                    <ColumnDirective field="Email" headerText="Mail" width="250px" /> 
                                    <ColumnDirective field="Initials" headerText="Initialer" width="250px" /> 
                                    <ColumnDirective field="FmUserName" headerText="Brugernavn" width="250px" /> 
                                    <ColumnDirective field="DepartmentName" headerText="Afdeling" width="250px" /> 
                                    <ColumnDirective field="UserGroupName" headerText="Brugergruppe" width="250px" /> 
                                    <ColumnDirective field="PrimaryContact" headerText="Primær Kontakt" width="250px" /> 
                                </ColumnsDirective>
                                <Inject services={[Page, Sort, Filter, Selection, Toolbar, Edit]} />
                            </GridComponent>
                        </div>
                    </DialogComponent>
                </div>
            </div>
        );
    }
}

export default MyAccount;