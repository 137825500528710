import React from 'react';
import logo from '../assets/logo_ilx_hor.png';
import Localization from '../utilities/Localization';

const NotFound = (props) => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 72px)'
        }}>
            <div className="text-center">
                <img src={logo}/>
                <h1 style={{
                    marginTop: 30
                }}>{Localization.General.PageNotFound}</h1>
            </div>
        </div>
    )
}

export default NotFound;