import HttpClient from '../utilities/HttpClient';

const API = {
    getReport: '/report/{id}',
    deleteReport: '/report/{id}',
    postPutReport: '/report',
    getMenuColumns: '/report/columns',
    getCustomersFlatFile: '/customer/{customerId}/customers/report',
    getUsersFlatFile: '/customer/{customerId}/users/report',
    getOrdersFlatFile: '/order/{customerId}/byCustomer/report',
    getProductsFlatFile: '/product/{customerId}/byCustomer/report',
    getSupplierFlatFile: '/supplier/{customerId}/byCustomer/report',
    getMachineFlatFile: '/machine/{customerId}/byCustomer/report',
    getStockFlatFile: '/stock/{customerId}/byCustomer/report',
    getConsumeFlatFile: '/stock/{customerId}/byCustomer/report/consume',
    postSaveFavorite: '/report/favorite'
};

class ReportService {
    async GetReport(id, cancelToken) {
        const url = API.getReport.replace('{id}', id);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async DeleteReport(id, cancelToken) {
        const url = API.deleteReport.replace('{id}', id);
        return await HttpClient.Delete(url, null, cancelToken);
    }

    async AddUpdateReport(state, data, cancelToken) {
        switch(state){
            case 'add':
                return await HttpClient.Post(API.postPutReport, data, cancelToken);
                break;
            case 'update':
                return await HttpClient.Put(API.postPutReport, data, cancelToken);
                break;
            default:
                return null
        }
    }

    async GetMenuColumns(cancelToken) {
        return await HttpClient.Get(API.getMenuColumns, null, cancelToken);
    }

    async GetCustomersReport(customerId, param, cancelToken) {
        const url = API.getCustomersFlatFile.replace('{customerId}', customerId);
        return await HttpClient.Post(url, param, cancelToken);
    }

    async GetUsersReport(customerId, param, cancelToken) {
        const url = API.getUsersFlatFile.replace('{customerId}', customerId);
        return await HttpClient.Post(url, param, cancelToken);
    }

    async GetOrdersReport(customerId, param, cancelToken) {
        const url = API.getOrdersFlatFile.replace('{customerId}', customerId);
        return await HttpClient.Post(url, param, cancelToken);
    }

    async GetProductsReport(customerId, param, cancelToken) {
        const url = API.getProductsFlatFile.replace('{customerId}', customerId);
        return await HttpClient.Post(url, param, cancelToken);
    }

    async GetSupplierReport(customerId, param, cancelToken) {
        const url = API.getSupplierFlatFile.replace('{customerId}', customerId);
        return await HttpClient.Post(url, param, cancelToken);
    }

    async GetMachineReport(customerId, param, cancelToken) {
        const url = API.getMachineFlatFile.replace('{customerId}', customerId);
        return await HttpClient.Post(url, param, cancelToken);
    }

    async GetStockReport(customerId, param, cancelToken) {
        const url = API.getStockFlatFile.replace('{customerId}', customerId);
        return await HttpClient.Post(url, param, cancelToken);
    }

    async GetConsumeReport(customerId, param, cancelToken) {
        const url = API.getConsumeFlatFile.replace('{customerId}', customerId);
        return await HttpClient.Post(url, param, cancelToken);
    }

    async SaveFavorite(param, cancelToken) {
        return await HttpClient.Post(API.postSaveFavorite, param, cancelToken);
    }
}

const reportService = new ReportService();
export default reportService;