import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false
    },
    resources: {
        da: {
            translation: {
                Cancel: 'Annuller',
                Back: 'Tilbage',
                Submit: 'Submit',
                Next: 'Næste',
                Edit: 'Redigere',
                SuccessAccountCreation: 'Konto blev tilføjet.',
                CheckYourEmailForCredentials: 'Please check your email for your credentials.',
                YouCanCloseWindow: 'You can now close this window.',
                GenericRegistrationErrorMessage: 'There was an error encountered during the registration',
                TitleWholesaler: 'Grossist',
                TitleWholesalerInfoText: 'Indtast grossist nr. på den grossist du har købt OilMat af',
                RequiredFieldsInfoText: 'Felter med * skal udfyldes, øvrige felter kan udfyldes senere',
                TitleCompanyData: 'Firma stamdata',
                TitleSupplierAddress: 'Leveringsadresse',
                TitleUserData: 'Brugerdata',
                TitleOverview: 'Oversigt',
                DataOverviewInfoText: 'Please review the data before you submit',
                LabelEnterWholesalerNo: 'Indtast grossist nr.',
                LabelWholesalerNotFound: 'Grossist ikke fundet – Prøv igen',
                TitleConfirmWholesaler: 'Bekræft grossist',
                TitleConfirmWholesalerInfoText: 'Check venligst nedenstående data er rigtige.',
                LabelWholesalerNo: 'Grossist Nr.',
                LabelWholesaler: 'Grossist',
                LabelCompanyName: 'Firma Navn',
                LabelAddress: 'Adresse',
                LabelPostalCode: 'Post Nr.',
                LabelCity: 'By',
                LabelRegion: 'Region',
                LabelCountry: 'Land',
                LabelTelephoneNumber: 'Tlf. Nr.',
                LabelMail: 'Mail',
                LabelCvrNo: 'Cvr. Nr.',
                LabelInternalAccountNo: 'Internt konto nr.',
                AcceptanceText: 'Du er ved at oprette en konto på OilMat app<br /><br/>Dette kræver at du accepterer vore generelle salgs og leveringsbetingelser samt vores databehandler aftale.<br /><br/>Ovenstående dokumenter kan du finde ved at trykke <a href="https://www.ilxsystem.dk/betingelser" target="_blank">her</a>',
                ConfirmTermsAndCondition: 'Jeg har læst og accepterer vilkårene og betingelserne',
                LabelAccept: 'Accepter',
                SearchPostalCode: 'Søg på postnummer',
                SearchCity: 'Søg på bynavn',
                SearchRegion: 'Søg på region',
                SearchCountry: 'Søg på land',
                SelectCountryOfOrigin: 'Please select your country of origin',
                SearchWholesaler: 'Søg på Grossist',
                DeliveryAddressSameAsInvoiceAddress: 'Leveringsadresse er den samme som fakturaadresse',
                LabelFirstName: 'Fornavn',
                LabelMiddleName: 'Mellemnavn',
                LabelLastName: 'Efternavn',
                LabelInitials: 'Initialer',
                LabelMailUsername: 'Mail / Brugernavn',
                NoRecordFound: 'No record found.',
                Add: 'Add',
            }
        },
        en: {
            translation: {
                Cancel: 'Cancel',
                Back: 'Back',
                Submit: 'Submit',
                Next: 'Next',
                Edit: 'Edit',
                SuccessAccountCreation: 'Account was successfully created.',
                CheckYourEmailForCredentials: 'Please check your email for your credentials.',
                YouCanCloseWindow: 'You can now close this window.',
                GenericRegistrationErrorMessage: 'There was an error encountered during the registration',
                TitleWholesaler: 'Wholesaler',
                TitleWholesalerInfoText: 'Enter the wholesaler number of the wholesaler you bought OilMat from',
                RequiredFieldsInfoText: 'Fields with * must be filled in, other fields can be filled in later',
                TitleCompanyData: 'Company data',
                TitleSupplierAddress: 'Supplier address',
                TitleUserData: 'User Data',
                TitleOverview: 'Data Overview',
                DataOverviewInfoText: 'Please review the data before you submit',
                LabelEnterWholesalerNo: 'Enter wholesaler no.',
                LabelWholesalerNotFound: 'Wholesaler not found - Try again',
                TitleConfirmWholesaler: 'Confirm Wholesaler',
                TitleConfirmWholesalerInfoText: 'Please check the data below if it is correct.',
                LabelWholesalerNo: 'Wholesaler No.',
                LabelWholesaler: 'Wholesaler',
                LabelCompanyName: 'Company name',
                LabelAddress: 'Address',
                LabelPostalCode: 'Postal code',
                LabelCity: 'City',
                LabelRegion: 'Region',
                LabelCountry: 'Country',
                LabelTelephoneNumber: 'Telephone number',
                LabelMail: 'Contact E-mail',
                LabelCvrNo: 'CVR No.', // Not applicable for en language
                LabelInternalAccountNo: 'Internal Account No.',
                AcceptanceText: 'Du er ved at oprette en konto på OilMat app<br /><br/>Dette kræver at du accepterer vore generelle salgs og leveringsbetingelser samt vores databehandler aftale.<br /><br/>Ovenstående dokumenter kan du finde ved at trykke <a href="https://www.ilxsystem.dk/betingelser" target="_blank">her</a>',
                ConfirmTermsAndCondition: 'I have read and accept the terms and conditions',
                LabelAccept: 'Accept',
                SearchPostalCode: 'Search postal code',
                SearchCity: 'Search city',
                SearchRegion: 'Search region',
                SearchCountry: 'Search country',
                SelectCountryOfOrigin: 'Please select your country of origin',
                SearchWholesaler: 'Search wholesaler',
                DeliveryAddressSameAsInvoiceAddress: 'Delivery address is the same as invoice address',
                LabelFirstName: 'First name',
                LabelMiddleName: 'Middle name',
                LabelLastName: 'Last name',
                LabelInitials: 'Initials',
                LabelMailUsername: 'Mail / Username',
                NoRecordFound: 'No record found.',
                Add: 'Add',
            }
        }
    }
});

export default i18n;