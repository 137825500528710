const noop = () => {}

const messages = {
    MB1: { 
        message: 'Du har ikke gemt ændringerne. <br /> Tryk Gem for at gemme eller annuller for at fortryde',
        buttons: [
            // {"Annuller"}, {"Gem"}
            {
                click: noop,
                buttonModel: {
                    content: 'Annuller'
                }
            },
            {
                click: noop,
                buttonModel: {
                    isPrimary: true,
                    content: 'Gem'
                } 
            }
        ]
    },
    MB2: { 
        message: 'Er du sikker på at du vil slette {context_message}. <br />Tryk slet for at slette eller annuller for at fortryde',
        buttons: [ //"Annuller", "Slet"
            {
                click: noop,
                buttonModel: {
                    content: 'Annuller'
                }
            },
            {
                click: noop,
                buttonModel: {
                    isPrimary: true,
                    content: 'Slet'
                } 
            }
        ]
    },
    MB3: { 
        message: 'Du er i gang med at slette data der er i brug, dataen vil blive gjort inaktiv. <br />Tryk OK for at fortsætte eller annuller for at fortryde.',
        buttons: [ //"Annuller", "OK"
            {
                click: noop,
                buttonModel: {
                    content: 'Annuller'
                }
            },
            {
                click: noop,
                buttonModel: {
                    isPrimary: true,
                    content: 'OK'
                } 
            }
        ]
    },
    MB4: { 
        message: 'Du mangler at udfylde felter, makeret med rødt.',
        buttons: [ //"OK"
            {
                click: noop,
                buttonModel: {
                    isPrimary: true,
                    content: 'OK'
                } 
            }
        ]
    },
    MB5: { 
        message: 'Du er ved at gemme ændringerne <br/> tryk Gem for at gemme eller annuller for at fortryde',
        buttons: [ //"Annuller", "Gem"
            {
                click: noop,
                buttonModel: {
                    content: 'Annuller'
                }
            },
            {
                click: noop,
                buttonModel: {
                    isPrimary: true,
                    content: 'Gem'
                } 
            }
        ]
    },
    MB6: { 
        message: 'Du er ved at slette blokering af denne notification <br />tryk Ok for at slette eller annuller for at fortryde',
        buttons: [ //"Annuller", "OK"
            {
                click: noop,
                buttonModel: {
                    content: 'Annuller'
                }
            },
            {
                click: noop,
                buttonModel: {
                    isPrimary: true,
                    content: 'OK'
                } 
            }
        ]
    },
    MB7: { 
        message: 'Du er ved at slette {context} <br/> tryk Slet for at slette eller annuller for at fortryde',
        buttons: [ //"Annuller", "Slet"
            {
                click: noop,
                buttonModel: {
                    content: 'Annuller'
                }
            },
            {
                click: noop,
                buttonModel: {
                    isPrimary: true,
                    content: 'Slet'
                } 
            }
        ]
    },
    CancelAssignProductToLocation: {
        message: 'Du har ikke gemt ændringerne <br>Vil du vende tilbage til produktlisten',
        buttons: [ //"Annuller", "Slet"
            {
                click: noop,
                buttonModel: {
                    content: 'Nej'
                }
            },
            {
                click: noop,
                buttonModel: {
                    isPrimary: true,
                    content: 'Ja'
                } 
            }
        ]
    }
}

export default messages;