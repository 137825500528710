import React, { Component } from 'react';
import { TreeGridComponent, ColumnsDirective as TreeGridColumnsDirective, ColumnDirective as TreeGridColumnDirective, Filter as TreeGridFilter, 
    Sort as TreeGridSort, Inject as TreeGridInject, Edit as TreeGridEdit, Page as TreeGridPage, Toolbar as TreeGridToolbar } from '@syncfusion/ej2-react-treegrid';
import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { FileManagerComponent, Inject, NavigationPane, DetailsView, Toolbar as FileManagerToolbar } from '@syncfusion/ej2-react-filemanager';

import Card from '../../components/Card';
import Image from '../../components/Image';

import SettingsService from '../../services/SettingsService';
import SessionService from '../../services/SessionService';
import ProductGroupService from '../../services/Product/ProductGroupService';
import { FileArchiveAPI } from '../../services/File/FileService';
import ExpenseGroupService from '../../services/Customer/ExpenseGroupService';

import Axios from 'axios';
import AppContext from '../../context/AppContext';
import Localization from '../../utilities/Localization';
import { buildGridSettings } from '../../utilities/Helper';
const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class ProductGroupSettings extends Component {
    addEditState = '';
    selectedProductGroup = null;
    gridSettingsKey = "ProductGroupsList";
    gridImgSettingsKey = "ProductGroupImagesList";

    constructor() {
        super();

        this.state = {
            loading: true,
            data: {
                ProductGroupName: '',
                selectedGroup: null,
                Factor: 0,
                ProfitPercentage: null,
                GuaranteedWeight: 0,
                PurchaseTolerance: 0,
                ActiveCaseNumber: false
            },
            linkEditDialog: {
                token: null
            }
        }

        this.pageSettings = {
            pageSize: 10,
            pageSizes: ['Alle', '5', '10', '20', '50', '100']
        };

        this.pageImgSettings = {
            pageSize: 10,
            pageSizes: ['Alle', '5', '10', '20', '50', '100']
        };

        this.productGroupSettingsRef = null;
        this.productGroupPicturesRef = null;
        
        this.productGroupLiteData = [];
        this.productGroupSettingsData = [];
        this.productGroupPicturesData = [];

        this.UnderWeightData = [
            { Id: 1, text: "Indtast altid antal" },
            { Id: 2, text: "Vej altid" },
            { Id: 3, text: "Vælg ved træk" },
        ];

        this.selectionSettings = {
            type: 'Single'
        };
        
        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        /* End Dialog options  */

        this.primaryProductGroupFields = {
            dataSource: [], 
            id: 'Id', text: 'Name', 
            child: 'SubGroups' 
        };

        this.primaryProductImageFields = {
            dataSource: [], 
            id: 'Key', text: 'Name', 
            child: 'SubFiles' 
        };

        this.init = this.init.bind(this);
        this.productSettingsActionBegin = this.productSettingsActionBegin.bind(this);
        this.onToolbarClick = this.onToolbarClick.bind(this);
        this.onSaveProductGroup = this.onSaveProductGroup.bind(this);
        this.doAddUpdateProductGroup = this.doAddUpdateProductGroup.bind(this);
        this.addEditDialogClose = this.addEditDialogClose.bind(this);
        this.onProductGroupChecked = this.onProductGroupChecked.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
        this.refreshProductGroupPictureGrid = this.refreshProductGroupPictureGrid.bind(this);

        this.linkEditDialogClose = this.linkEditDialogClose.bind(this);
        this.onSaveProductPicture = this.onSaveProductPicture.bind(this);
        this.onProductPictureToolbarClick = this.onProductPictureToolbarClick.bind(this);
        this.beforeSend = this.beforeSend.bind(this);
        this.beforeImageLoad = this.beforeImageLoad.bind(this);
        this.handleImgExpandCollapse = this.handleImgExpandCollapse.bind(this);
        this.handleExpandCollapse = this.handleExpandCollapse.bind(this);
        this.onProductImageCardExpanded = this.onProductImageCardExpanded.bind(this);
    }

    async componentDidMount() {
        source.cancel();
        source = CancelToken.source();
        this.init();
        const { linkEditDialog } = this.state;
        const sessionInfo = await SessionService.GetSessionInfo();
        linkEditDialog.token = sessionInfo.access_token;

        const productGroups = await ProductGroupService.GetProductGroups(sessionInfo.Id, source.token);
        
        this.productGroupSettingsData = productGroups;
        this.productGroupPicturesData = productGroups;

        const result = await ProductGroupService.GetProductGroupsLite(sessionInfo.Id, source.token);
        this.productGroupLiteData = result;
        this.primaryProductGroupFields.dataSource = result;

        const expenseGroups = await ExpenseGroupService.GetList(sessionInfo.Id, source.token);
        this.expenseGroups = expenseGroups;

        const gridSettings = await SettingsService.GetGridSettings(this.gridSettingsKey, source.token);
        if (!gridSettings.HasError) {
            this.gridSettings = gridSettings.Settings;
            let settings = JSON.parse(this.gridSettings);
            if (settings && settings.pageSize) {
                this.pageSettings.pageSize = settings.pageSize === "Alle" ? this.countProductGroupsLength(productGroups) : settings.pageSize;
            }
        }

        const gridImgSettings = await SettingsService.GetGridSettings(this.gridImgSettingsKey, source.token);
        if (!gridImgSettings.HasError) {
            this.gridImgSettings = gridImgSettings.Settings;
            let settings = JSON.parse(this.gridImgSettings);
            if (settings && settings.pageSize) {
                this.pageImgSettings.pageSize = settings.pageSize === "Alle" ? this.countProductGroupsLength(productGroups) : settings.pageSize;
            }
        }

        this.setState({ loading: false, linkEditDialog }, () => {
            window.addEventListener('wheel', this.onWheelScroll);
        });
    }

    countProductGroupsLength(productGroups) {
        let count = 0;
        productGroups.forEach(group => {
            if (group.SubGroups) {
                count += this.countProductGroupsLength(group.SubGroups);
            }

            if (group.Products) {
                count += group.Products.length;
            }

            count++;
        });

        return count;
    }

    componentWillUnmount() {
        source.cancel();
    }

    init() {
        const { permission, hasProductAccess } = this.props;
        this.productGroupSettingsToolbarOptions = [];
        this.productGrouppictureToolbarOptions = [];
        this.defaultEditSettings = {
            mode: 'row',
        };

        this.fileManagerToolbarSettings = {};
        this.fileManagerToolbarSettings.items = [];
        this.fileManagerContextMenuSettings = {
            file: ['Open', '|', 'Details'],
            folder: ['Open', '|', 'Details'],
            layout: ['SortBy', 'View', 'Refresh', '|', 'Details', '|', 'SelectAll']
        };
        
        if (hasProductAccess) {
            this.productGroupSettingsToolbarOptions.push({ text: 'Åben', tooltipText: 'Åben', id: 'openProduct', prefixIcon: 'e-external-link' });
        }
        // if (permission.Functions.some(f => f === "Tilføj")) {
            this.productGroupSettingsToolbarOptions.push({ text: 'Ny', tooltipText: 'Ny', id: 'addProductGroup', prefixIcon: 'e-add' });
        // }
        // if (permission.Functions.some(f => f === "Redigere")) {
            this.productGroupSettingsToolbarOptions.push({ text: 'Redigere', tooltipText: 'Redigere', id: 'editProductGroup', prefixIcon: 'e-edit' });
            this.productGrouppictureToolbarOptions.push({ text: 'Redigere', tooltipText: 'Redigere', id: 'editProductImage', prefixIcon: 'e-edit' })
        // }
        // if (permission.Functions.some(f => f === "Slette")) {
            this.productGroupSettingsToolbarOptions.push('Delete');
            this.productGrouppictureToolbarOptions.push({ text: 'Slette', tooltipText: 'Slette', id: 'deleteProductImage', prefixIcon: 'e-delete' });
            this.defaultEditSettings.allowDeleting = true;
            this.defaultEditSettingsshowDeleteConfirmDialog = true;
        // }

        this.productGroupSettingsToolbarOptions.push({ text: Localization.General.GridResetFilter, tooltipText: Localization.General.GridResetFilter, id: 'reset_filter', prefixIcon: 'e-cancel' });
        this.productGrouppictureToolbarOptions.push({ text: Localization.General.GridResetFilter, tooltipText: Localization.General.GridResetFilter, id: 'reset_filter', prefixIcon: 'e-cancel' });

        this.fileManagerToolbarSettings.items.push('SortBy');
        this.fileManagerToolbarSettings.items.push('Refresh');
        this.fileManagerToolbarSettings.items.push('Selection');
        this.fileManagerToolbarSettings.items.push('View');
        this.fileManagerToolbarSettings.items.push('Details');
    }

    //#region Product Group Settings event handlers and methods
    productTemplate = (props) => {
        if (props.ProductNo && props.Description1) {
            return (
                <div>
                    {`${props.ProductNo}_${props.Description1}`}
                </div>
            )
        }

        return ''
    }

    async productSettingsActionBegin(args) {
        const { onNotify } = this.props;
        if (args.requestType === "delete") {
            args.cancel = true;

            source.cancel();
            source = CancelToken.source();
            const result = await ProductGroupService.DeleteProductGroup(args.data[0].Id, source.token);

            if(!result.HasError) {
                onNotify(Localization.General.SuccessHeader, Localization.General.SuccessDelete.replace("{context}", "Produktgruppe"));
                this.refreshGrid();
            }
            else {
                onNotify("Advarsel", result.ErrorMessage);
            }
        }
    }
    
    async onToolbarClick(args) {
        const { onNotify, onOpenProduct } = this.props;
        this.primaryProductGroupFields.dataSource = this.productGroupLiteData;
        this.productGroupSelectionTree.fields.dataSource = this.productGroupLiteData;
        this.productGroupSelectionTree.refresh();
        if (this.productGroupSettingsRef && args.item.id === 'openProduct') {
            const selectedRows = this.productGroupSettingsRef.getSelectedRecords();
            if (selectedRows.length > 0) {
                if (selectedRows[0].ProductNo) {
                    // onOpenProduct(`/produktkort/edit/${selectedRows[0].Id}?tab=Pris`);
                    window.open(`/produktkort/edit/${selectedRows[0].Id}?tab=Pris`);
                }
                else {
                    onNotify("Advarsel", Localization.Purchase.PleaseSelectProduct);
                }
            }
            else {
                onNotify("Advarsel", Localization.Purchase.PleaseSelectProduct);
            }
        }
        else if (this.productGroupSettingsRef && args.item.id === 'addProductGroup') {
            this.addUpdateProductGroupDialogRef.header = Localization.Product.AddProductGroup;
            this.addUpdateProductGroupDialogRef.show();
            this.addEditState = 'add';
        }
        else if (this.productGroupSettingsRef && args.item.id === 'editProductGroup') {
            let selectedRow = this.productGroupSettingsRef.getSelectedRecords();
            if (selectedRow && selectedRow.length > 0) {
                if ('ParentId' in selectedRow[0]) {
                    let { data } = this.state;
                    this.addEditState = 'edit';
                    data.Id = selectedRow[0].Id;
                    data.ProductGroupName = selectedRow[0].Name;
                    data.Factor = selectedRow[0].Factor ? selectedRow[0].Factor : null;
                    data.ProfitPercentage = selectedRow[0].ProfitPercentage ? parseFloat(selectedRow[0].ProfitPercentage) * 100 : null;                    
                    data.GuaranteedWeight = selectedRow[0].GuaranteedWeight;
                    data.PurchaseTolerance = parseFloat(selectedRow[0].PurchaseTolerance) * 100;
                    data.ActiveCaseNumber = selectedRow[0].ActiveCaseNumber;
                    data.ExpenseGroupId = selectedRow[0].ExpenseGroupId;
                    
                    if (selectedRow[0].ParentId) {
                        this.selectedProductGroup = selectedRow[0].ParentId;
                        this.productGroupSelectionTree.checkedNodes = [selectedRow[0].ParentId.toString()];
                        this.productGroupSelectionTree.refresh();
                    }
                    this.productGroupSelectionTree.removeNodes([selectedRow[0].Id.toString()]);
                    
                    this.setState({ data }, () => {
                        this.addUpdateProductGroupDialogRef.header = Localization.Product.EditProductGroup;
                        this.addUpdateProductGroupDialogRef.show();
    
                    });
                }
                else {
                    onNotify("Advarsel", Localization.Product.PleaseSelectProductGroup);
                }
            }
            else {
                onNotify("Advarsel", Localization.Product.PleaseSelectProductGroup);
            }
        } else if (args.item.id === 'reset_filter') {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                settings.filters = [];
                this.gridSettings = settings;
                const payload = {
                    Key: this.gridSettingsKey,
                    Settings: JSON.stringify(this.gridSettings)
                };
    
                const result = await SettingsService.SaveGridSettings(payload, source.token);
    
                if (!result.HasError) {
                    const {onNotify} = this.props;
                    onNotify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                }
            }

            this.productGroupSettingsRef.clearFiltering();
        }
    }
    
    onSaveProductGroup() {
        const { data } = this.state;
        const { onNotify } = this.props;
        if (data.ProductGroupName !== '') {
            this.doAddUpdateProductGroup(this.addEditState);
        }
        else {
            onNotify("Advarsel", Localization.Product.PleaseEnterGroupName);
        }
    }

    async doAddUpdateProductGroup(action) {
        source.cancel();
        source = CancelToken.source();
        const { data, addEditDialog } = this.state;
        const { onNotify } = this.props;
        const sessionInfo = await SessionService.GetSessionInfo();

        let messageActionText = action === 'add' ? 'added' : 'updated';

        let factor = parseFloat(data.Factor)
        let profitPercentage = parseFloat(data.ProfitPercentage);

        const payload = {
            Id: data.Id,
            CustomerId: parseInt(sessionInfo.Id), 
            Name: data.ProductGroupName,
            ParentId: this.selectedProductGroup,
            Factor: factor === 0 ? null : factor,
            ProfitPercentage: profitPercentage ? parseFloat(profitPercentage) / 100 : (factor === 0 ? 0 : null),
            GuaranteedWeight: this.GuaranteedWeightRef.value,
            PurchaseTolerance: data.PurchaseTolerance ? parseFloat(data.PurchaseTolerance) / 100 : 0,
            ActiveCaseNumber: data.ActiveCaseNumber,
            ExpenseGroupId: this.ExpenseGroupRef.value
        };

        const result = await ProductGroupService.AddUpdateProductGroup(action, payload, source.token);

        if (!result.HasError) {
            // prompt success message
            onNotify(Localization.General.SuccessHeader, `${messageActionText === 'added' ? Localization.General.SuccessAdd.replace("{context}", "Produktgruppe") : Localization.General.SuccessUpdate.replace("{context}", "Produktgruppe")}`);
            
            data.ProductGroupName = '';
            data.selectedGroup = null;
            data.Factor = 0;
            data.ProfitPercentage = null;
            data.GuaranteedWeight = null;
            data.PurchaseTolerance = 0;
            data.Id = null;
            this.addUpdateProductGroupDialogRef.hide();
            this.selectedProductGroup = null;
            this.refreshGrid();
        }
        else {
            onNotify("Advarsel", result.ErrorMessage);
        }

        this.setState({ data, addEditDialog });
    }

    onProductGroupChecked(args) {
        // let { data } = this.state;

        if (args.action === 'check') {
            this.selectedProductGroup = parseInt(args.data[0].id);
            this.productGroupSelectionTree.checkedNodes = [args.data[0].id];
        }
        else if (args.action === 'uncheck') {
            this.selectedProductGroup = null;
            this.productGroupSelectionTree.checkedNodes = [];
        }

        // this.setState({ data });
    }

    caseNumberTemplate(props) {
        return (
            <CheckBoxComponent checked={props.ActiveCaseNumber} disabled={true} />
        )
    }

    addEditDialogClose() {
        let { addEditDialog, data } = this.state;
        this.addEditState = '';
        this.addUpdateProductGroupDialogRef.hide();
        data.ProductGroupName = '';
        data.selectedGroup = 0;
        data.Factor = 0;
        data.ProfitPercentage = 0;
        data.GuaranteedWeight = null;
        data.PurchaseTolerance = 0;
        data.ActiveCaseNumber = false;
        this.productGroupSelectionTree.checkedNodes = [];
        this.setState({ addEditDialog, data });
    }
    //#endregion
    
    //#region Product Group Pictures event handlers and methods
    onRenderProductImage = (props) => {
        if (props.ImageKey) {
            return (
                <div className="product-image-wrapper">
                    <Image src={props.ImageKey} alt="Product" className="product-image-data" />
                </div>
            )
        }

        return <div>{Localization.General.NoImageAvailable}</div>;
    }

    async onProductPictureToolbarClick(args) {
        let { linkEditDialog } = this.state;
        const { onNotify } = this.props;

        if (this.productGroupPicturesRef && args.item.id === 'editProductImage') {
            const selectedRows = this.productGroupPicturesRef.getSelectedRecords();

            if (selectedRows === null || selectedRows.length <= 0) {                
                onNotify("Advarsel", Localization.General.NoRowSelected);
            }
            else {
                const sessionInfo = await SessionService.GetSessionInfo();
                linkEditDialog.token = sessionInfo.access_token;
                linkEditDialog.id = selectedRows[0].Id;
                this.setState({ linkEditDialog }, () => {
                    let { linkEditDialog } = this.state;
                    this.linkEditDialogRef.show();
                    linkEditDialog.visible = true;
                    this.setState({ linkEditDialog });
                });
            }
        }
        else if (this.productGroupPicturesRef && args.item.id === 'deleteProductImage') {
            const selectedRows = this.productGroupPicturesRef.getSelectedRecords();

            if (selectedRows === null || selectedRows.length <= 0) {
                onNotify("Advarsel", Localization.General.NoRowSelected);
            }
            else {
                const payload = {
                    Id: selectedRows[0].Id,
                    RemoveImage: true
                };
        
                const result = await ProductGroupService.LinkUnlinkProductGroupImage(payload, source.token);
        
                if (!result.HasError) {
                    onNotify(Localization.General.SuccessHeader, Localization.Product.SuccessUnlinkedImage);
                    this.refreshProductGroupPictureGrid();
                }
                else {
                    onNotify("Advarsel", result.ErrorMessage);
                }
            }
        } else if (args.item.id === 'reset_filter') {
            if (this.gridImgSettings) {
                let settings = JSON.parse(this.gridImgSettings);
                settings.filters = [];
                this.gridImgSettings = settings;
                const payload = {
                    Key: this.gridImgSettingsKey,
                    Settings: JSON.stringify(this.gridImgSettings)
                };
    
                const result = await SettingsService.SaveGridSettings(payload, source.token);
    
                if (!result.HasError) {
                    const {onNotify} = this.props;
                    onNotify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                }
            }

            this.productGroupPicturesRef.clearFiltering();
        }
    }

    linkEditDialogClose() {
        const { linkEditDialog } = this.state;
        linkEditDialog.visible = false;
        this.setState({ linkEditDialog }, () => {
            this.linkEditDialogRef.hide();
        });
    }

    async onSaveProductPicture() {
        const selectedFiles = this.productImageArchiveRef.getSelectedFiles();
        const { linkEditDialog } = this.state;
        const { onNotify } = this.props;

        if (selectedFiles.length > 0) {
            const filepath = this.productImageArchiveRef.pathNames;
            let imageMimeTypes = ['jpg', 'jpeg', 'png', 'gif'];
            let splitFilename = selectedFiles[0].name.split('.');
            let fileExtension = splitFilename[splitFilename.length - 1];
            if (!imageMimeTypes.includes(fileExtension)) {
                onNotify("Advarsel", Localization.Product.PleaseSelectImage);
                return;
            }
            
            const fullImagePath = `/${filepath.join('/')}/${selectedFiles[0].name}`;

            source.cancel();
            source = CancelToken.source();

            const payload = {
                Id: linkEditDialog.id,
                ImageKey: fullImagePath,
                RemoveImage: false
            };
    
            const result = await ProductGroupService.LinkUnlinkProductGroupImage(payload, source.token);
    
            if (!result.HasError) {
                onNotify(Localization.General.SuccessHeader, Localization.Product.SuccessLinkedImageToProductGroup);
                this.refreshProductGroupPictureGrid().then(res => {
                    linkEditDialog.visible = false;
                    this.setState({ linkEditDialog }, () => {
                        this.linkEditDialogRef.hide();
                    });
                });
            }
            else {
                onNotify("Advarsel", result.ErrorMessage);
            }
        }
        else {
            onNotify("Advarsel", Localization.Product.PleaseSelectImage);
        }
    }

    async doLinkUpdateProductImage() {

    }
    //#endregion

    async refreshGrid() {
        source.cancel();
        source = CancelToken.source();
        
        const sessionInfo = await SessionService.GetSessionInfo();
        const productGroups = await ProductGroupService.GetProductGroups(sessionInfo.Id, source.token);
        
        this.productGroupSettingsRef.dataSource = productGroups;
        this.productGroupSettingsRef.dataBind();
        
        const result = await ProductGroupService.GetProductGroupsLite(sessionInfo.Id, source.token);
        this.productGroupLiteData = result;
        this.productGroupSelectionTree.fields.dataSource = result;
        this.productGroupSelectionTree.dataBind();

        // Refresh pager
        if (!this.productGroupSettingsRef.pageSettings.pageSizes.some(x => x === this.productGroupSettingsRef.pageSettings.pageSize.toString()) ||
            this.productGroupSettingsRef.pageSettings.pageSize === this.productGroupSettingsRef.pageSettings.totalRecordsCount) {
            document.querySelector("#groups-grid .e-pagerdropdown input").value = "Alle";
            this.productGroupSettingsRef.pageSettings.pageSize = this.productGroupSettingsRef.pageSettings.totalRecordsCount;
        }

        if (this.productGroupPicturesRef) {
            this.productGroupPicturesRef.dataSource = productGroups;
            this.productGroupPicturesRef.dataBind();
        }
        
        this.productGroupSettingsData = productGroups;
        this.productGroupPicturesData = productGroups;
    }

    async refreshProductGroupPictureGrid() {
        source.cancel();
        source = CancelToken.source();
        
        const sessionInfo = await SessionService.GetSessionInfo();
        const productGroups = await ProductGroupService.GetProductGroups(sessionInfo.Id, source.token);
        
        this.productGroupPicturesRef.dataSource = productGroups;
        this.productGroupPicturesRef.dataBind();
    }
    
    onChange(args) {
        if (args.isInteracted) {
            let input = args.container.querySelector('input');
    
            if (input) {
                let { data } = this.state;
                data[input.name] = args.value;
                
                if (input.name === 'Factor') {
                    data.ProfitPercentage = null;
                }
                else if (input.name === 'ProfitPercentage') {
                    data.Factor = null;
                }
    
                this.setState({ data });
            }
        }
    }

    checkChange(args) {
        let { data } = this.state;
        data.ActiveCaseNumber = args.checked;
        this.setState({ data });
    }

    onGuaranteedWeightChange(args) {
        if (args.value) {
            let { data } = this.state;
            data.GuaranteedWeight = args.value;
            this.setState({ data });
        }
    }

    purchaseToleranceTemplate(props) {
        let purchaseTolerance = null;
        if (props.PurchaseTolerance) {
            purchaseTolerance = props.PurchaseTolerance * 100;
            return (<div>{purchaseTolerance}%</div>)
        } else{
            return (<div>{purchaseTolerance}</div>)
        }
        
    }

    profitPercentageTemplate(props) {
        let profitPercentage = null;
        if (props.ProfitPercentage) {
            profitPercentage = props.ProfitPercentage * 100;
            return (<div>{profitPercentage}%</div>)
        } else {
            return (<div>{profitPercentage}</div>)
        }
    }

    beforeSend(args) {
        const { linkEditDialog } = this.state;
        args.ajaxSettings.beforeSend = function(e) {
            e.httpRequest.setRequestHeader("Authorization", `Bearer ${linkEditDialog.token}`);
        }
    }

    beforeImageLoad(args) {
        const { linkEditDialog } = this.state;
        args.imageUrl += `&access_token=${linkEditDialog.token}`;
    }

    queryCellinfo(args) {
        if (args.column.field === 'PurchaseTolerance') {
            if (args.data[args.column.field]) {
                args.cell.innerText = (args.data[args.column.field] * 100) + '%';
            }
        }
        else if (args.column.field === 'ProfitPercentage') {
            if (args.data[args.column.field]) {
                args.cell.innerText = args.data[args.column.field] + '%';
            }
        }
    }

    onWheelScroll = () => {
        let grid = document.getElementById('groups-grid');
        let toolbar = document.querySelector('#groups-grid .e-grid .e-toolbar');
        let tableHeader = document.querySelector('#groups-grid .e-gridheader');
        let tablePager = document.querySelector('#groups-grid .e-gridpager');

        if (toolbar && tableHeader) {
            let boundingRect = tableHeader.getBoundingClientRect();
            let pagerBoundingRect = tablePager.getBoundingClientRect();
            
            if (boundingRect.top < 90 && pagerBoundingRect.bottom > 200) {
                toolbar.classList.add('c-hover-grid-toolbar');
                toolbar.style.width = (grid.clientWidth - 5) + 'px';
            }
            else {
                toolbar.classList.remove('c-hover-grid-toolbar');
                toolbar.style.width = 'auto';
            }
        }

        let imgGrid = document.getElementById('group-images-grid');
        let imgToolbar = document.querySelector('#group-images-grid .e-grid .e-toolbar');
        let imgTableHeader = document.querySelector('#group-images-grid .e-gridheader');

        if (imgToolbar && imgTableHeader) {
            let boundingRect = imgTableHeader.getBoundingClientRect();
            
            if (boundingRect.top < 90) {
                imgToolbar.classList.add('c-hover-grid-toolbar');
                imgToolbar.style.width = (imgGrid.clientWidth - 5) + 'px';
            }
            else {
                imgToolbar.classList.remove('c-hover-grid-toolbar');
                imgToolbar.style.width = 'auto';
            }
        }
    }

    async onActionComplete(args) {
        if (args.requestType === 'paging') {
            if (this.productGroupSettingsRef.pageSettings.pageSize === this.productGroupSettingsRef.pageSettings.totalRecordsCount) {
                document.querySelector("#groups-grid .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, this.productGroupSettingsRef.pageSettings.pageSize, this.productGroupSettingsRef.pageSettings.totalRecordsCount, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };
            const result = await SettingsService.SaveGridSettings(payload, source.token);
        }
        else if (args.requestType === 'filtering') {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                const {onNotify} = this.props;
                onNotify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                const {onNotify} = this.props;
                onNotify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.productGroupSettingsRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, this.hasDraggedColumn, columns);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                const {onNotify} = this.props;
                onNotify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }

            this.hasDraggedColumn = false;
        }
        else if (args.requestType === "refresh") {
            if (this.productGroupSettingsRef.pageSettings.pageSize === this.productGroupSettingsRef.pageSettings.totalRecordsCount) {
                setTimeout(() => {
                    let input = document.querySelector("#groups-grid .e-pagerdropdown input");
                    if (input)
                        input.value = "Alle";
                }, 500);
            }
        }
    }

    onCreated() {
        if (this.gridSettings) {
            let settings = JSON.parse(this.gridSettings);
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(columns => {
                        if (columns.FromIndex !== columns.ToIndex) {
                            var tempIndex = this.productGroupSettingsRef.getColumnIndexByField(columns.Field);
                            if (tempIndex !== columns.ToIndex){
                                this.productGroupSettingsRef.reorderColumnByIndex(columns.FromIndex, columns.ToIndex);
                            }
                        }
    
                        if (columns.Width) {
                            this.productGroupSettingsRef.columns[columns.ToIndex].width = columns.Width;
                        }
                    });
                }
                
                if (settings.colName && settings.direction) {
                    this.productGroupSettingsRef.sortByColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.productGroupSettingsRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.productGroupSettingsRef.refreshColumns();

                if (settings.pageSize) {
                    if (settings.pageSize === "Alle" || !this.productGroupSettingsRef.pageSettings.pageSizes.some(x => x === this.pageSettings.pageSize.toString())) {
                        this.productGroupSettingsRef.pageSettings.pageSize = "Alle"; //this.pageSettings.pageSize;
                        document.querySelector("#groups-grid .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.productGroupSettingsRef.pageSettings.pageSize = settings.pageSize;
                    }
                    this.pageSettings.pageSize = this.productGroupSettingsRef.pageSettings.pageSize;
                }
            }
        }
    }

    async onImgActionComplete(args) {
        if (args.requestType === 'paging') {
            if (this.productGroupPicturesRef.pageSettings.pageSize === this.productGroupPicturesRef.pageSettings.totalRecordsCount) {
                document.querySelector("#group-images-grid .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.gridImgSettings, this.productGroupPicturesRef.pageSettings.pageSize, this.productGroupPicturesRef.pageSettings.totalRecordsCount, false, []);
            this.gridImgSettings = tempGridSettings;
            const payload = {
                Key: this.gridImgSettingsKey,
                Settings: this.gridImgSettings
            };
            const result = await SettingsService.SaveGridSettings(payload, source.token);
        }
        else if (args.requestType === 'filtering') {
            if (this.gridImgSettings) {
                let settings = JSON.parse(this.gridImgSettings);
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let tempGridSettings = buildGridSettings(args, this.gridImgSettings, 0, 0, false, []);
            this.gridImgSettings = tempGridSettings;
            const payload = {
                Key: this.gridImgSettingsKey,
                Settings: this.gridImgSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                const {onNotify} = this.props;
                onNotify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.gridImgSettings) {
                let settings = JSON.parse(this.gridImgSettings);
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.gridImgSettings, 0, 0, false, []);
            this.gridImgSettings = tempGridSettings;
            const payload = {
                Key: this.gridImgSettingsKey,
                Settings: this.gridImgSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                const {onNotify} = this.props;
                onNotify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.productGroupPicturesRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.gridImgSettings, 0, 0, this.hasDraggedColumn, columns);
            this.gridImgSettings = tempGridSettings;
            const payload = {
                Key: this.gridImgSettingsKey,
                Settings: this.gridImgSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                const {onNotify} = this.props;
                onNotify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }

            this.hasDraggedColumn = false;
        }
    }

    onImgCreated() {
        if (this.gridImgSettings) {
            let settings = JSON.parse(this.gridImgSettings);
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(columns => {
                        if (columns.FromIndex !== columns.ToIndex) {
                            var tempIndex = this.productGroupPicturesRef.getColumnIndexByField(columns.Field);
                            if (tempIndex !== columns.ToIndex){
                                this.productGroupPicturesRef.reorderColumnByIndex(columns.FromIndex, columns.ToIndex);
                            }
                        }
    
                        if (columns.Width) {
                            this.productGroupPicturesRef.columns[columns.ToIndex].width = columns.Width;
                        }
                    });
                }
                
                if (settings.colName && settings.direction) {
                    this.productGroupPicturesRef.sortByColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.productGroupPicturesRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.productGroupPicturesRef.refreshColumns();

                if (settings.pageSize) {
                    if (settings.pageSize === "Alle") {
                        this.productGroupPicturesRef.pageSettings.pageSize = this.pageImgSettings.pageSize;
                        document.querySelector("#group-images-grid .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.productGroupPicturesRef.pageSettings.pageSize = settings.pageSize;
                    }
                    this.pageImgSettings.pageSize = this.productGroupPicturesRef.pageSettings.pageSize;
                }
            }
        }
    }

    handleExpandCollapse(args) {
        let settings = JSON.parse(this.gridSettings);
        if (args.name === "expanded" || args.name === "collapsed") {
            if (settings.pageSize === "Alle" || !this.productGroupSettingsRef.pageSettings.pageSizes.some(x => x === settings.pageSize)) {
                setTimeout(() => {
                    let input = document.querySelector("#groups-grid .e-pagerdropdown input");
                    if (input) {
                        input.value = "Alle";
                    }
                }, 300);
            }

            // if (settings.pageSize === "Alle") {
            //     setTimeout(() => {
            //         let input = document.querySelector("#groups-grid .e-pagerdropdown input");
            //         if (input) {
            //             input.value = "Alle";
            //         }
            //     }, 300);
            // }
        }
    }

    handleImgExpandCollapse(args) {
        let settings = JSON.parse(this.gridImgSettings);
        if (args.name === "expanded" || args.name === "collapsed") {
            if (settings.pageSize === "Alle" || !this.productGroupSettingsRef.pageSettings.pageSizes.some(x => x === settings.pageSize)) {
                setTimeout(() => {
                    let input = document.querySelector("#group-images-grid .e-pagerdropdown input");
                    if (input) {
                        input.value = "Alle";
                    }
                }, 300);
            }
            // if (settings.pageSize === "Alle") {
            //     setTimeout(() => {
            //         let input = document.querySelector("#group-images-grid .e-pagerdropdown input");
            //         if (input) {
            //             input.value = "Alle";
            //         }
            //     }, 300);
            // }
        }
    }

    onProductImageCardExpanded(isExpanded) {
        if (isExpanded) {
            setTimeout(() => {
                this.productGroupPicturesRef.dataSource = this.productGroupPicturesData;
                this.productGroupPicturesRef.dataBind();
            }, 600);
        }
    }

    render() {
        const { loading, data, linkEditDialog } = this.state;

        if (loading) {
            return <div></div>
        }

        return (
            <div className="tab-control--item product-group-settings-wrapper">
                <div>
                    <Card id="product-group-settings" headerText="Produktgruppe indstillinger" subText="Klik på + for tilhørende funktioner" isExpandable={true}>
                        <TreeGridComponent id="groups-grid" ref={ref => this.productGroupSettingsRef = ref } dataSource={this.productGroupSettingsData} childMapping="ProductsAndGroups" allowPaging={true} allowSorting={true} pageSettings={this.pageSettings}
                            allowFiltering={true} filterSettings={{ type: 'Menu', hierarchyMode: 'Parent' }} allowTextWrap='true' editSettings={this.defaultEditSettings} toolbar={this.productGroupSettingsToolbarOptions} locale="da"
                            actionBegin={this.productSettingsActionBegin} toolbarClick={this.onToolbarClick} rowSelected={this.rowSelected} gridLines='Both' allowReordering={false}
                            actionComplete={this.onActionComplete.bind(this)} created={this.onCreated.bind(this)} collapsed={this.handleExpandCollapse} expanded={this.handleExpandCollapse}>
                            <TreeGridColumnsDirective>
                                <TreeGridColumnDirective field='Name' headerText='Produkt Gr.' width='130' textAlign='left' />
                                <TreeGridColumnDirective field='ProductNo' headerText='Vare' width='100' template={this.productTemplate} />
                                <TreeGridColumnDirective field='Factor' headerText='Faktor' width='50' textAlign='right' />
                                <TreeGridColumnDirective field='ProfitPercentage' headerText='Avance i %' width='60' textAlign='right' template={this.profitPercentageTemplate} />
                                <TreeGridColumnDirective field='ActiveCaseNumber' headerText='Sags Nr.' width='60' textAlign='left' displayAsCheckBox={true} />
                                <TreeGridColumnDirective field='ExpenseGroupName' headerText="Omkostnings gruppe" width='70' />
                                <TreeGridColumnDirective field='ProductDrawText' headerText='Under Garanteret Vægt' width='70' textAlign='right' />
                                <TreeGridColumnDirective field='PurchaseTolerance' headerText='Ved indkøb Tolerance i %' width='70' textAlign='right' template={this.purchaseToleranceTemplate} />
                            </TreeGridColumnsDirective>
                            <TreeGridInject services={[TreeGridFilter, TreeGridSort, TreeGridPage, TreeGridEdit, TreeGridToolbar]}/>
                        </TreeGridComponent>
                    </Card>
                    <Card id="product-group-images" headerText="Produktgruppe Billeder" subText="Klik på + for tilhørende funktioner" isExpandable={true} onExpandClickCallback={this.onProductImageCardExpanded}>
                        <TreeGridComponent id="group-images-grid" ref={ref => this.productGroupPicturesRef = ref } dataSource={this.productGroupPicturesData} childMapping="SubGroups" allowPaging={true} allowSorting={true} pageSettings={this.pageImgSettings}
                            allowFiltering={true} filterSettings={{ type: 'Menu', hierarchyMode: 'Parent' }} allowTextWrap='true' toolbar={this.productGrouppictureToolbarOptions} locale="da"
                            toolbarClick={this.onProductPictureToolbarClick} gridLines='Both' enableCollapseAll={true}
                            actionComplete={this.onImgActionComplete.bind(this)} created={this.onImgCreated.bind(this)} collapsed={this.handleImgExpandCollapse} expanded={this.handleImgExpandCollapse}>

                            <TreeGridColumnsDirective>
                                <TreeGridColumnDirective field='Name' headerText='Produkt Gr.' width='130' textAlign='left' allowFiltering={true} />
                                <TreeGridColumnDirective field='ImageName' headerText='Fil' width='70' textAlign='left' allowFiltering={true} />
                                <TreeGridColumnDirective field='ImageKey' headerText='Eksempel' width='70' textAlign='left' allowFiltering={false} template={this.onRenderProductImage} />
                            </TreeGridColumnsDirective>
                            <TreeGridInject services={[TreeGridFilter, TreeGridSort, TreeGridPage, TreeGridEdit, TreeGridToolbar]}/>
                        </TreeGridComponent>
                    </Card>
                </div>
                <div>
                    <DialogComponent id="add-update-productgroup-dialog" ref={ref => this.addUpdateProductGroupDialogRef = ref } isModal={true} width='auto' target='body' visible={false} animationSettings={this.animationSettings} allowDragging={true} enableResize={true}>
                        <div className="dialog-content form">
                            <div className="form-row">
                                <div className="form-group form-col">
                                    <TextBoxComponent value={data.ProductGroupName} placeholder="Navn" name="ProductGroupName" change={this.onChange.bind(this)} floatLabelType="Auto" />
                                </div>
                                <div className="form-group form-col">
                                    <TextBoxComponent value={data.Factor} placeholder="Faktor" name="Factor" change={this.onChange.bind(this)} floatLabelType="Auto" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group form-col">
                                    <TextBoxComponent value={data.ProfitPercentage} placeholder="Avance i %" name="ProfitPercentage" change={this.onChange.bind(this)} floatLabelType="Auto" />
                                </div>
                                <div className="form-group form-col">
                                    <TextBoxComponent value={data.PurchaseTolerance} placeholder="Ved indkøb Tolerance i %" name="PurchaseTolerance" change={this.onChange.bind(this)} floatLabelType="Auto" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group form-col">
                                    <DropDownListComponent ref={ref => this.GuaranteedWeightRef = ref} name="GuaranteedWeight" value={data.GuaranteedWeight} dataSource={this.UnderWeightData} placeholder="Under Garanteret Vægt" fields={{ text: 'text', value: 'Id' }} 
                                        floatLabelType="Auto" locale="da" />
                                        {/* change={this.onGuaranteedWeightChange.bind(this)}  */}
                                </div>
                                <div className="form-group form-col" style={{paddingTop: 30}}>
                                    <CheckBoxComponent checked={data.ActiveCaseNumber} label='Sags Nr.' change={this.checkChange.bind(this)} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group form-col">
                                    <DropDownListComponent ref={ref => this.ExpenseGroupRef = ref} name="ExpenseGroupId" value={data.ExpenseGroupId} dataSource={this.expenseGroups} placeholder="Omkostnings gruppe" fields={{ text: 'Name', value: 'Id' }} 
                                        floatLabelType="Auto" locale="da" />
                                        {/* change={this.onGuaranteedWeightChange.bind(this)}  */}
                                </div>
                            </div>
                            <div className="product-group-tree-selection">
                                <div>Vælg venligst overliggende gruppe:</div>
                                <TreeViewComponent ref={ref => this.productGroupSelectionTree = ref} id="product-group-selection" fields={this.primaryProductGroupFields} showCheckBox={true} autoCheck={false} nodeChecked={this.onProductGroupChecked} />
                            </div>
                        </div>
                        <div className="dialog-footer">
                            <button className="e-control e-btn e-lib" onClick={this.addEditDialogClose}>Annuller</button>
                            <button className="e-control e-btn e-lib e-primary" onClick={this.onSaveProductGroup}>Gem</button>
                        </div>
                    </DialogComponent>
                </div>
                <div>
                    <DialogComponent id="link-update-productpicture-dialog" ref={ref => this.linkEditDialogRef = ref} isModal={true} header="Tildel Billede til produktgruppe" target='body' 
                        visible={false} animationSettings={this.animationSettings} allowDragging={true} enableResize={true}>
                        <div className="dialog-content">
                            {
                                (linkEditDialog.token && linkEditDialog.visible) &&
                                <FileManagerComponent ref={ref => this.productImageArchiveRef = ref} id="product-images-archive" height="100%" ajaxSettings={{
                                        url: `${FileArchiveAPI.Url}`,
                                        getImageUrl: `${FileArchiveAPI.GetImageUrl}`
                                    }} view="Details" beforeSend={this.beforeSend} toolbarSettings={this.fileManagerToolbarSettings} beforeImageLoad={this.beforeImageLoad} 
                                        contextMenuSettings={this.fileManagerContextMenuSettings} locale="da">
                                    <Inject services={[ NavigationPane, DetailsView, FileManagerToolbar ]} />
                                </FileManagerComponent>
                            }
                        </div>
                        <div className="dialog-footer">
                            <button className="e-control e-btn e-lib" onClick={this.linkEditDialogClose}>Annuller</button>
                            <button className="e-control e-btn e-lib e-primary" onClick={this.onSaveProductPicture}>Gem</button>
                        </div>
                    </DialogComponent>
                </div>
            </div>
        )
    }
}

ProductGroupSettings.contextType = AppContext;
export default ProductGroupSettings;
