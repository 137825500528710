import React, { Component } from 'react';
import { ToolbarComponent, ItemsDirective, ItemDirective } from '@syncfusion/ej2-react-navigations';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { ListViewComponent } from '@syncfusion/ej2-react-lists';
import NumberFormat from 'react-number-format';
import { withRouter } from 'react-router-dom';

import NavigationPrompt from '../../../components/NavigationPrompt';
import Card from '../../../components/Card';
import Toolbar from '../../../components/Toolbar';
import WeightService from '../../../services/SystemSettings/WeightService';

import Axios from 'axios';
import Localization from '../../../utilities/Localization';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class ShelfWeight extends Component {
    constructor() {
        super();

        let defaultData = {
            SmallBoxWeight: 0,
            MediumBoxWeight: 0,
            LargeBoxWeight: 0,
            TotalWeight: 0,
            GuaranteeWeight: 0,
            NumberOfMainSamples: 0,
            SubSamplesPerSecond80: false,
            SubSamplesPerSecond10: false,
            VarianceLimit: 0,
            WeightTolerances: []
        };

        this.state = {
            loading: true,
            weightCalc: {},
            data: defaultData,
            selectedCustomerMachine: {
                id: -1,
                text: ''
            },
            confirmDialog: {
                header: '',
                content: null,
                visible: false
            },
            selectedTroubleshootingIndex: null,
            isEditMode: false,
            hasUnsavedChanges: false
        }

        this.customerMachineItems = [
            { text: 'Test Automater', id: 0},
            { text: 'Kunde Automater', id: 1},
            { text: 'Se sidste opsætning', id: 2}
        ];

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };

        this.confirmButtons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.confirmDialogInstance.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: false,
                content: 'Tilbage'
            }
        },
        {
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.onTransferData();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        /* End Dialog options  */
        
        this.onCustomerMachineSelected = this.onCustomerMachineSelected.bind(this);
        this.weightSpecificationToolbarClicked = this.weightSpecificationToolbarClicked.bind(this);
        this.onTotalWeightChange = this.onTotalWeightChange.bind(this);
        this.onPlasticBoxChange = this.onPlasticBoxChange.bind(this);
        this.toggleShelfWeightFields = this.toggleShelfWeightFields.bind(this);
        this.onSampleWeightChange = this.onSampleWeightChange.bind(this);
        this.onWeightToleranceChange = this.onWeightToleranceChange.bind(this);
        this.onWeightToleranceBlur = this.onWeightToleranceBlur.bind(this);
        this.onWeightToleranceFocus = this.onWeightToleranceFocus.bind(this);
        this.onTransferData = this.onTransferData.bind(this);
        this.onAddWeightTolerance = this.onAddWeightTolerance.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onToleranceTroubleshootingChange = this.onToleranceTroubleshootingChange.bind(this);
        this.sortToleranceTable = this.sortToleranceTable.bind(this);
        this.onDeleteWeightTolerance = this.onDeleteWeightTolerance.bind(this);
        this.rowHighlight = this.rowHighlight.bind(this);
        this.onWeightChange = this.onWeightChange.bind(this);
    }

    componentDidMount() {
        this.setState({ loading: false});
    }

    componentWillUnmount() {
        source.cancel();
    }

    async onCustomerMachineSelected(args) {
        source.cancel();
        source = CancelToken.source();
        
        let header = document.querySelector('h2.customer-machine-header');
        if (header) {
            header.innerHTML = args.text;
        };

        let { selectedCustomerMachine } = this.state;
        const { onTestMachineSelected } = this.props;
        
        selectedCustomerMachine = {
            id: args.data.id,
            text: args.text
        };

        const weightPayload = {
            test: args.data.id === 0 ? true : false,
            prev: args.data.id === 2 ? true : false
        };

        let weight = await WeightService.GetShelf(weightPayload, source.token);
        if (!weight) {
            weight = {
                SmallBoxWeight: 0,
                MediumBoxWeight: 0,
                LargeBoxWeight: 0,
                TotalWeight: 0,
                GuaranteeWeight: 0,
                NumberOfMainSamples: 0,
                SubSamplesPerSecond80: false,
                SubSamplesPerSecond10: false,
                VarianceLimit: 0,
                WeightTolerances: []
            };
        }
        else {
            if (weight.WeightTolerances) {
                weight.WeightTolerances = weight.WeightTolerances.map(w => {
                    w.TolerancePercentage = w.TolerancePercentage * 100
                    return {
                        ...w
                    }
                });
                
                weight.WeightTolerances.sort((a,b) => a.ToWeight > b.ToWeight ? 1 : -1);
            }
        }
        
        // this.WeightToolbarRef.enableItems(0, true);

        if (args.data.id === 0) {
            this.WeightToolbarRef.enableItems(1, true);
            onTestMachineSelected(true);
        }
        else {
            this.WeightToolbarRef.enableItems(1, false);
            onTestMachineSelected(false);
        }
        
        this.WeightToolbarRef.enableItems(2, false);
        this.WeightToolbarRef.enableItems(3, false);
        this.toggleShelfWeightFields(false);
        // this.SampleWeightRef.value = '';
        this.SampleMinRef.value = '';
        this.SampleMaxRef.value = '';
        this.setState({ data: weight, selectedCustomerMachine, isTestMachine: args.data.id === 0, isEditMode: false });
    }
 
    onWeightToleranceFocus(e) {
        let name = e.target.name;

        if (name === 'TolerancePercentage') {
            e.target.value = e.target.value.replace('%', '');
        }
    }

    onWeightToleranceChange(e, index)  {
        const { data } = this.state;

        let weightTolerance = data.WeightTolerances[index];
        weightTolerance[e.target.name] = e.target.value;

        this.setState({ data, hasUnsavedChanges: true });
    }

    onWeightToleranceBlur(e) {
        let name = e.target.name;

        if (name === 'TolerancePercentage') {
            e.target.value = e.target.value + '%';
        }
    }

    toggleShelfWeightFields(enabled) {
        this.SmallBoxWeightRef.enabled = enabled;
        this.MediumBoxWeightRef.enabled = enabled;
        this.LargeBoxWeightRef.enabled = enabled;
        this.TotalWeightRef.enabled = enabled;
        this.GuaranteeWeightRef.enabled = enabled;
        // this.SampleWeightRef.disabled = !enabled;
        this.NumberOfMainSamplesRef.enabled = enabled;
        this.SubSamplesPerSecond80Ref.disabled = !enabled;
        this.SubSamplesPerSecond10Ref.disabled = !enabled;
        this.VarianceLimitRef.enabled = enabled;
    }

    async onTransferData() {
        const { data } = this.state;
        const { onNotify } = this.props;
        const result = await WeightService.PostShelfTransferData(data, source.token);
        
        this.confirmDialogInstance.hide();

        if (!result.HasError) {
            onNotify({ header: Localization.General.SuccessHeader, content: Localization.General.SuccessUpdate.replace("{context}", "Hyldevægt")});

            this.WeightToolbarRef.enableItems(0, true);
            this.WeightToolbarRef.enableItems(2, false);
            this.WeightToolbarRef.enableItems(3, false);
        }
        else {
            onNotify({ header: 'Advarsel', content: result.ErrorMessage });
        }
    }

    async weightSpecificationToolbarClicked(args) {
        if (args.item.id === 'edit') {
            this.WeightToolbarRef.enableItems(0, false);
            this.WeightToolbarRef.enableItems(2, true);
            this.WeightToolbarRef.enableItems(3, true);
            
            this.toggleShelfWeightFields(true);

            this.setState({ isEditMode: true });
        }
        else if (args.item.id === 'transfer-data') {
            const { confirmDialog } = this.state;
            confirmDialog.header = 'Du er ved at overføre test data til kunde maskiner';
            confirmDialog.content = 'Tryk OK for at overføre, dette kan ikke fortrydes\r\nTryk Tilbage, for at fortryde ';
            confirmDialog.visible = true;
            this.setState({ confirmDialog });
        }
        else if (args.item.id === 'save') {
            source.cancel();
            source = CancelToken.source();

            let { data } = this.state;
            const { onNotify } = this.props;
            let hasError = false;
            let errorMessages = [];

            data.SmallBoxWeight = this.SmallBoxWeightRef.value ? this.SmallBoxWeightRef.value : 0;
            data.MediumBoxWeight = this.MediumBoxWeightRef.value ? this.MediumBoxWeightRef.value : 0;
            data.LargeBoxWeight = this.LargeBoxWeightRef.value ? this.LargeBoxWeightRef.value : 0;
            data.TotalWeight = this.TotalWeightRef.value ? this.TotalWeightRef.value : 0;
            data.GuaranteeWeight = this.GuaranteeWeightRef.value ? this.GuaranteeWeightRef.value : 0;
            data.NumberOfMainSamples = this.NumberOfMainSamplesRef.value ? this.NumberOfMainSamplesRef.value : 0;
            data.VarianceLimit = this.VarianceLimitRef.value ? this.VarianceLimitRef.value : 0;
            
            if (data.SmallBoxWeight > data.TotalWeight) {
                errorMessages.push(Localization.Settings.SmallBoxExceededTotalWeight);
                this.SmallBoxWeightRef.element.classList.add('e-error');
                hasError = true;
            }
            else if (data.MediumBoxWeight > data.TotalWeight) {
                errorMessages.push(Localization.Settings.MediumBoxExceededTotalWeight);
                this.MediumBoxWeightRef.element.classList.add('e-error');
                hasError = true;
            }
            else if (data.LargeBoxWeight > data.TotalWeight) {
                errorMessages.push(Localization.Settings.LargeBoxExceededTotalWeight);
                this.LargeBoxWeightRef.element.classList.add('e-error');
                hasError = true;
            }
            
            if (data.SmallBoxWeight <= 0) {
                errorMessages.push(Localization.Settings.PlasticBinMustBeAboveZero.replace("{plastic_bin}", "Plastkasse S,"));
                this.SmallBoxWeightRef.element.classList.add('e-error');
                hasError = true;
            }
            if (data.MediumBoxWeight <= 0) {
                errorMessages.push(Localization.Settings.PlasticBinMustBeAboveZero.replace("{plastic_bin}", "Plastkasse M,"));
                this.MediumBoxWeightRef.element.classList.add('e-error');
                hasError = true;
            }
            if (data.LargeBoxWeight <= 0) {
                errorMessages.push(Localization.Settings.PlasticBinMustBeAboveZero.replace("{plastic_bin}", "Plastkasse L,"));
                this.LargeBoxWeightRef.element.classList.add('e-error');
                hasError = true;
            }

            if (hasError) {
                onNotify({ header: 'Advarsel', content: errorMessages.join('<br>') });
                return;
            }
            
            let lastToWeightValue = 0.01;
            data.WeightTolerances = data.WeightTolerances.filter(x => x.FromWeight !== '-');
            data.WeightTolerances.sort((a,b) => a.ToWeight > b.ToWeight ? 1 : -1);

            data.WeightTolerances.forEach((wt, index) => {
                if (index === 0) {
                    wt.FromWeight = 0.01;
                }
                else {
                    wt.FromWeight = (lastToWeightValue + 0.01).toFixed(2) * 1;
                }

                lastToWeightValue = wt.ToWeight;
            });

            data.WeightTolerances = data.WeightTolerances.map(x => {
                if (typeof x.TolerancePercentage === 'string') {
                    x.TolerancePercentage = parseFloat(x.TolerancePercentage) / 100;
                }
                else {
                    x.TolerancePercentage = x.TolerancePercentage / 100;
                }

                return x;
            });

            let result = null;
            let isTest = this.CustomerMachineListRef.selectedItems.data.id === 0 ? true : false;

            if (isTest) {
                result = await WeightService.PostShelfTest(data, source.token);
            }
            else {
                result = await WeightService.PostShelf(data, source.token);
            }

            data.WeightTolerances = data.WeightTolerances.map(x => {
                x.TolerancePercentage = x.TolerancePercentage * 100;
                return x;
            });

            if (!result.HasError) {
                onNotify({ header: Localization.General.SuccessHeader, content: Localization.General.SuccessUpdate.replace("{context}", "Hyldevægt") });

                this.WeightToolbarRef.enableItems(0, true);
                this.WeightToolbarRef.enableItems(2, false);
                this.WeightToolbarRef.enableItems(3, false);

                this.toggleShelfWeightFields(false);
                this.setState({ isEditMode: false, hasUnsavedChanges: false });
            }
            else {
                onNotify({ header: 'Advarsel', content: result.ErrorMessage });
            }
        }
        else if (args.item.id === 'cancel') {
            source.cancel();
            source = CancelToken.source();
            
            let { selectedCustomerMachine, data } = this.state;
            
            const weightPayload = {
                test: selectedCustomerMachine.id === 0 ? true : false,
                prev: selectedCustomerMachine.id === 2 ? true : false
            };

            const weight = await WeightService.GetShelf(weightPayload, source.token);
            this.WeightToolbarRef.enableItems(0, true);
            this.WeightToolbarRef.enableItems(2, false);
            this.WeightToolbarRef.enableItems(3, false);
            
            this.toggleShelfWeightFields(false);
            
            if (weight !== '') {
                Object.assign(data, weight);
                data.SampleWeight = '';
                data.WeightTolerances = data.WeightTolerances.map(w => {
                    w.TolerancePercentage = w.TolerancePercentage * 100
                    return {
                        ...w
                    }
                });
                data.WeightTolerances.sort((a,b) => a.ToWeight > b.ToWeight ? 1 : -1);
            }
            else {
                data = {
                    SmallBoxWeight: 0,
                    MediumBoxWeight: 0,
                    LargeBoxWeight: 0,
                    TotalWeight: 0,
                    GuaranteeWeight: 0,
                    NumberOfMainSamples: 0,
                    SubSamplesPerSecond80: false,
                    SubSamplesPerSecond10: false,
                    VarianceLimit: 0,
                    WeightTolerances: [],
                    SampleWeight: null
                };
            }
            
            this.SmallBoxWeightRef.value = data.SmallBoxWeight;
            this.MediumBoxWeightRef.value = data.MediumBoxWeight;
            this.LargeBoxWeightRef.value = data.LargeBoxWeight;
            this.TotalWeightRef.value = data.TotalWeight;
            this.GuaranteeWeightRef.value = data.GuaranteeWeight;
            this.NumberOfMainSamplesRef.value = data.NumberOfMainSamples;
            this.SubSamplesPerSecond80Ref.checked = data.SubSamplesPerSecond80;
            this.SubSamplesPerSecond10Ref.checked = data.SubSamplesPerSecond10;
            this.VarianceLimitRef.value = data.VarianceLimit;

            // this.SampleWeightRef.value = '';
            this.SampleMinRef.value = '';
            this.SampleMaxRef.value = '';
            
            this.setState({ data, isEditMode: false, hasUnsavedChanges: false });
        }
    }

    onTotalWeightChange(args) {
        let input = args.event.currentTarget.id;
        if (input) {
            const { data } = this.state;
            data[input] = args.value;
            this.setState({ data, hasUnsavedChanges: true });
        }
    }

    onPlasticBoxChange(args) {
        let input = args.event.currentTarget.id;
        if (input) {
            const { data } = this.state;
            data[input] = args.value;

            let col = args.container.parentElement.parentElement;

            if (args.value > this.TotalWeightRef.value) {
                const errorLabel = col.parentElement.querySelector('.error-label')
                if (!errorLabel) {
                    let div = document.createElement('div');
                    div.classList.add('error-label');
                    div.innerHTML = Localization.Settings.WeightExceededTotalWeight;
                    col.append(div);
                    args.container.classList.add('e-error');
                }

                // args.container.querySelector('input').focus()
            }
            else {
                let div = col.querySelector('div.error-label');
                if (div) {
                    col.removeChild(div);
                }
                
                args.container.classList.remove('e-error');
            }

            this.setState({ data, hasUnsavedChanges: true });
        }
    }
    
    onChange(args) {
        if (args.isInteracted) {
            let input = args.event.currentTarget.id;
            if (input) {
                const { data } = this.state;
                data[input] = args.value;
                this.setState({ data, hasUnsavedChanges: true });
            }
        }
    }

    onToleranceTroubleshootingChange(index, name, values) {
        let { data } = this.state;
        let weightTolerance = data.WeightTolerances[index];

        let value = values.floatValue;
        if (value < 0) {
            return false;
        }
        weightTolerance[name] = value;

        if (name === 'ToWeight') {
            this.hasToWeightError = false;
        }

        if (index < data.WeightTolerances.length - 1) {
            if (name === 'ToWeight') {
                let nextRecord = data.WeightTolerances[index + 1];
                if (nextRecord) {
                    nextRecord.FromWeight = value + 0.01;
                }
            }
        }

        this.setState({ data, hasUnsavedChanges: true });
    }

    sortToleranceTable() {
        setTimeout(() => {
            let { data } = this.state;
            let element = document.querySelectorAll("#tolerance-troubleshooting-procedure :focus");
            
            if (element.length > 0)
                return;
            
            // Get last element and check wether ToWeight's value is not zero
            let lastRecord = data.WeightTolerances.find(x => x.FromWeight === '-');
            if (lastRecord && lastRecord.ToWeight === 0)
                return;
    
            let lastToWeightValue = 0.01;
            data.WeightTolerances.sort((a,b) => a.ToWeight > b.ToWeight ? 1 : -1);
    
            data.WeightTolerances.forEach((wt, index) => {
                if (index === 0) {
                    wt.FromWeight = 0.01;
                }
                else {
                    wt.FromWeight = (lastToWeightValue + 0.01).toFixed(2) * 1;
                }
    
                lastToWeightValue = wt.ToWeight;
            });
    
            this.setState({ data });
        }, 500);
    }

    onSampleWeightChange(values) {
        if (!values || !values.floatValue)
            return;

        let value = values.floatValue;
        if (value) {
            const { isTestMachine, data } = this.state;
            if (data.WeightTolerances.length <= 0) {
                this.props.onNotify({header: "Advarsel", content: Localization.Settings.ToAndFromWeightAndToleranceRequired});
                // this.SampleWeightRef.value = '';
                this.SampleMinRef.value = '';
                this.SampleMaxRef.value = '';
                return;
            }
            data.SampleWeight = value;
            this.setState({ data }, () => {
                clearTimeout(this.sampleWeightCompute);
                
                this.sampleWeightCompute = setTimeout(async () => {
                    // source.cancel();
                    // source = CancelToken.source();

                    // const payload = {
                    //     weight: value,
                    //     test: isTestMachine
                    // }

                    // const result = await WeightService.GetShelfCalc(payload, source.token);
                    
                    // if (!result.HasError) {
                    //     this.SampleMinRef.value = result.Min;
                    //     this.SampleMaxRef.value = result.Max;
                    // }
                    // else {
                    //     this.props.onNotify({header: "Advarsel", content: result.ErrorMessage});
                    // }
                    if (data.WeightTolerances && data.WeightTolerances.length <= 0) {
                        this.props.onNotify({header: "Advarsel", content: Localization.Settings.WeightToleranceNotSet});
                        return;
                    }

                    let matchingTolerance = data.WeightTolerances.find(x => x.FromWeight <= value && x.ToWeight >= value);
                    if (matchingTolerance) {
                        const tolerance = matchingTolerance.TolerancePercentage / 100;

                        this.SampleMinRef.value = value - (value * tolerance) - matchingTolerance.StaticValue;
                        this.SampleMaxRef.value = value + (value * tolerance) + matchingTolerance.StaticValue;
                    }
                    else {
                        this.props.onNotify({header: "Advarsel", content: Localization.Settings.NoWeightToleranceForWeight});
                    }
                }, 500);
            });
        }
        else {
            this.SampleMinRef.value = '';
            this.SampleMaxRef.value = '';
        }
    }
    
    onSubSamplesPerSecondChange = (args) => {
        const { data } = this.state;
        data[args.event.currentTarget.id] = args.checked;
        this.setState({ data, hasUnsavedChanges: true });
    }

    onAddWeightTolerance() {
        const { data } = this.state;
        // let penultimateRecord = data.WeightTolerances[data.WeightTolerances.length - 2];
        let lastToWeightValue = 0.01;

        // sort table
        data.WeightTolerances = data.WeightTolerances.sort((a,b) => a.ToWeight > b.ToWeight ? 1 : -1);
        // Recompute table values
        data.WeightTolerances.forEach((wt, index) => {
            if (index === 0) {
                wt.FromWeight = 0.01;
            }
            else {
                wt.FromWeight = (lastToWeightValue + 0.01).toFixed(2) * 1;
            }

            lastToWeightValue = wt.ToWeight;
        });

        let lastRecord = data.WeightTolerances[data.WeightTolerances.length - 1];
        
        if (lastRecord) {
            if (lastRecord.ToWeight > data.TotalWeight) {
                this.props.onNotify({header: "Advarsel", content: Localization.Settings.ToleranceWeightExceedsTotalWeight});
                let row = document.getElementById(`to-weight-${data.WeightTolerances.length - 1}-row`);
                if (row) {
                    row.querySelector('input').focus();
                }
                return;
            }
        }


        let rows = document.querySelectorAll(".tolerance-troubleshooting .table tbody tr");
        if (rows.length > 0) {
            rows.forEach(r => {
                r.classList.remove('row-selected');
            });
        }

        this.setState({ data, hasUnsavedChanges: true }, () => {
            let weightTolerance = {
                FromWeight: data.WeightTolerances.length === 0 ? lastToWeightValue : "-",
                ToWeight: 0,
                TolerancePercentage: 0,
                StaticValue: 0
            }
            data.WeightTolerances.push(weightTolerance);
            this.setState({ data });
        });
    }

    onWeightChange(e) {
        if (e.isInteracted) {
            this.setState({ hasUnsavedChanges: true });
        }
    }

    onDeleteWeightTolerance() {
        let { selectedTroubleshootingIndex, data } = this.state;
        if (selectedTroubleshootingIndex !== null) {
            data.WeightTolerances.splice(selectedTroubleshootingIndex, 1);
            this.setState({ data, selectedTroubleshootingIndex: null }, () => {
                let rows = document.querySelectorAll(".tolerance-troubleshooting .table tbody tr");
                if (rows.length > 0) {
                    rows.forEach((r, rowIndex) => {
                        r.classList.remove('row-selected');
                    });
                }
                this.sortToleranceTable();
            });
        }
        else {
            this.props.onNotify({ header: 'Advarsel', content: Localization.General.NoRowSelected });
        }
    }

    confirmDialogClose() {
        let { confirmDialog } = this.state;
        confirmDialog.visible = false;
        this.setState({ confirmDialog });
    }

    onToleranceTroubleshootingKeydown = (e) => {
        if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
        }
    }

    rowHighlight(e, index) {
        if (e.currentTarget.classList.contains('row-selected')) {
            e.currentTarget.classList.remove('row-selected');
            index = null;
        }
        else {
            let rows = document.querySelectorAll(".tolerance-troubleshooting .table tbody tr");
            if (rows.length > 0) {
                rows.forEach((r, rowIndex) => {
                    if (rowIndex !== index) {
                        r.classList.remove('row-selected');
                    }
                });
            }
            e.currentTarget.classList.add('row-selected');
        }
        this.setState({ selectedTroubleshootingIndex: index });
    }

    render() {
        const { loading, confirmDialog, data, isEditMode, selectedCustomerMachine, hasUnsavedChanges } = this.state;
        const { weightUnit } = this.props;
        
        // if (loading) {
        //     return <div></div>
        // }

        const buttons = [
            {
                id: 'c-toolbar-add',
                text: 'Ny',
                iconClass: 'c-icon-add-black',
                onClick: this.onAddWeightTolerance,
                disabled: isEditMode ? false : true
            },
            {
                id: 'c-toolbar-delete',
                text: 'Slette',
                iconClass: 'c-icon-delete',
                onClick: this.onDeleteWeightTolerance,
                disabled: isEditMode ? false : true
            }
        ]
        
        let isEditDisabled = true;
        
        if (selectedCustomerMachine.id === 0 || selectedCustomerMachine.id === 1) {
            isEditDisabled = false;
        }
        if (isEditMode) {
            isEditDisabled = true;
        }
        
        return (
            <div className="tab-control--item">
                <NavigationPrompt when={hasUnsavedChanges} 
                    navigate={path => this.props.history.push(path)}
                    initiateSave={() => this.weightSpecificationToolbarClicked({ item: { id: 'save' } })}
                    shouldBlockNavigation={location => {
                        if (hasUnsavedChanges) {
                            return true;
                        }
                        return false;
                    }}
                />
                <div className="shelf-weight-wrapper">
                    <div className="customer-machine-list">
                        <h3>Kunde Automater</h3>
                        <ListViewComponent ref={ref => this.CustomerMachineListRef = ref} id="customer-machine-list" dataSource={this.customerMachineItems} select={this.onCustomerMachineSelected}></ListViewComponent>
                    </div>
                    <div className="customer-machine-content">
                        <h2 className="customer-machine-header">{selectedCustomerMachine.text}</h2>
                        <div className={`customer-machine-toolbar-wrapper`}>
                            <ToolbarComponent ref={ref => this.WeightToolbarRef = ref} clicked={this.weightSpecificationToolbarClicked}>
                                <ItemsDirective>
                                    <ItemDirective id="edit" prefixIcon='tb-icons c-icon-edit' text="Redigere" tooltipText='Redigere' disabled={isEditDisabled} />
                                    <ItemDirective id="transfer-data" prefixIcon='tb-icons c-icon-transfer' text="Overfør Data" tooltipText='Overfør Data' disabled={!data.isTestMachine} />
                                    <ItemDirective id="save" prefixIcon='tb-icons c-icon-update' text="Gem" tooltipText='Gem' disabled={!isEditMode}  />
                                    <ItemDirective id="cancel" prefixIcon='tb-icons c-icon-cancel' text="Annuller" tooltipText='Annuller' disabled={!isEditMode}  />
                                </ItemsDirective>
                            </ToolbarComponent>
                        </div>
                        <div className="customer-machine-weight-indicators">
                            <Card headerText="Vægt specifikationer" subText="Maksimal tilladte vægt pr. vejeplade" className="weight-specification">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div>Total vægt på hylde</div>
                                                <div>
                                                    <div>
                                                        <NumericTextBoxComponent ref={ref => this.TotalWeightRef = ref} id="TotalWeight" showSpinButton={false} value={data.TotalWeight}
                                                            className="input-text-right" enabled={false} locale="da" min={1} step={0} change={this.onWeightChange} /> {/*blur={this.onTotalWeightChange} change={this.onChange} */}
                                                        <div className="weight-unit">{weightUnit}</div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div>Plastkasse S (x 2)</div>
                                                <div>
                                                    <div>
                                                        <NumericTextBoxComponent ref={ref => this.SmallBoxWeightRef = ref} id="SmallBoxWeight" showSpinButton={false} value={data.SmallBoxWeight} 
                                                            className="input-text-right" enabled={false} locale="da" min={1} step={0} change={this.onWeightChange} /> {/*blur={this.onPlasticBoxChange} change={this.onChange} */}
                                                        <div className="weight-unit">{weightUnit}</div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div>Plastkasse M</div>
                                                <div>
                                                    <div>
                                                        <NumericTextBoxComponent ref={ref => this.MediumBoxWeightRef = ref} id="MediumBoxWeight" showSpinButton={false} value={data.MediumBoxWeight}
                                                            className="input-text-right" enabled={false} locale="da" min={1} step={0} change={this.onWeightChange} /> {/*blur={this.onPlasticBoxChange} change={this.onChange}  */}
                                                        <div className="weight-unit">{weightUnit}</div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div>Plastkasse L</div>
                                                <div>
                                                    <div>
                                                        <NumericTextBoxComponent ref={ref => this.LargeBoxWeightRef = ref} id="LargeBoxWeight" showSpinButton={false} value={data.LargeBoxWeight}
                                                            className="input-text-right" enabled={false} locale="da" min={1} step={0} change={this.onWeightChange} /> {/*blur={this.onPlasticBoxChange} change={this.onChange} */}
                                                        <div className="weight-unit">{weightUnit}</div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div>Garanteret vægt</div>
                                                <div>
                                                    <div>
                                                        <NumericTextBoxComponent ref={ref => this.GuaranteeWeightRef = ref} id="GuaranteeWeight" showSpinButton={false} value={data.GuaranteeWeight}
                                                            className="input-text-right" enabled={false} locale="da" min={1} step={0} change={this.onWeightChange} /> {/*change={this.onChange} */}
                                                        <div className="weight-unit">{weightUnit}</div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Card>
                            <Card headerText="Samples og varians" className="samples-and-variance">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div>Antal main samples</div>
                                                <div>
                                                    <NumericTextBoxComponent ref={ref => this.NumberOfMainSamplesRef = ref} id="NumberOfMainSamples" showSpinButton={false} value={data.NumberOfMainSamples} 
                                                        className="input-text-right" enabled={false} locale="da" decimals={0} validateDecimalOnType={true} min={0} step={0} change={this.onWeightChange} /> {/* change={this.onChange}  */}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div>80 sub samples pr. sekund</div>
                                                <div className="text-right">
                                                    <CheckBoxComponent id="SubSamplesPerSecond80" ref={ref => this.SubSamplesPerSecond80Ref = ref} checked={data.SubSamplesPerSecond80} name="SubSamplesPerSecond80" 
                                                        cssClass="control-right" disabled={true} change={this.onSubSamplesPerSecondChange} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div>10 sub samples pr. sekund</div>
                                                <div className="text-right">
                                                    <CheckBoxComponent id="SubSamplesPerSecond10" ref={ref => this.SubSamplesPerSecond10Ref = ref} checked={data.SubSamplesPerSecond10} name="SubSamplesPerSecond10" 
                                                        cssClass="control-right" disabled={true} change={this.onSubSamplesPerSecondChange} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div>Varians grænse</div>
                                                <div>
                                                    <NumericTextBoxComponent ref={ref => this.VarianceLimitRef = ref} id="VarianceLimit" showSpinButton={false} value={data.VarianceLimit}
                                                        className="input-text-right" enabled={false} locale="da" min={0} step={0} change={this.onWeightChange} />
                                                        {/* blur={this.onPlasticBoxChange} change={this.onChange}*/}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Card>
                        </div>
                        <div className="tolerance-troubleshooting-procedure">
                            <Card headerText="Tolerancer for fejlfindingsprocedure ved Indkøb" subText="Indtast fra og til vægt, derefter tolerance i % og hvis det ønskes en fast værdi i tillæg til toleranceværdien" className="tolerance-troubleshooting">
                                <div>
                                    <Toolbar buttons={buttons} />
                                </div>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Total Vægt i gram Fra</th>
                                            <th>Total Vægt i gram Til</th>
                                            <th>Tolerance I %</th>
                                            <th>Statisk Værdi</th>
                                        </tr>
                                    </thead>
                                    <tbody id="tolerance-troubleshooting-procedure">
                                        {
                                            data.WeightTolerances.map((tolerance, index) => {
                                                let displayAs = (data.WeightTolerances.length - 1) === index ? 'input' : 'text';
                                                return (
                                                    <tr key={`tolerance_${index}`} onClick={(e) => this.rowHighlight(e, index)} onBlur={this.sortToleranceTable}>
                                                        <td key={`from-weight-${index}`} className="text-right">
                                                            <span>{tolerance.FromWeight}</span>
                                                        </td>
                                                        <td key={`to-weight-${index}`} id={`to-weight-${index}-row`} className="text-right">
                                                            {/* <input type="number" min={1} className="input-text-right e-control e-numerictextbox e-lib e-input is-numeric" disabled={!isEditMode} value={tolerance.ToWeight} 
                                                                onKeyDown={this.onToleranceTroubleshootingKeydown} onChange={(e) => this.onToleranceTroubleshootingChange(index, "ToWeight", e)} /> */}
                                                            <NumberFormat type="text" decimalScale={2} fixedDecimalScale={true} disabled={!isEditMode} className="e-input input-text-right" value={tolerance.ToWeight} displayType={displayAs}
                                                                thousandSeparator="." decimalSeparator="," onValueChange={(values) => this.onToleranceTroubleshootingChange(index, "ToWeight", values)} />
                                                        </td>
                                                        <td key={`total-percentage-${index}`} className="text-right">
                                                            {/* <input type="number" min={1} className="input-text-right e-control e-numerictextbox e-lib e-input is-numeric" disabled={!isEditMode} value={tolerance.TolerancePercentage} 
                                                                onKeyDown={this.onToleranceTroubleshootingKeydown} onChange={(e) => this.onToleranceTroubleshootingChange(index, "TolerancePercentage", e)} /> */}
                                                            <NumberFormat type="text" decimalScale={2} fixedDecimalScale={true} disabled={!isEditMode} className="e-input input-text-right" value={tolerance.TolerancePercentage}
                                                                thousandSeparator="." decimalSeparator="," suffix="%" onValueChange={(values) => this.onToleranceTroubleshootingChange(index, "TolerancePercentage", values)} />
                                                        </td>
                                                        <td key={`static-value-${index}`} className="text-right">
                                                            {/* <input type="number" min={1} className="input-text-right e-control e-numerictextbox e-lib e-input is-numeric" disabled={!isEditMode} value={tolerance.StaticValue} 
                                                                onKeyDown={this.onToleranceTroubleshootingKeydown} onChange={(e) => this.onToleranceTroubleshootingChange(index, "StaticValue", e)} /> */}
                                                            <NumberFormat type="text" decimalScale={2} fixedDecimalScale={true} disabled={!isEditMode} className="e-input input-text-right" value={tolerance.StaticValue}
                                                                thousandSeparator="." decimalSeparator="," onValueChange={(values) => this.onToleranceTroubleshootingChange(index, "StaticValue", values)} />
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        {
                                            data.WeightTolerances.length <= 0 &&
                                            <tr><td colSpan="4">Ingen poster at vise.</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </Card>
                            <Card headerText="Indtast en vægt i første kolonne nedenfor for at se eksempel på resultat" className="tolerance-testing">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Indtast vægt</th>
                                            <th>Min</th>
                                            <th>Maks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {/* <input ref={ref => this.SampleWeightRef = ref} disabled={true} id="SampleWeight" value={data.SampleWeight} className="e-input is-numeric input-text-right" onChange={this.onSampleWeightChange}  /> */}
                                                {/* <NumericTextBoxComponent ref={ref => this.SampleWeightRef = ref} id="SampleWeight" showSpinButton={false} value={data.SampleWeight} change={this.onSampleWeightChange} 
                                                    className="input-text-right" enabled={false} /> */}
                                                <NumberFormat type="text" decimalScale={2} fixedDecimalScale={true} disabled={!isEditMode} className="e-input input-text-right" value={data.SampleWeight}
                                                    thousandSeparator="." decimalSeparator="," onValueChange={(values) => this.onSampleWeightChange(values)} />
                                            </td>
                                            <td>
                                                <NumericTextBoxComponent ref={ref => this.SampleMinRef = ref} id="SampleMin" showSpinButton={false} className="input-text-right" enabled={false} locale="da" />
                                            </td>
                                            <td>
                                                <NumericTextBoxComponent ref={ref => this.SampleMaxRef = ref} id="SampleMax" showSpinButton={false} className="input-text-right" enabled={false} locale="da" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Card>
                        </div>
                    </div>
                </div>
                <div>
                    <DialogComponent id="confirm-dialog" isModal={true} buttons={this.confirmButtons} header={confirmDialog.header} width='auto' content={confirmDialog.content} 
                        ref={dialog => this.confirmDialogInstance = dialog} target='body' visible={confirmDialog.visible} showCloseIcon={true} allowDragging={true} enableResize={true}
                        cssClass="dialog-notification" animationSettings={this.animationSettings} />
                </div>
            </div>
        )
    }
}

export default withRouter(ShelfWeight);