import Axios from 'axios';
import AppSettings from '../settings/AppSettings';

import { StorageKeys } from '../settings/StorageKeys';
import AuthenticationService from '../services/AuthenticationService';

class HttpClient {
    async Get(url, param, cancelToken) {
        Axios.defaults.headers['Pragma'] = 'no-cache';
        Axios.defaults.headers['Cache-Control'] = 'no-cache';
        let config = {
            "Content-Type": "application/json",
            headers: {}
        };
        url = `${AppSettings.API_URL}${url}`;

        if (cancelToken) {
            config.cancelToken = cancelToken;
        }

        return await AuthenticationService.getUser().then(async user => {
            if (user !== null) {
                let token = user.access_token;
                try {
                    let loggedInAsToken = sessionStorage.getItem(StorageKeys.LoggedInAs_Key);
                    if (loggedInAsToken) {
                        token = loggedInAsToken;
                    }
                } catch (error) {
                    
                }

                config.headers.Authorization = `Bearer ${token}`;

                return await Axios.get(url, {
                    params: {
                        ...param
                    },
                    ...config
                })
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    let errorMessage = '';
                    let status = '';
                    if (error.response) {
                        status = error.response.status;
                    }
                    
                    try {
                        if (error.response.data) {
                            if (typeof error.response.data === 'string') {
                                errorMessage = error.response.data;
                            }
                            else {
                                error.response.data.forEach(prop => {
                                    if (!errorMessage.includes(prop.Message)) {
                                        errorMessage = errorMessage + prop.Message;
                                    }
                                });
                            }
                        }
                        else {
                            errorMessage = error.response;
                        }
                    } catch (error) {
                        errorMessage = '';
                    }

                    return {
                        Success: false,
                        HasError: true,
                        ErrorMessage: errorMessage,
                        Status: status
                    }
                });
            }
            else {
                AuthenticationService.login();
            }
        });
    }

    async GetImage(url, cancelToken) {
        let config = {
            headers: {},
            responseType: 'arraybuffer'
        };
        url = `${AppSettings.API_URL}${url}`;

        if (cancelToken) {
            config.cancelToken = cancelToken;
        }

        return await AuthenticationService.getUser().then(async user => {
            if (user !== null) {
                let token = user.access_token;
                try {
                    let loggedInAsToken = sessionStorage.getItem(StorageKeys.LoggedInAs_Key);
                    if (loggedInAsToken) {
                        token = loggedInAsToken;
                    }
                } catch (error) {
                    
                }

                config.headers.Authorization = `Bearer ${token}`;

                return await Axios.get(url, {
                    ...config
                })
                .then(response => {
                    if (response.headers["content-type"] === 'application/problem+json') {
                        return null;
                    }
                    else if (!response.headers['content-type'].includes('image/')) {
                        return null;
                    }

                    let image = btoa(
                        new Uint8Array(response.data)
                          .reduce((data, byte) => data + String.fromCharCode(byte), '')
                    );
                    return `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
                })
                .catch(error => {
                    let errorMessage = '';
                    
                    if (error.response && error.response.data) {
                        error.response.data.forEach(prop => {
                            if (!errorMessage.includes(prop.Message)) {
                                errorMessage = errorMessage + prop.Message;
                            }
                        });
                    }
                    else {
                        errorMessage = error.response;
                    }
                    
                    return {
                        Success: false,
                        HasError: true,
                        ErrorMessage: errorMessage
                    }
                });
            }
            else {
                AuthenticationService.login();
            }
        });
    }

    async GetFile(url, cancelToken) {
        let config = {
            headers: {},
            responseType: 'blob'
        };
        url = `${AppSettings.API_URL}${url}`;

        if (cancelToken) {
            config.cancelToken = cancelToken;
        }

        return await AuthenticationService.getUser().then(async user => {
            if (user !== null) {
                let token = user.access_token;
                try {
                    let loggedInAsToken = sessionStorage.getItem(StorageKeys.LoggedInAs_Key);
                    if (loggedInAsToken) {
                        token = loggedInAsToken;
                    }
                } catch (error) {
                    
                }

                config.headers.Authorization = `Bearer ${token}`;

                return await Axios.get(url, {
                    ...config
                })
                .then(response => {
                    if (response.headers["content-type"] === 'application/problem+json') {
                        return null;
                    }

                    // let image = btoa(
                    //     new Uint8Array(response.data)
                    //       .reduce((data, byte) => data + String.fromCharCode(byte), '')
                    // );
                    // return `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
                    const file = new Blob([response.data], { type: 'application/pdf' });
                    return URL.createObjectURL(file);
                })
                .catch(error => {
                    let errorMessage = '';
                    
                    if (error.response && error.response.data) {
                        error.response.data.forEach(prop => {
                            if (!errorMessage.includes(prop.Message)) {
                                errorMessage = errorMessage + prop.Message;
                            }
                        });
                    }
                    else {
                        errorMessage = error.response;
                    }
                    
                    return {
                        Success: false,
                        HasError: true,
                        ErrorMessage: errorMessage
                    }
                });
            }
            else {
                AuthenticationService.login();
            }
        });
    }

    async Post(url, param, cancelToken, isFormData = false, isUnauthenticated = false) {
        let config = {};
        url = `${AppSettings.API_URL}${url}`;
        
        if (isFormData) {
            config.headers = {
                'Content-Type' : 'multipart/form-data'
            }
        }
        else {
            config.headers = {
                'Content-Type': 'application/json'
            }
        }

        if (cancelToken) {
            config.cancelToken = cancelToken;
        }

        if (isUnauthenticated) {
            return Axios.post(url, param, config)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                let errorMessage = '';

                if (error.response.data) {
                    if (Array.isArray(error.response.data)) {
                        let errs = error.response.data.map(err => {
                            return err.Message || err.message;
                        });
                        errorMessage = errs.join('<br>');
                    } else {
                        errorMessage = error.response.data;
                    }
                }
                else {
                    errorMessage = error.response;
                }

                return {
                    Success: false,
                    HasError: true,
                    ErrorMessage: errorMessage,
                    ValidationResult: error.response.data ? error.response.data: ''
                }
            });
        }

        return await AuthenticationService.getUser().then(async user => {
            if (user !== null) {
                let token = user.access_token;
                try {
                    let loggedInAsToken = sessionStorage.getItem(StorageKeys.LoggedInAs_Key);
                    if (loggedInAsToken) {
                        token = loggedInAsToken;
                    }
                } catch (error) {
                    
                }
                config.headers.Authorization = `Bearer ${token}`;

                return Axios.post(url, param, config)
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    let errorMessage = '';

                    if (error.response.data) {
                        try {
                            let errs = error.response.data.map(err => {
                                return err.Message || err.message;
                            });
                            errorMessage = errs.join('<br>');
                        } catch (error2) {
                            errorMessage = error.response.data;
                        }
                    }
                    else {
                        errorMessage = error.response;
                    }

                    return {
                        Success: false,
                        HasError: true,
                        ErrorMessage: errorMessage,
                        ValidationResult: error.response.data ? error.response.data: ''
                    }
                });
            }
            else {
                AuthenticationService.login();
            }
        });
    }

    async Put(url, param, cancelToken) {
        let config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        url = `${AppSettings.API_URL}${url}`;

        if (cancelToken) {
            config.cancelToken = cancelToken;
        }

        return await AuthenticationService.getUser().then(async user => {
            if (user !== null) {
                let token = user.access_token;
                try {
                    let loggedInAsToken = sessionStorage.getItem(StorageKeys.LoggedInAs_Key);
                    if (loggedInAsToken) {
                        token = loggedInAsToken;
                    }
                } catch (error) {
                    
                }

                config.headers.Authorization = `Bearer ${token}`;

                return Axios.put(url, param, config)
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    let errorMessage = '';

                    if (error.response.data) {
                        error.response.data.forEach(prop => {
                            let message = prop.Message || prop.message;
                            if (!errorMessage.includes(message)) {
                                errorMessage = errorMessage + message;
                            }
                        });
                    }
                    else {
                        errorMessage = error.response;
                    }
                    return {
                        Success: false,
                        HasError: true,
                        ErrorMessage: errorMessage,
                        ValidationResult: error.response.data ? error.response.data: ''
                    }
                });
            }
            else {
                AuthenticationService.login();
            }
        });
    }

    async Delete(url, param, cancelToken) {
        let config = {
            "Content-Type": "application/json",
            headers: {}
        };
        url = `${AppSettings.API_URL}${url}`;

        if (cancelToken) {
            config.cancelToken = cancelToken;
        }

        return await AuthenticationService.getUser().then(async user => {
            if (user !== null) {
                let token = user.access_token;
                try {
                    let loggedInAsToken = sessionStorage.getItem(StorageKeys.LoggedInAs_Key);
                    if (loggedInAsToken) {
                        token = loggedInAsToken;
                    }
                } catch (error) {
                    
                }

                config.headers.Authorization = `Bearer ${token}`;

                return Axios.delete(url, {
                    params: {
                        ...param
                    },
                    ...config
                })
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    let errorMessage = '';

                    if (error.response.data) {
                        if (Array.isArray(error.response.data)) {
                            error.response.data.forEach(prop => {
                                if (!errorMessage.includes(prop.Message)) {
                                    errorMessage = errorMessage + prop.Message;
                                }
                            });
                        }
                        else {
                            errorMessage = error.response.data;
                        }
                    }
                    else {
                        errorMessage = error.response;
                    }
                    return {
                        Success: false,
                        HasError: true,
                        ErrorMessage: errorMessage,
                        ValidationResult: error.response.data ? error.response.data: ''
                    }
                });
            }
            else {
                AuthenticationService.login();
            }
        });
    }
}

const httpClient = new HttpClient();
export default httpClient;