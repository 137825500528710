import HttpClient from '../utilities/HttpClient';
import httpClientOilmat from '../utilities/HttpClientOilmat';

const API = {
    getRegions: "/settings/regions",
    getCountries: "/settings/countries"
}

class RegionService {
    async GetRegion(cancelToken) {        
        return await HttpClient.Get(API.getRegions, null, cancelToken);
    }

    async GetRegion_Oilmat(cancelToken) {
        return await httpClientOilmat.Get(API.getRegions, null, cancelToken);
    }

    async GetCountry_Oilmat(cancelToken) {
        return await httpClientOilmat.Get(API.getCountries, null, cancelToken);
    }
}

const regionService = new RegionService();
export default regionService;