import Axios from 'axios';
import AuthenticationService from './AuthenticationService';
import CustomerService from './Customer/CustomerService';
import UserService from './User/UserService';
import HttpClient from '../utilities/HttpClient';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const API = {
    getAccessToken: `/user/GenerateUserAccessToken`
}

class SessionService {
    async GetSessionInfo() {
        const user = await AuthenticationService.getUser();
        let result = null;
        if (user) {
            if (user.profile.isCustomer.toLowerCase() == "true") {
                const customer = await CustomerService.GetCustomerAccount(user.profile.sub, source.token);
                result = customer;
                result.userType = 'customer';
                result.GroupMenu = customer.CustomerGroup.Menu.Intralogix;
                result.access_token = user.access_token;
                result.ParentAcctId = user.profile.parentAcctId;
            }
            else {
                const userInfo = await UserService.GetUserAccount(user.profile.sub, source.token);
                result = userInfo;
                result.userType = 'user';
                result.UserId = result.Id;
                result.Id = result.CustomerId;
                result.GroupMenu = userInfo.UserGroup.Menu.Intralogix;
                result.access_token = user.access_token;
                result.ParentAcctId = user.profile.parentAcctId;

                result.GroupMenu = userInfo.CustomMenu.Intralogix;
                
                // if (userInfo.CustomMenu.Intralogix.length > 0) {
                //     userInfo.CustomMenu.Intralogix.forEach(int => {
                //         if (result.GroupMenu.some(x => x.Name === int.Name)) {
                //             let menu = result.GroupMenu.find(x => x.Name === int.Name);
                //             if (int.Functions.length > 0) {
                //                 menu.Functions.concat(int.Functions);
                //             }

                //             if (int.MenuItems.length > 0) {
                //                 menu.MenuItems.concat(int.MenuItems);
                //             }

                //             if (int.MenuTabs.length > 0) {
                //                 menu.MenuTabs.concat(int.MenuTabs);
                //             }
                            
                //         }
                //         else {
                //             result.GroupMenu.push(int);
                //         }
                //     });
                // }
            }
        }

        return result;
    }

    async LoginAs(username, cancelToken) {
        return await HttpClient.Get(API.getAccessToken, { username }, cancelToken);
    }
}

const sessionService = new SessionService();
export default sessionService;