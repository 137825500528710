import { Component } from 'react';
import AuthenticationService from '../services/AuthenticationService';

class SilentCallback extends Component {
    async componentDidMount() {
        await AuthenticationService.renewToken();
    }

    render() {
        return '';
    }
}

export default SilentCallback;