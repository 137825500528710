import React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';
import { DropDownList, DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Page, Sort, Toolbar, Selection, Search, Reorder, Resize, Edit } from '@syncfusion/ej2-react-grids';
import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { toast } from 'react-toastify';

import BaseComponent from '../../components/BaseComponent';
import Loading from '../../components/Loading';
import NavigationPrompt from '../../components/NavigationPrompt';
import Localization from '../../utilities/Localization';
import Card from '../../components/Card';

import MessageHelper from '../../utilities/MessageHelper';
import { buildVirtualMenu, buildMenuFromCheckedNodes, buildCheckedNodes } from '../../utilities/Helper';
import CustomerService from '../../services/Customer/CustomerService';
import CustomerGroupService from '../../services/Customer/CustomerGroupService';
import UserGroupService from '../../services/User/UserGroupService';
import SettingsService from '../../services/SettingsService';
import SessionService from '../../services/SessionService';
import ResponsibilityGroupService from '../../services/Customer/ResponsibilityGroupService';
import UserService from '../../services/User/UserService';
import { buildGridSettings } from '../../utilities/Helper';

import '../../styles/tool.scss';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class UserTools extends BaseComponent {
    moduleName = "Værktøjer";
    pageName = "Brugere";
    hasDraggedColumn = false;
    gridSettingsKey = "UserToolsList";
    gridSettings = '';

    constructor() {
        super();

        this.state = {
            loading: true,
            redirect: {
                to: '',
                isRedirect: false
            },
            hasUnsavedChanges: false,
            changeLog: [],
            changeLogReport: [],
            userSelected: {},
            responsibilityGroups: [],
            otherSettingsLog: [],
            tempOtherSettingsChanges: [],
            selectedUsers: [],
            CustomerGroupMenu: {}
        }

        this.data = [];
        this.userSettingsLoginRules = [];
        this.menuFields = {
            dataSource: [], 
            id: 'Id', text: 'Name', 
            child: 'VirtualMenuItems' 
        };

        this.pageSettings = {
            pageSize: 25,
            pageSizes: ['Alle', '5', '10', '20', '50', '100']
        };

        this.selectionSettings = {
            persistSelection: true,
            checkboxOnly: true
        }

        this.editSettings = {
            allowEditing: false,
            mode: 'Batch',
            showConfirmDialog: false
        }

        this.filterSettings = {
            type: 'Menu'
        };

        this.init = this.init.bind(this);
        this.onToolbarClick = this.onToolbarClick.bind(this);
        this.resetChangesTemplate = this.resetChangesTemplate.bind(this);
        this.saveChangesTemplate = this.saveChangesTemplate.bind(this);
        this.onResetChanges = this.onResetChanges.bind(this);
        this.onSaveChanges = this.onSaveChanges.bind(this);
        this.processUserChanges = this.processUserChanges.bind(this);
        this.onResponsibilityChange = this.onResponsibilityChange.bind(this);
        this.onOrdersApprovalChange = this.onOrdersApprovalChange.bind(this);
        this.onLoginRuleChange = this.onLoginRuleChange.bind(this);
        this.saveUserOtherSettings = this.saveUserOtherSettings.bind(this);

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.notificationButtons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        this.otherSettingButtons = [
            {
                click: () => {
                    this.notificationDialog.header = "Advarsel";
                    this.notificationDialog.content = MessageHelper.MB1.message;

                    let messageButton = MessageHelper.MB1.buttons;

                    messageButton[0].click = () => {
                        this.notificationDialog.header = "";
                        this.notificationDialog.content = "";
                        this.notificationDialog.buttons = this.notificationButtons;
                        this.notificationDialog.hide();

                        this.otherSettingsDialog.hide();
                        this.setState({ userSelected: {} });
                    }

                    messageButton[1].click = () => {
                        this.notificationDialog.header = "";
                        this.notificationDialog.content = "";
                        this.notificationDialog.buttons = this.notificationButtons;
                        this.notificationDialog.hide();
                        this.saveUserOtherSettings();
                        this.otherSettingsDialog.hide();
                    }

                    this.notificationDialog.buttons = messageButton; 

                    this.notificationDialog.show();
                },
                buttonModel: {
                    content: 'Tilbage'
                }
            },
            {
                click: () => {
                    this.saveUserOtherSettings();
                },
                buttonModel: {
                    isPrimary: true,
                    content: 'OK'
                }
            }
        ];

        this.changeLogButtons = [
            {
                click: () => {
                    this.changeLogDialog.hide();
                },
                buttonModel: {
                    content: 'Tilbage'
                }
            },
            {
                click: async () => {
                    await this.processUserChanges();
                },
                buttonModel: {
                    isPrimary: true,
                    content: 'Opdater'
                }
            }
        ]
        /* End Dialog options  */
    }

    async componentDidMount() {
        super.componentDidMount();
        source.cancel();
        source = CancelToken.source();

        if (!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }
        else if (!this.hasMenuItem("Brugere")) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }

        this.init();

        const sessionInfo = await SessionService.GetSessionInfo();
        this.CustomerId = sessionInfo.Id;

        const users = await CustomerService.GetUsers(sessionInfo.Id, { active: true }, source.token);
        this.data = users;
        
        const userGroups = await UserGroupService.GetUserGroups(sessionInfo.Id, source.token);
        this.userGroups = userGroups;

        const departments = await CustomerService.GetAccountDepartments(sessionInfo.Id, source.token);
        this.departments = departments;

        const responsibilityGroups = await ResponsibilityGroupService.GetList(source.token);

        const loginRules = await SettingsService.GetLoginRules(source.token);
        this.userSettingsLoginRules = loginRules.filter(x => x.Value == 1);

        const customerGroup = await CustomerGroupService.GetCustomerGroup(sessionInfo.CustomerGroup.Id, source.token);
        
        let tempMenus = buildVirtualMenu(customerGroup.Menu);
        
        this.menuFields.dataSource = tempMenus;
        this.menuData = tempMenus;

        this.userGroupDropDownParams = {
            create: () => {
                this.userGroupElem = document.createElement('input');
                return this.userGroupElem;
            },
            destroy: () => {
                this.userGroupObj.destroy();
            },
            read: () => {
                return this.userGroupElem.value;
            },
            write: (e) => {
                this.userGroupObj = new DropDownList({
                    change: (args) => {
                        
                    },
                    dataSource: new DataManager(userGroups),
                    fields: { value: 'Id', text: 'Name' },
                    floatLabelType: 'Never',
                    value: e.rowData.UserGroupId
                  });
                this.userGroupObj.appendTo(this.userGroupElem);
            },
        };

        this.departmentDropDownParams = {
            create: () => {
                this.departmentElem = document.createElement('input');
                return this.departmentElem;
            },
            destroy: () => {
                this.departmentObj.destroy();
            },
            read: () => {
                return this.departmentElem.value;
            },
            write: (e) => {
                this.departmentObj = new DropDownList({
                    change: (args) => {
                        
                    },
                    dataSource: new DataManager(departments),
                    fields: { value: 'Id', text: 'Name' },
                    floatLabelType: 'Never',
                    value: e.rowData.DepartmentId
                  });
                  this.departmentObj.appendTo(this.departmentElem);
            },
        };

        const gridSettings = await SettingsService.GetGridSettings(this.gridSettingsKey, source.token);
        if (!gridSettings.HasError) {
            this.gridSettings = gridSettings.Settings;
        }

        this.setState({ loading: false, responsibilityGroups: responsibilityGroups.Data, CustomerGroupMenu: customerGroup.Menu }, () => {
            window.addEventListener('wheel', this.onWheelScroll);
        });
    }

    init() {
        this.toolbarOptions = [
            { text: 'Rediger tabel', tooltipText: 'Rediger tabel', id: 'edit', prefixIcon: 'e-edit' },
            // { text: 'Nulstil udvalg', tooltipText: 'Nulstil udvalg', id: 'reset_filter', prefixIcon: 'e-refresh' },
            // 'Update', 
            // 'Cancel',
            { text: 'Øvrige indstillinger', tooltipText: 'Øvrige indstillinger', id: 'other_settings', prefixIcon: 'e-more-details', disabled: true },
            { id: 'reset', template: this.resetChangesTemplate, align: "Right" },
            { id: 'save_changes', template: this.saveChangesTemplate, align: "Right" },
            { text: Localization.General.GridResetFilter, tooltipText: Localization.General.GridResetFilter, id: 'reset_filter', prefixIcon: 'e-cancel' }
        ];
    }

    //#region Grid events
    onColumnDragStart(args) {
        this.hasDraggedColumn = true;
    }

    onCreated() {
        if (this.gridSettings) {
            let settings = null;
            try {
                settings = JSON.parse(this.gridSettings);
            } catch {
                settings = this.gridSettings;
            }
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(column => {
                        if (column.FromIndex !== column.ToIndex) {
                            var tempIndex = this.userGridRef.getColumnIndexByField(column.Field);
                            if (tempIndex !== column.ToIndex){
                                this.userGridRef.reorderColumnByIndex(column.FromIndex, column.ToIndex);
                            }
                        }
    
                        if (column.Width) {
                            this.userGridRef.columns[column.ToIndex].width = column.Width;
                        }
                    });
                }

                if (settings.colName && settings.direction) {
                    this.userGridRef.sortColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.userGridRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.userGridRef.refreshColumns();

                // Prevent resizing of last column
                let lastColumnIndex = this.userGridRef.columns.length - 1;    
                this.userGridRef.columns[lastColumnIndex].allowResizing = false;
                this.userGridRef.refreshColumns();

                if (settings.pageSize) {
                    if (settings.pageSize === "Alle" || !this.userGridRef.pageSettings.pageSizes.some(x => x === settings.pageSize.toString())) {
                        this.userGridRef.pageSettings.pageSize = "Alle"; //this.userGridRef.pageSettings.totalRecordsCount;
                        document.querySelector("#user-tools-grid .e-pagerdropdown input").value = "Alle";
                    }
                    else {
                        this.userGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
            }
        }
    }

    async onActionBegin(args) {
        if (args.requestType === "paging") {
            if (isNaN(this.userGridRef.pageSettings.pageSize)) {
                this.userGridRef.pagerModule.setPageSize(this.userGridRef.pageSettings.totalRecordsCount);
            }
        }
    }

    async onActionComplete(args) {
        if (args.requestType === 'paging') {
            if (this.userGridRef.pageSettings.pageSize === this.userGridRef.pageSettings.totalRecordsCount) {
                document.querySelector("#user-tools-grid .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, this.userGridRef.pageSettings.pageSize, this.userGridRef.pageSettings.totalRecordsCount, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };
            const result = await SettingsService.SaveGridSettings(payload, source.token);
            this.onWheelScroll();
        }
        else if (args.requestType === 'filtering') {
            if (this.gridSettings) {
                let settings = null;
                try {
                    settings = JSON.parse(this.gridSettings);
                } catch {
                    settings = this.gridSettings;
                }
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let pageSize = 0;
            if (this.userGridRef.getFilteredRecords().length > 0) {
                pageSize = this.userGridRef.getFilteredRecords().length;

                if (this.userGridRef.getFilteredRecords().length > 0 && this.userGridRef.getFilteredRecords().length === this.userGridRef.pageSettings.totalRecordsCount) {
                    document.querySelector("#user-tools-grid .e-pagerdropdown input").value = "Alle";
                }
            }
            else {
                pageSize = this.userGridRef.pageSettings.pageSize === this.userGridRef.pageSettings.totalRecordsCount ? "Alle" : this.userGridRef.pageSettings.pageSize;

                if (!this.userGridRef.pageSettings.pageSizes.some(x => x === pageSize)) {
                    pageSize = "Alle";
                }
                
                if (pageSize === "Alle") {
                    document.querySelector("#user-tools-grid .e-pagerdropdown input").value = "Alle";
                }
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, pageSize, this.userGridRef.pageSettings.totalRecordsCount, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.gridSettings) {
                let settings = null;
                try {
                    settings = JSON.parse(this.gridSettings);
                } catch {
                    settings = this.gridSettings;
                }
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.userGridRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, this.hasDraggedColumn, columns);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                let lastColumnIndex = this.userGridRef.columns.length - 1;
                for (let index = 0; index < this.userGridRef.columns.length; index++) {
                    let column = this.userGridRef.columns[index];
                    if (index === lastColumnIndex) { column.allowResizing = false }
                    else { column.allowResizing = true }
                }
                this.userGridRef.refreshColumns();
            }

            this.hasDraggedColumn = false;
        }
    }

    async onResizeStop(args) {
        args.requestType = "resizingGrid";
        let columns = this.userGridRef.getColumns();
        let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, columns);
        this.gridSettings = tempGridSettings;
        const payload = {
            Key: this.gridSettingsKey,
            Settings: this.gridSettings
        };

        const result = await SettingsService.SaveGridSettings(payload, source.token);

        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);

            // Adjust size of last column to fill spaces
            let lastColumnIndex = this.userGridRef.columns.length - 1;
            let totalColumnWidth = this.userGridRef.columns.reduce((acc, curVal) => acc + parseInt(curVal.width), 0);
            let lastColumnWidth = this.userGridRef.element.clientWidth - totalColumnWidth;
            this.userGridRef.columns[lastColumnIndex].width = this.userGridRef.columns[lastColumnIndex].width + lastColumnWidth;
            this.userGridRef.refreshColumns();
        }
    }
    
    async onToolbarClick(args) {
        if (args.item.id === 'edit') {
            this.userGridRef.editSettings.allowEditing = true;
            this.userGridRef.toolbarModule.enableItems(['edit'], false);
            this.userGridRef.toolbarModule.enableItems(['other_settings'], true);            
            toast("Tabel er nu åben for redigering", { position: 'top-center', hideProgressBar: true });
            
            let grid = document.getElementById('user-tools-grid');
            grid.classList.add("on-edit");
        }
        else if (args.item.id === 'other_settings') {
            let selectedRecord = this.userGridRef.getSelectedRecords();

            if (selectedRecord.length <= 0) {
                this.dialogOpen("Advarsel", Localization.General.NoRowSelected);
            }
            else {
                let { userSelected, tempOtherSettingsChanges, selectedUsers } = this.state;

                selectedUsers = this.userGridRef.getSelectedRecords().map(user => {
                    if (tempOtherSettingsChanges.some(x => x.Id === user.Id)) {
                        return tempOtherSettingsChanges.find(x => x.Id === user.Id);
                    } else {
                        return user;
                    }
                });

                if (tempOtherSettingsChanges.some(x => x.Id === selectedRecord[0].Id)) {
                    userSelected = tempOtherSettingsChanges.find(x => x.Id === selectedRecord[0].Id);                    
                }
                else {
                    userSelected = selectedRecord[0];
                }
                
                this.otherSettingsDialog.show();

                this.setState({ userSelected, selectedUsers }, () => {
                    const { CustomerGroupMenu } = this.state;
                    let menu = buildVirtualMenu(CustomerGroupMenu);
                    let checkedMenuNodes = buildCheckedNodes(menu, userSelected.CustomMenu);
                    this.userGroupTreeRef.fields.dataSource = [];
                    this.userGroupTreeRef.refresh();
                    this.menuFields.dataSource = checkedMenuNodes;
                    this.userGroupTreeRef.fields.dataSource = checkedMenuNodes;

                    setTimeout(() => {
                        this.userGroupTreeRef.collapseAll();
                    }, 300);
                });
            }
        } else if (args.item.id === 'reset_filter') {
            if (this.gridSettings) {
                let settings = null;
                try {
                    settings = JSON.parse(this.gridSettings);
                } catch {
                    settings = this.gridSettings;
                }

                settings.filters = [];
                settings.pageSize = "Alle";
                this.gridSettings = settings;
                const payload = {
                    Key: this.gridSettingsKey,
                    Settings: JSON.stringify(this.gridSettings)
                };
    
                const result = await SettingsService.SaveGridSettings(payload, source.token);
    
                if (!result.HasError) {
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                }
            }

            this.userGridRef.clearFiltering();
            setTimeout(() => {
                document.querySelector("#user-tools-grid .e-pagerdropdown input").value = "Alle";
            }, 600);
        }
    }

    resetChangesTemplate() {
        return <button className="e-btn e-control" style={{width: 184}} onClick={this.onResetChanges}>Nulstil Ændringer</button>

    }

    saveChangesTemplate() {
        return <button className="e-btn e-primary e-control" style={{width: 184}} onClick={this.onSaveChanges}>Opdater</button>
    }

    async onSaveChanges() {
        this.userGridRef.endEdit();

        let { changeLog, otherSettingsLog } = this.state;
        let selectedRows = this.userGridRef.getSelectedRecords();

        if (selectedRows.length > 0) {
            if (changeLog.length <= 0) {
                this.dialogOpen("Advarsel", "Der blev ikke foretaget nogen ændringer");
                return;
                // changeLog = selectedRows.map(c => { return {
                //     Id: c.Id, Username: c.Account.Username, currentData: c
                // }});
                // this.setState({ changeLog });
            }

            if (changeLog.length > 0 || otherSettingsLog.length > 0) {
                this.changeLogDialog.show();
            }
            else {
                this.dialogOpen("Advarsel", Localization.General.NoChangesMade);
            }
        }
        else {
            this.dialogOpen("Advarsel", "Der blev ikke foretaget nogen ændringer");
        }
    }

    async processUserChanges() {
        // TODO: call backend then refresh table
        let { changeLog, tempOtherSettingsChanges } = this.state;
        let selectedRecords = this.userGridRef.getSelectedRecords();
        let payload = [];

        for (let index = 0; index < selectedRecords.length; index++) {
            const item = selectedRecords[index];

            let otherSettings = tempOtherSettingsChanges.find(x => x.Id === item.Id);
            if (otherSettings) {
                item.CustomMenu = otherSettings.CustomMenu;
                item.ResponsiblityGroupIds = otherSettings.ResponsiblityGroupIds;
                item.OrdersApproval = otherSettings.OrdersApproval;
                item.Account.LoginRule = otherSettings.Account.LoginRule;
            }
            
            if (item.DepartmentName !== item.Department.Name) {
                let dept = this.departments.find(x => x.Name === item.DepartmentName);
                item.DepartmentId = dept.Id;
            }

            if (item.UserGroupName !== item.UserGroup.Name) {
                let grp = this.userGroups.find(x => x.Name === item.UserGroupName);
                item.UserGroupId = grp.Id;
            }

            payload.push({
                ...item,
                ChangeOrder: index + 1
            });
        }
        
        const result = await UserService.BulkSave(payload, source.token);

        if (!result.HasError) {
            let errorList = [];

            // for (let index = 0; index < result.length; index++) {
            //     const response = result[index];

            //     if (!response.Success) {
            //         let errItem = { Id: response.Id, errors: [] }
            //         for (let index = 0; index < response.Errors.length; index++) {
            //             const err = response.Errors[index];
            //             if (!errItem.errors.some(x => x === err)) {
            //                 errItem.errors.push(err);
            //             }
            //         }
            //         errorList.push(errItem);
            //     }
            // }

            // if (errorList.length > 0) {
            //     this.dialogOpen("Advarsel", "");
            // }
            // else {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Brugere"))
            // }
                        
            this.changeLogDialog.hide();
            this.onResetChanges();
        }
        else {
            this.dialogOpen("Advarsel", result.ErrorMessage);
        }
    }

    async onResetChanges() {
        this.userGridRef.closeEdit();
        this.userGridRef.clearRowSelection();
        this.userGridRef.editSettings.allowEditing = false;
        this.userGridRef.toolbarModule.enableItems(['edit'], true);
        this.userGridRef.toolbarModule.enableItems(['other_settings'], false);

        let grid = document.getElementById('user-tools-grid');
        grid.classList.remove("on-edit");

        const users = await CustomerService.GetUsers(this.CustomerId, { active: true }, source.token);
        this.data = users;
        this.userGridRef.dataSource = users;
        this.userGridRef.refresh();

        this.setState({ changeLog: [], hasUnsavedChanges: false, changeLogReport: [], otherSettingsLog: [], tempOtherSettingsChanges: [] });
    }
    //#endregion

    onResponsibilityChange(e, res) {
        let { userSelected, otherSettingsLog } = this.state;
        
        if (userSelected.ResponsiblityGroupIds.some(x => x === res.Id)) {
            if (!e.checked) {
                userSelected.ResponsiblityGroupIds = userSelected.ResponsiblityGroupIds.filter(i => i !== res.Id);
            }
        }
        else {
            userSelected.ResponsiblityGroupIds.push(res.Id);
        }

        if (!otherSettingsLog.some(x => x === "Ansvars Gruppe")) {
            otherSettingsLog.push("Ansvars Gruppe");
        }

        this.setState({ userSelected, hasUnsavedChanges: true, otherSettingsLog });
    }

    onOrdersApprovalChange(e) {
        let { userSelected, otherSettingsLog } = this.state;
        userSelected.OrdersApproval = e.checked;

        if (!otherSettingsLog.some(x => x === "Godkendelse af Ordre")) {
            otherSettingsLog.push("Godkendelse af Ordre");
        }
        
        this.setState({ userSelected, otherSettingsLog, hasUnsavedChanges: true });
    }

    onLoginRuleChange(e) {
        if (e.isInteracted) {
            let { userSelected, otherSettingsLog } = this.state;
            userSelected.Account.LoginRule = e.value;
            
            if (!otherSettingsLog.some(x => x === "Log in regel l på FlexMat")) {
                otherSettingsLog.push("Log in regel l på FlexMat");
            }
            this.setState({ userSelected, otherSettingsLog, hasUnsavedChanges: true });
        }
    }

    userGroupNodeSelected(e) {
        let { otherSettingsLog } = this.state;
        if (!otherSettingsLog.some(x => x === "Bruger Gr.")) {
            otherSettingsLog.push("Bruger Gr.");
            this.setState({ otherSettingsLog, hasUnsavedChanges: true });
        }
    }

    saveUserOtherSettings() {
        let { userSelected, tempOtherSettingsChanges, selectedUsers } = this.state;

        let checkedNodes = this.userGroupTreeRef.getAllCheckedNodes();
        let customMenu = buildMenuFromCheckedNodes(this.menuData, checkedNodes);

        userSelected.CustomMenu = customMenu;
        
        selectedUsers.forEach(user => {
            user.CustomMenu = userSelected.CustomMenu;
            user.Account.LoginRule = userSelected.Account.LoginRule;
            user.OrdersApproval = userSelected.OrdersApproval;
            user.ResponsiblityGroupIds = userSelected.ResponsiblityGroupIds;

            if (tempOtherSettingsChanges.some(x => x.Id === user.Id)) {
                let usr = tempOtherSettingsChanges.find(x => x.Id === user.Id)
                usr = user;
            }
            else {
                tempOtherSettingsChanges.push(user);
            }
        })

        userSelected = {};
        selectedUsers = [];
        this.userGroupTreeRef.checkedNodes = [];

        this.setState({ userSelected, tempOtherSettingsChanges }, () => {
            this.otherSettingsDialog.hide();
        });
    }

    beforeBatchSave(args) {
        let { changeLog } = this.state;

        let { changedRecords } = args.batchChanges;
        let dataSource = this.userGridRef.dataSource;
        if (changedRecords.length > 0) {
            for (let index = 0; index < changedRecords.length; index++) {
                const tempData = changedRecords[index];

                let sourceData = dataSource.find(d => d.Id === tempData.Id);
                let changeItem = {}, isExisting = false;

                if (changeLog.some(p => p.Id === sourceData.Id)) {
                    changeItem = changeLog.find(p => p.Id === sourceData.Id);
                    changeItem.currentData = sourceData;
                    isExisting = true;
                }
                else {
                    changeItem = {
                        Id: sourceData.Id,
                        IntUserName: tempData.Account.IntUserName,
                        FmUserName: tempData.Account.FmUserName,
                        OmUserName: tempData.Account.OmUserName,
                        currentData: sourceData
                    };
                }

                if (tempData.Account.IntUserName !== sourceData.Account.IntUserName) {
                    changeItem.isIntUserNameChanged = true;
                }

                if (tempData.Account.FmUserName !== sourceData.Account.FmUserName) {
                    changeItem.isFmUserNameChanged = true;
                }

                if (tempData.Account.OmUserName !== sourceData.Account.OmUserName) {
                    changeItem.isOmUserNameChanged = true;
                }

                if (tempData.Account.FirstName !== sourceData.Account.FirstName) {
                    changeItem.FirstName = tempData.Account.FirstName;
                }

                if (tempData.Account.MiddleName !== sourceData.Account.MiddleName) {
                    changeItem.MiddleName = tempData.Account.MiddleName;
                }

                if (tempData.Account.LastName !== sourceData.Account.LastName) {
                    changeItem.LastName = tempData.Account.LastName;
                }

                if (tempData.Position != sourceData.Position) {
                    changeItem.Position = tempData.Position;
                }

                if (tempData.Account.PhoneNumber !== sourceData.Account.PhoneNumber) {
                    changeItem.PhoneNumber = tempData.Account.PhoneNumber;
                }

                if (tempData.Account.Email !== sourceData.Account.Email) {
                    changeItem.Email = tempData.Account.Email;
                }

                if (tempData.Account.Initials !== sourceData.Account.Initials) {
                    changeItem.Initials = tempData.Account.Initials;
                }

                if (tempData.DepartmentName !== sourceData.DepartmentName) {
                    changeItem.DepartmentName = tempData.DepartmentName;
                }

                if (tempData.UserGroupName !== sourceData.UserGroupName) {
                    changeItem.UserGroupName = tempData.UserGroupName;
                }

                if (tempData.PrimaryContact !== sourceData.PrimaryContact) {
                    changeItem.PrimaryContact = tempData.PrimaryContact ? 'Ja' : 'Nej';
                }

                if (Object.keys(changeItem).length > 3 && !isExisting) {
                    changeLog.push(changeItem);
                }
            }
            
            this.setState({ changeLog, hasUnsavedChanges: true });
        }
    }

    onCellEdit(args) {
        this.userGridRef.selectRow(args.row.rowIndex);
    }

    onCellSave(args) {
        if (args.columnName === "Account.FirstName") {
            if (!args.value) {
                args.cancel = true;
                this.dialogOpen("Advarsel", "Fornavn kræves");
            }
        }
        else if (args.columnName === "Account.LastName") {
            if (!args.value) {
                args.cancel = true;
                this.dialogOpen("Advarsel", "Efternavn kræves");
            }
        }
        else if (args.columnName === "Account.Email") {
            let datasource = this.userGridRef.dataSource;
            let emails = datasource.map(x => { return { Id: x.Id, Email: x.Account.Email }});
            let sameEmail = emails.find(x => x.Email === args.value && x.Id !== args.rowData.Id);

            if (!args.value) {
                args.cancel = true;
                this.dialogOpen("Advarsel", "Mail kræves");
            }
            else if (sameEmail) {
                args.cancel = true;
                this.dialogOpen("Advarsel", Localization.User.ExistingEmailById.replace("{id}", sameEmail.Id));
            }
        }
        else if (args.columnName === "Account.IntUserName") {
            let datasource = this.userGridRef.dataSource;
            let usernames = datasource.map(x => {return { Id: x.Id, IntUserName: x.Account.IntUserName}});
            let sameUsername = usernames.find(x => x.IntUserName === args.value && x.Id !== args.rowData.Id);

            if (!args.value) {
                args.cancel = true;
                this.dialogOpen("Advarsel", "Brugernavn kræves");
            }
            else if (!args.value.match(/^[a-z0-9]+$/i)) {
                args.cancel = true;
                this.dialogOpen("Advarsel", Localization.Customer.ErrorInvalidUserName);
            }
            else if (sameUsername) {
                args.cancel = true;
                this.dialogOpen("Advarsel", Localization.User.ExistingUsernameById.replace("{id}", sameUsername.Id));
            }
        }
    }

    dialogOpen(header, content) {
        this.notificationDialog.header = header;
        this.notificationDialog.content = content;
        this.notificationDialog.buttons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        this.notificationDialog.show();
    }

    colorEditableCell(args) {
        if (args.column.index > 0 && !args.column.allowEditing) {
            args.cell.classList.add("cell-non-editable");
        }
    }

    onWheelScroll = () => {
        let grid = document.getElementById('user-tools-grid');
        let toolbar = document.querySelector('#user-tools-grid.e-grid .e-toolbar');
        let tableHeader = document.querySelector('#user-tools-grid .e-gridheader');

        if (toolbar && tableHeader) {
            let boundingRect = tableHeader.getBoundingClientRect();
            
            if (boundingRect.top < 90) {
                toolbar.classList.add('c-hover-grid-toolbar');
                toolbar.style.width = (grid.clientWidth - 5) + 'px';
            }
            else {
                toolbar.classList.remove('c-hover-grid-toolbar');
                toolbar.style.width = 'auto';
            }
        }
    }

    formatChangeValue(value) {
        return typeof value !== 'undefined' ? (value ? value : "Slettet") : '';
    }

    render() {
        const { loading, redirect, hasUnsavedChanges, userSelected, responsibilityGroups, otherSettingsLog, changeLog } = this.state;

        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        return (
            <div className="content-pane-wrapper">
                <NavigationPrompt when={hasUnsavedChanges} 
                    navigate={path => this.props.history.push(path)}
                    initiateSave={() => this.onSaveChanges()}
                    shouldBlockNavigation={location => {
                        if (hasUnsavedChanges) {
                            return true;
                        }
                        return false;
                    }}
                />
                <div className="module-header">
                    <h1>{this.pageName}</h1>
                </div>
                <div className="c-post-note">
                    <div className="post-note-wrapper">
                        <div className="post-note-content text-left">
                            <h2>Instruktioner</h2>
                            <ul className="c-bullet">
                                <li>Rediger tabel: Åbner tabel for redigering</li>
                                <li>Øvrige indstillinger: Åbner øvrige indstillinger</li>
                                <li>Nulstil filter: Fjerne filtre, så alle bruger vises igen</li>
                                <li>Nulstil Ændringer: Fjerner alle ændringer</li>
                                <li>Opdater: Gennemfører ændringer. (gælder kun markeret brugere)</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <GridComponent id="user-tools-grid" ref={ref => this.userGridRef = ref } dataSource={this.data} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings} toolbar={this.toolbarOptions}
                        selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both" allowReordering={true} allowResizing={true} 
                        editSettings={this.editSettings} toolbarClick={this.onToolbarClick} actionBegin={this.onActionBegin.bind(this)} actionComplete={this.onActionComplete.bind(this)} columnDragStart={this.onColumnDragStart.bind(this)} 
                        created={this.onCreated.bind(this)} resizeStop={this.onResizeStop.bind(this)}
                        beforeBatchSave={this.beforeBatchSave.bind(this)} cellSave={this.onCellSave.bind(this)} cellEdit={this.onCellEdit.bind(this)}
                        queryCellInfo={this.colorEditableCell}>
                        <ColumnsDirective>
                            <ColumnDirective type='checkbox' width="40" textAlign="Center"></ColumnDirective>
                            <ColumnDirective headerText="Bruger#" customAttributes={{class: 'cell-non-editable'}} field="Id" width='65' isPrimaryKey={true} allowFiltering={false} />
                            <ColumnDirective headerText="Fornavn" field="Account.FirstName" width='100' />
                            <ColumnDirective headerText="Mellem Navn" field="Account.MiddleName" width='100' />
                            <ColumnDirective headerText="Efternavn" field="Account.LastName" width='100' />
                            <ColumnDirective headerText="Stilling" field="Position" width='100' />
                            <ColumnDirective headerText="Tlf. nr." field="Account.PhoneNumber" width='100' />
                            <ColumnDirective headerText="Mail" field="Account.Email" width='100' />
                            <ColumnDirective headerText="Initialer" field="Account.Initials" width='70' />
                            <ColumnDirective headerText="Brugernavn for Intralogix" field="Account.IntUserName" width='100' />
                            <ColumnDirective headerText="Brugernavn for FlexMat" field="Account.FmUserName" width='100' />
                            <ColumnDirective headerText="Brugernavn for OilMat" field="Account.OmUserName" width='100' />
                            <ColumnDirective headerText="Afdeling" field="DepartmentName" width='100' editType="dropdownedit" edit={this.departmentDropDownParams} />
                            <ColumnDirective headerText="Brugergruppe" field="UserGroupName" width='100' editType="dropdownedit" edit={this.userGroupDropDownParams} />
                            <ColumnDirective headerText="Primær kontakt" field="PrimaryContact" width='100' textAlign="Center" editType="booleanedit" displayAsCheckBox={true}  />
                        </ColumnsDirective>
                        <Inject services={[Page, Sort, Filter, Selection, Toolbar, Search, Reorder, Resize, Edit]} />
                    </GridComponent>
                </div>
                <div>
                    <DialogComponent id="user-tool-dialog" isModal={true} buttons={this.notificationButtons} width='auto' ref={dialog => this.notificationDialog = dialog} target='body'
                        visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} />
                </div>
                <div>
                    <DialogComponent id="changes-report-dialog" isModal={true} buttons={this.changeLogButtons} width='auto' ref={dialog => this.changeLogDialog = dialog} target='body' 
                        visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} header="Rapport over ændringer">
                        <div>
                            {
                                otherSettingsLog.length > 0 &&
                                <React.Fragment>
                                    <div>
                                        <h3>Øvrige indstillinger</h3>
                                        <ul className="misc-changes">
                                            {
                                                otherSettingsLog.map(o => {
                                                    return <li>{o}</li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div className="report-separator"></div>
                                </React.Fragment>
                            }
                            <div>
                                <h3>Liste over brugere der ændres</h3>
                                <div className="list-context-changes">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Bruger#</th>
                                                <th className="text-left">Brugernavn for Intralogix</th>
                                                <th className="text-left">Brugernavn for FlexMat</th>
                                                <th className="text-left">Brugernavn for OilMat</th>
                                                <th className="text-left">Fornavn</th>
                                                <th className="text-left">Mellem Navn</th>
                                                <th className="text-left">Efternavn</th>
                                                <th className="text-left">Stilling</th>
                                                <th className="text-left">Tlf. nr.</th>
                                                <th className="text-left">Mail</th>
                                                <th className="text-left">Initialer</th>
                                                {/* <th className="text-left">Brugernavn</th> */}
                                                <th className="text-left">Afdeling</th>
                                                <th className="text-left">Brugergruppe</th>
                                                <th className="text-left">Primær kontakt</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                changeLog.map(user => {
                                                    let keys = Object.keys(user);
                                                    let isFirstNameChanged = keys.some(x => x === "FirstName");
                                                    let isMiddleNameChanged = keys.some(x => x === "MiddleName");
                                                    let isLastNameChanged = keys.some(x => x === "LastName");
                                                    let isPositionChanged = keys.some(x => x === "Position");
                                                    let isPhoneNumberChanged = keys.some(x => x === "PhoneNumber");
                                                    let isEmailChanged = keys.some(x => x === "Email");
                                                    let isInitialsChanged = keys.some(x => x === "Initials");
                                                    let isDepartmentChanged = keys.some(x => x === "DepartmentName");
                                                    let isUserGroupChanged = keys.some(x => x === "UserGroupName");
                                                    let isPrimaryContactChanged = keys.some(x => x === "PrimaryContact");

                                                    let primaryContactText = '';
                                                    if (!isPrimaryContactChanged) {
                                                        primaryContactText = user.currentData.PrimaryContact ? 'Ja' : 'Nej';
                                                    }

                                                    return (
                                                        <tr className="changelog-row">
                                                            <td>{user.Id}</td>
                                                            <td className={user.isIntUserNameChanged ? "changelog-item" : ''}>{user.IntUserName}</td>
                                                            <td className={user.isFmUserNameChanged ? "changelog-item" : ''}>{user.FmUserName}</td>
                                                            <td className={user.isOmUserNameChanged ? "changelog-item" : ''}>{user.OmUserName}</td>
                                                            <td className={isFirstNameChanged ? 'changelog-item' : ''}>
                                                                {isFirstNameChanged ? this.formatChangeValue(user.FirstName) : user.currentData.Account.FirstName}
                                                            </td>
                                                            <td className={isMiddleNameChanged ? 'changelog-item' : ''}>
                                                                {isMiddleNameChanged ? this.formatChangeValue(user.MiddleName) : user.currentData.Account.MiddleName}
                                                            </td>
                                                            <td className={isLastNameChanged ? 'changelog-item' : ''}>
                                                                {isLastNameChanged ? this.formatChangeValue(user.LastName) : user.currentData.Account.LastName}
                                                            </td>
                                                            <td className={isPositionChanged ? 'changelog-item' : ''}>
                                                                {isPositionChanged ? this.formatChangeValue(user.Position) : user.currentData.Position}
                                                            </td>
                                                            <td className={isPhoneNumberChanged ? 'changelog-item' : ''}>
                                                                {isPhoneNumberChanged ? this.formatChangeValue(user.PhoneNumber) : user.currentData.Account.PhoneNumber}
                                                            </td>
                                                            <td className={isEmailChanged ? 'changelog-item' : ''}>
                                                                {isEmailChanged ? this.formatChangeValue(user.Email) : user.currentData.Account.Email}
                                                            </td>
                                                            <td className={isInitialsChanged ? 'changelog-item' : ''}>
                                                                {isInitialsChanged ? this.formatChangeValue(user.Initials) : user.currentData.Account.Initials}
                                                            </td>
                                                            {/* <td className="changelog-item">{user.ModUsername}</td> */}
                                                            <td className={isDepartmentChanged ? 'changelog-item' : ''}>
                                                                {isDepartmentChanged ? user.DepartmentName : user.currentData.DepartmentName}
                                                            </td>
                                                            <td className={isUserGroupChanged ? 'changelog-item' : ''}>
                                                                {isUserGroupChanged ? user.UserGroupName : user.currentData.UserGroupName}
                                                            </td>
                                                            <td className={isPrimaryContactChanged ? 'changelog-item' : ''}>
                                                                {isPrimaryContactChanged ? user.PrimaryContact : primaryContactText}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="report-separator"></div>
                            <div>
                                <div className="c-primary-text c-bold">Kontroller at ovenstående oplysninger er korrekte, tryk derefter opdater.</div>
                                <br />
                                <div className="sub-text">Bemærk dette kan ikke fortrydes</div>
                            </div>
                        </div>
                    </DialogComponent>
                </div>
                <div>
                    <DialogComponent id="other-settings-dialog" isModal={true} buttons={this.otherSettingButtons} width='auto' ref={dialog => this.otherSettingsDialog = dialog} target='body'
                        visible={false} showCloseIcon={true} animationSettings={this.animationSettings} allowDragging={true} enableResize={true} header="Øvrige Indstillinger">
                            <div className="other-settings-wrapper">
                                <div className="c-post-note display-block">
                                    <div className="post-note-wrapper">
                                        <div className="post-note-content text-left">
                                            <h2>Instruktioner</h2>
                                            <div>
                                                Lav indstillingerne for de brugere der skal redigeres, tryk OK for at gemme og vende tilbage til brugere
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="user-custom-menu">
                                    <Card headerText="Bruger Gr." subText="Klik på + for tilhørende funktioner">
                                        <TreeViewComponent id="user-settings-user-group" ref={treeview => this.userGroupTreeRef = treeview} fields={this.menuFields} expandOn="Click"
                                            cssClass="sidebar-tree-menu" showCheckBox={true} nodeChecked={this.userGroupNodeSelected.bind(this)} />
                                    </Card>
                                </div>
                                <div className="user-misc-settings">
                                    <Card headerText="Log in regel l på FlexMat" className="mb-30">
                                        <DropDownListComponent id="ddlLoginRule" ref={ref => this.ddlLoginRuleRef = ref} name="LoginRule" value={userSelected.Account ? userSelected.Account.LoginRule : ''} dataSource={this.userSettingsLoginRules} 
                                            placeholder="Log in regel l på FlexMat" fields={{ text: 'Key', value: 'Value' }} change={this.onLoginRuleChange} locale="da" />
                                    </Card>
                                    <Card headerText="Notifikation" className="access-control-panel" showHeader={false}>
                                        <div className="field-bordered">
                                            <label htmlFor="OrdersApproval" style={{fontSize:13}}>Godkendelse af Ordre</label>
                                            <CheckBoxComponent id="OrdersApproval" ref={c => this.OrdersApprovalRef = c} name="OrdersApproval" cssClass="control-right" checked={userSelected.OrdersApproval} 
                                                change={this.onOrdersApprovalChange} />
                                            {/* checked={userInfo.AccessControlPanel}   */}
                                        </div>
                                    </Card>
                                </div>
                                <div className="responsibility-groups">
                                    <Card headerText="Ansvars Gruppe">
                                        {
                                            responsibilityGroups.length <= 0  ?
                                            <tr>
                                                <td colSpan="2" className="sub-text text-cenetr">{Localization.General.EmptyList}</td>
                                            </tr>
                                            :
                                            responsibilityGroups.map((res, index) => {
                                                let isChecked = userSelected.ResponsiblityGroupIds ? userSelected.ResponsiblityGroupIds.some(x => x === res.Id) : false;
                                                return (
                                                    <div className="field-bordered regular-text">
                                                        <label htmlFor={`rg_${res.Id}_${index}`} style={{fontSize:13}}>{res.Name}</label>
                                                        <CheckBoxComponent id={`rg_${res.Id}_${index}`} cssClass="control-right" checked={isChecked} 
                                                            change={(e) => this.onResponsibilityChange(e, res)} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </Card>
                                </div>
                            </div>
                    </DialogComponent>
                </div>
            </div>
        )
    }
}

export default UserTools;
