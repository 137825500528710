import React from 'react';
import { ToolbarComponent, ItemsDirective, ItemDirective, TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { ListViewComponent, Inject as InjectListView, Virtualization } from '@syncfusion/ej2-react-lists';
import { ButtonComponent, CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { Internationalization, createElement, detach, EventHandler, select } from '@syncfusion/ej2-base';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { RichTextEditorComponent, Inject as InjectRTE, Link, Image, HtmlEditor, Toolbar, QuickToolbar } from '@syncfusion/ej2-react-richtexteditor';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { Redirect } from 'react-router-dom';
import QueryString from 'query-string';
import { UploaderComponent, FilesDirective, UploadedFilesDirective } from '@syncfusion/ej2-react-inputs/src/uploader';

import Axios from 'axios';
import { getFileIcon, isImage } from '../utilities/Helper';
import AppSettings from '../settings/AppSettings';
import BaseComponent from '../components/BaseComponent';
import SessionService from '../services/SessionService';
import CustomToolbar from '../components/Toolbar';
import Loading from '../components/Loading';
import MessageService from '../services/Messages/MessagesService';
import SettingsService from '../services/SettingsService';
import '../styles/messages.scss';
import Localization from '../utilities/Localization';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

class Messages extends BaseComponent {
    moduleName = "Beskeder";
    pageName = "Beskeder";
    searchTimer = null
    contactList = [];
    filesList = [];
    filesDetails = [];

    defaultMessage = () => {
        return {
            Id: null,
            To: '',
            Cc: '',
            From: {
                Name: '',
                Address: ''
            },
            Subject: '',
            Body: '',
            FileAttachments: []
        };        
    }

    constructor() {
        super();

        // const mailBox = [
        //     { id: 1, name: 'Indbakke', selected: true, count: '4', iconClass: 'c-icon-inbox' },
        //     { id: 2, name: 'Kladder', count: '2', iconClass: 'c-icon-drafts' },
        //     { id: 3, name: 'Arkiv', count: '2', iconClass: 'c-icon-archive' },
        //     { id: 4, name: 'Slettet', count: '2', iconClass: 'c-icon-delete' },
        //     { id: 5, name: 'Sendt', iconClass: 'c-icon-sent' }
        // ];
    
        this.mailboxFolderFields = { dataSource: [], id: 'id', text: 'Key', hasChildren: 'hasChild' };    
        this.listFields = { text: 'Id' };
        this.multiSelectFields = { text: "Name", value: "Address" };

        this.state = {
            loading: true,
            messageContent: null,
            isNewMessage: false,
            loadingFolderMessagePane: false,
            loadingMessagePane: false,
            folderMessagesData: [],
            mailboxFolders: [],
            isSelectionMode: false,
            selectedMessages: [],
            message: {
                Id: null,
                To: '',
                Cc: '',
                From: {
                    Name: '',
                    Address: ''
                },
                Subject: '',
                Body: '',
                FileAttachments: []
            },
            searchString: '',
            folderId: -1,
            redirect: {
                to: '',
                isRedirect: false
            },
            filterText: "Sortere efter nyeste"
        }

        this.format = {
            types: [
              { text: "Paragraf", value: "p", cssClass: "e-paragraph" },
              { text: "Kode", value: "pre", cssClass: "e-code" },
              { text: "Citationstegn", value: "blockquote", cssClass: "e-quote" },
              { text: "Overskrift 1", value: "h1", cssClass: "e-h1" },
              { text: "Overskrift 2", value: "h2", cssClass: "e-h2" },
              { text: "Overskrift 3", value: "h3", cssClass: "e-h3" },
              { text: "Overskrift 4", value: "h4", cssClass: "e-h4" },
              { text: "Overskrift 5", value: "h5", cssClass: "e-h5" },
              { text: "Overskrift 6", value: "h6", cssClass: "e-h6" }
            ]
        };

        this.toolbarSettings = {
            items: [ 'Bold', 'Italic', 'Underline', '|', 'Formats', 'Alignments', 'OrderedList', 'UnorderedList', '|', 'CreateLink', 'Image', '|',
                {
                    tooltipText: 'Vedhæft filer...',
                    template: '<button id="browse-file" class="e-tbar-btn e-btn"><div>Vedhæft filer...</div></button>'
                }, '|', 'Undo', 'Redo'
            ]
        }

        this.uploaderAsyncSettings = {
            saveUrl: `${AppSettings.API_URL}/file/uploadAttachment`,
            removeUrl: `${AppSettings.API_URL}/file/removeAttachment`
        };

        this.FolderPanel = this.FolderPanel.bind(this);
        this.FolderMessagesPanel = this.FolderMessagesPanel.bind(this);

        this.getMessagesByFolder = this.getMessagesByFolder.bind(this);
        this.getMessage = this.getMessage.bind(this);
        this.onCreateNewMessage = this.onCreateNewMessage.bind(this);
        this.readMessage = this.readMessage.bind(this);
        this.createMessage = this.createMessage.bind(this);
        this.folderMessageItem = this.folderMessageItem.bind(this);

        this.sendEmail = this.sendEmail.bind(this);
        this.refreshFolderMessages = this.refreshFolderMessages.bind(this);
        this.selectionMode = this.selectionMode.bind(this);
        this.onMessageSearch = this.onMessageSearch.bind(this);
        this.onBulkOperationClicked = this.onBulkOperationClicked.bind(this);
        this.onBulkOperationSelect = this.onBulkOperationSelect.bind(this);
        this.onMessageActionToolbarClick = this.onMessageActionToolbarClick.bind(this);
        this.onCancelWriteEmail = this.onCancelWriteEmail.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.onSendInfoChange = this.onSendInfoChange.bind(this);
        this.multiSelectItemTemplate = this.multiSelectItemTemplate.bind(this);
        this.saveAsDraft = this.saveAsDraft.bind(this);
        this.onQuickFilterSelect = this.onQuickFilterSelect.bind(this);
        this.onEditDraft = this.onEditDraft.bind(this);
        
        this.messageQuickFilterItems = [
            { text: 'Ulæst', id: 'unread' }, { text: 'Læst', id: 'read' },
            { separator: true },
            { text: 'Sortere efter nyeste', id: 'sort-new' }, { text: 'Sortere efter ældste', id: 'sort-old' }
        ];

        this.messageOperationItems = [
            { text: 'Marker som ulæst', id: 'mark-unread' }, { text: 'Marker som læst', id: 'mark-read' }
        ]

        //Set customized group-header template
        this.grouptemplate = '<div class="e-list-wrapper"><span class="e-list-item-content"></span></div>';

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.buttons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        /* End Dialog options  */
    }

    async componentDidMount() {
        super.componentDidMount();
        source.cancel();
        source = CancelToken.source();

        if(!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }
        
        let { folderMessagesData, mailboxFolders, isNewMessage, message } = this.state;

        const session = await SessionService.GetSessionInfo();
        
        this.uploaderAsyncSettings.saveUrl = this.uploaderAsyncSettings.saveUrl + `?access_token=${session.access_token}`;
        this.uploaderAsyncSettings.removeUrl = this.uploaderAsyncSettings.removeUrl + `?access_token=${session.access_token}`;
        this.AccessToken = session.access_token;

        const mailboxFoldersResult = await SettingsService.GetMailboxFolders(source.token);
        const messagesResult = await MessageService.GetMessages(null, source.token);

        mailboxFoldersResult.forEach(folder => {
            let msg = messagesResult.Messages.filter(x => x.MessageFolderType === folder.Value && x.Read === false);
            folder.count = msg.length;
        });

        mailboxFolders = mailboxFoldersResult;
        this.messages = messagesResult.Messages;
        this.mailboxFolderFields.dataSource = mailboxFoldersResult;

        const currentFolder = mailboxFolders.find(f => f.selected);
        folderMessagesData = messagesResult.Messages.filter(d => d.MessageFolderType === currentFolder.id)

        const contactList = await MessageService.GetContacts(source.token);
        this.contactList = contactList;

        window.addEventListener('resize', this.resize);
        this.resize();

        let { location } = this.props;

        if (location.search) {
            let queries = location.search.substring(1).split('&');
            for (let index = 0; index < queries.length; index++) {
                let val = QueryString.parse(queries[index]);
                if ('m' in val) {
                    if (val.m === 'new') {
                        isNewMessage = true;
                    }
                }
                if ('s' in val) {
                    if (val.s === 'video-arkiv') {
                        this.redirectSource = val.s;

                        let attachments = localStorage.getItem("MSG_VIDEO_ATTACHMENTS");
                        if (attachments) {
                            attachments = JSON.parse(attachments);
                            message.FileAttachments = attachments;
                        }
                    }
                }
            }
        }

        this.setState({ loading: false, folderMessagesData, mailboxFolders, folderId: 1, message, isNewMessage }, () => {
            if (isNewMessage) {
                this.adjustMessagePanelHeight();
            }

            const folderMessages = document.getElementById('folder-messages-list');
            if (folderMessages) {
                folderMessages.style.height = `calc(100vh - ${folderMessages.offsetTop + 72}px)`;
            }

            const newMessagePane = document.getElementsByClassName('new-message-pane')[0];
            if (newMessagePane) {
                newMessagePane.style.height = `calc(100vh - ${newMessagePane.offsetTop + 72}px)`;
                newMessagePane.style.overflowY = "auto";
            }
        });
    }

    resize() {
        let folderMessageList = document.getElementById('folder-messages-list');
        
        if (folderMessageList) {
            folderMessageList.style.height = `calc(100vh - ${72 + folderMessageList.offsetTop}px)`;
        }
    }

    async getMessagesByFolder(folderId) {
        let { folderMessagesData, mailboxFolders } = this.state;

        await this.saveAsDraft();

        this.setState({ loadingFolderMessagePane: true });

        // CALL API 
        const result = await MessageService.GetMessages({ folderId }, source.token);
        folderMessagesData = result.Messages;

        mailboxFolders.forEach(folder => { folder.selected = false; });
        let folder = mailboxFolders.find(x => x.id === parseInt(folderId));
        folder.selected = true;
        this.setState({ loadingFolderMessagePane: false, folderMessagesData, isNewMessage: false, isSelectionMode: false, folderId }, () => {
            let messageList = document.querySelectorAll('#folder-messages-list ul li');
            if (messageList) {
                messageList.forEach(elem => {
                    let messageWrapper = elem.querySelector('.folder-message-wrapper');
                    if (messageWrapper) {
                        messageWrapper.classList.remove('is-selectionmode');
                    }
                });
            }

            const folderItems = document.querySelectorAll('#message-folder-tree .e-ul .e-list-item');
            folderItems.forEach(folder => {
                let iconElement = folder.querySelector('.message-folder-icon');
                let iconClass = iconElement.classList[1].split('-');
                iconElement.classList.remove(iconElement.classList[1]);
                iconClass[iconClass.length - 1] = 'inactive';
                iconElement.classList.add(iconClass.join('-'));
            });
            const activeNode = document.querySelector('#message-folder-tree .e-ul .e-active .message-folder-icon');
            if (activeNode) {
                let iconClass = activeNode.classList[1].split('-');
                activeNode.classList.remove(activeNode.classList[1]);
                iconClass[iconClass.length - 1] = 'active';
                activeNode.classList.add(iconClass.join('-'));
            }

            if (this.folderMessagesRef) {
                this.folderMessagesRef.dataSource = folderMessagesData;
                this.folderMessagesRef.dataBind();
            }
            
            this.resize();
        });
    }

    async refreshFolderMessages() {
        let { mailboxFolders, folderMessagesData } = this.state;
        this.setState({ loadingFolderMessagePane: true });
        let folder = mailboxFolders.find(x => x.selected);
        const result = await MessageService.GetMessages({ folderId : folder.id }, source.token);
        folderMessagesData = result.Messages;

        let message = this.defaultMessage();
        this.setState({ loadingFolderMessagePane: false, folderMessagesData, message, isNewMessage: false, searchString: '', selectedMessages: [], isSelectionMode: false }, () => {
            if (this.folderMessagesRef) {
                this.folderMessagesRef.dataSource = folderMessagesData;
                this.folderMessagesRef.dataBind();
                
                this.updateUnreadMessageCounter();
            }
            this.resize();
        });
    }

    selectionMode() {
        const { isSelectionMode } = this.state;

        let messageList = document.querySelectorAll('#folder-messages-list ul li');

        messageList.forEach(elem => {
            let messageWrapper = elem.querySelector('.folder-message-wrapper');
            if (!isSelectionMode) {
                messageWrapper.classList.add('is-selectionmode');
            }
            else {
                messageWrapper.classList.remove('is-selectionmode');
            }
        });

        this.setState({ isSelectionMode: !isSelectionMode });
    }

    onEditDraft(messageId){
        let { folderMessagesData, message, folderId } = this.state;
        if (folderId == 2) {
            const selectedMessage = folderMessagesData.find(x => x.Id === messageId);
            message.To = selectedMessage.To;
            message.Cc = selectedMessage.Cc;
            message.Body = selectedMessage.Body;
            message.ThreadId = selectedMessage.ThreadId;
    
            this.setState({ isNewMessage: true, parentAction: '', message }, () => {
                this.adjustMessagePanelHeight();
    
                if (typeof message.To === 'object') {
                    this.SendToRef.value = message.To.map(x => x.Address);
                }
                if (typeof message.Cc === 'object') {
                    this.SendCcRef.value = message.Cc.map(x => x.Address);
                }            
            });
        }
    }

    onBrowseFile() {
        let element = select('.e-file-select-wrap button');
        element.click();
    }

    async getMessage(messageId, event) {
        let { folderMessagesData, message } = this.state;

        await this.saveAsDraft();
        this.setState({ loadingMessagePane: true });

        const selectedMessage = folderMessagesData.find(x => x.Id === messageId);
        if (!selectedMessage.Read) {
            selectedMessage.Read = true;
            MessageService.MarkUnreadRead(messageId, true, source.token);

            if (event.target) {
                let messageElement = event.target.parentElement.parentElement;
                if (messageElement) {
                    if (messageElement.classList.contains('is-unread')) {
                        messageElement.classList.remove('is-unread');
                    }
                }
            }
            else {
                let item = document.querySelector(`div[data-id='${messageId}']`);
                if (item) {
                    if (item.parentElement.classList.contains('is-unread')) {
                        item.parentElement.classList.remove('is-unread');
                    }
                }
            }

            let folderUnreadCount = document.querySelector('#message-folder-tree li.e-active .countcontainer');
            if (folderUnreadCount) {
                let text = +folderUnreadCount.textContent;

                if (text - 1 > 0) {
                    folderUnreadCount.querySelector('.treeCount').innerHTML = text - 1;
                }
                else {
                    folderUnreadCount.querySelector('.treeCount').remove();
                }
            }
        }

        message = this.defaultMessage();
        Object.assign(message, selectedMessage);

        this.setState({ isNewMessage: false, loadingMessagePane: false, message, folderMessagesData });
    }

    async saveAsDraft() {
        source.cancel();
        source = CancelToken.source();

        const { message, folderId } = this.state;
        let to = [], cc = [], subject = '', body = '';

        if (this.SendToRef) {
            if (this.SendToRef.value && this.SendToRef.value.length > 0) {
                to = this.SendToRef.value.map(t => { return { Name: "", Address: t } });
            }
        }
        
        if (this.SendCcRef) {
            if (this.SendCcRef.value && this.SendCcRef.value.length > 0) {
                cc = this.SendCcRef.value.map(t => { return { Name: "", Address: t } });
            }
        }

        if (this.SendSubjectRef) {
            subject = this.SendSubjectRef.value;
        }

        if (this.richTextEditorRef) {
            body = this.richTextEditorRef.value;
        }

        if (subject || body) {
            const payload = {
                Id: message.Id,
                ThreadId: message.ThreadId,
                To: to,
                Cc: cc,
                Subject: subject,
                Body: body,
                FileAttachments: []
            };

            if (this.filesDetails.length > 0) {
                payload.FileAttachments = payload.FileAttachments.concat(this.filesDetails.map(d => {
                    return {
                        Key: d.Key,
                        Name: d.name
                    }
                }));
            }
    
            const result = await MessageService.SaveAsDraft(payload, source.token);
            if (!result.HasError) {
                // Update draft count
                if (folderId == 2) {
                    this.refreshFolderMessages();
                }
            }
        }
        else {
            return false;
        }
    }

    async onMessageActionToolbarClick(args) {
        let { message, mailboxFolders, folderId } = this.state;
        if (args.item.id === 'reply') {
            message.Id = null;
            message.To = message.From.Address;
            if (typeof message.Cc === 'object') {
                let ccTemp = message.Cc.map(x => x.Address);
                message.Cc = ccTemp.length > 0 ? ccTemp.join('; ') : '';
            }            
            message.Body = `<br><br><div style="mso-element:para-border-div;border:none;border-top:solid #E1E1E1 1.0pt;padding:3.0pt 0in 0in 0in">${message.Body}</div>`;

            this.setState({ isNewMessage: true, parentAction: 'reply', message }, () => {
                this.adjustMessagePanelHeight();

                this.SendToRef.value = [message.From.Address];
                if (typeof message.Cc === 'object') {
                    this.SendCcRef.value = message.Cc.map(x => x.Address);
                }            
            });
        }
        else if (args.item.id === 'replyall') {
            message.Id = null;
            message.To = message.From.Address;
            if (typeof message.Cc === 'object') {
                let ccTemp = message.Cc.map(x => x.Address);
                message.Cc = ccTemp.length > 0 ? ccTemp.join('; ') : '';
            }
            message.Body = `<br><br><div style="mso-element:para-border-div;border:none;border-top:solid #E1E1E1 1.0pt;padding:3.0pt 0in 0in 0in">${message.Body}</div>`;

            this.setState({ isNewMessage: true, parentAction: 'replyall', message }, () => {
                this.adjustMessagePanelHeight();
            });
        }
        else if (args.item.id === 'forward') {
            message.Id = null;
            message.To = '';
            message.Cc = '';
            message.Subject = `FWD: ${message.Subject}`;
            message.Body = `<br><br><div style="mso-element:para-border-div;border:none;border-top:solid #E1E1E1 1.0pt;padding:3.0pt 0in 0in 0in">${message.Body}</div>`;

            this.setState({ isNewMessage: true, parentAction: 'forward' }, () => {
                this.adjustMessagePanelHeight();
            });
        }
        else if (args.item.id === 'move') {
            const folder = mailboxFolders.find(x => x.selected);
            let result = null;

            switch (folder.Value) {
                case 1:
                    result = await MessageService.MoveToArchive(message.Id, source.token);
                    if (!result.HasError) {
                        this.dialogOpen(Localization.General.SuccessHeader, Localization.Message.SuccessMoveMessageToArchive);
                        this.setState({ message: this.defaultMessage() }, () => {
                            this.refreshFolderMessages();
                        });
                    }
                    else {
                        this.dialogOpen("Advarsel", result.ErrorMessage);
                    }
                    break;
                case 3:
                    result = await MessageService.MoveToInbox(message.Id, source.token);
                    if (!result.HasError) {
                        this.dialogOpen(Localization.General.SuccessHeader, Localization.Message.SuccessMoveMessageToInbox);
                        this.setState({ message: this.defaultMessage() }, () => {
                            this.refreshFolderMessages();
                        });
                    }
                    else {
                        this.dialogOpen("Advarsel", result.ErrorMessage);
                    }
                    break;
                default:
                    this.dialogOpen("Advarsel", Localization.Message.MessageNotAllowedToBeModified);
                    break;
            }
        }
        else if (args.item.id === 'delete') {
            const result = folderId == 4 ? await MessageService.DeleteMessage(message.Id, source.token) : await MessageService.MoveToTrash(message.Id, source.token);
            if (!result.HasError) {
                const result = await MessageService.GetMessages({ folderId }, source.token);
                let folderMessagesData = result.Messages;
                this.dialogOpen(Localization.General.SuccessHeader, Localization.Message.SuccessMoveMessageToTrash);
                this.setState({ folderMessagesData }, () => {
                    this.refreshFolderMessages();
                })
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
    }

    readMessage(args) {
        const { message } = this.state;
        let intl = new Internationalization();
        let messageDate = intl.formatDate(new Date(message.date), { format: 'd-MM-y h:m' });

        return (
            <div className="message-content-wrapper">
                <div className="message-action-toolbar-wrapper">
                    <ToolbarComponent ref={ref => this.messageActionToolbarRef = ref} clicked={this.onMessageActionToolbarClick}>
                        <ItemsDirective>
                            <ItemDirective id="reply" prefixIcon='tb-icons c-icon-reply' text="Svar" tooltipText='Svar' align="Right" />
                            <ItemDirective id="replyall" prefixIcon='tb-icons c-icon-reply-all' text="Svar Alle" tooltipText='Svar Alle' align="Right" />
                            <ItemDirective id="forward" prefixIcon='tb-icons c-icon-forward' text="Videresend" tooltipText='Videresend' align="Right" />
                            <ItemDirective id="move" prefixIcon='tb-icons c-icon-archive-active' text="Flyt" tooltipText='Flyt' align="Right" />
                            <ItemDirective id="delete" prefixIcon='tb-icons c-icon-delete' text="Slet" tooltipText='Slet' align="Right" />
                        </ItemsDirective>
                    </ToolbarComponent>
                </div>
                <div className="message-details-wrapper">
                    <div>
                        <span className="message-detail--name">Fra: {message.From.Name}</span>
                        <span className="message-detail--email">{`<${message.From.Address}>`}</span>
                    </div>
                    <div className="message-detail--subject">{message.Subject}</div>
                    {message.To &&
                        <div className="message-recipients-wrapper">
                            {message.To.length > 0 && <span className="message-recipient-title">Til: </span>}
                            {message.To.length > 0 ?
                                message.To.map(recipient => {
                                    return (
                                        <div>
                                            {recipient.Name && <span className="message-detail--name">{recipient.Name}</span>}
                                            <span className="message-detail--email">{`<${recipient.Address}>`}</span>
                                        </div>
                                    );
                                })
                            : <div></div>}
                        </div>}
                    {message.Cc &&
                        <div className="message-recipients-wrapper">
                            {message.Cc.length > 0 && <span className="message-recipient-title">Cc: </span>}
                            {message.Cc.length > 0 ?
                                message.Cc.map(recipient => {
                                    return (
                                        <div>
                                            {recipient.Name && <span className="message-detail--name">{recipient.Name}</span>}
                                            <span className="message-detail--email">{`<${recipient.Address}>`}</span>
                                        </div>
                                    );
                                })
                            : <div></div>}
                        </div>}
                    <hr/>
                    <div>
                        <div className="attachment-label sub-text">Vedhæftede filer</div>
                        <ul className="c-ul attachment-list">
                            {
                                (message.FileAttachments && message.FileAttachments.length > 0) && message.FileAttachments.map((x, index) => {
                                    let icon = getFileIcon(x.Key);
                                    return (
                                        <li className="file-attachment">
                                            <div className={`file-icon ${icon}`}></div>
                                            <div className="fileinfo-wrapper">
                                                <span className="file-name">
                                                    <a href={AppSettings.API_URL + '/' + x.Key + `?access_token=${this.AccessToken}`} target="_blank">{x.Name}</a>
                                                </span>

                                            </div>
                                            {/* <a href={AppSettings.API_URL + '/' + x.Key} target="_blank">{x.Name}</a> */}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div className="message-detail--date">{messageDate}</div>
                    <div className="message-attachment-list">
                        
                    </div>
                    <div className="message-detail--message" dangerouslySetInnerHTML={{ __html: message.Body}}></div>
                </div>
            </div>
        )
    }

    nodeTemplate(data) {
        return (
            <React.Fragment>
                <div className={`message-folder-icon ${data.iconClass}${data.selected ? '-active' : '-inactive'}`}></div>
                <div className={`folder-wrapper`}>
                    <div className="folder-text-wrapper">
                        <span className="folder-text">{data.Key}</span>
                    </div>
                    <div className="countcontainer">
                        {
                            data.count > 0 &&
                            <span className="treeCount e-badge e-badge-primary">{data.count}</span>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    FolderPanel() {
        return (
            <div className='splitter-content'>
                <div className="module-header">
                    <h1>{this.pageName}</h1>
                </div>
                <ButtonComponent onClick={() => this.onCreateNewMessage()} isPrimary style={{width: '100%', marginBottom: 24}} iconCss="c-icon c-icon-add-white">Ny mail</ButtonComponent>
                <TreeViewComponent ref={ref => this.mailboxTree = ref}id='message-folder-tree' fields={this.mailboxFolderFields} nodeTemplate={this.nodeTemplate} 
                    nodeSelected={({ nodeData, action }) => {
                        if (action === 'select') {
                            this.getMessagesByFolder(nodeData.id);
                        }
                    }}
                />
            </div>
        )
    }

    onMessageChecked(messageProp, args) {
        let { selectedMessages, isNewMessage, message } = this.state;
        if (args.checked) {
            selectedMessages.push(messageProp.Id);
        }
        else {
            selectedMessages = selectedMessages.filter(x => x !== messageProp.Id);
        }

        if (selectedMessages.length <= 0) {
            isNewMessage = false;
            message = messageProp;
        }
        else {
            isNewMessage = false;
            message = this.defaultMessage();
        }

        this.setState({ selectedMessages, isNewMessage, message });
    }

    folderMessageItem(data) {
        const { isSelectionMode } = this.state;
        let intl = new Internationalization();
        let messageDate = intl.formatDate(new Date(data.CreationDate), { format: 'd-MM-y' });

        let senderName = data.From.Name ? data.From.Name : data.From.Address;

        return (
            <div className={`folder-message-wrapper e-list-wrapper e-list-multi-line e-list-avatar clickable ${!data.Read ? 'is-unread' : ''}`} 
            onClick={(e) => {
                e.stopPropagation();
                this.getMessage(data.Id, e)
            }} onDoubleClick={(e) => {
                e.stopPropagation();
                this.onEditDraft(data.Id);
            }}>
                <div className="folder-message-select">
                        <CheckBoxComponent cssClass="control-right" change={this.onMessageChecked.bind(this, data)} />
                    </div>
                <div className="folder-message-preview-wrapper" data-id={data.Id} >
                    <div className="folder-message-preview--name">{senderName}</div>
                    <div className="folder-message-preview--subject">{data.Subject}</div>
                    <div className="folder-message-preview--content">{data.Excerpts}</div>
                    <div className="folder-message-preview--date">{messageDate}</div>
                </div>
            </div>
        )
    }

    onMessageSearch(args) {
        let { folderId, searchString } = this.state;
        searchString = args.target.value;
        this.setState({ searchString }, () => {
            if (searchString && searchString.length >= 3) {
                if (this.searchTimer) {
                    clearTimeout(this.searchTimer);
                }
                
                this.searchTimer = setTimeout(async () => {
                    if (folderId === -1) return;
                    this.setState({ loadingFolderMessagePane: true });
                    const messagesResult = await MessageService.GetMessages({ folderId, searchString }, source.token);

                    if (!messagesResult.HasError) {
                        this.setState({ loadingFolderMessagePane: false, folderMessagesData: messagesResult.Messages }, () => {
                            if (this.folderMessagesRef) {
                                this.folderMessagesRef.dataSource = messagesResult.Messages;
                                this.folderMessagesRef.dataBind();
                            }
                        });
                    }
                }, 1000);
            }
            else if (searchString.length === 0) {
                if (this.searchTimer) {
                    clearTimeout(this.searchTimer);
                }
                
                this.searchTimer = setTimeout(async () => {
                    if (folderId === -1) return;
                    this.setState({ loadingFolderMessagePane: true });
                    const messagesResult = await MessageService.GetMessages({ folderId }, source.token);

                    if (!messagesResult.HasError) {
                        this.setState({ loadingFolderMessagePane: false, folderMessagesData: messagesResult.Messages }, () => {
                            if (this.folderMessagesRef) {
                                this.folderMessagesRef.dataSource = messagesResult.Messages;
                                this.folderMessagesRef.dataBind();
                            }
                        });
                    }
                }, 1000);
            }
        });
    }

    async onQuickFilterSelect(args) {
        const { folderId, searchString } = this.state;
        if (args.item.id === 'unread') {
            this.setState({ loadingFolderMessagePane: true });
            const messagesResult = await MessageService.GetMessages({ folderId, searchString, read: false }, source.token);
            this.setState({ loadingFolderMessagePane: false, filterText: "Ulæst" }, () => {
                if (this.folderMessagesRef) {
                    this.folderMessagesRef.dataSource = messagesResult.Messages;
                    this.folderMessagesRef.dataBind();
                    this.resize();
                }
            });
        }
        else if (args.item.id === 'read') {
            this.setState({ loadingFolderMessagePane: true });
            const messagesResult = await MessageService.GetMessages({ folderId, searchString, read: true }, source.token);
            this.setState({ loadingFolderMessagePane: false, filterText: "Læst" }, () => {
                if (this.folderMessagesRef) {
                    this.folderMessagesRef.dataSource = messagesResult.Messages;
                    this.folderMessagesRef.dataBind();
                    this.resize();
                }
            });
        }
        else if (args.item.id === 'sort-new') {
            this.setState({ loadingFolderMessagePane: true });
            const messagesResult = await MessageService.GetMessages({ folderId, searchString, newest: true }, source.token);
            this.setState({ loadingFolderMessagePane: false, filterText: "Sortere efter nyeste" }, () => {
                if (this.folderMessagesRef) {
                    this.folderMessagesRef.dataSource = messagesResult.Messages;
                    this.folderMessagesRef.dataBind();
                    this.resize();
                }
            });
        }
        else if (args.item.id === 'sort-old') {
            this.setState({ loadingFolderMessagePane: true });
            const messagesResult = await MessageService.GetMessages({ folderId, searchString, newest: false }, source.token);
            this.setState({ loadingFolderMessagePane: false, filterText: "Sortere efter ældste" }, () => {
                if (this.folderMessagesRef) {
                    this.folderMessagesRef.dataSource = messagesResult.Messages;
                    this.folderMessagesRef.dataBind();
                    this.resize();
                }
            });
        }
    }

    async onBulkOperationClicked(args) {
        const { selectedMessages, folderId, searchString } = this.state;
        let id = args.target.id;
        if (selectedMessages.length <= 0) {
            this.dialogOpen("Advarsel", Localization.Message.NoMessageSelected);
            return;
        }

        let requests = [];

        if (id === 'bulk-delete') {
            for (let index = 0; index < selectedMessages.length; index++) {
                switch (parseInt(folderId)) {
                    case 1:
                    case 2:
                    case 3:
                    case 5:
                        requests.push(MessageService.MoveToTrash(selectedMessages[index], source.token));
                        break;
                    case 4:
                        requests.push(MessageService.DeleteMessage(selectedMessages[index], source.token));
                        break;
                }
            }
        }
        else if (id === 'bulk-archive') {
            for (let index = 0; index < selectedMessages.length; index++) {
                switch (parseInt(folderId)) {
                    case 1:
                    case 2:
                    case 4:
                    case 5:
                        requests.push(MessageService.MoveToArchive(selectedMessages[index], source.token));
                        break;
                    case 3:
                        requests.push(MessageService.MoveToInbox(selectedMessages[index], source.token));
                        break;
                }
            }
        }

        if (requests.length > 0) {
            Axios.all(requests).then(Axios.spread((...response) => {
                const result = response[requests.length - 1];
                if (!result.HasError) {
                    this.setState({ loadingFolderMessagePane: true }, async () => {
                        const messagesResult = await MessageService.GetMessages({ folderId, searchString }, source.token);
    
                        if (id === 'bulk-delete') {
                            this.dialogOpen('Udført', Localization.General.SuccessDelete.replace("{context}", "Beskeder"));
                        }
                        else if (id === 'bulk-archive') {
                            this.dialogOpen('Udført', Localization.Message.SuccessArchivedMessage);
                        }
    
                        this.setState({ loadingFolderMessagePane: false, isSelectionMode: false, folderMessagesData: messagesResult.Messages }, () => {
                            if (this.folderMessagesRef) {
                                this.folderMessagesRef.dataSource = messagesResult.Messages;
                                this.folderMessagesRef.dataBind();
                                this.resize();
                            }
                        });
                    });
                }
                else {
                    this.dialogOpen('Advarsel', result.ErrorMessage);
                }
            }));
        }
    }

    async onBulkOperationSelect(args) {
        const { selectedMessages } = this.state;
        if (selectedMessages.length <= 0) {
            this.dialogOpen("Advarsel", Localization.Message.NoMessageSelected);
            return;
        }

        let requests = [];
        if (args.item.id === 'mark-read') {
            for (let index = 0; index < selectedMessages.length; index++) {
                const element = selectedMessages[index];
                requests.push(MessageService.MarkUnreadRead(selectedMessages[index], true, source.token));
            }
            if (requests.length > 0) {
                Axios.all(requests).then(Axios.spread((...response) => {
                    const result = response[requests.length - 1];
    
                    if (!result.HasError) {
                        this.refreshFolderMessages().then(() => { 
                            this.resize();
                        });
                    }
                }));
            }
        }
        else if (args.item.id === 'mark-unread') {
            for (let index = 0; index < selectedMessages.length; index++) {
                const element = selectedMessages[index];
                requests.push(MessageService.MarkUnreadRead(selectedMessages[index], false, source.token));
            }

            if (requests.length > 0) {
                Axios.all(requests).then(Axios.spread((...response) => {
                    const result = response[requests.length - 1];
    
                    if (!result.HasError) {
                        this.refreshFolderMessages().then(() => { 
                            this.resize();
                        });
                    }
                }));
            }
        }
    }

    updateUnreadMessageCounter() {
        setTimeout(() => {
            let currentFolder = document.querySelector('#message-folder-tree .e-list-item.e-active .folder-text-wrapper .folder-text');
            let treeCount = document.querySelector('#message-folder-tree .e-list-item.e-active span.treeCount');
            let folderMessages = document.querySelectorAll('.folder-message-wrapper.is-unread');
    
            if (currentFolder) {
                if (currentFolder.innerText === "Indbakke" || currentFolder.innerText === "Arkiv") {
                    if (treeCount) {
                        if (folderMessages.length > 0) {
                            treeCount.innerHTML = folderMessages.length;
                        }
                        else {
                            treeCount.remove();
                        }
                    }
                    else if (folderMessages.length > 0) {
                        let countCounterWrapper = document.querySelector('#message-folder-tree .e-list-item.e-active div.countcontainer');
                        let treeCount = document.createElement('span');
                        treeCount.classList.add('treeCount');
                        treeCount.classList.add('e-badge');
                        treeCount.classList.add('e-badge-primary');
                        treeCount.innerText = folderMessages.length;
                        countCounterWrapper.append(treeCount);
                    }
                }
            }
        }, 300);
    }

    FolderMessagesPanel() {
        const { folderMessagesData, mailboxFolders, loadingFolderMessagePane, searchString, isSelectionMode, filterText } = this.state;
        const selectedFolder = mailboxFolders.find(x => x.selected);
        
        return (
            <div className='splitter-content'>
                <div className="messages-folderMessages--options">
                    {
                        !isSelectionMode ?
                        <React.Fragment>
                            <div className="search-field">
                                <input type="text" placeholder="Søg" className="search-input" value={searchString} onChange={this.onMessageSearch}></input> 
                            </div>
                            <div className="refresh-messages">
                                <button className="c-button-icon c-icon-refresh" onClick={this.refreshFolderMessages}></button>
                            </div>
                        </React.Fragment>
                        :
                        <div className="message-bulkoperation-wrapper text-right">
                            <button id="bulk-delete" className="c-button-icon c-icon-delete" title="Slet" onClick={this.onBulkOperationClicked}></button>
                            <button id="bulk-archive" className="c-button-icon c-icon-archive-active" title="Arkiv" onClick={this.onBulkOperationClicked}></button>
                            <DropDownButtonComponent items={this.messageOperationItems} cssClass='e-caret-hide c-bulk-operation-ext text-center' select={this.onBulkOperationSelect}>
                                <div>...</div>
                            </DropDownButtonComponent>
                        </div>
                    }
                    <div className={`messages-view ${isSelectionMode ? 'is-selectionmode' : ''}`}>
                        <button className="c-button-icon c-icon-list-view" onClick={this.selectionMode}></button>
                    </div>
                </div>
                <div className="folder-message-title">
                    <div>{selectedFolder.Key}</div>
                    <div className="folder-filter text-right">
                        <DropDownButtonComponent items={this.messageQuickFilterItems} cssClass='e-caret-hide c-quick-filter' select={this.onQuickFilterSelect}>
                            <span>{filterText}</span>
                        </DropDownButtonComponent>
                    </div>
                </div>
                {
                    loadingFolderMessagePane ? 
                    <div className="sub-text text-center">{Localization.Message.LoadingMessages}</div>
                    :
                    <ListViewComponent id='folder-messages-list' ref={lvRef => this.folderMessagesRef = lvRef} className='splitter-list'
                        dataSource={folderMessagesData} cssClass={'e-list-template'} template={this.folderMessageItem}>
                        {
                            folderMessagesData.length <= 0 &&
                            <div className="sub-text text-center">{Localization.Message.NoMessagesFound}</div>
                        }
                        {/* <InjectListView services={[Virtualization]} /> */}
                    </ListViewComponent>
                }
            </div>
        )
    }

    onCreateNewMessage() {
        let { message } = this.state;
        message = this.defaultMessage();
        this.setState({ isNewMessage: true, message }, () => {
            this.adjustMessagePanelHeight();
            this.richTextEditorRef.value = '';
            this.SendSubjectRef.value = '';
            this.SendToRef.value = null;
            this.SendCcRef.value = null;
        });
    }

    adjustMessagePanelHeight() {
        setTimeout(() => {
            // const messageRte = document.getElementById('outlookRTE');
            const sendMessageTable = document.getElementsByClassName('message-sending-info-table')[0];
            const actionButtons = document.getElementsByClassName('create-message-action-wrapper')[0];
            if (this.richTextEditorRef && sendMessageTable && actionButtons) {
                // messageRte.style.height = `calc(100vh - ${sendMessageTable.offsetHeight + actionButtons.offsetHeight + 117}px)`;
                this.richTextEditorRef.height = `calc(100vh - ${sendMessageTable.offsetHeight + actionButtons.offsetHeight + 250}px)`;
                this.richTextEditorRef.focusIn();
            }
        }, 300);
    }

    async sendEmail() {
        source.cancel();
        source = CancelToken.source();

        let { message, parentAction } = this.state;
        let to = [], cc = [];

        if (this.SendToRef.value && this.SendToRef.value.length > 0) {
            to = this.SendToRef.value.map(t => { return { Name: "", Address: t } });
        }
        if (this.SendCcRef.value && this.SendCcRef.value.length > 0) {
            cc = this.SendCcRef.value.map(t => { return { Name: "", Address: t } });
        }
        
        const payload = {
            Id: null,
            ThreadId: null,
            To: to,
            Cc: cc,
            Subject: this.SendSubjectRef.value,
            Body: this.richTextEditorRef.value,
            FileAttachments: []
        };

        if (message.FileAttachments.length > 0) {
            payload.FileAttachments = payload.FileAttachments.concat(message.FileAttachments);
        }

        if (this.filesDetails.length > 0) {
            payload.FileAttachments = payload.FileAttachments.concat(this.filesDetails.map(d => {
                return {
                    Key: d.Key,
                    Name: d.name
                }
            }));
        }

        if (message) {
            if (parentAction === '') {
                payload.Id = message.Id;
            }
            
            payload.ThreadId = message.ThreadId;
        }

        const result = await MessageService.SendMessage(payload, source.token);

        if (!result.HasError) {
            message = {
                Id: null,
                To: '',
                Cc: '',
                From: {
                    Name: '',
                    Address: ''
                },
                Subject: '',
                Body: ''
            };
            this.dialogOpen(Localization.General.SuccessHeader, Localization.Message.SuccessSentMail);
            this.setState({ message, isNewMessage: false }, () => {
                if (this.redirectSource) {
                    this.props.history.replace('/beskeder');
                    localStorage.removeItem("MSG_VIDEO_ATTACHMENTS");
                }
            });
            this.refreshFolderMessages();
        }
        else {
            this.dialogOpen("Advarsel", result.ErrorMessage);
        }
    }

    onCancelWriteEmail() {
        let message = this.defaultMessage();
        this.setState({ isNewMessage: false, message });
    }

    onKeyUp(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            let { message } = this.state;
            message.To = message.To + '; ';
            this.setState({ message });
        }
    }

    onSendInfoChange(e) {
        let { message } = this.state;
        message[e.target.name] = e.target.value;
        this.setState({ message });
    }

    multiSelectItemTemplate(data) {
        return (
            <div className="contact-list-item">
                <span className="contact--name">{data.Name}</span>
                <span className="message-detail--email">{`<${data.Address}>`}</span>
            </div>
        )
    }

    onUploadCreated = (args) => {
        this.uploader.dropArea = this.uploadDropArea;
        this.uploader.dataBind();
    }

    onFileRemove = (args) => {
        args.postRawFile = false;
    }

    onUploadFailed = (args) => {
        const li = this.uploadDropArea.querySelector('[data-file-name="' + args.file.name + '"]');
        EventHandler.add(li.querySelector('.close-icon-container'), 'click', this.removeFiles, this);
        li.querySelector('.file-name ').classList.add('upload-fails');
        if (args.operation === 'upload') {
            detach(li.querySelector('.progress-bar-container'));
        }
    }

    onFileUpload = (args) => {
        const li = this.uploadDropArea.querySelector('[data-file-name="' + args.file.name + '"]');
        EventHandler.remove(li.querySelector('.close-icon-container'), 'click', this.removeFiles);
        const progressValue = Math.round((args.e.loaded / args.e.total) * 100);
        if (!isNaN(progressValue)) {
            li.getElementsByTagName('progress')[0].value = progressValue; // Updating the progress bar value
        }
    }

    removeFiles = (args) => {
        let index = this.filesList.indexOf(args.currentTarget.parentElement);
        if (index > -1) {
            if (this.filesDetails[index]) {
                const status = this.filesDetails[index].statusCode;
                if (status === '2') {
                    this.uploader.remove(this.filesDetails[index]);
                    this.filesList.splice(index, 1);
                } else {
                    detach(args.currentTarget.parentElement);
                }
            }
        }
    }

    onFileSelect = (args) => {
        if (!this.uploadDropArea.querySelector('.attachment-list')) {
            this.parentElement = createElement('div', { className: 'attachment-list' });
            this.parentElement.appendChild(createElement('ul', {className: 'attachment-list' }));
           this.uploadDropArea.appendChild(this.parentElement);
        }

        for (const i of args.filesData) {
            this.formSelectedData(i, this);  // create the LI element for each file Data
        }
        
        this.filesDetails = this.filesDetails.concat(args.filesData);
        this.uploader.upload(args.filesData, true);
        args.cancel = true;
    }

    formSelectedData = (selectedFiles, proxy) => {
        const liEle = createElement('li',  { className: 'file-attachment', attrs: {'data-file-name' : selectedFiles.name} });
        const divWrapper = document.createElement('div');
        divWrapper.classList.add('fileinfo-wrapper');
        divWrapper.appendChild(createElement('span', {className: 'file-name ', innerHTML: selectedFiles.name }));
        divWrapper.appendChild(createElement('span', {className: 'file-size ', innerHTML: this.uploader.bytesToSize(selectedFiles.size) }));
        liEle.appendChild(divWrapper);

        if (selectedFiles.statusCode === '1') {
            this.progressbarContainer = createElement('span', {className: 'progress-bar-container'});
            this.progressbarContainer.appendChild(createElement('progress', {className: 'progress', attrs: {value : '0', max : '100'}} ));
            liEle.appendChild(this.progressbarContainer);
        } else { 
            liEle.querySelector('.file-name').classList.add('upload-fails'); 
        }
        const closeIconContainer = createElement('div', {className: 'e-icons close-icon-container'});
        EventHandler.add(closeIconContainer, 'click', this.removeFiles, proxy);
        liEle.appendChild(closeIconContainer);
        document.querySelector('.attachment-list').appendChild(liEle);
        this.filesList.push(liEle);
    }

    onUploadSuccess = (args) => {
        let fileName = '';
        let response = null;
        
        const li =  this.uploadDropArea.querySelector('[data-file-name="' + args.file.rawFile.name + '"]');

        if (args.operation === 'upload') {
            if (args.e.target) {
                response = JSON.parse(args.e.target.response);
                fileName = response[0].Name;
            }
    
            let fileDetails = this.filesDetails.find(x => x.name === args.file.name);
            fileDetails.name = fileName;
            fileDetails.Key = response[0].Key;
            

            li.querySelector('.close-icon-container').classList.add('c-icon-delete');
            detach(li.getElementsByTagName('progress')[0]);
            const divIcon = document.createElement('div');

            if (isImage(args.file.rawFile.name)) {
                const img = document.createElement('img');
                img.src = `${AppSettings.API_URL}/file?Key=${fileDetails.Key}&access_token=${this.AccessToken}`;
                divIcon.classList.add('file-image');
                divIcon.appendChild(img);
            }
            else {
                let iconClass = getFileIcon(args.file.rawFile.name);
                divIcon.classList.add(iconClass);
                divIcon.classList.add('file-icon');
            }
            
            li.prepend(divIcon);
        } else {
            this.filesDetails = this.filesDetails.filter(x => x.Key !== args.file.Key);
            this.uploader.element.value = '';
            detach(li);
        }

        EventHandler.add(li.querySelector('.close-icon-container'), 'click', this.removeFiles, this);
    }

    onRemoveFileAttachment = (data, index) => {
        let { message } = this.state;
        message.FileAttachments = message.FileAttachments.filter(f => f.Key !== data.Key);
        this.setState({ message })
    }

    onCreate() {
        this.customBtn = document.getElementById('browse-file');
        this.customBtn.onclick = (e) => {
            let element = select('.e-file-select-wrap button');
            element.click();
        }
    }

    createMessage() {
        let { message } = this.state;

        return (
            <div className='splitter-content new-message-pane'>
                <div>
                    <table className="message-sending-info-table">
                        <tbody>
                            <tr>
                                <td><button className='e-btn e-flat e-outline' disabled={true}>Til:</button></td>
                                <td id='firstname-target' style={{ width: '85%'}}>
                                    {/* <input id="to" className="e-control e-input" ref={ref => this.SendToRef = ref} value={message.To} name="To" onChange={this.onSendInfoChange} onKeyUp={this.onKeyUp} /> */}
                                    <MultiSelectComponent ref={ref => this.SendToRef = ref} dataSource={this.contactList} fields={this.multiSelectFields} mode="Box" locale="da" allowCustomValue={true} itemTemplate={this.multiSelectItemTemplate} />
                                </td>
                            </tr>
                            <tr>
                                <td><button className='e-btn e-flat e-outline' disabled={true}>CC:</button></td>
                                <td id='lastname-target' style={{ width: '85%'}}>
                                    {/* <input id="cc" className="e-control e-input" ref={ref => this.SendCcRef = ref} value={message.Cc} name="Cc" onChange={this.onSendInfoChange} onKeyUp={this.onKeyUp}/> */}
                                    <MultiSelectComponent ref={ref => this.SendCcRef = ref} dataSource={this.contactList} fields={this.multiSelectFields} mode="Box" locale="da" allowCustomValue={true} itemTemplate={this.multiSelectItemTemplate} />
                                </td>
                            </tr>
                            <tr>
                                <td><div id='subject-text'>Emne:</div></td>
                                <td id='subject-target' style={{ width: '85%'}}>
                                    <TextBoxComponent id="subject" ref={ref => this.SendSubjectRef = ref} value={message.Subject}/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='create-message-wrapper'>
                    <div className="rte-action-wrapper">
                        <div ref={ref => this.uploadDropArea = ref} className="attachment-wrapper">
                            <div className="attachment-label">Vedhæftede filer</div>
                            <ul className="c-ul attachment-list">
                                {
                                    (message.FileAttachments && message.FileAttachments.length > 0) && message.FileAttachments.map((x, index) => {
                                        let icon;
                                        if (isImage(x.Key)) {

                                        }
                                        else {
                                            icon = getFileIcon(x.Key)
                                        }
                                        
                                        return (
                                            <li className="file-attachment">
                                                <div className={`file-icon ${icon ? icon : 'file-image'}`}>
                                                    {!icon && <img src={`${AppSettings.API_URL}/file?Key=${x.Key}&access_token=${this.AccessToken}`} />}
                                                </div>
                                                <div className="fileinfo-wrapper">
                                                    <span className="file-name">{x.Name}</span>
                                                    <span className="file-size"></span>
                                                </div>
                                                <div className="e-icons close-icon-container c-icon-delete" onClick={() => this.onRemoveFileAttachment(x, index)}></div>
                                                {/* <a href={AppSettings.API_URL + '/' + x.Key} target="_blank">{x.Name}</a> */}
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <UploaderComponent id="fileUpload" type="file" ref={ref => this.uploader = ref} asyncSettings={this.uploaderAsyncSettings}
                            success={this.onUploadSuccess} removing={this.onFileRemove} failure={this.onUploadFailed} progress={this.onFileUpload} created={this.onUploadCreated}
                            selected={this.onFileSelect} template={this.uploaderTemplate}>
                        </UploaderComponent>
                        <RichTextEditorComponent id="outlookRTE" ref={rteRef => this.richTextEditorRef = rteRef} value={message.Body} locale="da" format={this.format}
                            toolbarSettings={this.toolbarSettings} created={this.onCreate.bind(this)}>
                            <InjectRTE services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}/>
                        </RichTextEditorComponent>
                        <div className="create-message-action-wrapper text-right">
                            <button className='e-btn' id='discard' onClick={this.onCancelWriteEmail}>Kassér</button>
                            <button className='e-btn e-primary' id='send' onClick={this.sendEmail}>Send</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    dialogOpen(header, content) {
        this.notificationDialog.header = header;
        this.notificationDialog.content = content;
        this.notificationDialog.show();
    }

    render() {
        const { loading, isNewMessage, message, redirect } = this.state;

        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        return (
            <div id="user-messages" className="control-section message-layout-wrapper">
                <div className="message-splitter-wrapper">
                    <div className="splitter-pane folder-pane">
                        {this.FolderPanel()}
                    </div>
                    <div className="splitter-pane folder-messages-pane">
                        {this.FolderMessagesPanel()}
                    </div>
                    <div className="splitter-pane message-pane">
                        { isNewMessage ? this.createMessage() : '' }
                        { !isNewMessage && message.From.Address !== '' ? this.readMessage(message) : '' }
                    </div>
                </div>
                <DialogComponent isModal={true} buttons={this.buttons} width='auto' ref={dialog => this.notificationDialog = dialog} target='body' showCloseIcon={true}
                    cssClass="dialog-notification" animationSettings={this.animationSettings} visible={false} allowDragging={true} enableResize={true} />
            </div>
        );
    }
}

export default Messages;
