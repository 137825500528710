import React, { Component } from 'react';
import { TreeGridComponent, ColumnsDirective as TreeGridColumnsDirective, ColumnDirective as TreeGridColumnDirective, Filter as TreeGridFilter, 
    Sort as TreeGridSort, Inject as TreeGridInject, Edit as TreeGridEdit, Page as TreeGridPage, Toolbar as TreeGridToolbar } from '@syncfusion/ej2-react-treegrid';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import Axios from 'axios';
import { Redirect } from 'react-router-dom';
import NavigationPrompt from '../../components/NavigationPrompt';

import BaseComponent from '../../components/BaseComponent';
import Card from '../../components/Card';
import AppContext from '../../context/AppContext';
// import Breadcrumb from '../../components/Breadcrumb';

import OrderService from '../../services/Order/OrderService';

import '../../styles/purchase.scss';
import Loading from '../../components/Loading';
import Localization from '../../utilities/Localization';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class PurchaseSettings extends BaseComponent {
    moduleName = "Indkøb";
    pageName = "Indkøbs Indstillinger";

    constructor() {
        super();

        this.state = {
            loading: true,
            // breadcrumbs: ['Indkøb', 'Indkøbs Indstillinger'],
            redirect: {
                to: '',
                isRedirect: false
            },
            hasUnsavedChanges: false
        }

        this.settingsData = [];

        this.selectionSettings = {
            type: 'Single'
        };
        
        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.buttons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        /* End Dialog options  */

        this.dialogOpen = this.dialogOpen.bind(this);
        this.actionBegin = this.actionBegin.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
        this.init = this.init.bind(this);
    }

    async componentDidMount() {
        super.componentDidMount();
        source.cancel();
        source = CancelToken.source();

        if (!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }
        else if (!super.hasMenuItem('Indkøbsindstillinger')) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }

        this.init();

        let result = await OrderService.GetOrderSettings(source.token);
        this.settingsData = result.map(res => {  
            res.Machines = res.Machines.map(m => {
                return {
                    ...m,
                    Name: m.CustomName
                }
            });

            return {
                ...res
            }
        });

        this.setState({ loading: false });
    }

    componentWillUnmount() {
        source.cancel();
    }

    init() {
        this.toolbarOptions = [];
        this.defaultEditSettings = {};

        if (super.hasMenuItemFunction("Indkøbsindstillinger", "Redigere")) {
            this.toolbarOptions.push('Edit');
            this.toolbarOptions.push('Update');
            this.toolbarOptions.push('Cancel');
            this.defaultEditSettings = {
                allowEditing: true,
                mode: 'row'
            };
        }
    }

    async actionBegin(args) {
        // if (args.requestType === 'beginEdit') {
        //     this.setState({ hasUnsavedChanges: true });
        // }
        if (args.requestType === 'save') {
            let hasError = false;
            let errorList = [];

            if (!args.data.OrderProcessingDays || args.data.OrderProcessingDays <= 0) {
                hasError = true;
                errorList.push('Antal dage til ordrebehandling er påkrævet.');
            }

            if (!args.data.DaysAfterShipLimit || args.data.DaysAfterShipLimit <= 0) {
                hasError = true;
                errorList.push('Fragtgrænse er påkrævet.');
            }

            if (hasError) {
                args.cancel = true;
                this.dialogOpen("Advarsel", errorList.join('<br>'));
                return;
            }

            const payload = {
                OrderProcessingDays: args.data.OrderProcessingDays,
                ApprovedAutomaticOrders: args.data.ApprovedAutomaticOrders,
                DaysAfterShipLimit: args.data.DaysAfterShipLimit
            };

            if (args.data.DeptId) {
                payload.DeptId = args.data.DeptId;
                payload.MachineId = null;
            }
            else if (args.data.MachineId) {
                payload.MachineId = args.data.MachineId;
                payload.DeptId = args.data.parentItem.DeptId;
            }
            
            const result = await OrderService.PostOrderSettings(payload, source.token);
            if (!result.HasError) {
                this.setState({ hasUnsavedChanges: false }, async () => {
                    await this.refreshGrid();
                });
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Indkøbsindstillinger"));
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
        // else if (args.requestType === 'cancel') {
        //     this.setState({ hasUnsavedChanges: false });
        // }
    }

    async refreshGrid() {
        let result = await OrderService.GetOrderSettings(source.token);
        result = result.map(res => {  
            res.Machines = res.Machines.map(m => {
                return {
                    ...m,
                    Name: m.CustomName
                }
            });

            return {
                ...res
            }
        });
        this.purchaseSettingsRef.dataSource = result;
        this.purchaseSettingsRef.dataBind();
    }
    
    dialogOpen(header, content) {
        this.notificationDialog.header = header;
        this.notificationDialog.content = content;
        this.notificationDialog.show();
    }

    render() {
        const { loading, breadcrumbs, redirect, hasUnsavedChanges } = this.state;

        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        return (
            <div className="content-pane-wrapper purchase-settings-wrapper">
                {/* <NavigationPrompt when={hasUnsavedChanges} 
                    navigate={path => this.props.history.push(path)}
                    initiateSave={() => this.purchaseSettingsRef.endEdit()}
                    shouldBlockNavigation={location => {
                        if (hasUnsavedChanges) {
                            return true;
                        }
                        return false;
                    }}
                /> */}
                {/* <div><Breadcrumb breadcrumbs={breadcrumbs} /></div> */}
                <div className="module-header">
                    <h1>{this.pageName}</h1>
                </div>
                <Card headerText="Indkøbs indstillinger pr Automat" className="purchase-settings-card-wrapper">
                    <TreeGridComponent ref={ref => this.purchaseSettingsRef = ref } dataSource={this.settingsData} childMapping="Machines" allowTextWrap='true' gridLines='Both'
                        allowFiltering={true} filterSettings={{ type: 'Menu', hierarchyMode: 'Parent' }} toolbar={this.toolbarOptions} locale="da" editSettings={this.defaultEditSettings}
                        actionBegin={this.actionBegin}>
                        <TreeGridColumnsDirective>
                            <TreeGridColumnDirective field='Name' headerText='Afdeling/Automat' width='100' textAlign='left' allowEditing={false} allowFiltering={false} />
                            <TreeGridColumnDirective field='OrderProcessingDays' headerText='Antal dage til Behandling af ordre' width='90' textAlign='Center' allowFiltering={false} />
                            <TreeGridColumnDirective field='ApprovedAutomaticOrders' headerText='Godkendelse af Automatiske ordre' editType='booleanedit' displayAsCheckBox={true} width='90' textAlign='Center' allowFiltering={false} />
                            <TreeGridColumnDirective field='DaysAfterShipLimit' headerText='Hvis fragtgrænse ikke er nået send ordre efter ”X” dage' width='110' textAlign='Center' allowFiltering={false} />
                        </TreeGridColumnsDirective>
                        <TreeGridInject services={[TreeGridFilter, TreeGridSort, TreeGridPage, TreeGridEdit, TreeGridToolbar]}/>
                    </TreeGridComponent>
                </Card>
                <DialogComponent id="purchase-settings-dialog" isModal={true} buttons={this.buttons} width='auto' ref={dialog => this.notificationDialog = dialog} target='body' 
                    visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true}/>
            </div>
        )
    }
}

PurchaseSettings.contextType = AppContext;

export default PurchaseSettings;
