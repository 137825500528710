import React from "react";
import { ButtonComponent, CheckBoxComponent } from '@syncfusion/ej2-react-buttons';

import OilmatLogo from '../../../assets/logo_oilmat_ilx_system.png'

export default function OilmatAcceptance({ data, onNext, onPrevious, onChange, t }) {
    const onChecked = (args) => {
        onChange('acceptedTermsAndConditions', args.checked);
    }

    return (
        <div className="container h-100vh">
            <div className="acceptance-container">
                <div className="oilmat-banner m-bottom-20">
                    <img src={OilmatLogo} alt="oilmat-logo" />
                </div>
                <p className="text-center" dangerouslySetInnerHTML={{__html: t('AcceptanceText')}}></p>
                <div className="text-center">
                    <div className="container m-bottom-20">
                        <CheckBoxComponent
                            name="acceptedTermsAndConditions"
                            cssClass="control-left m-right-15" 
                            checked={data.acceptedTermsAndConditions}
                            change={onChecked}
                        />
                        <span>{t('ConfirmTermsAndCondition')}</span>
                    </div>
                
                    <ButtonComponent 
                        onClick={onNext}
                        disabled={!data.acceptedTermsAndConditions}
                        isPrimary
                        style={{width: '100%', maxWidth: '400px', marginBottom: 20 }}>
                            {t('LabelAccept')}
                    </ButtonComponent>
                    <ButtonComponent 
                        onClick={onPrevious}
                        style={{width: '100%', maxWidth: '400px' }}>
                            {t('Back')}
                    </ButtonComponent>
                </div>
            </div>
        </div>
    );
}