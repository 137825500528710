import React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Page, Sort, Toolbar, Selection, Search, Reorder, Resize, Edit, QueryCellInfoEventArgs } from '@syncfusion/ej2-react-grids';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { toast } from 'react-toastify';

import BaseComponent from '../../components/BaseComponent';
import Loading from '../../components/Loading';
import NavigationPrompt from '../../components/NavigationPrompt';
import Localization from '../../utilities/Localization';
import Card from '../../components/Card';
import Image from '../../components/Image';

import SupplierService from '../../services/Supplier/SupplierService';
import MessageHelper from '../../utilities/MessageHelper';
import SettingsService from '../../services/SettingsService';
import SessionService from '../../services/SessionService';
import productService from '../../services/Product/ProductService';
import ProductGroupService from '../../services/Product/ProductGroupService';
import { buildGridSettings, toDanishNumberFormat, numberTo2DecimalPlace } from '../../utilities/Helper';

import placeHolderImg from '../../assets/placeholders/img_placeholder_108x80.jpg';
import '../../styles/tool.scss';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class ProductTools extends BaseComponent {
    moduleName = "Værktøjer";
    pageName = "Masse Redigering";
    hasDraggedColumn = false;
    gridSettingsKey = "ProductToolsList";
    gridSettings = '';

    constructor() {
        super();

        this.state = {
            loading: true,
            redirect: {
                to: '',
                isRedirect: false
            },
            hasUnsavedChanges: false,
            productSelected: {},
            changeLog: [],
            changeLogReport: [],
            otherSettingsLog: [],
            tempOtherSettingsChanges: [],
            priceChangeLog: [],
            supplierChangeLog: [],
            tempDeletedSuppliers: [],
            currentTabSelected: 0,
            selectedProducts: []
        }

        this.data = [];

        this.pageSettings = {
            pageSize: 25,
            pageSizes: ['Alle', '5', '10', '20', '50', '100']
        };

        this.selectionSettings = {
            persistSelection: true,
            checkboxOnly: true
        };

        this.editSettings = {
            allowEditing: false,
            mode: 'Batch',
            showConfirmDialog: false
        };

        this.supplierEditSettings = {
            allowEditing: true,
            allowDeleting: true,
            showDeleteConfirmDialog: true
        }

        this.numericParams = { params: { showSpinButton: false, min: 0, step: 0, cssClass: 'text-right' }};

        this.filterSettings = {
            type: 'Menu'
        };

        this.supplierToolbarOptions = [
            { text: 'Ny', tooltipText: 'Ny', id: 'add', prefixIcon: 'e-add' }, 
            'Delete', 'Edit', 'Update','Cancel'
        ];

        this.primaryProductGroupFields = {
            dataSource: [], id: 'Id', text: 'Name', child: 'SubGroups' 
        }

        this.init = this.init.bind(this);
        this.onToolbarClick = this.onToolbarClick.bind(this);
        this.resetChangesTemplate = this.resetChangesTemplate.bind(this);
        this.saveChangesTemplate = this.saveChangesTemplate.bind(this);
        this.processProductChanges = this.processProductChanges.bind(this);
        this.saveProductOtherSettings = this.saveProductOtherSettings.bind(this);
        this.onSaveChanges = this.onSaveChanges.bind(this);
        this.onResetChanges = this.onResetChanges.bind(this);

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.notificationButtons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        this.otherSettingButtons = [
            {
                click: () => {
                    this.notificationDialog.header = "Advarsel";
                    this.notificationDialog.content = MessageHelper.MB1.message;

                    let messageButton = MessageHelper.MB1.buttons;

                    messageButton[0].click = () => {
                        this.notificationDialog.header = "";
                        this.notificationDialog.content = "";
                        this.notificationDialog.button = this.notificationButtons;
                        this.notificationDialog.hide();

                        this.otherSettingsDialog.hide();
                        this.setState({ productSelected: {} });
                    }

                    messageButton[1].click = () => {
                        this.notificationDialog.header = "";
                        this.notificationDialog.content = "";
                        this.notificationDialog.button = this.notificationButtons;
                        this.notificationDialog.hide();
                        this.saveProductOtherSettings();
                        this.otherSettingsDialog.hide();
                    }

                    this.notificationDialog.buttons = messageButton;

                    this.notificationDialog.show();
                },
                buttonModel: {
                    content: 'Tilbage'
                }
            },
            {
                click: () => {
                    this.saveProductOtherSettings();
                },
                buttonModel: {
                    isPrimary: true,
                    content: 'OK'
                }
            }
        ];

        this.changeLogButtons = [
            {
                click: () => {
                    this.changeLogDialog.hide();
                },
                buttonModel: {
                    content: 'Tilbage'
                }
            },
            {
                click: async () => {
                    await this.processProductChanges();
                },
                buttonModel: {
                    isPrimary: true,
                    content: 'Opdater'
                }
            }
        ]   
        /* End Dialog options  */
    }

    async componentDidMount() {
        super.componentDidMount();
        source.cancel();
        source = CancelToken.source();

        if (!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }
        else if (!this.hasMenuItemTabAccess("Produkter", "Masse redigering")) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }

        this.init();

        const sessionInfo = await SessionService.GetSessionInfo();
        this.CustomerId = sessionInfo.Id;

        const products = await productService.GetProducts(sessionInfo.Id, { active: true }, source.token);
        this.data = products.map(p => {
            p = this.computeAllPrice(p);
            p.ProductGroupId = p.ProductGroup ? p.ProductGroup.Id : null;
            return p;
        });
        
        const productGroupsResult = await ProductGroupService.GetProductGroupsLite(sessionInfo.Id, source.token);
        this.primaryProductGroupFields.dataSource = productGroupsResult.map(x => {
            return {
                ...x,
                expanded: x.SubGroups.length > 0 ? true : false
            }
        });
        this.productGroups = productGroupsResult;

        const suppliers = await SupplierService.GetSuppliers(sessionInfo.Id, source.token);
        this.suppliersData = suppliers;
        this.filteredSuppliersData = suppliers;

        const gridSettings = await SettingsService.GetGridSettings(this.gridSettingsKey, source.token);
        if (!gridSettings.HasError) {
            this.gridSettings = gridSettings.Settings;
        }

        this.setState({ loading: false }, () => {
            window.addEventListener('wheel', this.onWheelScroll);
        });
    }

    init() {
        this.toolbarOptions = [
            { text: 'Rediger tabel', tooltipText: 'Rediger tabel', id: 'edit', prefixIcon: 'e-edit' },
            // { text: 'Nulstil udvalg', tooltipText: 'Nulstil udvalg', id: 'reset_filter', prefixIcon: 'e-refresh' },
            //'Update', //'Cancel',
            { text: 'Øvrige indstillinger', tooltipText: 'Øvrige indstillinger', id: 'other_settings', prefixIcon: 'e-more-details', disabled: true },
            { id: 'reset', template: this.resetChangesTemplate, align: "Right" },
            { id: 'save_changes', template: this.saveChangesTemplate, align: "Right" },
            { text: Localization.General.GridResetFilter, tooltipText: Localization.General.GridResetFilter, id: 'reset_filter', prefixIcon: 'e-cancel' }
        ];

        this.factorEditParams = { params: {
            change: (e) => {
                var rowDetils = this.productGridRef.getRowInfo(e.event.target);
                this.productGridRef.updateCell(rowDetils.rowIndex, "ProfitPercentage", null);
                this.productGridRef.updateCell(rowDetils.rowIndex, "Profit", null);
                this.productGridRef.updateCell(rowDetils.rowIndex, "SalesPrice", null);
            },
            showSpinButton: false, min: 0, step: 0, cssClass: 'text-right'
        }};

        this.profitPercentageEditParams = { params: {
            change: (e) => {
                var rowDetils = this.productGridRef.getRowInfo(e.event.target);
                this.productGridRef.updateCell(rowDetils.rowIndex, "Factor", null); 
                this.productGridRef.updateCell(rowDetils.rowIndex, "Profit", null); 
                this.productGridRef.updateCell(rowDetils.rowIndex, "SalesPrice", null); 
            },
            showSpinButton: false, min: 0, step: 0, cssClass: 'text-right'
        }};

        this.profitEditParams = { params: {
            change: (e) => {
                var rowDetils = this.productGridRef.getRowInfo(e.event.target);
                this.productGridRef.updateCell(rowDetils.rowIndex, "Factor", null); 
                this.productGridRef.updateCell(rowDetils.rowIndex, "ProfitPercentage", null); 
                this.productGridRef.updateCell(rowDetils.rowIndex, "SalesPrice", null); 
            },
            showSpinButton: false, min: 0, step: 0, cssClass: 'text-right'
        }};

        this.salesPriceEditParams = { params: {
            change: (e) => {
                var rowDetils = this.productGridRef.getRowInfo(e.event.target);
                this.productGridRef.updateCell(rowDetils.rowIndex, "Factor", null); 
                this.productGridRef.updateCell(rowDetils.rowIndex, "ProfitPercentage", null); 
                this.productGridRef.updateCell(rowDetils.rowIndex, "Profit", null);  
            },
            showSpinButton: false, min: 0, step: 0, cssClass: 'text-right'
        }};
    }

    onWheelScroll = () => {
        let grid = document.getElementById('product-tools-grid');
        let toolbar = document.querySelector('#product-tools-grid.e-grid .e-toolbar');
        let tableHeader = document.querySelector('#product-tools-grid .e-gridheader');

        if (toolbar && tableHeader) {
            let boundingRect = tableHeader.getBoundingClientRect();
            
            if (boundingRect.top < 90) {
                toolbar.classList.add('c-hover-grid-toolbar');
                toolbar.style.width = (grid.clientWidth - 5) + 'px';
            }
            else {
                toolbar.classList.remove('c-hover-grid-toolbar');
                toolbar.style.width = 'auto';
            }
        }
    }

    //#region Grid Events
    onColumnDragStart(args) {
        this.hasDraggedColumn = true;
    }

    onCreated() {
        if (this.gridSettings) {
            let settings = null;
            try {
                settings = JSON.parse(this.gridSettings);
            } catch {
                settings = this.gridSettings;
            }
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(column => {
                        if (column.FromIndex !== column.ToIndex) {
                            var tempIndex = this.productGridRef.getColumnIndexByField(column.Field);
                            if (tempIndex !== column.ToIndex){
                                this.productGridRef.reorderColumnByIndex(column.FromIndex, column.ToIndex);
                            }
                        }
    
                        if (column.Width) {
                            this.productGridRef.columns[column.ToIndex].width = column.Width;
                        }
                    });
                }

                if (settings.colName && settings.direction) {
                    this.productGridRef.sortColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.productGridRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.productGridRef.refreshColumns();

                // Prevent resizing of last column
                let lastColumnIndex = this.productGridRef.columns.length - 1;    
                this.productGridRef.columns[lastColumnIndex].allowResizing = false;
                this.productGridRef.refreshColumns();
                
                if (settings.pageSize) {
                    if (settings.pageSize === "Alle" || !this.productGridRef.pageSettings.pageSizes.some(x => x === settings.pageSize.toString())) {
                        this.productGridRef.pageSettings.pageSize = "Alle"; //this.productGridRef.pageSettings.totalRecordsCount;
                        document.querySelector("#product-tools-grid .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.productGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
            }
        }
    }

    async onActionBegin(args) {
        if (args.requestType === "paging") {
            if (isNaN(this.productGridRef.pageSettings.pageSize)) {
                this.productGridRef.pagerModule.setPageSize(this.productGridRef.pageSettings.totalRecordsCount);
            }
        }
    }

    async onActionComplete(args) {
        if (args.requestType === 'paging') {
            if (this.productGridRef.pageSettings.pageSize === this.productGridRef.pageSettings.totalRecordsCount) {
                document.querySelector("#product-tools-grid .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, this.productGridRef.pageSettings.pageSize, this.productGridRef.pageSettings.totalRecordsCount, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };
            const result = await SettingsService.SaveGridSettings(payload, source.token);
            this.onWheelScroll();
        }
        else if (args.requestType === 'filtering') {
            if (this.gridSettings) {
                let settings = null;
                try {
                    settings = JSON.parse(this.gridSettings);
                } catch {
                    settings = this.gridSettings;
                }
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let pageSize = 0;
            if (this.productGridRef.getFilteredRecords().length > 0) {
                pageSize = this.productGridRef.getFilteredRecords().length;

                if (this.productGridRef.getFilteredRecords().length > 0 && this.productGridRef.getFilteredRecords().length === this.productGridRef.pageSettings.totalRecordsCount) {
                    document.querySelector("#product-tools-grid .e-pagerdropdown input").value = "Alle";
                }
            }
            else {
                pageSize = this.productGridRef.pageSettings.pageSize === this.productGridRef.pageSettings.totalRecordsCount ? "Alle" : this.productGridRef.pageSettings.pageSize;

                if (!this.productGridRef.pageSettings.pageSizes.some(x => x === pageSize)) {
                    pageSize = "Alle";
                }
                
                if (pageSize === "Alle") {
                    document.querySelector("#product-tools-grid .e-pagerdropdown input").value = "Alle";
                }
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, pageSize, this.productGridRef.pageSettings.totalRecordsCount, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.gridSettings) {
                let settings = null;
                try {
                    settings = JSON.parse(this.gridSettings);
                } catch {
                    settings = this.gridSettings;
                }
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.productGridRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, this.hasDraggedColumn, columns);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                let lastColumnIndex = this.productGridRef.columns.length - 1;
                for (let index = 0; index < this.productGridRef.columns.length; index++) {
                    let column = this.productGridRef.columns[index];
                    if (index === lastColumnIndex) { column.allowResizing = false }
                    else { column.allowResizing = true }
                }
                this.productGridRef.refreshColumns();
            }

            this.hasDraggedColumn = false;
        }
    }

    async onResizeStop(args) {
        args.requestType = "resizingGrid";
        let columns = this.productGridRef.getColumns();
        let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, columns);
        this.gridSettings = tempGridSettings;
        const payload = {
            Key: this.gridSettingsKey,
            Settings: this.gridSettings
        };

        const result = await SettingsService.SaveGridSettings(payload, source.token);

        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);

            // Adjust size of last column to fill spaces
            let lastColumnIndex = this.productGridRef.columns.length - 1;
            let totalColumnWidth = this.productGridRef.columns.reduce((acc, curVal) => acc + parseInt(curVal.width), 0);
            let lastColumnWidth = this.productGridRef.element.clientWidth - totalColumnWidth;
            this.productGridRef.columns[lastColumnIndex].width = this.productGridRef.columns[lastColumnIndex].width + lastColumnWidth;
            this.productGridRef.refreshColumns();
        }
    }
    
    async onToolbarClick(args) {
        if (args.item.id === 'edit') {
            this.productGridRef.editSettings.allowEditing = true;
            let grid = document.getElementById('product-tools-grid');
            grid.classList.add("on-edit");
            this.productGridRef.toolbarModule.enableItems(['edit'], false);
            this.productGridRef.toolbarModule.enableItems(['other_settings'], true);
            toast("Tabel er nu åben for redigering", { position: 'top-center', hideProgressBar: true });
        }
        else if (args.item.id === 'other_settings') {
            let selectedRecord = this.productGridRef.getSelectedRecords();
            let selectedIndex = this.productGridRef.getSelectedRowIndexes();

            if (selectedRecord.length <= 0) {
                this.dialogOpen("Advarsel", Localization.General.NoRowSelected);
            }
            // else if (selectedRecord.length > 1) {
            //     this.dialogOpen("Advarsel", Localization.General.SingleRowEditOnly);
            // }
            else {
                let { productSelected, tempOtherSettingsChanges, selectedProducts } = this.state;

                selectedProducts = this.productGridRef.getSelectedRecords().map(product => {
                    if (tempOtherSettingsChanges.some(x => x.Id === product.Id)) {
                        return tempOtherSettingsChanges.find(x => x.Id === product.Id);
                    } else {
                        return product;
                    }
                });

                if (tempOtherSettingsChanges.some(x => x.Id === selectedRecord[0].Id)) {
                    productSelected = tempOtherSettingsChanges.find(x => x.Id === selectedRecord[0].Id);
                }
                else {
                    productSelected = selectedRecord[0];
                }
                productSelected.RowIndex = selectedIndex[0];
                productSelected.ProductGroupId = productSelected.ProductGroupId;
                
                this.otherSettingsDialog.show();
                if (this.primaryProductGroupRef) {
                    this.primaryProductGroupRef.checkedNodes = [productSelected.ProductGroupId.toString()];
                    this.primaryProductGroupRef.refresh();
                }
                else if (this.productSupplierGridRef) {
                    this.productSupplierData = productSelected.ProductSuppliers;
                    this.productSupplierGridRef.dataSource = productSelected.ProductSuppliers;
                    this.productSupplierGridRef.refresh();
                }

                this.setState({ productSelected, currentTabSelected: 0, selectedProducts });
            }
        } else if (args.item.id === 'reset_filter') {
            if (this.gridSettings) {
                let settings = null;
                try {
                    settings = JSON.parse(this.gridSettings);
                } catch {
                    settings = this.gridSettings;
                }
                settings.filters = [];
                settings.pageSize = "Alle";
                this.gridSettings = settings;
                const payload = {
                    Key: this.gridSettingsKey,
                    Settings: JSON.stringify(this.gridSettings)
                };
    
                const result = await SettingsService.SaveGridSettings(payload, source.token);
    
                if (!result.HasError) {
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                }
            }

            this.productGridRef.clearFiltering();
            setTimeout(() => {
                document.querySelector("#product-tools-grid .e-pagerdropdown input").value = "Alle";
            }, 600);
        }
    }

    resetChangesTemplate() {
        return <button className="e-btn e-control" style={{width: 184}} onClick={this.onResetChanges}>Nulstil Ændringer</button>

    }

    saveChangesTemplate() {
        return <button className="e-btn e-primary e-control" style={{width: 184}} onClick={this.onSaveChanges}>Opdater</button>
    }

    async onSaveChanges() {
        this.productGridRef.endEdit();
        
        let { changeLog, otherSettingsLog } = this.state;
        let selectedRows = this.productGridRef.getSelectedRecords();

        if (selectedRows.length > 0) {
            if (changeLog.length <= 0) {
                this.dialogOpen("Advarsel", "Der blev ikke foretaget nogen ændringer");
                return;
                // changeLog = selectedRows.map(c => { return {
                //     ProductNo: c.ProductNo, Description1: c.Description1, currentData: c
                // }})
    
                // this.setState({ changeLog });
            }

            if (changeLog.length > 0 || otherSettingsLog.length > 0) {
                this.changeLogDialog.show();
            }
            else {
                this.dialogOpen("Advarsel", Localization.General.NoChangesMade);
            }
        }
        else {
            this.dialogOpen("Advarsel", "Der blev ikke foretaget nogen ændringer");
        }
    }

    async processProductChanges() {
        let { changeLog, tempOtherSettingsChanges, priceChangeLog, supplierChangeLog } = this.state;
        let selectedRecords = this.productGridRef.getSelectedRecords();
        let payload = [];

        for (let index = 0; index < selectedRecords.length; index++) {
            const item = selectedRecords[index];

            let priceChange = priceChangeLog.find(x => x.Id === item.Id);
            if (priceChange) {
                item.Factor = priceChange.Factor;
                item.Profit = priceChange.Profit;
                item.ProfitPercentage = priceChange.ProfitPercentage;
                item.SalesPrice = priceChange.SalesPrice;
            }

            let otherSettings = tempOtherSettingsChanges.find(x => x.Id === item.Id);
            if (otherSettings) {
                item.ProductGroupId = parseInt(otherSettings.ProductGroupId);
                item.ActiveCaseNumber = otherSettings.ActiveCaseNumber;
                item.Deactivated = otherSettings.Deactivated;
            }

            let supplier = supplierChangeLog.find(s => s.Id === item.Id);
            if (supplier) {
                item.ProductSuppliers = supplier.ProductSuppliers;
            }
            else {
                item.ProductSuppliers = null;
            }

            payload.push({
                ...item,
                ChangeOrder: index + 1
            });
        }
        
        const result = await productService.ToolBulkSave(payload, source.token);
        
        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Produkter"));

            this.changeLogDialog.hide();
            this.onResetChanges();
        }
        else {
            this.dialogOpen("Advarsel", result.ErrorMessage);
        }
    }

    async onResetChanges() {
        this.productGridRef.closeEdit();
        this.productGridRef.clearRowSelection();
        this.productGridRef.editSettings.allowEditing = false;
        this.productGridRef.toolbarModule.enableItems(['edit'], true);
        this.productGridRef.toolbarModule.enableItems(['other_settings'], false);
        
        let grid = document.getElementById('product-tools-grid');
        grid.classList.remove("on-edit");

        let products = await this.getProducts();
        this.productGridRef.dataSource = products;
        this.productGridRef.refresh();

        this.setState({ changeLog: [], hasUnsavedChanges: false, changeLogReport: [], otherSettingsLog: [], tempOtherSettingsChanges: [] });
    }

    async getProducts() {
        let products = await productService.GetProducts(this.CustomerId, { active: true }, source.token);
        this.data = products.map(p => {
            p = this.computeAllPrice(p);
            p.ProductGroupId = p.ProductGroup ? p.ProductGroup.Id : null;
            return p;
        });

        return products;
    }

    saveProductOtherSettings() {
        let { productSelected, tempOtherSettingsChanges, supplierChangeLog, tempDeletedSuppliers, selectedProducts } = this.state;

        selectedProducts.forEach(product => {
            product.ProductGroupId = parseInt(productSelected.ProductGroupId);
            product.ActiveCaseNumber = productSelected.ActiveCaseNumber;
            product.Deactivated = productSelected.Deactivated;

            if (tempOtherSettingsChanges.some(x => x.Id === product.Id)) {
                let prod = tempOtherSettingsChanges.find(x => x.Id === product.Id);
                prod = product;
            }
            else {
                tempOtherSettingsChanges.push(product);
            }
        });

        // if (tempOtherSettingsChanges.some(x => x.Id === productSelected.Id)) {
        //     let prod = tempOtherSettingsChanges.find(x => x.Id === productSelected.Id);
        //     prod = productSelected;
        // }
        // else {
        //     tempOtherSettingsChanges.push(productSelected);
        // }
        
        if (supplierChangeLog.some(x => x.Id === productSelected.Id)) {
            let prd = supplierChangeLog.find(x => x.Id === productSelected.Id)
            prd.ProductSuppliers = productSelected.ProductSuppliers.map(s => {
                return {
                    Id: s.TempId ? 0 : s.Id,
                    SupplierId: s.Supplier.Id,
                    Priority: s.Priority,
                    IsDelete: false
                }
            });

            if (tempDeletedSuppliers.length > 0) {
                prd.ProductSuppliers = prd.ProductSuppliers.concat(tempDeletedSuppliers.map(r => {
                    return {
                        Id: r,
                        IsDelete: true
                    }
                }));
            }
        }
        else {
            let suppliers = productSelected.ProductSuppliers.map(s => {
                return {
                    Id: s.TempId ? 0 : s.Id,
                    SupplierId: s.Supplier.Id,
                    Priority: s.Priority,
                    IsDelete: false
                }
            });

            if (tempDeletedSuppliers.length > 0) {
                suppliers = suppliers.concat(tempDeletedSuppliers.map(r => {
                    return {
                        Id: r,
                        IsDelete: true
                    }
                }));
            }

            supplierChangeLog.push({
                Id: productSelected.Id,
                ProductSuppliers: suppliers
            })
        }

        productSelected = this.computeAllPrice(productSelected);
        this.productGridRef.updateCell(productSelected.RowIndex, "CostPrice", productSelected.CostPrice);
        // this.productGridRef.updateCell(productSelected.RowIndex, "Factor", productSelected.Factor);
        // this.productGridRef.updateCell(productSelected.RowIndex, "ProfitPercentage", productSelected.ProfitPercentage);
        // this.productGridRef.updateCell(productSelected.RowIndex, "Profit", productSelected.Profit);
        // this.productGridRef.updateCell(productSelected.RowIndex, "SalesPrice", productSelected.SalesPrice);

        productSelected = {};
        selectedProducts = [];
        this.otherSettingsDialog.hide();

        this.setState({ productSelected, supplierChangeLog, tempOtherSettingsChanges, tempDeletedSuppliers: [], selectedProducts });
    }

    validateFields(product) {
        let errorList = [];

        if (!product.ProductNo) {
            errorList.push('Vare Nr. kræves');
        }

        if (!product.Description1) {
            errorList.push('Beskrivelse kræves');
        }

        if (product.EAN) {
            if (product.EAN.length > 13) {
                errorList.push('EAN kan maks. være på 13 cifre');
            }
            else if (!product.EAN.match(/[0-9]/g)) {
                errorList.push('Tjek venligst ean nr., må kun indholde tal');
            }
        }

        return errorList;
    }

    beforeBatchSave(args) {
        let { changeLog } = this.state;
        let { changedRecords } = args.batchChanges;
        let dataSource = this.productGridRef.dataSource;
        
        if (changedRecords.length > 0) {
            for (let index = 0; index < changedRecords.length; index++) {
                const tempData = changedRecords[index];

                let sourceData = dataSource.find(d => d.Id === tempData.Id);
                let changeItem = {}, isExisting = false;

                if (changeLog.some(p => p.Id === sourceData.Id)) {
                    changeItem = changeLog.find(p => p.Id === sourceData.Id);
                    changeItem.currentData = sourceData;
                    isExisting = true;
                }
                else {
                    changeItem = {
                        Id: sourceData.Id,
                        ProductNo: tempData.ProductNo,
                        Description1: tempData.Description1,
                        currentData: sourceData
                    };
                }

                if (tempData.ProductNo !== sourceData.ProductNo) {
                    changeItem.isProductNoChanged = true;
                }

                if (tempData.Description1 !== sourceData.Description1) {
                    changeItem.isProductDescChanged = true;
                }

                if (tempData.EAN !== sourceData.EAN) {
                    changeItem.EAN = tempData.EAN;
                }

                if (tempData.CustomVolume !== sourceData.CustomVolume) {
                    changeItem.CustomVolume = tempData.CustomVolume;
                }

                if (tempData.Weight != sourceData.Weight) {
                    changeItem.Weight = tempData.Weight;
                }

                if (tempData.Factor !== sourceData.Factor) {
                    changeItem.Factor = tempData.Factor;
                }

                if (tempData.ProfitPercentage !== sourceData.ProfitPercentage) {
                    changeItem.ProfitPercentage = tempData.ProfitPercentage;
                }

                if (tempData.Profit !== sourceData.Profit) {
                    changeItem.Profit = tempData.Profit;
                }
                
                if (tempData.SalesPrice !== sourceData.SalesPrice) {
                    changeItem.SalesPrice = tempData.SalesPrice;
                }

                if (Object.keys(changeItem).length > 2 && !isExisting) {
                    changeLog.push(changeItem);
                }
            }
            
            this.setState({ changeLog, hasUnsavedChanges: true });
        }
    }
    //#endregion
    
    imageTemplate(props) {
        if (props.ProductImages) {
            let primaryImage = props.ProductImages.find(x => x.IsPrimary);
            if (!primaryImage && props.ProductImages.length > 0) {
                primaryImage = props.ProductImages[0];
            }
            if (primaryImage) {
                return (
                    <Image src={primaryImage.ImageKey} alt="" className="product-image-data" />
                )
            }
        }

        return <img src={placeHolderImg} alt="primary product" className="product-image-data" />;
    }

    onSupplierToolbarClick(args) {
        if (args.item.id === 'add') {
            let { productSelected } = this.state;
            let productSuppliers = productSelected.ProductSuppliers.map(x => x.Supplier.Id);

            this.filteredSuppliersData = this.suppliersData.filter(x => !productSuppliers.some(ps => ps === x.Id));
            this.addSuppliersGridRef.dataSource = this.filteredSuppliersData;
            this.addSuppliersGridRef.refresh();
            this.suppliersDialog.show();
        }
    }

    onSupplierActionComplete(args) {
        if (args.requestType === "delete") {
            let { productSelected, tempDeletedSuppliers, otherSettingsLog } = this.state;

            if (args.data[0].Id) {
                tempDeletedSuppliers.push(args.data[0].Id);
            }

            productSelected.ProductSuppliers = args.data[0].TempId ? productSelected.ProductSuppliers.filter(p => p.TempId !== args.data[0].TempId) :
                productSelected.ProductSuppliers.filter(p => p.Id !== args.data[0].Id);
            
            this.productSupplierData = productSelected.ProductSuppliers;
            this.productSupplierGridRef.dataSource = productSelected.ProductSuppliers;
            this.productSupplierGridRef.refresh();

            if (!otherSettingsLog.includes("Leverandør")) {
                otherSettingsLog.push("Leverandør");
            }

            this.setState({ productSelected, hasUnsavedChanges: true, otherSettingsLog, tempDeletedSuppliers });
        }        
    }

    onTabSelect = (index) => {
        this.setState({ currentTabSelected: index });
        setTimeout(() => {
            let { productSelected } = this.state;
    
            switch(index) {
                case 0:
                    this.primaryProductGroupRef.checkedNodes = [productSelected.ProductGroupId.toString()];
                    this.primaryProductGroupRef.refresh();
                    break;
                case 1:
                    // this.productSupplierData = productSelected.ProductSuppliers;
                    // this.productSupplierGridRef.dataSource = productSelected.ProductSuppliers;
                    // this.productSupplierGridRef.refresh();
                    break;
                case 2:
                    break;
            }
        }, 300);
    }
    
    onPrimaryProductGroupChecked(args) {
        if (args.action !== "indeterminate" && args.isInteracted) {
            let { productSelected, otherSettingsLog } = this.state;

            if (args.action === 'check') {
                this.primaryProductGroupRef.checkedNodes = [args.data[0].id];
                productSelected.ProductGroupId = args.data[0].id.toString();
            }
            else {
                this.primaryProductGroupRef.checkedNodes = [];
                productSelected.ProductGroupId = "";
            }

            if (!otherSettingsLog.some(x => x === "Produkt gruppe")) {
                otherSettingsLog.push("Produkt gruppe");
            }

            this.primaryProductGroupRef.selectedNodes = [];
            this.setState({ hasUnsavedChanges: true, productSelected });
        }
    }

    onActiveCaseNumberChange = (args) => {
        const { productSelected, otherSettingsLog } = this.state;
        productSelected.ActiveCaseNumber = args.checked;
        
        if (!otherSettingsLog.some(x => x === "Generelle Indstillinger")) {
            otherSettingsLog.push("Generelle Indstillinger");
        }

        this.setState({ productSelected, hasUnsavedChanges: true, otherSettingsLog });
    }

    onDeactivatedChange = (args) => {
        const { productSelected, otherSettingsLog } = this.state;
        productSelected.Deactivated = args.checked;
        if (!otherSettingsLog.includes("Generelle Indstillinger")) {
            otherSettingsLog.push("Generelle Indstillinger");
        }
        this.setState({ productSelected, hasUnsavedChanges: true, otherSettingsLog });
    }

    onSupplierSaveSelected() {
        const selectedRow = this.addSuppliersGridRef.getSelectedRecords();
        if (selectedRow.length > 0) {
            let { productSelected, otherSettingsLog } = this.state;
            let item = {
                Supplier: {
                    Id: selectedRow[0].Id,
                    SupplierNo: selectedRow[0].SupplierNo,
                    Company: selectedRow[0].Company,
                    DeliveryTimeInDays: selectedRow[0].DeliveryTimeInDays,
                },
                SupplierId: selectedRow[0].Id,
                Priority: productSelected.ProductSuppliers.length + 1,
                TempId: `T${productSelected.ProductSuppliers.length}`
            }

            productSelected.ProductSuppliers.push(item);

            if (!otherSettingsLog.includes("Leverandør")) {
                otherSettingsLog.push("Leverandør");
            }

            this.suppliersDialog.hide();
            this.productSupplierData = productSelected.ProductSuppliers;
            this.productSupplierGridRef.dataSource = productSelected.ProductSuppliers;
            this.productSupplierGridRef.refresh();

            this.setState({ productSelected, hasUnsavedChanges: true, otherSettingsLog });
        }
        else {
            this.dialogOpen('Advarsel', Localization.Product.PleaseSelectSupplier);
        }
    }

    onSupplierActionBegin(args) {
        if (args.requestType === 'save') {
            let { productSelected, otherSettingsLog, supplierChangeLog } = this.state;

            let product = args.data.TempId ? productSelected.ProductSuppliers.find(p => p.TempId === args.data.TempId) :
                productSelected.ProductSuppliers.find(p => p.Id === args.data.Id);
            
            product.Priority = args.data.Priority;

            if (!otherSettingsLog.includes("Leverandør")) {
                otherSettingsLog.push("Leverandør");
            }

            this.setState({ productSelected, otherSettingsLog, hasUnsavedChanges: true });
        }
    }

    dialogOpen(header, content) {
        this.notificationDialog.header = header;
        this.notificationDialog.content = content;
        this.notificationDialog.buttons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        this.notificationDialog.show();
    }

    computeAllPrice(product) {
        product.CostPrice = 0;
        let supplierCount = 0;
        // if (product.ProductSuppliers.length > 0) {
        //     for (let index = 0; index < product.ProductSuppliers.length; index++) {
        //         const s = product.ProductSuppliers[index];
                
        //         supplierCount += 1;
        //         product.CostPrice += s.PurchasePrice ? s.PurchasePrice : 0;
        //     }
            
        //     if (supplierCount > 0) {
        //         product.CostPrice = product.CostPrice / supplierCount;
        //     }
        // }
        product.CostPrice = product.ProductSuppliers.length > 0 ? product.ProductSuppliers.sort((a,b) => (a.Priority > b.Priority) ? 1 : -1)[0].PurchasePrice : 0;

        if (product.Factor) {
            product = this.computePrice("Factor", product);
        }
        else if (product.ProfitPercentage) {
            product.ProfitPercentage = product.ProfitPercentage / 100;
            product = this.computePrice("ProfitPercentage", product);
        }
        else if (product.Profit) {
            product = this.computePrice("Profit", product);
        }
        else if (product.SalesPrice) {
            product = this.computePrice("SalesPrice", product);
        }

        return product;
    }

    computePrice(key, product) {
        switch(key) {
            case "Factor":
                product.SalesPrice = (product.CostPrice * product.Factor).toFixed(2);
                product.Profit = (product.SalesPrice - product.CostPrice).toFixed(2);
                product.ProfitPercentage = ((product.Profit / product.SalesPrice) * 100);
                
                if (!isNaN(product.SalesPrice)) {
                    product.SalesPrice = parseFloat(product.SalesPrice);
                }
                else {
                    product.SalesPrice = 0;
                }

                if (!isNaN(product.Profit)) {
                    product.Profit = parseFloat(product.Profit);
                }
                else {
                    product.Profit = 0;
                }

                if (!isNaN(product.ProfitPercentage)) {
                    var newProfitPercentage = numberTo2DecimalPlace(product.ProfitPercentage);
                    product.ProfitPercentage = parseFloat(newProfitPercentage) / 100;
                }
                else {
                    product.ProfitPercentage = 0;
                }
                break;
            case "ProfitPercentage":
                product.SalesPrice = (product.CostPrice / (1 - product.ProfitPercentage)).toFixed(2);
                product.Profit = (product.SalesPrice - product.CostPrice).toFixed(2);
                product.Factor = (product.SalesPrice / product.CostPrice);
                
                if (!isNaN(product.SalesPrice)) {
                    product.SalesPrice = parseFloat(product.SalesPrice);
                }
                else {
                    product.SalesPrice = 0;
                }

                if (!isNaN(product.Profit)) {
                    product.Profit = parseFloat(product.Profit);
                }
                else {
                    product.Profit = 0;
                }

                if (!isNaN(product.Factor)) {
                    product.Factor = parseFloat(product.Factor);
                }
                else {
                    product.Factor = 0;
                }
                break;
            case "Profit":
                product.SalesPrice = (product.Profit + product.CostPrice).toFixed(2);
                product.ProfitPercentage = ((product.Profit / product.SalesPrice) * 100);
                product.Factor = (product.SalesPrice / product.CostPrice).toFixed(2);
                
                if (!isNaN(product.SalesPrice)) {
                    product.SalesPrice = parseFloat(product.SalesPrice);
                }
                else {
                    product.SalesPrice = 0;
                }

                if (!isNaN(product.ProfitPercentage)) {
                    var newProfitPercentage = numberTo2DecimalPlace(product.ProfitPercentage);
                    product.ProfitPercentage = parseFloat(newProfitPercentage) / 100;
                }
                else {
                    product.ProfitPercentage = 0;
                }

                if (!isNaN(product.Factor)) {
                    product.Factor = parseFloat(product.Factor);
                }
                else {
                    product.Factor = 0;
                }
                break;
            case "SalesPrice":
                product.Profit = (product.SalesPrice - product.CostPrice).toFixed(2);
                product.ProfitPercentage = ((product.Profit / product.SalesPrice) * 100);
                product.Factor = (product.SalesPrice / product.CostPrice).toFixed(2);

                if (!isNaN(product.Profit)) {
                    product.Profit = parseFloat(product.Profit);
                }
                else {
                    product.Profit = 0;
                }

                if (!isNaN(product.ProfitPercentage)) {
                    var newProfitPercentage = numberTo2DecimalPlace(product.ProfitPercentage);
                    product.ProfitPercentage = parseFloat(newProfitPercentage) / 100;
                }
                else {
                    product.ProfitPercentage = 0;
                }

                if (!isNaN(product.Factor)) {
                    product.Factor = parseFloat(product.Factor);
                }
                else {
                    product.Factor = 0;
                }
                break;
        }

        return product;
    }

    onCellEdit(args) {
        this.productGridRef.selectRow(args.row.rowIndex);
    }

    onCellSave(args) {
        let { priceChangeLog } = this.state;
        // if (args.columnName === "Factor") {
        //     if (priceChangeLog.some(x => x.Id === args.rowData.Id)) {
        //         let prod = priceChangeLog.find(x => x.Id === args.rowData.Id);
        //         prod.Factor = args.value;
        //         prod.Profit = null;
        //         prod.ProfitPercentage = null;
        //         prod.SalesPrice = null;
        //     }
        //     else {
        //         priceChangeLog.push({
        //             Id: args.rowData.Id,
        //             Factor: args.value,
        //             Profit: null,
        //             ProfitPercentage: null,
        //             SalesPrice: null
        //         })
        //     }
            
        //     this.setState({ priceChangeLog });
        // }
        // else if (args.columnName === "Profit") {
        //     if (priceChangeLog.some(x => x.Id === args.rowData.Id)) {
        //         let prod = priceChangeLog.find(x => x.Id === args.rowData.Id);
        //         prod.Factor = null;
        //         prod.Profit = args.value;
        //         prod.ProfitPercentage = null;
        //         prod.SalesPrice = null;
        //     }
        //     else {
        //         priceChangeLog.push({
        //             Id: args.rowData.Id,
        //             Factor: null,
        //             Profit: args.value,
        //             ProfitPercentage: null,
        //             SalesPrice: null
        //         })
        //     }
            
        //     this.setState({ priceChangeLog });
        // }
        // else if (args.columnName === "ProfitPercentage") {
        //     if (priceChangeLog.some(x => x.Id === args.rowData.Id)) {
        //         let prod = priceChangeLog.find(x => x.Id === args.rowData.Id);
        //         prod.Factor = null;
        //         prod.Profit = null;
        //         prod.ProfitPercentage = args.value;
        //         prod.SalesPrice = null;
        //     }
        //     else {
        //         priceChangeLog.push({
        //             Id: args.rowData.Id,
        //             Factor: null,
        //             Profit: null,
        //             ProfitPercentage: args.value,
        //             SalesPrice: null
        //         })
        //     }
            
        //     this.setState({ priceChangeLog });
        // }
        // else if (args.columnName === "SalesPrice") {
        //     if (priceChangeLog.some(x => x.Id === args.rowData.Id)) {
        //         let prod = priceChangeLog.find(x => x.Id === args.rowData.Id);
        //         prod.Factor = null;
        //         prod.Profit = null;
        //         prod.ProfitPercentage = null;
        //         prod.SalesPrice = args.value;
        //     }
        //     else {
        //         priceChangeLog.push({
        //             Id: args.rowData.Id,
        //             Factor: null,
        //             Profit: null,
        //             ProfitPercentage: null,
        //             SalesPrice: args.value
        //         })
        //     }
            
        //     this.setState({ priceChangeLog });
        // }
        if (args.columnName === "ProductNo") {
            let datasource = this.productGridRef.dataSource;
            let productNos = datasource.map(x => { return { Id: x.Id, ProductNo: x.ProductNo} });
            if (!args.value) {
                args.cancel = true;
                this.dialogOpen("Advarsel", "Vare Nr. kræves");
            }
            else if (productNos.some(x => x.ProductNo === args.value && x.Id !== args.rowData.Id)) {
                args.cancel = true;
                this.dialogOpen("Advarsel", Localization.Product.ProductNoAlreadyExist);
            }
        }
        else if (args.columnName === "Description1") {
            if (!args.value) {
                args.cancel = true;
                this.dialogOpen("Advarsel", "Beskrivelse kræves");
            }
        }
        else if (args.columnName === "EAN") {
            if (args.value) {
                if (args.value.length > 13) {
                    args.cancel = true;
                    this.dialogOpen("Advarsel", 'EAN kan maks. være på 13 cifre');
                }
                else if (!args.value.match(/[0-9]/g)) {
                    args.cancel = true;
                    this.dialogOpen("Advarsel", 'Tjek venligst ean nr., må kun indholde tal');
                }
            }
        }
    }

    colorEditableCell(args) {
        if (args.column.index > 0 && !args.column.allowEditing) {
            args.cell.classList.add("cell-non-editable");
        }
    }

    formatChangeValue(value) {
        return typeof value !== 'undefined' ? (value || value === 0 ? value : "Slettet") : '';
    }

    render() {
        const { loading, redirect, hasUnsavedChanges, otherSettingsLog, changeLog, productSelected, currentTabSelected } = this.state;

        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        return (
            <div className="content-pane-wrapper">
                <NavigationPrompt when={hasUnsavedChanges} 
                    navigate={path => this.props.history.push(path)}
                    initiateSave={() => this.onSaveChanges()}
                    shouldBlockNavigation={location => {
                        if (hasUnsavedChanges) {
                            return true;
                        }
                        return false;
                    }}
                />
                <div className="module-header">
                    <h1>{this.pageName}</h1>
                </div>
                <div className="c-post-note">
                    <div className="post-note-wrapper">
                        <div className="post-note-content text-left">
                            <h2>Instruktioner</h2>
                            <ul className="c-bullet">
                                <li>Rediger tabel: Åbner tabel for redigering</li>
                                <li>Øvrige indstillinger: Åbner øvrige indstillinger</li>
                                <li>Nulstil filter: Fjerne filtre, så alle produkt vises igen</li>
                                <li>Nulstil Ændringer: Fjerner alle ændringer</li>
                                <li>Opdater: Gennemfører ændringer. (gælder kun markeret produkter)</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <GridComponent id="product-tools-grid" ref={ref => this.productGridRef = ref } dataSource={this.data} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings} toolbar={this.toolbarOptions}
                        selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both" allowReordering={true} allowResizing={true} 
                        editSettings={this.editSettings} toolbarClick={this.onToolbarClick} actionComplete={this.onActionComplete.bind(this)} columnDragStart={this.onColumnDragStart.bind(this)} 
                        created={this.onCreated.bind(this)} resizeStop={this.onResizeStop.bind(this)} actionBegin={this.onActionBegin.bind(this)}
                        beforeBatchSave={this.beforeBatchSave.bind(this)} cellSave={this.onCellSave.bind(this)} cellEdit={this.onCellEdit.bind(this)}
                        queryCellInfo={this.colorEditableCell}>
                        <ColumnsDirective>
                            <ColumnDirective type='checkbox' width="50" textAlign="Center"></ColumnDirective>
                            <ColumnDirective field="Id" headerText="Billede" customAttributes={{class: 'cell-non-editable'}} template={this.imageTemplate} width="150" allowFiltering={false} allowEditing={false} isPrimaryKey={true}/>
                            <ColumnDirective headerText="Produkt Nr." field="ProductNo" width="120" />
                            <ColumnDirective headerText="Beskrivelse" field="Description1" />
                            <ColumnDirective headerText="EAN" field="EAN" />
                            <ColumnDirective headerText="Volume" field="CustomVolume" textAlign="Right" width="120" editType='numericedit' edit={this.numericParams} format="n2" />
                            <ColumnDirective headerText="Vægt" field="Weight" textAlign="Right" editType='numericedit' width="120" edit={this.numericParams} format="n2" />
                            <ColumnDirective headerText="Kost pris" customAttributes={{class: 'cell-non-editable'}} field="CostPrice" textAlign="Right" width="120" allowEditing={false} format="n4" />
                            <ColumnDirective headerText="Faktor" field="Factor" textAlign="Right" width="120" editType='numericedit' edit={this.factorEditParams} format="n2" />
                            <ColumnDirective headerText="Avc. I %" field="ProfitPercentage" textAlign="Right" width="120" editType='numericedit' edit={this.profitPercentageEditParams} format="p2" />
                            <ColumnDirective headerText="Db" field="Profit" textAlign="Right" width="120" editType='numericedit' edit={this.profitEditParams} format="n2" />
                            <ColumnDirective headerText="Salgs pris" field="SalesPrice" textAlign="Right" width="120" editType='numericedit' edit={this.salesPriceEditParams} format="n2" />
                        </ColumnsDirective>
                        <Inject services={[Page, Sort, Filter, Selection, Toolbar, Search, Reorder, Resize, Edit]} />
                    </GridComponent>
                </div>
                <div>
                    <DialogComponent isModal={true} buttons={this.notificationButtons} width='auto' ref={dialog => this.notificationDialog = dialog} target='body'
                        visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} />
                </div>
                <div>
                    <DialogComponent id="changes-report-dialog" isModal={true} buttons={this.changeLogButtons} width='auto' ref={dialog => this.changeLogDialog = dialog} target='body'
                        visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} header="Rapport over ændringer">
                        <div>
                            {
                                otherSettingsLog.length > 0 &&
                                <React.Fragment>
                                    <div>
                                        <h3>Øvrige indstillinger</h3>
                                        <ul className="misc-changes">
                                            {
                                                otherSettingsLog.map(o => {
                                                    return <li>{o}</li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div className="report-separator"></div>
                                </React.Fragment>
                            }
                            <div>
                                <h3>Liste over Produkter der ændres</h3>
                                <div className="list-context-changes">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th className="text-left">Product Nr.</th>
                                                <th className="text-left">Beskrivelse</th>
                                                <th className="text-left">EAN</th>
                                                <th className="text-right">Volume</th>
                                                <th className="text-right">Vægt</th>
                                                <th className="text-right">Faktor</th>
                                                <th className="text-right">Avc. I %</th>
                                                <th className="text-right">Db</th>
                                                <th className="text-right">Salgspris</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                changeLog.map(product => {
                                                    let keys = Object.keys(product);
                                                    let isEanChanged = keys.some(x => x === "EAN");
                                                    let isVolumeChanged = keys.some(x => x === "CustomVolume");
                                                    let isWeightChanged = keys.some(x => x === "Weight");
                                                    let isFactorChanged = keys.some(x => x === "Factor");
                                                    let isProfitPercentageChanged = keys.some(x => x === "ProfitPercentage");
                                                    let isProfitChanged = keys.some(x => x === "Profit");
                                                    let isSalesPriceChanged = keys.some(x => x === "SalesPrice");

                                                    return (
                                                        <tr className="changelog-row">
                                                            <td className={product.isProductNoChanged ? 'changelog-item' : ''}>{product.ProductNo}</td>
                                                            <td className={product.isProductDescChanged ? 'changelog-item' : ''}>{product.Description1}</td>
                                                            <td className={isEanChanged ? 'changelog-item' : ''}>
                                                                {isEanChanged ? product.EAN : product.currentData.EAN}
                                                            </td>
                                                            <td className={`${isVolumeChanged ? 'changelog-item' : ''} text-right`}>
                                                                {isVolumeChanged ? this.formatChangeValue(product.CustomVolume) : product.currentData.CustomVolume}
                                                            </td>
                                                            <td className={`${isWeightChanged ? 'changelog-item' : ''} text-right`}>
                                                                {isWeightChanged ? this.formatChangeValue(product.Weight) : product.currentData.Weight}
                                                            </td>
                                                            <td className={`${isFactorChanged ? 'changelog-item' : ''} text-right`}>
                                                                {isFactorChanged ? this.formatChangeValue(product.Factor) : product.currentData.Factor}
                                                            </td>
                                                            <td className={`${isProfitPercentageChanged ? 'changelog-item' : ''} text-right`}>
                                                                {isProfitPercentageChanged ? this.formatChangeValue(product.ProfitPercentage) : product.currentData.ProfitPercentage}
                                                            </td>
                                                            <td className={`${isProfitChanged ? 'changelog-item' : ''} text-right`}>
                                                                {isProfitChanged ? this.formatChangeValue(product.Profit) : product.currentData.Profit}
                                                            </td>
                                                            <td className={`${isSalesPriceChanged ? 'changelog-item' : ''} text-right`}>
                                                                {isSalesPriceChanged ? this.formatChangeValue(product.SalesPrice) : product.currentData.SalesPrice}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="report-separator"></div>
                            <div>
                                <div className="c-primary-text c-bold">Kontroller at ovenstående oplysninger er korrekte, tryk derefter opdater.</div>
                                <br />
                                <div className="sub-text">Bemærk dette kan ikke fortrydes</div>
                            </div>
                        </div>
                    </DialogComponent>
                </div>
                <div>
                    <DialogComponent id="other-settings-dialog" isModal={true} buttons={this.otherSettingButtons} width='auto' ref={dialog => this.otherSettingsDialog = dialog} target='body'
                        visible={false} showCloseIcon={true} animationSettings={this.animationSettings} allowDragging={true} enableResize={true} header="Øvrige Indstillinger">
                        <div className="product-other-settings-wrapper">
                            <div className="c-post-note">
                                <div className="post-note-wrapper">
                                    <div className="post-note-content text-left">
                                        <h2>Instruktioner</h2>
                                        <div>
                                            Lav indstillingerne for de produkter der skal redigeres, tryk OK for at gemme og vende tilbage til produkter.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Tabs className="c-tab" selectedIndex={currentTabSelected} disabledTabClassName="c-tabs__tab--disabled" onSelect={this.onTabSelect}>
                                <TabList className="c-tab__list">
                                    <Tab className={`c-tabs__tab`} selectedClassName="c-tabs__tab--selected">Produkt Grupper</Tab>
                                    {/* <Tab className={`c-tabs__tab`} selectedClassName="c-tabs__tab--selected">Leverandør</Tab> */}
                                    <Tab className={`c-tabs__tab`} selectedClassName="c-tabs__tab--selected">Generelle Indstillinger</Tab>
                                </TabList>
                                <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                                    <div className="tab-control--item">
                                        <div className="product-group-card-wrapper">
                                            <Card headerText="Tildel primær produkt gruppe" subText="Klik på '>' for tilhørende funktioner">
                                                <TreeViewComponent id="product-group-tab" ref={ref => this.primaryProductGroupRef = ref} fields={this.primaryProductGroupFields} showCheckBox={true}
                                                    nodeChecked={this.onPrimaryProductGroupChecked.bind(this)} autoCheck={false} />
                                            </Card>
                                        </div>
                                    </div>
                                </TabPanel>
                                {/* <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                                    <div className="tab-control--item">
                                        <GridComponent id="product-tools-supplier-grid" ref={ref => this.productSupplierGridRef = ref } dataSource={this.productSupplierData} allowPaging={true} allowSorting={true} 
                                            pageSettings={this.pageSettings} toolbar={this.supplierToolbarOptions} selectionSettings={{type: 'Single'}} allowFiltering={true} filterSettings={this.filterSettings} locale="da" 
                                            gridLines="Both" editSettings={this.supplierEditSettings} toolbarClick={this.onSupplierToolbarClick.bind(this)} actionComplete={this.onSupplierActionComplete.bind(this)}
                                            actionBegin={this.onSupplierActionBegin.bind(this)}>
                                            <ColumnsDirective>
                                                <ColumnDirective headerText="Leverandør Nr." field="Supplier.SupplierNo" width='100' isPrimaryKey={true} />
                                                <ColumnDirective headerText="Leverandør" field="Supplier.Company" width='100' allowEditing={false}/>
                                                <ColumnDirective headerText="Prioitet" field="Priority" width='100' />
                                            </ColumnsDirective>
                                            <Inject services={[Page, Sort, Filter, Selection, Toolbar, Search, Edit]} />
                                        </GridComponent>
                                    </div>
                                </TabPanel> */}
                                <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                                    <div className="tab-control--item tools-product-general-settings">
                                        <div>
                                            <Card headerText="Sags nummer styring" className="mb-15">
                                                <div className="field-bordered">
                                                    <label htmlFor="ActiveCaseNumber">Aktiv</label>
                                                    <CheckBoxComponent id="ActiveCaseNumber" ref={ref => this.ActiveCaseNumberRef = ref} checked={productSelected.ActiveCaseNumber} name="ActiveCaseNumber" cssClass="control-right" change={this.onActiveCaseNumberChange} />
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </DialogComponent>
                </div>
                <DialogComponent id="tools-product-suppliers-dialog" isModal={true} width='auto' ref={dialog => this.suppliersDialog = dialog} target='body' showCloseIcon={true} animationSettings={this.animationSettings}
                    visible={false}  allowDragging={true} enableResize={true} header="Tilføj Leverandør">
                        <div className="dialog-content">
                            <GridComponent ref={ref => this.addSuppliersGridRef = ref } allowPaging={true} dataSource={this.filteredSuppliersData} allowSorting={true} pageSettings={this.pageSettings} className="suppliers-list-grid" allowTextWrap='true'
                                selectionSettings={{type: 'Single'}} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both">
                                <ColumnsDirective>
                                    <ColumnDirective field="Id" headerText="Leverandør #" width='100' /> 
                                    <ColumnDirective field="Company" headerText="Leverandør" width='100' />
                                </ColumnsDirective>
                                <Inject services={[Page, Sort, Filter, Selection, Toolbar, Edit, Search]} />
                            </GridComponent>
                            <div className="dialog-footer">
                                <button className="e-control e-btn e-lib" onClick={() => this.suppliersDialog.hide()}>Annuller</button>
                                <button className="e-control e-btn e-lib e-primary" onClick={this.onSupplierSaveSelected.bind(this)}>Gem</button>
                            </div>
                        </div>
                </DialogComponent>
            </div>
        )
    }
}

export default ProductTools
