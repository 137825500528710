import React, { Component } from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import Axios from 'axios';
import { ToolbarComponent, ItemsDirective, ItemDirective } from '@syncfusion/ej2-react-navigations';
import { Redirect } from 'react-router-dom';
import NavigationPrompt from '../../components/NavigationPrompt';

import BaseComponent from '../../components/BaseComponent';
import Card from '../../components/Card';

import SettingsService from '../../services/SettingsService';

import Breadcrumb from '../../components/Breadcrumb';
import '../../styles/systemsettings.scss';
import Loading from '../../components/Loading';
import Localization from '../../utilities/Localization';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class Units extends BaseComponent {
    moduleName = "System indstillinger";
    pageName = "Enheder";
    constructor() {
        super();

        this.state = {
            loading: true,
            dimensions: [],
            breadcrumbs: ['System Indstillinger', 'Enheder'],
            weights: [],
            selectedDimension: null,
            selectedWeight: null,
            isEditMode: false,
            redirect: {
                to: '',
                isRedirect: false
            },
            hasUnsavedChanges: false
        }
        
        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.buttons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        /* End Dialog options  */

        this.onUnitsToolbarClicked = this.onUnitsToolbarClicked.bind(this);
        this.dialogOpen = this.dialogOpen.bind(this);
        this.refreshData = this.refreshData.bind(this);
    }

    async componentDidMount() {
        super.componentDidMount();
        source.cancel();
        source = CancelToken.source();

        if (!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }
        else if (!super.hasMenuItem("Enheder")) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }

        const unitsResult = await SettingsService.GetUnitSettings(source.token);
        const dimensions = await SettingsService.GetDimensions(source.token);
        const weights = await SettingsService.GetWeights(source.token);

        this.setState({ dimensions, weights, loading: false, selectedWeight: unitsResult.WeightUnit, selectedDimension: unitsResult.DimensionUnit });
    }

    componentWillUnmount() {
        source.cancel();
    }

    onDimensionChange = (e) => {
        this.setState({ selectedDimension: e.value, hasUnsavedChanges: true });
    }

    onWeightChange = (e) => {
        this.setState({ selectedWeight: e.value, hasUnsavedChanges: true });
    }

    async onUnitsToolbarClicked(args) {
        if (args.item.id === 'edit') {
            this.setState({ isEditMode: true });
        }
        else if (args.item.id === 'update') {
            source.cancel();
            source = CancelToken.source();
            
            let { selectedDimension, selectedWeight } = this.state;
    
            const payload = {
                DimensionUnit: selectedDimension,
                WeightUnit: selectedWeight
            };
    
            const result = await SettingsService.UpdateUnits(payload, source.token);
    
            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Enheder"));
                this.setState({ isEditMode: false, hasUnsavedChanges: false });
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
        else if (args.item.id === 'cancel') {
            this.refreshData().then(() => {
                this.setState({ isEditMode: false, hasUnsavedChanges: false });
            });
        }
    }

    async refreshData() {
        const unitsResult = await SettingsService.GetUnitSettings(source.token);
        const dimensions = await SettingsService.GetDimensions(source.token);
        const weights = await SettingsService.GetWeights(source.token);

        this.setState({ dimensions, weights, selectedWeight: unitsResult.WeightUnit, selectedDimension: unitsResult.DimensionUnit });
    }

    dialogOpen(header, content) {
        this.notificationDialog.header = header;
        this.notificationDialog.content = content;
        this.notificationDialog.show();
    }

    render() {
        const { loading, dimensions, weights, isEditMode, selectedDimension, selectedWeight, breadcrumbs, redirect, hasUnsavedChanges } = this.state;

        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        return (
            <div className="content-pane-wrapper">
                <NavigationPrompt when={hasUnsavedChanges} 
                    navigate={path => this.props.history.push(path)}
                    initiateSave={() => this.onUnitsToolbarClicked({ item: { id: 'update' } })}
                    shouldBlockNavigation={location => {
                        if (hasUnsavedChanges) {
                            return true;
                        }
                        return false;
                    }}
                />
                {/* <div>
                    <Breadcrumb breadcrumbs={breadcrumbs} />
                </div> */}
                <div className="module-header">
                    <h1>{this.pageName}</h1>
                </div>
                <div className={`system-setting-units-toolbar-wrapper`}>
                    <ToolbarComponent ref={ref => this.unitsToolbarRef = ref} clicked={this.onUnitsToolbarClicked}>
                        <ItemsDirective>
                            <ItemDirective id="edit" prefixIcon='tb-icons c-icon-edit' text="Redigere" tooltipText='Redigere' disabled={isEditMode} />
                            <ItemDirective id="update" prefixIcon='tb-icons c-icon-update' text="Gem" tooltipText='Gem' disabled={!isEditMode} />
                            <ItemDirective id="cancel" prefixIcon='tb-icons c-icon-cancel' text="Annuller" tooltipText='Annuller' disabled={!isEditMode} />
                        </ItemsDirective>
                    </ToolbarComponent>
                </div>
                <div>
                    <Card headerText="Måle Enheder" className="system-settings-units-card-wrapper">
                        <table className="table units-table">
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="system-settings-units-label">
                                            Dimensioner (L:H:B)
                                        </div>
                                        <div className="system-settings-units-values">
                                            {
                                                dimensions.map(dimension => {
                                                    let isChecked = selectedDimension === dimension.Value;
                                                    return <RadioButtonComponent disabled={!isEditMode} label={dimension.Key} checked={isChecked} value={dimension.Value} name="dimension" change={this.onDimensionChange} />
                                                })
                                            }
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="system-settings-units-label">
                                            Vægt
                                        </div>
                                        <div className="system-settings-units-values">
                                            {
                                                weights.map(weight => {
                                                    let isChecked = selectedWeight === weight.Value;
                                                    return <RadioButtonComponent disabled={!isEditMode} label={weight.Key} checked={isChecked} value={weight.Value} name="weight" change={this.onWeightChange}/>
                                                })
                                            }
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Card>
                </div>
                <DialogComponent id="units-dialog" isModal={true} buttons={this.buttons} width='auto' ref={dialog => this.notificationDialog = dialog} target='body' showCloseIcon={true}
                    cssClass="dialog-notification" animationSettings={this.animationSettings} visible={false} allowDragging={true} enableResize={true}/>
            </div>
        )
    }
}

export default Units;
