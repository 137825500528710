import HttpClient from '../../utilities/HttpClient';
import AppSettings from '../../settings/AppSettings';

const API = {
    getMessages: '/message',
    getMessage: '/message/{id}',
    postDraftMessage: '/message/save',
    postMessage:'/message/send',
    postMoveToArchive: '/message/{id}/archive',
    postSoftDelete: '/Message/{id}/trash',
    postMoveToInbox: '/Message/{id}/inbox',
    postMarkMessage: '/Message/{id}/mark',
    deleteMessage: '/message/{id}',
    getContacts: '/message/contacts',

    getSettingsMailVersion: '/settings/exchangeVersion',
    getSettingsMailServer: '/settings/mailServer',
    postSettingsMailServer: '/settings/mailServer'
}

class MessageService {
    async GetMessages(params, cancelToken) {
        let url = API.getMessages;
        
        var data = {
            type: null
        };
         
        if (params) {
            if (params.folderId !== undefined) {
                url += `?type=${params.folderId}`;
                // url += `?type=${params.folderId}`;
                data.type = parseInt(params.folderId);
            }
     
            if (params.searchString !== undefined) {
                url += `&searchString=${params.searchString}`
                // url += `&searchString=${params.searchString}`
                data.searchString = params.searchString;
            }
     
            if (params.read !== undefined) {
                url += `&read=${params.read ? 'true' : 'false'}`
                // url += `&read=${params.read ? 'true' : 'false'}`
                data.read = params.read ? true : false;
            }
     
            if (params.newest !== undefined) {
                url += `&newest=${params.newest ? 'true' : 'false'}`
                // url += `&newest=${params.newest ? 'true' : 'false'}`
                data.newest = params.newest ? true : false;
            }
        }
 
        return await HttpClient.Post(url, data, cancelToken);
    }

    async GetMessage(id, cancelToken) {
        const url = API.getMessage.replace('{id}', id);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async SaveAsDraft(data, cancelToken) {
        return await HttpClient.Post(API.postDraftMessage, data, cancelToken);
    }

    async SendMessage(data, cancelToken) {
        return await HttpClient.Post(API.postMessage, data, cancelToken);
    }
    async MoveToArchive(id, cancelToken) {
        const url = API.postMoveToArchive.replace('{id}', id);
        return await HttpClient.Post(url, null, cancelToken);
    }

    async MoveToTrash(id, cancelToken) {
        const url = API.postSoftDelete.replace('{id}', id);
        return await HttpClient.Post(url, null, cancelToken);
    }

    async MoveToInbox(id, cancelToken) {
        const url = API.postMoveToInbox.replace('{id}', id);
        return await HttpClient.Post(url, null, cancelToken);
    }

    async MarkUnreadRead(id, read, cancelToken) {
        let url = API.postMarkMessage.replace('{id}', id);
        url = url + `?read=${read ? 'true': 'false'}`;
        return await HttpClient.Post(url, null, cancelToken);
    }

    async DeleteMessage(id, cancelToken) {
        const url = API.deleteMessage.replace('{id}', id);
        return await HttpClient.Delete(url, null, cancelToken);
    }

    async GetContacts(cancelToken) {
        return await HttpClient.Get(API.getContacts, null, cancelToken);
    }

    async GetSettingsMailVersion(cancelToken) {
        return await HttpClient.Get(API.getSettingsMailVersion, null, cancelToken);
    }

    async GetSettingsMailServer(cancelToken) {
        return await HttpClient.Get(API.getSettingsMailServer, null, cancelToken);
    }

    async UpdateMailSettings(data, cancelToken) {
        return await HttpClient.Post(API.postSettingsMailServer, data, cancelToken);
    }
}

const messageService = new MessageService();
export default messageService; 