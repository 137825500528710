import React, { Component } from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { TabComponent, TabItemDirective, TabItemsDirective, TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { DropDownList } from '@syncfusion/ej2-react-dropdowns';
import { DataManager } from '@syncfusion/ej2-data';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Page, Sort, Toolbar, Selection, Search, Reorder, Resize, Edit } from '@syncfusion/ej2-react-grids';
import Axios from 'axios';
import XLSX from 'xlsx';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import Dropzone from 'react-dropzone'

import Image from '../../components/Image';
import NavigationPrompt from '../../components/NavigationPrompt';
import Localization from '../../utilities/Localization';
import Card from '../../components/Card';
import placeHolderImg from '../../assets/placeholders/img_placeholder_108x80.jpg';

import SessionService from '../../services/SessionService';
import Loading from '../../components/Loading';
import SettingsService from '../../services/SettingsService';
import ProductService from '../../services/Product/ProductService';
import SupplierService from '../../services/Supplier/SupplierService';
import ProductGroupService from '../../services/Product/ProductGroupService';
import FileService from '../../services/File/FileService';
import { buildGridSettings } from '../../utilities/Helper';

export class ProductImportExport extends Component {
    source = Axios.CancelToken.source();
    gridSettingsKey = "ProductImportExportList";
    hasDraggedColumn = false;
    gridSettings = '';
    
    constructor() {
        super();

        this.state = {
            hasUnsavedChanges: false,
            loading: true,
            stageData: [],
            validationReport: [],
            selectedProduct: {},
            files: [],
            currentTabSelected: 0,
            statusData: []
        };

        this.data = [];

        this.pageSettings = {
            pageSize: 25,
            pageSizes: ['Alle', '5', '10', '20', '50', '100']
        };

        this.importStatusDataPageSettings = {
            pageSize: 5
        };

        this.reviewDataPageSettings = {
            pageSize: 10
        };

        this.selectionSettings = {
            persistSelection: true
        };

        this.filterSettings = {
            type: 'Menu'
        };
        
        this.importTabHeader = [
            { text: "Vælg fil" }, 
            { text: "Se data" },
            { text: "Import" }
        ];

        this.productPicturesFields = {
            dataSource: [], id: 'Key', text: 'Name', child: 'SubFiles' 
        };

        this.picturesData = [];
        this.ImportColumns = [ "Vare Nr.", "Ean Nr.", "Beskrivelse", "Beskrivelse 2", "Enhed", "Salgs enhed i stk.", "Dimensioner(mm/cm/m)", 
            "Vægt (gram/kilogram)", "Længde", "Højde", "Bredde", "Volume", "Salgs Enheds Vægt", "Produkt gruppe", "Faktor", "Avance i %", "Dækningsbidrag", 
            "Salgspris", "Sags nummer styring (Y/N)", "Under Garanteret Vælg(Indtast altid antal/Vej altid/Vælg ved træk)",
            "Leverandør Nr.", "Lev Produkt#", "Lev EAN #", "Lev produkt navn", "Leverer i antal på", "Indkøbspris / stk.", "Priority",
            "Billede Fil Navn", "Primær (Y/N)"
        ];
        this.SupplierImportColumns = [ "Vare Nr.", "Leverandør Nr.", "Lev Produkt#", "Lev EAN #", "Lev produkt navn", "Leverer i antal på", "Indkøbspris / stk.", "Priority" ];
        this.ImageImportColumns = [ "Vare Nr.", "Billede Fil Navn", "Primær (Y/N)" ];

        this.init = this.init.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.processImport = this.processImport.bind(this);
        this.validateData = this.validateData.bind(this);
        this.onOtherSettingClose = this.onOtherSettingClose.bind(this);
        this.onSaveOtherSetting = this.onSaveOtherSetting.bind(this);
        this.onActiveCaseNumberChange = this.onActiveCaseNumberChange.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.clickHandler = this.clickHandler.bind(this);
        this.pictureNodeCheck = this.pictureNodeCheck.bind(this);
        this.onFileImageSelected = this.onFileImageSelected.bind(this);
        this.onCheckChange = this.onCheckChange.bind(this);

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.notificationButtons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.validationReportDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];

        this.otherSettingsButtons = [
            {
                click: this.onOtherSettingClose,
                buttonModel: {
                    content: 'Tilbage'
                }
            },
            {
                click: this.onSaveOtherSetting,
                buttonModel: {
                    isPrimary: true,
                    content: 'OK'
                }
            }
        ];
        /* End Dialog options  */
    }

    async componentDidMount() {
        this.source.cancel();
        this.source = Axios.CancelToken.source();

        this.init();

        const sessionInfo = await SessionService.GetSessionInfo();
        this.CustomerId = sessionInfo.Id;
        
        const products = await ProductService.GetProducts(sessionInfo.Id, { active: true }, this.source.token);
        this.data = products;

        const suppliers = await SupplierService.GetSuppliers(sessionInfo.Id, this.source.token);
        this.suppliersData = suppliers;

        const productGroups = await ProductGroupService.GetProductGroupsLite(sessionInfo.Id, this.source.token);
        this.primaryProductGroupFields.dataSource = productGroups.map(x => {
            return {
                ...x,
                expanded: x.SubGroups.length > 0 ? true : false
            }
        });
        this.productGroups = productGroups;
        this.productGroupsIter = this.iterGroups(productGroups, null);

        const dimensions = await SettingsService.GetDimensions(this.source.token);
        this.dimensionsData = dimensions;

        const weights = await SettingsService.GetWeights(this.source.token);
        this.weightsData = weights;

        const units = await SettingsService.GetUnitSettings(this.source.token);
        this.unitsData = units;

        const gridSettings = await SettingsService.GetGridSettings(this.gridSettingsKey, this.source.token);
        if (!gridSettings.HasError) {
            this.gridSettings = gridSettings.Settings;
        }

        const productImages = await FileService.GetProductImages(false, this.source.token);
        this.productImages = productImages;

        const files = await FileService.GetImageList(this.source.token);
        let tempFiles = [{ Key: files.Key, SubFiles: files.SubFiles, Name: files.Name, expanded: true }];
        this.productPicturesFields.dataSource = tempFiles;

        this.setState({ loading: false }, () => {
            window.addEventListener('wheel', this.onWheelScroll);
        });
    }

    iterGroups(productGroups, parentGroupName) {
        let groups = [];
        productGroups.forEach(s => {
            s.ParentGroupName = parentGroupName;
            groups.push(s);
            if (s.SubGroups.length > 0) {
                let subGroups = this.iterGroups(s.SubGroups, s.Name);
                groups.push(...subGroups);
            }
        });
        return groups;
    }

    init() {
        this.toolbarOptions = [
            { text: 'Import', tooltipText: 'Import', id: 'import', prefixIcon: 'e-upload' },
            { text: 'Excel Export', tooltipText: 'Excel Export', id: 'export', prefixIcon: 'e-export-excel' },
            { text: 'Download Template', tooltipText: 'Download Template', id: 'download_template', prefixIcon: 'e-download' },
            { text: Localization.General.GridResetFilter, tooltipText: Localization.General.GridResetFilter, id: 'reset_filter', prefixIcon: 'e-cancel' }
        ];

        this.reviewDataToolbarOptions = [
            'Edit', 'Update', 'Cancel', 'Delete',
            { text: 'Øvrige indstillinger', tooltipText: 'Øvrige indstillinger', id: 'other_settings', prefixIcon: 'e-more-details' }
        ];

        this.reviewDataEditSettings = {
            allowEditing: true,
            allowDeleting: true,
            showDeleteConfirmDialog: true
        };
        
        this.supplierEditSettings = {
            allowEditing: true,
            allowDeleting: true,
            showDeleteConfirmDialog: true
        };
        
        this.numericParams = { params: { showSpinButton: false, min: 0, step: 0, cssClass: 'text-right', decimals: 2 }};
        
        this.supplierToolbarOptions = [
            'Edit', 'Update', 'Cancel', 'Delete'
        ];

        this.imageToolbarOptions = [
            { text: 'Link Billede', tooltipText: 'Link Billede', id: 'linkImage', prefixIcon: 'e-icon-link' },
            'Delete'
        ];

        this.defaultEditSettings = {
            allowDeleting: true,
            showDeleteConfirmDialog: true
        };

        this.weightUnitParams = {
            create: () => {
                this.weightUnitElem = document.createElement('input');
                return this.weightUnitElem;
            },
            destroy: () => { this.weightUnitObj.destroy(); },
            read: () => {
                this.selectedWeightUnit = this.weightUnitObj.value;
                return this.weightUnitElem.value;
            },
            write: (e) => {
                this.weightUnitObj = new DropDownList({
                    dataSource: new DataManager(this.weightsData),
                    fields: { value: 'Value', text: 'Key' },
                    floatLabelType: 'Never',
                    value: e.rowData.WeightUnit
                  });
                  this.weightUnitObj.appendTo(this.weightUnitElem);
            }
        };

        this.dimensionUnitParams = {
            create: () => {
                this.dimensionUnitElem = document.createElement('input');
                return this.dimensionUnitElem;
            },
            destroy: () => { this.dimensionUnitObj.destroy(); },
            read: () => {
                this.selectedDimensionUnit = this.dimensionUnitObj.value;
                return this.dimensionUnitElem.value;
            },
            write: (e) => {
                this.dimensionUnitObj = new DropDownList({
                    dataSource: new DataManager(this.dimensionsData),
                    fields: { value: 'Value', text: 'Key' },
                    floatLabelType: 'Never',
                    value: e.rowData.DimensionUnit
                  });
                  this.dimensionUnitObj.appendTo(this.dimensionUnitElem);
            }
        };

        this.primaryProductGroupFields = {
            dataSource: [], id: 'Id', text: 'Name', child: 'SubGroups' 
        }

        this.LHWEditParams = { params: {
            change: (e) => {
                if (e.isInteracted) { 
                    var inputEle = this.stageGridRef.element.querySelector("#product-review-grid.e-grid .e-gridform input[name='CustomVolume']"); 
                    if (inputEle) { 
                      var volumeEle = inputEle.previousElementSibling.ej2_instances[0]; 
                      volumeEle.value = null; 
                    } 
                }
            },
            showSpinButton: false, min: 0, step: 0, format: 'n2'
        }};

        this.VolumeEditParams = { params: {
            change: (e) => {
                var grid = document.querySelector("#product-review-grid.e-grid").ej2_instances[0];
                if (grid.element.querySelector(".e-gridform") && e.isInteracted) { 
                    var lengthEle = this.stageGridRef.element.querySelector(".e-gridform input[name='Length']");
                    var heightlEle = this.stageGridRef.element.querySelector(".e-gridform input[name='Height']"); 
                    var widthEle = this.stageGridRef.element.querySelector(".e-gridform input[name='Width']");

                    if (lengthEle) {
                      lengthEle.previousElementSibling.ej2_instances[0].value = null; 
                    }
                    if (heightlEle) { 
                      heightlEle.previousElementSibling.ej2_instances[0].value = null; 
                    }
                    if (widthEle) { 
                      widthEle.previousElementSibling.ej2_instances[0].value = null; 
                    }
                }
            },
            showSpinButton: false, min: 0, step: 0, format: 'n2'
        }};

        this.factorEditParams = { params: {
            change: (e) => {
                var grid = document.querySelector("#product-review-grid.e-grid").ej2_instances[0];
                if (grid.element.querySelector(".e-gridform") && e.isInteracted) { 
                    var ppElem = this.stageGridRef.element.querySelector(".e-gridform input[name='ProfitPercentage']");
                    var pElem = this.stageGridRef.element.querySelector(".e-gridform input[name='Profit']"); 
                    var spElem = this.stageGridRef.element.querySelector(".e-gridform input[name='SalesPrice']");

                    if (ppElem) {
                        ppElem.previousElementSibling.ej2_instances[0].value = null; 
                    }
                    if (pElem) { 
                        pElem.previousElementSibling.ej2_instances[0].value = null; 
                    }
                    if (spElem) { 
                      spElem.previousElementSibling.ej2_instances[0].value = null; 
                    }
                }
            },
            showSpinButton: false, min: 0, step: 0
        }};

        this.profitPercentageEditParams = { params: {
            change: (e) => {
                var grid = document.querySelector("#product-review-grid.e-grid").ej2_instances[0];
                if (grid.element.querySelector(".e-gridform") && e.isInteracted) { 
                    var fElem = this.stageGridRef.element.querySelector(".e-gridform input[name='Factor']");
                    var pElem = this.stageGridRef.element.querySelector(".e-gridform input[name='Profit']"); 
                    var spElem = this.stageGridRef.element.querySelector(".e-gridform input[name='SalesPrice']");

                    if (fElem) {
                        fElem.previousElementSibling.ej2_instances[0].value = null; 
                    }
                    if (pElem) { 
                        pElem.previousElementSibling.ej2_instances[0].value = null; 
                    }
                    if (spElem) { 
                      spElem.previousElementSibling.ej2_instances[0].value = null; 
                    }
                }
            },
            showSpinButton: false, min: 0, step: 0
        }};

        this.profitEditParams = { params: {
            change: (e) => {
                var grid = document.querySelector("#product-review-grid.e-grid").ej2_instances[0];
                if (grid.element.querySelector(".e-gridform") && e.isInteracted) { 
                    var fElem = this.stageGridRef.element.querySelector(".e-gridform input[name='Factor']");
                    var ppElem = this.stageGridRef.element.querySelector(".e-gridform input[name='ProfitPercentage']"); 
                    var spElem = this.stageGridRef.element.querySelector(".e-gridform input[name='SalesPrice']");

                    if (ppElem) {
                        ppElem.previousElementSibling.ej2_instances[0].value = null; 
                    }
                    if (fElem) { 
                        fElem.previousElementSibling.ej2_instances[0].value = null; 
                    }
                    if (spElem) { 
                      spElem.previousElementSibling.ej2_instances[0].value = null; 
                    }
                }
            },
            showSpinButton: false, min: 0, step: 0
        }};

        this.salesPriceEditParams = { params: {
            change: (e) => {
                var grid = document.querySelector("#product-review-grid.e-grid").ej2_instances[0];
                if (grid.element.querySelector(".e-gridform") && e.isInteracted) { 
                    var ppElem = this.stageGridRef.element.querySelector(".e-gridform input[name='ProfitPercentage']");
                    var pElem = this.stageGridRef.element.querySelector(".e-gridform input[name='Profit']"); 
                    var fElem = this.stageGridRef.element.querySelector(".e-gridform input[name='Factor']");

                    if (ppElem) {
                        ppElem.previousElementSibling.ej2_instances[0].value = null; 
                    }
                    if (pElem) { 
                        pElem.previousElementSibling.ej2_instances[0].value = null; 
                    }
                    if (fElem) { 
                      fElem.previousElementSibling.ej2_instances[0].value = null; 
                    }
                }
            },
            showSpinButton: false, min: 0, step: 0
        }};
    }

    componentWillUnmount() {
        this.source.cancel();
        window.removeEventListener('wheel', this.onWheelScroll);
    }

    onColumnDragStart(args) {
        this.hasDraggedColumn = true;
    }

    onCreated() {
        if (this.gridSettings) {
            let settings = JSON.parse(this.gridSettings);
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(column => {
                        if (column.FromIndex !== column.ToIndex) {
                            var tempIndex = this.productGridRef.getColumnIndexByField(column.Field);
                            if (tempIndex !== column.ToIndex){
                                this.productGridRef.reorderColumnByIndex(column.FromIndex, column.ToIndex);
                            }
                        }
    
                        if (column.Width) {
                            this.productGridRef.columns[column.ToIndex].width = column.Width;
                        }
                    });
                }

                if (settings.colName && settings.direction) {
                    this.productGridRef.sortColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.productGridRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.productGridRef.refreshColumns();

                if (settings.pageSize) {
                    if (settings.pageSize === "Alle") {
                        this.productGridRef.pageSettings.pageSize = this.productGridRef.pageSettings.totalRecordsCount;
                        document.querySelector("#product-grid .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.productGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
            }
        }
    }

    async onActionComplete(args) {
        if (args.requestType === 'paging') {
            if (this.productGridRef.pageSettings.pageSize === this.productGridRef.pageSettings.totalRecordsCount) {
                document.querySelector("#product-grid .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, this.productGridRef.pageSettings.pageSize, this.productGridRef.pageSettings.totalRecordsCount, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };
            const result = await SettingsService.SaveGridSettings(payload, this.source.token);
        }
        else if (args.requestType === 'filtering') {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, this.source.token);

            if (!result.HasError) {
                this.props.notify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, this.source.token);

            if (!result.HasError) {
                this.props.notify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.productGridRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, this.hasDraggedColumn, columns);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, this.source.token);

            if (!result.HasError) {
                this.props.notify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }

            this.hasDraggedColumn = false;
        }
    }

    async onResizeStop(args) {
        args.requestType = "resizingGrid";
        let columns = this.productGridRef.getColumns();
        let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, columns);
        this.gridSettings = tempGridSettings;
        const payload = {
            Key: this.gridSettingsKey,
            Settings: this.gridSettings
        };

        const result = await SettingsService.SaveGridSettings(payload, this.source.token);

        if (!result.HasError) {
            this.props.notify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
        }
    }

    async onToolbarClick(args) {
        if (args.item.id === "import") {
            this.productImportDialog.show();
            this.productImportWizardTab.select(0);
            this.productImportWizardTab.enableTab(1, false);
            this.productImportWizardTab.enableTab(2, false);
            this.reviewButton.disabled = true;
            this.setState({ files: [] });
            
            setTimeout(() => {
                let element = document.querySelector('.product-file-list .file-name-text');
                if (element) {
                    element.innerHTML = "";
                }
            }, 300);
        }
        else if (args.item.id === "export") {
            let source = this.productGridRef.getSelectedRecords();
            if (source.length <= 0) {
                this.props.notify("Advarsel", Localization.General.PleaseSelectItemsToExport);
            } else {
                let productFormattedSource = [];
                let productSupplierFormattedSource = [];

                productFormattedSource.push(this.ImportColumns);
                for (let index = 0; index < source.length; index++) {
                    const element = source[index];
                    let item = [];

                    let dimensionUnit = this.dimensionsData.find(d => d.Value === element.DimensionUnit);
                    let weightUnit = this.weightsData.find(w => w.Value === element.WeightUnit);

                    item.push(element.ProductNo);
                    item.push(element.EAN);
                    item.push(element.Description1);
                    item.push(element.Description2);
                    item.push(element.SalesUnit);
                    item.push(element.SalesUnitPerPcs);
                    item.push(dimensionUnit.Key);
                    item.push(weightUnit.Key);
                    item.push(element.Length);
                    item.push(element.Height);
                    item.push(element.Width);
                    item.push(element.CustomVolume);
                    item.push(element.Weight);

                    if (element.ProductGroup) {
                        item.push(element.ProductGroup.Id);
                    }
                    else {
                        item.push('');
                    }
                    
                    item.push(element.Factor);
                    item.push(element.ProfitPercentage);
                    item.push(element.Profit);
                    item.push(element.SalesPrice);
                    item.push(element.ActiveCaseNumber ? "Y" : "N");
                    item.push(element.AlwaysInputQty ? "Indtast altid antal" : (element.AlwaysWeight ? "Vej altid" : "Vælg ved træk"));

                    let maxLength = element.ProductSuppliers.length > element.ProductImages.length ? element.ProductSuppliers.length : element.ProductImages.length;
                    if (maxLength > 0) {
                        for (let index = 0; index < maxLength; index++) {
                            if (index > 0) {
                                item = [];
                                item.push(element.ProductNo);
                                item = this.pushEmptyInArray(item, 19);
                            }
                            if (typeof element.ProductSuppliers[index] === 'undefined') {
                                item = this.pushEmptyInArray(item, 7);
                            } else {
                                const supplier = element.ProductSuppliers[index];
                                item.push(supplier.Supplier.SupplierNo);
                                item.push(supplier.SupplierProductNo);
                                item.push(supplier.SupplierEAN);
                                item.push(supplier.SupplierProductDesc);
                                item.push(supplier.DeliveryQty);
                                item.push(supplier.PurchasePrice);
                                item.push(supplier.Priority);
                            }

                            if (!(typeof element.ProductImages[index] === 'undefined')) {
                                const image = element.ProductImages[index];
                                item.push(image.ImageKey);
                                item.push(image.IsPrimary ? "Y" : "N");
                            }

                            productFormattedSource.push(item);
                        }
                    } else {
                        productFormattedSource.push(item);
                    }
                }

                let data2 = [
                    [ "Id", "Navn", "Overliggende gruppe" ]
                ];

                this.productGroupsIter.forEach(element => {
                    let item = [];
                    item.push(element.Id);
                    item.push(element.Name);
                    item.push(element.ParentGroupName);
                    data2.push(item);
                });

                let product = XLSX.utils.aoa_to_sheet(productFormattedSource);
                let sheet2 = XLSX.utils.aoa_to_sheet(data2);
                let workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, product, "Produkt");
                XLSX.utils.book_append_sheet(workbook, sheet2, "Produktgruppe Ids");
                XLSX.writeFile(workbook, "Products Export.xlsx"); // FOR TRANSLATION
            }
        }
        else if (args.item.id === "download_template") {
            let data1 = [
                this.ImportColumns
            ];

            let data4 = [
                [ "Id", "Navn", "Overliggende gruppe" ]
            ];

            this.productGroupsIter.forEach(element => {
                let item = [];
                item.push(element.Id);
                item.push(element.Name);
                item.push(element.ParentGroupName);
                data4.push(item);
            });

            let sheet = XLSX.utils.aoa_to_sheet(data1);
            let sheet4 = XLSX.utils.aoa_to_sheet(data4);
            let workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, sheet, "Produkt");
            XLSX.utils.book_append_sheet(workbook, sheet4, "Produktgruppe Ids");
            XLSX.writeFile(workbook, "Product Template.xlsx"); // FOR TRANSLATION
        } else if (args.item.id === 'reset_filter') {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                settings.filters = [];
                this.gridSettings = settings;
                const payload = {
                    Key: this.gridSettingsKey,
                    Settings: JSON.stringify(this.gridSettings)
                };
    
                const result = await SettingsService.SaveGridSettings(payload, this.source.token);
    
                if (!result.HasError) {
                    this.props.notify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                }
            }

            this.productGridRef.clearFiltering();
        }
    }

    pushEmptyInArray(array, count){
        for (let i = 0; i < count; i++) {
            array.push('');
        }
        return array;
    }

    onChangeFile(files) {
        if (this.importStatusRef) {
            this.importStatusRef.dataSource = [];
            this.importStatusRef.refresh();
            this.importStatusRef.showSpinner();
        }

        const file = files[0];
        let importStatusData = [];
        
        this.setState({ files }, () => {
            let element = document.querySelector('.product-file-list .file-name-text');
            element.innerHTML = file.name;
        });
        let tempProducts = this.data.map(x => {return { Id: 0, ProductNo: x.ProductNo, EAN: x.EAN, SourceId: x.Id }});

        if (file) {
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;
            reader.onload = e => {
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
                const productSheet = wb.Sheets[wb.SheetNames[0]];

                const prdColHeaders = []
                const prdColCount = XLSX.utils.decode_range(productSheet['!ref']).e.c + 1
                for (let i = 0; i < prdColCount; ++i) {
                    prdColHeaders[i] = productSheet[`${XLSX.utils.encode_col(i)}1`].v.toString().trim();
                }

                let allColDiffs = [];
                var prdColDiffs = this.ImportColumns.filter(x => !prdColHeaders.includes(x));
                if (prdColDiffs) {
                    allColDiffs.push(...prdColDiffs);
                }
                if (allColDiffs && allColDiffs.length > 0) {
                    importStatusData.push({
                        Row: "Column Headers",
                        Status: `Column/s (${allColDiffs.join(", ")}) findes ikke`
                    });
                }

                const productData = XLSX.utils.sheet_to_json(productSheet, { header: 0, raw: false });
                let data = [];
                for (let index = 0; index < productData.length; index++) {
                    const t = productData[index];
                    let errorMessages = [];
                    let productNo = t["Vare Nr."] ? t["Vare Nr."].toString().trim() : '';
                    let existingProduct = this.data.find(x => x.ProductNo.toString().trim().toLowerCase() === productNo.toLowerCase());
                    if (data.some(x => x.ProductNo === productNo)) {
                        let element = data.find(x => x.ProductNo === productNo);
                        if (t["Leverandør Nr."]) {
                            let supplierInfo = this.suppliersData.find(s => s.SupplierNo !== null && s.SupplierNo.toString().trim().toLowerCase() === t["Leverandør Nr."].toString().trim().toLowerCase());
                            if (!supplierInfo) {
                                errorMessages.push(`Leverandør (${t["Leverandør Nr."].toString()}) findes ikke`);
                            } else {
                                element.ProductSuppliers.push({
                                    Id: element.ProductSuppliers.length + 1,
                                    SupplierId: supplierInfo.Id,
                                    Supplier: supplierInfo,
                                    SupplierProductNo: t["Lev Produkt#"] ? t["Lev Produkt#"].toString().trim() : null,
                                    SupplierProductDesc: t["Lev produkt navn"] ? t["Lev produkt navn"].toString().trim() :null,
                                    SupplierEAN: t["Lev EAN #"] ? t["Lev EAN #"].toString().trim() :null,
                                    DeliveryQty: t["Leverer i antal på"] ? parseInt(t["Leverer i antal på"]) : 1,
                                    PurchasePrice: t["Indkøbspris / stk."] ? parseFloat(t["Indkøbspris / stk."]) : 0,
                                    Priority: t["Priority"] ? parseInt(t["Priority"]) : element.ProductSuppliers.length + 1
                                });
                            }
                        }
    
                        if (t["Billede Fil Navn"]) {
                            let imageInfo = this.productImages.find(x => x.Name.toLowerCase() == t["Billede Fil Navn"].toString().trim().toLowerCase() || x.NameWithoutExtension.toLowerCase() == t["Billede Fil Navn"].toString().trim().toLowerCase());
                            if (!imageInfo) {
                                if (existingProduct) {
                                    let exImage = existingProduct.ProductImages.find(x => x.ImageKey.trim().toLowerCase() === t["Billede Fil Navn"].toString().trim().toLowerCase());
                                    if (exImage) {
                                        element.ProductImages.push({
                                            Id: exImage.Id,
                                            ImageKey: exImage.ImageKey,
                                            IsPrimary: exImage.IsPrimary
                                        });
                                    } else {
                                        errorMessages.push(`Billeder (${t["Billede Fil Navn"].toString()}) findes ikke`);
                                    }
                                } else {
                                    errorMessages.push(`Billeder (${t["Billede Fil Navn"].toString()}) findes ikke`);
                                }
                            } else {
                                element.ProductImages.push({
                                    Id: element.ProductImages.length + 1,
                                    ImageKey: imageInfo.Key,
                                    IsPrimary: t["Primær (Y/N)"] && t["Primær (Y/N)"].toString().trim().toLowerCase() === "y" ? true : false
                                });
                            }
                        }
                    } else {
                        let rawDimensionUnit = t["Dimensioner(mm/cm/m)"], rawWeightUnit = t["Vægt (gram/kilogram)"], rawProductGroup = t["Produkt gruppe"], rawVolume = t["Volume"];
                        let dimensionUnit = null, weightUnit = null, productGroup = null;
                        let productSuppliers = [];
                        let productImages = [];
                        let length = t["Længde"], height = t["Højde"], width = t["Bredde"], customVolume = null, caseNumber = false, defaultPurchaseTolerance = null;
                        let rawProductDraw = t["Under Garanteret Vælg(Indtast altid antal/Vej altid/Vælg ved træk)"] ? t["Under Garanteret Vælg(Indtast altid antal/Vej altid/Vælg ved træk)"] : "Indtast altid antal";
    
                        if (rawDimensionUnit) {
                            dimensionUnit = this.dimensionsData.find(d => d.Key === rawDimensionUnit.toString().trim().toLowerCase());
                            if (!dimensionUnit) {
                                dimensionUnit = this.dimensionsData.find(d => d.Value === this.unitsData.DimensionUnit);
                                errorMessages.push(`Ukorrekt udfyldt dimensioner (value: ${rawDimensionUnit.toString().trim()})`);
                            }
                        }
                        else {
                            //dimensionUnit = this.dimensionsData.find(d => d.Value === this.unitsData.DimensionUnit);
                            errorMessages.push(`Dimensioner(mm/cm/m) kræves`);
                        }
    
                        if (rawWeightUnit) {
                            weightUnit = this.weightsData.find(d => d.Key === rawWeightUnit.toString().trim().toLowerCase());
                            if (!weightUnit) {
                                weightUnit = this.weightsData.find(d => d.Value === this.unitsData.WeightUnit);
                                errorMessages.push(`Ukorrekt udfyldt vægt (value: ${rawWeightUnit.toString().trim()})`);
                            }
                        }
                        else {
                            //weightUnit = this.weightsData.find(d => d.Value === this.unitsData.WeightUnit);
                            errorMessages.push(`Vægt (gram/kilogram) kræves`);
                        }
    
                        if (rawProductGroup) {
                            productGroup = this.productGroupsIter.find(p => p.Id == rawProductGroup);
    
                            if (productGroup) {
                                defaultPurchaseTolerance = productGroup.PurchaseTolerance;
                            } else {
                                errorMessages.push(`Produktgruppe (${rawProductGroup}) findes ikke`);
                            }
                        }
    
                        customVolume = parseFloat(rawVolume) ? parseFloat(rawVolume) : null;
                        if (!customVolume) {
                            length = parseFloat(length) ? parseFloat(length) : null;
                            height = parseFloat(height) ? parseFloat(height) : null;
                            width = parseFloat(width) ? parseFloat(width) : null;
                        }
    
                        if ("Sags nummer styring (Y/N)" in t) {
                            caseNumber = t["Sags nummer styring (Y/N)"].toString().trim().toLowerCase() === "y";
                        }
    
                        if (t["Leverandør Nr."]) {
                            let supplierInfo = this.suppliersData.find(s => s.SupplierNo !== null && s.SupplierNo.toString().toLowerCase() === t["Leverandør Nr."].toString().trim().toLowerCase());
                            if (!supplierInfo) {
                                errorMessages.push(`Leverandør (${t["Leverandør Nr."].toString()}) findes ikke`);
                            } else {
                                productSuppliers.push({
                                    Id: 1,
                                    SupplierId: supplierInfo.Id,
                                    Supplier: supplierInfo,
                                    SupplierProductNo: t["Lev Produkt#"] ? t["Lev Produkt#"].toString().trim() : null,
                                    SupplierProductDesc: t["Lev produkt navn"] ? t["Lev produkt navn"].toString().trim() :null,
                                    SupplierEAN: t["Lev EAN #"] ? t["Lev EAN #"].toString().trim() :null,
                                    DeliveryQty: t["Leverer i antal på"] ? parseInt(t["Leverer i antal på"]) : 1,
                                    PurchasePrice: t["Indkøbspris / stk."] ? parseFloat(t["Indkøbspris / stk."]) : 0,
                                    Priority: t["Priority"] ? parseInt(t["Priority"]) : 1
                                });
                            }
                        }
    
                        if (t["Billede Fil Navn"]) {
                            let imageInfo = this.productImages.find(x => x.Name.toLowerCase() == t["Billede Fil Navn"].toString().trim().toLowerCase() || x.NameWithoutExtension.toLowerCase() == t["Billede Fil Navn"].toString().trim().toLowerCase());
                            if (!imageInfo) {
                                if (existingProduct) {
                                    let exImage = existingProduct.ProductImages.find(x => x.ImageKey.trim().toLowerCase() === t["Billede Fil Navn"].toString().trim().toLowerCase());
                                    if (exImage) {
                                        productImages.push({
                                            Id: exImage.Id,
                                            ImageKey: exImage.ImageKey,
                                            IsPrimary: exImage.IsPrimary
                                        });
                                    } else {
                                        errorMessages.push(`Billeder (${t["Billede Fil Navn"].toString()}) findes ikke`);
                                    }
                                } else {
                                    errorMessages.push(`Billeder (${t["Billede Fil Navn"].toString()}) findes ikke`);
                                }
                            } else {
                                productImages.push({
                                    Id: 1,
                                    ImageKey: imageInfo.Key,
                                    IsPrimary: t["Primær (Y/N)"] && t["Primær (Y/N)"].toString().trim().toLowerCase() === "y" ? true : false
                                });
                            }
                        }
                        
                        let element = {
                            Id: index + 1,
                            SourceId: existingProduct ? existingProduct.Id : null,
                            ProductNo: productNo,
                            EAN: t["Ean Nr."] ? t["Ean Nr."].toString().trim() : '',
                            Description1: t["Beskrivelse"] ? t["Beskrivelse"].toString().trim() : '',
                            Description2: t["Beskrivelse 2"] ? t["Beskrivelse 2"].toString().trim() : '',
                            SalesUnit: t["Enhed"] ? t["Enhed"].toString().trim() : '',
                            SalesUnitPerPcs: parseFloat(t["Salgs enhed i stk."]) ? parseFloat(t["Salgs enhed i stk."]) : 0,
                            ProductGroup: productGroup ? productGroup : null,
                            ProductGroupId: productGroup ? productGroup.Id : null,
                            DimensionUnit: dimensionUnit ? dimensionUnit.Value : null,
                            WeightUnit: weightUnit ? weightUnit.Value : null,
                            DimensionUnitText: dimensionUnit ? dimensionUnit.Key : null,
                            WeightUnitText: weightUnit ? weightUnit.Key : null,
                            Length: length,
                            Height: height,
                            Width: width,
                            Weight: parseFloat(t["Salgs Enheds Vægt"]) ? parseFloat(t["Salgs Enheds Vægt"]) : null,
                            CustomVolume: customVolume,
                            Factor: parseFloat(t["Faktor"]) ? parseFloat(t["Faktor"]) : null,
                            ProfitPercentage: parseFloat(t["Avance i %"]) ? parseFloat(t["Avance i %"]) : null,
                            Profit: parseFloat(t["Dækningsbidrag"]) ? parseFloat(t["Dækningsbidrag"]) : null,
                            SalesPrice: parseFloat(t["Salgspris"]) ? parseFloat(t["Salgspris"]) : null,
                            PurchaseTolerance: parseFloat(t["Tillæg til tolerance ved indkøb"]) ? parseFloat(t["Tillæg til tolerance ved indkøb"]) : defaultPurchaseTolerance,
                            ActiveCaseNumber: caseNumber,
                            ProductSuppliers: productSuppliers,
                            ProductImages: productImages,
                            AlwaysInputQty: rawProductDraw.trim().toLowerCase() === "indtast altid antal",
                            AlwaysWeight: rawProductDraw.trim().toLowerCase() === "vej altid",
                            ChooseWhenProductDraw: rawProductDraw.trim().toLowerCase() === "vælg ved træk"
                        };
                        data.push(element);
    
                        tempProducts.push({ Id: element.Id, ProductNo: element.ProductNo, EAN: element.EAN });
                        errorMessages.push(...this.validateElement(element, tempProducts));
                    }

                    if (errorMessages.length > 0) {
                        importStatusData.push({
                            Row: (index + 1).toString(),
                            Status: errorMessages.join(", ")
                        });
                    }
                }
                
                this.setState({ stageData: data });

                if (this.importStatusRef) {
                    this.importStatusRef.hideSpinner();
                    if (data && data.length === 0) {
                        this.reviewButton.disabled = true;
                        importStatusData.push({
                            Row: "Alle",
                            Status: Localization.ImportExport.NoRowsFound
                        });
                    } else if (importStatusData.length === 0) {
                        this.reviewButton.disabled = false;
                        importStatusData.push({
                            Row: "Alle",
                            Status: Localization.ImportExport.NoErrorsFound
                        });
                    } else {
                        this.reviewButton.disabled = true;
                    }
                    this.importStatusRef.dataSource = importStatusData;
                    this.importStatusRef.refresh();
                }
            };

            if (rABS) reader.readAsBinaryString(file);
            else reader.readAsArrayBuffer(file);
        }
    }

    onButtonClick(args) {
        let { stageData, files } = this.state;
        
        switch(args.target.id) {
            case "select-file":
                this.productImportWizardTab.select(0);
                this.productImportWizardTab.enableTab(1, false);
                this.productImportWizardTab.enableTab(2, false);
                break;
            case "review-data":
                if (files.length <= 0) {
                    this.props.notify("Advarsel", Localization.ImportExport.PleaseSelectAFile);
                    return;
                }
                else if (stageData.length <= 0) {
                    this.props.notify("Advarsel", Localization.ImportExport.NoRowsFound);
                    return;
                }

                this.productImportWizardTab.enableTab(1, true);
                this.productImportWizardTab.select(1);
                setTimeout(() => {
                    this.stageGridRef.dataSource = stageData;
                    this.stageGridRef.refresh();
                }, 300);
                break;
            case "finalize-import":
                let isValid = this.validateData();

                if (!isValid) {
                    this.validationReportDialog.show();
                    return;
                }

                this.productImportWizardTab.enableTab(2, true);
                this.productImportWizardTab.select(2);

                this.processImport();
                break;
        }
    }

    validateData() {
        let { stageData, validationReport } = this.state;
        validationReport = [];
        let tempProducts = this.data.map(x => {return { Id: 0, ProductNo: x.ProductNo, EAN: x.EAN, SourceId: x.Id }});
        tempProducts.push(...stageData.map(x => {return { Id: x.Id, ProductNo: x.ProductNo, EAN: x.EAN }}));
        
        for (let index = 0; index < stageData.length; index++) {
            const element = stageData[index];
            let errorList = [];

            errorList = this.validateElement(element, tempProducts);

            if (errorList.length > 0) {
                validationReport.push({
                    Id: element.Id,
                    Messages: errorList
                })
            }
        }

        this.setState({ validationReport });
        return validationReport.length <= 0;
    }

    validateElement(element, tempProducts) {
        let errorList = [];
        if (!element.ProductNo) {
            errorList.push('Produkt Nr. kræves');
        }
        else {
            if (!element.ProductNo.match(/^[a-z0-9\-]+$/i)) {
                errorList.push('Ukorrekt udfyldt vare nr.');
            }

            if (tempProducts.some(x => x.ProductNo === element.ProductNo && x.Id !== element.Id && !(element.SourceId && x.SourceId === element.SourceId))) {
                errorList.push('Produkt nr. er allerede taget');
            }
        }

        if (element.EAN) {
            if (!element.EAN.match(/^[0-9]+$/i)) {
                errorList.push(Localization.Product.EANNumbersOnly);
            }

            if (tempProducts.some(x => x.EAN === element.EAN && x.Id !== element.Id && !(element.SourceId && x.SourceId === element.SourceId))) {
                errorList.push('Ean nr. er allerede taget');
            }
        }

        if (!element.Description1) {
            errorList.push('Beskrivelse kræves');
        }
        if (!element.ProductGroupId) {
            errorList.push('Produktgruppe kræves');
        }

        if (element.ProductSuppliers && element.ProductSuppliers.length > 0) {
            element.ProductSuppliers.map((supplier, index) => {
                if (supplier.SupplierEAN && !supplier.SupplierEAN.match(/^[0-9]+$/i)) {
                    errorList.push(`Leverandør Row ${index + 1} - ${Localization.Product.EANNumbersOnly}`);
                }
            });
        }

        return errorList;
    }

    fileSelectTab() {
        return (
            <div className="tab-control--item">
                <Dropzone onDrop={this.onChangeFile} accept=".xls, .xlsx" maxFiles={1}>
                    {({getRootProps, getInputProps}) => (
                        <section className="container">
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} />
                                <p>Træk og slip filen her, eller klik for at vælge en fil</p>
                            </div>
                            <div className="product-file-list">
                                <div className="file-name-label">Valgte fil:</div>
                                <div className="file-name-text"></div>
                            </div>
                        </section>
                    )}
                </Dropzone>
                <div className="import-status-container">
                    <div>Import Status:</div>
                </div>
                <GridComponent ref={ref => this.importStatusRef = ref } dataSource={this.statusData} allowPaging={true} allowSorting={true} pageSettings={this.importStatusDataPageSettings}
                    selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both" width="100%" allowTextWrap={true}>
                    <ColumnsDirective>
                        <ColumnDirective headerText="Row" field="Row" width='200' isPrimaryKey={true} />
                        <ColumnDirective headerText="Status" field="Status" allowEditing={false} />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Search]} />
                </GridComponent>
                <div className="wizard-action-buttons">
                    <button ref={ref => this.reviewButton = ref} id="review-data" className="e-btn e-primary" onClick={this.onButtonClick}>Se data</button>
                </div>
            </div>
        )
    }

    async processImport() {
        let { stageData } = this.state;
        let payload = []

        for (let index = 0; index < stageData.length; index++) {
            const element = stageData[index];

            if (element.ProductSuppliers) {
                element.ProductSuppliers = element.ProductSuppliers.sort(s => s.Priority).map((s, sIndex) => {
                    s.Priority = sIndex + 1;
                    return s;
                })
            }

            payload.push({
                Id: element.SourceId ? element.SourceId : 0,
                CustomerId: this.CustomerId,
                ProductNo: element.ProductNo,
                EAN: element.EAN,
                Description1: element.Description1,
                Description2: element.Description2,
                SalesUnit: element.SalesUnit,
                SalesUnitPerPcs: parseInt(element.SalesUnitPerPcs),
                ProductGroupId: element.ProductGroupId,
                DimensionUnit: element.DimensionUnit,
                WeightUnit: element.WeightUnit,
                Length: element.CustomVolume ? null : element.Length,
                Height: element.CustomVolume ? null : element.Height,
                Width: element.CustomVolume ? null : element.Width,
                Weight: element.Weight,
                CustomVolume: element.CustomVolume,
                Factor: element.Factor,
                ProfitPercentage: element.ProfitPercentage,
                Profit: element.Profit,
                SalesPrice: element.SalesPrice,
                PurchaseTolerance: element.PurchaseTolerance,
                ActiveCaseNumber: element.ActiveCaseNumber,
                ProductSuppliers: element.ProductSuppliers.map((s, sIndex) => {
                    return {
                        ChangeOrder: sIndex + 1,
                        SupplierId: s.SupplierId,
                        SupplierProductNo: s.SupplierProductNo,
                        SupplierEAN: s.SupplierEAN,
                        SupplierProductDesc: s.SupplierProductDesc,
                        DeliveryQty: s.DeliveryQty,
                        PurchasePrice: s.PurchasePrice,
                        Priority: parseInt(s.Priority),
                        DeliveryDays: 0,
                        IsDelete: false
                    }
                }),
                ProductImages: element.ProductImages.map(img => {
                    return {
                        ImageKey: img.ImageKey,
                        IsPrimary: img.IsPrimary
                    }
                }),
                IsDelete: false,
                ChangeOrder: index + 1
            })
        }
        
        const result = await ProductService.ImportBulkSave(payload, this.source.token);

        if (!result.HasError) {
            this.props.notify(Localization.General.SuccessHeader, Localization.Tools.SuccessImport);
            const products = await ProductService.GetProducts(this.CustomerId, { active: true }, this.source.token);
            this.data = products;

            this.productGridRef.dataSource = products;
            this.productGridRef.refresh();
            
            this.importStatusRef.dataSource = [];
            this.importStatusRef.refresh();

            this.productImportDialog.hide();
            this.productImportWizardTab.select(0);
            this.productImportWizardTab.enableTab(1, false);
            this.productImportWizardTab.enableTab(2, false);

            this.setState({ stageData: [] });
        }
        else {
            this.props.notify("Advarsel", result.ErrorMessage);
            this.onButtonClick({ target: { id: 'review-data' }});
        }
    }

    reviewDataActionBegin(args) {
        if (args.requestType === "save") {
            let errorList = [];

            if (!args.data.ProductNo) {
                errorList.push('Produkt Nr. kræves');
            }

            if (!args.data.Description1) {
                errorList.push('Beskrivelse kræves');
            }

            if (!args.data.ProductGroupId) {
                errorList.push('Produktgruppe kræves');
            }

            if (errorList.length > 0) {
                this.props.notify("Advarsel", errorList.join('<br/>'));
                args.cancel = true;
                return;
            }
        }
    }

    reviewDataActionComplete(args) {
        if (args.requestType === "save") {
            let { stageData } = this.state;
            let data = stageData.find(x => x.Id === args.data.Id);

            data.WeightUnit = this.selectedWeightUnit;
            data.DimensionUnit = this.selectedDimensionUnit;
            this.setState({ stageData }, () => {
                this.stageData = stageData;
                this.stageGridRef.dataSource = stageData;
                this.stageGridRef.refresh();
            });
        }
        else if (args.requestType === "delete") {
            let { stageData } = this.state;
            stageData = stageData.filter(x => x.Id !== args.data[0].Id);
            this.setState({ stageData });
        }
    }

    reviewDataTab() {
        return (
            <div className="tab-control--item">
                <GridComponent id="product-review-grid" ref={ref => this.stageGridRef = ref } dataSource={this.stageData} allowPaging={true} allowSorting={true} pageSettings={this.reviewDataPageSettings} toolbar={this.reviewDataToolbarOptions}
                    selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both" editSettings={this.reviewDataEditSettings}
                    allowResizing={true} actionBegin={this.reviewDataActionBegin.bind(this)} actionComplete={this.reviewDataActionComplete.bind(this)} toolbarClick={this.onReviewDataToolbarClick.bind(this)} width="100%">
                    <ColumnsDirective>
                        <ColumnDirective headerText="No." field="Id" width='100' isPrimaryKey={true} />
                        <ColumnDirective headerText="Produkt Nr." field="ProductNo" />
                        <ColumnDirective headerText="Beskrivelse" field="Description1" />
                        <ColumnDirective headerText="Beskrivelse 2" field="Description2" />
                        <ColumnDirective headerText="EAN" field="EAN" />
                        <ColumnDirective headerText="Enhed" field="SalesUnit" />
                        <ColumnDirective headerText="Salgs enhed i stk." field="SalesUnitPerPcs" editType='numericedit' edit={this.numericParams} format="n2" />
                        <ColumnDirective headerText="Længde" field="Length" textAlign="Right" editType='numericedit' edit={this.LHWEditParams} format="n2" />
                        <ColumnDirective headerText="Højde" field="Height" textAlign="Right" editType='numericedit' edit={this.LHWEditParams} format="n2" />
                        <ColumnDirective headerText="Bredde" field="Width" textAlign="Right" editType='numericedit' edit={this.LHWEditParams} format="n2" />
                        <ColumnDirective headerText="Volume" field="CustomVolume" textAlign="Right" editType='numericedit' edit={this.VolumeEditParams} format="n2" />
                        <ColumnDirective headerText="Dimensioner" field="DimensionUnitText" editType="dropdownedit" edit={this.dimensionUnitParams} />
                        <ColumnDirective headerText="Vægt" field="WeightUnitText" editType="dropdownedit" edit={this.weightUnitParams} />
                        <ColumnDirective headerText="Salgs Enheds Vægt" field="Weight" textAlign="Right" editType='numericedit' edit={this.numericParams} format="n2" />
                        <ColumnDirective headerText="Faktor" field="Factor" textAlign="Right" editType='numericedit' edit={this.factorEditParams} format="n2" />
                        <ColumnDirective headerText="Avc. I %" field="ProfitPercentage" textAlign="Right" editType='numericedit' edit={this.profitPercentageEditParams} format="p2" />
                        <ColumnDirective headerText="Db" field="Profit" textAlign="Right" editType='numericedit' edit={this.profitEditParams} format="n2" />
                        <ColumnDirective headerText="Salgs pris" field="SalesPrice" textAlign="Right" editType='numericedit' edit={this.salesPriceEditParams} format="n2" />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Selection, Toolbar, Search, Resize, Edit]} />
                </GridComponent>
                <div className="wizard-action-buttons">
                    <button id="select-file" className="e-btn" onClick={this.onButtonClick}>Vælg fil</button>
                    <button id="finalize-import" className="e-btn e-primary" onClick={this.onButtonClick}>Bekræft og importer</button>
                </div>
            </div>
        )
    }

    finalizeImportTab() {
        return (
            <div className="tab-control--item">
                <div className="importing-text">Importere data. Vent  venligst</div>
            </div>
        )
    }

    onReviewDataToolbarClick(args) {
        if (args.item.id === "other_settings") {
            let { selectedProduct } = this.state;

            let products = this.stageGridRef.getSelectedRecords();
            if (products.length > 0)  {
                selectedProduct = products[0];
                
                if (selectedProduct.ProductGroupId) {
                    this.primaryProductGroupRef.checkedNodes = [selectedProduct.ProductGroupId.toString()];
                    this.primaryProductGroupRef.refresh();
                }

                this.setState({ selectedProduct });
                this.otherSettingsDialog.show();
            }
            else {
                this.props.notify("Advarsel", Localization.General.NoRowSelected);
            }
        }
    }

    imageTemplate(props) {
        if (props.ProductImages) {
            let primaryImage = props.ProductImages.find(x => x.IsPrimary);
            if (!primaryImage && props.ProductImages.length > 0) {
                primaryImage = props.ProductImages[0];
            }
            if (primaryImage) {
                return (
                    <Image src={primaryImage.ImageKey} alt="" className="product-image-data" />
                )
            }
        }

        return <img src={placeHolderImg} alt="primary product" className="product-image-data" />;
    }

    singleImageTemplate(props) {
        if (props.ImageKey) {
            return (
                <Image src={props.ImageKey} alt="" className="product-image-data" />
            )
        }

        return <img src={placeHolderImg} alt="primary product" className="product-image-data" />;
    }

    imagePathTemplate(props) {        
        let path = props.ImageKey.split('/');
        path.shift();
        return ( <div>{path.join('/')}</div> )
    }

    imageNameTemplate(props) {        
        let imgFullPath = props.ImageKey.split('/');
        let imageName = imgFullPath[imgFullPath.length - 1];
        return ( <div>{imageName}</div> );
    }

    primaryTemplate(props) {
        return ( <div><CheckBoxComponent name="primary_picture" checked={props.IsPrimary} change={(e) => { this.onCheckChange(e, props) }} /></div> )
    }
    
    onPrimaryProductGroupChecked(args) {
        if (args.action !== "indeterminate" && args.isInteracted) {
            let { selectedProduct } = this.state;

            if (args.action === 'check') {
                this.primaryProductGroupRef.checkedNodes = [args.data[0].id];
                selectedProduct.ProductGroupId = parseInt(args.data[0].id);
            }
            else {
                this.primaryProductGroupRef.checkedNodes = [];
                selectedProduct.ProductGroupId = null;
            }

            this.primaryProductGroupRef.selectedNodes = [];
            this.setState({ selectedProduct });
        }
    }

    onTabSelect = (index) => {
        if (this.productSupplierGridRef) {
            this.productSupplierGridRef.endEdit();
        }

        this.setState({ currentTabSelected: index });
        setTimeout(() => {
            let { selectedProduct } = this.state;
    
            switch(index) {
                case 0:
                    if (selectedProduct.ProductGroupId) {
                        this.primaryProductGroupRef.checkedNodes = [selectedProduct.ProductGroupId.toString()];
                        this.primaryProductGroupRef.refresh();
                    }
                    break;
                case 1:
                    this.productSupplierData = selectedProduct.ProductSuppliers;
                    this.productSupplierGridRef.dataSource = selectedProduct.ProductSuppliers;
                    this.productSupplierGridRef.refresh();
                    break;
                case 2:
                    this.ProductPicturesGridRef.dataSource = selectedProduct.ProductImages;
                    this.ProductPicturesGridRef.refresh();
                    break;
                case 3:
                    break;
            }
        }, 300);
    }

    onOtherSettingClose() {
        this.otherSettingsDialog.hide();
        this.setState({ selectedProduct: {}, currentTabSelected: 0 });
    }

    onSaveOtherSetting() {
        if (this.productSupplierGridRef) {
            this.productSupplierGridRef.endEdit();
        }

        let { stageData, selectedProduct } = this.state;
        let product = stageData.find(s => s.Id === selectedProduct.Id);
        product.ProductGroupId = selectedProduct.ProductGroupId;
        product.ActiveCaseNumber = selectedProduct.ActiveCaseNumber;
        product.PurchaseTolerance = selectedProduct.PurchaseTolerance;
        product.ProductSuppliers = selectedProduct.ProductSuppliers;

        this.otherSettingsDialog.hide();
        this.setState({ stageData, selectedProduct: {}, currentTabSelected: 0 });
    }

    onSupplierActionComplete(args) {
        if (args.requestType === "delete") {
            let { selectedProduct } = this.state;

            selectedProduct.ProductSuppliers = selectedProduct.ProductSuppliers.filter(p => p.Id !== args.data[0].Id);
            
            this.productSupplierData = selectedProduct.ProductSuppliers;
            this.productSupplierGridRef.dataSource = selectedProduct.ProductSuppliers;
            this.productSupplierGridRef.refresh();

            this.setState({ selectedProduct });
        }        
    }

    onSupplierActionBegin(args) {
        if (args.requestType === 'save') {
            let { selectedProduct } = this.state;
            let data = selectedProduct.ProductSuppliers.find(p => p.Id === args.data.Id);
            data = Object.assign({}, args.data);
            this.setState({ selectedProduct });
        }
    }

    onActiveCaseNumberChange(args) {
        const { selectedProduct } = this.state;
        selectedProduct.ActiveCaseNumber = args.checked;
        this.setState({ selectedProduct });
    }

    onValueChange(values, name) {
        let { selectedProduct } = this.state;

        if (values.floatValue && values.floatValue > 0) {
            selectedProduct[name] = values.floatValue;
        }
        else if (!values.floatValue) {
            selectedProduct[name] = values.floatValue;
        }
        else {
            selectedProduct[name] = null;
        }

        this.setState({ selectedProduct });
    }

    onSelect(args) {
        if (args.isSwiped) {
            args.cancel = true;
        }
    }

    async clickHandler(args) {
        if (this.ProductPicturesGridRef && args.item.id === 'linkImage') {
            const dataSource = this.ProductPicturesGridRef.dataSource;
            if (dataSource.length == 5) {
                this.dialogOpen("Advarsel", Localization.Product.ErrorMaxImageReached)
            }
            else {
                this.fileArchiveDialog.show();
            }
        }
    }

    async onProductImagesActionComplete(args) {
        if (args.requestType === "delete") {
            let { selectedProduct } = this.state;
            selectedProduct.ProductImages = this.ProductPicturesGridRef.dataSource;

            if (selectedProduct.ProductImages.length === 1) {
                selectedProduct.ProductImages[0].IsPrimary = true;
            }

            this.picturesData = selectedProduct.ProductImages;
            this.ProductPicturesGridRef.refresh();

            this.setState({ selectedProduct });
        }
    }

    async onCheckChange(args, props) {
        let { selectedProduct } = this.state;

        selectedProduct.ProductImages.forEach(img => {
            if (img.TempId && img.TempId === props.TempId) {
                img.IsPrimary = args.checked;
            }
            else if (img.Id && img.Id === props.Id) {
                img.IsPrimary = args.checked;
            }
            else {
                img.IsPrimary = false;
            }
        });

        this.ProductPicturesGridRef.dataSource = selectedProduct.ProductImages;
        this.ProductPicturesGridRef.refresh();

        this.setState({ selectedProduct });
    }

    pictureNodeCheck(args) {
        let checkedNode = [args.node];
        if (args.event.target.classList.contains('e-fullrow') || args.event.key == "Enter") {
            let nodeDetails = this.fileImagesRef.getNode(args.node);
            
            let imageTypes = ['png', 'jpg', 'jpeg'];
            let s = nodeDetails.text.split('.');
            if (imageTypes.includes(s[s.length - 1])) {
                if (nodeDetails.isChecked == 'true') {
                    this.fileImagesRef.uncheckAll(checkedNode);
                }
                else {
                    this.fileImagesRef.checkAll(checkedNode);
                }
            }
        }
    }

    async onFileImageSelected(args) {
        const selectedFiles = this.fileImagesRef.checkedNodes;
        
        if (selectedFiles.length > 0) {
            let { selectedProduct } = this.state;
            let allowableImageCount = 5 - selectedProduct.ProductImages.length;

            if (selectedFiles.length > allowableImageCount) {
                this.dialogOpen('Advarsel', Localization.Product.ErrorMaxImageReached);
                return;
            }

            let isPrimary = selectedProduct.ProductImages.length === 0 && selectedFiles.length === 1;

            for (let index = 0; index < selectedFiles.length; index++) {
                let imageTypes = ['png', 'jpg', 'jpeg'];
                let image = selectedFiles[index];

                let spImg = image.split('.');
                if (imageTypes.includes(spImg[spImg.length - 1])) {
                    const pic = {
                        Id: null,
                        TempId: `T${selectedProduct.ProductImages.length}`,
                        ImageKey: selectedFiles[index],
                        IsPrimary: isPrimary,
                        ProductId: selectedProduct.Id,
                    }

                    selectedProduct.ProductImages.push(pic);
                }
            }

            this.picturesData = selectedProduct.ProductImages;
            this.ProductPicturesGridRef.dataSource = selectedProduct.ProductImages;
            this.ProductPicturesGridRef.refresh();

            this.setState({ selectedProduct }, () => {
                this.fileImagesRef.uncheckAll();
                this.fileArchiveDialog.hide();
            });
        }
        else {
            this.dialogOpen('Advarsel', Localization.Product.PleaseSelectImage);
        }
    }

    onWheelScroll = () => {
        let grid = document.getElementById('product-grid');
        let toolbar = document.querySelector('#product-grid.e-grid .e-toolbar');
        let tableHeader = document.querySelector('#product-grid .e-gridheader');

        if (toolbar && tableHeader) {
            let boundingRect = tableHeader.getBoundingClientRect();
            
            if (boundingRect.top < 90) {
                toolbar.classList.add('c-hover-grid-toolbar');
                toolbar.style.width = (grid.clientWidth - 5) + 'px';
            }
            else {
                toolbar.classList.remove('c-hover-grid-toolbar');
                toolbar.style.width = 'auto';
            }
        }
    }

    render() {
        const { loading, hasUnsavedChanges, validationReport, currentTabSelected, selectedProduct } = this.state;
        
        if (loading) {
            return <Loading />
        }

        return (
            <div style={{ margin: '20px 0' }}>
                <NavigationPrompt when={hasUnsavedChanges} 
                    navigate={path => this.props.history.push(path)}
                    initiateSave={() => {}}
                    shouldBlockNavigation={location => {
                        if (hasUnsavedChanges) {
                            return true;
                        }
                        return false;
                    }}
                />
                <GridComponent id="product-grid" ref={ref => this.productGridRef = ref } dataSource={this.data} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings} toolbar={this.toolbarOptions}
                    selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both" editSettings={{}}
                    allowReordering={true} allowResizing={true} toolbarClick={this.onToolbarClick.bind(this)} resizeStop={this.onResizeStop.bind(this)}
                    actionComplete={this.onActionComplete.bind(this)} columnDragStart={this.onColumnDragStart.bind(this)} created={this.onCreated.bind(this)}>
                    <ColumnsDirective>
                        <ColumnDirective type='checkbox' width='30' textAlign="Center"></ColumnDirective>
                        <ColumnDirective field="Id" headerText="Billede" template={this.imageTemplate} isPrimaryKey={true} />
                        <ColumnDirective field="ProductNo" headerText="Produkt Nr." />
                        <ColumnDirective field="Description1" headerText="Beskrivelse" template={this.descriptionTemplate} />
                        <ColumnDirective field="EAN" headerText="EAN" />
                        <ColumnDirective field="PrimarySupplierName" headerText="Primære Leverandør" />
                        <ColumnDirective field="PrimarySupplierProductNo" headerText="Primære Leverandør Produkt Nr." width='125' />
                        <ColumnDirective field="Department" headerText="Afdeling" />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Selection, Toolbar, Search, Reorder, Resize]} />
                </GridComponent>
                <div>
                    <DialogComponent isModal={true} width='auto' ref={dialog => this.productImportDialog = dialog} target='body'visible={false} showCloseIcon={true} header="Import Produkter" 
                        cssClass="import-dialog" animationSettings={this.animationSettings} allowDragging={true} enableResize={true}>
                            <div className="dialog-content">
                                <TabComponent ref={ref => this.productImportWizardTab = ref} className="tab-control" heightAdjustMode="Content" selecting={this.onSelect.bind(this)}>
                                    <TabItemsDirective>
                                        <TabItemDirective header={this.importTabHeader[0]} content={this.fileSelectTab.bind(this)} />
                                        <TabItemDirective header={this.importTabHeader[1]} content={this.reviewDataTab.bind(this)} disabled={true} />
                                        <TabItemDirective header={this.importTabHeader[2]} content={this.finalizeImportTab.bind(this)} disabled={true} />
                                    </TabItemsDirective>
                                </TabComponent>
                            </div>
                    </DialogComponent>
                </div>
                <div>
                    <DialogComponent isModal={true} width='auto' ref={dialog => this.validationReportDialog = dialog} target='body'visible={false} showCloseIcon={true} header="Rapport over Ændringer" 
                        cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} buttons={this.notificationButtons}>
                            <div>
                                <table className="table" style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th>Row #</th>
                                            <th className="text-left">Validation Messages</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            validationReport.map(validation => {
                                                return (
                                                    <tr className="changelog-row">
                                                        <td className="text-center">{validation.Id}</td>
                                                        <td className="required">
                                                            {validation.Messages.join(', ')}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                    </DialogComponent>
                </div>
                <div>
                    <DialogComponent id="other-settings-dialog" isModal={true} width='auto' ref={dialog => this.otherSettingsDialog = dialog} target='body'visible={false} showCloseIcon={true} header="Øvrige indstillinger" 
                        animationSettings={this.animationSettings} allowDragging={true} enableResize={true} buttons={this.otherSettingsButtons} close={() => this.setState({ selectedProduct: {}, currentTabSelected: 0 })}>
                            <div className="import-other-settings-wrapper">
                                <Tabs className="c-tab" selectedIndex={currentTabSelected} disabledTabClassName="c-tabs__tab--disabled" onSelect={this.onTabSelect}>
                                    <TabList className="c-tab__list">
                                        <Tab className={`c-tabs__tab`} selectedClassName="c-tabs__tab--selected">Produkt Grupper</Tab>
                                        <Tab className={`c-tabs__tab`} selectedClassName="c-tabs__tab--selected">Leverandør</Tab>
                                        <Tab className={`c-tabs__tab`} selectedClassName="c-tabs__tab--selected">Billeder</Tab>
                                        <Tab className={`c-tabs__tab`} selectedClassName="c-tabs__tab--selected">Generelle Indstillinger</Tab>
                                    </TabList>
                                    <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                                        <div className="tab-control--item">
                                            <div className="product-group-card-wrapper">
                                                <Card headerText="Tildel primær produkt gruppe" subText="Klik på '>' for tilhørende funktioner">
                                                    <TreeViewComponent id="product-group-tab" ref={ref => this.primaryProductGroupRef = ref} fields={this.primaryProductGroupFields} showCheckBox={true}
                                                        nodeChecked={this.onPrimaryProductGroupChecked.bind(this)} autoCheck={false} />
                                                </Card>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                                        <div className="tab-control--item">
                                            <GridComponent id="product-tools-supplier-grid" ref={ref => this.productSupplierGridRef = ref } dataSource={this.productSupplierData} allowPaging={true} allowSorting={true} width="100%"
                                                pageSettings={this.pageSettings} toolbar={this.supplierToolbarOptions} selectionSettings={{type: 'Single'}} allowFiltering={true} filterSettings={this.filterSettings} locale="da" 
                                                gridLines="Both" editSettings={this.supplierEditSettings} actionComplete={this.onSupplierActionComplete.bind(this)} actionBegin={this.onSupplierActionBegin.bind(this)} allowResizing={true}>
                                                <ColumnsDirective>
                                                    <ColumnDirective headerText="Leverandør Nr." field="Supplier.SupplierNo" isPrimaryKey={true} />
                                                    <ColumnDirective headerText="Leverandør" field="Supplier.Company" allowEditing={false}/>
                                                    <ColumnDirective headerText="Lev Produkt#" field="SupplierProductNo" />
                                                    <ColumnDirective headerText="Lev EAN #" field="SupplierEAN" />
                                                    <ColumnDirective headerText="Lev produkt navn" field="SupplierProductDesc" />
                                                    <ColumnDirective headerText="Leverer i antal på" field="DeliveryQty" />
                                                    <ColumnDirective headerText="Indkøbspris / stk." field="PurchasePrice" />
                                                    <ColumnDirective headerText="Prioitet" field="Priority" />
                                                </ColumnsDirective>
                                                <Inject services={[Page, Sort, Filter, Selection, Toolbar, Search, Edit, Resize]} />
                                            </GridComponent>
                                        </div>
                                    </TabPanel>
                                    <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                                        <div className="tab-control--item">
                                            <GridComponent ref={ref => this.ProductPicturesGridRef = ref } dataSource={this.picturesData} toolbar={this.imageToolbarOptions} editSettings={this.defaultEditSettings} width="100%"
                                                selectionSettings={{type: 'Single'}} locale="da" gridLines="Both" toolbarClick={this.clickHandler.bind(this)} allowTextWrap={true}
                                                actionComplete={this.onProductImagesActionComplete.bind(this)}>
                                                <ColumnsDirective>
                                                    <ColumnDirective field="ImageKey" headerText="Billede" width='100' textAlign="center" template={this.singleImageTemplate} />
                                                    <ColumnDirective field="ImageKey" headerText="Fil Sti" width='100' textAlign="center" template={this.imagePathTemplate} />
                                                    <ColumnDirective field="ImageKey" headerText="Fil Navn" width='100' textAlign="center" template={this.imageNameTemplate} />
                                                    <ColumnDirective field="IsPrimary" headerText="Primær" width='100' textAlign="center" template={this.primaryTemplate.bind(this)} /> 
                                                </ColumnsDirective>
                                                <Inject services={[Selection, Toolbar ]} />
                                            </GridComponent>
                                            <DialogComponent id="product-pictures-dialog" isModal={true} visible={false} ref={dialog => this.fileArchiveDialog = dialog} width="686px"
                                                target='body' animationSettings={this.animationSettings} allowDragging={true} enableResize={true} header="Tildel Billede til produkt">
                                                {/* <h3>Tildel Billede til produkt</h3> */}
                                                <div style={{marginBottom: 10}}></div>
                                                <small>Klik på + for tilhørende funktioner</small>
                                                <div className="dialog-content">
                                                    <TreeViewComponent ref={ref => this.fileImagesRef = ref} id="product-picture-treeview" fields={this.productPicturesFields} showCheckBox={true} autoCheck={false}
                                                        nodeClicked={this.pictureNodeCheck} />
                                                </div>
                                                <div className="dialog-footer">
                                                    <button className="e-control e-btn e-lib" onClick={() => this.fileArchiveDialog.hide()}>Annuller</button>
                                                    <button className="e-control e-btn e-lib e-primary" onClick={this.onFileImageSelected}>Gem</button>
                                                </div>
                                            </DialogComponent>
                                        </div>
                                    </TabPanel>
                                    <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                                        <div className="tab-control--item import-product-general-settings">
                                            <div>
                                                <Card headerText="Sags nummer styring" className="mb-15">
                                                    <div className="field-bordered">
                                                        <label htmlFor="ActiveCaseNumber">Aktiv</label>
                                                        <CheckBoxComponent id="ActiveCaseNumber" ref={ref => this.ActiveCaseNumberRef = ref} checked={selectedProduct.ActiveCaseNumber} name="ActiveCaseNumber" cssClass="control-right" change={this.onActiveCaseNumberChange} />
                                                    </div>
                                                </Card>
                                                <Card headerText="Tillæg til tolerance ved indkøb">
                                                    <div className="field-bordered purchase-tolerance-field">
                                                        <label htmlFor="PurchaseTolerance">Indtast værdi</label>
                                                        <NumberFormat ref={ref => this.PurchaseToleranceRef = ref} type="text" decimalScale={2} fixedDecimalScale={true} suffix="%" 
                                                                className={`control-right e-input input-text-right`} value={selectedProduct.PurchaseTolerance} name="PurchaseTolerance" 
                                                                onValueChange={(values) => this.onValueChange(values, "PurchaseTolerance")} />
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </Tabs>
                            </div>
                    </DialogComponent>
                </div>
            </div>
        )
    }
}

export default withRouter(ProductImportExport);
