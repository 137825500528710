import React, { Component } from 'react';
import Axios from 'axios';
import QueryString from 'query-string';

import Loading from '../components/Loading';
import OrderService from '../services/Order/OrderService';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class ModuleOilmatOrder extends Component {
    constructor() {
        super();

        this.state = {
            loading: true,
            status: {
                success: true,
                message: ''
            }
        }
    }

    async componentDidMount() {
        source.cancel();
        source = CancelToken.source();

        const { location } = this.props;
        const { status } = this.state;

        let type = '', payload = {}, queryparams = {};

        if (location.search) {
            let queryString = location.search.replace('?', '').split('&');
            for (let index = 0; index < queryString.length; index++) {
                let val = QueryString.parse(queryString[index]);
                Object.assign(queryparams, val);
            }
        }

        payload = {
            Id: parseInt(queryparams.orderId),
            Key: queryparams.key,
            Approve: queryparams.approve === "true" ? true: false,
            UserId: queryparams.userId
        };

        const result = await OrderService.OilmatOrderAction(queryparams.type, payload, source.token)

        if (!result.HasError) {
            status.success = true;
            status.message = result;
        }
        else {
            status.success = false;
            status.message = result.ErrorMessage;
        }

        this.setState({ loading: false });
    }

    render() {
        const { loading, status } = this.state;
        
        if (loading) {
            return <Loading />
        }

        return (
            <div className="approvals-message-wrapper">
                <div className={`approvals-message-container text-center ${status.success ? 'is-success' : 'error-label'}`}>
                    <h1>
                        { status.success ?  'Udført' : 'Advarsel' }
                    </h1>
                    <div className="message-content" dangerouslySetInnerHTML={{__html: status.message}}></div>
                </div>
            </div>
        )
    }
}

export default ModuleOilmatOrder;
