import React from 'react';
// import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { Redirect } from 'react-router-dom';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Axios from 'axios';

import SettingsService from '../../services/SettingsService';
import BaseComponent from '../../components/BaseComponent';
import Breadcrumb from '../../components/Breadcrumb';
import ShelfWeight from './Weight/ShelfWeight';
import BoxWeight from './Weight/BoxWeight';
import DrawerWeight from './Weight/DrawerWeight';
import '../../styles/systemsettings.scss';
import Loading from '../../components/Loading';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class Weight extends BaseComponent {
    moduleName = "System indstillinger";
    pageName = "Vægt";
    
    constructor() {
        super();

        this.state = {
            loading: true,
            breadcrumbs: ['Vægt', 'Hyldevægt'],
            dialogOpts: {
                header: '',
                content: null,
                visible: false
            },
            isTestMachine: false,
            weightUnit: '',
            redirect: {
                to: '',
                isRedirect: false
            },
            currentTabSelected: 0
        };

        this.tabHeader = [ {text: 'Hyldevægt'}, 
            {text: 'Skabsvægt'}, 
            {text: 'Skuffevægt'}
        ];

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.buttons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.dialogInstance.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        /* End Dialog options  */

        this.dialogOpen = this.dialogOpen.bind(this);
        this.onTestMachineSelected = this.onTestMachineSelected.bind(this);
        this.onTabSelected = this.onTabSelected.bind(this);
        this.shelfWeightTab = this.shelfWeightTab.bind(this);
        this.boxWeightTab = this.boxWeightTab.bind(this);
        this.drawerWeightTab = this.drawerWeightTab.bind(this);
    }

    async componentDidMount() {
        super.componentDidMount();
        source.cancel();
        source = CancelToken.source();

        if (!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }
        else if (!super.hasMenuItem('Vægt')) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }
        
        const unitsResult = await SettingsService.GetUnitSettings(source.token);
        let { weightUnit } = this.state;
        const weight = await SettingsService.GetWeights(source.token);
        if (weight) {
            weightUnit = weight.find(x => x.Value === unitsResult.WeightUnit);
        }
        
        this.setState({ loading: false, weightUnit: weightUnit.Key });
    }

    componentWillUnmount() {
        source.cancel();
    }

    shelfWeightTab() {
        const { weightUnit } = this.state;
        return <ShelfWeight onNotify={this.dialogOpen} onTestMachineSelected={this.onTestMachineSelected} weightUnit={weightUnit} />
    }

    boxWeightTab() {
        const { weightUnit } = this.state;
        return <BoxWeight onNotify={this.dialogOpen} onTestMachineSelected={this.onTestMachineSelected} weightUnit={weightUnit} />
    }

    drawerWeightTab() {
        const { weightUnit } = this.state;
        return <DrawerWeight onNotify={this.dialogOpen} onTestMachineSelected={this.onTestMachineSelected} weightUnit={weightUnit} />
    }

    tabSelected(args) {
        const { breadcrumbs } = this.state;
        if (breadcrumbs.length > 1) {
            breadcrumbs.pop();
        }
        breadcrumbs.push(this.tabHeader[args.selectedIndex].text);

        const headerText = this.tabHeader[args.selectedIndex].text;
        let headerElement = document.querySelector('.module-header > h1');
        headerElement.innerHTML = headerText;

        this.setState({ breadcrumbs, isTestMachine: false });
    }

    onTabSelected(index) {
        const { breadcrumbs } = this.state;
        if (breadcrumbs.length > 1) {
            breadcrumbs.pop();
        }
        breadcrumbs.push(this.tabHeader[index].text);

        const headerText = this.tabHeader[index].text;
        let headerElement = document.querySelector('.module-header > h1');
        headerElement.innerHTML = headerText;
        
        this.setState({ breadcrumbs, isTestMachine: false, currentTabSelected: index });
    }

    onTestMachineSelected(value) {
        this.setState({ isTestMachine: value });
    }

    dialogOpen({header, content}, callback) {
        this.dialogInstance.header = header;
        this.dialogInstance.content = content;
        this.dialogInstance.buttons = [
            {
                click: () => {
                    this.dialogInstance.hide();
                    if (callback) {
                        callback();
                    }
                },
                buttonModel: {
                    isPrimary: true,
                    content: 'OK'
                }
            }
        ]
        this.dialogInstance.show();
    }

    render() {
        const { loading, dialogOpts, breadcrumbs, isTestMachine, redirect, currentTabSelected } = this.state;

        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        return (
            <div className='content-pane-wrapper'>
                {/* <div>
                    <Breadcrumb breadcrumbs={breadcrumbs} />
                </div> */}
                <div className="module-header">
                    <h1>{this.tabHeader[0].text}</h1>
                </div>
                {
                    isTestMachine &&
                    <div><h2>Der vises Data for Test Automater</h2></div>
                }
                <div className='control-section tab-control'>
                    {/* <TabComponent ref={ref => this.userCardTabRef = ref} id='system-settings-weight-tab' selected={this.tabSelected.bind(this)} heightAdjustMode="Fill">
                        <TabItemsDirective>
                            <TabItemDirective header={this.tabHeader[0]} content={this.shelfWeightTab.bind(this)} />
                            <TabItemDirective header={this.tabHeader[1]} content={this.boxWeightTab.bind(this)} />
                            <TabItemDirective header={this.tabHeader[2]} content={this.drawerWeightTab.bind(this)} />
                        </TabItemsDirective>
                    </TabComponent> */}
                    <Tabs id="system-settings-weight-tab" className="c-tab" selectedIndex={currentTabSelected} onSelect={index => this.onTabSelected(index)} disabledTabClassName="c-tabs__tab--disabled">
                        <TabList className="c-tab__list">
                            <Tab className={`c-tabs__tab`} selectedClassName="c-tabs__tab--selected">{this.tabHeader[0].text}</Tab>
                            <Tab className={`c-tabs__tab`} selectedClassName="c-tabs__tab--selected">{this.tabHeader[1].text}</Tab>
                            <Tab className={`c-tabs__tab`} selectedClassName="c-tabs__tab--selected">{this.tabHeader[2].text}</Tab>
                        </TabList>
                        <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                            {this.shelfWeightTab()}
                        </TabPanel>
                        <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                            {this.boxWeightTab()}
                        </TabPanel>
                        <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                            {this.drawerWeightTab()}
                        </TabPanel>
                    </Tabs>
                </div>
                <DialogComponent id="system-settings-weight-dialog" isModal={true} buttons={this.buttons} header={dialogOpts.header} width='auto' content={dialogOpts.content} 
                    ref={dialog => this.dialogInstance = dialog} target='body' visible={dialogOpts.visible} showCloseIcon={true} allowDragging={true} enableResize={true}
                    cssClass="dialog-notification" animationSettings={this.animationSettings} />
            </div>
        )
    }
}

export default Weight;