import HttpClient from '../../utilities/HttpClient';

const API = {
    getProducts: `/oilmatProduct/{customer_id}/byCustomer`,
    postProduct: `/oilmatProduct/update/{customer_id}/product/{product_id}`
};

class OilmatProductService {
    async GetProducts(customerId, cancelToken) {
        const url = API.getProducts.replace("{customer_id}", customerId);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async UpdateProduct(customerId, productId, data, cancelToken) {
        const url = API.postProduct.replace("{customer_id}", customerId).replace("{product_id}", productId);
        return await HttpClient.Put(url, data, cancelToken);
    }
}

const oilmatProductService = new OilmatProductService();
export default oilmatProductService;