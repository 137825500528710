export function getPage(entity, parentId) {
    if (parentId) {
        if (entity.routeUrl.includes("{id}")) {
            entity.routeUrl = entity.routeUrl.replace("{id}", parentId);
        }
    }
    else {
        if (entity.routeUrl.includes("{id}")) {
            entity.routeUrl = entity.routeUrl.replace("{id}", '');
        }
    }

    return entity;
}

export function MapEntityToPage(entityTypes) {
    entityTypes.forEach(e => {
        switch(e.Value) {
            case 1: 
                e.routeUrl = '/produktkort/edit/{id}?tab=0';
                break;
            case 2:
                e.routeUrl = '/indkob/ordre-detaljer/edit/{id}';
                break;
            case 3: 
                // check if possible to navigate file path
                e.routeUrl = '/fil-arkiv';
                break;
            case 4:
                e.routeUrl = '/beskeder';
                break;
            case 5: // No mapping
                break;
            case 6: 
                e.routeUrl = '/system-indstillinger/grupper/produkt-grupper';
                break;
            case 7: // No mapping
                break;
            case 8: 
                e.routeUrl = '/produktkort/edit/{id}?tab=3';
                break;
            case 9: 
                e.routeUrl = '/system-indstillinger/integration';
                break;
            case 10: 
                e.routeUrl = '/leverandor/edit/{id}';
                break;
            case 11: 
                e.routeUrl = '/system-indstillinger/vægt';
                break;
            case 12: 
                e.routeUrl = '/system-indstillinger/vægt';
                break;
            case 13: 
                e.routeUrl = '/system-indstillinger/vægt';
                break;
            case 14: 
                e.routeUrl = '/system-indstillinger/enheder';
                break;
            case 15: // No mapping
                break;
            case 16: 
                e.routeUrl = '/indkobs-indstillinger';
                break;
            case 17: 
                e.routeUrl = '/system-indstillinger/mailindstillinger';
                break;
            case 18: // No mapping
                break;
            case 19: 
                e.routeUrl = '/automater/configurer/edit/{id}';
                break;
            case 20:  // No mapping
                break;
            case 21: 
                e.routeUrl = '/automater/configurer/edit/{id}?tab=3';
                break;
            case 22:  // No mapping
                break;
            case 23: 
                e.routeUrl = '/produktkort/edit/{id}?tab=5';
                break;
            case 24: 
                // TODO: add id
                e.routeUrl = '/kunder';
                break;
            case 25: 
                // TODO: add tab
                e.routeUrl = '/min-konto';
                break;
            case 26:  // No mapping
                break;
            case 27:  // No mapping
                break;
            case 28: 
                e.routeUrl = '/rapporter/bruger-defineret';
                break;
            case 29: 
                e.routeUrl = '/brugerkort/edit/{id}';
                break;
            case 30: 
                e.routeUrl = '/system-indstillinger/grupper/brugergrupper';
                break;
            case 31: 
                e.routeUrl = '/system-indstillinger/grupper/kundegruppe';
                break;

        }
    });
    return entityTypes;
}