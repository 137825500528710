import FMMaster_09_00_00 from '../../assets/machines/modules/module_09_00_00_master.png';
import FMSlave_09_00_00 from '../../assets/machines/modules/module_09_00_00_slave.png';

import FMMaster_05_04_02 from '../../assets/machines/modules/module_05_04_02_master.png';
import FMSlave_05_04_02 from '../../assets/machines/modules/module_05_04_02_slave.png';

import FMMaster_03_10_00 from '../../assets/machines/modules/module_03_10_00_master.png';
import FMSlave_03_10_00 from '../../assets/machines/modules/module_03_10_00_slave.png';

import FMMaster_03_08_01 from '../../assets/machines/modules/module_03_08_01_master.png';
import FMSlave_03_08_01 from '../../assets/machines/modules/module_03_08_01_slave.png';

import FMMaster_03_07_02 from '../../assets/machines/modules/module_03_07_02_master.png';
import FMSlave_03_07_02 from '../../assets/machines/modules/module_03_07_02_slave.png';

import FMMaster_03_04_04 from '../../assets/machines/modules/module_03_04_04_master.png';
import FMSlave_03_04_04 from '../../assets/machines/modules/module_03_04_04_slave.png';

import FMMaster_03_03_05 from '../../assets/machines/modules/module_03_03_05_master.png';
import FMSlave_03_03_05 from '../../assets/machines/modules/module_03_03_05_slave.png';

import FMMaster_03_01_06 from '../../assets/machines/modules/module_03_01_06_master.png';
import FMSlave_03_01_06 from '../../assets/machines/modules/module_03_01_06_slave.png';

import FMMaster_03_00_07 from '../../assets/machines/modules/module_03_00_07_master.png';
import FMSlave_03_00_07 from '../../assets/machines/modules/module_03_00_07_slave.png';


import Model_FMMaster_09_00_00 from '../../assets/machines/modules/model/model_FM-M-09-00-00.png';
import Model_FMSlave_09_00_00 from '../../assets/machines/modules/model/model_FM-S-09-00-00.png';

import Model_FMMaster_05_04_02 from '../../assets/machines/modules/model/model_FM-M-05-04-02.png';
import Model_FMSlave_05_04_02 from '../../assets/machines/modules/model/model_FM-S-05-04-02.png';

import Model_FMMaster_03_10_00 from '../../assets/machines/modules/model/model_FM-M-03-10-00.png';
import Model_FMSlave_03_10_00 from '../../assets/machines/modules/model/model_FM-S-03-10-00.png';

import Model_FMMaster_03_08_01 from '../../assets/machines/modules/model/model_FM-M-03-08-01.png';
import Model_FMSlave_03_08_01 from '../../assets/machines/modules/model/model_FM-S-03-08-01.png';

import Model_FMMaster_03_07_02 from '../../assets/machines/modules/model/model_FM-M-03-07-02.png';
import Model_FMSlave_03_07_02 from '../../assets/machines/modules/model/model_FM-S-03-07-02.png';

import Model_FMMaster_03_04_04 from '../../assets/machines/modules/model/model_FM-M-03-04-04.png';
import Model_FMSlave_03_04_04 from '../../assets/machines/modules/model/model_FM-S-03-04-04.png';

import Model_FMMaster_03_03_05 from '../../assets/machines/modules/model/model_FM-M-03-03-05.png';
import Model_FMSlave_03_03_05 from '../../assets/machines/modules/model/model_FM-S-03-03-05.png';

import Model_FMMaster_03_01_06 from '../../assets/machines/modules/model/model_FM-M-03-01-06.png';
import Model_FMSlave_03_01_06 from '../../assets/machines/modules/model/model_FM-S-03-01-06.png';

import Model_FMMaster_03_00_07 from '../../assets/machines/modules/model/model_FM-M-03-00-07.png';
import Model_FMSlave_03_00_07 from '../../assets/machines/modules/model/model_FM-S-03-00-07.png';

function getMachineImage(key, isModel = false) {
    switch(key) {
        case 'FM-M-09-00-00': 
            if (isModel) {
                return Model_FMMaster_09_00_00;
            }
            return FMMaster_09_00_00;
        case 'FM-S-09-00-00':
            if (isModel) {
                return Model_FMSlave_09_00_00;
            }
            return FMSlave_09_00_00;

        case 'FM-M-05-04-02': 
            if (isModel) {
                return Model_FMMaster_05_04_02;
            }
            return FMMaster_05_04_02;
        case 'FM-S-05-04-02': 
            if (isModel) {
                return Model_FMSlave_05_04_02;
            }
            return FMSlave_05_04_02;

        case 'FM-M-03-10-00': 
            if (isModel) {
                return Model_FMMaster_03_10_00;
            }
            return FMMaster_03_10_00;
        case 'FM-S-03-10-00': 
            if (isModel) {
                return Model_FMSlave_03_10_00;
            }
            return FMSlave_03_10_00;

        case 'FM-M-03-08-01': 
            if (isModel) {
                return Model_FMMaster_03_08_01;
            }
            return FMMaster_03_08_01;
        case 'FM-S-03-08-01': 
            if (isModel) {
                return Model_FMSlave_03_08_01;
            }
            return FMSlave_03_08_01;

        case 'FM-M-03-07-02': 
            if (isModel) {
                return Model_FMMaster_03_07_02;
            }
            return FMMaster_03_07_02;
        case 'FM-S-03-07-02': 
            if (isModel) {
                return Model_FMSlave_03_07_02;
            }
            return FMSlave_03_07_02;

        case 'FM-M-03-04-04': 
            if (isModel) {
                return Model_FMMaster_03_04_04;
            }
            return FMMaster_03_04_04;
        case 'FM-S-03-04-04': 
            if (isModel) {
                return Model_FMSlave_03_04_04;
            }
            return FMSlave_03_04_04;

        case 'FM-M-03-03-05': 
            if (isModel) {
                return Model_FMMaster_03_03_05;
            }
            return FMMaster_03_03_05;
        case 'FM-S-03-03-05': 
            if (isModel) {
                return Model_FMSlave_03_03_05;
            }
            return FMSlave_03_03_05;

        case 'FM-M-03-01-06': 
            if (isModel) {
                return Model_FMMaster_03_01_06;
            }
            return FMMaster_03_01_06;
        case 'FM-S-03-01-06': 
            if (isModel) {
                return Model_FMSlave_03_01_06;
            }
            return FMSlave_03_01_06;

        case 'FM-M-03-00-07': 
            if (isModel) {
                return Model_FMMaster_03_00_07;
            }
            return FMMaster_03_00_07;
        case 'FM-S-03-00-07': 
            if (isModel) {
                return Model_FMSlave_03_00_07;
            }
            return FMSlave_03_00_07;
        default:
            return null;
    }
}

const predefinedModules = [
    {
        name: "FM-M-09-00-00",
        Shelves: [
            { SlotNo: 0, Coords: "13,64,138,80", IsEditable: true, ShelfType: 3 }, 
            { SlotNo: 1, Coords: "13,119,138,135", IsEditable: false, ShelfType: 3 }, 
            { SlotNo: 2, Coords: "", IsEditable: false, ShelfType: 6 }, 
            { SlotNo: 3, Coords: "13,230,138,245", IsEditable: true, ShelfType: 3 }, 
            { SlotNo: 4, Coords: "13,285,138,300", IsEditable: true, ShelfType: 3 }, 
            { SlotNo: 5, Coords: "13,340,138,355", IsEditable: true, ShelfType: 3 }, 
            { SlotNo: 6, Coords: "13,395,138,410", IsEditable: true, ShelfType: 3 }, 
            { SlotNo: 7, Coords: "13,450,138,465", IsEditable: true, ShelfType: 3 },
            { SlotNo: 8, Coords: "13,505,138,520", IsEditable: false, ShelfType: 3 }
        ]
    },
    {
        name: "FM-S-09-00-00",
        Shelves: [
            { SlotNo: 0, Coords: "13,64,138,80", IsEditable: true, ShelfType: 3 }, 
            { SlotNo: 1, Coords: "13,119,138,135", IsEditable: true, ShelfType: 3 }, 
            { SlotNo: 2, Coords: "13,174,138,190", IsEditable: true, ShelfType: 3 }, 
            { SlotNo: 3, Coords: "13,230,138,245", IsEditable: true, ShelfType: 3 }, 
            { SlotNo: 4, Coords: "13,285,138,300", IsEditable: true, ShelfType: 3 },
            { SlotNo: 5, Coords: "13,340,138,355", IsEditable: true, ShelfType: 3 }, 
            { SlotNo: 6, Coords: "13,395,138,410", IsEditable: true, ShelfType: 3 }, 
            { SlotNo: 7, Coords: "13,450,138,465", IsEditable: true, ShelfType: 3 }, 
            { SlotNo: 8, Coords: "13,505,138,520", IsEditable: false, ShelfType: 3 }
        ]
    },
    {
        name: "FM-M-05-04-02",
        Shelves: [
            { SlotNo: 0, Coords: "13,64,138,80", IsEditable: true, ShelfType: 3 },
            { SlotNo: 1, Coords: "13,119,138,135", IsEditable: false, ShelfType: 3 },
            { SlotNo: 2, Coords: "", IsEditable: true, ShelfType: 6 },
            { SlotNo: 3, Coords: "13,230,138,245", IsEditable: true, ShelfType: 3 }, 
            { SlotNo: 4, Coords: "13,285,138,300", IsEditable: false, ShelfType: 3 }, 
            { SlotNo: 5, Coords: "13,325,138,332", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 6, Coords: "13,357,138,364", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 7, Coords: "13,389,138,396", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 8, Coords: "13,421,138,428", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 9, Coords: "13,440,138,480", IsEditable: false, ShelfType: 5 }, 
            { SlotNo: 10, Coords: "13,498,138,538", IsEditable: false, ShelfType: 5 }
        ]
    },
    {
        name: "FM-S-05-04-02",
        Shelves: [
            { SlotNo: 0, Coords: "13,64,138,80", IsEditable: true, ShelfType: 3 }, 
            { SlotNo: 1, Coords: "13,119,138,135", IsEditable: true, ShelfType: 3 }, 
            { SlotNo: 2, Coords: "13,174,138,190", IsEditable: true, ShelfType: 3 }, 
            { SlotNo: 3, Coords: "13,230,138,245", IsEditable: true, ShelfType: 3 }, 
            { SlotNo: 4, Coords: "13,285,138,300", IsEditable: false, ShelfType: 3 }, 
            { SlotNo: 5, Coords: "13,325,138,332", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 6, Coords: "13,357,138,364", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 7, Coords: "13,389,138,396", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 8, Coords: "13,421,138,428", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 9, Coords: "13,440,138,480", IsEditable: false, ShelfType: 5 }, 
            { SlotNo: 10, Coords: "13,498,138,538", IsEditable: false, ShelfType: 5 }
        ]
    },
    {
        name: "FM-M-03-10-00",
        Shelves: [
            { SlotNo: 0, Coords: "13,64,138,80", IsEditable: true, ShelfType: 3 }, 
            { SlotNo: 1, Coords: "13,119,138,135", IsEditable: false, ShelfType: 3 }, 
            { SlotNo: 2, Coords: "", IsEditable: false, ShelfType: 6 }, 
            { SlotNo: 3, Coords: "13,220,138,227", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 4, Coords: "13,250,138,257", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 5, Coords: "13,283,138,290", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 6, Coords: "13,316,138,323", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 7, Coords: "13,349,138,356", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 8, Coords: "13,384,138,391", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 9, Coords: "13,421,138,428", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 10, Coords: "13,453,138,460", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 11, Coords: "13,485,138,492", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 12, Coords: "13,520,138,537", IsEditable: false, ShelfType: 4 }
        ]
    },
    {
        name: "FM-S-03-10-00",
        Shelves: [
            { SlotNo: 0, Coords: "13,64,138,80", IsEditable: true, ShelfType: 3 }, 
            { SlotNo: 1, Coords: "13,119,138,135", IsEditable: true, ShelfType: 3 }, 
            { SlotNo: 2, Coords: "13,174,138,190", IsEditable: false, ShelfType: 3 }, 
            { SlotNo: 3, Coords: "13,220,138,227", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 4, Coords: "13,250,138,257", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 5, Coords: "13,283,138,290", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 6, Coords: "13,316,138,323", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 7, Coords: "13,349,138,356", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 8, Coords: "13,384,138,391", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 9, Coords: "13,421,138,428", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 10, Coords: "13,453,138,460", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 11, Coords: "13,485,138,492", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 12, Coords: "13,520,138,537", IsEditable: false, ShelfType: 4 }
        ]
    },
    {
        name: "FM-M-03-08-01",
        Shelves: [
            { SlotNo: 0, Coords: "13,64,138,80", IsEditable: true, ShelfType: 3 },
            { SlotNo: 1, Coords: "13,119,138,135", IsEditable: false, ShelfType: 3 },
            { SlotNo: 2, Coords: "", IsEditable: false, ShelfType: 6 },
            { SlotNo: 3, Coords: "13,220,138,227", IsEditable: false, ShelfType: 4 },
            { SlotNo: 4, Coords: "13,250,138,257", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 5, Coords: "13,283,138,290", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 6, Coords: "13,316,138,323", IsEditable: false, ShelfType: 4 },
            { SlotNo: 7, Coords: "13,349,138,356", IsEditable: false, ShelfType: 4 },
            { SlotNo: 8, Coords: "13,384,138,391", IsEditable: false, ShelfType: 4 }, 
            { SlotNo: 9, Coords: "13,421,138,428", IsEditable: false, ShelfType: 4 },
            { SlotNo: 10, Coords: "13,453,138,460", IsEditable: false, ShelfType: 4 },
            { SlotNo: 11, Coords: "13,480,138,523", IsEditable: false, ShelfType: 5 }
        ]
    },
    {
        name: "FM-S-03-08-01",
        Shelves: [
            { SlotNo: 0, Coords: "13,64,138,80", IsEditable: true, ShelfType: 3 },
            { SlotNo: 1, Coords: "13,119,138,135", IsEditable: true, ShelfType: 3 },
            { SlotNo: 2, Coords: "13,174,138,190", IsEditable: false, ShelfType: 3 },
            { SlotNo: 3, Coords: "13,220,138,227", IsEditable: false, ShelfType: 4 },
            { SlotNo: 4, Coords: "13,250,138,257", IsEditable: false, ShelfType: 4 },
            { SlotNo: 5, Coords: "13,283,138,290", IsEditable: false, ShelfType: 4 },
            { SlotNo: 6, Coords: "13,316,138,323", IsEditable: false, ShelfType: 4 },
            { SlotNo: 7, Coords: "13,349,138,356", IsEditable: false, ShelfType: 4 },
            { SlotNo: 8, Coords: "13,384,138,391", IsEditable: false, ShelfType: 4 },
            { SlotNo: 9, Coords: "13,421,138,428", IsEditable: false, ShelfType: 4 },
            { SlotNo: 10, Coords: "13,453,138,460", IsEditable: false, ShelfType: 4 },
            { SlotNo: 11, Coords: "13,480,138,523", IsEditable: false, ShelfType: 5 }
        ]
    },
    {
        name: "FM-M-03-07-02",
        Shelves: [
            { SlotNo: 0, Coords: "13,64,138,80", IsEditable: true, ShelfType: 3 },
            { SlotNo: 1, Coords: "13,119,138,135", IsEditable: false, ShelfType: 3 },
            { SlotNo: 2, Coords: "", IsEditable: false, ShelfType: 6 },
            { SlotNo: 3, Coords: "13,220,138,227", IsEditable: false, ShelfType: 4 },
            { SlotNo: 4, Coords: "13,250,138,257", IsEditable: false, ShelfType: 4 },
            { SlotNo: 5, Coords: "13,283,138,290", IsEditable: false, ShelfType: 4 },
            { SlotNo: 6, Coords: "13,316,138,323", IsEditable: false, ShelfType: 4 },
            { SlotNo: 7, Coords: "13,349,138,356", IsEditable: false, ShelfType: 4 },
            { SlotNo: 8, Coords: "13,384,138,391", IsEditable: false, ShelfType: 4 },
            { SlotNo: 9, Coords: "13,417,138,424", IsEditable: false, ShelfType: 4 },
            { SlotNo: 10, Coords: "13,435,138,472", IsEditable: false, ShelfType: 5 },
            { SlotNo: 11, Coords: "13,490,138,534", IsEditable: false, ShelfType: 5 }
        ]
    },
    {
        name: "FM-S-03-07-02",
        Shelves: [
            { SlotNo: 0, Coords: "13,64,138,80", IsEditable: true, ShelfType: 3 },
            { SlotNo: 1, Coords: "13,119,138,135", IsEditable: true, ShelfType: 3 },
            { SlotNo: 2, Coords: "13,174,138,190", IsEditable: false, ShelfType: 3 },
            { SlotNo: 3, Coords: "13,220,138,227", IsEditable: false, ShelfType: 4 },
            { SlotNo: 4, Coords: "13,250,138,257", IsEditable: false, ShelfType: 4 },
            { SlotNo: 5, Coords: "13,283,138,290", IsEditable: false, ShelfType: 4 },
            { SlotNo: 6, Coords: "13,316,138,323", IsEditable: false, ShelfType: 4 },
            { SlotNo: 7, Coords: "13,349,138,356", IsEditable: false, ShelfType: 4 },
            { SlotNo: 8, Coords: "13,384,138,391", IsEditable: false, ShelfType: 4 },
            { SlotNo: 9, Coords: "13,417,138,424", IsEditable: false, ShelfType: 4 },
            { SlotNo: 10, Coords: "13,435,138,472", IsEditable: false, ShelfType: 5 },
            { SlotNo: 11, Coords: "13,490,138,534", IsEditable: false, ShelfType: 5 }
        ]
    },
    {
        name: "FM-M-03-04-04",
        Shelves: [
            { SlotNo: 0, Coords: "13,64,138,80", IsEditable: true, ShelfType: 3 },
            { SlotNo: 1, Coords: "13,119,138,135", IsEditable: false, ShelfType: 3 },
            { SlotNo: 2, Coords: "", IsEditable: false, ShelfType: 6 },
            { SlotNo: 3, Coords: "13,220,138,227", IsEditable: false, ShelfType: 4 },
            { SlotNo: 4, Coords: "13,250,138,257", IsEditable: false, ShelfType: 4 },
            { SlotNo: 5, Coords: "13,283,138,290", IsEditable: false, ShelfType: 4 },
            { SlotNo: 6, Coords: "13,316,138,323", IsEditable: false, ShelfType: 4 },
            { SlotNo: 7, Coords: "13,334,138,374", IsEditable: false, ShelfType: 5 },
            { SlotNo: 8, Coords: "13,384,138,424", IsEditable: false, ShelfType: 5 },
            { SlotNo: 9, Coords: "13,432,138,472", IsEditable: false, ShelfType: 5 },
            { SlotNo: 10, Coords: "13,490,138,534", IsEditable: false, ShelfType: 5 }
        ]
    },
    {
        name: "FM-S-03-04-04",
        Shelves: [
            { SlotNo: 0, Coords: "13,64,138,80", IsEditable: true, ShelfType: 3 },
            { SlotNo: 1, Coords: "13,119,138,135", IsEditable: true, ShelfType: 3 },
            { SlotNo: 2, Coords: "13,174,138,190", IsEditable: false, ShelfType: 3 },
            { SlotNo: 3, Coords: "13,220,138,227", IsEditable: false, ShelfType: 4 },
            { SlotNo: 4, Coords: "13,250,138,257", IsEditable: false, ShelfType: 4 },
            { SlotNo: 5, Coords: "13,283,138,290", IsEditable: false, ShelfType: 4 },
            { SlotNo: 6, Coords: "13,316,138,323", IsEditable: false, ShelfType: 4 },
            { SlotNo: 7, Coords: "13,334,138,374", IsEditable: false, ShelfType: 5 },
            { SlotNo: 8, Coords: "13,384,138,424", IsEditable: false, ShelfType: 5 },
            { SlotNo: 9, Coords: "13,432,138,472", IsEditable: false, ShelfType: 5 },
            { SlotNo: 10, Coords: "13,490,138,534", IsEditable: false, ShelfType: 5 }
        ]
    },
    {
        name: "FM-M-03-03-05",
        Shelves: [
            { SlotNo: 0, Coords: "13,64,138,80", IsEditable: true, ShelfType: 3 },
            { SlotNo: 1, Coords: "13,119,138,135", IsEditable: false, ShelfType: 3 },
            { SlotNo: 2, Coords: "", IsEditable: false, ShelfType: 6 },
            { SlotNo: 3, Coords: "13,220,138,227", IsEditable: false, ShelfType: 4 },
            { SlotNo: 4, Coords: "13,250,138,257", IsEditable: false, ShelfType: 4 },
            { SlotNo: 5, Coords: "13,283,138,290", IsEditable: false, ShelfType: 4 },
            { SlotNo: 6, Coords: "13,298,136,338", IsEditable: false, ShelfType: 5 },
            { SlotNo: 7, Coords: "13,346,136,386", IsEditable: false, ShelfType: 5 },
            { SlotNo: 8, Coords: "13,394,136,434", IsEditable: false, ShelfType: 5 },
            { SlotNo: 9, Coords: "13,440,136,480", IsEditable: false, ShelfType: 5 },
            { SlotNo: 10, Coords: "13,497,136,537", IsEditable: false, ShelfType: 5 }
        ]
    },
    {
        name: "FM-S-03-03-05",
        Shelves: [
            { SlotNo: 0, Coords: "13,64,136,80", IsEditable: true, ShelfType: 3 },
            { SlotNo: 1, Coords: "13,119,136,135", IsEditable: true, ShelfType: 3 },
            { SlotNo: 2, Coords: "13,174,136,190", IsEditable: false, ShelfType: 3 },
            { SlotNo: 3, Coords: "13,220,136,227", IsEditable: false, ShelfType: 4 },
            { SlotNo: 4, Coords: "13,250,136,257", IsEditable: false, ShelfType: 4 },
            { SlotNo: 5, Coords: "13,283,136,290", IsEditable: false, ShelfType: 4 },
            { SlotNo: 6, Coords: "13,298,136,338", IsEditable: false, ShelfType: 5 },
            { SlotNo: 7, Coords: "13,346,136,386", IsEditable: false, ShelfType: 5 },
            { SlotNo: 8, Coords: "13,394,136,434", IsEditable: false, ShelfType: 5 },
            { SlotNo: 9, Coords: "13,440,136,480", IsEditable: false, ShelfType: 5 },
            { SlotNo: 10, Coords: "13,497,136,537", IsEditable: false, ShelfType: 5 }
        ]
    },
    {
        name: "FM-M-03-01-06",
        Shelves: [
            { SlotNo: 0, Coords: "13,64,136,80", IsEditable: true, ShelfType: 3 },
            { SlotNo: 1, Coords: "13,119,136,135", IsEditable: false, ShelfType: 3 },
            { SlotNo: 2, Coords: "", IsEditable: false, ShelfType: 6 },
            { SlotNo: 3, Coords: "13,217,136,224", IsEditable: false, ShelfType: 4 },
            { SlotNo: 4, Coords: "13,232,136,272", IsEditable: false, ShelfType: 5 },
            { SlotNo: 5, Coords: "13,284,136,324", IsEditable: false, ShelfType: 5 },
            { SlotNo: 6, Coords: "13,334,136,374", IsEditable: false, ShelfType: 5 },
            { SlotNo: 7, Coords: "13,384,136,424", IsEditable: false, ShelfType: 5 },
            { SlotNo: 8, Coords: "13,434,136,474", IsEditable: false, ShelfType: 5 },
            { SlotNo: 9, Coords: "13,497,136,537", IsEditable: false, ShelfType: 5 }
        ]
    },
    {
        name: "FM-S-03-01-06",
        Shelves: [
            { SlotNo: 0, Coords: "13,64,136,80", IsEditable: true, ShelfType: 3 },
            { SlotNo: 1, Coords: "13,119,136,135", IsEditable: true, ShelfType: 3 },
            { SlotNo: 2, Coords: "13,174,136,190", IsEditable: false, ShelfType: 3 },
            { SlotNo: 3, Coords: "13,217,136,224", IsEditable: false, ShelfType: 4 },
            { SlotNo: 4, Coords: "13,232,136,272", IsEditable: false, ShelfType: 5 },
            { SlotNo: 5, Coords: "13,284,136,324", IsEditable: false, ShelfType: 5 },
            { SlotNo: 6, Coords: "13,334,136,374", IsEditable: false, ShelfType: 5 },
            { SlotNo: 7, Coords: "13,384,136,424", IsEditable: false, ShelfType: 5 },
            { SlotNo: 8, Coords: "13,434,136,474", IsEditable: false, ShelfType: 5 },
            { SlotNo: 9, Coords: "13,497,136,537", IsEditable: false, ShelfType: 5 }
        ]
    },
    {
        name: "FM-M-03-00-07",
        Shelves: [
            { SlotNo: 0, Coords: "13,64,136,80", IsEditable: true, ShelfType: 3 },
            { SlotNo: 1, Coords: "13,119,136,135", IsEditable: false, ShelfType: 3 },
            { SlotNo: 2, Coords: "", IsEditable: false, ShelfType: 6 },
            { SlotNo: 3, Coords: "13,196,136,236", IsEditable: false, ShelfType: 5 },
            { SlotNo: 4, Coords: "13,244,136,284", IsEditable: false, ShelfType: 5 },
            { SlotNo: 5, Coords: "13,292,136,332", IsEditable: false, ShelfType: 5 },
            { SlotNo: 6, Coords: "13,341,136,381", IsEditable: false, ShelfType: 5 },
            { SlotNo: 7, Coords: "13,389,136,429", IsEditable: false, ShelfType: 5 },
            { SlotNo: 8, Coords: "13,437,136,477", IsEditable: false, ShelfType: 5 },
            { SlotNo: 9, Coords: "13,497,136,537", IsEditable: false, ShelfType: 5 }
        ]
    },
    {
        name: "FM-S-03-00-07",
        Shelves: [
            { SlotNo: 0, Coords: "13,64,136,80", IsEditable: true, ShelfType: 3 },
            { SlotNo: 1, Coords: "13,119,136,135", IsEditable: true, ShelfType: 3 },
            { SlotNo: 2, Coords: "13,174,136,190", IsEditable: false, ShelfType: 3 },
            { SlotNo: 3, Coords: "13,196,136,236", IsEditable: false, ShelfType: 5 },
            { SlotNo: 4, Coords: "13,244,136,284", IsEditable: false, ShelfType: 5 },
            { SlotNo: 5, Coords: "13,292,136,332", IsEditable: false, ShelfType: 5 },
            { SlotNo: 6, Coords: "13,341,136,381", IsEditable: false, ShelfType: 5 },
            { SlotNo: 7, Coords: "13,389,136,429", IsEditable: false, ShelfType: 5 },
            { SlotNo: 8, Coords: "13,437,136,477", IsEditable: false, ShelfType: 5 },
            { SlotNo: 9, Coords: "13,497,136,537", IsEditable: false, ShelfType: 5 }
        ]
    }
];

export { 
    predefinedModules,
    getMachineImage
};