import HttpClient from '../../utilities/HttpClient';

const API = {
    getProducts: `/product/{customer_id}/byCustomer`,
    getProduct: `/product/{product_id}`,
    getProductSuppliers: `/product/{product_id}/suppliers`,
    getProductStorages: `/product/{product_id}/storages`,
    getProductImages: `/product/{product_id}/images`,
    postInitialProduct: `/product/customer`,
    postProductDescription: `/product/desc`,
    postProductSizes: `/product/sizes`,
    postProductPrice: `/product/price`,
    postProductGroup: `/product/group`,
    postProductSettings: `/product/settings`,
    putPostProductPictures: `/productimage`,
    putPostProductStorage: `/stock`,
    putPostProductSuppliers: `/productsupplier`,
    deleteProductSuppliers: `/productsupplier/{supplier_id}`,
    deleteProduct: `/product/{product_id}`,
    deleteProductImage: `/productimage/{id}`,
    deleteProductStock: `/stock/{id}`,
    copyProduct: `/product/{id}/copy`,
    getFilteredProduct: '/product/{customer_id}/byCustomer/filter',

    putProductUndo: `/product/undo/{id}`,
    putProductRedo: `/product/redo/{id}`,
    putProductImageUndo: `/productimage/undo/{id}`,
    putProductImageRedo: `/productimage/redo/{id}`,
    putProductStorageUndo: `/stock/undo/{id}`,
    putProductStorageRedo: `/stock/redo/{id}`,
    putProductSupplierUndo: `/productsupplier/undo/{id}`,
    putProductSupplierRedo: `/productsupplier/redo/{id}`,

    getSupplierProducts: '/product/{customer_id}/byCustomer/bySupplier',
    getUndoRedoStatus: '/product/{productId}/undoRedoStatus/{tab}',

    postProductImage: '/productimage/save',
    postProductSupplier: '/productsupplier/save',
    postProductStorage: '/stock/save',

    postBulkSave: '/product/{product_id}/save',
    postProductToolBulkSave: '/product/bulkSave',
    postImportBulkSave: '/product/bulkSave/import'
}

class ProductService {
    async GetProducts(customerId, param, cancelToken) {
        const url = API.getProducts.replace('{customer_id}', customerId);
        let result = await HttpClient.Get(url, param, cancelToken);

        if (!result.HasError) {
            result = result.map(product => {
                let primaryProductSupplier = product.ProductSuppliers.find(x => x.Priority === 1);
                product.PrimarySupplierName = primaryProductSupplier ? primaryProductSupplier.Supplier.Company : '';
                product.PrimarySupplierProductNo = primaryProductSupplier ? primaryProductSupplier.SupplierProductNo : '';
                
                return {
                    ...product
                }
            });
        }

        return result;
    }

    async GetProduct(id, cancelToken) {
        const url = API.getProduct.replace("{product_id}", id);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async GetProductSuppliers(id, cancelToken) {
        const url = API.getProductSuppliers.replace("{product_id}", id);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async GetProductStorages(id, cancelToken) {
        const url = API.getProductStorages.replace("{product_id}", id);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async GetProductImages(id, cancelToken) {
        const url = API.getProductImages.replace("{product_id}", id);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async GetSupplierProducts(customerId, cancelToken) {
        const url = API.getSupplierProducts.replace("{customer_id}", customerId);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async GetUndoRedoStatus(id, tab, cancelToken) {
        const url = API.getUndoRedoStatus.replace("{productId}", id).replace("{tab}", tab);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async DeleteProduct(id, param, cancelToken) {
        const url = API.deleteProduct.replace('{product_id}', id);
        return await HttpClient.Delete(url, param, cancelToken);
    }

    async DeleteProductStock(id, cancelToken) {
        const url = API.deleteProductStock.replace('{id}', id);
        return await HttpClient.Delete(url, null, cancelToken);
    }

    async AddUpdateProductDescription(data, cancelToken) {
        let payload = {
            Id: data.Id,
            ProductNo: data.ProductNo,
            EAN: data.EAN.toString(),
            Description1:  data.Description1,
            Description2:  data.Description2,
            SalesUnit:  data.SalesUnit,
            SalesUnitPerPcs:  data.SalesUnitPerPcs,
            CustomerId: data.CustomerId
        };

        return await HttpClient.Post(API.postProductDescription, payload, cancelToken);
    }

    async AddUpdateProductSizes(data, cancelToken) {
        let payload = {
            Id: data.Id,
            Length: data.Length,
            Height: data.Height,
            Width:  data.Width,
            CustomVolume:  data.CustomVolume,
            Weight:  data.Weight,
            DimensionUnit:  data.DimensionUnit,
            WeightUnit: data.WeightUnit,
            CustomerId: data.CustomerId
        };

        return await HttpClient.Post(API.postProductSizes, payload, cancelToken);
    }

    async AddUpdateProductPrice(data, cancelToken) {
        let payload = {
            Id: data.Id,
            Factor: data.Factor,
            ProfitPercentage: data.ProfitPercentage,
            Profit:  data.Profit,
            SalesPrice:  data.SalesPrice,
            CustomerId: data.CustomerId
        };
        
        return await HttpClient.Post(API.postProductPrice, payload, cancelToken);
    }

    async AddUpdateProductGroup(data, cancelToken) {
        let payload = {
            Id: data.Id,
            ProductGroupId:  data.ProductGroupId,
            CustomerId: data.CustomerId
        };

        return await HttpClient.Post(API.postProductGroup, payload, cancelToken);
    }

    async AddUpdateProductSettings(data, cancelToken) {
        let payload = {
            Id: data.Id,
            ActiveCaseNumber: data.ActiveCaseNumber,
            Deactivated: data.Deactivated,
            AlwaysInputQty: data.AlwaysInputQty,
            AlwaysWeight: data.AlwaysWeight,
            ChooseWhenProductDraw: data.ChooseWhenProductDraw,
            PurchaseTolerance: parseFloat(data.PurchaseTolerance),
            CustomerId: data.CustomerId
        };

        return await HttpClient.Post(API.postProductSettings, payload, cancelToken);
    }

    async AddUpdateProductPictures(state, data, cancelToken) {
        let payload = {
            ImageKey: data.ImageKey,
            IsPrimary: data.IsPrimary,
            ProductId: data.ProductId
        };

        switch (state) {
            case 'add':
                return await HttpClient.Post(API.putPostProductPictures, payload, cancelToken);
            case 'edit':
                payload.Id = data.Id;
                return await HttpClient.Put(API.putPostProductPictures, payload, cancelToken);
            default:
                return null;
        }
    }

    async DeleteProductPicture(id, cancelToken) {
        const url = API.deleteProductImage.replace('{id}', id);
        return await HttpClient.Delete(url, null, cancelToken);
    }

    async AddInitialProduct(data, cancelToken) {
        return await HttpClient.Post(API.postInitialProduct, data, cancelToken);
    }

    async AddUpdateProductSuppliers(state, data, cancelToken) {
        let payload = {
            SupplierId: data.SupplierId,
            SupplierProductNo: data.SupplierProductNo,
            SupplierEAN: data.SupplierEAN,
            SupplierProductDesc: data.SupplierProductDesc,
            DeliveryQty: data.DeliveryQty,
            PurchasePrice: data.PurchasePrice,
            Priority: data.Priority,
            DeliveryDays: data.DeliveryDays,
            ProductId: data.ProductId
        };

        switch (state) {
            case 'add':
                return await HttpClient.Post(API.putPostProductSuppliers, payload, cancelToken);
            case 'edit':
                payload.Id = data.Id;
                return await HttpClient.Put(API.putPostProductSuppliers, payload, cancelToken);
            default:
                return null;
        }
    }

    async DeleteProductSupplier(id, cancelToken) {
        const url = API.deleteProductSuppliers.replace('{supplier_id}', id);
        return await HttpClient.Delete(url, null, cancelToken);
    }

    async AddUpdateProductStorage(state, data, cancelToken) {
        // let payload = {
        //     DeptId: data.DeptId,
        //     MachineId: data.MachineId,
        //     BoxNo: data.BoxNo,
        //     RoomNo: data.RoomNo,
        //     Min: data.Min,
        //     Max: data.Max,
        //     PurchaseQty: data.PurchaseQty,
        //     StandardLocation: data.StandardLocation,
        //     ProductId: data.ProductId
        // };
        let payload = {
            ProductId: data.ProductId,
            MinQty: data.Min,
            MaxQty: data.Max,
            PurchaseQty: data.PurchaseQty,
            StandardLocation: data.StandardLocation,
            Location: {
                Id: data.Room.Id,
                BoxId: data.Box.Id,
                RoomNoStart: data.Room.RoomNoStart,
                RoomNoEnd: data.Room.RoomNoEnd
            },
            MachineId: data.MachineId
        }

        switch (state) {
            case 'add':
                return await HttpClient.Post(API.putPostProductStorage, payload, cancelToken);
            case 'edit':
                payload.Id = data.Id;
                return await HttpClient.Put(API.putPostProductStorage, payload, cancelToken);
            default:
                return null;
        }
    }

    async CopyProduct(id, cancelToken) {
        const url = API.copyProduct.replace('{id}', id);
        return await HttpClient.Post(url, null, cancelToken);
    }

    async FilterProduct(customerId, data, cancelToken) {
        const url = API.getFilteredProduct.replace('{customer_id}', customerId);
        return await HttpClient.Post(url, data, cancelToken);
    }

    async UndoProduct(id, cancelToken) {
        const url = API.putProductUndo.replace('{id}', id);
        return await HttpClient.Put(url, null, cancelToken);
    }

    async RedoProduct(id, cancelToken) {
        const url = API.putProductRedo.replace('{id}', id);
        return await HttpClient.Put(url, null, cancelToken);
    }

    async UndoProductImage(id, cancelToken) {
        const url = API.putProductImageUndo.replace('{id}', id);
        return await HttpClient.Put(url, null, cancelToken);
    }

    async RedoProductImage(id, cancelToken) {
        const url = API.putProductImageRedo.replace('{id}', id);
        return await HttpClient.Put(url, null, cancelToken);
    }

    async UndoProductStorage(id, cancelToken) {
        const url = API.putProductStorageUndo.replace('{id}', id);
        return await HttpClient.Put(url, null, cancelToken);
    }

    async RedoProductStorage(id, cancelToken) {
        const url = API.putProductStorageRedo.replace('{id}', id);
        return await HttpClient.Put(url, null, cancelToken);
    }

    async UndoProductSupplier(id, cancelToken) {
        const url = API.putProductSupplierUndo.replace('{id}', id);
        return await HttpClient.Put(url, null, cancelToken);
    }

    async RedoProductSupplier(id, cancelToken) {
        const url = API.putProductSupplierRedo.replace('{id}', id);
        return await HttpClient.Put(url, null, cancelToken);
    }

    async ProductImageSave(data, cancelToken) {
        return await HttpClient.Post(API.postProductImage, data, cancelToken);
    }

    async BulkSave(id, data, cancelToken) {
        const url = API.postBulkSave.replace("{product_id}", id);
        return await HttpClient.Post(url, data, cancelToken);
    }

    async BulkSaveSupplier(data, cancelToken) {
        return await HttpClient.Post(API.postProductSupplier, data, cancelToken);
    }

    async BulkSaveStock(data, cancelToken) {
        return await HttpClient.Post(API.postProductStorage, data, cancelToken);
    }

    async ToolBulkSave(data, cancelToken) {
        return await HttpClient.Post(API.postProductToolBulkSave, data, cancelToken);
    }

    async ImportBulkSave(data, cancelToken) {
        return await HttpClient.Post(API.postImportBulkSave, data, cancelToken);
    }
}

const productService = new ProductService();
export default productService;