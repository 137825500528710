import React from 'react';
import '../styles/toolbar.scss'
/*
    Properties:
        buttons = array of button
            ex. button = {
                id = string
                text = string
                onClick = function
                iconClass = string
                disabled = boolean
            }
*/
const Toolbar = ({ buttons }) => {

    return (
        <div className="toolbar-wrapper">
            {
                buttons.map(button => {
                    let props = {};

                    if (Object.prototype.hasOwnProperty.call(button, 'disabled')) {
                        props.disabled = button.disabled
                    }
                    return (
                        <button key={button.id} id={button.id} type="button" className="toolbar-button" onClick={button.onClick} {...props}>
                            <i className={`c-icon ${button.iconClass}`}></i><span>{button.text}</span>
                        </button>
                    )
                })
            }
        </div>
    )
}

export default Toolbar;