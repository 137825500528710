import React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Page, Sort, Toolbar, Selection, Edit, Search, Reorder, Resize } from '@syncfusion/ej2-react-grids';
// import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { Internationalization } from '@syncfusion/ej2-base';
import { Redirect } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import BaseComponent from '../../components/BaseComponent';

import Axios from 'axios';

import AppContext from '../../context/AppContext';
import Breadcrumb from '../../components/Breadcrumb';
import { toDanishNumberFormat } from '../../utilities/Helper';
import messageHelper from '../../utilities/MessageHelper';

import OrderService from '../../services/Order/OrderService';
import SettingsService from '../../services/SettingsService';
// import MachineService from '../../services/Machine/MachineService';
import SessionService from '../../services/SessionService';
import ProductService from '../../services/Product/ProductService';
import Loading from '../../components/Loading';
import Localization from '../../utilities/Localization';
import { buildGridSettings } from '../../utilities/Helper';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class PurchaseOrder extends BaseComponent {
    moduleName = "Indkøb";
    pageName = 'Indkøb';
    purchaseOrderDetailsGridSettingsKey = "PurchaseOrderDetailsList";
    POProductSelectionGridSettingsKey = "POProductSelectionList";
    hasDraggedColumn = false;
    purchaseOrderDetailsGridSettings = '';
    POProductSelectionGridSettings = '';

    constructor() {
        super();

        this.state = {
            loading: true,
            breadcrumbs: [{ text: 'Indkøbsordrer', link: '/indkobsordrer' }, 'Ordre detaljer'],
            data: {
                Id: null,
                DepartmentId: null,
                MachineId: null,
                SupplierId: null,
                PurchaseNo: '',
                Machine: {
                    CustomName: ''
                },
                CreatedByMachine: null,
                CreatedByAccount: null,
                ReferenceNo: '',
                ApprovedBy: '',
                GrandTotal: 0,
                CreationDate: '',
                OrderedDate: '',
                RushOrder: false,
                OrderState: 3,
                ApprovedState: 0,
                AllowApproval: false
            },
            moduleState: 'add',
            filteredProducts: [],
            redirect: {
                to: '',
                isRedirect: false
            }
        };

        this.orderDetailsData = [];
        this.supplierProductsData = [];

        this.defaultEditSettings = {
            allowAdding: true,
            allowDeleting: true,
            newRowPosition: 'Bottom',
            showDeleteConfirmDialog: true,
        };

        this.selectionSettings = {
            type: 'Single'
        };

        this.filterSettings = {
            type: 'Menu'
        };

        this.autoCompleteFields = {
            value: "ProductNoAndDescription"
        }

        this.pageSettings = {
            pageSize: 15,
            pageSizes: ['Alle', '5', '10', '20', '50', '100']
        }

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.buttons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];

        let buttons = messageHelper.MB7.buttons;
        buttons[0].click = () => this.deleteConfirmationDialog.hide();
        this.deleteConfirmationButtons = buttons;
        // this.deleteConfirmationButtons = [
        //     {
        //         click: () => this.deleteConfirmationDialog.hide(),
        //         buttonModel: {
        //             content: messageHelper.MB7.buttons[0]
        //         }
        //     },
        //     {
        //         buttonModel: {
        //             isPrimary: true,
        //             content: messageHelper.MB7.buttons[1]
        //         }
        //     }
        // ];
        /* End Dialog options  */

        this.init = this.init.bind(this);
        this.clickHandler = this.clickHandler.bind(this);
        this.onDeleteOrder = this.onDeleteOrder.bind(this);
        this.deleteOrderTemplate = this.deleteOrderTemplate.bind(this);
        this.dialogOpen = this.dialogOpen.bind(this);
        this.onAddProduct = this.onAddProduct.bind(this);
        this.onProductSelected = this.onProductSelected.bind(this);
        this.onDeleteOrderDetail = this.onDeleteOrderDetail.bind(this);
        this.onConfirmDeleteOrder = this.onConfirmDeleteOrder.bind(this);
        this.onAddProductToList = this.onAddProductToList.bind(this);
        this.onQuantityChange = this.onQuantityChange.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onRecordDoubleClick = this.onRecordDoubleClick.bind(this);
        this.onConfirmAddProducts = this.onConfirmAddProducts.bind(this);
        this.onCancelProductFilter = this.onCancelProductFilter.bind(this);
        this.cancelConfirm = this.cancelConfirm.bind(this);
        this.dateTemplate =  this.dateTemplate.bind(this);
        this.onWindowFocus = this.onWindowFocus.bind(this);
        
        this.selectionSettings = {
            type: 'Single'
        }

        this.filterSettings = {
            type: 'Menu'
        };
    }

    async componentDidMount() {
        super.componentDidMount();
        source.cancel();
        source = CancelToken.source();

        if (!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }
        else if (!super.hasMenuItem('Indkøbsordre')) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }

        this.init();
        
        const { match, location } = this.props;
        let { moduleState, selectedTab, data } = this.state;
        let addStateError = false;
        this.intl = new Internationalization();
        
        const session = await SessionService.GetSessionInfo();

        const orderId = match && match.params && match.params.id;

        if (orderId) {
            moduleState = 'edit';
            const orderData = await OrderService.GetOrder(orderId, source.token);
            if (orderData.HasError) {
                let response = super.processModuleResponse(orderData);
                this.setState(response);
                return;
            }

            Object.assign(data, orderData);
            this.orderDetailsData = orderData.OrderDetails;
            data.DepartmentId = orderData.Machine.Department.Id;
            data.MachineId = orderData.Machine.Id;

            if (orderData.CreationDate) {
                data.CreationDate = this.intl.formatDate(new Date(orderData.CreationDate), { format: 'y-MM-d' });
            }
            if (orderData.OrderedDate) {
                data.OrderedDate = this.intl.formatDate(new Date(orderData.OrderedDate), { format: 'y-MM-d' });
            }
            if (orderData.ReceivedDate) {
                data.ReceivedDate = this.intl.formatDate(new Date(orderData.ReceivedDate), { format: 'y-MM-d' });
            }
        }
        else {
            if (location && location.state) {
                if (
                    (location.state.DepartmentId && location.state.DepartmentId !== -1) &&
                    (location.state.SupplierId && location.state.SupplierId !== -1) &&
                    (location.state.MachineId && location.state.MachineId !== -1)
                    ) {
                        data.DepartmentId = location.state.DepartmentId;
                        data.SupplierId = location.state.SupplierId;
                        data.MachineId = location.state.MachineId;
                        data.Machine.CustomName = location.state.MachineName;
                }
                else {
                    addStateError = true;
                }
            }
            else {
                addStateError = true;
            }
        }
        
        const orderRes = await SettingsService.GetOrderStatus(source.token);
        this.orderStatuses = orderRes;

        const approvalRes = await SettingsService.GetApprovalStatus(source.token);
        this.approvalStatuses = approvalRes;

        // const supplierProducts = await MachineService.GetMachineProductsBySupplier(data.MachineId, data.SupplierId, source.token);
        let filter = { GroupIds: [], SupplierIds: [data.SupplierId], DeptIds: [] };
        const supplierProducts = await ProductService.FilterProduct(session.Id, filter, source.token);
        this.supplierProductsData = supplierProducts.map((s, index) => {
            let supplier = s.ProductSuppliers.sort(x => x.Priority)[0];
            return {
                ...s,
                rowIndex: index,
                ProductNoAndDescription: `${s.Description1} ${s.ProductNo}`,
                PrimarySupplierName: supplier.Supplier.Company,
                PrimarySupplierProductNo: supplier.SupplierProductNo,
                PrimarySupplierEan: supplier.SupplierEAN,
                PurchasePrice: supplier.PurchasePrice,
                PrimarySupplierId: supplier.Supplier.Id
            }
        });
        
        const purchaseOrderDetailsGridSettings = await SettingsService.GetGridSettings(this.purchaseOrderDetailsGridSettingsKey, source.token);
        if (!purchaseOrderDetailsGridSettings.HasError) {
            this.purchaseOrderDetailsGridSettings = purchaseOrderDetailsGridSettings.Settings;
        }
        
        const POProductSelectionGridSettings = await SettingsService.GetGridSettings(this.POProductSelectionGridSettingsKey, source.token);
        if (!POProductSelectionGridSettings.HasError) {
            this.POProductSelectionGridSettings = POProductSelectionGridSettings.Settings;
        }

        this.setState({ loading: false, moduleState }, () => {
            setTimeout(() => {
                if (addStateError) {
                    this.dialogOpen('Advarsel', Localization.Purchase.ErrorSelectDeptMachSupplier, () => {
                        this.props.history.push('/indkobsordrer', {
                            openAddDialog: true
                        });
                    });
                }    
            }, 400);
            window.addEventListener('wheel', this.onWheelScroll);

            // Set global counter variable to verify event instances
            this.nCounter = 0;

            // Set up event handler to produce text for the window focus event
            window.addEventListener("focus", this.onWindowFocus);
        });
    }

    componentWillUnmount() {
        source.cancel();
        window.removeEventListener('wheel', this.onWheelScroll);
        window.removeEventListener("focus", this.onWindowFocus);
    }

    init() {
        this.toolbarOptions = [];

        if (super.hasMenuItemTabFunction("Indkøbsordre", "Ordre detaljer", "Ny")) {
            this.toolbarOptions.push({ text: 'Tilføj', tooltipText: 'Tilføj', id: 'add', prefixIcon: 'e-add' });
        }

        let productMenu = this.getSiteRoute("Produkt");
        if (productMenu && productMenu.MenuItems.some(p => p.Name === "Produktkort")) {
            this.toolbarOptions.push({ text: 'Åbne detaljer', tooltipText: 'Åbne detaljer', id: 'open', prefixIcon: 'e-external-link' });
        }

        if (super.hasMenuItemTabFunction("Indkøbsordre", "Ordre detaljer", "Slette")) {
            this.toolbarOptions.push({ text: 'Slette', tooltipText: 'Slette', id: 'delete', prefixIcon: 'e-delete' });
        }

        if (super.hasMenuItemFunction("Indkøbsordre", "Slette")) {
            this.toolbarOptions.push({ template: this.deleteOrderTemplate, align: "Right" });
        }

        this.toolbarOptions.push({ text: Localization.General.GridResetFilter, tooltipText: Localization.General.GridResetFilter, id: 'reset_filter', prefixIcon: 'e-cancel' });
    }

    deleteOrderTemplate(props) {
        const { data } = this.state;
        let isDisabled = false;
        if (data.OrderState === 1) {
            isDisabled = true;
        }
        return <button className="e-btn e-control btn-delete-order" disabled={isDisabled} onClick={() => this.onConfirmDeleteOrder('Order')}>Slet ordre</button>
    }

    onConfirmDeleteOrder(context) {
        this.deleteConfirmationDialog.content = messageHelper.MB7.message.replace('{context}', context);
        
        let buttons = [
            {
                click: () => this.deleteConfirmationDialog.hide(),
                buttonModel: {
                    content: messageHelper.MB7.buttons[0].buttonModel.content
                }
            }
        ];

        if (context === 'Order') {
            buttons.push({
                click: () => { this.onDeleteOrder() },
                buttonModel: {
                    isPrimary: true,
                    content: messageHelper.MB7.buttons[1].buttonModel.content
                }});
            // this.deleteConfirmationDialog.buttons[1].click = this.onDeleteOrder;
        }
        else if (context === 'Order Detail') {
            buttons.push({
                click: () => {this.onDeleteOrderDetail()},
                buttonModel: {
                    isPrimary: true,
                    content: messageHelper.MB7.buttons[1].buttonModel.content
                }});
            // this.deleteConfirmationDialog.buttons[1].click = this.onDeleteOrderDetail;
        }
        this.deleteConfirmationDialog.buttons = buttons;
        this.deleteConfirmationDialog.show();
    }

    async onDeleteOrder(e) {
        const { data, moduleState } = this.state;
        
        if (moduleState === 'add') {
            this.dialogOpen("Advarsel", Localization.Purchase.ErrorUnableDeleteAddState);
            return;
        }

        const result = await OrderService.DeleteOrder(data.Id, source.token);
        if (!result.HasError) {
            // refresh data
            this.deleteConfirmationDialog.hide();
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessDelete.replace("{context}", "Indkøbsordre"));
            this.props.history.push('/indkobsordrer');
        }
        else {
            this.deleteConfirmationDialog.hide()
            this.dialogOpen("Advarsel", result.ErrorMessage);
        }
    }

    async onDeleteOrderDetail(e) {
        const { data, moduleState } = this.state;
        const selectedRecords = this.purchaseOrderDetailsGridRef.getSelectedRecords();

        if (moduleState === 'add') {
            this.purchaseOrderDetailsGridRef.deleteRecord(selectedRecords[0]);
        }
        else {
            const result = await OrderService.DeleteOrderDetail(selectedRecords[0].Id, source.token);
            if (!result.HasError) {
                const details = await OrderService.GetOrderDetails(data.Id, source.token);
                this.purchaseOrderDetailsGridRef.dataSource = details;
                this.purchaseOrderDetailsGridRef.dataBind();
                this.deleteConfirmationDialog.hide();
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessDelete.replace("{context}", "Ordre detaljer"));
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
    }

    onAddProductToList(e) {
        const selectedValue = this.selectedProduct;
        const { filteredProducts, data } = this.state;

        if (selectedValue) {
            const supplier = selectedValue.ProductSuppliers.find(x => x.Supplier.Id === data.SupplierId);
            filteredProducts.push({
                Id: selectedValue.Id,
                ProductNo: selectedValue.ProductNo,
                Description1: selectedValue.Description1,
                Quantity: 1,
                EAN: selectedValue.EAN,
                PurchasePrice: supplier.PurchasePrice,
                CaseNumber: selectedValue.CaseNumber,
                SupplierId: supplier.Supplier.Id
            });

            this.productAutoCompleteRef.value = null;
            this.setState({ filteredProducts });
        }
        else {
            this.dialogOpen("Advarsel", Localization.Purchase.PleaseSelectProduct);
        }
    }

    cancelConfirm() {
        this.productFilterConfirmDialog.hide();
        this.setState({ filteredProducts: [] });
    }

    async onAddProduct() {
        let { filteredProducts, data } = this.state;
        let dataSource = this.purchaseOrderDetailsGridRef.dataSource;
        // let newProducts = this.productFilterGridRef.dataSource;

        if (filteredProducts.length <= 0) {
            this.dialogOpen("Advarsel", Localization.Purchase.ErrorNoProductsToAdd);
            return;
        }
        
        if (filteredProducts.some(x => x.Quantity === null || x.Quantity <= 0)) {
            this.dialogOpen("Advarsel", Localization.Purchase.InvalidQuantity);
            return;
        }
        
        let requests = [];
        for (let index = 0; index < filteredProducts.length; index++) {
            const prod = filteredProducts[index];
            const payload = {
                ProductId: prod.Id,
                OrderQty: prod.Quantity,
                UnitPrice: prod.PurchasePrice,
                CaseNumber: null,
                OrderId: data.Id,
                SupplierId: prod.SupplierId,
                StockId: prod.StockId ? prod.StockId : 0
            }
            requests.push(OrderService.PostOrderDetail(payload, source.token));
        }

        if (requests.length > 0) {
            Axios.all(requests).then(Axios.spread((...response) => {
                const result = response[requests.length - 1];
                
                if (!result.HasError) {
                    filteredProducts = [];
                    this.purchaseOrderDetailsGridRef.dataSource = dataSource;
                    this.purchaseOrderDetailsGridRef.dataBind();
                    this.productFilterGridRef.clearRowSelection();
                    this.productFilterDialog.hide();
                    this.productFilterConfirmDialog.hide();
    
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessAdd.replace("{context}", "Ordre detaljer"));
                    this.setState({ filteredProducts }, async () => {
                        await this.refreshOrderDetailsGrid();
                    });
                }
                else {
                    this.dialogOpen('Advarsel', result.ErrorMessage);
                }
            }));
        }
    }

    onCancelProductFilter() {
        this.productFilterGridRef.clearRowSelection();
        this.productFilterDialog.hide();
        this.setState({ filteredProducts: [] });
    }

    onConfirmAddProducts() {
        let { filteredProducts, data } = this.state;
        let selectedRecords = this.productFilterGridRef.getSelectedRecords();

        if (selectedRecords.length > 0) {
            selectedRecords.forEach(prod => {
                let stockId = 0;
                if (prod.ProductStorages) {
                    // TODO
                    const machStocks = prod.ProductStorages.filter(x => x.Machine.Id == data.MachineId).sort(function (a, b) {
                        const maxA = a.MaxQty ? a.MaxQty : (a.PurchaseQty ? a.PurchaseQty : 0);
                        const maxB = b.MaxQty ? b.MaxQty : (b.PurchaseQty ? b.PurchaseQty : 0);
                        return  maxA < maxB ? 1 : -1;
                    });
                    let stock = machStocks.find(x => x.StandardLocation);
                    if (!stock && machStocks) {
                        stock = machStocks[0];
                    }

                    if (stock) {
                        stockId = stock.Id;
                    }
                }

                filteredProducts.push({
                    Id: prod.Id,
                    ProductNo: prod.ProductNo,
                    Description1: prod.Description1,
                    Quantity: null,
                    PurchasePrice: prod.PurchasePrice,
                    SupplierId: prod.PrimarySupplierId,
                    StockId: stockId
                });
            })

            this.setState({ filteredProducts }, () => {
                this.productFilterConfirmDialog.show();
            });
        }
        else {
            this.dialogOpen("Advarsel", Localization.General.NoRowSelected);
        }
    }

    async refreshOrderDetailsGrid() {
        const { data } = this.state;
        const orderDetails = await OrderService.GetOrderDetails(data.Id, source.token);
        
        if (!orderDetails.HasError) {
            this.purchaseOrderDetailsGridRef.dataSource = orderDetails;
            this.purchaseOrderDetailsGridRef.dataBind();
            this.refreshGrandTotal();
        }
        else {
            this.dialogOpen("Advarsel", orderDetails.ErrorMessage);
        }
    }

    refreshGrandTotal() {
        const dataSource = this.purchaseOrderDetailsGridRef.dataSource
        let { data } = this.state;

        data.GrandTotal = 0;

        dataSource.forEach(d => {
            data.GrandTotal += d.Total;
        });

        this.setState({ data });
    }

    async clickHandler(args) {
        const selectedRows = this.purchaseOrderDetailsGridRef.getSelectedRecords();

        if (this.purchaseOrderDetailsGridRef && args.item.id === 'add') {
            // this.purchaseOrderDetailsGridRef.addRecord();
            this.productFilterGridRef.searchSettings.key = "";
            this.productFilterDialog.show();
        }
        else if (this.purchaseOrderDetailsGridRef && args.item.id === 'open') {
            if (selectedRows.length > 0) {
                let height = window.innerHeight - 200;
                let width = window.innerWidth - 200;
                window.open(`/produktkort/edit/${selectedRows[0].Product.Id}`, '_blank', `fullscreen=yes,location=yes,height=${height},width=${width},scrollbars=yes,status=yes`);
            }
            else {
                this.dialogOpen("Advarsel", Localization.General.NoRowSelected);
            }
        }
        else if (this.purchaseOrderDetailsGridRef && args.item.id === 'delete') {
            if (selectedRows.length > 0) {
                if (selectedRows[0].DeletedOrder) {
                    return false;
                }
                else {
                    this.onConfirmDeleteOrder('Order Detail');
                }
            }
            else {
                this.dialogOpen("Advarsel", Localization.General.NoRowSelected);
            }
        } else if (args.item.id === 'reset_filter') {
            if (this.purchaseOrderDetailsGridSettings) {
                let settings = JSON.parse(this.purchaseOrderDetailsGridSettings);
                settings.filters = [];
                this.purchaseOrderDetailsGridSettings = settings;
                const payload = {
                    Key: this.purchaseOrderDetailsGridSettingsKey,
                    Settings: JSON.stringify(this.purchaseOrderDetailsGridSettings)
                };
    
                const result = await SettingsService.SaveGridSettings(payload, source.token);
    
                if (!result.HasError) {
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                }
            }

            this.purchaseOrderDetailsGridRef.clearFiltering();
        }
    }

    onProductSelected(args) {
        this.selectedProduct = args.itemData;
    }

    onQuantityChange(sign, index, args) {
        let { filteredProducts }  = this.state;
        let product = filteredProducts[index];

        switch(sign) {
            case "+":
                product.Quantity += 1;
                break;
            case "-":
                if (product.Quantity !== 0) {
                    product.Quantity -= 1;
                }
                break;
        }

        this.setState({ filteredProducts });
    }

    async onButtonClick(args) {
        const { data } = this.state;
        let payload = { Id: data.Id };
        
        if (args.target.id === 'approve') {
            payload.Approve = true;
        }
        else if(args.target.id === 'reject') {
            payload.Approve = false;
        }

        const result = await OrderService.ApproverOrder(payload, source.token);
        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Ordre Status"));
            window.location.reload();
            this.purchaseOrderDetailsGridRef.toolbarModule.enableItems(['add', 'delete'],false);
        }
        else {
            this.dialogOpen("Advarsel", result.ErrorMessage);
        }
    }

    onRecordDoubleClick(args) {
        let height = window.innerHeight - 200;
        let width = window.innerWidth - 200;
        window.open(`/produktkort/edit/${args.rowData.Product.Id}`, '_blank', `fullscreen=yes,location=yes,height=${height},width=${width},scrollbars=yes,status=yes`);
    }

    onProductQuantityChange = (index, args) => {
        const { filteredProducts } = this.state;
        // filteredProducts[index].Quantity = +args.target.value;
        filteredProducts[index].Quantity = args.floatValue;
        this.setState({ filteredProducts });
    }

    dialogOpen(header, content, callback = null) {
        this.notificationDialog.header = header;
        this.notificationDialog.content = content;

        if (callback) {
            let buttons = this.buttons;
            buttons[0].click = () => {
                this.notificationDialog.hide();
                callback();
            }
            this.notificationDialog.buttons = buttons;
        }

        this.notificationDialog.show();
    }

    dateTemplate(props) {
        if (props[props.column.field]) {
            let date = new Date(props[props.column.field]);
            return <span>{this.intl.formatDate(date, { format: 'dd.MM.y' })}</span>
        }
        return '';
    }

    onCreated() {
        const { moduleState, data } = this.state;
        
        if (moduleState === 'add') {
            const deleteOrderButton = document.getElementsByClassName('btn-delete-order')[0];
            if (deleteOrderButton) {
                deleteOrderButton.style.display = 'none';
            }
        }

        if (![2, 3].includes(data.OrderState)) {
            this.purchaseOrderDetailsGridRef.toolbarModule.enableItems(['add', 'delete'],false);
        }
        
        if (this.purchaseOrderDetailsGridSettings) {
            let settings = JSON.parse(this.purchaseOrderDetailsGridSettings);
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(column => {
                        if (column.FromIndex !== column.ToIndex) {
                            var tempIndex = this.purchaseOrderDetailsGridRef.getColumnIndexByField(column.Field);
                            if (tempIndex !== column.ToIndex){
                                this.purchaseOrderDetailsGridRef.reorderColumnByIndex(column.FromIndex, column.ToIndex);
                            }
                        }
    
                        if (column.Width) {
                            this.purchaseOrderDetailsGridRef.columns[column.ToIndex].width = column.Width;
                        }
                    });
                }

                if (settings.colName && settings.direction) {
                    this.purchaseOrderDetailsGridRef.sortColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.purchaseOrderDetailsGridRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.purchaseOrderDetailsGridRef.refreshColumns();

                if (settings.pageSize) {
                    if (settings.pageSize === "Alle") {
                        this.purchaseOrderDetailsGridRef.pageSettings.pageSize = this.purchaseOrderDetailsGridRef.pageSettings.totalRecordsCount;
                        document.querySelector("#order-detail-grid .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.purchaseOrderDetailsGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
            }
        }
    }

    descriptionTemplate(props) {
        let description = props.Description1;
        if (description) {
            if (description.length > 50) {
                description = `${description.substring(0, 50)}...`;
            }
        }
        return (<div>{description}</div>)
    }
    orderProductDescriptionTemplate(props) {        
        let description = props.Product.Description1;
        if (description) {
            if (description.length > 50) {
                description = `${description.substring(0, 50)}...`;
            }
        }
        return (<div>{description}</div>)
    }

    primarySupplierTemplate(props) {
        let supplier = props.ProductSuppliers.find(x => x.Priority === 1);
        return (<div>{supplier.Supplier.Company}</div>)
    }

    primarySupplierProductNoTemplate(props) {
        let supplier = props.ProductSuppliers.find(x => x.Priority === 1);
        return (<div>{supplier.SupplierProductNo}</div>)
    }

    primarySupplierProductEanTemplate(props) {
        let supplier = props.ProductSuppliers.find(x => x.Priority === 1);
        return (<div>{supplier.SupplierEAN}</div>)
    }
    
    onPurchaseOrderDetailColumnDragStart(args) {
        this.hasDraggedColumn = true;
    }

    async onPurchaseOrderDetailActionComplete(args) {
        if (args.requestType === 'paging') {
            if (this.purchaseOrderDetailsGridRef.pageSettings.pageSize === this.purchaseOrderDetailsGridRef.pageSettings.totalRecordsCount) {
                document.querySelector("#order-detail-grid .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.purchaseOrderDetailsGridSettings, this.purchaseOrderDetailsGridRef.pageSettings.pageSize, this.purchaseOrderDetailsGridRef.pageSettings.totalRecordsCount, false, []);
            this.purchaseOrderDetailsGridSettings = tempGridSettings;
            const payload = {
                Key: this.purchaseOrderDetailsGridSettingsKey,
                Settings: this.purchaseOrderDetailsGridSettings
            };
            const result = await SettingsService.SaveGridSettings(payload, source.token);
        }
        else if (args.requestType === 'filtering') {
            if (this.purchaseOrderDetailsGridSettings) {
                let settings = JSON.parse(this.purchaseOrderDetailsGridSettings);
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let tempGridSettings = buildGridSettings(args, this.purchaseOrderDetailsGridSettings, 0, 0, false, []);
            this.purchaseOrderDetailsGridSettings = tempGridSettings;
            const payload = {
                Key: this.purchaseOrderDetailsGridSettingsKey,
                Settings: this.purchaseOrderDetailsGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.purchaseOrderDetailsGridSettings) {
                let settings = JSON.parse(this.purchaseOrderDetailsGridSettings);
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.purchaseOrderDetailsGridSettings, 0, 0, false, []);
            this.purchaseOrderDetailsGridSettings = tempGridSettings;
            const payload = {
                Key: this.purchaseOrderDetailsGridSettingsKey,
                Settings: this.purchaseOrderDetailsGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.purchaseOrderDetailsGridRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.purchaseOrderDetailsGridSettings, 0, 0, this.hasDraggedColumn, columns);
            this.purchaseOrderDetailsGridSettings = tempGridSettings;
            const payload = {
                Key: this.purchaseOrderDetailsGridSettingsKey,
                Settings: this.purchaseOrderDetailsGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }

            this.hasDraggedColumn = false;
        }
    }
    
    onPOProductSelectionColumnDragStart(args) {
        this.hasDraggedColumn = true;
    }

    onPOProductSelectionCreated() {
        if (this.POProductSelectionGridSettings) {
            let settings = JSON.parse(this.POProductSelectionGridSettings);
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(column => {
                        if (column.FromIndex !== column.ToIndex) {
                            var tempIndex = this.productFilterGridRef.getColumnIndexByField(column.Field);
                            if (tempIndex !== column.ToIndex){
                                this.productFilterGridRef.reorderColumnByIndex(column.FromIndex, column.ToIndex);
                            }
                        }
    
                        if (column.Width) {
                            this.productFilterGridRef.columns[column.ToIndex].width = column.Width;
                        }
                    });
                }

                if (settings.colName && settings.direction) {
                    this.productFilterGridRef.sortColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.productFilterGridRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.productFilterGridRef.refreshColumns();

                if (settings.pageSize) {
                    if (settings.pageSize === "Alle") {
                        this.productFilterGridRef.pageSettings.pageSize = this.productFilterGridRef.pageSettings.totalRecordsCount;
                        document.querySelector("#po-grid-filter .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.productFilterGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
            }
        }
    }

    onPOProductSelectionActionBegin(args) {
        if (args.requestType === "searching") {
            this.productFilterGridRef.clearRowSelection();
        }
    }

    async onPOProductSelectionActionComplete(args) {
        if (args.requestType === 'paging') {
            if (this.productFilterGridRef.pageSettings.pageSize === this.productFilterGridRef.pageSettings.totalRecordsCount) {
                document.querySelector("#po-grid-filter .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.POProductSelectionGridSettings, this.productFilterGridRef.pageSettings.pageSize, this.productFilterGridRef.pageSettings.totalRecordsCount, false, []);
            this.POProductSelectionGridSettings = tempGridSettings;
            const payload = {
                Key: this.POProductSelectionGridSettingsKey,
                Settings: this.POProductSelectionGridSettings
            };
            const result = await SettingsService.SaveGridSettings(payload, source.token);
        }
        else if (args.requestType === 'filtering') {
            if (this.POProductSelectionGridSettings) {
                let settings = JSON.parse(this.POProductSelectionGridSettings);
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let tempGridSettings = buildGridSettings(args, this.POProductSelectionGridSettings, 0, 0, false, []);
            this.POProductSelectionGridSettings = tempGridSettings;
            const payload = {
                Key: this.POProductSelectionGridSettingsKey,
                Settings: this.POProductSelectionGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.POProductSelectionGridSettings) {
                let settings = JSON.parse(this.POProductSelectionGridSettings);
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.POProductSelectionGridSettings, 0, 0, false, []);
            this.POProductSelectionGridSettings = tempGridSettings;
            const payload = {
                Key: this.POProductSelectionGridSettingsKey,
                Settings: this.POProductSelectionGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.productFilterGridRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.POProductSelectionGridSettings, 0, 0, this.hasDraggedColumn, columns);
            this.POProductSelectionGridSettings = tempGridSettings;
            const payload = {
                Key: this.POProductSelectionGridSettingsKey,
                Settings: this.POProductSelectionGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }

            this.hasDraggedColumn = false;
        }
    }

    async onResizeStop(args) {
        args.requestType = "resizingGrid";
        let columns = this.purchaseOrderDetailsGridRef.getColumns();
        let tempGridSettings = buildGridSettings(args, this.purchaseOrderDetailsGridSettings, 0, 0, false, columns);
        this.purchaseOrderDetailsGridSettings = tempGridSettings;
        const payload = {
            Key: this.purchaseOrderDetailsGridSettingsKey,
            Settings: this.purchaseOrderDetailsGridSettings
        };

        const result = await SettingsService.SaveGridSettings(payload, source.token);

        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
        }
    }

    async onPOProductSelectionResizeStop(args) {
        args.requestType = "resizingGrid";
        let columns = this.productFilterGridRef.getColumns();
        let tempGridSettings = buildGridSettings(args, this.POProductSelectionGridSettings, 0, 0, false, columns);
        this.POProductSelectionGridSettings = tempGridSettings;
        const payload = {
            Key: this.POProductSelectionGridSettingsKey,
            Settings: this.POProductSelectionGridSettings
        };

        const result = await SettingsService.SaveGridSettings(payload, source.token);

        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
        }
    }

    onRowDataBound(args) {
        if (args.row) {
            if (args.data.DeletedOrder) {
                args.row.classList.add('e-disabled')
            }
        }
    }

    onWheelScroll = () => {
        let grid = document.getElementById('order-detail-grid');
        let toolbar = document.querySelector('#order-detail-grid.e-grid .e-toolbar');
        let tableHeader = document.querySelector('#order-detail-grid .e-gridheader');

        if (toolbar && tableHeader) {
            let boundingRect = tableHeader.getBoundingClientRect();
            
            if (boundingRect.top < 90) {
                toolbar.classList.add('c-hover-grid-toolbar');
                toolbar.style.width = (grid.clientWidth - 5) + 'px';
            }
            else {
                toolbar.classList.remove('c-hover-grid-toolbar');
                toolbar.style.width = 'auto';
            }
        }
    }

    onWindowFocus() 
    { 
        this.refreshOrderDetailsGrid();
    }

    render() {
        const { loading, breadcrumbs, data, filteredProducts, redirect } = this.state;
        let orderStateText = '', approvalStateText = '';

        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        let orderedBy = '', confirmedBy = '';
        if (data.CreatedByAccount) {
            orderedBy = data.CreatedByAccount.FullName;
        }
        else if (data.CreatedByMachine) {
            orderedBy = data.CreatedByMachine.CustomName;
        }

        if (data.OrderedBy) {
            confirmedBy = data.OrderedBy.FullName;
        }

        if (data.OrderState) {
            if (this.orderStatuses.some(x => x.Value === data.OrderState)) {
                orderStateText = this.orderStatuses.find(x => x.Value === data.OrderState).Key;
            }
        }

        if (data.ApprovedState) {
            if (this.approvalStatuses.some(x => x.Value === data.ApprovedState)) {
                approvalStateText = this.approvalStatuses.find(x => x.Value === data.ApprovedState).Key;
            }
        }

        let creationDate = '', orderedDate = '';
        if (data.CreationDate) {
            creationDate = this.intl.formatDate(new Date(data.CreationDate), { format: 'dd.MM.y' });
        }

        if (data.OrderedDate) {
            orderedDate = this.intl.formatDate(new Date(data.OrderedDate), { format: 'dd.MM.y' });
        }

        return (
            <div className="content-pane-wrapper">
                <div><Breadcrumb breadcrumbs={breadcrumbs} /></div>
                <div className="module-header">
                    <h1>{this.pageName}</h1>
                </div>
                <div className="purchase-order-wrapper">
                    <div className="purchase-order-header-wrapper">
                        <div className="purchase-order-info-header">
                            <h2>Ordre Detaljer</h2>
                            <div className="purchase-order-actions">
                                {
                                    super.hasMenuItemTabFunction("Indkøbsordre", "Ordre detaljer", "Afvis") &&
                                    <button id='reject' onClick={this.onButtonClick} disabled={!data.AllowApproval} className="e-control e-btn e-btn--default-danger">Afvis</button>
                                }
                                {
                                    super.hasMenuItemTabFunction("Indkøbsordre", "Ordre detaljer", "Godkend") &&
                                    <button id='approve' onClick={this.onButtonClick} disabled={!data.AllowApproval} className="e-control e-btn e-primary">Godkend</button>
                                }
                            </div>
                        </div>
                        <table className="purchase-order-info-table">
                            <tbody>
                                <tr>
                                    <td className="label">Indkøbs Nr.:</td>
                                    <td className="field-value">{data.PurchaseNo}</td>
                                    <td className="label">Bestilt af:</td>
                                    <td className="field-value">{orderedBy}</td>
                                    <td className="label">Vor Ref:</td>
                                    <td className="field-value">{data.CustomerNoRef}</td>
                                </tr>
                                <tr>
                                    <td className="label">Status:</td>
                                    <td className="field-value">{orderStateText}</td>
                                    <td className="label">Godkendt af:</td>
                                    <td className="field-value">{confirmedBy}</td>
                                    <td className="label total-sum">Total Ordre Sum:</td>
                                    <td className="field-value">{toDanishNumberFormat(data.GrandTotal)}</td>
                                </tr>
                                <tr>
                                    <td className="label">Opret Dato:</td>
                                    <td className="field-value">{creationDate}</td>
                                    <td className="label">Godkendelse:</td>
                                    <td className="field-value">{approvalStateText}</td>
                                </tr>
                                <tr>
                                    <td className="label">Bestilt Dato:</td>
                                    <td className="field-value">{orderedDate}</td>
                                    <td className="label">Hastebestilling:</td>
                                    <td className="field-value">{data.RushOrder ? 'Ja' : 'Nej'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="purchase-order-details-wrapper">
                        <GridComponent id="order-detail-grid" ref={ref => this.purchaseOrderDetailsGridRef = ref } dataSource={this.orderDetailsData} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings} allowTextWrap='true'
                            toolbar={this.toolbarOptions} editSettings={this.defaultEditSettings} selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da"
                            gridLines="Both" toolbarClick={this.clickHandler} recordDoubleClick={this.onRecordDoubleClick} allowReordering={true} allowResizing={true} created={this.onCreated.bind(this)}
                            actionComplete={this.onPurchaseOrderDetailActionComplete.bind(this)} columnDragStart={this.onPurchaseOrderDetailColumnDragStart.bind(this)} resizeStop={this.onResizeStop.bind(this)}
                            rowDataBound={this.onRowDataBound.bind(this)}>
                            <ColumnsDirective>
                                <ColumnDirective field="Product.ProductNo" headerText="Produkt Nr." width="105" />
                                <ColumnDirective field="Product.Description1" headerText="Produkt beskrivelse" width="120" textAlign="Left" template={this.orderProductDescriptionTemplate} />
                                <ColumnDirective field="Product.EAN" headerText="EAN" width="100" textAlign="Right" />
                                <ColumnDirective field="OrderQty" headerText="Antal" width="100" textAlign="Right" />
                                <ColumnDirective field="UnitPrice" headerText="A Pris" width="100" textAlign="Right" allowEditing={false} format="n4" />
                                <ColumnDirective field="Total" headerText="Total" width="100" textAlign="Right" allowEditing={false} format="n2" />
                                <ColumnDirective field="CaseNumber" headerText="Sags Nr." width="100" textAlign="Left" displayAsCheckBox={true} allowEditing={false} />
                                <ColumnDirective field="ReceivedBy.FullName" headerText="Modtager" width="100" textAlign="Left" allowEditing={false} />
                                <ColumnDirective field="ReceivedDate" headerText="Modtaget dato" width="100" textAlign="Right" type="date" format={{ type: 'date', format: 'dd.MM.y' }} allowEditing={false} />
                                <ColumnDirective field="ReceivedQty" headerText="Modtaget" width="100" textAlign="Left" allowEditing={false} />
                                <ColumnDirective field="OrderNoteDisplay" headerText="Note" width="100" textAlign="Left" allowEditing={false} />
                            </ColumnsDirective>
                            <Inject services={[Page, Sort, Filter, Selection, Toolbar, Edit, Search, Reorder, Resize]} />
                        </GridComponent>
                    </div>
                </div>
                <DialogComponent id="notification-dialog" isModal={true} buttons={this.buttons} width='auto' ref={dialog => this.notificationDialog = dialog} target='body'
                    visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true}/>
                <DialogComponent id="delete-confirmation-dialog" isModal={true} buttons={this.deleteConfirmationButtons} width='auto' ref={dialog => this.deleteConfirmationDialog = dialog} target='body'
                    visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} header="Advarsel" allowDragging={true} enableResize={true}/>
                <DialogComponent id="order-product-filter-dialog" isModal={true} width='auto' ref={dialog => this.productFilterDialog = dialog} target='body' visible={false} animationSettings={this.animationSettings} 
                    header="Tilføj Produkt" allowDragging={true} enableResize={true}>
                    <div className="dialog-content">
                        <GridComponent id="po-grid-filter" ref={ref => this.productFilterGridRef = ref} allowPaging={true} allowSorting={true} allowFiltering={true} pageSettings={this.pageSettings} filterSettings={this.filterSettings}
                            selectionSettings={{type: 'Single', persistSelection: true}} gridLines="Both" allowTextWrap='true' locale="da" dataSource={this.supplierProductsData} allowReordering={true} allowResizing={true}
                            actionComplete={this.onPOProductSelectionActionComplete.bind(this)} columnDragStart={this.onPOProductSelectionColumnDragStart.bind(this)} created={this.onPOProductSelectionCreated.bind(this)}
                            resizeStop={this.onPOProductSelectionResizeStop.bind(this)} toolbar={['Search']} actionBegin={this.onPOProductSelectionActionBegin.bind(this)}>
                            <ColumnsDirective>
                                <ColumnDirective type="checkbox" width="50" textAlign="Center" />
                                <ColumnDirective field="rowIndex" visible={false} isPrimaryKey={true} />
                                <ColumnDirective headerText="Produkt nr." field="ProductNo" />
                                <ColumnDirective headerText="Produkt beskrivelse" field="Description1" template={this.descriptionTemplate} />
                                <ColumnDirective headerText="EAN" field="EAN" />
                                <ColumnDirective headerText="Produkt gruppe" field="ProductGroup.Name" />
                                <ColumnDirective headerText="Primære leverandør" field="PrimarySupplierName" template={this.primarySupplierTemplate} />
                                <ColumnDirective headerText="Leverandør produkt nr." field="PrimarySupplierProductNo" template={this.primarySupplierProductNoTemplate} />
                                <ColumnDirective headerText="Leverandør EAN" field="PrimarySupplierEan" template={this.primarySupplierProductEanTemplate} />
                                {/* <ColumnDirective headerText="Afdeling" field="EAN" /> */}
                            </ColumnsDirective>
                            <Inject services={[Page, Sort, Filter, Selection, Search, Reorder, Resize ]} />
                        </GridComponent>
                    </div>
                    <div className="dialog-footer">
                        <button className="e-control e-btn e-lib" style={{width: 122}} onClick={this.onCancelProductFilter}>Annuller</button>
                        <button className="e-control e-btn e-lib e-primary" style={{width: 122}} onClick={this.onConfirmAddProducts}>OK</button>
                    </div>
                </DialogComponent>
                <DialogComponent id="order-product-confirm-dialog" isModal={true} width='auto' ref={dialog => this.productFilterConfirmDialog = dialog} target='body'
                    visible={false} animationSettings={this.animationSettings} header="Tilføj Produkt" allowDragging={true} enableResize={true}>
                        <div className="dialog-content">
                            {/* <div className="product-search-wrapper">
                                <div>
                                    <label className="label">Produkt du vil tilføje til indkøbe</label>
                                </div>
                                <div>
                                    <AutoCompleteComponent id="products" dataSource={this.supplierProductsData} ref={acc => this.productAutoCompleteRef = acc} placeholder="Søg efter Produkt Nr/Produkt beskrivelse" 
                                        select={this.onProductSelected} autofill={true} fields={this.autoCompleteFields} />
                                    <button id="addProductToOrder" type="button" onClick={this.onAddProductToList}><i className="c-icon c-icon-add-black" style={{marginTop: 2}}></i><span style={{verticalAlign: 'top', fontSize: 14}}>Tilføje</span></button>
                                </div>
                            </div> */}
                            <div>
                                <table className="product-filter-table">
                                    <thead>
                                        <tr>
                                            <th width="35%" align="left">Produkt nr.</th>
                                            <th width="35%" align="left">Produkt beskrivelse</th>
                                            <th width="30%">Antal</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            filteredProducts.length > 0 ? 
                                            filteredProducts.map((prod, index) => {
                                                let desc = prod.Description1;

                                                if (desc.length > 30) {
                                                    desc = desc.substring(0, 30) + '...';
                                                }

                                                return <tr>
                                                    <td>{prod.ProductNo}</td>
                                                    <td>{desc}</td>
                                                    <td style={{position: 'relative'}}>
                                                        {/* {toDanishNumberFormat(prod.Quantity)} */}
                                                        {/* <input type="number" value={prod.Quantity} className="is-numeric e-input e-lib text-right" style={{ width: 81 }} onChange={this.onProductQuantityChange.bind(this, index)} /> */}
                                                        <NumberFormat type="text" thousandSeparator="." decimalSeparator="," className="is-numeric e-input e-lib text-right" value={prod.Quantity} 
                                                            allowNegative={false} onValueChange={(values) => this.onProductQuantityChange(index, values )} style={{ width: 81 }} />
                                                        <div className="quantiy-counter-wrapper">
                                                            <div className="quantity--up" onClick={this.onQuantityChange.bind(this, "+", index)}><i className="c-icon c-icon-chevron-arrow-up"></i></div>
                                                            <div className="quantity--down" onClick={this.onQuantityChange.bind(this, "-", index)}><i className="c-icon c-icon-chevron-arrow-down"></i></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            })
                                            :
                                            <tr className="text-center">
                                                <td colSpan="3">Ingen poster at vise</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="dialog-footer">
                            <button className="e-control e-btn e-lib" style={{width: 122}} onClick={this.cancelConfirm}>Annuller</button>
                            <button className="e-control e-btn e-lib e-primary" style={{width: 122}} onClick={this.onAddProduct}>Gem</button>
                        </div>
                </DialogComponent>
            </div>
        )
    }
}

PurchaseOrder.contextType = AppContext;
export default PurchaseOrder;