import HttpClient from '../utilities/HttpClient';

const API = {    
    getMenus: `/menu`,
    postSearchSite: '/SiteSearchable/customer/{customerId}',
    getContexts: '/settings/searchContexts',
    getEntityTypes: '/settings/entityTypes'
}

class MenuService {
    async GetMenus(cancelToken) {
        return await HttpClient.Get(API.getMenus, null, cancelToken);
    }
    async GetSearchContexts(cancelToken) {
        return await HttpClient.Get(API.getContexts, null, cancelToken);
    }

    async SearchSite(customerId, data, cancelToken) {
        const url = API.postSearchSite.replace("{customerId}", customerId);
        return await HttpClient.Post(url, data, cancelToken);
    }

    async GetEntityTypes(cancelToken) {
        return await HttpClient.Get(API.getEntityTypes, null, cancelToken);
    }
}

const menuService = new MenuService();
export default menuService;