import HttpClient from '../../utilities/HttpClient';

const API = {
    getProductGroups: `/productGroup/{customer_id}/byCustomer`,
    getProductGroupsLite: `/productGroup/{customer_id}/byCustomer/lite`,
    getProductGroup: `/productGroup/{productgroup_id}`,
    postProductGroup: `/productGroup`,
    putProductGroup: `/productGroup`,
    deleteProductGroup: `/productGroup/{productgroup_id}`,
    putProductGroupImage: `/productGroup/image`,
    postBulkSave: '/productGroup/bulkSave'
}

class ProductGroupService {
    async GetProductGroups(customerId, cancelToken) {
        const url = API.getProductGroups.replace('{customer_id}', customerId);
        let result = await HttpClient.Get(url, null, cancelToken);

        if (!result.HasError) {
            result = this.buildProductGroupStructure(result);
        }
        // result = result.map(data => {
        //     debugger
        //     const mergedData = data.Products.concat(data.SubGroups)
        //     return {
        //         ...data,
        //         ImageKey: data.Image ? data.Image.Key : null,
        //         ImageName: data.Image ? data.Image.Name : null,
        //         ProductsAndGroups: mergedData
        //     }
        // });

        return result
    }

    buildProductGroupStructure(data) {
        data.forEach(p => {
            p.ImageKey = p.Image ? p.Image.Key : null;
            p.ImageName = p.Image ? p.Image.Name : null;
            p.ExpenseGroupId = p.ExpenseGroup ? p.ExpenseGroup.Id : null;
            p.ExpenseGroupName = p.ExpenseGroup ? p.ExpenseGroup.Name : null;

            if (p.SubGroups && p.SubGroups.length > 0) {
                p.SubGroups = this.buildSubGroupStructure(p.SubGroups);
            }

            const mergedData = p.Products.concat(p.SubGroups);
            p.ProductsAndGroups = mergedData;
        });

        return data;
    }

    buildSubGroupStructure(subGroups) {
        subGroups.forEach(sb => {
            sb.ImageKey = sb.Image ? sb.Image.Key : null;
            sb.ImageName = sb.Image ? sb.Image.Name : null;
            sb.ExpenseGroupId = sb.ExpenseGroup ? sb.ExpenseGroup.Id : null;
            sb.ExpenseGroupName = sb.ExpenseGroup ? sb.ExpenseGroup.Name : null;

            if (sb.SubGroups && sb.SubGroups.length > 0) {
                sb.SubGroups = this.buildSubGroupStructure(sb.SubGroups);
            }

            const mergedData = sb.Products.concat(sb.SubGroups);
            sb.ProductsAndGroups = mergedData;
        });
        return subGroups;
    }

    async GetProductGroupsLite(customerId, cancelToken) {
        const url = API.getProductGroupsLite.replace('{customer_id}', customerId);
        let result = await HttpClient.Get(url, null, cancelToken);
        
        if (!result.HasError) {
            result = result.map(x => {
                if (x.SubGroups.length > 0) {
                    x.expanded = true;
                    x.SubGroups = this.expandNodes(x.SubGroups);
                }
                return x;
            });
        }
        
        return result;
    }

    expandNodes(subGroup) {
        subGroup.forEach(s => {
            if (s.SubGroups.length > 0) {
                s.expanded = true;
                s.SubGroups = this.expandNodes(s.SubGroups);
            }
        });
        return subGroup;
    }

    async GetProductGroup(id, cancelToken) {
        const url = API.getProductGroup.replace("{productgroup_id}", id);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async AddUpdateProductGroup(state, data, cancelToken) {
        const payload = {
            Name: data.Name,
            Factor: data.Factor,
            ProfitPercentage: data.ProfitPercentage,
            GuaranteedWeight: data.GuaranteedWeight,
            PurchaseTolerance: data.PurchaseTolerance,
            ParentId: data.ParentId,
            CustomerId: data.CustomerId,
            ActiveCaseNumber: data.ActiveCaseNumber,
            ExpenseGroupId: data.ExpenseGroupId
        };

        switch (state) {
            case 'add':
                return await HttpClient.Post(API.postProductGroup, payload, cancelToken);
            case 'edit':
                payload.Id = data.Id;
                return await HttpClient.Put(API.putProductGroup, payload, cancelToken);
        }
    }

    async DeleteProductGroup(id, cancelToken) {
        const url = API.deleteProductGroup.replace("{productgroup_id}", id);
        return await HttpClient.Delete(url, null, cancelToken);
    }

    async LinkUnlinkProductGroupImage(data, cancelToken) {
        return await HttpClient.Put(API.putProductGroupImage, data, cancelToken);
    }

    async BulkSave(data, cancelToken) {
        return await HttpClient.Post(API.postBulkSave, data, cancelToken);
    }
}

const productGroupService = new ProductGroupService();
export default productGroupService;