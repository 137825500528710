import HttpClient from '../utilities/HttpClient';

const API = {
    getProductLabels: '/product/{customer_id}/byCustomer/labels',
    getLocationLabels: '/stock/{customer_id}/byCustomer/labels',
}

class LabelService {
    async GetProductLabels(customerId, cancelToken) {
        const url = API.getProductLabels.replace('{customer_id}', customerId);
        return await HttpClient.Get(url, null, cancelToken);
    }
    async GetLocationLabels(customerId, cancelToken) {
        const url = API.getLocationLabels.replace('{customer_id}', customerId);
        return await HttpClient.Get(url, null, cancelToken);
    }
}

const labelService = new LabelService();
export default labelService;