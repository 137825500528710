import React, { Component } from 'react';
import { ToolbarComponent, ItemsDirective, ItemDirective, TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
// import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Page, Sort, Toolbar, Selection, Edit, Search, Reorder, Resize } from '@syncfusion/ej2-react-grids';

import NavigationPrompt from '../../components/NavigationPrompt';
import Localization from '../../utilities/Localization';
import MessageHelper from '../../utilities/MessageHelper';

import SessionService from '../../services/SessionService';
import ExpenseGroupService from '../../services/Customer/ExpenseGroupService';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class CostGroup extends Component {
    groupEditingKey = "GroupEditKey";

    constructor() {
        super();

        this.state = {
            hasUnsavedChanges: false,
            expenseGroups: [],
            loading: true,
            isEditMode: false,
            selectedRowId: -1,
            activeRowId: -1,
            tempList: [],
            tempIndexId: 4999
        }

        this.onToolbarClicked = this.onToolbarClicked.bind(this);
        this.onRowSelect = this.onRowSelect.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onRowDoubleClick = this.onRowDoubleClick.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.groupEditing = this.groupEditing.bind(this);
        
        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.deleteConfirmationButtons = [
            {
                click: () => {
                    let { tempList, hasUnsavedChanges } = this.state;
                    if (tempList.some(x => x.IsDelete)) {
                        tempList = tempList.filter(x => !x.IsDelete);
                    }

                    if (tempList.length <= 0) {
                        hasUnsavedChanges = false;
                    }

                    this.setState({ hasUnsavedChanges, tempList });
                    this.deleteConfirmationDialog.hide();
                },
                buttonModel: {
                    content: 'Annuller'
                }
            },
            {
                click: () => {
                    this.confirmDelete();
                    this.deleteConfirmationDialog.hide();
                },
                buttonModel: {
                    isPrimary: true,
                    content: 'Slet'
                }
            }
        ];
        /* End Dialog options  */
    }

    async componentDidMount() {
        source.cancel();
        source = CancelToken.source();

        const session = await SessionService.GetSessionInfo();
        this.CustomerId = session.Id;
        const result = await ExpenseGroupService.GetList(session.Id, source.token);

        this.setState({ loading: false, expenseGroups: result }, () => {
            this.toolbarRef.enableItems(2, false);
            this.toolbarRef.enableItems(3, false);
        });
    }

    componentDidUpdate(prevProps, prevState) {
        // const { forceSave } = this.props;
        // if (forceSave) {
        //     this.onToolbarClicked({ item: { id: 'update' } })
        // }
    }

    async onToolbarClicked(args) {
        if (args.item.id === 'add') {
            let { expenseGroups, selectedRowId, tempIndexId, tempList } = this.state;
            tempIndexId = tempIndexId + 1;
            expenseGroups.push({ Id: tempIndexId, Name: 'Ny gruppe', CustomerId: this.CustomerId });
            tempList.push({ TempId: tempIndexId, Name: 'Ny gruppe', CustomerId: this.CustomerId });
                
            this.toolbarRef.enableItems(1, false);
            this.toolbarRef.enableItems(2, true);
            this.toolbarRef.enableItems(3, true);

            this.setState({ expenseGroups, selectedRowId: tempIndexId, tempIndexId, tempList, isEditMode: true, hasUnsavedChanges: true }, () => {
                let input = document.getElementsByClassName(`input-${tempIndexId}`)[0];
                if (input) {
                    input.focus();
                    input.select();
                }
            });
        }
        else if (args.item.id === 'edit') {
            let { activeRowId, selectedRowId } = this.state;

            if (activeRowId !== -1) {
                selectedRowId = activeRowId;
            }

            this.toolbarRef.enableItems(1, false);
            this.toolbarRef.enableItems(2, true);
            this.toolbarRef.enableItems(3, true);
            this.setState({ isEditMode: true, selectedRowId }, () => {
                if (selectedRowId !== -1) {
                    let inputElem = document.querySelector('.cost-group-content .table input.e-input');
                    if (inputElem) {
                        inputElem.focus();
                    }
                }
            });
        }
        else if (args.item.id === 'update') {
            let { tempList } = this.state;
            let payload = [];
            let errorList = [];

            if (tempList.length <= 0) {
                this.dialogOpen(Localization.General.NoteHeader, Localization.General.NoChangesMade);
                this.setState({ isEditMode: false });
                return;
            }


            for (let index = 0; index < tempList.length; index++) {
                const element = tempList[index];
                
                if (element.Name === "Ny gruppe") {
                    errorList.push("Ugyldigt navn. Standardnavnet kan ikke bruges.");
                    break;
                }
                
                payload.push({
                    Id: element.Id ? element.Id : 0,
                    Name: element.Name,
                    CustomerId: this.CustomerId,
                    IsDelete: element.IsDelete ? element.IsDelete : false
                });
            }

            if (errorList.length > 0) {
                this.props.onNotify("Advarsel", errorList.join('<br />'));
                return;
            }

            const result = await ExpenseGroupService.Save(payload, source.token);
            
            if (!result.HasError) {
                // const result = await ExpenseGroupService.GetList(this.CustomerId, source.token);
                sessionStorage.removeItem(this.groupEditingKey);
                this.toolbarRef.enableItems(1, true);
                this.toolbarRef.enableItems(2, false);
                this.toolbarRef.enableItems(3, false);
                this.setState({ isEditMode: false, selectedRowId: -1, activeRowId: -1, hasUnsavedChanges: false, tempList: [], expenseGroups: result });
                this.props.onNotify(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Omkostnings Gruppe"));
            }
            else {
                this.props.onNotify("Advarsel", result.ErrorMessage);
            }
        }
        else if (args.item.id === 'cancel') {
            const result = await ExpenseGroupService.GetList(this.CustomerId, source.token);

            this.toolbarRef.enableItems(1, true);
            this.toolbarRef.enableItems(2, false);
            this.toolbarRef.enableItems(3, false);
            this.setState({ isEditMode: false, expenseGroups: result, tempList: [], selectedRowId: -1, activeRowId: -1, hasUnsavedChanges: false });
        }
        else if (args.item.id === 'delete') {
            let { activeRowId, expenseGroups, tempList } = this.state;

            if (activeRowId > 0) {
                // if (expenseGroups.some(x => x.Id === activeRowId)) {
                //     expenseGroups = expenseGroups.filter(x => x.Id !== activeRowId);
                // }

                if (tempList.some(x => x.TempId === activeRowId)) {
                    tempList = tempList.filter(x => x.TempId !== activeRowId);
                }
                else {
                    tempList.push({
                        Id: activeRowId,
                        CustomerId: this.CustomerId,
                        IsDelete: true
                    });
                }
                
                this.setState({ expenseGroups, tempList, activeRowId: -1, hasUnsavedChanges: true });
                this.deleteConfirmationDialog.show();
            }
            else {
                this.props.onNotify("Advarsel", Localization.General.PleaseSelectItem.replace("{context}", "Expense group"));
            }
        }
    }

    async confirmDelete() {
        let { tempList } = this.state;
        let payload = [];

        for (let index = 0; index < tempList.length; index++) {
            const element = tempList[index];
            
            if (element.IsDelete) {
                payload.push({
                    Id: element.Id ? element.Id : 0,
                    Name: element.Name,
                    CustomerId: this.CustomerId,
                    IsDelete: element.IsDelete ? element.IsDelete : false
                });
            }
        }

        if (payload.length > 0) {
            const result = await ExpenseGroupService.Save(payload, source.token);
    
            if (!result.HasError) {
                const result = await ExpenseGroupService.GetList(this.CustomerId, source.token);
    
                this.toolbarRef.enableItems(1, true);
                this.toolbarRef.enableItems(2, false);
                this.toolbarRef.enableItems(3, false);
    
                this.setState({ isEditMode: false, tempList: [], selectedRowId: -1, activeRowId: -1, expenseGroups: result });
                this.props.onNotify(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Omkostnings Gruppe"));
            }
            else {
                this.props.onNotify("Advarsel", result.ErrorMessage);
            }
        }
    }

    onRowSelect(id) {
        let { isEditMode, selectedRowId } = this.state;
        if (isEditMode) {
            selectedRowId = id;
        }

        this.setState({ activeRowId: id, selectedRowId }, () => {
            let inputElem = document.querySelector('.cost-group-content .table input.e-input');
            if (inputElem) {
                inputElem.focus();
            }
        });
    }

    groupEditing() {
        let { hasUnsavedChanges } = this.state;
        if (hasUnsavedChanges) {
            sessionStorage.setItem(this.groupEditingKey, "Expense Group");
        }
    }

    onChange(e, id) {
        let { expenseGroups, tempList } = this.state;

        let group = expenseGroups.find(e => e.Id === id);
        let temp = tempList.find(e => e.TempId === id || e.Id === id);

        if (group) {
            group.Name = e.target.value;
        }

        if (temp) {
            temp.Name = e.target.value;
        }
        else {
            tempList.push(group);
        }

        this.setState({ expenseGroups, tempList, hasUnsavedChanges: true }, () => {
            this.groupEditing();
        });
    }

    onKeyUp(e, id) {
        e.stopPropagation();

        let { isEditMode } = this.state;
        if (isEditMode) {
            if (e.keyCode === 13) {
                this.setState({ selectedRowId: -1 });
            }
        }
    }

    onRowDoubleClick(Id) {
        let { isEditMode } = this.state;
        if (isEditMode) {
            this.setState({ activeRowId: Id, selectedRowId: Id }, () => {
                let inputElem = document.querySelector('.cost-group-content .table input.e-input');
                if (inputElem) {
                    inputElem.focus();
                }
            });
        }
    }

    render() {
        const { hasUnsavedChanges, expenseGroups, isEditMode, selectedRowId, activeRowId } = this.state;

        return (
            <div className="cost-group-wrapper">
                <NavigationPrompt when={hasUnsavedChanges} 
                    navigate={path => this.props.history.push(path)}
                    initiateSave={() => this.onToolbarClicked({ item: { id: 'update' } })}
                    onCancelSave={() => sessionStorage.removeItem(this.groupEditingKey)}
                    shouldBlockNavigation={location => {
                        if (hasUnsavedChanges) {
                            return true;
                        }
                        return false;
                    }}
                />
                <div className="cost-group">
                    <div className="cost-group-toolbar-wrapper c-toolbar-plain">
                        <ToolbarComponent ref={ref => this.toolbarRef = ref} clicked={this.onToolbarClicked}>
                            <ItemsDirective>
                                <ItemDirective id="add" prefixIcon='tb-icons c-icon-add-black' text="Ny" tooltipText='Ny' />
                                <ItemDirective id="edit" prefixIcon='tb-icons c-icon-edit' text="Redigere" tooltipText='Redigere' />
                                <ItemDirective id="update" prefixIcon='tb-icons c-icon-update' text="Gem" tooltipText='Gem' />
                                <ItemDirective id="cancel" prefixIcon='tb-icons c-icon-cancel' text="Annuller" tooltipText='Annuller' />   
                                <ItemDirective id="delete" prefixIcon='tb-icons c-icon-delete' text="Slet" tooltipText='Slet' />
                            </ItemsDirective>
                        </ToolbarComponent>
                    </div>
                    <div className="cost-group-content">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="label">Omkostnings Gruppe</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    expenseGroups.length <= 0 ? 
                                    <tr>
                                        <td className="sub-text text-center">{Localization.General.EmptyList}</td>
                                    </tr>
                                    :
                                    expenseGroups.map(res => {
                                        let enabled = res.Id === selectedRowId;
                                        let active = res.Id === activeRowId;
                                        return (
                                            <tr className={`${active ? 'is-selected' : ''}`} onDoubleClick={(e) => this.onRowDoubleClick(res.Id)} onClick={(e) => this.onRowSelect(res.Id)}>
                                                <td>
                                                    {
                                                        enabled ? <input className={`e-input input-${res.Id}`} value={res.Name} onChange={(e) => this.onChange(e, res.Id)} onKeyUp={(e) => this.onKeyUp(e, res.Id)} /> 
                                                        : <span className="regular-text">{res.Name}</span>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <DialogComponent isModal={true} width='auto' ref={dialog => this.deleteConfirmationDialog = dialog} target='body' buttons={this.deleteConfirmationButtons}
                        header="Advarsel" content={MessageHelper.MB7.message.replace("{context}", "omkostnings gruppe")}
                        visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} />
                </div>
            </div>
        )
    }
}

export default withRouter(CostGroup);
