import React, { Component } from "react";

import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";

export default class OilmatUserData extends Component {
    onChangeHandler = (args) => {
        this.props.onChange(args.event.target.name, args.value);
    }
    
    render() {
        const { data, validation, t } = this.props;
        
        return (
            <div>
                <div className="title">{t('TitleUserData')}</div>
                <div className="info-text m-bottom-15">{t('RequiredFieldsInfoText')}</div>
                
                <div className="m-bottom-10">
                    <TextBoxComponent
                        name="User.FirstName"
                        value={data.User.FirstName}
                        placeholder={t('LabelFirstName') + "*"}
                        input={this.onChangeHandler} 
                        floatLabelType="Auto"
                        cssClass={`${validation.hasError && validation.errors.some(e => e.id === "User.FirstName") ? "e-error" : ""}`}
                    />
                </div>
                <div className="m-bottom-10">
                    <TextBoxComponent
                        name="User.MiddleName"
                        value={data.User.MiddleName}
                        placeholder={t("LabelMiddleName")}
                        input={this.onChangeHandler} 
                        floatLabelType="Auto"
                    />
                </div>
                <div className="m-bottom-10">
                    <TextBoxComponent
                        name="User.LastName"
                        value={data.User.LastName}
                        placeholder={t("LabelLastName") + "*"}
                        input={this.onChangeHandler} 
                        floatLabelType="Auto"
                        cssClass={`${validation.hasError && validation.errors.some(e => e.id === "User.LastName") ? "e-error" : ""}`}
                    />
                </div>
                <div className="m-bottom-10">
                    <TextBoxComponent
                        name="User.Initials"
                        value={data.User.Initials}
                        placeholder={t("LabelInitials")}
                        input={this.onChangeHandler} 
                        floatLabelType="Auto"
                    />
                </div>
                <div className="m-bottom-10">
                    <TextBoxComponent
                        name="User.PhoneNumber"
                        value={data.User.PhoneNumber}
                        placeholder={t("LabelTelephoneNumber")}
                        input={this.onChangeHandler} 
                        floatLabelType="Auto"
                    />
                </div>
                <div className="m-bottom-10">
                    <TextBoxComponent
                        name="User.Username"
                        value={data.User.Username}
                        placeholder={t('LabelMailUsername') + "*"}
                        input={this.onChangeHandler} 
                        floatLabelType="Auto" 
                        cssClass={`${validation.hasError && validation.errors.some(e => e.id === "User.Username") ? "e-error" : ""}`}
                    />
                </div>
            </div>
        );
    }
}