import React from 'react';
// import { TabComponent, TabItemDirective, TabItemsDirective, TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { Redirect } from 'react-router-dom';

import BaseComponent from '../../components/BaseComponent';
import CustomerGroup from '../Customer/CustomerGroup';
import UserGroup from '../User/UserGroup';
import ProductGroupSettings from '../Product/ProductGroupSettings';
import ResponsibilityGroup from './ResponsibilityGroup';
import ExpenseGroup from './ExpenseGroup';

import Loading from '../../components/Loading';
import AppContext from '../../context/AppContext';

export class SystemGroups extends BaseComponent {
    moduleName = "System indstillinger";
    pageName = "System Indstillinger - Grupper";
    groupEditingKey = "GroupEditKey";
    
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            defaultSelectedTab: 0,
            permissions: [],
            hasProductAccess: false,
            redirect: {
                to: '',
                isRedirect: false
            },
            currentTabSelected: 0
        }
        
        this.tabHeader = [
            { text: "Kunde Grupper", url: "/system-indstillinger/grupper/kundegruppe" }, 
            { text: "Brugergrupper", url: "/system-indstillinger/grupper/brugergrupper" }, 
            { text: "Ansvars Grupper", url: "/system-indstillinger/grupper/ansvars-grupper" }, 
            { text: "Produkt Grupper", url: "/system-indstillinger/grupper/produkt-grupper" }, 
            { text: "Omkostnings Grupper", url: "/system-indstillinger/grupper/omkostnings-grupper" }
        ];

        this.dialogOpen = this.dialogOpen.bind(this);
        this.onOpenProduct = this.onOpenProduct.bind(this);
        this.onTabSelected = this.onTabSelected.bind(this);
        this.customerGroupTab = this.customerGroupTab.bind(this);
        this.userGroupsTab = this.userGroupsTab.bind(this);
        this.productGroupsTab = this.productGroupsTab.bind(this);
        this.responsibilityGroupsTab = this.responsibilityGroupsTab.bind(this);
        this.expenseGroupsTab = this.expenseGroupsTab.bind(this);
        
        this.defaultSelectedTab = 0;
        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.buttons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        /* End Dialog options  */
    }

    componentDidMount() {
        super.componentDidMount();
        const { location } = this.props;
        let { currentTabSelected } = this.state;
        let headerText = '';
        
        if (location) {
            if (location.state) {
                if (location.state.tab) {
                    let header = this.tabHeader.find(x => x.text === location.state.tab);
                    currentTabSelected = this.tabHeader.indexOf(header);
                    headerText = header.text;
                }
            }
            else {
                let header = this.tabHeader.find(x => x.url === location.pathname);
                currentTabSelected = this.tabHeader.indexOf(header);
                headerText = header.text;
            }
        }

        const { siteRoutes } = this.context;
        let hasProductAccess = false;
        let groupsRoute = [];
        let permissions = [];
        
        // get groups
        if (siteRoutes) {
            let route = null;
            // if (siteRoutes.some(x => x.Name === "Kunde")) {
            //     route = siteRoutes.find(x => x.Name === "Kunde");
            //     if (route.MenuItems.some(a => a.Name === "Kunde Grupper")) {
            //         groupsRoute.push('Kunde Grupper');
            //         permissions.push(route.MenuItems.find(a => a.Name === "Kunde Grupper"));
            //     }
            // }
            // if (siteRoutes.some(x => x.Name === "Bruger")) {
            //     route = siteRoutes.find(x => x.Name === "Bruger");
            //     if (route.MenuItems.some(a => a.Name === "Brugergrupper")) {
            //         groupsRoute.push('Brugergrupper');
            //         permissions.push(route.MenuItems.find(a => a.Name === "Brugergrupper"));
            //     }
            // }
            if (siteRoutes.some(x => x.Name === "Produkt")) {
                route = siteRoutes.find(x => x.Name === "Produkt");
                // if (route.MenuItems.some(a => a.Name === "Produkt Grupper")) {
                //     groupsRoute.push('Produkt Grupper');
                //     permissions.push(route.MenuItems.find(a => a.Name === "Produkt Grupper"));
                // }

                if (route.MenuItems.some(a => a.Name === "Produktkort")) {
                    let productCard = route.MenuItems.find(a => a.Name === "Produktkort");
                    if (productCard.MenuTabs.some(f => f.Name === "Pris")) {
                        hasProductAccess = true;
                    }
                }
            }
        }

        if (!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }
        else if (super.hasMenuItem("Grupper")) {
            groupsRoute = [];
            groupsRoute.push('Kunde Grupper');
            groupsRoute.push('Brugergrupper');
            groupsRoute.push('Ansvars Grupper');
            groupsRoute.push('Produkt Grupper');
            groupsRoute.push('Omkostnings Grupper');
        }
        else {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }

        this.setState({ loading: false, currentTabSelected, groupsRoute, permissions, hasProductAccess }, () => {
            let headerElement = document.querySelector('.module-header > h1');
            if (headerElement) {
                headerElement.innerHTML = headerText;
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { location } = this.props;
        const { location: prevLocation } = prevProps;

        if (location && prevLocation) {
            if (!location.state) {
                if (location.pathname !== prevLocation.pathname) {
                    let header = this.tabHeader.find(x => x.url === location.pathname);
                    let currentTabSelected = this.tabHeader.indexOf(header);
                    let headerText = header.text;
                    this.setState({ currentTabSelected }, () => {
                        let headerElement = document.querySelector('.module-header > h1');
                        headerElement.innerHTML = headerText;
                    });
                }
            }
        }
    }
    
    tabSelected(args) {
        const selectedTab = this.tabHeader[args.selectedIndex];
        let headerElement = document.querySelector('.module-header > h1');
        headerElement.innerHTML = selectedTab.text;
        this.props.history.push({
            pathname: selectedTab.url,
            state: {
                tab: selectedTab.text
            }
        });
    }

    onTabSelected(index) {
        const selectedTab = this.tabHeader[index];
        let headerElement = document.querySelector('.module-header > h1');
        headerElement.innerHTML = selectedTab.text;
        this.props.history.push({
            pathname: selectedTab.url,
            state: {
                tab: selectedTab.text
            }
        });

        let grouEditing = sessionStorage.getItem(this.groupEditingKey);
        if (grouEditing) {
            this.setState({ forceSave: true });
            return;
        }
        this.setState({ currentTabSelected: index });
    }

    onOpenProduct(url) {
        this.props.history.push(url);
    }

    dialogOpen(header, content) {
        this.notificationDialog.header = header;
        this.notificationDialog.content = content;
        this.notificationDialog.show();
    }

    customerGroupTab() {
        return <CustomerGroup onNotify={this.dialogOpen} />
    }

    userGroupsTab() {
        return <UserGroup onNotify={this.dialogOpen} />
    }

    responsibilityGroupsTab() {
        let { forceSave } = this.state;
        return <ResponsibilityGroup onNotify={this.dialogOpen} forceSave={forceSave} />
    }

    productGroupsTab() {
        const { permissions, hasProductAccess } = this.state;
        return <ProductGroupSettings onNotify={this.dialogOpen} onOpenProduct={this.onOpenProduct} hasProductAccess={hasProductAccess} />
    }

    expenseGroupsTab() {
        let { forceSave } = this.state;
        return <ExpenseGroup onNotify={this.dialogOpen} forceSave={forceSave} />
    }

    render() {
        const { loading, defaultSelectedTab, groupsRoute, redirect, currentTabSelected } = this.state;

        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        return (
            <div className='content-pane-wrapper'>
                <div className="module-header">
                    <h1>{this.tabHeader[defaultSelectedTab].text}</h1>
                </div>
                <div className='control-section tab-control'>
                    {/* <TabComponent ref={ref => this.accountTabRef = ref} selectedItem={defaultSelectedTab} heightAdjustMode='Auto' id='account-tab' selected={this.tabSelected.bind(this)}>
                        <TabItemsDirective>
                            <TabItemDirective header={this.tabHeader[0]} content={this.customerGroupTab.bind(this)} disabled={!groupsRoute.some(g => g === this.tabHeader[0].text)} visible={groupsRoute.some(g => g === this.tabHeader[0].text)} />
                            <TabItemDirective header={this.tabHeader[1]} content={this.userGroupsTab.bind(this)} disabled={!groupsRoute.some(g => g === this.tabHeader[1].text)} visible={groupsRoute.some(g => g === this.tabHeader[1].text)} />
                            <TabItemDirective header={this.tabHeader[2]} content={this.productGroupsTab.bind(this)} disabled={!groupsRoute.some(g => g === this.tabHeader[2].text)} visible={groupsRoute.some(g => g === this.tabHeader[2].text)}  />
                        </TabItemsDirective>
                    </TabComponent> */}
                    <Tabs id="account-tab" className="c-tab" selectedIndex={currentTabSelected} onSelect={index => this.onTabSelected(index)} disabledTabClassName="c-tabs__tab--disabled">
                        <TabList className="c-tab__list">
                            {super.hasMenuItemTabAccess("Grupper", "Kunde Gruppe") && 
                                <Tab className={`c-tabs__tab ${groupsRoute.some(g => g === this.tabHeader[0].text) ? '' : 'hidden'}`} disabled={!groupsRoute.some(g => g === this.tabHeader[0].text)} selectedClassName="c-tabs__tab--selected">{this.tabHeader[0].text}</Tab>
                            }
                            {super.hasMenuItemTabAccess("Grupper", "Brugergrupper") &&
                                <Tab className={`c-tabs__tab ${groupsRoute.some(g => g === this.tabHeader[1].text) ? '' : 'hidden'}`} disabled={!groupsRoute.some(g => g === this.tabHeader[1].text)} selectedClassName="c-tabs__tab--selected">{this.tabHeader[1].text}</Tab>
                            }
                            {super.hasMenuItemTabAccess("Grupper", "Ansvars Grupper") && 
                                <Tab className={`c-tabs__tab ${groupsRoute.some(g => g === this.tabHeader[2].text) ? '' : 'hidden'}`} disabled={!groupsRoute.some(g => g === this.tabHeader[2].text)} selectedClassName="c-tabs__tab--selected">{this.tabHeader[2].text}</Tab>
                            }
                            {super.hasMenuItemTabAccess("Grupper", "Produkt Grupper") && 
                                <Tab className={`c-tabs__tab ${groupsRoute.some(g => g === this.tabHeader[3].text) ? '' : 'hidden'}`} disabled={!groupsRoute.some(g => g === this.tabHeader[3].text)} selectedClassName="c-tabs__tab--selected">{this.tabHeader[3].text}</Tab>
                            }
                            {super.hasMenuItemTabAccess("Grupper", "Omkostnings Grupper") && 
                                <Tab className={`c-tabs__tab ${groupsRoute.some(g => g === this.tabHeader[4].text) ? '' : 'hidden'}`} disabled={!groupsRoute.some(g => g === this.tabHeader[4].text)} selectedClassName="c-tabs__tab--selected">{this.tabHeader[4].text}</Tab>
                            }
                        </TabList>
                        {super.hasMenuItemTabAccess("Grupper", "Kunde Gruppe") && 
                            <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                                {this.customerGroupTab()}
                            </TabPanel>
                        }
                        {super.hasMenuItemTabAccess("Grupper", "Brugergrupper") && 
                            <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                                {this.userGroupsTab()}
                            </TabPanel>
                        }
                        {super.hasMenuItemTabAccess("Grupper", "Ansvars Grupper") && 
                            <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                                {this.responsibilityGroupsTab()}
                            </TabPanel>
                        }
                        {super.hasMenuItemTabAccess("Grupper", "Produkt Grupper") && 
                            <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                                {this.productGroupsTab()}
                            </TabPanel>
                        }
                        {super.hasMenuItemTabAccess("Grupper", "Omkostnings Grupper") && 
                            <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                                {this.expenseGroupsTab()}
                            </TabPanel>
                        }
                    </Tabs>
                </div>
                <DialogComponent id="system-group-dialog" isModal={true} buttons={this.buttons} width='auto' ref={dialog => this.notificationDialog = dialog} target='body'
                    visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} />
            </div>
        )
    }
}

SystemGroups.contextType = AppContext;
export default SystemGroups;