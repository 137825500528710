import HttpClient from '../../utilities/HttpClient';

const API = {
    getExpenseGroup: '/expensegroup/{customerId}',
    postExpenseGroup: '/expensegroup'
};

class ExpenseGroupService {
    async GetList(id, cancelToken) {
        const url = API.getExpenseGroup.replace('{customerId}', id);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async Save(data, cancelToken) {
        return await HttpClient.Post(API.postExpenseGroup, data, cancelToken);
    }
}

const expenseGroupService = new ExpenseGroupService();
export default expenseGroupService;