import React, { Component } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Page, Sort, Toolbar, Selection, Edit, Search, Reorder, Resize } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons'; //ChipDirective, ChipListComponent, ChipsDirective
import { withRouter } from 'react-router-dom';

import NavigationPrompt from '../../components/NavigationPrompt';
import Chips from '../../components/Chips';
import AppContext from '../../context/AppContext';
import Image from '../../components/Image';
import placeholder from '../../assets/placeholders/img_placeholder_108x80.jpg';

import SettingsService from '../../services/SettingsService';
import ProductService from '../../services/Product/ProductService';
import SupplierService from '../../services/Supplier/SupplierService';
import ProductGroupService from '../../services/Product/ProductGroupService';
import DepartmentService from '../../services/Customer/DepartmentService';
import AuthenticationService from '../../services/AuthenticationService';
import SessionService from '../../services/SessionService';
import StockService from '../../services/Stock/StockService';
import Axios from 'axios';
import Localization from '../../utilities/Localization';
import messageHelper from '../../utilities/MessageHelper';
import { buildGridSettings } from '../../utilities/Helper';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class AssignedProducts extends Component {
    IS_ASSIGNING_PRODUCTS_KEY = "IS_ASSIGNING_PRODUCTS";
    gridSettingsKey = "AssignedProductsList";
    assignProductPFGridSettingsKey = "assignProductPFList"; // Product filter key
    assignProductAFGridSettingsKey = "assignProductAFList"; // Additional filter key
    hasDraggedColumn = false;
    gridSettings = '';
    assignProductPFGridSettings = "";
    assignProductAFGridSettings = "";

    constructor() {
        super();

        this.state = {
            loading: true,
            filters: {
                productGroup: 0,
                suppliers: [],
                department: null,
                tempLinkProducts: []
            },
            isFilteringProducts: false,
            selectedSuppliers: [],
            hasUnsavedChanges: false
        }
        
        this.assignedProductsData = [];
        this.selectedProductsData = [];
        this.departmentsData = [];
        this.productGroupData = [];
        this.suppliersData = [];
        this.isSave = false;

        this.defaultPageSettings = {
            pageSize: 6,
            pageSizes: ['Alle', '5', '10', '20', '50', '100']
        }

        this.pageSettings = {
            // pageSize: 5,
            pageSizes: ['Alle', '5', '10', '20', '50', '100']
        };

        this.defaultSelectionSettings = {
            type: 'Single'
        }

        this.selectionSettings = {
            type: 'Single',
            persistSelection: true
        }

        this.filterSettings = {
            type: 'Menu'
        };
        
        this.numericParams = { 
            params: { 
                showSpinButton: false, min: 0, step: 0
            }
        };

        this.animationSettings = { effect: 'None' };

        this.productGroupTreeFields = { dataSource: [], child: 'SubGroups', text: 'Name', id: 'Id' };

        this.init = this.init.bind(this);
        this.clickHandler = this.clickHandler.bind(this);
        this.actionBegin = this.actionBegin.bind(this);
        this.assignLocationTemplate = this.assignLocationTemplate.bind(this);
        this.onNodeSelected = this.onNodeSelected.bind(this);
        this.linkTemplate = this.linkTemplate.bind(this);
        this.refreshFilterProductGrid = this.refreshFilterProductGrid.bind(this);
        this.onAdditionalFilterClick = this.onAdditionalFilterClick.bind(this);
        this.removeFilter = this.removeFilter.bind(this);
        this.onSaveAdditionalFilterClick = this.onSaveAdditionalFilterClick.bind(this);
        this.onLinkProducts = this.onLinkProducts.bind(this);
        this.refreshMachineProductsGrid = this.refreshMachineProductsGrid.bind(this);
        this.onCloseFilter = this.onCloseFilter.bind(this);
        this.onOpenLocation = this.onOpenLocation.bind(this);
        this.executeStock = this.executeStock.bind(this);
        this.linkHeaderTemplate = this.linkHeaderTemplate.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.onNodeCheck = this.onNodeCheck.bind(this);

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.notificationButtons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        /* End Dialog options  */
        

        this.maxQtyEditParams = { params: {
            change: (e) => {
                if (e.isInteracted) { 
                    var inputEle = this.assignedProductsGridRef.element.querySelector(".e-grid .e-gridform input[name='PurchaseQty']"); 
                    if (inputEle) { 
                      var pQtyEle = inputEle.previousElementSibling.ej2_instances[0]; 
                      pQtyEle.value = null; 
                    } 
                }
            },
            showSpinButton: false, min: 0, step: 0, format: 'n2'
        }};

        this.purchaseQtyEditParams = { params: {
            change: (e) => {
                if (e.isInteracted) { 
                    var inputEle = this.assignedProductsGridRef.element.querySelector(".e-grid .e-gridform input[name='MaxQty']"); 
                    if (inputEle) { 
                      var maxqQtyEle = inputEle.previousElementSibling.ej2_instances[0]; 
                      maxqQtyEle.value = null; 
                    } 
                }
            },
            showSpinButton: false, min: 0, step: 0, format: 'n2'
        }};
    }

    async componentDidMount() {
        this.init();

        const { machineId, forceUpdate } = this.props;
        const sessionInfo = await SessionService.GetSessionInfo();

        const machineProducts = await StockService.GetMachineStock(machineId, source.token);
        // this.assignedProductsGridRef.datasource = machineProducts;
        this.assignedProductsData = machineProducts;

        const productGroup = await ProductGroupService.GetProductGroupsLite(sessionInfo.Id, source.token);
        this.productGroupTreeFields.dataSource = productGroup;

        const departments = await DepartmentService.GetDepartments(source.token);
        this.departmentsData = departments;

        let payload = {GroupIds: [], DeptIds: []};
        const initialFilteredProducts = await ProductService.FilterProduct(sessionInfo.Id, payload, source.token);
        this.selectedProductsData = initialFilteredProducts;

        let supplierProducts = await ProductService.GetSupplierProducts(sessionInfo.Id, source.token);
        let suppIds = supplierProducts.map(s => s.SupplierId).filter((value, index, self) => self.indexOf(value) === index);
        let nonExistingSupplier = [];

        let customerSuppliers = await SupplierService.GetSuppliers(sessionInfo.Id, source.token);
        customerSuppliers = customerSuppliers.filter(x => !suppIds.includes(x.Id));
        customerSuppliers.forEach(cs => {
            nonExistingSupplier.push({ SupplierId: cs.Id, SupplierCompany: cs.Company, SupplierProductNo: '', SupplierEAN: ''});
        });
        supplierProducts = supplierProducts.concat(nonExistingSupplier);

        this.suppliersData = supplierProducts.map((s, index) => {
            return {
                ...s,
                rowIndex: index
            }
        });


        const gridSettings = await SettingsService.GetGridSettings(this.gridSettingsKey, source.token);
        if (!gridSettings.HasError) {
            this.gridSettings = gridSettings.Settings;
        }

        const assignProductPFGridSettings = await SettingsService.GetGridSettings(this.assignProductPFGridSettingsKey, source.token);
        if (!assignProductPFGridSettings.HasError) {
            this.assignProductPFGridSettings = assignProductPFGridSettings.Settings;
        }

        const assignProductAFGridSettings = await SettingsService.GetGridSettings(this.assignProductAFGridSettingsKey, source.token);
        if (!assignProductAFGridSettings.HasError) {
            this.assignProductAFGridSettings = assignProductAFGridSettings.Settings;
        }

        this.setState({ loading: false }, () => {
            setTimeout(() => {
                let editToolbar = document.querySelector('.assign-product-pane .e-grid .e-toolbar-item[title="Redigere"] span.e-tbar-btn-text');
                if (editToolbar) {
                    editToolbar.innerHTML = "Rediger produkt";
                }
            }, 300);

            window.addEventListener('wheel', this.onWheelScroll);
        });
    }

    init() {
        this.toolbarOptions = [];
        this.defaultEditSettings = {};

        this.toolbarOptions.push({ text: 'Tilføj produkt', tooltipText: 'Tilføj produkt', id: 'add', prefixIcon: 'e-add' });
        // this.toolbarOptions.push({ text: 'Rediger produkt', tooltipText: 'Rediger produkt', id: 'edit', prefixIcon: 'e-edit' });
        this.toolbarOptions.push('Edit');
        this.toolbarOptions.push('Update');
        this.toolbarOptions.push('Cancel');
        this.toolbarOptions.push({ text: 'Slette', tooltipText: 'Slette', id: 'delete', prefixIcon: 'e-delete' });
        this.toolbarOptions.push({ text: Localization.General.GridResetFilter, tooltipText: Localization.General.GridResetFilter, id: 'reset_filter', prefixIcon: 'e-cancel' });
        this.defaultEditSettings.allowEditing = true;
        this.defaultEditSettings.allowDeleting = true;
        this.defaultEditSettings.showDeleteConfirmDialog = true;
    }

    componentDidUpdate() {
        // const { showAddProduct } = this.props;
        // if (this.assignedProductsGridRef) {
        //     const dataSource = [...this.assignedProductsGridRef.dataSource];
        //     if (showAddProduct && dataSource.length <= 0) {
        //         this.productFiltersDialog.show();
        //         if (this.productFiltersDialog) {
        //             let productGroupFilterPane = document.getElementsByClassName('product-group-filter')[0];
        //             let productGroupTree = document.getElementById('product-group-tree');

        //             if (productGroupFilterPane) {
        //                 productGroupTree.style.height = (productGroupFilterPane.clientHeight - 70) + 'px';
        //                 productGroupTree.style.overflowY = "auto";
        //             }
        //         }
        //     }
        // }
    }

    async clickHandler(args) {
        if (args.item.id === 'add') {
            // let { filters } = this.state;
            // filters.tempLinkProducts = [];
            // filters.productGroup = 0;
            // filters.suppliers = [];
            // filters.department = null;
            this.productGroupTreeRef.selectedNodes = [];
            this.productFilterGridRef.clearRowSelection();

            // this.setState({ filters, selectedSuppliers: [] }, () => {
                this.refreshFilterProductGrid().then(() => {
                    this.productFiltersDialog.show();
    
                    if (this.productFiltersDialog) {
                        let productGroupFilterPane = document.getElementsByClassName('product-group-filter')[0];
                        let productGroupTree = document.getElementById('product-group-tree');
    
                        if (productGroupFilterPane) {
                            productGroupTree.style.height = (productGroupFilterPane.clientHeight - 70) + 'px';
                            productGroupTree.style.overflowY = "auto";
                        }
                    }
                });
            // });
        }
        // else if (args.item.id === 'edit') {
        //     const { onNotify } = this.props;
        //     const selectedRecords = this.assignedProductsGridRef.getSelectedRecords();
            
        //     if (selectedRecords.length > 0) {
        //         sessionStorage.setItem(this.IS_ASSIGNING_PRODUCTS_KEY, "true");
        //         this.assignedProductsGridRef.toolbarModule.enableItems(['edit'], false);
                
        //         if (this.assignedProductsGridRef.selectedRowIndex > 0) {
        //             this.assignedProductsGridRef.startEdit();
        //         }
        //     }
        //     else {
        //         onNotify("Advarsel", Localization.General.NoRowSelected);
        //     }
        // }
        else if (args.item.id === 'delete') {
            const { onNotify } = this.props;
            const selectedRecords = this.assignedProductsGridRef.getSelectedRecords();

            if (selectedRecords.length > 0) {
                this.notificationDialog.header = "Advarsel";
                this.notificationDialog.content = "Du er i gang med at fjerne et produkt fra automaten";
                let buttons = [
                    {
                        // Click the footer buttons to hide the Dialog
                        click: () => {
                            this.notificationDialog.hide();
                        },
                        buttonModel: {
                            content: 'Annuller'
                        }
                    },
                    {
                        click: async () => {
                            await this.executeStock('delete', selectedRecords);
                            this.notificationDialog.hide();
                        },
                        buttonModel: {
                            //Enables the primary button
                            isPrimary: true,
                            content: 'Slet'
                        }
                    }
                ];

                this.notificationDialog.buttons = buttons;
                this.notificationDialog.show();
            }
            else {
                onNotify("Advarsel", Localization.General.NoRowSelected);
            }
        } else if (args.item.id === 'reset_filter') {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                settings.filters = [];
                this.gridSettings = settings;
                const payload = {
                    Key: this.gridSettingsKey,
                    Settings: JSON.stringify(this.gridSettings)
                };
    
                const result = await SettingsService.SaveGridSettings(payload, source.token);
    
                if (!result.HasError) {
                    const {onNotify} = this.props;
                    onNotify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                }
            }

            this.assignedProductsGridRef.clearFiltering();
        }
    }

    async actionBegin(args) {
        if (args.requestType === 'beginEdit' || (args.requestType === 'add' && args.type === 'actionBegin') || (args.requestType === 'save' && args.type === 'actionBegin')) {
            this.isSave = false;
        }

        if (args.requestType === 'beginEdit') {
            sessionStorage.setItem(this.IS_ASSIGNING_PRODUCTS_KEY, "true");
            this.assignedProductsGridRef.toolbarModule.enableItems(['delete'], false);
        }

        if (args.requestType === 'save' && !this.isSave) {
            args.cancel = true;
            this.isSave = false;
            let hasError = false;
            const { onNotify } = this.props;

            if ((!args.data.MaxQty || args.data.MaxQty <= 0) && (!args.data.PurchaseQty || args.data.PurchaseQty <= 0))
            {
                hasError = true;
            }

            if (hasError) {
                onNotify("Advarsel", "For at finde en passende lokation kræver det at Max eller Indkøbs antal er udfyldt.");
                return;
            }

            const action = args.data.Id ? 'update' : 'add';
            this.executeStock(action, args);
        }
        else if (args.requestType === 'cancel') {
            this.assignedProductsGridRef.toolbarModule.enableItems(['delete'], true);
        }
        // else if (args.requestType === 'delete') {
        //     args.cancel = true;
        //     this.executeStock('delete', args);
        // }
    }

    async executeStock(state = null, args) {
        const { onNotify, machineId } = this.props;
        // const action = args.data.Id ? 'update' : 'add';
        let result = null;

        switch (state) {
            case 'add':
            case 'update':
                const payload = {
                    Id: args.data.Id,
                    ProductId: args.data.Product.Id,
                    MinQty: args.data.MinQty ? args.data.MinQty : 0 ,
                    MaxQty: args.data.MaxQty <= 0 ? null : args.data.MaxQty,
                    PurchaseQty: args.data.PurchaseQty <= 0 ? null: args.data.PurchaseQty,
                    StandardLocation: args.data.StandardLocation,
                    MachineId: machineId,
                    Location: null
                };
        
                if (args.data.Id) {
                    if (args.data.Room.Id && args.data.Box.Id) {
                        payload.Location = {
                            Id: args.data.Room.Id,
                            BoxId: args.data.Box.Id,
                            RoomNoStart: args.data.Room.RoomNoStart,
                            RoomNoEnd: args.data.Room.RoomNoEnd
                        }
                    }
                    else {
                        payload.Location = null;
                    }
                }
        
                result = await StockService.AddUpdateStock(state, payload, source.token);
                
                if (!result.HasError) {
                    this.isSave = true;
                    
                    let value = sessionStorage.getItem(this.IS_ASSIGNING_PRODUCTS_KEY);
                    if (value) {
                        if (value === "save") {
                            onNotify('Udført ', Localization.General.SuccessUpdate.replace("{context}", "Produktliste"));
                            this.props.onNavigate();
                        }
                        else {
                            this.assignedProductsGridRef.endEdit();
                            sessionStorage.removeItem(this.IS_ASSIGNING_PRODUCTS_KEY);
                            onNotify('Udført ', Localization.General.SuccessUpdate.replace("{context}", "Produktliste"));
                            await this.refreshMachineProductsGrid(args.rowIndex);
                        }
                    }
                }
                else {
                    this.isSave = true;
                    onNotify('Advarsel', result.ErrorMessage);
                }
                break;
            case 'delete':
                result = await StockService.DeleteStock(args[0].Id, source.token);
                if (!result.HasError) {
                    onNotify(Localization.General.SuccessHeader, Localization.General.SuccessDelete.replace("{context}", "Produkt"));
                    await this.refreshMachineProductsGrid();
                }
                else {
                    onNotify('Advarsel', result.ErrorMessage);
                }
                break;
        }
    }

    onRowSelected(args) {
        let { filters } = this.state;
        let dataSource = [...this.productFilterGridRef.dataSource];
        
        dataSource[args.rowIndex].isLinked = !dataSource[args.rowIndex].isLinked;
        this.productFilterGridRef.dataSource = dataSource;
        this.productFilterGridRef.dataBind();

        let data = dataSource[args.rowIndex];
        if (!dataSource[args.rowIndex].isLinked) {
            filters.tempLinkProducts = filters.tempLinkProducts.filter(p => p.Id !== data.Id);
        }
        else {
            filters.tempLinkProducts.push({
                Id: data.Id,
                Description1: data.Description1,
                EAN: data.EAN,
                ProductImage: data.ProductImage,
                ProductNo: data.ProductNo,
                isLinked: !dataSource[args.rowIndex].isLinked
            });
        }

        this.setState({ filters });
    }

    async onNodeSelected(args) {
        let checkedNode = [args.node];
        if (args.event.target.classList.contains('e-fullrow') || args.event.key == "Enter") {
            this.productGroupTreeRef.uncheckAll();

            let { filters } = this.state;
            let getNodeDetails = this.productGroupTreeRef.getNode(args.node);
            if (getNodeDetails.isChecked == 'true') {
                this.productGroupTreeRef.uncheckAll(checkedNode);
                filters.productGroup = 0;
                this.setState({ filters }, () => {
                    this.refreshFilterProductGrid();
                });
            }
            else {
                this.productGroupFilterCheckedNode = args.node;
                this.productGroupTreeRef.checkAll(checkedNode);
                filters.productGroup = getNodeDetails;
                this.setState({ filters }, () => {
                    this.refreshFilterProductGrid();
                });
            }
        }

        // if (args.isInteracted) {
        //     let { filters } = this.state;
        //     if (filters.productGroup !== 0 && filters.productGroup.id === args.nodeData) {
        //         filters.productGroup = 0;
        //         this.setState({ filters }, () => {
        //             this.refreshFilterProductGrid();
        //         });
        //     }
        //     else {
        //         let checkedNode = [args.node.querySelector('div.e-checkbox-wrapper')];
        //         let nodeDetails = this.productGroupTreeRef.getNode(args.node);
        //         if (nodeDetails.isChecked == 'true') {
        //             filters.productGroup = 0;
        //             this.setState({ filters }, () => {
        //                 this.productGroupTreeRef.uncheckAll(checkedNode);
        //                 this.refreshFilterProductGrid();
        //             });
        //         }
        //         else {
        //             filters.productGroup = args.nodeData;
        //             this.setState({ filters }, () => {
        //                 this.productGroupTreeRef.checkAll(checkedNode);
        //                 this.refreshFilterProductGrid();
        //             });
        //         }
        //     }
        // }
    }

    async onNodeCheck(args) {
        if (args.isInteracted) {
            let { filters } = this.state;
            if (args.action === 'uncheck') {
                if (this.productGroupFilterCheckedNode) {
                    this.productGroupTreeRef.uncheckAll([this.productGroupFilterCheckedNode]);
                    this.productGroupFilterCheckedNode = undefined;
                }
                filters.productGroup = 0;
                this.setState({ filters }, () => {
                    this.refreshFilterProductGrid();
                });
            }
            else {
                if (this.productGroupFilterCheckedNode) {
                    this.productGroupTreeRef.uncheckAll([this.productGroupFilterCheckedNode]);
                }
                this.productGroupFilterCheckedNode = args.node.parentElement.parentElement;
                filters.productGroup = args.data[0];
                this.setState({ filters }, () => {
                    this.refreshFilterProductGrid();
                });
            }
        }
    }

    operationStatusTemplate(props) {
        let status = 'not-operational';
        let nums = [], hasBox = false, hasRoom = false;

        if (props.MaxQty && props.MaxQty > 0) {
            nums.push(props.MaxQty);
        }

        if (props.PurchaseQty && props.PurchaseQty > 0) {
            nums.push(props.PurchaseQty);
        }

        if (props.Box && props.Box.No) {
            hasBox = true;
        }

        if (props.Room && props.Room.Name) {
            hasRoom = true;
        }

        if (nums.length > 0 && hasBox && hasRoom) {
            status = 'operational'
        }

        return (
            <div className="text-center product-status-template">
                <div><div className={`${status}`}></div></div>
            </div>
        )
    }

    onOpenLocation(props) {
        const { onAssignProductLocation, onNotify } = this.props;
        if (props.MinQty !== null && (props.MaxQty || props.PurchaseQty)) {
            onAssignProductLocation(props);
        }
        else {
            onNotify("Der er ikke angivet min. / max. på produktet", "For at finde en passende lokation kræver det at Max eller Indkøbs antal er udfyldt.");
        }
    }

    assignLocationTemplate(props) {
        return (
            <i className="c-icon c-icon-external-link clickable" onClick={() => this.onOpenLocation(props)}></i>
        )
    }

    productImageTemplate(props) {
        let productImage = null;
        
        if (props.Product && props.Product.ProductImages.length > 0) {
            productImage = props.Product.ProductImages.find(p => p.IsPrimary);
        }
        else if (props.ProductImage) {
            productImage = props.ProductImage.find(p => p.IsPrimary);
        }

        if (productImage) {
            return (
                <div className="product-image-wrapper">
                    <Image src={productImage.ImageKey} alt="Product" className="product-image-data" />
                </div>
            )
        }

        return <img src={placeholder} className="product-image-data" />;
    }

    productDescriptionTemplate(props) {
        let description = props.Description1;
        if (description) {
            if (description.length > 50) {
                description = `${description.substring(0, 50)}...`;
            }
        }
        return (<div>{description}</div>)
    }

    linkTemplate(props) {
        return ( <div><CheckBoxComponent name="primary_picture" checked={props.isLinked} /></div> )
    }

    onCheckAllFiltered(args) {
        let dataSource = this.productFilterGridRef.dataSource;
        let { filters } = this.state;

        filters.tempLinkProducts = [];
        if (args.checked) {
            dataSource.forEach(x => {
                x.isLinked = true;                
                filters.tempLinkProducts.push({
                    Id: x.Id,
                    Description1: x.Description1,
                    EAN: x.EAN,
                    ProductImage: x.ProductImage,
                    ProductNo: x.ProductNo,
                    isLinked: true
                });
            });
        }
        else {
            dataSource.forEach(x => {
                x.isLinked = false;
            });
        }

        this.productFilterGridRef.dataSource = dataSource;
        this.productFilterGridRef.dataBind();

        this.setState({ filters })
    }

    linkHeaderTemplate(props) {
        return (
            <div className="c-col-header-label">
                <CheckBoxComponent label="Link" labelPosition="Before" ref={ref => this.chkAllFiltered} change={this.onCheckAllFiltered.bind(this)} />
            </div>
        )
    }

    roomNoTemplate(props) {
        let roomNo = '';
        if (props.Room && props.Room.Id) {
            roomNo = props.Room.RoomNoEnd;
        }

        return ( <span>{roomNo}</span> )
    }

    async refreshFilterProductGrid(suppliers = null) {
        let { filters, selectedSuppliers } = this.state;

        let payload = {};
        const sessionInfo = await SessionService.GetSessionInfo();

        if (filters.productGroup) {
            payload.GroupIds = [parseInt(filters.productGroup.id)];
        }

        if (suppliers) {
            selectedSuppliers = suppliers;
            if (selectedSuppliers.length > 0) {
                payload.SupplierIds = selectedSuppliers.map(x => x.SupplierId);
            }
        }
        else{
            if (selectedSuppliers.length > 0) {
                payload.SupplierIds = selectedSuppliers.map(x => x.SupplierId);
            }
        }

        if (this.ddlDepartmentRef.value) {
            payload.DeptIds = [this.ddlDepartmentRef.value];
        }
        const filteredProd = await ProductService.FilterProduct(sessionInfo.Id, payload, source.token);

        if (!filteredProd.HasError) {
            let dataSource = [];
            let productsLinked = [];
            
            let selectedRecords = this.productFilterGridRef.getSelectedRecords();
            selectedRecords.forEach((prod, index) => {
                dataSource.push({
                    Id: prod.Id,
                    Description1: prod.Description1,
                    EAN: prod.EAN,
                    ProductImage: prod.ProductImage,
                    ProductNo: prod.ProductNo,
                    isLinked: true
                });

                productsLinked.push(index);
            });

            filteredProd.forEach(prod => {
                if (dataSource.length > 0) {
                    if (!dataSource.some(d => d.Id === prod.Id)) {
                        dataSource.push({
                            Id: prod.Id,
                            Description1: prod.Description1,
                            EAN: prod.EAN,
                            ProductImage: prod.ProductImages,
                            ProductNo: prod.ProductNo,
                            isLinked: false
                        });
                    }
                }
                else {
                    dataSource.push({
                        Id: prod.Id,
                        Description1: prod.Description1,
                        EAN: prod.EAN,
                        ProductImage: prod.ProductImages,
                        ProductNo: prod.ProductNo,
                        isLinked: false
                    });
                }
            });

            this.selectedProductsData = dataSource;
            this.productFilterGridRef.dataSource = dataSource;
            this.productFilterGridRef.dataBind();
            this.setState({ selectedSuppliers }, () => {
                let settings = {};
                try {
                    settings = JSON.parse(this.assignProductPFGridSettings);
                } catch {
                    settings = this.assignProductPFGridSettings;
                }
                
                if (settings.pageSize) {
                    if (settings.pageSize === "Alle" || !this.productFilterGridRef.pageSettings.pageSizes.some(x => x === settings.pageSize.toString())) {
                        this.productFilterGridRef.pageSettings.pageSize = "Alle"; //this.pageSettings.pageSize;
                        document.querySelector("#product-filter-grid .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.productFilterGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
                else {
                    this.productFilterGridRef.pageSettings.pageSize = "Alle";
                    document.querySelector("#product-filter-grid .e-pagerdropdown input").value = "Alle";
                }
            });
        }
    }

    onAdditionalFilterClick() {
        this.supplierGridRef.clearRowSelection();
        this.additionalFiltersDialog.show();
    }
    
    onSupplierFilterDelete(args) {
        let { selectedSuppliers } = this.state;
        selectedSuppliers = selectedSuppliers.filter(s => s.SupplierCompany !== args.text);
        this.setState({ selectedSuppliers }, () => {
            this.refreshFilterProductGrid(selectedSuppliers);
        });
    }

    onLinkProducts(e) {
        let { filters } = this.state;
        const { onNotify, machineId } = this.props;

        let requests = [];

        let linkedProducts = this.productFilterGridRef.getSelectedRecords();

        if (linkedProducts.length <= 0) {
            onNotify('Advarsel', Localization.General.NoRowSelected);
            return;
        }

        this.btnLinkProducts.disabled = true;

        for (let index = 0; index < linkedProducts.length; index++) {
            const product = linkedProducts[index];
            const payload = {
                ProductId: product.Id,
                MinQty: 0,
                MaxQty: 0,
                PurchaseQty: null,
                StandardLocation: false,
                MachineId: machineId,
                Location: null
            }

            requests.push(StockService.AddUpdateStock('add', payload, source.token));
        }

        if (requests.length > 0) {
            this.productFiltersDialog.hide();
            this.btnLinkProducts.disabled = false;

            Axios.all(requests).then(Axios.spread(async (...response) => {
                const result = response[requests.length - 1];
                if (!result.HasError) {
                    // onNotify('Udført', Localization.General.SuccessAdd.replace("{context}", "Produkt"));
                    
                    await this.refreshMachineProductsGrid().then(() => {
                        let settings = {};
                        try {
                            settings = JSON.parse(this.gridSettings)
                        } catch {
                            settings = this.gridSettings;
                        }

                        if (settings.pageSize) {
                            if (settings.pageSize === "Alle" || !this.assignedProductsGridRef.pageSettings.pageSizes.some(x => x === settings.pageSize.toString())) {
                                this.assignedProductsGridRef.pageSettings.pageSize = "Alle"; //this.assignedProductsGridRef.pageSettings.totalRecordsCount;
                                document.querySelector("#stock-grid .e-pagerdropdown input").value = "Alle";
                            } else {
                                this.assignedProductsGridRef.pageSettings.pageSize = settings.pageSize;
                            }
                        }
                        // this.assignedProductsGridRef.refresh();
                    });
                }
                else {
                    onNotify('Advarsel', result.ErrorMessage);
                }
            }));
        }
    }

    onCloseFilter() {
        this.productFiltersDialog.hide();
    }

    async refreshMachineProductsGrid(removeIndex = null) {
        const { machineId } = this.props;

        const machineProducts = await StockService.GetMachineStock(machineId, source.token);
        this.assignedProductsGridRef.dataSource = machineProducts;
        this.assignedProductsGridRef.dataBind();
    }

    onSaveAdditionalFilterClick() {        
        let supplierProducts = this.supplierGridRef.getSelectedRecords();
        let selectedSuppliers = [];

        if (supplierProducts.length > 0) {
            supplierProducts.forEach(p => {
                if (!selectedSuppliers.some(s => s.SupplierCompany === p.SupplierCompany)) {
                    selectedSuppliers.push({ SupplierId: p.SupplierId, SupplierCompany: p.SupplierCompany});
                }
            });
        }

        this.refreshFilterProductGrid(selectedSuppliers);
        this.additionalFiltersDialog.hide();
    }

    removeFilter(args) {
        let { filters, selectedSuppliers } = this.state;
        if (args.target.id === 'remove-product-group-filter') {
            if (filters.productGroup !== 0)  {
                this.productGroupTreeRef.uncheckAll([this.productGroupFilterCheckedNode]);
                filters.productGroup = 0;
                this.productGroupFilterCheckedNode = undefined;
                this.setState({ filters }, () => {
                    this.refreshFilterProductGrid();
                });
            }
        }
        else if (args.target.id === 'remove-additional-filters') {
            if ((filters.suppliers && filters.suppliers.length > 0 && filters.department) || selectedSuppliers.length > 0) {
                filters.suppliers = [];
                filters.department = null;
                this.ddlDepartmentRef.value = null;
                this.supplierGridRef.clearRowSelection();
                this.setState({ filters, selectedSuppliers: null }, () => {
                    // let chipsWrapper = document.querySelector('.selected-filters #chip-avatar');
                    // chipsWrapper.innerHTML = '';
                    this.refreshFilterProductGrid([]);
                });
            }
        }

    }

    onColumnDragStart(args) {
        this.hasDraggedColumn = true;
    }

    onCreated() {
        if (this.gridSettings) {
            let settings = JSON.parse(this.gridSettings);
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(columns => {
                        if (columns.FromIndex !== columns.ToIndex) {
                            var tempIndex = this.assignedProductsGridRef.getColumnIndexByField(columns.Field);
                            if (tempIndex !== columns.ToIndex){
                                this.assignedProductsGridRef.reorderColumnByIndex(columns.FromIndex, columns.ToIndex);
                            }
                        }
    
                        if (columns.Width) {
                            this.assignedProductsGridRef.columns[columns.ToIndex].width = columns.Width;
                        }
                    });
                }
                
                if (settings.colName && settings.direction) {
                    this.assignedProductsGridRef.sortColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.assignedProductsGridRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.assignedProductsGridRef.refreshColumns();

                if (settings.pageSize) {
                    if (settings.pageSize === "Alle" || !this.assignedProductsGridRef.pageSettings.pageSizes.some(x => x === settings.pageSize.toString())) {
                        this.assignedProductsGridRef.pageSettings.pageSize = this.assignedProductsGridRef.pageSettings.totalRecordsCount;
                        document.querySelector("#stock-grid .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.assignedProductsGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
            }
        }
    }

    async onActionComplete(args) {
        if (args.requestType === "cancel" || args.requestType === "save") {
            this.assignedProductsGridRef.toolbarModule.enableItems(['edit'], true);
        }
        else if (args.requestType === 'paging') {
            if (this.assignedProductsGridRef.pageSettings.pageSize === this.assignedProductsGridRef.pageSettings.totalRecordsCount) {
                document.querySelector("#stock-grid .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, this.assignedProductsGridRef.pageSettings.pageSize, this.assignedProductsGridRef.pageSettings.totalRecordsCount, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };
            const result = await SettingsService.SaveGridSettings(payload, source.token);
        }
        else if (args.requestType === 'filtering') {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                const {onNotify} = this.props;
                onNotify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                const {onNotify} = this.props;
                onNotify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.assignedProductsGridRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, this.hasDraggedColumn, columns);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                const {onNotify} = this.props;
                onNotify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }

            this.hasDraggedColumn = false;
        }
    }

    onPFCreated() {
        if (this.assignProductPFGridSettings) {
            let settings = JSON.parse(this.assignProductPFGridSettings);
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(setting => {
                        if (setting.FromIndex !== setting.ToIndex) {
                            this.productFilterGridRef.reorderColumnByIndex(setting.FromIndex, setting.ToIndex);
                        }
    
                        if (setting.Width) {
                            this.productFilterGridRef.columns[setting.ToIndex].width = setting.Width;
                        }
                    });
                }
                
                if (settings.colName && settings.direction) {
                    this.productFilterGridRef.sortColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.productFilterGridRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }

                this.productFilterGridRef.refreshColumns();
                
                if (settings.pageSize) {
                    if (settings.pageSize === "Alle" || !this.productFilterGridRef.pageSettings.pageSizes.some(x => x === settings.pageSize.toString())) {
                        this.productFilterGridRef.pageSettings.pageSize = "Alle"; //this.pageSettings.pageSize;
                        document.querySelector("#product-filter-grid .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.productFilterGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
                else {
                    this.productFilterGridRef.pageSettings.pageSize = "Alle";
                    document.querySelector("#product-filter-grid .e-pagerdropdown input").value = "Alle";
                }
            }
        }
        else {
            this.productFilterGridRef.pageSettings.pageSize = this.productFilterGridRef.pageSettings.totalRecordsCount;
            this.productFilterGridRef.pageSettings.pageSize = "Alle";
            setTimeout(() => {
                document.querySelector("#product-filter-grid .e-pagerdropdown input").value = "Alle";
            }, 500);
        }
    }

    async onPFActionBegin(args) {
        if (args.requestType === "paging") {
            if (isNaN(this.productFilterGridRef.pageSettings.pageSize)) {
                this.productFilterGridRef.pagerModule.setPageSize(this.productFilterGridRef.pageSettings.totalRecordsCount);
            }
        }
    }

    async onPFActionComplete(args) {
        if (args.requestType === 'paging') {
            if (this.productFilterGridRef.pageSettings.pageSize === this.productFilterGridRef.pageSettings.totalRecordsCount) {
                document.querySelector("#product-filter-grid .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.assignProductPFGridSettings, this.productFilterGridRef.pageSettings.pageSize, this.productFilterGridRef.pageSettings.totalRecordsCount, false, []);
            this.assignProductPFGridSettings = tempGridSettings;
            const payload = {
                Key: this.assignProductPFGridSettingsKey,
                Settings: this.assignProductPFGridSettings
            };
            const result = await SettingsService.SaveGridSettings(payload, source.token);
        }
        else if (args.requestType === 'filtering') {
            if (this.assignProductPFGridSettings) {
                let settings = JSON.parse(this.assignProductPFGridSettings);
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let tempGridSettings = buildGridSettings(args, this.assignProductPFGridSettings, 0, 0, false, []);
            this.assignProductPFGridSettings = tempGridSettings;
            const payload = {
                Key: this.assignProductPFGridSettingsKey,
                Settings: this.assignProductPFGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                const {onNotify} = this.props;
                onNotify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.assignProductPFGridSettings) {
                let settings = JSON.parse(this.assignProductPFGridSettings);
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.assignProductPFGridSettings, 0, 0, false, []);
            this.assignProductPFGridSettings = tempGridSettings;
            const payload = {
                Key: this.assignProductPFGridSettingsKey,
                Settings: this.assignProductPFGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                const {onNotify} = this.props;
                onNotify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.productFilterGridRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.assignProductPFGridSettings, 0, 0, this.hasDraggedColumn, columns);
            this.assignProductPFGridSettings = tempGridSettings;
            // let tempCols = [], orderedColumns = [];

            // if (this.assignProductPFGridSettings) {
            //     orderedColumns = JSON.parse(this.assignProductPFGridSettings);
            // }

            // columns.forEach((column, index) => {
            //     if (index === args.fromIndex) {
            //         tempCols.push({
            //             HeaderText: column.headerText,
            //             Field: column.field,
            //             FromIndex: column.index,
            //             ToIndex: index
            //         });
            //     }
            //     else {
            //         if (orderedColumns.some(x => x.HeaderText === column.headerText)) { //Check if previously saved column has been reordered
            //             let col = orderedColumns.find(x => x.HeaderText === column.headerText)
            //             if (col.ToIndex !== index) {
            //                 tempCols.push({
            //                     HeaderText: col.HeaderText,
            //                     Field: col.Field,
            //                     FromIndex: col.FromIndex,
            //                     ToIndex: index
            //                 });
            //             }
            //         }
            //     }
            // });

            const payload = {
                Key: this.assignProductPFGridSettingsKey,
                Settings: this.assignProductPFGridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                const {onNotify} = this.props;
                onNotify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }

            this.hasDraggedColumn = false;
        }
    }

    onAFCreated() {
        if (this.assignProductAFGridSettings) {
            let settings = JSON.parse(this.assignProductAFGridSettings);
            if (settings) {
                settings.forEach(setting => {
                    if (setting.FromIndex !== setting.ToIndex) {
                        this.supplierGridRef.reorderColumnByIndex(setting.FromIndex, setting.ToIndex);
                    }

                    if (setting.Width) {
                        this.supplierGridRef.columns[setting.ToIndex].width = setting.Width;
                    }
                });
                this.supplierGridRef.refreshColumns();
            }
        }
    }

    async onAFActionComplete(args) {
        if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.supplierGridRef.getColumns();
            let tempCols = [], orderedColumns = [];

            if (this.assignProductAFGridSettings) {
                orderedColumns = JSON.parse(this.assignProductAFGridSettings);
            }

            columns.forEach((column, index) => {
                if (index === args.fromIndex) {
                    tempCols.push({
                        HeaderText: column.headerText,
                        Field: column.field,
                        FromIndex: column.index,
                        ToIndex: index
                    });
                }
                else {
                    if (orderedColumns.some(x => x.HeaderText === column.headerText)) { //Check if previously saved column has been reordered
                        let col = orderedColumns.find(x => x.HeaderText === column.headerText)
                        if (col.ToIndex !== index) {
                            tempCols.push({
                                HeaderText: col.HeaderText,
                                Field: col.Field,
                                FromIndex: col.FromIndex,
                                ToIndex: index
                            });
                        }
                    }
                }
            });

            const payload = {
                Key: this.assignProductAFGridSettingsKey,
                Settings: JSON.stringify(tempCols)
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                const {onNotify} = this.props;
                onNotify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }

            this.hasDraggedColumn = false;
        }
    }

    async onResizeStop(args) {
        args.requestType = "resizingGrid";
        let columns = this.assignedProductsGridRef.getColumns();
        let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, columns);
        this.gridSettings = tempGridSettings;
        const payload = {
            Key: this.gridSettingsKey,
            Settings: this.gridSettings
        };

        const result = await SettingsService.SaveGridSettings(payload, source.token);

        if (!result.HasError) {
            const {onNotify} = this.props;
            onNotify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
        }
    }

    async onPFResizeStop(args) {
        args.requestType = "resizingGrid";
        let tempGridSettings = buildGridSettings(args, this.assignProductPFGridSettings, 0, 0, false, []);
        this.assignProductPFGridSettings = tempGridSettings;
        const payload = {
            Key: this.assignProductPFGridSettingsKey,
            Settings: this.assignProductPFGridSettings
        };

        const result = await SettingsService.SaveGridSettings(payload, source.token);

        if (!result.HasError) {
            const {onNotify} = this.props;
            onNotify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
        }

        // let columns = [];

        // if (this.assignProductPFGridSettings) {
        //     columns = JSON.parse(this.assignProductPFGridSettings);
        // }

        // if (columns.length > 0) {
        //     let column = columns.find(c => c.Field === args.column.field);
        //     if (column) {
        //         column.FromIndex =  args.column.index;
        //         column.ToIndex = args.column.index;
        //         column.Width = args.column.width
        //     }
        //     else {
        //         columns.push({
        //             HeaderText: args.column.headerText,
        //             Field: args.column.field,
        //             FromIndex: args.column.index,
        //             ToIndex: args.column.index,
        //             Width: args.column.width
        //         });
        //     }
        // }
        // else {
        //     columns.push({
        //         HeaderText: args.column.headerText,
        //         Field: args.column.field,
        //         FromIndex: args.column.index,
        //         ToIndex: args.column.index,
        //         Width: args.column.width
        //     });
        // }

        // const payload = {
        //     Key: this.assignProductPFGridSettingsKey,
        //     Settings: JSON.stringify(columns)
        // };

        // const result = await SettingsService.SaveGridSettings(payload, source.token);

        // if (!result.HasError) {
        //     const {onNotify} = this.props;
        //     onNotify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
        // }
    }

    async onAFResizeStop(args) {
        let columns = [];

        if (this.assignProductAFGridSettings) {
            columns = JSON.parse(this.assignProductAFGridSettings);
        }

        if (columns.length > 0) {
            let column = columns.find(c => c.Field === args.column.field);
            if (column) {
                column.FromIndex =  args.column.index;
                column.ToIndex = args.column.index;
                column.Width = args.column.width
            }
            else {
                columns.push({
                    HeaderText: args.column.headerText,
                    Field: args.column.field,
                    FromIndex: args.column.index,
                    ToIndex: args.column.index,
                    Width: args.column.width
                });
            }
        }
        else {
            columns.push({
                HeaderText: args.column.headerText,
                Field: args.column.field,
                FromIndex: args.column.index,
                ToIndex: args.column.index,
                Width: args.column.width
            });
        }

        const payload = {
            Key: this.assignProductAFGridSettingsKey,
            Settings: JSON.stringify(columns)
        };

        const result = await SettingsService.SaveGridSettings(payload, source.token);

        if (!result.HasError) {
            const {onNotify} = this.props;
            onNotify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
        }
    }

    onFinish() {
        let isAssigningproducts = sessionStorage.getItem(this.IS_ASSIGNING_PRODUCTS_KEY);
        if (isAssigningproducts) {
            this.notificationDialog.header = "Advarsel";
            this.notificationDialog.content = messageHelper.MB1.message;
            let buttons = messageHelper.MB1.buttons;

            buttons[0].click = () => {
                this.notificationDialog.button = this.notificationButtons;
                this.notificationDialog.hide();
            }

            buttons[1].click = async () => {
                sessionStorage.setItem(this.IS_ASSIGNING_PRODUCTS_KEY, "save");
                this.assignedProductsGridRef.endEdit();
            }

            this.notificationDialog.buttons = buttons;
            this.notificationDialog.show();
            return;
        }
        else {
            this.props.onNavigate();
        }
    }

    onWheelScroll = () => {
        let grid = document.getElementById('stock-grid');
        let toolbar = document.querySelector('#stock-grid.e-grid .e-toolbar');
        let tableHeader = document.querySelector('#stock-grid .e-gridheader');

        if (toolbar && tableHeader) {
            let boundingRect = tableHeader.getBoundingClientRect();
            
            if (boundingRect.top < 90) {
                toolbar.classList.add('c-hover-grid-toolbar');
                toolbar.style.width = (grid.clientWidth - 5) + 'px';
            }
            else {
                toolbar.classList.remove('c-hover-grid-toolbar');
                toolbar.style.width = 'auto';
            }
        }
    }

    render() {
        const { filters, loading, selectedSuppliers } = this.state;
        const { header, headerCssClass, onNavigate } = this.props;

        if (loading) {
            return <div></div>
        }

        return (
            <div className="machine-module-wrapper">
                <div className="machine-module-header">
                    <div className="action-back">
                        <span className="clickable c-back-arrow" onClick={onNavigate}>Tilbage</span>
                    </div>
                    <div className={`machine-header ${headerCssClass}`} dangerouslySetInnerHTML={{ __html: header }}>
                    </div>
                    <div className="action-finish">
                        <button className="e-control e-btn e-primary btn-finish" onClick={this.onFinish}>Udført</button>
                    </div>
                </div>
                <div className="machine-module-content">
                    <div className="assign-product-pane">
                        <GridComponent id="stock-grid" ref={ref => this.assignedProductsGridRef = ref } dataSource={this.assignedProductsData} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings} toolbar={this.toolbarOptions}
                            editSettings={this.defaultEditSettings} selectionSettings={this.defaultSelectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both" actionBegin={this.actionBegin.bind(this)} 
                            toolbarClick={this.clickHandler} allowReordering={true} allowResizing={true} actionComplete={this.onActionComplete.bind(this)} created={this.onCreated.bind(this)} columnDragStart={this.onColumnDragStart.bind(this)}
                            resizeStop={this.onResizeStop.bind(this)}>
                            <ColumnsDirective>
                                <ColumnDirective field="Id" isPrimaryKey={true} visible={false} />
                                <ColumnDirective headerText="Billede" field="Billede" template={this.productImageTemplate} allowEditing={false} editTemplate={this.productImageTemplate}/>
                                <ColumnDirective headerText="Beskrivelse" field="Product.Description1" allowEditing={false}/>
                                <ColumnDirective headerText="Produkt Nr." field="Product.ProductNo" allowEditing={false} /> 
                                <ColumnDirective headerText="EAN" field="Product.EAN" allowEditing={false} /> 
                                <ColumnDirective headerText="Min" field="MinQty" editType="numericedit" edit={this.numericParams} textAlign="Right" format="n2"/> 
                                <ColumnDirective headerText="Max" field="MaxQty" editType="numericedit" edit={this.maxQtyEditParams} textAlign="Right" format="n2" /> 
                                <ColumnDirective headerText="Beholding" field="StockQty" allowEditing={false} textAlign="Right" /> 
                                <ColumnDirective headerText="Indkøbs antal" field="PurchaseQty" editType="numericedit" edit={this.purchaseQtyEditParams} textAlign="Right" format="n2" />
                                <ColumnDirective headerText="Lokation" field="Box.No" allowEditing={false} /> 
                                <ColumnDirective headerText="Rum#" field="Room.Name" template={this.roomNoTemplate} allowEditing={false} /> 
                                <ColumnDirective headerText="Tildel Lokation" field="AssignLocation" template={this.assignLocationTemplate} allowEditing={false} /> 
                                <ColumnDirective headerText="Status" field="Status" textAlign="Center" template={this.operationStatusTemplate} allowEditing={false} /> 
                            </ColumnsDirective>
                            <Inject services={[Page, Sort, Filter, Selection, Toolbar, Edit, Search, Reorder, Resize]} />
                        </GridComponent>
                    </div>
                    <DialogComponent ref={dialog => this.notificationDialog = dialog} id="machine-dialog" isModal={true} buttons={this.notificationButtons} width='auto' 
                        target='body' visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true}/>
                    <div>
                        <DialogComponent ref={dialog => this.productFiltersDialog = dialog} id="machine-product-filter-dialog" isModal={true} width='auto' target='body' visible={false} 
                            header="Vælg et eller flere produkter du ønsker at linke til automaten" showCloseIcon={true} animationSettings={this.animationSettings} close={() => {}} allowDragging={true} enableResize={true}>
                            <div className="product-filter-wrapper">
                                <div className="product-filters">
                                    <div className="flex flex-col h-100p">
                                        <div className="product-group-filter">
                                            <div>
                                                <h3>Produktgrupper</h3>
                                                <span id="remove-product-group-filter" className="clickable" onClick={this.removeFilter}>Nulstil filtre</span>
                                            </div>
                                            <TreeViewComponent id='product-group-tree' ref={ref => this.productGroupTreeRef = ref} fields={this.productGroupTreeFields} 
                                                // nodeSelected={this.onNodeSelected}
                                                nodeChecked={this.onNodeCheck}
                                                nodeClicked={this.onNodeSelected}
                                                showCheckBox={true}  allowMultiSelection={false} autoCheck={false} />
                                        </div>
                                        <div className="additional-filter">
                                            <div className="text-center">
                                                <button className="e-btn e-control" onClick={this.onAdditionalFilterClick}>Yderligere filtrer</button>
                                            </div>
                                            <div className="selected-filters">
                                                <div>
                                                    <Chips id="chip-avatar" items={selectedSuppliers} field={{text: "SupplierCompany"}} onDelete={this.onSupplierFilterDelete.bind(this)} />
                                                </div>
                                            </div>
                                            <div style={{textAlign: 'center', paddingTop: 15}}>
                                                <span id="remove-additional-filters" className="clickable" onClick={this.removeFilter}>Nulstil filtre</span>
                                            </div>
                                        </div>
                                        <div className="action-buttons">
                                            <button className="e-btn e-control" onClick={this.onCloseFilter}>Tilbage</button>
                                            <button ref={ref => this.btnLinkProducts = ref} className="e-btn e-control e-primary" onClick={this.onLinkProducts}>Færdig</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="product-selected">
                                    <GridComponent id="product-filter-grid" ref={ref => this.productFilterGridRef = ref } dataSource={this.selectedProductsData} allowPaging={true} allowSorting={true} 
                                        allowReordering={true} allowResizing={true} pageSettings={this.pageSettings} selectionSettings={this.selectionSettings} locale="da" gridLines="Both" width="100%"
                                        allowTextWrap='true' allowFiltering={true} filterSettings={this.filterSettings} actionBegin={this.onPFActionBegin.bind(this)} actionComplete={this.onPFActionComplete.bind(this)} 
                                        created={this.onPFCreated.bind(this)} columnDragStart={this.onColumnDragStart.bind(this)} resizeStop={this.onPFResizeStop.bind(this)}>
                                            {/* rowSelected={this.onRowSelected.bind(this)} */}
                                        <ColumnsDirective>
                                            <ColumnDirective type="checkbox" width="50" textAlign="Center" allowFiltering={false} allowSorting={false} />
                                            <ColumnDirective field="Id" visible={false} isPrimaryKey={true} allowFiltering={false} allowSorting={false} />
                                            <ColumnDirective headerText="Billede" template={this.productImageTemplate} allowFiltering={false} allowSorting={false} /> 
                                            <ColumnDirective headerText="Produkt Nr." field="ProductNo" allowFiltering={true} allowSorting={true} /> 
                                            <ColumnDirective headerText="Beskrivelse" field="Description1" template={this.productDescriptionTemplate} allowFiltering={true} allowSorting={true} />
                                            <ColumnDirective headerText="EAN" field="EAN" allowFiltering={true} allowSorting={true} /> 
                                            {/* <ColumnDirective headerText="Link" field="isLinked" width="90px" textAlign="Center" template={this.linkTemplate} headerTemplate={this.linkHeaderTemplate} allowFiltering={false} allowSorting={false} /> */}
                                        </ColumnsDirective>
                                        <Inject services={[Page, Sort, Selection, Search, Filter]} />
                                    </GridComponent>
                                </div>
                            </div>
                        </DialogComponent>
                        <DialogComponent ref={dialog => this.additionalFiltersDialog = dialog} id="additional-filter-dialog" isModal={true} header="Filtre" showCloseIcon={true} animationSettings={this.animationSettings} visible={false} allowDragging={true} enableResize={true}>
                            <div style={{paddingBottom: 15}}>
                                <div style={{marginBottom: 8}}>
                                    <label className="label">Filtrer på en Leverandør oplysning</label>
                                </div>
                                <GridComponent ref={ref => this.supplierGridRef = ref } id="products-additional-filters-dialog" dataSource={this.suppliersData} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings}
                                    selectionSettings={this.selectionSettings} locale="da" gridLines="Both" allowTextWrap='true' allowFiltering={true} filterSettings={this.filterSettings} actionComplete={this.onAFActionComplete.bind(this)} 
                                    created={this.onAFCreated.bind(this)} columnDragStart={this.onColumnDragStart.bind(this)} resizeStop={this.onAFResizeStop.bind(this)}>
                                    <ColumnsDirective>
                                        <ColumnDirective type="checkbox" width="50" />
                                        <ColumnDirective field="rowIndex" isPrimaryKey={true} visible={false} />
                                        <ColumnDirective headerText="Leverandør" field="SupplierCompany" /> 
                                        <ColumnDirective headerText="Leverandør Produkt #" field="SupplierProductNo" /> 
                                        <ColumnDirective headerText="Leverandør EAN #" field="SupplierEAN" />
                                    </ColumnsDirective>
                                    <Inject services={[Page, Sort, Selection, Search, Filter]} />
                                </GridComponent>
                            </div>
                            <div style={{paddingBottom: 15}}>
                                <label>Produkt findes i:</label>
                                <DropDownListComponent ref={ref => this.ddlDepartmentRef = ref} dataSource={this.departmentsData} placeholder="Vælg Afdeling" fields={{ text: 'Name', value: 'Id' }} />
                            </div>
                            <div className="action-buttons text-right">
                                <button className="e-btn e-control" onClick={() => this.additionalFiltersDialog.hide() }>Annuller</button>
                                <button className="e-btn e-control e-primary" onClick={this.onSaveAdditionalFilterClick}>Gem</button>
                            </div>
                        </DialogComponent>
                    </div>
                </div>
            </div>
        )
    }
}

AssignedProducts.contextType = AppContext;
export default withRouter(AssignedProducts);
