import React, { Component } from 'react';
import { ToolbarComponent, ItemsDirective, ItemDirective } from '@syncfusion/ej2-react-navigations'; //TabComponent, TabItemDirective, TabItemsDirective, 
// import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Redirect } from 'react-router-dom';
// import NavigationPrompt from '../../components/NavigationPrompt';

import Axios from 'axios';
import Card from '../../components/Card';
import { ValidateEmail } from '../../utilities/Helper';

import Breadcrumb from '../../components/Breadcrumb';
import regionService from '../../services/RegionService';
import SupplierService from '../../services/Supplier/SupplierService';
import CustomerService from '../../services/Customer/CustomerService';
import SettingsService from '../../services/SettingsService';
import SessionService from '../../services/SessionService';

import '../../styles/supplier.scss';
import '../../styles/tabs.scss';
import Loading from '../../components/Loading';
import Localization from '../../utilities/Localization';

export class ProductSupplier extends Component {
    source = Axios.CancelToken.source();

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            supplierData: {
                AllOrderInterval: 0,
                SupplierNo: '',
                Company: '',
                Street: '',
                PostalCodeId: null,
                CityId: null,
                RegionId: null,
                CountryId: null,
                MainPhone: '',
                MainEmail: '',
                VatNo: '',
                DeliveryTimeInDays: '',
                OrderTimeSend: '',
                OrderOutput: null,
                OrderOutputFormat: null,
                DeptOrderInterval: [],
                OrderOutputEmails: []
            },
            breadcrumbs: [{text: 'Leverandørliste', link: '/leverandor/leverandorliste'}, 'Leverandør Info'],
            dialogOpts: {
                redirectOnClose: false,
                redirectTo: ''
            },
            moduleState: 'add',
            isEditMode: false,
            currentTabSelected: 0,
            undoRedoStatus: {
                Redo: false,
                RedoAction: '',
                Undo: false,
                UndoAction: ''
            },
            redirect: {
                to: '',
                isRedirect: false
            },
            hasUnsavedChanges: false,
            hasEdit: {
                info: false,
                settings: false
            }
        }

        this.regionSource = []
        this.postalCodes = [];
        this.cities = [];
        this.regions = [];
        this.countries = [];
        this.outputData = [];
        this.fileFormatData = [];
        this.infoFields = ["SupplierNo", "Company", "Street", "MainPhone", "MainEmail", "VatNo" ];
        this.settingsFields = ["DeliveryTimeInDays", "AllOrderInterval", "OrderTimeSend", "OrderOutput", "OrderOutputFormat", "OutputEmails"];

        this.tabHeader = [ {text: 'Leverandør Info'}, 
            // {text: 'Leverandør Kontakt'}, 
            {text: 'Leverandør Indstillinger'}
        ];

        this.onPostalCodeFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('Value', 'startswith', e.text, true) : query;
            e.updateData(this.postalCodes, query)
        }

        this.onCityFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('ShortName', 'startswith', e.text, true) : query;
            e.updateData(this.cities, query)
        }

        this.onRegionFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('Name', 'startswith', e.text, true) : query;
            e.updateData(this.regions, query)
        }

        this.onCountryFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('Name', 'startswith', e.text, true) : query;
            e.updateData(this.countries, query)
        }
        
        // this.supplierInfoToolbarClicked = this.supplierInfoToolbarClicked.bind(this);
        this.toggleSupplierInfoFields = this.toggleSupplierInfoFields.bind(this);
        this.dialogOpen = this.dialogOpen.bind(this);
        this.dialogClose = this.dialogClose.bind(this);
        this.refreshSupplier = this.refreshSupplier.bind(this);
        this.undoRedoOptions = this.undoRedoOptions.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.onAddEmail = this.onAddEmail.bind(this);
        this.onChangeByName = this.onChangeByName.bind(this);
        this.supplierInfoTab = this.supplierInfoTab.bind(this);
        this.vendorSettingsTab = this.vendorSettingsTab.bind(this);
        this.onTabSelected = this.onTabSelected.bind(this);
        this.supplierInfoToolbarClicked = this.supplierInfoToolbarClicked.bind(this);
        this.onChange = this.onChange.bind(this);
        this.initiateSave = this.initiateSave.bind(this);

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.buttons = [{
            // Click the footer buttons to hide the Dialog
            click: this.dialogClose,
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        /* End Dialog options  */
    }

    async componentDidMount() {
        this.source.cancel();
        this.source = Axios.CancelToken.source();

        const { supplierId } = this.props;
        let moduleState = 'add';
        let { supplierData, isEditMode } = this.state;

        const sessionInfo = await SessionService.GetSessionInfo();

        const outputData = await SettingsService.GetOutputOptions(this.source.token);
        const fileFormatData = await SettingsService.GetFileFormats(this.source.token);

        this.outputData = outputData;
        this.fileFormatData = fileFormatData;

        if (supplierId) {
            const supplierResult = await SupplierService.GetSupplier(supplierId, this.source.token);
            if (supplierResult.HasError) {
                let result = super.processModuleResponse(supplierResult);
                this.setState(result);
                return;
            }
            supplierData = supplierResult;
            moduleState = 'edit';
            // isEditMode = true;
        }

        const departments = await CustomerService.GetAccountDepartments(sessionInfo.Id, this.source.token);
        supplierData.DeptOrderInterval = departments.map(dept => {
            let deptOrderInterval = null;

            if (supplierData.DeptOrderInterval) {
                deptOrderInterval = supplierData.DeptOrderInterval.find(x => x.DeptId === dept.Id);
            }
            
            return {
                Id: dept.Id,
                Name: dept.Name,
                OrderInterval: deptOrderInterval && deptOrderInterval.OrderInterval && deptOrderInterval.OrderInterval != 0 ? deptOrderInterval.OrderInterval : null
            }
        });
        this.accountDepartments = departments;

        // Get city and region
        const regions = await regionService.GetRegion(this.source.token);
        this.regionSource = regions;

        let tempRegions = [], tempCities = [];

        regions.forEach(region => {
            tempRegions.push(region);

            region.Cities.forEach(city => {

                // if (tempCities.some(x => x.ShortName === city.ShortName)) 
                //     return;

                tempCities.push(city);

                city.PostalCodes.forEach(postal => {
                    this.postalCodes.push(postal);
                })
            });
        });

        tempRegions = [...new Set(tempRegions)];
        tempCities = [...new Set(tempCities)];

        tempRegions.sort((a,b) => (a.value > b.value) ? 1 : -1);
        tempCities.sort((a,b) => (a.value > b.value) ? 1 : -1);

        this.cities = tempCities;
        this.regions = tempRegions;

        const countryResult = await SettingsService.GetCountries(this.source.token);
        this.countries = countryResult;

        this.setState({ loading: false, supplierData, moduleState, isEditMode });
    }

    async componentWillUnmount() {
        this.source.cancel();
    }

    onChange(args) {
        // let input = args.container.querySelector('input');
        // if (input) {
        //     let { supplierData } = this.state;
        //     supplierData[input.name] = args.value
        //     this.setState({ supplierData });
        // }
        
        let { supplierData, hasEdit } = this.state;
        
        if (this.infoFields.includes(args.target.name)) {
            hasEdit.info = true;
        } else if (this.settingsFields.includes(args.target.name)) {
            hasEdit.settings = true;
        }

        supplierData[args.target.name] = args.target.value;

        if (args.target.name === "AllOrderInterval") {
            supplierData.DeptOrderInterval.forEach(x => {
                if (x.OrderInterval === parseInt(supplierData.AllOrderInterval)) {
                    x.OrderInterval = null;
                    document.getElementById(`DeliveryTimeInDays_${x.Id}`).value = null;
                }
            });
        }

        this.setState({ supplierData, hasUnsavedChanges: true, hasEdit });
    }

    onPostalCodeChange(args) {
        if (args.isInteracted) {
            let city = this.cities.find(c => c.Id === args.itemData.CityId);

            this.CityRef.value = args.itemData.CityId;

            let tempRegions = this.regions.filter(region => region.Id === city.RegionId);
            let selectedRegion = this.regions.find(x => x.Id === city.RegionId);

            this.RegionRef.dataSource = tempRegions;
            this.RegionRef.dataBind();

            this.RegionRef.value = city.RegionId;
            this.RegionRef.text =  selectedRegion.Name;

            let { supplierData, hasEdit } = this.state;
            hasEdit.info = true;
            supplierData.PostalCodeId = args.value;
            supplierData.CityId = args.itemData.CityId;
            supplierData.RegionId = city.RegionId
            supplierData.CountryId = selectedRegion.CountryId;
            this.setState({ supplierData, hasUnsavedChanges: true, hasEdit });
        }
    }

    onCityChange(args) {
        if (args.isInteracted) {
            let { supplierData } = this.state;

            let tempRegions = this.regionSource.filter(region => region.Id === args.itemData.RegionId);    
            this.regions = tempRegions;
            this.RegionRef.text = null;
            this.RegionRef.dataSource = tempRegions;
            this.RegionRef.dataBind();
            
            if (supplierData.PostalCodeId !== args.itemData.PostalCodes[0].Id) {
                supplierData.PostalCodeId = args.itemData.PostalCodes[0].Id;
            }

            let selectedRegion = this.regions.find(x => x.Id === args.itemData.RegionId);
            this.RegionRef.value = args.itemData.RegionId;
            this.RegionRef.text = selectedRegion.Name;

            supplierData.CityId = args.value;
            supplierData.RegionId = args.itemData.RegionId;

            this.setState({ supplierData, hasUnsavedChanges: true });
        }
    }

    onRegionChange(args) {
        if (args.isInteracted) {
            let { supplierData } = this.state;
            supplierData.RegionId = args.value;
            supplierData.CountryId = args.itemData.CountryId
            this.setState({ supplierData, hasUnsavedChanges: true });
        }
    }

    onCountryChange(args) {
        if (args.isInteracted) {
            let { supplierData } = this.state;
            supplierData.CountryId = args.value;
            this.setState({ supplierData });
        }
    }
    
    refreshDropdown = () => {
        // this.PostalCodeRef.showPopup();
        // this.CityRef.showPopup();
        // this.RegionRef.showPopup();

        // setTimeout(() => {
        //     this.PostalCodeRef.hidePopup();
        //     this.CityRef.hidePopup();
        //     this.RegionRef.hidePopup();
        // }, 200);
    }

    async refreshSupplier() {
        let { supplierData, currentTabSelected } = this.state;
        const supplierResult = await SupplierService.GetSupplier(supplierData.Id, this.source.token);
        supplierData = supplierResult;
        
        switch(currentTabSelected) {
            case 0:
                this.SupplierNoRef.value = supplierData.SupplierNo;
                this.CompanyRef.value = supplierData.Company;
                this.StreetRef.value = supplierData.Street;

                if (supplierData.PostalCode) {
                    this.PostalCodeRef.value = supplierData.PostalCode.Id;
                    // let elemPostalCode = document.getElementById('ddlPostalCode').ej2_instances[0];
                    // if (elemPostalCode) {
                    //     elemPostalCode.refresh();
                    // }
                }
                else {
                    this.PostalCodeRef.value = null;
                    this.PostalCodeRef.text = null;
                }

                if (supplierData.City) {
                    this.CityRef.value = supplierData.City.Id;
                }
                else {
                    this.CityRef.value = null;
                    this.CityRef.text = null;
                }

                if (supplierData.Region) {
                    this.RegionRef.value = supplierData.Region.Id;
                }
                else {
                    this.RegionRef.value = null;
                    this.RegionRef.text = null;
                }

                if (supplierData.Country) {
                    this.CountryRef.value = supplierData.Country.Id;
                }
                else {
                    this.CountryRef.value = null;
                    this.CountryRef.text = null;
                }

                this.MainPhoneRef.value = supplierData.MainPhone;
                this.MainEmailRef.value = supplierData.MainEmail;
                this.VatNoRef.value = supplierData.VatNo;
                break;
            case 1:
                this.DeliveryTimeInDaysRef.value = supplierData.DeliveryTimeInDays;
                this.OrderTimeSendRef.value = supplierData.OrderTimeSend;
                this.AllOrderIntervalRef.enabled = supplierData.AllOrderInterval;
                this.OrderOutputRef.enabled = supplierData.OrderOutput;
                this.OrderOutputFormatRef.enabled = supplierData.OrderOutputFormat;
                break;
        }
        
        const sessionInfo = await SessionService.GetSessionInfo();
        const departments = await CustomerService.GetAccountDepartments(sessionInfo.Id, this.source.token);
        
        supplierData.DeptOrderInterval = departments.map(dept => {
            let deptOrderInterval = null;

            if (supplierData.DeptOrderInterval) {
                deptOrderInterval = supplierData.DeptOrderInterval.find(x => x.DeptId === dept.Id);
            }
            
            return {
                Id: dept.Id,
                Name: dept.Name,
                OrderInterval: deptOrderInterval && deptOrderInterval.OrderInterval && deptOrderInterval.OrderInterval != 0 ? deptOrderInterval.OrderInterval : null
            }
        });

        this.setState({ supplierData, hasUnsavedChanges: false });
    }

    toggleSupplierInfoFields(enabled, isEmptyValues = false) {
        const { currentTabSelected } = this.state;

        switch(currentTabSelected) {
            case 0:
                this.SupplierNoRef.disabled = !enabled;
                this.CompanyRef.disabled = !enabled;
                this.StreetRef.disabled = !enabled;
                this.PostalCodeRef.enabled = enabled;
                this.CityRef.enabled = enabled;
                this.RegionRef.enabled = enabled;
                // this.CountryRef.disabled = !enabled;
                this.MainPhoneRef.disabled = !enabled;
                this.MainEmailRef.disabled = !enabled;
                this.VatNoRef.disabled = !enabled;

                if (isEmptyValues) {
                    this.SupplierNoRef.value = '';
                    this.CompanyRef.value = '';
                    this.StreetRef.value = '';

                    this.PostalCodeRef.value = null;
                    this.PostalCodeRef.text = null;
                    this.CityRef.value = null;
                    this.CityRef.text = null;
                    this.RegionRef.value = null;
                    this.RegionRef.text = null;

                    this.CountryRef.value = null;
                    this.MainPhoneRef.value = null;
                    this.MainEmailRef.value = null;
                    this.VatNoRef.value = null;
                }
                break;
            case 1:
                this.DeliveryTimeInDaysRef.enabled = enabled;
                this.OrderTimeSendRef.enabled = enabled;
                this.AllOrderIntervalRef.enabled = enabled;
                this.OrderOutputRef.enabled = enabled;
                this.OrderOutputFormatRef.enabled = enabled;

                let customerDepartments = document.querySelector('table.customer-department-table');
                if (customerDepartments) {
                    let tableData = customerDepartments.querySelectorAll('tbody tr.department-row-data');

                    for (let index = 0; index < tableData.length; index++) {
                        let column = tableData[index].querySelector('td:last-child');
                        // let span = column.querySelector('span');
                        let input = column.querySelector('input.e-control.e-input');
                        
                        if (enabled) {
                            // span.classList.remove('e-disabled');
                            input.classList.remove('e-disabled');
                            input.removeAttribute('disabled');
                        }
                        else {
                            // span.classList.add('e-disabled');
                            input.classList.add('e-disabled');
                            input.setAttribute('disabled', 'disabled');
                        }
                    }
                }
                
                if (isEmptyValues) {
                    this.DeliveryTimeInDaysRef.value = '';
                    this.OrderTimeSendRef.value = '';
                    this.AllOrderIntervalRef.value = '';
                    this.OrderOutputRef.value = null;
                    this.OrderOutputRef.text = null;
                    this.OrderOutputFormatRef.value = null;
                    this.OrderOutputFormatRef.text = null;

                    let deptOrderIntervals = document.querySelectorAll(".department-row-data input");
                    if (deptOrderIntervals) {
                        for (let i = 0; i < deptOrderIntervals.length; i++) {
                            deptOrderIntervals[i].value = null;
                        }
                    }
                }
                break;
        }
    }

    supplierInfoTab() {
        const { supplierData, moduleState, isEditMode } = this.state
        
        let isEnabled = false;
        if (moduleState === 'add') {
            isEnabled = true;
        }
        else if (isEditMode) {
            isEnabled = true;
        }
        
        return (
            <div className="tab-control--item supplier-info-wrapper">
                <div>
                    <table className="supplier-info-table" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td className="label">Leverandør Nr.<span className="required">*</span></td>
                                <td>
                                    {/* <TextBoxComponent ref={ref => this.SupplierNoRef = ref} value={supplierData.SupplierNo} enabled={isEnabled} name="SupplierNo" /> */}
                                    <input type="text" ref={ref => this.SupplierNoRef = ref} value={supplierData.SupplierNo} disabled={!isEnabled} name="SupplierNo" className="e-control e-textbox e-lib e-input" onChange={this.onChange} />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Firma Navn<span className="required">*</span></td>
                                <td>
                                    {/* <TextBoxComponent ref={ref => this.CompanyRef = ref} value={supplierData.Company} enabled={isEnabled} name="Company" /> */}
                                    <input type="text" ref={ref => this.CompanyRef = ref} value={supplierData.Company} disabled={!isEnabled} name="Company" className="e-control e-textbox e-lib e-input" onChange={this.onChange} />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Adresse</td>
                                <td>
                                    {/* <TextBoxComponent ref={ref => this.StreetRef = ref} value={supplierData.Street} enabled={isEnabled} name="Street" /> */}
                                    <input type="text" ref={ref => this.StreetRef = ref} value={supplierData.Street} disabled={!isEnabled} name="Street" className="e-control e-textbox e-lib e-input" onChange={this.onChange} />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Post Nr.</td>
                                <td>
                                    <DropDownListComponent ref={ref => this.PostalCodeRef = ref} id="ddlPostalCode" name="PostalCodeId" value={supplierData.PostalCodeId} dataSource={this.postalCodes} 
                                        filtering={this.onPostalCodeFiltering.bind(this)} filterBarPlaceholder={Localization.General.SearchPostalCode} allowFiltering={true} enabled={isEnabled}
                                        fields={{ text: 'Value', value: 'Id' }} change={this.onPostalCodeChange.bind(this)} locale="da" />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">By</td>
                                <td>
                                    <DropDownListComponent ref={ref => this.CityRef = ref} id="ddlCity" name="CityId" value={supplierData.CityId} dataSource={this.cities} 
                                        filtering={this.onCityFiltering.bind(this)} filterBarPlaceholder={Localization.General.SearchCity} allowFiltering={true} enabled={isEnabled}
                                        fields={{ text: 'ShortName', value: 'Id' }} change={this.onCityChange.bind(this)} locale="da" />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Region</td>
                                <td>
                                    <DropDownListComponent ref={ref => this.RegionRef = ref} id="ddlRegion" name="RegionId" value={supplierData.RegionId} dataSource={this.regions} 
                                        filtering={this.onRegionFiltering.bind(this)} filterBarPlaceholder={Localization.General.SearchRegion} allowFiltering={true} enabled={isEnabled}
                                        fields={{ text: 'Name', value: 'Id' }} change={this.onRegionChange.bind(this)} locale="da" />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Land</td>
                                <td>
                                    {/* <input type="text" ref={ref => this.CountryRef = ref} value={supplierData.Country} disabled={!isEnabled} name="Country" className="e-control e-textbox e-input" onChange={this.onChange} /> */}
                                    <DropDownListComponent ref={ref => this.CountryRef = ref} id="ddlCountry" name="CountryId" value={supplierData.CountryId} dataSource={this.countries} 
                                        filtering={this.onCountryFiltering.bind(this)} filterBarPlaceholder={Localization.General.SearchCountry} allowFiltering={true} enabled={false}
                                        fields={{ text: 'Name', value: 'Id' }} change={this.onCountryChange.bind(this)} locale="da" />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Tlf. Nr.</td>
                                <td>
                                    {/* <TextBoxComponent ref={ref => this.MainPhoneRef = ref} value={supplierData.MainPhone} enabled={isEnabled} name="MainPhone" /> */}
                                    <input type="text" ref={ref => this.MainPhoneRef = ref} value={supplierData.MainPhone} disabled={!isEnabled} name="MainPhone" className="e-control e-textbox e-input" onChange={this.onChange} />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Mail<span className="required">*</span></td>
                                <td>
                                    {/* <TextBoxComponent ref={ref => this.MainEmailRef = ref} value={supplierData.MainEmail} enabled={isEnabled} name="MainEmail" /> */}
                                    <input type="text" ref={ref => this.MainEmailRef = ref} value={supplierData.MainEmail} disabled={!isEnabled} name="MainEmail" className="e-control e-textbox e-input" onChange={this.onChange} />
                                     {/* change={this.onChange.bind(this)} */}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Cvr. Nr.</td>
                                <td>
                                    {/* <TextBoxComponent ref={ref => this.VatNoRef = ref} value={supplierData.VatNo} enabled={isEnabled} name="VatNo" /> */}
                                    <input type="text" ref={ref => this.VatNoRef = ref} value={supplierData.VatNo} disabled={!isEnabled} name="VatNo" className="e-control e-textbox e-input" onChange={this.onChange} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
    
    async supplierInfoToolbarClicked(args) {
        if (args.item.id === 'add') {
            let supplierData = {
                AllOrderInterval: 0,
                SupplierNo: '',
                Company: '',
                Street: '',
                PostalCodeId: null,
                CityId: null,
                RegionId: null,
                CountryId: null,
                MainPhone: '',
                MainEmail: '',
                VatNo: '',
                DeliveryTimeInDays: '',
                OrderTimeSend: '',
                OrderOutput: null,
                OrderOutputFormat: null,
                DeptOrderInterval: [],
                OrderOutputEmails: []
            }

            this.toggleSupplierInfoFields(true, true);
            this.props.changeAddEditState("Add");
            this.setState({ moduleState: 'add', supplierData });
        }
        else if (args.item.id === 'search') {
            this.props.onSearchClick('/leverandor/leverandorliste');
        }
        else if (args.item.id === 'edit') {
            this.toggleSupplierInfoFields(true);
            this.props.changeAddEditState("Edit");
            this.setState({ isEditMode: true });
        }
        else if (args.item.id === 'delete') {
            this.source.cancel();
            this.source = Axios.CancelToken.source();

            let { supplierData, dialogOpts } = this.state;

            const result = await SupplierService.DeleteSupplier(supplierData.Id, this.source.token);
            if (!result.HasError) {
                dialogOpts.redirectOnClose = true;
                dialogOpts.redirectTo = '/leverandor/leverandorliste';
                this.setState({ dialogOpts }, () => {
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessDelete.replace("{context}", "Leverandør"));
                });
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
        else if (args.item.id === 'save') {
            this.initiateSave();
        }
        else if (args.item.id === 'cancel') {
            let { moduleState, currentTabSelected } = this.state;
            if (moduleState === 'edit') {
                switch(currentTabSelected) {
                    case 0:
                        this.refreshDropdown();
                        break;
                }
                await this.refreshSupplier();
                this.toggleSupplierInfoFields(false);
                this.setState({ isEditMode: false });
            }
            else {
                this.props.onClose();
            }
        }
        else if (args.item.id === 'undo') {
            let { supplierData, currentTabSelected } = this.state;
            const result = await SupplierService.UndoSupplier(supplierData.Id, this.source.token);

            if(!result.HasError) {
                // this.undoRedoOptions(result.Undo, result.Redo, result.UndoAction, result.RedoAction);
                this.setState({ undoRedoStatus: result });
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Leverandør"));
                
                switch(currentTabSelected) {
                    case 0:
                        this.refreshDropdown();
                        break;
                }

                setTimeout(() => {
                    this.refreshSupplier();
                }, 400);
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
        else if (args.item.id === 'redo') {
            let { supplierData, currentTabSelected } = this.state;
            const result = await SupplierService.RedoSupplier(supplierData.Id, this.source.token);

            if(!result.HasError) {
                // this.undoRedoOptions(result.Undo, result.Redo, result.UndoAction, result.RedoAction);
                this.setState({ undoRedoStatus: result });
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Leverandør"));

                switch(currentTabSelected) {
                    case 0:
                        this.refreshDropdown();
                        break;
                }

                setTimeout(() => {
                    this.refreshSupplier();
                }, 400);
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
    }

    async initiateSave() {
        this.source.cancel();
        this.source = Axios.CancelToken.source();

        // Add validation here
        let hasError = this.validateFields();
        if (hasError) {
            return;
        }

        const sessionInfo = await SessionService.GetSessionInfo();
        let { supplierData, moduleState, currentTabSelected, hasEdit } = this.state;

        let actionText = moduleState === 'add' ? Localization.General.SuccessAdd : Localization.General.SuccessUpdate;
        let result = null;

        supplierData.CustomerId = parseInt(sessionInfo.Id);
        if (hasEdit.info && currentTabSelected != 0) {
            result = await SupplierService.AddUpdateSupplier(supplierData, this.source.token);
        } else if (hasEdit.settings && currentTabSelected != 1) {
            result = await SupplierService.AddUpdateSupplierSettings(supplierData, this.source.token);
        }

        if (moduleState === 'add' && result && !result.HasError) {
            supplierData.Id = result.Id;
        }

        switch(currentTabSelected) {
            case 0:
                supplierData.SupplierNo = this.SupplierNoRef.value;
                supplierData.Company = this.CompanyRef.value;
                supplierData.Street = this.StreetRef.value;
                supplierData.PostalCodeId = this.PostalCodeRef.value;
                supplierData.CountryId = this.CountryRef.value;
                supplierData.MainPhone = this.MainPhoneRef.value;
                supplierData.MainEmail = this.MainEmailRef.value;
                supplierData.VatNo = this.VatNoRef.value;

                result = await SupplierService.AddUpdateSupplier(supplierData, this.source.token);
                break;
            case 1:
                supplierData.DeliveryTimeInDays = parseInt(this.DeliveryTimeInDaysRef.value);
                supplierData.AllOrderInterval = parseInt(this.AllOrderIntervalRef.value);

                result = await SupplierService.AddUpdateSupplierSettings(supplierData, this.source.token);
                break;
        }

        if (!result.HasError) {
            supplierData.Id = result.Id;
            actionText = actionText.replace("{context}", "Leverandør");
            switch(currentTabSelected) {
                case 0:
                    this.refreshDropdown();
                    break;
            }
            this.dialogOpen(Localization.General.SuccessHeader, actionText);
            this.toggleSupplierInfoFields(false);
            hasEdit.info = false;
            hasEdit.settings = false;
            this.setState({ supplierData, isEditMode: false, moduleState: 'edit', undoRedoStatus: result.UndoRedoStatus, hasUnsavedChanges: false, hasEdit });
        }
        else {
            this.dialogOpen("Advarsel", result.ErrorMessage);
        }
    }

    validateFields() {
        const { supplierData, currentTabSelected } = this.state;
        let hasError = false, invalidMailFormat = false;
        let errorMessage = [];

        switch (currentTabSelected) {
            case 0:
                if (!this.SupplierNoRef.value) { this.SupplierNoRef.classList.add('e-error'); hasError = true; }
                else { this.SupplierNoRef.classList.remove('e-error'); }
        
                if (!this.CompanyRef.value) { this.CompanyRef.classList.add('e-error'); hasError = true; }
                else { this.CompanyRef.classList.remove('e-error'); }
        
                if (!this.MainEmailRef.value) { this.MainEmailRef.classList.add('e-error'); hasError = true; }
                else { this.MainEmailRef.classList.remove('e-error'); }

                break;
            case 1:
                if (!this.DeliveryTimeInDaysRef.value && this.DeliveryTimeInDaysRef.value <= 0) { this.DeliveryTimeInDaysRef.classList.add('e-error'); hasError = true; }
                else { this.DeliveryTimeInDaysRef.classList.remove('e-error'); }

                if (!this.OrderTimeSendRef.value ) { this.OrderTimeSendRef.element.parentElement.classList.add('e-error'); hasError = true; }
                else { this.OrderTimeSendRef.element.parentElement.classList.remove('e-error'); }

                if (!this.AllOrderIntervalRef.value || this.AllOrderIntervalRef.value < 0) { this.AllOrderIntervalRef.classList.add('e-error'); hasError = true; }
                else { this.AllOrderIntervalRef.classList.remove('e-error'); }

                if (!this.OrderOutputRef.value || this.OrderOutputRef.value === 0) { this.OrderOutputRef.element.parentElement.classList.add('e-error'); hasError = true; }
                else { this.OrderOutputRef.element.parentElement.classList.remove('e-error'); }

                if (!this.OrderOutputFormatRef.value || this.OrderOutputFormatRef.value === 0) { this.OrderOutputFormatRef.element.parentElement.classList.add('e-error'); hasError = true; }
                else { this.OrderOutputFormatRef.element.parentElement.classList.remove('e-error'); }

                if (supplierData.OrderOutputEmails.length > 0) {
                    let indexes = [];
                    supplierData.OrderOutputEmails.forEach((e, index) => {
                        if (!ValidateEmail(e)) {
                            indexes.push(index);
                        }
                    });

                    let emailList = document.querySelectorAll('.output-email-list-content .output-email-row');
                    if (indexes.length > 0) {
                        // hasError = true;
                        invalidMailFormat = true;

                        emailList.forEach((elem, index) => {
                            if (indexes.some(x => x === index)) {
                                let input = elem.querySelector('div:first-child input');
                                input.classList.add('e-error');
                            }
                        });
                    }
                    else {
                        emailList.forEach(elem => {
                            let input = elem.querySelector('div:first-child input');
                            input.classList.remove('e-error');
                        });
                    }
                }
                break;
        }

        let msg = [];
        if (hasError) {
            msg.push("Du mangler at udfylde felter, makeret med rødt.");
            if (invalidMailFormat) {
                msg.push(Localization.General.InvalidEmailFormat);
            }
            this.dialogOpen("Advarsel", msg.join('<br>'));
        }
        else if (!hasError && invalidMailFormat) {
            msg.push(Localization.General.InvalidEmailFormat);
            this.dialogOpen("Advarsel", msg.join('<br>'));
            hasError = true;
        }
        
        return hasError;
    }

    undoRedoOptions(hasUndo, hasRedo, undoTooltip = '', redoTooltip = '') {
        // this.SupplierInfoToolbarRef.items[6].disabled = !hasUndo;
        // this.SupplierInfoToolbarRef.items[6].tooltipText = undoTooltip;
        // this.SupplierInfoToolbarRef.items[7].disabled = !hasRedo;
        // this.SupplierInfoToolbarRef.items[7].tooltipText = redoTooltip;
    }

    // supplierContactTab() {
    //     const { moduleState } = this.state;
    //     return <SupplierContact state={moduleState} onNotify={this.dialogOpen} />
    // }

    onDepartmentDaysChange(args) {
        let { supplierData } = this.state;
        if (args.container) {
            let input = args.container.querySelector('input');
    
            if (input) {
                supplierData[input.name] = args.value
                this.setState({ supplierData });
            }
        }
        else if (args.event) {
            supplierData[args.event.srcElement.id] = args.value;
            this.setState({ supplierData });
        }
    }

    onChangeByName(name, args) {
        if (args.isInteracted) {
            let { supplierData, hasEdit } = this.state;
            
            if (name === "OrderTimeSend") {
                supplierData[name] = args.text;
            }
            else {
                supplierData[name] = args.value;
            }

            if (name === 'OrderOutput') {
                let emailSettings = document.querySelector('.vendor-settings-email');
                if (args.value === 1) {
                    emailSettings.style.display = 'block';
                }
                else if (args.value === 2) {
                    emailSettings.style.display = 'none';
                }
            }

            if (this.infoFields.includes(name)) {
                hasEdit.info = true;
            } else if (this.settingsFields.includes(name)) {
                hasEdit.settings = true;
            }

            this.setState({ supplierData, hasUnsavedChanges: true, hasEdit });
        }
    }

    onAddEmail() {
        let { supplierData, hasEdit } = this.state
        hasEdit.settings = true;
        supplierData.OrderOutputEmails.push('');
        this.setState({ supplierData, hasUnsavedChanges: true, hasEdit });
    }

    onChangeEmail(index, event) {
        let { supplierData, hasEdit } = this.state;
        hasEdit.settings = true;
        supplierData.OrderOutputEmails[index] = event.target.value;
        this.setState({ supplierData, hasUnsavedChanges: true, hasEdit });
    }

    onDeleteEmail(index, event) {
        let { supplierData, hasEdit } = this.state;
        hasEdit.settings = true;
        supplierData.OrderOutputEmails.splice(index, 1);
        this.setState({ supplierData, hasUnsavedChanges: true, hasEdit });
        // let emailList = document.querySelector('div.output-email-list-content');
    }

    onDepartmentChangeInterval(id, event) {
        const { supplierData } = this.state;
        let department = supplierData.DeptOrderInterval.find(x => x.Id === id);
        department.OrderInterval = parseInt(event.target.value);
        if (department.OrderInterval ===  parseInt(supplierData.AllOrderInterval)) {
            department.OrderInterval = null;
            event.target.value = null;
        }
        this.setState({ supplierData, hasUnsavedChanges: true });
    }

    onTimepickerBlur(args) {
        const { supplierData } = this.state;
        let data  = '';

        if (supplierData.OrderTimeSend) {
            data = supplierData.OrderTimeSend;
        }
        else {
            data = args.target.value;
        }
        
        if (!data.includes(':')) {
            // args.target.value
            if (data.length < 4) {
                data = `0${data}`;
            }

            if (data.length === 4) {
                let nums = data.split('');
                nums.splice(2, 0, ':');
                supplierData.OrderTimeSend = nums.join('');
                this.setState({ supplierData });
            }
        }
    }

    vendorSettingsTab() {
        const { supplierData, isEditMode, moduleState } = this.state;
        //return ( <VendorSettings state={moduleState} onNotify={this.dialogOpen} data={supplierData} /> )

        let isEnabled = false;
        if (moduleState === 'add') {
            isEnabled = true;
        }
        else if (isEditMode) {
            isEnabled = true;
        }
        
        return (
            <div className="tab-control--item vendor-settings-wrapper">
                <div className="vendor-settings-card-wrapper">
                    <Card headerText="Leveringsbetingelser">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="vendor-settings-label-wrapper">
                                            <div className="vendor-settings-label">Leveringstid I Dage</div>
                                            <div className="vendor-settings-sub-label">Fra orde modtagelse til afsendelse Weekend dage undtaget</div>
                                        </div>
                                        <div className="vendor-settings-value-wrapper">
                                            <input type="number" ref={ref => this.DeliveryTimeInDaysRef = ref} value={supplierData.DeliveryTimeInDays} disabled={!isEnabled} name="DeliveryTimeInDays" className="text-right e-control e-textbox e-input is-numeric" 
                                                onChange={this.onChange} />
                                            {/* <NumericTextBoxComponent ref={ref => this.DeliveryTimeInDaysRef = ref} cssClass="input-text-right" format="#,###" value={supplierData.DeliveryTimeInDays} showSpinButton={false} 
                                                id="DeliveryTimeInDays" enabled={isEnabled} /> */}
                                                {/* change={this.onChangeByName.bind(this, "DeliveryTimeInDays")}  */}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="vendor-settings-label-wrapper">
                                            <div className="vendor-settings-label">Afsend Ordre Inden Kl.:</div>
                                            <div className="vendor-settings-sub-label ">Når orde modtages inden indtastet tidspunkt vil ordre blive afsendt samme dag</div>
                                        </div>
                                        <div className="vendor-settings-value-wrapper">
                                            <TimePickerComponent ref={ref => this.OrderTimeSendRef = ref} cssClass="input-text-right timer-pop-up" value={supplierData.OrderTimeSend} id="OrderTimeSend" format="HH:mm" 
                                                change={this.onChangeByName.bind(this, "OrderTimeSend")} enabled={isEnabled} onBlur={this.onTimepickerBlur.bind(this)} openOnFocus={true} allowEdit={false} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Card>
                    <Card headerText="Genbestillings Interval">
                        <table className="table customer-department-table">
                            <thead>
                                <tr>
                                    <th>{Localization.Product.Department}</th>
                                    <th>Interval I Dage</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{Localization.Product.AllDepartment}</td>
                                    <td>
                                        <input type="number" ref={ref => this.AllOrderIntervalRef = ref} value={supplierData.AllOrderInterval} disabled={!isEnabled} name="AllOrderInterval" className="text-right e-control e-textbox e-input is-numeric" 
                                            onChange={this.onChange} />
                                        {/* <NumericTextBoxComponent ref={ref => this.AllOrderIntervalRef = ref} cssClass="input-text-right" format="#,###" showSpinButton={false} id="AllOrderInterval" 
                                            value={supplierData.AllOrderInterval} enabled={isEnabled} />  */}
                                            {/* change={this.onDepartmentDaysChange.bind(this)}  */}
                                    </td>
                                </tr>
                                {
                                    supplierData.DeptOrderInterval.map((department, index) => {
                                        return (
                                            <tr data-id={department.Id} className="department-row-data" key={`department_row_${index}`}>
                                                <td>{department.Name}</td>
                                                <td>
                                                    {/* <NumericTextBoxComponent cssClass="input-text-right" format="#,###" showSpinButton={false} value={department.OrderInterval} 
                                                        change={this.onDepartmentChangeInterval.bind(this, department.Id)} enabled={isEnabled} /> */}
                                                    <input type="number" id={`DeliveryTimeInDays_${department.Id}`} className="e-control e-input input-text-right is-numeric" value={department.OrderInterval} disabled={!isEnabled}
                                                        onChange={this.onDepartmentChangeInterval.bind(this, department.Id)} placeholder="Alle afdelinger" />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </Card>
                </div>
                <div className="vendor-settings-card-wrapper data-output">
                    <Card headerText="Ordre output">
                        <div className="vendor-settings-order-output">
                            <div className="vendor-settings-output-label-wrapper">Vælg venligst output</div>
                            <div className="vendor-settings-output-value-wrapper">
                                <DropDownListComponent ref={ref => this.OrderOutputRef = ref} value={supplierData.OrderOutput} dataSource={this.outputData} fields={{ text: 'Key', value: 'Value' }} 
                                    change={this.onChangeByName.bind(this, "OrderOutput")} enabled={isEnabled} locale="da" />
                            </div>
                        </div>
                        <div className="vendor-settings-order-output">
                            <div className="vendor-settings-output-label-wrapper">Vælg venligst Filformat</div>
                            <div className="vendor-settings-output-value-wrapper">
                                <DropDownListComponent ref={ref => this.OrderOutputFormatRef = ref} value={supplierData.OrderOutputFormat} dataSource={this.fileFormatData} fields={{ text: 'Key', value: 'Value' }} 
                                    change={this.onChangeByName.bind(this, "OrderOutputFormat")} enabled={isEnabled} locale="da" />
                            </div>
                        </div>
                    </Card>
                    {/* supplierData.OrderOutput === 1 && */}
                    <div className="vendor-settings-email">
                        <div className="output-email-list-header label">
                            Indtast venligst E-mail adresse
                            <button type="button" className="add-email-button c-button c-button-plain" disabled={!isEnabled} onClick={this.onAddEmail}>
                                <i className="c-icon c-icon-add-black"></i>Ny
                            </button>
                        </div>
                        <div className="output-email-list-content">
                            {
                                supplierData.OrderOutputEmails.map((email, index) => {
                                    return (
                                        <div className="output-email-row" key={`email-row-${index}`}>
                                            <div className="output-email-data-col">
                                                <input type="text" className={`e-control e-lib e-input ${isEnabled ? '' : 'e-disabled'}`} name="OutputEmails" value={email} onChange={this.onChangeEmail.bind(this, index)} />
                                            </div>
                                            {
                                                isEnabled &&
                                                <div className="output-email-action-col">
                                                    <i className="c-icon c-icon-delete clickable" onClick={this.onDeleteEmail.bind(this, index)}></i>
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    tabSelected(args) {
        const { breadcrumbs, supplierData, isEditMode, moduleState } = this.state;
        if (breadcrumbs.length > 1) {
            breadcrumbs.pop();
        }
        breadcrumbs.push(this.tabHeader[args.selectedIndex].text);

        const headerText = this.tabHeader[args.selectedIndex].text;
        let headerElement = document.querySelector('.module-header > h1');
        headerElement.innerHTML = headerText;

        const selectedTab = this.supplierTabRef.selectedItem;
        switch(selectedTab) {
            case 0:
                break;
            case 1:
                let emailSettings = document.querySelector('.vendor-settings-email');
                if (emailSettings) {
                    if (supplierData.OrderOutput === 1) {
                        emailSettings.style.display = 'block';
                    }
                    else if (supplierData.OrderOutput === 2) {
                        emailSettings.style.display = 'none';
                    }
                }
                break;
        }

        this.setState({ breadcrumbs });
    }

    dialogOpen(header, content, callback = null) {
        this.dialogInstance.header = header;
        this.dialogInstance.content = content;
        
        if (callback) {
            let buttons = this.buttons;
            buttons[0].click = callback;
            this.dialogInstance.buttons = buttons;
        }

        this.dialogInstance.show();
    }

    dialogClose() {
        let { dialogOpts, currentTabSelected, breadcrumbs } = this.state;
        this.dialogInstance.hide();

        if (this.hasRequiredFieldNotification) {
            let headerElement = document.querySelector('.module-header > h1');
            headerElement.innerHTML = 'Leverandør Indstillinger';
            breadcrumbs.push('Leverandør Indstillinger');
            this.hasRequiredFieldNotification = false;
            currentTabSelected = 1;
        }

        this.setState({ dialogOpts, currentTabSelected, breadcrumbs });
    }

    onTabSelected(index) {
        const { breadcrumbs, supplierData } = this.state;
        if (breadcrumbs.length > 1) {
            breadcrumbs.pop();
        }

        let headerText = '';
        let headerElement = document.querySelector('.module-header > h1');

        switch(index) {
            case 0:
                headerText = 'Leverandør Info';
                headerElement.innerHTML = headerText;
                breadcrumbs.push(headerText);
                this.setState({ currentTabSelected: index, breadcrumbs });
                break;
            case 1:
                let isProceedToNextTab = true;

                if (!supplierData.SupplierNo) { isProceedToNextTab =  false; }
                if (!supplierData.Company) { isProceedToNextTab =  false; }
                if (!supplierData.MainEmail) { isProceedToNextTab =  false; }

                if (!isProceedToNextTab) {
                    this.hasRequiredFieldNotification = true;
                    this.dialogOpen(Localization.General.NoteHeader, Localization.General.NotifyRequireFieldsNotFilled);
                    return false;
                }
                
                headerText = 'Leverandør Indstillinger';
                headerElement.innerHTML = headerText;
                breadcrumbs.push(headerText);
                this.setState({ currentTabSelected: index, breadcrumbs });
                break;
        }
    }

    hasMenuItemTabFunction = (menuItemName, tabName, functionName) => {
        const { permissions } = this.props;
        
        let menuItem = permissions.MenuItems.find(x => x.Name === menuItemName);
        if (menuItem) {
            let tabItem = menuItem.MenuTabs.find(x => x.Name === tabName);
            if (tabItem) {
                if (Array.isArray(functionName)) {
                    let hasAccess = false;

                    tabItem.Functions.forEach(x => {
                        if (!hasAccess) {
                            hasAccess = functionName.some(f => f === x);
                        }
                    });

                    return hasAccess;
                }
                else {
                    return tabItem.Functions.some(f => f === functionName);
                }
            }
            else { return false; }
        }
        else { return false; }
    }

    hasMenuItem = (menuItemName) => {
        const { permissions } = this.props;
        return permissions.MenuItems.some(x => x.Name === menuItemName);
    }

    hasMenuItemTabAccess = (menuItemName, tabName) => {
        const { permissions } = this.props;
        let menuItem = permissions.MenuItems.find(x => x.Name === menuItemName);
        if (menuItem) {
            return menuItem.MenuTabs.some(x => x.Name === tabName);
        }
        else { return false; }
    }

    render() {
        const { loading, breadcrumbs, supplierData, moduleState, isEditMode, undoRedoStatus, currentTabSelected, redirect, hasUnsavedChanges } = this.state;
        
        // if (loading) {
        //     return <Loading />
        // }

        let isUpdateDisabled = false, isEditDisabled = false, isCancelDisabled = false, isDeleteDisabled = false;
        let isEditVisible = true, isSaveVisible = false, isCancelVisible = false;

        if (moduleState === 'add') {
            isEditDisabled = true;
            isDeleteDisabled = true;

            isSaveVisible = true;
            isCancelVisible = true;
        }
        else {
            if (isEditVisible) {
                isSaveVisible = true;
                isCancelVisible = true;
            }

            if (isEditMode) {
                isEditDisabled = true;
            }
            else {
                isUpdateDisabled = true;
                isCancelDisabled = true;
            }
        }

        return (
            <div className='supplier-card-wrapper'>
                <div>
                    <Breadcrumb breadcrumbs={breadcrumbs} />
                </div>
                <div className="module-header">
                    <h1>{this.tabHeader[0].text}</h1>
                </div>
                <div className="supplier-info-toolbar-wrapper">
                    <ToolbarComponent ref={ref => this.SupplierInfoToolbarRef = ref} clicked={this.supplierInfoToolbarClicked}>
                        <ItemsDirective>
                            <ItemDirective ref={ref => this.toolbarAdd = ref} id="add" prefixIcon='tb-icons c-icon-add-black' text="Ny" tooltipText='Ny' visible={this.hasMenuItemTabFunction("Leverandørkort", this.tabHeader[currentTabSelected].text, "Ny")} />
                            <ItemDirective ref={ref => this.toolbarSearch = ref} id="search" prefixIcon='tb-icons c-icon-search-list' text="Søgeliste" tooltipText='Søgeliste' visible={this.hasMenuItem("Leverandørliste")} />
                            <ItemDirective ref={ref => this.toolbarEdit = ref} id="edit" prefixIcon='tb-icons c-icon-edit' text="Redigere" tooltipText='Redigere' disabled={isEditDisabled} visible={isEditVisible} />
                            <ItemDirective ref={ref => this.toolbarEdit = ref} id="delete" prefixIcon='tb-icons c-icon-delete' text="Slette" tooltipText='Slette' disabled={isDeleteDisabled} visible={this.hasMenuItemTabFunction("Leverandørkort", this.tabHeader[currentTabSelected].text,"Slette")} />
                            <ItemDirective ref={ref => this.toolbarSave = ref} id="save" prefixIcon='tb-icons c-icon-update' text='Gem' 
                                tooltipText='Gem' disabled={isUpdateDisabled} visible={isSaveVisible} />
                            <ItemDirective ref={ref => this.toolbarCancel = ref} id="cancel" prefixIcon='tb-icons c-icon-cancel' text="Annuller" tooltipText='Annuller' disabled={isCancelDisabled} visible={isCancelVisible}/>
                            <ItemDirective ref={ref => this.toolbarEdit = ref} id="undo" prefixIcon='tb-icons c-icon-undo' text="Fortryd" tooltipText={undoRedoStatus.UndoAction} disabled={!undoRedoStatus.Undo} visible={this.hasMenuItemTabFunction("Leverandørkort", this.tabHeader[currentTabSelected].text, ["Ny","Redigere","Slette"])} />
                            <ItemDirective ref={ref => this.toolbarEdit = ref} id="redo" prefixIcon='tb-icons c-icon-redo' text="Gentag" tooltipText={undoRedoStatus.RedoAction} disabled={!undoRedoStatus.Redo} visible={this.hasMenuItemTabFunction("Leverandørkort", this.tabHeader[currentTabSelected].text, ["Ny","Redigere","Slette"])} />
                        </ItemsDirective>
                    </ToolbarComponent>
                </div>
                {
                    moduleState === 'edit' &&
                    <div className="supplier-name-header">
                        <h2>{supplierData.Company}</h2>
                    </div>
                }
                <div className='control-section tab-control'>
                    <Tabs className="c-tab" selectedIndex={currentTabSelected} onSelect={index => this.onTabSelected(index)} disabledTabClassName="c-tabs__tab--disabled">
                        <TabList className="c-tab__list">
                            <Tab className={`c-tabs__tab ${this.hasMenuItemTabAccess("Leverandørkort", "Leverandør Info") ? '' : 'hidden'}`} disabled={!this.hasMenuItemTabAccess("Leverandørkort", "Leverandør Info")} selectedClassName="c-tabs__tab--selected">Leverandør Info</Tab>
                            <Tab className={`c-tabs__tab ${this.hasMenuItemTabAccess("Leverandørkort", "Leverandør Indstillinger") ? '' : 'hidden'}`} disabled={!this.hasMenuItemTabAccess("Leverandørkort", "Leverandør Indstillinger")} selectedClassName="c-tabs__tab--selected">Leverandør Indstillinger</Tab>
                        </TabList>
                        <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                            {this.supplierInfoTab()}
                        </TabPanel>
                        <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                            {this.vendorSettingsTab()}
                        </TabPanel>
                    </Tabs>
                </div>
                <div>
                    <DialogComponent id="supplier-card-dialog" isModal={true} buttons={this.buttons} width='auto' ref={dialog => this.dialogInstance = dialog} target='body' visible={false} 
                        showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true}/>
                </div>
            </div>
        )
    }
}

export default ProductSupplier;
