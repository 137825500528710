import React from 'react';
import { withRouter, } from "react-router";

const Breadcrumb = ({ breadcrumbs, ...props }) => {
    return (
        <div className="breadcrumbs-wrapper">
            <ul>
                {
                    breadcrumbs.map((crumb, index) => {
                        if (typeof crumb === "string") {
                            return (<li key={`breadcrumb_${index}`}>{crumb}</li>)
                        }

                        if (typeof crumb === "object") {
                            return (<li key={`breadcrumb_${index}`} onClick={() => { props.history.push(crumb.link) }} className="clickable"><span>{crumb.text}</span></li>)
                        }
                        
                        return '';
                    })
                }
            </ul>
        </div>
    )
}

export default withRouter(Breadcrumb);
