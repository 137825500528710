import React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { Redirect } from 'react-router-dom';
import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Page, Sort, Toolbar, Selection, Search, Reorder, Resize } from '@syncfusion/ej2-react-grids';
import Axios from 'axios';
import XLSX from 'xlsx';

import BaseComponent from '../../components/BaseComponent';
import Loading from '../../components/Loading';

import UserImportExport from './UserImportExport';
import SupplierImportExport from './SupplierImportExport';
import ProductImportExport from './ProductImportExport';
import StockImportExport from './StockImportExport';
import ProductGroupImportExport from './ProductGroupImportExport';

import '../../styles/import-export.scss';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class ImportExport extends BaseComponent {
    moduleName = "Værktøjer";
    pageName = "Import / Export";

    constructor() {
        super();

        this.state = {
            loading: true,
            redirect: {
                to: '',
                isRedirect: false
            },
            defaultTabSelected: 0
        }

        this.init = this.init.bind(this);
        this.dialogOpen = this.dialogOpen.bind(this);

        this.tabHeader = [
            { text: "Brugere" }, 
            { text: "Produkter" },
            { text: "Leverandør" },
            { text: "Lager" },
            { text: "Produkt Grupper" }
        ];

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.notificationButtons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        /* End Dialog options  */
    }

    componentDidMount() {
        super.componentDidMount();
        source.cancel();
        source = CancelToken.source();

        if (!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }
        else if (!super.hasMenuItem("Import / Export")) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }

        this.init();

        let defaultTabSelected = 0;
            
        let sessionSelectedTab = sessionStorage.getItem("IMPORTEXPORT_TAB_DEFAULT");
        if (sessionSelectedTab) {
            if (this.tabHeader.some(x => x.text.toLowerCase() === sessionSelectedTab.toLowerCase())) {
                defaultTabSelected = this.tabHeader.findIndex(x => x.text.toLowerCase() === sessionSelectedTab.toLowerCase());
            }
        }

        this.setState({ loading: false }, () => {
            this.importExportTabRef.select(defaultTabSelected);
        });
    }

    init() {
        
    }

    componentWillUnmount() {
        sessionStorage.removeItem("IMPORTEXPORT_TAB_DEFAULT");
    }

    userTab() {
        return <UserImportExport notify={this.dialogOpen} />
    }

    productTab() {
        return <ProductImportExport notify={this.dialogOpen} />
    }

    supplierTab() {
        return <SupplierImportExport notify={this.dialogOpen} />
    }

    stocksTab() {
        return <StockImportExport notify={this.dialogOpen} />
    }

    productGroupTab() {
        return <ProductGroupImportExport notify={this.dialogOpen}/>
    }

    dialogOpen(header, content) {
        this.notificationDialog.header = header;
        this.notificationDialog.content = content;
        this.notificationDialog.show();
    }

    onTabSelected(args) {
        sessionStorage.setItem("IMPORTEXPORT_TAB_DEFAULT", `${this.tabHeader[args.selectedIndex].text}`);
    }

    render() {
        const { loading, redirect } = this.state;

        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        return (
            <div className="content-pane-wrapper">
                <div className="module-header">
                    <h1>{this.pageName}</h1>
                </div>
                <div className='control-section tab-control'>
                    <TabComponent ref={ref => this.importExportTabRef = ref} heightAdjustMode='Auto' selected={this.onTabSelected.bind(this)}>
                        <TabItemsDirective>
                            <TabItemDirective header={this.tabHeader[0]} content={this.userTab.bind(this)} />
                            <TabItemDirective header={this.tabHeader[1]} content={this.productTab.bind(this)} />
                            <TabItemDirective header={this.tabHeader[2]} content={this.supplierTab.bind(this)} />
                            <TabItemDirective header={this.tabHeader[3]} content={this.stocksTab.bind(this)} />
                            <TabItemDirective header={this.tabHeader[4]} content={this.productGroupTab.bind(this)} />
                        </TabItemsDirective>
                    </TabComponent>
                </div>
                <DialogComponent id="notification-dialog" isModal={true} buttons={this.notificationButtons} width='auto' ref={dialog => this.notificationDialog = dialog} target='body'
                    visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} />
            </div>
        )
    }
}

export default ImportExport
