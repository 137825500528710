import React, { Component } from 'react';
import { TreeViewComponent, ToolbarComponent, ItemsDirective, ItemDirective } from '@syncfusion/ej2-react-navigations';
// import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { Query } from '@syncfusion/ej2-data';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Axios from 'axios';
import { Redirect } from 'react-router-dom';

import AppContext from '../../context/AppContext';
import { buildVirtualMenu, getCheckedMenuNodes, buildMenuFromCheckedNodes, buildCheckedNodes } from '../../utilities/Helper';
import NavigationPrompt from '../../components/NavigationPrompt'

import BaseComponent from '../../components/BaseComponent';
import Card from '../../components/Card';
import Breadcrumb from '../../components/Breadcrumb';

import UserService from '../../services/User/UserService';
import UserGroupService from '../../services/User/UserGroupService';
import CustomerService from '../../services/Customer/CustomerService';
// import MenuService from '../../services/MenuService';
import SessionService from '../../services/SessionService';
import SettingsService from '../../services/SettingsService';
import AccountService from '../../services/AccountService';
import messageHelper from '../../utilities/MessageHelper';
import ResponsibilityGroupService from '../../services/Customer/ResponsibilityGroupService';

import '../../styles/user.scss';
import Loading from '../../components/Loading';
import Localization from '../../utilities/Localization';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

class UserCard extends BaseComponent {
    moduleName = "Bruger";
    pageName = "Brugerkort";
    AccessControlPanel = false;

    constructor() {
        super();
        
        this.state = {
            loading: true,
            userInfo: {
                Id: '',
                FirstName: '',
                MiddleName: '',
                LastName: '',
                Initials: '',
                PhoneNumber: '',
                Email: '',
                Position: '',
                DepartmentId: 0,
                DepartmentName: '',
                PrimaryContact: '',
                Username: '',
                UserGroupId: 0,
                UserGroupName: '',
                PrimaryContact: false,
                LoginRule: 1,
                AccessControlPanel: false,
                CustomMenu: { Intralogix: [], Flexmat: [] },
                Account: {
                    AccessControlPanel: false,
                    Active: true,
                    AppAccess: true
                },
                UndoRedoStatus: {
                    Redo: false,
                    RedoAction: '',
                    Undo: false,
                    UndoAction: ''
                },
                OrdersApproval: false,
                ResponsiblityGroupIds: [],
                AllResGroupsChecked: false,
                PrevId: 0,
                NextId: 0,
                OilmatUser: {
                    ApprovePurchaseOrders: true
                }
            },
            breadcrumbs: [{text: 'Bruger liste', link: '/bruger-liste'}, 'Brugerkort'],
            moduleState: 'add',
            dialogOpts: {
                visible: false,
                redirectOnClose: false,
                redirectTo: ''
            },
            // selectedTab: 0,
            isEditMode: false,
            currentTabSelected: 0,
            redirect: {
                to: '',
                isRedirect: false
            },
            hasUnsavedChanges: false,
            responsibilityGroups: [],
            hasNext: false,
            hasPrev: false,
            redirectId: 0
        }

        this.userGroupsData = [];
        this.departmentsData = [];
        this.userSettingsLoginRules = [];

        this.onDepartmentFiltering = e => {
            let query = new Query();
            query = (e.text !== '') ? query.where('Name', 'startswith', e.text, true) : query;
            e.updateData(this.departmentsData, query);
        }

        this.tabHeader = [{text: 'Bruger kort'}, {text: 'Bruger indstillinger'}, { text: 'OilMat indstillinger'}];

        this.menuFields = {
            dataSource: [], 
            id: 'Id', text: 'Name', 
            child: 'VirtualMenuItems' 
        };

        // this.userInfoToolbarClicked = this.userInfoToolbarClicked.bind(this);
        this.onUserToolbarClicked = this.onUserToolbarClicked.bind(this);
        this.undoRedoOptions = this.undoRedoOptions.bind(this);
        // this.onUserSettingsToolbarClicked = this.onUserSettingsToolbarClicked.bind(this);
        this.toggleUserInfoFields = this.toggleUserInfoFields.bind(this);
        this.reloadUserInfo = this.reloadUserInfo.bind(this);
        this.dialogOpen = this.dialogOpen.bind(this);
        this.dialogClose = this.dialogClose.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onAccessControlPanelChange = this.onAccessControlPanelChange.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.refreshUser = this.refreshUser.bind(this);
        this.onConfirmDelete = this.onConfirmDelete.bind(this);
        this.userCardTab = this.userCardTab.bind(this);
        this.userSettingsTab = this.userSettingsTab.bind(this);
        this.onTabSelected = this.onTabSelected.bind(this);
        this.initiateSave = this.initiateSave.bind(this);
        this.onResponsibilityGroupChange = this.onResponsibilityGroupChange.bind(this);
        this.onResponsibilityGroupChangeAll = this.onResponsibilityGroupChangeAll.bind(this);
        this.onOrdersApprovalChange = this.onOrdersApprovalChange.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.resendCredentials = this.resendCredentials.bind(this);
        this.onUnload = this.onUnload.bind(this);
        this.onCancelSave = this.onCancelSave.bind(this);
        
        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.buttons = [{
            // Click the footer buttons to hide the Dialog
            click: this.dialogClose,
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        this.confirmDeleteButtons = [
            {
                click: () => {
                    this.confirmDeleteDialog.hide()
                },
                buttonModel: {
                    content: messageHelper.MB2.buttons[0].buttonModel.content
                }
            },
            {
                click: this.onConfirmDelete,
                buttonModel: {
                    isPrimary: true,
                    content: messageHelper.MB2.buttons[1].buttonModel.content
                }
            }
        ];
        this.confirmAccessControlPanelButtons = [
            {
                click: () => {
                    this.AccessControlPanelRef.checked = false;
                    this.AccessControlPanel = false;
                    this.confirmAccessControlPanelDialog.hide();
                },
                buttonModel: {
                    content: 'Annuller'
                }
            },
            {
                click: () => {
                    // let { userInfo } = this.state;
                    // userInfo.AccessControlPanel = true;
                    this.setState({ hasUnsavedChanges: true }, () => {
                        this.AccessControlPanelRef.checked = true;
                        this.AccessControlPanel = true;
                        this.confirmAccessControlPanelDialog.hide();
                    });
                },
                buttonModel: {
                    isPrimary: true,
                    content: 'OK'
                }
            }
        ]
        /* End Dialog options  */
    }

    onUnload = e => {
        const { hasUnsavedChanges } = this.state;
        if (hasUnsavedChanges) {
            this.NavigationPromptRef.showModal(window.location.href);
            e.preventDefault();
            e.returnValue = '';
            return null;
        }
        else {
            return;
        }
    }

    async componentDidMount() {
        super.componentDidMount();
        window.addEventListener("beforeunload", this.onUnload);
        source.cancel();
        source = CancelToken.source();

        if (!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }
        else if (!super.hasMenuItem("Bruger kort")) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }

        const { match } = this.props;

        const userId = match && match.params && match.params.id;
        let { userInfo, isEditMode, isInactive, hasUnsavedChanges, hasNext, hasPrev } = this.state;
        let moduleState = 'add';
        
        const sessionInfo = await SessionService.GetSessionInfo();
        
        if (userId) {
            const result = await UserService.GetUser(userId, source.token);
            Object.assign(userInfo, result);
            // userInfo.CustomMenu = this.combineMenus(userInfo.CustomMenu, userInfo.UserGroup.Menu.Intralogix);
            moduleState = 'edit';
            this.AccessControlPanel = userInfo.Account.AccessControlPanel;

            if (userInfo.Account.Active) {
                isInactive = false;
                // isEditMode = true;
            }
            else {
                isInactive = true;
                isEditMode = false;
            }
        }
        else {
            isEditMode = true;
            hasUnsavedChanges = true;
        }

        const userGroups = await UserGroupService.GetUserGroups(sessionInfo.Id, source.token);
        this.userGroupsData = userGroups;

        const departments = await CustomerService.GetAccountDepartments(sessionInfo.Id, source.token);
        this.departmentsData = departments;

        var tempMenus = null;
        this.menuData = null;
        if (userInfo && userInfo.UserGroup && userInfo.UserGroup.Menu) {
            tempMenus = buildVirtualMenu(userInfo.UserGroup.Menu);
            this.menuFields.dataSource = tempMenus;
            this.menuData = tempMenus;
        } 
        // else {
        //     const customerGroup = await CustomerGroupService.GetCustomerGroup(sessionInfo.CustomerGroup.Id, source.token);
        //     let tempMenus = buildVirtualMenu(customerGroup.Menu);
        //     this.menuFields.dataSource = tempMenus;
        //     this.menuData = tempMenus;
        // }

        var tempAllResGroupsChecked = false;
        const responsibilityGroups = await ResponsibilityGroupService.GetList(source.token);
        if (responsibilityGroups && userInfo && userInfo.ResponsiblityGroupIds) {
            var tempGroups = responsibilityGroups.Data.map(group => { return group.Id });
            tempAllResGroupsChecked = this.arraysEqual(tempGroups, userInfo.ResponsiblityGroupIds);
        }

        const loginRules = await SettingsService.GetLoginRules(source.token);
        this.userSettingsLoginRules = loginRules.filter(x => x.Value == 1);
        
        hasNext = userInfo.NextId > 0;
        hasPrev = userInfo.PrevId > 0;

        this.setState({ loading: false, userInfo, moduleState, menus: tempMenus, isEditMode, isInactive, hasUnsavedChanges, responsibilityGroups: responsibilityGroups.Data, AllResGroupsChecked: tempAllResGroupsChecked, hasNext, hasPrev }, () => {
            setTimeout(() => {
                if (moduleState === 'add') {
                    this.toggleUserInfoFields(true);
                    // this.UserToolbarRef.enableItems(2, false);
                    // this.UserToolbarRef.enableItems(3, false);
                    // this.UserToolbarRef.enableItems(4, true);
                }
                else if (moduleState === 'edit') {
                    if (userInfo.Account.Active) {
                        // this.toggleUserInfoFields(true);
                    }
                    else {
                        this.toggleUserInfoFields(false);
                    }
                    // this.UserToolbarRef.enableItems(2, false);
                    // this.UserToolbarRef.enableItems(3, true);
                    // this.UserToolbarRef.enableItems(4, true);
                    // this.UserToolbarRef.enableItems(5, true);
                }
            }, 500);
        });
    }

    componentWillUnmount() {
        source.cancel();
        window.removeEventListener("beforeunload", this.onUnload);
    }

    arraysEqual(a, b) {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;
      
        a = a.sort();
        b = b.sort();
        for (var i = 0; i < a.length; ++i) {
          if (a[i] !== b[i]) return false;
        }
        return true;
      }

    onChange(args) {
        // let input = args.container.querySelector('input');

        // if (input) {
        //     let { userInfo } = this.state;
        //     let key = input.name;
        //     let value = args.value;
        //     userInfo[key] = value
        //     this.setState({ userInfo });
        // }
        let { userInfo } = this.state;
        let key = args.target.name;
        let value = args.target.value;
        userInfo[key] = value;
        this.setState({ userInfo, hasUnsavedChanges: true });
    }
    
    onPrimaryContactChange = (args) => {
        const { userInfo } = this.state;
        userInfo.PrimaryContact = args.checked
        this.setState({ userInfo, hasUnsavedChanges: true });
    }
    
    onApprovePurchaseOrderChange = (args) => {
        const { userInfo } = this.state;
        userInfo.OilmatUser.ApprovePurchaseOrders = args.checked;
        this.setState({ userInfo, hasUnsavedChanges: true });
    }

    onDepartmentChange(args) {
        if (args.value) {
            let { userInfo } = this.state;
            userInfo.DepartmentId = args.value
            this.setState({ userInfo, hasUnsavedChanges: true });
        }
    }

    onUserGroupChange(args) {
        if (args.value) {
            let { userInfo, isEditMode } = this.state;
            userInfo.UserGroupId = args.value
            let group = this.userGroupsData.find(x => x.Id === args.value);
            if (group) {
                if (!userInfo.UserGroup) {
                    userInfo.UserGroup = {};
                }
                userInfo.UserGroup.Menu = group.Menu;
                userInfo.CustomMenu = group.Menu;
                let tempMenus = buildVirtualMenu(group.Menu);
                this.menuFields.dataSource = tempMenus;
                this.menuData = tempMenus;
            }            
            this.setState({ userInfo, hasUnsavedChanges: isEditMode });
        }
    }

    async reloadUserInfo() {
        let { userInfo } = this.state;
        if (userInfo && userInfo.Id) {
            let { currentTabSelected, menus, responsibilityGroups, AllResGroupsChecked } = this.state;
            const result = await UserService.GetUser(userInfo.Id, source.token);
            userInfo = Object.assign(userInfo, result);
            
            switch(currentTabSelected) {
                case 0:
                    this.FirstNameRef.value = userInfo.FirstName;
                    this.MiddleNameRef.value = userInfo.MiddleName;
                    this.LastNameRef.value = userInfo.LastName;
                    this.InitialsRef.value = userInfo.Initials;
                    this.PhoneNumberRef.value = userInfo.PhoneNumber;
                    this.EmailRef.value = userInfo.Email;
                    this.PositionRef.value = userInfo.Position;
                    this.DepartmentRef.value = userInfo.DepartmentId;
                    this.PrimaryContactRef.checked = userInfo.PrimaryContact;
                    this.IntUserNameRef.value = userInfo.IntUserName;
                    this.FmUserNameRef.value = userInfo.FmUserName;
                    this.OmUserNameRef.value = userInfo.OmUserName;
                    this.UserGroupRef.value = userInfo.UserGroupId;
                    break;
                case 1:
                    
                    if (userInfo.Id) {
                        this.userGroupTreeRef.checkedNodes = getCheckedMenuNodes(menus, userInfo.CustomMenu);
                    }
                    
                    this.ddlLoginRuleRef.value = userInfo.LoginRule;
                    this.AccessControlPanelRef.checked = userInfo.Account.AccessControlPanel;
                    this.AccessControlPanel = userInfo.Account.AccessControlPanel;
                    break;
            }

            var tempGroups = responsibilityGroups.map(group => { return group.Id });
            AllResGroupsChecked = this.arraysEqual(tempGroups, userInfo.ResponsiblityGroupIds);

            this.setState({ userInfo, AllResGroupsChecked, hasNext: userInfo.NextId > 0, hasPrev: userInfo.PrevId, redirectId: 0 });
        }
    }

    validateFields() {
        let { userInfo, moduleState } = this.state;
        let errorList = [];

        if (!userInfo.FirstName) {
            errorList.push('Fornavn kræves');
        }
        if (!userInfo.LastName) {
            errorList.push('Efternavn kræves');
        }
        if (!userInfo.Email) {
            errorList.push('Mail kræves');
        }
        if (!userInfo.DepartmentId) {
            errorList.push('Afdeling kræves');
        }
        if (!userInfo.UserGroupId) {
            errorList.push('Brugergruppe kræves');
        }

        // matches alphanumeric, case insensitive
        if (userInfo.FmUserName && !userInfo.FmUserName.match(/^[a-z0-9]+$/i)) {
            errorList.push(Localization.Customer.ErrorInvalidUserName);
        }
        if (userInfo.IntUserName && !userInfo.IntUserName.match(/^[a-z0-9]+$/i)) {
            errorList.push(Localization.Customer.ErrorInvalidUserName);
        }

        return errorList;
    }

    async userInfoToolbarClicked(args) {
        if (args.item.id === 'add') {
            this.props.history.push('/brugerkort/add');
            this.toggleUserInfoFields(true);

            let userInfo = {
                Id: '',
                FirstName: '',
                MiddleName: '',
                LastName: '',
                Initials: '',
                PhoneNumber: '',
                Email: '',
                Position: '',
                DepartmentId: 0,
                DepartmentName: '',
                Username: '',
                UserGroupId: 0,
                UserGroupName: '',
                PrimaryContact: false,
                LoginRule: 1,
                AccessControlPanel: true,
                CustomMenu: [],
                Account: {
                    AccessControlPanel: true,
                },
                UndoRedoStatus: {
                    Redo: false,
                    RedoAction: '',
                    Undo: false,
                    UndoAction: ''
                }
            };

            this.FirstNameRef.value = '';
            this.MiddleNameRef.value = '';
            this.LastNameRef.value = '';
            this.PhoneNumberRef.value = '';
            this.EmailRef.value = '';
            this.PositionRef.value = '';
            this.DepartmentRef.value = null;
            this.DepartmentRef.text = null;
            this.PrimaryContactRef.checked = false;
            this.IntUserNameRef.value = '';
            this.FmUserNameRef.value = '';
            this.OmUserNameRef.value = '';
            this.UserGroupRef.value = null;
            this.UserGroupRef.text = null;
            this.ApprovePurchaseOrderRef.checked = true;

            let userInfoId = document.getElementsByClassName('j-userInfoId')[0];
            if (userInfoId) {
                userInfoId.innerHTML = '';
            }

            // let userInfoInitials = document.getElementsByClassName('j-userInfoInitials')[0];
            // if (userInfoInitials) {
            //     userInfoInitials.innerHTML = '';
            // }

            this.userInfoToolbar.enableItems(2, false);
            this.userInfoToolbar.enableItems(3, false);
            this.userInfoToolbar.items[4].text = "Gem";
            this.userInfoToolbar.items[4].tooltipText = "Gem";
            this.userInfoToolbar.items[4].disabled = false;
            this.userInfoToolbar.enableItems(4, true);
            this.userInfoToolbar.enableItems(5, true);

            this.setState({ moduleState: 'add', userInfo });
        }
        else if (args.item.id === 'search') {
            this.props.history.push('/bruger-liste');
        }
        else if (args.item.id === 'edit') {
            this.userInfoToolbar.enableItems(2, false);
            this.userInfoToolbar.enableItems(4, true);
            this.userInfoToolbar.enableItems(5, true);
            this.toggleUserInfoFields(true);
            this.setState({ moduleState: 'edit', isEditMode: true });
        }
        else if (args.item.id === 'delete') {
            source.cancel();
            source = CancelToken.source();
            this.confirmDeleteDialog.show();
        }
        else if (args.item.id === 'save') {
            source.cancel();
            source = CancelToken.source();
    
            let { userInfo, moduleState } = this.state;

            const errorList = this.validateFields();
            if (errorList.length > 0) {
                this.dialogOpen("Advarsel", errorList.join('<br />'));
                return;
            }

            let state = moduleState === 'add' ? moduleState : 'update';
            
            const sessionInfo = await SessionService.GetSessionInfo();
            userInfo.CustomerId = sessionInfo.Id;
            
            userInfo.AccessControlPanel = this.AccessControlPanel;
            
            const result = await UserService.AddUpdateUser(state, userInfo, source.token);

            if (!result.HasError) {
                this.userInfoToolbar.enableItems(2, true);
                this.userInfoToolbar.enableItems(3, true);
                this.userInfoToolbar.enableItems(4, false);
                this.userInfoToolbar.enableItems(5, false);
                this.toggleUserInfoFields(false);
                this.dialogOpen(Localization.General.SuccessHeader, `${state === 'add'? Localization.General.SuccessAdd.replace("{context}", "Bruger") : Localization.General.SuccessUpdate.replace("{context}", "Bruger")}`);

                Object.assign(userInfo, result);
                this.setState({ userInfo, moduleState: 'edit' }, () => {
                    this.refreshUser();
                    
                    this.undoRedoOptions(result.UndoRedoStatus.Undo, result.UndoRedoStatus.Redo, result.UndoRedoStatus.UndoAction, result.UndoRedoStatus.RedoAction);
                    this.props.history.push(`/brugerkort/edit/${result.Id}`);
                });
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
        else if (args.item.id === 'cancel') {
            let { moduleState } = this.state;
            if (moduleState === 'add') {
                this.props.history.push('/bruger-liste');
            }
            else {
                this.reloadUserInfo().then(() => {
                    this.userInfoToolbar.enableItems(2, true);
                    this.userInfoToolbar.enableItems(4, false);
                    this.userInfoToolbar.enableItems(5, false);
                    this.toggleUserInfoFields(false);
                });
            }
        }
    }

    async onConfirmDelete(force = false) {
        let { userInfo, dialogOpts } = this.state;

        const result = await UserService.DeleteUser(userInfo.Id, { force }, source.token);
        if (!result.HasError) {
            this.confirmDeleteDialog.hide();
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessDelete.replace("{context}", "Bruger"));
            dialogOpts.redirectOnClose = true;
            dialogOpts.redirectTo = '/bruger-liste';
        }
        else {
            this.dialogOpen("Advarsel", result.ErrorMessage);
        }
        this.setState({ dialogOpts });
    }

    refreshUser() {
        const { userInfo, currentTabSelected } = this.state;
        // this.IdRef.value = userInfo.Id;

        switch(currentTabSelected) {
            case 0:
                this.FirstNameRef.value = userInfo.FirstName;
                this.MiddleNameRef.value = userInfo.MiddleName;
                this.LastNameRef.value = userInfo.LastName;
                this.InitialsRef.value = userInfo.Initials;
                this.PhoneNumberRef.value = userInfo.PhoneNumber;
                this.EmailRef.value = userInfo.Email;
                this.PositionRef.value = userInfo.Position;
                this.DepartmentRef.value = userInfo.DepartmentId;
                this.PrimaryContactRef.checked = userInfo.PrimaryContact;
                this.IntUserNameRef.value = userInfo.IntUserName;
                this.FmUserNameRef.value = userInfo.FmUserName;
                this.OmUserNameRef.value = userInfo.OmUserName;
                this.UserGroupRef.value = userInfo.UserGroupId;
        
                let userInfoId = document.getElementsByClassName('j-userInfoId')[0];
                if (userInfoId) {
                    userInfoId.innerHTML = userInfo.Id;
                }
                break;
            case 1:
                break;
        }

        // let userInfoInitials = document.getElementsByClassName('j-userInfoInitials')[0];
        // if (userInfoInitials) {
        //     userInfoInitials.innerHTML = userInfo.Initials || userInfo.Account.Initials;
        // }
    }

    toggleUserInfoFields(enabled) {
        const { currentTabSelected } = this.state;

        switch(currentTabSelected) {
            case 0:
                this.FirstNameRef.disabled = !enabled;
                this.MiddleNameRef.disabled = !enabled;
                this.LastNameRef.disabled = !enabled;
                this.InitialsRef.disabled = !enabled;
                this.PhoneNumberRef.disabled = !enabled;
                this.EmailRef.disabled = !enabled;
                this.PositionRef.disabled = !enabled;
                this.DepartmentRef.enabled = enabled;
                this.PrimaryContactRef.disabled = !enabled;
                this.IntUserNameRef.disabled = !enabled;
                this.FmUserNameRef.disabled = !enabled;
                this.OmUserNameRef.disabled = !enabled;
                this.UserGroupRef.enabled = enabled;
                break;
            case 1:
                this.userGroupTreeRef.disabled = !enabled;
                this.ddlLoginRuleRef.enabled = enabled;
                this.AccessControlPanelRef.disabled = !enabled;
                break;
        }
    }

    onResponsibilityGroupChangeAll (args) {
        let { userInfo, responsibilityGroups, AllResGroupsChecked} = this.state;

        AllResGroupsChecked = args.checked;
        if (args.checked) {
            userInfo.ResponsiblityGroupIds = responsibilityGroups.map(group => {
                return group.Id;
            });
        } else {
            userInfo.ResponsiblityGroupIds = [];
        }

        this.setState({ userInfo, AllResGroupsChecked });
    }

    onResponsibilityGroupChange(args, id) {
        let { userInfo, responsibilityGroups, AllResGroupsChecked} = this.state;

        if (userInfo.ResponsiblityGroupIds.some(x => x === id)) {
            if (!args.checked) {
                userInfo.ResponsiblityGroupIds = userInfo.ResponsiblityGroupIds.filter(x => x !== id);
            }
        }
        else if (args.checked) {
            userInfo.ResponsiblityGroupIds.push(id);
        }

        var tempGroups = responsibilityGroups.map(group => { return group.Id });
        AllResGroupsChecked = this.arraysEqual(tempGroups, userInfo.ResponsiblityGroupIds);

        this.setState({ userInfo, AllResGroupsChecked });
    }

    onRowClick(id) {
        let { userInfo, isEditMode, responsibilityGroups, AllResGroupsChecked } = this.state;
        if (isEditMode) {
            if (userInfo.ResponsiblityGroupIds.some(x => x === id)) {
                userInfo.ResponsiblityGroupIds = userInfo.ResponsiblityGroupIds.filter(x => x !== id);
            }
            else {
                userInfo.ResponsiblityGroupIds.push(id);
            }

            var tempGroups = responsibilityGroups.map(group => { return group.Id });
            AllResGroupsChecked = this.arraysEqual(tempGroups, userInfo.ResponsiblityGroupIds);

            this.setState({ userInfo, AllResGroupsChecked });
        }
    }

    userCardTab() {
        let { userInfo, moduleState, isEditMode, responsibilityGroups } = this.state;

        let isCancelDisabled = true, isFieldsEnable = false;

        if (moduleState === 'add') {
            isCancelDisabled = false;
            isFieldsEnable = true;
        }
        else {
            if (isEditMode) {
                isCancelDisabled = false;
                isFieldsEnable = true;
            }
        }
        
        return (
            <div className="tab-control--item user-card-wrapper">
                {/* <div className="user-card-toolbar-wrapper">
                    <ToolbarComponent ref={ref => this.userInfoToolbar = ref} clicked={this.userInfoToolbarClicked}>
                        <ItemsDirective>
                            <ItemDirective id="add" prefixIcon='tb-icons c-icon-add-black' text="Ny" tooltipText='Ny'/>
                            <ItemDirective id="search" prefixIcon='tb-icons c-icon-search-list' text="Søgeliste" tooltipText='Søgeliste' />
                            <ItemDirective id="edit" prefixIcon='tb-icons c-icon-edit' text="Redigere" tooltipText='Redigere' />
                            <ItemDirective id="delete" prefixIcon='tb-icons c-icon-delete' text="Slette" tooltipText='Slette' />
                            <ItemDirective id="save" prefixIcon='tb-icons c-icon-update' text='Gem' tooltipText='Gem' disabled={true} />
                            <ItemDirective id="cancel" prefixIcon='tb-icons c-icon-cancel' text="Annuller" tooltipText='Annuller' disabled={isCancelDisabled} />
                        </ItemsDirective>
                    </ToolbarComponent>
                </div> */}
                <div className="user-card">
                    <table className="user-info-table" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td className="label">Bruger nr.</td>
                                <td className="j-userInfoId" style={{fontSize: 13}}>
                                    {/* <TextBoxComponent ref={ref => this.IdRef = ref} value={userInfo.Id} enabled={false} name="Id" change={this.onChange} /> */}
                                    {userInfo.Id}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Fornavn <span className="required">*</span></td>
                                <td>
                                    {/* <TextBoxComponent ref={ref => this.FirstNameRef = ref} value={userInfo.FirstName} enabled={false} name="FirstName" change={this.onChange} /> */}
                                    <input type="text" ref={ref => this.FirstNameRef = ref} value={userInfo.FirstName} disabled={!isFieldsEnable} name="FirstName" className="e-control e-textbox e-lib e-input" onChange={this.onChange} />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Mellem navn</td>
                                <td>
                                    {/* <TextBoxComponent ref={ref => this.MiddleNameRef = ref} value={userInfo.MiddleName} enabled={false} name="MiddleName" change={this.onChange} /> */}
                                    <input type="text" ref={ref => this.MiddleNameRef = ref} value={userInfo.MiddleName} disabled={!isFieldsEnable} name="MiddleName" className="e-control e-textbox e-lib e-input" onChange={this.onChange} />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Efternavn <span className="required">*</span></td>
                                <td>
                                    {/* <TextBoxComponent ref={ref => this.LastNameRef = ref} value={userInfo.LastName} enabled={false} name="LastName" change={this.onChange} /> */}
                                    <input type="text" ref={ref => this.LastNameRef = ref} value={userInfo.LastName} disabled={!isFieldsEnable} name="LastName" className="e-control e-textbox e-lib e-input" onChange={this.onChange} />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Initialer</td>
                                <td>
                                    {/* <TextBoxComponent ref={ref => this.InitialsRef = ref} value={userInfo.Initials} enabled={false} name="Initials" change={this.onChange} /> */}
                                    {/* {userInfo.Initials} */}
                                    <input type="text" ref={ref => this.InitialsRef = ref} value={userInfo.Initials} disabled={!isFieldsEnable} name="Initials" className="e-control e-textbox e-lib e-input" onChange={this.onChange} />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Tlf.</td>
                                <td>
                                    {/* <TextBoxComponent ref={ref => this.PhoneNumberRef = ref} value={userInfo.PhoneNumber} enabled={false} name="PhoneNumber" change={this.onChange} /> */}
                                    <input type="text" ref={ref => this.PhoneNumberRef = ref} value={userInfo.PhoneNumber} disabled={!isFieldsEnable} name="PhoneNumber" className="e-control e-textbox e-lib e-input" onChange={this.onChange} />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Mail <span className="required">*</span></td>
                                <td>
                                    {/* <TextBoxComponent ref={ref => this.EmailRef = ref} value={userInfo.Email} enabled={false} name="Email" change={this.onChange} /> */}
                                    <input type="text" ref={ref => this.EmailRef = ref} value={userInfo.Email} disabled={!isFieldsEnable} name="Email" className="e-control e-textbox e-lib e-input" onChange={this.onChange} />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Stilling</td>
                                <td>
                                    {/* <TextBoxComponent ref={ref => this.PositionRef = ref} value={userInfo.Position} enabled={false} name="Position" change={this.onChange} /> */}
                                    <input type="text" ref={ref => this.PositionRef = ref} value={userInfo.Position} disabled={!isFieldsEnable} name="Position" className="e-control e-textbox e-lib e-input" onChange={this.onChange} />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Afdeling <span className="required">*</span></td>
                                <td>
                                    <DropDownListComponent ref={ref => this.DepartmentRef = ref} id="ddlDepartment" name="DepartmentId" value={userInfo.DepartmentId} dataSource={this.departmentsData} 
                                        filtering={this.onDepartmentFiltering.bind(this)} filterBarPlaceholder='Search department' allowFiltering={true} enabled={isFieldsEnable}
                                        fields={{ text: 'Name', value: 'Id' }} change={this.onDepartmentChange.bind(this)} locale="da" />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Primære kontakt</td>
                                <td>
                                    <CheckBoxComponent ref={ref => this.PrimaryContactRef = ref} checked={userInfo.PrimaryContact} disabled={!isFieldsEnable} name="PrimaryContact" change={this.onPrimaryContactChange.bind(this)} />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Brugergruppe <span className="required">*</span></td>
                                <td>
                                    <DropDownListComponent ref={ref => this.UserGroupRef = ref} id="ddlUserGroup" name="UserGroupId" value={userInfo.UserGroupId} dataSource={this.userGroupsData}
                                        fields={{ text: 'Name', value: 'Id' }} change={this.onUserGroupChange.bind(this)} enabled={isFieldsEnable} locale="da" />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Deaktiveret</td>
                                <td>
                                    <CheckBoxComponent  checked={!userInfo.Account.Active} disabled={true} />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Brugernavn for Intralogix</td>
                                <td>
                                    <input type="text" ref={ref => this.IntUserNameRef = ref} value={userInfo.IntUserName} disabled={!isFieldsEnable} name="IntUserName" className="e-control e-textbox e-lib e-input" onChange={this.onChange} />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Brugernavn for FlexMat</td>
                                <td>
                                    <input type="text" ref={ref => this.FmUserNameRef = ref} value={userInfo.FmUserName} disabled={!isFieldsEnable} name="FmUserName" className="e-control e-textbox e-lib e-input" onChange={this.onChange} />
                                </td>
                            </tr>
                            <tr>
                                <td className="label">Brugernavn for OilMat</td>
                                <td>
                                    <input type="text" ref={ref => this.OmUserNameRef = ref} value={userInfo.OmUserName} disabled={!isFieldsEnable} name="OmUserName" className="e-control e-textbox e-lib e-input" onChange={this.onChange} />
                                </td>
                            </tr>
                            <tr>
                                <td style={{border: 'none'}} className="label"></td>
                                <td style={{border: 'none'}}>
                                    <button className="e-btn e-primary" type="button" onClick={this.resendCredentials}>Send Log <br/>in oplysninger</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="user-responsibility-group">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="label" width="80%">Ansvars Gruppe</th>
                                    <th width="20%">
                                        <div className="text-center">
                                            <CheckBoxComponent disabled={!isFieldsEnable} checked={this.state.AllResGroupsChecked} change={this.onResponsibilityGroupChangeAll} />
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    responsibilityGroups.length <= 0  ?
                                    <tr>
                                        <td colSpan="2" className="sub-text text-center">{Localization.General.EmptyList}</td>
                                    </tr>
                                    :
                                    responsibilityGroups.map(res => {
                                        let isChecked = userInfo && userInfo.ResponsiblityGroupIds && userInfo.ResponsiblityGroupIds.some(x => x === res.Id);
                                        return (
                                            <tr className={isFieldsEnable ? 'clickable' : ''} onClick={() => this.onRowClick(res.Id)}>
                                                <td className="regular-text">{res.Name}</td>
                                                <td>
                                                    <div className="text-center">
                                                        <CheckBoxComponent disabled={!isFieldsEnable} checked={isChecked} value={res.Id} change={(e) => this.onResponsibilityGroupChange(e, res.Id)} />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    onLoginRuleChange(args) {
        let { userInfo } = this.state;
        userInfo.LoginRule = args.value;
        this.setState({ userInfo, hasUnsavedChanges: true });
    }

    // async onUserSettingsToolbarClicked(args) {
    //     if (args.item.id === 'edit') {
    //         this.UserSettingsToolbarRef.enableItems(0, false);
    //         this.UserSettingsToolbarRef.enableItems(1, true);
    //         this.UserSettingsToolbarRef.enableItems(2, true);

    //         this.userGroupTreeRef.disabled = false;
    //         this.ddlLoginRuleRef.enabled = true;
    //         this.AccessControlPanelRef.disabled = false;
    //         this.setState({ isEditMode: true });
    //     }
    //     else if (args.item.id === 'update') {
    //         source.cancel();
    //         source = CancelToken.source();

    //         let { userInfo, menus } = this.state;
    //         userInfo.CustomMenu = [];
            
    //         const nodeIds = this.userGroupTreeRef.getAllCheckedNodes();
    //         nodeIds.forEach(nId => {
    //             if (nId > 400 && nId < 500) {
    //                 if (menus.some(x => x.Id === parseInt(nId))) { // if parent node is checked
    //                     if (userInfo.CustomMenu.length === 0 || !userInfo.CustomMenu.some(x => x.Id === parseInt(nId) - 400)) {
    //                         let menu = menus.find(x => x.Id === parseInt(nId));
    //                         if (menu) {
    //                             let tMenu = JSON.parse(JSON.stringify(menu));
    //                             tMenu.Id = tMenu.Id - 400;
    //                             tMenu.MenuItems.forEach(m => {
    //                                 m.Id = m.Id - 500;
    //                             });
    //                             userInfo.CustomMenu.push(tMenu);
    //                         }
    //                     }
    //                 }
    //             }
    //             else {
    //                 menus.forEach(mi => {
    //                     if (mi.MenuItems.some(x => x.Id === parseInt(nId))) {
    //                         let menuItem = mi.MenuItems.find(x => x.Id === parseInt(nId));
    //                         if (userInfo.CustomMenu.some(u => u.Id === parseInt(mi.Id) - 400)) { // if child node's parent node already exist in state
    //                             let user = userInfo.CustomMenu.find(u => u.Id === parseInt(mi.Id) - 400);
    //                             if (!user.MenuItems.some(x=> x.Id === (menuItem.Id - 500))) {
    //                                 // menuItem.Id = menuItem.Id - 500;
    //                                 // user.MenuItems.push(menuItem)
    //                                 let tMenuItem = JSON.parse(JSON.stringify(menuItem));
    //                                 tMenuItem.Id = tMenuItem.Id - 500;
    //                                 if (tMenuItem.Id > 0) {
    //                                     user.MenuItems.push(tMenuItem);
    //                                 }
    //                             }
    //                         }
    //                         else { // if child node's parent node does not exist in state
    //                             let newMenu = Object.assign({}, mi);
    //                             newMenu.Id = newMenu.Id - 400;
    //                             newMenu.MenuItems = newMenu.MenuItems.filter(x=> x.Id === menuItem.Id).map(a => { 
    //                                 a.Id = a.Id - 500;
    //                                 return { ...a };
    //                             });
    //                             userInfo.CustomMenu.push(newMenu);
    //                         }
    //                     }
    //                 });
    //             }
    //         });

    //         userInfo.AccessControlPanel = this.AccessControlPanelRef.checked;
    //         const result = await UserService.AddUpdateUser('update', userInfo, source.token);

    //         if (!result.HasError) {
    //             this.undoRedoOptions(result.UndoRedoStatus.Undo, result.UndoRedoStatus.Redo, result.UndoRedoStatus.UndoAction, result.UndoRedoStatus.RedoAction);
    //             this.reloadUserInfo();
    //             this.UserSettingsToolbarRef.enableItems(0, true);
    //             this.UserSettingsToolbarRef.enableItems(1, false);
    //             this.UserSettingsToolbarRef.enableItems(2, false);
        
    //             this.userGroupTreeRef.disabled = true;
    //             this.ddlLoginRuleRef.enabled = false;
    //             this.AccessControlPanelRef.disabled = true;

    //             this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Bruger"));
    //         }
    //         else {
    //             this.dialogOpen("Advarsel", result.ErrorMessage);
    //         }
    //     }
    //     else if (args.item.id === 'cancel') {
    //         this.UserSettingsToolbarRef.enableItems(0, true);
    //         this.UserSettingsToolbarRef.enableItems(1, false);
    //         this.UserSettingsToolbarRef.enableItems(2, false);
    //         this.userGroupTreeRef.disabled = true;
    //         this.ddlLoginRuleRef.enabled = false;
    //         this.AccessControlPanelRef.disabled = true;
    //         this.toggleUserInfoFields(false);
    //         this.setState({ isEditMode: false });
    //     }
    // }

    onOrdersApprovalChange(args) {
        let { userInfo } = this.state;
        userInfo.OrdersApproval = args.checked;
        this.setState({ userInfo });
    }

    onAccessControlPanelChange(args) {
        if (args.checked) {
            let text = '';
            text = "Du har valgt at ”bruger” skal have adgang til et kontrolpanel. <br><br>";
            text += "Dette valg medføre en ændring i dit abonnement og dermed en ekstra udgift <br><br>";
            text += "Tryk OK for at acceptere abonnementsændringen";
            this.confirmAccessControlPanelDialog.content = text;
            this.confirmAccessControlPanelDialog.show();
        }
        else {
            this.AccessControlPanel = args.checked;
        }
    }

    userGroupNodeSelected() {
        let { userInfo, menus } = this.state;
        const nodeIds = this.userGroupTreeRef.getAllCheckedNodes();

        userInfo.CustomMenu = buildMenuFromCheckedNodes(this.menuData, nodeIds);
        this.setState({ userInfo, hasUnsavedChanges: true });
    }

    userGroupNodeCheck(args) {
        let checkedNode = [args.node];
        if (args.event.target.classList.contains('e-fullrow') || args.event.key == "Enter") {
            let getNodeDetails = this.userMenuTreeRef.getNode(args.node);
            if (getNodeDetails.expanded) {
                this.userMenuTreeRef.collapseAll(checkedNode);
            }
            else {
                this.userMenuTreeRef.expandAll(checkedNode);
            }
        }
        this.setState({ hasUnsavedChanges: true });
    }

    userSettingsTab() {
        const { userInfo, moduleState, isEditMode } = this.state;

        return (
            <div className="tab-control--item user-settings-wrapper">
                {/* <div className="user-setting-toolbar-wrapper">
                    <ToolbarComponent ref={ref => this.UserSettingsToolbarRef = ref} clicked={this.onUserSettingsToolbarClicked}>
                        <ItemsDirective>
                            <ItemDirective id="edit" prefixIcon='tb-icons c-icon-edit' text="Redigere" tooltipText='Redigere' disabled={moduleState === 'add' ? true: false} />
                            <ItemDirective id="update" prefixIcon='tb-icons c-icon-update' text="Gem" tooltipText='Gem' disabled={moduleState === 'add' ? false: true} />
                            <ItemDirective id="cancel" prefixIcon='tb-icons c-icon-cancel' text="Annuller" tooltipText='Annuller' disabled={true} />
                        </ItemsDirective>
                    </ToolbarComponent>
                </div> */}
                <div className="user-settings-group-functions">
                    <Card headerText="Bruger Gr." subText="Klik på + for tilhørende funktioner">
                        <TreeViewComponent id="user-settings-user-group" ref={ref => this.userGroupTreeRef = ref} fields={this.menuFields} nodeTemplate={this.menuNodeTemplate} expandOn="Click"
                            cssClass="sidebar-tree-menu" showCheckBox={true} disabled={!isEditMode} nodeChecked={this.userGroupNodeSelected.bind(this)} />
                        {/* <TreeViewComponent id='user-group-menu-tree' ref={ref => this.userMenuTreeRef = ref} fields={this.menuFields} showCheckBox={true} disabled={true}
                            nodeClicked={this.userGroupNodeCheck.bind(this)} /> */}
                    </Card>
                </div>
                <div className="user-settings-misc">
                    <Card headerText="Log in regel l på FlexMat">
                        <DropDownListComponent id="ddlLoginRule" ref={ref => this.ddlLoginRuleRef = ref} name="LoginRule" value={userInfo.LoginRule} dataSource={this.userSettingsLoginRules} 
                            placeholder="Log in regel l på FlexMat" fields={{ text: 'Key', value: 'Value' }} change={this.onLoginRuleChange.bind(this)} enabled={isEditMode} locale="da" />
                    </Card>
                    <Card headerText="Notifikation" className="access-control-panel" showHeader={false}>
                        <div className="field-bordered">
                            <label htmlFor="OrdersApproval" style={{fontSize:13}}>Godkendelse af Ordre</label>
                            <CheckBoxComponent id="OrdersApproval" ref={c => this.OrdersApprovalRef = c} disabled={!isEditMode} name="OrdersApproval" cssClass="control-right" checked={userInfo.OrdersApproval} 
                                change={this.onOrdersApprovalChange} />
                            {/* checked={userInfo.AccessControlPanel}   */}
                        </div>
                    </Card>
                    <Card headerText="Notifikation" className="access-control-panel" showHeader={false}>
                        <div className="field-bordered">
                            <label htmlFor="AccessControlPanel" style={{fontSize:13}}>Adgang til Kontrol panel</label>
                            <CheckBoxComponent id="AccessControlPanel" ref={c => this.AccessControlPanelRef = c} disabled={!isEditMode} name="AccessControlPanel" cssClass="control-right" checked={userInfo.Account.AccessControlPanel} 
                                change={this.onAccessControlPanelChange} />
                            {/* checked={userInfo.AccessControlPanel}   */}
                        </div>
                    </Card>
                </div>
            </div>
        )
    }
    
    oilmatSettingsTab() {
        let { userInfo, moduleState, isEditMode } = this.state;

        let isFieldsEnable = false;

        if (moduleState === 'add') {
            isFieldsEnable = true;
        }
        else {
            if (isEditMode) {
                isFieldsEnable = true;
            }
        }

        return (
            <div className="tab-control--item user-settings-wrapper">
                <div className="user-card">
                    <table className="user-info-table" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td className="label">Godkend Indkøbs ordre</td>
                                <td>
                                    <CheckBoxComponent ref={ref => this.ApprovePurchaseOrderRef = ref} checked={userInfo.OilmatUser.ApprovePurchaseOrders} disabled={!isFieldsEnable} name="ApprovePurchaseOrders" change={this.onApprovePurchaseOrderChange.bind(this)} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    tabSelected(args) {
        let { breadcrumbs, userInfo, selectedTab, moduleState } = this.state;
        const headerText = this.tabHeader[args.selectedIndex].text;
        let headerElement = document.querySelector('.module-header > h1');
        headerElement.innerHTML = headerText;

        const selectedItem = this.userCardTabRef.selectedItem;

        switch(selectedItem) {
            case 0:
                if (breadcrumbs.length > 1) {
                    breadcrumbs.pop();
                }
                breadcrumbs.push(userInfo.FullName);
                selectedTab = 0;
                break;
            case 1:
                if (breadcrumbs.length > 1) {
                    breadcrumbs.pop();
                }
                breadcrumbs.push(this.tabHeader[args.selectedIndex].text);
                selectedTab = 1;
                
                // if (userInfo.Id) {
                //     let selectedIds = [];
    
                //     userInfo.CustomMenu.forEach(c => {
                //         if (c.MenuItems && c.MenuItems.length > 0) {
                //             c.MenuItems.forEach(mi => {
                //                 selectedIds.push(mi.Id.toString());
                //             });
                //         }
                //         else {
                //             selectedIds.push(c.Id.toString());
                //         }
                //     });
    
                //     this.userGroupTreeRef.checkedNodes = selectedIds;
                // }

                // if (userInfo.CustomMenu.length > 0) {
                //     let selectedMenus = [];
                //     userInfo.CustomMenu.forEach(menu => {
                //         if (menu.MenuItems && menu.MenuItems.length > 0) {
                //             menu.MenuItems.forEach(mi => {
                //                 if (!selectedMenus.some(x => x === (mi.Id + 500).toString())) {
                //                     selectedMenus.push((mi.Id + 500).toString());
                //                 }
                //             });
                //         }
                //         else {
                //             if (!selectedMenus.some(x => x === (menu.Id + 400).toString())) {
                //                 selectedMenus.push((menu.Id + 400).toString());
                //             }
                //         }
                //     });

                //     this.userGroupTreeRef.checkedNodes = selectedMenus;
                // }

                let checkedMenuNodes = buildCheckedNodes(this.menuData, userInfo.CustomMenu);
                this.userGroupTreeRef.fields.dataSource = [];
                this.userGroupTreeRef.refresh();
                this.menuFields.dataSource = checkedMenuNodes;
                this.userGroupTreeRef.fields.dataSource = checkedMenuNodes;

                console.log(userInfo.UserGroup.Menu);
                // let virtualMenu = buildVirtualMenu(parentGroup.Menu);
                // let checkedMenuNodes = buildCheckedNodes(virtualMenu, selectedGroup.Menu);
                // this.menuTreeRef.fields.dataSource = [];
                // this.menuTreeRef.refresh();
                // this.treeFields.dataSource = checkedMenuNodes;
                // this.menuTreeRef.fields.dataSource = checkedMenuNodes;

                
                let isProceedToNextTab = true;

                if (!userInfo.FirstName) { isProceedToNextTab =  false; }
                if (!userInfo.LastName) { isProceedToNextTab =  false; }
                if (!userInfo.Email) { isProceedToNextTab =  false; }
                if (!userInfo.DepartmentId || userInfo.DepartmentId === 0) { isProceedToNextTab =  false; }
                if (!userInfo.Username) { isProceedToNextTab =  false; }
                if (!userInfo.UserGroupId || userInfo.UserGroupId === 0) { isProceedToNextTab =  false; }

                if (!isProceedToNextTab) {
                    this.dialogOpen(Localization.General.NoteHeader, Localization.General.NotifyRequireFieldsNotFilled);
                }

                // if (moduleState === 'add') {
                //     this.userGroupTreeRef.disabled = false;
                //     this.ddlLoginRuleRef.enabled = true;
                //     this.AccessControlPanelRef.disabled = false;
                // }
                break;
            default:
                break;
        }

        // this.undoRedoOptions(false, false, '', '');
        this.setState({ breadcrumbs, selectedTab });
    }

    async onUserToolbarClicked(args) {
        source.cancel()
        source = CancelToken.source();
        const { userInfo, currentTabSelected } = this.state;
        
        if (args.item.id === 'add') {
            this.props.history.push('/brugerkort/add');
            this.toggleUserInfoFields(true);

            let userInfo = {
                Id: '',
                FirstName: '',
                MiddleName: '',
                LastName: '',
                Initials: '',
                PhoneNumber: '',
                Email: '',
                Position: '',
                DepartmentId: 0,
                DepartmentName: '',
                Username: '',
                IntUserName: '',
                FmUserName: '',
                OmUserName: '',
                UserGroupId: 0,
                UserGroupName: '',
                PrimaryContact: false,
                LoginRule: 1,
                AccessControlPanel: false,
                CustomMenu: { Intralogix: [], Flexmat: [] },
                Account: {
                    Active: true,
                    AccessControlPanel: false,
                },
                UndoRedoStatus: {
                    Redo: false,
                    RedoAction: '',
                    Undo: false,
                    UndoAction: ''
                }
            };

            this.AccessControlPanel = false;

            this.setState({ moduleState: 'add', userInfo, isEditMode: true }, () => {
                switch(currentTabSelected) {
                    case 0:
                        this.DepartmentRef.value = null;
                        this.DepartmentRef.text = null;
                        this.DepartmentRef.enabled = true;
                        this.UserGroupRef.value = null;
                        this.UserGroupRef.text = null;
                        this.UserGroupRef.enabled = true;
                        let userInfoId = document.getElementsByClassName('j-userInfoId')[0];
                        if (userInfoId) {
                            userInfoId.innerHTML = '';
                        }
                        break;
                    case 1: 
                        this.AccessControlPanel = false;
                        this.AccessControlPanelRef.checked = false;
                        this.userGroupTreeRef.uncheckAll();
                        break;
                }
            });
        }
        else if (args.item.id === 'search') {
            this.props.history.push('/bruger-liste');
        }
        else if (args.item.id === 'edit') {
            this.UserToolbarRef.enableItems(2, false);
            this.UserToolbarRef.enableItems(4, true);
            this.UserToolbarRef.enableItems(5, true);
            this.toggleUserInfoFields(true);
            this.setState({ moduleState: 'edit', isEditMode: true });
        }
        else if (args.item.id === 'delete') {
            source.cancel();
            source = CancelToken.source();
            let { userInfo } = this.state;
            let buttons = null;
            
            if (userInfo.InUse) {
                buttons = messageHelper.MB3.buttons;

                buttons[0].click = () => {
                    this.confirmDeleteDialog.hide();
                };

                buttons[1].click = async () => {
                    await this.onConfirmDelete(true);
                    this.confirmDeleteDialog.hide()
                }
                this.confirmDeleteDialog.content = messageHelper.MB3.message;
            }
            else {
                buttons = this.confirmDeleteButtons;
                this.confirmDeleteDialog.content = messageHelper.MB2.message.replace("{context_message}", 'bruger')
            }

            this.confirmDeleteDialog.show();
        }
        else if (args.item.id === 'save') {
            await this.initiateSave();
        }
        else if (args.item.id === 'cancel') {
            let { moduleState } = this.state;

            if (moduleState === 'add') {
                this.props.history.push('/bruger-liste');
            }
            else {
                this.setState({ isEditMode: false }, () => {
                    this.reloadUserInfo().then(() => {
                        this.UserToolbarRef.enableItems(2, true);
                        this.UserToolbarRef.enableItems(4, false);
                        this.UserToolbarRef.enableItems(5, false);
                        this.toggleUserInfoFields(false);
                    });
                });
            }
        }
        else if (args.item.id === 'undo') {
            const result = await UserService.UndoUser(userInfo.Id, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Bruger"));
                this.undoRedoOptions(result.Undo, result.Redo, result.UndoAction, result.RedoAction);

                this.reloadUserInfo();
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
        else if (args.item.id === 'redo') {
            const result = await UserService.RedoUser(userInfo.Id, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Bruger"));
                this.undoRedoOptions(result.Undo, result.Redo, result.UndoAction, result.RedoAction);
                
                this.reloadUserInfo();
            }
            else {
                this.dialogOpen("Advarsel", result.ErrorMessage);
            }
        }
        else if (args.item.id === 'next' || args.item.id === 'prev') {
            let { userInfo, hasNext, hasPrev, hasUnsavedChanges } = this.state;
            let nextId = args.item.id === 'next' ? userInfo.NextId : userInfo.PrevId;

            if (hasUnsavedChanges) {
                this.setState({redirectId: nextId});
                this.NavigationPromptRef.showModal(window.location.href);
            }
            else {
                userInfo.Id = nextId;
                this.props.history.push(`/brugerkort/edit/${userInfo.Id}`);
                this.setState({ userInfo, hasNext, hasPrev }, () => {
                    this.reloadUserInfo();
                });
            }
        }
    }

    async initiateSave() {
        source.cancel();
        source = CancelToken.source();

        let { userInfo, moduleState, menus, redirectId } = this.state;
        // userInfo.CustomMenu = [];

        const errorList = this.validateFields();
        if (errorList.length > 0) {
            this.dialogOpen("Advarsel", errorList.join('<br />'));
            return;
        }

        userInfo.AccessControlPanel = this.AccessControlPanel;
        // const menuTreeIds = this.userGroupTreeRef.checkedNodes;
        // let selectedMenus = buildMenuFromCheckedNodes(this.menuData, menuTreeIds);
        // userInfo.CustomMenu = selectedMenus;

        let state = moduleState === 'add' ? moduleState : 'update';
        const sessionInfo = await SessionService.GetSessionInfo();
        userInfo.CustomerId = sessionInfo.Id;
        
        const result = await UserService.AddUpdateUser(state, userInfo, source.token);

        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, `${state === 'add'? Localization.General.SuccessAdd.replace("{context}", "Bruger") : Localization.General.SuccessUpdate.replace("{context}", "Bruger")}`);

            Object.assign(userInfo, result);
            userInfo.Initials = userInfo.Account.Initials;
            
            this.setState({ userInfo, moduleState: 'edit', isEditMode: false, hasUnsavedChanges: false }, () => {
                this.UserToolbarRef.enableItems(2, true);
                this.UserToolbarRef.enableItems(3, true);
                this.UserToolbarRef.enableItems(4, false);
                this.UserToolbarRef.enableItems(5, false);
                this.toggleUserInfoFields(false);
                if (redirectId != 0) {
                    userInfo.Id = redirectId;
                    this.props.history.push(`/brugerkort/edit/${userInfo.Id}`);
                    this.setState({ userInfo }, () => {
                        this.reloadUserInfo();
                    });
                }
                else {
                    this.refreshUser();
                    this.undoRedoOptions(result.UndoRedoStatus.Undo, result.UndoRedoStatus.Redo, result.UndoRedoStatus.UndoAction, result.UndoRedoStatus.RedoAction);
                    this.props.history.push(`/brugerkort/edit/${result.Id}`);
                }
            });
        }
        else {
            this.dialogOpen("Advarsel", result.ErrorMessage);
        }
    }

    undoRedoOptions(hasUndo, hasRedo, undoTooltip = '', redoTooltip = '') {
        // this.UserToolbarRef.items[0].disabled = !hasUndo;
        // this.UserToolbarRef.items[0].tooltipText = undoTooltip;
        // this.UserToolbarRef.items[1].disabled = !hasRedo;
        // this.UserToolbarRef.items[1].tooltipText = redoTooltip;
    }

    dialogOpen(header, content, callback = null) {
        this.dialogInstance.header = header;
        this.dialogInstance.content = content;

        if (callback) {
            let buttons = this.buttons;
            this.dialogInstance.buttons = buttons;
        }

        this.dialogInstance.show();
    }

    dialogClose() {
        this.dialogInstance.hide();
        let { dialogOpts } = this.state;
        this.setState({ dialogOpts }, () => {
            if(dialogOpts.redirectOnClose) {
                this.props.history.push(dialogOpts.redirectTo);
            }
        });
    }

    onTabSelected(index) {
        const { breadcrumbs, userInfo, menus } = this.state;
        if (breadcrumbs.length > 1) {
            breadcrumbs.pop();
        }

        let headerElement = document.querySelector('.module-header > h1');
        
        let headerText = this.tabHeader[index].text;
        headerElement.innerHTML = headerText;
        breadcrumbs.push(headerText);

        switch(index) {
            case 0:
                this.setState({ currentTabSelected: index, breadcrumbs });
                break;
            case 1:
                this.setState({ currentTabSelected: index, breadcrumbs }, () => {
                    if (userInfo.CustomMenu && this.menuData) {
                        let tempMenus = buildVirtualMenu(userInfo.UserGroup.Menu);
                        this.menuFields.dataSource = tempMenus;
                        this.menuData = tempMenus;
                        let checkedMenuNodes = buildCheckedNodes(tempMenus, userInfo.CustomMenu);
                        this.userGroupTreeRef.fields.dataSource = [];
                        this.userGroupTreeRef.refresh();
                        this.menuFields.dataSource = checkedMenuNodes;
                        this.userGroupTreeRef.fields.dataSource = checkedMenuNodes;
                    }
                    setTimeout(() => {
                        if (this.userGroupTreeRef) {
                            this.userGroupTreeRef.collapseAll();
                        }
                    }, 200);
                });
            case 2:
                this.setState({ currentTabSelected: index, breadcrumbs });
                break;
        }
    }

    combineMenus(customMenu, groupMenu) {
        if (customMenu.Intralogix.length > 0) {
            customMenu.Intralogix.forEach(int => {
                if (groupMenu.some(x => x.Name === int.Name)) {
                    let menu = groupMenu.find(x => x.Name === int.Name);
                    if (int.Functions.length > 0) {
                        menu.Functions.concat(int.Functions);
                    }

                    if (int.MenuItems.length > 0) {
                        menu.MenuItems.concat(int.MenuItems);
                    }

                    if (int.MenuTabs.length > 0) {
                        menu.MenuTabs.concat(int.MenuTabs);
                    }
                    
                }
                else {
                    groupMenu.push(int);
                }
            });
        }

        customMenu.Intralogix = groupMenu;
        return customMenu;
    }

    async resendCredentials() {
        const { userInfo } = this.state;
        const payload = {
            Email: userInfo.Email
        };
        const result = await AccountService.AutoResetPassword(payload, source.token);

        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, "Login information is sent to your e-mail.");
        }
        else {
            this.dialogOpen("Advarsel", result.ErrorMessage);
        }
    }

    onCancelSave() {
        this.setState({redirectId: 0});
    }

    render() {
        const { loading, breadcrumbs, userInfo, selectedTab, moduleState, isEditMode, isInactive, currentTabSelected, redirect, hasUnsavedChanges, hasNext, hasPrev } = this.state;

        if(loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        let isCancelDisabled = true, isSaveDisabled = true, isEditDisabled = isEditMode, isDeleteDisabled = false;
        let isEditVisible = false, isSaveVisible = false, isCancelVisible = false;

        if(super.hasMenuItemTabFunction("Bruger kort", "Bruger kort", "Redigere")) {
            isEditVisible = true;
        }

        if (moduleState === 'add') {
            isCancelDisabled = false;
            isSaveDisabled = false;
            isDeleteDisabled = true;
            isEditDisabled = true;

            isSaveVisible = true;
            isCancelVisible = true;
        }
        else {
            if (isEditVisible) {
                isSaveVisible = true;
                isCancelVisible = true;
            }

            if (isEditMode) {
                isCancelDisabled = false;
                isSaveDisabled = false;
                
                if (!userInfo.Account.Active) {
                    isCancelDisabled = true;
                    isSaveDisabled = true;
                }
            }

            if (isInactive) {
                isCancelDisabled = true;
                isSaveDisabled = true;
                isEditDisabled = true;
                isDeleteDisabled = true;
            }
        }

        return (
            <div className='content-pane-wrapper'>
                <NavigationPrompt 
                    ref={ref => this.NavigationPromptRef = ref}
                    when={hasUnsavedChanges}
                    navigate={path => this.props.history.push(path)}
                    initiateSave={this.initiateSave}
                    onCancelSave={() => this.onCancelSave}
                    shouldBlockNavigation={location => {
                        if (hasUnsavedChanges) {
                            return true;
                        }
                        return false;
                    }}
                />
                <div>
                    <Breadcrumb breadcrumbs={breadcrumbs} />
                </div>
                <div className="module-header">
                    <h1>{this.tabHeader[0].text}</h1>
                </div>
                <div className="user-toolbar-wrapper">
                    <ToolbarComponent ref={ref => this.UserToolbarRef = ref} clicked={this.onUserToolbarClicked}>
                        <ItemsDirective>
                            <ItemDirective id="add" prefixIcon='tb-icons c-icon-add-black' text="Ny" tooltipText='Ny' visible={super.hasMenuItemTabFunction("Bruger kort", "Bruger kort", "Ny")}/>
                            <ItemDirective id="search" prefixIcon='tb-icons c-icon-search-list' text="Søgeliste" tooltipText='Søgeliste' visible={super.hasMenuItem("Brugerliste")} />
                            <ItemDirective id="edit" prefixIcon='tb-icons c-icon-edit' text="Redigere" tooltipText='Redigere' disabled={isEditDisabled} visible={isEditVisible} />
                            <ItemDirective id="delete" prefixIcon='tb-icons c-icon-delete' text="Slette" tooltipText='Slette' disabled={isDeleteDisabled} visible={super.hasMenuItemTabFunction("Bruger kort", "Bruger kort", "Slette")} />
                            <ItemDirective id="save" prefixIcon='tb-icons c-icon-update' text='Gem' tooltipText='Gem' disabled={isSaveDisabled} visible={isSaveVisible} />
                            <ItemDirective id="cancel" prefixIcon='tb-icons c-icon-cancel' text="Annuller" tooltipText='Annuller' disabled={isCancelDisabled} visible={isCancelVisible} />
                            <ItemDirective id="undo" prefixIcon='tb-icons c-icon-undo' text="Fortryd" tooltipText={userInfo.UndoRedoStatus.UndoAction || 'Fortryd'} disabled={!userInfo.UndoRedoStatus.Undo} visible={isSaveVisible} />
                            <ItemDirective id="redo" prefixIcon='tb-icons c-icon-redo' text="Gentag" tooltipText={userInfo.UndoRedoStatus.RedoAction || 'Gentag'} disabled={!userInfo.UndoRedoStatus.Redo} visible={isSaveVisible} />
                            <ItemDirective id="prev" prefixIcon='tb-icons c-icon-chevron-arrow-left' text={Localization.General.Previous} tooltipText={Localization.General.Previous} disabled={!hasPrev} />
                            <ItemDirective id="next" prefixIcon='tb-icons c-icon-chevron-arrow-right' text={Localization.General.Next} tooltipText={Localization.General.Next} disabled={!hasNext} />
                        </ItemsDirective>
                    </ToolbarComponent>
                </div>
                { userInfo.FullName && <h2>{userInfo.FullName}</h2> }
                <div className='control-section tab-control'>
                    <Tabs className="c-tab" selectedIndex={currentTabSelected} onSelect={index => this.onTabSelected(index)} disabledTabClassName="c-tabs__tab--disabled">
                        <TabList className="c-tab__list">
                            <Tab className={`c-tabs__tab ${super.hasMenuItemTabAccess("Bruger kort", "Bruger kort") ? '' : 'hidden'}`} disabled={!super.hasMenuItemTabAccess("Bruger kort", "Bruger kort")} selectedClassName="c-tabs__tab--selected">{this.tabHeader[0].text}</Tab>
                            <Tab className={`c-tabs__tab ${super.hasMenuItemTabAccess("Bruger kort", "Bruger indstillinger") ? '' : 'hidden'}`} disabled={!super.hasMenuItemTabAccess("Bruger kort", "Bruger indstillinger")} selectedClassName="c-tabs__tab--selected">{this.tabHeader[1].text}</Tab>
                            {
                                userInfo.OilmatUser &&
                                <Tab className={`c-tabs__tab ${super.hasMenuItemTabAccess("Bruger kort", "Bruger kort") ? '' : 'hidden'}`} disabled={!super.hasMenuItemTabAccess("Bruger kort", "Bruger kort")} selectedClassName="c-tabs__tab--selected">{this.tabHeader[2].text}</Tab>
                            }
                        </TabList>
                        <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                            {this.userCardTab()}
                        </TabPanel>
                        <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                            {this.userSettingsTab()}
                        </TabPanel>
                        {
                            userInfo.OilmatUser && 
                            <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                                {this.oilmatSettingsTab()}
                            </TabPanel>
                        }
                    </Tabs>
                </div>
                <DialogComponent id="user-card-dialog" isModal={true} buttons={this.buttons} width='auto' ref={dialog => this.dialogInstance = dialog} target='body' visible={false} 
                    showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} />
                <DialogComponent id="user-card-dialog" isModal={true} buttons={this.confirmDeleteButtons} width='auto' ref={dialog => this.confirmDeleteDialog = dialog} target='body'
                    header="Advarsel" visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} />
                <DialogComponent id="user-card-dialog" isModal={true} buttons={this.confirmAccessControlPanelButtons} width='auto' ref={dialog => this.confirmAccessControlPanelDialog = dialog} target='body' visible={false} 
                    header="Bemærk!" showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} />
            </div>
        )
    }
}

UserCard.contextType = AppContext;

export default UserCard; 