import React, { Component } from 'react'
import { ToolbarComponent, ItemsDirective, ItemDirective, TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { ListViewComponent } from '@syncfusion/ej2-react-lists';
import Axios from 'axios';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { withRouter } from 'react-router-dom';

import NavigationPrompt from '../../components/NavigationPrompt';
import Card from '../../components/Card';
import { buildVirtualMenu, getCheckedMenuNodes, buildMenuFromCheckedNodes, buildCheckedNodes } from '../../utilities/Helper';
import CustomerGroupService from '../../services/Customer/CustomerGroupService';
import MenuService from '../../services/MenuService';
import '../../styles/customer.scss';
import Localization from '../../utilities/Localization';
import MessageHelper from '../../utilities/MessageHelper';
import SessionService from '../../services/SessionService';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class CustomerGroup extends Component {
    constructor() {
        super();

        this.state = {
            loading: true,
            selectedGroupId: -1,
            menuItems: [],
            accountCustomerGroupsData: [],
            customerGroup: {},
            UndoRedoStatus: {
                Redo: false,
                RedoAction: "Gentag",
                Undo: false,
                UndoAction: "Fortryd"
            },
            hasUnsavedChanges: false,
            isEditMode: false
        }

        this.treeFields = { 
            dataSource: [], id: 'Id', text: 'Name', child: 'VirtualMenuItems'
        };

        this.onCustomerGroupSelected = this.onCustomerGroupSelected.bind(this);
        this.onCustomerGroupToolbarClicked = this.onCustomerGroupToolbarClicked.bind(this);
        this.undoRedoOptions = this.undoRedoOptions.bind(this);
        this.reloadCustomerGroup = this.reloadCustomerGroup.bind(this);
        this.addGroup = this.addGroup.bind(this);
        this.itemTemplate = this.itemTemplate.bind(this);
        this.onEditClick = this.onEditClick.bind(this);
        
        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.buttons = [
            {
                // Click the footer buttons to hide the Dialog
                click: () => {
                    this.NewGroupNameRef.value = '';
                    this.addGroupDialog.hide();
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    //Enables the primary button
                    content: 'Annuller'
                }
            },
            {
                // Click the footer buttons to hide the Dialog
                click: async () => {
                    let { customerGroup } = this.state;
                    const { onNotify} = this.props;

                    if (this.isGroupEditing) {
                        customerGroup.Name = this.NewGroupNameRef.value;
                        const result = await CustomerGroupService.UpdateCustomerGroup(customerGroup, source.token);

                        if (!result.HasError) {
                            let listDataSource = this.groupListRef.dataSource;
                            let item = listDataSource.find(x => x.Id === customerGroup.Id);
                            item.text = customerGroup.Name;
                            this.groupListRef.dataSource = listDataSource;
                            this.groupListRef.dataBind();
                            this.groupListRef.refresh();

                            let header = document.querySelector('h2.user-group-header');
                            if (header) {
                                header.innerHTML = customerGroup.Name;
                            };

                            this.copyCustomerGroup.value = null;
                            this.NewGroupNameRef.value = '';
                            this.addGroupDialog.hide();
                            onNotify(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Kunde Gruppe"));
                        }
                        else {
                            onNotify("Advarsel", result.ErrorMessage);
                        }
                    }
                    else {
                        this.addGroup(this.NewGroupNameRef.value)
                    }
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    //Enables the primary button
                    isPrimary: true,
                    content: 'Gem'
                }
            }
        ];
        /* End Dialog options  */
    }

    async componentDidMount() {
        const sessionInfo = await SessionService.GetSessionInfo();
        let accountCustomerGroups = await CustomerGroupService.GetCustomerGroups(source.token);
        this.customerGroups = accountCustomerGroups;
        if (sessionInfo.CustomerGroup.Level !== 1) {
            accountCustomerGroups = accountCustomerGroups.filter(f => f.Level > sessionInfo.CustomerGroup.Level);
        }
        
        accountCustomerGroups.sort((a,b) => (a.Level > b.Level) ? 1 : -1);
        this.customerGroupItems = accountCustomerGroups.map(x => {
            return {
                text: x.Name,
                Id: x.Id
            }
        });

        const menuItems = await MenuService.GetMenus(source.token);
        
        let menu = buildVirtualMenu(menuItems);

        this.treeFields.dataSource = menu;
        this.setState({ loading: false, menuItems, menu, accountCustomerGroupsData: accountCustomerGroups }, () => {
            this.onResize();
            window.addEventListener('resize', this.onResize);
            setTimeout(() => {
                if (this.menuTreeRef) {
                    this.menuTreeRef.collapseAll();
                }
            }, 250);
        });
    }

    // buildMenuStructure(menuId, items) {
    //     let result = [];
    //     if (items && items.length > 0) {
    //         menuId = menuId + 1;

    //         items.forEach(i => {
    //             let virtualMenuItems = [];
    //             if (i.Functions.length > 0) {
    //                 const { lastIndex: lastFunctionId, VirtualMenuItems: functionVirtualMenuItems } = this.buildFunctionStructure(menuId, i.Functions);
    //                 menuId = lastFunctionId;
    //                 virtualMenuItems = virtualMenuItems.concat(functionVirtualMenuItems);
    //             }

    //             if (i.MenuTabs.length > 0) {
    //                 menuId = menuId + 1
    //                 const { lastIndex: lastTabId, VirtualMenuItems: tabVirtualMenuItems } = this.buildTabStructure(menuId, i.MenuTabs);
    //                 menuId = lastTabId;
    //                 virtualMenuItems = virtualMenuItems.concat(tabVirtualMenuItems);
    //             }

    //             result.push({
    //                 Id: i.Id, Name: i.Name, expanded: true, VirtualMenuItems: virtualMenuItems
    //             })
    //         });
            
    //     }
    //     return { lastIndex: menuId, VirtualMenuItems: result };
    // }

    // buildTabStructure(lastIndex, items) {
    //     let result = [];
    //     if (items && items.length > 0) {
    //         lastIndex = lastIndex + 1;
    //         result.push({
    //             Id: lastIndex, Name: "Tabs", expanded: true, VirtualMenuItems: items.map(tab => {
    //                 const { lastIndex: lastFunctionId, VirtualMenuItems } = this.buildFunctionStructure(lastIndex, tab.Functions);
    //                 lastIndex = lastFunctionId;
    //                 return {
    //                     Id: tab.Id, Name: tab.Name, expanded: true, VirtualMenuItems
    //                 };
    //             })
    //         });
    //     }
    //     return { lastIndex, VirtualMenuItems: result};
    // }

    // buildFunctionStructure(lastIndex, items) {
    //     let result = [];
    //     if (items && items.length > 0) {
    //         lastIndex = lastIndex + 1;
    //         result.push({ 
    //             Id: lastIndex, Name: "Functions", expanded: true, VirtualMenuItems: items.map(i => { 
    //                 lastIndex = lastIndex + 1;
    //                 return { Id: lastIndex, Name: i, VirtualMenuItems: [] }
    //             }) 
    //         });
    //     }
    //     return { lastIndex, VirtualMenuItems: result};
    // }

    onResize() {
        setTimeout(() => {
            let customerGroupList = document.querySelector('#customer-groups-list .e-content > .e-ul');
            let customerGroupsWrapper = document.querySelector('.customer-groups-wrapper .customer-groups');
            
            if (customerGroupList) {
                customerGroupList.style.maxHeight = (customerGroupsWrapper.clientHeight - 132) + 'px';
                customerGroupList.style.height = (customerGroupsWrapper.clientHeight - 132) + 'px';
                customerGroupList.style.overflowY = 'auto';
            }
        }, 300);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    async onCustomerGroupSelected(args) {
        let { accountCustomerGroupsData, customerGroup, menu, childGroupMenu } = this.state;

        const customerGroupHeader = document.querySelector('.customer-group-header');
        if (customerGroupHeader) {
            customerGroupHeader.innerHTML = args.text;
        };

        let selectedGroup = await CustomerGroupService.GetCustomerGroup(args.data.Id, source.token);

        // let selectedMenus = getCheckedMenuNodes(menu, selectedGroup.Menu);

        customerGroup = Object.assign({}, selectedGroup);
        if (selectedGroup.Level === 1) {
            let checkedMenuNodes = buildCheckedNodes(menu, selectedGroup.Menu);
            this.menuTreeRef.fields.dataSource = [];
            this.menuTreeRef.refresh();
            this.treeFields.dataSource = checkedMenuNodes;
            this.menuTreeRef.fields.dataSource = checkedMenuNodes;
        }
        else {
            //let selectedGroup = await CustomerGroupService.GetCustomerGroup(args.data.Id, source.token);
            let tempParentGroup = this.customerGroups.find(a => a.Level === (selectedGroup.Level - 1));
            let parentGroup = await CustomerGroupService.GetCustomerGroup(tempParentGroup.Id, source.token);
            let virtualMenu = buildVirtualMenu(parentGroup.Menu);
            let checkedMenuNodes = buildCheckedNodes(virtualMenu, selectedGroup.Menu);
            this.menuTreeRef.fields.dataSource = [];
            this.menuTreeRef.refresh();
            this.treeFields.dataSource = checkedMenuNodes;
            this.menuTreeRef.fields.dataSource = checkedMenuNodes;
            childGroupMenu = virtualMenu;
        }
        this.menuTreeRef.disabled = true;

        this.setState({ selectedGroupId: args.data.Id, customerGroup, isEditMode: false, hasUnsavedChanges: false, childGroupMenu }, () => {
            this.CustomerGroupToolbarRef.enableItems(0, true);
            this.CustomerGroupToolbarRef.enableItems(1, false);
            this.CustomerGroupToolbarRef.enableItems(2, false);
            
            setTimeout(() => {
                this.menuTreeRef.collapseAll();
            }, 200);
            // this.menuTreeRef.uncheckAll();
            // this.menuTreeRef.checkedNodes = selectedMenus;
        });
    }

    async onCustomerGroupToolbarClicked(args) {
        let { onNotify } = this.props;
        if (args.item.id === 'edit') {
            this.CustomerGroupToolbarRef.enableItems(0, false);
            this.CustomerGroupToolbarRef.enableItems(1, true);
            this.CustomerGroupToolbarRef.enableItems(2, true);
            this.menuTreeRef.disabled = false;
            this.setState({ isEditMode: true });
        }
        else if (args.item.id === 'update') {
            source.cancel();
            source = CancelToken.source();
    
            let { customerGroup, accountCustomerGroupsData, menu, childGroupMenu } = this.state;
            customerGroup.Menu = [];
            
            let checkedNodes = this.menuTreeRef.checkedNodes;
            
            if (customerGroup.Level === 1) {
                customerGroup.Menu = buildMenuFromCheckedNodes(menu, checkedNodes);
            }
            else {
                customerGroup.Menu = buildMenuFromCheckedNodes(childGroupMenu, checkedNodes);
            }
            
            const result = await CustomerGroupService.UpdateCustomerGroup(customerGroup, source.token);
    
            if (!result.HasError) {
                this.CustomerGroupToolbarRef.enableItems(0, true);
                this.CustomerGroupToolbarRef.enableItems(1, false);
                this.CustomerGroupToolbarRef.enableItems(2, false);

                this.menuTreeRef.disabled = true;
                onNotify(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Kunde Gruppe"));
                this.undoRedoOptions(result.UndoRedoStatus.Undo, result.UndoRedoStatus.Redo, result.UndoRedoStatus.UndoAction, result.UndoRedoStatus.RedoAction);

                let accountCustomerGroups = await CustomerGroupService.GetCustomerGroups(source.token);

                // let group = accountCustomerGroupsData.find(a => a.Id === customerGroup.Id);
                // group.Menu = customerGroup.Menu;
                accountCustomerGroupsData = accountCustomerGroups;

                this.setState({ accountCustomerGroupsData, UndoRedoStatus: result.UndoRedoStatus, isEditMode: false, hasUnsavedChanges: false });
            }
            else {
                onNotify("Advarsel", result.ErrorMessage);
            }
        }
        else if (args.item.id === 'cancel') {
            this.CustomerGroupToolbarRef.enableItems(0, true);
            this.CustomerGroupToolbarRef.enableItems(1, false);
            this.CustomerGroupToolbarRef.enableItems(2, false);
            this.menuTreeRef.disabled = true;
            await this.reloadCustomerGroup().then(() => {
                this.setState({ isEditMode: false, hasUnsavedChanges: false });
            });
        }
        else if (args.item.id === 'delete') {
            // Show Confirmation
            let buttons = MessageHelper.MB2.buttons;
            buttons[0].click = () => {
                this.customerNotificationDialog.hide();
            }
            buttons[1].click = async () => {
                let { selectedGroupId } = this.state;
                const result = await CustomerGroupService.DeleteCustomerGroup(selectedGroupId, source.token);
                if (!result.HasError) {
                    this.setState({ selectedGroup: {} });

                    const accountCustomerGroups = await CustomerGroupService.GetCustomerGroups(source.token);
                    accountCustomerGroups.sort((a,b) => (a.Level > b.Level) ? 1 : -1);
                    this.customerGroupItems = accountCustomerGroups.map(x => {
                        return {
                            text: x.Name,
                            Id: x.Id
                        }
                    });
                    this.groupListRef.dataSource = this.customerGroupItems;
                    this.groupListRef.dataBind();

                    onNotify(Localization.General.SuccessHeader, Localization.General.SuccessDelete.replace("{context}", "Kundegruppe"));
                    this.customerNotificationDialog.hide();
                }
                else {
                    this.customerNotificationDialog.hide();
                    onNotify("Advarsel", result.ErrorMessage);
                }
            }
            
            this.customerNotificationDialog.header = "Advarsel";
            this.customerNotificationDialog.content = MessageHelper.MB2.message.replace("{context_message}", "kundegruppe");
            this.customerNotificationDialog.buttons = buttons;
            this.customerNotificationDialog.show();
        }
        else if (args.item.id === 'undo') {
            source.cancel();
            source = CancelToken.source();
            
            const result = await CustomerGroupService.UndoCustomerGroup(source.token);

            if (!result.HasError) {
                onNotify(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Kunde Gruppe"));
                // this.undoRedoOptions(result.Undo, result.Redo, result.UndoAction, result.RedoAction);
                this.reloadCustomerGroup().then(() => {
                    this.setState({ UndoRedoStatus: result });
                });
            }
            else {
                onNotify("Advarsel", result.ErrorMessage);
            }
        }
        else if (args.item.id === 'redo') {
            source.cancel();
            source = CancelToken.source();

            const result = await CustomerGroupService.RedoCustomerGroup(source.token);

            if (!result.HasError) {
                onNotify(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Kunde Gruppe"));
                // this.undoRedoOptions(result.Undo, result.Redo, result.UndoAction, result.RedoAction);
                this.reloadCustomerGroup().then(() => {
                    this.setState({ UndoRedoStatus: result });
                });
            }
            else {
                onNotify("Advarsel", result.ErrorMessage);
            }
        }
    }

    async addGroup(name) {
        const { accountCustomerGroupsData } = this.state;
        const { onNotify } = this.props;

        let copiedCustomerGroup = null;
        if (this.copyCustomerGroup.value) {
            copiedCustomerGroup = accountCustomerGroupsData.find(c => c.Id === this.copyCustomerGroup.value);
        }

        let nextLevel = Math.max.apply(Math, accountCustomerGroupsData.map(function(u) { return u.Level; }));
        const payload = {
            Name: name,
            Menu: copiedCustomerGroup ? copiedCustomerGroup.Menu : { Intralogix: [], Flexmat: [] },
            Level: nextLevel
        };


        const result = await CustomerGroupService.AddCustomerGroup(payload, source.token);
        if (!result.HasError) {
            accountCustomerGroupsData.push(result);
            this.groupListRef.dataSource.push({
                text: result.Name,
                Id: result.Id
            });
            this.groupListRef.refresh();
            this.addGroupDialog.hide();
            onNotify(Localization.General.SuccessHeader, Localization.General.SuccessAdd.replace("{context}", "Kunde Gruppe"));
            this.setState({ accountCustomerGroupsData }, () => {
                this.addGroupDialog.hide();
            });
        }
        else {
            onNotify("Advarsel", result.ErrorMessage);
        }
    }

    undoRedoOptions(hasUndo, hasRedo, undoTooltip = '', redoTooltip = '') {
        this.CustomerGroupToolbarRef.items[4].disabled = !hasUndo;
        this.CustomerGroupToolbarRef.items[4].tooltipText = undoTooltip;
        this.CustomerGroupToolbarRef.items[5].disabled = !hasRedo;
        this.CustomerGroupToolbarRef.items[5].tooltipText = redoTooltip;
    }

    async reloadCustomerGroup() {
        source.cancel();
        source = CancelToken.source();

        const { customerGroup } = this.state;
        const result = await CustomerGroupService.GetCustomerGroups(source.token);
        this.setState({ accountCustomerGroupsData: result }, () => {
            this.onCustomerGroupSelected({ text: customerGroup.Name, data: { Id: customerGroup.Id} });
        });
    }

    onEditClick() {
        this.isGroupEditing = true;
        this.addGroupDialog.show();
        this.addGroupDialog.header = "Omdøb Gruppe";

        let copyGroup = document.querySelector('.copy-group-wrapper');
        if (copyGroup) {
            copyGroup.style.display = 'none';
        }
        setTimeout(() => {
            const { customerGroup } = this.state;
            if (this.copyCustomerGroup) {
                this.copyCustomerGroup.enabled = false;
            }

            if (this.copyUserGroup) {
                this.copyUserGroup.value = null;
            }
            
            if (this.NewGroupNameRef) {
                this.NewGroupNameRef.value = customerGroup.Name;
            }

        }, 350);
    }

    itemTemplate(props) {
        // const { permission } = this.props;
        return (
            <div className="item-wrapper">{props.text}
                {/* {
                    permission.Functions.some(f => f === "Redigere") &&
                } */}
                <div className="c-icon-edit item-edit" onClick={this.onEditClick}></div>
            </div>
        )
    }

    customerGroupNodeCheck(args) {
        let node = [args.node];
        if (args.event.target.classList.contains('e-fullrow') || args.event.key == "Enter") {
            let getNodeDetails = this.menuTreeRef.getNode(args.node);
            if (getNodeDetails.expanded) {
                this.menuTreeRef.collapseAll(node);
                // this.menuTreeRef.uncheckAll(checkedNode);
            }
            else {
                this.menuTreeRef.expandAll(node);
                // this.menuTreeRef.checkAll(checkedNode);
            }
        }

        this.setState({ hasUnsavedChanges: true });
    }

    showAddEditGroupDialog = () => {
        this.NewGroupNameRef.value = '';
        this.copyCustomerGroup.value = null;
        this.copyCustomerGroup.enabled = true;
        this.copyCustomerGroup.dataSource = this.customerGroupItems;
        this.copyCustomerGroup.dataBind();
        this.addGroupDialog.show();
        this.addGroupDialog.header = "Du er ved at oprette en kundegruppe";
        
        let copyGroup = document.querySelector('.copy-group-wrapper');
        if (copyGroup) {
            copyGroup.style.display = 'block';
        }
    }

    render() {
        const { loading, selectedGroupId, UndoRedoStatus, isEditMode, hasUnsavedChanges } = this.state;
        // const { permission } = this.props;

        if (loading) {
            return <div></div>
        }

        let isEditEnabled = false, isDeleteEnabled, isSaveCancelEnabled = false;
        if (selectedGroupId !== -1) {
            isEditEnabled = true;
        }

        if (selectedGroupId !== 1 && selectedGroupId !== -1) {
            isDeleteEnabled = true;
        }

        if (isEditMode) {
            isEditEnabled = false;
            isSaveCancelEnabled = true
        }

        return (
            <div className="customer-groups-wrapper">
                <NavigationPrompt when={hasUnsavedChanges} 
                    navigate={path => this.props.history.push(path)}
                    initiateSave={() => this.onCustomerGroupToolbarClicked({ item: { id: 'update' } })}
                    shouldBlockNavigation={location => {
                        if (hasUnsavedChanges) {
                            return true;
                        }
                        return false;
                    }}
                />
                <div className="customer-groups">
                    <h3>Kunde Grupper</h3>
                    {/* {
                        permission.Functions.some(f => f === "Tilføj") &&
                    }  */}
                    <div className="text-center" style={{ margin: '15px 0' }}>
                        <button className="e-btn e-primary" type="button" onClick={this.showAddEditGroupDialog}><i className="c-icon c-icon-add-white"></i>{Localization.Customer.AddCustomerGroup}</button>
                    </div>
                    <ListViewComponent id="customer-groups-list" ref={ref => this.groupListRef = ref}  dataSource={this.customerGroupItems} select={this.onCustomerGroupSelected} template={this.itemTemplate}></ListViewComponent>
                </div>
                <div className="customer-groups-menu">
                    <h2 className="customer-group-header"></h2>
                    <div className="customer-group-toolbar-wrapper">
                        <ToolbarComponent ref={ref => this.CustomerGroupToolbarRef = ref} clicked={this.onCustomerGroupToolbarClicked}>
                            <ItemsDirective>
                                <ItemDirective id="edit" prefixIcon='tb-icons c-icon-edit' text="Redigere" tooltipText='Redigere' disabled={!isEditEnabled}  /> {/* visible={permission.Functions.some(f => f === "Redigere")} */}
                                <ItemDirective id="update" prefixIcon='tb-icons c-icon-update' text="Gem" tooltipText='Gem' disabled={!isSaveCancelEnabled}  /> {/* visible={permission.Functions.some(f => f === "Redigere")} */}
                                <ItemDirective id="cancel" prefixIcon='tb-icons c-icon-cancel' text="Annuller" tooltipText='Annuller' disabled={!isSaveCancelEnabled}  /> {/* visible={permission.Functions.some(f => f === "Redigere")} */}   
                                <ItemDirective id="delete" prefixIcon='tb-icons c-icon-delete' text="Slet" tooltipText='Slet' disabled={!isDeleteEnabled} /> {/*visible={permission.Functions.some(f => f === "Slette")} */}
                                <ItemDirective id="undo" prefixIcon='tb-icons c-icon-undo' text="Fortryd" tooltipText={UndoRedoStatus.UndoAction} disabled={!UndoRedoStatus.Undo} /> {/* visible={permission.Functions.some(f => f === "Undo")}*/}
                                <ItemDirective id="redo" prefixIcon='tb-icons c-icon-redo' text="Gentag" tooltipText={UndoRedoStatus.RedoAction} disabled={!UndoRedoStatus.Redo} /> {/* visible={permission.Functions.some(f => f === "Fortryd")}*/}
                            </ItemsDirective>
                        </ToolbarComponent>
                    </div>
                    <div className="customer-group-menu-wrapper">
                        <Card headerText="Vælg en profil til venstre, vælg derefter hvilke menuer der skal være tilgængelige" subText="Klik på + for tilhørende funktioner">
                            <TreeViewComponent id='customer-group-menu-tree' ref={ref => this.menuTreeRef = ref} fields={this.treeFields} showCheckBox={true} disabled={true} nodeClicked={this.customerGroupNodeCheck.bind(this)} />
                        </Card>
                    </div>
                </div>
                <DialogComponent isModal={true} buttons={this.buttons} width='450px' ref={dialog => this.addGroupDialog = dialog} header="Du er ved at oprette en kundegruppel" target='body' 
                    visible={false} className="add-customer-group-dialog" showCloseIcon={true} animationSettings={this.animationSettings} allowDragging={true} enableResize={true}>
                        <div className="dialog-content">
                            <div style={{marginBottom: 15}}>
                                <TextBoxComponent ref={ref => this.NewGroupNameRef = ref} placeholder="Indtast navn" floatLabelType="Auto" />
                            </div>
                            <div className="copy-group-wrapper">
                                <DropDownListComponent ref={ref => this.copyCustomerGroup = ref} placeholder="Vil du oprette gruppen baseret på:" dataSource={this.customerGroupItems} fields={{ text: 'text', value: 'Id' }} floatLabelType="Auto" />
                            </div>
                        </div>
                </DialogComponent>
                <DialogComponent id="system-group-dialog" isModal={true} width='auto' ref={dialog => this.customerNotificationDialog = dialog} target='body'
                    visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} />
            </div>
        )
    }
}

export default withRouter(CustomerGroup);
