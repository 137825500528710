import HttpClient from '../utilities/HttpClient';
import AppSettings from '../settings/AppSettings';

const API = {
    postLog: `${AppSettings.API_URL}/logging/sendLog`
}

class LogService {
    log(user, data) {
        HttpClient.Post(API.postLog, data, null);
    }
}

const logService = new LogService();
export default logService;