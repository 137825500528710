import React, { Component } from 'react'
import PropTypes from "prop-types";
import isEqual from "react-fast-compare";

import emptyModule from '../assets/machines/modules/empty_module.png';

import PlasticBinSmallImg from '../assets/machines/plastic_bin_small.png';
import PlasticBinMediumImg from '../assets/machines/plastic_bin_medium.png';
import DividerHighImg from '../assets/machines/divider_high _inserted.png';
import DividerLowImg from '../assets/machines/divider_low _inserted.png';

import FullWeightShelfImg from '../assets/machines/weightshelf_full_inserted.png';
import HalfWeightShelfImg from '../assets/machines/weightshelf_half_inserted.png';

import '../styles/machine-mapper.scss';

export class MachineMapper extends Component {
    constructor() {
        super();

        this.initCanvas = this.initCanvas.bind(this);
        this.renderShelvesArea = this.renderShelvesArea.bind(this);
        this.renderElementsArea = this.renderElementsArea.bind(this);
        this.renderMachineElements = this.renderMachineElements.bind(this);
        this.watchedProps = [ "active", "isRemoving", "activeElement", "src" ];
    }

    shouldComponentUpdate(nextProps) {
		const propChanged = this.watchedProps.some(
			prop => this.props[prop] !== nextProps[prop]
		);
		return !isEqual(this.props.shelves, this.state.shelves) || propChanged;
    }

    componentWillMount() {
		this.updateCacheMap();
	}

	componentDidUpdate() {
		this.updateCacheMap();
		this.initCanvas();
	}

	updateCacheMap() {
		this.setState(
			{ shelves: JSON.parse(JSON.stringify(this.props.shelves)) },
			this.initCanvas
		);
	}

    initCanvas() {
        if (this.props.width) this.image.width = this.props.width;

        if (this.props.height) this.image.height = this.props.height;
        
        this.container.style.width =
			(this.props.width || this.image.clientWidth) + "px";
		this.container.style.height =
            (this.props.height || this.image.clientHeight) + "px";
            
        if (this.props.active) {
            this.canvas.width = this.props.width || this.image.clientWidth;
            this.canvas.height = this.image.clientHeight;
            this.canvasContext = this.canvas.getContext("2d");

            this.renderFilledShelvesAndElements();
        }
    }

    drawrect(coords, fillColor, lineWidth, strokeColor) {
		let [left, top, right, bot] = coords;
		this.canvasContext.fillStyle = fillColor;
		this.canvasContext.lineWidth = lineWidth;
		this.canvasContext.strokeStyle = strokeColor;
		this.canvasContext.strokeRect(left, top, right - left, bot - top);
		this.canvasContext.fillRect(left, top, right - left, bot - top);
		this.canvasContext.fillStyle = fillColor;
	}

    click(area, index, event) {
        if (this.props.onClick) {
			event.preventDefault();
			this.props.onClick(area, index, event);
		}
    }

    scaleCoords(coords) {
		const { imgWidth, width } = this.props;
		// calculate scale based on current 'width' and the original 'imgWidth'
		const scale = width && imgWidth && imgWidth > 0 ? width / imgWidth : 1;
		return coords.map(coord => parseInt(coord) * scale);
	}

    renderFilledShelvesAndElements() {
        let { activeElement } = this.props;
        let prevShelf = null;
        let emptyShelfCount = 0;
        let has2EmptyShelfBelow = false;

        for (let index = 0; index < this.state.shelves.length; index++) {
            const shelf = this.state.shelves[index];
            const nextShelf = this.state.shelves[index + 1];
            const nextNextShelf = this.state.shelves[index + 2];

            const isWeightShelf = shelf.ShelfType === 1 || shelf.ShelfType === 3;
           
            let fillColor = '';
            let [left, top, right, bot] = shelf.Coords.split(",");

            if (this.props.isRemoving) {
                if (!shelf.Coords) continue;
                
                if ((nextShelf && nextShelf.ShelfType === 1) && (nextNextShelf && nextNextShelf.ShelfType === 1)) {
                    has2EmptyShelfBelow = true;
                }

                fillColor = 'rgba(188, 40, 43)';

                if (shelf.DividerLow && !shelf.DividerHigh) {
                    if (nextShelf && nextShelf.DividerHigh) {
                        
                    }
                    else {
                        this.drawrect(["70", parseInt(top) - 35,"80", top], fillColor, 1, fillColor);
                    }
                }
                else if (shelf.DividerHigh) {
                    if (prevShelf && prevShelf.ShelfType === 2) {
                        // skip 
                    }
                    else if (nextShelf && !nextShelf.DividerHigh) {
                        this.drawrect(["70", parseInt(top) - 35,"80", top], fillColor, 1, fillColor);
                    }
                    else if (index === (this.state.shelves.length - 1)) {
                        this.drawrect(["70", parseInt(top) - 35,"80", top], fillColor, 1, fillColor);
                    }
                }

                if (emptyShelfCount === 2) {
                    debugger;
                    emptyShelfCount = 0;
                    prevShelf = shelf;
                    continue;
                }

                if (shelf.ShelfType === 1) {
                    if (prevShelf && prevShelf.ShelfType === 2) {

                    }
                    else if (nextShelf && nextShelf.ShelfType === 2) {

                    }
                    else {
                        emptyShelfCount++;
                    }
                }
                else {
                    emptyShelfCount = 0;
                }

                if (shelf.ShelfType === 2) {
                    if (shelf.IsEditable) {
                        this.drawrect([left, top, parseInt(right) / 2, bot], fillColor, 1, fillColor);
                    }
                }
                else if (shelf.ShelfType === 3) {
                    debugger;
                    if (shelf.IsEditable) {
                        if (has2EmptyShelfBelow) {
                            has2EmptyShelfBelow = false;
                        }
                        else if (nextShelf && nextShelf.DividerLow) {

                        }
                        else {
                            this.drawrect(this.scaleCoords(shelf.Coords.split(",")), fillColor, 1, fillColor);
                        }
                    }
                }
            }
            else {
                if (activeElement.Type === 'DividerLow') {
                    fillColor = '#ed7d31';
                    if (shelf.DividerLow === false && shelf.DividerHigh === false && isWeightShelf) {
                        if (prevShelf && (prevShelf.ShelfType !== 3 && prevShelf.ShelfType !== 6)) {
                            prevShelf = shelf;
                            continue;
                        }
                        else {
                            if(nextShelf) {
                                if (shelf.ShelfType === 2 && nextShelf.ShelfType === 3) {
                                    prevShelf = shelf;
                                    continue;
                                }
                            }
                            
                            if (shelf.SlotNo === 0) {
                                this.drawrect(["70", parseInt(top) - 35, "80", top], fillColor, 1, fillColor);
                            }
                            else {
                                this.drawrect(["70", parseInt(top) - 37, "80", top], fillColor, 1, fillColor);
                            }
                        }
                    }
                    else if (shelf.DividerLow === false && shelf.DividerHigh === false && shelf.ShelfType === 1) {
                        if (prevShelf && (prevShelf.ShelfType !== 3 && prevShelf.ShelfType !== 6)) {
                            prevShelf = shelf;
                            continue;
                        }
                        else {
                            if(nextShelf) {
                                if (shelf.ShelfType === 2 && nextShelf.ShelfType === 3) {
                                    prevShelf = shelf;
                                    continue;
                                }
                            }
                            fillColor = '#ed7d31';
                            if (shelf.SlotNo === 0) {
                                this.drawrect(["70", parseInt(top) - 35, "80", top], fillColor, 1, fillColor);
                            }
                            else {
                                this.drawrect(["70", parseInt(top) - 37, "80", top], fillColor, 1, fillColor);
                            }
                        }
                    }
                }
                else if (activeElement.Type === 'DividerHigh') {
                    if (prevShelf) {
                        if (prevShelf.ShelfType === 1 && prevShelf.DividerLow === true && !shelf.DividerHigh) {
                            fillColor = '#fcfc30';
                            let [prevLeft, prevTop, prevRight, prevBot ] = prevShelf.Coords.split(",");
                            this.drawrect(["70", prevTop, "80", top], fillColor, 1, fillColor);
                        }
                        else if (prevShelf.ShelfType === 1 && prevShelf.DividerHigh === true && !shelf.DividerHigh) {
                            fillColor = '#fcfc30';
                            let [prevLeft, prevTop, prevRight, prevBot ] = prevShelf.Coords.split(",");
                            this.drawrect(["70", prevTop, "80", top], fillColor, 1, fillColor);
                        }
                    }
                }
                else if (activeElement.Type === 'FullShelf') {
                    if (shelf.ShelfType !== 1)  {
                        prevShelf = shelf;
                        continue;
                    }
                    if (nextShelf) {
                        if (nextShelf.DividerHigh) {
                            prevShelf = shelf;
                            continue;
                        }
                    }
                    fillColor = '#92d050';
                    this.drawrect(this.scaleCoords(shelf.Coords.split(",")), fillColor, 1, fillColor);
                }
                else if (activeElement.Type === 'HalfShelf') {
                    if (shelf.ShelfType !== 1) {
                        prevShelf = shelf;
                        continue;
                    }
                    
                    if (nextShelf && !nextShelf.DividerHigh) {
                        prevShelf = shelf;
                        continue;
                    }
                    else if (nextShelf && nextShelf.ShelfType === 2) {
                        prevShelf = shelf;
                        continue;
                    }

                    if (prevShelf && prevShelf.ShelfType === 2) {
                        prevShelf = shelf;
                        continue;
                    }

                    fillColor = '#2f5597';
                    this.drawrect([left, top, (parseInt(right) / 2) + 5, bot], fillColor, 1, fillColor);
                }
            }

            prevShelf = shelf;
        }
	}

    renderShelvesArea() {
        if (!this.props.active) return;
        let emptyShelfCount = 0;
        

        return this.props.shelves.map((shelf, index) => {
            if (!shelf.IsEditable) {
                emptyShelfCount = 0;
                return;
            }
            const scaledCoords = this.scaleCoords(shelf.Coords.split(","));
            const extendedArea = { ...shelf, scaledCoords };
            let nextShelf = this.props.shelves[this.props.shelves.length + 1];

            if (this.props.isRemoving) {
                if (emptyShelfCount === 2) {
                    emptyShelfCount = 0;
                    return;
                }

                if (shelf.ShelfType === 1) {
                    emptyShelfCount++;
                }
                else {
                    emptyShelfCount = 0;
                }

                if (shelf.ShelfType === 1) {
                    return;
                }
                if (nextShelf && nextShelf.DividerLow) {
                    return;
                }
            }

			return (
				<area
					key={shelf._id || index}
					shape="rect"
					coords={scaledCoords.join(",")}
                    onClick={this.click.bind(this, extendedArea, index)}
                    className={`shelf_${index}`}
				/>
			);
		});
    }

    renderElementsArea() {
        if (!this.props.active) return;
        let prevShelf = null;
        return this.props.shelves.map((shelf, index) => {
            if (shelf.ShelfType !== 2 && shelf.ShelfType !== 3 && shelf.ShelfType !== 1) return;
            let nextShelf = this.props.shelves[index + 1];
            const [left, top, right, bottom] = this.scaleCoords(shelf.Coords.split(","));
            const extendedArea = { ...shelf, isDivider: true, scaleCoords: [left, top, right, bottom] };

            if (this.props.isRemoving) {
                if (shelf.DividerLow && !shelf.DividerHigh) {
                    if (nextShelf && nextShelf.DividerHigh) {
                        prevShelf = shelf;
                        return;
                    }
                }
                else if (!shelf.DividerLow && shelf.DividerHigh) {
                    if (prevShelf && prevShelf.ShelfType === 2) {
                        prevShelf = shelf;
                        return;
                    }
                    else if (nextShelf && nextShelf.DividerHigh) {
                        prevShelf = shelf;
                        return;
                    }
                }
                else {
                    prevShelf = shelf;
                    return;
                }
            }

            prevShelf = shelf;

			return (
				<area
					key={shelf._id || index}
					shape="rect"
					coords={`70,${top - 35},80,${top}`}
                    onClick={this.click.bind(this, extendedArea, index)}
				/>
			);
		});
    }

    renderMachineElements() {
        let emptyShelfCount = 0;
        let prevShelf = null;

        let shelfCount = this.props.shelves.length;
        let has2EmptyShelfOnTop = false;
        let has2EmptyShelfBelow = false;

        return this.props.shelves.map((shelf, index) => {
            if(!shelf.Coords) return;
            
            let imgSrc = null, dividerImgSrc = null;
            let dividerHeight = 40, shelfWidth = '63px', topOffset = '';
            let [ xleft, xtop, xright, xbottom ] = shelf.Coords.split(",");
            let nextShelf = this.props.shelves[index + 1];
            let nextNextShelf = this.props.shelves[index + 2];

            if (this.props.isRemoving) {
                if ((nextShelf && nextShelf.ShelfType === 1) && (nextNextShelf && nextNextShelf.ShelfType === 1)) {
                    has2EmptyShelfBelow = true;
                }

                if (shelf.ShelfType === 1) {
                    if (prevShelf && prevShelf.ShelfType === 2) {
                        // must not add empty shelf count if previous shelf is a half shelf
                        emptyShelfCount = 0;
                    }
                    else if (nextShelf && nextShelf.ShelfType === 2) {
                        // must not add empty shelf count if next shelf is a half shelf
                        emptyShelfCount = 0;
                    }
                    else {
                        emptyShelfCount++;
                    }
                }
                else {
                    if (emptyShelfCount === 2 && shelf.ShelfType === 3) {
                        has2EmptyShelfOnTop = true;
                    }
                    
                    emptyShelfCount = 0;
                }

                switch(shelf.ShelfType) {
                    case 2: // half shelf
                        prevShelf = shelf;

                        // return low divider only
                        dividerImgSrc = DividerLowImg;
                        
                        if (shelf.SlotNo === 0) {
                            dividerHeight = 35;
                            topOffset =  (parseInt(xtop) - 35) + 'px';
                        }
                        else {
                            dividerHeight = 39;
                            topOffset =  (parseInt(xtop) - 39) + 'px';
                        }

                        return (
                            <img src={dividerImgSrc} style={{
                                position: 'absolute', zIndex: 1,
                                left: 74, top: topOffset,
                                height: dividerHeight
                            }} />
                        )
                    case 3:
                        imgSrc = FullWeightShelfImg;
                        shelfWidth = (parseInt(xright) - parseInt(xleft)) + 2 + 'px';
                        break;
                }

                if (emptyShelfCount !== 2 && emptyShelfCount !== 0) {
                    imgSrc = null;
                }
                else if (shelf.ShelfType === 3) {
                    if (has2EmptyShelfBelow) {
                        has2EmptyShelfBelow = false;
                    }
                    else if (has2EmptyShelfOnTop) {
                        has2EmptyShelfOnTop = false;
                    }
                    else if ((nextShelf && !nextShelf.DividerLow)) {
                        // Prevent deleting shelf if next shelf has divider low
                        imgSrc = null;
                    }
                }
    
                if (shelf.DividerLow && !shelf.DividerHigh) {
                    if (this.props.isRemoving) {
                        dividerImgSrc = null;
    
                        if (shelf.DividerLow && (nextShelf && nextShelf.DividerHigh)) {
                            dividerImgSrc = DividerLowImg;
                        }
                    }
                    else {
                        dividerImgSrc = DividerLowImg;
                    }
                    
                    if (shelf.SlotNo === 0) {
                        dividerHeight = 35;
                        topOffset =  (parseInt(xtop) - 35) + 'px';
                    }
                    else {
                        dividerHeight = 39;
                        topOffset =  (parseInt(xtop) - 39) + 'px';
                    }
                }
                else if (shelf.DividerHigh) {
                    if (shelf.ShelfType === 3) {
                        if (prevShelf && prevShelf.ShelfType === 2) {
                            dividerImgSrc = DividerHighImg;
                            topOffset = (parseInt(xtop) - 90) + 'px';
                            dividerHeight = 90;
                        }
                    }
                    else {
                        dividerImgSrc = DividerHighImg;
                        if (shelf.SlotNo === 0) {
                            topOffset = (parseInt(xtop) - 90) + 'px';
                            dividerHeight = 90;
                        }
                        else {
                            topOffset = (parseInt(xtop) - 94) + 'px';
                            dividerHeight = 95;
                        }
                    }
                }
            }
            else {
                switch(shelf.ShelfType) {
                    case 2: //Half Shelf
                        imgSrc = HalfWeightShelfImg;
                        break;
                    case 3: // Full Shelf
                        imgSrc = FullWeightShelfImg;
                        shelfWidth = (parseInt(xright) - parseInt(xleft)) + 2 + 'px';
                        break;
                }
    
                if (shelf.DividerLow && !shelf.DividerHigh) {
                    dividerImgSrc = DividerLowImg;
                    
                    if (shelf.SlotNo === 0) {
                        dividerHeight = 35;
                        topOffset =  (parseInt(xtop) - 35) + 'px';
                    }
                    else {
                        dividerHeight = 39;
                        topOffset =  (parseInt(xtop) - 39) + 'px';
                    }
                }
                else if (shelf.DividerHigh) {
                    dividerImgSrc = DividerHighImg;
                    if (shelf.SlotNo === 0) {
                        topOffset = (parseInt(xtop) - 90) + 'px';
                        dividerHeight = 90;
                    }
                    else {
                        topOffset = (parseInt(xtop) - 94) + 'px';
                        dividerHeight = 95;
                    }
                }
            }

            prevShelf = shelf;

            return (
                <React.Fragment key={`${this.props.name}_shelf_${index}`}>
                    {
                        imgSrc && 
                        <img src={imgSrc} style={{
                            position: 'absolute', zIndex: 1,
                            left: xleft + 'px', 
                            top: xtop + 'px',
                            width: shelfWidth
                        }} />
                    }
                    {
                        dividerImgSrc && 
                        <img src={dividerImgSrc} style={{
                            position: 'absolute', zIndex: 1,
                            left: 74, top: topOffset,
                            height: dividerHeight
                        }} />
                    }
                </React.Fragment>
            )
        });
    }

    render() {
        return (
            <div className="image-mapper-wrapper" style={{width: this.props.width}} ref={node => (this.container = node)}>
                <img ref={ref => this.image = ref} src={this.props.src || emptyModule} useMap={`#${this.props.name}`}
                    className="main-image" onLoad={this.initCanvas} />
                {
                    this.props.active &&
                    <React.Fragment>
                        <canvas ref={ref => this.canvas = ref} className="image-canvas"></canvas>
                        {
                            this.props.shelves &&
                            <map name={this.props.name} style={{cursor: 'pointer'}}>
                                { this.renderShelvesArea() }
                                { this.renderElementsArea() }
                            </map>
                        }
                    </React.Fragment>
                }
                {
                    this.renderMachineElements()
                }
            </div>
        )
    }
}

MachineMapper.defaultProps = {
    name: "image-map-" + Math.random(),
    active: false,
    isRemoving: false,
    activeElement: null,
    width: 151
};

MachineMapper.propTypes = {
    name: PropTypes.string,
    src: PropTypes.string,
    width: PropTypes.number,
    active: PropTypes.bool,
    activeElement: PropTypes.object,
    shelves: PropTypes.arrayOf(
        PropTypes.shape({
            Coords: PropTypes.string,
            SlotNo: PropTypes.number,
            IsEditable: PropTypes.bool,
            ShelfType: PropTypes.number
        })
    )
}

export default MachineMapper;
