import React, { Component } from 'react';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';

import MachineMapper from '../../components/MachineMapper';
import SettingsService from '../../services/SettingsService';
import { arrayMove } from '../../utilities/Helper';

import { getMachineImage, predefinedModules } from './PredefinedModules';

import Axios from 'axios';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class AddRemoveMoveModule extends Component {
    constructor(props) {
        super(props);
        let tempStoredData = JSON.stringify(props.data);
        
        this.state = {
            loading: true,
            selectedModule: -1,
            masterModuleList: [],
            slaveModuleList: [],
            machineModules: JSON.parse(tempStoredData).Modules,
            selectedIndex: -1
        }

        this.onMoveMachine = this.onMoveMachine.bind(this);
        // this.onSelectModule = this.onSelectModule.bind(this);
        this.onRemoveModule = this.onRemoveModule.bind(this);
        this.toggleMachineDetails = this.toggleMachineDetails.bind(this);
        this.onResize = this.onResize.bind(this);
    }

    async componentDidMount() {
        source.cancel();
        source = CancelToken.source();

        let { masterModuleList, slaveModuleList } = this.state;
        
        const masterModules = await SettingsService.GetMasterModules(source.token);
        const slaveModules = await SettingsService.GetSlaveModules(source.token);

        masterModuleList = masterModuleList.concat(masterModules);
        slaveModuleList = slaveModuleList.concat(slaveModules);

        this.setState({ loading: false, masterModuleList, slaveModuleList }, () => {
            let removeModuleButton = document.querySelector('.btn-remove-module');
            let finishButton = document.querySelector('.btn-finish');
            if (removeModuleButton) {
                finishButton.style.width = removeModuleButton.clientWidth + 'px';
            }
            
            this.onResize();
            window.addEventListener('resize', this.onResize);
            this.resizeMachinesWrapper();
        });
    }

    onResize() {
        let modulesPanelWrapper = document.querySelector('.build-machine-wrapper');
        modulesPanelWrapper.style.height = `calc(100vh - ${330}px)`;

        let crudPanel = document.querySelector('.crud-content-pane');
        crudPanel.style.height = `calc(100vh - ${330}px)`;
        // if (modulesPanelWrapper) {
        //     crudPanel.style.maxHeight = modulesPanelWrapper.offsetHeight + 'px';
        //     crudPanel.style.height = modulesPanelWrapper.offsetHeight + 'px';
        //     crudPanel.style.overflowY = 'auto';
        // }
        
        let machineList = document.querySelector('.machine-list-wrapper');
        if (machineList) {
            machineList.style.maxHeight = `calc(100vh - ${330}px)`;
            machineList.style.height = `calc(100vh - ${330}px)`;
        }

        this.resizeMachinesWrapper();
    }

    componentWillUnmount() {
        source.cancel();
        window.removeEventListener('resize', this.onResize);
    }

    onSelectModule(index, args) {
        // this.props.onSelectModule(args.value);
        this.setState({ selectedModule: args.value, selectedIndex: index });
    }

    onMoveMachine(event) {
        let { selectedModule, machineModules, selectedIndex } = this.state;
        const { onNotify, onUpdate } = this.props;
        
        if (selectedModule === -1) {
            onNotify("Advarsel", "Vælg venligst det modul du ønsker at flytte.");
        }
        else {
            let moduleIndex = machineModules.findIndex(m => m.Id === selectedModule);
            if (event.currentTarget.id === 'move-left') {
                if (moduleIndex - 1 < 0) {
                    return;
                }

                arrayMove(machineModules, moduleIndex, moduleIndex - 1);

                machineModules.forEach((m, index) => {
                    m.Order = index + 1;
                });

                onUpdate(machineModules);
                this.setState({ machineModules, selectedIndex: selectedIndex - 1 });
            }
            else if (event.currentTarget.id === 'move-right') {
                if (moduleIndex + 1 >= machineModules.length) {
                    return;
                }

                arrayMove(machineModules, moduleIndex, moduleIndex + 1);
                
                machineModules.forEach((m, index) => {
                    m.Order = index + 1;
                });
                onUpdate(machineModules);
                this.setState({ machineModules, selectedIndex: selectedIndex + 1 });
            }
        }
    }

    onAddModule(data, e) {
        let { machineModules } = this.state;
        const { onUpdate } = this.props;
        const predefinedModule = predefinedModules.find(x => x.name === data.Name);

        let dataModule = {};
        Object.assign(dataModule, data.Module);

        if (predefinedModule) {
            dataModule.Shelves.forEach(shelf => {
                const preDefShelf = predefinedModule.Shelves.find(x => x.SlotNo === shelf.SlotNo);
                Object.assign(shelf, preDefShelf);
            });

            dataModule.Id = data.Id;
            dataModule.PredefinedId = data.Id;
            dataModule.Master = data.Master;
            dataModule.ShelfSize = dataModule.ShelfSize;
        }
        
        dataModule.Order = machineModules.length + 1;

        machineModules.push(dataModule);
        onUpdate(machineModules);
        this.setState({ machineModules }, () => {
            if (machineModules.length === 1) {
                this.onResize();
            }
            this.resizeMachinesWrapper();
        });
    }

    resizeMachinesWrapper() {
        let machineInnerWrapper = document.getElementsByClassName('build-machine-inner');
        let modules = document.querySelectorAll('.build-machine-inner .machine-configuration-wrapper');
        let totalModulesWidth = 0;
        modules.forEach(x => totalModulesWidth += x.clientWidth);
        
        if (totalModulesWidth > machineInnerWrapper[0].clientWidth) {
            machineInnerWrapper[0].style.justifyContent = 'unset';
        }
        else {
            machineInnerWrapper[0].style.justifyContent = 'center';
        }
    }

    onRemoveModule(event) {
        let { selectedModule, machineModules, selectedIndex } = this.state;
        const { onNotify, onUpdate } = this.props;

        if (machineModules.length === 1) {
            onUpdate([], {index: 0, moduleId: machineModules[0].Id});
            this.props.onSelectModule(-1);
            this.setState({ machineModules: [], selectedModule: -1, selectedIndex: -1 });
        }
        else if (selectedIndex !== -1) {
            // machineModules = machineModules.filter(x => x.Id !== selectedModule);
            machineModules.splice(selectedIndex, 1);
            onUpdate(machineModules, { index: selectedIndex, moduleId: selectedModule });            
            this.props.onSelectModule(-1);
            this.setState({ machineModules, selectedModule: -1, selectedIndex: -1 });
        }
        else {
            onNotify("Advarsel", "Vælg venligst det modul du ønsker at fjerne.");
        }
        
        this.resizeMachinesWrapper();
    }

    toggleMachineDetails(e) {
        if (e.target.classList.contains("more-details")) {
            e.target.classList.remove("more-details");
            e.target.classList.add("less-details");
            e.target.innerText = "Mindre detaljer";

            let machineDescription = e.target.parentElement.parentElement.querySelector('div.machine-description');
            if (machineDescription) {
                machineDescription.style.height = 'auto';
                e.target.parentElement.classList.add('active');
            }
        }
        else if (e.target.classList.contains("less-details")) {
            e.target.classList.remove("less-details");
            e.target.classList.add("more-details");
            e.target.innerText = "Flere detaljer";

            let machineDescription = e.target.parentElement.parentElement.querySelector('div.machine-description');
            if (machineDescription) {
                machineDescription.style.height = '150px';
                e.target.parentElement.classList.remove('active');
            }
        }
    }

    render() {
        let { machineModules, selectedModule, selectedIndex, masterModuleList, slaveModuleList, loading, hasUnsavedChanges } = this.state;

        let moduleList = [];
        if (machineModules.length <= 0) {
            moduleList = moduleList.concat(masterModuleList);
        }
        else if (machineModules.length > 0 && !machineModules.some(m => m.Master === true)) {
            moduleList = moduleList.concat(masterModuleList);
        }
        else {
            moduleList = moduleList.concat(slaveModuleList);
        }

        return (
            <div className="crud-content-pane">
                <div className="machine-list-wrapper">
                    <label className="label">Vælg Module</label>
                    {
                        loading && <div className="sub-text loading-list">Indlæser moduler...</div>
                    }
                    {
                        !loading && 
                        <ul>
                            {
                                moduleList.map(mod => {
                                    
                                    return (
                                        <li className="machine-item-wrapper" key={`module-item-${mod.Id}`}>
                                            <div className="machine-description">
                                                <div className="label machine-title">Modul: {mod.Name}</div>
                                                {mod.ControlModule > 0 && 
                                                    <div className="label">{mod.ControlModule} stk. Styringsmodul</div>
                                                }
                                                {mod.FullShelfCount > 0 && 
                                                <div>
                                                    <div className="label">{mod.FullShelfCount} stk. skabe</div>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>Antal rum:</td>
                                                                <td>{mod.FullShelfMinRooms}-{mod.FullShelfMaxRooms}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Min str.:</td>
                                                                <td>{mod.FullShelfMinSize}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Max str:</td>
                                                                <td>{mod.FullShelfMaxSize}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>}
                                                {mod.LowBinCount > 0 && 
                                                <div>
                                                    <div className="label">{mod.LowBinCount} stk. Lav vægthylde u. kasser</div>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>Antal rum:</td>
                                                                <td>{mod.LowBinMinRooms}-{mod.LowBinMaxRooms}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Min str.:</td>
                                                                <td>{mod.LowBinMinSize}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Max str.:</td>
                                                                <td>{mod.LowBinMaxSize}</td>
                                                            </tr>
                                                            {/* <tr>
                                                                <td>Kombinationer</td>
                                                                <td>4 KS. S / 2 KS S + 1 KS. M / 2 KS M</td>
                                                            </tr> */}
                                                        </tbody>
                                                    </table>
                                                </div>}
                                                {mod.HighBinCount > 0 && 
                                                <div>
                                                    <div className="label">{mod.HighBinCount} stk. Høj vægthylde m. kasser</div>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>Antal rum:</td>
                                                                <td>{mod.HighBinMinRooms}-{mod.HighBinMaxRooms}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Min str.:</td>
                                                                <td>{mod.HighBinMinSize}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Max str.:</td>
                                                                <td>{mod.HighBinMaxSize}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>}
                                            </div>
                                            <div className="machine-image"><img src={getMachineImage(mod.Name, true)} /></div>
                                            {/* <div className="j-toggle-details">
                                                <span className="more-details" onClick={this.toggleMachineDetails}>Flere detaljer</span>
                                            </div> */}
                                            <div className="action-button">
                                                <button className="e-btn e-control e-primary" onClick={this.onAddModule.bind(this, mod)}>Tilføj</button>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    }
                </div>
                <div className="build-machine-wrapper">
                    <div className="centered-container">
                        <div className="build-machine-inner">
                            {
                                machineModules.map((module, index) => {
                                    let machineImageSrc = getMachineImage(module.Name);
                                    let hasMultipleModules = machineModules.length > 1;
                                    let className = '';
                                    
                                    if (selectedIndex <= -1) {
                                        className = 'is-selected';
                                    }
                                    else {
                                        if (selectedIndex === index) {
                                            className = 'is-selected';
                                        }
                                    }
                                    return (
                                        <div className={`machine-configuration-wrapper ${className}`} key={`machine-module-${index}`}>
                                            {
                                                hasMultipleModules ?
                                                <div className="machine-selection-wrapper">
                                                    <RadioButtonComponent label={module.Name} checked={selectedIndex === index} value={module.Id} name="MachineType" change={this.onSelectModule.bind(this, index)} />
                                                </div>
                                                :
                                                <div className="machine-selection-wrapper label">{module.Name}</div>
                                            }
                                            <MachineMapper src={machineImageSrc} name={`${module.Name}_${index}`} shelves={module.Shelves} width={151} active={false} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div>
                            {
                                (machineModules.length > 1) &&
                                <div className="move-machine-wrapper">
                                    <div className="move-left">
                                        <button id="move-left" type="button" onClick={this.onMoveMachine}>
                                            <span><i className="c-icon c-icon-back-arrow"></i></span>
                                            <span>Flyt modul</span>
                                        </button>
                                    </div>
                                    <div className="move-right">
                                        <button id="move-right" type="button" onClick={this.onMoveMachine}>
                                            <span>Flyt modul</span>
                                            <span><i className="c-icon c-icon-back-arrow"></i></span>
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="action-remove">
                            <button className="e-btn e-control btn-remove-module" onClick={this.onRemoveModule}>Fjern Modul</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddRemoveMoveModule;
