import HttpClient from '../utilities/HttpClient';

const API = {
    getLoginRules: `/settings/loginrules`,
    getDimensions: `/settings/units/dimension`,
    getWeights: `/settings/units/weight`,
    getUnitSettings: `/settings`,
    updateUnits: `/settings`,
    getOutputOptions: `/settings/outputOptions`,
    getFileFormats: `/settings/fileFormats`,
    getMasterModules: '/settings/masterModules',
    getSlaveModules: '/settings/slaveModules',
    getMachineElements: '/settings/machineElements',
    getOrderStatus: '/settings/orderStatus',
    getApprovalStatus: '/settings/approveStatus',
    getIntegrations: '/Settings/integration',
    updateIntegration: '/Settings/integration',
    getMailboxFolders: '/Settings/messageFolderType',
    postGridSettings: '/listsetting',
    getGridSettings: '/listsetting/{key}',
    getSearchContexts: '/settings/searchContexts',
    getCountries: '/settings/countries',
    getReportAvailability: '/settings/reportLevels',
    getFolderPermissions: '/settings/folderPermissions'
}

class SettingsService {
    async GetLoginRules(cancelToken) {
        return await HttpClient.Get(API.getLoginRules, null, cancelToken);
    }

    async GetUnitSettings(cancelToken) {
        return await HttpClient.Get(API.getUnitSettings, null, cancelToken);
    }

    async GetDimensions(cancelToken) {
        return await HttpClient.Get(API.getDimensions, null, cancelToken);
    }

    async GetWeights(cancelToken) {
        return await HttpClient.Get(API.getWeights, null, cancelToken);
    }

    async UpdateUnits(data, cancelToken) {
        return await HttpClient.Put(API.updateUnits, data, cancelToken);
    }

    async GetOutputOptions(cancelToken) {
        return await HttpClient.Get(API.getOutputOptions, null, cancelToken);
    }

    async GetFileFormats(cancelToken) {
        return await HttpClient.Get(API.getFileFormats, null, cancelToken);
    }

    async GetMasterModules(cancelToken) {
        return await HttpClient.Get(API.getMasterModules, null, cancelToken);
    }

    async GetSlaveModules(cancelToken) {
        return await HttpClient.Get(API.getSlaveModules, null, cancelToken);
    }

    async GetMachineElements(cancelToken) {
        return await HttpClient.Get(API.getMachineElements, null, cancelToken);
    }

    async GetOrderStatus(cancelToken) {
        return await HttpClient.Get(API.getOrderStatus, null, cancelToken);
    }

    async GetApprovalStatus(cancelToken) {
        return await HttpClient.Get(API.getApprovalStatus, null, cancelToken);
    }

    async GetIntegrations(cancelToken) {
        return await HttpClient.Get(API.getIntegrations, null, cancelToken);
    }

    async UpdateIntegrations(data, cancelToken) {
        return await HttpClient.Put(API.updateIntegration, data, cancelToken);
    }

    async GetMailboxFolders(cancelToken) {
        let folders = await HttpClient.Get(API.getMailboxFolders, null, cancelToken);

        folders.forEach(folder => {
            folder.id = folder.Value;
            switch(folder.Value) {
                case 1:
                    folder.iconClass = 'c-icon-inbox';
                    folder.selected = true;
                    break;
                case 2:
                    folder.iconClass = 'c-icon-drafts';
                    break;
                case 3:
                    folder.iconClass = 'c-icon-archive';
                    break;
                case 4:
                    folder.iconClass = 'c-icon-delete';
                    break;
                case 5:
                    folder.iconClass = 'c-icon-sent';
                    break;
                default:
                    break;
            }
        })

        return folders;
    }

    async GetGridSettings(key, cancelToken) {
        const url = API.getGridSettings.replace("{key}", key);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async SaveGridSettings(data, cancelToken) {
        return await HttpClient.Post(API.postGridSettings, data, cancelToken);
    }

    async GetSearchContexts(cancelToken) {
        return await HttpClient.Get(API.getSearchContexts, null, cancelToken);
    }

    async GetCountries(cancelToken) {
        return await HttpClient.Get(API.getCountries, null, cancelToken);
    }

    async GetReportAvailabilities(cancelToken) {
        return await HttpClient.Get(API.getReportAvailability, null, cancelToken);
    }

    async GetFolderPermissions(cancelToken) {
        return await HttpClient.Get(API.getFolderPermissions, null, cancelToken);
    }
}

const settingsService = new SettingsService();
export default settingsService;