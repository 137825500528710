import Axios from 'axios';
import AppSettings from '../settings/AppSettings';

class HttpClientOilmat {
    async Get(url, param, cancelToken) {
        Axios.defaults.headers['Pragma'] = 'no-cache';
        Axios.defaults.headers['Cache-Control'] = 'no-cache';

        let config = {
            "Content-Type": "application/json",
            headers: {}
        };
        url = `${AppSettings.OILMAT_API_URL}${url}`;

        if (cancelToken) {
            config.cancelToken = cancelToken;
        }

        return await Axios.get(url, {
            params: {
                ...param
            },
            ...config
        })
        .then(response => response.data)
        .catch(error => {
            let errorMessage = '';
            let status = '';
            if (error.response) {
                status = error.response.status;
            }
            
            try {
                if (error.response.data) {
                    if (typeof error.response.data === 'string') {
                        errorMessage = error.response.data;
                    }
                    else {
                        error.response.data.forEach(prop => {
                            if (!errorMessage.includes(prop.Message)) {
                                errorMessage = errorMessage + prop.Message;
                            }
                        });
                    }
                }
                else {
                    errorMessage = error.response;
                }
            } catch (error) {
                errorMessage = '';
            }

            return {
                Success: false,
                HasError: true,
                ErrorMessage: errorMessage,
                Status: status
            }
        });
    }

    async Post(url, param, cancelToken, isFormData = false) {
        let config = {};
        url = `${AppSettings.OILMAT_API_URL}${url}`;
        
        if (isFormData) {
            config.headers = {
                'Content-Type' : 'multipart/form-data'
            }
        }
        else {
            config.headers = {
                'Content-Type': 'application/json'
            }
        }

        if (cancelToken) {
            config.cancelToken = cancelToken;
        }

        return Axios.post(url, param, config)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            let errorMessage = '';

            if (error.response.data) {
                try {
                    let errs = error.response.data.map(err => {
                        return err.Message || err.message;
                    });
                    errorMessage = errs.join('<br>');
                } catch (error2) {
                    errorMessage = error.response.data;
                }
            }
            else {
                errorMessage = error.response;
            }

            return {
                Success: false,
                HasError: true,
                ErrorMessage: errorMessage,
                ValidationResult: error.response.data ? error.response.data: ''
            }
        });
    }
}

const httpClientOilmat = new HttpClientOilmat();
export default httpClientOilmat;