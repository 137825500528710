import React, { Component } from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { TabComponent, TabItemDirective, TabItemsDirective, TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { DropDownList } from '@syncfusion/ej2-react-dropdowns';
import { DataManager } from '@syncfusion/ej2-data';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Page, Sort, Toolbar, Selection, Search, Reorder, Resize, Edit } from '@syncfusion/ej2-react-grids';
import Axios from 'axios';
import XLSX from 'xlsx';
import { withRouter } from 'react-router-dom';
import Dropzone from 'react-dropzone'

import Image from '../../components/Image';
import NavigationPrompt from '../../components/NavigationPrompt';
import Localization from '../../utilities/Localization';
import placeHolderImg from '../../assets/placeholders/img_placeholder_108x80.jpg';

import SessionService from '../../services/SessionService';
import Loading from '../../components/Loading';
import SettingsService from '../../services/SettingsService';
import ProductService from '../../services/Product/ProductService';
import StockService from '../../services/Stock/StockService';
import CustomerService from '../../services/Customer/CustomerService';
import MachineService from '../../services/Machine/MachineService';
import { buildGridSettings } from '../../utilities/Helper';

export class StockImportExport extends Component {
    source = Axios.CancelToken.source();
    gridSettingsKey = "StockImportExportList";
    hasDraggedColumn = false;
    gridSettings = '';

    constructor() {
        super();

        this.state = {
            hasUnsavedChanges: false,
            loading: true,
            stageData: [],
            validationReport: [],
            files: [],
            selectedProduct: {},
            statusData: []
        };

        this.data = [];

        this.pageSettings = {
            pageSize: 25,
            pageSizes: ['Alle', '5', '10', '20', '50', '100']
        };

        this.reviewDataPageSettings = {
            pageSize: 10
        };

        this.selectionSettings = {
            persistSelection: true
        };

        this.filterSettings = {
            type: 'Menu'
        };
        
        this.importTabHeader = [
            { text: "Vælg fil" }, 
            { text: "Se data" },
            { text: "Import" }
        ];

        this.ImportColumns = ["Lager Id", "Vare Nr.", "Afdeling", "Bruger Definere Automatnavn", "Box #", "Room Start #", "Room End #", "Min", "Max", "Indkøbs Antal", "Beholdning", "Standard lokation"];

        this.init = this.init.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.processImport = this.processImport.bind(this);
        this.validateData = this.validateData.bind(this);
        
        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.notificationButtons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.validationReportDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        /* End Dialog options  */
    }

    async componentDidMount() {
        this.source.cancel();
        this.source = Axios.CancelToken.source();

        this.init();

        const sessionInfo = await SessionService.GetSessionInfo();
        this.CustomerId = sessionInfo.Id;

        const products = await ProductService.GetProducts(sessionInfo.Id, { active: true }, this.source.token);
        this.products = products.filter(x => x.ProductNo);

        const stocks = await StockService.GetByCustomer(sessionInfo.Id, this.source.token);
        
        if (!stocks.HasError) {
            this.data = stocks.map(x => {
                x.NewStock = 0;
                x.DepartmentId = x.Machine.Department.Id;
                x.DepartmentName = x.Machine.Department.Name;
                x.MachineCustomName = x.Machine.CustomName;
                x.MachineName = x.Machine.Name;
    
                return x;
            });
        }

        const deptMachines = await MachineService.GetMachineAvailableBoxes(sessionInfo.Id, this.source.token);
        this.departmentMachines = deptMachines;

        this.machines = [];

        for (let index = 0; index < deptMachines.length; index++) {
            const deptMachine = deptMachines[index];

            for (let a = 0; a < deptMachine.Machines.length; a++) {
                const machine = deptMachine.Machines[a];
                this.machines.push({
                    ...machine,
                    DeptId: deptMachine.Id
                });
            }
        }

        let departments = await CustomerService.GetAccountDepartments(sessionInfo.Id, this.source.token);
        departments = departments.filter(x => x.Machines.length > 0)
        departments = departments.map(dept => {
            dept.Machines = dept.Machines.map(machine => {
                machine.Name = machine.CustomName;

                return {
                    ...machine,
                    tempId: machine.Id + 2000,
                    expanded: true
                }
            });

            return {
                ...dept,
                tempId: dept.Id + 1000,
                expanded: true
            }
        });

        this.departments = departments;

        this.departmentParams = {
            create: () => {
                this.departmentElem = document.createElement('input');
                return this.departmentElem;
            },
            destroy: () => { this.departmentObj.destroy(); },
            read: () => {
                this.selectedDepartment = this.departmentObj.value;
                return this.departmentElem.value;
            },
            write: (e) => {
                this.departmentObj = new DropDownList({
                    change: (e) => {
                        const dataSource = this.machines.filter(d => d.DeptId === this.departmentObj.value);
                        this.machineObj.dataSource = dataSource;
                        this.machineObj.dataBind();
                        this.machineObj.text = "";

                        this.boxObj.dataSource = [];
                        this.boxObj.dataBind();
                        this.boxObj.text = "";

                        this.roomStartObj.dataSource = [];
                        this.roomStartObj.dataBind();
                        this.roomStartObj.text = "";
                        
                        this.roomEndObj.dataSource = [];
                        this.roomEndObj.dataBind();
                        this.roomEndObj.text = "";
                    },
                    dataSource: new DataManager(this.departments),
                    fields: { value: 'Id', text: 'Name' },
                    floatLabelType: 'Never',
                    value: e.rowData.DepartmentId
                  });
                this.departmentObj.appendTo(this.departmentElem);
            }
        };

        this.machineParams = {
            create: () => {
                this.machineElem = document.createElement('input');
                return this.machineElem;
            },
            destroy: () => { this.machineObj.destroy(); },
            read: () => {
                this.selectedMachine = this.machineObj.value;
                return this.machineElem.value;
            },
            write: (e) => {
                this.machineObj = new DropDownList({
                    change: (e) => {
                        if (e.isInteracted) {
                            const dataSource = this.machines.find(x => x.Id === this.machineObj.value);
                            this.boxObj.dataSource = dataSource.Boxes;
                            this.boxObj.dataBind();
                            this.boxObj.text = "";
    
                            this.roomStartObj.dataSource = [];
                            this.roomStartObj.dataBind();
                            this.roomStartObj.text = "";
                            
                            this.roomEndObj.dataSource = [];
                            this.roomEndObj.dataBind();
                            this.roomEndObj.text = "";
                        }
                    },
                    dataSource: [],
                    fields: { value: 'Id', text: 'CustomName' },
                    floatLabelType: 'Never',
                    value: e.rowData.MachineId
                  });
                this.machineObj.appendTo(this.machineElem);
            }
        };

        this.boxParams = {
            create: () => {
                this.boxElem = document.createElement('input');
                return this.boxElem;
            },
            destroy: () => { this.boxObj.destroy(); },
            read: () => {
                this.selectedBox = this.boxObj.value;
                return this.boxElem.value;
            },
            write: (e) => {
                this.boxObj = new DropDownList({
                    change: (e) => {
                        if (e.isInteracted) {
                            const machine = this.machines.find(x => x.Id === this.machineObj.value);
                            const box = machine.Boxes.find(x => x.Id === this.boxObj.value);
                            let roomDataSource = box.AvailablesRooms.map(x => { return { Id: x, No: x } });
    
                            this.roomStartObj.dataSource = roomDataSource;
                            this.roomStartObj.dataBind();
                            this.roomStartObj.text = "";
    
                            this.roomEndObj.dataSource = roomDataSource;
                            this.roomEndObj.dataBind();
                            this.roomEndObj.text = "";
                        }
                    },
                    dataSource: [],
                    fields: { value: 'Id', text: 'No' },
                    floatLabelType: 'Never',
                    value: e.rowData.BoxId
                  });
                this.boxObj.appendTo(this.boxElem);
            }
        };

        this.roomStartParams = {
            create: () => {
                this.roomStartElem = document.createElement('input');
                return this.roomStartElem;
            },
            destroy: () => { this.roomStartObj.destroy(); },
            read: () => {
                this.selectedRoomStart = this.roomStartObj.value;
                return this.roomStartElem.value;
            },
            write: (e) => {
                this.roomStartObj = new DropDownList({
                    dataSource: [],
                    fields: { value: 'Id', text: 'No' },
                    floatLabelType: 'Never',
                    value: e.rowData.RoomStartNo
                  });
                this.roomStartObj.appendTo(this.roomStartElem);
            }
        };

        this.roomEndParams = {
            create: () => {
                this.roomEndElem = document.createElement('input');
                return this.roomEndElem;
            },
            destroy: () => { this.roomEndObj.destroy(); },
            read: () => {
                this.selectedRoomEnd = this.roomEndObj.value;
                return this.roomEndElem.value;
            },
            write: (e) => {
                this.roomEndObj = new DropDownList({
                    dataSource: [],
                    fields: { value: 'Id', text: 'No' },
                    floatLabelType: 'Never',
                    value: e.rowData.RoomStartNo
                  });
                this.roomEndObj.appendTo(this.roomEndElem);
            }
        };

        const gridSettings = await SettingsService.GetGridSettings(this.gridSettingsKey, this.source.token);
        if (!gridSettings.HasError) {
            this.gridSettings = gridSettings.Settings;
        }

        this.setState({ loading: false }, () => {
            window.addEventListener('wheel', this.onWheelScroll);
        });
    }

    init() {
        this.toolbarOptions = [
            { text: 'Import', tooltipText: 'Import', id: 'import', prefixIcon: 'e-upload' },
            { text: 'Excel Export', tooltipText: 'Excel Export', id: 'export', prefixIcon: 'e-export-excel' },
            { text: 'Download Template', tooltipText: 'Download Template', id: 'download_template', prefixIcon: 'e-download' },
            { text: Localization.General.GridResetFilter, tooltipText: Localization.General.GridResetFilter, id: 'reset_filter', prefixIcon: 'e-cancel' }
        ];

        this.reviewDataToolbarOptions = [
            'Edit', 'Update', 'Cancel', 'Delete'
        ];

        this.reviewDataEditSettings = {
            allowEditing: true,
            allowDeleting: true,
            showDeleteConfirmDialog: true
        };

        this.numericParams = { params: { showSpinButton: false, min: 0, step: 0, cssClass: 'text-right' }};
    }

    componentWillUnmount() {
        this.source.cancel();
        window.removeEventListener('wheel', this.onWheelScroll);
    }

    onColumnDragStart(args) {
        this.hasDraggedColumn = true;
    }

    onCreated() {
        if (this.gridSettings) {
            let settings = JSON.parse(this.gridSettings);
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(column => {
                        if (column.FromIndex !== column.ToIndex) {
                            var tempIndex = this.stockGridRef.getColumnIndexByField(column.Field);
                            if (tempIndex !== column.ToIndex){
                                this.stockGridRef.reorderColumnByIndex(column.FromIndex, column.ToIndex);
                            }
                        }
    
                        if (column.Width) {
                            this.stockGridRef.columns[column.ToIndex].width = column.Width;
                        }
                    });
                }

                if (settings.colName && settings.direction) {
                    this.stockGridRef.sortColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.stockGridRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.stockGridRef.refreshColumns();

                if (settings.pageSize) {
                    if (settings.pageSize === "Alle") {
                        this.stockGridRef.pageSettings.pageSize = this.stockGridRef.pageSettings.totalRecordsCount;
                        document.querySelector("#stock-grid .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.stockGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
            }
        }
    }

    async onActionComplete(args) {
        if (args.requestType === 'paging') {
            if (this.stockGridRef.pageSettings.pageSize === this.stockGridRef.pageSettings.totalRecordsCount) {
                document.querySelector("#stock-grid .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, this.stockGridRef.pageSettings.pageSize, this.stockGridRef.pageSettings.totalRecordsCount, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };
            const result = await SettingsService.SaveGridSettings(payload, this.source.token);
        }
        else if (args.requestType === 'filtering') {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, this.source.token);

            if (!result.HasError) {
                this.props.notify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, this.source.token);

            if (!result.HasError) {
                this.props.notify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.stockGridRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, this.hasDraggedColumn, columns);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, this.source.token);

            if (!result.HasError) {
                this.props.notify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }

            this.hasDraggedColumn = false;
        }
    }

    async onResizeStop(args) {
        args.requestType = "resizingGrid";
        let columns = this.stockGridRef.getColumns();
        let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, columns);
        this.gridSettings = tempGridSettings;
        const payload = {
            Key: this.gridSettingsKey,
            Settings: this.gridSettings
        };

        const result = await SettingsService.SaveGridSettings(payload, this.source.token);

        if (!result.HasError) {
            this.props.notify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
        }
    }

    async onToolbarClick(args) {
        if (args.item.id === "import") {
            this.stockImportDialog.show();
            this.stockImportWizardTab.select(0);
            this.stockImportWizardTab.enableTab(1, false);
            this.stockImportWizardTab.enableTab(2, false);
            this.reviewButton.disabled = true;
            this.setState({ files: [] });
            
            setTimeout(() => {
                let element = document.querySelector('.stock-file-list .file-name-text');
                if (element) {
                    element.innerHTML = "";
                }
            }, 300);
        }
        else if (args.item.id === "export") {
            let source = this.stockGridRef.getSelectedRecords();
            if (source.length <= 0) {
                this.props.notify("Advarsel", Localization.General.PleaseSelectItemsToExport);
            } else {
                let stockFormattedSource = [];
    
                stockFormattedSource.push([ "Lager Id", "Vare Nr.", "Afdeling", "Automat", "Bruger Definere Automatnavn", "Box #", "Rum #", "Room Start #", "Room End #", "Min", "Max", "Indkøbs Antal", "Beholdning", "Standard lokation"]);
    
                for (let index = 0; index < source.length; index++) {
                    const element = source[index];
                    let item = [];
    
                    item.push(element.Id);
                    item.push(element.Product.ProductNo);
                    item.push(element.Machine.Department.Name);
                    item.push(element.Machine.Name);
                    item.push(element.Machine.CustomName);
                    item.push(element.Box ? element.Box.No : null);
                    item.push(element.Room ? element.Room.RoomNoEnd : null);
                    item.push(element.Room ? element.Room.RoomStartNo : null);
                    item.push(element.Room ? element.Room.RoomNoEnd : null);
                    item.push(element.MinQty);
                    item.push(element.MaxQty);
                    item.push(element.PurchaseQty);
                    item.push(element.StockQty);
                    item.push(element.StandardLocation ? "Y": "N")
    
                    stockFormattedSource.push(item);
                }
    
                let product = XLSX.utils.aoa_to_sheet(stockFormattedSource);
                let workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, product, "Lager");
                XLSX.writeFile(workbook, "Lager Export.xlsx"); // FOR TRANSLATION
            }
        }
        else if (args.item.id === "download_template") {
            let data1 = [
                this.ImportColumns
            ];

            let sheet = XLSX.utils.aoa_to_sheet(data1);
            let workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, sheet, "Lager");
            XLSX.writeFile(workbook, "Lager Template.xlsx"); // FOR TRANSLATION
        } else if (args.item.id === 'reset_filter') {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                settings.filters = [];
                this.gridSettings = settings;
                const payload = {
                    Key: this.gridSettingsKey,
                    Settings: JSON.stringify(this.gridSettings)
                };
    
                const result = await SettingsService.SaveGridSettings(payload, this.source.token);
    
                if (!result.HasError) {
                    this.props.notify(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                }
            }

            this.stockGridRef.clearFiltering();
        }
    }

    onChangeFile(files) {
        if (this.importStatusRef) {
            this.importStatusRef.dataSource = [];
            this.importStatusRef.refresh();
            this.importStatusRef.showSpinner();
        }

        const file = files[0];
        let importStatusData = [];
        
        this.setState({ files }, () => {
            let element = document.querySelector('.stock-file-list .file-name-text');
            element.innerHTML = file.name;
        });
        
        if (file) {
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;
            reader.onload = e => {
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
                const stockSheet = wb.Sheets[wb.SheetNames[0]];

                const colHeaders = []
                const colCount = XLSX.utils.decode_range(stockSheet['!ref']).e.c + 1
                for (let i = 0; i < colCount; ++i) {
                    colHeaders[i] = stockSheet[`${XLSX.utils.encode_col(i)}1`].v.toString().trim();
                }

                var colDiffs = this.ImportColumns.filter(x => !colHeaders.includes(x));
                if (colDiffs && colDiffs.length > 0) {
                    importStatusData.push({
                        Row: "Column Headers",
                        Status: `Column/s (${colDiffs.join(", ")}) findes ikke`
                    });
                }

                const stockData = XLSX.utils.sheet_to_json(stockSheet, { header: 0, raw: false });
                let data = [];

                for (let index = 0; index < stockData.length; index++) {
                    const t = stockData[index];
                    let errorMessages = [];
                    let product = null, department = null, machine = null, box = null, standardLocation = false;
                    let rawRoomStart = parseInt(t["Room Start #"]) ? parseInt(t["Room Start #"]) : 0, 
                        rawRoomEnd = parseInt(t["Room End #"]) ? parseInt(t["Room End #"]) : 0;
                    let rawProductNo = t["Vare Nr."];
                    let rawStockId = t["Lager Id"] ? parseInt(t["Lager Id"]) : 0;
                    let existingStock = this.data.find(x => x.Id === rawStockId);

                    let isContinue = false;
                    if (rawProductNo) {
                        product = this.products.find(x => x.ProductNo.toString().trim().toLowerCase() === rawProductNo.toString().trim().toLowerCase());
                        if (!product) {
                            errorMessages.push(`Produkt nr. (${rawProductNo}) findes ikke`);
                            isContinue = true;
                        }
                    }
                    else {
                        errorMessages.push(`Produkt nr. skal udfyldes`);
                        isContinue = true;
                    }

                    if ("Afdeling" in t) {
                        department = this.departmentMachines.find(x => x.Name.toLowerCase() === t["Afdeling"].toString().trim().toLowerCase());

                        if (!department) {
                            errorMessages.push(`Afdeling (${t["Afdeling"].toString().trim()}) findes ikke`);
                            isContinue = true;
                        }
                    }

                    if ("Bruger Definere Automatnavn" in t) {
                        machine = department.Machines.find(m => m.CustomName.toLowerCase() === t["Bruger Definere Automatnavn"].toString().trim().toLowerCase());
                        if (!machine) {
                            errorMessages.push(`Automat (${t["Bruger Definere Automatnavn"].toString().trim()}) findes ikke`);
                            isContinue = true;
                        }
                    }

                    if (isContinue) {
                        importStatusData.push({
                            Row: (index + 1).toString(),
                            Status: errorMessages.join(", ")
                        });
                        continue;
                    }

                    if ("Box #" in t) {
                        if (machine) {
                            box = machine.Boxes.find(b => b.No.toLowerCase() === t["Box #"].toString().trim().toLowerCase());
                            if (!box) {
                                errorMessages.push(`Boks (${t["Box #"].toString().trim()}) findes ikke`);
                            }
                        }
                    }

                    if (rawRoomStart > 0 && box) {
                        if (box.OccupiedRooms.some(r => r === rawRoomStart) || !box.AvailablesRooms.some(r => r === rawRoomStart)) {
                            if (existingStock && existingStock.Room) {
                                rawRoomStart = existingStock.Room.RoomNoStart;
                            } else {
                                rawRoomStart = 0;
                                errorMessages.push(`Room Start # (${rawRoomStart.toString().trim()}) er optaget`);
                            }
                        }
                    }
                    else {
                        rawRoomStart = 0;
                        if (existingStock && existingStock.Room) {
                            rawRoomStart = existingStock.Room.RoomNoStart;
                        }
                    }

                    if (rawRoomEnd > 0 && box) {
                        if (box.OccupiedRooms.some(r => r === rawRoomEnd) || !box.AvailablesRooms.some(r => r === rawRoomEnd)) {
                            if (existingStock && existingStock.Room) {
                                rawRoomEnd = existingStock.Room.RoomNoEnd;
                            } else {
                                rawRoomEnd = 0;
                                errorMessages.push(`Room End # (${rawRoomEnd.toString().trim()}) er optaget`);
                            }
                        }
                    }
                    else {
                        rawRoomEnd = 0;
                        if (existingStock && existingStock.Room) {
                            rawRoomEnd = existingStock.Room.RoomNoEnd;
                        }
                    }

                    if ("Standard lokation" in t) {
                        standardLocation = t["Standard lokation"].toString().trim().toLowerCase() === "y";
                    }

                    data.push({
                        Id: index + 1,
                        SourceId: existingStock ? existingStock.Id : 0,
                        ProductId: product.Id,
                        ProductNo: product.ProductNo,
                        DepartmentId: department.Id,
                        DepartmentName: department.Name,
                        MachineId: machine ? machine.Id : null,
                        MachineCustomName: machine ? machine.CustomName : null,
                        MachineName: machine ? machine.Name : null,
                        BoxId: box ? box.Id : null,
                        BoxNoName: box ? box.No : null,
                        Box: box,
                        RoomStartNo: rawRoomStart,
                        RoomEndNo: rawRoomEnd,
                        MinQty: parseInt(t["Min"]) ? parseInt(t["Min"]) : 0,
                        MaxQty: parseInt(t["Max"]) ? parseInt(t["Max"]) : 0,
                        PurchaseQty: parseInt(t["Indkøbs Antal"]) ? parseInt(t["Indkøbs Antal"]) : null,
                        StockQty: parseInt(t["Beholdning"]) ? parseInt(t["Beholdning"]) : 0,
                        StandardLocation: standardLocation
                    });

                    if (errorMessages.length > 0) {
                        importStatusData.push({
                            Row: (index + 1).toString(),
                            Status: errorMessages.join(", ")
                        });
                    }
                }
                
                this.setState({ stageData: data });

                if (this.importStatusRef) {
                    this.importStatusRef.hideSpinner();
                    if (data && data.length === 0) {
                        this.reviewButton.disabled = true;
                        importStatusData.push({
                            Row: "Alle",
                            Status: Localization.ImportExport.NoRowsFound
                        });
                    } else if (importStatusData.length === 0) {
                        this.reviewButton.disabled = false;
                        importStatusData.push({
                            Row: "Alle",
                            Status: Localization.ImportExport.NoErrorsFound
                        });
                    } else {
                        this.reviewButton.disabled = true;
                    }
                    this.importStatusRef.dataSource = importStatusData;
                    this.importStatusRef.refresh();
                }
            };

            if (rABS) reader.readAsBinaryString(file);
            else reader.readAsArrayBuffer(file);
        }
    }

    onButtonClick(args) {
        let { stageData, files } = this.state;
        
        switch(args.target.id) {
            case "select-file":
                this.stockImportWizardTab.select(0);
                this.stockImportWizardTab.enableTab(1, false);
                this.stockImportWizardTab.enableTab(2, false);
                break;
            case "review-data":
                if (files.length <= 0) {
                    this.props.notify("Advarsel", Localization.ImportExport.PleaseSelectAFile);
                    return;
                }
                else if (stageData.length <= 0) {
                    this.props.notify("Advarsel", Localization.ImportExport.NoRowsFound);
                    return;
                }

                this.stockImportWizardTab.enableTab(1, true);
                this.stockImportWizardTab.select(1);
                setTimeout(() => {
                    this.stageGridRef.dataSource = stageData;
                    this.stageGridRef.refresh();
                }, 300);
                break;
            case "finalize-import":
                let isValid = this.validateData();

                if (!isValid) {
                    this.validationReportDialog.show();
                    return;
                }

                this.stockImportWizardTab.enableTab(2, true);
                this.stockImportWizardTab.select(2);

                this.processImport();
                break;
        }
    }

    validateData() {
        let { stageData, validationReport } = this.state;
        validationReport = [];
        let productNumbers = stageData.map(x => x.ProductNo);
        
        for (let index = 0; index < stageData.length; index++) {
            const element = stageData[index];
            let errorList = [];

            if (element.DepartmentId && !element.MachineId) {
                errorList.push('Automat kræves');
            }

            if (element.BoxId && (!element.RoomStartNo || !element.RoomEndNo)) {
                errorList.push("Rum Start # kræves.");
            }
            else if (!element.BoxId && element.RoomStartNo && element.RoomEndNo) {
                errorList.push("Rum End # kræves.");
            }

            if (element.RoomStartno && element.RoomEndNo.value) {
                if (element.RoomStartno < element.RoomEndNo.value) {
                    errorList.push("Invalid room selection. Room Start must be lower than Room End.");
                }
            }
            
            if ((!element.MaxQty || element.MaxQty <= 0) && (!element.PurchaseQty || element.PurchaseQty <= 0)) {
                errorList.push("For at finde en passende lokation kræver det at Max eller Indkøbs antal er udfyldt.");
            }

            if (errorList.length > 0) {
                validationReport.push({
                    Id: element.Id,
                    Messages: errorList
                })
            }
        }

        this.setState({ validationReport });
        return validationReport.length <= 0;
    }

    fileSelectTab() {
        return (
            <div className="tab-control--item">
                <Dropzone onDrop={this.onChangeFile} accept=".xls, .xlsx" maxFiles={1}>
                    {({getRootProps, getInputProps}) => (
                        <section className="container">
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} />
                                <p>Træk og slip filen her, eller klik for at vælge en fil</p>
                            </div>
                            <div className="stock-file-list">
                                <div className="file-name-label">Valgte fil:</div>
                                <div className="file-name-text"></div>
                            </div>
                        </section>
                    )}
                </Dropzone>
                <div className="import-status-container">
                    <div>Import Status:</div>
                </div>
                <GridComponent ref={ref => this.importStatusRef = ref } dataSource={this.statusData} allowPaging={true} allowSorting={true} pageSettings={{ pageSize: 5 }}
                    selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both" width="100%" allowTextWrap={true}>
                    <ColumnsDirective>
                        <ColumnDirective headerText="Row" field="Row" width='200' isPrimaryKey={true} />
                        <ColumnDirective headerText="Status" field="Status" allowEditing={false} />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Search]} />
                </GridComponent>
                <div className="wizard-action-buttons">
                    <button ref={ref => this.reviewButton = ref} id="review-data" className="e-btn e-primary" onClick={this.onButtonClick}>Se data</button>
                </div>
            </div>
        )
    }

    async processImport() {
        let { stageData } = this.state;
        let payload = []

        for (let index = 0; index < stageData.length; index++) {
            const element = stageData[index];
            element.Id = 0;

            payload.push({
                Id: element.SourceId ? element.SourceId : 0,
                IsDelete: false,
                ChangeOrder: index + 1,
                StockQty: element.StockQty,
                MinQty: element.MinQty,
                MaxQty: element.MaxQty,
                PurchaseQty: element.PurchaseQty,
                ProductId: element.ProductId,
                StandardLocation: false,
                Location: {
                    Id: null,
                    BoxId: element.BoxId,
                    RoomNoStart: element.RoomStartNo,
                    RoomNoEnd: element.RoomEndNo
                },
                MachineId: element.MachineId,
            })
        }
        
        const result = await StockService.ImportBulkSave(payload, this.source.token);

        if (!result.HasError) {
            let errorList = [];
            for (let index = 0; index < result.length; index++) {
                const response = result[index];

                if (!response.Success) {
                    let errItem = { Id: response.ChangeOrder, Messages: [] }
                    for (let index = 0; index < response.Errors.length; index++) {
                        const err = response.Errors[index];
                        if (!errItem.Messages.some(x => x === err)) {
                            errItem.Messages.push(err);
                        }
                    }
                    errorList.push(errItem);
                }
            }

            if (errorList.length > 0) {
                this.setState({ validationReport: errorList });
                this.onButtonClick({ target: { id: 'review-data' }});
                return;
            }
            else {
                this.props.notify(Localization.General.SuccessHeader, Localization.Tools.SuccessImport);
            }

            const stocks = await StockService.GetByCustomer(this.CustomerId, this.source.token);
            this.data = stocks.map(x => {
                x.NewStock = 0;
                x.DepartmentId = x.Machine.Department.Id;
                x.DepartmentName = x.Machine.Department.Name;
                x.MachineCustomName = x.Machine.CustomName;
                x.MachineName = x.Machine.Name;

                return x;
            });

            this.stockGridRef.dataSource = stocks;
            this.stockGridRef.refresh();

            this.importStatusRef.dataSource = [];
            this.importStatusRef.refresh();

            this.stockImportDialog.hide();
            this.stockImportWizardTab.select(0);
            this.stockImportWizardTab.enableTab(1, false);
            this.stockImportWizardTab.enableTab(2, false);

            this.setState({ stageData: [] });
        }
        else {
            this.props.notify("Advarsel", result.ErrorMessage);
            this.onButtonClick({ target: { id: 'review-data' }});
        }
    }

    reviewDataActionBegin(args) {
        if (args.requestType === "save") {
            let errorList = [];

            if (this.selectedDepartment && !this.selectedMachine) {
                errorList.push("Please select a machine.");
            }
            
            if (this.selectedBox && (!this.selectedRoomStart || !this.selectedRoomEnd)) {
                errorList.push("Please select Room Start # and Room End #.");
            }
            else if (!this.selectedBox && this.selectedRoomStart && this.selectedRoomEnd) {
                errorList.push("Please select Box #.");
            }

            if (this.selectedRoomStart && this.selectedRoomEnd) {
                if (this.selectedRoomStart < this.selectedRoomEnd) {
                    errorList.push("Invalid room selection. Room Start must be lower than Room End.");
                }
            }

            if ((!args.data.MaxQty || args.data.MaxQty <= 0) && (!args.data.PurchaseQty || args.data.PurchaseQty <= 0)) {
                errorList.push("For at finde en passende lokation kræver det at Max eller Indkøbs antal er udfyldt.");
            }

            if (errorList.length > 0) {
                this.props.notify("Advarsel", errorList.join('<br/>'));
                args.cancel = true;
                return;
            }
        }
    }

    reviewDataActionComplete(args) {
        if (args.requestType === 'save') {
            let { stageData } = this.state;
            let data = stageData.find(x => x.Id === args.data.Id);
            let machine = this.machines.find(x => x.Id === this.machineObj.value);
            
            data.MinQty = args.data.MinQty;
            data.MaxQty = args.data.MaxQty;
            data.PurchaseQty = args.data.PurchaseQty;
            data.StockQty = args.data.StockQty;
            data.DepartmentId = this.selectedDepartment;
            data.DepartmentName = this.departmentObj.text;
            data.MachineId = this.selectedMachine;
            data.MachineCustomName = this.machineObj.text;
            data.MachineName = machine.Name;
            data.BoxId = this.selectedBox;
            data.BoxNoName = this.boxObj.text;
            data.RoomStartNo = this.selectedRoomStart;
            data.RoomEndNo = this.selectedRoomEnd;
            data.StandardLocation = args.data.StandardLocation;
            
            this.setState({ stageData }, () => {
                this.stageData = stageData;
                this.stageGridRef.dataSource = stageData;
                this.stageGridRef.refresh();
            });
        }
        else if (args.requestType === "beginEdit") {
            let rowData = args.rowData;

            const machines = this.machines.filter(x => x.DeptId === rowData.DepartmentId);

            this.departmentObj.value = rowData.DepartmentId;

            this.machineObj.dataSource = machines;
            this.machineObj.dataBind();
            this.machineObj.value = rowData.MachineId;

            if (rowData.MachineId) {
                const dataSource = this.machines.find(x => x.Id === rowData.MachineId);
                this.boxObj.dataSource = dataSource.Boxes;
                this.boxObj.dataBind();
                if (rowData.BoxId) {
                    this.boxObj.value = rowData.BoxId;
    
                    const box = dataSource.Boxes.find(x => x.Id === rowData.BoxId);
                    let roomDataSource = box.AvailablesRooms.map(x => { return { Id: x, No: x } });
        
                    this.roomStartObj.dataSource = roomDataSource;
                    this.roomStartObj.dataBind();
                    this.roomStartObj.value = rowData.RoomStartNo;
    
                    this.roomEndObj.dataSource = roomDataSource;
                    this.roomEndObj.dataBind();
                    this.roomEndObj.value = rowData.RoomEndNo;
                }
            }
        }
        else if (args.requestType === "delete") {
            let { stageData } = this.state;
            stageData = stageData.filter(x => x.Id !== args.data[0].Id);
            this.setState({ stageData });
        }
    }

    reviewDataTab() {
        return (
            <div className="tab-control--item">
                <GridComponent ref={ref => this.stageGridRef = ref } dataSource={this.stageData} allowPaging={true} allowSorting={true} pageSettings={this.reviewDataPageSettings} toolbar={this.reviewDataToolbarOptions}
                    selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both" editSettings={this.reviewDataEditSettings}
                    allowResizing={true} actionBegin={this.reviewDataActionBegin.bind(this)} actionComplete={this.reviewDataActionComplete.bind(this)} width="100%">
                    <ColumnsDirective>
                        <ColumnDirective headerText="No." field="Id" width='100' isPrimaryKey={true} />
                        <ColumnDirective headerText="Produkt Nr." field="ProductNo" allowEditing={false} />
                        <ColumnDirective headerText="Afdeling" field="DepartmentName" editType="dropdownedit" edit={this.departmentParams} />
                        <ColumnDirective headerText="Bruger Definere Automatnavn" field="MachineCustomName" editType="dropdownedit" edit={this.machineParams} />
                        <ColumnDirective headerText="Automat" field="MachineName" allowEditing={false} />
                        <ColumnDirective headerText="Box #" field="BoxNoName" editType="dropdownedit" edit={this.boxParams} />
                        <ColumnDirective headerText="Room Start #" field="RoomStartNo" textAlign="Right" editType="dropdownedit" edit={this.roomStartParams} />
                        <ColumnDirective headerText="Room End #" field="RoomEndNo" textAlign="Right" editType="dropdownedit" edit={this.roomEndParams} />
                        <ColumnDirective headerText="Min" field="MinQty" textAlign="Right" editType='numericedit' edit={this.numericParams} format="n2" />
                        <ColumnDirective headerText="Max" field="MaxQty" textAlign="Right" editType="numericedit" edit={this.numericParams} format="n2" />
                        <ColumnDirective headerText="Indkøbs Antal" field="PurchaseQty" textAlign="Right" editType="numericedit" edit={this.numericParams} format="n2" />
                        <ColumnDirective headerText="Beholdning" field="StockQty" textAlign="Right" editType='numericedit' edit={this.numericParams} format="n2" />
                        <ColumnDirective headerText="Standard lokation" field="StandardLocation" textAlign="Center" editType='booleanedit' displayAsCheckBox={true} />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Selection, Toolbar, Search, Resize, Edit]} />
                </GridComponent>
                <div className="wizard-action-buttons">
                    <button id="select-file" className="e-btn" onClick={this.onButtonClick}>Vælg fil</button>
                    <button id="finalize-import" className="e-btn e-primary" onClick={this.onButtonClick}>Bekræft og importer</button>
                </div>
            </div>
        )
    }

    finalizeImportTab() {
        return (
            <div className="tab-control--item">
                <div className="importing-text">Importere data. Vent  venligst</div>
            </div>
        )
    }

    imageTemplate(props) {
        if (props.Product.PrimaryImageKey) {
            return <Image src={props.Product.PrimaryImageKey} alt="" className="product-image-data" />
        }

        return <img src={placeHolderImg} alt="primary product" className="product-image-data" />;
    }

    onSelect(args) {
        if (args.isSwiped) {
            args.cancel = true;
        }
    }

    onWheelScroll = () => {
        let grid = document.getElementById('stock-grid');
        let toolbar = document.querySelector('#stock-grid.e-grid .e-toolbar');
        let tableHeader = document.querySelector('#stock-grid .e-gridheader');

        if (toolbar && tableHeader) {
            let boundingRect = tableHeader.getBoundingClientRect();
            
            if (boundingRect.top < 90) {
                toolbar.classList.add('c-hover-grid-toolbar');
                toolbar.style.width = (grid.clientWidth - 5) + 'px';
            }
            else {
                toolbar.classList.remove('c-hover-grid-toolbar');
                toolbar.style.width = 'auto';
            }
        }
    }

    render() {
        const { loading, hasUnsavedChanges, validationReport, selectedStock } = this.state;
        
        if (loading) {
            return <Loading />
        }

        return (
            <div style={{ margin: '20px 0' }}>
                <NavigationPrompt when={hasUnsavedChanges} 
                    navigate={path => this.props.history.push(path)}
                    initiateSave={() => {}}
                    shouldBlockNavigation={location => {
                        if (hasUnsavedChanges) {
                            return true;
                        }
                        return false;
                    }}
                />
                <GridComponent id="stock-grid" ref={ref => this.stockGridRef = ref } dataSource={this.data} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings} toolbar={this.toolbarOptions}
                    selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both" editSettings={{}}
                    allowReordering={true} allowResizing={true} toolbarClick={this.onToolbarClick.bind(this)} resizeStop={this.onResizeStop.bind(this)}
                    actionComplete={this.onActionComplete.bind(this)} columnDragStart={this.onColumnDragStart.bind(this)} created={this.onCreated.bind(this)}>
                    <ColumnsDirective>
                        <ColumnDirective type='checkbox' width='30' textAlign="Center"></ColumnDirective>
                        <ColumnDirective field="Id" headerText="Lager Id" width='100' allowEditing={false} isPrimaryKey={true}/>
                        <ColumnDirective field="Billede" headerText="Billede" width='100' template={this.imageTemplate} allowFiltering={false} allowEditing={false}/>
                        <ColumnDirective headerText="Produkt Nr." field="Product.ProductNo" width='100' />
                        <ColumnDirective headerText="Beskrivelse" field="Product.Description1" width='100' />
                        <ColumnDirective headerText="Afdeling" field="DepartmentName" width='100' allowEditing={false} />
                        <ColumnDirective headerText="Automat" field="MachineName" width='80' allowEditing={false} />
                        <ColumnDirective headerText="Box #" field="Box.No" width='80' textAlign="Right" allowEditing={false} />
                        <ColumnDirective headerText="Rum #" field="Room.Name" width='80' textAlign="Right" allowEditing={false} />
                        <ColumnDirective headerText="Min" field="MinQty" width='80' textAlign="Right" editType='numericedit' edit={this.numericParams} format="n2" />
                        <ColumnDirective headerText="Max" field="MaxQty" width='80' textAlign="Right" editType='numericedit' edit={this.numericParams} format="n2" />
                        <ColumnDirective headerText="Indkøbs Antal" field="PurchaseQty" width='80' textAlign="Right" editType='numericedit' edit={this.numericParams} format="n2" />
                        <ColumnDirective headerText="Beh." field="StockQty" width='80' textAlign="Right" editType='numericedit' edit={this.numericParams} format="n2" allowEditing={false} />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Selection, Toolbar, Search, Reorder, Resize]} />
                </GridComponent>
                <div>
                    <DialogComponent isModal={true} width='auto' ref={dialog => this.stockImportDialog = dialog} target='body'visible={false} showCloseIcon={true} header="Import Lager" 
                        cssClass="import-dialog" animationSettings={this.animationSettings} allowDragging={true} enableResize={true}>
                            <div className="dialog-content">
                                <TabComponent ref={ref => this.stockImportWizardTab = ref} className="tab-control" heightAdjustMode="Content" selecting={this.onSelect.bind(this)}>
                                    <TabItemsDirective>
                                        <TabItemDirective header={this.importTabHeader[0]} content={this.fileSelectTab.bind(this)} />
                                        <TabItemDirective header={this.importTabHeader[1]} content={this.reviewDataTab.bind(this)} disabled={true} />
                                        <TabItemDirective header={this.importTabHeader[2]} content={this.finalizeImportTab.bind(this)} disabled={true} />
                                    </TabItemsDirective>
                                </TabComponent>
                            </div>
                    </DialogComponent>
                </div>
                <div>
                    <DialogComponent isModal={true} width='auto' ref={dialog => this.validationReportDialog = dialog} target='body'visible={false} showCloseIcon={true} header="Rapport over Ændringer" 
                        cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} buttons={this.notificationButtons}>
                            <div>
                                <table className="table" style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th>Row #</th>
                                            <th className="text-left">Validation Messages</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            validationReport.map(validation => {
                                                return (
                                                    <tr className="changelog-row">
                                                        <td className="text-center">{validation.Id}</td>
                                                        <td className="required">
                                                            {validation.Messages.join(', ')}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                    </DialogComponent>
                </div>
            </div>
        )
    }
}

export default withRouter(StockImportExport);
