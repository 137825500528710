import React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';

import BaseComponent from '../../components/BaseComponent';
import AccountService from '../../services/AccountService';
import Localization from '../../utilities/Localization';
import Axios from 'axios';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class ChangePassword extends BaseComponent {
    pageName = "Skift Adgangskode"

    constructor() {
        super();

        this.state = {
            data: {
                currentPassword: '',
                newPassword: '',
                confirmPassword: ''
            },
            response: {
                hasError: false,
                message: ''
            }
        }
        
        this.animationSettings = { effect: 'None' };
        this.buttons = [
            {
                // Click the footer buttons to hide the Dialog
                click: () => {
                    this.notificationDialog.hide();
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    //Enables the primary button
                    isPrimary: true,
                    content: 'Ok'
                }
            }
        ];
    }    

    onClick = async () => {
        this.submitBtn.disabled = true;
        let { data, response } = this.state;

        const payload = {
            CurrentPassword: data.currentPassword,
            NewPassword: data.newPassword,
            ConfirmPassword: data.confirmPassword
        };
        const result = await AccountService.ChangePassword(payload, source.token);

        if (!result.HasError) {
            data.currentPassword = '';
            data.currentPassword = '';
            data.currentPassword = '';
            this.setState({ data }, () => {
                this.notificationDialog.header = Localization.General.NoteHeader;
                this.notificationDialog.content = Localization.ChangePassword.SuccessfullyChange;
                this.notificationDialog.show();
            });
        }
        else {
            this.notificationDialog.header = Localization.General.ErrorHeader;
            this.notificationDialog.content = result.ErrorMessage;
            this.notificationDialog.show();
        }
    }

    onChange = (args) => {
        let { data } = this.state;
        let value = args.target.value;
        let name = args.target.name;
        data[name] = value;
        this.setState({ data });
    }

    render() {
        const { data } = this.state;

        return (
            <div className='content-pane-wrapper'>
                <div className="module-header">
                    <h1>{this.pageName}</h1>
                </div>
                <div className="account-change-wrapper">
                    <div className="field">
                        <div class="e-float-input e-control-wrapper e-valid-input">
                            <input type='password' name="currentPassword" class="e-control e-textbox e-lib" role="textbox" id="textbox_0" 
                                onChange={this.onChange} aria-labelledby="label_textbox_0" value={data.currentPassword} />
                            <span class="e-float-line"></span>
                            <label class="e-float-text e-label-top" id="label_textbox_0" for="textbox_0">Nuværende Adgangskode*</label>
                        </div>
                    </div>
                    <div className="field">
                        <div class="e-float-input e-control-wrapper e-valid-input">
                            <input type='password' name="newPassword" class="e-control e-textbox e-lib" role="textbox" id="textbox_0" 
                                onChange={this.onChange} aria-labelledby="label_textbox_0" value={data.newPassword} />
                            <span class="e-float-line"></span>
                            <label class="e-float-text e-label-top" id="label_textbox_0" for="textbox_0">Ny Adgangskode*</label>
                        </div>
                    </div>
                    <div className="field">
                        <div class="e-float-input e-control-wrapper e-valid-input">
                            <input type='password' name="confirmPassword" class="e-control e-textbox e-lib" role="textbox" id="textbox_0" 
                                onChange={this.onChange} aria-labelledby="label_textbox_0" value={data.confirmPassword} />
                            <span class="e-float-line"></span>
                            <label class="e-float-text e-label-top" id="label_textbox_0" for="textbox_0">Bekræft Adgangskode*</label>
                        </div>
                    </div>
                    <div className="action-button">
                        <button ref={ref => this.submitBtn = ref} className="e-btn e-primary" disabled={!data.currentPassword || !data.newPassword || !data.confirmPassword} onClick={this.onClick}>Bekræft</button>
                    </div>
                </div>
                <DialogComponent isModal={true} buttons={this.buttons} width='auto' ref={dialog => this.notificationDialog = dialog} target='body' visible={false} 
                        showCloseIcon={false} cssClass="dialog-notification" animationSettings={this.animationSettings} />
            </div>
        )
    }
}

export default ChangePassword
