import React, { useState } from 'react';
import '../styles/card.scss';

const Card = ({ id, headerText, subText, children, isExpandable, className, showHeader = true, onExpandClickCallback }) => {
    const [expanded, setExpanded] = useState(false);

    const onExpandClick = () => {
        setExpanded(!expanded);

        if (onExpandClickCallback) {
            onExpandClickCallback(!expanded);
        }
    }

    return (
        <div id={id} className={`card ${isExpandable ? 'control-expandable' : ''} ${expanded ? 'is-expanded': ''} ${className ? className : ''}`}>
            {
                showHeader &&
                <div className="card-header">
                    <div className="card-header--title">{headerText}</div>
                    { (subText && !isExpandable) && <div className="card-header--subtext" dangerouslySetInnerHTML={{ __html: subText}}></div>}
                    {
                        subText && isExpandable && expanded ? <div className="card-header--subtext">{subText}</div> : ''
                    }
                    { isExpandable && <div className="button-expand" onClick={onExpandClick}></div> }
                </div>
            }
            {
                isExpandable ?  
                    expanded ? 
                        <div className="card-content">
                            {children}
                        </div>
                    : null
                :
                <div className="card-content">
                    {children}
                </div>
            }
        </div>
    )
}

export default Card;