import React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Page, Sort, Toolbar, Selection, Search, Reorder, Resize } from '@syncfusion/ej2-react-grids';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { Redirect } from 'react-router-dom';

import BaseComponent from '../../components/BaseComponent';
import Axios from 'axios';
import AppContext from '../../context/AppContext';

import MachineService from '../../services/Machine/MachineService';

import SettingsService from '../../services/SettingsService';
import messageHelper from '../../utilities/MessageHelper';
import '../../styles/machine.scss';
import Loading from '../../components/Loading';
import Localization from '../../utilities/Localization';
import SessionService from '../../services/SessionService';
import { buildGridSettings } from '../../utilities/Helper';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class MachineList extends BaseComponent {
    moduleName = "Automater";
    pageName = "Automater";
    gridSettingsKey = "MachineList";
    hasDraggedColumn = false;
    gridSettings = '';

    constructor() {
        super();

        this.state = {
            loading: true,
            data: {
                selectedId: 0,
                deleteOption: 0,
            },
            redirect: {
                to: '',
                isRedirect: false
            }
        }

        this.pageSettings = {
            pageSize: 15,
            pageSizes: ['Alle', '5', '10', '20', '50', '100']
        };

        this.data = [];

        this.selectionSettings = {
            type: 'Single'
        }

        this.filterSettings = {
            type: 'Menu'
        };

        this.clickHandler = this.clickHandler.bind(this);
        this.onDeleteMachine = this.onDeleteMachine.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
        this.editMachineTemplate = this.editMachineTemplate.bind(this);
        this.assignProductTemplate = this.assignProductTemplate.bind(this);
        this.init = this.init.bind(this);

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.confirmButtons = [
            {
                // Click the footer buttons to hide the Dialog
                click: () => {
                    this.confirmDialog.hide();
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    //Enables the primary button
                    content: 'Tilbage'
                }
            },
            {
                // Click the footer buttons to hide the Dialog
                click: this.onConfirm,
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    //Enables the primary button
                    isPrimary: true,
                    content: 'Næste'
                }
            }
        ];

        this.buttons = [
            {
                // Click the footer buttons to hide the Dialog
                click: () => {
                    this.dialogInstance.hide();
                },
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    //Enables the primary button
                    content: 'Annuller'
                }
            },
            {
                // Click the footer buttons to hide the Dialog
                click: this.onDeleteMachine,
                // Accessing button component properties by buttonModel property
                buttonModel: {
                    //Enables the primary button
                    isPrimary: true,
                    content: 'Slet'
                }
            }
        ];
        /* End Dialog options  */

    }

    async componentDidMount() {
        super.componentDidMount();
        source.cancel();
        source = CancelToken.source();

        if (!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }

        this.init();

        const sessionInfo = await SessionService.GetSessionInfo();

        const machines = await MachineService.GetMachineByCustomer(sessionInfo.Id, source.token);
        this.data = machines;

        const gridSettings = await SettingsService.GetGridSettings(this.gridSettingsKey, source.token);
        if (!gridSettings.HasError) {
            this.gridSettings = gridSettings.Settings;
        }

        this.setState({ loading: false }, () => {
            window.addEventListener('wheel', this.onWheelScroll);
        });
    }

    componentWillUnmount() {
        source.cancel();
        window.removeEventListener('wheel', this.onWheelScroll);
    }

    init() {
        this.toolbarOptions = [];
        this.defaultEditSettings = {};

        if (super.hasTabAccess('Ny')) {
            this.toolbarOptions.push({ text: 'Ny', tooltipText: 'Ny', id: 'add', prefixIcon: 'e-add' });
        }
        if (super.hasTabAccess('Redigere')) {
            this.toolbarOptions.push({ text: 'Redigere', tooltipText: 'Redigere', id: 'edit', prefixIcon: 'e-edit' });
        }
        if (super.hasTabAccess('Slette')) {
            this.toolbarOptions.push({ text: 'Slette', tooltipText: 'Slette', id: 'delete', prefixIcon: 'e-delete' });
            this.defaultEditSettings = {
                allowDeleting: true,
                showDeleteConfirmDialog: true,
            };
        }

        this.toolbarOptions.push({ text: Localization.General.GridResetFilter, tooltipText: Localization.General.GridResetFilter, id: 'reset_filter', prefixIcon: 'e-cancel' });
    }

    operationStatusTemplate(props) {
        let text = 'Ikke Klar';
        if (props.Active) { text = 'Parat'; }
        return (<div className={`machine-status${props.Active ? ' active' : ''}`}>{text}</div>)
    }

    editMachineTemplate(props) {
        return (<i className="c-icon c-icon-external-link clickable" onClick={() => this.props.history.push(`/automater/configurer/edit/${props.Id}`)}></i>)
    }

    assignProductTemplate(props) {
        return (<i className="c-icon c-icon-link clickable" onClick={() => this.props.history.push(`/automater/configurer/edit/${props.Id}?tab=3`)}></i>)
    }

    async clickHandler(args) {
        if (this.machineGridRef && args.item.id === 'add') {
            this.props.history.push('/automater/configurer/add');
        }
        else if (this.machineGridRef && args.item.id === 'edit') {
            const selectedRecord = this.machineGridRef.getSelectedRecords();
            if (selectedRecord.length <= 0) {
                this.dialogInstance.header = "Advarsel";
                this.dialogInstance.content = Localization.Product.PleaseSelectMachineToModify;
                this.dialogInstance.buttons = [{
                    click: () => {
                        this.dialogInstance.hide();
                        this.dialogInstance.buttons = this.buttons;
                    },
                    buttonModel: {
                        isPrimary: true,
                        content: 'OK'
                    }
                }];
                this.dialogInstance.show();
            }
            else {
                this.props.history.push(`/automater/configurer/edit/${selectedRecord[0].Id}`);
            }
        }
        else if (this.machineGridRef && args.item.id === 'delete') {
            const selectedRecord = this.machineGridRef.getSelectedRecords();
            if (selectedRecord.length <= 0) {
                this.dialogInstance.header = "Advarsel";
                this.dialogInstance.content = Localization.Machine.SelectMachineToDelete;
                this.dialogInstance.buttons = [{
                    click: () => {
                        this.dialogInstance.hide();
                        this.dialogInstance.buttons = this.buttons;
                    },
                    buttonModel: {
                        isPrimary: true,
                        content: 'OK'
                    }
                }];
                this.dialogInstance.show();
            }
            else {
                if (selectedRecord[0].HasProduct) {
                    this.confirmDialog.show();
                }
                else {
                    this.dialogInstance.header = "Advarsel";
                    this.dialogInstance.content = messageHelper.MB7.message.replace("{context}", "Automat")
                    this.dialogInstance.show();
                }
                
                // let { data } = this.state;
                // data.selectedId = selectedRecord[0].Id;
                // this.setState({ data });
            }
        }
        else if (args.item.id === 'reset_filter') {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                settings.filters = [];
                this.gridSettings = settings;
                const payload = {
                    Key: this.gridSettingsKey,
                    Settings: JSON.stringify(this.gridSettings)
                };
    
                const result = await SettingsService.SaveGridSettings(payload, source.token);
    
                if (!result.HasError) {
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                }
            }

            this.machineGridRef.clearFiltering();
        }
    }

    onConfirm = async () => {
        const selectedRecord = this.machineGridRef.getSelectedRecords();
        if (this.isMassEditRef.checked) {
            this.props.history.push(`/værktøjer/produkter/lager-regulering?machine=${selectedRecord[0].Name}`);
        }
        else if (this.isDeleteRef.checked) {
            this.dialogInstance.header = "Advarsel";
            this.dialogInstance.content = "Du er ved at slette Automater tryk Slet for at slette eller annuller for at fortryde.";
            this.dialogInstance.show();
        }
    }

    async refreshGrid() {
        const sessionInfo = await SessionService.GetSessionInfo();
        const machines = await MachineService.GetMachineByCustomer(sessionInfo.Id, source.token);
        this.machineGridRef.dataSource = machines;
        this.machineGridRef.dataBind();
    }

    async onDeleteMachine() {
        source.cancel();
        source = CancelToken.source();

        const selectedRecord = this.machineGridRef.getSelectedRecords();
        const result = await MachineService.DeleteMachine(selectedRecord[0].Id, { force: true }, source.token);
        if (!result.HasError) {
            this.confirmDialog.hide();
            await this.refreshGrid();
            this.dialogInstance.header = "Udført";
            this.dialogInstance.content = "Automaten er nu slettet.";
            this.dialogInstance.buttons = [{
                click: () => {
                    this.dialogInstance.hide();
                    this.dialogInstance.buttons = this.buttons;
                },
                buttonModel: {
                    isPrimary: true,
                    content: 'OK'
                }
            }];
            this.dialogInstance.show();
        }
        else {
            this.dialogInstance.header = "Advarsel";
            this.dialogInstance.content = result.ErrorMessage;
            this.dialogInstance.buttons = [{
                click: () => {
                    this.dialogInstance.hide();
                    this.dialogInstance.buttons = this.buttons;
                },
                buttonModel: {
                    isPrimary: true,
                    content: 'OK'
                }
            }];
            this.dialogInstance.show();
        }
    }

    dialogClose() {
        this.dialogInstance.hide();
    }

    onColumnDragStart(args) {
        this.hasDraggedColumn = true;
    }

    onCreated() {
        if (this.gridSettings) {
            let settings = JSON.parse(this.gridSettings);
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(column => {
                        if (column.FromIndex !== column.ToIndex) {
                            var tempIndex = this.machineGridRef.getColumnIndexByField(column.Field);
                            if (tempIndex !== column.ToIndex){
                                this.machineGridRef.reorderColumnByIndex(column.FromIndex, column.ToIndex);
                            }
                        }
    
                        if (column.Width) {
                            this.machineGridRef.columns[column.ToIndex].width = column.Width;
                        }
                    });
                }

                if (settings.colName && settings.direction) {
                    this.machineGridRef.sortColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.machineGridRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.machineGridRef.refreshColumns();

                // Prevent resizing of last column
                let lastColumnIndex = this.machineGridRef.columns.length - 1;    
                this.machineGridRef.columns[lastColumnIndex].allowResizing = false;
                this.machineGridRef.refreshColumns();

                if (settings.pageSize) {
                    if (settings.pageSize === "Alle") {
                        this.machineGridRef.pageSettings.pageSize = this.machineGridRef.pageSettings.totalRecordsCount;
                        document.querySelector("#machine-grid .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.machineGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
            }
        }
    }

    async onActionComplete(args) {
        if (args.requestType === 'paging') {
            if (this.machineGridRef.pageSettings.pageSize === this.machineGridRef.pageSettings.totalRecordsCount) {
                document.querySelector("#machine-grid .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, this.machineGridRef.pageSettings.pageSize, this.machineGridRef.pageSettings.totalRecordsCount, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };
            const result = await SettingsService.SaveGridSettings(payload, source.token);
        }
        else if (args.requestType === 'filtering') {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.gridSettings) {
                let settings = JSON.parse(this.gridSettings);
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.machineGridRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, this.hasDraggedColumn, columns);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                let lastColumnIndex = this.machineGridRef.columns.length - 1;
                for (let index = 0; index < this.machineGridRef.columns.length; index++) {
                    let column = this.machineGridRef.columns[index];
                    if (index === lastColumnIndex) { column.allowResizing = false }
                    else { column.allowResizing = true }
                }
                this.machineGridRef.refreshColumns();
            }

            this.hasDraggedColumn = false;
        }
    }

    dialogOpen(header, content) {
        this.dialogInstance.header = header;
        this.dialogInstance.content = content;
        this.dialogInstance.buttons = [{
            click: () => {
                this.dialogInstance.hide();
                this.dialogInstance.buttons = this.buttons;
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK'
            }
        }];
        this.dialogInstance.show();
    }

    async onResizeStop(args) {
        args.requestType = "resizingGrid";
        let columns = this.machineGridRef.getColumns();
        let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, columns);
        this.gridSettings = tempGridSettings;
        const payload = {
            Key: this.gridSettingsKey,
            Settings: this.gridSettings
        };

        const result = await SettingsService.SaveGridSettings(payload, source.token);

        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);    

            // Adjust size of last column to fill spaces
            let lastColumnIndex = this.machineGridRef.columns.length - 1;
            let totalColumnWidth = this.machineGridRef.columns.reduce((acc, curVal) => acc + parseInt(curVal.width), 0);
            let lastColumnWidth = this.machineGridRef.element.clientWidth - totalColumnWidth;
            this.machineGridRef.columns[lastColumnIndex].width = this.machineGridRef.columns[lastColumnIndex].width + lastColumnWidth;
            this.machineGridRef.refreshColumns();        
        }
    }

    onWheelScroll = () => {
        let grid = document.getElementById('machine-grid');
        let toolbar = document.querySelector('#machine-grid.e-grid .e-toolbar');
        let tableHeader = document.querySelector('#machine-grid .e-gridheader');

        if (toolbar && tableHeader) {
            let boundingRect = tableHeader.getBoundingClientRect();
            
            if (boundingRect.top < 90) {
                toolbar.classList.add('c-hover-grid-toolbar');
                toolbar.style.width = (grid.clientWidth - 5) + 'px';
            }
            else {
                toolbar.classList.remove('c-hover-grid-toolbar');
                toolbar.style.width = 'auto';
            }
        }
    }

    render() {
        const { loading, redirect } = this.state;

        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        return (
            <div className="content-pane-wrapper">
                <div className="module-header">
                    <h1>{this.pageName}</h1>
                </div>
                <GridComponent id="machine-grid" ref={g => this.machineGridRef = g} dataSource={this.data} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings} toolbar={this.toolbarOptions}
                    editSettings={this.defaultEditSettings} selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" 
                    gridLines="Both" toolbarClick={this.clickHandler} allowTextWrap='true' allowReordering={true} allowResizing={true} actionComplete={this.onActionComplete.bind(this)}
                    columnDragStart={this.onColumnDragStart.bind(this)} created={this.onCreated.bind(this)} resizeStop={this.onResizeStop.bind(this)}>
                    <ColumnsDirective>
                        <ColumnDirective headerText="Automat navn" field="Name" width='auto' textAlign="Left" /> 
                        <ColumnDirective headerText="Bruger definere automatnavn" field="CustomName" width='auto' textAlign="Left" />
                        <ColumnDirective headerText="Placering i afdeling" field="Department.Name" width='auto' textAlign="Left" /> 
                        <ColumnDirective headerText="Kontakt person" field="ContactPersons" width='auto' textAlign="Left"  /> 
                        <ColumnDirective headerText="Klar til drift" field="Active" width='auto' textAlign="Left" template={this.operationStatusTemplate} /> 
                        {/* <ColumnDirective headerText="Rediger Automat" field="Id" width='100' textAlign="Center" allowFiltering={false} template={this.editMachineTemplate} /> 
                        <ColumnDirective headerText="Tildel Produkter" field="Id" width='100' textAlign="Center" allowFiltering={false} template={this.assignProductTemplate} />  */}
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Selection, Toolbar, Search, Reorder, Resize]} />
                </GridComponent>
                <div>
                    <DialogComponent ref={d => this.confirmDialog = d} id="machine-confirm-dialog" header="Advarsel" width='auto' target='body' visible={false} showCloseIcon={true} isModal={true} 
                        buttons={this.confirmButtons} cssClass="dialog-notification" animationSettings={this.animationSettings}>
                            <div className="dialog-content">
                                <label className="label">Du er i gang med at slette en Automat der har tilknyttet produkter med lagerbeholdning.</label>
                                <div className="delete-option">
                                    <RadioButtonComponent id="isDelete" ref={rc => this.isDeleteRef = rc} name="DeleteOption" label="Vil du slette automaten alligevel" />
                                </div>
                                <div className="delete-option">
                                    <RadioButtonComponent id="isMassEdit" ref={rc => this.isMassEditRef = rc} name="DeleteOption" label="Reguler varer beholdningen til 0" />
                                </div>
                            </div>
                    </DialogComponent>
                </div>
                <div>
                    <DialogComponent ref={dialog => this.dialogInstance = dialog} id="machine-list-dialog" isModal={true} buttons={this.buttons} width='auto' 
                        target='body' visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} />
                </div>
            </div>
        )
    }
}

MachineList.contextType = AppContext;
export default MachineList
