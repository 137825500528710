import HttpClient from '../../utilities/HttpClient';

const API = {
    getCustomerGroups: `/customergroup`,
    getCustomerGroup: `/customergroup/{customergroup_id}`,
    postCustomerGroup:`/customergroup`,
    updateCustomerGroup: `/customergroup`,
    deleteCustomerGroup: `/customergroup/{customergroup_id}`,
    undoCustomerGroup: `/customergroup/undo`,
    redoCustomerGroup: `/customergroup/redo`,
    deleteCustomerGroup: '/customergroup/{id}'
}

class CustomerGroupService {
    async GetCustomerGroup(id, cancelToken) {
        const url = API.getCustomerGroup.replace('{customergroup_id}', id);
        return await HttpClient.Get(url, null, cancelToken);
    }

    async GetCustomerGroups(cancelToken) {
        return await HttpClient.Get(API.getCustomerGroups, null, cancelToken);
    }

    async AddCustomerGroup(data, cancelToken) {
        return await HttpClient.Post(API.postCustomerGroup, data, cancelToken);
    }

    async UpdateCustomerGroup(data, cancelToken) {
        return await HttpClient.Put(API.updateCustomerGroup, data, cancelToken);
    }

    // async DeleteCustomerGroup(id, cancelToken) {
    //     const url = API.deleteCustomerGroup.replace('{customergroup_id}', id);
    //     return await HttpClient.Delete(url, null, cancelToken);
    // }

    async UndoCustomerGroup(cancelToken) {
        return await HttpClient.Put(API.undoCustomerGroup, null, cancelToken);
    }

    async RedoCustomerGroup(cancelToken) {
        return await HttpClient.Put(API.redoCustomerGroup, null, cancelToken);
    }

    async DeleteCustomerGroup(id, cancelToken) {
        const url = API.deleteCustomerGroup.replace("{id}", id);
        return await HttpClient.Delete(url, null, cancelToken);
    }
}

const customerGroupService = new CustomerGroupService();
export default customerGroupService;