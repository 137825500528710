import React, { Component } from 'react';
import isEqual from "react-fast-compare";

export class Chips extends Component {
    constructor() {
        super();

        this.state = {
            items: []
        }

        this.onDelete = this.onDelete.bind(this);
        this.watchedProps = [ "items" ];
    }

    componentDidMount() {
        this.updateCacheMap();
    }

    shouldComponentUpdate(nextProps) {
		return !isEqual(nextProps.items, this.state.items);
    }

    componentDidUpdate() {
		this.updateCacheMap();
	}

    updateCacheMap() {
        this.setState({ items: JSON.parse(JSON.stringify(this.props.items)) });
    }

    onDelete(value) {
        this.props.onDelete({ text: value});
    }

    render() {
        const { items } = this.state;
        return (
            <div id={this.props.id} className="e-control e-chip-list e-lib e-chip-set" role="listbox">
                {
                    items && items.map(chip => {
                        return (
                            <div className="e-chip">
                                <span className="e-chip-text">{chip[this.props.field.text]}</span>
                                <span className="e-chip-delete e-dlt-btn" onClick={() => this.onDelete(chip[this.props.field.text])}></span>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

export default Chips
