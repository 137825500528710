import React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import Axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Redirect } from 'react-router-dom';
import NavigationPrompt from '../../components/NavigationPrompt';

import BaseComponent from '../../components/BaseComponent';
import Loading from '../../components/Loading';
// import Breadcrumb from '../../components/Breadcrumb';

import SessionService from '../../services/SessionService';
import OilmatIntegrationService from '../../services/OilmatIntegrationService';

import '../../styles/systemsettings.scss';
import SemlerIntegration from './OilmatIntegrationSettings/SemlerIntegration';
import HeliosIntegration from './OilmatIntegrationSettings/HeliosIntegration';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class OilmatIntegrationSettings extends BaseComponent {
    moduleName = "System indstillinger";
    pageName = "Oilmat Integration";

    constructor() {
        super();

        this.state = {
            loading: true,
            breadcrumbs: ['System Indstillinger', 'Oilmat Integration'],
            redirect: {
                to: '',
                isRedirect: false
            },
            hasUnsavedChanges: false,
            currentTabSelected: 0,
            sessionId: 0
        }

        this.exchangeVersions = [];

        this.dialogOpen = this.dialogOpen.bind(this);
        this.onTabSelected = this.onTabSelected.bind(this);

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.buttons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        /* End Dialog options  */
    }

    async componentDidMount() {
        super.componentDidMount();
        source.cancel();
        source = CancelToken.source();

        if (!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }
        else if (!super.hasMenuItem('OilMat Integration')) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }

        const sessionInfo = await SessionService.GetSessionInfo();

        this.setState({ loading: false, sessionId: parseInt(sessionInfo.Id) });
    }

    onTabSelected(index) {
        this.setState({ currentTabSelected: index });
    }

    dialogOpen(header, content) {
        this.notificationDialog.header = header;
        this.notificationDialog.content = content;
        this.notificationDialog.show();
    }

    render() {
        const { loading, redirect, hasUnsavedChanges, currentTabSelected, sessionId } = this.state;
        
        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        return (
            <div className="content-pane-wrapper">
                <NavigationPrompt when={hasUnsavedChanges} 
                    navigate={path => this.props.history.push(path)}
                    initiateSave={() => this.onSettingsToolbarClicked({ item: { id: 'update' } })}
                    shouldBlockNavigation={location => {
                        if (hasUnsavedChanges) {
                            return true;
                        }
                        return false;
                    }}
                />
                {/* <div>
                    <Breadcrumb breadcrumbs={breadcrumbs} />
                </div> */}
                <div className="module-header">
                    <h1>{this.pageName}</h1>
                </div>

                <Tabs id="account-tab" className="c-tab" selectedIndex={currentTabSelected} onSelect={index => this.onTabSelected(index)} disabledTabClassName="c-tabs__tab--disabled">
                    <TabList className="c-tab__list">
                        <Tab className={`c-tabs__tab`} selectedClassName="c-tabs__tab--selected">Semler Integration</Tab>
                        <Tab className={`c-tabs__tab`} selectedClassName="c-tabs__tab--selected">Helios Integration</Tab>
                    </TabList>
                    <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">
                        <SemlerIntegration dirtyProperty={(val) => this.setState({ hasUnsavedChanges: val })} dialogOpen={this.dialogOpen} sessionId={sessionId} />
                    </TabPanel>
                    <TabPanel className="c-tabs__panel" selectedClassName="c-tabs__panel--selected">                        
                        <HeliosIntegration dirtyProperty={(val) => this.setState({ hasUnsavedChanges: val })} dialogOpen={this.dialogOpen} sessionId={sessionId} />
                    </TabPanel>
                </Tabs>
                <DialogComponent isModal={true} buttons={this.buttons} width='auto' ref={dialog => this.notificationDialog = dialog} target='body' showCloseIcon={true}
                    cssClass="dialog-notification" animationSettings={this.animationSettings} visible={false} allowDragging={true} enableResize={true} />
            </div>
        )
    }
}

export default OilmatIntegrationSettings;
