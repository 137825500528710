import React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';
import { GridComponent, ColumnsDirective, ColumnDirective, Filter, Inject, Page, Sort, Toolbar, Selection, Search, Reorder, Resize, Edit } from '@syncfusion/ej2-react-grids';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { DropDownList } from '@syncfusion/ej2-react-dropdowns';
import { toast } from 'react-toastify';
import QueryString from 'query-string';

import BaseComponent from '../../components/BaseComponent';
import Loading from '../../components/Loading';
import NavigationPrompt from '../../components/NavigationPrompt';
import Localization from '../../utilities/Localization';
import Image from '../../components/Image';

import MessageHelper from '../../utilities/MessageHelper';
import SettingsService from '../../services/SettingsService';
import SessionService from '../../services/SessionService';
import StockService from '../../services/Stock/StockService';
import CustomerService from '../../services/Customer/CustomerService';
import { buildGridSettings } from '../../utilities/Helper';

import placeHolderImg from '../../assets/placeholders/img_placeholder_108x80.jpg';
import '../../styles/tool.scss';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class StockRegulationTools extends BaseComponent {
    moduleName = "Værktøjer";
    pageName = "Lager regulering";
    hasDraggedColumn = false;
    gridSettingsKey = "StockToolsList";
    gridSettings = '';

    constructor() {
        super();

        this.state = {
            loading: true,
            redirect: {
                to: '',
                isRedirect: false
            },
            hasUnsavedChanges: false,
            stockSelected: {},
            changeLog: [],
            changeLogReport: []
        };

        this.data = [];

        this.pageSettings = {
            pageSize: 25,
            pageSizes: ['Alle', '5', '10', '20', '50', '100']
        };

        this.selectionSettings = {
            persistSelection: true,
            checkboxOnly: true
        };

        this.editSettings = {
            allowEditing: false,
            mode: 'Batch',
            showConfirmDialog: false
        };

        this.filterSettings = {
            type: 'Menu'
        };

        this.numericParams = { params: { showSpinButton: false, min: 0, step: 0, cssClass: 'text-right' }};

        this.init = this.init.bind(this);
        this.onToolbarClick = this.onToolbarClick.bind(this);
        this.resetChangesTemplate = this.resetChangesTemplate.bind(this);
        this.saveChangesTemplate = this.saveChangesTemplate.bind(this);
        this.processStockChanges = this.processStockChanges.bind(this);
        this.onSaveChanges = this.onSaveChanges.bind(this);
        this.onResetChanges = this.onResetChanges.bind(this);

        /* Dialog options  */
        this.animationSettings = { effect: 'None' };
        this.notificationButtons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];

        this.changeLogButtons = [
            {
                click: () => {
                    this.changeLogDialog.hide();
                },
                buttonModel: {
                    content: 'Tilbage'
                }
            },
            {
                click: async () => {
                    await this.processStockChanges();
                },
                buttonModel: {
                    isPrimary: true,
                    content: 'Opdater'
                }
            }
        ]   
        /* End Dialog options  */
    }

    async componentDidMount() {
        super.componentDidMount();
        source.cancel();
        source = CancelToken.source();

        if (!this.hasPermission) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }
        else if (!this.hasMenuItemTabAccess("Produkter", "Lager redigering")) {
            this.setState({ loading: false, redirect: { to: '/error/no-permission', isRedirect: true }});
            return;
        }
        
        this.init();

        const sessionInfo = await SessionService.GetSessionInfo();
        this.CustomerId = sessionInfo.Id;

        const stocks = await StockService.GetByCustomer(sessionInfo.Id, source.token);
        this.data = stocks.map(x => {
            x.NewStock = null;
            x.DepartmentId = x.Machine.Department.Id;
            x.DepartmentName = x.Machine.Department.Name;
            x.MachineCustomName = x.Machine.CustomName;
            x.MachineName = x.Machine.Name;

            return x;
        });

        const gridSettings = await SettingsService.GetGridSettings(this.gridSettingsKey, source.token);
        if (!gridSettings.HasError) {
            this.gridSettings = gridSettings.Settings;
        }

        this.setState({ loading: false }, () => {
            
            setTimeout(() => {
                const { location } = this.props;
                if (location.search) {
                    let machine = '';
                    let boxNo = '';
                    let roomNo = '';
                    let queryString = location.search.substring(1).split('&');
                    for (let index = 0; index < queryString.length; index++) {
                        let val = QueryString.parse(queryString[index].toLowerCase());
                        if ('machine' in val) {
                            machine = val.machine;
                        }
                        if ('boxno' in val) {
                            boxNo = val.boxno;
                        }

                        if ('roomno' in val) {
                            roomNo = val.roomno;
                        }
                    }

                    if (machine || boxNo || roomNo){
                        this.stockGridRef.clearFiltering();
                    }

                    if (machine) {
                        this.stockGridRef.filterByColumn("MachineName", "equal", machine);
                    }

                    if (boxNo) {
                        this.stockGridRef.filterByColumn("Box.No", "equal", boxNo);
                    }

                    if (roomNo) {
                        this.stockGridRef.filterByColumn("Room.Name", "equal", roomNo);
                    }
                }
            }, 300);

            window.addEventListener('wheel', this.onWheelScroll);
        });
    }

    init() {
        this.toolbarOptions = [
            { text: 'Rediger tabel', tooltipText: 'Rediger tabel', id: 'edit', prefixIcon: 'e-edit' },
            // { text: 'Nulstil udvalg', tooltipText: 'Nulstil udvalg', id: 'reset_filter', prefixIcon: 'e-refresh' },
            //'Update', //'Cancel',
            { id: 'reset', template: this.resetChangesTemplate, align: "Right" },
            { id: 'save_changes', template: this.saveChangesTemplate, align: "Right" },
            { text: Localization.General.GridResetFilter, tooltipText: Localization.General.GridResetFilter, id: 'reset_filter', prefixIcon: 'e-cancel' }
        ];
    }

    onWheelScroll = () => {
        let grid = document.getElementById('stock-tools-grid');
        let toolbar = document.querySelector('#stock-tools-grid.e-grid .e-toolbar');
        let tableHeader = document.querySelector('#stock-tools-grid .e-gridheader');

        if (toolbar && tableHeader) {
            let boundingRect = tableHeader.getBoundingClientRect();
            
            if (boundingRect.top < 90) {
                toolbar.classList.add('c-hover-grid-toolbar');
                toolbar.style.width = (grid.clientWidth - 5) + 'px';
            }
            else {
                toolbar.classList.remove('c-hover-grid-toolbar');
                toolbar.style.width = 'auto';
            }
        }
    }

    //#region Grid Events
    onColumnDragStart(args) {
        this.hasDraggedColumn = true;
    }

    onCreated() {
        if (this.gridSettings) {
            let settings = null;
            try {
                settings = JSON.parse(this.gridSettings);
            } catch {
                settings = this.gridSettings;
            }
            if (settings) {
                if (settings.columns && settings.columns.length > 0) {
                    settings.columns.forEach(column => {
                        if (column.FromIndex !== column.ToIndex) {
                            var tempIndex = this.stockGridRef.getColumnIndexByField(column.Field);
                            if (tempIndex !== column.ToIndex){
                                this.stockGridRef.reorderColumnByIndex(column.FromIndex, column.ToIndex);
                            }
                        }
    
                        if (column.Width) {
                            this.stockGridRef.columns[column.ToIndex].width = column.Width;
                        }
                    });
                }

                if (settings.colName && settings.direction) {
                    this.stockGridRef.sortColumn(settings.colName, settings.direction);
                }

                if (settings.filters) {
                    settings.filters.forEach(property => {
                        this.stockGridRef.filterByColumn(property.field, property.operator, property.value);
                    });
                }
                this.stockGridRef.refreshColumns();

                // Prevent resizing of last column
                let lastColumnIndex = this.stockGridRef.columns.length - 1;    
                this.stockGridRef.columns[lastColumnIndex].allowResizing = false;
                this.stockGridRef.refreshColumns();

                if (settings.pageSize) {
                    if (settings.pageSize === "Alle" || !this.stockGridRef.pageSettings.pageSizes.some(x => x === settings.pageSize.toString())) {
                        this.stockGridRef.pageSettings.pageSize = "Alle"; //this.stockGridRef.pageSettings.totalRecordsCount;
                        document.querySelector("#stock-tools-grid .e-pagerdropdown input").value = "Alle";
                    } else {
                        this.stockGridRef.pageSettings.pageSize = settings.pageSize;
                    }
                }
            }
        }
    }

    async onActionBegin(args) {
        if (args.requestType === "paging") {
            if (isNaN(this.stockGridRef.pageSettings.pageSize)) {
                this.stockGridRef.pagerModule.setPageSize(this.stockGridRef.pageSettings.totalRecordsCount);
            }
        }
    }

    async onActionComplete(args) {
        if (args.requestType === 'paging') {
            if (this.stockGridRef.pageSettings.pageSize === this.stockGridRef.pageSettings.totalRecordsCount) {
                document.querySelector("#stock-tools-grid .e-pagerdropdown input").value = "Alle";
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, this.stockGridRef.pageSettings.pageSize, this.stockGridRef.pageSettings.totalRecordsCount, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };
            const result = await SettingsService.SaveGridSettings(payload, source.token);
            this.onWheelScroll();
        }
        else if (args.requestType === 'filtering') {
            if (this.gridSettings) {
                let settings = null;
                try {
                    settings = JSON.parse(this.gridSettings);
                } catch {
                    settings = this.gridSettings;
                }
                if (settings.filters && settings.filters.length > 0 && 
                    settings.filters.some(x => x.field == args.currentFilterObject.field && x.operator == args.currentFilterObject.operator && x.value == args.currentFilterObject.value) &&
                    args.action !== 'clearFilter') {
                    return;
                }
            }

            let pageSize = 0;
            if (this.stockGridRef.getFilteredRecords().length > 0) {
                pageSize = this.stockGridRef.getFilteredRecords().length;

                if (this.stockGridRef.getFilteredRecords().length > 0 && this.stockGridRef.getFilteredRecords().length === this.stockGridRef.pageSettings.totalRecordsCount) {
                    document.querySelector("#stock-tools-grid .e-pagerdropdown input").value = "Alle";
                }
            }
            else {
                pageSize = this.stockGridRef.pageSettings.pageSize === this.stockGridRef.pageSettings.totalRecordsCount ? "Alle" : this.stockGridRef.pageSettings.pageSize;

                if (!this.stockGridRef.pageSettings.pageSizes.some(x => x === pageSize)) {
                    pageSize = "Alle";
                }
                
                if (pageSize === "Alle") {
                    document.querySelector("#stock-tools-grid .e-pagerdropdown input").value = "Alle";
                }
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, pageSize, this.stockGridRef.pageSettings.totalRecordsCount, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "sorting") {
            if (this.gridSettings) {
                let settings = null;
                try {
                    settings = JSON.parse(this.gridSettings);
                } catch {
                    settings = this.gridSettings;
                }
                if (settings.colName && settings.colName == args.columnName && settings.direction && settings.direction == args.direction) return;
            }

            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, []);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
            }
        }
        else if (args.requestType === "reorder" && this.hasDraggedColumn) {
            let columns = this.stockGridRef.getColumns();
            let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, this.hasDraggedColumn, columns);
            this.gridSettings = tempGridSettings;
            const payload = {
                Key: this.gridSettingsKey,
                Settings: this.gridSettings
            };

            const result = await SettingsService.SaveGridSettings(payload, source.token);

            if (!result.HasError) {
                this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                let lastColumnIndex = this.stockGridRef.columns.length - 1;
                for (let index = 0; index < this.stockGridRef.columns.length; index++) {
                    let column = this.stockGridRef.columns[index];
                    if (index === lastColumnIndex) { column.allowResizing = false }
                    else { column.allowResizing = true }
                }
                this.stockGridRef.refreshColumns();
            }

            this.hasDraggedColumn = false;
        }
    }

    async onResizeStop(args) {
        args.requestType = "resizingGrid";
        let columns = this.stockGridRef.getColumns();
        let tempGridSettings = buildGridSettings(args, this.gridSettings, 0, 0, false, columns);
        this.gridSettings = tempGridSettings;
        const payload = {
            Key: this.gridSettingsKey,
            Settings: this.gridSettings
        };

        const result = await SettingsService.SaveGridSettings(payload, source.token);

        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);

            // Adjust size of last column to fill spaces
            let lastColumnIndex = this.stockGridRef.columns.length - 1;
            let totalColumnWidth = this.stockGridRef.columns.reduce((acc, curVal) => acc + parseInt(curVal.width), 0);
            let lastColumnWidth = this.stockGridRef.element.clientWidth - totalColumnWidth;
            this.stockGridRef.columns[lastColumnIndex].width = this.stockGridRef.columns[lastColumnIndex].width + lastColumnWidth;
            this.stockGridRef.refreshColumns();
        }
    }
    
    async onToolbarClick(args) {
        if (args.item.id === 'edit') {
            this.stockGridRef.editSettings.allowEditing = true;
            this.stockGridRef.toolbarModule.enableItems(['edit'], false);
            toast("Tabel er nu åben for redigering", { position: 'top-center', hideProgressBar: true });
            let grid = document.getElementById('stock-tools-grid');
            grid.classList.add("on-edit");
        } else if (args.item.id === 'reset_filter') {
            if (this.gridSettings) {
                let settings = null;
                try {
                    settings = JSON.parse(this.gridSettings);
                } catch {
                    settings = this.gridSettings;
                }
                settings.filters = [];
                settings.pageSize = "Alle";
                this.gridSettings = settings;
                const payload = {
                    Key: this.gridSettingsKey,
                    Settings: JSON.stringify(this.gridSettings)
                };
    
                const result = await SettingsService.SaveGridSettings(payload, source.token);
    
                if (!result.HasError) {
                    this.dialogOpen(Localization.General.SuccessHeader, Localization.General.GridSettingsUpdated);
                }
            }

            this.stockGridRef.clearFiltering();
            setTimeout(() => {
                document.querySelector("#stock-tools-grid .e-pagerdropdown input").value = "Alle";
            }, 600);
        }
    }

    resetChangesTemplate() {
        return <button className="e-btn e-control" style={{width: 184}} onClick={this.onResetChanges}>Nulstil Ændringer</button>

    }

    saveChangesTemplate() {
        return <button className="e-btn e-primary e-control" style={{width: 184}} onClick={this.onSaveChanges}>Opdater</button>
    }

    async onSaveChanges() {
        this.stockGridRef.endEdit();
        
        let { changeLog, otherSettingsLog } = this.state;
        let selectedRows = this.stockGridRef.getSelectedRecords();

        if (selectedRows.length > 0) {
            if (changeLog.length <= 0) {
                this.dialogOpen("Advarsel", "Der blev ikke foretaget nogen ændringer");
                return;
                // changeLog = selectedRows.map(c => { return {
                //     ProductNo: c.Product.ProductNo, Description1: c.Product.Description1, currentData: c
                // }})
    
                // this.setState({ changeLog });
            }

            if (changeLog.length > 0 || otherSettingsLog.length > 0) {
                this.changeLogDialog.show();
            }
            else {
                this.dialogOpen("Advarsel", Localization.General.NoChangesMade);
            }
        }
        else {
            this.dialogOpen("Advarsel", "Der blev ikke foretaget nogen ændringer");
        }
    }

    async processStockChanges() {
        let { changeLog } = this.state;
        let selectedRecords = this.stockGridRef.getSelectedRecords();
        let payload = [];

        for (let index = 0; index < selectedRecords.length; index++) {
            let item = selectedRecords[index];

            if (item.NewStock !== null) {
                item.StockQty = item.NewStock;
            }

            payload.push({
                Id: item.Id,
                ProductNo: item.Product.ProductNo,
                Description1: item.Product.Description1,
                PrimaryImageKey: item.Product.PrimaryImageKey,
                StockQty: item.StockQty,
                ProductId: item.Product.Id,
                MinQty: item.MinQty,
                MaxQty: item.MaxQty === 0 ? null: item.MaxQty,
                PurchaseQty: item.PurchaseQty === 0 ? null : item.PurchaseQty,
                StandardLocation: item.StandardLocation,
                Location: null,
                MachineId: item.Machine.Id,
                ChangeOrder: index + 1
            });
        }
        
        const result = await StockService.BulkSave(payload, source.token);
        
        if (!result.HasError) {
            this.dialogOpen(Localization.General.SuccessHeader, Localization.General.SuccessUpdate.replace("{context}", "Lager"));
                        
            this.changeLogDialog.hide();
            this.onResetChanges();
        }
        else {
            this.dialogOpen("Advarsel", result.ErrorMessage);
        }
    }

    async onResetChanges() {
        this.stockGridRef.closeEdit();
        this.stockGridRef.clearRowSelection();
        this.stockGridRef.editSettings.allowEditing = false;
        this.stockGridRef.toolbarModule.enableItems(['edit'], true);

        let grid = document.getElementById('stock-tools-grid');
        grid.classList.remove("on-edit");

        let products = await this.getStocks();
        this.stockGridRef.dataSource = products;
        this.stockGridRef.refresh();

        this.setState({ changeLog: [], hasUnsavedChanges: false, changeLogReport: [], otherSettingsLog: [], tempOtherSettingsChanges: [] });
    }

    async getStocks() {
        const stocks = await StockService.GetByCustomer(this.CustomerId, source.token);
        this.data = stocks.map(x => {
            x.NewStock = null;
            x.DepartmentId = x.Machine.Department.Id;
            x.DepartmentName = x.Machine.Department.Name;
            x.MachineCustomName = x.Machine.CustomName;
            x.MachineName = x.Machine.Name;
            return x;
        });

        return stocks;
    }

    validateFields(product) {
        let errorList = [];

        if (!product.ProductNo) {
            errorList.push('Vare Nr. kræves');
        }

        if (!product.Description1) {
            errorList.push('Beskrivelse kræves');
        }

        return errorList;
    }

    beforeBatchSave(args) {
        let { changeLog } = this.state;
        let { changedRecords } = args.batchChanges;
        let dataSource = this.stockGridRef.dataSource;
        
        if (changedRecords.length > 0) {
            for (let index = 0; index < changedRecords.length; index++) {
                const tempData = changedRecords[index];

                let sourceData = dataSource.find(d => d.Id === tempData.Id);
                let changeItem = {}, isExisting = false;

                if (changeLog.some(p => p.Id === tempData.Id)) {
                    changeItem = changeLog.find(p => p.Id === tempData.Id);
                    changeItem.currentData = sourceData;
                    isExisting = true;
                }
                else {
                    changeItem = {
                        Id: sourceData.Id,
                        ProductNo: tempData.Product.ProductNo,
                        Description1: tempData.Product.Description1,
                        currentData: sourceData
                    }   
                }

                if (tempData.Product.ProductNo !== sourceData.Product.ProductNo) {
                    changeItem.ProductNo = tempData.Product.ProductNo;
                    changeItem.isProductNoChanged = true;
                }

                if (tempData.Product.Description1 !== sourceData.Product.Description1) {
                    changeItem.Description1 = tempData.Product.Description1;
                    changeItem.isProductDescChanged = true;
                }

                if (tempData.MinQty !== sourceData.MinQty) {
                    changeItem.MinQty = tempData.MinQty;
                }

                if (tempData.MaxQty !== sourceData.MaxQty) {
                    changeItem.MaxQty = tempData.MaxQty;
                }

                if (tempData.PurchaseQty !== sourceData.PurchaseQty) {
                    changeItem.PurchaseQty = tempData.PurchaseQty;
                }

                if (tempData.NewStock !== sourceData.NewStock) {
                    changeItem.NewStock = tempData.NewStock;
                }

                if (Object.keys(changeItem).length > 2 && !isExisting) {
                    changeLog.push(changeItem);
                }
            }

            this.setState({ changeLog, hasUnsavedChanges: true });
        }
    }

    onCellEdit(args) {
        this.stockGridRef.selectRow(args.row.rowIndex);
    }

    onCellSave(args) {
        if (args.columnName === "Product.ProductNo") {
            let datasource = this.stockGridRef.dataSource;
            let productNos = datasource.map(x => { return { Id: x.Product.Id, ProductNo: x.Product.ProductNo} });
            if (!args.value) {
                args.cancel = true;
                this.dialogOpen("Advarsel", "Vare Nr. kræves");
            }
            else if (productNos.some(x => x.ProductNo === args.value && x.Id !== args.rowData.Product.Id)) {
                args.cancel = true;
                this.dialogOpen("Advarsel", Localization.Product.ProductNoAlreadyExist);
            }
        }
        else if (args.columnName === "Product.Description1") {
            if (!args.value) {
                args.cancel = true;
                this.dialogOpen("Advarsel", "Beskrivelse kræves");
            }
        }
        else if (args.columnName === "MaxQty") {
            if (args.rowData.PurchaseQty > 0 && args.value) {
                this.dialogOpen("Advarsel", "Maks. og indkøbs antal har begge en værdi");
            }
            else if (!args.value && !args.rowData.PurchaseQty) {
                this.dialogOpen("Advarsel", "Maks. og indkøbs antal er begge ikke udfyldt, en af dem skal ha en værdi");
            }
        }
        else if (args.columnName === "PurchaseQty") {
            if (args.rowData.MaxQty > 0 && args.value) {
                this.dialogOpen("Advarsel", "Maks. og indkøbs antal har begge en værdi");
            }
            else if (!args.value && !args.rowData.MaxQty) {
                this.dialogOpen("Advarsel", "Maks. og indkøbs antal er begge ikke udfyldt, en af dem skal ha en værdi");
            }
        }
    }
    //#endregion


    imageTemplate(props) {
        if (props.Product.PrimaryImageKey) {
            return <Image src={props.Product.PrimaryImageKey} alt="" className="product-image-data" />
        }

        return <img src={placeHolderImg} alt="primary product" className="product-image-data" />;
    }

    dialogOpen(header, content) {
        this.notificationDialog.header = header;
        this.notificationDialog.content = content;
        this.notificationDialog.buttons = [{
            // Click the footer buttons to hide the Dialog
            click: () => {
                this.notificationDialog.hide();
            },
            // Accessing button component properties by buttonModel property
            buttonModel: {
                //Enables the primary button
                isPrimary: true,
                content: 'OK'
            }
        }];
        this.notificationDialog.show();
    }

    colorEditableCell(args) {
        if (args.column.index > 0 && !args.column.allowEditing) {
            args.cell.classList.add("cell-non-editable");
        }
    }

    formatChangeValue(value) {
        return typeof value !== 'undefined' ? (value || value === 0 ? value : "Slettet") : '';
    }

    render() {
        const { loading, redirect, hasUnsavedChanges, changeLog } = this.state;

        if (loading) {
            return <Loading />
        }

        if (redirect.isRedirect) {
            return <Redirect to={redirect.to} />
        }

        return (
            <div className="content-pane-wrapper">
                <NavigationPrompt when={hasUnsavedChanges} 
                    navigate={path => this.props.history.push(path)}
                    initiateSave={() => this.onSaveChanges()}
                    shouldBlockNavigation={location => {
                        if (hasUnsavedChanges) {
                            return true;
                        }
                        return false;
                    }}
                />
                <div className="module-header">
                    <h1>{this.pageName}</h1>
                </div>
                <div className="c-post-note">
                    <div className="post-note-wrapper">
                        <div className="post-note-content text-left">
                            <h2>Instruktioner</h2>
                            <ul className="c-bullet">
                                <li>Rediger tabel: Åbner tabel for redigering</li>
                                <li>Nulstil filter: Fjerne filtre, så alle lager vises igen</li>
                                <li>Nulstil Ændringer: Fjerner alle ændringer</li>
                                <li>Opdater: Gennemfører ændringer. (gælder kun markeret lager)</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <GridComponent id="stock-tools-grid" ref={ref => this.stockGridRef = ref } dataSource={this.data} allowPaging={true} allowSorting={true} pageSettings={this.pageSettings} toolbar={this.toolbarOptions}
                        selectionSettings={this.selectionSettings} allowFiltering={true} filterSettings={this.filterSettings} locale="da" gridLines="Both" allowReordering={true} allowResizing={true} 
                        editSettings={this.editSettings} toolbarClick={this.onToolbarClick} actionBegin={this.onActionBegin.bind(this)} actionComplete={this.onActionComplete.bind(this)} columnDragStart={this.onColumnDragStart.bind(this)} 
                        created={this.onCreated.bind(this)} resizeStop={this.onResizeStop.bind(this)} beforeBatchSave={this.beforeBatchSave.bind(this)} cellSave={this.onCellSave.bind(this)} cellEdit={this.onCellEdit.bind(this)}>
                        <ColumnsDirective>
                            <ColumnDirective type='checkbox' width="40" textAlign="Center"></ColumnDirective>
                            <ColumnDirective field="Id" headerText="Billede" customAttributes={{class: 'cell-non-editable'}} width='100' template={this.imageTemplate} allowFiltering={false} allowEditing={false} isPrimaryKey={true}/>
                            <ColumnDirective headerText="Produkt Nr." field="Product.ProductNo" width='100' />
                            <ColumnDirective headerText="Beskrivelse" field="Product.Description1" width='100' />
                            <ColumnDirective headerText="Afdeling" customAttributes={{class: 'cell-non-editable'}} field="DepartmentName" width='100' allowEditing={false} />
                            <ColumnDirective headerText="Automat" customAttributes={{class: 'cell-non-editable'}} field="MachineName" width='80' allowEditing={false} />
                            <ColumnDirective headerText="Box #" customAttributes={{class: 'cell-non-editable'}} field="Box.No" width='80' textAlign="Right" allowEditing={false} />
                            <ColumnDirective headerText="Rum #" customAttributes={{class: 'cell-non-editable'}} field="Room.Name" width='80' textAlign="Right" allowEditing={false} />
                            <ColumnDirective headerText="Min" field="MinQty" width='80' textAlign="Right" editType='numericedit' edit={this.numericParams} format="n2" />
                            <ColumnDirective headerText="Max" field="MaxQty" width='80' textAlign="Right" editType='numericedit' edit={this.numericParams} format="n2" />
                            <ColumnDirective headerText="Indkøbs Antal" field="PurchaseQty" width='80' textAlign="Right" editType='numericedit' edit={this.numericParams} format="n2" />
                            <ColumnDirective headerText="Beh." customAttributes={{class: 'cell-non-editable'}} field="StockQty" width='80' textAlign="Right" editType='numericedit' edit={this.numericParams} allowEditing={false} />
                            <ColumnDirective headerText="Ny beh." field="NewStock" width='80' textAlign="Right" editType='numericedit' edit={this.numericParams} />
                        </ColumnsDirective>
                        <Inject services={[Page, Sort, Filter, Selection, Toolbar, Search, Reorder, Resize, Edit]} />
                    </GridComponent>
                </div>
                <div>
                    <DialogComponent isModal={true} buttons={this.notificationButtons} width='auto' ref={dialog => this.notificationDialog = dialog} target='body'
                        visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} />
                </div>
                <div>
                    <DialogComponent id="changes-report-dialog" isModal={true} buttons={this.changeLogButtons} width='auto' ref={dialog => this.changeLogDialog = dialog} target='body'
                        visible={false} showCloseIcon={true} cssClass="dialog-notification" animationSettings={this.animationSettings} allowDragging={true} enableResize={true} header="Rapport over ændringer">
                        <div>
                            <div>
                                <h3>Liste over lager der ændres</h3>
                                <div className="list-context-changes">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th className="text-left">Product Nr.</th>
                                                <th className="text-left">Beskrivelse</th>
                                                {/* <th className="text-left">Afdeling</th>
                                                <th className="text-left">Automat</th>
                                                <th className="text-left">Box #</th>
                                                <th className="text-left">Rum #</th> */}
                                                <th className="text-right">Min</th>
                                                <th className="text-right">Max</th>
                                                <th className="text-right">Indkøbs Antal</th>
                                                <th className="text-right">Ny beh.</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                changeLog.map(product => {
                                                    let keys = Object.keys(product);
                                                    let minQtyChanged = keys.some(x => x === "MinQty");
                                                    let maxQtyChanged = keys.some(x => x === "MaxQty");
                                                    let purchaseQtyChanged = keys.some(x => x === "PurchaseQty");
                                                    let newStockQtyChanged = keys.some(x => x === "NewStock");

                                                    return (
                                                        <tr className="changelog-row">
                                                            <td className={product.isProductNoChanged ? 'changelog-item' : ''}>{product.ProductNo}</td>
                                                            <td className={product.isProductDescChanged ? 'changelog-item' : ''}>{product.Description1}</td>
                                                            {/* <td className="changelog-item"></td>
                                                            <td className="changelog-item"></td>
                                                            <td className="changelog-item"></td>
                                                            <td className="changelog-item"></td> */}
                                                            <td className={`${minQtyChanged ? 'changelog-item' : ''} text-right`}>
                                                                {minQtyChanged ? this.formatChangeValue(product.MinQty) : product.currentData.MinQty}
                                                            </td>
                                                            <td className={`${maxQtyChanged ? 'changelog-item' : ''} text-right`}>
                                                                {maxQtyChanged ? this.formatChangeValue(product.MaxQty) : product.currentData.MaxQty}
                                                            </td>
                                                            <td className={`${purchaseQtyChanged ? 'changelog-item' : ''} text-right`}>
                                                                {purchaseQtyChanged ? this.formatChangeValue(product.PurchaseQty) : product.currentData.PurchaseQty}
                                                            </td>
                                                            <td className={`${newStockQtyChanged ? 'changelog-item' : ''} text-right`}>
                                                                {newStockQtyChanged ? product.NewStock : product.currentData.NewStock}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="report-separator"></div>
                            <div>
                                <div className="c-primary-text c-bold">Kontroller at ovenstående oplysninger er korrekte, tryk derefter opdater.</div>
                                <br />
                                <div className="sub-text">Bemærk dette kan ikke fortrydes</div>
                            </div>
                        </div>
                    </DialogComponent>
                </div>
            </div>
        )
    }
}

export default StockRegulationTools
